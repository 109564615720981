import { Button as AntDButton, Modal } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as ErrorCircleIcon } from "assets/icons/common/error-circle.svg";
import { useSaga } from "hooks/store";
import { REPOSITORIES_URL } from "legacy/constants/routes";
import { deleteRepositorySaga } from "store/slices/repositories";
import { colors } from "styles/colors";

type DeleteRepoConnectionModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisiable: boolean) => void;
  repositoryId?: string;
};

const ModalButton = styled(AntDButton)`
  font-weight: 500;
  font-size: 12px;
  border-color: ${colors.GREY_100};
`;

const ConfirmationMessage = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${colors.GREY_500};
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    display: block;
  }
  > button:last-child {
    margin-left: 12px;
  }
`;

const ModalTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.GREY_900};
`;

const ModalIconTitle = styled.div<{ hasIcon: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: ${({ hasIcon }) => (hasIcon ? "-6px 0" : "0")};
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-header {
    padding: 14px 16px;
  }
  .ant-modal-title {
    font-size: 15px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.GREY_900};
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }
`;

const DeleteRepoConnectionModal = ({
  isVisible,
  setIsVisible,
  repositoryId,
}: DeleteRepoConnectionModalProps) => {
  const [deleteRepo] = useSaga(deleteRepositorySaga);
  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const deleteRepoConnection = async () => {
    if (!repositoryId) return;
    await deleteRepo({ repositoryId });
    navigate({ pathname: REPOSITORIES_URL });
  };

  return (
    <StyledModal
      data-test="delete-repo-connection-modal"
      title={
        <ModalIconTitle hasIcon>
          <ErrorCircleIcon />
          <ModalTitle>Remove repository?</ModalTitle>
        </ModalIconTitle>
      }
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width="384px"
    >
      <ConfirmationMessage>
        Are you sure you want to remove this repository? Changes will no longer
        sync for any connected Applications, Workflows, or Scheduled Jobs.
      </ConfirmationMessage>
      <Footer>
        <ModalButton onClick={onCancel}>Cancel</ModalButton>
        <ModalButton
          onClick={deleteRepoConnection}
          danger
          data-test="delete-repo-connection-modal-confirm-button"
        >
          Delete
        </ModalButton>
      </Footer>
    </StyledModal>
  );
};

export default React.memo(DeleteRepoConnectionModal);
