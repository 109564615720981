import { ApplicationScope } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import {
  AppStateVarsAndMetaMap,
  AppStateVar,
} from "../stateVars/StateConstants";
import { getAllStateVars } from "../stateVars/selectors";
import type { AppState } from "store/types";

export const getStateVarsAndMeta = createSelector(
  [(state: AppState) => state.application.stateVarsMeta, getAllStateVars],
  (metaMap, stateVarMap): AppStateVarsAndMetaMap => {
    const allStateVarIds = new Set(
      Object.keys(stateVarMap).concat(Object.keys(metaMap)),
    );
    const stateAndMeta = [...allStateVarIds].reduce((acc, id) => {
      const stateVar = stateVarMap[id];
      if (!stateVar) return acc;
      return {
        ...acc,
        [id]: {
          stateVar: stateVar,
          stateVarMeta:
            metaMap[ApplicationScope.PAGE][id] ??
            metaMap[ApplicationScope.APP][id],
        },
      };
    }, {} as AppStateVarsAndMetaMap);

    return stateAndMeta;
  },
);

export const getStateVarMetaById = (
  state: AppState,
  scope: ApplicationScope,
  id: AppStateVar["id"],
) => state.application.stateVarsMeta[scope][id];
