import { getDynamicStringSegments } from "@superblocksteam/shared";
import CodeMirror from "codemirror";
import EntityCompletion from "autocomplete/EntityCompletion";
import PresetListCompletion from "autocomplete/PresetListComplete";
import PythonCompletion from "autocomplete/PythonCompletion";
import TernServer from "autocomplete/TernServer";

import AnalyticsUtil from "legacy/utils/AnalyticsUtil";

import { AutocompleteConfiguration, Hinter } from "./EditorConfig";
import { adjustHintPosition } from "./hintUtils";
import { PresetOption } from "./types";
import type { HintHelper, HinterUpdateRequest } from "./EditorConfig";

export const cursorBetweenBinding = (
  editor: CodeMirror.Editor,
  overrideCursorPosition?: { line: number; ch: number },
) => {
  let cursorBetweenBinding = false;
  const cursor = overrideCursorPosition ?? editor.getCursor();
  const value = editor.getValue();
  let cursorIndex = cursor.ch;
  if (cursor.line > 0) {
    for (let lineIndex = 0; lineIndex < cursor.line; lineIndex++) {
      const line = editor.getLine(lineIndex);
      // Add line length + 1 for new line character
      cursorIndex = cursorIndex + line.length + 1;
    }
  }
  const stringSegments = getDynamicStringSegments(value);
  // count of chars processed
  let cumulativeCharCount = 0;
  stringSegments.forEach((segment: string) => {
    const start = cumulativeCharCount;
    const dynamicStart = segment.indexOf("{{");
    const dynamicDoesStart = dynamicStart > -1;
    const dynamicEnd = segment.indexOf("}}");
    const dynamicDoesEnd = dynamicEnd > -1;
    const dynamicStartIndex = dynamicStart + start + 2;
    const dynamicEndIndex = dynamicEnd + start;
    if (
      dynamicDoesStart &&
      cursorIndex >= dynamicStartIndex &&
      ((dynamicDoesEnd && cursorIndex <= dynamicEndIndex) ||
        (!dynamicDoesEnd && cursorIndex >= dynamicStartIndex))
    ) {
      cursorBetweenBinding = true;
    }
    cumulativeCharCount = start + segment.length;
  });
  return cursorBetweenBinding;
};

//TODO : add currentRow to data ?? currentRow: Object of columns ie first row
export const bindingHint: HintHelper = (
  editor,
  data,
  additionalData,
  datasourceMetadata,
  apiScope,
  appScope,
  configuration: AutocompleteConfiguration,
) => {
  const ternServer = new TernServer(
    data,
    additionalData,
    apiScope,
    appScope,
    configuration,
  );

  editor.setOption("extraKeys", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: No types available
    ...editor.options.extraKeys,
    "Ctrl-Space": (cm) => ternServer.complete(cm),
    "Ctrl-I": (cm) => ternServer.showType(cm),
    "Ctrl-O": (cm) => ternServer.showDocs(cm),
  });
  return {
    update: (updateRequest: HinterUpdateRequest) => {
      ternServer.update(updateRequest);
    },
    showHint: (editor: CodeMirror.Editor) => {
      if (
        Boolean(configuration.bindingsDisabled) === cursorBetweenBinding(editor)
      ) {
        editor.closeHint();
        return false;
      }

      if (
        Boolean(configuration.shouldOpenIconSelectorForIconBindings) &&
        cursorBetweenBinding(editor) &&
        // If the last 6 characters before the cursor are "icons." then we want to open the icon selector
        editor
          .getValue()
          .slice(0, editor.indexFromPos(editor.getCursor()))
          .slice(-6) === "icons."
      ) {
        editor.refresh();
        setTimeout(() => {
          configuration.openIconSelector?.();
        }, 50);

        return false;
      }

      AnalyticsUtil.logEvent("AUTO_COMPELTE_SHOW", {});
      ternServer.complete(editor);
      return true;
    },
    unregister() {
      ternServer.unregister();
    },
  };
};

export const pythonHint: HintHelper = (
  editor,
  data,
  additionalData,
  datasourceMetadata,
  apiScope,
  appScope,
  configuration,
) => {
  const pythonCompleter = new PythonCompletion(
    data,
    additionalData,
    apiScope,
    appScope,
    configuration,
  );

  return {
    update: (updateRequest: HinterUpdateRequest) => {
      pythonCompleter.update(updateRequest);
    },
    showHint: (instance) => {
      if (cursorBetweenBinding(editor)) {
        return false;
      }

      pythonCompleter.complete(instance);
      return true;
    },
    unregister() {
      pythonCompleter.unregister();
    },
  };
};

const DEFAULT_SCHEMA_NAMES = ["public", "dbo", "database"];

export const sqlHint: HintHelper = (
  editor,
  data,
  additionalData,
  datasourceMetadata,
) => {
  const tables: Record<string, string[]> = {};

  if (datasourceMetadata?.dbSchema?.tables) {
    datasourceMetadata?.dbSchema?.tables.forEach((table) => {
      const tableName =
        table.schema && !DEFAULT_SCHEMA_NAMES.includes(table.schema)
          ? `${table.schema}.${table.name}`
          : table.name;

      tables[tableName] =
        table.columns?.map((col) => col.escapedName ?? col.name) ?? [];
    });
  }

  return {
    update: (updateRequest: HinterUpdateRequest) => {
      if (updateRequest.datasourceMetadata?.dbSchema?.tables) {
        updateRequest.datasourceMetadata?.dbSchema?.tables.forEach((table) => {
          const tableName =
            table.schema && !DEFAULT_SCHEMA_NAMES.includes(table.schema)
              ? `${table.schema}.${table.name}`
              : table.name;
          tables[tableName] =
            table.columns?.map((col) => col.escapedName ?? col.name) ?? [];
        });
      }
    },
    showHint: (instance) => {
      if (cursorBetweenBinding(editor)) {
        return false;
      }

      instance.showHint({
        completeSingle: false,
        hint: adjustHintPosition(CodeMirror.hint.sql),
        tables,
      });
      return true;
    },
    unregister() {
      // Nothing to do here
    },
  };
};

export const entityHint: HintHelper = (
  editor,
  data,
  additionalData,
  datasourceMetadata,
  apiScope,
  appScope,
  configuration,
  openAiAssistant,
) => {
  const entityCompleter = new EntityCompletion(
    data,
    additionalData,
    apiScope,
    appScope,
    configuration,
    openAiAssistant,
  );
  return {
    update: (updateRequest: HinterUpdateRequest) => {
      entityCompleter.update(updateRequest);
    },
    showHint: (instance) => {
      entityCompleter.complete(instance);
      return true;
    },
    unregister() {
      entityCompleter.unregister();
    },
  };
};

export const presetListHint: (options: PresetOption[]) => Hinter = (
  options,
) => {
  const presetListCompleter = new PresetListCompletion(options);

  return {
    showHint: (instance) => {
      presetListCompleter.complete(instance);

      return true;
    },
    unregister() {
      presetListCompleter.unregister();
    },
  };
};
