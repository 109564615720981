import { RoleTypeEnum } from "@superblocksteam/shared";
import { Modal } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  ModalInnerWrapper,
  FooterWrapperWide,
  ModalWrapClass,
} from "components/ui/Modal";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { RoleDropdown } from "pages/Permissions/RoleDropdown";
import { useUpdateUserOrgRolesMutation } from "store/slices/reduxApi/rbac";
import { sendSuccessUINotification } from "utils/notification";
import { UserToRender } from "./constants";

const ChangeRoleModal = ({
  selectedUsers,
  isOpen,
  setIsOpen,
  onUsersUpdated,
  clearSelectedUsers,
}: {
  selectedUsers: UserToRender[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUsersUpdated: (updatedUsers: UserToRender[]) => void;
  clearSelectedUsers: () => void;
}) => {
  const modalRef = useRef(null);
  const organizationId = useSelector(getCurrentOrgId);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const [updateUserOrgRoles, { isLoading: isSaving }] =
    useUpdateUserOrgRolesMutation();

  const [saveError, setSaveError] = useState<string | null>(null);

  const handleSave = useCallback(async () => {
    if (!selectedRole) {
      return;
    }
    try {
      await updateUserOrgRoles({
        organizationId,
        assignmentId: selectedRole,
        userIds: selectedUsers.map((user) => user.id),
      }).unwrap();
      const newUsers = selectedUsers.map((user, index) => ({
        ...user,
        roleId: selectedRole,
      }));

      onUsersUpdated(newUsers);
      setIsOpen(false);
      setSaveError(null);
      sendSuccessUINotification({
        message: `${selectedUsers.length} roles updated`,
      });
      clearSelectedUsers();
    } catch (error: any) {
      setSaveError(
        `Failed to update roles: ${
          error?.message ??
          error?.data?.responseMeta?.error?.message ??
          error?.status
        }`,
      );
    }
  }, [
    selectedRole,
    updateUserOrgRoles,
    organizationId,
    selectedUsers,
    onUsersUpdated,
    setIsOpen,
    clearSelectedUsers,
  ]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Modal
      title={`Change the role of ${selectedUsers.length} users?`}
      open={isOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} ref={modalRef}>
        <div className={FormWrapper}>
          <FormItem
            label="New organization role"
            required={true}
            error={saveError}
          >
            <RoleDropdown
              selectedRole={selectedRole}
              onRoleChanged={setSelectedRole}
              roleType={RoleTypeEnum.ORGANIZATION}
              isClearable={false}
              isDisabled={false}
              parentRef={modalRef}
              shouldAutoUpdate={true}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton onClick={handleCancel} loading={isSaving}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            loading={isSaving}
            disabled={!selectedRole}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeRoleModal;
