import { Plugin, LanguagePluginID } from "@superblocksteam/shared";
import { ActionDto } from "store/slices/apis/types";
import {
  extractBindingsFromAction,
  extractBindingsFromValue,
} from "store/slices/apis/utils/bindings";
import {
  BlockType,
  GenericBlock,
  BreakControl,
  ReturnControl,
  WaitControl,
  ConditionControl,
  LoopControl,
  VariablesControl,
  StepBlock,
  ThrowControl,
  ControlBlock,
  ParallelControl,
  SendControl,
} from "./types";
import type { ScopeSpecificDataTree } from "utils/dataTree/MergedDataTree";

type BlockBindings = { blockName: string; bindings: string[] };

type BindingContextParams = {
  identifiers: Set<string>;
  dataTree: ScopeSpecificDataTree;
  plugins: Partial<Record<string, Plugin>>;
};

const makeGetSet = <
  T extends Record<string, unknown> & { [key in K]: string },
  K extends keyof T,
>(
  val: T,
  propertyKey: K,
): [string, (newVal: string) => void] => {
  return [
    val[propertyKey],
    (newVal: string) => {
      (val as { [key in K]: string })[propertyKey] = newVal;
    },
  ];
};

/*
  Only return the properties that could contain bindings or code
*/
export function* getSetControlBlockExpressions(
  block: ControlBlock,
): Generator<[string, (newVal: string) => void]> {
  const { config, type } = block;

  switch (type) {
    case BlockType.BREAK: {
      const controlConfig = config as BreakControl;
      yield makeGetSet(controlConfig, "condition");
      return;
    }
    case BlockType.THROW: {
      const controlConfig = config as ThrowControl;
      yield makeGetSet(controlConfig, "error");
      return;
    }
    case BlockType.RETURN: {
      const controlConfig = config as ReturnControl;
      yield makeGetSet(controlConfig, "data");
      return;
    }
    case BlockType.WAIT: {
      const controlConfig = config as WaitControl;
      yield makeGetSet(controlConfig, "condition");
      return;
    }
    case BlockType.PARALLEL: {
      const controlConfig = config as ParallelControl;
      if (controlConfig.dynamic) {
        yield makeGetSet(controlConfig.dynamic, "paths");
      }
      return;
    }
    case BlockType.CONDITION: {
      const controlConfig = config as ConditionControl;
      yield makeGetSet(controlConfig.if, "condition");
      for (const cond of controlConfig.elseIf ?? []) {
        yield makeGetSet(cond, "condition");
      }
      return;
    }
    case BlockType.LOOP: {
      const controlConfig = config as LoopControl;
      yield makeGetSet(controlConfig, "range");
      return;
    }
    case BlockType.TRY_CATCH: {
      return;
    }
    case BlockType.VARIABLES: {
      const controlConfig = config as VariablesControl;
      for (const variable of controlConfig.variables) {
        if (variable.value) {
          yield makeGetSet(variable, "value");
        }
      }
      return;
    }
    case BlockType.SEND: {
      const controlConfig = config as SendControl;
      yield makeGetSet(controlConfig, "message");
      return;
    }
    case BlockType.STREAM: {
      return;
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
}

// Return a list of all the expressions in the control block - e.g. loop range.
const getControlBlockExpressions = (block: ControlBlock): string[] => {
  return Array.from(getSetControlBlockExpressions(block)).map(([expr]) => expr);
};

async function computeControlBlockBindings(
  block: ControlBlock,
  params: BindingContextParams,
): Promise<BlockBindings> {
  const { identifiers, dataTree } = params;
  const expressions = getControlBlockExpressions(block);
  const bindings = (
    await Promise.all(
      expressions.map(async (expression) => {
        const bindings = await extractBindingsFromValue(
          expression,
          identifiers,
          LanguagePluginID.JavaScript,
          dataTree,
        );
        return bindings;
      }),
    )
  ).flat();

  return {
    blockName: block.name,
    bindings,
  };
}

async function computeStepBlockBindings(
  block: StepBlock,
  params: BindingContextParams,
) {
  const { config, name } = block;

  const action: ActionDto = config;
  const pluginId = action.pluginId;
  const plugin = pluginId && params.plugins[pluginId];
  if (!plugin) {
    return {
      blockName: name,
      bindings: [],
    };
  }
  const bindings = await extractBindingsFromAction(
    action,
    params.identifiers,
    plugin,
    params.dataTree,
  );
  return {
    blockName: name,
    bindings,
  };
}

export async function computeBlockBindings(
  block: GenericBlock,
  params: BindingContextParams,
) {
  const { type } = block;
  if (type === BlockType.STEP) {
    return await computeStepBlockBindings(block as StepBlock, params);
  } else {
    return await computeControlBlockBindings(block as ControlBlock, params);
  }
}
