import {
  Agent,
  AgentKey,
  AgentStatus,
  AgentType,
  Health,
  Organization,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { HttpError } from "store/utils/types";
import { sendErrorUINotification } from "utils/notification";
import { SUPERBLOCKS_UI_PROBE_AGENT_TIMEOUT_MS } from "../../../env";
import { AgentApiPaths } from "../../utils/agent";
import { callServer, callAgent, HttpMethod } from "../../utils/client";
import { OrchestratorApiPaths } from "../../utils/orchestrator";

enum AgentErrorMitigations {
  CHECK_AGENT_RUNNING = "Make sure your agent service is running and healthy.",
  CHECK_INGRESS = "Check the ingress and firewall configuration on your agent deployment.",
  CHECK_EGRESS = "Check the egress and firewall configuration on your agent deployment.",
  REMOVE_EXTRA_AUTH = "Please remove any extra authentication applied to the agent endpoints. The agent will authenticate on requests.",
  CHECK_CORS = "If you are using proxy or service mesh in your agent deployment, check if it's populating the CORS headers properly.",
  CHECK_VPN = "If a VPN is required to access the agent, check that you're connected to the VPN on your device.",
}

export class AgentWithHealth {
  agent: Agent;
  browserAgentHealthcheckError?: string;
  browserAgentHealthcheckErrorCode?: number;
  healthDetail?: Health;

  constructor({
    agent,
    browserAgentHealthcheckError,
    browserAgentHealthcheckErrorCode,
    healthDetail,
  }: {
    agent: Agent;
    browserAgentHealthcheckError?: string;
    browserAgentHealthcheckErrorCode?: number;
    healthDetail?: Health;
  }) {
    this.agent = agent;
    this.browserAgentHealthcheckError = browserAgentHealthcheckError;
    this.browserAgentHealthcheckErrorCode = browserAgentHealthcheckErrorCode;
    this.healthDetail = healthDetail;
  }

  healthy() {
    return this.agent.status === AgentStatus.ACTIVE;
  }

  // The browser only knows agent to server errors when the browser can connect to the agent
  hasServerErrors() {
    return !isEmpty(this.healthDetail?.server_errors);
  }

  hasServerConnectionIssue() {
    return this.agent.status === AgentStatus.DISCONNECTED;
  }

  hasBrowserConnectionIssue() {
    return (
      this.browserAgentHealthcheckError &&
      (!this.browserAgentHealthcheckErrorCode ||
        this.browserAgentHealthcheckErrorCode >= 500)
    );
  }

  hasUnexpectedAuthentication() {
    return this.browserAgentHealthcheckErrorCode === 401;
  }

  description(): string {
    if (this.hasServerConnectionIssue() && this.hasBrowserConnectionIssue()) {
      return "This agent cannot connect to Superblocks Cloud, and your browser cannot connect to this agent.";
    }
    if (this.hasServerConnectionIssue()) {
      return "This agent cannot connect to Superblocks Cloud, but your browser can connect to this agent.";
    }
    if (this.hasBrowserConnectionIssue()) {
      return "Your browser cannot connect to this agent, but this agent can connect to Superblocks cloud.";
    }
    return "This agent is healthy.";
  }

  mitigations() {
    if (this.hasUnexpectedAuthentication()) {
      return [AgentErrorMitigations.REMOVE_EXTRA_AUTH];
    }
    let mitigations: string[] = [];
    if (this.hasServerConnectionIssue()) {
      mitigations = [...mitigations, AgentErrorMitigations.CHECK_EGRESS];
    }
    if (this.hasBrowserConnectionIssue()) {
      mitigations = [
        ...mitigations,
        AgentErrorMitigations.CHECK_INGRESS,
        AgentErrorMitigations.CHECK_VPN,
        AgentErrorMitigations.CHECK_CORS,
      ];
    }
    if (this.hasServerConnectionIssue() && this.hasBrowserConnectionIssue()) {
      mitigations = [...mitigations, AgentErrorMitigations.CHECK_AGENT_RUNNING];
    }
    return mitigations;
  }
}

/**
 * @param organization Get the agents under the organization.
 * @param probeAll True: Browser will healthcheck OPAs and Cloud agents of the organization.
 *   False: Browser will only healthcheck either OPAs or Cloud agents based on the organization setting.
 *
 */
export function getAgents(
  organization: Organization,
  probeAll: boolean,
  controlFlowOverrideEnabled: boolean,
): Promise<AgentWithHealth[]> {
  const organizationId = organization.id;
  const promise = callServer<Agent[]>(
    {
      method: HttpMethod.Get,
      url: `v1/organizations/${organizationId}/agents`,
    },
    {
      notifyOnError: false,
      shouldCrashApp: false,
      onError: (error: HttpError) => {
        sendErrorUINotification({
          message: `Error fetching agents: ${error.message} (${error.code})`,
        });
      },
    },
  );
  return promise.then((agents) =>
    probeAgents(agents, organization, probeAll, controlFlowOverrideEnabled),
  );
}

export function probeAgents(
  agents: Agent[],
  organization: Organization,
  probeAll: boolean,
  controlFlowOverrideEnabled: boolean,
): Promise<AgentWithHealth[]> {
  if (isEmpty(agents)) {
    return Promise.resolve([]);
  }

  const needProbe = (agent: Agent): boolean => {
    if (probeAll) {
      return true;
    }
    if (
      agent.type === organization.agentType &&
      agent.type !== AgentType.MULTITENANT &&
      agent.status === AgentStatus.ACTIVE
    ) {
      return true;
    }

    return false;
  };

  const agentsNeedProbe = agents.filter(needProbe);
  const agentsNoNeedProbe = agents.filter(
    (agent) => !agentsNeedProbe.includes(agent),
  );

  const noNeedProbeInfo = agentsNoNeedProbe.map(
    (agent) => new AgentWithHealth({ agent: agent }),
  );

  // Merge the health info of agents need probes and agents don't need probes
  return uniqueProbe(
    agentsNeedProbe,
    organization,
    controlFlowOverrideEnabled,
  ).then((probeInfo) => {
    return noNeedProbeInfo.concat(probeInfo);
  });
}

// only probe the same url once
function uniqueProbe(
  agents: Agent[],
  organization: Organization,
  controlFlowOverrideEnabled: boolean,
): Promise<AgentWithHealth[]> {
  const urls: string[] = [];
  const deduped: Agent[] = [];
  for (const agent of agents) {
    if (!urls.includes(agent.url)) {
      deduped.push(agent);
      urls.push(agent.url);
    }
  }

  return Promise.all(
    deduped.map((agent) =>
      probe(agent, organization, controlFlowOverrideEnabled),
    ),
  ).then((agentWithHealths) => {
    const result: AgentWithHealth[] = [];
    // link the health info for agents with the same url
    for (const agentWithHealth of agentWithHealths) {
      for (const agent of agents) {
        if (agent.url === agentWithHealth.agent.url) {
          // Override the server side agent status with browser side info
          if (
            agent.status === AgentStatus.ACTIVE &&
            agentWithHealth.agent.status === AgentStatus.BROWSER_UNREACHABLE
          ) {
            agent.status = agentWithHealth.agent.status;
          }

          result.push(
            new AgentWithHealth({
              agent,
              browserAgentHealthcheckError:
                agentWithHealth.browserAgentHealthcheckError,
              browserAgentHealthcheckErrorCode:
                agentWithHealth.browserAgentHealthcheckErrorCode,
              healthDetail: agentWithHealth.healthDetail,
            }),
          );
        }
      }
    }
    return result;
  });
}

function probe(
  agent: Agent,
  organization: Organization,
  controlFlowOverrideEnabled: boolean,
): Promise<AgentWithHealth> {
  const isOrchestrator = controlFlowOverrideEnabled;

  return callAgent<Health>({
    agents: [agent],
    organization,
    method: HttpMethod.Get,
    url: isOrchestrator
      ? OrchestratorApiPaths.HEALTHCHECK
      : AgentApiPaths.HEALTHCHECK,
    timeout: Number(SUPERBLOCKS_UI_PROBE_AGENT_TIMEOUT_MS),
  })
    .then((response) => {
      if (
        (response?.systemError || response?.systemErrorCode) &&
        agent.status === AgentStatus.ACTIVE
      ) {
        agent.status = AgentStatus.BROWSER_UNREACHABLE;
      }
      return new AgentWithHealth({
        agent: agent,
        browserAgentHealthcheckError: response?.systemError,
        browserAgentHealthcheckErrorCode: response?.systemErrorCode,
        healthDetail: response,
      });
    })
    .catch((reason) => {
      return new AgentWithHealth({
        agent: agent,
        browserAgentHealthcheckError: reason,
      });
    });
}

export function removeAgent(organizationId: string, agentId: string) {
  return callServer<AgentKey[]>({
    method: HttpMethod.Delete,
    url: `v1/organizations/${organizationId}/agents/${agentId}`,
  });
}

export function getNewAgents(
  organization: Organization,
  startTime: Date,
  controlFlowOverrideEnabled: boolean,
) {
  const organizationId = organization.id;
  const newAgents = callServer<Agent[]>({
    method: HttpMethod.Get,
    url: `v1/organizations/${organizationId}/agents`,
    query: { startTime: startTime.toJSON() },
  });
  return newAgents
    .then((agents) => {
      const agentWithHealthPromise = agents.map((agent) =>
        probe(agent, organization, controlFlowOverrideEnabled),
      );
      return Promise.all(agentWithHealthPromise);
    })
    .then((agentWithHealth) => {
      return agentWithHealth.map((agentWithHealth) => {
        if (
          agentWithHealth.browserAgentHealthcheckError &&
          agentWithHealth.agent.status === AgentStatus.ACTIVE
        ) {
          agentWithHealth.agent.status = AgentStatus.BROWSER_UNREACHABLE;
        }
        return agentWithHealth.agent;
      });
    });
}
