import { Badge as AntBadge, BadgeProps } from "antd";
import React from "react";
import styled from "styled-components";

type Props = {
  backgroundColor?: string;
  children?: React.ReactNode;
} & BadgeProps;

const styledBadgeAttrs = (props: Props) => ({
  backgroundColor: props.backgroundColor,
});

const StyledBadge = styled(AntBadge).attrs(styledBadgeAttrs)`
  > sup {
    background: ${(props) => props.backgroundColor};
  }
`;

const Badge = (props: Props) => {
  return <StyledBadge {...props}>{props.children}</StyledBadge>;
};

export default Badge;
