import { AgentType } from "@superblocksteam/shared";
import _ from "lodash";
import { SUPPORT_EMAIL_ADDRESS } from "../../legacy/constants/routes";

export const noActiveAgentMessage = (organizationAgentType: AgentType) => {
  if (organizationAgentType === AgentType.ONPREMISE) {
    return NO_ACTIVE_ONPREMISE_MESSAGE_GENERAL;
  }
  return NO_ACTIVE_CLOUD_MESSAGE;
};

const NO_ACTIVE_ONPREMISE_MESSAGE_GENERAL =
  "Unable to connect to on-premise agent(s)";

const NO_ACTIVE_CLOUD_MESSAGE =
  `Cannot connect to Superblocks Cloud agents. Please check your network and contact the Superblocks team at ${SUPPORT_EMAIL_ADDRESS}` as const;

type InvalidPropsMap = { [key: string]: InvalidPropsMap | boolean };

export enum ApiErrorType {
  EXECUTION_ERROR = "EXECUTION_ERROR",
  NOT_EXECUTED = "NOT_EXECUTED",
  STALE = "STALE",
}

export type ApiError = {
  apiId: string;
  stepId: string | undefined;
  apiName: string;
  stepName: string | undefined;
  error: string;
  type: ApiErrorType;
};

const hasInvalidPropsRecursive = (
  invalidPropsMap: InvalidPropsMap | undefined,
  parentPath: string,
  allowlist?: string[],
): boolean => {
  if (invalidPropsMap) {
    const nameToInvalidMapArray = Object.entries(invalidPropsMap);
    for (let i = 0; i < nameToInvalidMapArray.length; i++) {
      const [propName, invalid] = nameToInvalidMapArray[i];
      if (allowlist && allowlist.includes(`${parentPath}${propName}`)) {
        continue;
      }
      if (_.isBoolean(invalid) && invalid) {
        return true;
      }
      if (
        _.isObject(invalid) &&
        hasInvalidPropsRecursive(
          invalid,
          `${parentPath}${propName}.`,
          allowlist,
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

export const hasInvalidPropsInComponent = (
  invalidPropsMap: InvalidPropsMap | undefined,
  allowlist?: string[],
): boolean => {
  return hasInvalidPropsRecursive(invalidPropsMap, "", allowlist);
};

const getInvalidPropsRecursive = (
  invalidPropsMap: InvalidPropsMap | undefined,
  parentPath: string,
  allowlist?: string[],
) => {
  if (!invalidPropsMap) return [];
  let invalidPropNames: string[] = [];

  Object.entries(invalidPropsMap).forEach(([propName, invalid]) => {
    if (allowlist && allowlist.includes(`${parentPath}${propName}`)) {
      return;
    }
    if (_.isBoolean(invalid) && invalid) {
      invalidPropNames.push(`${parentPath}${propName}`);
      return;
    }
    if (_.isObject(invalid)) {
      invalidPropNames = invalidPropNames.concat(
        getInvalidPropsRecursive(
          invalid,
          `${parentPath}${propName}.`,
          allowlist,
        ),
      );
      return;
    }
  });
  return invalidPropNames;
};

export const getComponentErrorMessage = (
  invalidPropsMap: InvalidPropsMap | undefined,
  allowlist?: string[],
) => {
  const invalidPropNames = getInvalidPropsRecursive(
    invalidPropsMap,
    "",
    allowlist,
  );
  if (invalidPropNames.length > 0) {
    return `Values of the following properties do not match required data format: ${invalidPropNames.join(
      ", ",
    )}`;
  }
  return "";
};
