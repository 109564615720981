import { all, put, select, takeLatest } from "redux-saga/effects";
import { setMetaProps } from "legacy/actions/metaActions";
import {
  deleteTableRows,
  insertTableRows,
  updateTableRows,
} from "legacy/actions/widgetActions";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getDataTreeItem } from "legacy/selectors/dataTreeSelectors";
import { TableWidgetEvaluatedProps } from "legacy/widgets/TableWidget/TableWidgetConstants";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { sendWarningUINotification } from "utils/notification";
import { pluralize } from "utils/string";

function* handleUpdateTableRows(
  action: ReturnType<typeof updateTableRows>,
): Generator<any, any, any> {
  const flag = yield select(selectFlagById, Flag.ENABLE_PROGRAMMATIC_TABLE);
  if (!flag) {
    return;
  }

  const { rows, options = { absoluteIndices: false } } = action.payload;

  const evaluatedTable: TableWidgetEvaluatedProps = yield select(
    getDataTreeItem,
    action.payload.widgetId,
  );

  const isColumnEditable = (columnId: string) =>
    evaluatedTable.primaryColumns[columnId]?.isEditable;

  const isInserted = (index: number) =>
    evaluatedTable.inserts?.insertedRowsById?.[index] != null;

  const overrides = fastClone(evaluatedTable.editOverrides) ?? {};
  const inserts = fastClone(evaluatedTable.inserts ?? {});

  const nonEditableColumns: string[] = [];

  Object.entries(rows).forEach(([rowIndexStr, row]) => {
    const rowIndex = Number.parseInt(rowIndexStr);
    const originalRowIndex = options.absoluteIndices
      ? Number(
          Object.entries(evaluatedTable.tableDataWithInsertsOrderMap).find(
            ([, value]) => value === rowIndex,
          )?.[0] ?? -1,
        )
      : evaluatedTable.filteredOrderMap?.[rowIndex];

    if (originalRowIndex == null || typeof row !== "object" || !row) {
      return;
    }

    row = Object.fromEntries(
      Object.entries(row).filter(([key]) => {
        const editable = isColumnEditable(key);
        if (!editable) {
          nonEditableColumns.push(key);
        }
        return editable;
      }),
    );

    if (
      isInserted(originalRowIndex) &&
      inserts?.insertedRowsById?.[originalRowIndex]
    ) {
      inserts.insertedRowsById[originalRowIndex] = {
        ...inserts.insertedRowsById[originalRowIndex],
        ...row,
      };
    } else {
      Object.entries(row).forEach(([key, value]) => {
        overrides[originalRowIndex] = {
          ...overrides[originalRowIndex],
          [key]: { value },
        };
      });
    }
  });

  const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);

  const uniqueNonEditableColumns = Array.from(new Set(nonEditableColumns));

  if (appMode === APP_MODE.EDIT && uniqueNonEditableColumns.length) {
    const listFormatter = new Intl.ListFormat("en", {
      style: "long",
      type: "conjunction",
    });

    const errorMessage = `Non-editable columns are detected in the updated rows. ${pluralize(
      uniqueNonEditableColumns.length,
      "Column",
    )} ${listFormatter.format(
      uniqueNonEditableColumns.map((param) => `"${param}"`),
    )} will not be updated.`;
    sendWarningUINotification({
      message: errorMessage,
      isUISystemInitiated: true,
    });
  }

  yield put(
    setMetaProps(action.payload.widgetId, {
      editOverrides: overrides,
      inserts,
    }),
  );
}

function* handleDeleteTableRows(
  action: ReturnType<typeof deleteTableRows>,
): Generator<any, any, any> {
  const flag = yield select(selectFlagById, Flag.ENABLE_PROGRAMMATIC_TABLE);
  if (!flag) {
    return;
  }

  const evaluatedTable: TableWidgetEvaluatedProps = yield select(
    getDataTreeItem,
    action.payload.widgetId,
  );

  if (!evaluatedTable.enableRowDeletion) {
    const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);

    if (appMode === APP_MODE.EDIT) {
      sendWarningUINotification({
        message: "Row deletion is disabled for this table.",
        isUISystemInitiated: true,
      });
    }

    return;
  }

  const isInserted = (index: number) =>
    evaluatedTable.inserts?.insertedRowsById?.[index] != null;

  const deletedRowIndices = fastClone(evaluatedTable.deletedRowIndices) ?? {};
  const inserts = fastClone(evaluatedTable.inserts ?? {});

  action.payload.rows.forEach((rowIndex) => {
    const originalRowIndex = evaluatedTable.filteredOrderMap?.[rowIndex];

    if (originalRowIndex == null) {
      return;
    }

    if (isInserted(originalRowIndex)) {
      const position = inserts.insertedRowsById?.[originalRowIndex]?.[
        "$relativePosition"
      ] as number;

      delete inserts.insertedRowsById?.[originalRowIndex];
      delete inserts.insertedRowValidations?.[originalRowIndex];
      if (inserts.insertedRowIdsByIndex && position != null) {
        inserts.insertedRowIdsByIndex[position] = inserts.insertedRowIdsByIndex[
          position
        ].filter((id) => id !== originalRowIndex);
      }
    } else {
      deletedRowIndices[originalRowIndex] = true;
    }
  });

  yield put(
    setMetaProps(action.payload.widgetId, {
      deletedRowIndices,
      inserts,
    }),
  );
}

function* handleInsertTableRows(
  action: ReturnType<typeof insertTableRows>,
): Generator<any, any, any> {
  const flag = yield select(selectFlagById, Flag.ENABLE_PROGRAMMATIC_TABLE);
  if (!flag) {
    return;
  }

  const { widgetId, startIndex, rows } = action.payload;

  if (Number.isNaN(startIndex)) {
    return;
  }

  const evaluatedTable: TableWidgetEvaluatedProps = yield select(
    getDataTreeItem,
    widgetId,
  );

  if (!evaluatedTable.enableRowInsertion) {
    const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);

    if (appMode === APP_MODE.EDIT) {
      sendWarningUINotification({
        message: "Row insertion is disabled for this table.",
        isUISystemInitiated: true,
      });
    }

    return;
  }

  const isInserted = (index: number) =>
    evaluatedTable.inserts?.insertedRowsById?.[index] != null;

  const inserts = fastClone(evaluatedTable.inserts);

  const insertedRowsById = inserts?.insertedRowsById ?? {};
  let insertedRowIdsByIndex = inserts?.insertedRowIdsByIndex ?? {};

  const rowsToAdd = rows.filter(
    (row) => typeof row === "object" && row != null && !Array.isArray(row),
  );

  const addingToEndOfTable =
    startIndex >= evaluatedTable.tableDataWithInserts.length;

  // Find the actual index to insert the row at, accounts for other insertions in the table
  let indexToInsert = addingToEndOfTable
    ? evaluatedTable.tableData.length
    : (evaluatedTable.filteredOrderMap?.[startIndex] ?? 0);

  if (isInserted(indexToInsert) && insertedRowsById && !addingToEndOfTable) {
    indexToInsert = insertedRowsById?.[indexToInsert]?.[
      "$relativePosition"
    ] as number;
  }

  const updatedInsertedRows = insertedRowIdsByIndex?.[indexToInsert]
    ? [...insertedRowIdsByIndex[indexToInsert]]
    : [];

  // if we are inserting into a group of inserted rows, we need to update the relative position of the rows
  if (updatedInsertedRows.length > 0 && !addingToEndOfTable) {
    // first get the realtive index of the row we are inserting into
    const targetInsertedRowIndex =
      evaluatedTable.filteredOrderMap?.[startIndex] ?? -1;
    // then find its position in the array of inserted rows
    const relativeIndexInInsertedRows =
      updatedInsertedRows.findIndex((n) => n === targetInsertedRowIndex) ?? -1;

    const keys: number[] = [];
    rowsToAdd.forEach((row, idx) => {
      const key = evaluatedTable.tableDataWithInserts.length + idx + 1;

      insertedRowsById[key] = {
        ...row,
        $originalIndex: key,
        $relativePosition: indexToInsert,
      };
      keys.push(key);
    });

    updatedInsertedRows.splice(relativeIndexInInsertedRows, 0, ...keys);
  } else {
    rowsToAdd.forEach((row, idx) => {
      const key = evaluatedTable.tableDataWithInserts.length + idx + 1;
      updatedInsertedRows.push(key);

      insertedRowsById[key] = {
        ...row,
        $originalIndex: key,
        $relativePosition: indexToInsert,
      };
    });
  }

  insertedRowIdsByIndex = {
    ...insertedRowIdsByIndex,
    [indexToInsert]: updatedInsertedRows,
  };

  yield put(
    setMetaProps(action.payload.widgetId, {
      inserts: {
        ...inserts,
        insertedRowsById,
        insertedRowIdsByIndex,
      },
    }),
  );
}

export default function* tableSagas() {
  yield all([
    takeLatest(updateTableRows.type, handleUpdateTableRows),
    takeLatest(deleteTableRows.type, handleDeleteTableRows),
    takeLatest(insertTableRows.type, handleInsertTableRows),
  ]);
}
