import React, { useState } from "react";
import styled from "styled-components";
import LoadingIndicator from "components/ui/LoadingIndicator";
import { useAppSelector } from "store/helpers";
import { getAnalysisStatus } from "./selectors";
import { useReferenceAnalysis } from "./useReferenceAnalysis";

const RunAgainButton = styled.span`
  color: ${({ theme }) => theme.colors.INFO};
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;

  border: 1px solid ${({ theme }) => theme.colors.WARNING};
  background: ${({ theme }) => theme.colors.ORANGE_25};
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
`;

const LoadingIndicatorPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MIN_ANIM_TIME = 500; // gives enough time for it not to be jarring

export const RunAnalysisAgain = () => {
  const runReferenceCalculation = useReferenceAnalysis();

  const [showLoading, setShowLoading] = useState(false);
  const analysisStatus = useAppSelector(getAnalysisStatus);
  const isStale = analysisStatus.isStale;

  if (!isStale && !showLoading) {
    return null;
  }

  return (
    <Container>
      {showLoading && (
        <LoadingIndicatorPosition>
          <LoadingIndicator />
        </LoadingIndicatorPosition>
      )}
      <div style={{ opacity: showLoading ? 0.25 : 1 }}>
        The app has been updated since the last reference analysis. This
        information may be outdated.{" "}
        <RunAgainButton
          onClick={async () => {
            const startTime = Date.now();
            setShowLoading(true);
            await runReferenceCalculation();
            const elapsed = Date.now() - startTime;
            setTimeout(
              () => {
                setShowLoading(false);
              },
              Math.max(0, MIN_ANIM_TIME - elapsed),
            );
          }}
        >
          Run analysis again.
        </RunAgainButton>
      </div>
    </Container>
  );
};
