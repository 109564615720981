// Avatar
export const DefaultAvatarColor = {
  FRONT: "#a4aab7",
  BACKGROUND: "#f0f0f0",
};

// for generating color based on string. e.g. used for generating avatar color
const DEFAULT_H_RANGE = [0, 360];
const DEFAULT_S_RANGE = [50, 70];
const DEFAULT_L_RANGE = [70, 85];

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

export const generateHSL = (
  name: string,
  hRange: number[] = DEFAULT_H_RANGE,
  sRange: number[] = DEFAULT_S_RANGE,
  lRange: number[] = DEFAULT_L_RANGE,
): { front: string; background: string } => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return {
    front: `hsl(${h}, ${s}%, ${l * 0.5}%)`,
    background: `hsl(${h}, ${s}%, ${l}%)`,
  };
};
