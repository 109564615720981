import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, InputRef, Space } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { RoundedInput } from "components/ui/RoundedInput";
import { FullWidthSpace } from "components/ui/Space";
import { ToggleSelect, ToggleSelectOption } from "components/ui/ToggleSelect";
import { User } from "legacy/constants/userConstants";
import { RoundedDropDown } from "./RoundedDropDown";
import {
  AdditionalQuestion,
  defaultAdditionalQuestionType,
  FormAnswers,
  FormElement,
} from "./SurveyForm";
import { ProgressBarContainer, SurveyProgressBar } from "./SurveyProgressBar";
import {
  BACK_BTN_LEFT_OFFSET_PX,
  BACK_BTN_MARGIN_RIGHT_PX,
  BACK_BTN_WIDTH_PX,
  NextButton,
  ProgressLabelContainer,
  QuestionLabel,
  SurveyContentsWrapper,
} from "./SurveyUtilities";

export interface SurveyToggleSelectOption extends ToggleSelectOption {
  requireAdditionalAnswer?: AdditionalQuestion;
}

function getAdditionalQuestion(
  selectedOptions: SurveyToggleSelectOption[],
  email: string | undefined,
) {
  const additionalQuestion = selectedOptions.find(
    (opt) => opt.requireAdditionalAnswer,
  )?.requireAdditionalAnswer;
  if (additionalQuestion === undefined) return;
  switch (additionalQuestion.type ?? defaultAdditionalQuestionType) {
    case "CompanyName":
      return email && /@gmail\.com$/i.test(email)
        ? additionalQuestion
        : undefined;
  }
  return additionalQuestion;
}

const BackBtn = styled(Button)`
  width: ${BACK_BTN_WIDTH_PX}px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  margin-right: ${BACK_BTN_MARGIN_RIGHT_PX}px;
  margin-left: -${BACK_BTN_LEFT_OFFSET_PX}px;
  color: #a3acb7;
  font-size: 20px;
`;

const ProgressLabelWrapper = styled.span`
  display: inline-block;
  background: #0e141b;
  color: white;
  border-radius: 6px;
  padding: 5px 10px;
`;

interface ProgressLabelProps {
  current: number;
  max: number;
}

const ProgressLabel = ({ current, max }: ProgressLabelProps) => {
  return <ProgressLabelWrapper>{`${current}/${max}`}</ProgressLabelWrapper>;
};

interface QuestionProps {
  formElement: FormElement;
  formAnswers: FormAnswers;
  user: User | undefined;
  onCompleted: (
    selectedOptions: SurveyToggleSelectOption[],
    additionalAnswers: Record<string, string>,
  ) => void;
}

export const Question = ({
  formElement,
  formAnswers,
  user,
  onCompleted,
}: QuestionProps) => {
  const options = useMemo<SurveyToggleSelectOption[]>(
    () =>
      formElement.options
        .filter(
          (option) =>
            !option.condition ||
            formAnswers.answers
              .find((val) => val.questionId === option.condition?.questionId)
              ?.selectedOptions?.includes(option.condition?.answerMatch ?? ""),
        )
        .map((option) => ({
          display: option.option,
          value: option.option,
          imageUrl: option.url,
          requireAdditionalAnswer: option.requireAdditionalAnswer,
        })),
    [formAnswers, formElement],
  );
  const [initialSelectedOptions, initialAdditionalAnswer] = useMemo(() => {
    const previousAnswer = formAnswers.answers.find(
      (val) => val.questionId === formElement.questionId,
    );
    const previousSelectedOptions = new Set(previousAnswer?.selectedOptions);
    const initialSelectedOptions = options.filter((opt) =>
      previousSelectedOptions.has(opt.value),
    );
    const initialAdditionalQuestion = getAdditionalQuestion(
      initialSelectedOptions,
      user?.email,
    );
    return [
      initialSelectedOptions,
      (initialAdditionalQuestion &&
        previousAnswer?.additionalAnswers?.[initialAdditionalQuestion.name]) ??
        "",
    ] as const;
  }, [formAnswers, formElement, options, user]);
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions,
  );
  const additionalQuestion = getAdditionalQuestion(
    selectedOptions,
    user?.email,
  );
  const [additionalAnswer, setAdditionalAnswer] = useState(
    initialAdditionalAnswer,
  );

  const handleToggleSelectChange = useCallback(
    (newSelectedOptions: SurveyToggleSelectOption[]) => {
      setSelectedOptions(newSelectedOptions);
      if (
        !formElement.isMultiselect &&
        !getAdditionalQuestion(newSelectedOptions, user?.email)
      )
        setTimeout(() => onCompleted(newSelectedOptions, {}), 100);
    },
    [formElement, onCompleted, user],
  );
  const goToNext = useCallback(
    (additionalAnswer: string) => {
      const additionalAnswers: Record<string, string> = {};
      if (additionalQuestion)
        additionalAnswers[additionalQuestion.name] = additionalAnswer;
      onCompleted(selectedOptions, additionalAnswers);
    },
    [additionalQuestion, onCompleted, selectedOptions],
  );
  const handleNextClick = useCallback(() => {
    goToNext(additionalAnswer);
  }, [additionalAnswer, goToNext]);
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAdditionalAnswer(e.target.value);
    },
    [],
  );
  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleNextClick();
      }
    },
    [handleNextClick],
  );
  const handleSelectChange = useCallback(
    (value: string) => {
      setAdditionalAnswer(value);
      goToNext(value);
    },
    [goToNext],
  );
  const focusElement = useCallback((elt: InputRef | null) => {
    elt?.focus({ preventScroll: true });
  }, []);

  return (
    <div>
      <FullWidthSpace direction="vertical" size={30}>
        <QuestionLabel>{formElement.questionDisplay}</QuestionLabel>
        <ToggleSelect
          options={options}
          defaultSelectedOptions={initialSelectedOptions}
          isMultiselect={formElement.isMultiselect ?? false}
          onChange={handleToggleSelectChange}
        />
        {additionalQuestion && (
          <Space direction="horizontal" size={16}>
            <label>
              {additionalQuestion.prompt}
              {additionalQuestion.options === undefined ? (
                <RoundedInput
                  // we use this instead of autoFocus
                  // autoFocus does effectively the same thing: it focuses the element as soon as it gets mounted
                  // the only difference with our focusElement fuction is that it passes the {preventScroll: true} arg to focus()
                  // we need that because without it, we might get undesirable scrolling to elements out of view
                  ref={focusElement}
                  placeholder={additionalQuestion.placeholder}
                  value={additionalAnswer}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                />
              ) : (
                <RoundedDropDown
                  placeholder={additionalQuestion.placeholder}
                  options={additionalQuestion.options.map((opt) => ({
                    value: opt,
                    display: opt,
                  }))}
                  // if the value is the empty string, use undefined instead because it prevents the placeholder from showing
                  value={additionalAnswer || undefined}
                  onChange={handleSelectChange}
                />
              )}
            </label>
          </Space>
        )}
        {((formElement.isMultiselect && selectedOptions.length > 0) ||
          (additionalQuestion && additionalAnswer)) && (
          <NextButton onClick={handleNextClick}>Next</NextButton>
        )}
      </FullWidthSpace>
    </div>
  );
};

interface SurveyItemProps {
  currentElementIdx: number;
  maxElementIdx: number;
  hasPreviousItem: boolean;
  transitionDurationMs: number;
  children: React.ReactNode;
  onGoBack?: () => void;
}

export const SurveyItem = ({
  currentElementIdx,
  maxElementIdx,
  hasPreviousItem,
  transitionDurationMs,
  children,
  onGoBack,
}: SurveyItemProps) => {
  return (
    <SurveyContentsWrapper>
      <ProgressLabelContainer>
        {hasPreviousItem && (
          <BackBtn onClick={onGoBack}>
            <ArrowLeftOutlined />
          </BackBtn>
        )}
        <ProgressLabel current={currentElementIdx + 1} max={maxElementIdx} />
      </ProgressLabelContainer>
      <ProgressBarContainer>
        <SurveyProgressBar
          value={currentElementIdx + 1}
          max={maxElementIdx}
          transitionDurationMs={transitionDurationMs}
        />
      </ProgressBarContainer>
      {children}
    </SurveyContentsWrapper>
  );
};
