import {
  BlockPositionLocator,
  StreamLocator,
} from "store/slices/apisV2/control-flow/locators";
import {
  GenericBlock,
  BlockType,
  LoopControlBlock,
} from "store/slices/apisV2/control-flow/types";

export const isLoopControlBlock = (
  block: GenericBlock,
): block is LoopControlBlock => {
  return block?.type === BlockType.LOOP;
};

export const locationIsStreamTrigger = (location: BlockPositionLocator) => {
  return (
    (location as StreamLocator).controlType === BlockType.STREAM &&
    (location as StreamLocator).sectionName === "trigger"
  );
};

export const addBindingsToStringValue = (str: string) => {
  if (str.slice(0, 2) !== "{{") {
    str = "{{" + str;
  }
  if (str.slice(-2) !== "}}") {
    str += "}}";
  }
  return str;
};

export const removeBindingsFromStringValue = (str: string) => {
  if (str.slice(0, 2) === "{{") {
    str = str.slice(2);
  }
  if (str.slice(-2) === "}}") {
    str = str.slice(0, -2);
  }
  return str;
};

export const makeStringValidJsVarName = (str?: string): string => {
  if (!str) return "";
  return str.replace(/[^\w\s-]/g, "").replace(/[\s-]+/g, "_");
};

export const UNUSED_VARIABLE_TO_HIDE = "SB_UNUSED_VARIABLE_TO_HIDE_IN_LIST";
