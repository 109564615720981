import { Space, Modal, Radio } from "antd";
import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormText } from "../FormText";

type Props = DynamicFormItemProps;

const StyledSpace = styled(Space)`
  max-height: 80vh;
  overflow: auto;

  pre {
    font-size: 12px;
    white-space: pre-wrap;
    background: ${(props) => props.theme.colors.GREY_25};
    padding: 16px;
    border: 1px solid ${(props) => props.theme.colors.GREY_100};
    border-radius: 4px;
  }
`;

const LinkButton = styled.span`
  display: block;
  padding-top: 24px;
  margin-top: 38px;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_100};

  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  cursor: pointer;
`;

export const DynamicSqlPreview = (props: Props) => {
  const context = useContext(FormContext);
  const { getValue } = context;
  const [isOpen, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);
  const [queryType, setQueryType] = useState<"insert" | "update" | "delete">(
    "update",
  );

  const tableName = useMemo(() => {
    const schema = getValue("schema") ?? "schemaname";
    return `"${schema}"."${getValue("table") ?? "tablename"}"`;
  }, [getValue]);

  const queryTypes = useMemo(() => {
    return [
      {
        label: "Update",
        value: "update",
      },
      {
        label: "Insert",
        value: "insert",
      },
      {
        label: "Delete",
        value: "delete",
      },
    ];
  }, []);

  const onChange = useCallback(({ target: { value } }: any) => {
    setQueryType(value);
  }, []);

  const queries = useMemo(() => {
    switch (queryType) {
      case "update":
        return (
          <>
            <FormText>
              For updates, every row in your update must have a unique match in
              your database.
            </FormText>
            <pre>
              {`CREATE TEMP TABLE temptable1
( "unique_col" coltype, "update_me" coltype)
ON COMMIT DROP`}
              ;
            </pre>
            <FormText>
              Note: The database connection must have permissions to CREATE TEMP
              TABLE. Temp tables are used for performance reasons.
            </FormText>
            <pre>{`INSERT INTO temptable1 (VALUES ($1, $2), ($3, $4));
CREATE INDEX "temptable1_idx" ON temptable1 ("unique_col");
ANALYZE temptable1;`}</pre>
            <pre>{`SELECT COUNT(*) as count, COUNT(${tableName}."unique_col") as non_null FROM temptable1
LEFT JOIN ${tableName} ON ${tableName}."unique_col" = temptable1."unique_col"`}</pre>
            <FormText>
              If all previous validations pass, the final update will be
              executed:
            </FormText>
            <pre>{`UPDATE "${tableName}"
SET "update_me" = temptable1."update_me"
FROM temptable1
WHERE ${tableName}."unique_col" = temptable1."unique_col"`}</pre>
          </>
        );
      case "insert":
        return (
          <>
            <FormText>
              Rows are inserted into the database with the following query:
            </FormText>
            <pre>{`INSERT INTO ${tableName} (VALUES ($1, $2), ($3, $4));`}</pre>
          </>
        );
      case "delete":
        return (
          <>
            <FormText>
              For deletions, each deleted row must only match one row in the
              database.
            </FormText>
            <pre>{`SELECT COUNT(*) FROM ${tableName} WHERE ("unique_col" = $1) OR ("unique_col" = $2)`}</pre>
            <FormText>
              If the previous validation passes, the delete will be executed:
            </FormText>
            <pre>{`DELETE FROM ${tableName} WHERE ("unique_col" = $1) OR ("unique_col" = $2)`}</pre>
          </>
        );
    }
  }, [tableName, queryType]);

  return (
    <>
      <LinkButton role="button" onClick={toggleModal}>
        Preview SQL operation
      </LinkButton>
      <Modal
        width={"600px"}
        open={isOpen}
        destroyOnClose={true}
        onCancel={toggleModal}
        title={"Preview SQL Operation"}
        footer={null}
      >
        <StyledSpace direction="vertical">
          <FormText>
            The SQL form mode protects your data by running validation queries
            before saving. All validation is done in a transaction, which
            prevents partial updates.
          </FormText>
          <FormText>
            The order of operations is inserts, then updates, then deletes.
          </FormText>
          <FormText>
            <b>Example queries with Postgres syntax:</b>
          </FormText>
          <Radio.Group
            options={queryTypes}
            onChange={onChange}
            value={queryType}
            optionType="button"
          />
          <div>{queries}</div>
        </StyledSpace>
      </Modal>
    </>
  );
};
