import { Popover, PopoverProps, Typography } from "antd";
import { RenderFunction } from "antd/lib/tooltip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { useMarkTaskRead } from "hooks/ui/useCheckTask";
import useTimeout from "hooks/ui/useTimeout";

import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import { chooseRandom } from "legacy/utils/Utils";
import {
  selectLastCompletedTask,
  selectVisibleChecklist,
} from "store/slices/user";
import { getTooltipMessage } from "./tasks";

const TOOLTIP_DELAY = 2000;

const TooltipWrapper = styled.div`
  max-width: 388px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  border-radius: 4px;
  white-space: pre-wrap;
  * {
    color: white;
  }
`;

const HeaderWrapper = styled.div`
  max-width: 388px;
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: pre-wrap;
  gap: 8px;
  * {
    color: white;
  }
  *:last-child {
    margin-left: auto;
  }
`;

const TitleWrapper = styled(Typography.Text)`
  color: white;
`;

const CloseWrapper = styled.div`
  color: white;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.SUBTLE_BLUE};
  }
`;

const Content = (props: {
  title?: React.ReactNode | RenderFunction;
  showCloseIcon?: boolean;
  onClick?: () => void;
  onClosePressed?: () => void;
  dataTest: string;
  children?: React.ReactNode;
}) => {
  const theme = useTheme();
  const finalTitle =
    typeof props.title === "function"
      ? props.title()
      : (props.title ?? props.children);
  return (
    <TooltipWrapper onClick={props.onClick} data-test={props.dataTest}>
      <HeaderWrapper>
        <ChecklistIcons.TROPHY_ICON
          color={theme.colors.ACCENT_GREEN}
          width={16}
          height={16}
        />
        <TitleWrapper strong>{finalTitle}</TitleWrapper>
        {props.showCloseIcon && (
          <CloseWrapper
            onClick={(e) => {
              e.stopPropagation();
              props.onClosePressed?.();
            }}
          >
            <ChecklistIcons.CLOSE_ICON
              color={theme.colors.WHITE}
              width={16}
              height={16}
            />
          </CloseWrapper>
        )}
      </HeaderWrapper>
      {props.title ? props.children : null}
    </TooltipWrapper>
  );
};

interface Props {
  text?: string;
  showCloseIcon?: boolean;
  onClosePressed?: () => void;
  onClick?: () => void;
  dataTest: string;
}

const InternalTooltip = ({
  title,
  text,
  showCloseIcon,
  onClosePressed,
  onClick,
  children,
  ...props
}: Props & PopoverProps) => {
  return text ? (
    <>
      <Popover
        content={
          <Content
            title={title}
            showCloseIcon={showCloseIcon}
            onClick={onClick}
            onClosePressed={onClosePressed}
            dataTest={props.dataTest}
          >
            {text}
          </Content>
        }
        placement="bottomRight"
        color="#2f3237"
        defaultOpen={true}
        {...props}
      >
        {children}
      </Popover>
    </>
  ) : (
    <>{children}</>
  );
};

type ChecklistItemProps = {
  onClick?: () => void;
  onClosePressed?: () => void;
};

export const ChecklistItemTooltip = ({
  children,
  ...props
}: ChecklistItemProps & PopoverProps) => {
  const checklist = useSelector(selectVisibleChecklist);
  const lastTaskCompleted = useSelector(selectLastCompletedTask);
  const nextTask = checklist.find((t) => t.completed === undefined);
  const completeCount = checklist.filter((i) => i.completed).length;
  const viewedTooltipAlready = Boolean(lastTaskCompleted?.viewed);

  const emoji = useMemo(() => chooseRandom("🙌", "🚀", "🎉"), []);
  const title = `${completeCount}/${checklist.length} steps completed ${emoji}`;
  const text = getTooltipMessage(lastTaskCompleted?.id, nextTask?.id);

  const [markTaskRead] = useMarkTaskRead(lastTaskCompleted?.id);

  const [visible, setVisible] = useState(false);
  const [initialDelayComplete, setInitialDelayComplete] = useState(false);

  useEffect(() => {
    setVisible((ov) => props.visible ?? ov);
  }, [props.visible]);

  useTimeout(() => {
    setInitialDelayComplete(true);
  }, TOOLTIP_DELAY);

  const onOpenChange = useCallback(
    (newVisible: boolean) => {
      setVisible(props.visible ?? newVisible);
      if (!newVisible) {
        markTaskRead();
      }
    },
    [markTaskRead, props.visible],
  );

  const handleClosePressed = useCallback(() => {
    onOpenChange(false);
    props.onClosePressed?.();
  }, [onOpenChange, props]);

  return (
    <InternalTooltip
      {...props}
      title={title}
      text={text}
      open={
        (viewedTooltipAlready || !initialDelayComplete ? visible : true) &&
        checklist.length > 0
      }
      showCloseIcon={true}
      onClosePressed={handleClosePressed}
      mouseEnterDelay={viewedTooltipAlready ? 1.0 : 0.1}
      zIndex={999} // this goes behind modals
      onOpenChange={onOpenChange}
      dataTest={`checklist-tooltip-${lastTaskCompleted?.id}`}
    >
      <div>{children}</div>
    </InternalTooltip>
  );
};
