import { PartialMessage } from "@bufbuild/protobuf";
import {
  ConnectError,
  PromiseClient,
  createPromiseClient,
} from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { v4 as uuidv4 } from "uuid";

import { AuthSource, Metadata } from "./rpc/gen/exa/codeium_common_pb";
import { LanguageServerService } from "./rpc/gen/exa/language_server_connect";
import {
  AcceptCompletionRequest,
  GetCompletionsRequest,
  GetCompletionsResponse,
} from "./rpc/gen/exa/language_server_pb";

interface ClientSettings {
  apiKey?: string;
  defaultModel?: string;
}

async function getClientSettings(userId: string): Promise<ClientSettings> {
  return {
    apiKey: userId,
  };
}

function languageServerClient(
  baseUrl: string,
): PromiseClient<typeof LanguageServerService> {
  const transport = createConnectTransport({
    baseUrl,
    useBinaryFormat: true,
  });
  return createPromiseClient(LanguageServerService, transport);
}

class ClientSettingsPoller {
  // This is initialized to a promise at construction, then updated to a
  // non-promise later.
  clientSettings: Promise<ClientSettings> | ClientSettings;
  constructor(userId: string) {
    this.clientSettings = getClientSettings(userId);
    setInterval(async () => {
      this.clientSettings = await getClientSettings(userId);
    }, 500);
  }
}

export interface IdeInfo {
  ideName: string;
  ideVersion: string;
}

class LanguageServerServiceWorkerClient {
  // Note that the URL won't refresh post-initialization.
  client: Promise<PromiseClient<typeof LanguageServerService> | undefined>;
  private abortController?: AbortController;
  clientSettingsPoller: ClientSettingsPoller;

  constructor(
    baseUrl: string,
    userId: string,
    private readonly sessionId: string,
  ) {
    this.client = (async (): Promise<
      PromiseClient<typeof LanguageServerService> | undefined
    > => {
      return languageServerClient(baseUrl);
    })();
    this.clientSettingsPoller = new ClientSettingsPoller(userId);
  }

  getHeaders(apiKey: string | undefined): Record<string, string> {
    if (apiKey === undefined) {
      return {};
    }
    const Authorization = `Basic ${apiKey}-${this.sessionId}`;
    return { Authorization };
  }

  async getCompletions(
    request: GetCompletionsRequest,
  ): Promise<GetCompletionsResponse | undefined> {
    this.abortController?.abort();
    this.abortController = new AbortController();
    const clientSettings = await this.clientSettingsPoller.clientSettings;
    if (clientSettings.apiKey === undefined || request.metadata === undefined) {
      return;
    }
    request.metadata.apiKey = clientSettings.apiKey;
    request.modelName = clientSettings.defaultModel ?? "";
    const signal = this.abortController.signal;
    const getCompletionsPromise = (await this.client)?.getCompletions(request, {
      signal,
      headers: this.getHeaders(request.metadata?.apiKey),
    });
    try {
      return await getCompletionsPromise;
    } catch (err) {
      if (signal.aborted) {
        return;
      }
      if (err instanceof ConnectError) {
        // TODO: Handle error
      } else {
        console.log((err as Error).message);
        // TODO: Handle error
      }
      return;
    }
  }

  async acceptedLastCompletion(
    acceptCompletionRequest: PartialMessage<AcceptCompletionRequest>,
  ): Promise<void> {
    if (acceptCompletionRequest.metadata === undefined) {
      return;
    }
    try {
      const clientSettings = await this.clientSettingsPoller.clientSettings;
      acceptCompletionRequest.metadata.apiKey = clientSettings.apiKey;
      await (
        await this.client
      )?.acceptCompletion(acceptCompletionRequest, {
        headers: this.getHeaders(acceptCompletionRequest.metadata?.apiKey),
      });
    } catch (err) {
      console.log((err as Error).message);
    }
  }
}

export class LanguageServerClient {
  private sessionId = uuidv4();
  private requestId = 0;
  private client: LanguageServerServiceWorkerClient;

  constructor(userId: string) {
    const url = `https://web-backend.codeium.com`;
    this.client = new LanguageServerServiceWorkerClient(
      url,
      userId,
      this.sessionId,
    );
  }

  getMetadata(ideInfo: IdeInfo): Metadata {
    return new Metadata({
      ideName: ideInfo.ideName,
      ideVersion: ideInfo.ideVersion,
      locale: navigator.language,
      extensionName: "Superblocks",
      extensionVersion: "1.12.7",
      sessionId: this.sessionId,
      requestId: BigInt(++this.requestId),
      userAgent: navigator.userAgent,
      url: window.location.href,
      authSource: AuthSource.SUPERBLOCKS,
    });
  }

  async getCompletions(
    request: GetCompletionsRequest,
  ): Promise<GetCompletionsResponse | undefined> {
    return this.client.getCompletions(request);
  }

  acceptedLastCompletion(ideInfo: IdeInfo, completionId: string): void {
    // this.client.acceptedLastCompletion({});
  }
}
