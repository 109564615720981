import Uppy, { UppyOptions } from "@uppy/core";
import { produce } from "immer";
import { isEmbeddedBySuperblocksFirstParty, sendMessage } from "utils/iframe";

export class SBUppy extends Uppy {
  private options;

  constructor(options: UppyOptions) {
    super(options);
    this.options = options;
  }

  setOptions(newOptions: Partial<UppyOptions>) {
    this.options = { ...newOptions, ...this.options };
    super.setOptions(newOptions);
  }

  getOptions(): UppyOptions {
    return this.options;
  }
}

let FilePickerState = produce({}, (s) => s) as Record<
  string,
  Record<string, File>
>;

const uppyInstances: Record<string, SBUppy> = {};

function create(widgetId: string, options: UppyOptions): SBUppy {
  const instance = new SBUppy(options);
  uppyInstances[widgetId] = instance;
  return instance;
}

export function resetFilePickers() {
  FilePickerState = produce({}, (s) => s);
  Object.entries(uppyInstances).forEach(([key, instance]) => {
    instance.cancelAll();
    delete uppyInstances[key];
  });
}

/*
This is a bugfix for an issue in Chrome. When you postMessage a File object,
and the file is used in a CORS request, Chrome would send an OPTIONS request but
immediately cancel the POST. The difference is that Chrome files are either "data elements"
or "chunked", which is apparently controlled by the file equality.

The workaround is to shallow-clone the File object before posting it, which causes
Chrome to treat the file as a "Data Element" instead of "Chunked".

This can be tested using superblockshq.com because it has CORS agents.
*/
function sendStateSafely() {
  const clone = Object.fromEntries(
    Object.entries(FilePickerState).map(([name, value]) => [
      name,
      Object.fromEntries(
        Object.entries(value).map(([fileid, file]) => {
          // Slice creates a File wrapper without reading into memory
          return [
            fileid,
            new File([file.slice()], file.name, { type: file.type }),
          ];
        }),
      ),
    ]),
  );

  sendMessage({
    type: "filepicker-singleton",
    payload: clone,
  });
}

function update(widgetName: string, filePath: string, file: File) {
  if (!FilePickerState[widgetName]) {
    FilePickerState = produce(FilePickerState, (draft) => {
      draft[widgetName] = { [filePath]: file };
    });
  } else {
    FilePickerState = produce(FilePickerState, (draft) => {
      draft[widgetName][filePath] = file;
    });
  }

  if (isEmbeddedBySuperblocksFirstParty()) {
    sendStateSafely();
  }
}

function deleteFiles(widgetName: string) {
  if (FilePickerState[widgetName]) {
    FilePickerState = produce(FilePickerState, (draft) => {
      delete draft[widgetName];
    });
    if (isEmbeddedBySuperblocksFirstParty()) {
      sendStateSafely();
    }
  }
}

function deleteFile(widgetName: string, filePath = "") {
  if (FilePickerState[widgetName]) {
    FilePickerState = produce(FilePickerState, (draft) => {
      delete draft[widgetName][filePath];
    });
    if (isEmbeddedBySuperblocksFirstParty()) {
      sendStateSafely();
    }
  }
}

export const FileManager = {
  deleteFile,
  deleteFiles,
  get: (widgetName: string, filePath: string) =>
    FilePickerState?.[widgetName]?.[filePath],
  update,
};

export const UppyManager = {
  create,
  get: (widgetId: string) => uppyInstances[widgetId],
};

export function setFileState(fileState: typeof FilePickerState) {
  FilePickerState = fileState;
}
