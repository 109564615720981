import { Typography, Button } from "antd";
import styled from "styled-components";
import { colors } from "styles/colors";

export const FormWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: "1fr";
  grid-template-rows: repeat(auto-fill, max-content);
  grid-column-gap: 28px;
  grid-row-gap: 12px;

  .control-form-label {
    font-size: 13px;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 8px;
    margin-bottom: 4px;

    &.with-control {
      padding-top: 0;
      align-items: center;
    }
  }

  .control-form-item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    justify-content: start;
    gap: 2px;

    > * {
      width: 100%;
      min-width: 260px;
    }
  }
`;

export const CenteredSingleFormItem = styled.div`
  grid-column: 1 / span 2;
  max-width: 600px;
`;

export const FormLabel = styled(Typography.Text)`
  width: max-content;
  color: ${({ theme }) => theme.colors.GREY_700};
  .with-help {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    text-decoration-color: transparent;
    &:hover {
      text-decoration-color: ${({ theme }) => theme.colors.GREY_700};
    }
  }
`;

export const ButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_700};
  border-color: ${(props) => props.theme.colors.GREY_100};
  gap: 6px;
`;

export const ButtonWithLightIcon = styled(ButtonWithIcon)`
  color: ${(props) => props.theme.colors.GREY_300};
  border-color: ${(props) => props.theme.colors.GREY_100};
`;

export const VarCodeText = styled(Typography.Text)`
  code {
    padding: 0px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: ${({ theme }) => theme.colors.GREY_500};
    border-radius: 2px;
    border: 1px solid ${colors.GREY_100};
    background-color: ${colors.GREY_25};
  }
`;

export const ErrorText = styled.p`
  position: absolute;
  margin: 0;
  padding: 0;
  left: 4px;
  top: 100%;
  font-size: 12.5px;
  color: ${({ theme }) => theme.colors.RED_500};
`;
