import { HttpMethod, VcsProvider } from "@superblocksteam/shared";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { callServer } from "../../store/utils/client";
import BranchAndDirectoryWrapper from "./BranchAndDirectoryWrapper";
import { GitOnboardingContext } from "./GitOnboardingContext";
import { Repository } from "./utils";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 20px;
  button > span {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 16px;
  }
`;

export const BackButton = SecondaryButton;

export const NextButton = PrimaryButton;

interface SelectConfigFileProps {
  repo: Repository | null;
  handleBack: () => void;
  handleNext: () => void;
  setDirectory: (value: any) => void;
  directory: string;
  selectedBranch: string;
  provider: VcsProvider;
}

// TODO(aayush): Add link to docs page below.
const SelectConfigFile = ({
  repo,
  handleBack,
  handleNext,
  directory,
  setDirectory,
  selectedBranch,
  provider,
}: SelectConfigFileProps) => {
  const [foundConfig, setFoundConfig] = useState(false);
  const { setAppFlobsInConfig } = useContext(GitOnboardingContext);

  useEffect(() => {
    const fetchAppFlobs = async () => {
      try {
        if (repo) {
          const response = await callServer<any>({
            method: HttpMethod.POST,
            url: "v1/git-sync/config/appflobs",
            body: {
              repo: repo.name,
              owner: repo.owner?.name,
              path: directory,
              branch: selectedBranch,
              installationId: repo.installationId,
              provider,
            },
          });
          setAppFlobsInConfig?.(response.appflobs);
        }
      } catch (e) {
        console.error(`Error fetching appflobs: ${e}`);
      }
    };
    fetchAppFlobs();
  }, [directory, setAppFlobsInConfig, selectedBranch, provider, repo]);
  return (
    <div style={{ paddingTop: "16px" }}>
      <BranchAndDirectoryWrapper
        repo={repo}
        showAlert={true}
        foundConfig={foundConfig}
        setFoundConfig={setFoundConfig}
        setDirectory={setDirectory}
        directory={directory}
        selectedBranch={selectedBranch}
      />
      <ButtonContainer>
        <BackButton onClick={handleBack}>← Back</BackButton>
        <NextButton onClick={handleNext}>Next →</NextButton>
      </ButtonContainer>
    </div>
  );
};

export default SelectConfigFile;
