export type DataType =
  | "OBJECT"
  | "NUMBER"
  | "ARRAY"
  | "FUNCTION"
  | "BOOLEAN"
  | "STRING"
  | "UNKNOWN";

export function getDataType(type: string): DataType {
  if (type === "?") return "UNKNOWN";
  else if (type === "number") return "NUMBER";
  else if (type === "string") return "STRING";
  else if (type === "bool") return "BOOLEAN";
  else if (/^fn\(/.test(type)) return "FUNCTION";
  else if (/^\[/.test(type)) return "ARRAY";
  else return "OBJECT";
}
