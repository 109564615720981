import { SurveyToShow } from "@superblocksteam/shared";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import envs from "env";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { isDev } from "utils/env";
import logger from "utils/logger";
import { Survey } from "./Survey";
import { FormElement } from "./SurveyForm";

const SurveyWrapper = ({ children }: { children: React.ReactNode }) => {
  const user = useSelector(getCurrentUser);
  const surveyToShow =
    (isDev() &&
      envs.get("SUPERBLOCKS_UI_ONBOARDING_SURVEY_SKIP_ON_DEV") === "true") ||
    user?.surveyToShow === undefined
      ? SurveyToShow.NO_SURVEY
      : user.surveyToShow;

  const [loadingFormDef, setLoadingFormDef] = useState(true);
  const [formDefinition, setFormDefinition] = useState<FormElement[]>([]);
  useEffect(() => {
    (async () => {
      try {
        switch (surveyToShow) {
          case SurveyToShow.SURVEY_FOR_NEW_USER_NEW_ORG: {
            const ret: any = await fetch(
              envs.get("SUPERBLOCKS_UI_ONBOARDING_SURVEY_URL"),
            );
            const formData = await ret.json();
            setFormDefinition(formData);
            break;
          }
          case SurveyToShow.SURVEY_FOR_NEW_USER_EXISTING_ORG: {
            const ret: any = await fetch(
              envs.get("SUPERBLOCKS_UI_ONBOARDING_SURVEY_URL_EXISTING_ORG"),
            );
            const formData = await ret.json();
            setFormDefinition(formData);
            break;
          }
        }
      } catch (e) {
        logger.error(e);
      } finally {
        setLoadingFormDef(false);
      }
    })();
  }, [surveyToShow]);

  if (
    surveyToShow === SurveyToShow.NO_SURVEY ||
    // if form def is not loaded correctly, skip survey
    (!loadingFormDef && formDefinition.length === 0)
  ) {
    return <>{children}</>;
  }

  return loadingFormDef ? (
    <FullPageSpinner />
  ) : (
    <Survey
      user={user}
      surveyShown={surveyToShow}
      formDefinition={formDefinition}
    />
  );
};

export default SurveyWrapper;
