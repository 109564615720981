import { getDottedPathTo } from "utils/dottedPaths";
import type { ExpandedAutocompleteDefinition } from "./types";

const isValidRankType = (rank: unknown): rank is string | number => {
  return typeof rank === "string" || typeof rank === "number";
};

function sortComparator(
  a: undefined | ExpandedAutocompleteDefinition,
  b: undefined | ExpandedAutocompleteDefinition,
) {
  const aRanks = a?.sortRanks ?? [];
  const bRanks = b?.sortRanks ?? [];

  for (let i = 0; i < aRanks.length && i < bRanks.length; i++) {
    const aRank = aRanks[i];
    const bRank = bRanks[i];
    if (aRank === bRank) {
      continue;
    }
    if (!isValidRankType(aRank)) {
      return 1;
    }
    if (!isValidRankType(bRank)) {
      return -1;
    }
    if (typeof aRank === "number" && typeof bRank === "number") {
      return aRank - bRank;
    }
    if (typeof aRank === "string" && typeof bRank === "string") {
      return aRank.toLowerCase().localeCompare(bRank.toLowerCase());
    }
    // at this point, one of the ranks is a number, the other must be a string
    // tie break in favor of numeric rank
    return typeof aRank === "number" ? -1 : 1;
  }
  return 0;
}

export function sortAndFilterExpandedCompletions<CompletionT>(
  completions: Array<CompletionT>,
  extractExpanded: (
    completion: CompletionT,
  ) => undefined | ExpandedAutocompleteDefinition,
): Array<CompletionT> {
  const processed = completions
    .filter((a) => {
      const expanded = extractExpanded(a);
      return !expanded?.hideSuggestion;
    })
    .sort((a, b) => {
      return sortComparator(extractExpanded(a), extractExpanded(b));
    });
  return processed;
}

export function compileKeyPathStr(keyPath: string[], fnSig?: string) {
  fnSig = fnSig ?? "";
  const basePath = keyPath
    .slice(0, -1)
    .map((p, i) => (i === 0 ? p : getDottedPathTo(p)))
    .join("");

  const finalPath = keyPath[keyPath.length - 1];

  // TODO: Remove this check once we have a better way to handle functions
  if (finalPath.endsWith("()")) {
    return `${basePath}${getDottedPathTo(finalPath.slice(0, -2))}${fnSig}`;
  }
  return `${basePath}${getDottedPathTo(finalPath)}${fnSig}`;
}

export function renderAutocompleteRow(
  definition: ExpandedAutocompleteDefinition,
  value: string,
  node: HTMLElement,
) {
  const row = document.createElement("div");
  row.classList.add("expanded-autocomplete-row");

  if (definition.icon) {
    const image = document.createElement("img");
    image.setAttribute("src", definition.icon);
    image.classList.add("expanded-autocomplete-icon");
    row.appendChild(image);
  }

  const text = document.createElement("div");
  text.classList.add("expanded-autocomplete-text");
  text.innerText = value;
  row.appendChild(text);

  node.appendChild(row);
}
