import { normalizeRoleName, RoleDto } from "@superblocksteam/shared";
import { Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { useUpdateRolesMutation } from "store/slices/reduxApi/rbac";

type ErrorType = {
  name: string | null;
  description: string | null;
  key: string | null;
  modal: string | null;
};

const NO_ERRORS: ErrorType = {
  name: null,
  description: null,
  key: null,
  modal: null,
};

export const UpdateRoleModal = ({
  onClose,
  role,
}: {
  onClose: () => void;
  role: RoleDto;
}) => {
  const organizationId = useSelector(getCurrentOrgId);

  const [name, setName] = useState(role.name);
  const [description, setDescription] = useState(role.description);

  const [errors, setErrors] = useState<ErrorType>(NO_ERRORS);

  const [updateRoles, { isLoading: isSaving }] = useUpdateRolesMutation();
  const handleUpdateRole = useCallback(async () => {
    if (!name) {
      setErrors({
        ...NO_ERRORS,
        name: "Name is required",
      });
      return;
    }
    try {
      await updateRoles({
        roles: [
          {
            ...role,
            name,
            description,
          },
        ],
        organizationId,
      }).unwrap();
      onClose();
    } catch (error: any) {
      setErrors({
        ...NO_ERRORS,
        modal: error?.message ?? error?.error ?? "Failed to update role",
      });
    }
  }, [name, updateRoles, role, description, organizationId, onClose]);

  return (
    <Modal
      title={`Update ${role.name} role`}
      open={true}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper}>
        <div className={FormWrapper}>
          <FormItem label="Name" required={true} error={errors.name}>
            <Input
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormItem>
          <FormItem label="Key" error={errors.key}>
            <Input
              placeholder="Enter key"
              value={normalizeRoleName(name)}
              disabled={true}
            />
          </FormItem>
          <FormItem label="Description" error={errors.description}>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </FormItem>
          <FormItem error={errors.modal} hidden={!errors.modal} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            data-test="cancel-update-role-button"
            onClick={onClose}
            loading={isSaving}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="primary"
            onClick={handleUpdateRole}
            data-test="update-role-button"
            loading={isSaving}
            disabled={!name}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
