import { getDynamicBindings } from "@superblocksteam/shared";

export const pluralize = (amount: number, noun: string): string => {
  return noun + (amount > 1 ? "s" : "");
};

export const prettyCount = (amount: number, noun: string): string => {
  return `${amount} ${pluralize(amount, noun)}`;
};

export const truncate = (str: string, limit = 40, inMiddle = false): string => {
  const prefixLength = inMiddle ? limit >> 1 : limit;
  const postfixLength = limit - prefixLength;

  return str.length > limit
    ? `${str.substring(0, prefixLength)}...${str.substring(
        str.length - postfixLength,
      )}`
    : str;
};

/**
 * Takes a string with dynamic bindings in braces and returns a JS expression that evaluates to the string with its bindings evaluated
 */
export const stringToJS = (string: string, skipUnescape = true): string => {
  // If string is empty, the code below will return an empty string, which is not a valid JS expression.
  // So handle this case separately and return an empty string literal.
  if (string === undefined || string === "") return "''";

  const { stringSegments, jsSnippets } = getDynamicBindings(string);
  const shouldWrapInParentheses = jsSnippets.length > 1;
  const js = stringSegments
    .map((segment, index) => {
      if (jsSnippets[index] && jsSnippets[index].length > 0) {
        return shouldWrapInParentheses
          ? `(${jsSnippets[index]})`
          : jsSnippets[index];
      } else {
        if (skipUnescape) {
          const escaped = segment
            .replaceAll("\\", "\\\\")
            .replaceAll(`\n`, `\\n`);

          if (segment.includes("'")) {
            return `\"${escaped.replaceAll(`"`, '\\\\"')}\"`;
          } else {
            return `'${escaped}'`;
          }
        } else {
          // TODO. To be removed: EG-17106
          // Remove this code branch
          if (segment.includes("'")) {
            return `\"${segment.replaceAll(`"`, '\\\\"')}\"`;
          } else {
            return `'${segment}'`;
          }
        }
      }
    })
    .join(" + ");
  return js;
};

export const JSToString = (js: string): string => {
  const segments = mergeNonJSStrings(getSegmentsFromJS(js));
  return segments
    .map((segment) => {
      const isStringSegment =
        segment.charAt(0) === "'" && segment.charAt(segment.length - 1) === "'";
      if (isStringSegment) {
        return segment.substring(1, segment.length - 1);
      } else return "{{" + segment + "}}";
    })
    .join("");
};

function getSegmentsFromJS(js: string) {
  const segments: string[] = [];

  let rest = js;
  while (rest.length > 0) {
    const nextApostrophe = rest.indexOf("'");
    const endApostrophe =
      nextApostrophe >= 0 ? rest.indexOf("'", nextApostrophe + 1) : -1;
    const nextPlus = rest.indexOf(" + ");

    const captureString = nextApostrophe === 0 && endApostrophe > 0;
    const captureJS = nextPlus !== -1 && !captureString;

    if (captureString) {
      segments.push(rest.substring(nextApostrophe, endApostrophe + 1));
      rest = rest.substring(endApostrophe + 1);
    } else if (captureJS) {
      segments.push(rest.substring(0, nextPlus));
      rest = rest.substring(nextPlus + 3);
    } else {
      segments.push(rest);
      rest = "";
    }
  }

  return segments.filter((s) => s.trim().length > 0);
}

/**
 * @param {string[]} segments - string[] - An array of strings that are either JS or strings.
 * @returns {string[]} - An array of strings where neighboring JS expressions are merged.
 */
const mergeNonJSStrings = (segments: string[]): string[] => {
  let currentJS: string[] = [];
  const pushMergedJS = () => {
    if (currentJS.length > 0) {
      newSegments.push(currentJS.join(" + "));
      currentJS = [];
    }
  };

  const newSegments: string[] = [];
  segments.forEach((segment) => {
    const isStringSegment =
      segment.charAt(0) === "'" && segment.charAt(segment.length - 1) === "'";
    if (isStringSegment) {
      pushMergedJS();
      newSegments.push(segment);
    } else {
      currentJS.push(segment);
    }
  });

  pushMergedJS();
  return newSegments;
};

/**
 * Encodes a JS expression into a string with bindings that can be used as a property value and evaluates to the JS expression.
 * @param jsExpr The JS expression to encode
 * @returns A string with bindings that can be used as a property value and evaluates to the JS expression
 */
// FIXME: if `jsExpr` contains a `}}` then this will create a binding string that will not work
export function encodeJsExpr(jsExpr: string) {
  if (!/\S/.test(jsExpr ?? " ")) {
    // if `jsExpr` is `undefined`, empty, or only contains whitespace, return undefined
    return undefined;
  }
  return "{{" + jsExpr + "}}";
}

/**
 * Performs the inverse of `encodeJsExpr`.
 * @param propValue A string with bindings that was produced by `encodeJsExpr`
 * @returns The original JS expression
 */
export function decodeJsExpr(propValue: string | undefined) {
  return !propValue ? "" : propValue.replace(/^{{(.*)}}$/s, "$1");
}

export const capitalize = (s: string) => {
  if (!s) return s;
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};
