import { MinusCircleOutlined } from "@ant-design/icons";
import { KVPair } from "@superblocksteam/shared";
import { Input, Select } from "antd";
import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import styled, { useTheme } from "styled-components";
import CodeEditor from "components/app/CodeEditor";
import {
  AutocompleteConfiguration,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { Button } from "components/ui/Button";

const { Option } = Select;

const fileContentsPlaceholder = [
  "Reference the contents of a file uploaded in the file picker, or specify the contents directly:",
  "1. {{FilePicker1.files[0].readContents()}}",
  "2. sample file contents here",
].join("\n");

const StyledSelect = styled(Select)`
  &&& .ant-select-selector {
    height: 29px; // match the height of the other fields: 29 = 27 (the height of other fields) + 2 (for the borders)
  }
  &&& .ant-select-selection-item {
    display: inline-flex;
    align-items: center; // fix vertical alignment of text
  }
`;

interface DynamicFormFieldItemFormProps {
  autocompleteConfiguration: AutocompleteConfiguration;
  autocompleteAdditionalData?: Record<string, Record<string, unknown>>;
  idx: number;
  kvPair: KVPair;
  path: string;
  readOnly?: boolean;
  onRemove: (idx: number) => void;
  onChange: (newKvPair: KVPair, idx: number) => void;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const FilenameEditorWrapper = styled.div`
  flex-basis: 170px;
`;

const ValueEditorWrapper = styled.div`
  flex-grow: 1;
`;

enum FieldType {
  Text = "Text",
  FileContents = "FileContents",
}

const FieldTypeLabel: { [fieldType in FieldType]: string } = {
  [FieldType.Text]: "Text",
  [FieldType.FileContents]: "File",
};

export const DynamicFormFieldItemForm = React.memo(
  ({
    autocompleteConfiguration,
    autocompleteAdditionalData,
    idx,
    kvPair,
    path,
    readOnly,
    onChange,
    onRemove,
  }: DynamicFormFieldItemFormProps) => {
    const theme = useTheme();

    const initialFieldType: FieldType =
      kvPair.file === undefined ? FieldType.Text : FieldType.FileContents;
    const [fieldType, setFieldType] = useState<FieldType>(initialFieldType);
    const [filename, setFilename] = useState(kvPair.file?.filename ?? "");

    const inputFilename = useMemo<Omit<Partial<any>, "checked">>(
      () => ({
        value: kvPair.file?.filename ?? "",
        onChange: (val: unknown) => {
          setFilename(val as string);
          if (fieldType === FieldType.FileContents) {
            onChange({ ...kvPair, file: { filename: val as string } }, idx);
          }
        },
      }),
      [fieldType, idx, kvPair, onChange],
    );

    const inputValue = useMemo<Omit<Partial<any>, "checked">>(
      () => ({
        value: kvPair.value,
        onChange: (val: unknown) => {
          onChange({ ...kvPair, value: val as string }, idx);
        },
      }),
      [idx, kvPair, onChange],
    );

    const handleKeyChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        onChange({ ...kvPair, key: e.target.value }, idx);
      },
      [idx, kvPair, onChange],
    );

    const handleTypeChange = useCallback(
      (newFieldType: FieldType) => {
        setFieldType(newFieldType);
        if (newFieldType === FieldType.FileContents) {
          onChange({ ...kvPair, file: { filename } }, idx);
        } else {
          const newKvPair = { ...kvPair };
          delete newKvPair.file;
          onChange(newKvPair, idx);
        }
      },
      [filename, idx, kvPair, onChange],
    );

    const handleRemove = useCallback(() => {
      onRemove(idx);
    }, [idx, onRemove]);

    // editable makes the field non-editable by its very existence
    const isDisabled =
      kvPair.editable !== undefined || readOnly || kvPair.system;

    return (
      <Wrapper>
        <StyledSelect
          key="field-type-select"
          defaultValue={initialFieldType}
          style={{ width: 96 }}
          onChange={handleTypeChange as any}
          disabled={isDisabled}
        >
          {Object.values(FieldType).map((type) => (
            <Option key={type} value={type}>
              {FieldTypeLabel[type]}
            </Option>
          ))}
        </StyledSelect>
        <Input
          key="key"
          id={`${path}_key_${idx}`}
          placeholder={"Key"}
          value={kvPair.key}
          onChange={handleKeyChange}
          disabled={isDisabled}
          style={{
            fontSize: theme.text.sizes.default,
            maxWidth: 120,
          }}
        />
        {fieldType === FieldType.FileContents && (
          <FilenameEditorWrapper key="filename">
            {isDisabled ? (
              <Input
                id={`${path}_filename_${idx}`}
                value={filename}
                disabled={true}
                style={{ fontSize: theme.text.sizes.default }}
              />
            ) : (
              <CodeEditor
                autocompleteConfiguration={autocompleteConfiguration}
                additionalDynamicData={autocompleteAdditionalData}
                showLineNumbers={false}
                showShortcutMenu={false}
                mode={"text-js" as any}
                tabBehaviour={TabBehaviour.INPUT}
                enableSearch={false}
                theme={EditorTheme.LIGHT as any}
                size={EditorSize.EXTENDED}
                minHeight="30px" // awkward size to match antd's input
                monospace={true}
                input={inputFilename}
                placeholder={"Filename"}
                disabled={isDisabled}
              />
            )}
          </FilenameEditorWrapper>
        )}
        <ValueEditorWrapper key="value">
          {isDisabled ? (
            <Input
              id={`${path}_value_${idx}`}
              placeholder={"Value"}
              value={kvPair.value}
              disabled={true}
              style={{ fontSize: theme.text.sizes.default }}
            />
          ) : (
            <CodeEditor
              // force the editor object to be recreated when the type changes
              key={`value-${fieldType}`}
              autocompleteConfiguration={autocompleteConfiguration}
              additionalDynamicData={autocompleteAdditionalData}
              data-test={`dynamic-text-${path}_value_${idx}`}
              showLineNumbers={false}
              showShortcutMenu={false}
              mode={"text-js" as any}
              tabBehaviour={TabBehaviour.INPUT}
              enableSearch={false}
              theme={EditorTheme.LIGHT}
              size={EditorSize.EXTENDED}
              minHeight={fieldType === FieldType.Text ? "30px" : "84px"} // 1 or 4 lines of text
              maxHeight="84px" // 4 lines of text
              monospace={true}
              input={inputValue}
              placeholder={
                fieldType === FieldType.Text ? "Value" : fileContentsPlaceholder
              }
              disabled={isDisabled}
            />
          )}
        </ValueEditorWrapper>
        <Button
          data-test={`form_delete_${idx}_button`}
          icon={<MinusCircleOutlined />}
          aria-label="Delete"
          type="text"
          onClick={handleRemove}
          disabled={isDisabled}
        />
      </Wrapper>
    );
  },
);

DynamicFormFieldItemForm.displayName = "DynamicFormFieldItemForm";
