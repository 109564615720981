import { Graph } from "@dagrejs/graphlib";
import { DataTreeEntity } from "legacy/entities/DataTree/dataTreeFactory";

// Reference edge direction points from the entity that is referencing to the entity that is being referenced.
export enum ReferenceEdgeType {
  PARENT = "PARENT", // e.g. if Text1 is rendered underneath Canvas1
  BINDING = "BINDING", // e.g. if Text1 depends on API1 for its value
  EVENT = "EVENT", // e.g. if Button1 references Table1 in an event handler
}

// i.e. if edge A->B exists, then B is used in the app because of A's existence.
// example: Section -> Canvas, the canvas is used because of the section - if the section were not used, the canvas would not be either
// example: Text1 -> API1, the API is used because Text1 depends on it for some part of its state.

export enum UsageEdgeType {
  DEPENDENCY = "DEPENDENCY", // e.g. If Text1 depends on API1 for its value. This is generally the same as Binding
  PARENT = "PARENT", // e.g. if Text1 is rendered underneath Canvas1
  ACTIVATION = "ACTIVATION", // e.g. if Button1 is responsible for opening Modal1, or Button1 is responsible for starting Timer1
}

export type UsageNodeState = {
  isUsed: boolean;
  canPrune?: boolean;
  entity?: DataTreeEntity;
  usageExplanation?: string; // currently only populated for globally used nodes
};

export type TypedGraph<VertexLabel, EdgeType, EdgeLabel> = Omit<
  Graph,
  "node" | "setNode" | "edge" | "setEdge" | "outEdges" | "inEdges"
> & {
  node(nodeId: string): VertexLabel | undefined;
  setNode(
    nodeId: string,
    value: VertexLabel,
  ): TypedGraph<VertexLabel, EdgeType, EdgeLabel>;

  edge(
    source: string,
    target: string,
    edgeType?: EdgeType,
  ): EdgeLabel | undefined;

  setEdge(
    source: string,
    target: string,
    value: EdgeLabel,
    edgeType: EdgeType,
  ): TypedGraph<VertexLabel, EdgeType, EdgeLabel>;

  outEdges(
    v: string,
    w?: string,
  ): void | Array<{ v: string; w: string; name?: EdgeType }>;

  inEdges(
    v: string,
    w?: string,
  ): void | Array<{ v: string; w: string; name?: EdgeType }>;
};

export type UsageGraph = TypedGraph<UsageNodeState, UsageEdgeType, undefined>;

export type ReferenceGraph = TypedGraph<
  DataTreeEntity,
  ReferenceEdgeType,
  string | undefined // path info
>;
