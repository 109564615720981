import { chunk } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { forwardRef, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { useDebounce, useElementRect } from "hooks/ui";
import { MAX_CARDS_PER_ROW } from "./EntityCard";

const Row = styled.div<{
  $numCols: number;
  $minItemWidth: number;
  $colGap: number;
  $rowGap: number;
}>`
  display: grid;
  ${({ $numCols, $minItemWidth }) =>
    ` grid-template-columns: repeat(${$numCols}, minmax(${$minItemWidth}px, 1fr));`}
  column-gap: ${({ $colGap }) => `${$colGap}px`};
  margin-bottom: ${({ $rowGap }) => `${$rowGap}px`};
`;

interface ResponsiveGridProps<T> {
  rowHeight: number;
  rowGap: number;
  columnGap?: number;
  ItemRenderer: ({ item }: { item: T }) => React.ReactElement;
  items: Array<T>;
  minItemWidth: number;
}

const OuterElement = forwardRef<any, any>(
  ({ onScroll, children }, forwardedRef) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollableElement = useRef<HTMLElement>(
      document.querySelector(`[data-superblocks='scrollable-container']`),
    );
    const onScrollChange = useCallback(() => {
      if (scrollableElement.current && typeof onScroll === "function") {
        const { clientHeight, scrollTop, scrollHeight } =
          scrollableElement.current;
        onScroll({
          currentTarget: {
            clientHeight,
            scrollTop:
              scrollTop -
              (containerRef.current
                ? containerRef.current.getBoundingClientRect().top + scrollTop
                : 0),
            scrollHeight,
          },
        });
      }
    }, [onScroll]);

    const debouncedScrollChange = useDebounce(onScrollChange, 10);

    useEffect(() => {
      const mainEl = scrollableElement.current;
      if (mainEl && debouncedScrollChange) {
        mainEl?.addEventListener("scroll", debouncedScrollChange);

        return () => {
          mainEl?.removeEventListener("scroll", debouncedScrollChange);
        };
      }
    }, [debouncedScrollChange]);

    return (
      <div ref={containerRef} style={{ position: "relative" }}>
        {children}
      </div>
    );
  },
);

OuterElement.displayName = "OuterElement";
const ResponsiveGrid = <T,>(props: ResponsiveGridProps<T>) => {
  const { items, rowHeight, ItemRenderer, rowGap, columnGap, minItemWidth } =
    props;

  const contents = useRef<HTMLDivElement>(null);
  const rect = useElementRect(contents, 10);
  const numItemsInRow = useMemo(() => {
    const width = rect?.width ?? 800;
    const numItemsPerRow = Math.floor(
      (width + rowGap) / (minItemWidth + rowGap),
    );
    return Math.min(numItemsPerRow, MAX_CARDS_PER_ROW);
  }, [rect?.width, minItemWidth, rowGap]);

  // memoize creating rows based off of the items + numItemsInRow
  const groupedItems = useMemo(() => {
    return chunk(items, numItemsInRow);
  }, [items, numItemsInRow]);

  const RowRenderer = useCallback(
    ({ index, style }: any) => {
      return (
        <Row
          key={index}
          style={style}
          $colGap={columnGap ?? 0}
          $numCols={numItemsInRow}
          $minItemWidth={minItemWidth}
          $rowGap={rowGap ?? 0}
        >
          {groupedItems[index].map((item, subIndex) => (
            <ItemRenderer item={item} key={subIndex} />
          ))}
        </Row>
      );
    },
    [
      ItemRenderer,
      columnGap,
      numItemsInRow,
      minItemWidth,
      rowGap,
      groupedItems,
    ],
  );

  return (
    <div ref={contents}>
      <List
        width={rect?.width ?? "100%"}
        itemCount={groupedItems.length}
        itemSize={rowHeight + (rowGap ?? 0)}
        height={window.innerHeight}
        outerElementType={OuterElement}
        className="LIST"
        overscanCount={5}
      >
        {RowRenderer}
      </List>
    </div>
  );
};

export default ResponsiveGrid;
