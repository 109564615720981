import { Typography } from "antd";
import React from "react";

import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import Helm from "./Helm";

const EKS = ({
  agentHost,
  agentUrl,
  agentEnvironment,
  agentDataDomain,
  showInstruction,
}: AgentDeploymentInstructionsProps) => {
  return (
    <Typography.Paragraph>
      <Typography.Text strong>
        Create an EKS cluster with the{" "}
        <Typography.Link
          href={
            "https://docs.aws.amazon.com/eks/latest/userguide/getting-started-eksctl.html"
          }
          target="_blank"
        >
          EKS CLI
        </Typography.Link>
      </Typography.Text>
      <CodeBlock content={`eksctl create cluster`} />
      <Helm
        agentHost={agentHost}
        agentUrl={agentUrl}
        agentEnvironment={agentEnvironment}
        agentDataDomain={agentDataDomain}
        showInstruction={showInstruction}
      />
    </Typography.Paragraph>
  );
};

export default EKS;
