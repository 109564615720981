import { createSelector } from "@reduxjs/toolkit";
import type { AppState } from "store/types";

export const selectAiState = (state: AppState) => state.ai;

export const selectAiModalOpen = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId !== undefined,
);

export const selectInitialPosition = createSelector(
  selectAiState,
  (state) => state.initialPosition,
);

export const selectAiEditedWidgetId = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId,
);

export const selectAiDataTreeChangesById = createSelector(
  selectAiState,
  (state) => {
    if (!state.selectedWidgetId || !state.dataTreeChanges) {
      return undefined;
    }
    return {
      [state.selectedWidgetId]: state.dataTreeChanges,
    };
  },
);

export const selectAiComponentDataTreeEdits = createSelector(
  selectAiState,
  (state) => {
    return state.dataTreeChanges;
  },
);

export const selectAiComponentPropertiesToChange = createSelector(
  selectAiState,
  (state) => {
    return state.propertiesToChange;
  },
);
