import { all, put, takeLatest } from "redux-saga/effects";
import { setMetaProp } from "legacy/actions/metaActions";
import { switchTab } from "legacy/actions/widgetActions";

function* switchTabSaga(action: ReturnType<typeof switchTab>) {
  yield put(
    setMetaProp(
      action.payload.tabsId,
      "selectedTabWidgetId",
      action.payload.tabId,
    ),
  );
}

export default function* tabsSagas() {
  yield all([takeLatest(switchTab.type, switchTabSaga)]);
}
