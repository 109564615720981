import { Layout as AntLayout } from "antd";
import React, { ReactElement } from "react";
import styled from "styled-components";
interface LayoutProps {
  Sider?: ReactElement | null;
  Header?: React.ReactNode | null;
  children: React.ReactNode;
}

const MainLayout = styled(AntLayout)`
  height: 100%;
  &,
  .ant-layout {
    background-color: inherit;
  }
`;

const SiderLayout = styled(AntLayout)`
  flex: 1 1 0%;
`;

const Content = styled(AntLayout.Content)`
  overflow-y: auto;
`;

const Layout = ({ children, Sider, Header }: LayoutProps) => (
  <MainLayout>
    {Header}
    {Sider ? (
      <SiderLayout>
        {Sider}
        <Content data-superblocks="scrollable-container">{children}</Content>
      </SiderLayout>
    ) : (
      <Content data-superblocks="scrollable-container">{children}</Content>
    )}
  </MainLayout>
);

export default Layout;
