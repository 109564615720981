import { UIErrorType } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import { APP_MODE } from "legacy/reducers/types";
import { waitForNextEvaluationToComplete } from "legacy/sagas/waitForEvaluation";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import {
  TriggerFrontendEventHandlersPayload,
  triggerFrontendEventHandlersSaga,
} from "store/sagas/triggerFrontendEventHandlers";
import { getEnvironment } from "store/slices/application/selectors";
import { callSagas } from "store/utils/saga";
import UITracing from "tracing/UITracing";
import { sendWarningUINotification } from "utils/notification";
import { executeV2ApiSaga, type ExecutionResponse } from "../apisV2";
import { executeApiSaga } from "./sagas/executeApi";
import { ApiExecutionResponseDto } from "./types";

const MAX_CALL_DEPTH = 10;

export function* executeApiWithViewMode(
  actionId: string,
  eventType: EventType,
  version: "v1" | "v2",
  callStack: ExecuteActionCallStack = [],
  spanId?: string,
  commitId?: string,
) {
  // note that `callStack` includes the current and yet unexecuted action, so as soon as its length reaches
  // `MAX_CALL_DEPTH + 1`, this means that we have performed `MAX_CALL_DEPTH` actions
  if (callStack && callStack.length > MAX_CALL_DEPTH) {
    const msg =
      "Infinite loop detected. Breaking out of the loop, max depth: " +
      MAX_CALL_DEPTH +
      ". Triggers executed: " +
      [...callStack]
        .reverse()
        .map((item) => item.propertyPath)
        .join(" ➜ ");
    console.warn(msg);
    UITracing.addEvent(spanId, msg, UIErrorType.VALIDATION_ERROR);
    sendWarningUINotification({
      message: msg,
    });
    return;
  }

  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_ACTION,
    { actionId },
    actionId,
  );

  const appMode: string = yield select(getAppMode);
  const environment: string = yield select(getEnvironment);

  const [result]: [ApiExecutionResponseDto | ExecutionResponse | undefined] =
    yield callSagas(
      version === "v1"
        ? [
            executeApiSaga.apply({
              apiId: actionId,
              viewMode: appMode === APP_MODE.PUBLISHED,
              environment,
              eventType,
              params: [],
              notifyOnSystemError: true,
              manualRun: false,
              callStack,
              spanId,
              commitId,
            }),
          ]
        : [
            executeV2ApiSaga.apply({
              apiId: actionId,
              includeOutputs: appMode === APP_MODE.EDIT,
              viewMode: appMode === APP_MODE.PUBLISHED,
              environment,
              eventType,
              params: [],
              notifyOnSystemError: true,
              manualRun: false,
              callStack,
              spanId,
              commitId,
            }),
          ],
    );
  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.EXECUTE_ACTION,
    undefined,
    actionId,
  );

  if (result) {
    yield call(waitForNextEvaluationToComplete);
  }

  const payload: TriggerFrontendEventHandlersPayload = {
    version,
    apiId: actionId,
    result,
    callStack,
    manualRun: eventType === EventType.ON_RUN_CLICK,
  };
  yield call(triggerFrontendEventHandlersSaga, payload);

  return result;
}
