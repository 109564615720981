import React, { useMemo } from "react";
import CodeEditor from "components/app/CodeEditor";
import { AutocompleteConfiguration } from "components/app/CodeEditor/EditorConfig";
import { EditorProps } from "components/app/CodeEditor/types";
import { EMPTY_ON_CHANGE } from "../utils";

const CodeField = ({
  value,
  onChange = EMPTY_ON_CHANGE,
  onBlur,
  onDragStart,
  onDrop,
  onFocus,
  name,
  showShortcutMenu = false,
  ...otherProps
}: EditorProps["input"] &
  Omit<EditorProps, "autocompleteConfiguration" | "input">) => {
  const input = useMemo(
    () => ({ name, value, onChange, onBlur, onDragStart, onDrop, onFocus }),
    [name, value, onChange, onBlur, onDragStart, onDrop, onFocus],
  );

  const autocompleteConfiguration = useMemo<AutocompleteConfiguration>(
    () => ({
      env: true,
    }),
    [],
  );

  return (
    <CodeEditor
      input={input}
      showShortcutMenu={showShortcutMenu}
      monospace={true}
      minHeight="350px"
      autocompleteConfiguration={autocompleteConfiguration}
      {...otherProps}
    />
  );
};

export default CodeField;
