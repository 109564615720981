import CodeMirror from "codemirror";
import React, { useEffect, useRef } from "react";

type Props = {
  stringValue: string;
  mode: string;
  height?: string;
};

const defaultOptions: CodeMirror.EditorConfiguration = {
  viewportMargin: 10,
  tabSize: 2,
  lineWrapping: true,
  lineNumbers: true,
  lint: true,
  scrollbarStyle: "native",
  extraKeys: {
    "Ctrl-/": (cm) => cm.execCommand("toggleComment"),
    "Cmd-/": (cm) => cm.execCommand("toggleComment"),
    "Ctrl-F": (cm) => cm.execCommand("findPersistent"),
    "Cmd-F": (cm) => cm.execCommand("findPersistent"),
  },
  theme: "neo",
  foldOptions: {},
  foldGutter: true,
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-foldgutter",
    "CodeMirror-linenumbers",
  ],
};

const CodeBlockAdvanced = (props: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const cm = useRef<CodeMirror.Editor | null>(null);

  const { stringValue, mode } = props;

  useEffect(() => {
    if (
      cm.current &&
      typeof stringValue === "string" &&
      cm.current.getValue() !== stringValue
    ) {
      cm.current.setValue(stringValue);
      cm.current.performLint();
    }
  }, [stringValue]);

  return (
    <div>
      <textarea
        ref={(v) => {
          if (!textareaRef.current && v) {
            textareaRef.current = v;
            cm.current = CodeMirror.fromTextArea(v, {
              ...defaultOptions,
              readOnly: true,
              lineNumbers: true,
              mode,
            });
          }
        }}
      >
        {stringValue}
      </textarea>
    </div>
  );
};

export default CodeBlockAdvanced;
