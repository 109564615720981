// Heavily inspired from https://github.com/codemirror/CodeMirror/blob/master/addon/tern/tern.js
import { ApplicationScope } from "@superblocksteam/shared";
import CodeMirror, { Hint, Hints } from "codemirror";
import {
  AutocompleteConfiguration,
  HinterUpdateRequest,
} from "components/app/CodeEditor/EditorConfig";
import { adjustHintPosition } from "components/app/CodeEditor/hintUtils";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ApiScope } from "utils/dataTree/scope";
import {
  sortAndFilterExpandedCompletions,
  renderAutocompleteRow,
  compileKeyPathStr,
} from "./ExpandedAutocomplete";
import { HINTER_OPTIONS } from "./constants";
import { customTreeTypeDefCreator } from "./customTreeTypeDefCreator";
import {
  dataTreeTypeDefCreator,
  DataTreeDef,
  Origin,
  appScopeDataTreeTypeDefCreator,
} from "./dataTreeTypeDefCreator";
import { functionInfo } from "./function-info";
import { getDataType } from "./getDataType";
import { ExpandedAutocompleteDefinition } from "./types";
import {
  AUTOCOMPLETE_CLASS,
  completionComparator,
  Completion,
  typeToIcon,
  makeDecrementedHintCompletion,
  renderHint,
} from "./util";

const SUPERBLOCKS_INSERTION_TOKEN = "___SB_INSERTION_TOKEN___";

function positionToOffset(position: CodeMirror.Position, code: string) {
  const lines = code.split("\n");
  let offset = 0;
  for (let i = 0; i < lines.length; i++) {
    if (i === position.line) {
      return offset + position.ch;
    }
    offset += lines[i].length + 1;
  }
  return offset;
}

class PythonCompletion {
  defs: DataTreeDef[];
  additionalDataTree: any;
  keywords: string[];
  rendered: { tooltip: HTMLElement; remove: () => void } | undefined =
    undefined;

  constructor(
    dataTree: DataTree,
    additionalData: Record<string, Record<string, unknown>> | undefined,
    apiScope: ApiScope | undefined,
    appScope: ApplicationScope,
    configuration: AutocompleteConfiguration,
  ) {
    const dataTreeDef = dataTreeTypeDefCreator({
      dataTree,
      apiScope,
      appScope,
      configuration,
    });
    this.defs = [dataTreeDef];

    if (appScope === ApplicationScope.PAGE && configuration.global) {
      const appDataTreeDef = appScopeDataTreeTypeDefCreator({
        dataTree,
        apiScope,
        appScope: ApplicationScope.APP,
        keepOriginalDef: false,
      });
      this.defs.push(appDataTreeDef);
    }

    if (additionalData) {
      const customTreeDef = customTreeTypeDefCreator(additionalData);
      this.defs.push(customTreeDef);
    }
    this.keywords = [...CodeMirror.hintWords.python].sort();
  }

  unregister() {
    this.remove();
    this.defs = [];
    this.additionalDataTree = null;
    this.keywords = [];
  }

  static getExpanded(
    def: DataTreeDef | undefined,
  ): undefined | ExpandedAutocompleteDefinition {
    return def?.["!doc"]?.expanded;
  }

  static renderExpandedRow(el: HTMLElement, pos: Hints, cur: Hint) {
    const data = (cur as Completion).data;
    const expanded = PythonCompletion.getExpanded(data);
    if (expanded) {
      renderAutocompleteRow(expanded, cur.displayText ?? cur.text, el);
    }
  }

  update(updateRequest: HinterUpdateRequest) {
    const dataTreeDef = dataTreeTypeDefCreator({
      dataTree: updateRequest.data,
      apiScope: updateRequest.apiScope,
      appScope: updateRequest.appScope,
      configuration: updateRequest.configuration,
    });
    this.defs = [dataTreeDef];

    if (
      updateRequest.appScope === ApplicationScope.PAGE &&
      updateRequest.configuration.global
    ) {
      const appDataTreeDef = appScopeDataTreeTypeDefCreator({
        dataTree: updateRequest.data,
        apiScope: updateRequest.apiScope,
        appScope: ApplicationScope.APP,
        configuration: updateRequest.configuration,
      });
      this.defs.push(appDataTreeDef);
    }

    if (updateRequest.additionalData) {
      const customTreeDef = customTreeTypeDefCreator(
        updateRequest.additionalData,
      );
      this.defs.push(customTreeDef);
    }
  }

  complete(cm: CodeMirror.Editor) {
    cm.showHint({
      hint: adjustHintPosition(this.getHint.bind(this)),
      ...HINTER_OPTIONS,
    });
  }

  async getHint(cm: CodeMirror.Editor): Promise<CodeMirror.Hints> {
    const completion = await this.getHints(cm);

    const sorted = {
      ...completion,
      list: this.sortCompletions(completion.list),
    };

    CodeMirror.on(sorted, "close", () => this.remove());
    CodeMirror.on(sorted, "update", () => this.remove());
    CodeMirror.on(sorted, "select", (cur: any, node: any) => {
      this.remove();
      this.rendered = renderHint(cm, {
        node,
        "!doc": cur.data["!doc"],
        "!type": cur.data["!type"],
      });
    });

    return sorted;
  }

  makeCompletion(key: string, dt: DataTreeDef) {
    const expanded = PythonCompletion.getExpanded(dt);
    const render = expanded ? PythonCompletion.renderExpandedRow : undefined;
    const decrementCursor =
      functionInfo(dt).hasParams && key.endsWith(")") ? 1 : 0;
    return {
      text: key,
      displayText: key,
      data: dt,
      origin,
      type: getDataType(dt["!type"] as string),
      render,
      hint:
        typeof decrementCursor === "number"
          ? makeDecrementedHintCompletion(key, decrementCursor)
          : undefined,
      decrementCursor,
      className: render ? undefined : typeToIcon(dt["!type"] as string),
    };
  }

  async getHints(cm: CodeMirror.Editor): Promise<{
    from: CodeMirror.Position;
    to: CodeMirror.Position;
    list: Completion[];
  }> {
    const { parser } = await import(
      /* webpackChunkName: "python" */ "@lezer/python"
    );

    // Insert a token at your cursor position to determine the type of token
    const code = cm.getValue();
    const insertion = cm.getCursor();
    if (cm.somethingSelected()) {
      return { from: insertion, to: insertion, list: [] };
    }
    const insertionOffset = positionToOffset(insertion, code);
    const codeWithToken = `${code.substring(
      0,
      insertionOffset,
    )}${SUPERBLOCKS_INSERTION_TOKEN}${code.substring(insertionOffset)}`;

    // Parsing always succeeds, but does not imply that the grammar is valid.
    // Invalid tokens use the type ⚠, but does not affect the entity extraction
    const tree = parser.parse(codeWithToken);
    const cursor = tree.cursorAt(insertionOffset, 1);

    const startPos = {
      line: insertion.line,
      ch: insertion.ch + (cursor.from - insertionOffset),
    };
    const endPos = {
      line: insertion.line,
      // Handles the case where you are typing into the middle of an existing token
      ch:
        insertion.ch +
        (cursor.to - insertionOffset - SUPERBLOCKS_INSERTION_TOKEN.length),
    };

    const emptyCompletion = { from: startPos, to: endPos, list: [] };

    const path = codeWithToken
      .substring(cursor.from, cursor.to)
      .replace(SUPERBLOCKS_INSERTION_TOKEN, "")
      .toLowerCase();

    if (cursor.name === "VariableName") {
      const values: Completion[] = this.defs.flatMap((def) => {
        if (!def) {
          return [];
        }
        // Keys that start with ! are reserved by the doc generator
        const keys = Object.keys(def).filter((key) => key.indexOf("!") !== 0);
        return keys
          .filter((key) => key.toLowerCase().indexOf(path) > -1)
          .flatMap((key) => {
            const results: Array<Completion> = [];
            const dt = def[key] as DataTreeDef;
            const expanded = PythonCompletion.getExpanded(dt);

            if (expanded?.expandedShortcuts?.length) {
              expanded.expandedShortcuts.forEach((shortcut) => {
                const { keyPath, fnSignature, ...shortcutDef } = shortcut;
                const text = compileKeyPathStr(keyPath, fnSignature);
                results.push(this.makeCompletion(text, shortcutDef));
              });
            }

            if (!dt["!doc"]?.hidden) {
              results.push(this.makeCompletion(key, dt));
            }
            return results;
          });
      });

      return {
        ...emptyCompletion,
        list: values.concat(this.getMatchingKeywords(path)),
      };
    } else if (cursor.name === "PropertyName") {
      const paths = [path];
      // The logic can only handle dotted property access like a.b.c.d, everything else is skipped.
      // The tree for a.b.c.d can be represented as (((a b) c) d), where the parentheses are MemberExpressions
      while (cursor.prevSibling()) {
        // Enter the end of the inner grouping
        // @ts-expect-error Static analysis is wrong here, because the cursor itself changes each time
        if (cursor.name === "MemberExpression") {
          if (!cursor.lastChild()) {
            // This error should never happen, but just in case
            return emptyCompletion;
          }
        }
        if (
          // @ts-expect-error Static analysis is wrong here, because the cursor itself changes each time
          cursor.name === "VariableName" ||
          cursor.name === "PropertyName"
        ) {
          // Insert at the beginning because we are traversing backwards
          paths.unshift(codeWithToken.substring(cursor.from, cursor.to));
        } else if (cursor.name !== ".") {
          return emptyCompletion;
        }
      }

      let parentDef: DataTreeDef | undefined = undefined;
      let ret: Completion[] = [];
      let origin: string;
      this.defs.forEach((def) => {
        if (def && def[paths[0]]) {
          parentDef = def[paths[0]] as DataTreeDef;
          origin = def["!name"] ?? "";
        }
      });
      if (parentDef) {
        paths.slice(1).forEach((path, index) => {
          if (index === paths.length - 2) {
            const keys = Object.keys(parentDef!).filter(
              // Keys that start with ! are reserved
              (key) => key.indexOf("!") !== 0,
            );
            ret = keys
              .filter(
                (key) => key.toLowerCase().indexOf(path.toLowerCase()) > -1,
              )
              .flatMap((key) => {
                const results: Array<Completion> = [];

                const def = parentDef![key] as DataTreeDef;

                const expanded = PythonCompletion.getExpanded(def);
                if (expanded?.expandedShortcuts?.length) {
                  expanded.expandedShortcuts.forEach((shortcut) => {
                    const { keyPath, fnSignature, ...shortcutDef } = shortcut;
                    const text = compileKeyPathStr(keyPath, fnSignature);
                    results.push(this.makeCompletion(text, shortcutDef));
                  });
                }
                if (!def["!doc"]?.hidden) {
                  results.push({
                    ...this.makeCompletion(key, def),
                    origin,
                  });
                }
                return results;
              });
          } else if (parentDef?.[path]) {
            parentDef = parentDef[path] as Record<string, unknown>;
          } else {
            ret = [];
          }
        });
      }

      return { ...emptyCompletion, list: ret ?? [] };
    }
    return {
      ...emptyCompletion,
      list: this.getMatchingKeywords(path),
    };
  }

  getMatchingKeywords(path: string): Completion[] {
    return this.keywords
      .filter((keyword) => keyword.toLowerCase().indexOf(path) > -1)
      .sort((a, b) => {
        return a.toLowerCase().indexOf(path) - b.toLowerCase().indexOf(path);
      })
      .map((keyword) => ({
        text: keyword,
        origin: "keyword",
        className: AUTOCOMPLETE_CLASS + "completion",
        type: "UNKNOWN",
        data: {},
      }));
  }

  sortCompletions(completions: Completion[]) {
    // Add data tree completions before others
    const expandedCompletions = new Array<Completion>();
    const dataTreeCompletions = new Array<Completion>();
    const otherCompletions = new Array<Completion>();
    const keywordCompletions = new Array<Completion>();
    const appDataTreeCompletions = new Array<Completion>();

    for (const c of completions) {
      if (PythonCompletion.getExpanded(c.data)) {
        expandedCompletions.push(c);
      } else if (
        c.origin === Origin.DATA_TREE ||
        c.origin === Origin.CUSTOM_DATA_TREE
      ) {
        dataTreeCompletions.push(c);
      } else if (c.origin === Origin.APP_DATA_TREE) {
        appDataTreeCompletions.push(c);
      } else if (c.origin === "keyword") {
        keywordCompletions.push(c);
      } else {
        otherCompletions.push(c);
      }
    }

    dataTreeCompletions.sort((a, b) => completionComparator(a, b, "!doc"));

    const processedExpandedCompletions = sortAndFilterExpandedCompletions(
      expandedCompletions,
      (a) => PythonCompletion.getExpanded(a.data),
    );

    return processedExpandedCompletions.concat(
      dataTreeCompletions,
      appDataTreeCompletions,
      otherCompletions,
      keywordCompletions,
    );
  }

  remove() {
    if (this.rendered) {
      this.rendered.remove();
      delete this.rendered;
    }
  }
}

export default PythonCompletion;
