import type { AppState } from "store/types";

export const getCurrentOrgId = (state: AppState) =>
  state.legacy.ui.orgs.currentOrg.id;

export const getUserCurrentOrgId = (state: AppState) => {
  return state.legacy.ui.users.currentUser?.currentOrganizationId;
};

export const getCurrentOrgNameSelector = (state: AppState) => {
  const orgId = state.legacy.ui.users.currentUser?.currentOrganizationId;
  if (orgId) {
    return state.organizations.entities[orgId]?.name;
  }
  return orgId;
};
