import { Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import { Terraform_AgentKey } from "../constants";
const { Link, Paragraph, Text } = Typography;

const StepParagraph = styled(Text)`
  & > .ant-typography-secondary {
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const CloudRun = ({
  agentDomain,
  agentSubdomain,
  agentEnvironment,
  agentDataDomain,
  latestOpaVersion,
  showInstruction,
}: AgentDeploymentInstructionsProps) => {
  return (
    <Space direction="vertical" size={20}>
      <StepParagraph>
        <Text type="secondary">Requirements:</Text>
        <br />
        <Text type="secondary">
          • Install{" "}
          <Link
            href={"https://developer.hashicorp.com/terraform/downloads"}
            target="_blank"
          >
            Terraform
          </Link>
        </Text>
        <br />
        <Text type="secondary">
          • Configure{" "}
          <Link
            href={
              "https://developer.hashicorp.com/terraform/tutorials/gcp-get-started/google-cloud-platform-build"
            }
            target="_blank"
          >
            GCP with Terraform
          </Link>
        </Text>
      </StepParagraph>

      <Paragraph>
        <Text strong>Add Superblocks Module to main.tf</Text>
        <CodeBlock
          content={`provider "google" {
  project = <GOOGLE_CLOUD_PROJECT_ID>
  region  = <GOOGLE_CLOUD_REGION>
}

module "terraform-google-superblocks" {
  source  = "superblocksteam/superblocks/google"
  version = "~>1.0"

  project_id = "<GOOGLE_CLOUD_PROJECT_ID>"
  region     = "<GOOGLE_CLOUD_REGION>"

  ${Terraform_AgentKey}        = "<AGENT_KEY>"
  superblocks_agent_tags = "profile:*"

  # Subdomain & domain in you Superblocks agent host url, for example superblocks.example.com
  subdomain = "${agentSubdomain}"
  domain    = "${agentDomain}"

  # Google Cloud DNS Zone Name
  zone_name = "<YOUR_DOMAINS_CLOUD_DNS_ZONE_NAME>"

  superblocks_agent_data_domain = "${agentDataDomain}"

  superblocks_agent_image = "us-east1-docker.pkg.dev/superblocks-registry/superblocks/agent:${latestOpaVersion}"
}`}
        />
      </Paragraph>

      <Paragraph>
        <Text strong>Initialize & Apply Terraform</Text>
        <CodeBlock
          content={`terraform init
terraform apply`}
        />
      </Paragraph>
    </Space>
  );
};

export default CloudRun;
