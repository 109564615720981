import { ProfileUpdate } from "@superblocksteam/schemas";
import { Profile } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { PayloadAction } from "../../../utils/action";
import { createSaga } from "../../../utils/saga";
import { updateProfile } from "../client";
import slice from "../slice";

interface UpdateProfilePayload {
  orgId: string;
  profileId: string;
  updated: ProfileUpdate;
}

function* updateProfileInternal({
  orgId,
  profileId,
  updated,
}: UpdateProfilePayload) {
  const profile: Profile = yield call(updateProfile, orgId, profileId, updated);
  return {
    orgId: orgId,
    profileId: profileId,
    updated: profile,
  } as UpdateProfilePayload;
}

export const updateProfileSaga = createSaga(
  updateProfileInternal,
  "updateProfileSaga",
  { sliceName: "organizations" },
);

slice.saga(updateProfileSaga, {
  start(state, payload: PayloadAction<UpdateProfilePayload>) {
    state.loading[payload.payload.orgId] = true;
    delete state.errors[payload.payload.orgId];
  },
  success(state, { payload }) {
    state.entities[payload.orgId] = state.entities[payload.orgId] ?? {};
    const existingProfiles = state.entities[payload.orgId].profiles;
    state.entities[payload.orgId].profiles = existingProfiles?.map(
      (profile) => {
        if (profile.key === payload.updated.key) {
          return {
            ...profile,
            ...payload.updated,
          };
        }
        return profile;
      },
    );
    delete state.loading[payload.orgId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.orgId] = { error: payload };
    delete state.loading[meta.args.orgId];
  },
});
