import { colors } from "styles/colors";
import { isProduction } from "utils/env";

export type PlanDetails = {
  displayName: string;
  description: string;
  // ENTERPRISE pricing is custom - represented as null when compared to PRO.
  creatorCostPerMonth: number | null;
  endUserCostPerMonth: number | null;
  includedStepRuns: number | null;
  incrementalStepRunCost: number | null;
  incrementalStepRunQuanitty: number | null;
  bulletPoints: string[];
  summaryBulletPoints: string[];
  color: string;
  secondaryColor: string;
};

export const PlanTypes: Record<"PRO" | "ENTERPRISE", PlanDetails> = {
  PRO: {
    displayName: "Pro",
    description: "For growing business managing internal tools across teams",
    creatorCostPerMonth: 49,
    endUserCostPerMonth: 15,
    includedStepRuns: 1000,
    incrementalStepRunQuanitty: 1000,
    incrementalStepRunCost: 55,
    color: colors.ACCENT_ORANGE,
    secondaryColor: colors.LIGHT_ORANGE,
    bulletPoints: [
      "Unlimited Custom Integration Profiles",
      "Granular Permissions and Groups (RBAC)",
      "Import Custom Components",
      "User Analytics",
      "Live Queries with Timers",
      "Reusable Modules",
      "Audit Logging (3 days)",
    ],
    summaryBulletPoints: [
      "Build unlimited Apps",
      "Build unlimited Workflows & Scheduled Jobs",
      "Integration with any API and Database",
      "Unlimited Custom Integration Profiles",
      "Reusable Modules",
      "Live Queries with Timers",
    ],
  },
  ENTERPRISE: {
    displayName: "Enterprise",
    description:
      "For businesses with advanced security, management and support needs",
    creatorCostPerMonth: null,
    endUserCostPerMonth: null,
    includedStepRuns: null,
    incrementalStepRunQuanitty: null,
    incrementalStepRunCost: null,
    color: colors.ACCENT_PURPLE,
    secondaryColor: colors.LIGHT_PURPLE,
    bulletPoints: [],
    summaryBulletPoints: [
      "Single-Sign-On / SAML",
      "Sync user groups with IdP (SCIM)",
      "Git Sync via GitHub, GitLab, Bitbucket",
      "Audit Logging (custom retention)",
      "User Analytics & Production Observability",
      "Build Streaming APIs via Kafka, Flink, Kinesis",
      "Dedicated CSM & Technical Support from a Superblocks Engineer",
      "Keep data in your VPC with the On-Premise Agent",
    ],
  },
};

type PricingSpec = {
  productId: string;
  priceId: string;
  price: number;
};

type PricingByInterval = {
  YEARLY: PricingSpec;
  MONTHLY: PricingSpec;
};

export const PRICING: Record<
  "CREATOR_SEAT" | "END_USER_SEAT",
  PricingByInterval
> = {
  CREATOR_SEAT: {
    YEARLY: {
      productId: !isProduction()
        ? "prod_Lqv4hI2rBcLe9p"
        : "prod_LsjdY5KzeX8F36",
      priceId: !isProduction()
        ? "price_1LBhj9A0IJVGmXTQiSui4rPE"
        : "price_1LAyAhA0IJVGmXTQmwj41F9z",
      price: 49 * 12,
    },
    MONTHLY: {
      productId: !isProduction()
        ? "prod_Lqv4hI2rBcLe9p"
        : "prod_LsjdY5KzeX8F36",
      priceId: !isProduction()
        ? "price_1LBPO4A0IJVGmXTQHFMOVtUS"
        : "price_1LBPHqA0IJVGmXTQhQJm9CAG",
      price: 70,
    },
  },
  END_USER_SEAT: {
    YEARLY: {
      productId: !isProduction()
        ? "prod_N9qfmFr3TbclvE"
        : "prod_N9rSOaXooja5Ju",
      priceId: !isProduction()
        ? "price_1MPWydA0IJVGmXTQQFCSxD8k"
        : "price_1MPXk2A0IJVGmXTQGA7eiyRb",
      price: 15 * 12,
    },
    MONTHLY: {
      productId: !isProduction()
        ? "prod_N9qfmFr3TbclvE"
        : "prod_N9rSOaXooja5Ju",
      priceId: !isProduction()
        ? "price_1MPWydA0IJVGmXTQYWwYgPot"
        : "price_1MPXk2A0IJVGmXTQzIVi0fKX",
      price: 21,
    },
  },
};
