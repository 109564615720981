import { ApplicationScope } from "@superblocksteam/shared";
import { omit } from "lodash";

export function toScopedEntity<T>(
  entityMap: Record<string, Omit<T, "scope">>,
  scope: ApplicationScope,
): Record<string, T> {
  return Object.fromEntries(
    Object.entries(entityMap ?? {}).map(([id, entity]) => [
      id,
      { ...entity, scope: scope } as T,
    ]),
  );
}

export function toUnscopedEntity<T>(
  scopedEntityMap: Record<string, T & { scope: ApplicationScope }>,
): Record<string, T> {
  return Object.fromEntries(
    Object.entries(scopedEntityMap ?? {}).map(([id, scopedEntity]) => [
      id,
      omit(scopedEntity, "scope") as T,
    ]),
  );
}

export const getScopedEntityPrefix = (
  scope: ApplicationScope,
  name: string,
): string => {
  const prefix = scope === ApplicationScope.APP ? "App" : "";
  return `${prefix}${name}`;
};
