import styled from "styled-components";

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex: 1;

  button {
    height: 24px;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    min-width: 70px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:first-child {
    background-color: ${({ theme }) => theme.colors.WHITE};
    color: ${({ theme }) => theme.colors.GREY_700};
    border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  }

  button:last-child {
    background-color: ${({ theme }) => theme.colors.RED_500};
    color: ${({ theme }) => theme.colors.WHITE};
    border: none;
  }
`;

export const TableText = styled.div<{ isLink?: boolean }>`
  font-size: 12px;
  color: ${({ theme, isLink }) =>
    isLink ? theme.colors.INFO : theme.colors.GREY_900};
  cursor: ${({ isLink }) => (isLink ? "pointer" : "default")};
  padding: 8px 16px;
`;
