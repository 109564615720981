import React from "react";
import styled from "styled-components";
import { ReactComponent as ResizeIcon } from "assets/icons/common/resize.svg";

enum Direction {
  TOP_LEFT = "TOP_LEFT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

const DiagonalWrapper = styled.div`
  height: 100%;
  width: 100%;
  &:hover {
    svg {
      visibility: visible;
    }
  }
`;

const DiagonalIcon = styled.div`
  position: relative;
  z-index: 10000;
  height: 10px;
  width: 10px;
  svg {
    visibility: hidden;
    height: 12px;
    width: 12px;
    position: relative;
    display: block;
    path {
      fill: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }
  }
`;

const UpperRightDiagonal = styled(DiagonalIcon)`
  left: 7px;
  top: 11px;
  svg {
    transform: rotate(270deg);
  }
`;

const BottomRightDiagonal = styled(DiagonalIcon)`
  bottom: 0px;
  right: 0px;
`;

const BottomLeftDiagonal = styled(DiagonalIcon)`
  left: 11px;
  top: 17px;
  svg {
    transform: rotate(90deg);
  }
`;

const TopLeftDiagonal = styled(DiagonalIcon)`
  top: 11px;
  left: 11px;
  svg {
    transform: rotate(180deg);
  }
`;

const ResizeHandle = ({ direction }: { direction: Direction }) => {
  switch (direction) {
    case Direction.TOP_LEFT:
      return (
        <DiagonalWrapper>
          <TopLeftDiagonal>
            <ResizeIcon />
          </TopLeftDiagonal>
        </DiagonalWrapper>
      );
    case Direction.TOP_RIGHT:
      return (
        <DiagonalWrapper>
          <UpperRightDiagonal>
            <ResizeIcon />
          </UpperRightDiagonal>
        </DiagonalWrapper>
      );
    case Direction.BOTTOM_LEFT:
      return (
        <DiagonalWrapper>
          <BottomLeftDiagonal>
            <ResizeIcon />
          </BottomLeftDiagonal>
        </DiagonalWrapper>
      );
    case Direction.BOTTOM_RIGHT:
      return (
        <DiagonalWrapper>
          <BottomRightDiagonal>
            <ResizeIcon />
          </BottomRightDiagonal>
        </DiagonalWrapper>
      );
  }
};

export const RESIZE_HANDLES = {
  bottomLeft: <ResizeHandle direction={Direction.BOTTOM_LEFT} />,
  bottomRight: <ResizeHandle direction={Direction.BOTTOM_RIGHT} />,
  topLeft: <ResizeHandle direction={Direction.TOP_LEFT} />,
  topRight: <ResizeHandle direction={Direction.TOP_RIGHT} />,
};

export const RESIZE_HANDLE_STYLES = {
  bottomLeft: {
    height: "40px",
    width: "40px",
  },
  bottomRight: {
    // slightly smaller to avoid conflict with send button
    height: "25px",
    width: "25px",
    bottom: "-12px",
    right: "-12px",
  },
  topLeft: {
    height: "40px",
    width: "40px",
  },
  topRight: {
    height: "30px",
    width: "30px",
  },
};
