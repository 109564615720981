import styled from "styled-components";

export const HeaderDropdownSelector = styled.div<{
  isSelected: boolean;
  backgroundColor?: string;
}>`
  cursor: pointer;
  color: ${(props) => props.theme.colors.OFF_WHITE};
  padding: 0 6px 0 12px;
  user-select: none;
  font-size: 12px;

  background-color: ${({ isSelected, backgroundColor, theme }) =>
    isSelected ? (backgroundColor ?? theme.colors.GREY_800) : "transparent"};

  :hover {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ?? theme.colors.GREY_800};
  }

  display: flex;
  align-items: center;
`;
