export function adjustHintPosition(originalHint: CodeMirror.HintFunction) {
  return async function (
    cm: CodeMirror.Editor,
    options: CodeMirror.ShowHintOptions,
  ) {
    const result = await originalHint(cm, options);

    if (result && result.list.length > 0 && cm.state.renderHintAbove) {
      // Set a small delay to ensure the hints element has been created
      setTimeout(() => {
        const hintsElement = cm.state.completionActive?.widget?.hints;
        if (hintsElement) {
          hintsElement.classList.add("autocomplete-wrapper-above");
        }
      }, 0);
    }

    return result;
  };
}
