import { PageDSL6 } from '../types';

const page: PageDSL6 = {
  version: 6,
  widgetName: 'MainContainer',
  backgroundColor: 'none',
  detachFromLayout: true,
  widgetId: '0',
  topRow: 0,
  bottomRow: 99,
  containerStyle: 'none',
  snapColumns: 96,
  snapRows: 99,
  type: 'CANVAS_WIDGET',
  canExtend: true,
  dynamicBindingPathList: [],
  minHeight: 1292,
  children: []
};

export default page;
