import { QuestionCircleTwoTone } from "@ant-design/icons";
import { FormComponentType, FormItem } from "@superblocksteam/shared";
import { Alert, Checkbox, Form, Row, Switch, Tooltip, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import styled from "styled-components";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { Markdown } from "components/ui/Markdown";
import { getEntityACShortcutString } from "utils/navigator";
import { EditorLanguage } from "../CodeEditor/types";
import CodeEditor from "./components/CodeEditor";
import Dropdown from "./components/Dropdown";
import DynamicDropdown from "./components/DynamicDropdown";
import FieldList from "./components/FieldList";
import Input from "./components/Input";

const StyledFormItem = styled(Form.Item).attrs(
  (props: { isInline: boolean }) => ({
    isInline: props.isInline,
  }),
)`
  ${(props) =>
    props.isInline &&
    `flex-direction: row !important;
  margin-bottom: 10px;
  align-items: center;`}
  label {
    ${(props) => !props.isInline && "display: inline-block;"}
    width: 100%;
    ${(props) => props.isInline && "margin-right: 10px !important;"}
  }
  .ant-form-item-label {
    ${(props) => props.isInline && "padding: 0px;"}
  }
`;

const EDITOR_MODE_LANGUAGE: Record<EditorLanguage, EditorModes> = {
  [EditorLanguage.TEXT]: EditorModes.TEXT,
  [EditorLanguage.TEXT_BINDING]: EditorModes.TEXT_WITH_BINDING,
  [EditorLanguage.SQL]: EditorModes.SQL_WITH_BINDING,
  [EditorLanguage.SQL_BINDING]: EditorModes.SQL_WITH_BINDING,
  [EditorLanguage.JSON]: EditorModes.JSON_WITH_BINDING,
  [EditorLanguage.JSON_BINDING]: EditorModes.JSON_WITH_BINDING,
  [EditorLanguage.JAVASCRIPT]: EditorModes.JAVASCRIPT,
  [EditorLanguage.PYTHON]: EditorModes.PYTHON,
};

type Props = {
  formItem: FormItem;
  wrapperRect?: DOMRect | undefined;
};

type ItemComponentProps = {
  onFocus: () => void;
  onBlur: () => void;
} & Props;

// Using a helper function instead of a functional component so that the top level Form.Item child is bound
const getItemComponent = ({
  formItem,
  wrapperRect,
  onFocus,
  onBlur,
}: ItemComponentProps) => {
  switch (formItem.componentType) {
    case FormComponentType.INPUT_TEXT:
      return (
        <Input
          dataType={formItem.dataType}
          placeholder={formItem.placeholder}
          disabled={formItem.disabled}
        />
      );
    case FormComponentType.DYNAMIC_INPUT_TEXT:
      return (
        <CodeEditor
          data-test={`dynamic-text-${formItem.name}`}
          showLineNumbers={false}
          mode={EditorModes.TEXT_WITH_BINDING}
          key={EditorModes.TEXT_WITH_BINDING}
          tabBehaviour={TabBehaviour.INDENT}
          disabled={Boolean(formItem.disabled)}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          minHeight={formItem.style?.minHeight ?? "33px"}
          maxHeight="350px"
          placeholder={formItem.placeholder}
          onEditorFocus={onFocus}
          onEditorBlur={onBlur}
        />
      );
    case FormComponentType.INPUT_AREA:
      return (
        <TextArea
          style={{ fontFamily: "var(--font-monospace)" }}
          placeholder={formItem.placeholder}
        />
      );
    case FormComponentType.CODE_EDITOR: {
      const mode =
        EDITOR_MODE_LANGUAGE[formItem.language ?? EditorLanguage.TEXT_BINDING];
      // Ant design uses 24px margins in forms, plus 2px for the borders
      const wrapperHeight = wrapperRect
        ? `${wrapperRect.height - 26}px`
        : undefined;
      return (
        <CodeEditor
          data-test={`code-${formItem.name}`}
          showLineNumbers={true}
          mode={mode}
          key={mode}
          tabBehaviour={TabBehaviour.INDENT}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          borderLess={true}
          placeholder={formItem.placeholder}
          height={wrapperHeight}
          minHeight={wrapperHeight}
          maxHeight={wrapperHeight ?? "350px"}
        />
      );
    }
    case FormComponentType.FIELD_LIST: {
      return (
        <FieldList
          name={formItem.name}
          disabledPairCount={formItem.disabledPairCount}
          isDynamic={false}
        />
      );
    }
    case FormComponentType.DYNAMIC_FIELD_LIST: {
      return (
        <FieldList
          name={formItem.name}
          disabledPairCount={formItem.disabledPairCount}
          isDynamic={true}
        />
      );
    }
    case FormComponentType.CHECKBOX:
      return <Checkbox defaultChecked={false} />;
    case FormComponentType.DROPDOWN:
      return (
        <Dropdown
          options={formItem.options}
          data-test={`dropdown-${formItem.name}`}
        />
      );
    case FormComponentType.DYNAMIC_DROPDOWN:
      return (
        <DynamicDropdown
          fetchOptions={formItem.fetchOptions}
          extraOptions={formItem.extraOptions}
          dataTest={`dynamic-dropdown-${formItem.name}`}
        />
      );
    case FormComponentType.SWITCH:
      return <Switch defaultChecked={false} />;
    case FormComponentType.ALERT:
      return (
        <Alert
          message={
            <Markdown linkTarget="_blank">
              {formItem.messageTemplate as string}
            </Markdown>
          }
        />
      );
  }
};

export function DynamicFormItem({ formItem, wrapperRect }: Props) {
  const isCheckbox = [
    FormComponentType.CHECKBOX,
    FormComponentType.SWITCH,
  ].includes(formItem.componentType);

  const isDropdown =
    formItem.componentType === FormComponentType.DROPDOWN ||
    formItem.componentType === FormComponentType.DYNAMIC_DROPDOWN;
  const [showEntityACReminder, setShowEntityACReminder] = useState(false);

  return (
    <StyledFormItem
      label={
        formItem.label.length && (
          <Row justify="space-between">
            <Typography.Text>
              {formItem.label}
              {formItem.tooltip && (
                <>
                  {" "}
                  <Tooltip
                    placement="topLeft"
                    title={
                      <Markdown linkTarget="_blank">
                        {formItem.tooltip.markdownText}
                      </Markdown>
                    }
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </>
              )}
            </Typography.Text>
            {showEntityACReminder && (
              <Typography.Text type="secondary">{`${getEntityACShortcutString()} for variables`}</Typography.Text>
            )}
          </Row>
        )
      }
      isInline={isCheckbox}
      name={formItem.name}
      initialValue={formItem.initialValue}
      valuePropName={isCheckbox ? "checked" : "value"}
      trigger={isDropdown || isCheckbox ? "onChange" : "onBlur"}
      validateTrigger={isDropdown || isCheckbox ? "onChange" : "onBlur"}
      rules={formItem.rules}
    >
      {getItemComponent({
        formItem,
        wrapperRect,
        onFocus: () => setShowEntityACReminder(true),
        onBlur: () => setShowEntityACReminder(false),
      })}
    </StyledFormItem>
  );
}
