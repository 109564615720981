import { Dimension } from "@superblocksteam/shared";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetLayoutProps } from "legacy/widgets/shared";
import type { AutoHeightWidget } from "./Widget";
import type { WidgetProps } from "legacy/widgets";

export function sortByYX(widgets: AutoHeightWidget[]) {
  widgets.sort((a, b) => {
    if (a.y !== b.y) {
      return a.y - b.y;
    } else if ((a.yIndex ?? 0) !== (b.yIndex ?? 0)) {
      return (a.yIndex ?? 0) - (b.yIndex ?? 0);
    } else if (a.x !== b.x) {
      return a.x - b.x;
    } else {
      return a.id.localeCompare(b.id);
    }
  });
  return widgets;
}

export const getWidgetAHFromProps = (
  widget: WidgetProps | FlattenedWidgetLayoutProps,
  ignoreFitContentConstraints = false,
): number => {
  if (widget.height.mode === "fillParent") {
    const minHeight =
      widget.minHeight ??
      Dimension.gridUnit(GridDefaults.FILL_PARENT_DEFAULT_MIN_HEIGHT);
    return Dimension.toPx(minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
      .value;
  }

  if (widget.height.mode === "fitContent" && !ignoreFitContentConstraints) {
    const minHeightPx = Dimension.toPx(
      widget.minHeight ?? Dimension.gridUnit(0),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).value;
    const height = Math.max(
      Dimension.toPx(widget.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value,
      minHeightPx,
    );
    return Dimension.toPx(
      Dimension.px(height),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).value;
  }

  return Dimension.toPx(widget.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
    .value;
};

export const toAHWidget = (widget: WidgetProps): AutoHeightWidget => {
  return {
    x: widget.left.value,
    y: Dimension.toPx(widget.top, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value,
    width: widget.width.value,
    // we ignore minheight here even if the mode is fitContent because we want the DSL value to update
    // when this gets called from compositeReflow
    height: getWidgetAHFromProps(widget, true),
    id: widget.widgetId,
  };
};

export const getBottomPadding = (height: number, roundToGrid: number) => {
  let bottomPadding = 0;
  if (roundToGrid > 0) {
    // We want to round up to the nearest grid unit so we use bottomPadding
    const gridHeight = Math.ceil(height / roundToGrid) * roundToGrid;
    bottomPadding = gridHeight - height;
  }
  return bottomPadding;
};
