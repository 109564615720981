import { Classes } from "@blueprintjs/core";
import { css } from "styled-components";
import * as FontFamilies from "./Fonts";
import { LegacyNamedColor, LegacyNamedColors } from "./LegacyNamedColors";

type FontFamily = (typeof FontFamilies)[keyof typeof FontFamilies];

export enum Skin {
  LIGHT,
  DARK,
}

export const INVISIBLE_OPACITY = 0.6;

export const innerInvisible = css`
  && > * {
    opacity: ${INVISIBLE_OPACITY};
  }
`;

export const BlueprintInputTransform = css`
  && {
    .${Classes.INPUT} {
      border-radius: ${(props) => props.theme.legacy.radii[1]}px;
      box-shadow: none;
      border: ${(props) =>
        getBorderCSSShorthand(props.theme.legacy.borders[2])};

      &:focus {
        border: ${(props) =>
          getBorderCSSShorthand(props.theme.legacy.borders[2])};
        box-shadow: none;
      }
    }
  }
`;

type ThemeBorder = {
  thickness: number;
  style: "dashed" | "solid";
  color: LegacyNamedColor;
};

export type Theme = {
  explorerClosedWidth: number;
  radii: Array<number>;
  fontSizes: Array<number>;
  spaces: Array<number>;
  fontWeights: Array<number>;
  colors: BaseColorType & ThemeColorType;
  typography: TypographyType;
  lineHeights: Array<number>;
  fonts: {
    code: FontFamily;
    text: FontFamily;
  };
  borders: ThemeBorder[];
  evaluatedValuePopup: {
    width: number;
    height: number;
  };
  headerHeight: string;
  freeTrialBannerHeight: string;
  agentDisasterHeaderHeight: string;
  sidebarWidth: number;
  wideSidebarWidth: number;
  profilesSidebarWidth: number;
  pageNavWidth: number;
  canvasPadding: string;
  iconSizes: IconSizeType;
};

type IconSizeType = {
  XXS: number;
  XS: number;
  SMALL: number;
  MEDIUM: number;
  LARGE: number;
  XL: number;
  XXL: number;
  XXXL: number;
};

const getBorderCSSShorthand = (border?: ThemeBorder): string => {
  const values: string[] = [];
  if (border) {
    for (const [key, value] of Object.entries(border)) {
      values.push(key === "thickness" ? value + "px" : value.toString());
    }
  }
  return values.join(" ");
};

type darkShades = [
  "#1A191C",
  "#232324",
  "#262626",
  "#2B2B2B",
  "#404040",
  "#6D6D6D",
  "#9F9F9F",
  "#D4D4D4",
  "#E9E9E9",
  "#FFFFFF",
];

const lightShades = [
  "#FAFAFA",
  "#F7F7F7",
  "#F0F0F0",
  "#E8E8E8",
  "#C5C5C5",
  "#A9A7A7",
  "#939090",
  "#716E6E",
  "#4B4848",
  "#302D2D",
  "#090707",
  "#FFFFFF",
] as const;

type ShadeColor =
  | darkShades[number]
  | (typeof lightShades)[number]
  | LegacyNamedColor;

type buttonVariant = {
  main: string;
  light: string;
  dark: string;
  darker: string;
  darkest: string;
};

type BaseColorType = {
  main: string;
  background: string;
  appBackground: string;
  primaryOld: string;
  primaryDarkest: string;
  error: string;
  infoOld: string;
  inputActiveBorder: string;
  textDefault: string;
  textOnDarkBG: string;
  paneCard: string;
  paneBG: string;
  paneText: string;
  paneSectionLabel: string;
  bodyBG: string;
  widgetDashedBoundary: string;
  bindingTextDark: string;
  bindingText: string;
};

type ThemeColorType = {
  danger: buttonVariant;
  homepageBackground: string;
  text: {
    normal: ShadeColor;
    heading: ShadeColor;
    hightlight: ShadeColor;
  };
  icon: {
    normal: ShadeColor;
    hover: ShadeColor;
    active: ShadeColor;
  };
  textInput: {
    normal: {
      text: ShadeColor;
    };
  };
  editableText: {
    dangerBg: string;
  };
  spinner: ShadeColor;
  toast: {
    undo: string;
    warningColor: string;
    dangerColor: string;
    textColor: string;
    bg: ShadeColor;
  };
};

type FontDescription = {
  fontSize: number;
  fontStyle?: string;
  fontWeight: number | "normal";
  lineHeight: number;
  letterSpacing?: number | "normal";
};

type TypographyType = {
  h4: FontDescription;
  h5: FontDescription;
  h6: FontDescription;
  p1: FontDescription;
  p2: FontDescription;
};

const light: ThemeColorType = {
  danger: {
    main: "#F22B2B",
    light: "#C60707",
    dark: "#B90707",
    darker: "#FFF0F0",
    darkest: "#FDE4E4",
  },
  homepageBackground: "#fafafa",
  text: {
    normal: lightShades[8],
    heading: lightShades[9],
    hightlight: lightShades[11],
  },
  icon: {
    normal: lightShades[4],
    hover: lightShades[8],
    active: lightShades[9],
  },
  textInput: {
    normal: {
      text: lightShades[9],
    },
  },
  editableText: {
    dangerBg: "rgba(242, 43, 43, 0.06)",
  },
  spinner: lightShades[6],
  toast: {
    undo: "#27BBFF",
    warningColor: "#DCAD00",
    dangerColor: "#F22B2B",
    textColor: "#F7F7F7",
    bg: lightShades[10],
  },
};

export const theme: Theme = {
  explorerClosedWidth: 40,
  radii: [0, 2, 4, 8, 10, 20, 50],
  fontSizes: [0, 10, 12, 14, 16, 18, 24, 28, 32, 48, 64],
  spaces: [
    0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 38, 40, 42, 44,
  ],
  fontWeights: [0, 400, 500, 700],
  typography: {
    h4: {
      fontSize: 16,
      lineHeight: 21,
      letterSpacing: -0.24,
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      lineHeight: 19,
      letterSpacing: -0.24,
      fontWeight: 500,
    },
    h6: {
      fontSize: 12,
      lineHeight: 14,
      letterSpacing: 0.8,
      fontWeight: 500,
    },
    p1: {
      fontSize: 14,
      lineHeight: 19,
      letterSpacing: -0.24,
      fontWeight: "normal",
    },
    p2: {
      fontSize: 13,
      lineHeight: 17,
      letterSpacing: -0.24,
      fontWeight: "normal",
    },
  },
  iconSizes: {
    XXS: 8,
    XS: 10,
    SMALL: 12,
    MEDIUM: 14,
    LARGE: 15,
    XL: 16,
    XXL: 18,
    XXXL: 20,
  },
  evaluatedValuePopup: {
    width: 300,
    height: 500,
  },
  colors: {
    main: LegacyNamedColors.PURPLE,
    background: LegacyNamedColors.WHITE,
    appBackground: "#F7F8FA",
    primaryOld: LegacyNamedColors.PURPLE,
    primaryDarkest: LegacyNamedColors.JUNGLE_GREEN_DARKER,
    error: LegacyNamedColors.RED,
    infoOld: LegacyNamedColors.SLATE_GRAY,
    inputActiveBorder: LegacyNamedColors.HIT_GRAY,
    textDefault: LegacyNamedColors.BLACK,
    textOnDarkBG: LegacyNamedColors.WHITE,
    paneCard: LegacyNamedColors.WHITE,
    paneBG: LegacyNamedColors.WHITE,
    paneText: LegacyNamedColors.BLACK,
    paneSectionLabel: LegacyNamedColors.GRAY,
    bodyBG: LegacyNamedColors.ATHENS_GRAY,
    widgetDashedBoundary: LegacyNamedColors.GRAY_LIGHT,
    bindingTextDark: LegacyNamedColors.BINDING_COLOR,
    bindingText: LegacyNamedColors.BINDING_COLOR_LT,
    ...light,
  },
  lineHeights: [0, 14, 16, 18, 22, 24, 28, 36, 48, 64, 80],
  fonts: {
    text: FontFamilies.TextFonts,
    code: FontFamilies.CodeFonts,
  },
  borders: [],
  sidebarWidth: 320,
  wideSidebarWidth: 440,
  profilesSidebarWidth: 200,
  pageNavWidth: 240,
  headerHeight: "40px",
  freeTrialBannerHeight: "28px",
  agentDisasterHeaderHeight: "35px",
  canvasPadding: "0 0 0 0",
};

export const scrollbarLight = css<{ backgroundColor?: LegacyNamedColor }>`
  scrollbar-color: ${(props) => props.theme.legacy.colors.inputActiveBorder};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.legacy.colors.bodyBG};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.legacy.colors.inputActiveBorder};
  }
`;

export const scrollbarLightCss = `
  scrollbar-color: ${theme.colors.inputActiveBorder};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.colors.bodyBG};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.inputActiveBorder};
  }
`;
