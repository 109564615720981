import { QuestionCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { FormItemTooltip, TooltipIconType } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React from "react";
import { StyledReactMarkdown } from "./DynamicFormItem";

interface Props {
  tooltip?: FormItemTooltip;
}

export const FormTooltip = ({ tooltip }: Props) => {
  return tooltip ? (
    <>
      {" "}
      <Tooltip
        placement="right"
        title={
          <StyledReactMarkdown linkTarget="_blank">
            {tooltip.markdownText}
          </StyledReactMarkdown>
        }
      >
        {tooltip.iconType === TooltipIconType.WARNING ? (
          <WarningTwoTone />
        ) : (
          <QuestionCircleTwoTone />
        )}
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
