import { DropdownOption } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { colors } from "../../../../styles/colors";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";

interface DropdownProps {
  options?: DropdownOption[];
  showSearch?: boolean;
  optionFilterProp?: string;
  ["data-test"]?: string;
  renderSelectedOptionWithStyles?: boolean;
}

type Props = DropdownProps & DynamicFormItemProps;

export const DynamicFormDropdown = ({
  path,
  label,
  options,
  placeholder,
  initialValue,
  loading,
  rules,
  isNew,
  immutable,
  renderSelectedOptionWithStyles,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const onChange = useCallback(
    (val: DropdownOption) => {
      context.onChange(path, val.value); // when user updates the value
    },
    [path, context],
  );
  const [value, setValue] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) => {
      setValue(value as string); //update UI
    });
    return () => unsubscribe();
  }, [path, context.subscribe, context]);

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [
    path,
    rules,
    context,
    context.registerValidation,
    context.unregisterValidation,
  ]);

  if (otherProps.hidden) {
    return <></>;
  } else {
    return (
      <div>
        <label>
          <FormLabel hidden={otherProps.hidden}>{label}</FormLabel>
          <FormTooltip tooltip={otherProps.tooltip} />
          <RecommendedSingleDropdown
            data-test={otherProps["data-test"]}
            value={value ?? initialValue}
            onChange={onChange}
            options={options ?? []}
            // Disabled when:
            // - User doesn't have permissions at all
            // - The dropdown is part of an unsaved integration that is read-only after save (such as gsheets)
            disabled={otherProps.disabled || (!isNew && immutable)}
            renderSelectedOptionWithStyles={renderSelectedOptionWithStyles}
          />
        </label>
        <Typography.Text type="danger">{validationMessage}</Typography.Text>
        <div style={{ color: colors.GREY_400 }}>
          <StyledReactMarkdown linkTarget="_blank">
            {otherProps.subtitle ?? ""}
          </StyledReactMarkdown>
        </div>
      </div>
    );
  }
};
