import { Agent, ENVIRONMENT_ALL } from "@superblocksteam/shared";
import { isEmpty } from "lodash";

export const isProfileSupported = (agent: Agent, targetEnvironment: string) => {
  const supportedProfiles = agent.tags?.profile ?? [];

  // If the agent has a wildcard profile tag, it supports executions for all profiles.
  // Example of agent tags that satisfy this condition: { profile: ["*"] }
  if (supportedProfiles.includes(ENVIRONMENT_ALL)) {
    return true;
  }

  // If the agent has no profile tag, check if the legacy agent environment supports the target profile.
  // Otherwise, check if the target profile is explicitly specified in the agent's profile tag.
  if (isEmpty(supportedProfiles)) {
    return (
      agent.environment === ENVIRONMENT_ALL ||
      agent.environment === targetEnvironment
    );
  } else {
    return supportedProfiles.includes(targetEnvironment);
  }
};
