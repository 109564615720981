import { NumericInput } from "@blueprintjs/core";
import { Button, Dropdown, Tooltip } from "antd";
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Column,
  usePagination,
  useSortBy,
  useTable,
  UseSortByColumnOptions,
  useExpanded,
  useFlexLayout,
} from "react-table";
import { ReactComponent as ArrowDesc } from "assets/icons/common/arrow-down.svg";
import { ReactComponent as ChevronLeft } from "assets/icons/common/chevron-left-md.svg";
import { ReactComponent as EditIcon } from "assets/icons/common/dotdotdot.svg";
import { usePrevious } from "hooks/ui";
import { scrollbarLightCss } from "legacy/constants/DefaultTheme";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { Checkbox } from "./Checkbox";
import { CollapseSectionButton } from "./CollapseSectionButton";
import { Spinner } from "./Spinner";

const TABLE_ROW_HEIGHT = 48;
const TABLE_HEADER_HEIGHT = 40;
export const DEFAULT_TABLE_PAGE_SIZE = 15;

const TableWrapper = styleAsClass`
  border-radius: 4px;
  border: 1px solid ${colors.GREY_100};
  overflow: clip;
`;

// wrapper on table element, execluding pagination element
const TableContentWrapper = styleAsClass`
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollbarLightCss}
`;

const TableStyles = styleAsClass`
  width: 100%;
  font-size: 12px;
  th > .header-cell {
    width: 100%;
    display: flex;
    text-align: left;
    color: ${colors.GREY_700};
    font-weight: 500;
    align-items: center;
    height: ${`${TABLE_HEADER_HEIGHT}px`};
    white-space: nowrap;
  }
  tr {
    width: 100%;
    border-bottom: 1px solid ${colors.GREY_100};
  }
  th, td {
    padding: 0px 16px;
    white-space: nowrap;
  }
`;

const RowStyle = styleAsClass`
  transition: background-color 0.2s ease-in-out;
  height: ${`${TABLE_ROW_HEIGHT}px`};
  &:hover {
    background-color: ${colors.GREY_25};
  }

  .action-button-wrapper {
    display: flex;
  }

  &:hover .action-button-wrapper button {
    opacity: 1;
  }

  .action-button-wrapper button {
    opacity: 0;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;

    border: 1px solid transparent;
    background: transparent;
    box-shadow: 0px 0px 0px;

    &:hover, &[data-focused=true] {
      opacity: 1;
      border-color: ${colors.GREY_100};
      background: ${colors.GREY_25};
    }
    
    &:active {
      background: ${colors.GREY_50};
    }

    &[data-focused=false] {
      border-color: "transparent";
    }
  }
`;

const UnclickableRowStyle = styleAsClass`
  &:hover {
    background-color: transparent;
  }
  cursor: default;
`;

const HeaderRowStyle = styleAsClass`
  height: ${`${TABLE_HEADER_HEIGHT}px`};
  cursor: default;
`;

const PaginationWrapper = styleAsClass`
  display: flex;
  position: relative;
  height: 48px;
  align-items: center;
  justify-content: center;
`;

const PaginationRecords = styleAsClass`
  font-size: 12px;
  color: ${colors.GREY_500};
  padding-left: 16px;
  position: absolute;
  left: 0px;
`;

const NumericInputStyle = styleAsClass`
  &&& input {
    width: 44px;
    height: 28px;
    padding: 0 !important;
    text-align: center;
    border-width: 0px;
    box-shadow: none;
    border: 1px solid ${colors.GREY_100};
    font-size: 12px;
  }
  margin: 0 8px;
`;

const PageInputWrapper = styleAsClass`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.GREY_500};
  margin: 0 4px;
  white-space: nowrap;
`;

const PageArrow = styleAsClass`
  box-sizing: border-box;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;

  cursor: pointer;
  svg {
    width: 18px;
    height: 24px;
  }
  svg path, {
    stroke: ${colors.GREY_500};
  }

  &[data-disabled="true"] {
    pointer-events: none;
    cursor: not-allowed;
    svg path {
      stroke: ${colors.GREY_300};
    }
  }

  &:hover {
    svg path {
      stroke: ${colors.GREY_700};
    }
  }
`;

const EmptyResults = styleAsClass`
  font-size: 12px;
  color: ${colors.GREY_500};
  padding: 16px;
  text-align: center;
  min-height: ${`${TABLE_ROW_HEIGHT}px`};
  border-radius: 4px;
  border-bottom: 1px solid ${colors.GREY_100}
`;

export const TagStyle = styleAsClass`
  height: 24px;
  border-radius: 32px;
  border: 1px solid ${colors.GREY_200};
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  width: fit-content;
  font-weight: 500;
  white-space: nowrap;
`;

const getFixedWidthStyles = ({
  isHeader,
  width,
}: {
  isHeader: boolean;
  width: undefined | number | string;
}) => {
  return {
    display: "inline-flex",
    // height - 1 to fix tr height larger than td because of border issue
    height: isHeader ? `${TABLE_HEADER_HEIGHT}px` : `${TABLE_ROW_HEIGHT - 1}px`,
    alignItems: "center",
    width,
  };
};

const DEFAULT_PAGE_SIZE = 10;

type ActionMenuItem = {
  key: string;
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
};

const ActionCell = <ItemType extends object>({
  menuItems,
  rowValue,
  uniqueKey,
}: {
  menuItems: (rowValue: ItemType) => ActionMenuItem[];
  rowValue: ItemType;
  uniqueKey: keyof ItemType | undefined;
}) => {
  const [focused, setFocused] = useState(false);
  const stopPropagation = useCallback((e: any) => {
    e?.stopPropagation();
  }, []);

  const menuItemsConverted = useMemo(() => {
    const items = menuItems?.(rowValue);
    return items?.map((item, index) => ({
      ...item,
      label: item.tooltip ? (
        <Tooltip title={item.tooltip}>{item.label}</Tooltip>
      ) : (
        item.label
      ),
      onClick: () => {
        item.onClick?.();
        setFocused(false);
      },
      style: { fontSize: "12px" },
    }));
  }, [menuItems, rowValue]);

  return menuItemsConverted.length > 0 ? (
    <div className="action-button-wrapper" onClick={stopPropagation}>
      <Dropdown
        menu={{ items: menuItemsConverted }}
        trigger={["click"]}
        onOpenChange={setFocused}
      >
        <Button
          icon={<EditIcon />}
          data-focused={focused}
          data-test={`table-action-button-${
            uniqueKey ? rowValue?.[uniqueKey] : ""
          }`}
        />
      </Dropdown>
    </div>
  ) : null;
};

type WithAction = {
  tableAction?: any;
};

type WithChildren = {
  children?: Array<any>;
};

type WithSelected = {
  isMultiSelectSelected?: any;
};

export type WithCustomRender<ItemType extends object> = {
  renderRow?: (row: ItemType) => React.ReactNode;
};

export type RecColumn<ItemType extends object> = Column<ItemType> & {
  style?: React.CSSProperties;
  hidden?: boolean;
} & UseSortByColumnOptions<ItemType>;

interface TableProps<ItemType extends object> {
  columns: RecColumn<ItemType>[];
  data: ItemType[];
  dataTest?: string;
  dataLabel?: string; // data label for empty result
  actionMenuItems?: (row: ItemType) => ActionMenuItem[];
  paginationOptions?:
    | {
        pageSize: number;
      }
    | undefined;
  currentPage?: number;
  onRowClick?: (row: ItemType) => void;
  rowClickable?: (row: ItemType) => void;
  uniqueKey?: keyof ItemType;
  className?: string;
  rowClassName?: string;
  loading?: boolean;
  autoResetPage?: boolean;
  canExpandRows?: boolean;
  expandedState?: Record<string, boolean>;
  useFixedColumnWidths?: boolean;
  hideHeader?: boolean;
  onRowExpand?: (row: ItemType, isExpanded: boolean) => void;
  enableMultiSelect?: boolean;
  onMultiRowsSelectChange?: (selectedRows: ItemType[]) => void;
  rowSelectable?: (row: ItemType) => boolean;
  getRowSelectableTooltip?: (row: ItemType) => string;
}

export const ACTION_MENU_WIDTH = 64;

export type RecommendedTableRef<ItemType extends object> = {
  setSelectedRows: (
    newSelectedRows: Record<string, ItemType & { rowIndex: number }>,
  ) => void;
};

const RecommendedTable = <ItemType extends object>(
  props: TableProps<ItemType>,
  ref?: Ref<RecommendedTableRef<ItemType>>,
) => {
  const {
    data,
    dataLabel = "records",
    dataTest,
    columns,
    paginationOptions,
    actionMenuItems,
    onRowClick,
    rowClickable,
    uniqueKey,
    className,
    rowClassName,
    loading,
    autoResetPage = false,
    canExpandRows,
    expandedState,
    useFixedColumnWidths,
    onRowExpand,
    enableMultiSelect,
    onMultiRowsSelectChange,
    rowSelectable,
    getRowSelectableTooltip,
    hideHeader,
  } = props;
  const [selectedRows, setSelectedRows] = useState<
    Record<string, ItemType & { rowIndex: number }>
  >({});

  useImperativeHandle(ref, () => ({
    setSelectedRows,
  }));

  const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);

  const toggleRowSelection = useCallback(
    (row: ItemType, rowIndex: number, shiftKey: boolean) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = { ...prevSelectedRows };
        const rowId = row[uniqueKey as keyof ItemType] as string;

        if (shiftKey && lastClickedIndex !== null) {
          const start = Math.min(lastClickedIndex, rowIndex);
          const end = Math.max(lastClickedIndex, rowIndex);
          for (let i = start; i <= end; i++) {
            const rowData = data[i];
            const id = rowData[uniqueKey as keyof ItemType] as string;
            newSelectedRows[id] = { ...rowData, rowIndex };
          }
        } else {
          if (rowId in newSelectedRows) {
            delete newSelectedRows[rowId];
          } else {
            newSelectedRows[rowId] = { ...row, rowIndex };
          }
        }

        setLastClickedIndex(rowIndex);

        onMultiRowsSelectChange?.(
          Array.from(Object.values(newSelectedRows)).sort(
            (a, b) => a.rowIndex - b.rowIndex,
          ),
        );

        return newSelectedRows;
      });
    },
    [lastClickedIndex, onMultiRowsSelectChange, data, uniqueKey],
  );

  const selectableRows = useMemo(() => {
    return rowSelectable ? data.filter(rowSelectable) : data;
  }, [data, rowSelectable]);

  const columnsToRender = useMemo(() => {
    const selectedRowsLength = enableMultiSelect
      ? Object.keys(selectedRows).length
      : 0;
    const columnsWithActions: RecColumn<ItemType>[] = actionMenuItems
      ? [
          ...(enableMultiSelect
            ? [
                {
                  Header: () => (
                    <Checkbox
                      checked={
                        selectedRowsLength === selectableRows.length &&
                        selectedRowsLength > 0
                      }
                      partialChecked={
                        selectedRowsLength > 0 &&
                        selectedRowsLength < selectableRows.length
                      }
                      onClick={() => {
                        if (selectedRowsLength === selectableRows.length) {
                          setSelectedRows({});
                          onMultiRowsSelectChange?.([]);
                        } else {
                          const allRows = selectableRows.reduce(
                            (acc, row, index) => {
                              const rowId = row[
                                uniqueKey as keyof ItemType
                              ] as string;
                              acc[rowId] = { ...row, rowIndex: index };
                              return acc;
                            },
                            {} as Record<
                              string,
                              ItemType & { rowIndex: number }
                            >,
                          );
                          setSelectedRows(allRows);
                          onMultiRowsSelectChange?.(Object.values(allRows));
                        }
                      }}
                    />
                  ),
                  accessor: "isMultiSelectSelected" as keyof (ItemType &
                    WithSelected),
                  Cell: ({ cell }: any) => {
                    const isSelectable =
                      rowSelectable?.(cell.row.original) !== false;
                    const tooltipMessage = getRowSelectableTooltip?.(
                      cell.row.original,
                    );
                    return (
                      <Checkbox
                        disabled={!isSelectable}
                        checked={
                          (cell.row.original[
                            uniqueKey as keyof ItemType
                          ] as string) in selectedRows
                        }
                        onClick={(e) =>
                          toggleRowSelection(
                            cell.row.original,
                            cell.row.index,
                            e.shiftKey,
                          )
                        }
                        tooltipTitle={tooltipMessage}
                      />
                    );
                  },
                  width: ACTION_MENU_WIDTH,
                  style: { width: "16px" },
                  disableSortBy: true,
                } as RecColumn<ItemType>,
              ]
            : []),
          ...columns,
          {
            Header: "",
            accessor: "tableAction" as keyof (ItemType & WithAction),
            Cell: ({ cell }: any) => {
              return (
                <ActionCell<ItemType>
                  menuItems={actionMenuItems}
                  rowValue={cell.row.original}
                  uniqueKey={uniqueKey}
                />
              );
            },
            width: ACTION_MENU_WIDTH,
            style: { width: "16px" },
            disableSortBy: true,
          } as RecColumn<ItemType>,
        ]
      : columns;
    if (canExpandRows && columnsWithActions.length > 0) {
      const firstColumn = columnsWithActions[enableMultiSelect ? 1 : 0];
      const originalCell: any =
        "Cell" in firstColumn && firstColumn.Cell
          ? firstColumn.Cell
          : ({ value }: { value: unknown }) => String(value);

      columnsWithActions[enableMultiSelect ? 1 : 0] = {
        ...firstColumn,
        Cell: (cellProps: any) => {
          const onExpand = () => {
            cellProps.row.getToggleRowExpandedProps().onClick();
            onRowExpand?.(cellProps.row.original, !cellProps.row.isExpanded);
          };
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {cellProps.row.canExpand ? (
                <CollapseSectionButton
                  onClick={onExpand}
                  isCollapsed={!cellProps.row.isExpanded}
                />
              ) : (
                <span style={{ width: "16px", marginRight: "5px" }}></span>
              )}
              {typeof originalCell === "function"
                ? originalCell(cellProps)
                : originalCell}
            </div>
          );
        },
      };
    }
    return columnsWithActions;
  }, [
    actionMenuItems,
    enableMultiSelect,
    columns,
    canExpandRows,
    selectedRows,
    selectableRows,
    onMultiRowsSelectChange,
    uniqueKey,
    toggleRowSelection,
    onRowExpand,
    rowSelectable,
    getRowSelectableTooltip,
  ]) as RecColumn<
    ItemType & WithAction & WithSelected & WithCustomRender<ItemType>
  >[];

  const hiddenColumns = useMemo(
    () =>
      columnsToRender
        .filter((column) => column.hidden)
        .map((column) => column.accessor) ?? [],
    [columnsToRender],
  ) as string[];

  const hooks = useMemo(() => {
    const hooks: Array<any> = [useSortBy];
    if (canExpandRows) {
      hooks.push(useExpanded);
    }
    if (useFixedColumnWidths) {
      hooks.push(useFlexLayout);
    }
    hooks.push(usePagination);
    return hooks;
  }, [canExpandRows, useFixedColumnWidths]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    state: { pageIndex },
    setPageSize,
  } = useTable<
    ItemType & WithAction & WithChildren & WithCustomRender<ItemType>
  >(
    {
      columns: columnsToRender as Column<
        ItemType & WithAction & WithChildren & WithCustomRender<ItemType>
      >[],
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: paginationOptions?.pageSize ?? DEFAULT_PAGE_SIZE,
        hiddenColumns,
        expanded: expandedState,
      },
      autoResetExpanded: false,
      // if set to false, it will prevent reset page num when add/remove item (undefined is same as true)
      autoResetPage,
      getSubRows: (row) => row.children ?? [],
    },
    ...hooks,
  );

  const prevExpandedState = usePrevious(expandedState);
  useEffect(() => {
    if (canExpandRows && expandedState && prevExpandedState !== expandedState) {
      rows.forEach((row) => {
        const id = row.original[
          uniqueKey ?? ("id" as keyof ItemType)
        ] as string;
        const shouldExpand = Boolean(expandedState[id]);
        if (row.canExpand) {
          row.toggleRowExpanded(shouldExpand);
        }
      });
    }
  }, [expandedState, prevExpandedState, rows, uniqueKey, canExpandRows]);

  useEffect(() => {
    // data.length could be 0 that caused some issues.
    setPageSize(paginationOptions?.pageSize ?? DEFAULT_PAGE_SIZE);
  }, [paginationOptions?.pageSize, setPageSize]);

  const [inputPageNo, setInputPageNo] = React.useState<number>(pageIndex + 1);
  useEffect(() => {
    setInputPageNo(pageIndex + 1);
  }, [pageIndex]);

  useEffect(() => {
    if (
      paginationOptions?.pageSize &&
      data.length <= pageIndex * paginationOptions.pageSize
    ) {
      gotoPage(0);
    }
  }, [data.length, paginationOptions?.pageSize, pageIndex, gotoPage]);

  const tableHeight = paginationOptions
    ? paginationOptions.pageSize * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT + 1
    : undefined;

  const emtpyResultHeight = paginationOptions
    ? paginationOptions.pageSize * TABLE_ROW_HEIGHT
    : undefined;

  return (
    <div
      className={`${TableWrapper} ${className} table-wrapper`}
      style={paginationOptions ? undefined : { borderBottom: "0px" }}
      data-test={dataTest ?? `${dataLabel}-table`}
    >
      <Spinner spinning={loading ?? false}>
        <div
          className={TableContentWrapper}
          style={tableHeight ? { height: tableHeight } : {}}
        >
          <table {...getTableProps()} className={TableStyles}>
            {!hideHeader && (
              <thead>
                {headerGroups.map((headerGroup, headerGroupIndex) => (
                  <tr
                    className={`${HeaderRowStyle} ${rowClassName ?? ""}`}
                    {...headerGroup.getHeaderGroupProps()}
                    key={`headerGroup-${headerGroup.id}`}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      let style = (column as RecColumn<ItemType>).style;
                      if (useFixedColumnWidths) {
                        style = getFixedWidthStyles({
                          isHeader: true,
                          width: column.width,
                        });
                      }
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          key={`header-${column.id}`}
                          style={style}
                        >
                          <div className="header-cell">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDesc stroke="#6C7689" />
                              ) : (
                                <ArrowDesc
                                  stroke="#6C7689"
                                  style={{ transform: "rotate(180deg)" }}
                                />
                              )
                            ) : (
                              ""
                            )}
                            {column.render("Header")}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {(paginationOptions ? page : rows).map((row, rowIndex) => {
                prepareRow(row);
                const clickable = rowClickable
                  ? rowClickable?.(row.original)
                  : Boolean(onRowClick);

                if (row.original.renderRow) {
                  return row.original.renderRow(row.original);
                }

                return (
                  <tr
                    className={`table-row ${RowStyle} ${rowClassName ?? ""} ${
                      clickable ? "" : UnclickableRowStyle
                    }`}
                    {...row.getRowProps()}
                    key={`row-${
                      uniqueKey !== undefined
                        ? row.original?.[uniqueKey]
                        : row.id
                    }`}
                    style={{
                      cursor: clickable ? "pointer" : "default",
                    }}
                    onClick={() => {
                      clickable && onRowClick?.(row.original);
                    }}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      let cellStyle = (cell.column as RecColumn<ItemType>)
                        .style;
                      if (useFixedColumnWidths) {
                        cellStyle = getFixedWidthStyles({
                          isHeader: false,
                          width: cell.column.width,
                        });
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`cell-${cell.column.id}`}
                          style={cellStyle}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data.length === 0 && (
            <div
              className={EmptyResults}
              style={{
                height: emtpyResultHeight,
                borderRadius: paginationOptions ? "0px" : "4px",
              }}
              data-test={`empty-results-${dataLabel}`}
            >
              {`No ${dataLabel} found`}
            </div>
          )}
        </div>
      </Spinner>
      {paginationOptions && (
        <div className={PaginationWrapper}>
          <div className={PaginationRecords}>{`${data.length} Records`}</div>
          <div
            className={PageArrow}
            data-disabled={pageIndex === 0 ? "true" : "false"}
            onClick={previousPage}
          >
            <ChevronLeft />
          </div>
          <div className={PageInputWrapper}>
            Page{" "}
            <NumericInput
              className={NumericInputStyle}
              value={inputPageNo}
              min={1}
              max={pageCount || 1}
              buttonPosition="none"
              clampValueOnBlur
              onValueChange={setInputPageNo}
              onBlur={() => gotoPage(inputPageNo - 1)}
            />{" "}
            of {pageCount}
          </div>
          <div
            className={PageArrow}
            data-disabled={
              pageIndex === pageOptions.length - 1 ? "true" : "false"
            }
            onClick={nextPage}
          >
            <ChevronLeft style={{ transform: "rotate(180deg)" }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(RecommendedTable) as <ItemType extends object>(
  props: TableProps<ItemType> & { ref?: Ref<RecommendedTableRef<ItemType>> },
) => ReturnType<typeof RecommendedTable>;
