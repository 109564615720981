import { get } from "lodash";
import {
  PropertyPaneCategoryConfig,
  PropertyPaneConfig,
  PropertyPaneControlConfig,
  PropertyPaneSectionConfig,
} from "legacy/constants/PropertyControlConstants";
import { AllFlags } from "store/slices/featureFlags/models/Flags";
import { splitJSPath, stringifySplitPaths } from "utils/dottedPaths";
import { getParentPath } from "utils/dottedPaths";
import type { ApplicationScope } from "@superblocksteam/shared/src/types/application";
import type { ControlData } from "legacy/components/propertyControls/BaseControl";

export function isPropertyPaneControlConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneControlConfig<T> {
  return "propertyName" in value;
}
export function isPropertyPaneSectionConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneSectionConfig<T> {
  return "sectionName" in value;
}
export function isPropertyPaneCategoryConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneCategoryConfig<T> {
  return "categoryName" in value;
}

function findConfigForPathHelper(
  pathToFind: string,
  configToSearch: readonly PropertyPaneConfig<any>[],
  currentPath = "",
): PropertyPaneControlConfig<any> | undefined {
  let found;

  for (const section of configToSearch) {
    if (section.children) {
      for (const child of section.children) {
        if ("propertyName" in child && typeof child.propertyName === "string") {
          if (currentPath + child.propertyName === pathToFind) {
            return child;
          }

          if ("children" in child && Array.isArray(child.children)) {
            found = findConfigForPathHelper(
              pathToFind,
              child.children,
              currentPath + child.propertyName + ".",
            );
            if (found) {
              return found;
            }
          }

          if ("panelConfig" in child && child.panelConfig?.children) {
            found = findConfigForPathHelper(
              pathToFind,
              child.panelConfig.children,
              currentPath + child.propertyName + ".",
            );
            if (found) {
              return found;
            }
          }
        }
      }
    }
  }
  return found;
}

export function findConfigForPath(
  pathToFind: string,
  config: readonly PropertyPaneConfig<any>[],
) {
  // A primaryColumns path can contain the column name, which is not actually a part of the config,
  // so we might need to remove it. We can check first if the path is valid as is (event handlers, editing properties, etc)
  if (pathToFind.startsWith("primaryColumns")) {
    const possibleConfig = findConfigForPathHelper(pathToFind, config);
    if (possibleConfig) {
      return possibleConfig;
    }

    const [parent, columnName, ...rest] = splitJSPath(pathToFind) ?? [];
    if (parent && columnName) {
      pathToFind = stringifySplitPaths([parent, ...rest]);
    }
  }
  return findConfigForPathHelper(pathToFind, config);
}

export function filterOptions({
  options: originalOptions,
  optionsFunc,
  widgetProperties,
  propertyName,
  featureFlags,
  itemScope,
}: {
  options: PropertyPaneControlConfig["options"];
  optionsFunc?: PropertyPaneControlConfig["optionsFunc"];
  widgetProperties: ControlData["widgetProperties"];
  propertyName: string;
  featureFlags: Partial<AllFlags>;
  itemScope: ApplicationScope | undefined;
  selectedValue?: string;
}) {
  let options = originalOptions;

  if (optionsFunc) {
    const controlProps = get(
      widgetProperties,
      getParentPath(propertyName) || "",
      widgetProperties,
    );
    options = optionsFunc(controlProps, featureFlags, itemScope, options);
  }

  // Removed flagged values
  options = options?.filter((option) => {
    return !option.hidden || !option.hidden(option, featureFlags);
  });

  return options;
}
