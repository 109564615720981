import {
  GroupAction,
  OrganizationUserDto,
  PostGroupResponseDto,
  UserType,
} from "@superblocksteam/shared";
import {
  ErrorHandlingOptions,
  HttpMethod,
  callServer,
} from "store/utils/client";

export const getUsersRequest = (
  orgId: string,
  type?: UserType,
  errorOptions?: ErrorHandlingOptions,
) =>
  callServer<OrganizationUserDto[]>(
    {
      url: `/v1/organizations/${orgId}/users`,
      method: HttpMethod.Get,
      query: type ? { type } : undefined,
    },
    errorOptions,
  );

export const deactivateUserRequest = ({
  orgId,
  groupId,
  userEmail,
  errorOptions,
}: {
  orgId: string;
  groupId: string;
  userEmail: string;
  errorOptions?: ErrorHandlingOptions;
}) => {
  return callServer<PostGroupResponseDto>(
    {
      method: HttpMethod.Post,
      url: `v1/organizations/${orgId}/groups/${groupId}`,
      body: {
        operations: [{ email: userEmail, action: GroupAction.REMOVE_MEMBER }],
      },
    },
    errorOptions,
  );
};

export const getUserDetailsRequest = ({
  orgId,
  userId,
  errorOptions,
}: {
  orgId: string;
  userId: string;
  errorOptions?: ErrorHandlingOptions;
}) =>
  callServer<OrganizationUserDto>(
    {
      url: `/v1/organizations/${orgId}/users/${userId}`,
      method: HttpMethod.Get,
      query: { includeDeactivatedUser: "true" },
    },
    errorOptions,
  );
