import {
  FormComponentType,
  FormItem,
  FormRow,
  FormTemplate,
  InputDataType,
  isFormItem,
} from "@superblocksteam/shared";
import { produce } from "immer";

interface WorkflowIntegrationFormData {
  formTemplate: FormTemplate;
  dynamicPasswordFields?: Record<string, boolean>;
  showAllPasswordFieldsAsDynamic: boolean;
}

export const makeApplicableInputsDynamic = ({
  formTemplate,
  dynamicPasswordFields,
  showAllPasswordFieldsAsDynamic = true,
}: WorkflowIntegrationFormData) => {
  const sectionsToTransform = ["main"];
  const itemBlacklist = ["name"];

  return produce(formTemplate, (draftTemplate) => {
    const sectionsToModify = draftTemplate.sections.filter((section) =>
      sectionsToTransform.includes(section.name),
    );
    sectionsToModify.forEach((section) => {
      const newItems: (FormItem | FormRow)[] = [];
      for (const sectionItem of section.items) {
        const isRow = !isFormItem(sectionItem);
        const rowItems = isRow
          ? (sectionItem as FormRow).rowItems
          : [sectionItem];
        const dynamicRowItems = [];
        for (const item of rowItems) {
          const notBlacklisted = !itemBlacklist.includes(item.name);
          const isInputText =
            item.componentType === FormComponentType.INPUT_TEXT;
          const isPassword =
            isInputText && (item as any).dataType === InputDataType.PASSWORD;
          const isNumber =
            isInputText && (item as any).dataType === InputDataType.NUMBER;
          // Hidden fields should never be dynamic.
          const isHidden = item.hidden;
          const forcedStatic = (item as any).forcedStatic;
          let newItem = item;
          if (notBlacklisted && !isHidden && isInputText && !forcedStatic) {
            // Consider making this input text dynamic.

            if (isPassword) {
              if (
                showAllPasswordFieldsAsDynamic ||
                dynamicPasswordFields?.[item.name]
              ) {
                newItem = {
                  ...item,
                  showHideIcon: !showAllPasswordFieldsAsDynamic,
                  componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
                };
              }
              // otherwise, password/number field is not dynamic
            } else if (isNumber) {
              // don't make number fields dynamic.
            } else {
              newItem = {
                ...item,
                componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
              };
            }
          }

          dynamicRowItems.push(newItem);
        }
        if (isRow) {
          sectionItem.rowItems = dynamicRowItems;
          newItems.push(sectionItem);
        } else if (dynamicRowItems.length === 1) {
          newItems.push(dynamicRowItems[0]);
        }
      }
      section.items = newItems;
    });
  });
};
