import { ReferenceEdgeType, UsageEdgeType } from "analysis/types";
import { isAction } from "legacy/workers/evaluationUtils";
import getApiExecuteOnPageLoad from "store/slices/apisV2/utils/get-api-execute-on-pageload";
import { UsageContext } from "./UsageContext";
import { EdgeInfo } from "./types";

/**
 * There are 2 ways that APIs are known to be used
 * - 1. executeOnPageLoad is not explicitly set to false and they have an incoming BINDING edge from a used entity
 * - 2. They have an incoming EVENT edge from a used entity
 */
export const calculateApiUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;
  const apis = context.apis;

  const entity = referenceGraph.node(nodeId);
  if (!entity || !isAction(entity)) {
    throw new Error(`Expected entity to be action`);
  }
  const api = apis[entity.actionId];
  if (!api) {
    throw new Error(`API not found: ${entity.actionId}`);
  }

  const executeOnPageLoad = getApiExecuteOnPageLoad(api.apiPb);
  if (executeOnPageLoad === true) {
    return []; // this means that this API does not owe its usage to any other entity
  }
  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);
  const usageEdges: EdgeInfo[] = [];
  for (const predecessorId of usedPredecessors) {
    const hasBindingEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.BINDING,
    );
    const hasEventEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.EVENT,
    );
    if (hasBindingEdge && executeOnPageLoad !== false) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }
    if (hasEventEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.ACTIVATION,
      });
    }
  }

  return usageEdges;
};
