import { Collapse } from "@blueprintjs/core";
import React, { ReactNode } from "react";

const EntityCollapse = (props: {
  children: ReactNode;
  isOpen: boolean;
  step: number;
  active?: boolean;
}) => {
  if (!props.children) return null;
  return <Collapse isOpen={props.isOpen}>{props.children}</Collapse>;
};

export default EntityCollapse;
