import { isEmbeddedBySuperblocksFirstParty, sendMessage } from "utils/iframe";

const EMBED_STRING = `embedded-`;

let promiseCount = 0;
const resolverMap = new Map<string, (...args: any[]) => any>();
const rejectorMap = new Map<string, (...args: any[]) => any>();

const multiUseCallbacks = new Map<string, (...args: any[]) => any>();

export function addNewPromise(
  resolver: (...args: any[]) => any,
  isMultiUse = false,
  rejecter?: (...args: any[]) => any,
) {
  const promiseId = `${
    isEmbeddedBySuperblocksFirstParty() ? EMBED_STRING : ""
  }p${++promiseCount}`;
  resolverMap.set(promiseId, resolver);
  if (isMultiUse) {
    multiUseCallbacks.set(promiseId, resolver);
  }
  if (rejecter) {
    rejectorMap.set(promiseId, rejecter);
  }
  return promiseId;
}

export function resolveById(id: string, payload: any) {
  const isEmbedId = id.startsWith(EMBED_STRING);
  if (
    (isEmbeddedBySuperblocksFirstParty() && !isEmbedId) ||
    (!isEmbeddedBySuperblocksFirstParty() && isEmbedId)
  ) {
    sendMessage({
      type: "resolve-promise",
      callbackId: id,
      payload,
    });
  } else {
    try {
      const resolver = resolverMap.get(id);
      if (!resolver) {
        throw new Error(`Promise not found for id ${id}`);
      }
      resolver(payload);
      if (!multiUseCallbacks.has(id)) {
        resolverMap.delete(id);
        rejectorMap.delete(id);
      }
    } catch (e) {
      console.log("Error resolving promise", e);
    }
  }
}

export function rejectById(id: string, payload: any) {
  const isEmbedId = id.startsWith(EMBED_STRING);
  if (
    (isEmbeddedBySuperblocksFirstParty() && !isEmbedId) ||
    (!isEmbeddedBySuperblocksFirstParty() && isEmbedId)
  ) {
    sendMessage({
      type: "reject-promise",
      callbackId: id,
      payload,
    });
  } else {
    try {
      const rejecter = rejectorMap.get(id);
      if (!rejecter) {
        throw new Error(`Promise not found for id ${id}`);
      }
      rejecter(payload);
      if (!multiUseCallbacks.has(id)) {
        resolverMap.delete(id);
        rejectorMap.delete(id);
      }
    } catch (e) {
      console.log("Error rejecting promise", e);
    }
  }
}

export function clearMultiCallback(id: string) {
  multiUseCallbacks.delete(id);
}
