export const MODIFIER_CLASSNAMES = {
  DISABLED_MODIFIER: "sb-disabled",
  ERROR_MODIFIER: "sb-error",
  ACTIVE_MODIFIER: "sb-active",
};

export const CLASS_NAMES = {
  ...MODIFIER_CLASSNAMES,
  // user-controllable typefaces
  HEADING1: "sb-heading-1",
  HEADING2: "sb-heading-2",
  HEADING3: "sb-heading-3",
  HEADING4: "sb-heading-4",
  HEADING5: "sb-heading-5",
  HEADING6: "sb-heading-6",
  BODY1: "sb-body-1",
  BODY2: "sb-body-2",
  BODY3: "sb-body-3",
  LINK: "sb-link",
  INPUT_LABEL: "sb-input-label",
  LABEL: "sb-label",
  CODE_TEXT: "sb-code",
  INPUT_TEXT: "sb-input-text",
  INPUT_PLACEHOLDER: "sb-input-placeholder",
  BUTTON_LABEL: "sb-button-label",

  // built-in typefaces
  SYSTEM_TEXT: "sb-system-text",
  BUILTIN_BODY: "sb-builtin-body",
  ELLIPSIS_TEXT: "sb-ellipsis-text",

  // buttons
  PRIMARY_BUTTON: "sb-button-primary",
  SECONDARY_BUTTON: "sb-button-secondary",
  SECONDARY_NEUTRAL_BUTTON: "sb-button-secondary-neutral",
  TERTIARY_BUTTON: "sb-button-tertiary",
  SYSTEM_BUTTON: "sb-button-system",
  // inputs
  INPUT: "sb-input",
  SWITCH: "sb-switch",
  CHECKBOX: "sb-checkbox",
  RADIO: "sb-radio",
  // containers
  SECTION: "sb-section",
  ROUNDED_CONTAINER: "sb-rounded-container",

  CANVAS: "sb-canvas", // the inner canvas of a container
  DEFAULT_CONTAINER: "sb-default-container", // used for container-like wrappers (i.e. table, graph, etc.)

  DEFAULT_CONTAINER_STYLE_CARD: "sb-default-container-style-card", // used for container components
  DEFAULT_CONTAINER_STYLE_NONE: "sb-default-container-style-none", // used for container components

  DEFAULT_CONTAINER_BORDER: "sb-default-container-border", // used to render container borders, which are customizable + overlay the component

  CONTAINER_BORDER_OUTLINE: "sb-container-border-overlay",
  POPOVER_WRAPPER: "sb-popover-wrapper",
  TAB: "sb-tab",
  MODAL: "sb-modal",
  SLIDEOUT: "sb-slideout",
  BORDER_BOTTOM: "sb-util-border-bottom",
  BORDER_TOP: "sb-util-border-top",
  NOTIFICATION: "sb-notification",
  STYLED_SCROLLBAR: "sb-scrollable",
  TOOLTIP: "sb-tooltip",
  // menus
  DROPDOWN: "sb-dropdown",
  DROPDOWN_MENU: "sb-dropdown-menu",
  MENU_ITEM: "sb-menu-item",
  TAG_INPUT: "sb-tag-input",
  DATEPICKER: "sb-datepicker",
  // icons
  DROPDOWN_CLEAR_ICON: "sb-dropdown-clear-icon",
  CARET_ICON: "sb-caret-icon",
  PRIMARY_COLOR_ICON: "sb-primary-color-icon",
  CLOSE_ICON: "sb-close-icon",
  ICON: "sb-icon",
  // misc
  PAGINATOR: "sb-rc-paginator",
  CODE_EDITOR: "sb-code-editor",
};
