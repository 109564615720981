import { isEmpty } from "lodash";
import type { ActionDtoV1, ActionsDtoV1 } from "store/slices/apisShared";

const findChildren = (
  actions: ActionDtoV1[],
  parent: ActionDtoV1,
): ActionDtoV1[] | undefined => {
  if (!parent.children) {
    return;
  }

  const children = Object.values(parent.children);

  return children
    .map((child) => actions.find((action) => action.id === child))
    .filter(
      ((child) => !!child) as (
        child: ActionDtoV1 | undefined,
      ) => child is ActionDtoV1,
    );
};

export const getOrderedActions = (actions?: ActionsDtoV1): ActionDtoV1[] => {
  if (!actions?.actions || isEmpty(actions?.actions)) {
    return [];
  }
  const values = Object.values(actions.actions);

  const firstAction: any = values.find(
    (action: any) => action.id === actions.triggerActionId,
  );

  const result = [firstAction];

  while (result.length !== values.length) {
    const last = result[result.length - 1];
    const childActions = findChildren(values, last);

    if (childActions) {
      result.push(...childActions);
    }
  }

  return result;
};

export function distanceToRoot(actions: ActionsDtoV1, actionId: string) {
  let result = 0;
  let currentAction = actions.actions[actions.triggerActionId];

  while (currentAction && currentAction.id !== actionId) {
    const children: string[] = Object.keys(currentAction.children ?? {});

    if (children.length) {
      currentAction = actions.actions[children[0]];
      result += 1;
    } else {
      return undefined;
    }
  }

  return result;
}
