import {
  LanguagePluginID,
  refactorNameInStep,
  ApplicationScope,
} from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  extractJsEvaluationPairs,
  extractPythonEvaluationPairs,
} from "legacy/workers/evaluationUtils";
import {
  createFakeDataTree,
  refactorNameInString,
  ScopedFakeDataTree,
} from "../../../helpers/refactoring";
import { getSetControlBlockExpressions } from "./block-bindings";
import {
  BlockType,
  ControlBlock,
  ControlFlowFrontendDSL,
  GenericBlock,
  StepBlock,
  WaitControl,
} from "./types";

function isStepBlock(block: GenericBlock): block is StepBlock {
  return block.type === BlockType.STEP;
}

function isControlBlock(block: GenericBlock): block is ControlBlock {
  return !isStepBlock(block);
}

export enum RefactoredType {
  ENTITY = "ENTITY",
  BLOCK = "BLOCK",
  BLOCK_VAR = "BLOCK_VAR",
}

export function* refactorEntityName(
  controlFlow: ControlFlowFrontendDSL,
  payload: {
    prevName: string;
    newName: string;
    refactoredType: RefactoredType;
    affectedBlockNames?: string[]; // optionally limit the block names to refactor
    namespace?: string;
  },
) {
  const { prevName, newName, refactoredType, affectedBlockNames, namespace } =
    payload;
  const dataTree: DataTree = yield select(getDataTree);
  yield call(refactorEntityNameWithDataTree, controlFlow, {
    prevName,
    newName,
    refactoredType,
    affectedBlockNames,
    dataTree,
    namespace,
  });
}

export async function refactorEntityNameWithDataTree(
  controlFlow: ControlFlowFrontendDSL,
  payload: {
    prevName: string;
    newName: string;
    refactoredType: RefactoredType;
    affectedBlockNames?: string[]; // optionally limit the block names to refactor
    dataTree: DataTree;
    namespace?: string;
  },
) {
  const {
    prevName,
    newName,
    refactoredType,
    affectedBlockNames,
    dataTree,
    namespace,
  } = payload;

  const affectedBlockNamesSet = affectedBlockNames
    ? new Set(affectedBlockNames)
    : undefined;

  const fakeDataTree: ScopedFakeDataTree =
    refactoredType === RefactoredType.ENTITY
      ? {
          [ApplicationScope.GLOBAL]: {},
          [ApplicationScope.APP]: {},
          [ApplicationScope.PAGE]: Object.fromEntries(
            Object.values(controlFlow.blocks).map((block) => [block.name, {}]),
          ),
        }
      : { ...createFakeDataTree(dataTree) };
  fakeDataTree[ApplicationScope.PAGE][prevName] = {};
  fakeDataTree[ApplicationScope.PAGE][newName] = undefined;

  for (const blockName of Object.keys(controlFlow.blocks)) {
    if (affectedBlockNamesSet && !affectedBlockNamesSet.has(blockName))
      continue;

    const block = controlFlow.blocks[blockName];
    if (!block) {
      throw new Error("Could not find block");
    }
    if (isStepBlock(block)) {
      await refactorNameInStep({
        // TODO(APP_SCOPE): App.<EntityName> should be supported, but its currently not, lets just use the page scope for now
        dataTree: fakeDataTree[ApplicationScope.PAGE],
        newName,
        oldName: prevName,
        extractor: getExtractorByPluginId(block.config.pluginId),
        namespace,
        configuration: block.config.configuration,
        pluginId: block.config.pluginId,
        suffixes:
          refactoredType === RefactoredType.BLOCK_VAR
            ? [".value", ".set"]
            : undefined,
      });
    } else if (isControlBlock(block)) {
      if (
        block.type === BlockType.WAIT &&
        refactoredType === RefactoredType.BLOCK
      ) {
        // TODO, come up with something more elegant than this
        const config = block.config as WaitControl;
        const blockName = (config.condition ?? "").match(
          /{{["'`]([^"'`]+)["'`]}}/,
        )?.[1];
        if (blockName && config.condition && blockName === prevName) {
          config.condition = config.condition.replace(prevName, newName);
        }
        continue;
      }
      for (const [expr, setExpr] of getSetControlBlockExpressions(block)) {
        setExpr(
          await refactorNameInString({
            value: expr,
            oldName: prevName,
            newName,
            dataTree: fakeDataTree[ApplicationScope.PAGE],
            namespace,
            extractPairs: extractJsEvaluationPairs,
          }),
        );
      }
    }
  }
}

function getExtractorByPluginId(pluginId?: string) {
  if (!pluginId) {
    return extractJsEvaluationPairs;
  }

  switch (pluginId.trim().toLowerCase()) {
    case LanguagePluginID.Python:
      return extractPythonEvaluationPairs;
    case LanguagePluginID.JavaScript:
    default:
      return extractJsEvaluationPairs;
  }
}
