import { select, take } from "redux-saga/effects";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  getHasEvaluated,
  getIsEvaluating,
} from "legacy/selectors/evaluatorSelectors";

export function* waitForEvaluationToComplete() {
  const isEvaluating: boolean = yield select(getIsEvaluating);
  if (isEvaluating) {
    yield take(ReduxActionTypes.SET_EVALUATED_TREE);
  }
}

// Triggers are often fired at the same time as props updates. If there is
// an evaluation in progress, it won't include the correct props updates but will immediately
// queue another evaluation.
export function* waitForCurrentAndQueuedEvaluations() {
  const isEvaluating: boolean = yield select(getIsEvaluating);
  if (isEvaluating) {
    yield take(ReduxActionTypes.SET_EVALUATED_TREE);
  }
  const isStillEvaluating: boolean = yield select(getIsEvaluating);
  if (isStillEvaluating) {
    yield take(ReduxActionTypes.SET_EVALUATED_TREE);
  }
}

export function* waitForNextEvaluationToComplete() {
  const isEvaluating: boolean = yield select(getIsEvaluating);
  if (isEvaluating) {
    yield take(ReduxActionTypes.START_EVALUATE_TREE);
  }
  yield take(ReduxActionTypes.SET_EVALUATED_TREE);
}

export function* waitForFirstEvaluation() {
  const hasFirstEval: boolean = yield select(getHasEvaluated);
  if (!hasFirstEval) {
    yield take(ReduxActionTypes.SET_EVALUATED_TREE);
  }
}
