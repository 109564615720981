import { MenuInfo } from "rc-menu/lib/interface";
import { useCallback } from "react";
import { updateWidget } from "legacy/actions/pageActions";
import { PAGE_WIDGET_ID, WidgetTypes } from "legacy/constants/WidgetConstants";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { ItemKinds } from "../../PropertyPane/ItemKindConstants";

const MOVABLE_WIDGETS = [WidgetTypes.MODAL_WIDGET, WidgetTypes.SLIDEOUT_WIDGET];

export const useEntityMoveToPage = (props: {
  entityId: string;
  entityKind?: ItemKinds;
}) => {
  const { entityId, entityKind } = props;

  const widget = useAppSelector((state) =>
    entityKind === ItemKinds.WIDGET ? getWidget(state, entityId) : undefined,
  );

  const dispatch = useAppDispatch();

  const shouldShowMoveMenuItem =
    widget &&
    MOVABLE_WIDGETS.includes(widget.type as WidgetTypes) &&
    widget.parentId !== PAGE_WIDGET_ID;

  const handleMoveEntityToPage = useCallback(
    (info: MenuInfo) => {
      info.domEvent.stopPropagation();
      dispatch(
        updateWidget("WIDGET_REPARENT", entityId, {
          newParentId: PAGE_WIDGET_ID,
        }),
      );
    },
    [dispatch, entityId],
  );

  return {
    shouldShowMoveMenuItem,
    handleMoveEntityToPage,
  };
};
