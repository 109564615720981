import { createSlice } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { initCanvasLayout } from "legacy/actions/pageActions";
import {
  AppStateVar,
  AppStateVarMetaType,
} from "store/slices/application/stateVars/StateConstants";
import { deleteStateVar } from "store/slices/application/stateVars/stateVarsActions";

type StateVarsMetaState = {
  [ApplicationScope.GLOBAL]: Record<AppStateVar["id"], AppStateVarMetaType>;
  [ApplicationScope.APP]: Record<AppStateVar["id"], AppStateVarMetaType>;
  [ApplicationScope.PAGE]: Record<AppStateVar["id"], AppStateVarMetaType>;
};

const initialState: StateVarsMetaState = {
  [ApplicationScope.GLOBAL]: {},
  [ApplicationScope.APP]: {},
  [ApplicationScope.PAGE]: {},
};

interface UpdateStateVarMetaPropertiesPayload {
  id: AppStateVar["id"];
  updates: AppStateVarMetaType;
}

export const updateStateVarMetaProperties = createAction(
  "UPDATE_STATE_VAR_META",
  (
    scope: ApplicationScope,
    id: AppStateVar["id"],
    updates: UpdateStateVarMetaPropertiesPayload["updates"],
  ) => ({
    payload: {
      scope,
      id,
      updates,
    },
  }),
);

export const resetStateVarMetaProperties = createAction(
  "RESET_STATE_VAR_META",
  (scope: ApplicationScope, id: AppStateVar["id"]) => ({
    payload: {
      scope,
      id,
    },
  }),
);

export const stateVarsMetaSlice = createSlice({
  name: "stateVarsMeta",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        updateStateVarMetaProperties,
        (state, { payload: { scope, id, updates } }) => {
          state[scope][id] = {
            ...state[scope][id],
            ...updates,
          };
        },
      )
      .addCase(
        resetStateVarMetaProperties,
        (state, { payload: { scope, id } }) => {
          delete state[scope][id];
        },
      )
      .addCase(deleteStateVar, (state, action) => {
        delete state[action.payload.scope][action.payload.id];
      })
      .addCase(stopEvaluation, () => initialState)
      .addCase(initCanvasLayout, (state) => {
        // when switching pages, leave app scope state vars alone
        state[ApplicationScope.PAGE] = {};
      }),
});
