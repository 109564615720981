import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
} from "@reduxjs/toolkit/query/react";
import {
  DATA_DOMAIN_HEADER,
  SUPERBLOCKS_AUTHORIZATION_HEADER,
  SUPERBLOCKS_EMBED_HEADER,
  SUPERBLOCKS_REQUEST_ID_HEADER,
} from "@superblocksteam/shared";
import { v4 as uuidv4 } from "uuid";
import AuthProvider from "auth/auth0";
import TokenProvider from "auth/token";
import { SUPERBLOCKS_UI_DATA_DOMAIN } from "env";
import { SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL } from "env";
import { isOnEmbedRoute } from "utils/embed/messages";

const baseQueryWithHeaders: BaseQueryFn = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL || "/api/",
  });

  // Prepare headers asynchronously
  const headers = new Headers();
  // default headers
  headers.set(SUPERBLOCKS_EMBED_HEADER, isOnEmbedRoute() ? "true" : "false");
  headers.set(DATA_DOMAIN_HEADER, SUPERBLOCKS_UI_DATA_DOMAIN);
  headers.set(SUPERBLOCKS_REQUEST_ID_HEADER, uuidv4());
  headers.set("Content-Type", "application/json");

  // Auth headers
  const token = await AuthProvider.generateToken();
  const authorizationJwt = TokenProvider.getToken();

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  if (token && authorizationJwt) {
    headers.set(SUPERBLOCKS_AUTHORIZATION_HEADER, `Bearer ${authorizationJwt}`);
  }

  // Merge the prepared headers with any existing headers in the query
  let mergedArgs: any;
  if (typeof args === "string") {
    mergedArgs = { url: args, headers };
  } else {
    mergedArgs = {
      ...args,
      headers: new Headers({ ...Object.fromEntries(headers), ...args.headers }),
    };
  }

  return baseQuery(mergedArgs, api, extraOptions);
};

// Tag type constants
export const RoleTag = "Role";

export const reduxApiSlice = createApi({
  reducerPath: "reduxApi", // not using the default "api" name because we have an entity called api
  baseQuery: baseQueryWithHeaders,
  endpoints: (builder) => ({}),
  tagTypes: [RoleTag],
});
