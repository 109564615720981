import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { initCanvasLayout } from "legacy/actions/pageActions";
import { AppTimer, AppTimerMetaType } from "../timers/TimerConstants";
import { deleteTimer } from "../timers/timerActions";

type TimersMetaState = {
  [ApplicationScope.GLOBAL]: Record<AppTimer["id"], AppTimerMetaType>;
  [ApplicationScope.APP]: Record<AppTimer["id"], AppTimerMetaType>;
  [ApplicationScope.PAGE]: Record<AppTimer["id"], AppTimerMetaType>;
};

const initialState: TimersMetaState = {
  [ApplicationScope.GLOBAL]: {},
  [ApplicationScope.APP]: {},
  [ApplicationScope.PAGE]: {},
};

export const timersMetaSlice = createSlice({
  name: "timersMeta",
  initialState,
  reducers: {
    updateTimerMetaProperties: (
      state,
      action: PayloadAction<{
        scope: ApplicationScope;
        id: AppTimer["id"];
        updates: Partial<AppTimerMetaType>;
      }>,
    ) => {
      state[action.payload.scope][action.payload.id] = {
        ...(state[action.payload.scope][action.payload.id] ?? {}),
        ...action.payload.updates,
      };
    },
    resetTimerMetaProperties: (
      state,
      action: PayloadAction<{ scope: ApplicationScope; id: AppTimer["id"] }>,
    ) => {
      state[action.payload.scope][action.payload.id] = {
        isActive: false,
      };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(deleteTimer, (state, action) => {
        delete state[action.payload.scope][action.payload.id];
      })
      .addCase(stopEvaluation, () => initialState)
      .addCase(initCanvasLayout, (state) => {
        state[ApplicationScope.PAGE] = {};
      }),
});

export const { updateTimerMetaProperties, resetTimerMetaProperties } =
  timersMetaSlice.actions;
