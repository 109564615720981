import { SUPERBLOCKS_AUTHORIZATION_HEADER } from "@superblocksteam/shared";
import TokenProvider from "auth/token";
import { SUPERBLOCKS_UI_AI_URL } from "env";
import PerformanceTracker, {
  PerformanceName,
} from "legacy/utils/PerformanceTracker";
import { AI_ASSISTANT_TASK } from "pages/routes";
import { HttpMethod, stream } from "store/utils/client";
import { StatusCodes } from "utils/http";
import { AiAssistantRequest, AiAssistantOptionType } from "./constants";

const GENERIC_ERROR_MESSAGE =
  "Oops, looks like AI service is unavailable. Try again now or later.";

export const queryAiAssistant = ({
  body,
  onMessage,
  onComplete,
  onError,
  token,
  option,
}: {
  body: AiAssistantRequest;
  onMessage: (message: string) => void;
  onComplete: () => void;
  onError: (error: string, statusCode?: StatusCodes) => void;
  token: string;
  option: AiAssistantOptionType;
}): (() => void) => {
  PerformanceTracker.track(PerformanceName.AI_RESPONSE_GENERATED, {
    type: `${option.flowType} ${option.syntax}`,
  });

  const controller = new AbortController();
  const signal = controller.signal;

  const handleMessage = (message: any) => {
    if (typeof message?.result?.chunk === "string") {
      onMessage(message.result.chunk);
    } else if (message?.error) {
      onError(message.error.message, message.error.code);
    }
  };

  const authorizationJwt = TokenProvider.getToken();

  stream({
    body,
    onMessage: handleMessage,
    onError,
    onComplete,
    url: AI_ASSISTANT_TASK,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
    method: HttpMethod.Post,
    defaultError: GENERIC_ERROR_MESSAGE,
    baseUrl: SUPERBLOCKS_UI_AI_URL,
    signal,
  });
  return () => {
    controller.abort();
  };
};
