import { InboxOutlined } from "@ant-design/icons";
import SwaggerParser from "@apidevtools/swagger-parser";
import { Modal, UploadProps } from "antd";
import { Upload } from "antd";
import { produce } from "immer";
import yaml from "js-yaml";
import React, { useCallback, useRef, useState } from "react";
import { useAppDispatch } from "store/helpers";
import { getMetadataSaga } from "store/slices/datasources";
import { sendErrorUINotification } from "utils/notification";

const { Dragger } = Upload;

const LIMIT_MAX_FILE_SIZE_MB = 25;

const SpecUploadModal = ({
  visible,
  setVisible,
  integrationId,
  setOpenApiSpecFile,
  setSpecUpdated,
  setConfigIdToFormData,
  configIdToFormData,
  setOpenApiString,
}: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  integrationId: string;
  setOpenApiSpecFile: (file: File | null) => void;
  setSpecUpdated: (updated: boolean) => void;
  setConfigIdToFormData: (configIdToFormData: Record<string, any>) => void;
  configIdToFormData: Record<string, any> | undefined;
  setOpenApiString: (openApiString: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const specRef = useRef<any>(null);
  const specStringRef = useRef<string>("");
  const tempSpecFileRef = useRef<File | null>(null);
  const cancelSelectFile = useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  const [isFileValid, setIsFileValid] = useState(false);
  const onFileConfirm = useCallback(() => {
    if (specRef.current) {
      dispatch({
        type: getMetadataSaga.store.type,
        payload: {
          integrationId,
          openApiSpec: specRef.current,
        },
      });
      setOpenApiSpecFile(tempSpecFileRef.current);
      setOpenApiString(specStringRef.current);
      setSpecUpdated(true);
      if (configIdToFormData) {
        Object.entries(configIdToFormData).forEach(([configId, formData]) => {
          const openApiSpec = specRef.current;
          if (
            formData?.urlBase ||
            (!openApiSpec.host && !openApiSpec.servers?.[0]?.url)
          )
            return;
          // if urlBase is not set, fill it based on openAPI spec "host"
          // swagger 2.0 use host + basePath, openAPI 3.0 use servers
          setConfigIdToFormData(
            produce((draft: any) => {
              draft[configId] = {
                ...formData,
                urlBase:
                  openApiSpec.servers?.[0]?.url ??
                  `${
                    openApiSpec?.schemes?.[0]
                      ? `${openApiSpec?.schemes?.[0]}://`
                      : ""
                  }${openApiSpec.host ?? ""}${openApiSpec.basePath ?? ""}`,
              };
            }),
          );
        });
      }
      setVisible(false);
    }
  }, [
    configIdToFormData,
    dispatch,
    integrationId,
    setConfigIdToFormData,
    setOpenApiSpecFile,
    setOpenApiString,
    setSpecUpdated,
    setVisible,
  ]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".yaml,.yml,.json",
    beforeUpload: (file) => {
      return false;
    },
    onChange(info) {
      if (info.fileList.length < 1) {
        // this is deleting file
        setIsFileValid(false);
        return;
      }
      if (
        info?.file?.size &&
        info?.file?.size > LIMIT_MAX_FILE_SIZE_MB * 1024 * 1024
      ) {
        info.file.status = "error";
        info.fileList[0].status = "error";
        info.fileList[0].response = `${info.file.name} size exceeds ${LIMIT_MAX_FILE_SIZE_MB} MB.`;
        setIsFileValid(false);
        return;
      }

      const file: any = info.fileList[0].originFileObj;

      const reader = new FileReader();
      reader.onload = async () => {
        const fileContent = reader.result;

        let loadFunc;
        if (file?.type === "application/x-yaml") {
          loadFunc = yaml.load;
        } else if (file?.type === "application/json") {
          loadFunc = JSON.parse;
        } else {
          sendErrorUINotification({
            message: `Invalid file type`,
          });
          return;
        }

        tempSpecFileRef.current = file as File;
        if (loadFunc) {
          try {
            let specObject;
            let specString;

            if (typeof fileContent === "string") {
              specObject = loadFunc(fileContent);
              specString = fileContent;
            } else if (fileContent instanceof ArrayBuffer) {
              const decoder = new TextDecoder();
              const decodedContent = decoder.decode(fileContent);
              specObject = loadFunc(decodedContent);
              specString = decodedContent;
            } else {
              sendErrorUINotification({
                message: `Invalid file content`,
              });
            }
            const parser = new SwaggerParser();
            const specResolvedObject = await parser.dereference(specObject, {
              dereference: {
                circular: "ignore", // keep only circular $ref as ref string
              },
            });

            specRef.current = specResolvedObject;
            specStringRef.current = specString ?? "";
            setIsFileValid(true);
          } catch (error) {
            sendErrorUINotification({
              message: `Failed to parse the file: ${error}. Please check if the file is valid or has external references.`,
            });
          }
        }
      };

      reader.readAsArrayBuffer(file as File);
    },
  };

  return (
    <Modal
      open={visible}
      title="Upload API Specification"
      onCancel={cancelSelectFile}
      onOk={onFileConfirm}
      okButtonProps={{ disabled: !isFileValid }}
    >
      <div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">{"(JSON, X-YAML, YAML)"}</p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default SpecUploadModal;
