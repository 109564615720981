import {
  IntegrationDto,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";

export const getConfigFromIntegrationWithProfileId = (
  datasource: SupersetIntegrationDto | IntegrationDto | undefined,
  profileId: string,
) => {
  if (!datasource || !datasource.configurations) return undefined;
  return (
    datasource.configurations.find((config) =>
      config.profileIds?.includes(profileId),
    )?.configuration ??
    datasource.configurations.find((config) => config.isDefault)?.configuration
  );
};

export const getConfigIdFromIntegrationWithProfileId = (
  datasource: SupersetIntegrationDto | IntegrationDto | undefined,
  profileId: string,
) => {
  if (!datasource || !datasource.configurations) return undefined;
  return (
    datasource.configurations.find((config) =>
      config.profileIds?.includes(profileId),
    )?.id ?? datasource.configurations.find((config) => config.isDefault)?.id
  );
};

export const metadataSelectorKey = (id: string) => `${id}_metadata`;

export const connectedTokensKey = (integrationConfigurationId: string) =>
  `connectedTokens_${integrationConfigurationId}`;
