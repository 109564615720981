import { Solver, Constraint, Operator } from "@lume/kiwi";
import { AutoHeightWidget } from "./Widget";
import { WidgetVariable } from "./WidgetVariable";
import { createAboveMap } from "./createAboveMap";
import { getBottomPadding, sortByYX } from "./utils";

interface LayoutUpdate {
  all?: AutoHeightWidget[];
}

export class LayoutUpdateBuilder {
  private all?: AutoHeightWidget[];

  static fullUpdate(widgets: AutoHeightWidget[]) {
    const b = new LayoutUpdateBuilder();
    b.all = widgets;
    return b.build();
  }

  build(): LayoutUpdate {
    return {
      all: this.all,
    };
  }
}

export interface ILayoutSystem {
  updateLayout: (batchUpdate: LayoutUpdate) => void;
  updateDynamicHeights: (dynamicHeights: Record<string, number>) => void;
  getLayout: () => AutoHeightWidget[];
}

export class OverlapLayoutSystem implements ILayoutSystem {
  private widgets: AutoHeightWidget[] = [];

  updateLayout(batchUpdate: LayoutUpdate) {
    const { all } = batchUpdate;
    if (all) {
      this.widgets = all;
    }
  }
  updateDynamicHeights(dynamicHeights: Record<string, number>) {
    // Loop through widgets and update heights
    Object.keys(dynamicHeights).forEach((id) => {
      const widget = this.widgets.find((w) => w.id === id);
      if (widget) {
        widget.height = dynamicHeights[id];
      }
    });
  }

  getLayout() {
    return this.widgets;
  }
}

export class ConstraintLayoutSystem implements ILayoutSystem {
  private widgets: AutoHeightWidget[] = [];

  private widgetVariables: { [key: string]: WidgetVariable } = {};
  private solver: Solver = new Solver();

  private roundToGrid: number;
  constructor(roundHeightToGrid: number = 0) {
    this.roundToGrid = roundHeightToGrid;
  }

  updateLayout(batchUpdate: LayoutUpdate) {
    // Update widgets
    const { all } = batchUpdate;
    if (all) {
      this.widgets = all;
    }
    sortByYX(this.widgets);

    const { widgetVariables, solver } = this.createSolver(this.widgets);
    this.widgetVariables = widgetVariables;
    this.solver = solver;

    const widgetAboveMap = createAboveMap(this.widgets);

    this.widgets.forEach((widget) => {
      const widgetVariable = this.widgetVariables[widget.id];
      const widgetsAbove = Array.from(widgetAboveMap.get(widget.id) ?? []);

      if (widgetsAbove.length === 0) {
        // If there are no widgets above this widget, ensure it's y matches the widget's OG y
        this.solver.addConstraint(
          new Constraint(widgetVariable.y, Operator.Eq, widget.y),
        );
      } else {
        const addSpacingConstraint = (
          widgetA: AutoHeightWidget,
          widgetB: AutoHeightWidget,
          marginTop: number,
        ) => {
          const widgetAV = widgetVariables[widgetA.id];
          const widgetBV = widgetVariables[widgetB.id];

          solver.addConstraint(
            new Constraint(
              widgetAV.y,
              Operator.Ge,
              widgetBV.y
                .plus(widgetBV.height)
                .plus(widgetBV.bottomPadding)
                .plus(marginTop),
            ),
          );
        };
        const marginTop = this.getMarginToWidgetAbove(widgetsAbove, widget);

        widgetsAbove.forEach((above) =>
          addSpacingConstraint(widget, above, marginTop),
        );
      }
    });
    this.solver.updateVariables();
  }

  updateDynamicHeights(dynamicHeights: Record<string, number>) {
    // Update heights of widgets that have dynamic heights
    Object.keys(dynamicHeights).forEach((id) => {
      const widgetVariable = this.widgetVariables[id];
      if (widgetVariable) {
        widgetVariable.updateHeight(dynamicHeights[id]);
      }
    });
    this.solver.updateVariables();
  }

  getLayout() {
    return this.widgets.map((widget) =>
      this.widgetVariables[widget.id].toWidget(),
    );
  }

  createSolver(widgets: AutoHeightWidget[]) {
    const solver = new Solver();
    const widgetVariables = widgets.reduce(
      (acc, widget) => ({
        ...acc,
        [widget.id]: new WidgetVariable(widget, solver, {
          roundToGrid: this.roundToGrid,
        }),
      }),
      {} as { [key: string]: WidgetVariable },
    );
    return { widgetVariables, solver };
  }

  getMarginToWidgetAbove(
    widgetsAbove: AutoHeightWidget[],
    widget: AutoHeightWidget,
  ) {
    const closestWidgetsAbove = widgetsAbove.reduce((closest, widgetAbove) => {
      const wBottom =
        widgetAbove.y +
        widgetAbove.height +
        getBottomPadding(widgetAbove.height, this.roundToGrid);
      const cBottom =
        closest.length > 0
          ? closest[0].y +
            closest[0].height +
            getBottomPadding(widgetAbove.height, this.roundToGrid)
          : -Infinity;
      if (wBottom === cBottom) {
        closest.push(widgetAbove);
        return closest;
      }
      return wBottom > cBottom ? [widgetAbove] : closest;
    }, [] as AutoHeightWidget[]);
    const marginTop = Math.max(
      widget.y -
        (closestWidgetsAbove[0].y +
          closestWidgetsAbove[0].height +
          getBottomPadding(closestWidgetsAbove[0].height, this.roundToGrid)),
      0,
    );
    return marginTop;
  }
}
