import { IntegrationCreate, IntegrationUpdate } from "@superblocksteam/schemas";
import {
  ActionConfiguration,
  Agent,
  AgentDiagnosticMetadata,
  AuthId,
  ConnectedUserTokenDto,
  DatasourceConfiguration,
  DatasourceMetadataDto,
  DatasourceTestRequest,
  DatasourceTestResult,
  DeleteDatasourceOnAgentResult,
  IntegrationDto,
  IntegrationKind,
  Organization,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import AuthProvider from "auth/auth0";
import {
  SUPERBLOCKS_UI_AGENT_BASE_URL,
  SUPERBLOCKS_UI_BUCKETEER_URL,
} from "env";
import { AgentApiPaths } from "store/utils/agent";
import { OrchestratorApiPaths } from "store/utils/orchestrator";
import { isValidUrl } from "utils/url";
import { HttpMethod, callServer, callAgent } from "../../utils/client";
import { callOrchestrator } from "../apisShared/call-orchestrator";
import { callWithExpectedError } from "../apisShared/callWithExpectedError";

const OAUTH_CONNECTED_USER_TOKENS_PATH = "v1/oauth2/user/connectedTokens";
const OAUTH_CONNECTED_SHARED_TOKENS_PATH = "v1/oauth2/connectedTokens";

export function getDatasources(organizationId: string, kind?: IntegrationKind) {
  const query: any = { organizationId };
  if (kind) {
    query.kind = kind;
  }
  return callServer<IntegrationDto[]>({
    method: HttpMethod.Get,
    url: "v1/integrations/",
    query,
  });
}

export function getSupersetDatasources(
  organizationId: string,
  kind: IntegrationKind = IntegrationKind.PLUGIN,
) {
  const query: any = { organizationId };
  if (kind) {
    query.kind = kind;
  }
  return callServer<SupersetIntegrationDto[]>({
    method: HttpMethod.Get,
    url: "v1/integrations/superset",
    query,
  });
}

export function createDatasource(body: IntegrationCreate) {
  return callServer<IntegrationDto>({
    method: HttpMethod.Post,
    url: "v1/integrations/",
    body,
  });
}

export function reportDatasourceCreationError(
  body: Partial<IntegrationDto> & { pluginName?: string; error?: string },
) {
  return callServer<IntegrationDto>({
    method: HttpMethod.Post,
    url: "v1/integrations/report-error",
    body,
  });
}

export function updateDatasource(body: IntegrationUpdate) {
  return callServer<IntegrationDto>({
    method: HttpMethod.Put,
    url: "v1/integrations/:datasourceId",
    params: { datasourceId: body.id },
    body,
  });
}

export function deleteDatasource(datasourceId: string) {
  return callServer<IntegrationDto>({
    method: HttpMethod.Delete,
    url: "v1/integrations/:datasourceId",
    params: { datasourceId },
  });
}

// Note(taha pimeng) proj:profiles These agent caller functions' signature should be made similar
// to that of the agent auth functions like checkAuth, requestToken, exchangeCode, etc., and accept
// a profile object to be able to set the profile ID and profile name as query params.

export function testV1Datasource(
  agents: Agent[],
  organization: Organization,
  environment: string,
  datasourceId: string | undefined,
  body: DatasourceTestRequest,
  diagnosticMetadata: AgentDiagnosticMetadata = {},
) {
  const query: any = { environment: environment };
  if (datasourceId) {
    query.datasourceId = datasourceId;
  }
  const payload: any = {
    method: HttpMethod.Post,
    url: AgentApiPaths.DATASOURCE_TEST,
    body,
    agents,
    diagnosticMetadata,
    query: query,
    organization,
  };
  return callAgent<DatasourceTestResult>(payload);
}

export function testV2Datasource({
  integrationType,
  datasourceConfig,
  actionConfig,
  agents,
  organization,
  datasourceId,
  configurationId,
  environment,
}: {
  integrationType: string;
  datasourceConfig: DatasourceConfiguration;
  actionConfig: ActionConfiguration;
  agents: Agent[];
  organization: Organization;
  datasourceId?: string;
  configurationId?: string;
  environment?: string;
}) {
  const payload = {
    method: HttpMethod.Post,
    url: OrchestratorApiPaths.DATASOURCE_TEST,
    baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
    body: {
      integration_type: integrationType,
      action_config: actionConfig,
      datasource_config: {
        ...datasourceConfig,
        id: datasourceId,
      },
      configurationId: configurationId,
      profile: { name: environment },
    },
    agents,
    organization,
  };

  return callWithExpectedError<Record<string, never>>(payload);
}

export function testSecretStore({
  agents,
  organization,
  datasourceSlug,
  profileId,
  provider,
}: {
  agents: Agent[];
  organization: Organization;
  datasourceSlug: string;
  profileId: string;
  provider: unknown;
}) {
  const payload = {
    method: HttpMethod.Post,
    url: OrchestratorApiPaths.SECRET_METADATA,
    baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
    body: {
      provider,
      profile: {
        id: profileId,
      },
    },
    agents,
    organization,
    params: { datasourceSlug },
  };

  return callWithExpectedError<Record<string, never>>(payload);
}

// TODO: change this call method from "GET" to "POST", after we upgrade all the old agents.
export function getMetadata(
  agents: Agent[],
  organization: Organization,
  datasourceId: string,
  environment: string,
  apiId?: string,
  actionId?: string,
) {
  const query: Record<string, string> = {
    environment,
  };
  if (apiId) {
    query.apiId = apiId;
  }
  if (actionId) {
    query.actionId = actionId;
  }
  return callAgent<DatasourceMetadataDto>({
    method: HttpMethod.Get,
    url: AgentApiPaths.DATASOURCE_METADATA,
    params: { datasourceId: datasourceId },
    query: query,
    agents,
    organization,
  });
}

export function getMetadataV2Stateless(
  agents: Agent[],
  organization: Organization,
  datasourceId: string,
  environment: string,
  stepConfiguration: any,
) {
  const body: Record<string, unknown> = {
    profile: { name: environment },
    integration: datasourceId,
    stepConfiguration,
  };

  return callOrchestrator<DatasourceMetadataDto>({
    method: HttpMethod.Post,
    baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
    url: OrchestratorApiPaths.DATASOURCE_METADATA,
    body,
    agents,
    organization,
  });
}

export function deleteDatasourceOnAgent(
  agents: Agent[],
  organization: Organization,
  environment: string,
  datasourceId: string,
  diagnosticMetadata: AgentDiagnosticMetadata = {},
) {
  const query: any = { environment: environment };
  const params: any = {};
  if (!isEmpty(datasourceId)) {
    params.datasourceId = datasourceId;
  }
  const payload: any = {
    method: HttpMethod.Delete,
    url: AgentApiPaths.DATASOURCE_PRE_DELETE,
    params: params,
    body: {},
    agents,
    diagnosticMetadata,
    query: query,
    organization,
  };
  return callAgent<DeleteDatasourceOnAgentResult>(payload);
}

export async function deleteDatasourceOnOrchestrator({
  datasourceId,
  configurationId,
  pluginName,
  agents,
  organization,
}: {
  datasourceId: string;
  configurationId?: string;
  pluginName?: string;
  agents: Agent[];
  organization: Organization;
  // TODO: Do we need to add this too for agent calls?
  // diagnosticMetadata: AgentDiagnosticMetadata = {},
}): Promise<{
  success?: boolean;
  message?: string;
  systemError?: string; // just to comply with the v1 type
}> {
  const payload = {
    method: HttpMethod.Post,
    url: OrchestratorApiPaths.DATASOURCE_DELETE,
    baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
    body: {
      integration: datasourceId,
      configurationId,
      pluginName,
    },
    agents,
    organization,
  };

  const res = await callWithExpectedError<unknown>(payload);
  return {
    success: res.success,
    message: res.error,
  };
}

export function fetchOpenApiSpecUrl(openApiSpecRef: string) {
  const isRefUrl = isValidUrl(openApiSpecRef);
  const url = isRefUrl
    ? openApiSpecRef
    : `${SUPERBLOCKS_UI_BUCKETEER_URL}/integration/${openApiSpecRef}`;
  if (AuthProvider.ready()) {
    return AuthProvider.generateToken().then((token) => {
      return fetch(url, {
        method: HttpMethod.Get,
        headers: isRefUrl
          ? {}
          : {
              Authorization: `Bearer ${token}`,
            },
      });
    });
  } else {
    return fetch(url, {
      method: HttpMethod.Get,
    });
  }
}

export function uploadOpenApiSpecUrl({
  file,
  newName,
  integrationId,
}: {
  file: File;
  newName: string;
  integrationId: string;
}) {
  const formData = new FormData();
  formData.append("file", file, newName);

  if (AuthProvider.ready()) {
    return AuthProvider.generateToken().then((token) => {
      return fetch(
        `${SUPERBLOCKS_UI_BUCKETEER_URL}/integration/${integrationId}`,
        {
          method: HttpMethod.Post,
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    });
  } else {
    return fetch(
      `${SUPERBLOCKS_UI_BUCKETEER_URL}/integration/${integrationId}`,
      {
        method: HttpMethod.Post,
        body: formData,
      },
    );
  }
}

export function getSharedConnectedTokens(
  integrationId: string,
  integrationConfigurationId: string,
  authId: AuthId,
) {
  return callServer<ConnectedUserTokenDto[]>({
    method: HttpMethod.Get,
    url: OAUTH_CONNECTED_SHARED_TOKENS_PATH,
    query: { integrationId, integrationConfigurationId, authId },
  });
}

export function getUserConnectedTokens(
  integrationId: string,
  integrationConfigurationId: string,
  authId: AuthId,
) {
  return callServer<ConnectedUserTokenDto[]>({
    method: HttpMethod.Get,
    url: OAUTH_CONNECTED_USER_TOKENS_PATH,
    query: { integrationId, integrationConfigurationId, authId },
  });
}

export function revokeAndDeleteSharedConnectedTokens(
  authId: string,
  revokeTokenUrl: string,
) {
  return callServer<ConnectedUserTokenDto[]>({
    method: HttpMethod.Delete,
    url: `${OAUTH_CONNECTED_SHARED_TOKENS_PATH}/${authId}`,
    query: { revokeTokenUrl },
  });
}

export function revokeAndDeleteUserConnectedTokens(
  authId: string,
  revokeTokenUrl: string,
) {
  return callServer<ConnectedUserTokenDto[]>({
    method: HttpMethod.Delete,
    url: `${OAUTH_CONNECTED_USER_TOKENS_PATH}/${authId}`,
    query: { revokeTokenUrl },
  });
}

export function clearSecretStoreCache({
  organization,
  datasourceSlug,
  agents,
  configurationId,
}: {
  agents: Agent[];
  organization: Organization;
  datasourceSlug: string;
  configurationId: string;
}) {
  const payload = {
    method: HttpMethod.Post,
    url: OrchestratorApiPaths.CLEAR_CACHE,
    params: { datasourceSlug, configurationId },
    baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
    agents,
    organization,
  };

  return callWithExpectedError<Record<string, never>>(payload);
}

export function getSecretMetadata(
  agents: Agent[],
  organization: Organization,
  datasourceSlug: string,
  environment: string,
) {
  const query: Record<string, string> = {
    "profile.name": environment,
  };

  return callOrchestrator<DatasourceMetadataDto>(
    {
      method: HttpMethod.Get,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      url: OrchestratorApiPaths.SECRET_METADATA,
      query,
      agents,
      organization,
      params: { datasourceSlug },
    },
    {
      notifyOnError: false,
    },
  );
}
