export enum AgentApiPaths {
  // The /agent root resource which is part of the
  // agent host url acts as the health endpoint
  HEALTHCHECK = "",
  API_EXECUTE = "v1/apis/execute",
  DATASOURCE_TEST = "v1/datasources/test",
  // datasource metadata
  DATASOURCE_METADATA = "v1/datasources/:datasourceId/metadata",
  DATASOURCE_PRE_DELETE = "v1/datasources/:datasourceId",

  CHECK_AUTH = "v1/auth/check-auth",
  AUTH_LOGIN = "v1/auth/login",
  AUTH_LOGOUT = "v1/auth/logout",
  EXCHANGE_CODE = "v1/auth/exchange-code",
  REQUEST_TOKEN = "v1/auth/request-token",
}
