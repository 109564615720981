import { VcsProvider } from "@superblocksteam/shared";
import { Input, Typography } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";

import { PrimaryButton } from "components/ui/Button";
import {
  DropdownOption,
  OptionIconWrapper,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { SpanTruncMiddle } from "components/ui/SpanTruncMiddle";
import { SUPERBLOCKS_UI_GITHUB_APP_SLUG } from "env";
import { usePageVisibility } from "hooks/ui";
import { SessionStorageKey } from "legacy/utils/sessionStorage";
import { colors } from "styles/colors";
import { Repository, getProviderIcon } from "./utils";

const RepoNameCell = styled.td`
  display: flex;
  padding: 8px 18px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const RepoActionsCell = styled.td`
  display: flex;
  padding: 8px 18px;
  justify-content: flex-end;
  align-items: center;
  button > span {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 16px;
  }
`;

const RepoListWrapper = styled.div`
  width: 100%;
  height: 220px;
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  border-radius: 4px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const RepoName = styled.span`
  color: #000;
  text-align: center;
  font-weight: 600;
  line-height: 16px;
  width: 368px;
`;

const RepoList = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ListItemWrapper = styled.tr`
  display: flex;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.GREY_100};
  min-height: 48px;

  &:first-child {
    border-top: none;
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.GREY_100};
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
`;

const SetupButton = styled(PrimaryButton)`
  font-size: 12px;
`;

const RepoListItem = ({
  repo,
  handleSetup,
}: {
  repo: Repository;
  handleSetup: (repo: Repository) => void;
}) => {
  const ProviderIcon = getProviderIcon(repo.provider);
  const onSetup = useCallback(() => {
    handleSetup(repo);
  }, [handleSetup, repo]);

  return (
    <ListItemWrapper>
      <RepoNameCell>
        <ProviderIcon />
        {/* TODO:git, show display name of both owner and repo */}
        <RepoName>
          <SpanTruncMiddle text={`${repo.owner?.name}/${repo.name}`} />
        </RepoName>
      </RepoNameCell>
      <RepoActionsCell>
        <SetupButton onClick={onSetup}>Set up →</SetupButton>
      </RepoActionsCell>
    </ListItemWrapper>
  );
};
const ChooseRepositoryWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  margin-bottom: 12px;
`;

const DropdownWrapper = styled.div`
  flex-basis: 40%;
  width: 40%;
`;

const SearchBoxWrapper = styled.div`
  flex-grow: 1;
  input {
    border-color: ${(props) => props.theme.colors.GREY_100};
  }
`;

const CenteredDiv = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.GREY_700};
  .no-repo-found {
    color: ${(props) => props.theme.colors.GREY_300};
  }
  .no-repo-found-bold {
    color: ${(props) => props.theme.colors.GREY_300};
    font-weight: 700;
  }
`;

// This link serves 2 purposes:
// 1. It allows users to install the GitHub app for a specific account/org to allow read/write access to repositories.
// 2. It allows users to adjust the permissions of the GitHub app.
// For the 1st use, users are expected to select the account/org for which they want to install the app, and then select either `All repositories` or specify a subset of that account's repositories via `Only select repositories`.
// For the 2nd use, users are expected to select the account/org for which they want to adjust permissions, and these accounts should have the `Configure` text.
// Currently, the permissions we require for connecting and syncing are `Read & write` for `Contents` and `Read-only` for `Metadata`.
const GITHUB_APP_INSTALLATION_LINK = `https://github.com/apps/${SUPERBLOCKS_UI_GITHUB_APP_SLUG}/installations/select_target`;

const GITHUB_CREATE_REPO_LINK = `https://github.com/new`;
const GITLAB_CREATE_REPO_LINK = `https://gitlab.com/projects/new`;

interface ChooseRepositoryProps {
  reposByInstallation: Record<string, Repository[]>;
  handleSetup: (repo: Repository) => void;
  refreshOrgsList: () => void;
  iconsByInstallation: Record<string, string>;
  provider: VcsProvider;
  connectToProvider: (provider: VcsProvider) => void;
  orgToDisplayName: Record<string, string>;
}

const ChooseRepository = ({
  reposByInstallation,
  handleSetup,
  refreshOrgsList,
  iconsByInstallation,
  provider,
  connectToProvider,
  orgToDisplayName,
}: ChooseRepositoryProps) => {
  const [lastVisible, setLastVisible] = useState(true);
  const documentVisible = usePageVisibility();

  const orgs = useMemo(
    () => Object.keys(reposByInstallation),
    [reposByInstallation],
  );

  const [selectedOrg, setSelectedOrg] = useState(orgs[0] || "");

  useEffect(() => {
    // Open the GitHub app installation link in a new tab if the user has not installed the app for any account/org.
    // This is done only once per session.
    if (
      isEmpty(orgs) &&
      isEmpty(
        sessionStorage.getItem(SessionStorageKey.GITHUB_INSTALL_AUTO_TRIGGERED),
      )
    ) {
      if (provider === VcsProvider.GITHUB) {
        sessionStorage.setItem(
          SessionStorageKey.GITHUB_INSTALL_AUTO_TRIGGERED,
          "true",
        );

        window.open(
          GITHUB_APP_INSTALLATION_LINK,
          "githubAppInstallation",
          "noopener noreferrer",
        );
      }
    } else if (!isEmpty(orgs)) {
      setSelectedOrg(orgs[0]);
    }
  }, [orgs, provider]);

  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    // Refresh orgs list if the previous state was hidden and the current state is visible.
    if (documentVisible && !lastVisible) {
      refreshOrgsList();
      setLastVisible(true);
    } else if (!documentVisible) {
      setLastVisible(false);
    }
  }, [documentVisible, lastVisible, refreshOrgsList]);

  const handleOrgChange = (option: DropdownOption) => {
    const value = option.value;
    if (value === "add-account") {
      window.open(
        GITHUB_APP_INSTALLATION_LINK,
        "githubAppInstallation",
        "noopener noreferrer",
      );
    } else {
      setSelectedOrg(value as string);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const filteredRepos = useMemo(() => {
    const repos = reposByInstallation?.[selectedOrg] || [];
    return repos.filter((repo) =>
      repo.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [reposByInstallation, searchValue, selectedOrg]);

  const ProviderIcon = getProviderIcon(provider);
  const orgOptions: DropdownOption[] = useMemo(() => {
    const options: DropdownOption[] = orgs.map((org) => {
      return {
        key: org,
        value: org,
        displayName: orgToDisplayName[org] ?? org,
        icon: (
          <OptionIconWrapper>
            {iconsByInstallation[org] ? (
              <img src={iconsByInstallation[org]} alt="org-icon" />
            ) : (
              <ProviderIcon />
            )}
          </OptionIconWrapper>
        ),
      };
    });
    if (provider === VcsProvider.GITHUB) {
      options.push({
        key: "add-account",
        value: "add-account",
        displayName: "Add GitHub account",
        icon: (
          <OptionIconWrapper>
            <PlusIcon color={colors.GREY_500} />
          </OptionIconWrapper>
        ),
      });
    }
    return options;
  }, [ProviderIcon, iconsByInstallation, orgToDisplayName, orgs, provider]);

  const parentRef = React.useRef<HTMLDivElement>(null);
  return (
    <ChooseRepositoryWrapper ref={parentRef}>
      {!isEmpty(selectedOrg) && (
        <FilterWrapper>
          <DropdownWrapper>
            <RecommendedSingleDropdown
              renderSelectedOptionWithStyles
              options={orgOptions}
              onChange={handleOrgChange}
              value={selectedOrg}
              parentRef={parentRef}
            />
          </DropdownWrapper>
          <SearchBoxWrapper>
            <Input
              onChange={handleSearchChange}
              value={searchValue}
              // TODO(aayush): Search icon.
              placeholder="Search"
            />
          </SearchBoxWrapper>
        </FilterWrapper>
      )}
      <RepoListWrapper
        style={{
          height: isEmpty(selectedOrg) ? 163 : 220,
          justifyContent: filteredRepos.length > 0 ? "flex-start" : "center",
        }}
      >
        {isEmpty(selectedOrg) ? (
          <CenteredDiv>
            {provider === VcsProvider.GITHUB && (
              <>
                <a
                  href={GITHUB_APP_INSTALLATION_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Install Superblocks Github App
                </a>{" "}
                to connect its repositories.
              </>
            )}

            {provider === VcsProvider.GITLAB && (
              <>
                <Typography.Link onClick={() => connectToProvider(provider)}>
                  Update access token
                </Typography.Link>{" "}
                to connect its repositories.
              </>
            )}
          </CenteredDiv>
        ) : filteredRepos.length > 0 ? (
          <RepoList>
            <tbody>
              {filteredRepos.map((repo) => (
                <RepoListItem
                  key={repo.id}
                  repo={repo}
                  handleSetup={handleSetup}
                />
              ))}
            </tbody>
          </RepoList>
        ) : !isEmpty(searchValue) ? (
          <CenteredDiv>
            <span className="no-repo-found">
              No repository found named &nbsp;
            </span>
            <span className="no-repo-found-bold">{searchValue}</span>
          </CenteredDiv>
        ) : (
          <CenteredDiv>
            <span className="no-repo-found">No repository in &nbsp;</span>
            <span className="no-repo-found-bold">{selectedOrg}</span>
          </CenteredDiv>
        )}
      </RepoListWrapper>

      {!isEmpty(selectedOrg) && (
        <>
          <div style={{ marginTop: "12px" }}>
            <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
              Missing Git repository?{" "}
              {provider === VcsProvider.GITHUB && (
                <a
                  href={GITHUB_APP_INSTALLATION_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Adjust GitHub App Permissions
                </a>
              )}
              {provider === VcsProvider.GITLAB && (
                <>
                  <Typography.Link onClick={() => connectToProvider(provider)}>
                    Update access token
                  </Typography.Link>{" "}
                  or token permissions
                </>
              )}
            </Typography.Text>
          </div>
          <Footer>
            <Typography.Text type="secondary" style={{ fontSize: "13px" }}>
              Want to sync changes to a new Git repository? &nbsp;
              <a
                href={
                  provider === VcsProvider.GITHUB
                    ? GITHUB_CREATE_REPO_LINK
                    : GITLAB_CREATE_REPO_LINK
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Create a repository
              </a>
            </Typography.Text>
          </Footer>
        </>
      )}
    </ChooseRepositoryWrapper>
  );
};

export default ChooseRepository;
