import { createSelector } from "reselect";
import { createMarkedSelector } from "./markedSelector";
import type { AppState } from "store/types";

const getEvaluatorLifecycle = (state: AppState) =>
  state.legacy.evaluations.lifecycle;

export const getIsEvaluating = createMarkedSelector("getIsEvaluating")(
  getEvaluatorLifecycle,
  (lifecycle) => {
    if (!lifecycle.lastStartTime) return false;
    if (!lifecycle.lastCompleteTime) return true;
    if (lifecycle.isNextCycleQueued) return true;

    return +lifecycle.lastStartTime > +lifecycle.lastCompleteTime;
  },
);

export const getHasEvaluated = createMarkedSelector("getHasEvaluated")(
  getEvaluatorLifecycle,
  (lifecycle) => !!lifecycle.lastCompleteTime,
);

export const getLastEvaluationStartTime = createMarkedSelector(
  "getLastEvaluationStartTime",
)(getEvaluatorLifecycle, (lifecycle) => lifecycle.lastStartTime);

export const getHasStartedPageLoad = createSelector(
  getEvaluatorLifecycle,
  (lifecycle) => !!lifecycle.startedPageLoadApis,
);
