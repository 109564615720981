import { Progress } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import { selectVisibleChecklist } from "store/slices/user";
import { getTaskSchema } from "./tasks";

const ContainerWrapper = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "grid" : "none")};
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.HOVER_GREEN};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.CLICK_GREEN};
  }
`;

const ChecklistButton = () => {
  const checklist = useSelector(selectVisibleChecklist);
  const totalSteps = checklist.length;
  const stepsComplete = Math.min(
    totalSteps,
    (checklist ?? [])
      .filter((t) => t.completed)
      .filter((t) => !getTaskSchema(t.id)?.hidden).length,
  );

  const theme = useTheme();
  const show = totalSteps !== stepsComplete;

  return (
    <ContainerWrapper
      show={show}
      data-test={`checklist-button${show ? "" : "-hidden"}`}
    >
      <ChecklistIcons.TROPHY_ICON
        color={theme.colors.WHITE}
        padding={12}
        width={40}
        height={40}
        marginTop={1}
      />
      <Progress
        percent={(stepsComplete / totalSteps) * 100}
        showInfo={false}
        strokeColor={theme.colors.ACCENT_GREEN}
        width={28}
        style={{ padding: 6 }}
        trailColor={theme.colors.CLICK_GREEN}
        type="circle"
      />
    </ContainerWrapper>
  );
};

export default ChecklistButton;
