import {
  ButtonClickPayload,
  LoadButtonPayloadInit,
  LoadButtonPayloadSuccess,
} from "@superblocksteam/shared";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

export const dynamicFormButtonClick = (payload: ButtonClickPayload) => {
  return {
    type: ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK,
    payload,
  };
};

export const loadDynamicFormButtonInit = (payload: LoadButtonPayloadInit) => {
  return {
    type: ReduxActionTypes.LOAD_DYNAMIC_FORM_BUTTON_INIT,
    payload,
  };
};

export const loadDynamicFormButtonSuccess = (
  payload: LoadButtonPayloadSuccess,
) => {
  return {
    type: ReduxActionTypes.LOAD_DYNAMIC_FORM_BUTTON_SUCCESS,
    payload,
  };
};

export const resetDynamicFormState = () => {
  return {
    type: ReduxActionTypes.RESET_DYNAMIC_FORM,
  };
};
