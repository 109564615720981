import { ApplicationScope } from "@superblocksteam/shared";
import { useCallback, useMemo } from "react";
import { Dispatch } from "redux";
import { useAppSelector } from "store/helpers";
import { AppTimer } from "store/slices/application/timers/TimerConstants";
import { getTimerById } from "store/slices/application/timers/selectors";
import { deleteTimer } from "store/slices/application/timers/timerActions";
import { updateTimers } from "store/slices/application/timers/timerActions";
import { NOOP } from "utils/function";
import { timerIcon } from "../../Explorer/ExplorerIcons";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import { type ItemKindAccessor } from "../ItemKinds";

export const TimerAccessor: ItemKindAccessor<ItemKinds.TIMER> = {
  useItemName: (itemProperties) =>
    useMemo(() => {
      return {
        name: itemProperties.name,
        editable: true,
        requiresValidation: true,
      };
    }, [itemProperties.name]),
  itemType: (itemProperties) => ItemTypeNonWidget.TIMER,
  useItemProperties: (itemId: string) => {
    return useAppSelector(
      useCallback((state) => getTimerById(state, itemId), [itemId]),
    );
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: AppTimer,
    updates: Record<string, unknown>,
    scope: ApplicationScope,
  ) => {
    dispatch(updateTimers({ [properties.id]: { updates, scope } }));
  },
  deleteItemProperties: NOOP,
  deleteItem: (dispatch, timerId, itemScope) => {
    dispatch(deleteTimer(itemScope, timerId));
  },
  icon: () => timerIcon,
};
