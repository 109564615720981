import { ApplicationScope } from "@superblocksteam/shared";

const VALID_JS_IDENTIFIER_REGEX = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/;

// Previous function
export function getDottedPathTo(name: string | number) {
  if (typeof name === "number") {
    return `[${name}]`;
  }
  if (!isValidJSIdentifier(name)) {
    return `["${name.replaceAll(`"`, `\\"`)}"]`;
  }
  return `.${name}`;
}

export function isValidJSIdentifier(name: string) {
  return VALID_JS_IDENTIFIER_REGEX.test(name);
}

export function splitJSPath(path: string): string[] | null {
  return splitJSPathAdvanced(path, null);
}

export function splitJSPathAdvanced<T>(
  path: string,
  emptyValue: T,
): string[] | T {
  /* Match JS variables using a regex. It would be simpler to use a real parser,
  especially if future changes are needed here.

  (?:\['(?:[^']|\\.)+'\])      match single quoted array access, ignoring escaped chars
  |
  (?:\["(?:[^"]|\\.)+"\])      match double quoted array access, ignoring escaped chars
  |
  \[\d+\]                      match array access, e.g. [0]
  |
  [^.\[\]]+                    match regular variable names, e.g. name
  /g                           global
  */

  const matchSimpleStrings =
    /(?:\['(?:[^']|\\.)+'\])|(?:\["(?:[^"]|\\.)+"\])|\[\d+\]|[^.\[\]]+/g;
  const matches = path.match(matchSimpleStrings);
  if (!matches || matches.length < 1) {
    return emptyValue;
  }
  return matches;
}

export function stringifySplitPaths(paths: string[]) {
  const str: string[] = [...paths];
  const finalResult = [];
  for (const segment of str) {
    if (segment === ".") {
      if (finalResult.length === 0) {
        // empty string becomes . with .join(".")
        finalResult.push("");
      }
    } else if (segment.startsWith("[")) {
      finalResult[finalResult.length - 1] += segment;
    } else {
      finalResult.push(segment);
    }
  }
  return finalResult.join(".");
}

export function getParentPath(path: string): string | null {
  const matches = splitJSPath(path);
  if (matches) {
    return stringifySplitPaths(matches.slice(0, matches.length - 1));
  }
  return null;
}

export function getAllParentPaths(path: string): string[] {
  const matches = splitJSPathAdvanced(path, []);
  return matches
    .map((match, index, matches) =>
      match === "."
        ? undefined
        : stringifySplitPaths(matches.slice(0, index + 1)),
    )
    .filter((match) => match !== undefined) as string[];
}

export function getFirstPathSegments(path: string, n: number) {
  const segments = (splitJSPath(path) ?? [])
    .filter((c) => c !== ".")
    .slice(0, n);
  return stringifySplitPaths(segments);
}

// TODO consolidate with getParentPath. See https://github.com/superblocksteam/superblocks/pull/9238/files#r1707534794
export function removeLastPathSegments(path: string, n: number) {
  const result = (splitJSPath(path) ?? [])
    .filter((c) => c !== ".")
    .slice(0, -n);
  return stringifySplitPaths(result);
}

export function getScopeAndEntityName(
  path: `${ApplicationScope}.${string}${string}`,
) {
  const [scope, entityName, ...rest] = splitJSPath(path) ?? [];
  if (
    !scope ||
    (scope !== ApplicationScope.PAGE &&
      scope !== ApplicationScope.GLOBAL &&
      scope !== ApplicationScope.APP)
  ) {
    throw new Error(`Invalid scope: ${path}`);
  }
  return {
    scope: scope as ApplicationScope,
    entityName,
    rest,
  };
}

export function pathToScopedEntityName(
  path: `${ApplicationScope}.${string}${string}`,
) {
  const { scope, entityName } = getScopeAndEntityName(path);
  return `${scope}.${entityName}` as const;
}

export function getScope(path: `${ApplicationScope}.${string}${string}`) {
  return getScopeAndEntityName(path).scope;
}

export function getEntityName(path: `${ApplicationScope}.${string}${string}`) {
  return getScopeAndEntityName(path).entityName;
}
