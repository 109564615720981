import { ErrorTypes as FormattingErrorTypes } from "code-formatting/constants";
import { ApiV2ExecutionResponse } from "./backend-types";
import type { SharedExecutionOutput } from "../apisShared";

export type ExecutionResponse =
  | ApiV2ExecutionResponse
  | {
      systemError: string;
    };

export type BlockExecutionInfo = {
  executions: SharedExecutionOutput[];
  numFailed: number;
  numHandled: number;
  numPassed: number;
  numStarted: number;
};

export type EnrichedExecutionResult = {
  apiResult?: SharedExecutionOutput;
  lastOutputs?: Record<string, SharedExecutionOutput>;
  blockInfos?: Record<string, BlockExecutionInfo>;
  orderedExecutions?: SharedExecutionOutput[];
  systemError?: string;
  executionError?: string;
};

export type EnrichedExecutionProcessingOptions = {
  mergeExecutionEvents: boolean;
  blocksToReinitialize: string[];
  sendErrorOnUnknownBlockErrors?: boolean;
};

export enum FormattingStatus {
  STARTED = "started",
  FAILED = "failed",
}

export type BlockFormatting = {
  status: FormattingStatus;
  error?: FormattingErrorTypes;
};

export type ApiFormatting = {
  manualRun: boolean;
};
