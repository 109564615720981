import {
  LanguagePluginID,
  Plugin,
  isReadableFile,
  EvaluationPair,
  extractPythonEvaluationPairsWithParser,
  PythonParser,
  getDynamicBindings,
} from "@superblocksteam/shared";
import { isArray, get, isObject } from "lodash";
import { FileManager } from "legacy/widgets/FilepickerWidget/FilePickerSingleton";
import {
  extractJsEvaluationBindings,
  extractJsEvaluationPairs,
  extractPythonEvaluationBindings,
} from "legacy/workers/evaluationUtils";
import { ScopeSpecificDataTree } from "utils/dataTree/MergedDataTree";
import { getPluginById } from "utils/integrations";
import { ActionDto, ActionsDto, PropertyDto } from "../types";
import { recursivelyExtractValues } from "./recursivelyExtractValues";

export async function extractBindingsFromValueWithRanges(
  value: any,
  identifiers: string[],
  pluginID: string,
  dataTree: Record<string, unknown>,
): Promise<EvaluationPair[]> {
  if (value?.toString) {
    const stringValue = value.toString();
    if (pluginID === LanguagePluginID.JavaScript) {
      return await extractJsEvaluationPairs(
        stringValue,
        new Set(identifiers),
        dataTree,
      );
    } else if (pluginID === LanguagePluginID.Python) {
      const { parser } = await import(
        /* webpackChunkName: "python" */ "@lezer/python"
      );
      const result = await extractPythonEvaluationPairsWithParser(
        stringValue,
        new Set(identifiers),
        dataTree,
        parser as unknown as PythonParser,
      );
      return result;
    }

    const { positionedSnippets } = getDynamicBindings(stringValue);
    return (
      await Promise.all(
        positionedSnippets.map(async (jsSnippet) => {
          const pairs = await extractJsEvaluationPairs(
            jsSnippet.str,
            new Set(identifiers),
            dataTree,
          );
          // format offsets
          for (const pair of pairs) {
            pair.range.start = pair.range.start + jsSnippet.from;
            pair.range.end = pair.range.end + jsSnippet.from;
          }
          return pairs;
        }),
      )
    ).flat();
  }

  return [];
}

export async function extractBindingsFromValue(
  value: any,
  identifiers: Set<string>,
  pluginID: string,
  dataTree: ScopeSpecificDataTree,
) {
  if (value?.toString) {
    const stringValue = value.toString();
    if (pluginID === LanguagePluginID.JavaScript) {
      return await extractJsEvaluationBindings(
        stringValue,
        identifiers,
        dataTree,
      );
    } else if (pluginID === LanguagePluginID.Python) {
      const result = await extractPythonEvaluationBindings(
        stringValue,
        identifiers,
        dataTree,
      );
      return result;
    }

    const { jsSnippets } = getDynamicBindings(stringValue);
    return (
      await Promise.all(
        jsSnippets.map((jsSnippet) =>
          extractJsEvaluationBindings(jsSnippet, identifiers, dataTree),
        ),
      )
    ).flat();
  }

  return [];
}

async function extractBindingsFromNotifications(
  actions: ActionsDto,
  identifiers: string[],
  dataTree: ScopeSpecificDataTree,
) {
  const notificationActions = [
    {
      name: "Success UI Notification",
      toResolve: actions.notificationConfig?.onSuccess?.customText,
    },
    {
      name: "Error UI Notification",
      toResolve: actions.notificationConfig?.onError?.customText,
    },
  ];

  const evaluatedActions = notificationActions.map(async (action) => {
    const { jsSnippets } = getDynamicBindings(action.toResolve ?? "");
    const resolvedStrings = await Promise.all(
      jsSnippets.map((jsSnippet) =>
        extractJsEvaluationBindings(jsSnippet, new Set(identifiers), dataTree),
      ),
    );
    return {
      action: action.name,
      bindings: resolvedStrings.flat(),
    };
  });

  return await Promise.all(evaluatedActions);
}

const isPropertyDto = (value: any): value is PropertyDto => {
  return value && value.key && value.value;
};
export async function extractBindingsFromAction(
  action: ActionDto,
  identifiers: Set<string>,
  plugin: Plugin,
  dataTree: ScopeSpecificDataTree,
) {
  // recursively extract
  return (
    await Promise.all(
      Object.values(action.configuration).flatMap((value) => {
        const extracted =
          isObject(value) || isArray(value)
            ? recursivelyExtractValues(value)
            : [value as PropertyDto | string | boolean | number];

        const bindings = [];
        for (const toExtract of extracted) {
          if (isPropertyDto(toExtract)) {
            bindings.push(
              extractBindingsFromValue(
                toExtract.key,
                identifiers,
                plugin.id,
                dataTree,
              ),
            );
            bindings.push(
              extractBindingsFromValue(
                toExtract.value,
                identifiers,
                plugin.id,
                dataTree,
              ),
            );
          } else {
            bindings.push(
              extractBindingsFromValue(
                toExtract,
                identifiers,
                plugin.id,
                dataTree,
              ),
            );
          }
        }
        return bindings;
      }),
    )
  ).flat();
}

export async function extractBindingsFromActions(
  actions: ActionsDto | undefined,
  identifiers: string[],
  dataTree: ScopeSpecificDataTree,
): Promise<Array<{ action: string; bindings: string[] }>> {
  if (!actions) {
    return [];
  }

  const notificationBindings = await extractBindingsFromNotifications(
    actions,
    identifiers,
    dataTree,
  );

  const identifierSet = new Set(identifiers);

  const stepBindings = await Promise.all(
    Object.values(actions?.actions ?? {})
      .map((action) => {
        const plugin = getPluginById(action.pluginId);
        if (plugin) {
          return { action, plugin };
        }
        throw new Error(`Plugin "${action.pluginId}" not found`);
      })
      .map(async ({ action, plugin }) => ({
        action: action.name,
        bindings: await extractBindingsFromAction(
          action,
          identifierSet,
          plugin,
          dataTree,
        ),
      })),
  );

  return [...notificationBindings, ...stepBindings];
}

export async function extractBindingStringsFromActions(
  actions: ActionsDto,
  identifiers: string[],
  dataTree: ScopeSpecificDataTree,
): Promise<string[]> {
  return (
    await extractBindingsFromActions(actions, identifiers, dataTree)
  ).flatMap((binding) => binding.bindings);
}

export function convertValuesToParams(bindings: string[], values: unknown[]) {
  return Array(Math.max(bindings.length, values.length))
    .fill(undefined)
    .map((_, i) => ({
      key: bindings[i],
      value: values[i],
    }));
}

// Extracts the property ordering for each nested file, which can be used
// by lodash to extract the specific files.
// [
//   ['FilePicker1', 'files', '0']
// ]
function getFilePaths(root: unknown, path: string[] = []): string[][] {
  const paths: string[][] = [];
  if (!root || !(typeof root === "object")) {
    return paths;
  }
  if (Array.isArray(root)) {
    root.forEach((v, i) => {
      paths.push(...getFilePaths(v, [...path, i.toString()]));
    });
    return paths;
  }
  if (isReadableFile(root)) {
    return [path];
  }
  Object.entries(root as Record<string, unknown>).forEach(([key, value]) => {
    if (isReadableFile(value)) {
      paths.push([...path, key]);
    } else if (value && Array.isArray(value)) {
      value.forEach((v, i) => {
        paths.push(...getFilePaths(v, [...path, key, i.toString()]));
      });
    } else if (value && typeof value === "object") {
      paths.push(...getFilePaths(value, [...path, key]));
    }
  });
  return paths;
}

// We will extract files as long as any top-level entity contains files, like
// if all we can detect is that the user references FilePicker1
export async function extractAttachedFiles(
  dynamicValues: ReturnType<typeof convertValuesToParams>,
): Promise<Record<string, File>> {
  const files: Record<string, File> = {};
  dynamicValues.forEach(({ key, value }) => {
    if (isReadableFile(value)) {
      const id = value?.$superblocksId;
      const file = FileManager.get(key.split(".")[0], id);
      if (file) {
        files[id] = file;
      }
      return;
    }
    const foundFiles = getFilePaths(value);
    foundFiles.forEach((path) => {
      const id = get(value, path)?.$superblocksId;
      // Extracts the component name, for example FilePicker1- the dynamic bindings
      // sometimes have dots in them, for example if we are actually binding to FilePicker1.files
      const file = FileManager.get(key.split(".")[0], id);
      if (file) {
        files[id] = file;
      }
    });
  });
  return files;
}
