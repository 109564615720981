export interface CreateRepositoryRequest {
  name: string;
  owner: string;
  configFileDirectory: string;
  defaultBranch: string;
  protectDefaultBranch: boolean;
  installationId?: string;
  provider: VcsProvider;
}

export interface FetchAppFlobsRequest {
  path: string;
  branch: string;
  repo: string;
  owner: string;
  installationId?: string; // GitHub only
  provider: VcsProvider;
}

export enum RepositoryEntityType {
  APPLICATION = 'application',
  WORKFLOW = 'workflow',
  SCHEDULED_JOB = 'scheduled_job'
}

export interface UpdateBranchProtectionRequest {
  protected: boolean;
}

export interface RepositoryDto {
  id: string;
  name: string;
  owner: string;
  configFileDirectory: string;
  defaultBranch: string;
  githubInstallationId: string | null;
  provider: VcsProvider;
}

export enum VcsProvider {
  GITHUB = 'github',
  GITLAB = 'gitlab'
}

export interface RepositoryWithEntitiesDto extends RepositoryDto {
  entities: {
    id: string;
    name: string;
    type: RepositoryEntityType;
    lastSyncedTo: Date;
  }[];
}

export const getRemoteGitHubUrl = (owner: string, name: string): string => {
  return `https://github.com/${owner}/${name}`;
};

export const getRemoteGitLabUrl = (owner: string, name: string): string => {
  return `https://gitlab.com/${owner}/${name}`;
};

// This function is used to get the owner and name of a repository from a github url.
// Example: `https://github.com/superblocksteam/monorepo` => { owner: 'superblocksteam', name: 'monorepo' }
export const getOwnerAndNameFromGitHubUrl = (
  remoteUrl: string
): {
  owner: string;
  name: string;
} => {
  const [owner, name] = remoteUrl.replace('https://github.com/', '').split('/');
  return {
    owner,
    name
  };
};
