import { createCachedSelector } from "re-reselect";
import { AdditionalScopeProps } from "autocomplete/types";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import { selectV2ApiById, selectV2ApiMetaById } from "../selectors";
import { computeBlockScope } from "./computeBlockScope";
import { selectCachedControlFlowById } from "./control-flow-selectors";
import type { ApiDtoWithPb, ApiV2Meta } from "../slice";
import type { ControlFlowFrontendDSL } from "./types";
import type { ApiScope } from "utils/dataTree/scope";

export const selectV2UserAccessibleScope = createCachedSelector(
  selectCachedControlFlowById,
  selectV2ApiById,
  selectV2ApiMetaById,
  (
    state: unknown,
    apiId: string | undefined,
    blockName: string | undefined,
    additionalScopeProps?: AdditionalScopeProps,
  ) => blockName,
  (
    state: unknown,
    apiId: string | undefined,
    blockName: string | undefined,
    additionalScopeProps?: AdditionalScopeProps,
  ) => additionalScopeProps,
  (
    controlFlow: undefined | ControlFlowFrontendDSL,
    api: undefined | ApiDtoWithPb,
    apiMeta: undefined | ApiV2Meta,
    blockName: string | undefined,
    additionalScopeProps?: AdditionalScopeProps,
  ): ApiScope | undefined => {
    if (!controlFlow || !blockName || !api) {
      return;
    }
    const executionResult = apiMeta?.enrichedExecutionResult;
    const scope = computeBlockScope(blockName, {
      controlFlow,
      executionResult,
      additionalScopeProps,
    });

    return {
      apiName: getV2ApiName(api),
      v2ComputedScope: scope,
      previousV1ActionNames: [],
    };
  },
)(
  (state, apiId: string | undefined, blockName?: string | undefined) =>
    `${apiId}_${blockName}`,
);
