import React, { memo, useEffect, useState, ComponentType } from "react";
import PageLoadingBar from "legacy/pages/common/PageLoadingSpinner";
import { retryPromise } from "legacy/utils/Utils";

const CheckoutLoader = (props: React.PropsWithChildren<any>) => {
  const [Component, setComponent] = useState<ComponentType>(
    () => PageLoadingBar,
  );

  useEffect(() => {
    const abortController = new AbortController();

    retryPromise(
      () => import(/* webpackChunkName: "checkout" */ "."),
      abortController.signal,
    ).then((module) => setComponent(() => module.default));

    return () => abortController.abort();
  }, []);

  return <Component {...props} />;
};

export default memo(CheckoutLoader);
