import { Space, Tag } from "antd";
import React from "react";
import styled from "styled-components";
import SbText from "components/ui/SbText";

const radioAttrs = (props: { isSelected: boolean }) => ({
  isSelected: props.isSelected,
});

const RadioInner = styled.div.attrs(radioAttrs)`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.ACCENT_BLUE_500 : "white"};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  line-height: 12px;
`;

const RadioOuter = styled.div.attrs(radioAttrs)`
  margin: 0 8px;
  background-color: "white";
  border-radius: 50%;
  border-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.ACCENT_BLUE_500
      : props.theme.colors.GREY_300};
  border-width: 1px;
  border-style: solid;
  height: 18px;
  width: 18px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const RadioIndicator = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <RadioOuter isSelected={isSelected}>
      <RadioInner isSelected={isSelected} />
    </RadioOuter>
  );
};

type PricingSelectorOption = {
  title: string;
  tag?: string;
  monthly_price: number;
  key: string;
};

type PricingSelectorProps = {
  onChange: (v: string) => void;
  value: string;
  items: PricingSelectorOption[];
};

const RadioElementContents = styled.div`
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.GREY_100};
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 20px 10px;
  cursor: pointer;
`;

const RadioElement = ({
  item,
  isSelected,
  onClick,
}: {
  onClick: (v: string) => void;
  item: PricingSelectorOption;
  isSelected: boolean;
}) => {
  return (
    <RadioElementContents>
      <div
        onClick={() => onClick(item.key)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space direction="horizontal" size={10}>
          <RadioIndicator isSelected={isSelected} />
          <Space direction="horizontal">
            <div style={{ fontWeight: 500, fontSize: 14, color: "#454D5F" }}>
              {item.title}
            </div>
            {item.tag && (
              <Tag color="green" style={{ fontSize: 12 }}>
                <b>{item.tag}</b>
              </Tag>
            )}
          </Space>
        </Space>
        <div>
          <Space direction="vertical" size={4} align="end">
            <Space direction="horizontal">
              <SbText style={{ fontSize: 18, fontWeight: 700 }}>
                ${item.monthly_price}{" "}
              </SbText>
              <SbText style={{ fontSize: 13, fontWeight: 400 }}>/ month</SbText>
            </Space>
          </Space>
        </div>
      </div>
    </RadioElementContents>
  );
};

const PricingSelector = ({ items, value, onChange }: PricingSelectorProps) => {
  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      {items.map((item) => (
        <RadioElement
          onClick={(newValue: string) => {
            if (newValue !== value) {
              onChange(newValue);
            }
          }}
          item={item}
          isSelected={item.key === value}
          key={item.key}
        />
      ))}
    </Space>
  );
};

export default PricingSelector;
