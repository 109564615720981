import styled from "styled-components";
import { colors } from "styles/colors";

export const SectionTitle = styled.div`
  transition: padding 0.2s;
  padding: 10px 15px 10px 20px;

  user-select: none;

  &[data-header="Large"] {
    padding-top: 18px;
    padding-bottom: 0px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  &[data-header="Collapse"] {
    cursor: pointer;
  }

  & span {
    color: ${colors.GREY_900};
    padding: ${(props) => props.theme.legacy.spaces[2]}px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    &[data-header="Large"] {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
    &[data-header="Secondary"] {
      color: ${colors.GREY_300};
      font-weight: 400;
      flex-shrink: 0;
      max-width: 100%;
    }
  }

  &[data-header="Secondary"] {
    .t--property-pane-section-secondary-divider {
      border-top: 1px solid ${colors.GREY_100};
      margin-left: 12px;
      height: 1px;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 20px;
    }
  }
`;

export const SectionSubTitle = styled.div`
  color: ${colors.GREY_500};
  font-size: 13px;
  line-height: 20px;
  width: max-content;
  padding-top: 0px;
  font-weight: 400;
`;
