import { Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useRef, useState } from "react";
import { DragPreviewImage } from "react-dnd";
import { useHotkeys } from "react-hotkeys-hook";
import Popper, { PopperProps } from "components/ui/Popper";
import {
  CustomRenderProps,
  ItemsType,
  SearchableListPopup,
  SearchListItem,
} from "components/ui/SearchableListPopup";
import { MenuItem } from "components/ui/dropdowns/MenuItem";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { useInsertWidgetByType } from "hooks/ui/useInsertWidgetByType";
import { usePointerDownOutside } from "hooks/ui/usePointerDownOutside";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import blankImage from "legacy/assets/images/blank.png";
import {
  getEditorReadOnly,
  getIsLeftPanePinned,
} from "legacy/selectors/editorSelectors";
import { getSelectedWidgets } from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { getAvailableWidgets } from "store/slices/availableWidgets/selectors";
import { Flag } from "store/slices/featureFlags/models/Flags";
import {
  setInsertionContext,
  showUiBlocksModal,
} from "store/slices/uiBlocks/slice";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";
import { PlusButton } from "../Entity/HeaderButtons";
import { getWidgetIcon } from "../ExplorerIcons";
import { useDragWidgetIntoCanvas } from "./useDragWidgetIntoCanvas";
import type {
  WidgetCardProps,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";

// A custom implementation just to use enable Dragging a widget into the canvas
const CustomRenderedItemWithDrag = (
  props: CustomRenderProps & {
    cardProps: WidgetCardProps;
    onDragStart?: () => void;
  },
) => {
  const { drag, preview } = useDragWidgetIntoCanvas(props.cardProps, {
    onDragStart: props.onDragStart,
  });

  return (
    <>
      <DragPreviewImage connect={preview} src={blankImage} />
      <Tooltip
        title="Drag component to canvas"
        mouseEnterDelay={0.5}
        placement="right"
      >
        <SearchListItem
          ref={drag}
          data-focused={props.focusedIndex === props.index}
          className={props.className}
          onMouseEnter={props.onMouseEnter}
          onClick={props.onClick}
          data-test={props.dataTest}
          style={{ cursor: "grab", userSelect: "none" }}
        >
          {getWidgetIcon(props.cardProps.type)}
          {props.cardProps.widgetCardName}
        </SearchListItem>
      </Tooltip>
    </>
  );
};

const CustomRenderedItemWithoutDrag = (
  props: CustomRenderProps & { cardProps: WidgetCardProps },
) => {
  return (
    <SearchListItem
      data-focused={props.focusedIndex === props.index}
      className={props.className}
      onMouseEnter={props.onMouseEnter}
      onClick={props.onClick}
      data-test={props.dataTest}
    >
      {getWidgetIcon(props.cardProps.type)}
      {props.cardProps.widgetCardName}
    </SearchListItem>
  );
};

const RenderBelowListClassName = styleAsClass`
  list-style: none;
  margin: 4px 0;
`;

// exported separately to allow for customizing the open/close behavior of this popup
export const AddWidgetPopup = (props: {
  isOpen: boolean;
  anchorRef: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>;
  onClose: () => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  popperProps?: Partial<PopperProps>;
  originatingWidgetId?: string; // if this is initiated from a specific widget
  allowDrag?: boolean;
  hideUIBlocks?: boolean;
}) => {
  const {
    isOpen,
    anchorRef,
    onClose,
    onDragStart,
    popperProps,
    onDragEnd,
    originatingWidgetId,
    allowDrag,
    hideUIBlocks,
  } = props;

  usePointerDownOutside({
    onClickOutside: onClose,
    wrapperIds: ["add-widget-menu", "add-widget-button"],
  });

  useHotkeys(
    "Esc",
    () => {
      onClose();
    },
    { enabled: isOpen, enableOnTags: ["INPUT"] },
    [isOpen],
  );

  const widgets = useAppSelector(getAvailableWidgets);
  const isUIBlocksEnabled = useFeatureFlag(Flag.UI_BLOCKS_FOR_USERS);
  const isDraggingNewWidget = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.isDraggingNewWidget,
  );
  const [isWaitingForDragEnd, setIsWaitingForDragEnd] = useState(false);
  const internalDragStart = useCallback(() => {
    onDragStart?.();
    setIsWaitingForDragEnd(true);
  }, [onDragStart]);
  useEffect(() => {
    if (!isDraggingNewWidget && isWaitingForDragEnd) {
      onDragEnd?.();
      setIsWaitingForDragEnd(false);
    }
  }, [isDraggingNewWidget, isWaitingForDragEnd, onDragEnd]);

  const menuItems = useMemo(() => {
    return widgets
      .flatMap((widgetGroup) => widgetGroup.widgetCards)
      .map((widgetCardProps) => {
        const itemRender = allowDrag
          ? (props: CustomRenderProps) => (
              <CustomRenderedItemWithDrag
                {...props}
                cardProps={widgetCardProps}
                onDragStart={internalDragStart}
              />
            )
          : (props: CustomRenderProps) => (
              <CustomRenderedItemWithoutDrag
                {...props}
                cardProps={widgetCardProps}
              />
            );
        return {
          customRender: itemRender,
          value: widgetCardProps.type,
          label: widgetCardProps.widgetCardName,
        } satisfies ItemsType;
      });
  }, [widgets, internalDragStart, allowDrag]);

  const dispatch = useAppDispatch();
  const selectedWidgets = useAppSelector(getSelectedWidgets);
  const firstSelectedWidget = selectedWidgets?.[0];

  const handleOpenUiBlocksModal = useCallback(() => {
    onClose();
    if (firstSelectedWidget) {
      dispatch(
        setInsertionContext({
          insertionTargetId:
            originatingWidgetId ?? firstSelectedWidget?.widgetId,
        }),
      );
    }

    dispatch(showUiBlocksModal());
    logger.info("UI Blocks: Opened modal from component menu");
  }, [onClose, dispatch, firstSelectedWidget, originatingWidgetId]);

  const insertWidgetByType = useInsertWidgetByType();
  const handleInsert = useCallback(
    (item: string) => {
      insertWidgetByType({
        targetWidgetId: firstSelectedWidget?.widgetId,
        widgetType: item as WidgetTypes,
      });
    },
    [insertWidgetByType, firstSelectedWidget],
  );

  return (
    <>
      {isOpen && anchorRef.current && (
        <Popper
          targetNode={anchorRef.current}
          zIndex={100}
          isOpen={true}
          placement="top-end"
          {...(popperProps ?? {})}
        >
          <SearchableListPopup
            items={menuItems}
            onItemSelect={handleInsert}
            id="add-widget-menu"
            renderBelowList={
              !hideUIBlocks && isUIBlocksEnabled ? (
                <div className={RenderBelowListClassName}>
                  <MenuItem
                    label="Browse UI Templates"
                    intent="primary"
                    onClick={handleOpenUiBlocksModal}
                  />
                </div>
              ) : undefined
            }
          />
        </Popper>
      )}
    </>
  );
};

const AddWidgetPopupWithButton = () => {
  const dispatch = useAppDispatch();
  const readOnly = useAppSelector(getEditorReadOnly);
  const isLeftPanePinned = useAppSelector(getIsLeftPanePinned);
  const [isListOpen, setIsListOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOpenList = useCallback(() => {
    setIsListOpen(true);
  }, [setIsListOpen]);

  const handleCloseList = useCallback(() => {
    setIsListOpen(false);
  }, [setIsListOpen]);

  const handleDragStart = useCallback(() => {
    setIsListOpen(false);
    if (!isLeftPanePinned) {
      dispatch(updateApplicationSidebarKey({ selectedKey: undefined }));
    }
  }, [setIsListOpen, dispatch, isLeftPanePinned]);

  if (readOnly) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title="Add a new component"
        key="add-component-button"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
      >
        <PlusButton ref={buttonRef} onClick={handleOpenList} />
      </Tooltip>
      <AddWidgetPopup
        isOpen={isListOpen}
        anchorRef={buttonRef}
        onClose={handleCloseList}
        onDragStart={handleDragStart}
        allowDrag={true}
      />
    </div>
  );
};

export default AddWidgetPopupWithButton;
