import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "dot-object";
import { Dispatch } from "redux";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { getApiInfoById } from "legacy/selectors/sagaSelectors";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { useAppSelector } from "store/helpers";
import { Flag, Flags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import {
  apiV1ResponsePropertyPane,
  apiV2ResponsePropertyPane,
} from "../../Explorer/ApiResponseProperties";
import { apiIcon } from "../../Explorer/ExplorerIcons";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

const NameInfo = Object.freeze({
  name: "<API>",
  editable: true,
  requiresValidation: true,
});

export const ApiV1Accessor: ItemKindAccessor<ItemKinds.API_V1> = {
  // the name is not stored in ApiInfo but this method is not use anywhere because APIs cannot be viewed in the property pane
  useItemName: () => NameInfo,
  itemType: () => ItemTypeNonWidget.API_V1,
  useItemProperties: (itemId: string) => {
    return useAppSelector((state) => getApiInfoById(state, itemId));
  },
  updateItemProperties: (
    dispatch: Dispatch,
    api: ApiInfo,
    updates: Record<string, unknown>,
  ) => {
    const changes: ApiInfo = fastClone(api);

    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      api,
      apiV1ResponsePropertyPane,
      updates,
      false, // API v1 is no longer supported.
    );

    for (const [key, value] of Object.entries(updatesWithBindings)) {
      set(key, value, changes);
    }
    dispatch(
      updatePartialApiInfo({
        id: api.id,
        data: changes,
      }),
    );
  },
  deleteItemProperties: (
    dispatch: Dispatch,
    properties: ApiInfo,
    propertyPaths: string[],
  ) => {
    throw new Error("Not implemented");
  },
  deleteItem: () => {
    throw new Error("Not implemented");
  },
  icon: () => apiIcon,
};

export const ApiV2Accessor: ItemKindAccessor<ItemKinds.API_V2> = {
  // the name is not stored in ApiInfo but this method is not use anywhere because APIs cannot be viewed in the property pane
  useItemName: () => NameInfo,
  itemType: () => ItemTypeNonWidget.API_V2,
  useItemProperties: (itemId: string) => {
    return useAppSelector((state) => {
      return getApiInfoById(state, itemId);
    });
  },
  updateItemProperties: (
    dispatch: Dispatch,
    api: ApiInfo,
    updates: Record<string, unknown>,
    itemScope: ApplicationScope,
    flags: Flags,
  ) => {
    const changes: ApiInfo = fastClone(api);

    const updatesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      api,
      apiV2ResponsePropertyPane,
      updates,
      flags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    for (const [key, value] of Object.entries(updatesWithBindings)) {
      set(key, value, changes);
    }
    dispatch(
      updatePartialApiInfo({
        id: api.id,
        data: changes,
      }),
    );
  },
  deleteItemProperties: (
    dispatch: Dispatch,
    properties: ApiInfo,
    propertyPaths: string[],
  ) => {
    throw new Error("Not implemented");
  },
  deleteItem: () => {
    throw new Error("Not implemented");
  },
  icon: () => apiIcon,
};
