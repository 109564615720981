import { CloseOutlined } from "@ant-design/icons";
import {
  AgentType,
  AgentsHealthStatus,
  NotificationPosition,
  Profile,
} from "@superblocksteam/shared";
import { notification, Typography } from "antd";
import { isString } from "lodash";
import React from "react";
import styled from "styled-components";
import { ReactComponent as WarningIcon } from "assets/icons/common/system-danger.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/common/system-error.svg";
import { ReactComponent as InfoIcon } from "assets/icons/common/system-info.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/common/system-success.svg";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { colors } from "styles/colors";
import { NoActiveAgentDetailedMessage } from "./error/agentError";

export { NotificationPosition };

type Notification = {
  key?: string;
  message: JSX.Element | string;
  description?: JSX.Element | string;
  // Duration in seconds
  duration?: number;
  placement?: NotificationPosition;
  style?: React.CSSProperties;
  className?: string;
  isUISystemInitiated?: boolean;
  dataTest?: string;
};

const AGENT_SYSTEM_ERROR_DURATION_SECOND = 5;

// Removing maxCount now as it is a gloabl setting, in some cases users do not have time to view all the messages if maxCount is set too low.
notification.config({ placement: "bottomRight" });
// Used to keep track of active notifications and collapse them when displayed
const activeNotifications = new Map<string, { count: number; message: any }>();

const CloseIconWrapper = styled.div`
  font-size: 12px;
  margin-top: -3px;
  margin-right: -4px;
`;

const StatusIconWrapper = styled.div<{
  color: string;
}>`
  color: ${(props) => props.color};
  font-size: 16px;
  margin-top: -2px;
  margin-left: -10px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const getCloseIcon = (dataTest?: string) => (
  <CloseIconWrapper
    data-test={dataTest ? `${dataTest}-close` : "notification-close"}
  >
    <CloseOutlined />
  </CloseIconWrapper>
);

const MessageWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  margin-left: -26px;
  margin-right: 0;
  white-space: pre-wrap;
`;

const DescriptionWrapper = styled.div`
  font-size: 12px;
  margin-left: -26px;
  margin-right: -10px;
`;
const styleMessage = (
  message: string | JSX.Element,
  dataTest?: string,
): JSX.Element => {
  return (
    <MessageWrapper data-test={dataTest ? dataTest : "notification"}>
      {message}
    </MessageWrapper>
  );
};

const styleDescription = (
  description: undefined | string | JSX.Element,
): undefined | JSX.Element => {
  if (!description) {
    return undefined;
  }
  return <DescriptionWrapper>{description}</DescriptionWrapper>;
};

const messageWithCount = (
  key: string | undefined,
  message: string | JSX.Element,
  dataTest?: string,
) => {
  let updatedMessage = message;
  if (key) {
    const count = (activeNotifications.get(key)?.count ?? 0) + 1;
    activeNotifications.set(key, { count: count, message });
    if (count > 1 && isString(message)) {
      // If message is a string, add count to message
      updatedMessage = `${message} (${count} times)`;
    }
  }
  return styleMessage(updatedMessage, dataTest);
};

/**
 * When a Key is used,
 * duplicate notifications are not shown but the timeout is added to the first notification.
 * @returns A string key based on inputs
 */
const getKey = (
  type: string,
  message: string | JSX.Element,
  description: string | JSX.Element | undefined,
  duration: number | undefined,
) => {
  return isString(message)
    ? `${type}--${String(message)}--${description}--${duration}`
    : undefined;
};

const ALERT_PADDING = "14px 24px 10px 24px";
const NotificationBaseStyles = {
  padding: ALERT_PADDING,
  minHeight: "44px",
};

const getPositioning = (
  placement?: NotificationPosition,
): { top?: number; bottom?: number } => {
  if (placement === "top") {
    return {
      top: 46,
    };
  } else {
    return {};
  }
};

const getClassName = (
  isUISystemInitiated: Notification["isUISystemInitiated"],
) => (isUISystemInitiated === false ? CLASS_NAMES.NOTIFICATION : "");

const removeNotificationsWithKey = (key: string) => {
  document
    .querySelectorAll(`[data-testid="notification_${key}"]`)
    .forEach((el) => {
      el.remove();
    });
};

const attrSafeKey = (str: string) => {
  return str.replace(/\W+/g, "_");
};

export function sendSuccessUINotification({
  message,
  description,
  duration,
  key = getKey("success", message, description, duration),
  placement,
  style,
  className,
  isUISystemInitiated,
  dataTest,
}: Notification) {
  const icon = (
    <StatusIconWrapper color={colors.SUCCESS}>
      <SuccessIcon />
    </StatusIconWrapper>
  );

  const notificationStyle = { ...NotificationBaseStyles, ...style };

  if (key) {
    notification.close(key);
    removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
  }

  notification.success({
    key,
    message: messageWithCount(key, message, dataTest),
    description: styleDescription(description),
    icon,
    closeIcon: getCloseIcon(dataTest),
    duration,
    placement,
    className: `${getClassName(isUISystemInitiated)} ${className}`,
    style: notificationStyle,
    ...getPositioning(placement),
    onClose() {
      onAlertClose(key);
      if (key) {
        removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
      }
    },
    props: {
      "data-testid": attrSafeKey(`notification-${key}`),
    },
  });
}

export function sendErrorUINotification({
  message,
  description,
  duration,
  key = getKey("error", message, description, duration),
  placement,
  style,
  isUISystemInitiated,
  dataTest,
}: Notification) {
  const icon = (
    <StatusIconWrapper color={colors.DANGER}>
      <ErrorIcon />
    </StatusIconWrapper>
  );

  const notificationStyle = { ...NotificationBaseStyles, ...style };
  if (key) {
    notification.close(key);
    removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
  }

  notification.error({
    key,
    message: messageWithCount(key, message, dataTest),
    description: styleDescription(description),
    icon,
    closeIcon: getCloseIcon(dataTest),
    duration,
    placement,
    style: notificationStyle,
    className: getClassName(isUISystemInitiated),
    ...getPositioning(placement),
    onClose() {
      onAlertClose(key);
      if (key) {
        removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
      }
    },
    props: {
      "data-testid": attrSafeKey(`notification-${key}`),
    },
  });
}

export function sendInfoUINotification({
  message,
  description,
  duration,
  key = getKey("info", message, description, duration),
  placement,
  style,
  isUISystemInitiated,
  dataTest,
}: Notification) {
  const icon = (
    <StatusIconWrapper color={colors.INFO}>
      <InfoIcon />
    </StatusIconWrapper>
  );

  const notificationStyle = { ...NotificationBaseStyles, ...style };
  if (key) {
    notification.close(key);
    removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
  }

  notification.info({
    key,
    message: messageWithCount(key, message, dataTest),
    description: styleDescription(description),
    icon,
    closeIcon: getCloseIcon(dataTest),
    duration,
    placement,
    style: notificationStyle,
    className: getClassName(isUISystemInitiated),
    ...getPositioning(placement),
    onClose() {
      onAlertClose(key);
      if (key) {
        removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
      }
    },
    props: {
      "data-testid": attrSafeKey(`notification-${key}`),
    },
  });
}

function onAlertClose(key: string | undefined) {
  key && activeNotifications.delete(key);
}

export function sendWarningUINotification({
  message,
  description,
  duration,
  key = getKey("warn", message, description, duration),
  placement,
  style,
  isUISystemInitiated,
  dataTest,
}: Notification) {
  const icon = (
    <StatusIconWrapper color={colors.WARNING}>
      <WarningIcon width={20} height={20} />
    </StatusIconWrapper>
  );

  const notificationStyle = { ...NotificationBaseStyles, ...style };
  if (key) {
    notification.close(key);
    removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
  }
  notification.warning({
    key,
    message: messageWithCount(key, message, dataTest),
    description: styleDescription(description),
    icon,
    closeIcon: getCloseIcon(dataTest),
    duration: duration,
    placement,
    style: notificationStyle,
    className: getClassName(isUISystemInitiated),
    ...getPositioning(placement),
    onClose() {
      onAlertClose(key);
      if (key) {
        removeNotificationsWithKey(attrSafeKey(`notification-${key}`));
      }
    },
    props: {
      "data-testid": attrSafeKey(`notification-${key}`),
    },
  });
}

export const sendAgentSystemErrorNotification = ({
  message,
  description,
  key,
}: Omit<Notification, "duration">) => {
  sendErrorUINotification({
    key,
    message,
    description,
    duration: AGENT_SYSTEM_ERROR_DURATION_SECOND,
  });
};

const messageComponent = (props: {
  organizationAgentType: AgentType;
  userIsAdmin: boolean;
  agentsHealthStatus: AgentsHealthStatus;
  profile: Profile | undefined;
}) => {
  return (
    <Typography>
      <Typography.Text>
        <NoActiveAgentDetailedMessage
          organizationAgentType={props.organizationAgentType}
          userIsAdmin={props.userIsAdmin}
          agentsHealthStatus={props.agentsHealthStatus}
          profile={props.profile}
        />
      </Typography.Text>
    </Typography>
  );
};

export function sendNoActiveAgentUINotification(props: {
  organizationAgentType: AgentType;
  userIsAdmin: boolean;
  agentsHealthStatus: AgentsHealthStatus;
  profile: Profile | undefined;
}) {
  sendAgentSystemErrorNotification({
    key: `agentActive--${props.organizationAgentType}`,
    message: messageComponent(props),
  });
}

export function cleanNotification() {
  notification.destroy();
  activeNotifications.clear();
}

export function closeNotification(key: string) {
  if (!key) {
    return;
  }
  notification.close(key);
  removeNotificationsWithKey(attrSafeKey(key));
  activeNotifications.delete(key);
}
