export const iconData: Record<string, any> = {
  "123": {
    name: "123",
    version: 1,
    popularity: 5111,
    codepoint: 60301,
    categories: ["action"],
    tags: ["1", "2", "3", "digit", "number", "symbol"],
  },
  "360": {
    name: "360",
    version: 14,
    popularity: 8571,
    codepoint: 58743,
    categories: ["maps"],
    tags: [
      "360",
      "arrow",
      "av",
      "camera",
      "direction",
      "rotate",
      "rotation",
      "vr",
    ],
  },
  "10k": {
    name: "10k",
    version: 10,
    popularity: 1172,
    codepoint: 59729,
    categories: ["av"],
    tags: [
      "10000",
      "10K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "10mp": {
    name: "10mp",
    version: 10,
    popularity: 602,
    codepoint: 59730,
    categories: ["image"],
    tags: [
      "10",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "11mp": {
    name: "11mp",
    version: 10,
    popularity: 555,
    codepoint: 59731,
    categories: ["image"],
    tags: [
      "11",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "12mp": {
    name: "12mp",
    version: 10,
    popularity: 691,
    codepoint: 59732,
    categories: ["image"],
    tags: [
      "12",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "13mp": {
    name: "13mp",
    version: 10,
    popularity: 523,
    codepoint: 59733,
    categories: ["image"],
    tags: [
      "13",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "14mp": {
    name: "14mp",
    version: 10,
    popularity: 504,
    codepoint: 59734,
    categories: ["image"],
    tags: [
      "14",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "15mp": {
    name: "15mp",
    version: 10,
    popularity: 521,
    codepoint: 59735,
    categories: ["image"],
    tags: [
      "15",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "16mp": {
    name: "16mp",
    version: 10,
    popularity: 519,
    codepoint: 59736,
    categories: ["image"],
    tags: [
      "16",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "17mp": {
    name: "17mp",
    version: 10,
    popularity: 513,
    codepoint: 59737,
    categories: ["image"],
    tags: [
      "17",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "18_up_rating": {
    name: "18_up_rating",
    version: 1,
    popularity: 619,
    codepoint: 63741,
    categories: ["social"],
    tags: [],
  },
  "18mp": {
    name: "18mp",
    version: 10,
    popularity: 615,
    codepoint: 59738,
    categories: ["image"],
    tags: [
      "18",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "19mp": {
    name: "19mp",
    version: 10,
    popularity: 499,
    codepoint: 59739,
    categories: ["image"],
    tags: [
      "19",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "1k": {
    name: "1k",
    version: 10,
    popularity: 824,
    codepoint: 59740,
    categories: ["av"],
    tags: [
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "1k_plus": {
    name: "1k_plus",
    version: 10,
    popularity: 745,
    codepoint: 59741,
    categories: ["av"],
    tags: [
      "+",
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "1x_mobiledata": {
    name: "1x_mobiledata",
    version: 9,
    popularity: 1615,
    codepoint: 61389,
    categories: ["device"],
    tags: [
      "1x",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "1x_mobiledata_badge": {
    name: "1x_mobiledata_badge",
    version: 229,
    popularity: 3,
    codepoint: 63473,
    categories: ["Android"],
    tags: [
      "1x",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "20mp": {
    name: "20mp",
    version: 10,
    popularity: 552,
    codepoint: 59742,
    categories: ["image"],
    tags: [
      "20",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "21mp": {
    name: "21mp",
    version: 10,
    popularity: 519,
    codepoint: 59743,
    categories: ["image"],
    tags: [
      "21",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "22mp": {
    name: "22mp",
    version: 10,
    popularity: 503,
    codepoint: 59744,
    categories: ["image"],
    tags: [
      "22",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "23mp": {
    name: "23mp",
    version: 10,
    popularity: 597,
    codepoint: 59745,
    categories: ["image"],
    tags: [
      "23",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "24mp": {
    name: "24mp",
    version: 10,
    popularity: 920,
    codepoint: 59746,
    categories: ["image"],
    tags: [
      "24",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "2d": {
    name: "2d",
    version: 229,
    popularity: 8,
    codepoint: 61239,
    categories: ["Audio&Video"],
    tags: [
      "2d",
      "alphabet",
      "character",
      "digit",
      "dimensional",
      "font",
      "letter",
      "number",
      "symbol",
      "text",
      "two",
      "type",
    ],
  },
  "2k": {
    name: "2k",
    version: 10,
    popularity: 779,
    codepoint: 59747,
    categories: ["av"],
    tags: [
      "2000",
      "2K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "2k_plus": {
    name: "2k_plus",
    version: 10,
    popularity: 586,
    codepoint: 59748,
    categories: ["av"],
    tags: [
      "+",
      "2k",
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "plus",
      "symbol",
      "text",
      "type",
    ],
  },
  "2mp": {
    name: "2mp",
    version: 10,
    popularity: 516,
    codepoint: 59749,
    categories: ["image"],
    tags: [
      "2",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "30fps": {
    name: "30fps",
    version: 10,
    popularity: 1214,
    codepoint: 61390,
    categories: ["device"],
    tags: [
      "30fps",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "fps",
      "frames",
      "letter",
      "number",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "30fps_select": {
    name: "30fps_select",
    version: 10,
    popularity: 1021,
    codepoint: 61391,
    categories: ["image"],
    tags: [
      "30",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "image",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video",
    ],
  },
  "3d_rotation": {
    name: "3d_rotation",
    version: 18,
    popularity: 14388,
    codepoint: 59469,
    categories: ["action"],
    tags: [
      "3",
      "3d",
      "D",
      "alphabet",
      "arrow",
      "arrows",
      "av",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "rotation",
      "symbol",
      "text",
      "type",
      "vr",
    ],
  },
  "3g_mobiledata": {
    name: "3g_mobiledata",
    version: 9,
    popularity: 1071,
    codepoint: 61392,
    categories: ["device"],
    tags: [
      "3g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "3g_mobiledata_badge": {
    name: "3g_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63472,
    categories: ["Android"],
    tags: [
      "3g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "3k": {
    name: "3k",
    version: 10,
    popularity: 637,
    codepoint: 59750,
    categories: ["av"],
    tags: [
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "3k_plus": {
    name: "3k_plus",
    version: 10,
    popularity: 581,
    codepoint: 59751,
    categories: ["av"],
    tags: [
      "+",
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "3mp": {
    name: "3mp",
    version: 10,
    popularity: 538,
    codepoint: 59752,
    categories: ["image"],
    tags: [
      "3",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "3p": {
    name: "3p",
    version: 11,
    popularity: 7352,
    codepoint: 61393,
    categories: ["communication"],
    tags: [
      "3",
      "3p",
      "account",
      "avatar",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "face",
      "human",
      "message",
      "party",
      "people",
      "person",
      "profile",
      "speech",
      "user",
    ],
  },
  "4g_mobiledata": {
    name: "4g_mobiledata",
    version: 9,
    popularity: 1887,
    codepoint: 61394,
    categories: ["device"],
    tags: [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "4g_mobiledata_badge": {
    name: "4g_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63471,
    categories: ["Android"],
    tags: [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "4g_plus_mobiledata": {
    name: "4g_plus_mobiledata",
    version: 9,
    popularity: 1267,
    codepoint: 61395,
    categories: ["device"],
    tags: [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "plus",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "4k": {
    name: "4k",
    version: 11,
    popularity: 1820,
    codepoint: 57458,
    categories: ["av"],
    tags: [
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "4k_plus": {
    name: "4k_plus",
    version: 10,
    popularity: 870,
    codepoint: 59753,
    categories: ["av"],
    tags: [
      "+",
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "4mp": {
    name: "4mp",
    version: 10,
    popularity: 490,
    codepoint: 59754,
    categories: ["image"],
    tags: [
      "4",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "50mp": {
    name: "50mp",
    version: 229,
    popularity: 8,
    codepoint: 63219,
    categories: ["Photo&Image"],
    tags: [
      "50",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "5g": {
    name: "5g",
    version: 13,
    popularity: 2427,
    codepoint: 61240,
    categories: ["av"],
    tags: [
      "5g",
      "alphabet",
      "cellular",
      "character",
      "data",
      "digit",
      "font",
      "letter",
      "mobile",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "5g_mobiledata_badge": {
    name: "5g_mobiledata_badge",
    version: 229,
    popularity: 3,
    codepoint: 63470,
    categories: ["Android"],
    tags: [
      "5g",
      "alphabet",
      "cellular",
      "character",
      "data",
      "digit",
      "font",
      "letter",
      "mobile",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  "5k": {
    name: "5k",
    version: 10,
    popularity: 653,
    codepoint: 59755,
    categories: ["av"],
    tags: [
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "5k_plus": {
    name: "5k_plus",
    version: 10,
    popularity: 614,
    codepoint: 59756,
    categories: ["av"],
    tags: [
      "+",
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "5mp": {
    name: "5mp",
    version: 10,
    popularity: 526,
    codepoint: 59757,
    categories: ["image"],
    tags: [
      "5",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "60fps": {
    name: "60fps",
    version: 10,
    popularity: 1027,
    codepoint: 61396,
    categories: ["device"],
    tags: [
      "60fps",
      "camera",
      "digit",
      "fps",
      "frames",
      "number",
      "symbol",
      "video",
    ],
  },
  "60fps_select": {
    name: "60fps_select",
    version: 10,
    popularity: 949,
    codepoint: 61397,
    categories: ["image"],
    tags: [
      "60",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video",
    ],
  },
  "6_ft_apart": {
    name: "6_ft_apart",
    version: 9,
    popularity: 2313,
    codepoint: 61982,
    categories: ["social"],
    tags: [
      "6",
      "apart",
      "body",
      "covid",
      "distance",
      "feet",
      "ft",
      "human",
      "people",
      "person",
      "social",
    ],
  },
  "6k": {
    name: "6k",
    version: 10,
    popularity: 579,
    codepoint: 59758,
    categories: ["av"],
    tags: [
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "6k_plus": {
    name: "6k_plus",
    version: 10,
    popularity: 586,
    codepoint: 59759,
    categories: ["av"],
    tags: [
      "+",
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "6mp": {
    name: "6mp",
    version: 10,
    popularity: 489,
    codepoint: 59760,
    categories: ["image"],
    tags: [
      "6",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "7k": {
    name: "7k",
    version: 10,
    popularity: 582,
    codepoint: 59761,
    categories: ["av"],
    tags: [
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "7k_plus": {
    name: "7k_plus",
    version: 10,
    popularity: 581,
    codepoint: 59762,
    categories: ["av"],
    tags: [
      "+",
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "7mp": {
    name: "7mp",
    version: 10,
    popularity: 489,
    codepoint: 59763,
    categories: ["image"],
    tags: [
      "7",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "8k": {
    name: "8k",
    version: 11,
    popularity: 715,
    codepoint: 59764,
    categories: ["av"],
    tags: [
      "8000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "8k_plus": {
    name: "8k_plus",
    version: 11,
    popularity: 654,
    codepoint: 59765,
    categories: ["av"],
    tags: [
      "+",
      "7000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "8mp": {
    name: "8mp",
    version: 10,
    popularity: 489,
    codepoint: 59766,
    categories: ["image"],
    tags: [
      "8",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  "9k": {
    name: "9k",
    version: 10,
    popularity: 593,
    codepoint: 59767,
    categories: ["av"],
    tags: [
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "9k_plus": {
    name: "9k_plus",
    version: 10,
    popularity: 655,
    codepoint: 59768,
    categories: ["av"],
    tags: [
      "+",
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  "9mp": {
    name: "9mp",
    version: 10,
    popularity: 492,
    codepoint: 59769,
    categories: ["image"],
    tags: [
      "9",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  abc: {
    name: "abc",
    version: 1,
    popularity: 3032,
    codepoint: 60308,
    categories: ["action"],
    tags: ["alphabet", "character", "font", "letter", "symbol", "text", "type"],
  },
  ac_unit: {
    name: "ac_unit",
    version: 12,
    popularity: 17536,
    codepoint: 60219,
    categories: ["places"],
    tags: [
      "ac",
      "air",
      "cold",
      "conditioner",
      "flake",
      "snow",
      "snowflake",
      "temperature",
      "unit",
      "weather",
      "winter",
    ],
  },
  accessibility: {
    name: "accessibility",
    version: 13,
    popularity: 26180,
    codepoint: 59470,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person",
    ],
  },
  accessibility_new: {
    name: "accessibility_new",
    version: 13,
    popularity: 24208,
    codepoint: 59692,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "new",
      "people",
      "person",
    ],
  },
  accessible: {
    name: "accessible",
    version: 13,
    popularity: 13497,
    codepoint: 59668,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair",
    ],
  },
  accessible_forward: {
    name: "accessible_forward",
    version: 13,
    popularity: 6642,
    codepoint: 59700,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "forward",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair",
    ],
  },
  account_balance: {
    name: "account_balance",
    version: 19,
    popularity: 83200,
    codepoint: 59471,
    categories: ["action"],
    tags: [
      "account",
      "balance",
      "bank",
      "bill",
      "building",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "government",
      "money",
      "online",
      "pay",
      "payment",
    ],
  },
  account_balance_wallet: {
    name: "account_balance_wallet",
    version: 12,
    popularity: 68314,
    codepoint: 59472,
    categories: ["action"],
    tags: [
      "account",
      "balance",
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "wallet",
    ],
  },
  account_box: {
    name: "account_box",
    version: 14,
    popularity: 45215,
    codepoint: 59473,
    categories: ["action"],
    tags: [
      "account",
      "avatar",
      "box",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "square",
      "thumbnail",
      "user",
    ],
  },
  account_child: {
    name: "account_child",
    version: 229,
    popularity: 26,
    codepoint: 59474,
    categories: ["Common actions"],
    tags: [
      "account",
      "body",
      "child",
      "children",
      "human",
      "kid",
      "people",
      "person",
      "young",
    ],
  },
  account_child_invert: {
    name: "account_child_invert",
    version: 229,
    popularity: 27,
    codepoint: 58969,
    categories: ["Common actions"],
    tags: [
      "account",
      "body",
      "child",
      "children",
      "human",
      "kid",
      "people",
      "person",
      "young",
    ],
  },
  account_circle: {
    name: "account_circle",
    version: 20,
    popularity: 578847,
    codepoint: 59475,
    categories: ["action"],
    tags: [
      "account",
      "avatar",
      "circle",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "thumbnail",
      "user",
    ],
  },
  account_circle_off: {
    name: "account_circle_off",
    version: 229,
    popularity: 16,
    codepoint: 63411,
    categories: ["Common actions"],
    tags: [
      "account",
      "avatar",
      "circle",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "thumbnail",
      "user",
    ],
  },
  account_tree: {
    name: "account_tree",
    version: 12,
    popularity: 43273,
    codepoint: 59770,
    categories: ["notification"],
    tags: [
      "account",
      "analytics",
      "chart",
      "connect",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "process",
      "square",
      "statistics",
      "structure",
      "tracking",
      "tree",
    ],
  },
  activity_zone: {
    name: "activity_zone",
    version: 229,
    popularity: 385,
    codepoint: 57830,
    categories: ["Home"],
    tags: ["activity ", "home", "nest", "security", "zone"],
  },
  acute: {
    name: "acute",
    version: 229,
    popularity: 112,
    codepoint: 58571,
    categories: ["UI actions"],
    tags: [
      "clock",
      "date",
      "fast",
      "health",
      "schedule",
      "speed",
      "time",
      "timer",
    ],
  },
  ad: {
    name: "ad",
    version: 229,
    popularity: 41,
    codepoint: 58970,
    categories: ["Common actions"],
    tags: [
      "ad",
      "advertisement",
      "banner",
      "browser",
      "internet",
      "website",
      "window",
    ],
  },
  ad_group: {
    name: "ad_group",
    version: 229,
    popularity: 58,
    codepoint: 58971,
    categories: ["Common actions"],
    tags: [
      "ad",
      "advertisement",
      "banner multiple",
      "browser",
      "group",
      "internet",
      "website",
      "window",
    ],
  },
  ad_group_off: {
    name: "ad_group_off",
    version: 229,
    popularity: 29,
    codepoint: 60133,
    categories: ["Common actions"],
    tags: [
      "Ads",
      "group",
      "hidden",
      "off",
      "pause",
      "remove",
      "settings",
      "windows",
    ],
  },
  ad_off: {
    name: "ad_off",
    version: 229,
    popularity: 15,
    codepoint: 63410,
    categories: ["Common actions"],
    tags: [
      "ad",
      "advertisement",
      "banner",
      "browser",
      "disabled",
      "enabled",
      "internet",
      "off",
      "offline",
      "on",
      "slash",
      "website",
      "window",
    ],
  },
  ad_units: {
    name: "ad_units",
    version: 13,
    popularity: 2794,
    codepoint: 61241,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "ad",
      "banner",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "notification",
      "notifications",
      "phone",
      "tablet",
      "top",
      "units",
    ],
  },
  adb: {
    name: "adb",
    version: 12,
    popularity: 4442,
    codepoint: 58894,
    categories: ["notification"],
    tags: ["adb", "android", "bridge", "debug"],
  },
  add: {
    name: "add",
    version: 21,
    popularity: 324560,
    codepoint: 57669,
    categories: ["content"],
    tags: ["+", "add", "new symbol", "plus", "symbol"],
  },
  add_a_photo: {
    name: "add_a_photo",
    version: 14,
    popularity: 34325,
    codepoint: 58425,
    categories: ["image"],
    tags: [
      "+",
      "a photo",
      "add",
      "camera",
      "lens",
      "new",
      "photography",
      "picture",
      "plus",
      "symbol",
    ],
  },
  add_ad: {
    name: "add_ad",
    version: 229,
    popularity: 25,
    codepoint: 59178,
    categories: ["Common actions"],
    tags: [
      "+",
      "ad",
      "add",
      "advertisement",
      "browser",
      "internet",
      "plus",
      "website",
      "window",
    ],
  },
  add_alert: {
    name: "add_alert",
    version: 16,
    popularity: 6370,
    codepoint: 57347,
    categories: ["alert"],
    tags: [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "new",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol",
    ],
  },
  add_box: {
    name: "add_box",
    version: 13,
    popularity: 40898,
    codepoint: 57670,
    categories: ["content"],
    tags: ["add", "box", "new square", "plus", "symbol"],
  },
  add_business: {
    name: "add_business",
    version: 17,
    popularity: 9784,
    codepoint: 59177,
    categories: ["maps"],
    tags: [
      "+",
      "add",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol",
    ],
  },
  add_call: {
    name: "add_call",
    version: 10,
    popularity: 717,
    codepoint: 57576,
    categories: ["notification"],
    tags: [
      "+",
      "add",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "new",
      "phone",
      "plus",
      "symbol",
      "telephone",
    ],
  },
  add_card: {
    name: "add_card",
    version: 1,
    popularity: 5650,
    codepoint: 60294,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "price",
      "shopping",
      "symbol",
    ],
  },
  add_chart: {
    name: "add_chart",
    version: 11,
    popularity: 4374,
    codepoint: 59771,
    categories: ["editor"],
    tags: [
      "+",
      "add",
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "plus",
      "statistics",
      "symbol",
      "tracking",
    ],
  },
  add_circle: {
    name: "add_circle",
    version: 13,
    popularity: 122640,
    codepoint: 57671,
    categories: ["content"],
    tags: ["+", "add", "circle", "counter", "create", "new", "plus"],
  },
  add_comment: {
    name: "add_comment",
    version: 11,
    popularity: 8808,
    codepoint: 57958,
    categories: ["editor"],
    tags: [
      "+",
      "add",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "new",
      "plus",
      "speech",
      "symbol",
    ],
  },
  add_home: {
    name: "add_home",
    version: 1,
    popularity: 1248,
    codepoint: 63723,
    categories: ["action"],
    tags: [],
  },
  add_home_work: {
    name: "add_home_work",
    version: 1,
    popularity: 1180,
    codepoint: 63725,
    categories: ["navigation"],
    tags: [],
  },
  add_link: {
    name: "add_link",
    version: 11,
    popularity: 9267,
    codepoint: 57720,
    categories: ["content"],
    tags: ["add", "attach", "clip", "link", "new", "plus", "symbol"],
  },
  add_location: {
    name: "add_location",
    version: 16,
    popularity: 6052,
    codepoint: 58727,
    categories: ["maps"],
    tags: [
      "+",
      "add",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol",
    ],
  },
  add_location_alt: {
    name: "add_location_alt",
    version: 13,
    popularity: 7438,
    codepoint: 61242,
    categories: ["maps"],
    tags: [
      "+",
      "add",
      "alt",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol",
    ],
  },
  add_moderator: {
    name: "add_moderator",
    version: 12,
    popularity: 5409,
    codepoint: 59773,
    categories: ["social"],
    tags: [
      "+",
      "add",
      "certified",
      "moderator",
      "new",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "symbol",
      "verified",
    ],
  },
  add_notes: {
    name: "add_notes",
    version: 229,
    popularity: 30,
    codepoint: 57489,
    categories: ["Text Formatting"],
    tags: [
      "+",
      "add",
      "doc",
      "document",
      "file",
      "new",
      "note",
      "page",
      "paper",
      "plus",
    ],
  },
  add_photo_alternate: {
    name: "add_photo_alternate",
    version: 12,
    popularity: 22962,
    codepoint: 58430,
    categories: ["image"],
    tags: [
      "+",
      "add",
      "alternate",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "new",
      "photo",
      "photography",
      "picture",
      "plus",
      "symbol",
    ],
  },
  add_reaction: {
    name: "add_reaction",
    version: 9,
    popularity: 5307,
    codepoint: 57811,
    categories: ["social"],
    tags: [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol",
    ],
  },
  add_road: {
    name: "add_road",
    version: 12,
    popularity: 4695,
    codepoint: 61243,
    categories: ["maps"],
    tags: [
      "+",
      "add",
      "destination",
      "direction",
      "highway",
      "maps",
      "new",
      "plus",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic",
    ],
  },
  add_shopping_cart: {
    name: "add_shopping_cart",
    version: 15,
    popularity: 53814,
    codepoint: 59476,
    categories: ["action"],
    tags: [
      "add",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "plus",
      "shopping",
    ],
  },
  add_task: {
    name: "add_task",
    version: 6,
    popularity: 25166,
    codepoint: 62010,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "approve",
      "check",
      "circle",
      "completed",
      "increase",
      "mark",
      "ok",
      "plus",
      "select",
      "task",
      "tick",
      "yes",
    ],
  },
  add_to_drive: {
    name: "add_to_drive",
    version: 18,
    popularity: 6390,
    codepoint: 58972,
    categories: ["action"],
    tags: [
      "add",
      "app",
      "application",
      "backup",
      "cloud",
      "drive",
      "files",
      "folders",
      "gdrive",
      "google",
      "recovery",
      "shortcut",
      "storage",
    ],
  },
  add_to_home_screen: {
    name: "add_to_home_screen",
    version: 12,
    popularity: 2060,
    codepoint: 57854,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "add to",
      "arrow",
      "cell",
      "device",
      "hardware",
      "home",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "tablet",
      "up",
    ],
  },
  add_to_photos: {
    name: "add_to_photos",
    version: 12,
    popularity: 4305,
    codepoint: 58269,
    categories: ["image"],
    tags: [
      "add",
      "collection",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "photos",
      "picture",
      "plus",
      "to",
    ],
  },
  add_to_queue: {
    name: "add_to_queue",
    version: 11,
    popularity: 3526,
    codepoint: 57436,
    categories: ["av"],
    tags: [
      "+",
      "Android",
      "OS",
      "add",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "new",
      "plus",
      "queue",
      "screen",
      "symbol",
      "to",
      "web",
      "window",
    ],
  },
  adf_scanner: {
    name: "adf_scanner",
    version: 2,
    popularity: 1057,
    codepoint: 60122,
    categories: ["hardware"],
    tags: ["adf", "document", "feeder", "machine", "office", "scan", "scanner"],
  },
  adjust: {
    name: "adjust",
    version: 12,
    popularity: 14629,
    codepoint: 58270,
    categories: ["image"],
    tags: [
      "adjust",
      "alter",
      "auto click",
      "center",
      "circle",
      "circles",
      "dot",
      "fix",
      "focus",
      "image",
      "move",
      "target",
    ],
  },
  admin_meds: {
    name: "admin_meds",
    version: 229,
    popularity: 3,
    codepoint: 58509,
    categories: ["Social"],
    tags: ["admin", "doctor", "health", "med", "medical", "medicine"],
  },
  admin_panel_settings: {
    name: "admin_panel_settings",
    version: 13,
    popularity: 60115,
    codepoint: 61245,
    categories: ["action"],
    tags: [
      "account",
      "admin",
      "avatar",
      "certified",
      "face",
      "human",
      "panel",
      "people",
      "person",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
      "user",
      "verified",
    ],
  },
  ads_click: {
    name: "ads_click",
    version: 3,
    popularity: 22220,
    codepoint: 59234,
    categories: ["action"],
    tags: [
      "ads",
      "browser",
      "click",
      "clicks",
      "cursor",
      "internet",
      "target",
      "traffic",
      "web",
    ],
  },
  agender: {
    name: "agender",
    version: 229,
    popularity: 170,
    codepoint: 63624,
    categories: ["Social"],
    tags: [
      "agender",
      "female",
      "gender",
      "genderfree",
      "genderless",
      "identity",
      "lgbt",
      "male",
      "neutral",
      "social",
      "symbol",
    ],
  },
  agriculture: {
    name: "agriculture",
    version: 11,
    popularity: 8882,
    codepoint: 60025,
    categories: ["maps"],
    tags: [
      "agriculture",
      "automobile",
      "car",
      "cars",
      "cultivation",
      "farm",
      "harvest",
      "maps",
      "tractor",
      "transport",
      "travel",
      "truck",
      "vehicle",
    ],
  },
  air: {
    name: "air",
    version: 10,
    popularity: 13039,
    codepoint: 61400,
    categories: ["device"],
    tags: ["air", "blowing", "breeze", "flow", "wave", "weather", "wind"],
  },
  air_freshener: {
    name: "air_freshener",
    version: 229,
    popularity: 408,
    codepoint: 58058,
    categories: ["Household"],
    tags: [
      "air freshener",
      "bottle",
      "bottler",
      "fragnance",
      "nest",
      "scent",
      "smell",
    ],
  },
  air_purifier: {
    name: "air_purifier",
    version: 229,
    popularity: 13,
    codepoint: 59774,
    categories: ["Household"],
    tags: [
      "air",
      "appliance",
      "clean",
      "fragrance",
      "fresh",
      "freshener",
      "home",
      "house",
      "purifier",
      "purify",
    ],
  },
  air_purifier_gen: {
    name: "air_purifier_gen",
    version: 229,
    popularity: 300,
    codepoint: 59433,
    categories: ["Household"],
    tags: ["air", "air purifier", "appliance", "cleaner", "nest", "wind"],
  },
  airline_seat_flat: {
    name: "airline_seat_flat",
    version: 12,
    popularity: 1309,
    codepoint: 58928,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel",
    ],
  },
  airline_seat_flat_angled: {
    name: "airline_seat_flat_angled",
    version: 12,
    popularity: 1050,
    codepoint: 58929,
    categories: ["notification"],
    tags: [
      "airline",
      "angled",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel",
    ],
  },
  airline_seat_individual_suite: {
    name: "airline_seat_individual_suite",
    version: 11,
    popularity: 1459,
    codepoint: 58930,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "human",
      "individual",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "suite",
      "travel",
    ],
  },
  airline_seat_legroom_extra: {
    name: "airline_seat_legroom_extra",
    version: 12,
    popularity: 879,
    codepoint: 58931,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel",
    ],
  },
  airline_seat_legroom_normal: {
    name: "airline_seat_legroom_normal",
    version: 12,
    popularity: 942,
    codepoint: 58932,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel",
    ],
  },
  airline_seat_legroom_reduced: {
    name: "airline_seat_legroom_reduced",
    version: 12,
    popularity: 847,
    codepoint: 58933,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "reduced",
      "seat",
      "sitting",
      "space",
      "travel",
    ],
  },
  airline_seat_recline_extra: {
    name: "airline_seat_recline_extra",
    version: 12,
    popularity: 2422,
    codepoint: 58934,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel",
    ],
  },
  airline_seat_recline_normal: {
    name: "airline_seat_recline_normal",
    version: 12,
    popularity: 3614,
    codepoint: 58935,
    categories: ["notification"],
    tags: [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "recline",
      "seat",
      "sitting",
      "space",
      "travel",
    ],
  },
  airline_stops: {
    name: "airline_stops",
    version: 2,
    popularity: 1673,
    codepoint: 59344,
    categories: ["maps"],
    tags: [
      "airline",
      "arrow",
      "destination",
      "direction",
      "layover",
      "location",
      "maps",
      "place",
      "stops",
      "transportation",
      "travel",
      "trip",
    ],
  },
  airlines: {
    name: "airlines",
    version: 2,
    popularity: 1303,
    codepoint: 59338,
    categories: ["maps"],
    tags: [
      "airlines",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip",
    ],
  },
  airplane_ticket: {
    name: "airplane_ticket",
    version: 11,
    popularity: 7259,
    codepoint: 61401,
    categories: ["device"],
    tags: [
      "airplane",
      "airport",
      "boarding",
      "flight",
      "fly",
      "maps",
      "pass",
      "ticket",
      "transportation",
      "travel",
    ],
  },
  airplanemode_active: {
    name: "airplanemode_active",
    version: 16,
    popularity: 4638,
    codepoint: 57749,
    categories: ["device"],
    tags: [
      "active",
      "airplane",
      "airplanemode",
      "flight",
      "mode",
      "on",
      "signal",
    ],
  },
  airplanemode_inactive: {
    name: "airplanemode_inactive",
    version: 17,
    popularity: 1650,
    codepoint: 57748,
    categories: ["device"],
    tags: [
      "airplane",
      "airplanemode",
      "airport",
      "disabled",
      "enabled",
      "flight",
      "fly",
      "inactive",
      "maps",
      "mode",
      "off",
      "offline",
      "on",
      "slash",
      "transportation",
      "travel",
    ],
  },
  airplay: {
    name: "airplay",
    version: 13,
    popularity: 3302,
    codepoint: 57429,
    categories: ["av"],
    tags: [
      "airplay",
      "arrow",
      "connect",
      "control",
      "desktop",
      "device",
      "display",
      "monitor",
      "screen",
      "signal",
    ],
  },
  airport_shuttle: {
    name: "airport_shuttle",
    version: 12,
    popularity: 10452,
    codepoint: 60220,
    categories: ["places"],
    tags: [
      "airport",
      "automobile",
      "car",
      "cars",
      "commercial",
      "delivery",
      "direction",
      "maps",
      "mini",
      "public",
      "shuttle",
      "transport",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle",
    ],
  },
  airware: {
    name: "airware",
    version: 229,
    popularity: 421,
    codepoint: 61780,
    categories: ["Household"],
    tags: [],
  },
  airwave: {
    name: "airwave",
    version: 229,
    popularity: 580,
    codepoint: 58012,
    categories: ["Home"],
    tags: [
      "air",
      "airwave",
      "blowing",
      "breeze",
      "climate",
      "flow",
      "home",
      "nest",
      "thermostat",
      "wave",
      "weather",
      "wind",
    ],
  },
  alarm: {
    name: "alarm",
    version: 12,
    popularity: 28816,
    codepoint: 59477,
    categories: ["action"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "notification",
      "schedule",
      "time",
    ],
  },
  alarm_add: {
    name: "alarm_add",
    version: 12,
    popularity: 5519,
    codepoint: 59478,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "new",
      "notification",
      "plus",
      "schedule",
      "symbol",
      "time",
    ],
  },
  alarm_off: {
    name: "alarm_off",
    version: 12,
    popularity: 3614,
    codepoint: 59479,
    categories: ["action"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch",
    ],
  },
  alarm_on: {
    name: "alarm_on",
    version: 12,
    popularity: 11569,
    codepoint: 59480,
    categories: ["action"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch",
    ],
  },
  alarm_smart_wake: {
    name: "alarm_smart_wake",
    version: 229,
    popularity: 11,
    codepoint: 63152,
    categories: ["Common actions"],
    tags: ["alert", "clock", "notification", "time"],
  },
  album: {
    name: "album",
    version: 12,
    popularity: 7299,
    codepoint: 57369,
    categories: ["av"],
    tags: [
      "album",
      "artist",
      "audio",
      "bvb",
      "cd",
      "computer",
      "data",
      "disk",
      "file",
      "music",
      "record",
      "sound",
      "storage",
      "track",
    ],
  },
  align_center: {
    name: "align_center",
    version: 229,
    popularity: 2,
    codepoint: 58198,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alignment",
      "center",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
    ],
  },
  align_end: {
    name: "align_end",
    version: 229,
    popularity: 0,
    codepoint: 63383,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_flex_center: {
    name: "align_flex_center",
    version: 229,
    popularity: 2,
    codepoint: 63382,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical",
    ],
  },
  align_flex_end: {
    name: "align_flex_end",
    version: 229,
    popularity: 0,
    codepoint: 63381,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical",
    ],
  },
  align_flex_start: {
    name: "align_flex_start",
    version: 229,
    popularity: 1,
    codepoint: 63380,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical",
    ],
  },
  align_horizontal_center: {
    name: "align_horizontal_center",
    version: 6,
    popularity: 2042,
    codepoint: 57359,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "center",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
    ],
  },
  align_horizontal_left: {
    name: "align_horizontal_left",
    version: 7,
    popularity: 3101,
    codepoint: 57357,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "left",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
    ],
  },
  align_horizontal_right: {
    name: "align_horizontal_right",
    version: 7,
    popularity: 1935,
    codepoint: 57360,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "right",
      "rule",
      "rules",
      "style",
      "text",
    ],
  },
  align_items_stretch: {
    name: "align_items_stretch",
    version: 229,
    popularity: 0,
    codepoint: 63379,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "rules",
      "style",
      "vertical",
    ],
  },
  align_justify_center: {
    name: "align_justify_center",
    version: 229,
    popularity: 1,
    codepoint: 63378,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style",
    ],
  },
  align_justify_flex_end: {
    name: "align_justify_flex_end",
    version: 229,
    popularity: 1,
    codepoint: 63377,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical",
    ],
  },
  align_justify_flex_start: {
    name: "align_justify_flex_start",
    version: 229,
    popularity: 1,
    codepoint: 63376,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical",
    ],
  },
  align_justify_space_around: {
    name: "align_justify_space_around",
    version: 229,
    popularity: 4,
    codepoint: 63375,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style",
    ],
  },
  align_justify_space_between: {
    name: "align_justify_space_between",
    version: 229,
    popularity: 4,
    codepoint: 63374,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style",
    ],
  },
  align_justify_space_even: {
    name: "align_justify_space_even",
    version: 229,
    popularity: 3,
    codepoint: 63373,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style",
    ],
  },
  align_justify_stretch: {
    name: "align_justify_stretch",
    version: 229,
    popularity: 0,
    codepoint: 63372,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style",
    ],
  },
  align_self_stretch: {
    name: "align_self_stretch",
    version: 229,
    popularity: 1,
    codepoint: 63371,
    categories: ["Text Formatting"],
    tags: [
      "alignment",
      "center",
      "distribute",
      "format",
      "layout",
      "lines",
      "rule",
      "rules",
      "style",
      "vertical",
    ],
  },
  align_space_around: {
    name: "align_space_around",
    version: 229,
    popularity: 1,
    codepoint: 63370,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_space_between: {
    name: "align_space_between",
    version: 229,
    popularity: 4,
    codepoint: 63369,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_space_even: {
    name: "align_space_even",
    version: 229,
    popularity: 1,
    codepoint: 63368,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_start: {
    name: "align_start",
    version: 229,
    popularity: 0,
    codepoint: 63367,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_stretch: {
    name: "align_stretch",
    version: 229,
    popularity: 0,
    codepoint: 63366,
    categories: ["Text Formatting"],
    tags: [],
  },
  align_vertical_bottom: {
    name: "align_vertical_bottom",
    version: 6,
    popularity: 2124,
    codepoint: 57365,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "bottom",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical",
    ],
  },
  align_vertical_center: {
    name: "align_vertical_center",
    version: 6,
    popularity: 1563,
    codepoint: 57361,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "center",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical",
    ],
  },
  align_vertical_top: {
    name: "align_vertical_top",
    version: 6,
    popularity: 1577,
    codepoint: 57356,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "top",
      "vertical",
    ],
  },
  all_inbox: {
    name: "all_inbox",
    version: 11,
    popularity: 8081,
    codepoint: 59775,
    categories: ["action"],
    tags: [
      "Inbox",
      "all",
      "delivered",
      "delivery",
      "email",
      "mail",
      "message",
      "send",
    ],
  },
  all_inclusive: {
    name: "all_inclusive",
    version: 11,
    popularity: 10762,
    codepoint: 60221,
    categories: ["places"],
    tags: [
      "all",
      "endless",
      "forever",
      "inclusive",
      "infinity",
      "loop",
      "mobius",
      "neverending",
      "strip",
      "sustainability",
      "sustainable",
    ],
  },
  all_match: {
    name: "all_match",
    version: 229,
    popularity: 26,
    codepoint: 57491,
    categories: ["UI actions"],
    tags: [
      "approve",
      "check",
      "complete",
      "correct",
      "done",
      "label",
      "mark",
      "match",
      "ok",
      "select",
      "tag",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  all_out: {
    name: "all_out",
    version: 12,
    popularity: 2556,
    codepoint: 59659,
    categories: ["action"],
    tags: ["all", "circle", "out", "shape"],
  },
  allergies: {
    name: "allergies",
    version: 229,
    popularity: 6,
    codepoint: 57492,
    categories: ["Social"],
    tags: [
      "allergen",
      "allergic",
      "allergies",
      "allergy",
      "health",
      "medical",
      "pollen",
    ],
  },
  allergy: {
    name: "allergy",
    version: 229,
    popularity: 5,
    codepoint: 58958,
    categories: ["Social"],
    tags: [
      "allergen",
      "allergic",
      "allergies",
      "allergy",
      "health",
      "medical",
      "pollen",
    ],
  },
  alt_route: {
    name: "alt_route",
    version: 9,
    popularity: 9112,
    codepoint: 61828,
    categories: ["maps"],
    tags: [
      "alt",
      "alternate",
      "alternative",
      "arrows",
      "dash",
      "dashed",
      "direction",
      "maps",
      "navigation",
      "options",
      "other",
      "route",
      "routes",
      "split",
      "symbol",
    ],
  },
  alternate_email: {
    name: "alternate_email",
    version: 13,
    popularity: 36836,
    codepoint: 57574,
    categories: ["communication"],
    tags: ["@", "address", "alternate", "contact", "email", "tag"],
  },
  altitude: {
    name: "altitude",
    version: 229,
    popularity: 26,
    codepoint: 63603,
    categories: ["Social"],
    tags: ["arrow", "height", "level", "mountains", "sea", "up"],
  },
  ambulance: {
    name: "ambulance",
    version: 229,
    popularity: 8,
    codepoint: 63491,
    categories: ["Transportation"],
    tags: [
      "approve",
      "auto",
      "automobile",
      "check",
      "checkmark",
      "complete",
      "done",
      "emergency",
      "health",
      "mark",
      "medical",
      "ok",
      "select",
      "sos",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  amend: {
    name: "amend",
    version: 229,
    popularity: 8,
    codepoint: 63490,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "back",
      "direction",
      "document",
      "left",
      "navigation",
      "refresh",
      "renew",
      "rotate",
    ],
  },
  amp_stories: {
    name: "amp_stories",
    version: 229,
    popularity: 177,
    codepoint: 59923,
    categories: ["Text Formatting"],
    tags: ["amp", "stories"],
  },
  analytics: {
    name: "analytics",
    version: 12,
    popularity: 63365,
    codepoint: 61246,
    categories: ["action"],
    tags: [
      "analytics",
      "assessment",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  anchor: {
    name: "anchor",
    version: 7,
    popularity: 8139,
    codepoint: 61901,
    categories: ["action"],
    tags: ["anchor", "google", "logo"],
  },
  android: {
    name: "android",
    version: 15,
    popularity: 26180,
    codepoint: 59481,
    categories: ["action"],
    tags: ["android", "character", "logo", "mascot", "toy"],
  },
  animation: {
    name: "animation",
    version: 14,
    popularity: 4157,
    codepoint: 59164,
    categories: ["image"],
    tags: [
      "animation",
      "circles",
      "film",
      "motion",
      "movement",
      "sequence",
      "video",
    ],
  },
  aod: {
    name: "aod",
    version: 10,
    popularity: 2087,
    codepoint: 61402,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "always",
      "aod",
      "device",
      "display",
      "hardware",
      "homescreen",
      "iOS",
      "mobile",
      "on",
      "phone",
      "tablet",
    ],
  },
  aod_tablet: {
    name: "aod_tablet",
    version: 229,
    popularity: 132,
    codepoint: 63647,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "always",
      "aod",
      "device",
      "display",
      "hardware",
      "homescreen",
      "iOS",
      "mobile",
      "on",
      "phone",
      "tablet",
    ],
  },
  aod_watch: {
    name: "aod_watch",
    version: 229,
    popularity: 20,
    codepoint: 63148,
    categories: ["Hardware"],
    tags: [
      "always on display",
      "ar",
      "clock",
      "device",
      "display",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  apartment: {
    name: "apartment",
    version: 11,
    popularity: 42403,
    codepoint: 59968,
    categories: ["places"],
    tags: [
      "accommodation",
      "apartment",
      "architecture",
      "building",
      "city",
      "company",
      "estate",
      "flat",
      "home",
      "house",
      "office",
      "places",
      "real",
      "residence",
      "residential",
      "shelter",
      "units",
      "workplace",
    ],
  },
  api: {
    name: "api",
    version: 7,
    popularity: 17244,
    codepoint: 61879,
    categories: ["action"],
    tags: ["api", "developer", "development", "enterprise", "software"],
  },
  apk_document: {
    name: "apk_document",
    version: 229,
    popularity: 128,
    codepoint: 63630,
    categories: ["Android"],
    tags: [
      "android",
      "apk",
      "data",
      "document",
      "drive",
      "file",
      "page",
      "paper",
      "system",
    ],
  },
  apk_install: {
    name: "apk_install",
    version: 229,
    popularity: 183,
    codepoint: 63631,
    categories: ["Android"],
    tags: [
      "android",
      "apk",
      "arrow",
      "data",
      "document",
      "download",
      "drive",
      "file",
      "install",
      "page",
      "paper",
      "system",
    ],
  },
  app_badging: {
    name: "app_badging",
    version: 229,
    popularity: 10,
    codepoint: 63279,
    categories: ["UI actions"],
    tags: [
      "alert",
      "alerts",
      "apps",
      "badge",
      "icon",
      "notification",
      "notifications",
    ],
  },
  app_blocking: {
    name: "app_blocking",
    version: 13,
    popularity: 3398,
    codepoint: 61247,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "app",
      "application",
      "block",
      "blocking",
      "cancel",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "stop",
      "stopped",
      "tablet",
    ],
  },
  app_promo: {
    name: "app_promo",
    version: 229,
    popularity: 44,
    codepoint: 59777,
    categories: ["Hardware"],
    tags: [
      "Android",
      "app",
      "arrow",
      "arrows",
      "cell",
      "dash",
      "dashed",
      "device",
      "direction",
      "down",
      "downloading",
      "hardware",
      "mobile",
      "phone",
      "promo",
      "tablet",
    ],
  },
  app_registration: {
    name: "app_registration",
    version: 11,
    popularity: 18277,
    codepoint: 61248,
    categories: ["communication"],
    tags: ["app", "apps", "edit", "pencil", "register", "registration"],
  },
  app_shortcut: {
    name: "app_shortcut",
    version: 2,
    popularity: 3108,
    codepoint: 60132,
    categories: ["action"],
    tags: [
      "app",
      "bookmarked",
      "favorite",
      "highlight",
      "important",
      "marked",
      "mobile",
      "save",
      "saved",
      "shortcut",
      "software",
      "special",
      "star",
    ],
  },
  apparel: {
    name: "apparel",
    version: 229,
    popularity: 164,
    codepoint: 61307,
    categories: ["Household"],
    tags: [
      "apparel",
      "blouse",
      "clothes",
      "clothing",
      "fashion",
      "shit",
      "tee",
      "top",
    ],
  },
  approval: {
    name: "approval",
    version: 11,
    popularity: 9023,
    codepoint: 59778,
    categories: ["file"],
    tags: [
      "apply",
      "approval",
      "approvals",
      "approve",
      "certificate",
      "certification",
      "disapproval",
      "drive",
      "file",
      "impression",
      "ink",
      "mark",
      "postage",
      "stamp",
    ],
  },
  approval_delegation: {
    name: "approval_delegation",
    version: 229,
    popularity: 212,
    codepoint: 63562,
    categories: ["Common actions"],
    tags: [
      "apply",
      "approval",
      "approvals",
      "approve",
      "check",
      "checkmark",
      "fingers",
      "gesture",
      "giving",
      "hand",
      "hands",
    ],
  },
  apps: {
    name: "apps",
    version: 12,
    popularity: 70640,
    codepoint: 58819,
    categories: ["navigation"],
    tags: [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "squares",
      "ui",
      "ux",
    ],
  },
  apps_outage: {
    name: "apps_outage",
    version: 2,
    popularity: 2986,
    codepoint: 59340,
    categories: ["navigation"],
    tags: [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "outage",
      "squares",
      "ui",
      "ux",
    ],
  },
  aq: {
    name: "aq",
    version: 229,
    popularity: 5,
    codepoint: 62810,
    categories: ["Home"],
    tags: [
      "air",
      "air quality",
      "alphabet",
      "atmosphere",
      "bismuth",
      "character",
      "climate",
      "font",
      "letter",
      "pollutants",
      "pollution",
      "quality",
      "symbol",
      "text",
      "type",
    ],
  },
  aq_indoor: {
    name: "aq_indoor",
    version: 229,
    popularity: 8,
    codepoint: 62811,
    categories: ["Home"],
    tags: [
      "air",
      "air quality",
      "alphabet",
      "atmosphere",
      "bismuth",
      "character",
      "climate",
      "font",
      "letter",
      "pollutants",
      "pollution",
      "quality",
      "symbol",
      "text",
      "type",
    ],
  },
  ar_on_you: {
    name: "ar_on_you",
    version: 229,
    popularity: 65,
    codepoint: 61308,
    categories: ["Audio&Video"],
    tags: [
      "ar",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "on",
      "person",
      "pleased",
      "smile",
      "smiling",
      "survey",
      "you",
    ],
  },
  ar_stickers: {
    name: "ar_stickers",
    version: 229,
    popularity: 15,
    codepoint: 59779,
    categories: ["Audio&Video"],
    tags: [
      "ar",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happy",
      "mood",
      "playground",
      "smile",
      "smiling",
      "stickers",
      "survey",
    ],
  },
  architecture: {
    name: "architecture",
    version: 11,
    popularity: 10564,
    codepoint: 59963,
    categories: ["social"],
    tags: [
      "architecture",
      "art",
      "compass",
      "design",
      "draw",
      "drawing",
      "engineering",
      "geometric",
      "tool",
    ],
  },
  archive: {
    name: "archive",
    version: 16,
    popularity: 22555,
    codepoint: 57673,
    categories: ["content"],
    tags: ["archive", "inbox", "mail", "store"],
  },
  area_chart: {
    name: "area_chart",
    version: 3,
    popularity: 5904,
    codepoint: 59248,
    categories: ["editor"],
    tags: [
      "analytics",
      "area",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  arming_countdown: {
    name: "arming_countdown",
    version: 229,
    popularity: 172,
    codepoint: 59274,
    categories: ["Home"],
    tags: ["arming", "countdown", "home", "nest", "security", "shield"],
  },
  arrow_and_edge: {
    name: "arrow_and_edge",
    version: 229,
    popularity: 17,
    codepoint: 62935,
    categories: ["UI actions"],
    tags: [
      "arrows",
      "directions",
      "down",
      "merge",
      "navigation",
      "path",
      "route",
    ],
  },
  arrow_back: {
    name: "arrow_back",
    version: 17,
    popularity: 231275,
    codepoint: 58820,
    categories: ["navigation"],
    tags: [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_back_ios: {
    name: "arrow_back_ios",
    version: 14,
    popularity: 164442,
    codepoint: 58848,
    categories: ["navigation"],
    tags: [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_back_ios_new: {
    name: "arrow_back_ios_new",
    version: 6,
    popularity: 49752,
    codepoint: 58090,
    categories: ["navigation"],
    tags: [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "new",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_circle_down: {
    name: "arrow_circle_down",
    version: 8,
    popularity: 15017,
    codepoint: 61825,
    categories: ["action"],
    tags: ["arrow", "circle", "direction", "down", "navigation"],
  },
  arrow_circle_left: {
    name: "arrow_circle_left",
    version: 2,
    popularity: 12672,
    codepoint: 60071,
    categories: ["action"],
    tags: ["arrow", "circle", "direction", "left", "navigation"],
  },
  arrow_circle_right: {
    name: "arrow_circle_right",
    version: 2,
    popularity: 21148,
    codepoint: 60074,
    categories: ["action"],
    tags: ["arrow", "circle", "direction", "navigation", "right"],
  },
  arrow_circle_up: {
    name: "arrow_circle_up",
    version: 8,
    popularity: 17695,
    codepoint: 61826,
    categories: ["action"],
    tags: ["arrow", "circle", "direction", "navigation", "up"],
  },
  arrow_downward: {
    name: "arrow_downward",
    version: 17,
    popularity: 46406,
    codepoint: 58843,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "downward",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_downward_alt: {
    name: "arrow_downward_alt",
    version: 229,
    popularity: 89,
    codepoint: 59780,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "down", "downward"],
  },
  arrow_drop_down: {
    name: "arrow_drop_down",
    version: 16,
    popularity: 153217,
    codepoint: 58821,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_drop_down_circle: {
    name: "arrow_drop_down_circle",
    version: 12,
    popularity: 13565,
    codepoint: 58822,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "circle",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_drop_up: {
    name: "arrow_drop_up",
    version: 12,
    popularity: 32684,
    codepoint: 58823,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_forward: {
    name: "arrow_forward",
    version: 13,
    popularity: 119611,
    codepoint: 58824,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_forward_ios: {
    name: "arrow_forward_ios",
    version: 16,
    popularity: 166553,
    codepoint: 58849,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "direction",
      "forward",
      "interface",
      "ios",
      "navigation",
      "next",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_insert: {
    name: "arrow_insert",
    version: 229,
    popularity: 35,
    codepoint: 63543,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "insert",
      "interface",
      "left",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_left: {
    name: "arrow_left",
    version: 12,
    popularity: 19146,
    codepoint: 58846,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "left",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_left_alt: {
    name: "arrow_left_alt",
    version: 229,
    popularity: 54,
    codepoint: 61309,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "left", "side"],
  },
  arrow_or_edge: {
    name: "arrow_or_edge",
    version: 229,
    popularity: 10,
    codepoint: 62934,
    categories: ["UI actions"],
    tags: [
      "arrows",
      "directions",
      "down",
      "merge",
      "navigation",
      "path",
      "route",
    ],
  },
  arrow_outward: {
    name: "arrow_outward",
    version: 1,
    popularity: 2278,
    codepoint: 63694,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_range: {
    name: "arrow_range",
    version: 229,
    popularity: 15,
    codepoint: 63131,
    categories: ["UI actions"],
    tags: ["arrows", "back", "fitbit", "forth", "scope"],
  },
  arrow_right: {
    name: "arrow_right",
    version: 12,
    popularity: 44154,
    codepoint: 58847,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_right_alt: {
    name: "arrow_right_alt",
    version: 13,
    popularity: 69611,
    codepoint: 59713,
    categories: ["action"],
    tags: [
      "alt",
      "arrow",
      "arrows",
      "direction",
      "east",
      "navigation",
      "pointing",
      "right",
    ],
  },
  arrow_selector_tool: {
    name: "arrow_selector_tool",
    version: 229,
    popularity: 234,
    codepoint: 63535,
    categories: ["Common actions"],
    tags: [
      "arrow",
      "arrows",
      "click",
      "direction",
      "left",
      "mouse",
      "pointer",
      "pointing",
      "select",
    ],
  },
  arrow_split: {
    name: "arrow_split",
    version: 229,
    popularity: 147,
    codepoint: 59781,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "down",
      "multiple",
      "options",
      "right",
      "split",
      "up",
    ],
  },
  arrow_top_left: {
    name: "arrow_top_left",
    version: 229,
    popularity: 10,
    codepoint: 63278,
    categories: ["UI actions"],
    tags: ["arrows", "direction", "navigation", "turn", "up", "west"],
  },
  arrow_top_right: {
    name: "arrow_top_right",
    version: 229,
    popularity: 7,
    codepoint: 63277,
    categories: ["UI actions"],
    tags: ["arrows", "direction", "east", "navigation", "turn", "up"],
  },
  arrow_upward: {
    name: "arrow_upward",
    version: 11,
    popularity: 61422,
    codepoint: 58840,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "upward",
      "ux",
      "web",
      "website",
    ],
  },
  arrow_upward_alt: {
    name: "arrow_upward_alt",
    version: 229,
    popularity: 113,
    codepoint: 59782,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "up", "upward"],
  },
  arrows_more_down: {
    name: "arrows_more_down",
    version: 229,
    popularity: 154,
    codepoint: 63659,
    categories: ["Home"],
    tags: ["angle", "arrows", "climate", "down", "home", "nest", "thermostat"],
  },
  arrows_more_up: {
    name: "arrows_more_up",
    version: 229,
    popularity: 328,
    codepoint: 63660,
    categories: ["Home"],
    tags: ["angle", "arrows", "climate", "home", "nest", "thermostat", "up"],
  },
  arrows_outward: {
    name: "arrows_outward",
    version: 229,
    popularity: 10,
    codepoint: 63276,
    categories: ["UI actions"],
    tags: ["arrow", "double", "left", "out", "right"],
  },
  art_track: {
    name: "art_track",
    version: 13,
    popularity: 2121,
    codepoint: 57440,
    categories: ["av"],
    tags: [
      "album",
      "art",
      "artist",
      "audio",
      "image",
      "music",
      "photo",
      "photography",
      "picture",
      "sound",
      "track",
      "tracks",
    ],
  },
  article: {
    name: "article",
    version: 13,
    popularity: 98496,
    codepoint: 61250,
    categories: ["action"],
    tags: [
      "article",
      "doc",
      "document",
      "file",
      "page",
      "paper",
      "text",
      "writing",
    ],
  },
  article_shortcut: {
    name: "article_shortcut",
    version: 229,
    popularity: 18,
    codepoint: 62855,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "article",
      "direction",
      "doc",
      "document",
      "file",
      "forward",
      "page",
      "paper",
      "query",
      "query reference",
      "reference",
      "right",
      "shortcut",
      "text",
      "writing",
    ],
  },
  artist: {
    name: "artist",
    version: 229,
    popularity: 24,
    codepoint: 57370,
    categories: ["Audio&Video"],
    tags: [
      "account",
      "artist",
      "face",
      "human",
      "music",
      "musical",
      "musician",
      "note",
      "people",
      "person",
      "playlist",
      "profile",
      "song",
      "user",
    ],
  },
  aspect_ratio: {
    name: "aspect_ratio",
    version: 12,
    popularity: 12416,
    codepoint: 59483,
    categories: ["action"],
    tags: [
      "aspect",
      "dash",
      "dashed",
      "expand",
      "image",
      "ratio",
      "resize",
      "scale",
      "size",
      "square",
    ],
  },
  assignment: {
    name: "assignment",
    version: 15,
    popularity: 77407,
    codepoint: 59485,
    categories: ["action"],
    tags: ["assignment", "clipboard", "doc", "document", "text", "writing"],
  },
  assignment_add: {
    name: "assignment_add",
    version: 1,
    popularity: 2613,
    codepoint: 63560,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "new",
      "note",
      "plus",
      "quick",
      "symbol",
      "text",
      "writing",
    ],
  },
  assignment_ind: {
    name: "assignment_ind",
    version: 12,
    popularity: 35242,
    codepoint: 59486,
    categories: ["action"],
    tags: [
      "account",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "face",
      "ind",
      "people",
      "person",
      "profile",
      "user",
    ],
  },
  assignment_late: {
    name: "assignment_late",
    version: 15,
    popularity: 8538,
    codepoint: 59487,
    categories: ["action"],
    tags: [
      "!",
      "alert",
      "assignment",
      "attention",
      "caution",
      "clipboard",
      "danger",
      "doc",
      "document",
      "error",
      "exclamation",
      "important",
      "late",
      "mark",
      "notification",
      "symbol",
      "warning",
    ],
  },
  assignment_return: {
    name: "assignment_return",
    version: 14,
    popularity: 8550,
    codepoint: 59488,
    categories: ["action"],
    tags: [
      "arrow",
      "assignment",
      "back",
      "clipboard",
      "doc",
      "document",
      "left",
      "retun",
    ],
  },
  assignment_returned: {
    name: "assignment_returned",
    version: 12,
    popularity: 5736,
    codepoint: 59489,
    categories: ["action"],
    tags: [
      "arrow",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "down",
      "returned",
    ],
  },
  assignment_turned_in: {
    name: "assignment_turned_in",
    version: 16,
    popularity: 31211,
    codepoint: 59490,
    categories: ["action"],
    tags: [
      "approve",
      "assignment",
      "check",
      "clipboard",
      "complete",
      "doc",
      "document",
      "done",
      "in",
      "mark",
      "ok",
      "select",
      "tick",
      "turn",
      "validate",
      "verified",
      "yes",
    ],
  },
  assist_walker: {
    name: "assist_walker",
    version: 1,
    popularity: 678,
    codepoint: 63701,
    categories: ["social"],
    tags: [
      "accessibility",
      "accessible",
      "assist",
      "body",
      "disability",
      "handicap",
      "help",
      "human",
      "injured",
      "injury",
      "mobility",
      "person",
      "walk",
      "walker",
    ],
  },
  assistant_device: {
    name: "assistant_device",
    version: 229,
    popularity: 11,
    codepoint: 59783,
    categories: ["Hardware"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless",
    ],
  },
  assistant_direction: {
    name: "assistant_direction",
    version: 14,
    popularity: 5242,
    codepoint: 59784,
    categories: ["navigation"],
    tags: [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigate",
      "navigation",
      "pin",
      "place",
      "right",
      "stop",
    ],
  },
  assistant_navigation: {
    name: "assistant_navigation",
    version: 10,
    popularity: 1464,
    codepoint: 59785,
    categories: ["navigation"],
    tags: [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop",
      "straight",
      "up",
    ],
  },
  assistant_on_hub: {
    name: "assistant_on_hub",
    version: 229,
    popularity: 18,
    codepoint: 63169,
    categories: ["Home"],
    tags: [
      "IoT",
      "assistant",
      "desktop",
      "device",
      "display",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "monitor",
      "nest",
      "screen",
      "smart",
      "wireless",
    ],
  },
  assured_workload: {
    name: "assured_workload",
    version: 1,
    popularity: 4787,
    codepoint: 60271,
    categories: ["action"],
    tags: [
      "assured",
      "compliance",
      "confidential",
      "federal",
      "government",
      "secure",
      "sensitive regulatory",
      "workload",
    ],
  },
  asterisk: {
    name: "asterisk",
    version: 229,
    popularity: 1,
    codepoint: 62757,
    categories: ["Text Formatting"],
    tags: ["annotation", "character", "font", "mark", "symbol", "text", "type"],
  },
  atm: {
    name: "atm",
    version: 12,
    popularity: 2842,
    codepoint: 58739,
    categories: ["maps"],
    tags: [
      "alphabet",
      "atm",
      "automated",
      "bill",
      "card",
      "cart",
      "cash",
      "character",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "font",
      "letter",
      "machine",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol",
      "teller",
      "text",
      "type",
    ],
  },
  atr: {
    name: "atr",
    version: 229,
    popularity: 986,
    codepoint: 60359,
    categories: ["Business&Payments"],
    tags: ["circles", "collaboration", "dot", "group", "space", "team", "work"],
  },
  attach_email: {
    name: "attach_email",
    version: 16,
    popularity: 5611,
    codepoint: 59998,
    categories: ["file"],
    tags: [
      "attach",
      "attachment",
      "clip",
      "compose",
      "email",
      "envelop",
      "letter",
      "link",
      "mail",
      "message",
      "send",
    ],
  },
  attach_file: {
    name: "attach_file",
    version: 16,
    popularity: 45885,
    codepoint: 57894,
    categories: ["editor"],
    tags: [
      "add",
      "attach",
      "attachment",
      "clip",
      "file",
      "link",
      "mail",
      "media",
    ],
  },
  attach_file_add: {
    name: "attach_file_add",
    version: 229,
    popularity: 4,
    codepoint: 63553,
    categories: ["Text Formatting"],
    tags: [
      "+",
      "add",
      "attach",
      "attachment",
      "clip",
      "file",
      "link",
      "mail",
      "media",
      "new",
      "plus",
    ],
  },
  attach_money: {
    name: "attach_money",
    version: 17,
    popularity: 82656,
    codepoint: 57895,
    categories: ["editor"],
    tags: [
      "attach",
      "attachment",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol",
    ],
  },
  attachment: {
    name: "attachment",
    version: 15,
    popularity: 22216,
    codepoint: 58044,
    categories: ["file"],
    tags: ["attach", "attachment", "clip", "compose", "file", "image", "link"],
  },
  attractions: {
    name: "attractions",
    version: 10,
    popularity: 4010,
    codepoint: 59986,
    categories: ["maps"],
    tags: [
      "amusement",
      "attractions",
      "entertainment",
      "ferris",
      "fun",
      "maps",
      "park",
      "places",
      "wheel",
    ],
  },
  attribution: {
    name: "attribution",
    version: 11,
    popularity: 5103,
    codepoint: 61403,
    categories: ["content"],
    tags: [
      "attribute",
      "attribution",
      "body",
      "copyright",
      "copywriter",
      "human",
      "people",
      "person",
    ],
  },
  audio_description: {
    name: "audio_description",
    version: 229,
    popularity: 12,
    codepoint: 62860,
    categories: ["Audio&Video"],
    tags: [
      "accessibility",
      "alphabet",
      "character",
      "descriptive",
      "font",
      "letter",
      "movie",
      "narrated",
      "screen",
      "script",
      "symbol",
      "text",
      "tv",
      "type",
      "video",
    ],
  },
  audio_file: {
    name: "audio_file",
    version: 1,
    popularity: 2362,
    codepoint: 60290,
    categories: ["av"],
    tags: [
      "audio",
      "doc",
      "document",
      "key",
      "music",
      "note",
      "sound",
      "track",
    ],
  },
  audio_video_receiver: {
    name: "audio_video_receiver",
    version: 229,
    popularity: 3,
    codepoint: 62931,
    categories: ["Hardware"],
    tags: [
      "amp",
      "amplifier",
      "av",
      "avr",
      "home theater",
      "renderer",
      "stereo",
      "television",
      "theater",
      "tv",
    ],
  },
  auto_awesome_mosaic: {
    name: "auto_awesome_mosaic",
    version: 14,
    popularity: 5387,
    codepoint: 58976,
    categories: ["image"],
    tags: [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "mosaic",
      "photo",
    ],
  },
  auto_awesome_motion: {
    name: "auto_awesome_motion",
    version: 14,
    popularity: 6966,
    codepoint: 58977,
    categories: ["image"],
    tags: [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "motion",
      "photo",
      "video",
    ],
  },
  auto_delete: {
    name: "auto_delete",
    version: 11,
    popularity: 6268,
    codepoint: 59980,
    categories: ["alert"],
    tags: [
      "auto",
      "bin",
      "can",
      "clock",
      "date",
      "delete",
      "garbage",
      "remove",
      "schedule",
      "time",
      "trash",
    ],
  },
  auto_read_pause: {
    name: "auto_read_pause",
    version: 229,
    popularity: 276,
    codepoint: 61977,
    categories: ["Communication"],
    tags: [
      "auto",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "listen",
      "message",
      "pause",
      "read",
      "speech",
      "stop",
    ],
  },
  auto_read_play: {
    name: "auto_read_play",
    version: 229,
    popularity: 398,
    codepoint: 61974,
    categories: ["Communication"],
    tags: [
      "auto",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "listen",
      "message",
      "play",
      "read",
      "speech",
    ],
  },
  auto_stories: {
    name: "auto_stories",
    version: 13,
    popularity: 37596,
    codepoint: 58982,
    categories: ["image"],
    tags: ["auto", "book", "flipping", "pages", "stories"],
  },
  auto_towing: {
    name: "auto_towing",
    version: 229,
    popularity: 11,
    codepoint: 59166,
    categories: ["Transportation"],
    tags: [
      "auto",
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "tow",
      "towing",
      "transportation",
      "truck",
      "vehicle",
    ],
  },
  auto_transmission: {
    name: "auto_transmission",
    version: 229,
    popularity: 9,
    codepoint: 62783,
    categories: ["Transportation"],
    tags: [
      "automatic transmission",
      "automobile",
      "automotive",
      "car",
      "clutch",
      "gearbox",
      "gears",
      "manual transmission",
      "stick shift",
    ],
  },
  autofps_select: {
    name: "autofps_select",
    version: 10,
    popularity: 838,
    codepoint: 61404,
    categories: ["image"],
    tags: [
      "A",
      "alphabet",
      "auto",
      "character",
      "font",
      "fps",
      "frame",
      "frequency",
      "letter",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  autopause: {
    name: "autopause",
    version: 229,
    popularity: 21,
    codepoint: 63158,
    categories: ["Audio&Video"],
    tags: [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "pause",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "sync",
    ],
  },
  autoplay: {
    name: "autoplay",
    version: 229,
    popularity: 24,
    codepoint: 63157,
    categories: ["Audio&Video"],
    tags: [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "play",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "sync",
    ],
  },
  autorenew: {
    name: "autorenew",
    version: 12,
    popularity: 65354,
    codepoint: 59491,
    categories: ["action"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "autorenew",
      "cache",
      "cached",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "renew",
      "rotate",
      "turn",
    ],
  },
  autostop: {
    name: "autostop",
    version: 229,
    popularity: 8,
    codepoint: 63106,
    categories: ["Audio&Video"],
    tags: [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "stop",
      "sync",
    ],
  },
  av_timer: {
    name: "av_timer",
    version: 12,
    popularity: 7328,
    codepoint: 57371,
    categories: ["av"],
    tags: [
      "av",
      "clock",
      "countdown",
      "duration",
      "minutes",
      "seconds",
      "time",
      "timer",
      "watch",
    ],
  },
  avg_pace: {
    name: "avg_pace",
    version: 229,
    popularity: 29,
    codepoint: 63163,
    categories: ["Activities"],
    tags: [
      "average",
      "clock",
      "dash",
      "dashed",
      "fitness",
      "rate",
      "speed",
      "stopwatch",
      "time",
      "timer",
    ],
  },
  avg_time: {
    name: "avg_time",
    version: 229,
    popularity: 24,
    codepoint: 63507,
    categories: ["Activities"],
    tags: [
      "average",
      "fitness",
      "health",
      "measure",
      "monitor",
      "stopwatch",
      "timer",
      "wellness",
    ],
  },
  award_star: {
    name: "award_star",
    version: 229,
    popularity: 79,
    codepoint: 62994,
    categories: ["Common actions"],
    tags: ["badge", "emblem", "prize", "reward", "seal"],
  },
  azm: {
    name: "azm",
    version: 229,
    popularity: 41,
    codepoint: 63212,
    categories: ["Activities"],
    tags: [
      "active",
      "active zone minutes",
      "exercise",
      "fitbit",
      "fitness",
      "minutes",
      "sports",
      "workout",
      "zone",
    ],
  },
  baby_changing_station: {
    name: "baby_changing_station",
    version: 8,
    popularity: 2817,
    codepoint: 61851,
    categories: ["places"],
    tags: [
      "babies",
      "baby",
      "bathroom",
      "body",
      "changing",
      "child",
      "children",
      "father",
      "human",
      "infant",
      "kids",
      "mother",
      "newborn",
      "people",
      "person",
      "station",
      "toddler",
      "wc",
      "young",
    ],
  },
  back_hand: {
    name: "back_hand",
    version: 3,
    popularity: 10666,
    codepoint: 59236,
    categories: ["social"],
    tags: ["back", "fingers", "gesture", "hand", "raised"],
  },
  back_to_tab: {
    name: "back_to_tab",
    version: 229,
    popularity: 10,
    codepoint: 63275,
    categories: ["UI actions"],
    tags: ["arrow", "frame", "left", "up", "window"],
  },
  background_dot_large: {
    name: "background_dot_large",
    version: 229,
    popularity: 7,
    codepoint: 63390,
    categories: ["Photo&Image"],
    tags: ["dots", "grid", "layout", "space", "square"],
  },
  background_grid_small: {
    name: "background_grid_small",
    version: 229,
    popularity: 7,
    codepoint: 63389,
    categories: ["Photo&Image"],
    tags: ["4", "4x4", "layout", "line", "space"],
  },
  background_replace: {
    name: "background_replace",
    version: 229,
    popularity: 1252,
    codepoint: 61962,
    categories: ["Common actions"],
    tags: [
      "backdrop",
      "background",
      "camera",
      "filter",
      "human",
      "people",
      "person",
      "replace",
      "user",
      "video",
      "webcam",
    ],
  },
  backlight_high: {
    name: "backlight_high",
    version: 229,
    popularity: 2,
    codepoint: 63469,
    categories: ["Android"],
    tags: ["backlit", "bright", "brightness"],
  },
  backlight_low: {
    name: "backlight_low",
    version: 229,
    popularity: 4,
    codepoint: 63468,
    categories: ["Android"],
    tags: ["backlit", "brightness", "dim"],
  },
  backpack: {
    name: "backpack",
    version: 8,
    popularity: 3738,
    codepoint: 61852,
    categories: ["places"],
    tags: [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "knapsack",
      "pack",
      "storage",
      "travel",
    ],
  },
  backspace: {
    name: "backspace",
    version: 13,
    popularity: 26165,
    codepoint: 57674,
    categories: ["content"],
    tags: [
      "arrow",
      "back",
      "backspace",
      "cancel",
      "clear",
      "correct",
      "delete",
      "erase",
      "remove",
    ],
  },
  backup: {
    name: "backup",
    version: 15,
    popularity: 15877,
    codepoint: 59492,
    categories: ["action"],
    tags: [
      "arrow",
      "backup",
      "cloud",
      "data",
      "drive",
      "files folders",
      "storage",
      "up",
      "upload",
    ],
  },
  backup_table: {
    name: "backup_table",
    version: 11,
    popularity: 5486,
    codepoint: 61251,
    categories: ["action"],
    tags: [
      "backup",
      "drive",
      "files folders",
      "format",
      "layout",
      "stack",
      "storage",
      "table",
    ],
  },
  badge: {
    name: "badge",
    version: 11,
    popularity: 55961,
    codepoint: 60007,
    categories: ["maps"],
    tags: [
      "account",
      "avatar",
      "badge",
      "card",
      "certified",
      "employee",
      "face",
      "human",
      "identification",
      "name",
      "people",
      "person",
      "profile",
      "security",
      "user",
      "work",
    ],
  },
  badge_critical_battery: {
    name: "badge_critical_battery",
    version: 229,
    popularity: 330,
    codepoint: 61767,
    categories: ["Android"],
    tags: ["badge", "battery", "cell", "charge", "critical", "mobile", "power"],
  },
  bakery_dining: {
    name: "bakery_dining",
    version: 11,
    popularity: 5910,
    codepoint: 59987,
    categories: ["maps"],
    tags: [
      "bakery",
      "bread",
      "breakfast",
      "brunch",
      "croissant",
      "dining",
      "food",
    ],
  },
  balance: {
    name: "balance",
    version: 1,
    popularity: 8341,
    codepoint: 60150,
    categories: ["action"],
    tags: [
      "balance",
      "equal",
      "equity",
      "impartiality",
      "justice",
      "parity",
      "stability. equilibrium",
      "steadiness",
      "symmetry",
    ],
  },
  balcony: {
    name: "balcony",
    version: 4,
    popularity: 2250,
    codepoint: 58767,
    categories: ["places"],
    tags: [
      "architecture",
      "balcony",
      "doors",
      "estate",
      "home",
      "house",
      "maps",
      "out",
      "outside",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "terrace",
      "window",
    ],
  },
  ballot: {
    name: "ballot",
    version: 14,
    popularity: 13575,
    codepoint: 57714,
    categories: ["content"],
    tags: ["ballot", "bullet", "election", "list", "point", "poll", "vote"],
  },
  bar_chart: {
    name: "bar_chart",
    version: 13,
    popularity: 36270,
    codepoint: 57963,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  bar_chart_4_bars: {
    name: "bar_chart_4_bars",
    version: 229,
    popularity: 109,
    codepoint: 63105,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "fitbit",
      "fitness",
      "graph",
      "health dashboard",
      "health metric dashboard",
      "health monitoring",
      "infographic",
      "measure",
      "metric",
      "metrics",
      "oxygen",
      "statistics",
      "tracking",
    ],
  },
  barcode: {
    name: "barcode",
    version: 229,
    popularity: 2399,
    codepoint: 59147,
    categories: ["Business&Payments"],
    tags: ["barcode", "ecommerce", "price", "scan", "shop", "shopping", "tag"],
  },
  barcode_reader: {
    name: "barcode_reader",
    version: 1,
    popularity: 2338,
    codepoint: 63580,
    categories: ["action"],
    tags: [
      "barcode",
      "ecommerce",
      "handheld",
      "machine",
      "price",
      "reader",
      "scan",
      "scanner",
      "shop",
      "shopping",
      "tag",
    ],
  },
  barcode_scanner: {
    name: "barcode_scanner",
    version: 229,
    popularity: 2897,
    codepoint: 59148,
    categories: ["Business&Payments"],
    tags: ["barcode", "ecommerce", "price", "scan", "shop", "shopping", "tag"],
  },
  barefoot: {
    name: "barefoot",
    version: 229,
    popularity: 21,
    codepoint: 63601,
    categories: ["Social"],
    tags: [
      "bare",
      "feet",
      "foot",
      "footmark",
      "footprint",
      "footstep",
      "footsteps",
      "ground",
      "human",
      "impression",
      "trace",
      "walking",
    ],
  },
  batch_prediction: {
    name: "batch_prediction",
    version: 12,
    popularity: 4448,
    codepoint: 61685,
    categories: ["action"],
    tags: ["batch", "bulb", "idea", "light", "prediction"],
  },
  bath_outdoor: {
    name: "bath_outdoor",
    version: 229,
    popularity: 21,
    codepoint: 63227,
    categories: ["Activities"],
    tags: [
      "bathe",
      "bathhouse",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "onsen",
      "outside",
      "spa",
      "steam",
      "water",
    ],
  },
  bath_private: {
    name: "bath_private",
    version: 229,
    popularity: 27,
    codepoint: 63226,
    categories: ["Activities"],
    tags: [
      "bathe",
      "bathhouse",
      "body",
      "couple",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "inside",
      "onsen",
      "people",
      "person",
      "spa",
      "steam",
      "water",
    ],
  },
  bath_public_large: {
    name: "bath_public_large",
    version: 229,
    popularity: 9,
    codepoint: 63225,
    categories: ["Activities"],
    tags: [
      "bathe",
      "bathhouse",
      "body",
      "group",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "inside",
      "onsen",
      "people",
      "person",
      "spa",
      "steam",
      "water",
    ],
  },
  bathroom: {
    name: "bathroom",
    version: 10,
    popularity: 3425,
    codepoint: 61405,
    categories: ["search"],
    tags: [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc",
    ],
  },
  bathtub: {
    name: "bathtub",
    version: 11,
    popularity: 6215,
    codepoint: 59969,
    categories: ["places"],
    tags: [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "home",
      "hotel",
      "human",
      "person",
      "shower",
      "travel",
      "tub",
    ],
  },
  battery_0_bar: {
    name: "battery_0_bar",
    version: 1,
    popularity: 2516,
    codepoint: 60380,
    categories: ["device"],
    tags: ["0", "bar", "battery", "cell", "charge", "full", "mobile", "power"],
  },
  battery_1_bar: {
    name: "battery_1_bar",
    version: 1,
    popularity: 1898,
    codepoint: 60377,
    categories: ["device"],
    tags: ["1", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_2_bar: {
    name: "battery_2_bar",
    version: 1,
    popularity: 1598,
    codepoint: 60384,
    categories: ["device"],
    tags: ["2", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_3_bar: {
    name: "battery_3_bar",
    version: 1,
    popularity: 2207,
    codepoint: 60381,
    categories: ["device"],
    tags: ["3", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_4_bar: {
    name: "battery_4_bar",
    version: 1,
    popularity: 2635,
    codepoint: 60386,
    categories: ["device"],
    tags: ["4", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_5_bar: {
    name: "battery_5_bar",
    version: 1,
    popularity: 3217,
    codepoint: 60372,
    categories: ["device"],
    tags: ["5", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_6_bar: {
    name: "battery_6_bar",
    version: 1,
    popularity: 2351,
    codepoint: 60370,
    categories: ["device"],
    tags: ["6", "bar", "battery", "cell", "charge", "mobile", "power"],
  },
  battery_alert: {
    name: "battery_alert",
    version: 12,
    popularity: 4380,
    codepoint: 57756,
    categories: ["device"],
    tags: [
      "!",
      "alert",
      "attention",
      "battery",
      "caution",
      "cell",
      "charge",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "mobile",
      "notification",
      "power",
      "symbol",
      "warning",
    ],
  },
  battery_change: {
    name: "battery_change",
    version: 229,
    popularity: 4,
    codepoint: 63467,
    categories: ["Android"],
    tags: ["cell", "charge", "mobile", "power", "surge"],
  },
  battery_charging_20: {
    name: "battery_charging_20",
    version: 12,
    popularity: 19,
    codepoint: 61602,
    categories: ["device"],
    tags: [
      "20",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightning",
      "low",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_30: {
    name: "battery_charging_30",
    version: 12,
    popularity: 15,
    codepoint: 61603,
    categories: ["device"],
    tags: [
      "30",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "low",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_50: {
    name: "battery_charging_50",
    version: 11,
    popularity: 18,
    codepoint: 61604,
    categories: ["device"],
    tags: [
      "50",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "half",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_60: {
    name: "battery_charging_60",
    version: 13,
    popularity: 19,
    codepoint: 61605,
    categories: ["device"],
    tags: [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_80: {
    name: "battery_charging_80",
    version: 12,
    popularity: 20,
    codepoint: 61606,
    categories: ["device"],
    tags: [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_90: {
    name: "battery_charging_90",
    version: 12,
    popularity: 25,
    codepoint: 61607,
    categories: ["device"],
    tags: [
      "90",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_charging_full: {
    name: "battery_charging_full",
    version: 12,
    popularity: 14595,
    codepoint: 57763,
    categories: ["device"],
    tags: [
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "full",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt",
    ],
  },
  battery_error: {
    name: "battery_error",
    version: 229,
    popularity: 9,
    codepoint: 63466,
    categories: ["Android"],
    tags: [
      "alert",
      "cancel",
      "cell",
      "charge",
      "clear",
      "close",
      "exit",
      "issue",
      "mobile",
      "no",
      "power",
      "remove",
      "stop",
      "warning",
      "x",
    ],
  },
  battery_full: {
    name: "battery_full",
    version: 12,
    popularity: 19876,
    codepoint: 57764,
    categories: ["device"],
    tags: ["0", "bar", "battery", "cell", "charge", "full", "mobile", "power"],
  },
  battery_full_alt: {
    name: "battery_full_alt",
    version: 229,
    popularity: 1143,
    codepoint: 61755,
    categories: ["Android"],
    tags: ["0", "bar", "battery", "cell", "charge", "full", "mobile", "power"],
  },
  battery_horiz_000: {
    name: "battery_horiz_000",
    version: 229,
    popularity: 288,
    codepoint: 63662,
    categories: ["Home"],
    tags: ["battery ", "empty", "home", "horizontal", "nest"],
  },
  battery_horiz_050: {
    name: "battery_horiz_050",
    version: 229,
    popularity: 484,
    codepoint: 63663,
    categories: ["Home"],
    tags: ["50%", "battery", "fifty", "home", "horizontal", "nest", "percent"],
  },
  battery_horiz_075: {
    name: "battery_horiz_075",
    version: 229,
    popularity: 807,
    codepoint: 63664,
    categories: ["Home"],
    tags: [
      "75%",
      "battery",
      "home",
      "horizontal",
      "nest",
      "percent",
      "seventy-five",
    ],
  },
  battery_low: {
    name: "battery_low",
    version: 229,
    popularity: 683,
    codepoint: 61781,
    categories: ["Android"],
    tags: ["bar", "cell", "charge", "horizontal", "mobile", "power"],
  },
  battery_plus: {
    name: "battery_plus",
    version: 229,
    popularity: 5,
    codepoint: 63465,
    categories: ["Android"],
    tags: [
      "+",
      "add",
      "battery",
      "charge",
      "charging",
      "new",
      "plus",
      "power",
      "saver",
      "symbol",
    ],
  },
  battery_profile: {
    name: "battery_profile",
    version: 229,
    popularity: 179,
    codepoint: 57862,
    categories: ["Home"],
    tags: [
      "application",
      "battery",
      "change",
      "details",
      "gear",
      "home",
      "info",
      "information",
      "nest",
      "options",
      "personal",
      "profile",
      "security",
      "service",
      "settings",
    ],
  },
  battery_saver: {
    name: "battery_saver",
    version: 9,
    popularity: 3139,
    codepoint: 61406,
    categories: ["device"],
    tags: [
      "+",
      "add",
      "battery",
      "charge",
      "charging",
      "new",
      "plus",
      "power",
      "saver",
      "symbol",
    ],
  },
  battery_share: {
    name: "battery_share",
    version: 229,
    popularity: 0,
    codepoint: 63102,
    categories: ["Android"],
    tags: ["arrow", "arrows", "cell", "charge", "mobile", "power", "right"],
  },
  battery_status_good: {
    name: "battery_status_good",
    version: 229,
    popularity: 13,
    codepoint: 63101,
    categories: ["Android"],
    tags: [
      "approve",
      "cell",
      "charge",
      "check",
      "checkmark",
      "complete",
      "done",
      "mobile",
      "ok",
      "power",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  battery_unknown: {
    name: "battery_unknown",
    version: 13,
    popularity: 2563,
    codepoint: 57766,
    categories: ["device"],
    tags: [
      "?",
      "assistance",
      "battery",
      "cell",
      "charge",
      "help",
      "info",
      "information",
      "mobile",
      "power",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "unknown",
    ],
  },
  battery_very_low: {
    name: "battery_very_low",
    version: 229,
    popularity: 432,
    codepoint: 61782,
    categories: ["Android"],
    tags: ["bar", "battery", "cell", "charge", "low", "mobile", "power"],
  },
  beach_access: {
    name: "beach_access",
    version: 12,
    popularity: 9296,
    codepoint: 60222,
    categories: ["places"],
    tags: ["access", "beach", "places", "summer", "sunny", "umbrella"],
  },
  bed: {
    name: "bed",
    version: 10,
    popularity: 13648,
    codepoint: 61407,
    categories: ["search"],
    tags: [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "size",
      "sleep",
    ],
  },
  bedroom_baby: {
    name: "bedroom_baby",
    version: 9,
    popularity: 3465,
    codepoint: 61408,
    categories: ["search"],
    tags: [
      "babies",
      "baby",
      "bedroom",
      "child",
      "children",
      "home",
      "horse",
      "house",
      "infant",
      "kid",
      "newborn",
      "rocking",
      "room",
      "toddler",
      "young",
    ],
  },
  bedroom_child: {
    name: "bedroom_child",
    version: 9,
    popularity: 2862,
    codepoint: 61409,
    categories: ["search"],
    tags: [
      "bed",
      "bedroom",
      "child",
      "children",
      "furniture",
      "home",
      "hotel",
      "house",
      "kid",
      "night",
      "pillows",
      "rest",
      "room",
      "size",
      "sleep",
      "twin",
      "young",
    ],
  },
  bedroom_parent: {
    name: "bedroom_parent",
    version: 9,
    popularity: 3573,
    codepoint: 61410,
    categories: ["search"],
    tags: [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "parent",
      "pillows",
      "queen",
      "rest",
      "room",
      "sizem master",
      "sleep",
    ],
  },
  bedtime: {
    name: "bedtime",
    version: 15,
    popularity: 4211,
    codepoint: 61252,
    categories: ["image"],
    tags: ["bedtime", "nightime", "sleep"],
  },
  bedtime_off: {
    name: "bedtime_off",
    version: 2,
    popularity: 584,
    codepoint: 60278,
    categories: ["image"],
    tags: [
      "bedtime",
      "disabled",
      "lunar",
      "moon",
      "night",
      "nightime",
      "off",
      "offline",
      "slash",
      "sleep",
    ],
  },
  beenhere: {
    name: "beenhere",
    version: 12,
    popularity: 8857,
    codepoint: 58669,
    categories: ["maps"],
    tags: [
      "approve",
      "archive",
      "beenhere",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "label",
      "library",
      "mark",
      "ok",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "select",
      "tag",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  bento: {
    name: "bento",
    version: 6,
    popularity: 2085,
    codepoint: 61940,
    categories: ["places"],
    tags: [
      "bento",
      "box",
      "dinner",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "takeout",
    ],
  },
  bia: {
    name: "bia",
    version: 229,
    popularity: 13,
    codepoint: 63211,
    categories: ["Activities"],
    tags: [
      "bodies",
      "body",
      "body composition",
      "bone",
      "fat",
      "fitbit",
      "fitness",
      "health",
      "human",
      "measurement",
      "muscle",
    ],
  },
  bid_landscape: {
    name: "bid_landscape",
    version: 229,
    popularity: 99,
    codepoint: 58983,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "bid",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "landscape",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "up",
    ],
  },
  bid_landscape_disabled: {
    name: "bid_landscape_disabled",
    version: 229,
    popularity: 8,
    codepoint: 61313,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "bid",
      "chart",
      "data",
      "diagram",
      "disabled",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "off",
      "statistics",
      "tracking",
    ],
  },
  bigtop_updates: {
    name: "bigtop_updates",
    version: 229,
    popularity: 41,
    codepoint: 58985,
    categories: ["Android"],
    tags: [
      "bigtop",
      "connection",
      "internet",
      "network",
      "signal",
      "updates",
      "wifi",
      "wireless",
    ],
  },
  bike_scooter: {
    name: "bike_scooter",
    version: 11,
    popularity: 1325,
    codepoint: 61253,
    categories: ["maps"],
    tags: [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa",
    ],
  },
  biotech: {
    name: "biotech",
    version: 11,
    popularity: 13406,
    codepoint: 59962,
    categories: ["content"],
    tags: [
      "biotech",
      "chemistry",
      "laboratory",
      "microscope",
      "research",
      "science",
      "technology",
    ],
  },
  blanket: {
    name: "blanket",
    version: 229,
    popularity: 158,
    codepoint: 59432,
    categories: ["Household"],
    tags: ["blanket", "cold", "cover", "nest", "throw", "wrap"],
  },
  blender: {
    name: "blender",
    version: 9,
    popularity: 3970,
    codepoint: 61411,
    categories: ["search"],
    tags: [
      "appliance",
      "blender",
      "cook",
      "cooking",
      "electric",
      "juicer",
      "kitchen",
      "machine",
      "vitamix",
    ],
  },
  blind: {
    name: "blind",
    version: 1,
    popularity: 599,
    codepoint: 63702,
    categories: ["social"],
    tags: [
      "accessibility",
      "accessible",
      "assist",
      "blind",
      "body",
      "cane",
      "disability",
      "handicap",
      "help",
      "human",
      "mobility",
      "person",
      "walk",
      "walker",
    ],
  },
  blinds: {
    name: "blinds",
    version: 3,
    popularity: 604,
    codepoint: 57990,
    categories: ["home"],
    tags: [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  blinds_closed: {
    name: "blinds_closed",
    version: 1,
    popularity: 565,
    codepoint: 60447,
    categories: ["home"],
    tags: [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  block: {
    name: "block",
    version: 17,
    popularity: 38797,
    codepoint: 57675,
    categories: ["content"],
    tags: [
      "avoid",
      "block",
      "cancel",
      "close",
      "entry",
      "exit",
      "no",
      "prohibited",
      "quit",
      "remove",
      "stop",
    ],
  },
  blood_pressure: {
    name: "blood_pressure",
    version: 229,
    popularity: 6,
    codepoint: 57495,
    categories: ["Social"],
    tags: ["doctor", "health", "measure", "medical", "medicine", "monitor"],
  },
  bloodtype: {
    name: "bloodtype",
    version: 10,
    popularity: 5985,
    codepoint: 61412,
    categories: ["device"],
    tags: [
      "blood",
      "bloodtype",
      "donate",
      "droplet",
      "emergency",
      "hospital",
      "medicine",
      "negative",
      "positive",
      "type",
      "water",
    ],
  },
  bluetooth: {
    name: "bluetooth",
    version: 11,
    popularity: 11684,
    codepoint: 57767,
    categories: ["device"],
    tags: [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless",
    ],
  },
  bluetooth_connected: {
    name: "bluetooth_connected",
    version: 12,
    popularity: 3467,
    codepoint: 57768,
    categories: ["device"],
    tags: [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless",
    ],
  },
  bluetooth_disabled: {
    name: "bluetooth_disabled",
    version: 17,
    popularity: 2742,
    codepoint: 57769,
    categories: ["device"],
    tags: [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "paring",
      "slash",
      "streaming",
      "symbol",
      "wireless",
    ],
  },
  bluetooth_drive: {
    name: "bluetooth_drive",
    version: 9,
    popularity: 1203,
    codepoint: 61413,
    categories: ["device"],
    tags: [
      "automobile",
      "bluetooth",
      "car",
      "cars",
      "cast",
      "connect",
      "connection",
      "device",
      "drive",
      "maps",
      "paring",
      "streaming",
      "symbol",
      "transportation",
      "travel",
      "vehicle",
      "wireless",
    ],
  },
  bluetooth_searching: {
    name: "bluetooth_searching",
    version: 13,
    popularity: 2893,
    codepoint: 57770,
    categories: ["device"],
    tags: [
      "bluetooth",
      "connection",
      "device",
      "paring",
      "search",
      "searching",
      "symbol",
    ],
  },
  blur_circular: {
    name: "blur_circular",
    version: 12,
    popularity: 2367,
    codepoint: 58274,
    categories: ["image"],
    tags: [
      "blur",
      "circle",
      "circular",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
    ],
  },
  blur_linear: {
    name: "blur_linear",
    version: 12,
    popularity: 1681,
    codepoint: 58275,
    categories: ["image"],
    tags: [
      "blur",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "linear",
    ],
  },
  blur_medium: {
    name: "blur_medium",
    version: 229,
    popularity: 233,
    codepoint: 59468,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "medium",
      "motion",
      "movement",
    ],
  },
  blur_off: {
    name: "blur_off",
    version: 12,
    popularity: 1106,
    codepoint: 58276,
    categories: ["image"],
    tags: [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "off",
      "on",
      "slash",
    ],
  },
  blur_on: {
    name: "blur_on",
    version: 12,
    popularity: 6746,
    codepoint: 58277,
    categories: ["image"],
    tags: [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "filter",
      "off",
      "on",
      "slash",
    ],
  },
  blur_short: {
    name: "blur_short",
    version: 229,
    popularity: 196,
    codepoint: 59599,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "motion",
      "movement",
      "short",
    ],
  },
  body_fat: {
    name: "body_fat",
    version: 229,
    popularity: 4,
    codepoint: 57496,
    categories: ["Social"],
    tags: ["caliper", "fitness", "health", "measure", "meter"],
  },
  body_system: {
    name: "body_system",
    version: 229,
    popularity: 10,
    codepoint: 57497,
    categories: ["Social"],
    tags: ["body", "fitness", "health", "human", "people", "person"],
  },
  bolt: {
    name: "bolt",
    version: 10,
    popularity: 47171,
    codepoint: 59915,
    categories: ["content"],
    tags: [
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "power",
      "thunderbolt",
    ],
  },
  bomb: {
    name: "bomb",
    version: 229,
    popularity: 11,
    codepoint: 62824,
    categories: ["Social"],
    tags: [
      "atomic",
      "blast",
      "blasts",
      "boom",
      "burst",
      "bursts",
      "disaster",
      "explode",
      "explosion",
      "explosive",
      "loud",
      "mine",
    ],
  },
  book: {
    name: "book",
    version: 16,
    popularity: 28929,
    codepoint: 59493,
    categories: ["action"],
    tags: [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  book_2: {
    name: "book_2",
    version: 229,
    popularity: 11,
    codepoint: 62782,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  book_3: {
    name: "book_3",
    version: 229,
    popularity: 0,
    codepoint: 62781,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  book_4: {
    name: "book_4",
    version: 229,
    popularity: 0,
    codepoint: 62780,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  book_5: {
    name: "book_5",
    version: 229,
    popularity: 3,
    codepoint: 62779,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  book_online: {
    name: "book_online",
    version: 10,
    popularity: 15318,
    codepoint: 61975,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "admission",
      "appointment",
      "book",
      "cell",
      "device",
      "event",
      "hardware",
      "iOS",
      "mobile",
      "online",
      "pass",
      "phone",
      "reservation",
      "tablet",
      "ticket",
    ],
  },
  bookmark: {
    name: "bookmark",
    version: 17,
    popularity: 46224,
    codepoint: 59494,
    categories: ["action"],
    tags: [
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
    ],
  },
  bookmark_add: {
    name: "bookmark_add",
    version: 3,
    popularity: 11363,
    codepoint: 58776,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "bookmark",
      "favorite",
      "plus",
      "remember",
      "ribbon",
      "save",
      "symbol",
    ],
  },
  bookmark_added: {
    name: "bookmark_added",
    version: 3,
    popularity: 9060,
    codepoint: 58777,
    categories: ["action"],
    tags: [
      "added",
      "approve",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "mark",
      "ok",
      "remember",
      "save",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  bookmark_manager: {
    name: "bookmark_manager",
    version: 229,
    popularity: 21,
    codepoint: 63409,
    categories: ["Common actions"],
    tags: [
      "compose",
      "create",
      "data",
      "doc",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "folder",
      "input",
      "new",
      "pen",
      "pencil",
      "plus",
      "sheet",
      "slide",
      "storage",
      "symbol",
      "write",
      "writing",
    ],
  },
  bookmark_remove: {
    name: "bookmark_remove",
    version: 3,
    popularity: 4286,
    codepoint: 58778,
    categories: ["action"],
    tags: [
      "bookmark",
      "delete",
      "favorite",
      "minus",
      "remember",
      "remove",
      "ribbon",
      "save",
      "subtract",
    ],
  },
  bookmarks: {
    name: "bookmarks",
    version: 12,
    popularity: 17718,
    codepoint: 59787,
    categories: ["action"],
    tags: [
      "bookmark",
      "bookmarks",
      "favorite",
      "label",
      "layers",
      "library",
      "multiple",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag",
    ],
  },
  border_all: {
    name: "border_all",
    version: 12,
    popularity: 2540,
    codepoint: 57896,
    categories: ["editor"],
    tags: [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_bottom: {
    name: "border_bottom",
    version: 12,
    popularity: 825,
    codepoint: 57897,
    categories: ["editor"],
    tags: [
      "border",
      "bottom",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_clear: {
    name: "border_clear",
    version: 12,
    popularity: 1579,
    codepoint: 57898,
    categories: ["editor"],
    tags: [
      "border",
      "clear",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_color: {
    name: "border_color",
    version: 15,
    popularity: 19391,
    codepoint: 57899,
    categories: ["editor"],
    tags: [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "pen",
      "pencil",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_horizontal: {
    name: "border_horizontal",
    version: 12,
    popularity: 785,
    codepoint: 57900,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "horizontal",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_inner: {
    name: "border_inner",
    version: 12,
    popularity: 864,
    codepoint: 57901,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "inner",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_left: {
    name: "border_left",
    version: 12,
    popularity: 850,
    codepoint: 57902,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "left",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_outer: {
    name: "border_outer",
    version: 12,
    popularity: 1301,
    codepoint: 57903,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "outer",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_right: {
    name: "border_right",
    version: 12,
    popularity: 738,
    codepoint: 57904,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "right",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing",
    ],
  },
  border_style: {
    name: "border_style",
    version: 12,
    popularity: 1413,
    codepoint: 57905,
    categories: ["editor"],
    tags: [
      "border",
      "color",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  border_top: {
    name: "border_top",
    version: 12,
    popularity: 756,
    codepoint: 57906,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "top",
      "type",
      "writing",
    ],
  },
  border_vertical: {
    name: "border_vertical",
    version: 12,
    popularity: 800,
    codepoint: 57907,
    categories: ["editor"],
    tags: [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "vertical",
      "writing",
    ],
  },
  bottom_app_bar: {
    name: "bottom_app_bar",
    version: 229,
    popularity: 5,
    codepoint: 59184,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bar",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  bottom_drawer: {
    name: "bottom_drawer",
    version: 229,
    popularity: 18,
    codepoint: 59181,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "drawer",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  bottom_navigation: {
    name: "bottom_navigation",
    version: 229,
    popularity: 22,
    codepoint: 59788,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "navigation",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  bottom_panel_close: {
    name: "bottom_panel_close",
    version: 229,
    popularity: 5,
    codepoint: 63274,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "down",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  bottom_panel_open: {
    name: "bottom_panel_open",
    version: 229,
    popularity: 26,
    codepoint: 63273,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "direction",
      "expand",
      "layout",
      "panels",
      "spaces",
      "up",
      "window",
      "workflow",
    ],
  },
  bottom_right_click: {
    name: "bottom_right_click",
    version: 229,
    popularity: 11,
    codepoint: 63108,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "dot",
      "east",
      "navigation",
      "south",
    ],
  },
  bottom_sheets: {
    name: "bottom_sheets",
    version: 229,
    popularity: 17,
    codepoint: 59789,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "sheets",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  box: {
    name: "box",
    version: 229,
    popularity: 27,
    codepoint: 62884,
    categories: ["Business&Payments"],
    tags: [
      "closed box",
      "package",
      "parcel",
      "post",
      "postal",
      "shipment",
      "shipping",
    ],
  },
  box_add: {
    name: "box_add",
    version: 229,
    popularity: 13,
    codepoint: 62885,
    categories: ["Business&Payments"],
    tags: [
      "+",
      "add",
      "closed box",
      "new",
      "package",
      "parcel",
      "plus",
      "post",
      "postal",
      "shipment",
      "shipping",
    ],
  },
  box_edit: {
    name: "box_edit",
    version: 229,
    popularity: 3,
    codepoint: 62886,
    categories: ["Business&Payments"],
    tags: [
      "compose",
      "create",
      "draft",
      "editing",
      "input",
      "modify",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  boy: {
    name: "boy",
    version: 1,
    popularity: 3134,
    codepoint: 60263,
    categories: ["social"],
    tags: [
      "body",
      "boy",
      "gender",
      "human",
      "male",
      "man",
      "people",
      "person",
      "social",
      "symbol",
    ],
  },
  brand_awareness: {
    name: "brand_awareness",
    version: 229,
    popularity: 240,
    codepoint: 59790,
    categories: ["Audio&Video"],
    tags: [
      "alert",
      "announcement",
      "audio",
      "awareness",
      "brand",
      "control",
      "music",
      "news",
      "sound",
      "speaker",
      "tv",
      "volume",
    ],
  },
  branding_watermark: {
    name: "branding_watermark",
    version: 13,
    popularity: 6548,
    codepoint: 57451,
    categories: ["av"],
    tags: [
      "branding",
      "components",
      "copyright",
      "design",
      "emblem",
      "format",
      "identity",
      "interface",
      "layout",
      "logo",
      "screen",
      "site",
      "stamp",
      "ui",
      "ux",
      "watermark",
      "web",
      "website",
      "window",
    ],
  },
  breakfast_dining: {
    name: "breakfast_dining",
    version: 11,
    popularity: 2975,
    codepoint: 59988,
    categories: ["maps"],
    tags: ["bakery", "bread", "breakfast", "butter", "dining", "food", "toast"],
  },
  breaking_news: {
    name: "breaking_news",
    version: 229,
    popularity: 67,
    codepoint: 59912,
    categories: ["Text Formatting"],
    tags: [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "headline",
      "important",
      "mark",
      "news",
      "newspaper",
      "notification",
      "symbol",
      "warning",
    ],
  },
  breaking_news_alt_1: {
    name: "breaking_news_alt_1",
    version: 229,
    popularity: 935,
    codepoint: 61626,
    categories: ["Text Formatting"],
    tags: [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "headline",
      "important",
      "mark",
      "news",
      "newspaper",
      "notification",
      "symbol",
      "warning",
    ],
  },
  breastfeeding: {
    name: "breastfeeding",
    version: 229,
    popularity: 90,
    codepoint: 63574,
    categories: ["Social"],
    tags: [
      "babies",
      "baby",
      "body",
      "breast",
      "breastfed",
      "breastfeed",
      "care",
      "child",
      "children",
      "cuddle",
      "feed",
      "feeding",
      "hug",
      "human",
      "infant",
      "kids",
      "lactation",
      "mama",
      "mom",
      "mommy",
      "newborn",
      "parent",
      "people",
      "person",
      "toddler",
      "young",
    ],
  },
  brightness_1: {
    name: "brightness_1",
    version: 19,
    popularity: 5055,
    codepoint: 58278,
    categories: ["image"],
    tags: [
      "1",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
    ],
  },
  brightness_2: {
    name: "brightness_2",
    version: 12,
    popularity: 2209,
    codepoint: 58279,
    categories: ["image"],
    tags: [
      "2",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
    ],
  },
  brightness_3: {
    name: "brightness_3",
    version: 16,
    popularity: 2406,
    codepoint: 58280,
    categories: ["image"],
    tags: [
      "3",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
    ],
  },
  brightness_4: {
    name: "brightness_4",
    version: 16,
    popularity: 5912,
    codepoint: 58281,
    categories: ["image"],
    tags: [
      "4",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun",
    ],
  },
  brightness_5: {
    name: "brightness_5",
    version: 12,
    popularity: 5187,
    codepoint: 58282,
    categories: ["image"],
    tags: [
      "5",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun",
    ],
  },
  brightness_6: {
    name: "brightness_6",
    version: 12,
    popularity: 5272,
    codepoint: 58283,
    categories: ["image"],
    tags: [
      "6",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun",
    ],
  },
  brightness_7: {
    name: "brightness_7",
    version: 13,
    popularity: 3736,
    codepoint: 58284,
    categories: ["image"],
    tags: [
      "7",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun",
    ],
  },
  brightness_alert: {
    name: "brightness_alert",
    version: 229,
    popularity: 21,
    codepoint: 62927,
    categories: ["Android"],
    tags: [
      "!",
      "alert",
      "attention",
      "brightness",
      "caution",
      "control",
      "danger",
      "display",
      "error",
      "exclamation",
      "feedback",
      "important",
      "level",
      "mark",
      "mobile",
      "monitor",
      "notification",
      "phone",
      "problem",
      "report",
      "screen",
      "sun",
      "warning",
    ],
  },
  brightness_auto: {
    name: "brightness_auto",
    version: 12,
    popularity: 1794,
    codepoint: 57771,
    categories: ["device"],
    tags: [
      "A",
      "auto",
      "brightness",
      "control",
      "display",
      "level",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "sun",
    ],
  },
  brightness_empty: {
    name: "brightness_empty",
    version: 229,
    popularity: 14,
    codepoint: 63464,
    categories: ["Android"],
    tags: ["0", "bright", "level", "star"],
  },
  brightness_high: {
    name: "brightness_high",
    version: 13,
    popularity: 3240,
    codepoint: 57772,
    categories: ["device"],
    tags: [
      "auto",
      "brightness",
      "control",
      "high",
      "mobile",
      "monitor",
      "phone",
      "sun",
    ],
  },
  brightness_low: {
    name: "brightness_low",
    version: 12,
    popularity: 2907,
    codepoint: 57773,
    categories: ["device"],
    tags: [
      "auto",
      "brightness",
      "control",
      "low",
      "mobile",
      "monitor",
      "phone",
      "sun",
    ],
  },
  brightness_medium: {
    name: "brightness_medium",
    version: 12,
    popularity: 2954,
    codepoint: 57774,
    categories: ["device"],
    tags: [
      "auto",
      "brightness",
      "control",
      "medium",
      "mobile",
      "monitor",
      "phone",
      "sun",
    ],
  },
  bring_your_own_ip: {
    name: "bring_your_own_ip",
    version: 229,
    popularity: 864,
    codepoint: 57366,
    categories: ["Social"],
    tags: [
      "bring",
      "globe",
      "internet",
      "ip",
      "own",
      "protocol",
      "world",
      "your",
    ],
  },
  broadcast_on_home: {
    name: "broadcast_on_home",
    version: 1,
    popularity: 531,
    codepoint: 63736,
    categories: ["home"],
    tags: [],
  },
  broadcast_on_personal: {
    name: "broadcast_on_personal",
    version: 1,
    popularity: 632,
    codepoint: 63737,
    categories: ["home"],
    tags: [],
  },
  broken_image: {
    name: "broken_image",
    version: 11,
    popularity: 6160,
    codepoint: 58285,
    categories: ["image"],
    tags: [
      "broken",
      "corrupt",
      "error",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "torn",
    ],
  },
  browse: {
    name: "browse",
    version: 229,
    popularity: 86,
    codepoint: 60179,
    categories: ["Common actions"],
    tags: ["browse", "grid", "layout", "pages", "web"],
  },
  browse_activity: {
    name: "browse_activity",
    version: 229,
    popularity: 1243,
    codepoint: 63653,
    categories: ["UI actions"],
    tags: [
      "activity",
      "browse",
      "desktop",
      "device",
      "display",
      "hardware",
      "history",
      "monitor",
      "screen",
      "star",
    ],
  },
  browse_gallery: {
    name: "browse_gallery",
    version: 1,
    popularity: 1474,
    codepoint: 60369,
    categories: ["action"],
    tags: ["clock", "collection", "gallery", "library", "stack", "watch"],
  },
  browser_updated: {
    name: "browser_updated",
    version: 2,
    popularity: 2812,
    codepoint: 59343,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "browser",
      "chrome",
      "desktop",
      "device",
      "display",
      "download",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "updated",
      "web",
      "window",
    ],
  },
  brunch_dining: {
    name: "brunch_dining",
    version: 11,
    popularity: 2654,
    codepoint: 60019,
    categories: ["maps"],
    tags: [
      "breakfast",
      "brunch",
      "champagne",
      "dining",
      "drink",
      "food",
      "lunch",
      "meal",
    ],
  },
  brush: {
    name: "brush",
    version: 13,
    popularity: 19610,
    codepoint: 58286,
    categories: ["image"],
    tags: [
      "art",
      "brush",
      "design",
      "draw",
      "edit",
      "editing",
      "paint",
      "painting",
      "tool",
    ],
  },
  bubble: {
    name: "bubble",
    version: 229,
    popularity: 65,
    codepoint: 61315,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "bubble",
      "circle",
      "diagonal",
      "direction",
      "right",
      "up",
    ],
  },
  bubble_chart: {
    name: "bubble_chart",
    version: 12,
    popularity: 7449,
    codepoint: 59101,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "bubble",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  bubbles: {
    name: "bubbles",
    version: 229,
    popularity: 7,
    codepoint: 63054,
    categories: ["UI actions"],
    tags: [
      "app",
      "arrow",
      "arrows",
      "close",
      "collapse",
      "direction",
      "down",
      "interface",
      "right",
      "ui",
      "ux",
      "window",
    ],
  },
  bug_report: {
    name: "bug_report",
    version: 12,
    popularity: 28726,
    codepoint: 59496,
    categories: ["action"],
    tags: [
      "animal",
      "bug",
      "fix",
      "insect",
      "issue",
      "problem",
      "report",
      "testing",
      "virus",
      "warning",
    ],
  },
  build: {
    name: "build",
    version: 12,
    popularity: 64779,
    codepoint: 59497,
    categories: ["action"],
    tags: [
      "adjust",
      "build",
      "fix",
      "home",
      "nest",
      "repair",
      "tool",
      "tools",
      "wrench",
    ],
  },
  build_circle: {
    name: "build_circle",
    version: 14,
    popularity: 16800,
    codepoint: 61256,
    categories: ["action"],
    tags: ["adjust", "build", "circle", "fix", "repair", "tool", "wrench"],
  },
  bungalow: {
    name: "bungalow",
    version: 4,
    popularity: 2002,
    codepoint: 58769,
    categories: ["places"],
    tags: [
      "architecture",
      "bungalow",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
    ],
  },
  burst_mode: {
    name: "burst_mode",
    version: 12,
    popularity: 1843,
    codepoint: 58428,
    categories: ["image"],
    tags: [
      "burst",
      "image",
      "landscape",
      "mode",
      "mountain",
      "mountains",
      "multiple",
      "photo",
      "photography",
      "picture",
    ],
  },
  bus_alert: {
    name: "bus_alert",
    version: 15,
    popularity: 2306,
    codepoint: 59791,
    categories: ["maps"],
    tags: [
      "!",
      "alert",
      "attention",
      "automobile",
      "bus",
      "car",
      "cars",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "symbol",
      "transportation",
      "vehicle",
      "warning",
    ],
  },
  business_center: {
    name: "business_center",
    version: 15,
    popularity: 22563,
    codepoint: 60223,
    categories: ["places"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "center",
      "places",
      "purse",
      "suitcase",
      "work",
    ],
  },
  business_chip: {
    name: "business_chip",
    version: 229,
    popularity: 17,
    codepoint: 63564,
    categories: ["Text Formatting"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "chip",
      "purse",
      "suitcase",
      "work",
    ],
  },
  business_messages: {
    name: "business_messages",
    version: 229,
    popularity: 41,
    codepoint: 61316,
    categories: ["Communication"],
    tags: [
      "arrows",
      "bubble",
      "business",
      "chat",
      "messages",
      "speech",
      "talk",
      "text",
    ],
  },
  buttons_alt: {
    name: "buttons_alt",
    version: 229,
    popularity: 17,
    codepoint: 59183,
    categories: ["UI actions"],
    tags: [
      "+",
      "UI",
      "action",
      "button",
      "buttons",
      "circle",
      "component",
      "floating",
      "interface",
      "plus",
    ],
  },
  cabin: {
    name: "cabin",
    version: 4,
    popularity: 3227,
    codepoint: 58761,
    categories: ["places"],
    tags: [
      "architecture",
      "cabin",
      "camping",
      "cottage",
      "estate",
      "home",
      "house",
      "log",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "wood",
    ],
  },
  cable: {
    name: "cable",
    version: 10,
    popularity: 7758,
    codepoint: 61414,
    categories: ["device"],
    tags: [
      "cable",
      "connect",
      "connection",
      "device",
      "electronics",
      "usb",
      "wire",
    ],
  },
  cached: {
    name: "cached",
    version: 13,
    popularity: 28647,
    codepoint: 59498,
    categories: ["action"],
    tags: [
      "around",
      "arrows",
      "cache",
      "cached",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate",
    ],
  },
  cake: {
    name: "cake",
    version: 13,
    popularity: 22409,
    codepoint: 59369,
    categories: ["social"],
    tags: [
      "add",
      "baked",
      "birthday",
      "cake",
      "candles",
      "celebration",
      "dessert",
      "food",
      "frosting",
      "new",
      "party",
      "pastries",
      "pastry",
      "plus",
      "social",
      "sweet",
      "symbol",
    ],
  },
  cake_add: {
    name: "cake_add",
    version: 229,
    popularity: 89,
    codepoint: 63579,
    categories: ["Activities"],
    tags: [],
  },
  calculate: {
    name: "calculate",
    version: 11,
    popularity: 37110,
    codepoint: 59999,
    categories: ["content"],
    tags: ["+", "-", "=", "calculate", "count", "finance calculator", "math"],
  },
  calendar_add_on: {
    name: "calendar_add_on",
    version: 229,
    popularity: 2067,
    codepoint: 61317,
    categories: ["Communication"],
    tags: [
      "+",
      "add",
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "new symbol",
      "plus",
      "schedule",
      "symbol",
      "today",
    ],
  },
  calendar_apps_script: {
    name: "calendar_apps_script",
    version: 229,
    popularity: 313,
    codepoint: 61627,
    categories: ["Communication"],
    tags: ["calendar", "date", "day", "event", "month", "schedule", "today"],
  },
  calendar_clock: {
    name: "calendar_clock",
    version: 229,
    popularity: 18,
    codepoint: 62784,
    categories: ["Common actions"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "calendar",
      "clock",
      "date",
      "day",
      "disabled",
      "duration",
      "enabled",
      "event",
      "later",
      "limit",
      "month",
      "notification",
      "schedule",
      "time",
      "timer",
      "today",
      "watch",
    ],
  },
  calendar_month: {
    name: "calendar_month",
    version: 1,
    popularity: 61597,
    codepoint: 60364,
    categories: ["action"],
    tags: ["calendar", "date", "day", "event", "month", "schedule", "today"],
  },
  calendar_today: {
    name: "calendar_today",
    version: 12,
    popularity: 119296,
    codepoint: 59701,
    categories: ["action"],
    tags: ["calendar", "date", "day", "event", "month", "schedule", "today"],
  },
  calendar_view_day: {
    name: "calendar_view_day",
    version: 15,
    popularity: 5863,
    codepoint: 59702,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week",
    ],
  },
  calendar_view_month: {
    name: "calendar_view_month",
    version: 10,
    popularity: 11840,
    codepoint: 61415,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
    ],
  },
  calendar_view_week: {
    name: "calendar_view_week",
    version: 10,
    popularity: 6777,
    codepoint: 61416,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week",
    ],
  },
  call: {
    name: "call",
    version: 17,
    popularity: 173110,
    codepoint: 57520,
    categories: ["communication"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  call_end: {
    name: "call_end",
    version: 16,
    popularity: 10955,
    codepoint: 57521,
    categories: ["communication"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "end",
      "hardware",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  call_log: {
    name: "call_log",
    version: 229,
    popularity: 9,
    codepoint: 57486,
    categories: ["Communication"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "list",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  call_made: {
    name: "call_made",
    version: 13,
    popularity: 7018,
    codepoint: 57522,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "made", "mobile"],
  },
  call_merge: {
    name: "call_merge",
    version: 13,
    popularity: 2724,
    codepoint: 57523,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "merge", "mobile"],
  },
  call_missed: {
    name: "call_missed",
    version: 13,
    popularity: 1988,
    codepoint: 57524,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "missed", "mobile"],
  },
  call_missed_outgoing: {
    name: "call_missed_outgoing",
    version: 15,
    popularity: 2251,
    codepoint: 57572,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "missed", "mobile", "outgoing"],
  },
  call_quality: {
    name: "call_quality",
    version: 229,
    popularity: 13,
    codepoint: 63058,
    categories: ["Communication"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "call",
      "cell",
      "contact",
      "details",
      "device",
      "hardware",
      "help",
      "i",
      "info",
      "information",
      "mobile",
      "phone",
      "service",
      "support",
      "telephone",
    ],
  },
  call_received: {
    name: "call_received",
    version: 13,
    popularity: 4409,
    codepoint: 57525,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "mobile", "received"],
  },
  call_split: {
    name: "call_split",
    version: 13,
    popularity: 6350,
    codepoint: 57526,
    categories: ["communication"],
    tags: ["arrow", "call", "device", "mobile", "split"],
  },
  call_to_action: {
    name: "call_to_action",
    version: 11,
    popularity: 3168,
    codepoint: 57452,
    categories: ["av"],
    tags: [
      "action",
      "alert",
      "bar",
      "call",
      "components",
      "cta",
      "design",
      "info",
      "information",
      "interface",
      "layout",
      "message",
      "notification",
      "screen",
      "site",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  camera: {
    name: "camera",
    version: 12,
    popularity: 12691,
    codepoint: 58287,
    categories: ["image"],
    tags: [
      "aperture",
      "camera",
      "lens",
      "photo",
      "photography",
      "picture",
      "shutter",
    ],
  },
  camera_front: {
    name: "camera_front",
    version: 12,
    popularity: 1969,
    codepoint: 58289,
    categories: ["image"],
    tags: [
      "body",
      "camera",
      "front",
      "human",
      "lens",
      "mobile",
      "person",
      "phone",
      "photography",
      "portrait",
      "selfie",
    ],
  },
  camera_indoor: {
    name: "camera_indoor",
    version: 9,
    popularity: 3523,
    codepoint: 61417,
    categories: ["search"],
    tags: [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "indoor",
      "inside",
      "motion",
      "nest",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography",
    ],
  },
  camera_outdoor: {
    name: "camera_outdoor",
    version: 9,
    popularity: 3216,
    codepoint: 61418,
    categories: ["search"],
    tags: [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "motion",
      "nest",
      "outdoor",
      "outside",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography",
    ],
  },
  camera_rear: {
    name: "camera_rear",
    version: 13,
    popularity: 985,
    codepoint: 58290,
    categories: ["image"],
    tags: [
      "camera",
      "front",
      "lens",
      "mobile",
      "phone",
      "photo",
      "photography",
      "picture",
      "portrait",
      "rear",
      "selfie",
    ],
  },
  camera_roll: {
    name: "camera_roll",
    version: 12,
    popularity: 1773,
    codepoint: 58291,
    categories: ["image"],
    tags: [
      "camera",
      "film",
      "image",
      "library",
      "photo",
      "photography",
      "roll",
    ],
  },
  camera_video: {
    name: "camera_video",
    version: 229,
    popularity: 26,
    codepoint: 63398,
    categories: ["Hardware"],
    tags: [
      "cam",
      "desk",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography",
      "webcam",
    ],
  },
  cameraswitch: {
    name: "cameraswitch",
    version: 10,
    popularity: 7555,
    codepoint: 61419,
    categories: ["device"],
    tags: [
      "arrows",
      "camera",
      "cameraswitch",
      "flip",
      "rotate",
      "swap",
      "switch",
      "view",
    ],
  },
  campaign: {
    name: "campaign",
    version: 12,
    popularity: 51932,
    codepoint: 61257,
    categories: ["navigation"],
    tags: [
      "alert",
      "announcement",
      "campaign",
      "loud",
      "megaphone",
      "microphone",
      "notification",
      "speaker",
    ],
  },
  camping: {
    name: "camping",
    version: 229,
    popularity: 738,
    codepoint: 63650,
    categories: ["Activities"],
    tags: ["activity", "camp", "camping", "shelter", "tent", "travel"],
  },
  cancel: {
    name: "cancel",
    version: 16,
    popularity: 158452,
    codepoint: 58825,
    categories: ["navigation"],
    tags: ["cancel", "circle", "clear", "close", "exit", "remove", "stop", "x"],
  },
  cancel_presentation: {
    name: "cancel_presentation",
    version: 14,
    popularity: 7351,
    codepoint: 57577,
    categories: ["communication"],
    tags: [
      "cancel",
      "clear",
      "close",
      "device",
      "exit",
      "no",
      "present",
      "presentation",
      "quit",
      "remove",
      "screen",
      "slide",
      "stop",
      "website",
      "window",
      "x",
    ],
  },
  cancel_schedule_send: {
    name: "cancel_schedule_send",
    version: 12,
    popularity: 4848,
    codepoint: 59961,
    categories: ["action"],
    tags: [
      "cancel",
      "clear",
      "email",
      "mail",
      "no",
      "quit",
      "remove",
      "schedule",
      "send",
      "share",
      "stop",
      "x",
    ],
  },
  candle: {
    name: "candle",
    version: 229,
    popularity: 2,
    codepoint: 62856,
    categories: ["Social"],
    tags: [
      "candelabra",
      "candlestick",
      "flame",
      "ideology",
      "light",
      "lighting",
      "religion",
      "spiritual",
      "torch",
      "worship",
    ],
  },
  candlestick_chart: {
    name: "candlestick_chart",
    version: 2,
    popularity: 2085,
    codepoint: 60116,
    categories: ["editor"],
    tags: [
      "analytics",
      "candlestick",
      "chart",
      "data",
      "diagram",
      "finance",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  captive_portal: {
    name: "captive_portal",
    version: 229,
    popularity: 54,
    codepoint: 63272,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "direction",
      "globe",
      "input",
      "internet",
      "language",
      "left",
      "link",
      "northwest",
      "planet",
      "upload",
      "website",
      "world",
      "www",
    ],
  },
  capture: {
    name: "capture",
    version: 229,
    popularity: 28,
    codepoint: 63271,
    categories: ["UI actions"],
    tags: [
      "frame",
      "picture in picture",
      "position",
      "rectangle",
      "screengrab",
      "screenshot",
      "window",
    ],
  },
  car_crash: {
    name: "car_crash",
    version: 1,
    popularity: 1826,
    codepoint: 60402,
    categories: ["maps"],
    tags: [
      "accident",
      "automobile",
      "car",
      "cars",
      "collision",
      "crash",
      "direction",
      "maps",
      "public",
      "transportation",
      "vehicle",
    ],
  },
  car_rental: {
    name: "car_rental",
    version: 10,
    popularity: 4066,
    codepoint: 59989,
    categories: ["maps"],
    tags: [
      "access",
      "automobile",
      "car",
      "cars",
      "entry",
      "key",
      "lock",
      "maps",
      "password",
      "rental",
      "transportation",
      "unlock",
      "vehicle",
    ],
  },
  car_repair: {
    name: "car_repair",
    version: 10,
    popularity: 3340,
    codepoint: 59990,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "maps",
      "repair",
      "transportation",
      "vehicle",
    ],
  },
  card_membership: {
    name: "card_membership",
    version: 14,
    popularity: 15155,
    codepoint: 59639,
    categories: ["action"],
    tags: [
      "bill",
      "bookmark",
      "card",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "loyalty",
      "membership",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "subscription",
    ],
  },
  card_travel: {
    name: "card_travel",
    version: 13,
    popularity: 6103,
    codepoint: 59640,
    categories: ["action"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "membership",
      "miles",
      "money",
      "online",
      "pay",
      "payment",
      "travel",
      "trip",
    ],
  },
  cardiology: {
    name: "cardiology",
    version: 229,
    popularity: 25,
    codepoint: 57500,
    categories: ["Social"],
    tags: ["cardio", "health", "heart", "measure", "medical", "monitor"],
  },
  cards: {
    name: "cards",
    version: 229,
    popularity: 74,
    codepoint: 59793,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "cards",
      "components",
      "design",
      "grid",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  carpenter: {
    name: "carpenter",
    version: 6,
    popularity: 2867,
    codepoint: 61944,
    categories: ["places"],
    tags: [
      "building",
      "carpenter",
      "construction",
      "cutting",
      "handyman",
      "repair",
      "saw",
      "tool",
    ],
  },
  carry_on_bag: {
    name: "carry_on_bag",
    version: 229,
    popularity: 3,
    codepoint: 60168,
    categories: ["Travel"],
    tags: ["bag", "carry", "luggage", "on", "suitcase", "travel", "trip"],
  },
  carry_on_bag_checked: {
    name: "carry_on_bag_checked",
    version: 229,
    popularity: 4,
    codepoint: 60171,
    categories: ["Travel"],
    tags: [
      "approve",
      "bag",
      "carry",
      "check",
      "checked",
      "luggage",
      "ok",
      "on",
      "select",
      "suitcase",
      "tick",
      "travel",
      "yes",
    ],
  },
  carry_on_bag_inactive: {
    name: "carry_on_bag_inactive",
    version: 229,
    popularity: 0,
    codepoint: 60170,
    categories: ["Travel"],
    tags: [
      "bag",
      "carry",
      "disabled",
      "enabled",
      "inactive",
      "luggage",
      "off",
      "on",
      "slash",
      "suitcase",
      "travel",
    ],
  },
  carry_on_bag_question: {
    name: "carry_on_bag_question",
    version: 229,
    popularity: 9,
    codepoint: 60169,
    categories: ["Travel"],
    tags: [
      "?",
      "bag",
      "carry",
      "help",
      "info",
      "information",
      "luggage",
      "on",
      "question",
      "question mark",
      "suitcase",
      "support",
      "symbol",
      "travel",
    ],
  },
  cases: {
    name: "cases",
    version: 11,
    popularity: 6145,
    codepoint: 59794,
    categories: ["image"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "cases",
      "purse",
      "suitcase",
    ],
  },
  casino: {
    name: "casino",
    version: 13,
    popularity: 8242,
    codepoint: 60224,
    categories: ["places"],
    tags: [
      "casino",
      "dice",
      "dots",
      "entertainment",
      "gamble",
      "gambling",
      "game",
      "games",
      "luck",
      "places",
    ],
  },
  cast: {
    name: "cast",
    version: 18,
    popularity: 6903,
    codepoint: 58119,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless",
    ],
  },
  cast_connected: {
    name: "cast_connected",
    version: 13,
    popularity: 3107,
    codepoint: 58120,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless",
    ],
  },
  cast_for_education: {
    name: "cast_for_education",
    version: 14,
    popularity: 7607,
    codepoint: 61420,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "education",
      "for",
      "hardware",
      "iOS",
      "learning",
      "lessons teaching",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless",
    ],
  },
  cast_pause: {
    name: "cast_pause",
    version: 229,
    popularity: 6,
    codepoint: 62960,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "control",
      "controls",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "media",
      "monitor",
      "music",
      "pause",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "video",
      "web",
      "window",
      "wireless",
    ],
  },
  cast_warning: {
    name: "cast_warning",
    version: 229,
    popularity: 7,
    codepoint: 62959,
    categories: ["Hardware"],
    tags: [
      "!",
      "Android",
      "OS",
      "airplay",
      "alert",
      "attention",
      "cast",
      "caution",
      "chrome",
      "connect",
      "danger",
      "desktop",
      "device",
      "display",
      "error",
      "exclamation",
      "hardware",
      "high",
      "iOS",
      "important",
      "mac",
      "mark",
      "monitor",
      "notification",
      "screen",
      "screencast",
      "streaming",
      "symbol",
      "television",
      "tv",
      "warning",
      "web",
      "window",
      "wireless",
    ],
  },
  castle: {
    name: "castle",
    version: 2,
    popularity: 2126,
    codepoint: 60081,
    categories: ["maps"],
    tags: ["castle", "fort", "fortress", "mansion", "palace"],
  },
  category: {
    name: "category",
    version: 12,
    popularity: 53205,
    codepoint: 58740,
    categories: ["maps"],
    tags: [
      "categories",
      "category",
      "circle",
      "collection",
      "items",
      "product",
      "sort",
      "square",
      "triangle",
    ],
  },
  celebration: {
    name: "celebration",
    version: 11,
    popularity: 18788,
    codepoint: 60005,
    categories: ["maps"],
    tags: ["activity", "birthday", "celebration", "event", "fun", "party"],
  },
  cell_merge: {
    name: "cell_merge",
    version: 229,
    popularity: 20,
    codepoint: 63534,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "cell",
      "cells",
      "combine",
      "inward",
      "merge",
      "sheets",
      "spreadsheet",
    ],
  },
  cell_tower: {
    name: "cell_tower",
    version: 1,
    popularity: 3522,
    codepoint: 60346,
    categories: ["communication"],
    tags: [
      "broadcast",
      "casting",
      "cell",
      "network",
      "signal",
      "tower",
      "transmitting",
      "wireless",
    ],
  },
  cell_wifi: {
    name: "cell_wifi",
    version: 14,
    popularity: 2197,
    codepoint: 57580,
    categories: ["communication"],
    tags: [
      "cell",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  center_focus_strong: {
    name: "center_focus_strong",
    version: 12,
    popularity: 6860,
    codepoint: 58292,
    categories: ["image"],
    tags: [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "strong",
      "zoom",
    ],
  },
  center_focus_weak: {
    name: "center_focus_weak",
    version: 12,
    popularity: 4126,
    codepoint: 58293,
    categories: ["image"],
    tags: [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "weak",
      "zoom",
    ],
  },
  chair: {
    name: "chair",
    version: 10,
    popularity: 14452,
    codepoint: 61421,
    categories: ["search"],
    tags: [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa",
    ],
  },
  chair_alt: {
    name: "chair_alt",
    version: 9,
    popularity: 3320,
    codepoint: 61422,
    categories: ["search"],
    tags: [
      "cahir",
      "furniture",
      "home",
      "house",
      "kitchen",
      "lounging",
      "seating",
      "table",
    ],
  },
  chalet: {
    name: "chalet",
    version: 4,
    popularity: 1808,
    codepoint: 58757,
    categories: ["places"],
    tags: [
      "architecture",
      "chalet",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
    ],
  },
  change_circle: {
    name: "change_circle",
    version: 8,
    popularity: 19002,
    codepoint: 58087,
    categories: ["content"],
    tags: [
      "around",
      "arrows",
      "change",
      "circle",
      "direction",
      "navigation",
      "rotate",
    ],
  },
  change_history: {
    name: "change_history",
    version: 11,
    popularity: 14002,
    codepoint: 59499,
    categories: ["action"],
    tags: ["change", "history", "shape", "triangle"],
  },
  charger: {
    name: "charger",
    version: 229,
    popularity: 933,
    codepoint: 58030,
    categories: ["Android"],
    tags: ["adapter", "batteries", "charger", "device", "nest", "power"],
  },
  charging_station: {
    name: "charging_station",
    version: 8,
    popularity: 2856,
    codepoint: 61853,
    categories: ["places"],
    tags: [
      "Android",
      "OS",
      "battery",
      "bolt",
      "cell",
      "charging",
      "device",
      "electric",
      "energy",
      "hardware",
      "iOS",
      "instant",
      "lightning",
      "mobile",
      "phone",
      "station",
      "tablet",
      "thunderbolt",
    ],
  },
  chart_data: {
    name: "chart_data",
    version: 229,
    popularity: 74,
    codepoint: 58483,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "graph",
      "health",
      "infographic",
      "line",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "trend",
      "up",
    ],
  },
  chat: {
    name: "chat",
    version: 19,
    popularity: 68491,
    codepoint: 57527,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech",
    ],
  },
  chat_add_on: {
    name: "chat_add_on",
    version: 229,
    popularity: 493,
    codepoint: 61683,
    categories: ["Communication"],
    tags: [],
  },
  chat_apps_script: {
    name: "chat_apps_script",
    version: 229,
    popularity: 261,
    codepoint: 61629,
    categories: ["Communication"],
    tags: [],
  },
  chat_bubble: {
    name: "chat_bubble",
    version: 15,
    popularity: 30744,
    codepoint: 57546,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech",
    ],
  },
  chat_error: {
    name: "chat_error",
    version: 229,
    popularity: 27,
    codepoint: 63404,
    categories: ["Communication"],
    tags: [
      "alert",
      "attention",
      "bubble",
      "cancel",
      "caution",
      "chat",
      "clear",
      "close",
      "comment",
      "communicate",
      "danger",
      "error",
      "exit",
      "feedback",
      "important",
      "mark",
      "message",
      "no",
      "notification",
      "problem",
      "remove",
      "report",
      "speech",
      "stop",
      "symbol",
      "warning",
      "x",
    ],
  },
  chat_info: {
    name: "chat_info",
    version: 229,
    popularity: 7,
    codepoint: 62763,
    categories: ["Communication"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "details",
      "feedback",
      "help",
      "i",
      "info",
      "information",
      "message",
      "service",
      "speech",
      "support",
    ],
  },
  chat_paste_go: {
    name: "chat_paste_go",
    version: 229,
    popularity: 22,
    codepoint: 63165,
    categories: ["Communication"],
    tags: [
      "arrow",
      "arrows",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "direction",
      "directions",
      "feedback",
      "message",
      "navigation",
      "right",
      "speech",
    ],
  },
  check: {
    name: "check",
    version: 18,
    popularity: 102010,
    codepoint: 58826,
    categories: ["navigation"],
    tags: [
      "DISABLE_IOS",
      "check",
      "confirm",
      "correct",
      "disable_ios",
      "done",
      "enter",
      "mark",
      "ok",
      "okay",
      "select",
      "tick",
      "yes",
    ],
  },
  check_box: {
    name: "check_box",
    version: 16,
    popularity: 116595,
    codepoint: 59444,
    categories: ["toggle"],
    tags: [
      "approved",
      "box",
      "button",
      "check",
      "component",
      "control",
      "form",
      "mark",
      "ok",
      "select",
      "selected",
      "selection",
      "tick",
      "toggle",
      "ui",
      "yes",
    ],
  },
  check_box_outline_blank: {
    name: "check_box_outline_blank",
    version: 16,
    popularity: 95103,
    codepoint: 59445,
    categories: ["toggle"],
    tags: [
      "blank",
      "box",
      "button",
      "check",
      "component",
      "control",
      "dash",
      "dashed",
      "deselected",
      "empty",
      "form",
      "outline",
      "select",
      "selection",
      "square",
      "tick",
      "toggle",
      "ui",
    ],
  },
  check_circle: {
    name: "check_circle",
    version: 19,
    popularity: 378572,
    codepoint: 59500,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  check_in_out: {
    name: "check_in_out",
    version: 229,
    popularity: 6,
    codepoint: 63222,
    categories: ["Activities"],
    tags: ["bathhouse", "hospitality", "hotel", "sauna", "spa"],
  },
  check_indeterminate_small: {
    name: "check_indeterminate_small",
    version: 229,
    popularity: 637,
    codepoint: 63626,
    categories: ["UI actions"],
    tags: [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "finished",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  check_small: {
    name: "check_small",
    version: 229,
    popularity: 1649,
    codepoint: 63627,
    categories: ["UI actions"],
    tags: [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "finished",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  checkbook: {
    name: "checkbook",
    version: 229,
    popularity: 40,
    codepoint: 59149,
    categories: ["Business&Payments"],
    tags: [
      "bank",
      "book",
      "business",
      "check",
      "checkbook",
      "money",
      "payment",
    ],
  },
  checked_bag: {
    name: "checked_bag",
    version: 229,
    popularity: 10,
    codepoint: 60172,
    categories: ["Travel"],
    tags: ["bag", "check", "luggage", "suitcase", "travel"],
  },
  checked_bag_question: {
    name: "checked_bag_question",
    version: 229,
    popularity: 6,
    codepoint: 60173,
    categories: ["Travel"],
    tags: ["bag", "check", "luggage", "question", "suitcase", "travel"],
  },
  checklist: {
    name: "checklist",
    version: 3,
    popularity: 34218,
    codepoint: 59057,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes",
    ],
  },
  checklist_rtl: {
    name: "checklist_rtl",
    version: 3,
    popularity: 13543,
    codepoint: 59059,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "rtl",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes",
    ],
  },
  checkroom: {
    name: "checkroom",
    version: 8,
    popularity: 12239,
    codepoint: 61854,
    categories: ["places"],
    tags: ["checkroom", "closet", "clothes", "coat check", "hanger"],
  },
  cheer: {
    name: "cheer",
    version: 229,
    popularity: 18,
    codepoint: 63144,
    categories: ["Social"],
    tags: ["applaud", "encourage", "hype"],
  },
  chess: {
    name: "chess",
    version: 229,
    popularity: 28,
    codepoint: 62951,
    categories: ["Social"],
    tags: ["board", "board game", "game", "google play", "strategy"],
  },
  chevron_left: {
    name: "chevron_left",
    version: 16,
    popularity: 119596,
    codepoint: 58827,
    categories: ["navigation"],
    tags: [
      "DISABLE_IOS",
      "arrow",
      "arrows",
      "chevron",
      "direction",
      "disable_ios",
      "left",
    ],
  },
  chevron_right: {
    name: "chevron_right",
    version: 16,
    popularity: 208012,
    codepoint: 58828,
    categories: ["navigation"],
    tags: ["arrow", "arrows", "chevron", "direction", "right"],
  },
  child_care: {
    name: "child_care",
    version: 12,
    popularity: 10056,
    codepoint: 60225,
    categories: ["places"],
    tags: [
      "babies",
      "baby",
      "care",
      "child",
      "children",
      "face",
      "infant",
      "kids",
      "newborn",
      "toddler",
      "young",
    ],
  },
  child_friendly: {
    name: "child_friendly",
    version: 12,
    popularity: 5968,
    codepoint: 60226,
    categories: ["places"],
    tags: [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "friendly",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young",
    ],
  },
  chip_extraction: {
    name: "chip_extraction",
    version: 229,
    popularity: 16,
    codepoint: 63521,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "data", "direction", "east", "extract", "right"],
  },
  chips: {
    name: "chips",
    version: 229,
    popularity: 12,
    codepoint: 59795,
    categories: ["UI actions"],
    tags: [
      "action",
      "app",
      "application",
      "attribute",
      "buttons",
      "chips",
      "components",
      "design",
      "input",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  chrome_reader_mode: {
    name: "chrome_reader_mode",
    version: 12,
    popularity: 5794,
    codepoint: 59501,
    categories: ["action"],
    tags: ["chrome", "mode", "read", "reader", "text"],
  },
  chromecast_2: {
    name: "chromecast_2",
    version: 229,
    popularity: 186,
    codepoint: 61819,
    categories: ["Home"],
    tags: [
      "2",
      "airplay",
      "cast",
      "chromecast",
      "connect",
      "device",
      "google",
      "hardware",
      "nest",
      "screencast",
      "streaming",
      "wireless",
    ],
  },
  chromecast_device: {
    name: "chromecast_device",
    version: 229,
    popularity: 203,
    codepoint: 59452,
    categories: ["Hardware"],
    tags: [
      "IoT",
      "airplay",
      "cast",
      "chromecast",
      "connect",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "screencast",
      "smart",
      "streaming",
      "wireless",
    ],
  },
  chronic: {
    name: "chronic",
    version: 229,
    popularity: 17,
    codepoint: 60338,
    categories: ["UI actions"],
    tags: [
      "alarm",
      "alert",
      "clock",
      "duration",
      "health",
      "time",
      "timer",
      "watch",
    ],
  },
  church: {
    name: "church",
    version: 2,
    popularity: 3535,
    codepoint: 60078,
    categories: ["maps"],
    tags: [
      "christian",
      "christianity",
      "ideology",
      "religion",
      "spiritual",
      "worship",
    ],
  },
  cinematic_blur: {
    name: "cinematic_blur",
    version: 229,
    popularity: 31,
    codepoint: 63571,
    categories: ["Audio&Video"],
    tags: [
      "account",
      "blur",
      "cinema",
      "human",
      "movie",
      "person",
      "profile",
      "user",
      "video",
    ],
  },
  circle: {
    name: "circle",
    version: 11,
    popularity: 54500,
    codepoint: 61258,
    categories: ["image"],
    tags: ["circle", "full", "geometry", "moon"],
  },
  circle_notifications: {
    name: "circle_notifications",
    version: 11,
    popularity: 15587,
    codepoint: 59796,
    categories: ["action"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "circle",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
    ],
  },
  circles: {
    name: "circles",
    version: 229,
    popularity: 11,
    codepoint: 59370,
    categories: ["Common actions"],
    tags: ["circles", "drive"],
  },
  circles_ext: {
    name: "circles_ext",
    version: 229,
    popularity: 22,
    codepoint: 59372,
    categories: ["Common actions"],
    tags: ["circles", "exit", "ext"],
  },
  clarify: {
    name: "clarify",
    version: 229,
    popularity: 228,
    codepoint: 61631,
    categories: ["Text Formatting"],
    tags: [],
  },
  clean_hands: {
    name: "clean_hands",
    version: 8,
    popularity: 4774,
    codepoint: 61983,
    categories: ["social"],
    tags: [
      "bacteria",
      "clean",
      "disinfect",
      "germs",
      "gesture",
      "hand",
      "hands",
      "sanitize",
      "sanitizer",
    ],
  },
  cleaning: {
    name: "cleaning",
    version: 229,
    popularity: 11,
    codepoint: 59797,
    categories: ["Activities"],
    tags: [
      "bleach",
      "bottle",
      "cleaner",
      "cleaning",
      "home",
      "household",
      "liquid",
      "sanitize",
      "spray",
    ],
  },
  cleaning_bucket: {
    name: "cleaning_bucket",
    version: 229,
    popularity: 366,
    codepoint: 63668,
    categories: ["Home"],
    tags: ["bucket", "cleaning", "home"],
  },
  cleaning_services: {
    name: "cleaning_services",
    version: 12,
    popularity: 13183,
    codepoint: 61695,
    categories: ["maps"],
    tags: ["clean", "cleaning", "dust", "services", "sweep"],
  },
  clear_all: {
    name: "clear_all",
    version: 12,
    popularity: 10361,
    codepoint: 57528,
    categories: ["communication"],
    tags: ["all", "clear", "doc", "document", "format", "lines", "list"],
  },
  clear_day: {
    name: "clear_day",
    version: 229,
    popularity: 1496,
    codepoint: 61783,
    categories: ["Social"],
    tags: [
      "clear",
      "climate",
      "home",
      "hot",
      "nest",
      "summer",
      "sun",
      "sunny",
      "temperature",
      "thermostat",
      "warm",
      "weather",
    ],
  },
  clear_night: {
    name: "clear_night",
    version: 229,
    popularity: 1038,
    codepoint: 61785,
    categories: ["Social"],
    tags: [
      "bedtime",
      "clear",
      "climate",
      "home",
      "lunar",
      "moon",
      "nest",
      "night",
      "thermostat",
      "weather",
    ],
  },
  climate_mini_split: {
    name: "climate_mini_split",
    version: 229,
    popularity: 145,
    codepoint: 63669,
    categories: ["Home"],
    tags: ["climate", "home", "mini", "split"],
  },
  clinical_notes: {
    name: "clinical_notes",
    version: 229,
    popularity: 48,
    codepoint: 57502,
    categories: ["Social"],
    tags: [
      "body",
      "clinic",
      "data",
      "doc",
      "doctor",
      "document",
      "file",
      "health",
      "human",
      "medic",
      "medical",
      "note",
      "nurse",
      "page",
      "paper",
      "people",
      "person",
      "writing",
    ],
  },
  clock_loader_10: {
    name: "clock_loader_10",
    version: 229,
    popularity: 44,
    codepoint: 63270,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  clock_loader_20: {
    name: "clock_loader_20",
    version: 229,
    popularity: 22,
    codepoint: 63269,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  clock_loader_40: {
    name: "clock_loader_40",
    version: 229,
    popularity: 36,
    codepoint: 63268,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  clock_loader_60: {
    name: "clock_loader_60",
    version: 229,
    popularity: 70,
    codepoint: 63267,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  clock_loader_80: {
    name: "clock_loader_80",
    version: 229,
    popularity: 28,
    codepoint: 63266,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  clock_loader_90: {
    name: "clock_loader_90",
    version: 229,
    popularity: 26,
    codepoint: 63265,
    categories: ["UI actions"],
    tags: [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload",
    ],
  },
  close: {
    name: "close",
    version: 19,
    popularity: 401688,
    codepoint: 58829,
    categories: ["navigation"],
    tags: ["cancel", "clear", "close", "exit", "remove", "stop", "x"],
  },
  close_fullscreen: {
    name: "close_fullscreen",
    version: 7,
    popularity: 21746,
    codepoint: 61903,
    categories: ["action"],
    tags: [
      "action",
      "arrow",
      "arrows",
      "close",
      "collapse",
      "direction",
      "full",
      "fullscreen",
      "minimize",
      "screen",
    ],
  },
  closed_caption: {
    name: "closed_caption",
    version: 12,
    popularity: 5101,
    codepoint: 57372,
    categories: ["av"],
    tags: [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type",
    ],
  },
  closed_caption_disabled: {
    name: "closed_caption_disabled",
    version: 8,
    popularity: 1505,
    codepoint: 61916,
    categories: ["av"],
    tags: [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "disabled",
      "enabled",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type",
    ],
  },
  cloud: {
    name: "cloud",
    version: 12,
    popularity: 29863,
    codepoint: 58045,
    categories: ["file"],
    tags: [
      "climate",
      "cloud",
      "connection",
      "internet",
      "network",
      "queue",
      "sky",
      "temperature",
      "upload",
      "weather",
    ],
  },
  cloud_circle: {
    name: "cloud_circle",
    version: 12,
    popularity: 4067,
    codepoint: 58046,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "backup",
      "circle",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload",
    ],
  },
  cloud_done: {
    name: "cloud_done",
    version: 12,
    popularity: 11163,
    codepoint: 58047,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "approve",
      "backup",
      "check",
      "cloud",
      "complete",
      "connection",
      "done",
      "drive",
      "files",
      "folders",
      "internet",
      "mark",
      "network",
      "ok",
      "select",
      "sky",
      "storage",
      "tick",
      "upload",
      "validate",
      "verified",
      "yes",
    ],
  },
  cloud_download: {
    name: "cloud_download",
    version: 12,
    popularity: 27889,
    codepoint: 58048,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "down",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload",
    ],
  },
  cloud_off: {
    name: "cloud_off",
    version: 16,
    popularity: 8687,
    codepoint: 58049,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "backup",
      "cloud",
      "connection",
      "disabled",
      "drive",
      "enabled",
      "files",
      "folders",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "sky",
      "slash",
      "storage",
      "upload",
    ],
  },
  cloud_sync: {
    name: "cloud_sync",
    version: 1,
    popularity: 6208,
    codepoint: 60250,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "around",
      "backup",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "inprogress",
      "internet",
      "load",
      "loading refresh",
      "network",
      "renew",
      "rotate",
      "sky",
      "storage",
      "turn",
      "upload",
    ],
  },
  cloud_upload: {
    name: "cloud_upload",
    version: 12,
    popularity: 36797,
    codepoint: 58051,
    categories: ["file"],
    tags: [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "up",
      "upload",
    ],
  },
  cloudy_snowing: {
    name: "cloudy_snowing",
    version: 2,
    popularity: 1213,
    codepoint: 59408,
    categories: ["home"],
    tags: [
      "climate",
      "cloud",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter",
    ],
  },
  co2: {
    name: "co2",
    version: 3,
    popularity: 3239,
    codepoint: 59312,
    categories: ["social"],
    tags: ["carbon", "chemical", "co2", "dioxide", "gas"],
  },
  co_present: {
    name: "co_present",
    version: 1,
    popularity: 4594,
    codepoint: 60144,
    categories: ["communication"],
    tags: [
      "arrow",
      "co-present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "togather",
      "web",
      "website",
    ],
  },
  code: {
    name: "code",
    version: 11,
    popularity: 55631,
    codepoint: 59503,
    categories: ["action"],
    tags: [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "html",
      "platform",
    ],
  },
  code_blocks: {
    name: "code_blocks",
    version: 229,
    popularity: 265,
    codepoint: 63565,
    categories: ["Common actions"],
    tags: [
      "block",
      "blocks",
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "html",
      "platform",
    ],
  },
  code_off: {
    name: "code_off",
    version: 4,
    popularity: 4323,
    codepoint: 58611,
    categories: ["action"],
    tags: [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "html",
      "off",
      "on",
      "platform",
      "slash",
    ],
  },
  coffee: {
    name: "coffee",
    version: 9,
    popularity: 10052,
    codepoint: 61423,
    categories: ["search"],
    tags: ["beverage", "coffee", "cup", "drink", "mug", "plate", "set", "tea"],
  },
  coffee_maker: {
    name: "coffee_maker",
    version: 9,
    popularity: 4188,
    codepoint: 61424,
    categories: ["search"],
    tags: [
      "appliances",
      "beverage",
      "coffee",
      "cup",
      "drink",
      "machine",
      "maker",
      "mug",
    ],
  },
  cognition: {
    name: "cognition",
    version: 229,
    popularity: 150,
    codepoint: 57503,
    categories: ["Social"],
    tags: [
      "body",
      "brain",
      "head",
      "health",
      "human",
      "idea",
      "ideas",
      "people",
      "person",
      "psychology",
      "thought",
      "thoughts",
    ],
  },
  collapse_all: {
    name: "collapse_all",
    version: 229,
    popularity: 179,
    codepoint: 59716,
    categories: ["UI actions"],
    tags: [
      "all",
      "arrows",
      "cell",
      "close",
      "collapse",
      "directions",
      "expand",
      "list",
      "minimize",
    ],
  },
  collections_bookmark: {
    name: "collections_bookmark",
    version: 12,
    popularity: 8963,
    codepoint: 58417,
    categories: ["image"],
    tags: [
      "album",
      "archive",
      "bookmark",
      "collections",
      "favorite",
      "gallery",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag",
    ],
  },
  colorize: {
    name: "colorize",
    version: 12,
    popularity: 6079,
    codepoint: 58296,
    categories: ["image"],
    tags: ["color", "colorize", "dropper", "extract", "eye", "picker", "tool"],
  },
  colors: {
    name: "colors",
    version: 229,
    popularity: 75,
    codepoint: 59799,
    categories: ["Text Formatting"],
    tags: [
      "bucket",
      "color",
      "doc",
      "drop",
      "edit",
      "editing",
      "editor",
      "fill",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  comic_bubble: {
    name: "comic_bubble",
    version: 229,
    popularity: 9,
    codepoint: 62941,
    categories: ["Social"],
    tags: [
      "animation",
      "anime",
      "cartoons",
      "comic book",
      "comic strip",
      "comics",
      "google play",
      "graphic novels",
      "speech bubble",
    ],
  },
  comment: {
    name: "comment",
    version: 19,
    popularity: 23222,
    codepoint: 57529,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "outline",
      "speech",
    ],
  },
  comment_bank: {
    name: "comment_bank",
    version: 15,
    popularity: 4772,
    codepoint: 59982,
    categories: ["action"],
    tags: [
      "archive",
      "bank",
      "bookmark",
      "bubble",
      "cchat",
      "comment",
      "communicate",
      "favorite",
      "label",
      "library",
      "message",
      "remember",
      "ribbon",
      "save",
      "speech",
      "tag",
    ],
  },
  comments_disabled: {
    name: "comments_disabled",
    version: 3,
    popularity: 1736,
    codepoint: 59298,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "comments",
      "communicate",
      "disabled",
      "enabled",
      "feedback",
      "message",
      "off",
      "offline",
      "on",
      "slash",
      "speech",
    ],
  },
  commit: {
    name: "commit",
    version: 1,
    popularity: 2218,
    codepoint: 60149,
    categories: ["action"],
    tags: [
      "accomplish",
      "bind",
      "circle",
      "commit",
      "dedicate",
      "execute",
      "line",
      "perform",
      "pledge",
    ],
  },
  communication: {
    name: "communication",
    version: 229,
    popularity: 1919,
    codepoint: 57980,
    categories: ["Social"],
    tags: ["communication", "feedback", "message", "nest", "people", "speech"],
  },
  communities: {
    name: "communities",
    version: 229,
    popularity: 165,
    codepoint: 59373,
    categories: ["Social"],
    tags: [
      "circle",
      "communities",
      "community",
      "group",
      "groups",
      "network",
      "people",
      "share",
      "team",
    ],
  },
  commute: {
    name: "commute",
    version: 12,
    popularity: 10087,
    codepoint: 59712,
    categories: ["action"],
    tags: [
      "automobile",
      "car",
      "commute",
      "direction",
      "maps",
      "public",
      "train",
      "transportation",
      "trip",
      "vehicle",
    ],
  },
  compare: {
    name: "compare",
    version: 12,
    popularity: 7468,
    codepoint: 58297,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustment",
      "compare",
      "edit",
      "editing",
      "edits",
      "enhance",
      "fix",
      "image",
      "images",
      "photo",
      "photography",
      "photos",
      "scan",
      "settings",
    ],
  },
  compare_arrows: {
    name: "compare_arrows",
    version: 14,
    popularity: 19261,
    codepoint: 59669,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "collide",
      "compare",
      "direction",
      "left",
      "pressure",
      "push",
      "right",
      "together",
    ],
  },
  compass_calibration: {
    name: "compass_calibration",
    version: 12,
    popularity: 1787,
    codepoint: 58748,
    categories: ["maps"],
    tags: [
      "calibration",
      "compass",
      "connection",
      "internet",
      "location",
      "maps",
      "network",
      "refresh",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  component_exchange: {
    name: "component_exchange",
    version: 229,
    popularity: 1521,
    codepoint: 61927,
    categories: ["Common actions"],
    tags: [],
  },
  compost: {
    name: "compost",
    version: 3,
    popularity: 7815,
    codepoint: 59233,
    categories: ["social"],
    tags: [
      "bio",
      "compost",
      "compostable",
      "decomposable",
      "decompose",
      "eco",
      "green",
      "leaf",
      "leafs",
      "nature",
      "organic",
      "plant",
      "recycle",
      "sustainability",
      "sustainable",
    ],
  },
  compress: {
    name: "compress",
    version: 10,
    popularity: 5811,
    codepoint: 59725,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "collide",
      "compress",
      "pressure",
      "push",
      "together",
    ],
  },
  computer: {
    name: "computer",
    version: 12,
    popularity: 31971,
    codepoint: 58122,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "web",
      "window",
    ],
  },
  concierge: {
    name: "concierge",
    version: 229,
    popularity: 9,
    codepoint: 62817,
    categories: ["Travel"],
    tags: [
      "alert",
      "bell",
      "delivery",
      "fingers",
      "gesture",
      "hand",
      "hotel",
      "local",
      "notify",
      "room",
      "service",
      "travel",
      "trip",
    ],
  },
  conditions: {
    name: "conditions",
    version: 229,
    popularity: 40,
    codepoint: 57504,
    categories: ["Social"],
    tags: [
      "body",
      "discover",
      "explore",
      "find",
      "glass",
      "health",
      "human",
      "look",
      "magnify",
      "magnifying",
      "people",
      "person",
      "search",
      "see",
    ],
  },
  confirmation_number: {
    name: "confirmation_number",
    version: 15,
    popularity: 23092,
    codepoint: 58936,
    categories: ["notification"],
    tags: [
      "admission",
      "confirmation",
      "entertainment",
      "event",
      "number",
      "ticket",
    ],
  },
  congenital: {
    name: "congenital",
    version: 229,
    popularity: 2,
    codepoint: 57505,
    categories: ["Social"],
    tags: [
      "body",
      "cleft",
      "disease",
      "health",
      "human",
      "lip",
      "lips",
      "mouth",
      "palate",
    ],
  },
  connect_without_contact: {
    name: "connect_without_contact",
    version: 7,
    popularity: 10988,
    codepoint: 61987,
    categories: ["social"],
    tags: [
      "communicating",
      "connect",
      "contact",
      "distance",
      "people",
      "signal",
      "social",
      "socialize",
      "without",
    ],
  },
  connected_tv: {
    name: "connected_tv",
    version: 11,
    popularity: 3446,
    codepoint: 59800,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless",
    ],
  },
  connecting_airports: {
    name: "connecting_airports",
    version: 2,
    popularity: 2349,
    codepoint: 59337,
    categories: ["maps"],
    tags: [
      "airplane",
      "airplanes",
      "airport",
      "airports",
      "connecting",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip",
    ],
  },
  construction: {
    name: "construction",
    version: 11,
    popularity: 46719,
    codepoint: 59964,
    categories: ["social"],
    tags: [
      "build",
      "carpenter",
      "construction",
      "equipment",
      "fix",
      "hammer",
      "improvement",
      "industrial",
      "industry",
      "repair",
      "tools",
      "wrench",
    ],
  },
  contact_emergency: {
    name: "contact_emergency",
    version: 1,
    popularity: 1731,
    codepoint: 63697,
    categories: ["communication"],
    tags: [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user",
    ],
  },
  contact_mail: {
    name: "contact_mail",
    version: 13,
    popularity: 27964,
    codepoint: 57552,
    categories: ["communication"],
    tags: [
      "account",
      "address",
      "avatar",
      "communicate",
      "contact",
      "email",
      "face",
      "human",
      "info",
      "information",
      "mail",
      "message",
      "people",
      "person",
      "profile",
      "user",
    ],
  },
  contact_page: {
    name: "contact_page",
    version: 6,
    popularity: 28882,
    codepoint: 61998,
    categories: ["action"],
    tags: [
      "account",
      "avatar",
      "contact",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "folders",
      "human",
      "page",
      "people",
      "person",
      "profile",
      "sheet",
      "slide",
      "storage",
      "user",
      "writing",
    ],
  },
  contact_phone: {
    name: "contact_phone",
    version: 13,
    popularity: 17542,
    codepoint: 57551,
    categories: ["communication"],
    tags: [
      "account",
      "avatar",
      "call",
      "communicate",
      "contact",
      "face",
      "human",
      "info",
      "information",
      "message",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user",
    ],
  },
  contact_support: {
    name: "contact_support",
    version: 13,
    popularity: 53674,
    codepoint: 59724,
    categories: ["action"],
    tags: [
      "?",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "contact",
      "help",
      "info",
      "information",
      "mark",
      "message",
      "punctuation",
      "question",
      "question mark",
      "speech",
      "support",
      "symbol",
    ],
  },
  contactless: {
    name: "contactless",
    version: 11,
    popularity: 8382,
    codepoint: 60017,
    categories: ["action"],
    tags: [
      "bluetooth",
      "cash",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "contactless",
      "credit",
      "device",
      "finance",
      "pay",
      "payment",
      "signal",
      "transaction",
      "wifi",
      "wireless",
    ],
  },
  contactless_off: {
    name: "contactless_off",
    version: 229,
    popularity: 79,
    codepoint: 63576,
    categories: ["Business&Payments"],
    tags: [
      "bluetooth",
      "cash",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "contactless",
      "credit",
      "device",
      "disable",
      "disabled",
      "enabled",
      "finance",
      "off",
      "offline",
      "on",
      "pay",
      "payment",
      "signal",
      "slash",
      "transaction",
      "wifi",
      "wireless",
    ],
  },
  contacts: {
    name: "contacts",
    version: 11,
    popularity: 23357,
    codepoint: 57530,
    categories: ["communication"],
    tags: [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user",
    ],
  },
  contacts_product: {
    name: "contacts_product",
    version: 229,
    popularity: 172,
    codepoint: 59801,
    categories: ["Common actions"],
    tags: ["contacts", "logo", "product"],
  },
  content_copy: {
    name: "content_copy",
    version: 17,
    popularity: 125616,
    codepoint: 57677,
    categories: ["content"],
    tags: [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "paste",
      "stack",
    ],
  },
  content_cut: {
    name: "content_cut",
    version: 17,
    popularity: 15807,
    codepoint: 57678,
    categories: ["content"],
    tags: [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "paste",
      "scissors",
      "trim",
    ],
  },
  content_paste: {
    name: "content_paste",
    version: 14,
    popularity: 30340,
    codepoint: 57679,
    categories: ["content"],
    tags: [
      "clipboard",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "multiple",
      "paste",
    ],
  },
  content_paste_go: {
    name: "content_paste_go",
    version: 2,
    popularity: 4064,
    codepoint: 60046,
    categories: ["content"],
    tags: [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "go",
      "on",
      "paste",
      "slash",
    ],
  },
  content_paste_off: {
    name: "content_paste_off",
    version: 4,
    popularity: 2875,
    codepoint: 58616,
    categories: ["content"],
    tags: [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "off",
      "on",
      "paste",
      "slash",
    ],
  },
  content_paste_search: {
    name: "content_paste_search",
    version: 2,
    popularity: 7628,
    codepoint: 60059,
    categories: ["content"],
    tags: [
      "clipboard",
      "content",
      "doc",
      "document",
      "file",
      "find",
      "paste",
      "search",
      "trace",
      "track",
    ],
  },
  contract: {
    name: "contract",
    version: 229,
    popularity: 34,
    codepoint: 62880,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "document",
      "list",
      "page",
      "paper",
      "paperwork",
      "record",
      "transaction",
    ],
  },
  contract_delete: {
    name: "contract_delete",
    version: 229,
    popularity: 9,
    codepoint: 62882,
    categories: ["Text Formatting"],
    tags: [
      "cancel",
      "clear",
      "close",
      "doc",
      "document",
      "exit",
      "list",
      "no",
      "page",
      "paper",
      "paperwork",
      "quit",
      "record",
      "remove",
      "stop",
      "transaction",
      "x",
    ],
  },
  contract_edit: {
    name: "contract_edit",
    version: 229,
    popularity: 31,
    codepoint: 62881,
    categories: ["Text Formatting"],
    tags: [
      "compose",
      "create",
      "doc",
      "document",
      "draft",
      "editing",
      "input",
      "list",
      "modify",
      "page",
      "paper",
      "paperwork",
      "pen",
      "pencil",
      "record",
      "transaction",
      "write",
      "writing",
    ],
  },
  contrast: {
    name: "contrast",
    version: 1,
    popularity: 2829,
    codepoint: 60215,
    categories: ["image"],
    tags: [
      "black",
      "contrast",
      "dark theme",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "white",
    ],
  },
  contrast_rtl_off: {
    name: "contrast_rtl_off",
    version: 229,
    popularity: 1,
    codepoint: 60530,
    categories: ["Photo&Image"],
    tags: [
      "black",
      "contrast",
      "disabled",
      "edit",
      "editing",
      "effect",
      "enabled",
      "filter",
      "grayscale",
      "image",
      "images",
      "off",
      "offline",
      "on",
      "photography",
      "picture",
      "pictures",
      "settings",
      "white",
    ],
  },
  control_camera: {
    name: "control_camera",
    version: 12,
    popularity: 3941,
    codepoint: 57460,
    categories: ["av"],
    tags: [
      "adjust",
      "arrow",
      "arrows",
      "camera",
      "center",
      "control",
      "direction",
      "left",
      "move",
      "reposition",
      "right",
    ],
  },
  control_point_duplicate: {
    name: "control_point_duplicate",
    version: 12,
    popularity: 3745,
    codepoint: 58299,
    categories: ["image"],
    tags: [
      "+",
      "add",
      "circle",
      "control",
      "duplicate",
      "multiple",
      "new",
      "plus",
      "point",
      "symbol",
    ],
  },
  controller_gen: {
    name: "controller_gen",
    version: 229,
    popularity: 233,
    codepoint: 59453,
    categories: ["Household"],
    tags: ["button", "circle", "controller", "nest", "regulator"],
  },
  conversion_path: {
    name: "conversion_path",
    version: 229,
    popularity: 2433,
    codepoint: 61633,
    categories: ["Business&Payments"],
    tags: [
      "ads",
      "analytics",
      "attribution",
      "connecting",
      "conversion",
      "dots",
      "line",
      "path",
    ],
  },
  conversion_path_off: {
    name: "conversion_path_off",
    version: 229,
    popularity: 4,
    codepoint: 63412,
    categories: ["Business&Payments"],
    tags: [
      "ads",
      "analytics",
      "attribution",
      "connecting",
      "conversion",
      "disabled",
      "dots",
      "enabled",
      "line",
      "off",
      "offline",
      "on",
      "path",
      "slash",
    ],
  },
  conveyor_belt: {
    name: "conveyor_belt",
    version: 1,
    popularity: 1168,
    codepoint: 63591,
    categories: ["hardware"],
    tags: [
      "factory",
      "logistic",
      "logistics",
      "manufactory",
      "production",
      "supply",
      "system",
      "transport",
    ],
  },
  cookie: {
    name: "cookie",
    version: 2,
    popularity: 5708,
    codepoint: 60076,
    categories: ["social"],
    tags: ["biscuit", "cookies", "data", "dessert", "wafer"],
  },
  cookie_off: {
    name: "cookie_off",
    version: 229,
    popularity: 11,
    codepoint: 63386,
    categories: ["Social"],
    tags: [
      "biscuit",
      "cookies",
      "data",
      "dessert",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "slash",
      "wafer",
    ],
  },
  cooking: {
    name: "cooking",
    version: 229,
    popularity: 1067,
    codepoint: 58038,
    categories: ["Household"],
    tags: [
      "braiser",
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "dutch oven",
      "food",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "nest",
      "oven",
      "pan",
      "pot",
      "stockpot",
      "stove",
      "stovetop",
    ],
  },
  cool_to_dry: {
    name: "cool_to_dry",
    version: 229,
    popularity: 193,
    codepoint: 57974,
    categories: ["Home"],
    tags: ["climate", "cool ", "dry", "home", "nest", "thermostat"],
  },
  copy_all: {
    name: "copy_all",
    version: 8,
    popularity: 8788,
    codepoint: 58092,
    categories: ["content"],
    tags: [
      "all",
      "content",
      "copy",
      "cut",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "multiple",
      "page",
      "paper",
      "past",
    ],
  },
  copyright: {
    name: "copyright",
    version: 17,
    popularity: 17548,
    codepoint: 59660,
    categories: ["action"],
    tags: [
      "alphabet",
      "c",
      "character",
      "copyright",
      "emblem",
      "font",
      "legal",
      "letter",
      "owner",
      "symbol",
      "text",
    ],
  },
  coronavirus: {
    name: "coronavirus",
    version: 7,
    popularity: 17646,
    codepoint: 61985,
    categories: ["social"],
    tags: [
      "19",
      "bacteria",
      "coronavirus",
      "covid",
      "disease",
      "germs",
      "illness",
      "sick",
      "social",
    ],
  },
  corporate_fare: {
    name: "corporate_fare",
    version: 7,
    popularity: 18049,
    codepoint: 61904,
    categories: ["places"],
    tags: [
      "architecture",
      "building",
      "business",
      "corporate",
      "estate",
      "fare",
      "organization",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
    ],
  },
  cottage: {
    name: "cottage",
    version: 4,
    popularity: 16315,
    codepoint: 58759,
    categories: ["places"],
    tags: [
      "architecture",
      "beach",
      "cottage",
      "estate",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
    ],
  },
  counter_0: {
    name: "counter_0",
    version: 229,
    popularity: 7,
    codepoint: 63365,
    categories: ["Text Formatting"],
    tags: [
      "0",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "zero",
    ],
  },
  counter_1: {
    name: "counter_1",
    version: 229,
    popularity: 238,
    codepoint: 63364,
    categories: ["Text Formatting"],
    tags: [
      "1",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "one",
      "symbol",
    ],
  },
  counter_2: {
    name: "counter_2",
    version: 229,
    popularity: 86,
    codepoint: 63363,
    categories: ["Text Formatting"],
    tags: [
      "2",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "two",
    ],
  },
  counter_3: {
    name: "counter_3",
    version: 229,
    popularity: 61,
    codepoint: 63362,
    categories: ["Text Formatting"],
    tags: [
      "3",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "three",
    ],
  },
  counter_4: {
    name: "counter_4",
    version: 229,
    popularity: 36,
    codepoint: 63361,
    categories: ["Text Formatting"],
    tags: [
      "4",
      "circle",
      "digit",
      "four",
      "looks",
      "number",
      "numbers",
      "symbol",
    ],
  },
  counter_5: {
    name: "counter_5",
    version: 229,
    popularity: 18,
    codepoint: 63360,
    categories: ["Text Formatting"],
    tags: [
      "5",
      "circle",
      "digit",
      "five",
      "looks",
      "number",
      "numbers",
      "symbol",
    ],
  },
  counter_6: {
    name: "counter_6",
    version: 229,
    popularity: 12,
    codepoint: 63359,
    categories: ["Text Formatting"],
    tags: [
      "6",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "six",
      "symbol",
    ],
  },
  counter_7: {
    name: "counter_7",
    version: 229,
    popularity: 8,
    codepoint: 63358,
    categories: ["Text Formatting"],
    tags: [
      "7",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "seven",
      "symbol",
    ],
  },
  counter_8: {
    name: "counter_8",
    version: 229,
    popularity: 8,
    codepoint: 63357,
    categories: ["Text Formatting"],
    tags: [
      "8",
      "circle",
      "digit",
      "eight",
      "looks",
      "number",
      "numbers",
      "symbol",
    ],
  },
  counter_9: {
    name: "counter_9",
    version: 229,
    popularity: 8,
    codepoint: 63356,
    categories: ["Text Formatting"],
    tags: [
      "9",
      "circle",
      "digit",
      "looks",
      "nine",
      "number",
      "numbers",
      "symbol",
    ],
  },
  countertops: {
    name: "countertops",
    version: 6,
    popularity: 3081,
    codepoint: 61943,
    categories: ["places"],
    tags: [
      "counter",
      "countertops",
      "home",
      "house",
      "kitchen",
      "sink",
      "table",
      "tops",
    ],
  },
  create_new_folder: {
    name: "create_new_folder",
    version: 11,
    popularity: 15123,
    codepoint: 58060,
    categories: ["file"],
    tags: [
      "+",
      "add",
      "create",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "new",
      "plus",
      "sheet",
      "slide",
      "storage",
      "symbol",
    ],
  },
  credit_card: {
    name: "credit_card",
    version: 12,
    popularity: 86788,
    codepoint: 59504,
    categories: ["action"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  credit_card_gear: {
    name: "credit_card_gear",
    version: 229,
    popularity: 0,
    codepoint: 62765,
    categories: ["Business&Payments"],
    tags: [
      "application",
      "bill",
      "card",
      "cash",
      "change",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "details",
      "dollars",
      "finance",
      "gear",
      "info",
      "information",
      "money",
      "online",
      "options",
      "pay",
      "payment",
      "personal",
      "price",
      "service",
      "settings",
      "shopping",
      "symbol",
    ],
  },
  credit_card_heart: {
    name: "credit_card_heart",
    version: 229,
    popularity: 1,
    codepoint: 62764,
    categories: ["Business&Payments"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "favorite",
      "finance",
      "heart",
      "like",
      "love",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "remember",
      "save",
      "shopping",
      "symbol",
    ],
  },
  credit_card_off: {
    name: "credit_card_off",
    version: 4,
    popularity: 5402,
    codepoint: 58612,
    categories: ["action"],
    tags: [
      "card",
      "charge",
      "commerce",
      "cost",
      "credit",
      "disabled",
      "enabled",
      "finance",
      "money",
      "off",
      "online",
      "pay",
      "payment",
      "slash",
    ],
  },
  credit_score: {
    name: "credit_score",
    version: 10,
    popularity: 21996,
    codepoint: 61425,
    categories: ["device"],
    tags: [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "loan",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "score",
      "select",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  crib: {
    name: "crib",
    version: 4,
    popularity: 1798,
    codepoint: 58760,
    categories: ["places"],
    tags: [
      "babies",
      "baby",
      "bassinet",
      "bed",
      "child",
      "children",
      "cradle",
      "crib",
      "infant",
      "kid",
      "newborn",
      "sleeping",
      "toddler",
    ],
  },
  crisis_alert: {
    name: "crisis_alert",
    version: 1,
    popularity: 2484,
    codepoint: 60393,
    categories: ["maps"],
    tags: [
      "!",
      "alert",
      "attention",
      "bullseye",
      "caution",
      "crisis",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "target",
      "warning",
    ],
  },
  crop: {
    name: "crop",
    version: 12,
    popularity: 6776,
    codepoint: 58302,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_16_9: {
    name: "crop_16_9",
    version: 13,
    popularity: 2650,
    codepoint: 58300,
    categories: ["image"],
    tags: [
      "16",
      "9",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_3_2: {
    name: "crop_3_2",
    version: 13,
    popularity: 1654,
    codepoint: 58301,
    categories: ["image"],
    tags: [
      "2",
      "3",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_5_4: {
    name: "crop_5_4",
    version: 13,
    popularity: 1938,
    codepoint: 58303,
    categories: ["image"],
    tags: [
      "4",
      "5",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing settings",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "size",
      "square",
    ],
  },
  crop_7_5: {
    name: "crop_7_5",
    version: 13,
    popularity: 2134,
    codepoint: 58304,
    categories: ["image"],
    tags: [
      "5",
      "7",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_9_16: {
    name: "crop_9_16",
    version: 229,
    popularity: 2,
    codepoint: 62793,
    categories: ["Photo&Image"],
    tags: [
      "16",
      "9",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_free: {
    name: "crop_free",
    version: 12,
    popularity: 9844,
    codepoint: 58306,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "crop",
      "display",
      "edit",
      "editing",
      "focus",
      "frame",
      "free",
      "image",
      "photo",
      "photos",
      "settings",
      "size",
      "zoom",
    ],
  },
  crop_landscape: {
    name: "crop_landscape",
    version: 12,
    popularity: 1655,
    codepoint: 58307,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "landscape",
      "photo",
      "photos",
      "settings",
      "size",
    ],
  },
  crop_portrait: {
    name: "crop_portrait",
    version: 12,
    popularity: 2298,
    codepoint: 58309,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "portrait",
      "rectangle",
      "settings",
      "size",
      "square",
    ],
  },
  crop_rotate: {
    name: "crop_rotate",
    version: 12,
    popularity: 2080,
    codepoint: 58423,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "area",
      "arrow",
      "arrows",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rotate",
      "settings",
      "size",
      "turn",
    ],
  },
  crop_square: {
    name: "crop_square",
    version: 12,
    popularity: 8721,
    codepoint: 58310,
    categories: ["image"],
    tags: [
      "adjust",
      "adjustments",
      "app",
      "application",
      "area",
      "components",
      "crop",
      "design",
      "edit",
      "editing",
      "expand",
      "frame",
      "image",
      "images",
      "interface",
      "open",
      "photo",
      "photos",
      "rectangle",
      "screen",
      "settings",
      "shape",
      "shapes",
      "site",
      "size",
      "square",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  crossword: {
    name: "crossword",
    version: 229,
    popularity: 31,
    codepoint: 62949,
    categories: ["Social"],
    tags: ["board game", "google play", "puzzle", "word puzzle", "words"],
  },
  crowdsource: {
    name: "crowdsource",
    version: 229,
    popularity: 99,
    codepoint: 60184,
    categories: ["Social"],
    tags: [
      "business",
      "crowd",
      "crowdsource",
      "funding",
      "funds",
      "investment",
      "logo",
      "payment",
      "people",
    ],
  },
  cruelty_free: {
    name: "cruelty_free",
    version: 3,
    popularity: 6129,
    codepoint: 59289,
    categories: ["social"],
    tags: [
      "animal",
      "bunny",
      "cruelty",
      "eco",
      "free",
      "nature",
      "rabbit",
      "social",
      "sustainability",
      "sustainable",
      "testing",
    ],
  },
  css: {
    name: "css",
    version: 1,
    popularity: 2044,
    codepoint: 60307,
    categories: ["action"],
    tags: [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type",
    ],
  },
  csv: {
    name: "csv",
    version: 229,
    popularity: 46,
    codepoint: 59087,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "comma",
      "csv",
      "file",
      "font",
      "format",
      "letter",
      "separated",
      "spreadsheets",
      "symbol",
      "text",
      "type",
      "values",
    ],
  },
  currency_bitcoin: {
    name: "currency_bitcoin",
    version: 1,
    popularity: 3680,
    codepoint: 60357,
    categories: ["image"],
    tags: [
      "bill",
      "blockchain",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digital",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  currency_exchange: {
    name: "currency_exchange",
    version: 1,
    popularity: 17573,
    codepoint: 60272,
    categories: ["action"],
    tags: [
      "360",
      "around",
      "arrow",
      "arrows",
      "cash",
      "coin",
      "commerce",
      "currency",
      "direction",
      "dollars",
      "exchange",
      "inprogress",
      "money",
      "pay",
      "renew",
      "rotate",
      "sync",
      "turn",
      "universal",
    ],
  },
  currency_franc: {
    name: "currency_franc",
    version: 1,
    popularity: 665,
    codepoint: 60154,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  currency_lira: {
    name: "currency_lira",
    version: 1,
    popularity: 897,
    codepoint: 60143,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "lira",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  currency_pound: {
    name: "currency_pound",
    version: 1,
    popularity: 2835,
    codepoint: 60145,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pound",
      "price",
      "shopping",
      "symbol",
    ],
  },
  currency_ruble: {
    name: "currency_ruble",
    version: 1,
    popularity: 1990,
    codepoint: 60140,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "ruble",
      "shopping",
      "symbol",
    ],
  },
  currency_rupee: {
    name: "currency_rupee",
    version: 1,
    popularity: 9148,
    codepoint: 60151,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "rupee",
      "shopping",
      "symbol",
    ],
  },
  currency_yen: {
    name: "currency_yen",
    version: 1,
    popularity: 3327,
    codepoint: 60155,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yen",
    ],
  },
  currency_yuan: {
    name: "currency_yuan",
    version: 1,
    popularity: 906,
    codepoint: 60153,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yuan",
    ],
  },
  curtains: {
    name: "curtains",
    version: 1,
    popularity: 553,
    codepoint: 60446,
    categories: ["home"],
    tags: [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  curtains_closed: {
    name: "curtains_closed",
    version: 1,
    popularity: 523,
    codepoint: 60445,
    categories: ["home"],
    tags: [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  custom_typography: {
    name: "custom_typography",
    version: 229,
    popularity: 14,
    codepoint: 59186,
    categories: ["Text Formatting"],
    tags: [
      "A",
      "alphabet",
      "character",
      "custom",
      "customization",
      "font",
      "letter",
      "star",
      "style",
      "symbol",
      "text",
      "theme",
      "type",
      "typography",
    ],
  },
  cut: {
    name: "cut",
    version: 229,
    popularity: 1684,
    codepoint: 61579,
    categories: ["Text Formatting"],
    tags: [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "past",
      "scissors",
      "trim",
    ],
  },
  cycle: {
    name: "cycle",
    version: 229,
    popularity: 505,
    codepoint: 63572,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "history",
      "load",
      "loading",
      "navigation",
      "recycle",
      "refresh",
      "renew",
      "reuse",
      "rotate",
      "turn",
    ],
  },
  cyclone: {
    name: "cyclone",
    version: 1,
    popularity: 1171,
    codepoint: 60373,
    categories: ["social"],
    tags: [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather",
      "wind",
      "winds",
    ],
  },
  dangerous: {
    name: "dangerous",
    version: 12,
    popularity: 20318,
    codepoint: 59802,
    categories: ["action"],
    tags: [
      "broken",
      "danger",
      "dangerous",
      "fix",
      "no",
      "sign",
      "stop",
      "update",
      "warning",
      "wrong",
      "x",
    ],
  },
  dark_mode: {
    name: "dark_mode",
    version: 4,
    popularity: 47475,
    codepoint: 58652,
    categories: ["device"],
    tags: [
      "app",
      "application",
      "dark",
      "device",
      "interface",
      "mode",
      "moon",
      "night",
      "silent",
      "theme",
      "ui",
      "ux",
      "website",
    ],
  },
  dashboard: {
    name: "dashboard",
    version: 13,
    popularity: 130019,
    codepoint: 59505,
    categories: ["action"],
    tags: [
      "cards",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website",
    ],
  },
  dashboard_customize: {
    name: "dashboard_customize",
    version: 11,
    popularity: 22240,
    codepoint: 59803,
    categories: ["action"],
    tags: [
      "cards",
      "customize",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website",
    ],
  },
  data_alert: {
    name: "data_alert",
    version: 229,
    popularity: 79,
    codepoint: 63478,
    categories: ["UI actions"],
    tags: [
      "!",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "list",
      "lists",
      "mark",
      "notification",
      "symbol",
      "warning",
    ],
  },
  data_array: {
    name: "data_array",
    version: 2,
    popularity: 1744,
    codepoint: 60113,
    categories: ["editor"],
    tags: ["array", "brackets", "code", "coder", "data", "parentheses"],
  },
  data_check: {
    name: "data_check",
    version: 229,
    popularity: 91,
    codepoint: 63474,
    categories: ["UI actions"],
    tags: [
      "approve",
      "checkmark",
      "complete",
      "done",
      "list",
      "lists",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  data_exploration: {
    name: "data_exploration",
    version: 3,
    popularity: 7136,
    codepoint: 59247,
    categories: ["action"],
    tags: [
      "analytics",
      "arrow",
      "chart",
      "data",
      "diagram",
      "exploration",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  data_info_alert: {
    name: "data_info_alert",
    version: 229,
    popularity: 70,
    codepoint: 63477,
    categories: ["UI actions"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "list",
      "lists",
      "service",
      "support",
    ],
  },
  data_loss_prevention: {
    name: "data_loss_prevention",
    version: 229,
    popularity: 69,
    codepoint: 58076,
    categories: ["Common actions"],
    tags: [
      "account",
      "avatar",
      "data",
      "face",
      "find",
      "glass",
      "human",
      "look",
      "loss",
      "magnify",
      "magnifying",
      "people",
      "person",
      "prevention",
      "profile",
      "search",
      "see",
      "user",
    ],
  },
  data_object: {
    name: "data_object",
    version: 2,
    popularity: 4620,
    codepoint: 60115,
    categories: ["editor"],
    tags: ["brackets", "code", "coder", "data", "object", "parentheses"],
  },
  data_saver_on: {
    name: "data_saver_on",
    version: 10,
    popularity: 2931,
    codepoint: 61427,
    categories: ["device"],
    tags: [
      "+",
      "add",
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "on",
      "plus",
      "ring",
      "saver",
      "statistics",
      "symbol",
      "tracking",
    ],
  },
  data_table: {
    name: "data_table",
    version: 229,
    popularity: 130,
    codepoint: 59804,
    categories: ["Business&Payments"],
    tags: [
      "app",
      "application",
      "cells",
      "chart",
      "column",
      "columns",
      "components",
      "data",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "row",
      "screen",
      "sheet",
      "site",
      "spreadsheet",
      "table",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  data_thresholding: {
    name: "data_thresholding",
    version: 1,
    popularity: 2337,
    codepoint: 60319,
    categories: ["action"],
    tags: ["data", "hidden", "privacy", "thresholding", "thresold"],
  },
  data_usage: {
    name: "data_usage",
    version: 12,
    popularity: 6892,
    codepoint: 57775,
    categories: ["device"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "usage",
    ],
  },
  database: {
    name: "database",
    version: 229,
    popularity: 11497,
    codepoint: 61966,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "coin",
      "data",
      "database",
      "diagram",
      "graph",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  dataset: {
    name: "dataset",
    version: 1,
    popularity: 1463,
    codepoint: 63726,
    categories: ["device"],
    tags: [],
  },
  dataset_linked: {
    name: "dataset_linked",
    version: 1,
    popularity: 799,
    codepoint: 63727,
    categories: ["device"],
    tags: [],
  },
  date_range: {
    name: "date_range",
    version: 12,
    popularity: 99728,
    codepoint: 59670,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "range",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today",
      "week",
    ],
  },
  deblur: {
    name: "deblur",
    version: 1,
    popularity: 1015,
    codepoint: 60279,
    categories: ["image"],
    tags: [
      "adjust",
      "deblur",
      "edit",
      "editing",
      "enhance",
      "face",
      "image",
      "lines",
      "photo",
      "photography",
      "sharpen",
    ],
  },
  deceased: {
    name: "deceased",
    version: 229,
    popularity: 21,
    codepoint: 57509,
    categories: ["Social"],
    tags: ["dead", "death", "flower", "flowers", "garden", "health", "yard"],
  },
  decimal_decrease: {
    name: "decimal_decrease",
    version: 229,
    popularity: 2,
    codepoint: 63533,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "currency",
      "decimal",
      "decline",
      "decrease",
      "finance",
      "fraction",
      "fractions",
      "left",
      "less",
      "money",
    ],
  },
  decimal_increase: {
    name: "decimal_increase",
    version: 229,
    popularity: 14,
    codepoint: 63532,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "currency",
      "decimal",
      "finance",
      "fraction",
      "fractions",
      "growth",
      "increase",
      "money",
      "more",
      "right",
    ],
  },
  deck: {
    name: "deck",
    version: 11,
    popularity: 5326,
    codepoint: 59970,
    categories: ["social"],
    tags: [
      "chairs",
      "deck",
      "home",
      "house",
      "outdoors",
      "outside",
      "patio",
      "social",
      "terrace",
      "umbrella",
      "yard",
    ],
  },
  dehaze: {
    name: "dehaze",
    version: 12,
    popularity: 5759,
    codepoint: 58311,
    categories: ["image"],
    tags: [
      "adjust",
      "dehaze",
      "edit",
      "editing",
      "enhance",
      "haze",
      "image",
      "lines",
      "photo",
      "photography",
      "remove",
    ],
  },
  delete: {
    name: "delete",
    version: 17,
    popularity: 355502,
    codepoint: 59506,
    categories: ["action"],
    tags: ["bin", "can", "delete", "garbage", "remove", "trash"],
  },
  delete_forever: {
    name: "delete_forever",
    version: 15,
    popularity: 62195,
    codepoint: 59691,
    categories: ["action"],
    tags: [
      "bin",
      "can",
      "cancel",
      "clear",
      "delete",
      "exit",
      "forever",
      "garbage",
      "remove",
      "trash",
      "x",
    ],
  },
  delete_history: {
    name: "delete_history",
    version: 229,
    popularity: 0,
    codepoint: 62744,
    categories: ["Common actions"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "cancel",
      "clear",
      "clock",
      "close",
      "date",
      "exit",
      "history",
      "no",
      "quit",
      "refresh",
      "remove",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "stop",
      "time",
      "turn",
      "x",
    ],
  },
  delete_sweep: {
    name: "delete_sweep",
    version: 12,
    popularity: 12048,
    codepoint: 57708,
    categories: ["content"],
    tags: ["bin", "can", "delete", "garbage", "remove", "sweep", "trash"],
  },
  demography: {
    name: "demography",
    version: 229,
    popularity: 61,
    codepoint: 58505,
    categories: ["Social"],
    tags: [
      "body",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "human",
      "note",
      "page",
      "paper",
      "people",
      "person",
      "writing",
    ],
  },
  density_large: {
    name: "density_large",
    version: 1,
    popularity: 1233,
    codepoint: 60329,
    categories: ["action"],
    tags: ["density", "horizontal", "large", "lines", "rule", "rules"],
  },
  density_medium: {
    name: "density_medium",
    version: 1,
    popularity: 5869,
    codepoint: 60318,
    categories: ["action"],
    tags: ["density", "horizontal", "lines", "medium", "rule", "rules"],
  },
  density_small: {
    name: "density_small",
    version: 1,
    popularity: 2433,
    codepoint: 60328,
    categories: ["action"],
    tags: ["density", "horizontal", "lines", "rule", "rules", "small"],
  },
  dentistry: {
    name: "dentistry",
    version: 229,
    popularity: 636,
    codepoint: 57510,
    categories: ["Social"],
    tags: [
      "dentist",
      "dentistry",
      "health",
      "hygiene",
      "medical",
      "mouth",
      "oral",
      "teeth",
      "tooth",
    ],
  },
  departure_board: {
    name: "departure_board",
    version: 11,
    popularity: 4658,
    codepoint: 58742,
    categories: ["maps"],
    tags: [
      "automobile",
      "board",
      "bus",
      "car",
      "cars",
      "clock",
      "departure",
      "maps",
      "public",
      "schedule",
      "time",
      "transportation",
      "travel",
      "vehicle",
    ],
  },
  deployed_code: {
    name: "deployed_code",
    version: 229,
    popularity: 120,
    codepoint: 63264,
    categories: ["UI actions"],
    tags: [
      "3d",
      "coding",
      "create",
      "cube",
      "development",
      "dimension",
      "install",
      "installed",
      "package",
      "packet",
    ],
  },
  deployed_code_account: {
    name: "deployed_code_account",
    version: 229,
    popularity: 1,
    codepoint: 62747,
    categories: ["UI actions"],
    tags: [
      "3d",
      "accounts",
      "change",
      "coding",
      "create",
      "cube",
      "details",
      "development",
      "dimension",
      "face",
      "gear",
      "human",
      "install",
      "installed",
      "manage",
      "options",
      "package",
      "packet",
      "people",
      "person",
      "profile",
      "service",
      "settings",
      "user",
    ],
  },
  deployed_code_alert: {
    name: "deployed_code_alert",
    version: 229,
    popularity: 6,
    codepoint: 62962,
    categories: ["UI actions"],
    tags: [
      "!",
      "3d",
      "alert",
      "attention",
      "borg",
      "caution",
      "coding",
      "create",
      "cube",
      "danger",
      "development",
      "dimension",
      "error",
      "exclamation",
      "important",
      "install",
      "installed",
      "mark",
      "notification",
      "package",
      "packet",
      "symbol",
      "warning",
    ],
  },
  deployed_code_history: {
    name: "deployed_code_history",
    version: 229,
    popularity: 7,
    codepoint: 62963,
    categories: ["UI actions"],
    tags: [
      "3d",
      "borg",
      "clock",
      "coding",
      "create",
      "cube",
      "date",
      "development",
      "dimension",
      "install",
      "installed",
      "package",
      "packet",
      "pending",
      "recent",
      "schedule",
      "time",
      "updates",
    ],
  },
  deployed_code_update: {
    name: "deployed_code_update",
    version: 229,
    popularity: 24,
    codepoint: 62964,
    categories: ["UI actions"],
    tags: [
      "3d",
      "arrow",
      "arrows",
      "borg",
      "coding",
      "create",
      "cube",
      "development",
      "dimension",
      "down",
      "download",
      "install",
      "installed",
      "package",
      "packet",
    ],
  },
  dermatology: {
    name: "dermatology",
    version: 229,
    popularity: 13,
    codepoint: 57511,
    categories: ["Social"],
    tags: [
      "body",
      "derm",
      "follicle",
      "hair",
      "health",
      "human",
      "skin",
      "skincare",
    ],
  },
  description: {
    name: "description",
    version: 12,
    popularity: 241645,
    codepoint: 59507,
    categories: ["action"],
    tags: [
      "article",
      "data",
      "description",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  deselect: {
    name: "deselect",
    version: 1,
    popularity: 1611,
    codepoint: 60342,
    categories: ["content"],
    tags: [
      "all",
      "disabled",
      "enabled",
      "off",
      "on",
      "selection",
      "slash",
      "square",
      "tool",
    ],
  },
  design_services: {
    name: "design_services",
    version: 12,
    popularity: 15982,
    codepoint: 61706,
    categories: ["maps"],
    tags: [
      "compose",
      "create",
      "design",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "ruler",
      "service",
      "write",
      "writing",
    ],
  },
  desk: {
    name: "desk",
    version: 1,
    popularity: 545,
    codepoint: 63732,
    categories: ["places"],
    tags: [],
  },
  deskphone: {
    name: "deskphone",
    version: 229,
    popularity: 8,
    codepoint: 63482,
    categories: ["Hardware"],
    tags: [
      "analog",
      "call",
      "contact",
      "desk",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  desktop_access_disabled: {
    name: "desktop_access_disabled",
    version: 11,
    popularity: 2188,
    codepoint: 59805,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "access",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "slash",
      "web",
      "window",
    ],
  },
  desktop_mac: {
    name: "desktop_mac",
    version: 17,
    popularity: 5764,
    codepoint: 58123,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
    ],
  },
  desktop_windows: {
    name: "desktop_windows",
    version: 13,
    popularity: 26590,
    codepoint: 58124,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "television",
      "tv",
      "web",
      "window",
      "windows",
    ],
  },
  destruction: {
    name: "destruction",
    version: 229,
    popularity: 8,
    codepoint: 62853,
    categories: ["Social"],
    tags: ["attack", "damage", "destroy", "explode", "explosion", "threat"],
  },
  details: {
    name: "details",
    version: 16,
    popularity: 5122,
    codepoint: 58312,
    categories: ["image"],
    tags: [
      "details",
      "edit",
      "editing",
      "enhance",
      "image",
      "photo",
      "photography",
      "sharpen",
      "triangle",
    ],
  },
  detection_and_zone: {
    name: "detection_and_zone",
    version: 229,
    popularity: 277,
    codepoint: 58015,
    categories: ["Home"],
    tags: [
      "activity",
      "detection",
      "home",
      "nest",
      "person",
      "security",
      "zone",
    ],
  },
  detector: {
    name: "detector",
    version: 229,
    popularity: 146,
    codepoint: 57986,
    categories: ["Home"],
    tags: ["co", "detector", "home", "nest", "protect", "safety", "smoke"],
  },
  detector_alarm: {
    name: "detector_alarm",
    version: 229,
    popularity: 239,
    codepoint: 57847,
    categories: ["Home"],
    tags: [
      "alarm",
      "co",
      "detector",
      "home",
      "nest",
      "protect",
      "safety",
      "smoke",
    ],
  },
  detector_battery: {
    name: "detector_battery",
    version: 229,
    popularity: 118,
    codepoint: 57860,
    categories: ["Home"],
    tags: ["battery", "co", "detector", "home", "nest", "safety", "smoke"],
  },
  detector_co: {
    name: "detector_co",
    version: 229,
    popularity: 122,
    codepoint: 58031,
    categories: ["Home"],
    tags: ["alert", "co", "detector", "home", "nest", "safety", "smoke"],
  },
  detector_offline: {
    name: "detector_offline",
    version: 229,
    popularity: 95,
    codepoint: 57891,
    categories: ["Home"],
    tags: ["detector", "home", "nest", "offline", "safety", "smoke"],
  },
  detector_smoke: {
    name: "detector_smoke",
    version: 229,
    popularity: 382,
    codepoint: 57989,
    categories: ["Household"],
    tags: [
      "alarm",
      "detector",
      "device",
      "fire",
      "home",
      "house",
      "nest",
      "smoke",
    ],
  },
  detector_status: {
    name: "detector_status",
    version: 229,
    popularity: 164,
    codepoint: 57832,
    categories: ["Home"],
    tags: ["detector", "home", "nest", "safety", "smoke", "status"],
  },
  developer_board: {
    name: "developer_board",
    version: 13,
    popularity: 9603,
    codepoint: 58125,
    categories: ["hardware"],
    tags: [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "hardware",
      "microchip",
      "processor",
    ],
  },
  developer_board_off: {
    name: "developer_board_off",
    version: 4,
    popularity: 1059,
    codepoint: 58623,
    categories: ["hardware"],
    tags: [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "disabled",
      "enabled",
      "hardware",
      "microchip",
      "off",
      "on",
      "processor",
      "slash",
    ],
  },
  developer_guide: {
    name: "developer_guide",
    version: 229,
    popularity: 169,
    codepoint: 59806,
    categories: ["Common actions"],
    tags: [
      "agreement",
      "booklet",
      "bookmark",
      "certificate",
      "code",
      "coder",
      "contract",
      "developer",
      "doc",
      "document",
      "guide",
      "guideline",
      "instruction",
      "manual",
      "rules",
      "software",
    ],
  },
  developer_mode: {
    name: "developer_mode",
    version: 12,
    popularity: 6313,
    codepoint: 57776,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "bracket",
      "cell",
      "code",
      "developer",
      "development",
      "device",
      "engineer",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "phone",
      "tablet",
    ],
  },
  developer_mode_tv: {
    name: "developer_mode_tv",
    version: 229,
    popularity: 42,
    codepoint: 59508,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "brackets",
      "chrome",
      "code",
      "coding",
      "desktop",
      "developer",
      "device",
      "hardware",
      "iOS",
      "mac",
      "mode",
      "monitor",
      "tv",
      "web",
      "window",
    ],
  },
  device_hub: {
    name: "device_hub",
    version: 12,
    popularity: 7548,
    codepoint: 58165,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "circle",
      "computer",
      "desktop",
      "device",
      "hardware",
      "hub",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "square",
      "tablet",
      "triangle",
      "watch",
      "wearable",
      "web",
    ],
  },
  device_reset: {
    name: "device_reset",
    version: 229,
    popularity: 966,
    codepoint: 57998,
    categories: ["Home"],
    tags: ["clock", "device", "home", "nest", "reset"],
  },
  device_thermostat: {
    name: "device_thermostat",
    version: 11,
    popularity: 7121,
    codepoint: 57855,
    categories: ["device"],
    tags: [
      "celsius",
      "device",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
    ],
  },
  device_unknown: {
    name: "device_unknown",
    version: 12,
    popularity: 2891,
    codepoint: 58169,
    categories: ["hardware"],
    tags: [
      "?",
      "Android",
      "OS",
      "assistance",
      "cell",
      "device",
      "hardware",
      "help",
      "iOS",
      "info",
      "information",
      "mobile",
      "phone",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "tablet",
      "unknown",
    ],
  },
  devices: {
    name: "devices",
    version: 17,
    popularity: 30757,
    codepoint: 57777,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "tablet",
      "watch",
      "wearable",
      "web",
    ],
  },
  devices_fold: {
    name: "devices_fold",
    version: 1,
    popularity: 510,
    codepoint: 60382,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "cell",
      "dash",
      "dashed",
      "device",
      "fold",
      "foldable",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet",
    ],
  },
  devices_off: {
    name: "devices_off",
    version: 229,
    popularity: 23,
    codepoint: 63397,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "computer",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "off",
      "offline",
      "on",
      "phone",
      "slash",
      "tablet",
      "watch",
      "wearable",
      "web",
    ],
  },
  devices_other: {
    name: "devices_other",
    version: 12,
    popularity: 6036,
    codepoint: 58167,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "ar",
      "cell",
      "chrome",
      "desktop",
      "device",
      "gadget",
      "hardware",
      "iOS",
      "ipad",
      "mac",
      "mobile",
      "monitor",
      "other",
      "phone",
      "tablet",
      "vr",
      "watch",
      "wearables",
      "window",
    ],
  },
  devices_wearables: {
    name: "devices_wearables",
    version: 229,
    popularity: 65,
    codepoint: 63147,
    categories: ["Hardware"],
    tags: [
      "ar",
      "call",
      "cell",
      "chat",
      "clock",
      "device",
      "gadget",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "smartphone",
      "tablet",
      "text",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  dew_point: {
    name: "dew_point",
    version: 1,
    popularity: 860,
    codepoint: 63609,
    categories: ["social"],
    tags: [
      "atmospheric",
      "condense",
      "dew",
      "droplets",
      "point",
      "temperature",
      "thermometer",
      "water",
    ],
  },
  diagnosis: {
    name: "diagnosis",
    version: 229,
    popularity: 30,
    codepoint: 57512,
    categories: ["Text Formatting"],
    tags: [
      "data",
      "doc",
      "document",
      "file",
      "health",
      "heart",
      "letter",
      "note",
      "page",
      "paper",
      "writing",
    ],
  },
  dialer_sip: {
    name: "dialer_sip",
    version: 12,
    popularity: 1792,
    codepoint: 57531,
    categories: ["communication"],
    tags: [
      "alphabet",
      "call",
      "cell",
      "character",
      "contact",
      "device",
      "dialer",
      "font",
      "hardware",
      "initiation",
      "internet",
      "letter",
      "mobile",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "telephone",
      "text",
      "type",
      "voice",
    ],
  },
  dialogs: {
    name: "dialogs",
    version: 229,
    popularity: 33,
    codepoint: 59807,
    categories: ["UI actions"],
    tags: [
      "action",
      "app",
      "application",
      "components",
      "design",
      "dialogs",
      "information",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "squares",
      "tablet",
      "tasks",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  dialpad: {
    name: "dialpad",
    version: 12,
    popularity: 9100,
    codepoint: 57532,
    categories: ["communication"],
    tags: [
      "buttons",
      "call",
      "contact",
      "device",
      "dial",
      "dialpad",
      "dots",
      "mobile",
      "numbers",
      "pad",
      "phone",
    ],
  },
  diamond: {
    name: "diamond",
    version: 2,
    popularity: 11053,
    codepoint: 60117,
    categories: ["maps"],
    tags: [
      "diamond",
      "fashion",
      "gems",
      "jewelry",
      "logo",
      "retail",
      "valuable",
      "valuables",
    ],
  },
  dictionary: {
    name: "dictionary",
    version: 229,
    popularity: 26,
    codepoint: 62777,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "glossary",
      "lexicon",
      "library",
      "read",
      "reading",
      "textbook",
      "thesaurus",
      "vocabulary",
    ],
  },
  difference: {
    name: "difference",
    version: 1,
    popularity: 3371,
    codepoint: 60285,
    categories: ["file"],
    tags: [
      "compare",
      "content",
      "copy",
      "cut",
      "diff",
      "difference",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack",
    ],
  },
  digital_out_of_home: {
    name: "digital_out_of_home",
    version: 229,
    popularity: 276,
    codepoint: 61918,
    categories: ["Audio&Video"],
    tags: [],
  },
  digital_wellbeing: {
    name: "digital_wellbeing",
    version: 229,
    popularity: 126,
    codepoint: 61318,
    categories: ["Social"],
    tags: [
      "app",
      "application",
      "digital",
      "healthy",
      "heart",
      "person",
      "wellbeing",
    ],
  },
  dining: {
    name: "dining",
    version: 9,
    popularity: 4664,
    codepoint: 61428,
    categories: ["search"],
    tags: [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon",
    ],
  },
  dinner_dining: {
    name: "dinner_dining",
    version: 10,
    popularity: 5988,
    codepoint: 59991,
    categories: ["maps"],
    tags: [
      "breakfast",
      "dining",
      "dinner",
      "food",
      "fork",
      "lunch",
      "meal",
      "restaurant",
      "spaghetti",
      "utensils",
    ],
  },
  directions: {
    name: "directions",
    version: 14,
    popularity: 10528,
    codepoint: 58670,
    categories: ["maps"],
    tags: ["arrow", "directions", "maps", "right", "route", "sign", "traffic"],
  },
  directions_alt: {
    name: "directions_alt",
    version: 229,
    popularity: 225,
    codepoint: 63616,
    categories: ["Maps"],
    tags: ["arrow", "directions", "maps", "right", "route", "sign", "traffic"],
  },
  directions_alt_off: {
    name: "directions_alt_off",
    version: 229,
    popularity: 41,
    codepoint: 63617,
    categories: ["Maps"],
    tags: [
      "arrow",
      "directions",
      "disabled",
      "enabled",
      "maps",
      "off",
      "on",
      "right",
      "route",
      "sign",
      "slash",
      "traffic",
    ],
  },
  directions_bike: {
    name: "directions_bike",
    version: 13,
    popularity: 14395,
    codepoint: 58671,
    categories: ["maps"],
    tags: [
      "bicycle",
      "bike",
      "direction",
      "directions",
      "human",
      "maps",
      "person",
      "public",
      "route",
      "transportation",
    ],
  },
  directions_boat: {
    name: "directions_boat",
    version: 17,
    popularity: 9240,
    codepoint: 58674,
    categories: ["maps"],
    tags: [
      "automobile",
      "boat",
      "car",
      "cars",
      "direction",
      "directions",
      "ferry",
      "maps",
      "public",
      "transportation",
      "vehicle",
    ],
  },
  directions_bus: {
    name: "directions_bus",
    version: 12,
    popularity: 15258,
    codepoint: 58672,
    categories: ["maps"],
    tags: [
      "automobile",
      "bus",
      "car",
      "cars",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle",
    ],
  },
  directions_car: {
    name: "directions_car",
    version: 18,
    popularity: 38188,
    codepoint: 58673,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle",
    ],
  },
  directions_off: {
    name: "directions_off",
    version: 14,
    popularity: 940,
    codepoint: 61711,
    categories: ["notification"],
    tags: [
      "arrow",
      "directions",
      "disabled",
      "enabled",
      "maps",
      "off",
      "on",
      "right",
      "route",
      "sign",
      "slash",
      "traffic",
    ],
  },
  directions_railway: {
    name: "directions_railway",
    version: 13,
    popularity: 1605,
    codepoint: 58676,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "railway",
      "train",
      "transportation",
      "vehicle",
    ],
  },
  directions_run: {
    name: "directions_run",
    version: 13,
    popularity: 24039,
    codepoint: 58726,
    categories: ["maps"],
    tags: [
      "body",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "running",
      "walk",
    ],
  },
  directions_subway: {
    name: "directions_subway",
    version: 12,
    popularity: 1835,
    codepoint: 58675,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle",
    ],
  },
  directions_walk: {
    name: "directions_walk",
    version: 13,
    popularity: 19420,
    codepoint: 58678,
    categories: ["maps"],
    tags: [
      "body",
      "direction",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "walk",
    ],
  },
  directory_sync: {
    name: "directory_sync",
    version: 229,
    popularity: 88,
    codepoint: 58260,
    categories: ["UI actions"],
    tags: [
      "around",
      "arrows",
      "direction",
      "directory",
      "navigation",
      "rotate",
      "sync",
    ],
  },
  dirty_lens: {
    name: "dirty_lens",
    version: 15,
    popularity: 1016,
    codepoint: 61259,
    categories: ["image"],
    tags: [
      "camera",
      "dirty",
      "lens",
      "photo",
      "photography",
      "picture",
      "splat",
    ],
  },
  disabled_by_default: {
    name: "disabled_by_default",
    version: 6,
    popularity: 18840,
    codepoint: 62000,
    categories: ["action"],
    tags: [
      "box",
      "by",
      "cancel",
      "clear",
      "close",
      "default",
      "disabled",
      "exit",
      "no",
      "quit",
      "remove",
      "square",
      "stop",
      "x",
    ],
  },
  disabled_visible: {
    name: "disabled_visible",
    version: 3,
    popularity: 4986,
    codepoint: 59246,
    categories: ["action"],
    tags: [
      "cancel",
      "close",
      "disabled",
      "exit",
      "eye",
      "no",
      "on",
      "quit",
      "remove",
      "reveal",
      "see",
      "show",
      "stop",
      "view",
      "visibility",
      "visible",
    ],
  },
  disc_full: {
    name: "disc_full",
    version: 12,
    popularity: 1754,
    codepoint: 58896,
    categories: ["notification"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "cd",
      "danger",
      "disc",
      "error",
      "exclamation",
      "full",
      "important",
      "mark",
      "music",
      "notification",
      "storage",
      "symbol",
      "warning",
    ],
  },
  discover_tune: {
    name: "discover_tune",
    version: 229,
    popularity: 1328,
    codepoint: 57368,
    categories: ["Audio&Video"],
    tags: [
      "adjust",
      "discover",
      "edit",
      "editing",
      "music",
      "options",
      "setting",
      "settings",
      "tune",
    ],
  },
  dishwasher: {
    name: "dishwasher",
    version: 229,
    popularity: 16,
    codepoint: 59808,
    categories: ["Household"],
    tags: [
      "appliance",
      "clean",
      "cleaning",
      "dish",
      "dishwasher",
      "drop",
      "home",
      "house",
      "kitchen",
      "machine",
      "washer",
      "water",
    ],
  },
  dishwasher_gen: {
    name: "dishwasher_gen",
    version: 229,
    popularity: 409,
    codepoint: 59442,
    categories: ["Household"],
    tags: [
      "appliance",
      "clean",
      "cook",
      "cooking",
      "dishes",
      "dishwasher",
      "electric",
      "home",
      "house",
      "kitchen",
      "machine",
      "nest",
    ],
  },
  display_external_input: {
    name: "display_external_input",
    version: 229,
    popularity: 14,
    codepoint: 63463,
    categories: ["Android"],
    tags: [
      "arrow",
      "frame",
      "layout",
      "link",
      "move",
      "out",
      "output",
      "right",
      "screen",
      "window",
    ],
  },
  display_settings: {
    name: "display_settings",
    version: 1,
    popularity: 4775,
    codepoint: 60311,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "application",
      "change",
      "chrome",
      "desktop",
      "details",
      "device",
      "display",
      "gear",
      "hardware",
      "iOS",
      "info",
      "information",
      "mac",
      "monitor",
      "options",
      "personal",
      "screen",
      "service",
      "settings",
      "web",
      "window",
    ],
  },
  distance: {
    name: "distance",
    version: 229,
    popularity: 41,
    codepoint: 63210,
    categories: ["Activities"],
    tags: [
      "destination",
      "direction",
      "drop",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop",
    ],
  },
  diversity_1: {
    name: "diversity_1",
    version: 1,
    popularity: 4600,
    codepoint: 63703,
    categories: ["social"],
    tags: [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team",
    ],
  },
  diversity_2: {
    name: "diversity_2",
    version: 1,
    popularity: 3097,
    codepoint: 63704,
    categories: ["social"],
    tags: [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team",
    ],
  },
  diversity_3: {
    name: "diversity_3",
    version: 1,
    popularity: 6738,
    codepoint: 63705,
    categories: ["social"],
    tags: [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team",
    ],
  },
  diversity_4: {
    name: "diversity_4",
    version: 229,
    popularity: 162,
    codepoint: 63575,
    categories: ["Social"],
    tags: [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team",
    ],
  },
  dns: {
    name: "dns",
    version: 12,
    popularity: 31193,
    codepoint: 59509,
    categories: ["action"],
    tags: [
      "address",
      "bars",
      "dns",
      "domain",
      "information",
      "ip",
      "list",
      "lookup",
      "name",
      "server",
      "system",
    ],
  },
  do_not_disturb_off: {
    name: "do_not_disturb_off",
    version: 11,
    popularity: 1511,
    codepoint: 58947,
    categories: ["notification"],
    tags: [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop",
    ],
  },
  do_not_disturb_on: {
    name: "do_not_disturb_on",
    version: 11,
    popularity: 11412,
    codepoint: 58948,
    categories: ["notification"],
    tags: [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop",
    ],
  },
  do_not_disturb_on_total_silence: {
    name: "do_not_disturb_on_total_silence",
    version: 15,
    popularity: 1700,
    codepoint: 61435,
    categories: ["device"],
    tags: [
      "busy",
      "disturb",
      "do",
      "mute",
      "no",
      "not",
      "on total",
      "quiet",
      "silence",
    ],
  },
  do_not_step: {
    name: "do_not_step",
    version: 8,
    popularity: 1607,
    codepoint: 61855,
    categories: ["places"],
    tags: [
      "boot",
      "disabled",
      "do",
      "enabled",
      "feet",
      "foot",
      "not",
      "off",
      "on",
      "shoe",
      "slash",
      "sneaker",
      "step",
      "steps",
    ],
  },
  do_not_touch: {
    name: "do_not_touch",
    version: 8,
    popularity: 2363,
    codepoint: 61872,
    categories: ["places"],
    tags: [
      "disabled",
      "do",
      "enabled",
      "fingers",
      "gesture",
      "hand",
      "not",
      "off",
      "on",
      "slash",
      "touch",
    ],
  },
  dock: {
    name: "dock",
    version: 12,
    popularity: 1334,
    codepoint: 58126,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "cell",
      "charging",
      "connector",
      "device",
      "dock",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "power",
      "station",
      "tablet",
    ],
  },
  dock_to_bottom: {
    name: "dock_to_bottom",
    version: 229,
    popularity: 7,
    codepoint: 63462,
    categories: ["Android"],
    tags: ["base", "layout", "panel", "panels", "snap", "window", "workflow"],
  },
  dock_to_left: {
    name: "dock_to_left",
    version: 229,
    popularity: 79,
    codepoint: 63461,
    categories: ["Android"],
    tags: [
      "layout",
      "panel",
      "panels",
      "side",
      "sidebar",
      "snap",
      "window",
      "workflow",
    ],
  },
  dock_to_right: {
    name: "dock_to_right",
    version: 229,
    popularity: 25,
    codepoint: 63460,
    categories: ["Android"],
    tags: [
      "layout",
      "panel",
      "panels",
      "side",
      "sidebar",
      "snap",
      "window",
      "workflow",
    ],
  },
  docs_add_on: {
    name: "docs_add_on",
    version: 229,
    popularity: 684,
    codepoint: 61634,
    categories: ["Text Formatting"],
    tags: [],
  },
  docs_apps_script: {
    name: "docs_apps_script",
    version: 229,
    popularity: 141,
    codepoint: 61635,
    categories: ["Text Formatting"],
    tags: [],
  },
  document_scanner: {
    name: "document_scanner",
    version: 3,
    popularity: 11373,
    codepoint: 58874,
    categories: ["communication"],
    tags: [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  domain: {
    name: "domain",
    version: 16,
    popularity: 14628,
    codepoint: 59374,
    categories: ["social"],
    tags: [
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "web",
      "www",
    ],
  },
  domain_add: {
    name: "domain_add",
    version: 1,
    popularity: 2958,
    codepoint: 60258,
    categories: ["social"],
    tags: [
      "+",
      "add",
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "new",
      "place",
      "plus",
      "real",
      "residence",
      "residential",
      "shelter",
      "symbol",
      "web",
      "www",
    ],
  },
  domain_disabled: {
    name: "domain_disabled",
    version: 12,
    popularity: 2355,
    codepoint: 57583,
    categories: ["communication"],
    tags: [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "disabled",
      "domain",
      "enabled",
      "estate",
      "home",
      "internet",
      "maps",
      "off",
      "office",
      "offline",
      "on",
      "place",
      "real",
      "residence",
      "residential",
      "slash",
      "web",
      "website",
    ],
  },
  domain_verification: {
    name: "domain_verification",
    version: 11,
    popularity: 4937,
    codepoint: 61260,
    categories: ["communication"],
    tags: [
      "app",
      "application desktop",
      "approve",
      "check",
      "complete",
      "design",
      "domain",
      "done",
      "interface",
      "internet",
      "layout",
      "mark",
      "ok",
      "screen",
      "select",
      "site",
      "tick",
      "ui",
      "ux",
      "validate",
      "verification",
      "verified",
      "web",
      "website",
      "window",
      "www",
      "yes",
    ],
  },
  domain_verification_off: {
    name: "domain_verification_off",
    version: 229,
    popularity: 3,
    codepoint: 63408,
    categories: ["Common actions"],
    tags: [
      "app",
      "application desktop",
      "approve",
      "check",
      "complete",
      "design",
      "disabled",
      "domain",
      "done",
      "enabled",
      "interface",
      "internet",
      "layout",
      "mark",
      "off",
      "offline",
      "ok",
      "on",
      "screen",
      "select",
      "site",
      "slash",
      "tick",
      "ui",
      "ux",
      "validate",
      "verification",
      "verified",
      "web",
      "website",
      "window",
      "www",
      "yes",
    ],
  },
  domino_mask: {
    name: "domino_mask",
    version: 229,
    popularity: 19,
    codepoint: 62948,
    categories: ["Social"],
    tags: ["carnival", "costume", "eye mask", "google play", "superhero"],
  },
  done: {
    name: "done",
    version: 19,
    popularity: 484295,
    codepoint: 59510,
    categories: ["action"],
    tags: [
      "DISABLE_IOS",
      "approve",
      "check",
      "complete",
      "disable_ios",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  done_all: {
    name: "done_all",
    version: 12,
    popularity: 49662,
    codepoint: 59511,
    categories: ["action"],
    tags: [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "layers",
      "mark",
      "multiple",
      "ok",
      "select",
      "stack",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  done_outline: {
    name: "done_outline",
    version: 12,
    popularity: 35653,
    codepoint: 59695,
    categories: ["action"],
    tags: [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  donut_large: {
    name: "donut_large",
    version: 13,
    popularity: 12312,
    codepoint: 59671,
    categories: ["action"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "large",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking",
    ],
  },
  donut_small: {
    name: "donut_small",
    version: 12,
    popularity: 8442,
    codepoint: 59672,
    categories: ["action"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "measure",
      "metrics",
      "pie",
      "small",
      "statistics",
      "tracking",
    ],
  },
  door_back: {
    name: "door_back",
    version: 10,
    popularity: 3191,
    codepoint: 61436,
    categories: ["search"],
    tags: [
      "back",
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "home",
      "house",
      "way",
    ],
  },
  door_front: {
    name: "door_front",
    version: 10,
    popularity: 5856,
    codepoint: 61437,
    categories: ["search"],
    tags: [
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "front",
      "home",
      "house",
      "way",
    ],
  },
  door_open: {
    name: "door_open",
    version: 229,
    popularity: 1248,
    codepoint: 59260,
    categories: ["Home"],
    tags: ["clock", "device", "home", "nest", "open"],
  },
  door_sensor: {
    name: "door_sensor",
    version: 229,
    popularity: 144,
    codepoint: 57994,
    categories: ["Home"],
    tags: ["door", "handle", "home", "nest", "security", "sensor"],
  },
  door_sliding: {
    name: "door_sliding",
    version: 10,
    popularity: 3266,
    codepoint: 61438,
    categories: ["search"],
    tags: [
      "auto",
      "automatic",
      "door",
      "doorway",
      "double",
      "entrance",
      "exit",
      "glass",
      "home",
      "house",
      "sliding",
      "two",
    ],
  },
  doorbell: {
    name: "doorbell",
    version: 10,
    popularity: 2990,
    codepoint: 61439,
    categories: ["search"],
    tags: ["alarm", "bell", "door", "doorbell", "home", "house", "ringing"],
  },
  doorbell_3p: {
    name: "doorbell_3p",
    version: 229,
    popularity: 160,
    codepoint: 57831,
    categories: ["Household"],
    tags: [
      "active",
      "alarm",
      "alert",
      "chime",
      "doorbell",
      "nest",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
    ],
  },
  doorbell_chime: {
    name: "doorbell_chime",
    version: 229,
    popularity: 127,
    codepoint: 57843,
    categories: ["Home"],
    tags: ["chime", "doorbell", "home", "nest", "security"],
  },
  double_arrow: {
    name: "double_arrow",
    version: 11,
    popularity: 31970,
    codepoint: 59984,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right",
    ],
  },
  downhill_skiing: {
    name: "downhill_skiing",
    version: 4,
    popularity: 3416,
    codepoint: 58633,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "downhill",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "ski social",
      "skiing",
      "snow",
      "sports",
      "travel",
      "winter",
    ],
  },
  download: {
    name: "download",
    version: 10,
    popularity: 70659,
    codepoint: 61584,
    categories: ["file"],
    tags: [
      "arrow",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "upload",
    ],
  },
  download_2: {
    name: "download_2",
    version: 229,
    popularity: 0,
    codepoint: 62755,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "upload",
    ],
  },
  download_done: {
    name: "download_done",
    version: 10,
    popularity: 9113,
    codepoint: 61585,
    categories: ["file"],
    tags: [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "installed",
      "ok",
      "tick",
      "upload",
    ],
  },
  download_for_offline: {
    name: "download_for_offline",
    version: 9,
    popularity: 17145,
    codepoint: 61440,
    categories: ["file"],
    tags: [
      "arrow",
      "circle",
      "down",
      "download",
      "for offline",
      "install",
      "upload",
    ],
  },
  downloading: {
    name: "downloading",
    version: 9,
    popularity: 13073,
    codepoint: 61441,
    categories: ["file"],
    tags: [
      "arrow",
      "circle",
      "down",
      "download",
      "downloading",
      "downloads",
      "install",
      "pending",
      "progress",
      "upload",
    ],
  },
  draft: {
    name: "draft",
    version: 229,
    popularity: 5934,
    codepoint: 58989,
    categories: ["Text Formatting"],
    tags: [
      "data",
      "doc",
      "document",
      "draft",
      "drive",
      "file",
      "folder",
      "folders",
      "sheet",
      "slide",
      "storage",
    ],
  },
  draft_orders: {
    name: "draft_orders",
    version: 229,
    popularity: 32,
    codepoint: 59315,
    categories: ["Common actions"],
    tags: [
      "compose",
      "create",
      "document",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  drafts: {
    name: "drafts",
    version: 17,
    popularity: 13529,
    codepoint: 57681,
    categories: ["content"],
    tags: [
      "document",
      "draft",
      "drafts",
      "email",
      "file",
      "letter",
      "mail",
      "message",
      "read",
    ],
  },
  drag_click: {
    name: "drag_click",
    version: 229,
    popularity: 18,
    codepoint: 63263,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "circles",
      "clicks",
      "mouse",
      "move",
      "ripples",
      "select",
      "selection",
      "selects",
    ],
  },
  drag_handle: {
    name: "drag_handle",
    version: 14,
    popularity: 25985,
    codepoint: 57949,
    categories: ["editor"],
    tags: [
      "app",
      "application ui",
      "components",
      "design",
      "drag",
      "handle",
      "interface",
      "layout",
      "menu",
      "move",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  drag_indicator: {
    name: "drag_indicator",
    version: 15,
    popularity: 40901,
    codepoint: 59717,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "circles",
      "components",
      "design",
      "dots",
      "drag",
      "drop",
      "indicator",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "move",
      "phone",
      "screen",
      "shape",
      "shift",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  drag_pan: {
    name: "drag_pan",
    version: 229,
    popularity: 34,
    codepoint: 63262,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "clicks",
      "direction",
      "expand",
      "mouse",
      "move",
      "open",
      "select",
      "selection",
      "selects",
      "with",
    ],
  },
  draw: {
    name: "draw",
    version: 6,
    popularity: 12071,
    codepoint: 59206,
    categories: ["editor"],
    tags: [
      "compose",
      "create",
      "design",
      "draft",
      "draw",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  draw_abstract: {
    name: "draw_abstract",
    version: 229,
    popularity: 37,
    codepoint: 63480,
    categories: ["Text Formatting"],
    tags: [
      "abstraction",
      "compose",
      "craft",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "gesture",
      "input",
      "shape",
      "shapes",
      "squiggle",
    ],
  },
  draw_collage: {
    name: "draw_collage",
    version: 229,
    popularity: 9,
    codepoint: 63479,
    categories: ["Text Formatting"],
    tags: [
      "abstraction",
      "compose",
      "craft",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "gesture",
      "input",
      "shape",
      "shapes",
      "squiggle",
    ],
  },
  dresser: {
    name: "dresser",
    version: 229,
    popularity: 339,
    codepoint: 57872,
    categories: ["Household"],
    tags: ["dresser", "furniture", "home", "hotel", "house", "mirror", "nest"],
  },
  drive_file_move: {
    name: "drive_file_move",
    version: 19,
    popularity: 7881,
    codepoint: 58997,
    categories: ["file"],
    tags: [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "move",
      "right",
      "sheet",
      "slide",
      "storage",
    ],
  },
  drive_folder_upload: {
    name: "drive_folder_upload",
    version: 11,
    popularity: 7183,
    codepoint: 59811,
    categories: ["file"],
    tags: [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "up",
      "upload",
    ],
  },
  dropdown: {
    name: "dropdown",
    version: 229,
    popularity: 24,
    codepoint: 59812,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "dropdown",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "squares",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  dry: {
    name: "dry",
    version: 8,
    popularity: 1501,
    codepoint: 61875,
    categories: ["places"],
    tags: [
      "air",
      "bathroom",
      "dry",
      "dryer",
      "fingers",
      "gesture",
      "hand",
      "wc",
    ],
  },
  dry_cleaning: {
    name: "dry_cleaning",
    version: 10,
    popularity: 3637,
    codepoint: 59992,
    categories: ["maps"],
    tags: [
      "cleaning",
      "dry",
      "hanger",
      "hotel",
      "laundry",
      "places",
      "service",
      "towel",
    ],
  },
  dual_screen: {
    name: "dual_screen",
    version: 229,
    popularity: 76,
    codepoint: 63183,
    categories: ["Android"],
    tags: [
      "android",
      "device",
      "duo",
      "foldable",
      "folding",
      "phone",
      "screens",
    ],
  },
  duo: {
    name: "duo",
    version: 12,
    popularity: 3519,
    codepoint: 59813,
    categories: ["communication"],
    tags: ["call", "chat", "conference", "device", "duo", "video"],
  },
  dvr: {
    name: "dvr",
    version: 13,
    popularity: 11433,
    codepoint: 57778,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "audio",
      "chrome",
      "computer",
      "desktop",
      "device",
      "display",
      "dvr",
      "electronic",
      "hardware",
      "iOS",
      "list",
      "mac",
      "monitor",
      "record",
      "recorder",
      "screen",
      "tv",
      "video",
      "web",
      "window",
    ],
  },
  dynamic_feed: {
    name: "dynamic_feed",
    version: 15,
    popularity: 8790,
    codepoint: 59924,
    categories: ["content"],
    tags: [
      "'mail_outline'",
      "'markunread'. Keep 'mail' and remove others.",
      "Duplicate of 'email'",
    ],
  },
  dynamic_form: {
    name: "dynamic_form",
    version: 7,
    popularity: 6852,
    codepoint: 61887,
    categories: ["action"],
    tags: [
      "bolt",
      "code",
      "dynamic",
      "electric",
      "fast",
      "form",
      "lightning",
      "lists",
      "questionnaire",
      "thunderbolt",
    ],
  },
  e911_avatar: {
    name: "e911_avatar",
    version: 229,
    popularity: 220,
    codepoint: 61722,
    categories: ["Communication"],
    tags: [],
  },
  e911_emergency: {
    name: "e911_emergency",
    version: 229,
    popularity: 1050,
    codepoint: 61721,
    categories: ["Privacy&Security"],
    tags: [],
  },
  e_mobiledata: {
    name: "e_mobiledata",
    version: 9,
    popularity: 702,
    codepoint: 61442,
    categories: ["device"],
    tags: [
      "alphabet",
      "data",
      "e",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "text",
      "type",
    ],
  },
  e_mobiledata_badge: {
    name: "e_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63459,
    categories: ["Android"],
    tags: [
      "alphabet",
      "data",
      "e",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "text",
      "type",
    ],
  },
  earbuds: {
    name: "earbuds",
    version: 9,
    popularity: 1962,
    codepoint: 61443,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound",
    ],
  },
  earbuds_battery: {
    name: "earbuds_battery",
    version: 9,
    popularity: 1229,
    codepoint: 61444,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "battery",
      "charging",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound",
    ],
  },
  early_on: {
    name: "early_on",
    version: 229,
    popularity: 233,
    codepoint: 58042,
    categories: ["Home"],
    tags: ["calendar", "climate", "early", "home", "nest", "on"],
  },
  earthquake: {
    name: "earthquake",
    version: 229,
    popularity: 16,
    codepoint: 63055,
    categories: ["Social"],
    tags: [
      "aftershock",
      "chart",
      "disaster",
      "measure",
      "monitor",
      "shake",
      "tremor",
    ],
  },
  east: {
    name: "east",
    version: 7,
    popularity: 60883,
    codepoint: 61919,
    categories: ["navigation"],
    tags: ["arrow", "directional", "east", "maps", "navigation", "right"],
  },
  ecg: {
    name: "ecg",
    version: 229,
    popularity: 18,
    codepoint: 63503,
    categories: ["Hardware"],
    tags: [
      "cardio",
      "doc",
      "doctor",
      "electrocardiogram",
      "health",
      "heart",
      "medical",
      "monitor",
      "nurse",
      "rate",
    ],
  },
  ecg_heart: {
    name: "ecg_heart",
    version: 229,
    popularity: 89,
    codepoint: 63209,
    categories: ["Activities"],
    tags: [
      "cardio",
      "doc",
      "doctor",
      "electrocardiogram",
      "fitbit",
      "health",
      "heart",
      "heart rhythm",
      "medical",
      "monitor",
      "nurse",
      "rate",
    ],
  },
  eco: {
    name: "eco",
    version: 229,
    popularity: 6657,
    codepoint: 59957,
    categories: ["Social"],
    tags: ["eco", "economical", "green", "leaf", "nature", "sustainable"],
  },
  eda: {
    name: "eda",
    version: 229,
    popularity: 18,
    codepoint: 63208,
    categories: ["Activities"],
    tags: [
      "back",
      "electrodermal",
      "electrodermal activity responses",
      "electrodermal responses",
      "fingers",
      "fitbit",
      "gesture",
      "hand",
      "heart rate",
      "palm",
      "raised",
    ],
  },
  edgesensor_high: {
    name: "edgesensor_high",
    version: 10,
    popularity: 1358,
    codepoint: 61445,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "edge",
      "hardware",
      "high",
      "iOS",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate",
    ],
  },
  edgesensor_low: {
    name: "edgesensor_low",
    version: 10,
    popularity: 1067,
    codepoint: 61446,
    categories: ["device"],
    tags: [
      "Android",
      "cell",
      "device",
      "edge",
      "hardware",
      "iOS",
      "low",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate",
    ],
  },
  edit: {
    name: "edit",
    version: 12,
    popularity: 287984,
    codepoint: 58313,
    categories: ["image"],
    tags: [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  edit_attributes: {
    name: "edit_attributes",
    version: 12,
    popularity: 3580,
    codepoint: 58744,
    categories: ["maps"],
    tags: [
      "approve",
      "attribution",
      "check",
      "complete",
      "done",
      "edit",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  edit_calendar: {
    name: "edit_calendar",
    version: 5,
    popularity: 28166,
    codepoint: 59202,
    categories: ["action"],
    tags: [
      "calendar",
      "compose",
      "create",
      "date",
      "day",
      "draft",
      "edit",
      "editing",
      "event",
      "month",
      "pen",
      "pencil",
      "schedule",
      "write",
      "writing",
    ],
  },
  edit_document: {
    name: "edit_document",
    version: 1,
    popularity: 5882,
    codepoint: 63628,
    categories: ["action"],
    tags: [
      "compose",
      "create",
      "doc",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "folder",
      "folders",
      "input",
      "page",
      "paper",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  edit_location: {
    name: "edit_location",
    version: 16,
    popularity: 3957,
    codepoint: 58728,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "edit",
      "location",
      "maps",
      "pen",
      "pencil",
      "pin",
      "place",
      "stop",
    ],
  },
  edit_location_alt: {
    name: "edit_location_alt",
    version: 8,
    popularity: 3558,
    codepoint: 57797,
    categories: ["maps"],
    tags: ["alt", "edit", "location", "pen", "pencil", "pin"],
  },
  edit_note: {
    name: "edit_note",
    version: 5,
    popularity: 53389,
    codepoint: 59205,
    categories: ["editor"],
    tags: [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "lines",
      "note",
      "pen",
      "pencil",
      "text",
      "write",
      "writing",
    ],
  },
  edit_notifications: {
    name: "edit_notifications",
    version: 4,
    popularity: 4156,
    codepoint: 58661,
    categories: ["social"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "new",
      "notifications",
      "notify",
      "pen",
      "pencil",
      "reminder",
      "ring",
      "sound",
      "write",
      "writing",
    ],
  },
  edit_off: {
    name: "edit_off",
    version: 15,
    popularity: 7997,
    codepoint: 59728,
    categories: ["action"],
    tags: [
      "compose",
      "create",
      "disabled",
      "draft",
      "edit",
      "editing",
      "enabled",
      "input",
      "new",
      "off",
      "offline",
      "on",
      "pen",
      "pencil",
      "slash",
      "write",
      "writing",
    ],
  },
  edit_road: {
    name: "edit_road",
    version: 11,
    popularity: 3619,
    codepoint: 61261,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "edit",
      "highway",
      "maps",
      "pen",
      "pencil",
      "road",
      "street",
      "traffic",
    ],
  },
  edit_square: {
    name: "edit_square",
    version: 1,
    popularity: 4256,
    codepoint: 63629,
    categories: ["action"],
    tags: [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "square",
      "write",
      "writing",
    ],
  },
  editor_choice: {
    name: "editor_choice",
    version: 229,
    popularity: 3,
    codepoint: 62760,
    categories: ["Social"],
    tags: [
      "approve",
      "award",
      "badge",
      "champion",
      "check",
      "circle",
      "completed",
      "first",
      "increase",
      "mark",
      "ok",
      "plus",
      "prize",
      "reward",
      "ribbon",
      "select",
      "sport",
      "task",
      "tick",
      "trophy",
      "win",
      "winner",
      "yes",
    ],
  },
  egg: {
    name: "egg",
    version: 2,
    popularity: 2307,
    codepoint: 60108,
    categories: ["maps"],
    tags: ["breakfast", "brunch", "egg", "food"],
  },
  egg_alt: {
    name: "egg_alt",
    version: 2,
    popularity: 1286,
    codepoint: 60104,
    categories: ["maps"],
    tags: ["breakfast", "brunch", "egg", "food"],
  },
  eject: {
    name: "eject",
    version: 11,
    popularity: 3782,
    codepoint: 59643,
    categories: ["action"],
    tags: ["disc", "drive", "dvd", "eject", "remove", "triangle", "usb"],
  },
  elderly: {
    name: "elderly",
    version: 7,
    popularity: 5468,
    codepoint: 61978,
    categories: ["social"],
    tags: [
      "body",
      "cane",
      "elderly",
      "human",
      "old",
      "people",
      "person",
      "senior",
    ],
  },
  elderly_woman: {
    name: "elderly_woman",
    version: 1,
    popularity: 1847,
    codepoint: 60265,
    categories: ["social"],
    tags: [
      "body",
      "cane",
      "elderly",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "old",
      "people",
      "person",
      "senior",
      "social",
      "symbol",
      "woman",
      "women",
    ],
  },
  electric_bike: {
    name: "electric_bike",
    version: 14,
    popularity: 2532,
    codepoint: 60187,
    categories: ["maps"],
    tags: [
      "bike",
      "electric",
      "electricity",
      "maps",
      "scooter",
      "transportation",
      "travel",
      "vespa",
    ],
  },
  electric_bolt: {
    name: "electric_bolt",
    version: 1,
    popularity: 5251,
    codepoint: 60444,
    categories: ["home"],
    tags: [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "nest",
      "thunderbolt",
    ],
  },
  electric_car: {
    name: "electric_car",
    version: 14,
    popularity: 4356,
    codepoint: 60188,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "electric",
      "electricity",
      "maps",
      "transportation",
      "travel",
      "vehicle",
    ],
  },
  electric_meter: {
    name: "electric_meter",
    version: 1,
    popularity: 1693,
    codepoint: 60443,
    categories: ["home"],
    tags: [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "measure",
      "meter",
      "nest",
      "thunderbolt",
      "usage",
      "voltage",
      "volts",
    ],
  },
  electric_moped: {
    name: "electric_moped",
    version: 14,
    popularity: 1510,
    codepoint: 60189,
    categories: ["maps"],
    tags: [
      "automobile",
      "bike",
      "car",
      "cars",
      "electric",
      "maps",
      "moped",
      "scooter",
      "transportation",
      "travel",
      "vehicle",
      "vespa",
    ],
  },
  electric_rickshaw: {
    name: "electric_rickshaw",
    version: 15,
    popularity: 1721,
    codepoint: 60190,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "electric",
      "india",
      "maps",
      "rickshaw",
      "transportation",
      "truck",
      "vehicle",
    ],
  },
  electric_scooter: {
    name: "electric_scooter",
    version: 14,
    popularity: 2393,
    codepoint: 60191,
    categories: ["maps"],
    tags: [
      "bike",
      "electric",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa",
    ],
  },
  electrical_services: {
    name: "electrical_services",
    version: 12,
    popularity: 9677,
    codepoint: 61698,
    categories: ["maps"],
    tags: [
      "charge",
      "cord",
      "electric",
      "electrical",
      "plug",
      "power",
      "services",
      "wire",
    ],
  },
  elevation: {
    name: "elevation",
    version: 229,
    popularity: 15,
    codepoint: 63207,
    categories: ["Activities"],
    tags: [
      "fitness",
      "health",
      "hill",
      "hills",
      "incline",
      "inclines",
      "mountain",
      "mountains",
    ],
  },
  elevator: {
    name: "elevator",
    version: 8,
    popularity: 3404,
    codepoint: 61856,
    categories: ["places"],
    tags: ["body", "down", "elevator", "human", "people", "person", "up"],
  },
  emergency: {
    name: "emergency",
    version: 5,
    popularity: 8287,
    codepoint: 57835,
    categories: ["maps"],
    tags: [
      "asterisk",
      "clinic",
      "emergency",
      "health",
      "hospital",
      "maps",
      "medical",
      "symbol",
    ],
  },
  emergency_heat: {
    name: "emergency_heat",
    version: 229,
    popularity: 300,
    codepoint: 61789,
    categories: ["Household"],
    tags: [],
  },
  emergency_home: {
    name: "emergency_home",
    version: 229,
    popularity: 1917,
    codepoint: 59434,
    categories: ["Household"],
    tags: [
      "alert",
      "attention",
      "caution",
      "danger",
      "emergency",
      "error",
      "exclamation",
      "home",
      "house",
      "important",
      "mark",
      "nest",
      "notification",
      "symbol",
      "warning",
    ],
  },
  emergency_recording: {
    name: "emergency_recording",
    version: 1,
    popularity: 560,
    codepoint: 60404,
    categories: ["maps"],
    tags: [
      "alert",
      "attention",
      "camera",
      "caution",
      "danger",
      "emergency",
      "film",
      "filming",
      "hardware",
      "image",
      "important",
      "motion",
      "notification",
      "picture",
      "record",
      "video",
      "videography",
      "warning",
    ],
  },
  emergency_share: {
    name: "emergency_share",
    version: 1,
    popularity: 961,
    codepoint: 60406,
    categories: ["maps"],
    tags: [
      "alert",
      "attention",
      "caution",
      "danger",
      "emergency",
      "important",
      "notification",
      "share",
      "warning",
    ],
  },
  emergency_share_off: {
    name: "emergency_share_off",
    version: 229,
    popularity: 4,
    codepoint: 62878,
    categories: ["Household"],
    tags: [
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "emergency",
      "enabled",
      "important",
      "notification",
      "offline",
      "on",
      "share",
      "slash",
      "warning",
    ],
  },
  emoji_events: {
    name: "emoji_events",
    version: 13,
    popularity: 74113,
    codepoint: 59939,
    categories: ["social"],
    tags: [
      "achievement",
      "award",
      "chalice",
      "champion",
      "cup",
      "emoji",
      "events",
      "first",
      "prize",
      "reward",
      "sport",
      "trophy",
      "winner",
    ],
  },
  emoji_flags: {
    name: "emoji_flags",
    version: 229,
    popularity: 1667,
    codepoint: 59930,
    categories: ["Social"],
    tags: [
      "country",
      "destination",
      "emoji",
      "flags",
      "landmark",
      "location",
      "mark",
      "milepost",
      "milestone",
      "nation",
      "place",
      "pole",
      "save",
      "social",
      "world",
    ],
  },
  emoji_food_beverage: {
    name: "emoji_food_beverage",
    version: 11,
    popularity: 6108,
    codepoint: 59931,
    categories: ["social"],
    tags: [
      "beverage",
      "coffee",
      "cup",
      "drink",
      "emoji",
      "mug",
      "plate",
      "set",
      "tea",
    ],
  },
  emoji_nature: {
    name: "emoji_nature",
    version: 11,
    popularity: 8630,
    codepoint: 59932,
    categories: ["social"],
    tags: [
      "animal",
      "bee",
      "bug",
      "daisy",
      "emoji",
      "flower",
      "insect",
      "ladybug",
      "nature",
      "petals",
      "spring",
      "summer",
    ],
  },
  emoji_objects: {
    name: "emoji_objects",
    version: 11,
    popularity: 24548,
    codepoint: 59940,
    categories: ["social"],
    tags: [
      "bulb",
      "creative",
      "emoji",
      "idea",
      "light",
      "objects",
      "solution",
      "thinking",
    ],
  },
  emoji_people: {
    name: "emoji_people",
    version: 11,
    popularity: 17679,
    codepoint: 59933,
    categories: ["social"],
    tags: [
      "arm",
      "body",
      "emoji",
      "greeting",
      "human",
      "people",
      "person",
      "social",
      "waving",
    ],
  },
  emoji_symbols: {
    name: "emoji_symbols",
    version: 11,
    popularity: 6174,
    codepoint: 59934,
    categories: ["social"],
    tags: [
      "ampersand",
      "character",
      "emoji",
      "hieroglyph",
      "music",
      "note",
      "percent",
      "sign",
      "symbols",
    ],
  },
  emoji_transportation: {
    name: "emoji_transportation",
    version: 11,
    popularity: 5904,
    codepoint: 59935,
    categories: ["social"],
    tags: [
      "architecture",
      "automobile",
      "building",
      "car",
      "cars",
      "direction",
      "emoji",
      "estate",
      "maps",
      "place",
      "public",
      "real",
      "residence",
      "residential",
      "shelter",
      "transportation",
      "travel",
      "vehicle",
    ],
  },
  emoticon: {
    name: "emoticon",
    version: 229,
    popularity: 18,
    codepoint: 58867,
    categories: ["Social"],
    tags: [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol",
    ],
  },
  empty_dashboard: {
    name: "empty_dashboard",
    version: 229,
    popularity: 135,
    codepoint: 63556,
    categories: ["UI actions"],
    tags: [
      "cards",
      "dashboard",
      "empty",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website",
    ],
  },
  enable: {
    name: "enable",
    version: 229,
    popularity: 1292,
    codepoint: 61832,
    categories: ["UI actions"],
    tags: ["arrow", "circle", "down", "enable", "pixel", "settings"],
  },
  encrypted: {
    name: "encrypted",
    version: 229,
    popularity: 515,
    codepoint: 58771,
    categories: ["Privacy&Security"],
    tags: [
      "certified",
      "encrypted",
      "encryption",
      "key",
      "password",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "secure",
      "security",
      "shield",
      "verified",
    ],
  },
  endocrinology: {
    name: "endocrinology",
    version: 229,
    popularity: 3,
    codepoint: 57513,
    categories: ["Social"],
    tags: [
      "body",
      "gland",
      "glands",
      "health",
      "human",
      "medical",
      "neck",
      "throat",
    ],
  },
  energy: {
    name: "energy",
    version: 229,
    popularity: 43,
    codepoint: 59814,
    categories: ["Business&Payments"],
    tags: [
      "electricity",
      "energy",
      "environment",
      "generator",
      "green",
      "power",
      "turbine",
      "windmill",
    ],
  },
  energy_program_saving: {
    name: "energy_program_saving",
    version: 229,
    popularity: 406,
    codepoint: 61791,
    categories: ["Household"],
    tags: [],
  },
  energy_program_time_used: {
    name: "energy_program_time_used",
    version: 229,
    popularity: 358,
    codepoint: 61793,
    categories: ["Household"],
    tags: [],
  },
  energy_savings_leaf: {
    name: "energy_savings_leaf",
    version: 1,
    popularity: 3274,
    codepoint: 60442,
    categories: ["home"],
    tags: ["eco", "energy", "leaf", "leaves", "nest", "savings", "usage"],
  },
  engineering: {
    name: "engineering",
    version: 11,
    popularity: 47867,
    codepoint: 59965,
    categories: ["social"],
    tags: [
      "body",
      "cogs",
      "cogwheel",
      "construction",
      "engineering",
      "fixing",
      "gears",
      "hat",
      "helmet",
      "human",
      "maintenance",
      "people",
      "person",
      "setting",
      "worker",
    ],
  },
  enhanced_encryption: {
    name: "enhanced_encryption",
    version: 19,
    popularity: 5855,
    codepoint: 58943,
    categories: ["notification"],
    tags: [
      "+",
      "add",
      "encryption",
      "enhanced",
      "lock",
      "locked",
      "new",
      "password",
      "plus",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "symbol",
    ],
  },
  ent: {
    name: "ent",
    version: 229,
    popularity: 11,
    codepoint: 57514,
    categories: ["Social"],
    tags: [
      "body",
      "brain",
      "ear",
      "head",
      "health",
      "human",
      "idea",
      "ideas",
      "nose",
      "people",
      "person",
      "psychology",
      "thought",
      "thoughts",
      "throat",
    ],
  },
  enterprise: {
    name: "enterprise",
    version: 229,
    popularity: 98,
    codepoint: 59150,
    categories: ["Business&Payments"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "enterprise",
      "purse",
      "suitcase",
    ],
  },
  enterprise_off: {
    name: "enterprise_off",
    version: 229,
    popularity: 9,
    codepoint: 60237,
    categories: ["Business&Payments"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "disabled",
      "enabled",
      "enterprise",
      "off",
      "on",
      "purse",
      "slash",
      "suitcase",
    ],
  },
  equal: {
    name: "equal",
    version: 229,
    popularity: 107,
    codepoint: 63355,
    categories: ["Text Formatting"],
    tags: [
      "calculate",
      "calculator",
      "count",
      "equality",
      "equals",
      "equation",
      "math",
      "mathmatical",
      "operation",
      "same",
      "sum",
      "symbol",
    ],
  },
  equalizer: {
    name: "equalizer",
    version: 12,
    popularity: 18361,
    codepoint: 57373,
    categories: ["av"],
    tags: [
      "adjustment",
      "analytics",
      "chart",
      "data",
      "equalizer",
      "graph",
      "measure",
      "metrics",
      "music",
      "noise",
      "sound",
      "static",
      "statistics",
      "tracking",
      "volume",
    ],
  },
  error: {
    name: "error",
    version: 20,
    popularity: 98638,
    codepoint: 57344,
    categories: ["alert"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "circle",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning",
    ],
  },
  error_med: {
    name: "error_med",
    version: 229,
    popularity: 8,
    codepoint: 58523,
    categories: ["UI actions"],
    tags: [
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "health",
      "important",
      "medicine",
      "notification",
      "warning",
    ],
  },
  escalator: {
    name: "escalator",
    version: 8,
    popularity: 1512,
    codepoint: 61857,
    categories: ["places"],
    tags: ["down", "escalator", "staircase", "up"],
  },
  escalator_warning: {
    name: "escalator_warning",
    version: 8,
    popularity: 6144,
    codepoint: 61868,
    categories: ["places"],
    tags: [
      "body",
      "child",
      "escalator",
      "human",
      "kid",
      "parent",
      "people",
      "person",
      "warning",
    ],
  },
  euro: {
    name: "euro",
    version: 12,
    popularity: 17984,
    codepoint: 59925,
    categories: ["image"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "euros",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  euro_symbol: {
    name: "euro_symbol",
    version: 13,
    popularity: 20006,
    codepoint: 59686,
    categories: ["action"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol",
    ],
  },
  ev_charger: {
    name: "ev_charger",
    version: 229,
    popularity: 393,
    codepoint: 57865,
    categories: ["Home"],
    tags: ["charger", "electric", "electricity", "ev", "home"],
  },
  ev_mobiledata_badge: {
    name: "ev_mobiledata_badge",
    version: 229,
    popularity: 0,
    codepoint: 63458,
    categories: ["Android"],
    tags: [
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
    ],
  },
  ev_shadow: {
    name: "ev_shadow",
    version: 229,
    popularity: 295,
    codepoint: 61327,
    categories: ["Photo&Image"],
    tags: ["charger", "charging", "electric", "electricity", "ev", "power"],
  },
  ev_shadow_add: {
    name: "ev_shadow_add",
    version: 229,
    popularity: 2,
    codepoint: 62848,
    categories: ["Photo&Image"],
    tags: [
      "+",
      "add",
      "charger",
      "charging",
      "electric",
      "electricity",
      "ev",
      "new",
      "plus",
      "power",
    ],
  },
  ev_shadow_minus: {
    name: "ev_shadow_minus",
    version: 229,
    popularity: 1,
    codepoint: 62847,
    categories: ["Photo&Image"],
    tags: [
      "-",
      "charger",
      "charging",
      "delete",
      "electric",
      "electricity",
      "ev",
      "minus",
      "power",
      "remove",
      "subtract",
    ],
  },
  ev_station: {
    name: "ev_station",
    version: 11,
    popularity: 4495,
    codepoint: 58733,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "charging",
      "electric",
      "electricity",
      "ev",
      "maps",
      "places",
      "station",
      "transportation",
      "vehicle",
    ],
  },
  event: {
    name: "event",
    version: 21,
    popularity: 132574,
    codepoint: 59512,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "range",
      "remember",
      "reminder",
      "today",
      "week",
    ],
  },
  event_available: {
    name: "event_available",
    version: 17,
    popularity: 38530,
    codepoint: 58900,
    categories: ["notification"],
    tags: [
      "approve",
      "available",
      "calendar",
      "check",
      "complete",
      "date",
      "done",
      "event",
      "mark",
      "ok",
      "schedule",
      "select",
      "tick",
      "time",
      "validate",
      "verified",
      "yes",
    ],
  },
  event_busy: {
    name: "event_busy",
    version: 20,
    popularity: 12580,
    codepoint: 58901,
    categories: ["notification"],
    tags: [
      "busy",
      "calendar",
      "cancel",
      "clear",
      "close",
      "date",
      "event",
      "exit",
      "no",
      "remove",
      "schedule",
      "stop",
      "time",
      "unavailable",
      "x",
    ],
  },
  event_list: {
    name: "event_list",
    version: 229,
    popularity: 22,
    codepoint: 63107,
    categories: ["UI actions"],
    tags: ["boxes", "camerazilla", "checkbox", "checklist", "lists"],
  },
  event_note: {
    name: "event_note",
    version: 14,
    popularity: 23436,
    codepoint: 58902,
    categories: ["notification"],
    tags: [
      "calendar",
      "date",
      "event",
      "note",
      "schedule",
      "text",
      "time",
      "writing",
    ],
  },
  event_repeat: {
    name: "event_repeat",
    version: 1,
    popularity: 4810,
    codepoint: 60283,
    categories: ["action"],
    tags: [
      "around",
      "calendar",
      "date",
      "day",
      "event",
      "inprogress",
      "load",
      "loading refresh",
      "month",
      "renew",
      "rotate",
      "schedule",
      "turn",
    ],
  },
  event_seat: {
    name: "event_seat",
    version: 19,
    popularity: 7327,
    codepoint: 59651,
    categories: ["action"],
    tags: [
      "assign",
      "assigned",
      "chair",
      "event",
      "furniture",
      "reservation",
      "row",
      "seat",
      "section",
      "sit",
    ],
  },
  event_upcoming: {
    name: "event_upcoming",
    version: 229,
    popularity: 2616,
    codepoint: 62008,
    categories: ["Common actions"],
    tags: [
      "arrow",
      "calendar",
      "date",
      "event",
      "month",
      "reminder",
      "right",
      "schedule",
      "time",
      "upcoming",
    ],
  },
  exclamation: {
    name: "exclamation",
    version: 229,
    popularity: 2651,
    codepoint: 61999,
    categories: ["Privacy&Security"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "high",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning",
    ],
  },
  exercise: {
    name: "exercise",
    version: 229,
    popularity: 78,
    codepoint: 63206,
    categories: ["Activities"],
    tags: [
      "athlete",
      "center",
      "dumbbell",
      "exercise",
      "fitbit",
      "fitness",
      "gym",
      "hobby",
      "places",
      "sport",
      "sports",
      "weights",
      "workout",
    ],
  },
  exit_to_app: {
    name: "exit_to_app",
    version: 13,
    popularity: 35552,
    codepoint: 59513,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "export",
      "interface",
      "layout",
      "leave",
      "mobile",
      "monitor",
      "move",
      "output",
      "phone",
      "screen",
      "site",
      "tablet",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  expand: {
    name: "expand",
    version: 11,
    popularity: 13047,
    codepoint: 59727,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "compress",
      "enlarge",
      "expand",
      "grow",
      "move",
      "push",
      "together",
    ],
  },
  expand_all: {
    name: "expand_all",
    version: 229,
    popularity: 231,
    codepoint: 59718,
    categories: ["UI actions"],
    tags: [
      "all",
      "arrow",
      "arrows",
      "directions",
      "down",
      "expand",
      "open",
      "up",
    ],
  },
  expand_circle_down: {
    name: "expand_circle_down",
    version: 2,
    popularity: 20154,
    codepoint: 59341,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
    ],
  },
  expand_circle_right: {
    name: "expand_circle_right",
    version: 229,
    popularity: 30,
    codepoint: 62865,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
    ],
  },
  expand_circle_up: {
    name: "expand_circle_up",
    version: 229,
    popularity: 33,
    codepoint: 62930,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
    ],
  },
  expand_content: {
    name: "expand_content",
    version: 229,
    popularity: 268,
    codepoint: 63536,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "expansion",
      "list",
      "more",
      "navigation",
      "open",
    ],
  },
  expand_less: {
    name: "expand_less",
    version: 12,
    popularity: 102150,
    codepoint: 58830,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "less",
      "list",
      "up",
    ],
  },
  expand_more: {
    name: "expand_more",
    version: 14,
    popularity: 332158,
    codepoint: 58831,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
    ],
  },
  experiment: {
    name: "experiment",
    version: 229,
    popularity: 858,
    codepoint: 59014,
    categories: ["Activities"],
    tags: [
      "beaker",
      "chemical",
      "chemistry",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "research",
      "science",
      "tube",
    ],
  },
  explicit: {
    name: "explicit",
    version: 12,
    popularity: 1933,
    codepoint: 57374,
    categories: ["av"],
    tags: [
      "adult",
      "alphabet",
      "character",
      "content",
      "e",
      "explicit",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "music",
      "symbol",
      "text",
      "type",
    ],
  },
  explore: {
    name: "explore",
    version: 12,
    popularity: 47075,
    codepoint: 59514,
    categories: ["action"],
    tags: [
      "compass",
      "destination",
      "direction",
      "east",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "south",
      "travel",
      "west",
    ],
  },
  explore_nearby: {
    name: "explore_nearby",
    version: 229,
    popularity: 38,
    codepoint: 58680,
    categories: ["Maps"],
    tags: [
      "explore",
      "locartion",
      "map",
      "marker",
      "navigation",
      "nearby",
      "pin",
      "place",
      "spot",
      "travel",
    ],
  },
  explore_off: {
    name: "explore_off",
    version: 12,
    popularity: 2204,
    codepoint: 59816,
    categories: ["action"],
    tags: [
      "compass",
      "destination",
      "direction",
      "disabled",
      "east",
      "enabled",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "off",
      "on",
      "slash",
      "south",
      "travel",
      "west",
    ],
  },
  explosion: {
    name: "explosion",
    version: 229,
    popularity: 11,
    codepoint: 63109,
    categories: ["Social"],
    tags: [
      "atomic",
      "blast",
      "blasts",
      "boom",
      "burst",
      "bursts",
      "disaster",
      "explode",
      "explosion",
      "explosive",
      "loud",
      "mine",
    ],
  },
  export_notes: {
    name: "export_notes",
    version: 229,
    popularity: 67,
    codepoint: 57516,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "data",
      "doc",
      "document",
      "export",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "right",
      "writing",
    ],
  },
  exposure: {
    name: "exposure",
    version: 12,
    popularity: 2605,
    codepoint: 58314,
    categories: ["image"],
    tags: [
      "add",
      "brightness",
      "contrast",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "minus",
      "photo",
      "photography",
      "picture",
      "plus",
      "settings",
      "subtract",
    ],
  },
  exposure_neg_1: {
    name: "exposure_neg_1",
    version: 13,
    popularity: 1319,
    codepoint: 58315,
    categories: ["image"],
    tags: [
      "1",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol",
    ],
  },
  exposure_neg_2: {
    name: "exposure_neg_2",
    version: 13,
    popularity: 915,
    codepoint: 58316,
    categories: ["image"],
    tags: [
      "2",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol",
    ],
  },
  exposure_plus_1: {
    name: "exposure_plus_1",
    version: 12,
    popularity: 2700,
    codepoint: 58317,
    categories: ["image"],
    tags: [
      "1",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol",
    ],
  },
  exposure_plus_2: {
    name: "exposure_plus_2",
    version: 12,
    popularity: 1485,
    codepoint: 58318,
    categories: ["image"],
    tags: [
      "2",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol",
    ],
  },
  exposure_zero: {
    name: "exposure_zero",
    version: 12,
    popularity: 2029,
    codepoint: 58319,
    categories: ["image"],
    tags: [
      "0",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol",
      "zero",
    ],
  },
  extension: {
    name: "extension",
    version: 12,
    popularity: 26743,
    codepoint: 59515,
    categories: ["action"],
    tags: [
      "app",
      "extended",
      "extension",
      "game",
      "jigsaw",
      "plugin add",
      "puzzle",
      "shape",
    ],
  },
  extension_off: {
    name: "extension_off",
    version: 4,
    popularity: 1918,
    codepoint: 58613,
    categories: ["action"],
    tags: [
      "disabled",
      "enabled",
      "extended",
      "extension",
      "jigsaw",
      "off",
      "on",
      "piece",
      "puzzle",
      "shape",
      "slash",
    ],
  },
  eyeglasses: {
    name: "eyeglasses",
    version: 229,
    popularity: 108,
    codepoint: 63214,
    categories: ["Social"],
    tags: ["glasses", "optical", "specs", "spectacles", "sunglasses", "vision"],
  },
  face: {
    name: "face",
    version: 15,
    popularity: 151009,
    codepoint: 59516,
    categories: ["action"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_2: {
    name: "face_2",
    version: 1,
    popularity: 1464,
    codepoint: 63706,
    categories: ["social"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_3: {
    name: "face_3",
    version: 1,
    popularity: 1896,
    codepoint: 63707,
    categories: ["social"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_4: {
    name: "face_4",
    version: 1,
    popularity: 1416,
    codepoint: 63708,
    categories: ["social"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_5: {
    name: "face_5",
    version: 1,
    popularity: 1061,
    codepoint: 63709,
    categories: ["social"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_6: {
    name: "face_6",
    version: 1,
    popularity: 2130,
    codepoint: 63710,
    categories: ["social"],
    tags: [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user",
    ],
  },
  face_retouching_off: {
    name: "face_retouching_off",
    version: 15,
    popularity: 1317,
    codepoint: 61447,
    categories: ["image"],
    tags: [
      "disabled",
      "edit",
      "editing",
      "effect",
      "emoji",
      "emotion",
      "enabled",
      "face",
      "faces",
      "image",
      "natural",
      "off",
      "on",
      "photo",
      "photography",
      "retouch",
      "retouching",
      "settings",
      "slash",
      "tag",
    ],
  },
  fact_check: {
    name: "fact_check",
    version: 14,
    popularity: 78996,
    codepoint: 61637,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "fact",
      "list",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  factory: {
    name: "factory",
    version: 1,
    popularity: 9957,
    codepoint: 60348,
    categories: ["maps"],
    tags: ["factory", "industry", "manufacturing", "warehouse"],
  },
  falling: {
    name: "falling",
    version: 229,
    popularity: 18,
    codepoint: 62989,
    categories: ["Social"],
    tags: ["body", "fall", "human", "people", "person"],
  },
  familiar_face_and_zone: {
    name: "familiar_face_and_zone",
    version: 229,
    popularity: 645,
    codepoint: 57884,
    categories: ["Home"],
    tags: [
      "activity",
      "face",
      "familiar",
      "frame",
      "guided frame",
      "home",
      "nest",
      "person",
      "security",
      "zone",
    ],
  },
  family_history: {
    name: "family_history",
    version: 229,
    popularity: 53,
    codepoint: 57517,
    categories: ["Business&Payments"],
    tags: [
      "chart",
      "connect",
      "diagram",
      "flow",
      "genealogy",
      "graph",
      "health",
      "infographic",
      "pedigree",
    ],
  },
  family_home: {
    name: "family_home",
    version: 229,
    popularity: 120,
    codepoint: 60198,
    categories: ["Household"],
    tags: [
      "architecture",
      "building",
      "estate",
      "family",
      "home",
      "house",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
    ],
  },
  family_link: {
    name: "family_link",
    version: 229,
    popularity: 223,
    codepoint: 60185,
    categories: ["Activities"],
    tags: ["activity", "family", "game", "kite", "kiting", "link"],
  },
  family_restroom: {
    name: "family_restroom",
    version: 9,
    popularity: 13419,
    codepoint: 61858,
    categories: ["places"],
    tags: [
      "bathroom",
      "child",
      "children",
      "family",
      "father",
      "kids",
      "mother",
      "parents",
      "restroom",
      "wc",
    ],
  },
  family_star: {
    name: "family_star",
    version: 229,
    popularity: 0,
    codepoint: 62759,
    categories: ["Social"],
    tags: [
      "best",
      "bookmark",
      "child",
      "emoji",
      "face",
      "favorite",
      "highlight",
      "kid",
      "ranking",
      "rate",
      "rating",
      "save",
      "smile",
      "star",
    ],
  },
  farsight_digital: {
    name: "farsight_digital",
    version: 229,
    popularity: 1,
    codepoint: 62809,
    categories: ["Home"],
    tags: [
      "climate",
      "clock",
      "digital",
      "farsight",
      "home",
      "nest",
      "thermostat",
    ],
  },
  fast_forward: {
    name: "fast_forward",
    version: 18,
    popularity: 16914,
    codepoint: 57375,
    categories: ["av"],
    tags: [
      "control",
      "fast",
      "forward",
      "media",
      "music",
      "play",
      "speed",
      "time",
      "tv",
      "video",
    ],
  },
  fast_rewind: {
    name: "fast_rewind",
    version: 12,
    popularity: 10097,
    codepoint: 57376,
    categories: ["av"],
    tags: [
      "back",
      "control",
      "fast",
      "media",
      "music",
      "play",
      "rewind",
      "speed",
      "time",
      "tv",
      "video",
    ],
  },
  fastfood: {
    name: "fastfood",
    version: 12,
    popularity: 14259,
    codepoint: 58746,
    categories: ["maps"],
    tags: ["drink", "fastfood", "food", "hamburger", "maps", "meal", "places"],
  },
  faucet: {
    name: "faucet",
    version: 229,
    popularity: 414,
    codepoint: 57976,
    categories: ["Household"],
    tags: ["appliance", "faucet", "kitchen", "nest", "outlet", "tap", "water"],
  },
  favorite: {
    name: "favorite",
    version: 17,
    popularity: 256708,
    codepoint: 59517,
    categories: ["action"],
    tags: [
      "appreciate",
      "favorite",
      "heart",
      "like",
      "love",
      "remember",
      "save",
      "shape",
    ],
  },
  fax: {
    name: "fax",
    version: 2,
    popularity: 5781,
    codepoint: 60120,
    categories: ["action"],
    tags: ["fax", "machine", "office", "phone", "send"],
  },
  feature_search: {
    name: "feature_search",
    version: 229,
    popularity: 129,
    codepoint: 59817,
    categories: ["Common actions"],
    tags: [
      "feature",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see",
    ],
  },
  featured_play_list: {
    name: "featured_play_list",
    version: 12,
    popularity: 4439,
    codepoint: 57453,
    categories: ["av"],
    tags: [
      "collection",
      "featured",
      "highlighted",
      "list",
      "music",
      "play",
      "playlist",
      "recommended",
    ],
  },
  featured_seasonal_and_gifts: {
    name: "featured_seasonal_and_gifts",
    version: 229,
    popularity: 357,
    codepoint: 61329,
    categories: ["Activities"],
    tags: [
      "and",
      "birthday",
      "bow",
      "box",
      "featured",
      "gifts",
      "holiday",
      "present",
      "ribbon",
      "seasonal",
    ],
  },
  featured_video: {
    name: "featured_video",
    version: 12,
    popularity: 2642,
    codepoint: 57454,
    categories: ["av"],
    tags: [
      "advertised",
      "advertisement",
      "featured",
      "highlighted",
      "recommended",
      "video",
      "watch",
    ],
  },
  feed: {
    name: "feed",
    version: 10,
    popularity: 38524,
    codepoint: 61449,
    categories: ["search"],
    tags: [
      "article",
      "feed",
      "headline",
      "information",
      "news",
      "newspaper",
      "paper",
      "public",
      "social",
      "timeline",
    ],
  },
  feedback: {
    name: "feedback",
    version: 12,
    popularity: 32709,
    codepoint: 59519,
    categories: ["action"],
    tags: [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "chat",
      "chat bubble",
      "comment",
      "communicate",
      "communication",
      "conversation",
      "danger",
      "error",
      "exclamation",
      "failed",
      "feedback",
      "important",
      "mark",
      "message",
      "notification",
      "service",
      "sms",
      "speech",
      "symbol",
      "warning",
    ],
  },
  female: {
    name: "female",
    version: 3,
    popularity: 13822,
    codepoint: 58768,
    categories: ["social"],
    tags: [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women",
    ],
  },
  femur: {
    name: "femur",
    version: 229,
    popularity: 169,
    codepoint: 63633,
    categories: ["Social"],
    tags: [
      "body",
      "bone",
      "bones",
      "femur",
      "health",
      "hip",
      "knee",
      "leg",
      "medical",
      "skeleton",
    ],
  },
  femur_alt: {
    name: "femur_alt",
    version: 229,
    popularity: 163,
    codepoint: 63634,
    categories: ["Social"],
    tags: [
      "body",
      "bone",
      "bones",
      "femur",
      "health",
      "hip",
      "knee",
      "leg",
      "medical",
      "skeleton",
    ],
  },
  fence: {
    name: "fence",
    version: 6,
    popularity: 2168,
    codepoint: 61942,
    categories: ["places"],
    tags: [
      "backyard",
      "barrier",
      "boundaries",
      "boundary",
      "fence",
      "home",
      "house",
      "protection",
      "yard",
    ],
  },
  fertile: {
    name: "fertile",
    version: 229,
    popularity: 19,
    codepoint: 63205,
    categories: ["Activities"],
    tags: [
      "blood",
      "cycle",
      "female",
      "fitbit",
      "health",
      "menstrual",
      "ovulation",
    ],
  },
  festival: {
    name: "festival",
    version: 10,
    popularity: 4236,
    codepoint: 60008,
    categories: ["maps"],
    tags: [
      "circus",
      "event",
      "festival",
      "local",
      "maps",
      "places",
      "tent",
      "tour",
      "travel",
    ],
  },
  fiber_dvr: {
    name: "fiber_dvr",
    version: 13,
    popularity: 930,
    codepoint: 57437,
    categories: ["av"],
    tags: [
      "alphabet",
      "character",
      "digital",
      "dvr",
      "electronics",
      "fiber",
      "font",
      "letter",
      "network",
      "record",
      "recorder",
      "symbol",
      "text",
      "tv",
      "type",
      "video",
    ],
  },
  fiber_manual_record: {
    name: "fiber_manual_record",
    version: 16,
    popularity: 29125,
    codepoint: 57441,
    categories: ["av"],
    tags: ["circle", "dot", "fiber", "manual", "play", "record", "watch"],
  },
  fiber_new: {
    name: "fiber_new",
    version: 13,
    popularity: 7643,
    codepoint: 57438,
    categories: ["av"],
    tags: [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "new",
      "symbol",
      "text",
      "type",
    ],
  },
  fiber_pin: {
    name: "fiber_pin",
    version: 12,
    popularity: 1043,
    codepoint: 57450,
    categories: ["av"],
    tags: [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "pin",
      "symbol",
      "text",
      "type",
    ],
  },
  fiber_smart_record: {
    name: "fiber_smart_record",
    version: 12,
    popularity: 1797,
    codepoint: 57442,
    categories: ["av"],
    tags: ["circle", "dot", "fiber", "play", "record", "smart", "watch"],
  },
  file_copy: {
    name: "file_copy",
    version: 12,
    popularity: 28258,
    codepoint: 57715,
    categories: ["content"],
    tags: [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack",
    ],
  },
  file_download_done: {
    name: "file_download_done",
    version: 11,
    popularity: 7027,
    codepoint: 59818,
    categories: ["file"],
    tags: [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "file",
      "install",
      "installed",
      "tick",
      "upload",
    ],
  },
  file_download_off: {
    name: "file_download_off",
    version: 4,
    popularity: 2977,
    codepoint: 58622,
    categories: ["file"],
    tags: [
      "arrow",
      "disabled",
      "down",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "install",
      "off",
      "on",
      "save",
      "slash",
      "upload",
    ],
  },
  file_map: {
    name: "file_map",
    version: 229,
    popularity: 26,
    codepoint: 58053,
    categories: ["Maps"],
    tags: [
      "file",
      "location",
      "map",
      "mark",
      "marker",
      "pin",
      "place",
      "sport",
    ],
  },
  file_open: {
    name: "file_open",
    version: 1,
    popularity: 7540,
    codepoint: 60147,
    categories: ["file"],
    tags: [
      "arrow",
      "doc",
      "document",
      "drive",
      "file",
      "left",
      "open",
      "page",
      "paper",
    ],
  },
  file_present: {
    name: "file_present",
    version: 10,
    popularity: 15028,
    codepoint: 59918,
    categories: ["action"],
    tags: [
      "clip",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "note",
      "paper",
      "present",
      "reminder",
      "sheet",
      "slide",
      "storage",
      "writing",
    ],
  },
  file_save: {
    name: "file_save",
    version: 229,
    popularity: 49,
    codepoint: 61823,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "data",
      "doc",
      "document",
      "down",
      "download",
      "drive",
      "file",
      "folder",
      "folders",
      "keep",
      "save",
      "sheet",
      "slide",
      "storage",
      "writing",
    ],
  },
  file_save_off: {
    name: "file_save_off",
    version: 229,
    popularity: 5,
    codepoint: 58629,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "data",
      "disabled",
      "doc",
      "document",
      "down",
      "download",
      "drive",
      "enabled",
      "file",
      "folder",
      "folders",
      "keep",
      "off",
      "on",
      "save",
      "sheet",
      "slash",
      "slide",
      "storage",
      "writing",
    ],
  },
  file_upload_off: {
    name: "file_upload_off",
    version: 1,
    popularity: 333,
    codepoint: 63622,
    categories: ["file"],
    tags: [
      "arrow",
      "arrows",
      "disabled",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "off",
      "offline",
      "on",
      "slash",
      "up",
      "upload",
    ],
  },
  filter: {
    name: "filter",
    version: 12,
    popularity: 3753,
    codepoint: 58323,
    categories: ["image"],
    tags: [
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "settings",
    ],
  },
  filter_1: {
    name: "filter_1",
    version: 12,
    popularity: 4492,
    codepoint: 58320,
    categories: ["image"],
    tags: [
      "1",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_2: {
    name: "filter_2",
    version: 12,
    popularity: 2601,
    codepoint: 58321,
    categories: ["image"],
    tags: [
      "2",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_3: {
    name: "filter_3",
    version: 12,
    popularity: 2045,
    codepoint: 58322,
    categories: ["image"],
    tags: [
      "3",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_4: {
    name: "filter_4",
    version: 12,
    popularity: 1485,
    codepoint: 58324,
    categories: ["image"],
    tags: [
      "4",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_5: {
    name: "filter_5",
    version: 12,
    popularity: 1358,
    codepoint: 58325,
    categories: ["image"],
    tags: [
      "5",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_6: {
    name: "filter_6",
    version: 12,
    popularity: 1114,
    codepoint: 58326,
    categories: ["image"],
    tags: [
      "6",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_7: {
    name: "filter_7",
    version: 12,
    popularity: 1508,
    codepoint: 58327,
    categories: ["image"],
    tags: [
      "7",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_8: {
    name: "filter_8",
    version: 12,
    popularity: 1190,
    codepoint: 58328,
    categories: ["image"],
    tags: [
      "8",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_9: {
    name: "filter_9",
    version: 12,
    popularity: 1177,
    codepoint: 58329,
    categories: ["image"],
    tags: [
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_9_plus: {
    name: "filter_9_plus",
    version: 13,
    popularity: 1483,
    codepoint: 58330,
    categories: ["image"],
    tags: [
      "+",
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "plus",
      "settings",
      "stack",
      "symbol",
    ],
  },
  filter_alt: {
    name: "filter_alt",
    version: 11,
    popularity: 135542,
    codepoint: 61263,
    categories: ["action"],
    tags: ["alt", "edit", "filter", "funnel", "options", "refine", "sift"],
  },
  filter_alt_off: {
    name: "filter_alt_off",
    version: 1,
    popularity: 8433,
    codepoint: 60210,
    categories: ["action"],
    tags: [
      "alt",
      "disabled",
      "edit",
      "filter",
      "funnel",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash",
    ],
  },
  filter_b_and_w: {
    name: "filter_b_and_w",
    version: 13,
    popularity: 1700,
    codepoint: 58331,
    categories: ["image"],
    tags: [
      "and",
      "b",
      "black",
      "contrast",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "w",
      "white",
    ],
  },
  filter_center_focus: {
    name: "filter_center_focus",
    version: 12,
    popularity: 5907,
    codepoint: 58332,
    categories: ["image"],
    tags: [
      "camera",
      "center",
      "dot",
      "edit",
      "filter",
      "focus",
      "image",
      "photo",
      "photography",
      "picture",
    ],
  },
  filter_drama: {
    name: "filter_drama",
    version: 12,
    popularity: 7358,
    codepoint: 58333,
    categories: ["image"],
    tags: [
      "cloud",
      "drama",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "photo",
      "photography",
      "picture",
      "sky camera",
    ],
  },
  filter_frames: {
    name: "filter_frames",
    version: 13,
    popularity: 1869,
    codepoint: 58334,
    categories: ["image"],
    tags: [
      "boarders",
      "border",
      "camera",
      "center",
      "edit",
      "editing",
      "effect",
      "filter",
      "filters",
      "focus",
      "frame",
      "frames",
      "image",
      "options",
      "photo",
      "photography",
      "picture",
    ],
  },
  filter_hdr: {
    name: "filter_hdr",
    version: 13,
    popularity: 2144,
    codepoint: 58335,
    categories: ["image"],
    tags: [
      "camera",
      "edit",
      "editing",
      "effect",
      "filter",
      "hdr",
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
    ],
  },
  filter_list: {
    name: "filter_list",
    version: 13,
    popularity: 69904,
    codepoint: 57682,
    categories: ["content"],
    tags: ["filter", "lines", "list", "organize", "sort"],
  },
  filter_list_off: {
    name: "filter_list_off",
    version: 1,
    popularity: 3509,
    codepoint: 60247,
    categories: ["content"],
    tags: [
      "alt",
      "disabled",
      "edit",
      "filter",
      "list",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash",
    ],
  },
  filter_none: {
    name: "filter_none",
    version: 13,
    popularity: 6045,
    codepoint: 58336,
    categories: ["image"],
    tags: ["filter", "multiple", "none", "square", "stack"],
  },
  filter_retrolux: {
    name: "filter_retrolux",
    version: 229,
    popularity: 10,
    codepoint: 58337,
    categories: ["Photo&Image"],
    tags: [
      "activity",
      "app",
      "application",
      "edit",
      "editing",
      "editor",
      "filter",
      "game",
      "image",
      "kite",
      "kiting",
      "photo",
      "program",
      "retrolux",
      "software",
    ],
  },
  filter_tilt_shift: {
    name: "filter_tilt_shift",
    version: 14,
    popularity: 2551,
    codepoint: 58338,
    categories: ["image"],
    tags: [
      "blur",
      "center",
      "dash",
      "dashed",
      "edit",
      "editing",
      "effect",
      "filter",
      "focus",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "shift",
      "tilt",
    ],
  },
  filter_vintage: {
    name: "filter_vintage",
    version: 13,
    popularity: 7502,
    codepoint: 58339,
    categories: ["image"],
    tags: [
      "edit",
      "editing",
      "effect",
      "filter",
      "flower",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "vintage",
    ],
  },
  finance: {
    name: "finance",
    version: 229,
    popularity: 116,
    codepoint: 59071,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "finance",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  finance_chip: {
    name: "finance_chip",
    version: 229,
    popularity: 127,
    codepoint: 63566,
    categories: ["Text Formatting"],
    tags: [
      "bill",
      "cash",
      "chip",
      "coin",
      "commerce",
      "currency",
      "dollars",
      "money",
      "pay",
      "payment",
      "price",
    ],
  },
  finance_mode: {
    name: "finance_mode",
    version: 229,
    popularity: 177,
    codepoint: 61330,
    categories: ["Business&Payments"],
    tags: [],
  },
  find_in_page: {
    name: "find_in_page",
    version: 12,
    popularity: 22199,
    codepoint: 59520,
    categories: ["action"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "find",
      "folder",
      "folders",
      "glass",
      "in",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
      "sheet",
      "slide",
      "writing",
    ],
  },
  find_replace: {
    name: "find_replace",
    version: 12,
    popularity: 5858,
    codepoint: 59521,
    categories: ["action"],
    tags: [
      "around",
      "arrows",
      "find",
      "glass",
      "inprogress",
      "load",
      "loading refresh",
      "look",
      "magnify",
      "magnifying",
      "renew",
      "replace",
      "rotate",
      "search",
      "see",
    ],
  },
  fingerprint: {
    name: "fingerprint",
    version: 12,
    popularity: 132363,
    codepoint: 59661,
    categories: ["action"],
    tags: [
      "finger",
      "fingerprint",
      "id",
      "identification",
      "identity",
      "print",
      "reader",
      "thumbprint",
      "verification",
    ],
  },
  fire_extinguisher: {
    name: "fire_extinguisher",
    version: 7,
    popularity: 2905,
    codepoint: 61912,
    categories: ["places"],
    tags: ["emergency", "extinguisher", "fire", "water"],
  },
  fire_hydrant: {
    name: "fire_hydrant",
    version: 229,
    popularity: 346,
    codepoint: 61859,
    categories: ["Maps"],
    tags: [
      "911",
      "department",
      "emergency",
      "fire",
      "firefighter",
      "flame",
      "hot",
      "hydrant",
      "water",
    ],
  },
  fire_truck: {
    name: "fire_truck",
    version: 1,
    popularity: 815,
    codepoint: 63730,
    categories: ["maps"],
    tags: [],
  },
  fireplace: {
    name: "fireplace",
    version: 11,
    popularity: 4866,
    codepoint: 59971,
    categories: ["social"],
    tags: [
      "chimney",
      "fire",
      "fireplace",
      "flame",
      "home",
      "house",
      "living",
      "pit",
      "place",
      "room",
      "warm",
      "winter",
    ],
  },
  first_page: {
    name: "first_page",
    version: 11,
    popularity: 15781,
    codepoint: 58844,
    categories: ["navigation"],
    tags: ["arrow", "back", "chevron", "first", "left", "page", "rewind"],
  },
  fit_page: {
    name: "fit_page",
    version: 229,
    popularity: 2,
    codepoint: 63354,
    categories: ["Text Formatting"],
    tags: [
      "dash",
      "dashed",
      "enlarge",
      "fit",
      "format",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size",
    ],
  },
  fit_screen: {
    name: "fit_screen",
    version: 15,
    popularity: 7913,
    codepoint: 59920,
    categories: ["action"],
    tags: [
      "enlarge",
      "fit",
      "format",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size",
    ],
  },
  fit_width: {
    name: "fit_width",
    version: 229,
    popularity: 4,
    codepoint: 63353,
    categories: ["Text Formatting"],
    tags: [
      "enlarge",
      "fit",
      "format",
      "horizontal",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size",
      "wide",
    ],
  },
  fitness_center: {
    name: "fitness_center",
    version: 12,
    popularity: 25544,
    codepoint: 60227,
    categories: ["places"],
    tags: [
      "athlete",
      "center",
      "dumbbell",
      "exercise",
      "fitness",
      "gym",
      "hobby",
      "places",
      "sport",
      "weights",
      "workout",
    ],
  },
  flag: {
    name: "flag",
    version: 18,
    popularity: 42847,
    codepoint: 57683,
    categories: ["content"],
    tags: ["country", "flag", "goal", "mark", "nation", "report", "start"],
  },
  flag_circle: {
    name: "flag_circle",
    version: 1,
    popularity: 4219,
    codepoint: 60152,
    categories: ["content"],
    tags: [
      "circle",
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "report",
      "round",
      "start",
    ],
  },
  flaky: {
    name: "flaky",
    version: 13,
    popularity: 7805,
    codepoint: 61264,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "close",
      "complete",
      "contrast",
      "done",
      "exit",
      "flaky",
      "mark",
      "no",
      "ok",
      "options",
      "select",
      "stop",
      "tick",
      "verified",
      "x",
      "yes",
    ],
  },
  flare: {
    name: "flare",
    version: 12,
    popularity: 6336,
    codepoint: 58340,
    categories: ["image"],
    tags: [
      "bright",
      "edit",
      "editing",
      "effect",
      "flare",
      "image",
      "images",
      "light",
      "photography",
      "picture",
      "pictures",
      "sun",
    ],
  },
  flash_auto: {
    name: "flash_auto",
    version: 12,
    popularity: 2337,
    codepoint: 58341,
    categories: ["image"],
    tags: [
      "a",
      "auto",
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "thunderbolt",
    ],
  },
  flash_off: {
    name: "flash_off",
    version: 12,
    popularity: 5466,
    codepoint: 58342,
    categories: ["image"],
    tags: [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt",
    ],
  },
  flash_on: {
    name: "flash_on",
    version: 12,
    popularity: 17815,
    codepoint: 58343,
    categories: ["image"],
    tags: [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt",
    ],
  },
  flashlight_off: {
    name: "flashlight_off",
    version: 10,
    popularity: 2049,
    codepoint: 61450,
    categories: ["device"],
    tags: [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash",
    ],
  },
  flashlight_on: {
    name: "flashlight_on",
    version: 10,
    popularity: 5025,
    codepoint: 61451,
    categories: ["device"],
    tags: [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash",
    ],
  },
  flatware: {
    name: "flatware",
    version: 9,
    popularity: 4415,
    codepoint: 61452,
    categories: ["search"],
    tags: [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon",
    ],
  },
  flex_direction: {
    name: "flex_direction",
    version: 229,
    popularity: 5,
    codepoint: 63352,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "down",
      "format",
      "layout",
      "layouts",
      "list",
      "move",
      "north",
    ],
  },
  flex_no_wrap: {
    name: "flex_no_wrap",
    version: 229,
    popularity: 0,
    codepoint: 63351,
    categories: ["Text Formatting"],
    tags: [
      "3x3",
      "format",
      "grid",
      "horizontal",
      "layout",
      "rectangle",
      "rectangles",
      "view",
    ],
  },
  flex_wrap: {
    name: "flex_wrap",
    version: 229,
    popularity: 7,
    codepoint: 63350,
    categories: ["Text Formatting"],
    tags: [
      "6x6",
      "format",
      "grid",
      "horizontal",
      "layout",
      "rectangle",
      "rectangles",
      "view",
      "wraparound",
    ],
  },
  flight: {
    name: "flight",
    version: 18,
    popularity: 26757,
    codepoint: 58681,
    categories: ["maps"],
    tags: [
      "air",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip",
    ],
  },
  flight_class: {
    name: "flight_class",
    version: 2,
    popularity: 1100,
    codepoint: 59339,
    categories: ["maps"],
    tags: [
      "airplane",
      "business",
      "class",
      "first",
      "flight",
      "plane",
      "seat",
      "transportation",
      "travel",
      "trip",
      "window",
    ],
  },
  flight_land: {
    name: "flight_land",
    version: 13,
    popularity: 8846,
    codepoint: 59652,
    categories: ["action"],
    tags: [
      "airport",
      "arrival",
      "arriving",
      "flight",
      "fly",
      "land",
      "landing",
      "plane",
      "transportation",
      "travel",
    ],
  },
  flight_takeoff: {
    name: "flight_takeoff",
    version: 13,
    popularity: 30991,
    codepoint: 59653,
    categories: ["action"],
    tags: [
      "airport",
      "departed",
      "departing",
      "flight",
      "fly",
      "landing",
      "plane",
      "takeoff",
      "transportation",
      "travel",
    ],
  },
  flights_and_hotels: {
    name: "flights_and_hotels",
    version: 229,
    popularity: 49,
    codepoint: 59819,
    categories: ["Travel"],
    tags: [
      "air",
      "airplane",
      "airport",
      "and",
      "bed",
      "flight",
      "furniture",
      "hotel",
      "hotels",
      "night",
      "plane",
      "rest",
      "room",
      "sleep",
      "transportation",
      "travel",
      "trip",
    ],
  },
  flightsmode: {
    name: "flightsmode",
    version: 229,
    popularity: 141,
    codepoint: 61331,
    categories: ["Transportation"],
    tags: [
      "air",
      "airplane",
      "airport",
      "flight",
      "flightsmode",
      "plane",
      "transportation",
      "travel",
      "trip",
    ],
  },
  flip: {
    name: "flip",
    version: 12,
    popularity: 4971,
    codepoint: 58344,
    categories: ["image"],
    tags: [
      "dash",
      "dashed",
      "edit",
      "editing",
      "flip",
      "image",
      "orientation",
      "scan scanning",
    ],
  },
  flip_camera_android: {
    name: "flip_camera_android",
    version: 12,
    popularity: 5071,
    codepoint: 59959,
    categories: ["image"],
    tags: [
      "android",
      "camera",
      "center",
      "edit",
      "editing",
      "flip",
      "image",
      "mobile",
      "orientation",
      "rotate",
      "turn",
    ],
  },
  flip_camera_ios: {
    name: "flip_camera_ios",
    version: 12,
    popularity: 4637,
    codepoint: 59960,
    categories: ["image"],
    tags: [
      "DISABLE_IOS",
      "android",
      "camera",
      "disable_ios",
      "edit",
      "editing",
      "flip",
      "image",
      "ios",
      "mobile",
      "orientation",
      "rotate",
      "turn",
    ],
  },
  flip_to_back: {
    name: "flip_to_back",
    version: 12,
    popularity: 2718,
    codepoint: 59522,
    categories: ["action"],
    tags: [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to",
    ],
  },
  flip_to_front: {
    name: "flip_to_front",
    version: 12,
    popularity: 3954,
    codepoint: 59523,
    categories: ["action"],
    tags: [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to",
    ],
  },
  flood: {
    name: "flood",
    version: 1,
    popularity: 1000,
    codepoint: 60390,
    categories: ["social"],
    tags: ["crisis", "disaster", "natural", "rain", "storm", "weather"],
  },
  floor: {
    name: "floor",
    version: 229,
    popularity: 40,
    codepoint: 63204,
    categories: ["Activities"],
    tags: ["fitbit", "floor", "staircase", "stairs", "steps"],
  },
  floor_lamp: {
    name: "floor_lamp",
    version: 229,
    popularity: 231,
    codepoint: 57886,
    categories: ["Home"],
    tags: ["floor ", "home", "lamp"],
  },
  flowsheet: {
    name: "flowsheet",
    version: 229,
    popularity: 46,
    codepoint: 57518,
    categories: ["Business&Payments"],
    tags: [
      "chart",
      "doc",
      "document",
      "file",
      "flow",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide",
    ],
  },
  fluid: {
    name: "fluid",
    version: 229,
    popularity: 2,
    codepoint: 58499,
    categories: ["Social"],
    tags: ["fluids", "health", "med", "medical", "medicine"],
  },
  fluid_balance: {
    name: "fluid_balance",
    version: 229,
    popularity: 3,
    codepoint: 63501,
    categories: ["Social"],
    tags: [
      "chart",
      "doc",
      "document",
      "file",
      "fluids",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide",
    ],
  },
  fluid_med: {
    name: "fluid_med",
    version: 229,
    popularity: 2,
    codepoint: 63500,
    categories: ["Social"],
    tags: [
      "chart",
      "doc",
      "document",
      "file",
      "fluids",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide",
    ],
  },
  fluorescent: {
    name: "fluorescent",
    version: 1,
    popularity: 374,
    codepoint: 60465,
    categories: ["device"],
    tags: ["bright", "fluorescent", "lamp", "light", "lightbulb"],
  },
  flutter: {
    name: "flutter",
    version: 229,
    popularity: 137,
    codepoint: 61917,
    categories: ["Common actions"],
    tags: [
      "app",
      "application",
      "framework",
      "logo",
      "open source",
      "program",
      "software",
    ],
  },
  flutter_dash: {
    name: "flutter_dash",
    version: 5,
    popularity: 21888,
    codepoint: 57355,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "bird",
      "dash",
      "flutter",
      "framework",
      "logo",
      "mascot",
      "open source",
      "program",
      "software",
    ],
  },
  fmd_bad: {
    name: "fmd_bad",
    version: 15,
    popularity: 5029,
    codepoint: 61454,
    categories: ["device"],
    tags: [
      "!",
      "alert",
      "attention",
      "bad",
      "caution",
      "danger",
      "destination",
      "direction",
      "error",
      "exclamation",
      "fmd",
      "important",
      "location",
      "maps",
      "mark",
      "notification",
      "pin",
      "place",
      "symbol",
      "warning",
    ],
  },
  foggy: {
    name: "foggy",
    version: 2,
    popularity: 598,
    codepoint: 59416,
    categories: ["home"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "fog",
      "hazy",
      "overcast",
      "temperature",
      "weather",
    ],
  },
  folded_hands: {
    name: "folded_hands",
    version: 229,
    popularity: 22,
    codepoint: 62957,
    categories: ["Social"],
    tags: [
      "gratitude",
      "hand",
      "hope",
      "please",
      "pray",
      "prayer",
      "spirituality",
      "thank you",
      "thanks",
    ],
  },
  folder: {
    name: "folder",
    version: 16,
    popularity: 66843,
    codepoint: 58055,
    categories: ["file"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_copy: {
    name: "folder_copy",
    version: 1,
    popularity: 4018,
    codepoint: 60349,
    categories: ["file"],
    tags: [
      "content",
      "copy",
      "cut",
      "data",
      "doc",
      "document",
      "drive",
      "duplicate",
      "file",
      "folder",
      "folders",
      "multiple",
      "paste",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_data: {
    name: "folder_data",
    version: 229,
    popularity: 1,
    codepoint: 62854,
    categories: ["Text Formatting"],
    tags: [
      "code",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "node",
      "path",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_delete: {
    name: "folder_delete",
    version: 1,
    popularity: 2339,
    codepoint: 60212,
    categories: ["file"],
    tags: [
      "bin",
      "can",
      "data",
      "delete",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "garbage",
      "remove",
      "sheet",
      "slide",
      "storage",
      "trash",
    ],
  },
  folder_managed: {
    name: "folder_managed",
    version: 229,
    popularity: 24,
    codepoint: 63349,
    categories: ["Text Formatting"],
    tags: [
      "application",
      "change",
      "data",
      "details",
      "doc",
      "document",
      "drive",
      "file",
      "gear",
      "info",
      "information",
      "options",
      "permission",
      "permissions",
      "personal",
      "service",
      "settings",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_off: {
    name: "folder_off",
    version: 1,
    popularity: 1414,
    codepoint: 60291,
    categories: ["file"],
    tags: [
      "data",
      "disabled",
      "doc",
      "document",
      "drive",
      "enabled",
      "file",
      "folder",
      "folders",
      "off",
      "on",
      "online",
      "sheet",
      "slash",
      "slide",
      "storage",
    ],
  },
  folder_open: {
    name: "folder_open",
    version: 13,
    popularity: 30195,
    codepoint: 58056,
    categories: ["file"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_shared: {
    name: "folder_shared",
    version: 16,
    popularity: 11851,
    codepoint: 58057,
    categories: ["file"],
    tags: [
      "account",
      "collaboration",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "human",
      "people",
      "person",
      "profile",
      "share",
      "shared",
      "sheet",
      "slide",
      "storage",
      "team",
      "user",
    ],
  },
  folder_special: {
    name: "folder_special",
    version: 11,
    popularity: 5908,
    codepoint: 58903,
    categories: ["notification"],
    tags: [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "favorite",
      "file",
      "folder",
      "highlight",
      "important",
      "marked",
      "save",
      "saved",
      "shape",
      "sheet",
      "slide",
      "special",
      "star",
      "storage",
    ],
  },
  folder_supervised: {
    name: "folder_supervised",
    version: 229,
    popularity: 12,
    codepoint: 63348,
    categories: ["Text Formatting"],
    tags: [
      "account",
      "body",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "human",
      "permission",
      "permissions",
      "person",
      "profile",
      "sheet",
      "slide",
      "storage",
    ],
  },
  folder_zip: {
    name: "folder_zip",
    version: 1,
    popularity: 4216,
    codepoint: 60204,
    categories: ["file"],
    tags: [
      "compress",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage",
      "zip",
    ],
  },
  follow_the_signs: {
    name: "follow_the_signs",
    version: 7,
    popularity: 5150,
    codepoint: 61986,
    categories: ["social"],
    tags: [
      "arrow",
      "body",
      "directional",
      "follow",
      "human",
      "people",
      "person",
      "right",
      "signs",
      "social",
      "the",
    ],
  },
  font_download: {
    name: "font_download",
    version: 11,
    popularity: 6117,
    codepoint: 57703,
    categories: ["content"],
    tags: [
      "A",
      "alphabet",
      "character",
      "download",
      "font",
      "letter",
      "square",
      "symbol",
      "text",
      "type",
    ],
  },
  font_download_off: {
    name: "font_download_off",
    version: 4,
    popularity: 1544,
    codepoint: 58617,
    categories: ["content"],
    tags: [
      "alphabet",
      "character",
      "disabled",
      "download",
      "enabled",
      "font",
      "letter",
      "off",
      "on",
      "slash",
      "square",
      "symbol",
      "text",
      "type",
    ],
  },
  food_bank: {
    name: "food_bank",
    version: 7,
    popularity: 5125,
    codepoint: 61938,
    categories: ["places"],
    tags: [
      "architecture",
      "bank",
      "building",
      "charity",
      "eat",
      "estate",
      "food",
      "fork",
      "house",
      "knife",
      "meal",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "utensils",
    ],
  },
  foot_bones: {
    name: "foot_bones",
    version: 229,
    popularity: 176,
    codepoint: 63635,
    categories: ["Social"],
    tags: ["body", "bone", "bones", "foot", "health", "medical", "skeleton"],
  },
  footprint: {
    name: "footprint",
    version: 229,
    popularity: 97,
    codepoint: 63613,
    categories: ["Social"],
    tags: [
      "feet",
      "foot",
      "footmark",
      "footprints",
      "footstep",
      "footsteps",
      "ground",
      "impression",
      "shoe",
      "trace",
      "walking",
    ],
  },
  for_you: {
    name: "for_you",
    version: 229,
    popularity: 38,
    codepoint: 59820,
    categories: ["Communication"],
    tags: [
      "account",
      "customize",
      "face",
      "for",
      "human",
      "people",
      "person",
      "profile",
      "user",
      "you",
    ],
  },
  forest: {
    name: "forest",
    version: 2,
    popularity: 5917,
    codepoint: 60057,
    categories: ["maps"],
    tags: [
      "forest",
      "jungle",
      "nature",
      "plantation",
      "plants",
      "trees",
      "woodland",
    ],
  },
  fork_left: {
    name: "fork_left",
    version: 1,
    popularity: 855,
    codepoint: 60320,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
    ],
  },
  fork_right: {
    name: "fork_right",
    version: 1,
    popularity: 1534,
    codepoint: 60332,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
    ],
  },
  forklift: {
    name: "forklift",
    version: 1,
    popularity: 1190,
    codepoint: 63592,
    categories: ["hardware"],
    tags: [
      "carry",
      "factory",
      "industrial",
      "lift",
      "manufactory",
      "supply",
      "truck",
      "vehicle",
    ],
  },
  format_align_center: {
    name: "format_align_center",
    version: 12,
    popularity: 6409,
    codepoint: 57908,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "center",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_align_justify: {
    name: "format_align_justify",
    version: 12,
    popularity: 4869,
    codepoint: 57909,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "density",
      "doc",
      "edit",
      "editing",
      "editor",
      "extra",
      "format",
      "justify",
      "sheet",
      "small",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_align_left: {
    name: "format_align_left",
    version: 13,
    popularity: 9799,
    codepoint: 57910,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "left",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_align_right: {
    name: "format_align_right",
    version: 13,
    popularity: 5551,
    codepoint: 57911,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "right",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_bold: {
    name: "format_bold",
    version: 12,
    popularity: 13954,
    codepoint: 57912,
    categories: ["editor"],
    tags: [
      "B",
      "alphabet",
      "bold",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "styles",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_clear: {
    name: "format_clear",
    version: 12,
    popularity: 2310,
    codepoint: 57913,
    categories: ["editor"],
    tags: [
      "T",
      "alphabet",
      "character",
      "clear",
      "disabled",
      "doc",
      "edit",
      "editing",
      "editor",
      "enabled",
      "font",
      "format",
      "letter",
      "off",
      "on",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_color_fill: {
    name: "format_color_fill",
    version: 14,
    popularity: 9425,
    codepoint: 57914,
    categories: ["editor"],
    tags: [
      "bucket",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_color_reset: {
    name: "format_color_reset",
    version: 11,
    popularity: 3128,
    codepoint: 57915,
    categories: ["editor"],
    tags: [
      "clear",
      "color",
      "disabled",
      "doc",
      "droplet",
      "edit",
      "editing",
      "editor",
      "enabled",
      "fill",
      "format",
      "off",
      "on",
      "paint",
      "reset",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "text",
      "type",
      "water",
      "writing",
    ],
  },
  format_color_text: {
    name: "format_color_text",
    version: 17,
    popularity: 5590,
    codepoint: 57916,
    categories: ["editor"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h1: {
    name: "format_h1",
    version: 229,
    popularity: 100,
    codepoint: 63581,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h2: {
    name: "format_h2",
    version: 229,
    popularity: 58,
    codepoint: 63582,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h3: {
    name: "format_h3",
    version: 229,
    popularity: 38,
    codepoint: 63583,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h4: {
    name: "format_h4",
    version: 229,
    popularity: 30,
    codepoint: 63584,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h5: {
    name: "format_h5",
    version: 229,
    popularity: 35,
    codepoint: 63585,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_h6: {
    name: "format_h6",
    version: 229,
    popularity: 35,
    codepoint: 63586,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_image_left: {
    name: "format_image_left",
    version: 229,
    popularity: 56,
    codepoint: 63587,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "left",
      "paragraph",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_image_right: {
    name: "format_image_right",
    version: 229,
    popularity: 41,
    codepoint: 63588,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paragraph",
      "right",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_indent_decrease: {
    name: "format_indent_decrease",
    version: 13,
    popularity: 2404,
    codepoint: 57917,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "decrease",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_indent_increase: {
    name: "format_indent_increase",
    version: 13,
    popularity: 3441,
    codepoint: 57918,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "increase",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_ink_highlighter: {
    name: "format_ink_highlighter",
    version: 229,
    popularity: 35,
    codepoint: 63531,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_italic: {
    name: "format_italic",
    version: 12,
    popularity: 9703,
    codepoint: 57919,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "italic",
      "letter",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_letter_spacing: {
    name: "format_letter_spacing",
    version: 229,
    popularity: 11,
    codepoint: 63347,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "arrows",
      "distribute",
      "expand",
      "left",
      "right",
      "space",
      "spread",
    ],
  },
  format_letter_spacing_2: {
    name: "format_letter_spacing_2",
    version: 229,
    popularity: 11,
    codepoint: 63000,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alphabet",
      "arrows",
      "character",
      "distribute",
      "expand",
      "font",
      "left",
      "letter",
      "right",
      "space",
      "spread",
      "text",
      "type",
    ],
  },
  format_letter_spacing_standard: {
    name: "format_letter_spacing_standard",
    version: 229,
    popularity: 17,
    codepoint: 62999,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type",
    ],
  },
  format_letter_spacing_wide: {
    name: "format_letter_spacing_wide",
    version: 229,
    popularity: 6,
    codepoint: 62998,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type",
    ],
  },
  format_letter_spacing_wider: {
    name: "format_letter_spacing_wider",
    version: 229,
    popularity: 6,
    codepoint: 62997,
    categories: ["Text Formatting"],
    tags: [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type",
    ],
  },
  format_line_spacing: {
    name: "format_line_spacing",
    version: 12,
    popularity: 2210,
    codepoint: 57920,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "line",
      "sheet",
      "spacing",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_list_bulleted: {
    name: "format_list_bulleted",
    version: 13,
    popularity: 51668,
    codepoint: 57921,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_list_bulleted_add: {
    name: "format_list_bulleted_add",
    version: 1,
    popularity: 1583,
    codepoint: 63561,
    categories: ["editor"],
    tags: [
      "+",
      "add",
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "new",
      "note",
      "notes",
      "plus",
      "quick",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_list_numbered: {
    name: "format_list_numbered",
    version: 12,
    popularity: 20947,
    codepoint: 57922,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_list_numbered_rtl: {
    name: "format_list_numbered_rtl",
    version: 12,
    popularity: 5180,
    codepoint: 57959,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "rtl",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_overline: {
    name: "format_overline",
    version: 1,
    popularity: 620,
    codepoint: 60261,
    categories: ["file"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "overline",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "writing",
    ],
  },
  format_paint: {
    name: "format_paint",
    version: 12,
    popularity: 5899,
    codepoint: 57923,
    categories: ["editor"],
    tags: [
      "brush",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "roller",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_paragraph: {
    name: "format_paragraph",
    version: 229,
    popularity: 57,
    codepoint: 63589,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paragraph",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  format_quote: {
    name: "format_quote",
    version: 12,
    popularity: 22947,
    codepoint: 57924,
    categories: ["editor"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "quotation",
      "quote",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing",
    ],
  },
  format_shapes: {
    name: "format_shapes",
    version: 12,
    popularity: 4079,
    codepoint: 57950,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "shapes",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_size: {
    name: "format_size",
    version: 12,
    popularity: 7752,
    codepoint: 57925,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "sheet",
      "size",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_strikethrough: {
    name: "format_strikethrough",
    version: 12,
    popularity: 1928,
    codepoint: 57926,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  format_text_clip: {
    name: "format_text_clip",
    version: 229,
    popularity: 4,
    codepoint: 63530,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "truncate",
      "type",
      "writing",
    ],
  },
  format_text_overflow: {
    name: "format_text_overflow",
    version: 229,
    popularity: 16,
    codepoint: 63529,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "overlap",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing",
    ],
  },
  format_text_wrap: {
    name: "format_text_wrap",
    version: 229,
    popularity: 1,
    codepoint: 63528,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "wrap",
      "writing",
    ],
  },
  format_textdirection_l_to_r: {
    name: "format_textdirection_l_to_r",
    version: 13,
    popularity: 824,
    codepoint: 57927,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "ltr",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing",
    ],
  },
  format_textdirection_r_to_l: {
    name: "format_textdirection_r_to_l",
    version: 13,
    popularity: 777,
    codepoint: 57928,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "rtl",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing",
    ],
  },
  format_underlined: {
    name: "format_underlined",
    version: 13,
    popularity: 7565,
    codepoint: 57929,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "underlined",
      "writing",
    ],
  },
  format_underlined_squiggle: {
    name: "format_underlined_squiggle",
    version: 229,
    popularity: 66,
    codepoint: 63621,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "sheet",
      "spreadsheet",
      "squiggle",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "underlined",
      "wavy",
      "writing",
    ],
  },
  forms_add_on: {
    name: "forms_add_on",
    version: 229,
    popularity: 670,
    codepoint: 61639,
    categories: ["Text Formatting"],
    tags: [],
  },
  forms_apps_script: {
    name: "forms_apps_script",
    version: 229,
    popularity: 156,
    codepoint: 61640,
    categories: ["Text Formatting"],
    tags: [],
  },
  fort: {
    name: "fort",
    version: 2,
    popularity: 1073,
    codepoint: 60077,
    categories: ["maps"],
    tags: ["castle", "fort", "fortress", "mansion", "palace"],
  },
  forum: {
    name: "forum",
    version: 19,
    popularity: 31488,
    codepoint: 57535,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "community",
      "conversation",
      "feedback",
      "forum",
      "hub",
      "message",
      "speech",
    ],
  },
  forward: {
    name: "forward",
    version: 17,
    popularity: 16134,
    codepoint: 57684,
    categories: ["content"],
    tags: ["arrow", "forward", "mail", "message", "playback", "right", "sent"],
  },
  forward_10: {
    name: "forward_10",
    version: 13,
    popularity: 6221,
    codepoint: 57430,
    categories: ["av"],
    tags: [
      "10",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "play",
      "seconds",
      "symbol",
      "video",
    ],
  },
  forward_30: {
    name: "forward_30",
    version: 13,
    popularity: 2439,
    codepoint: 57431,
    categories: ["av"],
    tags: [
      "30",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video",
    ],
  },
  forward_5: {
    name: "forward_5",
    version: 13,
    popularity: 2063,
    codepoint: 57432,
    categories: ["av"],
    tags: [
      "10",
      "5",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video",
    ],
  },
  forward_circle: {
    name: "forward_circle",
    version: 229,
    popularity: 4,
    codepoint: 63221,
    categories: ["Audio&Video"],
    tags: [
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video",
    ],
  },
  forward_media: {
    name: "forward_media",
    version: 229,
    popularity: 20,
    codepoint: 63220,
    categories: ["Audio&Video"],
    tags: [
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video",
    ],
  },
  forward_to_inbox: {
    name: "forward_to_inbox",
    version: 9,
    popularity: 16945,
    codepoint: 61831,
    categories: ["communication"],
    tags: [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to",
    ],
  },
  foundation: {
    name: "foundation",
    version: 6,
    popularity: 5396,
    codepoint: 61952,
    categories: ["places"],
    tags: [
      "architecture",
      "base",
      "basis",
      "building",
      "construction",
      "estate",
      "foundation",
      "home",
      "house",
      "real",
      "residential",
    ],
  },
  frame_inspect: {
    name: "frame_inspect",
    version: 229,
    popularity: 85,
    codepoint: 63346,
    categories: ["Text Formatting"],
    tags: [
      "border",
      "borders",
      "filter",
      "find",
      "frames",
      "look",
      "magnify",
      "magnifying glass",
      "mode",
      "search",
      "see",
    ],
  },
  frame_person: {
    name: "frame_person",
    version: 229,
    popularity: 719,
    codepoint: 63654,
    categories: ["Audio&Video"],
    tags: ["body", "center", "frame", "human", "people", "person"],
  },
  frame_person_off: {
    name: "frame_person_off",
    version: 229,
    popularity: 2,
    codepoint: 63441,
    categories: ["Audio&Video"],
    tags: [
      "body",
      "border",
      "borders",
      "center",
      "disabled",
      "enabled",
      "frames",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "slash",
    ],
  },
  frame_reload: {
    name: "frame_reload",
    version: 229,
    popularity: 4,
    codepoint: 63345,
    categories: ["Text Formatting"],
    tags: [
      "around",
      "arrow",
      "border",
      "borders",
      "frames",
      "inprogress",
      "load",
      "loading",
      "refresh",
      "renew",
      "rotate",
      "turn",
    ],
  },
  frame_source: {
    name: "frame_source",
    version: 229,
    popularity: 29,
    codepoint: 63344,
    categories: ["Text Formatting"],
    tags: [
      "border",
      "borders",
      "code",
      "coding",
      "create",
      "development",
      "frames",
    ],
  },
  free_cancellation: {
    name: "free_cancellation",
    version: 5,
    popularity: 5353,
    codepoint: 59208,
    categories: ["action"],
    tags: [
      "approve",
      "calendar",
      "cancel",
      "cancellation",
      "check",
      "clear",
      "complete",
      "date",
      "day",
      "done",
      "event",
      "exit",
      "free",
      "mark",
      "month",
      "no",
      "ok",
      "remove",
      "schedule",
      "select",
      "stop",
      "tick",
      "validate",
      "verified",
      "x",
      "yes",
    ],
  },
  front_hand: {
    name: "front_hand",
    version: 3,
    popularity: 9610,
    codepoint: 59241,
    categories: ["social"],
    tags: ["fingers", "front", "gesture", "hand", "hello", "palm", "stop"],
  },
  front_loader: {
    name: "front_loader",
    version: 1,
    popularity: 840,
    codepoint: 63593,
    categories: ["hardware"],
    tags: [
      "carry",
      "factory",
      "industrial",
      "load",
      "manufactory",
      "supply",
      "truck",
      "vehicle",
    ],
  },
  full_coverage: {
    name: "full_coverage",
    version: 229,
    popularity: 77,
    codepoint: 60178,
    categories: ["Text Formatting"],
    tags: [
      "article",
      "breaking",
      "coverage",
      "full",
      "information",
      "magazine",
      "news",
      "newspaper",
      "paper",
      "propaganda",
    ],
  },
  full_hd: {
    name: "full_hd",
    version: 229,
    popularity: 10,
    codepoint: 62859,
    categories: ["Audio&Video"],
    tags: [
      "alphabet",
      "camera",
      "character",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
      "video",
    ],
  },
  full_stacked_bar_chart: {
    name: "full_stacked_bar_chart",
    version: 229,
    popularity: 612,
    codepoint: 61970,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "full",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking",
    ],
  },
  fullscreen: {
    name: "fullscreen",
    version: 12,
    popularity: 33865,
    codepoint: 58832,
    categories: ["navigation"],
    tags: [
      "adjust",
      "app",
      "application",
      "components",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website",
    ],
  },
  fullscreen_exit: {
    name: "fullscreen_exit",
    version: 12,
    popularity: 15200,
    codepoint: 58833,
    categories: ["navigation"],
    tags: [
      "adjust",
      "app",
      "application",
      "components",
      "exit",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website",
    ],
  },
  function: {
    name: "function",
    version: 229,
    popularity: 295,
    codepoint: 63590,
    categories: ["Text Formatting"],
    tags: [
      "average",
      "calculate",
      "count",
      "custom",
      "doc",
      "edit",
      "editing",
      "editor",
      "functions",
      "math",
      "sheet",
      "spreadsheet",
      "style",
      "sum",
      "text",
      "type",
      "writing",
    ],
  },
  functions: {
    name: "functions",
    version: 12,
    popularity: 10613,
    codepoint: 57930,
    categories: ["editor"],
    tags: [
      "average",
      "calculate",
      "count",
      "custom",
      "doc",
      "edit",
      "editing",
      "editor",
      "functions",
      "math",
      "sheet",
      "spreadsheet",
      "style",
      "sum",
      "text",
      "type",
      "writing",
    ],
  },
  g_mobiledata: {
    name: "g_mobiledata",
    version: 9,
    popularity: 829,
    codepoint: 61456,
    categories: ["device"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "g",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  g_mobiledata_badge: {
    name: "g_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63457,
    categories: ["Android"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "g",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  g_translate: {
    name: "g_translate",
    version: 15,
    popularity: 13358,
    codepoint: 59687,
    categories: ["action"],
    tags: [
      "emblem",
      "g",
      "google",
      "language",
      "logo",
      "mark",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words",
    ],
  },
  gallery_thumbnail: {
    name: "gallery_thumbnail",
    version: 229,
    popularity: 50,
    codepoint: 63599,
    categories: ["Photo&Image"],
    tags: [
      "gallery",
      "grid",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "photos",
      "picture",
      "preview",
      "thumbnail",
    ],
  },
  gamepad: {
    name: "gamepad",
    version: 12,
    popularity: 4140,
    codepoint: 58127,
    categories: ["hardware"],
    tags: [
      "buttons",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video",
    ],
  },
  garage: {
    name: "garage",
    version: 10,
    popularity: 5634,
    codepoint: 61457,
    categories: ["search"],
    tags: [
      "automobile",
      "automotive",
      "car",
      "cars",
      "direction",
      "garage",
      "maps",
      "transportation",
      "travel",
      "vehicle",
    ],
  },
  garage_door: {
    name: "garage_door",
    version: 229,
    popularity: 20,
    codepoint: 59156,
    categories: ["Household"],
    tags: ["car", "door", "garage", "home", "house", "storage"],
  },
  garage_home: {
    name: "garage_home",
    version: 229,
    popularity: 1053,
    codepoint: 59437,
    categories: ["Household"],
    tags: ["car", "garage", "home", "nest", "parking", "storage"],
  },
  garden_cart: {
    name: "garden_cart",
    version: 229,
    popularity: 760,
    codepoint: 63657,
    categories: ["Social"],
    tags: ["cart", "garden", "gardening", "wheelbarrow", "yard"],
  },
  gas_meter: {
    name: "gas_meter",
    version: 1,
    popularity: 1434,
    codepoint: 60441,
    categories: ["home"],
    tags: [
      "droplet",
      "energy",
      "gas",
      "measure",
      "meter",
      "nest",
      "usage",
      "water",
    ],
  },
  gastroenterology: {
    name: "gastroenterology",
    version: 229,
    popularity: 4,
    codepoint: 57585,
    categories: ["Social"],
    tags: [
      "body",
      "digest",
      "digestive",
      "gastro",
      "gi",
      "gut",
      "guts",
      "health",
      "human",
      "intestine",
      "stomach",
      "tract",
    ],
  },
  gate: {
    name: "gate",
    version: 229,
    popularity: 441,
    codepoint: 57975,
    categories: ["Household"],
    tags: ["barrier", "door", "entrance", "gate", "nest"],
  },
  gavel: {
    name: "gavel",
    version: 14,
    popularity: 28274,
    codepoint: 59662,
    categories: ["action"],
    tags: [
      "agreement",
      "contract",
      "court",
      "document",
      "gavel",
      "government",
      "judge",
      "law",
      "mallet",
      "official",
      "police",
      "rule",
      "rules",
      "terms",
    ],
  },
  general_device: {
    name: "general_device",
    version: 229,
    popularity: 15,
    codepoint: 59102,
    categories: ["Hardware"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "general",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "remote",
      "smart",
      "wireless",
    ],
  },
  genetics: {
    name: "genetics",
    version: 229,
    popularity: 22,
    codepoint: 57587,
    categories: ["Social"],
    tags: [
      "dna",
      "gene",
      "genetic",
      "health",
      "hereditary",
      "medical",
      "science",
    ],
  },
  genres: {
    name: "genres",
    version: 229,
    popularity: 32,
    codepoint: 57378,
    categories: ["Audio&Video"],
    tags: ["circle", "genres", "music", "note", "songs", "themes"],
  },
  gesture: {
    name: "gesture",
    version: 12,
    popularity: 5573,
    codepoint: 57685,
    categories: ["content"],
    tags: ["drawing", "finger", "gesture", "gestures", "hand", "motion"],
  },
  gesture_select: {
    name: "gesture_select",
    version: 229,
    popularity: 22,
    codepoint: 63063,
    categories: ["Common actions"],
    tags: ["dash", "dashed", "finger", "hand", "pointer", "selection"],
  },
  gif: {
    name: "gif",
    version: 14,
    popularity: 5052,
    codepoint: 59656,
    categories: ["action"],
    tags: [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type",
    ],
  },
  gif_box: {
    name: "gif_box",
    version: 3,
    popularity: 3822,
    codepoint: 59299,
    categories: ["action"],
    tags: [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type",
    ],
  },
  girl: {
    name: "girl",
    version: 1,
    popularity: 2505,
    codepoint: 60264,
    categories: ["social"],
    tags: [
      "body",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "people",
      "person",
      "social",
      "symbol",
      "woman",
      "women",
    ],
  },
  gite: {
    name: "gite",
    version: 4,
    popularity: 5009,
    codepoint: 58763,
    categories: ["places"],
    tags: [
      "architecture",
      "estate",
      "gite",
      "home",
      "hostel",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
    ],
  },
  glass_cup: {
    name: "glass_cup",
    version: 229,
    popularity: 10,
    codepoint: 63203,
    categories: ["Activities"],
    tags: ["drink", "drinking", "fitbit", "water", "waterglass"],
  },
  globe: {
    name: "globe",
    version: 229,
    popularity: 71,
    codepoint: 58956,
    categories: ["Social"],
    tags: [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "website",
      "world",
      "www",
    ],
  },
  globe_asia: {
    name: "globe_asia",
    version: 229,
    popularity: 73,
    codepoint: 63385,
    categories: ["Social"],
    tags: [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "southeast",
      "website",
      "world",
      "www",
    ],
  },
  globe_uk: {
    name: "globe_uk",
    version: 229,
    popularity: 38,
    codepoint: 63384,
    categories: ["Social"],
    tags: [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "united kingdom",
      "website",
      "world",
      "www",
    ],
  },
  glucose: {
    name: "glucose",
    version: 229,
    popularity: 24,
    codepoint: 58528,
    categories: ["Social"],
    tags: [
      "blood",
      "blood sugar",
      "blood test",
      "body",
      "diabetes",
      "drop",
      "droplet",
      "finger",
      "hand",
      "health",
      "human",
      "medical",
      "test",
    ],
  },
  glyphs: {
    name: "glyphs",
    version: 229,
    popularity: 303,
    codepoint: 63651,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "ampersand",
      "character",
      "emoji",
      "hieroglyph",
      "percent",
      "sign",
      "symbols",
      "tester",
      "type",
    ],
  },
  go_to_line: {
    name: "go_to_line",
    version: 229,
    popularity: 18,
    codepoint: 63261,
    categories: ["UI actions"],
    tags: ["dots", "goto", "jump", "move", "squares"],
  },
  golf_course: {
    name: "golf_course",
    version: 11,
    popularity: 4492,
    codepoint: 60229,
    categories: ["places"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "club",
      "course",
      "entertainment",
      "flag",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "hole",
      "places",
      "putt",
      "sports",
    ],
  },
  google_home_devices: {
    name: "google_home_devices",
    version: 229,
    popularity: 69,
    codepoint: 59157,
    categories: ["Hardware"],
    tags: [
      "chrome",
      "desktop",
      "device",
      "devices",
      "google",
      "hardware",
      "home",
      "monitor",
      "music",
      "sound",
      "speaker",
      "web",
    ],
  },
  google_tv_remote: {
    name: "google_tv_remote",
    version: 229,
    popularity: 6,
    codepoint: 62939,
    categories: ["Home"],
    tags: ["controller", "device", "google", "hardware", "nest", "remote"],
  },
  google_wifi: {
    name: "google_wifi",
    version: 229,
    popularity: 284,
    codepoint: 59456,
    categories: ["Home"],
    tags: [
      "cellular",
      "connection",
      "data",
      "device",
      "gale",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  gpp_bad: {
    name: "gpp_bad",
    version: 10,
    popularity: 6973,
    codepoint: 61458,
    categories: ["device"],
    tags: [
      "bad",
      "cancel",
      "certified",
      "close",
      "error",
      "exit",
      "gpp",
      "no",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "sim",
      "stop",
      "verified",
      "x",
    ],
  },
  gpp_maybe: {
    name: "gpp_maybe",
    version: 10,
    popularity: 8325,
    codepoint: 61460,
    categories: ["device"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "certified",
      "danger",
      "error",
      "exclamation",
      "gpp",
      "important",
      "mark",
      "maybe",
      "notification",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "sim",
      "symbol",
      "verified",
      "warning",
    ],
  },
  grade: {
    name: "grade",
    version: 16,
    popularity: 56678,
    codepoint: 59525,
    categories: ["action"],
    tags: [
      "'favorite_news'  .",
      "'star_outline'",
      "Duplicate of 'star_boarder'",
      "star_border_purple500'",
    ],
  },
  gradient: {
    name: "gradient",
    version: 12,
    popularity: 2965,
    codepoint: 58345,
    categories: ["image"],
    tags: [
      "color",
      "edit",
      "editing",
      "effect",
      "filter",
      "gradient",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
    ],
  },
  grading: {
    name: "grading",
    version: 12,
    popularity: 17356,
    codepoint: 59983,
    categories: ["action"],
    tags: [
      "'favorite'_new'.  ' Remove this icon & keep 'star'.",
      "'star_boarder'",
      "'star_border_purple500'",
      "'star_outline'",
      "'star_purple500'",
      "'star_rate'",
      "Same as 'star'",
    ],
  },
  grain: {
    name: "grain",
    version: 12,
    popularity: 5093,
    codepoint: 58346,
    categories: ["image"],
    tags: [
      "dots",
      "edit",
      "editing",
      "effect",
      "filter",
      "grain",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
    ],
  },
  graphic_eq: {
    name: "graphic_eq",
    version: 13,
    popularity: 11636,
    codepoint: 57784,
    categories: ["device"],
    tags: [
      "audio",
      "detect",
      "detection",
      "eq",
      "equalizer",
      "graphic",
      "music",
      "noise",
      "recording",
      "sound",
      "voice",
    ],
  },
  grass: {
    name: "grass",
    version: 7,
    popularity: 10718,
    codepoint: 61957,
    categories: ["places"],
    tags: [
      "backyard",
      "fodder",
      "grass",
      "ground",
      "home",
      "lawn",
      "plant",
      "turf",
      "yard",
    ],
  },
  grid_3x3: {
    name: "grid_3x3",
    version: 10,
    popularity: 1861,
    codepoint: 61461,
    categories: ["device"],
    tags: ["3", "grid", "layout", "line", "space"],
  },
  grid_3x3_off: {
    name: "grid_3x3_off",
    version: 229,
    popularity: 1,
    codepoint: 63100,
    categories: ["Android"],
    tags: [
      "3",
      "disabled",
      "enabled",
      "grid",
      "layout",
      "line",
      "off",
      "on",
      "slash",
      "space",
    ],
  },
  grid_4x4: {
    name: "grid_4x4",
    version: 10,
    popularity: 2884,
    codepoint: 61462,
    categories: ["device"],
    tags: ["4", "by", "grid", "layout", "lines", "space"],
  },
  grid_goldenratio: {
    name: "grid_goldenratio",
    version: 10,
    popularity: 1069,
    codepoint: 61463,
    categories: ["device"],
    tags: [
      "golden",
      "goldenratio",
      "grid",
      "layout",
      "lines",
      "ratio",
      "space",
    ],
  },
  grid_guides: {
    name: "grid_guides",
    version: 229,
    popularity: 24,
    codepoint: 63343,
    categories: ["Text Formatting"],
    tags: [
      "grids",
      "guide",
      "guideline",
      "guidelines",
      "placeholder",
      "template",
    ],
  },
  grid_off: {
    name: "grid_off",
    version: 12,
    popularity: 1427,
    codepoint: 58347,
    categories: ["image"],
    tags: [
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view",
    ],
  },
  grid_on: {
    name: "grid_on",
    version: 12,
    popularity: 9870,
    codepoint: 58348,
    categories: ["image"],
    tags: [
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view",
    ],
  },
  grid_view: {
    name: "grid_view",
    version: 12,
    popularity: 61329,
    codepoint: 59824,
    categories: ["file"],
    tags: [
      "app",
      "application square",
      "blocks",
      "components",
      "dashboard",
      "design",
      "grid",
      "interface",
      "layout",
      "screen",
      "site",
      "tiles",
      "ui",
      "ux",
      "view",
      "web",
      "website",
      "window",
    ],
  },
  grocery: {
    name: "grocery",
    version: 229,
    popularity: 74,
    codepoint: 61335,
    categories: ["Household"],
    tags: [
      "apple",
      "bottle",
      "drink",
      "food",
      "fruit",
      "grocery",
      "ingredients",
      "milk",
      "produce",
    ],
  },
  group: {
    name: "group",
    version: 19,
    popularity: 57963,
    codepoint: 59375,
    categories: ["social"],
    tags: [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users",
    ],
  },
  group_add: {
    name: "group_add",
    version: 18,
    popularity: 39742,
    codepoint: 59376,
    categories: ["social"],
    tags: [
      "accounts",
      "add",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "increase",
      "more",
      "network",
      "people",
      "persons",
      "plus",
      "profiles",
      "social",
      "team",
      "users",
    ],
  },
  group_off: {
    name: "group_off",
    version: 5,
    popularity: 2771,
    codepoint: 59207,
    categories: ["social"],
    tags: [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "group",
      "human",
      "meeting",
      "off",
      "people",
      "person",
      "social",
      "teams",
    ],
  },
  group_remove: {
    name: "group_remove",
    version: 3,
    popularity: 3276,
    codepoint: 59309,
    categories: ["social"],
    tags: [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "remove",
      "social",
      "team",
      "users",
    ],
  },
  group_work: {
    name: "group_work",
    version: 12,
    popularity: 19826,
    codepoint: 59526,
    categories: ["action"],
    tags: [
      "alliance",
      "collaboration",
      "group",
      "partnership",
      "team",
      "teamwork",
      "together",
      "work",
    ],
  },
  grouped_bar_chart: {
    name: "grouped_bar_chart",
    version: 229,
    popularity: 860,
    codepoint: 61969,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "grouped",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  groups: {
    name: "groups",
    version: 6,
    popularity: 160805,
    codepoint: 62003,
    categories: ["social"],
    tags: [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams",
    ],
  },
  groups_2: {
    name: "groups_2",
    version: 1,
    popularity: 3718,
    codepoint: 63711,
    categories: ["social"],
    tags: [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "hair",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams",
    ],
  },
  groups_3: {
    name: "groups_3",
    version: 1,
    popularity: 2240,
    codepoint: 63712,
    categories: ["social"],
    tags: [
      "abstract",
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams",
    ],
  },
  gynecology: {
    name: "gynecology",
    version: 229,
    popularity: 4,
    codepoint: 57588,
    categories: ["Social"],
    tags: [
      "body",
      "cervix",
      "fallopian",
      "gyno",
      "health",
      "human",
      "obgyn",
      "ovaries",
      "ovary",
      "reproductive",
      "uterus",
    ],
  },
  h_mobiledata: {
    name: "h_mobiledata",
    version: 9,
    popularity: 764,
    codepoint: 61464,
    categories: ["device"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  h_mobiledata_badge: {
    name: "h_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63456,
    categories: ["Android"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  h_plus_mobiledata: {
    name: "h_plus_mobiledata",
    version: 9,
    popularity: 672,
    codepoint: 61465,
    categories: ["device"],
    tags: [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "plus",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  h_plus_mobiledata_badge: {
    name: "h_plus_mobiledata_badge",
    version: 229,
    popularity: 2,
    codepoint: 63455,
    categories: ["Android"],
    tags: [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "plus",
      "service",
      "symbol",
      "text",
      "type",
    ],
  },
  hail: {
    name: "hail",
    version: 11,
    popularity: 6716,
    codepoint: 59825,
    categories: ["maps"],
    tags: [
      "body",
      "hail",
      "human",
      "people",
      "person",
      "pick",
      "public",
      "stop",
      "taxi",
      "transportation",
    ],
  },
  hallway: {
    name: "hallway",
    version: 229,
    popularity: 7,
    codepoint: 59128,
    categories: ["Household"],
    tags: [
      "frame",
      "hallway",
      "hanging",
      "home",
      "house",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
    ],
  },
  hand_bones: {
    name: "hand_bones",
    version: 229,
    popularity: 293,
    codepoint: 63636,
    categories: ["Social"],
    tags: [
      "body",
      "bone",
      "bones",
      "fingers",
      "hand",
      "health",
      "medical",
      "palm",
      "skeleton",
    ],
  },
  hand_gesture: {
    name: "hand_gesture",
    version: 229,
    popularity: 948,
    codepoint: 61340,
    categories: ["Common actions"],
    tags: ["fingers", "gesture", "hand", "palm"],
  },
  handshake: {
    name: "handshake",
    version: 1,
    popularity: 18930,
    codepoint: 60363,
    categories: ["social"],
    tags: ["agreement", "hand", "hands", "partnership", "shake"],
  },
  handyman: {
    name: "handyman",
    version: 12,
    popularity: 24836,
    codepoint: 61707,
    categories: ["maps"],
    tags: [
      "build",
      "construction",
      "fix",
      "hammer",
      "handyman",
      "repair",
      "screw",
      "screwdriver",
      "tools",
    ],
  },
  hangout_video: {
    name: "hangout_video",
    version: 229,
    popularity: 32,
    codepoint: 57537,
    categories: ["Audio&Video"],
    tags: [
      "camera",
      "film",
      "filming",
      "google",
      "hangout",
      "image",
      "motion",
      "picture",
      "rectangle",
      "video",
      "videography",
    ],
  },
  hangout_video_off: {
    name: "hangout_video_off",
    version: 229,
    popularity: 9,
    codepoint: 57538,
    categories: ["Audio&Video"],
    tags: [
      "camera",
      "disabled",
      "enabled",
      "film",
      "filming",
      "google",
      "hangout",
      "image",
      "motion",
      "off",
      "on",
      "picture",
      "rectangle",
      "slash",
      "video",
      "videography",
    ],
  },
  hard_drive: {
    name: "hard_drive",
    version: 229,
    popularity: 16,
    codepoint: 63502,
    categories: ["Hardware"],
    tags: ["documents", "health", "save", "storage", "store"],
  },
  hard_drive_2: {
    name: "hard_drive_2",
    version: 229,
    popularity: 7,
    codepoint: 63396,
    categories: ["Hardware"],
    tags: ["data", "documents", "save", "storage", "store"],
  },
  hardware: {
    name: "hardware",
    version: 10,
    popularity: 4698,
    codepoint: 59993,
    categories: ["maps"],
    tags: [
      "break",
      "construction",
      "hammer",
      "hardware",
      "nail",
      "repair",
      "tool",
    ],
  },
  hd: {
    name: "hd",
    version: 12,
    popularity: 2991,
    codepoint: 57426,
    categories: ["av"],
    tags: [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "movies",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
    ],
  },
  hdr_auto: {
    name: "hdr_auto",
    version: 10,
    popularity: 2656,
    codepoint: 61466,
    categories: ["device"],
    tags: [
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_auto_select: {
    name: "hdr_auto_select",
    version: 10,
    popularity: 613,
    codepoint: 61467,
    categories: ["device"],
    tags: [
      "+",
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_enhanced_select: {
    name: "hdr_enhanced_select",
    version: 11,
    popularity: 667,
    codepoint: 61265,
    categories: ["image"],
    tags: [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_off: {
    name: "hdr_off",
    version: 11,
    popularity: 577,
    codepoint: 58349,
    categories: ["image"],
    tags: [
      "alphabet",
      "character",
      "disabled",
      "dynamic",
      "enabled",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_off_select: {
    name: "hdr_off_select",
    version: 10,
    popularity: 572,
    codepoint: 61468,
    categories: ["device"],
    tags: [
      "alphabet",
      "camera",
      "character",
      "circle",
      "disabled",
      "dynamic",
      "enabled",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "photo",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_on: {
    name: "hdr_on",
    version: 11,
    popularity: 1061,
    codepoint: 58350,
    categories: ["image"],
    tags: [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_on_select: {
    name: "hdr_on_select",
    version: 10,
    popularity: 643,
    codepoint: 61469,
    categories: ["device"],
    tags: [
      "+",
      "alphabet",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_plus: {
    name: "hdr_plus",
    version: 10,
    popularity: 771,
    codepoint: 61470,
    categories: ["image"],
    tags: [
      "+",
      "add",
      "alphabet",
      "character",
      "circle",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type",
    ],
  },
  hdr_plus_off: {
    name: "hdr_plus_off",
    version: 229,
    popularity: 1,
    codepoint: 58351,
    categories: ["Photo&Image"],
    tags: [
      "+",
      "disabled",
      "enabled",
      "hdr",
      "image",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "plus",
      "slash",
    ],
  },
  hdr_strong: {
    name: "hdr_strong",
    version: 12,
    popularity: 2479,
    codepoint: 58353,
    categories: ["image"],
    tags: [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "strong",
    ],
  },
  hdr_weak: {
    name: "hdr_weak",
    version: 12,
    popularity: 1986,
    codepoint: 58354,
    categories: ["image"],
    tags: [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "weak",
    ],
  },
  headphones: {
    name: "headphones",
    version: 9,
    popularity: 16257,
    codepoint: 61471,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound",
    ],
  },
  headphones_battery: {
    name: "headphones_battery",
    version: 9,
    popularity: 1398,
    codepoint: 61472,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "battery",
      "charging",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound",
    ],
  },
  headset_mic: {
    name: "headset_mic",
    version: 12,
    popularity: 15204,
    codepoint: 58129,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "chat",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "sound",
      "talk",
    ],
  },
  headset_off: {
    name: "headset_off",
    version: 12,
    popularity: 1810,
    codepoint: 58170,
    categories: ["hardware"],
    tags: [
      "accessory",
      "audio",
      "chat",
      "device",
      "disabled",
      "ear",
      "earphone",
      "enabled",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "talk",
    ],
  },
  healing: {
    name: "healing",
    version: 12,
    popularity: 7081,
    codepoint: 58355,
    categories: ["image"],
    tags: [
      "bandage",
      "edit",
      "editing",
      "emergency",
      "fix",
      "healing",
      "hospital",
      "image",
      "medicine",
    ],
  },
  health_and_beauty: {
    name: "health_and_beauty",
    version: 229,
    popularity: 110,
    codepoint: 61341,
    categories: ["Household"],
    tags: [
      "and",
      "beauty",
      "care",
      "comb",
      "hair",
      "health",
      "makeup",
      "nail",
      "polish",
      "self",
    ],
  },
  health_and_safety: {
    name: "health_and_safety",
    version: 7,
    popularity: 32815,
    codepoint: 57813,
    categories: ["social"],
    tags: [
      "+",
      "add",
      "and",
      "certified",
      "cross",
      "health",
      "home",
      "nest",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "security",
      "shield",
      "symbol",
      "verified",
    ],
  },
  health_metrics: {
    name: "health_metrics",
    version: 229,
    popularity: 99,
    codepoint: 63202,
    categories: ["Activities"],
    tags: [
      "blood oxygen saturation",
      "body metrics",
      "breathing rate",
      "chart",
      "diagram",
      "fitness",
      "graph",
      "health",
      "health dashboard",
      "health metric dashboard",
      "health monitoring",
      "heart rate variability",
      "measure",
      "metric",
      "oxygen",
      "resting heart rate",
      "skin temperature",
    ],
  },
  heap_snapshot_large: {
    name: "heap_snapshot_large",
    version: 229,
    popularity: 5,
    codepoint: 63342,
    categories: ["Text Formatting"],
    tags: [
      "data",
      "doc",
      "document",
      "file",
      "java",
      "page",
      "paper",
      "percent",
      "percentage",
    ],
  },
  heap_snapshot_multiple: {
    name: "heap_snapshot_multiple",
    version: 229,
    popularity: 6,
    codepoint: 63341,
    categories: ["Text Formatting"],
    tags: [
      "copy",
      "create",
      "data",
      "doc",
      "document",
      "duplicate",
      "file",
      "java",
      "page",
      "paper",
      "percent",
      "percentage",
      "stack",
    ],
  },
  heap_snapshot_thumbnail: {
    name: "heap_snapshot_thumbnail",
    version: 229,
    popularity: 7,
    codepoint: 63340,
    categories: ["Text Formatting"],
    tags: [
      "data",
      "gallery",
      "grid",
      "java",
      "percent",
      "percentage",
      "photo",
      "photos",
      "preview",
    ],
  },
  hearing: {
    name: "hearing",
    version: 12,
    popularity: 8114,
    codepoint: 57379,
    categories: ["av"],
    tags: [
      "accessibility",
      "accessible",
      "aid",
      "ear",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "mono",
      "sound",
      "volume",
    ],
  },
  hearing_disabled: {
    name: "hearing_disabled",
    version: 14,
    popularity: 2073,
    codepoint: 61700,
    categories: ["av"],
    tags: [
      "accessibility",
      "accessible",
      "aid",
      "disabled",
      "ear",
      "enabled",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "off",
      "on",
      "slash",
      "sound",
      "volume",
    ],
  },
  heart_broken: {
    name: "heart_broken",
    version: 2,
    popularity: 5655,
    codepoint: 60098,
    categories: ["social"],
    tags: [
      "break",
      "broken",
      "core",
      "crush",
      "health",
      "heart",
      "nucleus",
      "split",
    ],
  },
  heart_check: {
    name: "heart_check",
    version: 229,
    popularity: 30,
    codepoint: 62986,
    categories: ["UI actions"],
    tags: [
      "appreciate",
      "approve",
      "checkmark",
      "complete",
      "done",
      "favorite",
      "like",
      "love",
      "ok",
      "remember",
      "save",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  heart_minus: {
    name: "heart_minus",
    version: 229,
    popularity: 775,
    codepoint: 63619,
    categories: ["UI actions"],
    tags: [
      "-",
      "appreciate",
      "delete",
      "favorite",
      "like",
      "love",
      "minus",
      "remember",
      "remove",
      "save",
      "subtract",
    ],
  },
  heart_plus: {
    name: "heart_plus",
    version: 229,
    popularity: 2372,
    codepoint: 63620,
    categories: ["UI actions"],
    tags: [
      "+",
      "add",
      "appreciate",
      "favorite",
      "like",
      "love",
      "new",
      "plus",
      "remember",
      "save",
    ],
  },
  heat: {
    name: "heat",
    version: 229,
    popularity: 13,
    codepoint: 62775,
    categories: ["Home"],
    tags: [
      "",
      "air",
      "airwave",
      "bismuth",
      "blowing",
      "breeze",
      "climate",
      "flow",
      "home",
      "hot",
      "nest",
      "steam",
      "temperature",
      "thermostat",
      "wave",
      "weather",
      "wind",
    ],
  },
  heat_pump: {
    name: "heat_pump",
    version: 1,
    popularity: 1595,
    codepoint: 60440,
    categories: ["home"],
    tags: [
      "air conditioner",
      "cool",
      "energy",
      "furnance",
      "heat",
      "nest",
      "pump",
      "usage",
    ],
  },
  heat_pump_balance: {
    name: "heat_pump_balance",
    version: 229,
    popularity: 177,
    codepoint: 57982,
    categories: ["Home"],
    tags: ["climate", "heat", "home", "nest", "pump", "thermostat"],
  },
  height: {
    name: "height",
    version: 11,
    popularity: 7089,
    codepoint: 59926,
    categories: ["editor"],
    tags: [
      "arrow",
      "color",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "height",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "up",
      "writing",
    ],
  },
  helicopter: {
    name: "helicopter",
    version: 229,
    popularity: 13,
    codepoint: 62988,
    categories: ["Social"],
    tags: ["aircraft", "chopper", "plane", "rotorcraft"],
  },
  help: {
    name: "help",
    version: 13,
    popularity: 106872,
    codepoint: 59527,
    categories: ["action"],
    tags: [
      "?",
      "assistance",
      "circle",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "shape",
      "support",
      "symbol",
    ],
  },
  help_center: {
    name: "help_center",
    version: 9,
    popularity: 24791,
    codepoint: 61888,
    categories: ["action"],
    tags: [
      "?",
      "assistance",
      "center",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "support",
      "symbol",
    ],
  },
  help_clinic: {
    name: "help_clinic",
    version: 229,
    popularity: 10,
    codepoint: 63504,
    categories: ["Common actions"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "details",
      "health",
      "help",
      "home",
      "household",
      "i",
      "info",
      "information",
      "service",
      "support",
    ],
  },
  hematology: {
    name: "hematology",
    version: 229,
    popularity: 3,
    codepoint: 57590,
    categories: ["Social"],
    tags: [
      "blood",
      "body",
      "cell",
      "cells",
      "health",
      "human",
      "vein",
      "veins",
      "vessel",
    ],
  },
  hevc: {
    name: "hevc",
    version: 10,
    popularity: 650,
    codepoint: 61473,
    categories: ["image"],
    tags: [
      "alphabet",
      "character",
      "coding",
      "efficiency",
      "font",
      "hevc",
      "high",
      "letter",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  hexagon: {
    name: "hexagon",
    version: 1,
    popularity: 2928,
    codepoint: 60217,
    categories: ["editor"],
    tags: ["hexagon", "shape", "six sides"],
  },
  hide: {
    name: "hide",
    version: 229,
    popularity: 1006,
    codepoint: 61342,
    categories: ["UI actions"],
    tags: [],
  },
  hide_image: {
    name: "hide_image",
    version: 10,
    popularity: 2750,
    codepoint: 61474,
    categories: ["image"],
    tags: [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
    ],
  },
  hide_source: {
    name: "hide_source",
    version: 9,
    popularity: 9139,
    codepoint: 61475,
    categories: ["action"],
    tags: [
      "circle",
      "disabled",
      "enabled",
      "hide",
      "off",
      "offline",
      "on",
      "shape",
      "slash",
      "source",
    ],
  },
  high_density: {
    name: "high_density",
    version: 229,
    popularity: 2,
    codepoint: 63388,
    categories: ["Photo&Image"],
    tags: ["dash", "dashed", "dense", "output", "quality", "screen"],
  },
  high_quality: {
    name: "high_quality",
    version: 12,
    popularity: 4066,
    codepoint: 57380,
    categories: ["av"],
    tags: [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "high",
      "hq",
      "letter",
      "movie",
      "movies",
      "quality",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
    ],
  },
  high_res: {
    name: "high_res",
    version: 229,
    popularity: 5,
    codepoint: 62795,
    categories: ["Photo&Image"],
    tags: [
      "alphabet",
      "camera",
      "character",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
      "video",
    ],
  },
  highlight: {
    name: "highlight",
    version: 14,
    popularity: 5041,
    codepoint: 57951,
    categories: ["editor"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "emphasize",
      "fill",
      "flash",
      "format",
      "highlight",
      "light",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  highlighter_size_1: {
    name: "highlighter_size_1",
    version: 229,
    popularity: 0,
    codepoint: 63339,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  highlighter_size_2: {
    name: "highlighter_size_2",
    version: 229,
    popularity: 0,
    codepoint: 63338,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  highlighter_size_3: {
    name: "highlighter_size_3",
    version: 229,
    popularity: 8,
    codepoint: 63337,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  highlighter_size_4: {
    name: "highlighter_size_4",
    version: 229,
    popularity: 1,
    codepoint: 63336,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  highlighter_size_5: {
    name: "highlighter_size_5",
    version: 229,
    popularity: 2,
    codepoint: 63335,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  hiking: {
    name: "hiking",
    version: 4,
    popularity: 10642,
    codepoint: 58634,
    categories: ["social"],
    tags: [
      "backpacking",
      "bag",
      "climbing",
      "duffle",
      "hiking",
      "mountain",
      "social",
      "sports",
      "stick",
      "trail",
      "travel",
      "walking",
    ],
  },
  history: {
    name: "history",
    version: 12,
    popularity: 85270,
    codepoint: 59529,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn",
    ],
  },
  history_edu: {
    name: "history_edu",
    version: 11,
    popularity: 22783,
    codepoint: 59966,
    categories: ["social"],
    tags: [
      "document",
      "edu",
      "education",
      "feather",
      "history",
      "letter",
      "paper",
      "pen",
      "quill",
      "school",
      "story",
      "tools",
      "write",
      "writing",
    ],
  },
  history_toggle_off: {
    name: "history_toggle_off",
    version: 9,
    popularity: 9973,
    codepoint: 61821,
    categories: ["action"],
    tags: [
      "clock",
      "dash",
      "dashed",
      "date",
      "history",
      "off",
      "schedule",
      "time",
      "toggle",
    ],
  },
  hive: {
    name: "hive",
    version: 2,
    popularity: 4118,
    codepoint: 60070,
    categories: ["social"],
    tags: ["bee", "honey", "honeycomb"],
  },
  hls: {
    name: "hls",
    version: 1,
    popularity: 639,
    codepoint: 60298,
    categories: ["action"],
    tags: [
      "alphabet",
      "character",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "platform",
      "symbol",
      "text",
      "type",
    ],
  },
  hls_off: {
    name: "hls_off",
    version: 1,
    popularity: 638,
    codepoint: 60300,
    categories: ["action"],
    tags: [
      "alphabet",
      "character",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "off",
      "offline",
      "on",
      "platform",
      "slash",
      "symbol",
      "text",
      "type",
    ],
  },
  holiday_village: {
    name: "holiday_village",
    version: 4,
    popularity: 8106,
    codepoint: 58762,
    categories: ["places"],
    tags: [
      "architecture",
      "beach",
      "camping",
      "cottage",
      "estate",
      "holiday",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "vacation",
      "village",
    ],
  },
  home: {
    name: "home",
    version: 16,
    popularity: 710930,
    codepoint: 59530,
    categories: ["action"],
    tags: [
      "address",
      "app",
      "application--house",
      "architecture",
      "building",
      "components",
      "design",
      "estate",
      "home",
      "interface",
      "layout",
      "place",
      "real",
      "residence",
      "residential",
      "screen",
      "shelter",
      "site",
      "structure",
      "ui",
      "unit",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  home_and_garden: {
    name: "home_and_garden",
    version: 229,
    popularity: 18,
    codepoint: 61343,
    categories: ["Household"],
    tags: [
      "and",
      "architecture",
      "building",
      "estate",
      "flower",
      "garden",
      "home",
      "house",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
    ],
  },
  home_app_logo: {
    name: "home_app_logo",
    version: 229,
    popularity: 1829,
    codepoint: 58005,
    categories: ["Common actions"],
    tags: ["app", "home", "house", "logo", "nest"],
  },
  home_health: {
    name: "home_health",
    version: 229,
    popularity: 27,
    codepoint: 58553,
    categories: ["Social"],
    tags: [
      "care",
      "clinic",
      "health",
      "home",
      "hospital",
      "household",
      "medical",
    ],
  },
  home_improvement_and_tools: {
    name: "home_improvement_and_tools",
    version: 229,
    popularity: 12,
    codepoint: 61344,
    categories: ["Household"],
    tags: ["and", "home", "improvement", "nail", "screw", "tools"],
  },
  home_iot_device: {
    name: "home_iot_device",
    version: 229,
    popularity: 454,
    codepoint: 57987,
    categories: ["Household"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless",
    ],
  },
  home_max: {
    name: "home_max",
    version: 10,
    popularity: 1457,
    codepoint: 61476,
    categories: ["hardware"],
    tags: [
      "device",
      "gadget",
      "hardware",
      "home",
      "internet",
      "iot",
      "max",
      "nest",
      "smart",
      "things",
    ],
  },
  home_max_dots: {
    name: "home_max_dots",
    version: 229,
    popularity: 209,
    codepoint: 59465,
    categories: ["Home"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "dots",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless",
    ],
  },
  home_mini: {
    name: "home_mini",
    version: 10,
    popularity: 1160,
    codepoint: 61477,
    categories: ["hardware"],
    tags: [
      "Internet",
      "device",
      "gadget",
      "hardware",
      "home",
      "iot",
      "mini",
      "nest",
      "smart",
      "things",
    ],
  },
  home_pin: {
    name: "home_pin",
    version: 229,
    popularity: 10546,
    codepoint: 61773,
    categories: ["Maps"],
    tags: [
      "destination",
      "direction",
      "home",
      "house",
      "location",
      "maps",
      "pin",
      "place",
      "stop",
    ],
  },
  home_repair_service: {
    name: "home_repair_service",
    version: 12,
    popularity: 13757,
    codepoint: 61696,
    categories: ["maps"],
    tags: [
      "box",
      "equipment",
      "fix",
      "home",
      "kit",
      "mechanic",
      "repair",
      "repairing",
      "service",
      "tool",
      "toolbox",
      "tools",
      "workshop",
    ],
  },
  home_speaker: {
    name: "home_speaker",
    version: 229,
    popularity: 226,
    codepoint: 61724,
    categories: ["Home"],
    tags: [
      "airplay",
      "cast",
      "connect",
      "device",
      "google",
      "hardware",
      "home",
      "nest",
      "screencast",
      "speaker",
      "streaming",
      "wireless",
    ],
  },
  home_storage: {
    name: "home_storage",
    version: 229,
    popularity: 319,
    codepoint: 63596,
    categories: ["Home"],
    tags: ["file", "files", "home", "storage"],
  },
  home_work: {
    name: "home_work",
    version: 13,
    popularity: 15928,
    codepoint: 59913,
    categories: ["navigation"],
    tags: [
      "architecture",
      "building",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "work",
    ],
  },
  horizontal_distribute: {
    name: "horizontal_distribute",
    version: 6,
    popularity: 2159,
    codepoint: 57364,
    categories: ["editor"],
    tags: [
      "alignment",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
    ],
  },
  horizontal_rule: {
    name: "horizontal_rule",
    version: 13,
    popularity: 9799,
    codepoint: 61704,
    categories: ["editor"],
    tags: ["gmail", "horizontal", "line", "novitas", "rule"],
  },
  horizontal_split: {
    name: "horizontal_split",
    version: 15,
    popularity: 4545,
    codepoint: 59719,
    categories: ["action"],
    tags: [
      "bars",
      "format",
      "horizontal",
      "layout",
      "lines",
      "split",
      "stacked",
    ],
  },
  hot_tub: {
    name: "hot_tub",
    version: 12,
    popularity: 3144,
    codepoint: 60230,
    categories: ["places"],
    tags: [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "hot",
      "hotel",
      "human",
      "jacuzzi",
      "person",
      "shower",
      "spa",
      "steam",
      "travel",
      "tub",
      "water",
    ],
  },
  hotel: {
    name: "hotel",
    version: 19,
    popularity: 13199,
    codepoint: 58682,
    categories: ["maps"],
    tags: [
      "body",
      "hotel",
      "human",
      "people",
      "person",
      "sleep",
      "stay",
      "travel",
      "trip",
    ],
  },
  hotel_class: {
    name: "hotel_class",
    version: 6,
    popularity: 8345,
    codepoint: 59203,
    categories: ["action"],
    tags: [
      "achievement",
      "bookmark",
      "class",
      "favorite",
      "highlight",
      "hotel",
      "important",
      "marked",
      "rank",
      "ranking",
      "rate",
      "rating",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
    ],
  },
  hourglass: {
    name: "hourglass",
    version: 229,
    popularity: 19,
    codepoint: 60415,
    categories: ["Common actions"],
    tags: [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting",
    ],
  },
  hourglass_bottom: {
    name: "hourglass_bottom",
    version: 11,
    popularity: 16407,
    codepoint: 59996,
    categories: ["communication"],
    tags: [
      "bottom",
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "wait",
      "waiting",
    ],
  },
  hourglass_disabled: {
    name: "hourglass_disabled",
    version: 12,
    popularity: 3541,
    codepoint: 61267,
    categories: ["action"],
    tags: [
      "clock",
      "countdown",
      "disabled",
      "empty",
      "enabled",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "off",
      "on",
      "slash",
      "time",
      "wait",
      "waiting",
    ],
  },
  hourglass_empty: {
    name: "hourglass_empty",
    version: 15,
    popularity: 24785,
    codepoint: 59531,
    categories: ["action"],
    tags: [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting",
    ],
  },
  hourglass_top: {
    name: "hourglass_top",
    version: 11,
    popularity: 15883,
    codepoint: 59995,
    categories: ["communication"],
    tags: [
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "top",
      "wait",
      "waiting",
    ],
  },
  house: {
    name: "house",
    version: 11,
    popularity: 19391,
    codepoint: 59972,
    categories: ["places"],
    tags: [
      "architecture",
      "building",
      "estate",
      "family",
      "home",
      "homepage",
      "house",
      "place",
      "places",
      "real",
      "residence",
      "residential",
      "shelter",
    ],
  },
  house_siding: {
    name: "house_siding",
    version: 6,
    popularity: 3203,
    codepoint: 61954,
    categories: ["places"],
    tags: [
      "architecture",
      "building",
      "construction",
      "estate",
      "exterior",
      "facade",
      "home",
      "house",
      "real",
      "residential",
      "siding",
    ],
  },
  house_with_shield: {
    name: "house_with_shield",
    version: 229,
    popularity: 200,
    codepoint: 59270,
    categories: ["Home"],
    tags: ["home", "house", "nest", "security", "shield"],
  },
  houseboat: {
    name: "houseboat",
    version: 4,
    popularity: 2192,
    codepoint: 58756,
    categories: ["places"],
    tags: [
      "architecture",
      "beach",
      "boat",
      "estate",
      "floating",
      "home",
      "house",
      "houseboat",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "sea",
      "stay",
      "traveling",
      "vacation",
    ],
  },
  household_supplies: {
    name: "household_supplies",
    version: 229,
    popularity: 22,
    codepoint: 61345,
    categories: ["Household"],
    tags: [
      "bottle",
      "clean",
      "cleaner",
      "cleaning",
      "disinfecting",
      "household",
      "spray",
      "supplies",
    ],
  },
  how_to_reg: {
    name: "how_to_reg",
    version: 14,
    popularity: 31045,
    codepoint: 57716,
    categories: ["content"],
    tags: [
      "approve",
      "ballot",
      "check",
      "complete",
      "done",
      "election",
      "how",
      "mark",
      "ok",
      "poll",
      "register",
      "registration",
      "select",
      "tick",
      "to reg",
      "validate",
      "verified",
      "vote",
      "yes",
    ],
  },
  how_to_vote: {
    name: "how_to_vote",
    version: 15,
    popularity: 5798,
    codepoint: 57717,
    categories: ["content"],
    tags: ["ballot", "election", "how", "poll", "to", "vote"],
  },
  hr_resting: {
    name: "hr_resting",
    version: 229,
    popularity: 10,
    codepoint: 63162,
    categories: ["Activities"],
    tags: ["heart", "rate", "rest"],
  },
  html: {
    name: "html",
    version: 1,
    popularity: 3984,
    codepoint: 60286,
    categories: ["action"],
    tags: [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type",
    ],
  },
  http: {
    name: "http",
    version: 11,
    popularity: 5663,
    codepoint: 59650,
    categories: ["action"],
    tags: [
      "alphabet",
      "character",
      "font",
      "http",
      "letter",
      "symbol",
      "text",
      "transfer",
      "type",
      "url",
      "website",
    ],
  },
  hub: {
    name: "hub",
    version: 2,
    popularity: 12268,
    codepoint: 59892,
    categories: ["communication"],
    tags: [
      "center",
      "connection",
      "core",
      "focal point",
      "hub",
      "network",
      "nucleus",
      "topology",
    ],
  },
  humerus: {
    name: "humerus",
    version: 229,
    popularity: 182,
    codepoint: 63637,
    categories: ["Social"],
    tags: ["arm", "body", "bone", "bones", "forelimb", "humerus", "skeleton"],
  },
  humerus_alt: {
    name: "humerus_alt",
    version: 229,
    popularity: 148,
    codepoint: 63638,
    categories: ["Social"],
    tags: ["arm", "body", "bone", "bones", "forelimb", "humerus", "skeleton"],
  },
  humidity_high: {
    name: "humidity_high",
    version: 229,
    popularity: 761,
    codepoint: 61795,
    categories: ["Household"],
    tags: ["atmosphere", "droplet", "high", "moisture", "vapor", "water"],
  },
  humidity_indoor: {
    name: "humidity_indoor",
    version: 229,
    popularity: 0,
    codepoint: 62808,
    categories: ["Home"],
    tags: [
      "atmosphere",
      "bismuth",
      "climate",
      "droplet",
      "home",
      "house",
      "moisture",
      "vapor",
      "water",
    ],
  },
  humidity_low: {
    name: "humidity_low",
    version: 229,
    popularity: 729,
    codepoint: 61796,
    categories: ["Household"],
    tags: [
      "atmosphere",
      "droplet",
      "fitbit",
      "low",
      "menstruation",
      "moisture",
      "period",
      "vapor",
      "water",
    ],
  },
  humidity_mid: {
    name: "humidity_mid",
    version: 229,
    popularity: 669,
    codepoint: 61797,
    categories: ["Household"],
    tags: ["atmosphere", "droplet", "mid", "moisture", "vapor", "water"],
  },
  humidity_percentage: {
    name: "humidity_percentage",
    version: 229,
    popularity: 7,
    codepoint: 63614,
    categories: ["Social"],
    tags: ["atmosphere", "droplet", "moisture", "percent", "vapor", "water"],
  },
  hvac: {
    name: "hvac",
    version: 12,
    popularity: 2552,
    codepoint: 61710,
    categories: ["maps"],
    tags: ["air", "conditioning", "heating", "hvac", "ventilation"],
  },
  ice_skating: {
    name: "ice_skating",
    version: 4,
    popularity: 1955,
    codepoint: 58635,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "ice",
      "shoe",
      "skates",
      "skating",
      "social",
      "sports",
      "travel",
    ],
  },
  icecream: {
    name: "icecream",
    version: 11,
    popularity: 5132,
    codepoint: 60009,
    categories: ["maps"],
    tags: ["cream", "dessert", "food", "ice", "icecream", "snack"],
  },
  ifl: {
    name: "ifl",
    version: 229,
    popularity: 133,
    codepoint: 57381,
    categories: ["Activities"],
    tags: ["dice", "die", "feeling", "i'm", "ifl", "lucky", "search"],
  },
  iframe: {
    name: "iframe",
    version: 229,
    popularity: 16,
    codepoint: 63259,
    categories: ["UI actions"],
    tags: ["display", "frame", "html", "inline", "screen", "tag", "window"],
  },
  iframe_off: {
    name: "iframe_off",
    version: 229,
    popularity: 4,
    codepoint: 63260,
    categories: ["UI actions"],
    tags: [
      "disabled",
      "display",
      "enabled",
      "frame",
      "html",
      "inline",
      "off",
      "offline",
      "on",
      "screen",
      "slash",
      "tag",
      "window",
    ],
  },
  image: {
    name: "image",
    version: 16,
    popularity: 93196,
    codepoint: 58356,
    categories: ["image"],
    tags: [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
    ],
  },
  image_aspect_ratio: {
    name: "image_aspect_ratio",
    version: 12,
    popularity: 1020,
    codepoint: 58357,
    categories: ["image"],
    tags: [
      "aspect",
      "image",
      "photo",
      "photography",
      "picture",
      "ratio",
      "rectangle",
      "square",
    ],
  },
  image_not_supported: {
    name: "image_not_supported",
    version: 13,
    popularity: 4711,
    codepoint: 61718,
    categories: ["image"],
    tags: [
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "not",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
      "supported",
    ],
  },
  image_search: {
    name: "image_search",
    version: 12,
    popularity: 7148,
    codepoint: 58431,
    categories: ["image"],
    tags: [
      "find",
      "glass",
      "image",
      "landscape",
      "look",
      "magnify",
      "magnifying",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "search",
      "see",
    ],
  },
  imagesearch_roller: {
    name: "imagesearch_roller",
    version: 11,
    popularity: 2105,
    codepoint: 59828,
    categories: ["notification"],
    tags: ["art", "image", "imagesearch", "paint", "roller", "search"],
  },
  imagesmode: {
    name: "imagesmode",
    version: 229,
    popularity: 2984,
    codepoint: 61346,
    categories: ["Photo&Image"],
    tags: [
      "image",
      "mode",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
    ],
  },
  immunology: {
    name: "immunology",
    version: 229,
    popularity: 4,
    codepoint: 57595,
    categories: ["Social"],
    tags: [
      "body",
      "disease",
      "health",
      "human",
      "immune",
      "infection",
      "infectious",
      "virus",
      "viruses",
    ],
  },
  import_contacts: {
    name: "import_contacts",
    version: 14,
    popularity: 18575,
    codepoint: 57568,
    categories: ["communication"],
    tags: [
      "address",
      "book",
      "contacts",
      "import",
      "info",
      "information",
      "open",
    ],
  },
  important_devices: {
    name: "important_devices",
    version: 11,
    popularity: 10708,
    codepoint: 59666,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "desktop",
      "devices",
      "hardware",
      "iOS",
      "important",
      "mobile",
      "monitor",
      "phone",
      "star",
      "tablet",
      "web",
    ],
  },
  in_home_mode: {
    name: "in_home_mode",
    version: 229,
    popularity: 520,
    codepoint: 59443,
    categories: ["Household"],
    tags: [
      "check",
      "home",
      "house mode",
      "mark",
      "nest",
      "ok",
      "tick",
      "validate",
      "verified",
    ],
  },
  inactive_order: {
    name: "inactive_order",
    version: 229,
    popularity: 13,
    codepoint: 57596,
    categories: ["Business&Payments"],
    tags: [
      "approve",
      "check",
      "complete",
      "doc",
      "document",
      "done",
      "file",
      "health",
      "inactive",
      "mark",
      "medication",
      "ok",
      "order",
      "orders",
      "page",
      "paper",
      "pause",
      "receipt",
      "receipts",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  inbox: {
    name: "inbox",
    version: 18,
    popularity: 14635,
    codepoint: 57686,
    categories: ["content"],
    tags: ["archive", "email", "inbox", "incoming", "mail", "message"],
  },
  inbox_customize: {
    name: "inbox_customize",
    version: 229,
    popularity: 181,
    codepoint: 63577,
    categories: ["Communication"],
    tags: [
      "application",
      "archive",
      "change",
      "custom",
      "details",
      "email",
      "inbox",
      "incoming",
      "info",
      "information",
      "mail",
      "message",
      "options",
      "personal",
      "service",
      "settings",
    ],
  },
  incomplete_circle: {
    name: "incomplete_circle",
    version: 3,
    popularity: 3641,
    codepoint: 59291,
    categories: ["image"],
    tags: ["chart", "circle", "incomplete"],
  },
  indeterminate_check_box: {
    name: "indeterminate_check_box",
    version: 18,
    popularity: 14907,
    codepoint: 59657,
    categories: ["toggle"],
    tags: [
      "app",
      "application",
      "box",
      "button",
      "check",
      "components",
      "control",
      "design",
      "form",
      "indeterminate",
      "interface",
      "screen",
      "select",
      "selected",
      "selection",
      "site",
      "square",
      "toggle",
      "ui",
      "undetermined",
      "ux",
      "web",
      "website",
    ],
  },
  indeterminate_question_box: {
    name: "indeterminate_question_box",
    version: 229,
    popularity: 13,
    codepoint: 62829,
    categories: ["Common actions"],
    tags: [
      "?",
      "assistance",
      "frame",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "support",
      "symbol",
    ],
  },
  info: {
    name: "info",
    version: 21,
    popularity: 428467,
    codepoint: 59534,
    categories: ["action"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "service",
      "support",
    ],
  },
  info_i: {
    name: "info_i",
    version: 229,
    popularity: 8,
    codepoint: 62875,
    categories: ["Common actions"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "service",
      "support",
    ],
  },
  infrared: {
    name: "infrared",
    version: 229,
    popularity: 8,
    codepoint: 63612,
    categories: ["Social"],
    tags: ["electromagnetic", "radiation", "wavelength"],
  },
  ink_eraser: {
    name: "ink_eraser",
    version: 229,
    popularity: 170,
    codepoint: 59088,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  ink_eraser_off: {
    name: "ink_eraser_off",
    version: 229,
    popularity: 5,
    codepoint: 59363,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "ink",
      "off",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  ink_highlighter: {
    name: "ink_highlighter",
    version: 229,
    popularity: 134,
    codepoint: 59089,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  ink_highlighter_move: {
    name: "ink_highlighter_move",
    version: 229,
    popularity: 10,
    codepoint: 62756,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "dash",
      "doc",
      "drag",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "highlighting",
      "ink",
      "lines",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  ink_marker: {
    name: "ink_marker",
    version: 229,
    popularity: 41,
    codepoint: 59090,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "maker",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  ink_pen: {
    name: "ink_pen",
    version: 229,
    popularity: 170,
    codepoint: 59091,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "paint",
      "pen",
      "pencil",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  inpatient: {
    name: "inpatient",
    version: 229,
    popularity: 3,
    codepoint: 57598,
    categories: ["Social"],
    tags: [
      "arrow",
      "body",
      "clinic",
      "health",
      "hospital",
      "human",
      "in",
      "left",
      "medical",
      "patient",
      "person",
    ],
  },
  input: {
    name: "input",
    version: 13,
    popularity: 17310,
    codepoint: 59536,
    categories: ["action"],
    tags: ["arrow", "box", "download", "input", "login", "move", "right"],
  },
  input_circle: {
    name: "input_circle",
    version: 229,
    popularity: 33,
    codepoint: 63258,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "inside",
      "install",
      "into",
      "navigation",
      "north",
      "up",
    ],
  },
  insert_chart: {
    name: "insert_chart",
    version: 12,
    popularity: 8250,
    codepoint: 57931,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "insert",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  insert_page_break: {
    name: "insert_page_break",
    version: 2,
    popularity: 1126,
    codepoint: 60106,
    categories: ["editor"],
    tags: [
      "break",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "page",
      "paper",
    ],
  },
  insert_text: {
    name: "insert_text",
    version: 229,
    popularity: 13,
    codepoint: 63527,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  install_desktop: {
    name: "install_desktop",
    version: 1,
    popularity: 2664,
    codepoint: 60273,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "fix",
      "hardware",
      "iOS",
      "install",
      "mac",
      "monitor",
      "place",
      "pwa",
      "screen",
      "web",
      "window",
    ],
  },
  install_mobile: {
    name: "install_mobile",
    version: 1,
    popularity: 2124,
    codepoint: 60274,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "pwa",
      "tablet",
    ],
  },
  instant_mix: {
    name: "instant_mix",
    version: 229,
    popularity: 120,
    codepoint: 57382,
    categories: ["Audio&Video"],
    tags: [
      "adjust",
      "audio",
      "controls",
      "custom",
      "customize",
      "edit",
      "editing",
      "filter",
      "filters",
      "instant",
      "mix",
      "music",
      "options",
      "setting",
      "settings",
      "slider",
      "sliders",
      "switches",
      "tune",
    ],
  },
  integration_instructions: {
    name: "integration_instructions",
    version: 11,
    popularity: 14057,
    codepoint: 61268,
    categories: ["action"],
    tags: [
      "brackets",
      "clipboard",
      "code",
      "css",
      "develop",
      "developer",
      "doc",
      "document",
      "engineer",
      "engineering clipboard",
      "html",
      "instructions",
      "integration",
      "platform",
    ],
  },
  interactive_space: {
    name: "interactive_space",
    version: 229,
    popularity: 22,
    codepoint: 63487,
    categories: ["Activities"],
    tags: [
      "art",
      "body",
      "human",
      "installation",
      "interact",
      "movie",
      "people",
      "person",
      "screen",
    ],
  },
  interests: {
    name: "interests",
    version: 2,
    popularity: 7820,
    codepoint: 59336,
    categories: ["social"],
    tags: [
      "circle",
      "heart",
      "interests",
      "shapes",
      "social",
      "square",
      "triangle",
    ],
  },
  interpreter_mode: {
    name: "interpreter_mode",
    version: 2,
    popularity: 2113,
    codepoint: 59451,
    categories: ["av"],
    tags: [
      "interpreter",
      "language",
      "microphone",
      "mode",
      "person",
      "speaking",
      "symbol",
    ],
  },
  inventory: {
    name: "inventory",
    version: 13,
    popularity: 54260,
    codepoint: 57721,
    categories: ["content"],
    tags: [
      "archive",
      "box",
      "clipboard",
      "doc",
      "document",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "supply",
    ],
  },
  inventory_2: {
    name: "inventory_2",
    version: 9,
    popularity: 59425,
    codepoint: 57761,
    categories: ["content"],
    tags: [
      "archive",
      "box",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "storage",
      "supply",
    ],
  },
  invert_colors: {
    name: "invert_colors",
    version: 13,
    popularity: 10221,
    codepoint: 59537,
    categories: ["action"],
    tags: [
      "colors",
      "drop",
      "droplet",
      "edit",
      "editing",
      "hue",
      "invert",
      "inverted",
      "palette",
      "tone",
      "water",
    ],
  },
  invert_colors_off: {
    name: "invert_colors_off",
    version: 12,
    popularity: 1740,
    codepoint: 57540,
    categories: ["communication"],
    tags: [
      "colors",
      "disabled",
      "drop",
      "droplet",
      "enabled",
      "hue",
      "invert",
      "inverted",
      "off",
      "offline",
      "on",
      "opacity",
      "palette",
      "slash",
      "tone",
      "water",
    ],
  },
  ios: {
    name: "ios",
    version: 229,
    popularity: 163,
    codepoint: 57383,
    categories: ["Android"],
    tags: [
      "apple",
      "hardware",
      "ios",
      "iphone",
      "ipod",
      "mac",
      "operating",
      "system",
    ],
  },
  ios_share: {
    name: "ios_share",
    version: 13,
    popularity: 27173,
    codepoint: 59064,
    categories: ["social"],
    tags: ["arrow", "export", "ios", "send", "share", "up"],
  },
  iron: {
    name: "iron",
    version: 4,
    popularity: 2012,
    codepoint: 58755,
    categories: ["places"],
    tags: [
      "appliance",
      "clothes",
      "electric",
      "iron",
      "ironing",
      "machine",
      "object",
    ],
  },
  jamboard_kiosk: {
    name: "jamboard_kiosk",
    version: 229,
    popularity: 44,
    codepoint: 59829,
    categories: ["Hardware"],
    tags: [
      "business",
      "chrome",
      "desktop",
      "device",
      "hardware",
      "jamboard",
      "kiosk",
      "monitor",
      "presentation",
      "whiteboard",
    ],
  },
  javascript: {
    name: "javascript",
    version: 1,
    popularity: 3117,
    codepoint: 60284,
    categories: ["action"],
    tags: [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "javascript",
      "letter",
      "platform",
      "symbol",
      "text",
      "type",
    ],
  },
  join: {
    name: "join",
    version: 229,
    popularity: 89,
    codepoint: 63567,
    categories: ["Text Formatting"],
    tags: [
      "circle",
      "combine",
      "command",
      "join",
      "left",
      "outer",
      "overlap",
      "right",
      "sql",
    ],
  },
  join_inner: {
    name: "join_inner",
    version: 1,
    popularity: 2730,
    codepoint: 60148,
    categories: ["action"],
    tags: [
      "circle",
      "command",
      "inner",
      "join",
      "matching",
      "overlap",
      "sql",
      "values",
    ],
  },
  join_left: {
    name: "join_left",
    version: 1,
    popularity: 1961,
    codepoint: 60146,
    categories: ["action"],
    tags: [
      "circle",
      "command",
      "join",
      "left",
      "matching",
      "overlap",
      "sql",
      "values",
    ],
  },
  join_right: {
    name: "join_right",
    version: 1,
    popularity: 1718,
    codepoint: 60138,
    categories: ["action"],
    tags: [
      "circle",
      "command",
      "join",
      "matching",
      "overlap",
      "right",
      "sql",
      "values",
    ],
  },
  joystick: {
    name: "joystick",
    version: 229,
    popularity: 33,
    codepoint: 62958,
    categories: ["Hardware"],
    tags: [
      "arcade",
      "console",
      "controller",
      "device",
      "flight stick",
      "gamepad",
      "gaming",
    ],
  },
  jump_to_element: {
    name: "jump_to_element",
    version: 229,
    popularity: 7,
    codepoint: 63257,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "frame",
      "left",
      "move",
      "navigation",
      "northeast",
      "position",
    ],
  },
  kayaking: {
    name: "kayaking",
    version: 4,
    popularity: 3409,
    codepoint: 58636,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "canoe",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kayak",
      "kayaking",
      "lake",
      "paddle",
      "paddling",
      "people",
      "person",
      "rafting",
      "river",
      "row",
      "social",
      "sports",
      "summer",
      "travel",
      "water",
    ],
  },
  kebab_dining: {
    name: "kebab_dining",
    version: 2,
    popularity: 1072,
    codepoint: 59458,
    categories: ["maps"],
    tags: ["dining", "dinner", "food", "kebab", "meal", "meat", "skewer"],
  },
  kettle: {
    name: "kettle",
    version: 229,
    popularity: 307,
    codepoint: 58041,
    categories: ["Household"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "home",
      "house",
      "kettle",
      "kitchen",
      "nest",
      "steamer",
      "tea",
    ],
  },
  key: {
    name: "key",
    version: 2,
    popularity: 20020,
    codepoint: 59196,
    categories: ["communication"],
    tags: ["access", "door", "entry", "key", "lock", "password", "unlock"],
  },
  key_off: {
    name: "key_off",
    version: 1,
    popularity: 1229,
    codepoint: 60292,
    categories: ["communication"],
    tags: [
      "access",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "off",
      "offline",
      "on",
      "password",
      "slash",
      "unlock",
    ],
  },
  key_vertical: {
    name: "key_vertical",
    version: 229,
    popularity: 1,
    codepoint: 62746,
    categories: ["UI actions"],
    tags: ["access", "door", "entry", "key", "lock", "password", "unlock"],
  },
  key_visualizer: {
    name: "key_visualizer",
    version: 229,
    popularity: 447,
    codepoint: 61849,
    categories: ["Privacy&Security"],
    tags: [
      "data",
      "heatmap",
      "hot",
      "insight",
      "introspection",
      "key",
      "lines",
      "monitoring",
      "row",
      "visualizer",
    ],
  },
  keyboard: {
    name: "keyboard",
    version: 13,
    popularity: 15734,
    codepoint: 58130,
    categories: ["hardware"],
    tags: [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type",
    ],
  },
  keyboard_alt: {
    name: "keyboard_alt",
    version: 15,
    popularity: 3908,
    codepoint: 61480,
    categories: ["hardware"],
    tags: [
      "alt",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type",
    ],
  },
  keyboard_arrow_down: {
    name: "keyboard_arrow_down",
    version: 18,
    popularity: 65902,
    codepoint: 58131,
    categories: ["hardware"],
    tags: ["arrow", "arrows", "down", "keyboard"],
  },
  keyboard_arrow_left: {
    name: "keyboard_arrow_left",
    version: 17,
    popularity: 17573,
    codepoint: 58132,
    categories: ["hardware"],
    tags: ["arrow", "arrows", "keyboard", "left"],
  },
  keyboard_arrow_right: {
    name: "keyboard_arrow_right",
    version: 17,
    popularity: 32254,
    codepoint: 58133,
    categories: ["hardware"],
    tags: ["arrow", "arrows", "keyboard", "right"],
  },
  keyboard_arrow_up: {
    name: "keyboard_arrow_up",
    version: 19,
    popularity: 19655,
    codepoint: 58134,
    categories: ["hardware"],
    tags: ["arrow", "arrows", "keyboard", "up"],
  },
  keyboard_backspace: {
    name: "keyboard_backspace",
    version: 13,
    popularity: 21105,
    codepoint: 58135,
    categories: ["hardware"],
    tags: ["arrow", "back", "backspace", "keyboard", "left"],
  },
  keyboard_capslock: {
    name: "keyboard_capslock",
    version: 13,
    popularity: 1955,
    codepoint: 58136,
    categories: ["hardware"],
    tags: ["arrow", "capslock", "keyboard", "up"],
  },
  keyboard_capslock_badge: {
    name: "keyboard_capslock_badge",
    version: 229,
    popularity: 5,
    codepoint: 63454,
    categories: ["Android"],
    tags: [
      "arrow",
      "arrows",
      "capitalization",
      "caps",
      "format",
      "function",
      "lowercase",
      "type",
      "up",
      "uppercase",
    ],
  },
  keyboard_command_key: {
    name: "keyboard_command_key",
    version: 1,
    popularity: 1503,
    codepoint: 60135,
    categories: ["hardware"],
    tags: ["button", "command key", "control", "keyboard"],
  },
  keyboard_control_key: {
    name: "keyboard_control_key",
    version: 1,
    popularity: 754,
    codepoint: 60134,
    categories: ["hardware"],
    tags: ["control key", "keyboard"],
  },
  keyboard_double_arrow_down: {
    name: "keyboard_double_arrow_down",
    version: 2,
    popularity: 10458,
    codepoint: 60112,
    categories: ["hardware"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "double",
      "down",
      "multiple",
      "navigation",
    ],
  },
  keyboard_double_arrow_left: {
    name: "keyboard_double_arrow_left",
    version: 2,
    popularity: 12192,
    codepoint: 60099,
    categories: ["hardware"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "double",
      "left",
      "multiple",
      "navigation",
    ],
  },
  keyboard_double_arrow_right: {
    name: "keyboard_double_arrow_right",
    version: 2,
    popularity: 18403,
    codepoint: 60105,
    categories: ["hardware"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right",
    ],
  },
  keyboard_double_arrow_up: {
    name: "keyboard_double_arrow_up",
    version: 2,
    popularity: 7859,
    codepoint: 60111,
    categories: ["hardware"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "up",
    ],
  },
  keyboard_external_input: {
    name: "keyboard_external_input",
    version: 229,
    popularity: 10,
    codepoint: 63453,
    categories: ["Android"],
    tags: [
      "arrow",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "link",
      "office",
      "output",
      "right",
      "text",
      "type",
    ],
  },
  keyboard_full: {
    name: "keyboard_full",
    version: 229,
    popularity: 3,
    codepoint: 63452,
    categories: ["Android"],
    tags: ["fullscreen", "layout", "panel", "panels"],
  },
  keyboard_hide: {
    name: "keyboard_hide",
    version: 13,
    popularity: 2625,
    codepoint: 58138,
    categories: ["hardware"],
    tags: [
      "arrow",
      "computer",
      "device",
      "down",
      "hardware",
      "hide",
      "input",
      "keyboard",
      "keypad",
      "text",
    ],
  },
  keyboard_keys: {
    name: "keyboard_keys",
    version: 229,
    popularity: 12,
    codepoint: 63099,
    categories: ["Android"],
    tags: [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type",
    ],
  },
  keyboard_off: {
    name: "keyboard_off",
    version: 229,
    popularity: 7,
    codepoint: 63098,
    categories: ["Android"],
    tags: [
      "computer",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "off",
      "office",
      "on",
      "slash",
      "text",
      "type",
    ],
  },
  keyboard_onscreen: {
    name: "keyboard_onscreen",
    version: 229,
    popularity: 2,
    codepoint: 63451,
    categories: ["Android"],
    tags: [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type",
    ],
  },
  keyboard_option_key: {
    name: "keyboard_option_key",
    version: 1,
    popularity: 599,
    codepoint: 60136,
    categories: ["hardware"],
    tags: ["alt key", "key", "keyboard", "modifier key", "option"],
  },
  keyboard_previous_language: {
    name: "keyboard_previous_language",
    version: 229,
    popularity: 2,
    codepoint: 63450,
    categories: ["Android"],
    tags: [
      "arrow",
      "back",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "redo",
      "refresh",
      "restore",
      "text",
      "type",
      "undo",
    ],
  },
  keyboard_return: {
    name: "keyboard_return",
    version: 13,
    popularity: 17984,
    codepoint: 58139,
    categories: ["hardware"],
    tags: ["arrow", "back", "keyboard", "left", "return"],
  },
  keyboard_tab: {
    name: "keyboard_tab",
    version: 13,
    popularity: 5346,
    codepoint: 58140,
    categories: ["hardware"],
    tags: ["arrow", "keyboard", "left", "next", "right", "tab"],
  },
  keyboard_tab_rtl: {
    name: "keyboard_tab_rtl",
    version: 229,
    popularity: 9,
    codepoint: 60531,
    categories: ["Hardware"],
    tags: ["arrow", "keyboard", "left", "next", "right", "tab"],
  },
  keyboard_voice: {
    name: "keyboard_voice",
    version: 17,
    popularity: 9787,
    codepoint: 58141,
    categories: ["hardware"],
    tags: [
      "keyboard",
      "mic",
      "microphone",
      "noise",
      "record",
      "recorder",
      "speaker",
      "voice",
    ],
  },
  kid_star: {
    name: "kid_star",
    version: 229,
    popularity: 0,
    codepoint: 62758,
    categories: ["Social"],
    tags: [
      "best",
      "bookmark",
      "child",
      "favorite",
      "highlight",
      "kid",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
    ],
  },
  king_bed: {
    name: "king_bed",
    version: 12,
    popularity: 7823,
    codepoint: 59973,
    categories: ["social"],
    tags: [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "sleep",
    ],
  },
  kitchen: {
    name: "kitchen",
    version: 12,
    popularity: 8376,
    codepoint: 60231,
    categories: ["places"],
    tags: [
      "appliance",
      "cold",
      "food",
      "fridge",
      "home",
      "house",
      "ice",
      "kitchen",
      "places",
      "refrigerator",
      "storage",
    ],
  },
  kitesurfing: {
    name: "kitesurfing",
    version: 4,
    popularity: 2256,
    codepoint: 58637,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kitesurfing",
      "people",
      "person",
      "social",
      "sports",
      "surf",
      "travel",
      "water",
    ],
  },
  lab_panel: {
    name: "lab_panel",
    version: 229,
    popularity: 13,
    codepoint: 57603,
    categories: ["Social"],
    tags: [
      "health",
      "labs",
      "medical",
      "research",
      "sample",
      "samples",
      "test",
    ],
  },
  lab_profile: {
    name: "lab_profile",
    version: 229,
    popularity: 61,
    codepoint: 57604,
    categories: ["Text Formatting"],
    tags: [
      "doc",
      "document",
      "file",
      "folder",
      "folders",
      "health",
      "labs",
      "page",
      "paper",
      "report",
      "reports",
    ],
  },
  lab_research: {
    name: "lab_research",
    version: 229,
    popularity: 40,
    codepoint: 63499,
    categories: ["Social"],
    tags: [],
  },
  label: {
    name: "label",
    version: 17,
    popularity: 31186,
    codepoint: 59538,
    categories: ["action"],
    tags: [
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag",
    ],
  },
  label_important: {
    name: "label_important",
    version: 20,
    popularity: 15179,
    codepoint: 59703,
    categories: ["action"],
    tags: [
      "favorite",
      "important",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag",
      "wing",
    ],
  },
  label_off: {
    name: "label_off",
    version: 13,
    popularity: 2438,
    codepoint: 59830,
    categories: ["action"],
    tags: [
      "disabled",
      "enabled",
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "off",
      "on",
      "remember",
      "save",
      "slash",
      "stamp",
      "sticker",
      "tag",
      "wing",
    ],
  },
  labs: {
    name: "labs",
    version: 229,
    popularity: 114,
    codepoint: 57605,
    categories: ["Social"],
    tags: ["health", "lab", "medical", "research", "sample", "samples", "test"],
  },
  lan: {
    name: "lan",
    version: 1,
    popularity: 5014,
    codepoint: 60207,
    categories: ["device"],
    tags: [
      "computer",
      "connection",
      "data",
      "internet",
      "lan",
      "network",
      "service",
    ],
  },
  landscape: {
    name: "landscape",
    version: 12,
    popularity: 9218,
    codepoint: 58359,
    categories: ["image"],
    tags: [
      "image",
      "landscape",
      "mountain",
      "mountains",
      "nature",
      "photo",
      "photography",
      "picture",
    ],
  },
  landslide: {
    name: "landslide",
    version: 1,
    popularity: 734,
    codepoint: 60375,
    categories: ["social"],
    tags: ["crisis", "disaster", "natural", "rain", "storm", "weather"],
  },
  language: {
    name: "language",
    version: 12,
    popularity: 173321,
    codepoint: 59540,
    categories: ["action"],
    tags: [
      "globe",
      "internet",
      "language",
      "planet",
      "website",
      "world",
      "www",
    ],
  },
  language_chinese_array: {
    name: "language_chinese_array",
    version: 229,
    popularity: 1,
    codepoint: 63334,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_chinese_cangjie: {
    name: "language_chinese_cangjie",
    version: 229,
    popularity: 1,
    codepoint: 63333,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_chinese_dayi: {
    name: "language_chinese_dayi",
    version: 229,
    popularity: 2,
    codepoint: 63332,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_chinese_pinyin: {
    name: "language_chinese_pinyin",
    version: 229,
    popularity: 1,
    codepoint: 63331,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_chinese_quick: {
    name: "language_chinese_quick",
    version: 229,
    popularity: 7,
    codepoint: 63330,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_chinese_wubi: {
    name: "language_chinese_wubi",
    version: 229,
    popularity: 2,
    codepoint: 63329,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_french: {
    name: "language_french",
    version: 229,
    popularity: 4,
    codepoint: 63328,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_gb_english: {
    name: "language_gb_english",
    version: 229,
    popularity: 3,
    codepoint: 63327,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_international: {
    name: "language_international",
    version: 229,
    popularity: 4,
    codepoint: 63326,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_korean_latin: {
    name: "language_korean_latin",
    version: 229,
    popularity: 4,
    codepoint: 63325,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_pinyin: {
    name: "language_pinyin",
    version: 229,
    popularity: 2,
    codepoint: 63324,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_spanish: {
    name: "language_spanish",
    version: 229,
    popularity: 7,
    codepoint: 62953,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_us: {
    name: "language_us",
    version: 229,
    popularity: 1,
    codepoint: 63321,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_us_colemak: {
    name: "language_us_colemak",
    version: 229,
    popularity: 1,
    codepoint: 63323,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  language_us_dvorak: {
    name: "language_us_dvorak",
    version: 229,
    popularity: 1,
    codepoint: 63322,
    categories: ["Text Formatting"],
    tags: [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  laps: {
    name: "laps",
    version: 229,
    popularity: 50,
    codepoint: 63161,
    categories: ["Activities"],
    tags: ["arrow", "exercise", "fitness", "lap", "loop", "run", "workout"],
  },
  laptop_chromebook: {
    name: "laptop_chromebook",
    version: 12,
    popularity: 5177,
    codepoint: 58143,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "chromebook",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac chromebook",
      "monitor",
      "screen",
      "web",
      "window",
    ],
  },
  laptop_mac: {
    name: "laptop_mac",
    version: 12,
    popularity: 7068,
    codepoint: 58144,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
    ],
  },
  laptop_windows: {
    name: "laptop_windows",
    version: 12,
    popularity: 2999,
    codepoint: 58145,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
      "windows",
    ],
  },
  lasso_select: {
    name: "lasso_select",
    version: 229,
    popularity: 18,
    codepoint: 60163,
    categories: ["Text Formatting"],
    tags: [
      "color",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "lasso",
      "paint",
      "pen",
      "pencil",
      "select",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing",
    ],
  },
  last_page: {
    name: "last_page",
    version: 13,
    popularity: 13371,
    codepoint: 58845,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "end",
      "forward",
      "interface",
      "last",
      "page",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  laundry: {
    name: "laundry",
    version: 229,
    popularity: 876,
    codepoint: 58024,
    categories: ["Home"],
    tags: ["home", "laundry", "shirt", "washer"],
  },
  layers: {
    name: "layers",
    version: 12,
    popularity: 21490,
    codepoint: 58683,
    categories: ["maps"],
    tags: [
      "arrange",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash",
    ],
  },
  layers_clear: {
    name: "layers_clear",
    version: 12,
    popularity: 4070,
    codepoint: 58684,
    categories: ["maps"],
    tags: [
      "arrange",
      "clear",
      "delete",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash",
    ],
  },
  lda: {
    name: "lda",
    version: 229,
    popularity: 2,
    codepoint: 57606,
    categories: ["Hardware"],
    tags: ["airway", "doctor", "drain", "health", "line", "medical", "nurse"],
  },
  leaderboard: {
    name: "leaderboard",
    version: 6,
    popularity: 38819,
    codepoint: 61964,
    categories: ["action"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "leaderboard",
      "measure",
      "metrics",
      "statistics",
      "tracking",
    ],
  },
  leak_add: {
    name: "leak_add",
    version: 12,
    popularity: 3723,
    codepoint: 58360,
    categories: ["image"],
    tags: [
      "add",
      "connection",
      "data",
      "leak",
      "link",
      "network",
      "service",
      "signals",
      "synce",
      "wireless",
    ],
  },
  leak_remove: {
    name: "leak_remove",
    version: 12,
    popularity: 1191,
    codepoint: 58361,
    categories: ["image"],
    tags: [
      "connection",
      "data",
      "disabled",
      "enabled",
      "leak",
      "link",
      "network",
      "off",
      "offline",
      "on",
      "remove",
      "service",
      "signals",
      "slash",
      "synce",
      "wireless",
    ],
  },
  left_click: {
    name: "left_click",
    version: 229,
    popularity: 91,
    codepoint: 63256,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "circle",
      "clicks",
      "left",
      "mouse",
      "move",
      "select",
      "selection",
      "selects",
    ],
  },
  left_panel_close: {
    name: "left_panel_close",
    version: 229,
    popularity: 28,
    codepoint: 63255,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  left_panel_open: {
    name: "left_panel_open",
    version: 229,
    popularity: 29,
    codepoint: 63254,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  legend_toggle: {
    name: "legend_toggle",
    version: 8,
    popularity: 5893,
    codepoint: 61723,
    categories: ["navigation"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "legend",
      "measure",
      "metrics",
      "monitoring",
      "stackdriver",
      "statistics",
      "toggle",
      "tracking",
    ],
  },
  lens: {
    name: "lens",
    version: 12,
    popularity: 8668,
    codepoint: 58362,
    categories: ["image"],
    tags: ["circle", "full", "geometry", "lens", "moon"],
  },
  lens_blur: {
    name: "lens_blur",
    version: 10,
    popularity: 4239,
    codepoint: 61481,
    categories: ["device"],
    tags: [
      "blur",
      "camera",
      "dim",
      "dot",
      "effect",
      "foggy",
      "fuzzy",
      "image",
      "lens",
      "photo",
      "soften",
    ],
  },
  letter_switch: {
    name: "letter_switch",
    version: 229,
    popularity: 2,
    codepoint: 63320,
    categories: ["Text Formatting"],
    tags: [
      "arrows",
      "flip",
      "flips",
      "format",
      "languages",
      "localization",
      "swap",
      "swaps",
      "text",
      "translation",
      "translations",
      "writing",
    ],
  },
  library_add: {
    name: "library_add",
    version: 13,
    popularity: 14775,
    codepoint: 57390,
    categories: ["av"],
    tags: [
      "+",
      "add",
      "collection",
      "layers",
      "library",
      "multiple",
      "music",
      "new",
      "plus",
      "stacked",
      "symbol",
      "video",
    ],
  },
  library_add_check: {
    name: "library_add_check",
    version: 17,
    popularity: 8179,
    codepoint: 59831,
    categories: ["av"],
    tags: [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "layers",
      "library",
      "mark",
      "multiple",
      "music",
      "ok",
      "select",
      "stacked",
      "tick",
      "validate",
      "verified",
      "video",
      "yes",
    ],
  },
  library_books: {
    name: "library_books",
    version: 14,
    popularity: 31059,
    codepoint: 57391,
    categories: ["av"],
    tags: [
      "add",
      "album",
      "audio",
      "book",
      "books",
      "collection",
      "library",
      "read",
      "reading",
    ],
  },
  library_music: {
    name: "library_music",
    version: 13,
    popularity: 9918,
    codepoint: 57392,
    categories: ["av"],
    tags: ["add", "album", "collection", "library", "music", "song", "sounds"],
  },
  license: {
    name: "license",
    version: 229,
    popularity: 131,
    codepoint: 60164,
    categories: ["Common actions"],
    tags: [
      "certification",
      "degree",
      "ecommerce",
      "guarantee",
      "license",
      "medal",
      "permit",
      "premium",
      "ribbon",
      "verification",
    ],
  },
  lift_to_talk: {
    name: "lift_to_talk",
    version: 229,
    popularity: 299,
    codepoint: 61347,
    categories: ["Hardware"],
    tags: [],
  },
  light: {
    name: "light",
    version: 9,
    popularity: 5827,
    codepoint: 61482,
    categories: ["search"],
    tags: [
      "bulb",
      "ceiling",
      "hanging",
      "inside",
      "interior",
      "lamp",
      "light",
      "lighting",
      "pendent",
      "room",
    ],
  },
  light_group: {
    name: "light_group",
    version: 229,
    popularity: 287,
    codepoint: 57995,
    categories: ["Home"],
    tags: ["group", "home", "lamps", "lighting"],
  },
  light_mode: {
    name: "light_mode",
    version: 4,
    popularity: 54138,
    codepoint: 58648,
    categories: ["device"],
    tags: [
      "bright",
      "brightness",
      "day",
      "device",
      "light",
      "lighting",
      "mode",
      "morning",
      "sky",
      "sun",
      "sunny",
    ],
  },
  light_off: {
    name: "light_off",
    version: 229,
    popularity: 41,
    codepoint: 59832,
    categories: ["Household"],
    tags: [
      "bulb",
      "disabled",
      "enabled",
      "idea",
      "lamp",
      "light",
      "lights",
      "off",
      "on",
      "slash",
    ],
  },
  lightbulb: {
    name: "lightbulb",
    version: 18,
    popularity: 91238,
    codepoint: 57584,
    categories: ["action"],
    tags: [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb",
    ],
  },
  lightbulb_circle: {
    name: "lightbulb_circle",
    version: 1,
    popularity: 2892,
    codepoint: 60414,
    categories: ["action"],
    tags: [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb",
    ],
  },
  lightning_stand: {
    name: "lightning_stand",
    version: 229,
    popularity: 23,
    codepoint: 61348,
    categories: ["Hardware"],
    tags: [
      "bolt",
      "charge",
      "charging",
      "device",
      "dock",
      "electric",
      "energy",
      "hardware",
      "instant",
      "lightning",
      "mobile",
      "stand",
      "thunderbolt",
      "wireless",
    ],
  },
  line_axis: {
    name: "line_axis",
    version: 2,
    popularity: 1245,
    codepoint: 60058,
    categories: ["editor"],
    tags: ["axis", "dash", "horizontal", "line", "stroke", "vertical"],
  },
  line_curve: {
    name: "line_curve",
    version: 229,
    popularity: 11,
    codepoint: 63319,
    categories: ["Text Formatting"],
    tags: [
      "corner",
      "draw",
      "drawing",
      "edit",
      "editing",
      "format",
      "line",
      "round",
      "rounded",
      "vector",
    ],
  },
  line_end: {
    name: "line_end",
    version: 229,
    popularity: 12,
    codepoint: 63526,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_end_arrow: {
    name: "line_end_arrow",
    version: 229,
    popularity: 12,
    codepoint: 63517,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_end_arrow_notch: {
    name: "line_end_arrow_notch",
    version: 229,
    popularity: 11,
    codepoint: 63516,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_end_circle: {
    name: "line_end_circle",
    version: 229,
    popularity: 16,
    codepoint: 63515,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_end_diamond: {
    name: "line_end_diamond",
    version: 229,
    popularity: 7,
    codepoint: 63514,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_end_square: {
    name: "line_end_square",
    version: 229,
    popularity: 8,
    codepoint: 63513,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke",
    ],
  },
  line_start: {
    name: "line_start",
    version: 229,
    popularity: 11,
    codepoint: 63525,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_start_arrow: {
    name: "line_start_arrow",
    version: 229,
    popularity: 4,
    codepoint: 63512,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_start_arrow_notch: {
    name: "line_start_arrow_notch",
    version: 229,
    popularity: 2,
    codepoint: 63511,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_start_circle: {
    name: "line_start_circle",
    version: 229,
    popularity: 6,
    codepoint: 63510,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_start_diamond: {
    name: "line_start_diamond",
    version: 229,
    popularity: 7,
    codepoint: 63509,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_start_square: {
    name: "line_start_square",
    version: 229,
    popularity: 3,
    codepoint: 63508,
    categories: ["Text Formatting"],
    tags: [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke",
    ],
  },
  line_style: {
    name: "line_style",
    version: 14,
    popularity: 3313,
    codepoint: 59673,
    categories: ["action"],
    tags: ["dash", "dotted", "line", "rule", "spacing", "style"],
  },
  line_weight: {
    name: "line_weight",
    version: 14,
    popularity: 4672,
    codepoint: 59674,
    categories: ["action"],
    tags: ["height", "line", "size", "spacing", "style", "thickness", "weight"],
  },
  linear_scale: {
    name: "linear_scale",
    version: 17,
    popularity: 8059,
    codepoint: 57952,
    categories: ["editor"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "linear",
      "measure",
      "menu",
      "scale",
      "screen",
      "site",
      "slider",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  link: {
    name: "link",
    version: 21,
    popularity: 76689,
    codepoint: 57687,
    categories: ["content"],
    tags: [
      "chain",
      "clip",
      "connection",
      "link",
      "linked",
      "links",
      "multimedia",
      "url",
    ],
  },
  link_off: {
    name: "link_off",
    version: 11,
    popularity: 13879,
    codepoint: 57711,
    categories: ["content"],
    tags: [
      "attached",
      "chain",
      "clip",
      "connection",
      "disabled",
      "enabled",
      "link",
      "linked",
      "links",
      "multimedia",
      "off",
      "on",
      "slash",
      "url",
    ],
  },
  linked_camera: {
    name: "linked_camera",
    version: 15,
    popularity: 2407,
    codepoint: 58424,
    categories: ["image"],
    tags: [
      "camera",
      "connect",
      "connection",
      "lens",
      "linked",
      "network",
      "photo",
      "photography",
      "picture",
      "signal",
      "signals",
      "sync",
      "wireless",
    ],
  },
  linked_services: {
    name: "linked_services",
    version: 229,
    popularity: 11,
    codepoint: 62773,
    categories: ["UI actions"],
    tags: [
      "chart",
      "data",
      "digital markets act",
      "dma",
      "federation",
      "group",
      "link",
      "nodes",
      "pool",
    ],
  },
  liquor: {
    name: "liquor",
    version: 10,
    popularity: 8259,
    codepoint: 60000,
    categories: ["maps"],
    tags: [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "party",
      "store",
      "wine",
    ],
  },
  list: {
    name: "list",
    version: 18,
    popularity: 126429,
    codepoint: 59542,
    categories: ["action"],
    tags: ["file", "format", "index", "list", "menu", "options"],
  },
  list_alt: {
    name: "list_alt",
    version: 13,
    popularity: 48612,
    codepoint: 57582,
    categories: ["communication"],
    tags: [
      "alt",
      "box",
      "contained",
      "form",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "title",
    ],
  },
  list_alt_add: {
    name: "list_alt_add",
    version: 229,
    popularity: 17,
    codepoint: 63318,
    categories: ["Text Formatting"],
    tags: [
      "+",
      "add",
      "doc",
      "document",
      "drive",
      "file",
      "lists",
      "new",
      "page",
      "plus",
    ],
  },
  lists: {
    name: "lists",
    version: 229,
    popularity: 126,
    codepoint: 59833,
    categories: ["Common actions"],
    tags: [
      "bullet",
      "check",
      "checklist",
      "directory",
      "document",
      "form",
      "list",
      "lists",
      "menu",
      "points",
      "to do",
    ],
  },
  live_help: {
    name: "live_help",
    version: 15,
    popularity: 17174,
    codepoint: 57542,
    categories: ["communication"],
    tags: [
      "?",
      "assistance",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "help",
      "info",
      "information",
      "live",
      "message",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "speech",
      "support",
      "symbol",
    ],
  },
  live_tv: {
    name: "live_tv",
    version: 11,
    popularity: 17825,
    codepoint: 58937,
    categories: ["notification"],
    tags: [
      "Android",
      "OS",
      "antennas hardware",
      "chrome",
      "desktop",
      "device",
      "iOS",
      "live",
      "mac",
      "monitor",
      "movie",
      "play",
      "stream",
      "television",
      "tv",
      "web",
      "window",
    ],
  },
  living: {
    name: "living",
    version: 9,
    popularity: 3133,
    codepoint: 61483,
    categories: ["search"],
    tags: [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa",
    ],
  },
  local_activity: {
    name: "local_activity",
    version: 19,
    popularity: 16481,
    codepoint: 58687,
    categories: ["maps"],
    tags: [
      "activity",
      "event",
      "event ticket",
      "local",
      "star",
      "things",
      "ticket",
    ],
  },
  local_atm: {
    name: "local_atm",
    version: 12,
    popularity: 16959,
    codepoint: 58686,
    categories: ["maps"],
    tags: [
      "atm",
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "local",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol",
    ],
  },
  local_bar: {
    name: "local_bar",
    version: 12,
    popularity: 9253,
    codepoint: 58688,
    categories: ["maps"],
    tags: [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "local",
      "wine",
    ],
  },
  local_cafe: {
    name: "local_cafe",
    version: 11,
    popularity: 15869,
    codepoint: 58689,
    categories: ["maps"],
    tags: [
      "bottle",
      "cafe",
      "coffee",
      "cup",
      "drink",
      "food",
      "restaurant",
      "tea",
    ],
  },
  local_car_wash: {
    name: "local_car_wash",
    version: 12,
    popularity: 2276,
    codepoint: 58690,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "local",
      "maps",
      "transportation",
      "travel",
      "vehicle",
      "wash",
    ],
  },
  local_convenience_store: {
    name: "local_convenience_store",
    version: 16,
    popularity: 3826,
    codepoint: 58691,
    categories: ["maps"],
    tags: [
      "--",
      "24",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "convenience",
      "credit",
      "currency",
      "dollars",
      "local",
      "maps",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol",
    ],
  },
  local_dining: {
    name: "local_dining",
    version: 21,
    popularity: 7255,
    codepoint: 58710,
    categories: ["maps"],
    tags: [
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "restaurant",
      "spoon",
    ],
  },
  local_drink: {
    name: "local_drink",
    version: 12,
    popularity: 6675,
    codepoint: 58692,
    categories: ["maps"],
    tags: [
      "cup",
      "drink",
      "drop",
      "droplet",
      "liquid",
      "local",
      "park",
      "water",
    ],
  },
  local_fire_department: {
    name: "local_fire_department",
    version: 15,
    popularity: 37192,
    codepoint: 61269,
    categories: ["maps"],
    tags: [
      "911",
      "climate",
      "department",
      "fire",
      "firefighter",
      "flame",
      "heat",
      "home",
      "hot",
      "nest",
      "thermostat",
    ],
  },
  local_florist: {
    name: "local_florist",
    version: 12,
    popularity: 11042,
    codepoint: 58693,
    categories: ["maps"],
    tags: ["florist", "flower", "local", "shop"],
  },
  local_gas_station: {
    name: "local_gas_station",
    version: 13,
    popularity: 11542,
    codepoint: 58694,
    categories: ["maps"],
    tags: ["auto", "car", "gas", "local", "oil", "station", "vehicle"],
  },
  local_hospital: {
    name: "local_hospital",
    version: 12,
    popularity: 18932,
    codepoint: 58696,
    categories: ["maps"],
    tags: [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine",
    ],
  },
  local_laundry_service: {
    name: "local_laundry_service",
    version: 16,
    popularity: 6674,
    codepoint: 58698,
    categories: ["maps"],
    tags: [
      "cleaning",
      "clothing",
      "dry",
      "dryer",
      "hotel",
      "laundry",
      "local",
      "service",
      "washer",
    ],
  },
  local_library: {
    name: "local_library",
    version: 12,
    popularity: 18067,
    codepoint: 58699,
    categories: ["maps"],
    tags: ["book", "community learning", "library", "local", "read"],
  },
  local_mall: {
    name: "local_mall",
    version: 12,
    popularity: 26647,
    codepoint: 58700,
    categories: ["maps"],
    tags: [
      "bag",
      "bill",
      "building",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "handbag",
      "local",
      "mall",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront",
    ],
  },
  local_parking: {
    name: "local_parking",
    version: 12,
    popularity: 11123,
    codepoint: 58703,
    categories: ["maps"],
    tags: [
      "alphabet",
      "auto",
      "car",
      "character",
      "font",
      "garage",
      "letter",
      "local",
      "park",
      "parking",
      "symbol",
      "text",
      "type",
      "vehicle",
    ],
  },
  local_pharmacy: {
    name: "local_pharmacy",
    version: 12,
    popularity: 4915,
    codepoint: 58704,
    categories: ["maps"],
    tags: [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine",
      "pharmacy",
      "places",
    ],
  },
  local_pizza: {
    name: "local_pizza",
    version: 12,
    popularity: 6400,
    codepoint: 58706,
    categories: ["maps"],
    tags: ["drink", "fastfood", "food", "local", "meal", "pizza"],
  },
  local_police: {
    name: "local_police",
    version: 13,
    popularity: 14454,
    codepoint: 61270,
    categories: ["maps"],
    tags: [
      "911",
      "badge",
      "law",
      "local",
      "officer",
      "police",
      "protect",
      "protection",
      "security",
      "shield",
    ],
  },
  local_post_office: {
    name: "local_post_office",
    version: 12,
    popularity: 10634,
    codepoint: 58708,
    categories: ["maps"],
    tags: [
      "delivery",
      "email",
      "envelop",
      "letter",
      "local",
      "mail",
      "message",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "stamp",
    ],
  },
  local_see: {
    name: "local_see",
    version: 16,
    popularity: 4392,
    codepoint: 58711,
    categories: ["maps"],
    tags: ["camera", "lens", "local", "photo", "photography", "picture", "see"],
  },
  local_shipping: {
    name: "local_shipping",
    version: 12,
    popularity: 189600,
    codepoint: 58712,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "delivery",
      "letter",
      "local",
      "mail",
      "maps",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "shipping",
      "shopping",
      "stamp",
      "transportation",
      "truck",
      "vehicle",
    ],
  },
  local_taxi: {
    name: "local_taxi",
    version: 12,
    popularity: 6983,
    codepoint: 58713,
    categories: ["maps"],
    tags: [
      "automobile",
      "cab",
      "call",
      "car",
      "cars",
      "direction",
      "local",
      "lyft",
      "maps",
      "public",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "yellow",
    ],
  },
  location_away: {
    name: "location_away",
    version: 229,
    popularity: 2106,
    codepoint: 61776,
    categories: ["Maps"],
    tags: [
      "account",
      "architecture",
      "avatar",
      "away",
      "building",
      "estate",
      "face",
      "home",
      "house",
      "human",
      "location",
      "people",
      "person",
      "place",
      "profile",
      "real",
      "residence",
      "residential",
      "user",
    ],
  },
  location_chip: {
    name: "location_chip",
    version: 229,
    popularity: 30,
    codepoint: 63568,
    categories: ["Text Formatting"],
    tags: [
      "chip",
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "stop",
    ],
  },
  location_city: {
    name: "location_city",
    version: 12,
    popularity: 27469,
    codepoint: 59377,
    categories: ["social"],
    tags: [
      "apartments",
      "architecture",
      "buildings",
      "business",
      "city",
      "estate",
      "home",
      "landscape",
      "location",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "town",
      "urban",
    ],
  },
  location_disabled: {
    name: "location_disabled",
    version: 12,
    popularity: 1211,
    codepoint: 57782,
    categories: ["device"],
    tags: [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "off",
      "on",
      "pin",
      "place",
      "pointer",
      "slash",
      "stop",
      "tracking",
    ],
  },
  location_home: {
    name: "location_home",
    version: 229,
    popularity: 1324,
    codepoint: 61778,
    categories: ["Maps"],
    tags: [
      "account",
      "architecture",
      "avatar",
      "building",
      "estate",
      "face",
      "home",
      "human",
      "location",
      "people",
      "person",
      "place",
      "profile",
      "real",
      "residence",
      "residential",
      "shelter",
      "user",
    ],
  },
  location_off: {
    name: "location_off",
    version: 11,
    popularity: 3580,
    codepoint: 57543,
    categories: ["communication"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "off",
      "pin",
      "place",
      "room",
      "stop",
    ],
  },
  location_on: {
    name: "location_on",
    version: 15,
    popularity: 194382,
    codepoint: 57544,
    categories: ["communication"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "on",
      "pin",
      "place",
      "room",
      "stop",
    ],
  },
  location_searching: {
    name: "location_searching",
    version: 12,
    popularity: 7747,
    codepoint: 57783,
    categories: ["device"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "pointer",
      "searching",
      "stop",
      "tracking",
    ],
  },
  lock: {
    name: "lock",
    version: 18,
    popularity: 204614,
    codepoint: 59543,
    categories: ["action"],
    tags: [
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
    ],
  },
  lock_clock: {
    name: "lock_clock",
    version: 11,
    popularity: 7547,
    codepoint: 61271,
    categories: ["action"],
    tags: [
      "clock",
      "date",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "schedule",
      "secure",
      "security",
      "time",
    ],
  },
  lock_open: {
    name: "lock_open",
    version: 16,
    popularity: 57920,
    codepoint: 59544,
    categories: ["action"],
    tags: [
      "lock",
      "open",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "unlocked",
    ],
  },
  lock_open_right: {
    name: "lock_open_right",
    version: 229,
    popularity: 37,
    codepoint: 63062,
    categories: ["Common actions"],
    tags: [
      "lock",
      "open",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "unlocked",
    ],
  },
  lock_person: {
    name: "lock_person",
    version: 1,
    popularity: 2018,
    codepoint: 63731,
    categories: ["action"],
    tags: [],
  },
  lock_reset: {
    name: "lock_reset",
    version: 1,
    popularity: 7030,
    codepoint: 60126,
    categories: ["action"],
    tags: [
      "around",
      "inprogress",
      "load",
      "loading refresh",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "turn",
    ],
  },
  login: {
    name: "login",
    version: 12,
    popularity: 122749,
    codepoint: 60023,
    categories: ["action"],
    tags: [
      "access",
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "enter",
      "in",
      "interface",
      "left",
      "log",
      "login",
      "screen",
      "sign",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  logo_dev: {
    name: "logo_dev",
    version: 2,
    popularity: 1741,
    codepoint: 60118,
    categories: ["image"],
    tags: ["dev", "dev.to", "logo"],
  },
  logout: {
    name: "logout",
    version: 12,
    popularity: 238982,
    codepoint: 59834,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "interface",
      "leave",
      "log",
      "login",
      "logout",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  looks: {
    name: "looks",
    version: 13,
    popularity: 2448,
    codepoint: 58364,
    categories: ["image"],
    tags: ["circle", "half", "looks", "rainbow"],
  },
  looks_3: {
    name: "looks_3",
    version: 12,
    popularity: 7431,
    codepoint: 58363,
    categories: ["image"],
    tags: ["3", "digit", "looks", "numbers", "square", "symbol"],
  },
  looks_4: {
    name: "looks_4",
    version: 12,
    popularity: 4121,
    codepoint: 58365,
    categories: ["image"],
    tags: ["4", "digit", "looks", "numbers", "square", "symbol"],
  },
  looks_5: {
    name: "looks_5",
    version: 12,
    popularity: 3116,
    codepoint: 58366,
    categories: ["image"],
    tags: ["5", "digit", "looks", "numbers", "square", "symbol"],
  },
  looks_6: {
    name: "looks_6",
    version: 13,
    popularity: 2378,
    codepoint: 58367,
    categories: ["image"],
    tags: ["6", "digit", "looks", "numbers", "square", "symbol"],
  },
  looks_one: {
    name: "looks_one",
    version: 12,
    popularity: 16112,
    codepoint: 58368,
    categories: ["image"],
    tags: ["1", "digit", "looks", "numbers", "square", "symbol"],
  },
  looks_two: {
    name: "looks_two",
    version: 12,
    popularity: 8549,
    codepoint: 58369,
    categories: ["image"],
    tags: ["2", "digit", "looks", "numbers", "square", "symbol"],
  },
  loupe: {
    name: "loupe",
    version: 12,
    popularity: 4827,
    codepoint: 58370,
    categories: ["image"],
    tags: [
      "+",
      "add",
      "details",
      "focus",
      "glass",
      "loupe",
      "magnifying",
      "new",
      "plus",
      "symbol",
    ],
  },
  low_density: {
    name: "low_density",
    version: 229,
    popularity: 2,
    codepoint: 63387,
    categories: ["Photo&Image"],
    tags: ["dash", "dashed", "dense", "output", "quality", "screen"],
  },
  low_priority: {
    name: "low_priority",
    version: 18,
    popularity: 6849,
    codepoint: 57709,
    categories: ["content"],
    tags: [
      "arrange",
      "arrow",
      "backward",
      "bottom",
      "list",
      "low",
      "move",
      "order",
      "priority",
    ],
  },
  loyalty: {
    name: "loyalty",
    version: 12,
    popularity: 21345,
    codepoint: 59546,
    categories: ["action"],
    tags: [
      "benefits",
      "card",
      "credit",
      "heart",
      "loyalty",
      "membership",
      "miles",
      "points",
      "program",
      "subscription",
      "tag",
      "travel",
      "trip",
    ],
  },
  lte_mobiledata: {
    name: "lte_mobiledata",
    version: 9,
    popularity: 1234,
    codepoint: 61484,
    categories: ["device"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless",
    ],
  },
  lte_mobiledata_badge: {
    name: "lte_mobiledata_badge",
    version: 229,
    popularity: 2,
    codepoint: 63449,
    categories: ["Android"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless",
    ],
  },
  lte_plus_mobiledata: {
    name: "lte_plus_mobiledata",
    version: 9,
    popularity: 1015,
    codepoint: 61485,
    categories: ["device"],
    tags: [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "plus",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless",
    ],
  },
  lte_plus_mobiledata_badge: {
    name: "lte_plus_mobiledata_badge",
    version: 229,
    popularity: 1,
    codepoint: 63448,
    categories: ["Android"],
    tags: [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "plus",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless",
    ],
  },
  luggage: {
    name: "luggage",
    version: 7,
    popularity: 10039,
    codepoint: 62005,
    categories: ["social"],
    tags: [
      "airport",
      "bag",
      "baggage",
      "carry",
      "flight",
      "hotel",
      "luggage",
      "on",
      "suitcase",
      "travel",
      "trip",
    ],
  },
  lunch_dining: {
    name: "lunch_dining",
    version: 11,
    popularity: 19239,
    codepoint: 60001,
    categories: ["maps"],
    tags: [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "hamburger",
      "lunch",
      "meal",
    ],
  },
  lyrics: {
    name: "lyrics",
    version: 3,
    popularity: 1159,
    codepoint: 60427,
    categories: ["av"],
    tags: [
      "audio",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "key",
      "lyrics",
      "message",
      "music",
      "note",
      "song",
      "sound",
      "speech",
      "track",
    ],
  },
  macro_auto: {
    name: "macro_auto",
    version: 229,
    popularity: 4,
    codepoint: 63218,
    categories: ["Photo&Image"],
    tags: [
      "automatic",
      "automation",
      "camera",
      "flower",
      "garden",
      "image",
      "macro",
    ],
  },
  macro_off: {
    name: "macro_off",
    version: 1,
    popularity: 219,
    codepoint: 63698,
    categories: ["device"],
    tags: [
      "camera",
      "disabled",
      "enabled",
      "flower",
      "garden",
      "image",
      "macro",
      "off",
      "offline",
      "on",
      "slash",
    ],
  },
  magnification_large: {
    name: "magnification_large",
    version: 229,
    popularity: 24,
    codepoint: 63549,
    categories: ["UI actions"],
    tags: ["large", "magnify", "magnifying", "view", "zoom"],
  },
  magnification_small: {
    name: "magnification_small",
    version: 229,
    popularity: 25,
    codepoint: 63548,
    categories: ["UI actions"],
    tags: ["magnify", "magnifying", "small", "view", "zoom"],
  },
  magnify_docked: {
    name: "magnify_docked",
    version: 229,
    popularity: 3,
    codepoint: 63446,
    categories: ["Android"],
    tags: [
      "+",
      "add",
      "display",
      "dock",
      "magnification",
      "new",
      "plus",
      "screen",
      "window",
      "zoom",
    ],
  },
  magnify_fullscreen: {
    name: "magnify_fullscreen",
    version: 229,
    popularity: 7,
    codepoint: 63445,
    categories: ["Android"],
    tags: [
      "+",
      "add",
      "display",
      "dock",
      "magnification",
      "new",
      "plus",
      "screen",
      "window",
      "zoom",
    ],
  },
  mail: {
    name: "mail",
    version: 16,
    popularity: 78960,
    codepoint: 57688,
    categories: ["content"],
    tags: ["email", "envelop", "letter", "mail", "message", "send"],
  },
  mail_lock: {
    name: "mail_lock",
    version: 1,
    popularity: 1427,
    codepoint: 60426,
    categories: ["communication"],
    tags: [
      "email",
      "envelop",
      "letter",
      "lock",
      "locked",
      "mail",
      "message",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "send",
    ],
  },
  male: {
    name: "male",
    version: 3,
    popularity: 12542,
    codepoint: 58766,
    categories: ["social"],
    tags: ["boy", "gender", "male", "man", "social", "symbol"],
  },
  man: {
    name: "man",
    version: 2,
    popularity: 6098,
    codepoint: 58603,
    categories: ["social"],
    tags: ["boy", "gender", "male", "man", "social", "symbol"],
  },
  man_2: {
    name: "man_2",
    version: 1,
    popularity: 645,
    codepoint: 63713,
    categories: ["social"],
    tags: ["boy", "gender", "male", "man", "social", "symbol"],
  },
  man_3: {
    name: "man_3",
    version: 1,
    popularity: 319,
    codepoint: 63714,
    categories: ["social"],
    tags: ["abstract", "boy", "gender", "male", "man", "social", "symbol"],
  },
  man_4: {
    name: "man_4",
    version: 1,
    popularity: 377,
    codepoint: 63715,
    categories: ["social"],
    tags: ["abstract", "boy", "gender", "male", "man", "social", "symbol"],
  },
  manage_accounts: {
    name: "manage_accounts",
    version: 10,
    popularity: 145563,
    codepoint: 61486,
    categories: ["action"],
    tags: [
      "accounts",
      "change",
      "details",
      "face",
      "gear",
      "human",
      "manage",
      "options",
      "people",
      "person",
      "profile",
      "service",
      "settings",
      "user",
    ],
  },
  manage_history: {
    name: "manage_history",
    version: 1,
    popularity: 4385,
    codepoint: 60391,
    categories: ["action"],
    tags: [
      "application",
      "arrow",
      "back",
      "backwards",
      "change",
      "clock",
      "date",
      "details",
      "gear",
      "history",
      "options",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "settings",
      "time",
      "turn",
    ],
  },
  manage_search: {
    name: "manage_search",
    version: 10,
    popularity: 40368,
    codepoint: 61487,
    categories: ["search"],
    tags: ["glass", "history", "magnifying", "manage", "search", "text"],
  },
  manga: {
    name: "manga",
    version: 229,
    popularity: 6,
    codepoint: 62947,
    categories: ["Social"],
    tags: [
      "animation",
      "anime",
      "cartoons",
      "comic book",
      "comic strip",
      "comics",
      "google play",
      "graphic novels",
      "speech bubble",
    ],
  },
  manufacturing: {
    name: "manufacturing",
    version: 229,
    popularity: 426,
    codepoint: 59174,
    categories: ["Common actions"],
    tags: [
      "adjustments",
      "clockwork",
      "cog",
      "configuration",
      "factory",
      "gears",
      "industry",
      "machine",
      "manufacturing",
      "mechanical",
      "options",
      "refinery",
      "repair",
      "settings",
    ],
  },
  map: {
    name: "map",
    version: 16,
    popularity: 55045,
    codepoint: 58715,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "location",
      "map",
      "maps",
      "pin",
      "place",
      "route",
      "stop",
      "travel",
    ],
  },
  maps_ugc: {
    name: "maps_ugc",
    version: 13,
    popularity: 7206,
    codepoint: 61272,
    categories: ["maps"],
    tags: [
      "+",
      "add",
      "bubble",
      "comment",
      "communicate",
      "feedback",
      "maps",
      "message",
      "new",
      "plus",
      "speech",
      "symbol",
      "ugc",
    ],
  },
  margin: {
    name: "margin",
    version: 11,
    popularity: 1794,
    codepoint: 59835,
    categories: ["editor"],
    tags: ["design", "layout", "margin", "padding", "size", "square"],
  },
  mark_as_unread: {
    name: "mark_as_unread",
    version: 10,
    popularity: 9036,
    codepoint: 59836,
    categories: ["action"],
    tags: [
      "as",
      "envelop",
      "letter",
      "mail",
      "mark",
      "post",
      "postal",
      "read",
      "receive",
      "send",
      "unread",
    ],
  },
  mark_chat_read: {
    name: "mark_chat_read",
    version: 8,
    popularity: 4787,
    codepoint: 61835,
    categories: ["communication"],
    tags: [
      "approve",
      "bubble",
      "chat",
      "check",
      "comment",
      "communicate",
      "complete",
      "done",
      "mark",
      "message",
      "ok",
      "read",
      "select",
      "sent",
      "speech",
      "tick",
      "verified",
      "yes",
    ],
  },
  mark_chat_unread: {
    name: "mark_chat_unread",
    version: 8,
    popularity: 7554,
    codepoint: 61833,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "mark",
      "message",
      "notification",
      "speech",
      "unread",
    ],
  },
  mark_email_read: {
    name: "mark_email_read",
    version: 8,
    popularity: 14847,
    codepoint: 61836,
    categories: ["communication"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "ok",
      "read",
      "select",
      "send",
      "sent",
      "tick",
      "yes",
    ],
  },
  mark_email_unread: {
    name: "mark_email_unread",
    version: 8,
    popularity: 11081,
    codepoint: 61834,
    categories: ["communication"],
    tags: [
      "check",
      "circle",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "notification",
      "send",
      "unread",
    ],
  },
  mark_unread_chat_alt: {
    name: "mark_unread_chat_alt",
    version: 1,
    popularity: 1930,
    codepoint: 60317,
    categories: ["communication"],
    tags: [
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "mark",
      "message",
      "notification",
      "speech",
      "unread",
    ],
  },
  markdown: {
    name: "markdown",
    version: 229,
    popularity: 1,
    codepoint: 62802,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "code",
      "coding",
      "font",
      "letter",
      "markup",
      "markup language",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type",
    ],
  },
  markdown_copy: {
    name: "markdown_copy",
    version: 229,
    popularity: 5,
    codepoint: 62803,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "code",
      "coding",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "font",
      "letter",
      "markup",
      "markup language",
      "multiple",
      "paste",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type",
    ],
  },
  markdown_paste: {
    name: "markdown_paste",
    version: 229,
    popularity: 0,
    codepoint: 62804,
    categories: ["Text Formatting"],
    tags: [
      "alphabet",
      "character",
      "clipboard",
      "code",
      "coding",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "font",
      "letter",
      "markup",
      "markup language",
      "multiple",
      "paste",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type",
    ],
  },
  markunread_mailbox: {
    name: "markunread_mailbox",
    version: 12,
    popularity: 6892,
    codepoint: 59547,
    categories: ["action"],
    tags: [
      "deliver",
      "envelop",
      "letter",
      "mail",
      "mailbox",
      "markunread",
      "post",
      "postal",
      "postbox",
      "receive",
      "send",
      "unread",
    ],
  },
  masked_transitions: {
    name: "masked_transitions",
    version: 229,
    popularity: 50,
    codepoint: 59182,
    categories: ["Photo&Image"],
    tags: [
      "edit",
      "editing",
      "effect",
      "film",
      "filter",
      "masked",
      "movie",
      "settings",
      "transitions",
      "video",
    ],
  },
  masks: {
    name: "masks",
    version: 7,
    popularity: 10471,
    codepoint: 61976,
    categories: ["social"],
    tags: [
      "air",
      "cover",
      "covid",
      "face",
      "hospital",
      "masks",
      "medical",
      "pollution",
      "protection",
      "respirator",
      "sick",
      "social",
    ],
  },
  match_case: {
    name: "match_case",
    version: 229,
    popularity: 13,
    codepoint: 63217,
    categories: ["Text Formatting"],
    tags: [
      "a",
      "alphabet",
      "character",
      "font",
      "letter",
      "letters",
      "lowercase",
      "symbol",
      "text",
      "text transformation",
      "title case",
      "type",
      "uppercase",
    ],
  },
  match_word: {
    name: "match_word",
    version: 229,
    popularity: 6,
    codepoint: 63216,
    categories: ["Text Formatting"],
    tags: [
      "a",
      "alphabet",
      "b",
      "character",
      "font",
      "letter",
      "letters",
      "symbol",
      "text",
      "type",
    ],
  },
  matter: {
    name: "matter",
    version: 229,
    popularity: 434,
    codepoint: 59655,
    categories: ["Household"],
    tags: [
      "bluetooth",
      "connect",
      "connection",
      "device",
      "matter",
      "paring",
      "symbol",
      "wireless",
    ],
  },
  maximize: {
    name: "maximize",
    version: 11,
    popularity: 11489,
    codepoint: 59696,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "maximize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  measuring_tape: {
    name: "measuring_tape",
    version: 229,
    popularity: 3,
    codepoint: 63151,
    categories: ["Common actions"],
    tags: ["body", "fitness", "measure", "tape"],
  },
  media_bluetooth_off: {
    name: "media_bluetooth_off",
    version: 10,
    popularity: 709,
    codepoint: 61489,
    categories: ["device"],
    tags: [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless",
    ],
  },
  media_bluetooth_on: {
    name: "media_bluetooth_on",
    version: 10,
    popularity: 1169,
    codepoint: 61490,
    categories: ["device"],
    tags: [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "on",
      "online",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless",
    ],
  },
  media_link: {
    name: "media_link",
    version: 229,
    popularity: 31,
    codepoint: 63551,
    categories: ["Audio&Video"],
    tags: [
      "attach",
      "attachment",
      "link",
      "media",
      "paperclip",
      "play",
      "slide",
      "slideshow",
      "video",
    ],
  },
  mediation: {
    name: "mediation",
    version: 15,
    popularity: 8011,
    codepoint: 61351,
    categories: ["action"],
    tags: ["arrow", "arrows", "direction", "dots", "mediation", "right"],
  },
  medical_information: {
    name: "medical_information",
    version: 1,
    popularity: 3689,
    codepoint: 60397,
    categories: ["maps"],
    tags: [
      "badge",
      "card",
      "health",
      "id",
      "information",
      "medical",
      "services",
    ],
  },
  medical_mask: {
    name: "medical_mask",
    version: 229,
    popularity: 2,
    codepoint: 63498,
    categories: ["Social"],
    tags: [
      "health",
      "isolation",
      "masks",
      "medical",
      "pandemic",
      "social distance",
    ],
  },
  medical_services: {
    name: "medical_services",
    version: 12,
    popularity: 20937,
    codepoint: 61705,
    categories: ["maps"],
    tags: [
      "aid",
      "bag",
      "briefcase",
      "emergency",
      "first",
      "kit",
      "medical",
      "medicine",
      "services",
    ],
  },
  medication: {
    name: "medication",
    version: 10,
    popularity: 17452,
    codepoint: 61491,
    categories: ["device"],
    tags: [
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "medication",
      "medicine",
      "pharmacy",
      "pills",
      "prescription",
    ],
  },
  medication_liquid: {
    name: "medication_liquid",
    version: 2,
    popularity: 1871,
    codepoint: 60039,
    categories: ["device"],
    tags: [
      "+",
      "bottle",
      "doctor",
      "drug",
      "health",
      "hospital",
      "liquid",
      "medications",
      "medicine",
      "pharmacy",
      "spoon",
      "vessel",
    ],
  },
  meeting_room: {
    name: "meeting_room",
    version: 17,
    popularity: 19629,
    codepoint: 60239,
    categories: ["places"],
    tags: [
      "building",
      "door",
      "doorway",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "office",
      "open",
      "places",
      "room",
    ],
  },
  memory: {
    name: "memory",
    version: 12,
    popularity: 15979,
    codepoint: 58146,
    categories: ["hardware"],
    tags: [
      "card",
      "chip",
      "digital",
      "memory",
      "micro",
      "processor",
      "sd",
      "storage",
    ],
  },
  memory_alt: {
    name: "memory_alt",
    version: 229,
    popularity: 32,
    codepoint: 63395,
    categories: ["Hardware"],
    tags: ["card", "chip", "digital", "micro", "processor", "sd", "storage"],
  },
  menstrual_health: {
    name: "menstrual_health",
    version: 229,
    popularity: 46,
    codepoint: 63201,
    categories: ["Activities"],
    tags: [
      "blood",
      "cycle",
      "female",
      "fitbit",
      "health",
      "menstruation",
      "ovulation",
      "period",
      "pregnancy",
    ],
  },
  menu: {
    name: "menu",
    version: 13,
    popularity: 348659,
    codepoint: 58834,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "components",
      "hamburger",
      "interface",
      "line",
      "lines",
      "menu",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  menu_book: {
    name: "menu_book",
    version: 12,
    popularity: 67207,
    codepoint: 59929,
    categories: ["maps"],
    tags: ["book", "dining", "food", "meal", "menu", "restaurant"],
  },
  menu_open: {
    name: "menu_open",
    version: 13,
    popularity: 41062,
    codepoint: 59837,
    categories: ["navigation"],
    tags: [
      "app",
      "application",
      "arrow",
      "components",
      "hamburger",
      "interface",
      "left",
      "line",
      "lines",
      "menu",
      "open",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  merge: {
    name: "merge",
    version: 1,
    popularity: 2732,
    codepoint: 60312,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "merge",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
    ],
  },
  merge_type: {
    name: "merge_type",
    version: 13,
    popularity: 4591,
    codepoint: 57938,
    categories: ["editor"],
    tags: ["arrow", "combine", "direction", "format", "merge", "text", "type"],
  },
  metabolism: {
    name: "metabolism",
    version: 229,
    popularity: 12,
    codepoint: 57611,
    categories: ["Social"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "body",
      "health",
      "human",
      "inprogress",
      "load",
      "loading refresh",
      "person",
      "renew",
      "rotate",
      "turn",
    ],
  },
  mfg_nest_yale_lock: {
    name: "mfg_nest_yale_lock",
    version: 229,
    popularity: 269,
    codepoint: 61725,
    categories: ["Home"],
    tags: [
      "device",
      "google",
      "hardware",
      "lock",
      "mfg",
      "nest",
      "passcode",
      "password",
      "yale",
    ],
  },
  mic: {
    name: "mic",
    version: 13,
    popularity: 58970,
    codepoint: 57385,
    categories: ["av"],
    tags: [
      "dictation",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "record",
      "sound",
      "voice",
    ],
  },
  mic_double: {
    name: "mic_double",
    version: 229,
    popularity: 22,
    codepoint: 62929,
    categories: ["Audio&Video"],
    tags: [
      "dictation",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "record",
      "sound",
      "voice",
    ],
  },
  mic_external_off: {
    name: "mic_external_off",
    version: 11,
    popularity: 751,
    codepoint: 61273,
    categories: ["image"],
    tags: [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice",
    ],
  },
  mic_external_on: {
    name: "mic_external_on",
    version: 11,
    popularity: 3079,
    codepoint: 61274,
    categories: ["image"],
    tags: [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice",
    ],
  },
  mic_off: {
    name: "mic_off",
    version: 14,
    popularity: 14365,
    codepoint: 57387,
    categories: ["av"],
    tags: [
      "audio",
      "disabled",
      "enabled",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "off",
      "on",
      "record",
      "recording",
      "slash",
      "sound",
      "voice",
    ],
  },
  microbiology: {
    name: "microbiology",
    version: 229,
    popularity: 19,
    codepoint: 57612,
    categories: ["Social"],
    tags: [
      "body",
      "disease",
      "health",
      "human",
      "immune",
      "infection",
      "infectious",
      "microbe",
      "microbes",
      "virus",
      "viruses",
    ],
  },
  microwave: {
    name: "microwave",
    version: 6,
    popularity: 3857,
    codepoint: 61956,
    categories: ["places"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "heat",
      "home",
      "house",
      "kitchen",
      "machine",
      "microwave",
    ],
  },
  microwave_gen: {
    name: "microwave_gen",
    version: 229,
    popularity: 385,
    codepoint: 59463,
    categories: ["Household"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "food",
      "home",
      "hot",
      "house",
      "kitchen",
      "machine",
      "meal",
      "microwave",
      "nest",
    ],
  },
  military_tech: {
    name: "military_tech",
    version: 11,
    popularity: 24778,
    codepoint: 59967,
    categories: ["social"],
    tags: [
      "army",
      "award",
      "badge",
      "honor",
      "medal",
      "merit",
      "military",
      "order",
      "privilege",
      "prize",
      "rank",
      "reward",
      "ribbon",
      "soldier",
      "star",
      "status",
      "tech",
      "trophy",
      "win",
      "winner",
    ],
  },
  mimo: {
    name: "mimo",
    version: 229,
    popularity: 41,
    codepoint: 59838,
    categories: ["Hardware"],
    tags: [
      "4k",
      "camera",
      "chrome",
      "device",
      "disabled",
      "display",
      "enabled",
      "google",
      "hangouts",
      "hardware",
      "hdmi",
      "meet",
      "mic",
      "mimo",
      "monitor",
      "off",
      "on",
      "slash",
      "speaker",
      "touch",
    ],
  },
  mimo_disconnect: {
    name: "mimo_disconnect",
    version: 229,
    popularity: 30,
    codepoint: 59839,
    categories: ["Hardware"],
    tags: [
      "4k",
      "camera",
      "chrome",
      "device",
      "disabled",
      "disconnect",
      "display",
      "enabled",
      "google",
      "hangouts",
      "hardware",
      "hdmi",
      "meet",
      "mic",
      "mimo",
      "monitor",
      "off",
      "on",
      "slash",
      "speaker",
      "touch",
    ],
  },
  mindfulness: {
    name: "mindfulness",
    version: 229,
    popularity: 94,
    codepoint: 63200,
    categories: ["Activities"],
    tags: ["attention", "fitbit", "focus", "mindfulness", "wellness"],
  },
  minimize: {
    name: "minimize",
    version: 11,
    popularity: 27396,
    codepoint: 59697,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "minimize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  minor_crash: {
    name: "minor_crash",
    version: 1,
    popularity: 1053,
    codepoint: 60401,
    categories: ["maps"],
    tags: [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "collision",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle",
    ],
  },
  mintmark: {
    name: "mintmark",
    version: 229,
    popularity: 22,
    codepoint: 61353,
    categories: ["Business&Payments"],
    tags: [
      "arrow",
      "arrows",
      "bill",
      "billing",
      "card",
      "cash",
      "cloud",
      "coin",
      "commerce",
      "credit",
      "currency",
      "diagonal",
      "direction",
      "dollars",
      "google",
      "mintmark",
      "money",
      "online",
      "pay",
      "payment",
      "service",
      "symbol",
    ],
  },
  missed_video_call: {
    name: "missed_video_call",
    version: 13,
    popularity: 1226,
    codepoint: 57459,
    categories: ["av"],
    tags: [
      "arrow",
      "call",
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "missed",
      "motion",
      "picture",
      "record",
      "video",
      "videography",
    ],
  },
  missing_controller: {
    name: "missing_controller",
    version: 229,
    popularity: 12,
    codepoint: 59137,
    categories: ["Hardware"],
    tags: [
      "controller",
      "device",
      "locate",
      "locating",
      "missing",
      "remote",
      "signals",
      "wireless",
    ],
  },
  mist: {
    name: "mist",
    version: 229,
    popularity: 17,
    codepoint: 57736,
    categories: ["Social"],
    tags: ["climate", "fog", "haze", "misty", "temperature", "weather"],
  },
  mitre: {
    name: "mitre",
    version: 229,
    popularity: 7,
    codepoint: 62791,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "chart",
      "cyber attacks",
      "data",
      "diagram",
      "flow",
      "framework",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "protect",
      "protection",
      "schema",
      "security",
      "squares",
      "statistics",
      "tracking",
    ],
  },
  mixture_med: {
    name: "mixture_med",
    version: 229,
    popularity: 5,
    codepoint: 58568,
    categories: ["Social"],
    tags: [
      "bandage",
      "bandaid",
      "booster",
      "health",
      "immune",
      "immunization",
      "shot",
      "syringe",
    ],
  },
  mms: {
    name: "mms",
    version: 12,
    popularity: 2083,
    codepoint: 58904,
    categories: ["notification"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "image",
      "landscape",
      "message",
      "mms",
      "mountain",
      "mountains",
      "multimedia",
      "photo",
      "photography",
      "picture",
      "speech",
    ],
  },
  mobile_friendly: {
    name: "mobile_friendly",
    version: 12,
    popularity: 4625,
    codepoint: 57856,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "approve",
      "cell",
      "check",
      "complete",
      "device",
      "done",
      "friendly",
      "hardware",
      "iOS",
      "mark",
      "mobile",
      "ok",
      "phone",
      "select",
      "tablet",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  mobile_off: {
    name: "mobile_off",
    version: 12,
    popularity: 1179,
    codepoint: 57857,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "off",
      "on",
      "phone",
      "silence",
      "slash",
      "tablet",
    ],
  },
  mobile_screen_share: {
    name: "mobile_screen_share",
    version: 12,
    popularity: 2648,
    codepoint: 57575,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "cast",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mirror",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "screencast",
      "share",
      "stream",
      "streaming",
      "tablet",
      "tv",
      "wireless",
    ],
  },
  mobiledata_off: {
    name: "mobiledata_off",
    version: 10,
    popularity: 2845,
    codepoint: 61492,
    categories: ["device"],
    tags: [
      "arrow",
      "data",
      "disabled",
      "down",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "slash",
      "speed",
      "up",
      "wifi",
      "wireless",
    ],
  },
  mode_comment: {
    name: "mode_comment",
    version: 12,
    popularity: 11597,
    codepoint: 57939,
    categories: ["editor"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "mode comment",
      "speech",
    ],
  },
  mode_cool: {
    name: "mode_cool",
    version: 229,
    popularity: 470,
    codepoint: 61798,
    categories: ["Household"],
    tags: ["climate", "cool", "flame", "heat", "home", "nest", "thermostat"],
  },
  mode_cool_off: {
    name: "mode_cool_off",
    version: 229,
    popularity: 119,
    codepoint: 61799,
    categories: ["Household"],
    tags: [
      "climate",
      "cool",
      "disabled",
      "enabled",
      "flame",
      "heat",
      "home",
      "nest",
      "off",
      "offline",
      "on",
      "slash",
      "thermostat",
    ],
  },
  mode_dual: {
    name: "mode_dual",
    version: 229,
    popularity: 6,
    codepoint: 62807,
    categories: ["Home"],
    tags: [
      "air",
      "bismuth",
      "blowing",
      "breeze",
      "climate",
      "cool",
      "flame",
      "flow",
      "home",
      "nest",
      "thermostat",
      "wave",
      "weather",
      "wind",
    ],
  },
  mode_fan: {
    name: "mode_fan",
    version: 229,
    popularity: 1687,
    codepoint: 61800,
    categories: ["Household"],
    tags: ["air conditioner", "cool", "fan", "nest"],
  },
  mode_fan_off: {
    name: "mode_fan_off",
    version: 1,
    popularity: 928,
    codepoint: 60439,
    categories: ["home"],
    tags: [
      "air conditioner",
      "cool",
      "disabled",
      "enabled",
      "fan",
      "nest",
      "off",
      "on",
      "slash",
    ],
  },
  mode_heat: {
    name: "mode_heat",
    version: 229,
    popularity: 988,
    codepoint: 61802,
    categories: ["Household"],
    tags: ["climate", "flame", "home", "hot", "nest", "thermostat"],
  },
  mode_heat_cool: {
    name: "mode_heat_cool",
    version: 229,
    popularity: 252,
    codepoint: 61803,
    categories: ["Household"],
    tags: ["climate", "cool", "flame", "heat", "home", "nest", "thermostat"],
  },
  mode_heat_off: {
    name: "mode_heat_off",
    version: 229,
    popularity: 142,
    codepoint: 61805,
    categories: ["Household"],
    tags: [
      "climate",
      "disabled",
      "enabled",
      "flame",
      "home",
      "hot",
      "nest",
      "off",
      "on",
      "slash",
      "thermostat",
    ],
  },
  mode_night: {
    name: "mode_night",
    version: 10,
    popularity: 6155,
    codepoint: 61494,
    categories: ["device"],
    tags: ["dark", "disturb", "lunar", "mode", "moon", "night", "sleep"],
  },
  mode_of_travel: {
    name: "mode_of_travel",
    version: 2,
    popularity: 2248,
    codepoint: 59342,
    categories: ["maps"],
    tags: [
      "arrow",
      "destination",
      "direction",
      "location",
      "maps",
      "mode",
      "of",
      "pin",
      "place",
      "stop",
      "transportation",
      "travel",
      "trip",
    ],
  },
  mode_off_on: {
    name: "mode_off_on",
    version: 229,
    popularity: 514,
    codepoint: 61807,
    categories: ["Household"],
    tags: [],
  },
  mode_standby: {
    name: "mode_standby",
    version: 9,
    popularity: 3306,
    codepoint: 61495,
    categories: ["device"],
    tags: ["disturb", "mode", "power", "sleep", "standby", "target"],
  },
  model_training: {
    name: "model_training",
    version: 13,
    popularity: 12378,
    codepoint: 61647,
    categories: ["action"],
    tags: [
      "arrow",
      "bulb",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "model",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "training",
    ],
  },
  monetization_on: {
    name: "monetization_on",
    version: 12,
    popularity: 46120,
    codepoint: 57955,
    categories: ["editor"],
    tags: [
      "bill",
      "card",
      "cash",
      "circle",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "monetization",
      "money",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol",
    ],
  },
  money: {
    name: "money",
    version: 11,
    popularity: 10565,
    codepoint: 58749,
    categories: ["maps"],
    tags: [
      "100",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digit",
      "dollars",
      "finance",
      "money",
      "number",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
    ],
  },
  money_off: {
    name: "money_off",
    version: 12,
    popularity: 7427,
    codepoint: 57948,
    categories: ["editor"],
    tags: [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "money",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "slash",
      "symbol",
    ],
  },
  monitor: {
    name: "monitor",
    version: 11,
    popularity: 4831,
    codepoint: 61275,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
    ],
  },
  monitor_heart: {
    name: "monitor_heart",
    version: 2,
    popularity: 11077,
    codepoint: 60066,
    categories: ["device"],
    tags: [
      "baseline",
      "device",
      "ecc",
      "ecg",
      "fitness",
      "health",
      "heart",
      "medical",
      "monitor",
      "track",
    ],
  },
  monitor_weight: {
    name: "monitor_weight",
    version: 10,
    popularity: 6756,
    codepoint: 61497,
    categories: ["device"],
    tags: [
      "body",
      "device",
      "diet",
      "health",
      "monitor",
      "scale",
      "smart",
      "weight",
    ],
  },
  monitor_weight_gain: {
    name: "monitor_weight_gain",
    version: 229,
    popularity: 5,
    codepoint: 63199,
    categories: ["Activities"],
    tags: [
      "+",
      "add",
      "body",
      "device",
      "diet",
      "fitbit",
      "health",
      "monitor",
      "new",
      "plus",
      "scale",
      "smart",
      "weight",
    ],
  },
  monitor_weight_loss: {
    name: "monitor_weight_loss",
    version: 229,
    popularity: 6,
    codepoint: 63198,
    categories: ["Activities"],
    tags: [
      "body",
      "device",
      "diet",
      "fitbit",
      "health",
      "minus",
      "monitor",
      "reduce",
      "remove",
      "scale",
      "smart",
      "subtract",
      "weight",
    ],
  },
  monitoring: {
    name: "monitoring",
    version: 229,
    popularity: 16979,
    codepoint: 61840,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "monitoring",
      "statistics",
      "tracking",
    ],
  },
  monochrome_photos: {
    name: "monochrome_photos",
    version: 11,
    popularity: 1391,
    codepoint: 58371,
    categories: ["image"],
    tags: [
      "black",
      "camera",
      "image",
      "monochrome",
      "photo",
      "photography",
      "photos",
      "picture",
      "white",
    ],
  },
  mood: {
    name: "mood",
    version: 16,
    popularity: 14270,
    codepoint: 59378,
    categories: ["social"],
    tags: [
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "smile",
      "smiling",
      "social",
      "survey",
    ],
  },
  mood_bad: {
    name: "mood_bad",
    version: 12,
    popularity: 9097,
    codepoint: 59379,
    categories: ["social"],
    tags: [
      "bad",
      "disappointment",
      "dislike",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "rating",
      "social",
      "survey",
      "unhappiness",
      "unhappy",
      "unpleased",
      "unsmile",
      "unsmiling",
    ],
  },
  mop: {
    name: "mop",
    version: 229,
    popularity: 1549,
    codepoint: 57997,
    categories: ["Household"],
    tags: ["clean", "mop", "nest", "polish", "sweeper", "wash", "wipe"],
  },
  more: {
    name: "more",
    version: 13,
    popularity: 9475,
    codepoint: 58905,
    categories: ["notification"],
    tags: [
      "3",
      "archive",
      "bookmark",
      "dots",
      "etc",
      "favorite",
      "indent",
      "label",
      "more",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tab",
      "tag",
      "three",
    ],
  },
  more_down: {
    name: "more_down",
    version: 229,
    popularity: 608,
    codepoint: 61846,
    categories: ["UI actions"],
    tags: [],
  },
  more_horiz: {
    name: "more_horiz",
    version: 13,
    popularity: 95403,
    codepoint: 58835,
    categories: ["navigation"],
    tags: [
      "3",
      "DISABLE_IOS",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "horiz",
      "horizontal",
      "interface",
      "ios",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  more_time: {
    name: "more_time",
    version: 11,
    popularity: 11288,
    codepoint: 59997,
    categories: ["communication"],
    tags: [
      "+",
      "add",
      "clock",
      "date",
      "more",
      "new",
      "plus",
      "schedule",
      "symbol",
      "time",
    ],
  },
  more_up: {
    name: "more_up",
    version: 229,
    popularity: 979,
    codepoint: 61847,
    categories: ["UI actions"],
    tags: [],
  },
  more_vert: {
    name: "more_vert",
    version: 19,
    popularity: 138900,
    codepoint: 58836,
    categories: ["navigation"],
    tags: [
      "3",
      "DISABLE_IOS",
      "android",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "interface",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "vert",
      "vertical",
      "web",
      "website",
    ],
  },
  mosque: {
    name: "mosque",
    version: 2,
    popularity: 1808,
    codepoint: 60082,
    categories: ["maps"],
    tags: [
      "ideology",
      "islam",
      "islamic",
      "masjid",
      "muslim",
      "religion",
      "spiritual",
      "worship",
    ],
  },
  motion_blur: {
    name: "motion_blur",
    version: 229,
    popularity: 257,
    codepoint: 61648,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "motionblur",
      "photo",
      "photography",
      "picture",
    ],
  },
  motion_mode: {
    name: "motion_mode",
    version: 229,
    popularity: 12,
    codepoint: 63554,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "photo",
      "photography",
      "picture",
    ],
  },
  motion_photos_auto: {
    name: "motion_photos_auto",
    version: 15,
    popularity: 2306,
    codepoint: 61498,
    categories: ["image"],
    tags: [
      "A",
      "alphabet",
      "animation",
      "auto",
      "automatic",
      "character",
      "circle",
      "font",
      "gif",
      "letter",
      "live",
      "motion",
      "photos",
      "symbol",
      "text",
      "type",
      "video",
    ],
  },
  motion_photos_off: {
    name: "motion_photos_off",
    version: 14,
    popularity: 1099,
    codepoint: 59840,
    categories: ["image"],
    tags: [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "slash",
      "video",
    ],
  },
  motion_photos_on: {
    name: "motion_photos_on",
    version: 18,
    popularity: 3218,
    codepoint: 59841,
    categories: ["image"],
    tags: [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "play",
      "slash",
      "video",
    ],
  },
  motion_photos_paused: {
    name: "motion_photos_paused",
    version: 17,
    popularity: 1401,
    codepoint: 59842,
    categories: ["image"],
    tags: [
      "animation",
      "circle",
      "motion",
      "pause",
      "paused",
      "photos",
      "video",
    ],
  },
  motion_sensor_active: {
    name: "motion_sensor_active",
    version: 229,
    popularity: 202,
    codepoint: 59282,
    categories: ["Home"],
    tags: ["active", "frame", "home", "motion sensor", "nest", "security"],
  },
  motion_sensor_alert: {
    name: "motion_sensor_alert",
    version: 229,
    popularity: 109,
    codepoint: 59268,
    categories: ["Home"],
    tags: ["alert", "frame", "home", "motion ", "nest", "security", "sensor"],
  },
  motion_sensor_idle: {
    name: "motion_sensor_idle",
    version: 229,
    popularity: 88,
    codepoint: 59267,
    categories: ["Home"],
    tags: [
      "frame",
      "home",
      "idle",
      "motion",
      "nest",
      "off",
      "security",
      "sensor",
    ],
  },
  motion_sensor_urgent: {
    name: "motion_sensor_urgent",
    version: 229,
    popularity: 110,
    codepoint: 59278,
    categories: ["Home"],
    tags: ["home", "motion", "nest", "security", "sensor", "urgent"],
  },
  motorcycle: {
    name: "motorcycle",
    version: 229,
    popularity: 832,
    codepoint: 59675,
    categories: ["Transportation"],
    tags: [
      "automobile",
      "bicycle",
      "bike",
      "car",
      "cars",
      "direction",
      "maps",
      "motorcycle",
      "public",
      "sport",
      "transportation",
      "vehicle",
    ],
  },
  mountain_flag: {
    name: "mountain_flag",
    version: 229,
    popularity: 23,
    codepoint: 62946,
    categories: ["Social"],
    tags: ["flag", "google play", "mountain top", "self help"],
  },
  mouse: {
    name: "mouse",
    version: 12,
    popularity: 10237,
    codepoint: 58147,
    categories: ["hardware"],
    tags: [
      "click",
      "computer",
      "cursor",
      "device",
      "hardware",
      "mouse",
      "wireless",
    ],
  },
  move: {
    name: "move",
    version: 229,
    popularity: 3,
    codepoint: 59200,
    categories: ["Maps"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "body",
      "destination",
      "direction",
      "health",
      "human",
      "loading",
      "location",
      "maps",
      "navigation",
      "people",
      "person",
      "pin",
      "place",
      "refresh",
      "reload",
      "right",
      "rotate",
      "stop",
    ],
  },
  move_down: {
    name: "move_down",
    version: 2,
    popularity: 2702,
    codepoint: 60257,
    categories: ["editor"],
    tags: [
      "arrow",
      "direction",
      "down",
      "jump",
      "move",
      "navigation",
      "transfer",
    ],
  },
  move_group: {
    name: "move_group",
    version: 229,
    popularity: 26,
    codepoint: 63253,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "copy",
      "duplicate",
      "exit",
      "export",
      "groups",
      "leave",
      "output",
      "tab",
      "tabs",
      "to",
      "window",
      "windows",
    ],
  },
  move_item: {
    name: "move_item",
    version: 229,
    popularity: 60,
    codepoint: 61951,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "exit",
      "item",
      "leave",
      "logout",
      "move",
    ],
  },
  move_location: {
    name: "move_location",
    version: 229,
    popularity: 13,
    codepoint: 59201,
    categories: ["Maps"],
    tags: [
      "arrow",
      "arrows",
      "body",
      "destination",
      "direction",
      "east",
      "forward",
      "health",
      "human",
      "location",
      "maps",
      "navigation",
      "out",
      "people",
      "person",
      "pin",
      "place",
      "right",
      "stop",
    ],
  },
  move_selection_down: {
    name: "move_selection_down",
    version: 229,
    popularity: 3,
    codepoint: 63252,
    categories: ["UI actions"],
    tags: [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift",
    ],
  },
  move_selection_left: {
    name: "move_selection_left",
    version: 229,
    popularity: 14,
    codepoint: 63251,
    categories: ["UI actions"],
    tags: [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift",
    ],
  },
  move_selection_right: {
    name: "move_selection_right",
    version: 229,
    popularity: 8,
    codepoint: 63250,
    categories: ["UI actions"],
    tags: [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift",
    ],
  },
  move_selection_up: {
    name: "move_selection_up",
    version: 229,
    popularity: 8,
    codepoint: 63249,
    categories: ["UI actions"],
    tags: [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift",
    ],
  },
  move_to_inbox: {
    name: "move_to_inbox",
    version: 15,
    popularity: 8715,
    codepoint: 57704,
    categories: ["content"],
    tags: [
      "archive",
      "arrow",
      "down",
      "email",
      "envelop",
      "inbox",
      "incoming",
      "letter",
      "mail",
      "message",
      "move to",
      "send",
    ],
  },
  move_up: {
    name: "move_up",
    version: 2,
    popularity: 3164,
    codepoint: 60260,
    categories: ["editor"],
    tags: [
      "arrow",
      "direction",
      "jump",
      "move",
      "navigation",
      "transfer",
      "up",
    ],
  },
  moved_location: {
    name: "moved_location",
    version: 229,
    popularity: 14,
    codepoint: 58772,
    categories: ["Maps"],
    tags: [
      "arrow",
      "destination",
      "direction",
      "location",
      "maps",
      "moved",
      "navigation",
      "pin",
      "place",
      "stop",
    ],
  },
  movie: {
    name: "movie",
    version: 12,
    popularity: 24250,
    codepoint: 57388,
    categories: ["av"],
    tags: ["cinema", "film", "media", "movie", "slate", "video"],
  },
  movie_edit: {
    name: "movie_edit",
    version: 1,
    popularity: 527,
    codepoint: 63552,
    categories: ["av"],
    tags: [
      "cinema",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "film",
      "input",
      "movie",
      "movies",
      "pen",
      "pencil",
      "slate",
      "video",
    ],
  },
  movie_info: {
    name: "movie_info",
    version: 229,
    popularity: 15,
    codepoint: 57389,
    categories: ["Audio&Video"],
    tags: [
      "i",
      "info",
      "information",
      "movie",
      "screen",
      "show",
      "tv",
      "watch",
    ],
  },
  moving: {
    name: "moving",
    version: 4,
    popularity: 7712,
    codepoint: 58625,
    categories: ["maps"],
    tags: ["arrow", "direction", "moving", "navigation", "travel", "up"],
  },
  moving_beds: {
    name: "moving_beds",
    version: 229,
    popularity: 3,
    codepoint: 59197,
    categories: ["Social"],
    tags: [
      "arrow",
      "arrows",
      "bed",
      "body",
      "clinic",
      "direction",
      "east",
      "forward",
      "health",
      "hospital",
      "human",
      "navigation",
      "out",
      "patient",
      "people",
      "person",
      "right",
    ],
  },
  moving_ministry: {
    name: "moving_ministry",
    version: 229,
    popularity: 5,
    codepoint: 59198,
    categories: ["Maps"],
    tags: [
      "arrow",
      "arrows",
      "building",
      "clinic",
      "direction",
      "east",
      "forward",
      "health",
      "hospital",
      "navigation",
      "office",
      "out",
      "right",
    ],
  },
  mp: {
    name: "mp",
    version: 15,
    popularity: 669,
    codepoint: 59843,
    categories: ["image"],
    tags: [
      "alphabet",
      "character",
      "font",
      "image",
      "letter",
      "megapixel",
      "mp",
      "photo",
      "photography",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type",
    ],
  },
  multicooker: {
    name: "multicooker",
    version: 229,
    popularity: 272,
    codepoint: 58003,
    categories: ["Household"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "food",
      "home",
      "house",
      "kitchen",
      "machine",
      "meal",
      "multicooker",
      "nest",
    ],
  },
  multiline_chart: {
    name: "multiline_chart",
    version: 13,
    popularity: 2782,
    codepoint: 59103,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "multiple",
      "statistics",
      "tracking",
    ],
  },
  multiple_stop: {
    name: "multiple_stop",
    version: 7,
    popularity: 5609,
    codepoint: 61881,
    categories: ["maps"],
    tags: [
      "arrows",
      "dash",
      "dashed",
      "directions",
      "dots",
      "left",
      "maps",
      "multiple",
      "navigation",
      "right",
      "stop",
    ],
  },
  museum: {
    name: "museum",
    version: 11,
    popularity: 5053,
    codepoint: 59958,
    categories: ["maps"],
    tags: [
      "architecture",
      "attraction",
      "building",
      "estate",
      "event",
      "exhibition",
      "explore",
      "local",
      "museum",
      "places",
      "real",
      "see",
      "shop",
      "store",
      "tour",
    ],
  },
  music_cast: {
    name: "music_cast",
    version: 229,
    popularity: 43,
    codepoint: 60186,
    categories: ["Audio&Video"],
    tags: [
      "airplay",
      "audio",
      "bluetooth",
      "cast",
      "connect",
      "key",
      "music",
      "note",
      "sound",
      "stream",
      "track",
      "wireless",
    ],
  },
  music_note: {
    name: "music_note",
    version: 12,
    popularity: 26379,
    codepoint: 58373,
    categories: ["image"],
    tags: ["audio", "audiotrack", "key", "music", "note", "sound", "track"],
  },
  music_off: {
    name: "music_off",
    version: 12,
    popularity: 2726,
    codepoint: 58432,
    categories: ["image"],
    tags: [
      "audio",
      "audiotrack",
      "disabled",
      "enabled",
      "key",
      "music",
      "note",
      "off",
      "on",
      "slash",
      "sound",
      "track",
    ],
  },
  music_video: {
    name: "music_video",
    version: 15,
    popularity: 2366,
    codepoint: 57443,
    categories: ["av"],
    tags: ["band", "music", "recording", "screen", "tv", "video", "watch"],
  },
  my_location: {
    name: "my_location",
    version: 12,
    popularity: 30029,
    codepoint: 58716,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop",
    ],
  },
  mystery: {
    name: "mystery",
    version: 229,
    popularity: 48,
    codepoint: 62945,
    categories: ["Social"],
    tags: [
      "eye",
      "eyeball",
      "find",
      "google play",
      "look",
      "magnify",
      "magnify glass",
      "magnifying",
      "search",
      "see",
      "thrillers",
    ],
  },
  nat: {
    name: "nat",
    version: 11,
    popularity: 1335,
    codepoint: 61276,
    categories: ["communication"],
    tags: ["communication", "nat"],
  },
  nature: {
    name: "nature",
    version: 12,
    popularity: 3654,
    codepoint: 58374,
    categories: ["image"],
    tags: [
      "forest",
      "nature",
      "outdoor",
      "outside",
      "park",
      "tree",
      "wilderness",
    ],
  },
  nature_people: {
    name: "nature_people",
    version: 12,
    popularity: 5743,
    codepoint: 58375,
    categories: ["image"],
    tags: [
      "activity",
      "body",
      "forest",
      "human",
      "nature",
      "outdoor",
      "outside",
      "park",
      "people",
      "person",
      "tree",
      "wilderness",
    ],
  },
  navigate_before: {
    name: "navigate_before",
    version: 13,
    popularity: 34748,
    codepoint: 58376,
    categories: ["image"],
    tags: ["arrow", "arrows", "before", "direction", "left", "navigate"],
  },
  navigate_next: {
    name: "navigate_next",
    version: 13,
    popularity: 112272,
    codepoint: 58377,
    categories: ["image"],
    tags: ["arrow", "arrows", "direction", "navigate", "next", "right"],
  },
  navigation: {
    name: "navigation",
    version: 12,
    popularity: 11791,
    codepoint: 58717,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop",
    ],
  },
  near_me: {
    name: "near_me",
    version: 12,
    popularity: 25945,
    codepoint: 58729,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "pin",
      "place",
      "point",
      "stop",
    ],
  },
  near_me_disabled: {
    name: "near_me_disabled",
    version: 6,
    popularity: 1278,
    codepoint: 61935,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "off",
      "on",
      "pin",
      "place",
      "point",
      "slash",
    ],
  },
  nearby: {
    name: "nearby",
    version: 229,
    popularity: 34,
    codepoint: 59063,
    categories: ["Android"],
    tags: ["nearby", "squares"],
  },
  nearby_error: {
    name: "nearby_error",
    version: 10,
    popularity: 1644,
    codepoint: 61499,
    categories: ["device"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "nearby",
      "notification",
      "symbol",
      "warning",
    ],
  },
  nearby_off: {
    name: "nearby_off",
    version: 10,
    popularity: 581,
    codepoint: 61500,
    categories: ["device"],
    tags: ["disabled", "enabled", "nearby", "off", "on", "slash"],
  },
  nephrology: {
    name: "nephrology",
    version: 229,
    popularity: 3,
    codepoint: 57613,
    categories: ["Social"],
    tags: ["body", "clinical", "health", "human", "kidney", "medical", "organ"],
  },
  nest_audio: {
    name: "nest_audio",
    version: 229,
    popularity: 212,
    codepoint: 60351,
    categories: ["Home"],
    tags: [
      "IoT",
      "assistant",
      "audio",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "speaker",
      "wireless",
    ],
  },
  nest_cam_floodlight: {
    name: "nest_cam_floodlight",
    version: 229,
    popularity: 141,
    codepoint: 63671,
    categories: ["Home"],
    tags: ["cam", "floodlight", "home", "light", "nest", "wired"],
  },
  nest_cam_indoor: {
    name: "nest_cam_indoor",
    version: 229,
    popularity: 340,
    codepoint: 61726,
    categories: ["Home"],
    tags: [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "protext",
      "security",
      "surveillance",
    ],
  },
  nest_cam_iq: {
    name: "nest_cam_iq",
    version: 229,
    popularity: 230,
    codepoint: 61727,
    categories: ["Home"],
    tags: [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "protext",
      "security",
      "surveillance",
    ],
  },
  nest_cam_iq_outdoor: {
    name: "nest_cam_iq_outdoor",
    version: 229,
    popularity: 234,
    codepoint: 61728,
    categories: ["Home"],
    tags: [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "outdoor",
      "protection",
      "security",
      "surveillance",
    ],
  },
  nest_cam_magnet_mount: {
    name: "nest_cam_magnet_mount",
    version: 229,
    popularity: 104,
    codepoint: 63672,
    categories: ["Home"],
    tags: ["cam", "home", "magnetic", "mount", "nest"],
  },
  nest_cam_outdoor: {
    name: "nest_cam_outdoor",
    version: 229,
    popularity: 282,
    codepoint: 61729,
    categories: ["Home"],
    tags: [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "nest",
      "outdoor",
      "protection",
      "security",
      "surveillance",
    ],
  },
  nest_cam_stand: {
    name: "nest_cam_stand",
    version: 229,
    popularity: 97,
    codepoint: 63673,
    categories: ["Home"],
    tags: ["cam", "home", "nest", "stand", "wired"],
  },
  nest_cam_wall_mount: {
    name: "nest_cam_wall_mount",
    version: 229,
    popularity: 96,
    codepoint: 63674,
    categories: ["Home"],
    tags: ["cam", "home", "mount", "nest", "wall ", "wired"],
  },
  nest_cam_wired_stand: {
    name: "nest_cam_wired_stand",
    version: 1,
    popularity: 524,
    codepoint: 60438,
    categories: ["home"],
    tags: [
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "nest",
      "picture",
      "stand",
      "video",
      "videography",
      "wired",
    ],
  },
  nest_clock_farsight_analog: {
    name: "nest_clock_farsight_analog",
    version: 229,
    popularity: 950,
    codepoint: 63675,
    categories: ["Home"],
    tags: [
      "analog",
      "climate",
      "clock",
      "farsight",
      "home",
      "nest",
      "thermostat",
    ],
  },
  nest_clock_farsight_digital: {
    name: "nest_clock_farsight_digital",
    version: 229,
    popularity: 230,
    codepoint: 63676,
    categories: ["Home"],
    tags: [
      "climate",
      "clock",
      "digital",
      "farsight",
      "home",
      "nest",
      "thermostat",
    ],
  },
  nest_connect: {
    name: "nest_connect",
    version: 229,
    popularity: 136,
    codepoint: 61730,
    categories: ["Home"],
    tags: [
      "connect",
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance",
    ],
  },
  nest_detect: {
    name: "nest_detect",
    version: 229,
    popularity: 147,
    codepoint: 61731,
    categories: ["Home"],
    tags: [
      "detect",
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance",
    ],
  },
  nest_display: {
    name: "nest_display",
    version: 229,
    popularity: 400,
    codepoint: 61732,
    categories: ["Home"],
    tags: [
      "device",
      "display",
      "google",
      "hardware",
      "monitor",
      "nest",
      "protection",
      "screen",
      "security",
      "surveillance",
    ],
  },
  nest_display_max: {
    name: "nest_display_max",
    version: 229,
    popularity: 196,
    codepoint: 61733,
    categories: ["Home"],
    tags: [
      "device",
      "display",
      "google",
      "hardware",
      "max",
      "monitor",
      "nest",
      "protection",
      "screen",
      "security",
      "surveillance",
    ],
  },
  nest_doorbell_visitor: {
    name: "nest_doorbell_visitor",
    version: 229,
    popularity: 143,
    codepoint: 63677,
    categories: ["Home"],
    tags: ["doorbell", "home", "nest", "person", "security", "visitor"],
  },
  nest_eco_leaf: {
    name: "nest_eco_leaf",
    version: 229,
    popularity: 1019,
    codepoint: 63678,
    categories: ["Home"],
    tags: ["climate", "eco", "home", "leaf", "nest", "thermostat"],
  },
  nest_farsight_weather: {
    name: "nest_farsight_weather",
    version: 229,
    popularity: 189,
    codepoint: 63679,
    categories: ["Home"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "farsight",
      "home",
      "nest",
      "thermostat",
      "weather",
    ],
  },
  nest_found_savings: {
    name: "nest_found_savings",
    version: 229,
    popularity: 169,
    codepoint: 63680,
    categories: ["Home"],
    tags: ["climate", "eco", "home", "leaf", "nest", "savings", "thermostat"],
  },
  nest_gale_wifi: {
    name: "nest_gale_wifi",
    version: 229,
    popularity: 0,
    codepoint: 62841,
    categories: ["Brand"],
    tags: [],
  },
  nest_heat_link_e: {
    name: "nest_heat_link_e",
    version: 229,
    popularity: 315,
    codepoint: 61734,
    categories: ["Home"],
    tags: [
      "device",
      "e",
      "google",
      "hardware",
      "heat",
      "link",
      "nest",
      "temperature",
      "thermostat",
    ],
  },
  nest_heat_link_gen_3: {
    name: "nest_heat_link_gen_3",
    version: 229,
    popularity: 410,
    codepoint: 61735,
    categories: ["Home"],
    tags: [
      "3",
      "device",
      "gen",
      "google",
      "hardware",
      "heat",
      "link",
      "nest",
      "temperature",
      "thermostat",
    ],
  },
  nest_hello_doorbell: {
    name: "nest_hello_doorbell",
    version: 229,
    popularity: 216,
    codepoint: 59436,
    categories: ["Home"],
    tags: [
      "active",
      "alarm",
      "alert",
      "chime",
      "doorbell",
      "hello",
      "nest",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
    ],
  },
  nest_mini: {
    name: "nest_mini",
    version: 229,
    popularity: 227,
    codepoint: 59273,
    categories: ["Home"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless",
    ],
  },
  nest_multi_room: {
    name: "nest_multi_room",
    version: 229,
    popularity: 304,
    codepoint: 63682,
    categories: ["Home"],
    tags: ["climate", "home", "house", "multi", "nest", "rooms", "thermostat"],
  },
  nest_protect: {
    name: "nest_protect",
    version: 229,
    popularity: 11,
    codepoint: 59022,
    categories: ["Home"],
    tags: [
      "alarm",
      "fire",
      "google",
      "hardware",
      "home",
      "house",
      "nest",
      "protect",
      "smarthouse",
      "system",
    ],
  },
  nest_remote: {
    name: "nest_remote",
    version: 229,
    popularity: 373,
    codepoint: 61737,
    categories: ["Home"],
    tags: ["controller", "device", "google", "hardware", "nest", "remote"],
  },
  nest_remote_comfort_sensor: {
    name: "nest_remote_comfort_sensor",
    version: 229,
    popularity: 957,
    codepoint: 61738,
    categories: ["Home"],
    tags: [
      "comfort",
      "connection",
      "data",
      "google",
      "internet",
      "nest",
      "network",
      "remote",
      "scan",
      "sensor",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  nest_secure_alarm: {
    name: "nest_secure_alarm",
    version: 229,
    popularity: 294,
    codepoint: 61739,
    categories: ["Home"],
    tags: [
      "alarm",
      "circle",
      "clock",
      "dot",
      "nest",
      "protection",
      "secure",
      "security",
      "time",
    ],
  },
  nest_sunblock: {
    name: "nest_sunblock",
    version: 229,
    popularity: 134,
    codepoint: 63683,
    categories: ["Home"],
    tags: ["climate", "home", "nest", "sunblock", "thermostat"],
  },
  nest_tag: {
    name: "nest_tag",
    version: 229,
    popularity: 141,
    codepoint: 61740,
    categories: ["Home"],
    tags: [
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance",
      "tag",
    ],
  },
  nest_thermostat: {
    name: "nest_thermostat",
    version: 229,
    popularity: 42,
    codepoint: 59023,
    categories: ["Home"],
    tags: [
      "70",
      "energy",
      "fahrenheit",
      "frostat",
      "hardware",
      "heating",
      "home",
      "house",
      "nest",
      "smarthouse",
      "system",
      "thermostat",
    ],
  },
  nest_thermostat_e_eu: {
    name: "nest_thermostat_e_eu",
    version: 229,
    popularity: 127,
    codepoint: 61741,
    categories: ["Home"],
    tags: [
      "20",
      "celsius",
      "device",
      "e",
      "eu",
      "google",
      "hardware",
      "nest",
      "temperature",
      "thermostat",
    ],
  },
  nest_thermostat_gen_3: {
    name: "nest_thermostat_gen_3",
    version: 229,
    popularity: 270,
    codepoint: 61742,
    categories: ["Home"],
    tags: [
      "3",
      "device",
      "gen",
      "google",
      "hardware",
      "nest",
      "temperature",
      "thermostat",
    ],
  },
  nest_thermostat_sensor: {
    name: "nest_thermostat_sensor",
    version: 229,
    popularity: 135,
    codepoint: 61743,
    categories: ["Home"],
    tags: [
      "70",
      "device",
      "fahrenheit",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat",
    ],
  },
  nest_thermostat_sensor_eu: {
    name: "nest_thermostat_sensor_eu",
    version: 229,
    popularity: 135,
    codepoint: 61744,
    categories: ["Home"],
    tags: [
      "20",
      "device",
      "eu",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat",
    ],
  },
  nest_thermostat_zirconium_eu: {
    name: "nest_thermostat_zirconium_eu",
    version: 229,
    popularity: 158,
    codepoint: 61745,
    categories: ["Home"],
    tags: [
      "20",
      "celsius",
      "device",
      "eu",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat",
    ],
  },
  nest_true_radiant: {
    name: "nest_true_radiant",
    version: 229,
    popularity: 151,
    codepoint: 63684,
    categories: ["Home"],
    tags: ["climate", "home", "nest", "radiant", "thermostat", "true"],
  },
  nest_wake_on_approach: {
    name: "nest_wake_on_approach",
    version: 229,
    popularity: 116,
    codepoint: 63685,
    categories: ["Home"],
    tags: ["approach", "climate", "home", "nest", "thermostat", "wake"],
  },
  nest_wake_on_press: {
    name: "nest_wake_on_press",
    version: 229,
    popularity: 113,
    codepoint: 63686,
    categories: ["Home"],
    tags: ["climate", "home", "nest", "press", "thermostat", "wake"],
  },
  nest_wifi_point: {
    name: "nest_wifi_point",
    version: 229,
    popularity: 144,
    codepoint: 59455,
    categories: ["Home"],
    tags: [
      "connection",
      "connectivity",
      "data",
      "device",
      "google",
      "hardware",
      "home",
      "internet",
      "nest",
      "network",
      "point",
      "service",
      "signal",
      "vento",
      "wifi",
      "wireless",
    ],
  },
  nest_wifi_pro: {
    name: "nest_wifi_pro",
    version: 229,
    popularity: 2,
    codepoint: 62827,
    categories: ["Home"],
    tags: [
      "cellular",
      "connection",
      "data",
      "device",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  nest_wifi_pro_2: {
    name: "nest_wifi_pro_2",
    version: 229,
    popularity: 2,
    codepoint: 62826,
    categories: ["Home"],
    tags: [
      "cellular",
      "connection",
      "data",
      "device",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  nest_wifi_router: {
    name: "nest_wifi_router",
    version: 229,
    popularity: 203,
    codepoint: 59457,
    categories: ["Home"],
    tags: [
      "connection",
      "connectivity",
      "data",
      "device",
      "google",
      "hardware",
      "home",
      "internet",
      "mistral",
      "nest",
      "network",
      "router",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  network_cell: {
    name: "network_cell",
    version: 16,
    popularity: 2857,
    codepoint: 57785,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_check: {
    name: "network_check",
    version: 12,
    popularity: 4852,
    codepoint: 58944,
    categories: ["notification"],
    tags: [
      "check",
      "connect",
      "connection",
      "internet",
      "meter",
      "network",
      "signal",
      "speed",
      "tick",
      "wifi",
      "wireless",
    ],
  },
  network_intelligence_history: {
    name: "network_intelligence_history",
    version: 229,
    popularity: 5,
    codepoint: 62966,
    categories: ["Communication"],
    tags: [
      "alert",
      "borg",
      "brain",
      "clever",
      "clock",
      "connection",
      "date",
      "intelligence",
      "internet",
      "network",
      "pending",
      "recent",
      "schedule",
      "signal",
      "smart",
      "time",
      "updates",
      "wifi",
    ],
  },
  network_intelligence_update: {
    name: "network_intelligence_update",
    version: 229,
    popularity: 7,
    codepoint: 62965,
    categories: ["Communication"],
    tags: [
      "alert",
      "arrow",
      "arrows",
      "borg",
      "brain",
      "clever",
      "connection",
      "down",
      "download",
      "install",
      "intelligence",
      "internet",
      "network",
      "signal",
      "smart",
      "wifi",
    ],
  },
  network_locked: {
    name: "network_locked",
    version: 17,
    popularity: 1315,
    codepoint: 58906,
    categories: ["notification"],
    tags: [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "error",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "not",
      "privacy",
      "private",
      "protection",
      "restricted",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "warning",
      "wifi",
      "wireless",
    ],
  },
  network_manage: {
    name: "network_manage",
    version: 229,
    popularity: 3,
    codepoint: 63403,
    categories: ["Communication"],
    tags: [
      "application",
      "cell",
      "change",
      "connection",
      "data",
      "details",
      "gear",
      "info",
      "information",
      "internet",
      "mobile",
      "network",
      "options",
      "permission",
      "permissions",
      "personal",
      "phone",
      "service",
      "settings",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  network_node: {
    name: "network_node",
    version: 229,
    popularity: 34,
    codepoint: 62830,
    categories: ["Business&Payments"],
    tags: [
      "analytics",
      "chart",
      "communication",
      "data",
      "diagram",
      "endpoints",
      "flow",
      "graph",
      "infographic",
      "networking",
      "transmit",
    ],
  },
  network_ping: {
    name: "network_ping",
    version: 1,
    popularity: 1305,
    codepoint: 60362,
    categories: ["action"],
    tags: [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "internet",
      "ip",
      "mobile",
      "network",
      "ping",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  network_wifi: {
    name: "network_wifi",
    version: 16,
    popularity: 4851,
    codepoint: 57786,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_1_bar: {
    name: "network_wifi_1_bar",
    version: 1,
    popularity: 1156,
    codepoint: 60388,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_1_bar_locked: {
    name: "network_wifi_1_bar_locked",
    version: 229,
    popularity: 4,
    codepoint: 62863,
    categories: ["Android"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_2_bar: {
    name: "network_wifi_2_bar",
    version: 1,
    popularity: 938,
    codepoint: 60374,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_2_bar_locked: {
    name: "network_wifi_2_bar_locked",
    version: 229,
    popularity: 7,
    codepoint: 62862,
    categories: ["Android"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_3_bar: {
    name: "network_wifi_3_bar",
    version: 1,
    popularity: 1093,
    codepoint: 60385,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_3_bar_locked: {
    name: "network_wifi_3_bar_locked",
    version: 229,
    popularity: 15,
    codepoint: 62861,
    categories: ["Android"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  network_wifi_locked: {
    name: "network_wifi_locked",
    version: 229,
    popularity: 6,
    codepoint: 62770,
    categories: ["Android"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  neurology: {
    name: "neurology",
    version: 229,
    popularity: 114,
    codepoint: 57614,
    categories: ["Social"],
    tags: ["body", "brain", "clinical", "health", "human", "medical", "organ"],
  },
  new_label: {
    name: "new_label",
    version: 3,
    popularity: 5498,
    codepoint: 58889,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "new",
      "plus",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "symbol",
      "tag",
    ],
  },
  new_releases: {
    name: "new_releases",
    version: 13,
    popularity: 22010,
    codepoint: 57393,
    categories: ["av"],
    tags: [
      "approve",
      "award",
      "check",
      "checkmark",
      "complete",
      "done",
      "new",
      "notification",
      "ok",
      "release",
      "releases",
      "select",
      "star",
      "symbol",
      "tick",
      "verification",
      "verified",
      "warning",
      "yes",
    ],
  },
  new_window: {
    name: "new_window",
    version: 229,
    popularity: 45,
    codepoint: 63248,
    categories: ["UI actions"],
    tags: ["+", "add", "create", "frame", "new", "plus", "square"],
  },
  news: {
    name: "news",
    version: 229,
    popularity: 123,
    codepoint: 57394,
    categories: ["Text Formatting"],
    tags: [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "magazine",
      "media",
      "news",
      "newspaper",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  newsmode: {
    name: "newsmode",
    version: 229,
    popularity: 157,
    codepoint: 61357,
    categories: ["Text Formatting"],
    tags: ["article", "magazine", "media", "mode", "news", "newspaper"],
  },
  newspaper: {
    name: "newspaper",
    version: 1,
    popularity: 16342,
    codepoint: 60289,
    categories: ["file"],
    tags: [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "magazine",
      "media",
      "news",
      "newspaper",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  newsstand: {
    name: "newsstand",
    version: 229,
    popularity: 42,
    codepoint: 59844,
    categories: ["Activities"],
    tags: [
      "article",
      "location",
      "magazine",
      "media",
      "news",
      "newspaper",
      "newsstand",
      "place",
      "stack",
      "stand",
    ],
  },
  next_plan: {
    name: "next_plan",
    version: 16,
    popularity: 7195,
    codepoint: 61277,
    categories: ["action"],
    tags: ["arrow", "circle", "next", "plan", "right"],
  },
  next_week: {
    name: "next_week",
    version: 14,
    popularity: 3495,
    codepoint: 57706,
    categories: ["content"],
    tags: [
      "arrow",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "next",
      "suitcase",
      "week",
    ],
  },
  nfc: {
    name: "nfc",
    version: 12,
    popularity: 3343,
    codepoint: 57787,
    categories: ["device"],
    tags: [
      "communication",
      "data",
      "field",
      "mobile",
      "near",
      "nfc",
      "wireless",
    ],
  },
  night_shelter: {
    name: "night_shelter",
    version: 6,
    popularity: 4812,
    codepoint: 61937,
    categories: ["places"],
    tags: [
      "architecture",
      "bed",
      "building",
      "estate",
      "homeless",
      "house",
      "night",
      "place",
      "real",
      "shelter",
      "sleep",
    ],
  },
  night_sight_auto: {
    name: "night_sight_auto",
    version: 229,
    popularity: 323,
    codepoint: 61911,
    categories: ["Photo&Image"],
    tags: [
      "a",
      "auto",
      "automatic",
      "camera",
      "dark",
      "feature",
      "moon",
      "night",
      "photo",
      "photography",
      "sight",
    ],
  },
  night_sight_auto_off: {
    name: "night_sight_auto_off",
    version: 229,
    popularity: 145,
    codepoint: 61945,
    categories: ["Photo&Image"],
    tags: [
      "a",
      "auto",
      "automatic",
      "camera",
      "dark",
      "disabled",
      "enabled",
      "feature",
      "moon",
      "night",
      "off",
      "on",
      "photo",
      "photography",
      "sight",
      "slash",
    ],
  },
  night_sight_max: {
    name: "night_sight_max",
    version: 229,
    popularity: 17,
    codepoint: 63171,
    categories: ["Hardware"],
    tags: [
      "+",
      "add",
      "auto",
      "automatic",
      "camera",
      "crescent",
      "dark",
      "enabled",
      "moon",
      "new",
      "nighttime",
      "photo",
      "photography",
      "picture",
      "plus",
      "symbol",
    ],
  },
  nightlife: {
    name: "nightlife",
    version: 10,
    popularity: 4938,
    codepoint: 60002,
    categories: ["maps"],
    tags: [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "dance",
      "drink",
      "food",
      "glass",
      "liquor",
      "music",
      "nightlife",
      "note",
      "wine",
    ],
  },
  nightlight: {
    name: "nightlight",
    version: 10,
    popularity: 10290,
    codepoint: 61501,
    categories: ["device"],
    tags: ["dark", "disturb", "mode", "moon", "night", "nightlight", "sleep"],
  },
  nights_stay: {
    name: "nights_stay",
    version: 12,
    popularity: 7941,
    codepoint: 59974,
    categories: ["social"],
    tags: [
      "climate",
      "cloud",
      "crescent",
      "dark",
      "lunar",
      "mode",
      "moon",
      "nights",
      "phases",
      "silence",
      "silent",
      "sky",
      "stay",
      "time",
      "weather",
    ],
  },
  no_accounts: {
    name: "no_accounts",
    version: 10,
    popularity: 10080,
    codepoint: 61502,
    categories: ["action"],
    tags: [
      "account",
      "accounts",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "no",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "thumbnail",
      "unavailable",
      "unidentifiable",
      "unknown",
      "user",
    ],
  },
  no_adult_content: {
    name: "no_adult_content",
    version: 1,
    popularity: 541,
    codepoint: 63742,
    categories: ["social"],
    tags: [],
  },
  no_backpack: {
    name: "no_backpack",
    version: 6,
    popularity: 795,
    codepoint: 62007,
    categories: ["places"],
    tags: [
      "accessory",
      "backpack",
      "bag",
      "bookbag",
      "knapsack",
      "no",
      "pack",
      "travel",
    ],
  },
  no_crash: {
    name: "no_crash",
    version: 1,
    popularity: 1332,
    codepoint: 60400,
    categories: ["maps"],
    tags: [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "check",
      "collision",
      "confirm",
      "correct",
      "crash",
      "direction",
      "done",
      "enter",
      "maps",
      "mark",
      "no",
      "ok",
      "okay",
      "select",
      "tick",
      "transportation",
      "vehicle",
      "yes",
    ],
  },
  no_drinks: {
    name: "no_drinks",
    version: 8,
    popularity: 1330,
    codepoint: 61861,
    categories: ["places"],
    tags: [
      "alcohol",
      "beverage",
      "bottle",
      "cocktail",
      "drink",
      "drinks",
      "food",
      "liquor",
      "no",
      "wine",
    ],
  },
  no_encryption: {
    name: "no_encryption",
    version: 17,
    popularity: 2804,
    codepoint: 58945,
    categories: ["notification"],
    tags: [
      "disabled",
      "enabled",
      "encryption",
      "lock",
      "no",
      "off",
      "on",
      "password",
      "safety",
      "security",
      "slash",
    ],
  },
  no_flash: {
    name: "no_flash",
    version: 8,
    popularity: 872,
    codepoint: 61862,
    categories: ["places"],
    tags: [
      "bolt",
      "camera",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "flash",
      "image",
      "instant",
      "lightning",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
      "thunderbolt",
    ],
  },
  no_food: {
    name: "no_food",
    version: 8,
    popularity: 2210,
    codepoint: 61863,
    categories: ["places"],
    tags: [
      "disabled",
      "drink",
      "enabled",
      "fastfood",
      "food",
      "hamburger",
      "meal",
      "no",
      "off",
      "on",
      "slash",
    ],
  },
  no_luggage: {
    name: "no_luggage",
    version: 7,
    popularity: 1189,
    codepoint: 62011,
    categories: ["social"],
    tags: [
      "bag",
      "baggage",
      "carry",
      "disabled",
      "enabled",
      "luggage",
      "no",
      "off",
      "on",
      "slash",
      "suitcase",
      "travel",
    ],
  },
  no_meals: {
    name: "no_meals",
    version: 7,
    popularity: 1570,
    codepoint: 61910,
    categories: ["maps"],
    tags: [
      "dining",
      "disabled",
      "eat",
      "enabled",
      "food",
      "fork",
      "knife",
      "meal",
      "meals",
      "no",
      "off",
      "on",
      "restaurant",
      "slash",
      "spoon",
      "utensils",
    ],
  },
  no_meeting_room: {
    name: "no_meeting_room",
    version: 11,
    popularity: 1584,
    codepoint: 60238,
    categories: ["places"],
    tags: [
      "building",
      "disabled",
      "door",
      "doorway",
      "enabled",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "no",
      "off",
      "office",
      "on",
      "open",
      "places",
      "room",
      "slash",
    ],
  },
  no_photography: {
    name: "no_photography",
    version: 8,
    popularity: 4032,
    codepoint: 61864,
    categories: ["places"],
    tags: [
      "camera",
      "disabled",
      "enabled",
      "image",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
    ],
  },
  no_sim: {
    name: "no_sim",
    version: 12,
    popularity: 1246,
    codepoint: 57548,
    categories: ["communication"],
    tags: [
      "camera",
      "card",
      "device",
      "eject",
      "insert",
      "memory",
      "no",
      "phone",
      "sim",
      "storage",
    ],
  },
  no_sound: {
    name: "no_sound",
    version: 229,
    popularity: 40,
    codepoint: 59152,
    categories: ["Audio&Video"],
    tags: [
      "Volume",
      "audio",
      "control",
      "error",
      "music",
      "off",
      "sound",
      "tv",
    ],
  },
  no_stroller: {
    name: "no_stroller",
    version: 9,
    popularity: 739,
    codepoint: 61871,
    categories: ["places"],
    tags: [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "disabled",
      "enabled",
      "infant",
      "kid",
      "newborn",
      "no",
      "off",
      "on",
      "parents",
      "slash",
      "stroller",
      "toddler",
      "young",
    ],
  },
  no_transfer: {
    name: "no_transfer",
    version: 7,
    popularity: 1137,
    codepoint: 61909,
    categories: ["maps"],
    tags: [
      "automobile",
      "bus",
      "car",
      "cars",
      "direction",
      "disabled",
      "enabled",
      "maps",
      "no",
      "off",
      "on",
      "public",
      "slash",
      "transfer",
      "transportation",
      "vehicle",
    ],
  },
  noise_aware: {
    name: "noise_aware",
    version: 2,
    popularity: 1818,
    codepoint: 60396,
    categories: ["action"],
    tags: ["audio", "aware", "cancellation", "music", "noise", "note", "sound"],
  },
  noise_control_off: {
    name: "noise_control_off",
    version: 2,
    popularity: 4620,
    codepoint: 60403,
    categories: ["action"],
    tags: [
      "audio",
      "aware",
      "cancel",
      "cancellation",
      "control",
      "disabled",
      "enabled",
      "music",
      "noise",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
    ],
  },
  noise_control_on: {
    name: "noise_control_on",
    version: 229,
    popularity: 142,
    codepoint: 63656,
    categories: ["Android"],
    tags: [
      "audio",
      "aware",
      "cancel",
      "cancellation",
      "control",
      "disabled",
      "enabled",
      "music",
      "noise",
      "note",
      "off",
      "on",
      "online",
      "slash",
      "sound",
    ],
  },
  nordic_walking: {
    name: "nordic_walking",
    version: 4,
    popularity: 2602,
    codepoint: 58638,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hiking",
      "hobby",
      "human",
      "nordic",
      "people",
      "person",
      "social",
      "sports",
      "travel",
      "walker",
      "walking",
    ],
  },
  north: {
    name: "north",
    version: 7,
    popularity: 16476,
    codepoint: 61920,
    categories: ["navigation"],
    tags: ["arrow", "directional", "maps", "navigation", "north", "up"],
  },
  north_east: {
    name: "north_east",
    version: 7,
    popularity: 16581,
    codepoint: 61921,
    categories: ["navigation"],
    tags: ["arrow", "east", "maps", "navigation", "noth", "right", "up"],
  },
  north_west: {
    name: "north_west",
    version: 7,
    popularity: 5038,
    codepoint: 61922,
    categories: ["navigation"],
    tags: [
      "arrow",
      "directional",
      "left",
      "maps",
      "navigation",
      "north",
      "up",
      "west",
    ],
  },
  not_accessible: {
    name: "not_accessible",
    version: 13,
    popularity: 2058,
    codepoint: 61694,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "not",
      "person",
      "wheelchair",
    ],
  },
  not_accessible_forward: {
    name: "not_accessible_forward",
    version: 229,
    popularity: 26,
    codepoint: 62794,
    categories: ["Social"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "disabled",
      "enabled",
      "forward",
      "handicap",
      "help",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "slash",
      "wheelchair",
    ],
  },
  not_listed_location: {
    name: "not_listed_location",
    version: 18,
    popularity: 6223,
    codepoint: 58741,
    categories: ["maps"],
    tags: [
      "?",
      "assistance",
      "destination",
      "direction",
      "help",
      "info",
      "information",
      "listed",
      "location",
      "maps",
      "not",
      "pin",
      "place",
      "punctuation",
      "question mark",
      "stop",
      "support",
      "symbol",
    ],
  },
  not_started: {
    name: "not_started",
    version: 13,
    popularity: 8982,
    codepoint: 61649,
    categories: ["action"],
    tags: ["circle", "media", "not", "pause", "play", "started", "video"],
  },
  note: {
    name: "note",
    version: 12,
    popularity: 7405,
    codepoint: 57455,
    categories: ["av"],
    tags: [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "message",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing",
    ],
  },
  note_add: {
    name: "note_add",
    version: 13,
    popularity: 45150,
    codepoint: 59548,
    categories: ["action"],
    tags: [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "new",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing",
    ],
  },
  note_alt: {
    name: "note_alt",
    version: 10,
    popularity: 12883,
    codepoint: 61504,
    categories: ["device"],
    tags: [
      "alt",
      "clipboard",
      "document",
      "file",
      "memo",
      "note",
      "page",
      "paper",
      "writing",
    ],
  },
  note_stack: {
    name: "note_stack",
    version: 229,
    popularity: 80,
    codepoint: 62818,
    categories: ["Text Formatting"],
    tags: [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "message",
      "more",
      "multiple",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "stack",
      "stacked",
      "symbol",
      "writing",
    ],
  },
  note_stack_add: {
    name: "note_stack_add",
    version: 229,
    popularity: 91,
    codepoint: 62819,
    categories: ["Text Formatting"],
    tags: [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "new",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing",
    ],
  },
  notes: {
    name: "notes",
    version: 12,
    popularity: 15379,
    codepoint: 57964,
    categories: ["editor"],
    tags: [
      "comment",
      "doc",
      "document",
      "note",
      "notes",
      "text",
      "write",
      "writing",
    ],
  },
  notification_add: {
    name: "notification_add",
    version: 5,
    popularity: 6970,
    codepoint: 58265,
    categories: ["social"],
    tags: [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notification",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol",
    ],
  },
  notification_important: {
    name: "notification_important",
    version: 12,
    popularity: 18827,
    codepoint: 57348,
    categories: ["alert"],
    tags: [
      "!",
      "active",
      "alarm",
      "alert",
      "attention",
      "bell",
      "caution",
      "chime",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
      "symbol",
      "warning",
    ],
  },
  notification_multiple: {
    name: "notification_multiple",
    version: 229,
    popularity: 24,
    codepoint: 59074,
    categories: ["Communication"],
    tags: [
      "approve",
      "check",
      "document",
      "email",
      "file",
      "letter",
      "mail",
      "multiple",
      "notification",
      "open",
      "read",
      "select",
      "tick",
      "yes",
    ],
  },
  notifications: {
    name: "notifications",
    version: 18,
    popularity: 192409,
    codepoint: 59380,
    categories: ["social"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
    ],
  },
  notifications_active: {
    name: "notifications_active",
    version: 13,
    popularity: 47570,
    codepoint: 59383,
    categories: ["social"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "ringing",
      "sound",
    ],
  },
  notifications_off: {
    name: "notifications_off",
    version: 12,
    popularity: 10429,
    codepoint: 59382,
    categories: ["social"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "disabled",
      "enabled",
      "notifications",
      "notify",
      "off",
      "offline",
      "on",
      "reminder",
      "ring",
      "slash",
      "sound",
    ],
  },
  notifications_paused: {
    name: "notifications_paused",
    version: 12,
    popularity: 3194,
    codepoint: 59384,
    categories: ["social"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "ignore",
      "notifications",
      "notify",
      "paused",
      "quiet",
      "reminder",
      "ring --- pause",
      "sleep",
      "snooze",
      "sound",
      "z",
      "zzz",
    ],
  },
  numbers: {
    name: "numbers",
    version: 2,
    popularity: 4025,
    codepoint: 60103,
    categories: ["editor"],
    tags: ["digit", "number", "numbers", "symbol"],
  },
  nutrition: {
    name: "nutrition",
    version: 229,
    popularity: 70,
    codepoint: 57616,
    categories: ["Social"],
    tags: ["apple", "food", "fruit", "health", "orange", "produce", "wellness"],
  },
  ods: {
    name: "ods",
    version: 229,
    popularity: 18,
    codepoint: 59112,
    categories: ["Communication"],
    tags: [
      "analytics",
      "data",
      "google",
      "infrastructure",
      "ods",
      "operations",
      "science",
    ],
  },
  odt: {
    name: "odt",
    version: 229,
    popularity: 7,
    codepoint: 59113,
    categories: ["Communication"],
    tags: [
      "chip",
      "die",
      "odt",
      "on",
      "semiconductor",
      "technology",
      "termination",
    ],
  },
  offline_bolt: {
    name: "offline_bolt",
    version: 18,
    popularity: 13003,
    codepoint: 59698,
    categories: ["action"],
    tags: [
      "bolt",
      "circle",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "offline",
      "thunderbolt",
    ],
  },
  offline_pin: {
    name: "offline_pin",
    version: 18,
    popularity: 5984,
    codepoint: 59658,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "checkmark",
      "circle",
      "complete",
      "done",
      "mark",
      "offline",
      "ok",
      "pin",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  offline_share: {
    name: "offline_share",
    version: 12,
    popularity: 2832,
    codepoint: 59845,
    categories: ["navigation"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "cell",
      "connect",
      "device",
      "direction",
      "hardware",
      "iOS",
      "link",
      "mobile",
      "multiple",
      "offline",
      "phone",
      "right",
      "share",
      "tablet",
    ],
  },
  oil_barrel: {
    name: "oil_barrel",
    version: 1,
    popularity: 1856,
    codepoint: 60437,
    categories: ["home"],
    tags: ["barrel", "droplet", "gas", "gasoline", "nest", "oil", "water"],
  },
  on_device_training: {
    name: "on_device_training",
    version: 1,
    popularity: 1222,
    codepoint: 60413,
    categories: ["action"],
    tags: [
      "arrow",
      "bulb",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "mobile",
      "model",
      "phone",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "telephone",
      "training",
    ],
  },
  on_hub_device: {
    name: "on_hub_device",
    version: 229,
    popularity: 8,
    codepoint: 59075,
    categories: ["Home"],
    tags: ["device", "hardware", "hub", "internet", "on", "router", "wifi"],
  },
  oncology: {
    name: "oncology",
    version: 229,
    popularity: 4,
    codepoint: 57620,
    categories: ["Social"],
    tags: [
      "body",
      "digest",
      "digestive",
      "find",
      "gastro",
      "gi",
      "glass",
      "gut",
      "guts",
      "health",
      "human",
      "intestine",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see",
      "stomach",
      "tract",
    ],
  },
  online_prediction: {
    name: "online_prediction",
    version: 12,
    popularity: 7307,
    codepoint: 61675,
    categories: ["action"],
    tags: [
      "bulb",
      "connection",
      "idea",
      "light",
      "network",
      "online",
      "prediction",
      "signal",
      "wireless",
    ],
  },
  onsen: {
    name: "onsen",
    version: 229,
    popularity: 21,
    codepoint: 63224,
    categories: ["Activities"],
    tags: [
      "bathe",
      "bathhouse",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "spa",
      "steam",
      "water",
    ],
  },
  opacity: {
    name: "opacity",
    version: 15,
    popularity: 10144,
    codepoint: 59676,
    categories: ["action"],
    tags: [
      "color",
      "drop",
      "droplet",
      "hue",
      "invert",
      "inverted",
      "opacity",
      "palette",
      "tone",
      "water",
    ],
  },
  open_in_browser: {
    name: "open_in_browser",
    version: 12,
    popularity: 10103,
    codepoint: 59549,
    categories: ["action"],
    tags: [
      "arrow",
      "browser",
      "in",
      "open",
      "site",
      "up",
      "web",
      "website",
      "window",
    ],
  },
  open_in_full: {
    name: "open_in_full",
    version: 7,
    popularity: 37728,
    codepoint: 61902,
    categories: ["action"],
    tags: [
      "action",
      "arrow",
      "arrows",
      "expand",
      "full",
      "grow",
      "in",
      "move",
      "open",
    ],
  },
  open_in_new: {
    name: "open_in_new",
    version: 21,
    popularity: 94490,
    codepoint: 59550,
    categories: ["action"],
    tags: [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "in",
      "interface",
      "new",
      "open",
      "right",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  open_in_new_down: {
    name: "open_in_new_down",
    version: 229,
    popularity: 17,
    codepoint: 63247,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "in",
      "interface",
      "new",
      "open",
      "out",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  open_in_new_off: {
    name: "open_in_new_off",
    version: 4,
    popularity: 2637,
    codepoint: 58614,
    categories: ["action"],
    tags: [
      "arrow",
      "box",
      "disabled",
      "enabled",
      "export",
      "in",
      "new",
      "off",
      "on",
      "open",
      "slash",
      "window",
    ],
  },
  open_in_phone: {
    name: "open_in_phone",
    version: 229,
    popularity: 152,
    codepoint: 59138,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "cell",
      "device",
      "direction",
      "hardware",
      "iOS",
      "in",
      "mobile",
      "open",
      "phone",
      "right",
      "tablet",
    ],
  },
  open_jam: {
    name: "open_jam",
    version: 229,
    popularity: 9,
    codepoint: 61358,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "chrome",
      "desktop",
      "device",
      "direction",
      "hardware",
      "iOS",
      "jam",
      "mac",
      "monitor",
      "open",
      "up",
      "web",
      "window",
    ],
  },
  open_with: {
    name: "open_with",
    version: 12,
    popularity: 15828,
    codepoint: 59551,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "expand",
      "move",
      "open",
      "pan",
      "with",
    ],
  },
  ophthalmology: {
    name: "ophthalmology",
    version: 229,
    popularity: 5,
    codepoint: 57621,
    categories: ["Social"],
    tags: ["body", "eye", "eyes", "health", "human"],
  },
  oral_disease: {
    name: "oral_disease",
    version: 229,
    popularity: 9,
    codepoint: 57622,
    categories: ["Social"],
    tags: ["dentist", "dentistry", "health", "teeth"],
  },
  order_approve: {
    name: "order_approve",
    version: 229,
    popularity: 46,
    codepoint: 63506,
    categories: ["Business&Payments"],
    tags: [],
  },
  order_play: {
    name: "order_play",
    version: 229,
    popularity: 4,
    codepoint: 63505,
    categories: ["Business&Payments"],
    tags: [
      "doc",
      "document",
      "file",
      "health",
      "orders",
      "page",
      "paper",
      "play",
      "receipt",
      "receipts",
    ],
  },
  orders: {
    name: "orders",
    version: 229,
    popularity: 112,
    codepoint: 60180,
    categories: ["Business&Payments"],
    tags: ["box", "delivery", "mail", "open", "orders", "packaged"],
  },
  orthopedics: {
    name: "orthopedics",
    version: 229,
    popularity: 224,
    codepoint: 63639,
    categories: ["Social"],
    tags: ["bone", "bones", "health", "medical", "spine"],
  },
  other_admission: {
    name: "other_admission",
    version: 229,
    popularity: 8,
    codepoint: 58491,
    categories: ["Text Formatting"],
    tags: [
      "clinical",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "pend",
      "pending",
      "writing",
    ],
  },
  other_houses: {
    name: "other_houses",
    version: 4,
    popularity: 12347,
    codepoint: 58764,
    categories: ["places"],
    tags: [
      "architecture",
      "cottage",
      "estate",
      "home",
      "house",
      "houses",
      "maps",
      "other",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
    ],
  },
  outbound: {
    name: "outbound",
    version: 8,
    popularity: 4953,
    codepoint: 57802,
    categories: ["action"],
    tags: ["arrow", "circle", "directional", "outbound", "right", "up"],
  },
  outbox: {
    name: "outbox",
    version: 10,
    popularity: 6084,
    codepoint: 61279,
    categories: ["action"],
    tags: ["box", "mail", "outbox", "send", "sent"],
  },
  outbox_alt: {
    name: "outbox_alt",
    version: 229,
    popularity: 9,
    codepoint: 60183,
    categories: ["Communication"],
    tags: [
      "chat",
      "communicate",
      "communication",
      "email",
      "mail",
      "message",
      "outbox",
      "send",
      "sending",
      "talk",
    ],
  },
  outdoor_garden: {
    name: "outdoor_garden",
    version: 229,
    popularity: 609,
    codepoint: 57861,
    categories: ["Social"],
    tags: [
      "flowers",
      "garden",
      "grass",
      "nature",
      "nest",
      "outdoor",
      "outside",
    ],
  },
  outdoor_grill: {
    name: "outdoor_grill",
    version: 11,
    popularity: 5400,
    codepoint: 59975,
    categories: ["social"],
    tags: [
      "barbecue",
      "bbq",
      "charcoal",
      "cook",
      "cooking",
      "grill",
      "home",
      "house",
      "outdoor",
      "outside",
    ],
  },
  outgoing_mail: {
    name: "outgoing_mail",
    version: 11,
    popularity: 3220,
    codepoint: 61650,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to",
    ],
  },
  outlet: {
    name: "outlet",
    version: 7,
    popularity: 5757,
    codepoint: 61908,
    categories: ["action"],
    tags: ["connect", "connecter", "electricity", "outlet", "plug", "power"],
  },
  outpatient: {
    name: "outpatient",
    version: 229,
    popularity: 4,
    codepoint: 57624,
    categories: ["Social"],
    tags: [
      "clinical",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "pend",
      "pending",
      "writing",
    ],
  },
  outpatient_med: {
    name: "outpatient_med",
    version: 229,
    popularity: 2,
    codepoint: 57625,
    categories: ["Social"],
    tags: [
      "arrow",
      "clinic",
      "clinical",
      "health",
      "medical",
      "medicine",
      "out",
      "right",
    ],
  },
  output: {
    name: "output",
    version: 1,
    popularity: 3999,
    codepoint: 60350,
    categories: ["action"],
    tags: [],
  },
  output_circle: {
    name: "output_circle",
    version: 229,
    popularity: 11,
    codepoint: 63246,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "down",
      "install",
      "navigation",
      "out",
      "outside",
      "south",
    ],
  },
  oven: {
    name: "oven",
    version: 229,
    popularity: 13,
    codepoint: 59847,
    categories: ["Household"],
    tags: [
      "appliance",
      "baking",
      "cook",
      "cooker",
      "cooking",
      "food",
      "furnace",
      "heet",
      "home",
      "hot",
      "house",
      "kitchen",
      "oven",
      "store",
    ],
  },
  oven_gen: {
    name: "oven_gen",
    version: 229,
    popularity: 542,
    codepoint: 59459,
    categories: ["Household"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "home",
      "house",
      "kitchen",
      "machine",
      "nest",
      "oven",
    ],
  },
  overview: {
    name: "overview",
    version: 229,
    popularity: 80,
    codepoint: 58535,
    categories: ["Text Formatting"],
    tags: [
      "clinical",
      "clock",
      "date",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "schedule",
      "time",
    ],
  },
  overview_key: {
    name: "overview_key",
    version: 229,
    popularity: 34,
    codepoint: 63444,
    categories: ["Android"],
    tags: [
      "chrome",
      "chromebook",
      "display",
      "function",
      "layout",
      "window",
      "windows",
      "workflow",
    ],
  },
  oxygen_saturation: {
    name: "oxygen_saturation",
    version: 229,
    popularity: 9,
    codepoint: 58590,
    categories: ["Social"],
    tags: ["body", "health", "human", "medical", "nose", "nostril"],
  },
  p2p: {
    name: "p2p",
    version: 229,
    popularity: 0,
    codepoint: 62762,
    categories: ["Hardware"],
    tags: [
      "device",
      "dots",
      "hardware",
      "mobile",
      "network",
      "new",
      "p2p",
      "peer",
      "peer to peer",
      "peer-to-peer",
      "phone",
      "plus",
      "send",
      "sending",
      "share",
      "sharing",
      "symbol",
      "telephone",
    ],
  },
  pace: {
    name: "pace",
    version: 229,
    popularity: 42,
    codepoint: 63160,
    categories: ["Activities"],
    tags: ["clock", "fitness", "rate", "speed", "stopwatch", "time", "timer"],
  },
  pacemaker: {
    name: "pacemaker",
    version: 229,
    popularity: 7,
    codepoint: 58966,
    categories: ["Hardware"],
    tags: ["cardio", "health", "heart", "medical", "monitor"],
  },
  package: {
    name: "package",
    version: 229,
    popularity: 1779,
    codepoint: 58511,
    categories: ["Maps"],
    tags: [
      "box",
      "boxes",
      "delivery",
      "mail",
      "package",
      "packages",
      "parcel",
      "post",
      "postal",
      "send",
      "shipment",
      "shipping",
      "stamp",
    ],
  },
  package_2: {
    name: "package_2",
    version: 229,
    popularity: 23,
    codepoint: 62825,
    categories: ["Maps"],
    tags: [
      "box",
      "boxes",
      "delivery",
      "mail",
      "package",
      "packages",
      "parcel",
      "post",
      "postal",
      "send",
      "shipment",
      "shipping",
      "stamp",
    ],
  },
  padding: {
    name: "padding",
    version: 11,
    popularity: 1307,
    codepoint: 59848,
    categories: ["editor"],
    tags: ["design", "layout", "margin", "padding", "size", "square"],
  },
  page_control: {
    name: "page_control",
    version: 229,
    popularity: 34,
    codepoint: 59185,
    categories: ["UI actions"],
    tags: [
      "circles",
      "content",
      "control",
      "dots",
      "media",
      "more",
      "page",
      "scroll",
      "steppers",
      "swipe",
      "web",
    ],
  },
  page_info: {
    name: "page_info",
    version: 229,
    popularity: 77,
    codepoint: 62996,
    categories: ["UI actions"],
    tags: [
      "chrome",
      "controls",
      "details",
      "filters",
      "information",
      "list",
      "options",
      "settings",
    ],
  },
  pages: {
    name: "pages",
    version: 11,
    popularity: 3781,
    codepoint: 59385,
    categories: ["social"],
    tags: ["article", "gplus", "pages", "paper", "post", "star"],
  },
  pageview: {
    name: "pageview",
    version: 12,
    popularity: 14692,
    codepoint: 59552,
    categories: ["action"],
    tags: [
      "doc",
      "document",
      "find",
      "glass",
      "magnifying",
      "page",
      "paper",
      "search",
      "view",
    ],
  },
  paid: {
    name: "paid",
    version: 9,
    popularity: 103631,
    codepoint: 61505,
    categories: ["action"],
    tags: ["circle", "currency", "money", "paid", "payment", "transaction"],
  },
  palette: {
    name: "palette",
    version: 16,
    popularity: 32232,
    codepoint: 58378,
    categories: ["image"],
    tags: ["art", "color", "colors", "filters", "paint", "palette"],
  },
  pallet: {
    name: "pallet",
    version: 1,
    popularity: 1477,
    codepoint: 63594,
    categories: ["hardware"],
    tags: [
      "box",
      "construction",
      "factory",
      "manufactory",
      "storage",
      "transport",
    ],
  },
  pan_tool: {
    name: "pan_tool",
    version: 14,
    popularity: 28198,
    codepoint: 59685,
    categories: ["action"],
    tags: [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool",
    ],
  },
  pan_tool_alt: {
    name: "pan_tool_alt",
    version: 1,
    popularity: 4607,
    codepoint: 60345,
    categories: ["action"],
    tags: [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool",
    ],
  },
  pan_zoom: {
    name: "pan_zoom",
    version: 229,
    popularity: 46,
    codepoint: 63061,
    categories: ["Common actions"],
    tags: [
      "arrow",
      "arrows",
      "expand",
      "fill",
      "out",
      "outward",
      "stretch",
      "zooms",
    ],
  },
  panorama: {
    name: "panorama",
    version: 17,
    popularity: 4223,
    codepoint: 58379,
    categories: ["image"],
    tags: [
      "angle",
      "image",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "wide",
    ],
  },
  panorama_fish_eye: {
    name: "panorama_fish_eye",
    version: 13,
    popularity: 8672,
    codepoint: 58380,
    categories: ["image"],
    tags: [
      "angle",
      "circle",
      "eye",
      "fish",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide",
    ],
  },
  panorama_horizontal: {
    name: "panorama_horizontal",
    version: 12,
    popularity: 1034,
    codepoint: 58381,
    categories: ["image"],
    tags: [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide",
    ],
  },
  panorama_photosphere: {
    name: "panorama_photosphere",
    version: 11,
    popularity: 1215,
    codepoint: 59849,
    categories: ["image"],
    tags: [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "photosphere",
      "picture",
      "wide",
    ],
  },
  panorama_vertical: {
    name: "panorama_vertical",
    version: 13,
    popularity: 694,
    codepoint: 58382,
    categories: ["image"],
    tags: [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "vertical",
      "wide",
    ],
  },
  panorama_wide_angle: {
    name: "panorama_wide_angle",
    version: 14,
    popularity: 767,
    codepoint: 58383,
    categories: ["image"],
    tags: [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide",
    ],
  },
  paragliding: {
    name: "paragliding",
    version: 4,
    popularity: 2409,
    codepoint: 58639,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "fly",
      "gliding",
      "hobby",
      "human",
      "parachute",
      "paragliding",
      "people",
      "person",
      "sky",
      "skydiving",
      "social",
      "sports",
      "travel",
    ],
  },
  park: {
    name: "park",
    version: 10,
    popularity: 17737,
    codepoint: 60003,
    categories: ["maps"],
    tags: [
      "attraction",
      "fresh",
      "local",
      "nature",
      "outside",
      "park",
      "plant",
      "tree",
    ],
  },
  partly_cloudy_day: {
    name: "partly_cloudy_day",
    version: 229,
    popularity: 1818,
    codepoint: 61810,
    categories: ["Social"],
    tags: ["climate", "cloud", "sun", "sunny", "temperature", "weather"],
  },
  partly_cloudy_night: {
    name: "partly_cloudy_night",
    version: 229,
    popularity: 608,
    codepoint: 61812,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "lunar",
      "moon",
      "night",
      "temperature",
      "weather",
    ],
  },
  partner_exchange: {
    name: "partner_exchange",
    version: 229,
    popularity: 333,
    codepoint: 63481,
    categories: ["Social"],
    tags: [
      "body",
      "collaborate",
      "collaboration",
      "couple",
      "diamond",
      "gem",
      "greeting",
      "group",
      "handshake",
      "human",
      "people",
      "person",
    ],
  },
  partner_reports: {
    name: "partner_reports",
    version: 229,
    popularity: 25,
    codepoint: 61359,
    categories: ["UI actions"],
    tags: [
      "!",
      "alert",
      "attention",
      "bracket",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "partner",
      "reports",
      "symbol",
      "warning",
    ],
  },
  party_mode: {
    name: "party_mode",
    version: 12,
    popularity: 2314,
    codepoint: 59386,
    categories: ["social"],
    tags: [
      "camera",
      "lens",
      "mode",
      "party",
      "photo",
      "photography",
      "picture",
    ],
  },
  passkey: {
    name: "passkey",
    version: 229,
    popularity: 397,
    codepoint: 63615,
    categories: ["Privacy&Security"],
    tags: [
      "access",
      "body",
      "entry",
      "human",
      "key",
      "login",
      "pass",
      "password",
      "people",
      "person",
      "pin",
      "security",
      "unlock",
    ],
  },
  password: {
    name: "password",
    version: 10,
    popularity: 36008,
    codepoint: 61506,
    categories: ["device"],
    tags: ["key", "login", "password", "pin", "security", "star", "unlock"],
  },
  patient_list: {
    name: "patient_list",
    version: 229,
    popularity: 39,
    codepoint: 58963,
    categories: ["UI actions"],
    tags: ["body", "health", "human", "list", "lists", "person"],
  },
  pattern: {
    name: "pattern",
    version: 11,
    popularity: 3306,
    codepoint: 61507,
    categories: ["device"],
    tags: [
      "key",
      "login",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "unlock",
    ],
  },
  pause: {
    name: "pause",
    version: 16,
    popularity: 50757,
    codepoint: 57396,
    categories: ["av"],
    tags: ["control", "controls", "media", "music", "pause", "video"],
  },
  pause_circle: {
    name: "pause_circle",
    version: 10,
    popularity: 14188,
    codepoint: 57762,
    categories: ["av"],
    tags: ["circle", "control", "controls", "media", "music", "pause", "video"],
  },
  pause_presentation: {
    name: "pause_presentation",
    version: 12,
    popularity: 1903,
    codepoint: 57578,
    categories: ["communication"],
    tags: [
      "app",
      "application desktop",
      "device",
      "pause",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "web",
      "website",
      "window",
      "www",
    ],
  },
  payments: {
    name: "payments",
    version: 13,
    popularity: 67397,
    codepoint: 61283,
    categories: ["navigation"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "layer",
      "money",
      "multiple",
      "online",
      "pay",
      "payment",
      "payments",
      "price",
      "shopping",
      "symbol",
    ],
  },
  pedal_bike: {
    name: "pedal_bike",
    version: 14,
    popularity: 8865,
    codepoint: 60201,
    categories: ["maps"],
    tags: [
      "automobile",
      "bicycle",
      "bike",
      "car",
      "cars",
      "direction",
      "human",
      "maps",
      "pedal",
      "public",
      "route",
      "scooter",
      "transportation",
      "vehicle",
      "vespa",
    ],
  },
  pediatrics: {
    name: "pediatrics",
    version: 229,
    popularity: 5,
    codepoint: 57629,
    categories: ["Social"],
    tags: ["baby", "bottle", "health", "infant", "medical"],
  },
  pen_size_1: {
    name: "pen_size_1",
    version: 229,
    popularity: 0,
    codepoint: 63317,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  pen_size_2: {
    name: "pen_size_2",
    version: 229,
    popularity: 3,
    codepoint: 63316,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  pen_size_3: {
    name: "pen_size_3",
    version: 229,
    popularity: 2,
    codepoint: 63315,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  pen_size_4: {
    name: "pen_size_4",
    version: 229,
    popularity: 1,
    codepoint: 63314,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  pen_size_5: {
    name: "pen_size_5",
    version: 229,
    popularity: 4,
    codepoint: 63313,
    categories: ["Text Formatting"],
    tags: [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight",
    ],
  },
  pending: {
    name: "pending",
    version: 17,
    popularity: 40402,
    codepoint: 61284,
    categories: ["action"],
    tags: [
      "circle",
      "dots",
      "loading",
      "pending",
      "progress",
      "wait",
      "waiting",
    ],
  },
  pending_actions: {
    name: "pending_actions",
    version: 7,
    popularity: 43990,
    codepoint: 61883,
    categories: ["action"],
    tags: [
      "actions",
      "clipboard",
      "clock",
      "date",
      "doc",
      "document",
      "pending",
      "remember",
      "schedule",
      "time",
    ],
  },
  pentagon: {
    name: "pentagon",
    version: 1,
    popularity: 1609,
    codepoint: 60240,
    categories: ["editor"],
    tags: ["five sides", "pentagon", "shape"],
  },
  percent: {
    name: "percent",
    version: 1,
    popularity: 13577,
    codepoint: 60248,
    categories: ["action"],
    tags: ["math", "number", "percent", "symbol"],
  },
  pergola: {
    name: "pergola",
    version: 229,
    popularity: 161,
    codepoint: 57859,
    categories: ["Maps"],
    tags: ["ceiling", "cover", "garden", "nest", "outdoor", "pergola", "roof"],
  },
  perm_camera_mic: {
    name: "perm_camera_mic",
    version: 12,
    popularity: 1893,
    codepoint: 59554,
    categories: ["action"],
    tags: [
      "camera",
      "image",
      "microphone",
      "min",
      "perm",
      "photo",
      "photography",
      "picture",
      "speaker",
    ],
  },
  perm_contact_calendar: {
    name: "perm_contact_calendar",
    version: 13,
    popularity: 16209,
    codepoint: 59555,
    categories: ["action"],
    tags: [
      "account",
      "calendar",
      "contact",
      "date",
      "face",
      "human",
      "information",
      "people",
      "perm",
      "person",
      "profile",
      "schedule",
      "time",
      "user",
    ],
  },
  perm_data_setting: {
    name: "perm_data_setting",
    version: 12,
    popularity: 3604,
    codepoint: 59556,
    categories: ["action"],
    tags: ["data", "gear", "info", "information", "perm", "settings"],
  },
  perm_device_information: {
    name: "perm_device_information",
    version: 13,
    popularity: 3279,
    codepoint: 59557,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "alert",
      "announcement",
      "device",
      "hardware",
      "i",
      "iOS",
      "info",
      "information",
      "mobile",
      "perm",
      "phone",
      "tablet",
    ],
  },
  perm_media: {
    name: "perm_media",
    version: 15,
    popularity: 17178,
    codepoint: 59559,
    categories: ["action"],
    tags: [
      "collection",
      "copy",
      "data",
      "doc",
      "document",
      "duplicate",
      "file",
      "folder",
      "folders",
      "image",
      "landscape",
      "media",
      "mountain",
      "mountains",
      "perm",
      "photo",
      "photography",
      "picture",
      "stack",
      "storage",
    ],
  },
  perm_phone_msg: {
    name: "perm_phone_msg",
    version: 12,
    popularity: 15384,
    codepoint: 59560,
    categories: ["action"],
    tags: [
      "bubble",
      "call",
      "cell",
      "chat",
      "comment",
      "communicate",
      "contact",
      "device",
      "message",
      "msg",
      "perm",
      "phone",
      "recording",
      "speech",
      "telephone",
      "voice",
    ],
  },
  perm_scan_wifi: {
    name: "perm_scan_wifi",
    version: 12,
    popularity: 3552,
    codepoint: 59561,
    categories: ["action"],
    tags: [
      "alert",
      "announcement",
      "connection",
      "info",
      "information",
      "internet",
      "network",
      "perm",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  person: {
    name: "person",
    version: 17,
    popularity: 342177,
    codepoint: 59389,
    categories: ["social"],
    tags: ["account", "face", "human", "people", "person", "profile", "user"],
  },
  person_2: {
    name: "person_2",
    version: 1,
    popularity: 1585,
    codepoint: 63716,
    categories: ["social"],
    tags: ["account", "face", "human", "people", "person", "profile", "user"],
  },
  person_3: {
    name: "person_3",
    version: 1,
    popularity: 1028,
    codepoint: 63717,
    categories: ["social"],
    tags: ["account", "face", "human", "people", "person", "profile", "user"],
  },
  person_4: {
    name: "person_4",
    version: 1,
    popularity: 1081,
    codepoint: 63718,
    categories: ["social"],
    tags: ["account", "face", "human", "people", "person", "profile", "user"],
  },
  person_add: {
    name: "person_add",
    version: 16,
    popularity: 81756,
    codepoint: 59390,
    categories: ["social"],
    tags: [
      "+",
      "account",
      "add",
      "avatar",
      "face",
      "human",
      "new",
      "people",
      "person",
      "plus",
      "profile",
      "symbol",
      "user",
    ],
  },
  person_add_disabled: {
    name: "person_add_disabled",
    version: 11,
    popularity: 2384,
    codepoint: 59851,
    categories: ["communication"],
    tags: [
      "+",
      "account",
      "add",
      "disabled",
      "enabled",
      "face",
      "human",
      "new",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "plus",
      "profile",
      "slash",
      "symbol",
      "user",
    ],
  },
  person_alert: {
    name: "person_alert",
    version: 229,
    popularity: 23,
    codepoint: 62823,
    categories: ["Social"],
    tags: [
      "!",
      "account",
      "add",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "face",
      "feedback",
      "human",
      "important",
      "mark",
      "notification",
      "people",
      "person",
      "plus",
      "problem",
      "profile",
      "report",
      "symbol",
      "user",
      "warning",
    ],
  },
  person_apron: {
    name: "person_apron",
    version: 229,
    popularity: 16,
    codepoint: 62883,
    categories: ["Social"],
    tags: [
      "account",
      "employee",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user",
      "worker",
    ],
  },
  person_book: {
    name: "person_book",
    version: 229,
    popularity: 6,
    codepoint: 62952,
    categories: ["Text Formatting"],
    tags: [
      "account",
      "biography",
      "body",
      "book",
      "face",
      "human",
      "library",
      "memoir",
      "people",
      "person",
      "profile",
      "read",
      "reading",
      "user",
    ],
  },
  person_cancel: {
    name: "person_cancel",
    version: 229,
    popularity: 8,
    codepoint: 62822,
    categories: ["Social"],
    tags: [
      "account",
      "add",
      "cancel",
      "circle",
      "clear",
      "close",
      "exit",
      "face",
      "human",
      "people",
      "person",
      "plus",
      "profile",
      "stop",
      "user",
      "x",
    ],
  },
  person_celebrate: {
    name: "person_celebrate",
    version: 229,
    popularity: 47,
    codepoint: 63486,
    categories: ["Activities"],
    tags: [
      "body",
      "celebrate",
      "confetti",
      "festive",
      "happy",
      "human",
      "juggle",
      "people",
      "person",
      "play",
      "shapes",
    ],
  },
  person_check: {
    name: "person_check",
    version: 229,
    popularity: 15,
    codepoint: 62821,
    categories: ["Social"],
    tags: [
      "account",
      "add",
      "approve",
      "check",
      "checkmark",
      "complete",
      "done",
      "face",
      "human",
      "mark",
      "ok",
      "people",
      "person",
      "plus",
      "profile",
      "select",
      "tick",
      "user",
      "validate",
      "verified",
      "yes",
    ],
  },
  person_off: {
    name: "person_off",
    version: 4,
    popularity: 12872,
    codepoint: 58640,
    categories: ["social"],
    tags: [
      "account",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "user",
    ],
  },
  person_pin: {
    name: "person_pin",
    version: 13,
    popularity: 16092,
    codepoint: 58714,
    categories: ["maps"],
    tags: [
      "account",
      "avatar",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user",
    ],
  },
  person_pin_circle: {
    name: "person_pin_circle",
    version: 18,
    popularity: 11296,
    codepoint: 58730,
    categories: ["maps"],
    tags: [
      "account",
      "circle",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user",
    ],
  },
  person_play: {
    name: "person_play",
    version: 229,
    popularity: 50,
    codepoint: 63485,
    categories: ["Activities"],
    tags: [
      "body",
      "celebrate",
      "confetti",
      "festive",
      "happy",
      "human",
      "juggle",
      "people",
      "person",
      "play",
      "shapes",
    ],
  },
  person_raised_hand: {
    name: "person_raised_hand",
    version: 229,
    popularity: 90,
    codepoint: 62874,
    categories: ["Social"],
    tags: ["body", "help", "human", "people", "question", "raise"],
  },
  person_remove: {
    name: "person_remove",
    version: 12,
    popularity: 16940,
    codepoint: 61286,
    categories: ["social"],
    tags: [
      "account",
      "avatar",
      "delete",
      "face",
      "human",
      "minus",
      "people",
      "person",
      "profile",
      "remove",
      "unfriend",
      "user",
    ],
  },
  person_search: {
    name: "person_search",
    version: 12,
    popularity: 27732,
    codepoint: 61702,
    categories: ["communication"],
    tags: [
      "account",
      "avatar",
      "face",
      "find",
      "glass",
      "human",
      "look",
      "magnify",
      "magnifying",
      "people",
      "person",
      "profile",
      "search",
      "user",
    ],
  },
  personal_bag: {
    name: "personal_bag",
    version: 229,
    popularity: 17,
    codepoint: 60174,
    categories: ["Travel"],
    tags: [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "pack",
      "personal",
      "storage",
      "travel",
    ],
  },
  personal_bag_off: {
    name: "personal_bag_off",
    version: 229,
    popularity: 1,
    codepoint: 60175,
    categories: ["Travel"],
    tags: [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "disabled",
      "enabled",
      "off",
      "on",
      "pack",
      "personal",
      "slash",
      "storage",
      "travel",
    ],
  },
  personal_bag_question: {
    name: "personal_bag_question",
    version: 229,
    popularity: 10,
    codepoint: 60176,
    categories: ["Travel"],
    tags: [
      "?",
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "help",
      "info",
      "information",
      "pack",
      "personal",
      "question",
      "question mark",
      "storage",
      "support",
      "symbol",
      "travel",
    ],
  },
  personal_injury: {
    name: "personal_injury",
    version: 3,
    popularity: 5177,
    codepoint: 59098,
    categories: ["social"],
    tags: [
      "accident",
      "aid",
      "arm",
      "bandage",
      "body",
      "broke",
      "cast",
      "fracture",
      "health",
      "human",
      "injury",
      "medical",
      "patient",
      "people",
      "person",
      "personal",
      "sling",
      "social",
    ],
  },
  personal_places: {
    name: "personal_places",
    version: 229,
    popularity: 46,
    codepoint: 59139,
    categories: ["Travel"],
    tags: [
      "flag",
      "location",
      "map",
      "maps",
      "mark",
      "personal",
      "places",
      "save",
      "tag",
      "territory",
    ],
  },
  pest_control: {
    name: "pest_control",
    version: 12,
    popularity: 3760,
    codepoint: 61690,
    categories: ["maps"],
    tags: ["bug", "control", "exterminator", "insects", "pest"],
  },
  pest_control_rodent: {
    name: "pest_control_rodent",
    version: 12,
    popularity: 1297,
    codepoint: 61693,
    categories: ["maps"],
    tags: ["control", "exterminator", "mice", "pest", "rodent"],
  },
  pet_supplies: {
    name: "pet_supplies",
    version: 229,
    popularity: 41,
    codepoint: 61361,
    categories: ["Maps"],
    tags: ["bone", "dog", "pet", "supplies", "treat"],
  },
  pets: {
    name: "pets",
    version: 12,
    popularity: 49558,
    codepoint: 59677,
    categories: ["action"],
    tags: ["animal", "cat", "dog", "hand", "paw", "pet"],
  },
  phishing: {
    name: "phishing",
    version: 2,
    popularity: 1457,
    codepoint: 60119,
    categories: ["device"],
    tags: ["fish", "fishing", "fraud", "hook", "phishing", "scam"],
  },
  phone_android: {
    name: "phone_android",
    version: 12,
    popularity: 20509,
    codepoint: 58148,
    categories: ["hardware"],
    tags: [
      "OS",
      "android",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet",
    ],
  },
  phone_bluetooth_speaker: {
    name: "phone_bluetooth_speaker",
    version: 12,
    popularity: 1060,
    codepoint: 58907,
    categories: ["notification"],
    tags: [
      "bluetooth",
      "call",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "speaker",
      "symbol",
      "telephone",
      "wireless",
    ],
  },
  phone_callback: {
    name: "phone_callback",
    version: 13,
    popularity: 4727,
    codepoint: 58953,
    categories: ["notification"],
    tags: [
      "arrow",
      "call",
      "callback",
      "cell",
      "contact",
      "device",
      "down",
      "hardware",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  phone_disabled: {
    name: "phone_disabled",
    version: 12,
    popularity: 3786,
    codepoint: 59852,
    categories: ["communication"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "mobile",
      "off",
      "offline",
      "on",
      "phone",
      "slash",
      "telephone",
    ],
  },
  phone_enabled: {
    name: "phone_enabled",
    version: 16,
    popularity: 8152,
    codepoint: 59853,
    categories: ["communication"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "enabled",
      "hardware",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  phone_forwarded: {
    name: "phone_forwarded",
    version: 13,
    popularity: 4335,
    codepoint: 58908,
    categories: ["notification"],
    tags: [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "direction",
      "forwarded",
      "hardware",
      "mobile",
      "phone",
      "right",
      "telephone",
    ],
  },
  phone_in_talk: {
    name: "phone_in_talk",
    version: 229,
    popularity: 9675,
    codepoint: 58909,
    categories: ["Communication"],
    tags: [
      "call",
      "cell",
      "connection",
      "contact",
      "data",
      "device",
      "hardware",
      "in",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "sound",
      "speaker",
      "talk",
      "telephone",
      "waves",
      "wireless",
    ],
  },
  phone_iphone: {
    name: "phone_iphone",
    version: 12,
    popularity: 62969,
    codepoint: 58149,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "iphone",
      "mobile",
      "phone",
      "tablet",
    ],
  },
  phone_locked: {
    name: "phone_locked",
    version: 19,
    popularity: 1319,
    codepoint: 58910,
    categories: ["notification"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "telephone",
    ],
  },
  phone_missed: {
    name: "phone_missed",
    version: 13,
    popularity: 2667,
    codepoint: 58911,
    categories: ["notification"],
    tags: [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "missed",
      "mobile",
      "phone",
      "telephone",
    ],
  },
  phone_paused: {
    name: "phone_paused",
    version: 13,
    popularity: 1459,
    codepoint: 58912,
    categories: ["notification"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "pause",
      "paused",
      "phone",
      "telephone",
    ],
  },
  phonelink_erase: {
    name: "phonelink_erase",
    version: 12,
    popularity: 2626,
    codepoint: 57563,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "cancel",
      "cell",
      "clear",
      "close",
      "connection",
      "device",
      "erase",
      "exit",
      "hardware",
      "iOS",
      "mobile",
      "no",
      "phone",
      "phonelink",
      "remove",
      "stop",
      "tablet",
      "x",
    ],
  },
  phonelink_lock: {
    name: "phonelink_lock",
    version: 15,
    popularity: 3997,
    codepoint: 57564,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "cell",
      "connection",
      "device",
      "erase",
      "hardware",
      "iOS",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "phonelink",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "tablet",
    ],
  },
  phonelink_off: {
    name: "phonelink_off",
    version: 12,
    popularity: 1186,
    codepoint: 58151,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "computer",
      "connect",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "link",
      "mac",
      "mobile",
      "off",
      "on",
      "phone",
      "phonelink",
      "slash",
      "sync",
      "tablet",
      "web",
      "windows",
    ],
  },
  phonelink_ring: {
    name: "phonelink_ring",
    version: 12,
    popularity: 4562,
    codepoint: 57565,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "cell",
      "connection",
      "data",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "network",
      "phone",
      "phonelink",
      "ring",
      "service",
      "signal",
      "tablet",
      "wireless",
    ],
  },
  phonelink_ring_off: {
    name: "phonelink_ring_off",
    version: 229,
    popularity: 4,
    codepoint: 63402,
    categories: ["Communication"],
    tags: [
      "Android",
      "OS",
      "cell",
      "connection",
      "data",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "phonelink",
      "ring",
      "service",
      "signal",
      "tablet",
      "wireless",
    ],
  },
  phonelink_setup: {
    name: "phonelink_setup",
    version: 13,
    popularity: 3550,
    codepoint: 57566,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "call",
      "chat",
      "device",
      "hardware",
      "iOS",
      "info",
      "mobile",
      "phone",
      "phonelink",
      "settings",
      "setup",
      "tablet",
      "text",
    ],
  },
  photo: {
    name: "photo",
    version: 12,
    popularity: 5609,
    codepoint: 58384,
    categories: ["image"],
    tags: ["image", "mountain", "mountains", "photo", "photography", "picture"],
  },
  photo_album: {
    name: "photo_album",
    version: 14,
    popularity: 2583,
    codepoint: 58385,
    categories: ["image"],
    tags: [
      "album",
      "archive",
      "bookmark",
      "image",
      "label",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "ribbon",
      "save",
      "tag",
    ],
  },
  photo_auto_merge: {
    name: "photo_auto_merge",
    version: 229,
    popularity: 5,
    codepoint: 62768,
    categories: ["Photo&Image"],
    tags: [
      "a",
      "account",
      "alphabet",
      "automatic",
      "avatar",
      "best take",
      "blend",
      "body",
      "face",
      "group",
      "human",
      "image",
      "letter",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "stack",
      "user",
    ],
  },
  photo_camera: {
    name: "photo_camera",
    version: 18,
    popularity: 96544,
    codepoint: 58386,
    categories: ["image"],
    tags: ["camera", "image", "photo", "photography", "picture"],
  },
  photo_camera_back: {
    name: "photo_camera_back",
    version: 11,
    popularity: 1374,
    codepoint: 61288,
    categories: ["image"],
    tags: [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear",
    ],
  },
  photo_camera_front: {
    name: "photo_camera_front",
    version: 11,
    popularity: 3143,
    codepoint: 61289,
    categories: ["image"],
    tags: [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "portrait",
      "profile",
      "user",
    ],
  },
  photo_frame: {
    name: "photo_frame",
    version: 229,
    popularity: 289,
    codepoint: 61657,
    categories: ["Photo&Image"],
    tags: [],
  },
  photo_library: {
    name: "photo_library",
    version: 12,
    popularity: 12928,
    codepoint: 58387,
    categories: ["image"],
    tags: [
      "album",
      "image",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
    ],
  },
  photo_prints: {
    name: "photo_prints",
    version: 229,
    popularity: 25,
    codepoint: 61362,
    categories: ["Photo&Image"],
    tags: [
      "flower",
      "image",
      "photo",
      "photography",
      "picture",
      "prints",
      "stack",
    ],
  },
  photo_size_select_large: {
    name: "photo_size_select_large",
    version: 12,
    popularity: 2056,
    codepoint: 58419,
    categories: ["image"],
    tags: [
      "adjust",
      "album",
      "dash",
      "dashed",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size",
    ],
  },
  photo_size_select_small: {
    name: "photo_size_select_small",
    version: 12,
    popularity: 2165,
    codepoint: 58420,
    categories: ["image"],
    tags: [
      "adjust",
      "album",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size",
      "small",
    ],
  },
  php: {
    name: "php",
    version: 1,
    popularity: 1458,
    codepoint: 60303,
    categories: ["action"],
    tags: [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "php",
      "platform",
      "symbol",
      "text",
      "type",
    ],
  },
  physical_therapy: {
    name: "physical_therapy",
    version: 229,
    popularity: 5,
    codepoint: 57630,
    categories: ["Activities"],
    tags: [
      "body",
      "health",
      "human",
      "person",
      "stretch",
      "stretching",
      "yoga",
    ],
  },
  piano: {
    name: "piano",
    version: 4,
    popularity: 4744,
    codepoint: 58657,
    categories: ["social"],
    tags: [
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "piano",
      "social",
    ],
  },
  piano_off: {
    name: "piano_off",
    version: 4,
    popularity: 995,
    codepoint: 58656,
    categories: ["social"],
    tags: [
      "disabled",
      "enabled",
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "off",
      "on",
      "piano",
      "slash",
      "social",
    ],
  },
  picture_as_pdf: {
    name: "picture_as_pdf",
    version: 12,
    popularity: 58103,
    codepoint: 58389,
    categories: ["image"],
    tags: [
      "alphabet",
      "as",
      "character",
      "copy",
      "document",
      "duplicate",
      "file",
      "font",
      "image",
      "letter",
      "multiple",
      "pdf",
      "photo",
      "photography",
      "picture",
      "stack",
      "symbol",
      "text",
      "type",
    ],
  },
  picture_in_picture: {
    name: "picture_in_picture",
    version: 12,
    popularity: 4399,
    codepoint: 59562,
    categories: ["action"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_alt: {
    name: "picture_in_picture_alt",
    version: 12,
    popularity: 3333,
    codepoint: 59665,
    categories: ["action"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_center: {
    name: "picture_in_picture_center",
    version: 229,
    popularity: 2,
    codepoint: 62800,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_large: {
    name: "picture_in_picture_large",
    version: 229,
    popularity: 2,
    codepoint: 62799,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_medium: {
    name: "picture_in_picture_medium",
    version: 229,
    popularity: 4,
    codepoint: 62798,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_mobile: {
    name: "picture_in_picture_mobile",
    version: 229,
    popularity: 0,
    codepoint: 62743,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  picture_in_picture_off: {
    name: "picture_in_picture_off",
    version: 229,
    popularity: 10,
    codepoint: 62767,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "slash",
      "talktrack",
    ],
  },
  picture_in_picture_small: {
    name: "picture_in_picture_small",
    version: 229,
    popularity: 16,
    codepoint: 62797,
    categories: ["Communication"],
    tags: [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack",
    ],
  },
  pie_chart: {
    name: "pie_chart",
    version: 11,
    popularity: 17083,
    codepoint: 59076,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking",
    ],
  },
  pill: {
    name: "pill",
    version: 229,
    popularity: 34,
    codepoint: 57631,
    categories: ["Social"],
    tags: [
      "drug",
      "drugs",
      "health",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx",
    ],
  },
  pill_off: {
    name: "pill_off",
    version: 229,
    popularity: 1,
    codepoint: 63497,
    categories: ["Social"],
    tags: [
      "drug",
      "drugs",
      "health",
      "illicit",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx",
    ],
  },
  pin: {
    name: "pin",
    version: 10,
    popularity: 16992,
    codepoint: 61509,
    categories: ["device"],
    tags: [
      "1",
      "2",
      "3",
      "digit",
      "key",
      "login",
      "logout",
      "number",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "symbol",
      "unlock",
    ],
  },
  pin_drop: {
    name: "pin_drop",
    version: 18,
    popularity: 17932,
    codepoint: 58718,
    categories: ["maps"],
    tags: [
      "destination",
      "direction",
      "drop",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop",
    ],
  },
  pin_end: {
    name: "pin_end",
    version: 3,
    popularity: 1777,
    codepoint: 59239,
    categories: ["action"],
    tags: ["action", "arrow", "dot", "end", "pin"],
  },
  pin_invoke: {
    name: "pin_invoke",
    version: 3,
    popularity: 1996,
    codepoint: 59235,
    categories: ["action"],
    tags: ["action", "arrow", "dot", "invoke", "pin"],
  },
  pinch: {
    name: "pinch",
    version: 1,
    popularity: 1905,
    codepoint: 60216,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "compress",
      "direction",
      "finger",
      "grasp",
      "hand",
      "navigation",
      "nip",
      "pinch",
      "squeeze",
      "tweak",
    ],
  },
  pinch_zoom_in: {
    name: "pinch_zoom_in",
    version: 229,
    popularity: 583,
    codepoint: 61946,
    categories: ["Common actions"],
    tags: [
      "arrows",
      "close",
      "direction",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "in",
      "pinch",
      "zoom",
    ],
  },
  pinch_zoom_out: {
    name: "pinch_zoom_out",
    version: 229,
    popularity: 691,
    codepoint: 61947,
    categories: ["Common actions"],
    tags: [
      "arrows",
      "direction",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "open",
      "out",
      "pinch",
      "zoom",
    ],
  },
  pip: {
    name: "pip",
    version: 229,
    popularity: 17,
    codepoint: 63053,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "close",
      "frame",
      "input",
      "into",
      "move",
      "picture",
      "picture in picture",
      "right",
      "window",
    ],
  },
  pip_exit: {
    name: "pip_exit",
    version: 229,
    popularity: 8,
    codepoint: 63245,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "close",
      "frame",
      "input",
      "into",
      "left",
      "move",
      "out",
      "picture",
      "picture in picture",
      "window",
    ],
  },
  pivot_table_chart: {
    name: "pivot_table_chart",
    version: 11,
    popularity: 3868,
    codepoint: 59854,
    categories: ["navigation"],
    tags: [
      "analytics",
      "arrow",
      "arrows",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "direction",
      "drive",
      "edit",
      "editing",
      "graph",
      "grid",
      "infographic",
      "measure",
      "metrics",
      "pivot",
      "rotate",
      "sheet",
      "statistics",
      "table",
      "tracking",
    ],
  },
  place_item: {
    name: "place_item",
    version: 229,
    popularity: 1898,
    codepoint: 61936,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "down",
      "drop",
      "item",
      "move",
      "place",
      "put",
    ],
  },
  plagiarism: {
    name: "plagiarism",
    version: 11,
    popularity: 9003,
    codepoint: 59994,
    categories: ["action"],
    tags: [
      "doc",
      "document",
      "find",
      "glass",
      "look",
      "magnifying",
      "page",
      "paper",
      "plagiarism",
      "search",
      "see",
    ],
  },
  planner_banner_ad_pt: {
    name: "planner_banner_ad_pt",
    version: 229,
    popularity: 19,
    codepoint: 59026,
    categories: ["Photo&Image"],
    tags: [
      "ad",
      "banner",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "planner",
      "portrait",
      "pt",
      "shot",
    ],
  },
  planner_review: {
    name: "planner_review",
    version: 229,
    popularity: 53,
    codepoint: 59028,
    categories: ["Business&Payments"],
    tags: ["data", "graph", "lines", "planner", "review"],
  },
  play_arrow: {
    name: "play_arrow",
    version: 16,
    popularity: 143266,
    codepoint: 57399,
    categories: ["av"],
    tags: ["arrow", "control", "controls", "media", "music", "play", "video"],
  },
  play_circle: {
    name: "play_circle",
    version: 9,
    popularity: 62719,
    codepoint: 57796,
    categories: ["av"],
    tags: [
      "arrow",
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video",
    ],
  },
  play_disabled: {
    name: "play_disabled",
    version: 10,
    popularity: 1413,
    codepoint: 61290,
    categories: ["av"],
    tags: [
      "control",
      "controls",
      "disabled",
      "enabled",
      "media",
      "music",
      "off",
      "on",
      "play",
      "slash",
      "video",
    ],
  },
  play_for_work: {
    name: "play_for_work",
    version: 12,
    popularity: 5354,
    codepoint: 59654,
    categories: ["action"],
    tags: ["arrow", "circle", "down", "google", "half", "play", "work"],
  },
  play_lesson: {
    name: "play_lesson",
    version: 10,
    popularity: 3106,
    codepoint: 61511,
    categories: ["device"],
    tags: [
      "audio",
      "book",
      "bookmark",
      "digital",
      "ebook",
      "lesson",
      "multimedia",
      "play",
      "play lesson",
      "read",
      "reading",
      "ribbon",
    ],
  },
  play_pause: {
    name: "play_pause",
    version: 229,
    popularity: 1431,
    codepoint: 61751,
    categories: ["Audio&Video"],
    tags: [
      "arrow",
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "play",
      "video",
    ],
  },
  play_shapes: {
    name: "play_shapes",
    version: 229,
    popularity: 25,
    codepoint: 63484,
    categories: ["Activities"],
    tags: [
      "circle",
      "cross",
      "interests",
      "shape",
      "social",
      "square",
      "triangle",
      "x",
    ],
  },
  playing_cards: {
    name: "playing_cards",
    version: 229,
    popularity: 14,
    codepoint: 62940,
    categories: ["Social"],
    tags: ["card deck", "games", "google play"],
  },
  playlist_add: {
    name: "playlist_add",
    version: 16,
    popularity: 21931,
    codepoint: 57403,
    categories: ["av"],
    tags: [
      "+",
      "add",
      "collection",
      "list",
      "music",
      "new",
      "playlist",
      "plus",
      "symbol",
    ],
  },
  playlist_add_check: {
    name: "playlist_add_check",
    version: 17,
    popularity: 16020,
    codepoint: 57445,
    categories: ["av"],
    tags: [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "list",
      "mark",
      "music",
      "ok",
      "playlist",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  playlist_add_check_circle: {
    name: "playlist_add_check_circle",
    version: 2,
    popularity: 4086,
    codepoint: 59366,
    categories: ["av"],
    tags: [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track",
    ],
  },
  playlist_add_circle: {
    name: "playlist_add_circle",
    version: 2,
    popularity: 2375,
    codepoint: 59365,
    categories: ["av"],
    tags: [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track",
    ],
  },
  playlist_play: {
    name: "playlist_play",
    version: 14,
    popularity: 6934,
    codepoint: 57439,
    categories: ["av"],
    tags: ["arrow", "collection", "list", "music", "play", "playlist"],
  },
  playlist_remove: {
    name: "playlist_remove",
    version: 1,
    popularity: 3613,
    codepoint: 60288,
    categories: ["av"],
    tags: ["-", "collection", "list", "minus", "music", "playlist", "remove"],
  },
  plumbing: {
    name: "plumbing",
    version: 12,
    popularity: 4226,
    codepoint: 61703,
    categories: ["maps"],
    tags: [
      "build",
      "construction",
      "fix",
      "handyman",
      "plumbing",
      "repair",
      "tools",
      "wrench",
    ],
  },
  podcasts: {
    name: "podcasts",
    version: 9,
    popularity: 10685,
    codepoint: 61512,
    categories: ["search"],
    tags: [
      "broadcast",
      "casting",
      "network",
      "podcasts",
      "signal",
      "transmitting",
      "wireless",
    ],
  },
  podiatry: {
    name: "podiatry",
    version: 229,
    popularity: 17,
    codepoint: 57632,
    categories: ["Activities"],
    tags: ["body", "foot", "health", "human", "shoe", "step", "steps", "walk"],
  },
  podium: {
    name: "podium",
    version: 229,
    popularity: 60,
    codepoint: 63483,
    categories: ["Business&Payments"],
    tags: [
      "conference",
      "keynote",
      "platform",
      "presentation",
      "presenter",
      "pulpit",
      "speech",
      "stage",
      "stand",
    ],
  },
  point_of_sale: {
    name: "point_of_sale",
    version: 8,
    popularity: 15485,
    codepoint: 61822,
    categories: ["hardware"],
    tags: [
      "checkout",
      "cost",
      "machine",
      "merchant",
      "money",
      "of",
      "pay",
      "payment",
      "point",
      "pos",
      "retail",
      "sale",
      "system",
      "transaction",
    ],
  },
  point_scan: {
    name: "point_scan",
    version: 229,
    popularity: 54,
    codepoint: 63244,
    categories: ["UI actions"],
    tags: ["chrome", "locate", "pointer", "scanning", "target"],
  },
  policy: {
    name: "policy",
    version: 12,
    popularity: 20451,
    codepoint: 59927,
    categories: ["content"],
    tags: [
      "certified",
      "find",
      "glass",
      "legal",
      "look",
      "magnify",
      "magnifying",
      "policy",
      "privacy",
      "private",
      "protect",
      "protection",
      "search",
      "security",
      "see",
      "shield",
      "verified",
    ],
  },
  polyline: {
    name: "polyline",
    version: 1,
    popularity: 2239,
    codepoint: 60347,
    categories: ["editor"],
    tags: ["compose", "create", "design", "draw", "line", "polyline", "vector"],
  },
  polymer: {
    name: "polymer",
    version: 12,
    popularity: 4468,
    codepoint: 59563,
    categories: ["action"],
    tags: ["emblem", "logo", "mark", "polymer"],
  },
  pool: {
    name: "pool",
    version: 12,
    popularity: 8964,
    codepoint: 60232,
    categories: ["places"],
    tags: [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "ocean",
      "people",
      "person",
      "places",
      "pool",
      "sea",
      "sports",
      "swim",
      "swimming",
      "water",
    ],
  },
  portable_wifi_off: {
    name: "portable_wifi_off",
    version: 12,
    popularity: 1639,
    codepoint: 57550,
    categories: ["communication"],
    tags: [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "portable",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless",
    ],
  },
  position_bottom_left: {
    name: "position_bottom_left",
    version: 229,
    popularity: 4,
    codepoint: 63243,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  position_bottom_right: {
    name: "position_bottom_right",
    version: 229,
    popularity: 6,
    codepoint: 63242,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  position_top_right: {
    name: "position_top_right",
    version: 229,
    popularity: 7,
    codepoint: 63241,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  post: {
    name: "post",
    version: 229,
    popularity: 7,
    codepoint: 59141,
    categories: ["Text Formatting"],
    tags: ["doc", "document", "memo", "note", "post", "writing"],
  },
  post_add: {
    name: "post_add",
    version: 11,
    popularity: 37324,
    codepoint: 59936,
    categories: ["editor"],
    tags: [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "plus",
      "post",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  potted_plant: {
    name: "potted_plant",
    version: 229,
    popularity: 2185,
    codepoint: 63658,
    categories: ["Social"],
    tags: ["houseplant", "leaf", "leaves", "plant", "pot", "potted"],
  },
  power: {
    name: "power",
    version: 12,
    popularity: 8981,
    codepoint: 58940,
    categories: ["notification"],
    tags: [
      "charge",
      "cord",
      "electric",
      "electrical",
      "outlet",
      "plug",
      "power",
    ],
  },
  power_input: {
    name: "power_input",
    version: 12,
    popularity: 1364,
    codepoint: 58166,
    categories: ["hardware"],
    tags: ["input", "lines", "power", "supply"],
  },
  power_off: {
    name: "power_off",
    version: 12,
    popularity: 4290,
    codepoint: 58950,
    categories: ["notification"],
    tags: [
      "charge",
      "cord",
      "disabled",
      "electric",
      "electrical",
      "enabled",
      "off",
      "on",
      "outlet",
      "plug",
      "power",
      "slash",
    ],
  },
  power_settings_new: {
    name: "power_settings_new",
    version: 12,
    popularity: 52169,
    codepoint: 59564,
    categories: ["action"],
    tags: [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown",
    ],
  },
  prayer_times: {
    name: "prayer_times",
    version: 229,
    popularity: 16,
    codepoint: 63544,
    categories: ["Social"],
    tags: ["islam", "muslim", "prayer", "salah"],
  },
  precision_manufacturing: {
    name: "precision_manufacturing",
    version: 10,
    popularity: 26310,
    codepoint: 61513,
    categories: ["social"],
    tags: [
      "arm",
      "automatic",
      "chain",
      "conveyor",
      "crane",
      "factory",
      "industry",
      "machinery",
      "manufacturing",
      "mechanical",
      "precision",
      "production",
      "repairing",
      "robot",
      "supply",
      "warehouse",
    ],
  },
  pregnancy: {
    name: "pregnancy",
    version: 229,
    popularity: 4,
    codepoint: 62961,
    categories: ["Social"],
    tags: [
      "baby",
      "birth",
      "body",
      "dad",
      "father",
      "female",
      "human",
      "lady",
      "male",
      "maternity",
      "men",
      "mom",
      "mother",
      "parent",
      "paternity",
      "people",
      "person",
      "pregnant",
      "women",
    ],
  },
  pregnant_woman: {
    name: "pregnant_woman",
    version: 14,
    popularity: 7127,
    codepoint: 59678,
    categories: ["action"],
    tags: [
      "baby",
      "birth",
      "body",
      "female",
      "human",
      "lady",
      "maternity",
      "mom",
      "mother",
      "people",
      "person",
      "pregnant",
      "women",
    ],
  },
  preliminary: {
    name: "preliminary",
    version: 229,
    popularity: 55,
    codepoint: 59352,
    categories: ["UI actions"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "health",
      "load",
      "loading",
      "mark",
      "ok",
      "pend",
      "pending",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  prescriptions: {
    name: "prescriptions",
    version: 229,
    popularity: 24,
    codepoint: 57633,
    categories: ["Social"],
    tags: [
      "clipboard",
      "doc",
      "document",
      "drug",
      "drugs",
      "illicit",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx",
    ],
  },
  present_to_all: {
    name: "present_to_all",
    version: 11,
    popularity: 4940,
    codepoint: 57567,
    categories: ["communication"],
    tags: [
      "all",
      "arrow",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "to",
      "web",
      "website",
    ],
  },
  preview: {
    name: "preview",
    version: 7,
    popularity: 35762,
    codepoint: 61893,
    categories: ["action"],
    tags: [
      "design",
      "eye",
      "layout",
      "preview",
      "reveal",
      "screen",
      "see",
      "show",
      "site",
      "view",
      "web",
      "website",
      "window",
      "www",
    ],
  },
  preview_off: {
    name: "preview_off",
    version: 229,
    popularity: 5,
    codepoint: 63407,
    categories: ["Common actions"],
    tags: [
      "design",
      "disabled",
      "enabled",
      "eye",
      "layout",
      "off",
      "offline",
      "on",
      "preview",
      "reveal",
      "screen",
      "see",
      "show",
      "site",
      "view",
      "web",
      "website",
      "window",
      "www",
    ],
  },
  price_change: {
    name: "price_change",
    version: 10,
    popularity: 14878,
    codepoint: 61514,
    categories: ["device"],
    tags: [
      "arrows",
      "bill",
      "card",
      "cash",
      "change",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "down",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "up",
    ],
  },
  price_check: {
    name: "price_check",
    version: 10,
    popularity: 17990,
    codepoint: 61515,
    categories: ["device"],
    tags: [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "price",
      "select",
      "shopping",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  print: {
    name: "print",
    version: 16,
    popularity: 63834,
    codepoint: 59565,
    categories: ["action"],
    tags: [
      "draft",
      "fax",
      "ink",
      "machine",
      "office",
      "paper",
      "print",
      "printer",
      "send",
    ],
  },
  print_add: {
    name: "print_add",
    version: 229,
    popularity: 1,
    codepoint: 63394,
    categories: ["Hardware"],
    tags: [
      "+",
      "create",
      "draft",
      "fax",
      "ink",
      "machine",
      "new",
      "office",
      "paper",
      "plus",
      "print",
      "printer",
      "send",
    ],
  },
  print_connect: {
    name: "print_connect",
    version: 229,
    popularity: 2,
    codepoint: 63393,
    categories: ["Hardware"],
    tags: [
      "approve",
      "check",
      "checkmark",
      "complete",
      "done",
      "draft",
      "fax",
      "ink",
      "machine",
      "mark",
      "office",
      "ok",
      "paper",
      "print",
      "printer",
      "select",
      "send",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  print_disabled: {
    name: "print_disabled",
    version: 12,
    popularity: 2033,
    codepoint: 59855,
    categories: ["communication"],
    tags: [
      "disabled",
      "enabled",
      "off",
      "on",
      "paper",
      "print",
      "printer",
      "slash",
    ],
  },
  print_error: {
    name: "print_error",
    version: 229,
    popularity: 2,
    codepoint: 63392,
    categories: ["Hardware"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "draft",
      "error",
      "exclamation",
      "fax",
      "feedback",
      "important",
      "ink",
      "machine",
      "mark",
      "notification",
      "office",
      "paper",
      "print",
      "printer",
      "problem",
      "report",
      "send",
    ],
  },
  print_lock: {
    name: "print_lock",
    version: 229,
    popularity: 1,
    codepoint: 63057,
    categories: ["Hardware"],
    tags: [
      "draft",
      "fax",
      "ink",
      "lock",
      "locked",
      "machine",
      "office",
      "paper",
      "password",
      "print",
      "printer",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "secure",
      "security",
      "send",
    ],
  },
  priority: {
    name: "priority",
    version: 229,
    popularity: 2794,
    codepoint: 57759,
    categories: ["Common actions"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "filled",
      "ok",
      "priority",
      "select",
      "tick",
      "yes",
    ],
  },
  priority_high: {
    name: "priority_high",
    version: 19,
    popularity: 39286,
    codepoint: 58949,
    categories: ["notification"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "high",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning",
    ],
  },
  privacy: {
    name: "privacy",
    version: 229,
    popularity: 128,
    codepoint: 61768,
    categories: ["Audio&Video"],
    tags: ["privacy"],
  },
  privacy_tip: {
    name: "privacy_tip",
    version: 12,
    popularity: 18601,
    codepoint: 61660,
    categories: ["action"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "certified",
      "details",
      "help",
      "i",
      "info",
      "information",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "service",
      "shield",
      "support",
      "tip",
      "verified",
    ],
  },
  private_connectivity: {
    name: "private_connectivity",
    version: 5,
    popularity: 2888,
    codepoint: 59204,
    categories: ["action"],
    tags: [
      "connectivity",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
    ],
  },
  problem: {
    name: "problem",
    version: 229,
    popularity: 116,
    codepoint: 57634,
    categories: ["Common actions"],
    tags: [
      "!",
      "alert",
      "attention",
      "card",
      "caution",
      "danger",
      "error",
      "exclamation",
      "health",
      "important",
      "mark",
      "notification",
      "report",
      "symbol",
      "warning",
    ],
  },
  procedure: {
    name: "procedure",
    version: 229,
    popularity: 3,
    codepoint: 58961,
    categories: ["Social"],
    tags: ["health", "medical", "surgery"],
  },
  process_chart: {
    name: "process_chart",
    version: 229,
    popularity: 36,
    codepoint: 63573,
    categories: ["Text Formatting"],
    tags: [
      "chart",
      "data",
      "diagram",
      "forward",
      "graph",
      "infographic",
      "line",
      "lines",
      "measure",
      "metrics",
      "slant",
      "slants",
      "slash",
      "slashes",
    ],
  },
  production_quantity_limits: {
    name: "production_quantity_limits",
    version: 7,
    popularity: 12845,
    codepoint: 57809,
    categories: ["action"],
    tags: [
      "!",
      "alert",
      "attention",
      "bill",
      "card",
      "cart",
      "cash",
      "caution",
      "coin",
      "commerce",
      "credit",
      "currency",
      "danger",
      "dollars",
      "error",
      "exclamation",
      "important",
      "limits",
      "mark",
      "money",
      "notification",
      "online",
      "pay",
      "payment",
      "production",
      "quantity",
      "shopping",
      "symbol",
      "warning",
    ],
  },
  productivity: {
    name: "productivity",
    version: 229,
    popularity: 517,
    codepoint: 58006,
    categories: ["Home"],
    tags: ["home", "nest", "productivity"],
  },
  progress_activity: {
    name: "progress_activity",
    version: 229,
    popularity: 486,
    codepoint: 59856,
    categories: ["UI actions"],
    tags: [
      "activity",
      "circle",
      "duration",
      "loading",
      "progress",
      "time",
      "turning",
    ],
  },
  propane: {
    name: "propane",
    version: 1,
    popularity: 622,
    codepoint: 60436,
    categories: ["home"],
    tags: ["gas", "nest", "propane"],
  },
  propane_tank: {
    name: "propane_tank",
    version: 1,
    popularity: 1090,
    codepoint: 60435,
    categories: ["home"],
    tags: ["bbq", "gas", "grill", "nest", "propane", "tank"],
  },
  psychiatry: {
    name: "psychiatry",
    version: 229,
    popularity: 110,
    codepoint: 57635,
    categories: ["Social"],
    tags: ["garden", "health", "leaf", "leaves", "plant", "sprout", "yard"],
  },
  psychology: {
    name: "psychology",
    version: 11,
    popularity: 35352,
    codepoint: 59978,
    categories: ["social"],
    tags: [
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "human",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "science",
      "settings",
      "social",
      "therapy",
      "thinking",
      "thoughts",
    ],
  },
  psychology_alt: {
    name: "psychology_alt",
    version: 1,
    popularity: 3448,
    codepoint: 63722,
    categories: ["social"],
    tags: [
      "?",
      "assistance",
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "help",
      "human",
      "info",
      "information",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "punctuation",
      "question mark",
      "science",
      "settings",
      "social",
      "support",
      "symbol",
      "therapy",
      "thinking",
      "thoughts",
    ],
  },
  public: {
    name: "public",
    version: 13,
    popularity: 81277,
    codepoint: 59403,
    categories: ["social"],
    tags: [
      "earth",
      "global",
      "globe",
      "map",
      "network",
      "planet",
      "public",
      "social",
      "space",
      "web",
      "world",
    ],
  },
  public_off: {
    name: "public_off",
    version: 8,
    popularity: 3336,
    codepoint: 61898,
    categories: ["social"],
    tags: [
      "disabled",
      "earth",
      "enabled",
      "global",
      "globe",
      "map",
      "network",
      "off",
      "on",
      "planet",
      "public",
      "slash",
      "social",
      "space",
      "web",
      "world",
    ],
  },
  publish: {
    name: "publish",
    version: 12,
    popularity: 13400,
    codepoint: 57941,
    categories: ["editor"],
    tags: ["arrow", "cloud", "file", "import", "publish", "up", "upload"],
  },
  published_with_changes: {
    name: "published_with_changes",
    version: 6,
    popularity: 34790,
    codepoint: 62002,
    categories: ["action"],
    tags: [
      "approve",
      "arrow",
      "arrows",
      "changes",
      "check",
      "complete",
      "done",
      "inprogress",
      "load",
      "loading",
      "mark",
      "ok",
      "published",
      "refresh",
      "renew",
      "replace",
      "rotate",
      "select",
      "tick",
      "validate",
      "verified",
      "with",
      "yes",
    ],
  },
  pulmonology: {
    name: "pulmonology",
    version: 229,
    popularity: 11,
    codepoint: 57636,
    categories: ["Social"],
    tags: [
      "airways",
      "body",
      "health",
      "human",
      "lung",
      "lungs",
      "organ",
      "respiratory",
    ],
  },
  punch_clock: {
    name: "punch_clock",
    version: 2,
    popularity: 1843,
    codepoint: 60072,
    categories: ["device"],
    tags: ["clock", "date", "punch", "schedule", "time", "timer", "timesheet"],
  },
  push_pin: {
    name: "push_pin",
    version: 13,
    popularity: 39036,
    codepoint: 61709,
    categories: ["content"],
    tags: ["location", "marker", "pin", "place", "push", "remember", "save"],
  },
  qr_code: {
    name: "qr_code",
    version: 12,
    popularity: 27180,
    codepoint: 61291,
    categories: ["communication"],
    tags: [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls",
    ],
  },
  qr_code_2: {
    name: "qr_code_2",
    version: 6,
    popularity: 26679,
    codepoint: 57354,
    categories: ["communication"],
    tags: [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls",
    ],
  },
  qr_code_2_add: {
    name: "qr_code_2_add",
    version: 229,
    popularity: 14,
    codepoint: 63064,
    categories: ["Business&Payments"],
    tags: [
      "+",
      "add",
      "barcode",
      "camera",
      "code",
      "media",
      "new",
      "plus",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls",
    ],
  },
  qr_code_scanner: {
    name: "qr_code_scanner",
    version: 7,
    popularity: 46495,
    codepoint: 61958,
    categories: ["communication"],
    tags: [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "scanner",
      "smartphone",
      "url",
      "urls",
    ],
  },
  query_stats: {
    name: "query_stats",
    version: 4,
    popularity: 20589,
    codepoint: 58620,
    categories: ["editor"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "query",
      "search",
      "see",
      "statistics",
      "stats",
      "tracking",
    ],
  },
  question_exchange: {
    name: "question_exchange",
    version: 229,
    popularity: 62,
    codepoint: 63475,
    categories: ["UI actions"],
    tags: [],
  },
  question_mark: {
    name: "question_mark",
    version: 1,
    popularity: 25831,
    codepoint: 60299,
    categories: ["action"],
    tags: [
      "?",
      "assistance",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "support",
      "symbol",
    ],
  },
  queue_music: {
    name: "queue_music",
    version: 18,
    popularity: 8126,
    codepoint: 57405,
    categories: ["av"],
    tags: ["collection", "list", "music", "playlist", "queue"],
  },
  queue_play_next: {
    name: "queue_play_next",
    version: 14,
    popularity: 2322,
    codepoint: 57446,
    categories: ["av"],
    tags: [
      "+",
      "add",
      "arrow",
      "desktop",
      "device",
      "display",
      "hardware",
      "monitor",
      "new",
      "next",
      "play",
      "plus",
      "queue",
      "screen",
      "stream",
      "symbol",
      "tv",
    ],
  },
  quick_phrases: {
    name: "quick_phrases",
    version: 229,
    popularity: 838,
    codepoint: 59345,
    categories: ["Android"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "phrases",
      "quick",
      "speech",
    ],
  },
  quick_reference: {
    name: "quick_reference",
    version: 229,
    popularity: 80,
    codepoint: 58478,
    categories: ["UI actions"],
    tags: [
      "alert",
      "announcement",
      "assistance",
      "details",
      "doc",
      "document",
      "file",
      "find",
      "glass",
      "help",
      "i",
      "info",
      "information",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
      "service",
      "support",
    ],
  },
  quick_reference_all: {
    name: "quick_reference_all",
    version: 229,
    popularity: 155,
    codepoint: 63489,
    categories: ["UI actions"],
    tags: [
      "doc",
      "document",
      "file",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
    ],
  },
  quick_reorder: {
    name: "quick_reorder",
    version: 229,
    popularity: 45,
    codepoint: 60181,
    categories: ["Business&Payments"],
    tags: ["box", "delivery", "fast", "mail", "packaged", "quick", "reorder"],
  },
  quickreply: {
    name: "quickreply",
    version: 12,
    popularity: 4850,
    codepoint: 61292,
    categories: ["action"],
    tags: [
      "bolt",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "message",
      "quick",
      "quickreply",
      "reply",
      "speech",
      "thunderbolt",
    ],
  },
  quiet_time: {
    name: "quiet_time",
    version: 229,
    popularity: 141,
    codepoint: 57849,
    categories: ["Home"],
    tags: ["home", "nest", "quiet ", "security ", "time"],
  },
  quiet_time_active: {
    name: "quiet_time_active",
    version: 229,
    popularity: 101,
    codepoint: 58001,
    categories: ["Home"],
    tags: ["active", "home", "nest", "quiet ", "security ", "time"],
  },
  quiz: {
    name: "quiz",
    version: 10,
    popularity: 28022,
    codepoint: 61516,
    categories: ["device"],
    tags: [
      "?",
      "assistance",
      "faq",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "quiz",
      "support",
      "symbol",
      "test",
    ],
  },
  r_mobiledata: {
    name: "r_mobiledata",
    version: 9,
    popularity: 690,
    codepoint: 61517,
    categories: ["device"],
    tags: [
      "alphabet",
      "character",
      "data",
      "font",
      "letter",
      "mobile",
      "r",
      "symbol",
      "text",
      "type",
    ],
  },
  radar: {
    name: "radar",
    version: 10,
    popularity: 7054,
    codepoint: 61518,
    categories: ["device"],
    tags: [
      "detect",
      "military",
      "near",
      "network",
      "position",
      "radar",
      "scan",
    ],
  },
  radio: {
    name: "radio",
    version: 12,
    popularity: 6719,
    codepoint: 57406,
    categories: ["av"],
    tags: [
      "antenna",
      "audio",
      "device",
      "frequency",
      "hardware",
      "listen",
      "media",
      "music",
      "player",
      "radio",
      "signal",
      "tune",
    ],
  },
  radio_button_checked: {
    name: "radio_button_checked",
    version: 13,
    popularity: 63183,
    codepoint: 59447,
    categories: ["toggle"],
    tags: [
      "app",
      "application",
      "bullet",
      "button",
      "checked",
      "circle",
      "components",
      "design",
      "form",
      "interface",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  radio_button_partial: {
    name: "radio_button_partial",
    version: 229,
    popularity: 20,
    codepoint: 62816,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bullet",
      "button",
      "checked",
      "circle",
      "components",
      "design",
      "form",
      "interface",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  radio_button_unchecked: {
    name: "radio_button_unchecked",
    version: 13,
    popularity: 78707,
    codepoint: 59446,
    categories: ["toggle"],
    tags: [
      "bullet",
      "button",
      "circle",
      "deselected",
      "form",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "select",
      "toggle",
      "unchecked",
    ],
  },
  radiology: {
    name: "radiology",
    version: 229,
    popularity: 343,
    codepoint: 57637,
    categories: ["Social"],
    tags: [
      "bone",
      "bones",
      "health",
      "medical",
      "radiologists",
      "radiology",
      "scan",
    ],
  },
  railway_alert: {
    name: "railway_alert",
    version: 10,
    popularity: 1523,
    codepoint: 59857,
    categories: ["maps"],
    tags: [
      "!",
      "alert",
      "attention",
      "automobile",
      "bike",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "public",
      "railway",
      "scooter",
      "subway",
      "symbol",
      "train",
      "transportation",
      "vehicle",
      "vespa",
      "warning",
    ],
  },
  rainy: {
    name: "rainy",
    version: 229,
    popularity: 2129,
    codepoint: 61814,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather",
    ],
  },
  rainy_heavy: {
    name: "rainy_heavy",
    version: 229,
    popularity: 2,
    codepoint: 63007,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather",
    ],
  },
  rainy_light: {
    name: "rainy_light",
    version: 229,
    popularity: 3,
    codepoint: 63006,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather",
    ],
  },
  rainy_snow: {
    name: "rainy_snow",
    version: 229,
    popularity: 5,
    codepoint: 63005,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "sleet",
      "snow",
      "snowing",
      "temperature",
      "weather",
      "winter",
    ],
  },
  ramen_dining: {
    name: "ramen_dining",
    version: 10,
    popularity: 6886,
    codepoint: 60004,
    categories: ["maps"],
    tags: [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "lunch",
      "meal",
      "noodles",
      "ramen",
      "restaurant",
    ],
  },
  ramp_left: {
    name: "ramp_left",
    version: 1,
    popularity: 455,
    codepoint: 60316,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "ramp",
      "route",
      "sign",
      "traffic",
    ],
  },
  ramp_right: {
    name: "ramp_right",
    version: 1,
    popularity: 431,
    codepoint: 60310,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "ramp",
      "right",
      "route",
      "sign",
      "traffic",
    ],
  },
  range_hood: {
    name: "range_hood",
    version: 229,
    popularity: 166,
    codepoint: 57834,
    categories: ["Household"],
    tags: [
      "appliance",
      "cook",
      "cooking",
      "device",
      "home",
      "house",
      "kitchen",
      "nest",
      "range hood",
      "restaurants",
    ],
  },
  rate_review: {
    name: "rate_review",
    version: 11,
    popularity: 15738,
    codepoint: 58720,
    categories: ["maps"],
    tags: [
      "comment",
      "feedback",
      "pen",
      "pencil",
      "rate",
      "review",
      "stars",
      "write",
    ],
  },
  raven: {
    name: "raven",
    version: 229,
    popularity: 7,
    codepoint: 62805,
    categories: ["Social"],
    tags: ["animal", "bird", "creature", "pet"],
  },
  raw_off: {
    name: "raw_off",
    version: 10,
    popularity: 598,
    codepoint: 61519,
    categories: ["image"],
    tags: [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type",
    ],
  },
  raw_on: {
    name: "raw_on",
    version: 10,
    popularity: 1095,
    codepoint: 61520,
    categories: ["image"],
    tags: [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type",
    ],
  },
  read_more: {
    name: "read_more",
    version: 13,
    popularity: 11799,
    codepoint: 61293,
    categories: ["communication"],
    tags: ["arrow", "more", "read", "text"],
  },
  readiness_score: {
    name: "readiness_score",
    version: 229,
    popularity: 74,
    codepoint: 63197,
    categories: ["Activities"],
    tags: [
      "azm",
      "control",
      "controls",
      "fast",
      "fitbit",
      "fitness",
      "gauge",
      "health",
      "heart rate variability",
      "hrv",
      "meter",
      "motion",
      "music",
      "score",
      "sleep",
      "slow",
      "speed",
      "speedometer",
      "velocity",
      "video",
      "wellness",
    ],
  },
  real_estate_agent: {
    name: "real_estate_agent",
    version: 3,
    popularity: 10043,
    codepoint: 59194,
    categories: ["social"],
    tags: [
      "agent",
      "architecture",
      "broker",
      "estate",
      "hand",
      "home",
      "house",
      "loan",
      "mortgage",
      "property",
      "real",
      "residence",
      "residential",
      "sales",
      "social",
    ],
  },
  rear_camera: {
    name: "rear_camera",
    version: 229,
    popularity: 53,
    codepoint: 63170,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "back",
      "backside",
      "cell",
      "device",
      "fold",
      "foldable",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "photo",
      "photography",
      "picture",
      "tablet",
    ],
  },
  rebase: {
    name: "rebase",
    version: 229,
    popularity: 237,
    codepoint: 63557,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "arrows",
      "chart",
      "combine",
      "direction",
      "navigation",
      "path",
      "process",
      "workflow",
    ],
  },
  rebase_edit: {
    name: "rebase_edit",
    version: 1,
    popularity: 1462,
    codepoint: 63558,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "chart",
      "combine",
      "compose",
      "create",
      "direction",
      "draft",
      "edit",
      "editing",
      "input",
      "navigation",
      "path",
      "pen",
      "pencil",
      "process",
      "workflow",
      "write",
      "writing",
    ],
  },
  receipt: {
    name: "receipt",
    version: 12,
    popularity: 53729,
    codepoint: 59568,
    categories: ["action"],
    tags: [],
  },
  receipt_long: {
    name: "receipt_long",
    version: 12,
    popularity: 56697,
    codepoint: 61294,
    categories: ["image"],
    tags: [
      "bill",
      "check",
      "document",
      "list",
      "long",
      "paper",
      "paperwork",
      "receipt",
      "record",
      "store",
      "transaction",
    ],
  },
  recent_actors: {
    name: "recent_actors",
    version: 12,
    popularity: 8754,
    codepoint: 57407,
    categories: ["av"],
    tags: [
      "account",
      "actors",
      "avatar",
      "card",
      "cards",
      "carousel",
      "face",
      "human",
      "layers",
      "list",
      "people",
      "person",
      "profile",
      "recent",
      "thumbnail",
      "user",
    ],
  },
  recent_patient: {
    name: "recent_patient",
    version: 229,
    popularity: 18,
    codepoint: 63496,
    categories: ["Social"],
    tags: [
      "body",
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "health",
      "human",
      "instant",
      "lightning",
      "person",
      "thunderbolt",
    ],
  },
  recommend: {
    name: "recommend",
    version: 14,
    popularity: 14259,
    codepoint: 59858,
    categories: ["social"],
    tags: [
      "approved",
      "circle",
      "confirm",
      "favorite",
      "gesture",
      "hand",
      "like",
      "reaction",
      "recommend",
      "social",
      "support",
      "thumbs",
      "up",
      "well",
    ],
  },
  record_voice_over: {
    name: "record_voice_over",
    version: 12,
    popularity: 25243,
    codepoint: 59679,
    categories: ["action"],
    tags: [
      "account",
      "dictation",
      "face",
      "human",
      "over",
      "people",
      "person",
      "profile",
      "record",
      "recording",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice",
    ],
  },
  rectangle: {
    name: "rectangle",
    version: 1,
    popularity: 2793,
    codepoint: 60244,
    categories: ["editor"],
    tags: [
      "four sides",
      "parallelograms",
      "polygons",
      "quadrilaterals",
      "recangle",
      "shape",
    ],
  },
  recycling: {
    name: "recycling",
    version: 4,
    popularity: 12989,
    codepoint: 59232,
    categories: ["social"],
    tags: [
      "bio",
      "eco",
      "green",
      "loop",
      "recyclable",
      "recycle",
      "recycling",
      "rotate",
      "sustainability",
      "sustainable",
      "trash",
    ],
  },
  redeem: {
    name: "redeem",
    version: 12,
    popularity: 21565,
    codepoint: 59569,
    categories: ["action"],
    tags: [
      "bill",
      "card",
      "cart",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "gift",
      "giftcard",
      "money",
      "online",
      "pay",
      "payment",
      "present",
      "redeem",
      "shopping",
    ],
  },
  redo: {
    name: "redo",
    version: 13,
    popularity: 16702,
    codepoint: 57690,
    categories: ["content"],
    tags: [
      "arrow",
      "backward",
      "forward",
      "next",
      "redo",
      "repeat",
      "rotate",
      "undo",
    ],
  },
  reduce_capacity: {
    name: "reduce_capacity",
    version: 6,
    popularity: 6107,
    codepoint: 61980,
    categories: ["social"],
    tags: [
      "arrow",
      "body",
      "capacity",
      "covid",
      "decrease",
      "down",
      "human",
      "people",
      "person",
      "reduce",
      "social",
    ],
  },
  refresh: {
    name: "refresh",
    version: 16,
    popularity: 89609,
    codepoint: 58837,
    categories: ["navigation"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "refresh",
      "renew",
      "right",
      "rotate",
      "turn",
    ],
  },
  regular_expression: {
    name: "regular_expression",
    version: 229,
    popularity: 23,
    codepoint: 63312,
    categories: ["Text Formatting"],
    tags: [
      "algorithm",
      "algorithms",
      "code",
      "coding",
      "find and replace",
      "pattern",
      "patterns",
      "string",
      "strings",
    ],
  },
  relax: {
    name: "relax",
    version: 229,
    popularity: 43,
    codepoint: 63196,
    categories: ["Activities"],
    tags: [
      "calm",
      "chill",
      "chillout",
      "de-stress",
      "destress",
      "fitbit",
      "heart",
      "lax",
      "loosen up",
      "mood",
      "relax",
      "repose",
      "rest",
      "selfcare",
      "slow down",
      "stay loose",
      "unwind",
    ],
  },
  release_alert: {
    name: "release_alert",
    version: 229,
    popularity: 69,
    codepoint: 63060,
    categories: ["Common actions"],
    tags: [
      "!",
      "alert",
      "attention",
      "award",
      "burst",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "new",
      "notification",
      "release",
      "releases",
      "seal",
      "symbol",
      "warning",
    ],
  },
  remember_me: {
    name: "remember_me",
    version: 10,
    popularity: 3401,
    codepoint: 61521,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "avatar",
      "device",
      "hardware",
      "human",
      "iOS",
      "identity",
      "me",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "remember",
      "tablet",
      "user",
    ],
  },
  reminder: {
    name: "reminder",
    version: 229,
    popularity: 131,
    codepoint: 59029,
    categories: ["Common actions"],
    tags: [
      "alert",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "hint",
      "notice",
      "notification",
      "remember",
      "reminder",
      "reminders",
    ],
  },
  remote_gen: {
    name: "remote_gen",
    version: 229,
    popularity: 442,
    codepoint: 59454,
    categories: ["Household"],
    tags: [
      "TV",
      "control",
      "device",
      "hardware",
      "monitor",
      "nest",
      "remote",
      "television",
    ],
  },
  remove: {
    name: "remove",
    version: 16,
    popularity: 72738,
    codepoint: 57691,
    categories: ["content"],
    tags: [
      "can",
      "delete",
      "minus",
      "negative",
      "remove",
      "substract",
      "trash",
    ],
  },
  remove_done: {
    name: "remove_done",
    version: 10,
    popularity: 6493,
    codepoint: 59859,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "complete",
      "disabled",
      "done",
      "enabled",
      "finished",
      "mark",
      "multiple",
      "off",
      "ok",
      "on",
      "remove",
      "select",
      "slash",
      "tick",
      "yes",
    ],
  },
  remove_from_queue: {
    name: "remove_from_queue",
    version: 13,
    popularity: 1476,
    codepoint: 57447,
    categories: ["av"],
    tags: [
      "desktop",
      "device",
      "display",
      "from",
      "hardware",
      "monitor",
      "queue",
      "remove",
      "screen",
      "stream",
    ],
  },
  remove_moderator: {
    name: "remove_moderator",
    version: 11,
    popularity: 3267,
    codepoint: 59860,
    categories: ["social"],
    tags: [
      "certified",
      "disabled",
      "enabled",
      "moderator",
      "off",
      "on",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "slash",
      "verified",
    ],
  },
  remove_road: {
    name: "remove_road",
    version: 1,
    popularity: 824,
    codepoint: 60412,
    categories: ["maps"],
    tags: [
      "-",
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "highway",
      "maps",
      "minus",
      "new",
      "no",
      "remove",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic",
      "x",
    ],
  },
  remove_selection: {
    name: "remove_selection",
    version: 229,
    popularity: 38,
    codepoint: 59861,
    categories: ["Text Formatting"],
    tags: [
      "all",
      "clear",
      "dash",
      "dashed",
      "delete",
      "remove",
      "select",
      "selection",
      "square",
      "tool",
      "x",
    ],
  },
  remove_shopping_cart: {
    name: "remove_shopping_cart",
    version: 12,
    popularity: 7813,
    codepoint: 59688,
    categories: ["action"],
    tags: [
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "remove",
      "shopping",
      "slash",
      "tick",
    ],
  },
  reopen_window: {
    name: "reopen_window",
    version: 229,
    popularity: 13,
    codepoint: 63240,
    categories: ["UI actions"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "auto",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "mode",
      "navigation",
      "nest",
      "renew",
      "rotate",
      "screen",
      "turn",
    ],
  },
  reorder: {
    name: "reorder",
    version: 16,
    popularity: 44582,
    codepoint: 59646,
    categories: ["action"],
    tags: ["format", "lines", "list", "order", "reorder", "stacked"],
  },
  repartition: {
    name: "repartition",
    version: 1,
    popularity: 759,
    codepoint: 63720,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "data",
      "partition",
      "refresh",
      "renew",
      "repartition",
      "restore",
      "table",
    ],
  },
  repeat: {
    name: "repeat",
    version: 12,
    popularity: 13336,
    codepoint: 57408,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "repeat",
      "video",
    ],
  },
  repeat_on: {
    name: "repeat_on",
    version: 12,
    popularity: 2856,
    codepoint: 59862,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "on",
      "repeat",
      "video",
    ],
  },
  repeat_one: {
    name: "repeat_one",
    version: 12,
    popularity: 3292,
    codepoint: 57409,
    categories: ["av"],
    tags: [
      "1",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "one",
      "repeat",
      "symbol",
      "video",
    ],
  },
  repeat_one_on: {
    name: "repeat_one_on",
    version: 12,
    popularity: 1564,
    codepoint: 59863,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "on",
      "one",
      "repeat",
      "symbol",
      "video",
    ],
  },
  replay: {
    name: "replay",
    version: 13,
    popularity: 31633,
    codepoint: 57410,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "video",
    ],
  },
  replay_10: {
    name: "replay_10",
    version: 14,
    popularity: 5944,
    codepoint: 57433,
    categories: ["av"],
    tags: [
      "10",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "ten",
      "video",
    ],
  },
  replay_30: {
    name: "replay_30",
    version: 14,
    popularity: 2849,
    codepoint: 57434,
    categories: ["av"],
    tags: [
      "30",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "thirty",
      "video",
    ],
  },
  replay_5: {
    name: "replay_5",
    version: 14,
    popularity: 2280,
    codepoint: 57435,
    categories: ["av"],
    tags: [
      "5",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "five",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "video",
    ],
  },
  reply: {
    name: "reply",
    version: 20,
    popularity: 42014,
    codepoint: 57694,
    categories: ["content"],
    tags: [
      "arrow",
      "backward",
      "left",
      "mail",
      "message",
      "reply",
      "send",
      "share",
    ],
  },
  reply_all: {
    name: "reply_all",
    version: 17,
    popularity: 7445,
    codepoint: 57695,
    categories: ["content"],
    tags: [
      "all",
      "arrow",
      "backward",
      "group",
      "left",
      "mail",
      "message",
      "multiple",
      "reply",
      "send",
      "share",
    ],
  },
  report: {
    name: "report",
    version: 18,
    popularity: 28615,
    codepoint: 57696,
    categories: ["content"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "report",
      "symbol",
      "warning",
    ],
  },
  report_off: {
    name: "report_off",
    version: 12,
    popularity: 2149,
    codepoint: 57712,
    categories: ["content"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "enabled",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "off",
      "offline",
      "on",
      "report",
      "slash",
      "symbol",
      "warning",
    ],
  },
  request_page: {
    name: "request_page",
    version: 7,
    popularity: 6634,
    codepoint: 61996,
    categories: ["action"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "request",
      "sheet",
      "slide",
      "writing",
    ],
  },
  request_quote: {
    name: "request_quote",
    version: 7,
    popularity: 29460,
    codepoint: 61878,
    categories: ["file"],
    tags: [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "quote",
      "request",
      "shopping",
      "symbol",
    ],
  },
  reset_image: {
    name: "reset_image",
    version: 229,
    popularity: 43,
    codepoint: 63524,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "back",
      "image",
      "photo",
      "recover",
      "redo",
      "refresh",
      "undo",
    ],
  },
  reset_tv: {
    name: "reset_tv",
    version: 10,
    popularity: 1838,
    codepoint: 59865,
    categories: ["device"],
    tags: [
      "arrow",
      "device",
      "hardware",
      "monitor",
      "reset",
      "television",
      "tv",
    ],
  },
  reset_wrench: {
    name: "reset_wrench",
    version: 229,
    popularity: 11,
    codepoint: 62828,
    categories: ["Hardware"],
    tags: [
      "adjust",
      "around",
      "build",
      "fix",
      "inprogress",
      "load",
      "loading refresh",
      "modem",
      "renew",
      "repair",
      "rotate",
      "tool",
      "tools",
      "turn",
    ],
  },
  resize: {
    name: "resize",
    version: 229,
    popularity: 22,
    codepoint: 63239,
    categories: ["UI actions"],
    tags: ["dash", "dashes", "frame", "sizing"],
  },
  respiratory_rate: {
    name: "respiratory_rate",
    version: 229,
    popularity: 4,
    codepoint: 57639,
    categories: ["Social"],
    tags: [
      "doctor",
      "health",
      "lung",
      "lungs",
      "measure",
      "medical",
      "medicine",
      "monitor",
    ],
  },
  responsive_layout: {
    name: "responsive_layout",
    version: 229,
    popularity: 41,
    codepoint: 59866,
    categories: ["UI actions"],
    tags: [
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "orientation",
      "phone",
      "rectangles",
      "responsive",
      "screen",
      "site",
      "size",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  restart_alt: {
    name: "restart_alt",
    version: 10,
    popularity: 50171,
    codepoint: 61523,
    categories: ["device"],
    tags: [
      "alt",
      "around",
      "arrow",
      "inprogress",
      "load",
      "loading refresh",
      "reboot",
      "renew",
      "repeat",
      "reset",
      "restart",
    ],
  },
  restaurant: {
    name: "restaurant",
    version: 16,
    popularity: 42295,
    codepoint: 58732,
    categories: ["maps"],
    tags: [
      "breakfast",
      "dining",
      "dinner",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "lunch",
      "meal",
      "places",
      "restaurant",
      "spoon",
      "utensils",
    ],
  },
  restaurant_menu: {
    name: "restaurant_menu",
    version: 12,
    popularity: 23055,
    codepoint: 58721,
    categories: ["maps"],
    tags: [
      "book",
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "menu",
      "restaurant",
      "spoon",
    ],
  },
  restore_from_trash: {
    name: "restore_from_trash",
    version: 12,
    popularity: 7072,
    codepoint: 59704,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn",
    ],
  },
  restore_page: {
    name: "restore_page",
    version: 12,
    popularity: 5422,
    codepoint: 59689,
    categories: ["action"],
    tags: [
      "arrow",
      "data",
      "doc",
      "file",
      "page",
      "paper",
      "refresh",
      "restore",
      "rotate",
      "sheet",
      "storage",
    ],
  },
  resume: {
    name: "resume",
    version: 229,
    popularity: 15,
    codepoint: 63440,
    categories: ["Audio&Video"],
    tags: [
      "arrow",
      "continue",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video",
    ],
  },
  reviews: {
    name: "reviews",
    version: 10,
    popularity: 13969,
    codepoint: 61524,
    categories: ["device"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "rate",
      "rating",
      "recommendation",
      "reviews",
      "speech",
    ],
  },
  rewarded_ads: {
    name: "rewarded_ads",
    version: 229,
    popularity: 169,
    codepoint: 61366,
    categories: ["Activities"],
    tags: [
      "achievement",
      "ads",
      "award",
      "chalice",
      "champion",
      "cup",
      "first",
      "prize",
      "reward",
      "rewarded",
      "sport",
      "trophy",
      "winner",
    ],
  },
  rheumatology: {
    name: "rheumatology",
    version: 229,
    popularity: 272,
    codepoint: 57640,
    categories: ["Social"],
    tags: [
      "arthritis",
      "bone",
      "bones",
      "health",
      "joints",
      "medical",
      "rheumatic",
      "rheumatism",
      "rheumatologists",
      "rheumatology",
    ],
  },
  rib_cage: {
    name: "rib_cage",
    version: 229,
    popularity: 315,
    codepoint: 63640,
    categories: ["Social"],
    tags: [
      "body",
      "bone",
      "bones",
      "chest",
      "health",
      "medical",
      "ribs",
      "skeleton",
    ],
  },
  rice_bowl: {
    name: "rice_bowl",
    version: 6,
    popularity: 2359,
    codepoint: 61941,
    categories: ["places"],
    tags: ["bowl", "dinner", "food", "lunch", "meal", "restaurant", "rice"],
  },
  right_click: {
    name: "right_click",
    version: 229,
    popularity: 15,
    codepoint: 63238,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "circle",
      "clicks",
      "mouse",
      "move",
      "select",
      "selection",
      "selects",
    ],
  },
  right_panel_close: {
    name: "right_panel_close",
    version: 229,
    popularity: 48,
    codepoint: 63237,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  right_panel_open: {
    name: "right_panel_open",
    version: 229,
    popularity: 44,
    codepoint: 63236,
    categories: ["UI actions"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  ring_volume: {
    name: "ring_volume",
    version: 13,
    popularity: 3726,
    codepoint: 57553,
    categories: ["communication"],
    tags: [
      "call",
      "calling",
      "cell",
      "contact",
      "device",
      "hardware",
      "incoming",
      "mobile",
      "phone",
      "ring",
      "ringer",
      "sound",
      "telephone",
      "volume",
    ],
  },
  ripples: {
    name: "ripples",
    version: 229,
    popularity: 14,
    codepoint: 59867,
    categories: ["UI actions"],
    tags: [
      "components",
      "design",
      "function",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "ripples",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  robot: {
    name: "robot",
    version: 229,
    popularity: 76,
    codepoint: 63618,
    categories: ["Hardware"],
    tags: ["bot", "droid", "games", "robot", "smart", "toy"],
  },
  robot_2: {
    name: "robot_2",
    version: 229,
    popularity: 56,
    codepoint: 62928,
    categories: ["Hardware"],
    tags: ["bot", "droid", "games", "robot", "smart", "toy"],
  },
  rocket: {
    name: "rocket",
    version: 1,
    popularity: 7798,
    codepoint: 60325,
    categories: ["action"],
    tags: ["rocket", "space", "spaceship"],
  },
  rocket_launch: {
    name: "rocket_launch",
    version: 1,
    popularity: 26058,
    codepoint: 60315,
    categories: ["action"],
    tags: ["launch", "rocket", "space", "spaceship", "takeoff"],
  },
  roller_shades: {
    name: "roller_shades",
    version: 1,
    popularity: 789,
    codepoint: 60434,
    categories: ["home"],
    tags: [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "roller",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  roller_shades_closed: {
    name: "roller_shades_closed",
    version: 1,
    popularity: 594,
    codepoint: 60433,
    categories: ["home"],
    tags: [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  roller_skating: {
    name: "roller_skating",
    version: 1,
    popularity: 830,
    codepoint: 60365,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "roller",
      "shoe",
      "skate",
      "skates",
      "skating",
      "social",
      "sports",
      "travel",
    ],
  },
  roofing: {
    name: "roofing",
    version: 6,
    popularity: 7478,
    codepoint: 61953,
    categories: ["places"],
    tags: [
      "architecture",
      "building",
      "chimney",
      "construction",
      "estate",
      "home",
      "house",
      "real",
      "residence",
      "residential",
      "roof",
      "roofing",
      "service",
      "shelter",
    ],
  },
  room_preferences: {
    name: "room_preferences",
    version: 7,
    popularity: 6845,
    codepoint: 61880,
    categories: ["places"],
    tags: [
      "building",
      "door",
      "doorway",
      "entrance",
      "gear",
      "home",
      "house",
      "interior",
      "office",
      "open",
      "preferences",
      "room",
      "settings",
    ],
  },
  room_service: {
    name: "room_service",
    version: 11,
    popularity: 7484,
    codepoint: 60233,
    categories: ["places"],
    tags: ["alert", "bell", "delivery", "hotel", "notify", "room", "service"],
  },
  rotate_90_degrees_ccw: {
    name: "rotate_90_degrees_ccw",
    version: 12,
    popularity: 3136,
    codepoint: 58392,
    categories: ["image"],
    tags: [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn",
    ],
  },
  rotate_90_degrees_cw: {
    name: "rotate_90_degrees_cw",
    version: 2,
    popularity: 2111,
    codepoint: 60075,
    categories: ["image"],
    tags: [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn",
    ],
  },
  rotate_left: {
    name: "rotate_left",
    version: 13,
    popularity: 6961,
    codepoint: 58393,
    categories: ["image"],
    tags: [
      "around",
      "arrow",
      "dash",
      "dashed",
      "direction",
      "inprogress",
      "left",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "turn",
    ],
  },
  rotate_right: {
    name: "rotate_right",
    version: 13,
    popularity: 9972,
    codepoint: 58394,
    categories: ["image"],
    tags: [
      "around",
      "arrow",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "right",
      "rotate",
      "turn",
    ],
  },
  roundabout_left: {
    name: "roundabout_left",
    version: 1,
    popularity: 589,
    codepoint: 60313,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "roundabout",
      "route",
      "sign",
      "traffic",
    ],
  },
  roundabout_right: {
    name: "roundabout_right",
    version: 1,
    popularity: 798,
    codepoint: 60323,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "roundabout",
      "route",
      "sign",
      "traffic",
    ],
  },
  rounded_corner: {
    name: "rounded_corner",
    version: 15,
    popularity: 2356,
    codepoint: 59680,
    categories: ["action"],
    tags: [
      "adjust",
      "corner",
      "dash",
      "dashed",
      "edit",
      "rounded",
      "shape",
      "square",
      "transform",
    ],
  },
  route: {
    name: "route",
    version: 2,
    popularity: 9267,
    codepoint: 60109,
    categories: ["maps"],
    tags: ["directions", "maps", "path", "route", "sign", "traffic"],
  },
  router: {
    name: "router",
    version: 13,
    popularity: 8955,
    codepoint: 58152,
    categories: ["hardware"],
    tags: [
      "box",
      "cable",
      "connection",
      "hardware",
      "internet",
      "network",
      "router",
      "signal",
      "wifi",
    ],
  },
  routine: {
    name: "routine",
    version: 229,
    popularity: 812,
    codepoint: 57868,
    categories: ["Social"],
    tags: [
      "climate",
      "lunar",
      "moon",
      "nest",
      "order",
      "pattern",
      "routine",
      "schedule",
      "sun",
      "weather",
    ],
  },
  rowing: {
    name: "rowing",
    version: 14,
    popularity: 5817,
    codepoint: 59681,
    categories: ["action"],
    tags: [
      "activity",
      "boat",
      "body",
      "canoe",
      "human",
      "people",
      "person",
      "row",
      "rowing",
      "sport",
      "water",
    ],
  },
  rss_feed: {
    name: "rss_feed",
    version: 12,
    popularity: 15743,
    codepoint: 57573,
    categories: ["communication"],
    tags: [
      "application",
      "blog",
      "connection",
      "data",
      "feed",
      "internet",
      "network",
      "rss",
      "service",
      "signal",
      "website",
      "wifi",
      "wireless",
    ],
  },
  rsvp: {
    name: "rsvp",
    version: 10,
    popularity: 1084,
    codepoint: 61525,
    categories: ["device"],
    tags: [
      "alphabet",
      "character",
      "font",
      "invitation",
      "invite",
      "letter",
      "plaît",
      "respond",
      "rsvp",
      "répondez",
      "sil",
      "symbol",
      "text",
      "type",
      "vous",
    ],
  },
  rtt: {
    name: "rtt",
    version: 15,
    popularity: 1675,
    codepoint: 59821,
    categories: ["communication"],
    tags: ["call", "real", "rrt", "text", "time"],
  },
  rubric: {
    name: "rubric",
    version: 229,
    popularity: 46,
    codepoint: 60199,
    categories: ["Text Formatting"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "check",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "ok",
      "rubric",
      "sheet",
      "spreadsheet",
      "statistics",
      "tick",
      "tracking",
    ],
  },
  rule: {
    name: "rule",
    version: 8,
    popularity: 23990,
    codepoint: 61890,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "incomplete",
      "line",
      "mark",
      "missing",
      "no",
      "ok",
      "rule",
      "select",
      "tick",
      "validate",
      "verified",
      "wrong",
      "x",
      "yes",
    ],
  },
  rule_folder: {
    name: "rule_folder",
    version: 7,
    popularity: 4687,
    codepoint: 61897,
    categories: ["file"],
    tags: [
      "approve",
      "cancel",
      "check",
      "close",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "exit",
      "file",
      "folder",
      "mark",
      "no",
      "ok",
      "remove",
      "rule",
      "select",
      "sheet",
      "slide",
      "storage",
      "tick",
      "validate",
      "verified",
      "x",
      "yes",
    ],
  },
  rule_settings: {
    name: "rule_settings",
    version: 229,
    popularity: 40,
    codepoint: 63052,
    categories: ["UI actions"],
    tags: [
      "360",
      "application",
      "around",
      "arrow",
      "arrows",
      "change",
      "custom",
      "customize",
      "details",
      "direction",
      "gear",
      "info",
      "information",
      "inprogress",
      "load",
      "loading refresh",
      "options",
      "renew",
      "rotate",
      "service",
      "setting",
      "sync",
      "turn",
    ],
  },
  run_circle: {
    name: "run_circle",
    version: 11,
    popularity: 3638,
    codepoint: 61295,
    categories: ["maps"],
    tags: [
      "body",
      "circle",
      "exercise",
      "human",
      "people",
      "person",
      "run",
      "running",
    ],
  },
  running_with_errors: {
    name: "running_with_errors",
    version: 4,
    popularity: 6057,
    codepoint: 58653,
    categories: ["notification"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "duration",
      "error",
      "errors",
      "exclamation",
      "important",
      "mark",
      "notification",
      "process",
      "processing",
      "running",
      "symbol",
      "time",
      "warning",
      "with",
    ],
  },
  rv_hookup: {
    name: "rv_hookup",
    version: 12,
    popularity: 2849,
    codepoint: 58946,
    categories: ["places"],
    tags: [
      "arrow",
      "attach",
      "automobile",
      "automotive",
      "back",
      "car",
      "cars",
      "connect",
      "direction",
      "hookup",
      "left",
      "maps",
      "public",
      "right",
      "rv",
      "trailer",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle",
    ],
  },
  safety_check: {
    name: "safety_check",
    version: 1,
    popularity: 2011,
    codepoint: 60399,
    categories: ["maps"],
    tags: [
      "certified",
      "check",
      "clock",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "schedule",
      "security",
      "shield",
      "time",
      "verified",
    ],
  },
  safety_check_off: {
    name: "safety_check_off",
    version: 229,
    popularity: 7,
    codepoint: 62877,
    categories: ["Maps"],
    tags: [
      "certified",
      "check",
      "clock",
      "disabled",
      "enabled",
      "offline",
      "on",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "schedule",
      "security",
      "shield",
      "slash",
      "time",
      "verified",
    ],
  },
  safety_divider: {
    name: "safety_divider",
    version: 7,
    popularity: 3058,
    codepoint: 57804,
    categories: ["social"],
    tags: [
      "apart",
      "distance",
      "divider",
      "safety",
      "separate",
      "social",
      "space",
    ],
  },
  sailing: {
    name: "sailing",
    version: 4,
    popularity: 7513,
    codepoint: 58626,
    categories: ["maps"],
    tags: [
      "boat",
      "entertainment",
      "fishing",
      "hobby",
      "ocean",
      "sailboat",
      "sailing",
      "sea",
      "social sports",
      "travel",
      "water",
    ],
  },
  salinity: {
    name: "salinity",
    version: 229,
    popularity: 3,
    codepoint: 63606,
    categories: ["Social"],
    tags: ["droplet", "saline", "salt", "water"],
  },
  sanitizer: {
    name: "sanitizer",
    version: 6,
    popularity: 4554,
    codepoint: 61981,
    categories: ["social"],
    tags: [
      "bacteria",
      "bottle",
      "clean",
      "covid",
      "disinfect",
      "germs",
      "pump",
      "sanitizer",
    ],
  },
  satellite: {
    name: "satellite",
    version: 12,
    popularity: 3213,
    codepoint: 58722,
    categories: ["maps"],
    tags: [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "data",
      "device",
      "image",
      "internet",
      "landscape",
      "location",
      "maps",
      "mountain",
      "mountains",
      "network",
      "photo",
      "photography",
      "picture",
      "satellite",
      "scan",
      "service",
      "signal",
      "symbol",
      "wireless-- wifi",
    ],
  },
  satellite_alt: {
    name: "satellite_alt",
    version: 1,
    popularity: 4493,
    codepoint: 60218,
    categories: ["action"],
    tags: [
      "alternative",
      "artificial",
      "communication",
      "satellite",
      "space",
      "space station",
      "television",
    ],
  },
  sauna: {
    name: "sauna",
    version: 229,
    popularity: 5,
    codepoint: 63223,
    categories: ["Activities"],
    tags: [
      "bathe",
      "bathhouse",
      "body",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "people",
      "person",
      "spa",
      "steam",
      "water",
    ],
  },
  save: {
    name: "save",
    version: 12,
    popularity: 80405,
    codepoint: 57697,
    categories: ["content"],
    tags: [
      "data",
      "disk",
      "document",
      "drive",
      "file",
      "floppy",
      "multimedia",
      "save",
      "storage",
    ],
  },
  save_as: {
    name: "save_as",
    version: 2,
    popularity: 6263,
    codepoint: 60256,
    categories: ["content"],
    tags: [
      "compose",
      "create",
      "data",
      "disk",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "floppy",
      "input",
      "multimedia",
      "pen",
      "pencil",
      "save",
      "storage",
      "write",
      "writing",
    ],
  },
  saved_search: {
    name: "saved_search",
    version: 11,
    popularity: 8414,
    codepoint: 59921,
    categories: ["action"],
    tags: [
      "find",
      "glass",
      "important",
      "look",
      "magnify",
      "magnifying",
      "marked",
      "saved",
      "search",
      "see",
      "star",
    ],
  },
  savings: {
    name: "savings",
    version: 5,
    popularity: 55909,
    codepoint: 58091,
    categories: ["action"],
    tags: [
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pig",
      "piggy",
      "savings",
      "symbol",
    ],
  },
  scale: {
    name: "scale",
    version: 1,
    popularity: 4860,
    codepoint: 60255,
    categories: ["social"],
    tags: ["measure", "monitor", "scale", "weight"],
  },
  scan: {
    name: "scan",
    version: 229,
    popularity: 12,
    codepoint: 63310,
    categories: ["Text Formatting"],
    tags: [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "text",
      "writing",
    ],
  },
  scan_delete: {
    name: "scan_delete",
    version: 229,
    popularity: 32,
    codepoint: 63311,
    categories: ["Text Formatting"],
    tags: [
      "cancel",
      "clear",
      "close",
      "data",
      "doc",
      "document",
      "drive",
      "exit",
      "file",
      "folder",
      "folders",
      "no",
      "notes",
      "off",
      "page",
      "paper",
      "remove",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "stop",
      "text",
      "writing",
      "x",
    ],
  },
  scanner: {
    name: "scanner",
    version: 12,
    popularity: 2508,
    codepoint: 58153,
    categories: ["hardware"],
    tags: ["copy", "device", "hardware", "machine", "scan", "scanner"],
  },
  scatter_plot: {
    name: "scatter_plot",
    version: 12,
    popularity: 5394,
    codepoint: 57960,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "circles",
      "data",
      "diagram",
      "dot",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "plot",
      "scatter",
      "statistics",
      "tracking",
    ],
  },
  scene: {
    name: "scene",
    version: 229,
    popularity: 573,
    codepoint: 58023,
    categories: ["Household"],
    tags: [
      "IoT",
      "couch",
      "home",
      "house",
      "light",
      "nest",
      "room",
      "scene",
      "sofa",
    ],
  },
  schedule: {
    name: "schedule",
    version: 17,
    popularity: 189984,
    codepoint: 59573,
    categories: ["action"],
    tags: ["clock", "date", "history", "recent", "schedule", "time"],
  },
  schedule_send: {
    name: "schedule_send",
    version: 14,
    popularity: 11635,
    codepoint: 59914,
    categories: ["action"],
    tags: [
      "calendar",
      "clock",
      "date",
      "email",
      "letter",
      "mail",
      "remember",
      "schedule",
      "send",
      "share",
      "time",
    ],
  },
  schema: {
    name: "schema",
    version: 4,
    popularity: 6680,
    codepoint: 58621,
    categories: ["editor"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "schema",
      "squares",
      "statistics",
      "tracking",
    ],
  },
  school: {
    name: "school",
    version: 13,
    popularity: 91706,
    codepoint: 59404,
    categories: ["social"],
    tags: [
      "academy",
      "achievement",
      "cap",
      "class",
      "college",
      "education",
      "graduation",
      "hat",
      "knowledge",
      "learning",
      "school",
      "university",
    ],
  },
  science: {
    name: "science",
    version: 17,
    popularity: 24693,
    codepoint: 59979,
    categories: ["social"],
    tags: [
      "beaker",
      "chemical",
      "chemistry",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "research",
      "science",
      "tube",
    ],
  },
  science_off: {
    name: "science_off",
    version: 229,
    popularity: 8,
    codepoint: 62786,
    categories: ["Activities"],
    tags: [
      "beaker",
      "chemical",
      "chemistry",
      "disabled",
      "enabled",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "off",
      "offline",
      "on",
      "research",
      "science",
      "slash",
      "tube",
    ],
  },
  score: {
    name: "score",
    version: 12,
    popularity: 2584,
    codepoint: 57961,
    categories: ["editor"],
    tags: [
      "2k",
      "alphabet",
      "analytics",
      "bar",
      "bars",
      "character",
      "chart",
      "data",
      "diagram",
      "digit",
      "font",
      "graph",
      "infographic",
      "letter",
      "measure",
      "metrics",
      "number",
      "score",
      "statistics",
      "symbol",
      "text",
      "tracking",
      "type",
    ],
  },
  scoreboard: {
    name: "scoreboard",
    version: 1,
    popularity: 1643,
    codepoint: 60368,
    categories: ["social"],
    tags: ["board", "points", "score", "scoreboard", "sports"],
  },
  screen_lock_landscape: {
    name: "screen_lock_landscape",
    version: 16,
    popularity: 1019,
    codepoint: 57790,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "screen",
      "tablet",
    ],
  },
  screen_lock_portrait: {
    name: "screen_lock_portrait",
    version: 17,
    popularity: 1488,
    codepoint: 57791,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "portrait",
      "rotate",
      "screen",
      "tablet",
    ],
  },
  screen_lock_rotation: {
    name: "screen_lock_rotation",
    version: 16,
    popularity: 1047,
    codepoint: 57792,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn",
    ],
  },
  screen_record: {
    name: "screen_record",
    version: 229,
    popularity: 14,
    codepoint: 63097,
    categories: ["Android"],
    tags: [
      "app",
      "application",
      "circle",
      "components",
      "interface",
      "record",
      "recorder",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
    ],
  },
  screen_rotation: {
    name: "screen_rotation",
    version: 12,
    popularity: 3954,
    codepoint: 57793,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn",
    ],
  },
  screen_rotation_alt: {
    name: "screen_rotation_alt",
    version: 1,
    popularity: 786,
    codepoint: 60398,
    categories: ["maps"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn",
    ],
  },
  screen_rotation_up: {
    name: "screen_rotation_up",
    version: 229,
    popularity: 8,
    codepoint: 63096,
    categories: ["Android"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn",
    ],
  },
  screen_search_desktop: {
    name: "screen_search_desktop",
    version: 10,
    popularity: 4229,
    codepoint: 61296,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "lock",
      "monitor",
      "rotate",
      "screen",
      "web",
    ],
  },
  screen_share: {
    name: "screen_share",
    version: 12,
    popularity: 6804,
    codepoint: 57570,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "screen",
      "share",
      "stream",
      "streaming",
      "web",
      "window",
    ],
  },
  screenshot: {
    name: "screenshot",
    version: 10,
    popularity: 2848,
    codepoint: 61526,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "cell",
      "crop",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screenshot",
      "tablet",
    ],
  },
  screenshot_frame: {
    name: "screenshot_frame",
    version: 229,
    popularity: 17,
    codepoint: 63095,
    categories: ["Android"],
    tags: ["area", "capture", "crop", "frame", "screen", "screengrab"],
  },
  screenshot_keyboard: {
    name: "screenshot_keyboard",
    version: 229,
    popularity: 10,
    codepoint: 63443,
    categories: ["Android"],
    tags: ["capture", "screen", "screengrab"],
  },
  screenshot_monitor: {
    name: "screenshot_monitor",
    version: 1,
    popularity: 1500,
    codepoint: 60424,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screengrab",
      "screenshot",
      "web",
      "window",
    ],
  },
  screenshot_region: {
    name: "screenshot_region",
    version: 229,
    popularity: 21,
    codepoint: 63442,
    categories: ["Android"],
    tags: [
      "+",
      "add",
      "area",
      "capture",
      "crop",
      "frame",
      "new",
      "plus",
      "screen",
      "screengrab",
    ],
  },
  screenshot_tablet: {
    name: "screenshot_tablet",
    version: 229,
    popularity: 8,
    codepoint: 63127,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screengrab",
      "screenshot",
      "web",
      "window",
    ],
  },
  scrollable_header: {
    name: "scrollable_header",
    version: 229,
    popularity: 9,
    codepoint: 59868,
    categories: ["Common actions"],
    tags: ["design", "function", "header", "scrollable", "ui", "ux"],
  },
  scuba_diving: {
    name: "scuba_diving",
    version: 1,
    popularity: 976,
    codepoint: 60366,
    categories: ["social"],
    tags: [
      "diving",
      "entertainment",
      "exercise",
      "hobby",
      "scuba",
      "social",
      "swim",
      "swimming",
    ],
  },
  sd: {
    name: "sd",
    version: 10,
    popularity: 1280,
    codepoint: 59869,
    categories: ["av"],
    tags: [
      "alphabet",
      "camera",
      "card",
      "character",
      "data",
      "device",
      "digital",
      "drive",
      "flash",
      "font",
      "image",
      "letter",
      "memory",
      "photo",
      "sd",
      "secure",
      "symbol",
      "text",
      "type",
    ],
  },
  sd_card: {
    name: "sd_card",
    version: 12,
    popularity: 2724,
    codepoint: 58915,
    categories: ["notification"],
    tags: [
      "camera",
      "card",
      "digital",
      "memory",
      "photos",
      "sd",
      "secure",
      "storage",
    ],
  },
  sd_card_alert: {
    name: "sd_card_alert",
    version: 11,
    popularity: 1594,
    codepoint: 61527,
    categories: ["notification"],
    tags: [
      "!",
      "alert",
      "attention",
      "camera",
      "card",
      "caution",
      "danger",
      "digital",
      "error",
      "exclamation",
      "important",
      "mark",
      "memory",
      "notification",
      "photos",
      "sd",
      "secure",
      "storage",
      "symbol",
      "warning",
    ],
  },
  sdk: {
    name: "sdk",
    version: 229,
    popularity: 78,
    codepoint: 59168,
    categories: ["Common actions"],
    tags: [
      "applications",
      "apps",
      "build",
      "create",
      "development",
      "kit",
      "sdk",
      "software",
      "websites",
    ],
  },
  search: {
    name: "search",
    version: 17,
    popularity: 794586,
    codepoint: 59574,
    categories: ["action"],
    tags: [
      "filter",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see",
    ],
  },
  search_check: {
    name: "search_check",
    version: 229,
    popularity: 53,
    codepoint: 63488,
    categories: ["UI actions"],
    tags: [
      "approve",
      "check",
      "complete",
      "done",
      "find",
      "glass",
      "health",
      "look",
      "magnify",
      "magnifying",
      "mark",
      "ok",
      "search",
      "see",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  search_hands_free: {
    name: "search_hands_free",
    version: 229,
    popularity: 43,
    codepoint: 59030,
    categories: ["Common actions"],
    tags: ["free", "hands", "search", "steering", "wheel"],
  },
  search_off: {
    name: "search_off",
    version: 12,
    popularity: 8982,
    codepoint: 60022,
    categories: ["action"],
    tags: [
      "cancel",
      "clear",
      "close",
      "disabled",
      "enabled",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "off",
      "on",
      "search",
      "see",
      "slash",
      "stop",
      "x",
    ],
  },
  security: {
    name: "security",
    version: 12,
    popularity: 29712,
    codepoint: 58154,
    categories: ["hardware"],
    tags: [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified",
    ],
  },
  security_update_good: {
    name: "security_update_good",
    version: 10,
    popularity: 2626,
    codepoint: 61529,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "checkmark",
      "device",
      "good",
      "hardware",
      "iOS",
      "mobile",
      "ok",
      "phone",
      "security",
      "tablet",
      "tick",
      "update",
    ],
  },
  security_update_warning: {
    name: "security_update_warning",
    version: 10,
    popularity: 1603,
    codepoint: 61530,
    categories: ["device"],
    tags: [
      "!",
      "Android",
      "OS",
      "alert",
      "attention",
      "caution",
      "danger",
      "device",
      "download",
      "error",
      "exclamation",
      "hardware",
      "iOS",
      "important",
      "mark",
      "mobile",
      "notification",
      "phone",
      "security",
      "symbol",
      "tablet",
      "update",
      "warning",
    ],
  },
  segment: {
    name: "segment",
    version: 11,
    popularity: 12226,
    codepoint: 59723,
    categories: ["action"],
    tags: [
      "alignment",
      "fonts",
      "format",
      "lines",
      "list",
      "paragraph",
      "part",
      "piece",
      "rule",
      "rules",
      "segment",
      "style",
      "text",
    ],
  },
  select: {
    name: "select",
    version: 229,
    popularity: 32,
    codepoint: 63309,
    categories: ["Text Formatting"],
    tags: ["all", "dash", "dashed", "selection", "square", "tool"],
  },
  select_all: {
    name: "select_all",
    version: 12,
    popularity: 7327,
    codepoint: 57698,
    categories: ["content"],
    tags: ["all", "dash", "dashed", "select", "selection", "square", "tool"],
  },
  select_check_box: {
    name: "select_check_box",
    version: 229,
    popularity: 5104,
    codepoint: 61950,
    categories: ["UI actions"],
    tags: [
      "approved",
      "box",
      "button",
      "check",
      "component",
      "form",
      "mark",
      "ok",
      "select",
      "selected",
      "selection",
      "tick",
      "toggle",
      "ui",
      "verified",
      "yes",
    ],
  },
  select_to_speak: {
    name: "select_to_speak",
    version: 229,
    popularity: 13,
    codepoint: 63439,
    categories: ["Audio&Video"],
    tags: [
      "audio",
      "control",
      "frame",
      "music",
      "selection",
      "sound",
      "speaker",
      "tv",
      "up",
      "volume",
    ],
  },
  select_window: {
    name: "select_window",
    version: 229,
    popularity: 58,
    codepoint: 59130,
    categories: ["Common actions"],
    tags: [
      "multiple",
      "pages",
      "select",
      "web",
      "webpages",
      "websites",
      "window",
      "windows",
    ],
  },
  select_window_off: {
    name: "select_window_off",
    version: 229,
    popularity: 14,
    codepoint: 58630,
    categories: ["Common actions"],
    tags: [
      "browser",
      "disabled",
      "enabled",
      "internet",
      "multiple",
      "off",
      "on",
      "pages",
      "screen",
      "select",
      "slash",
      "web",
      "webpages",
      "websites",
      "window",
      "windows",
      "www",
    ],
  },
  self_care: {
    name: "self_care",
    version: 229,
    popularity: 167,
    codepoint: 63597,
    categories: ["Home"],
    tags: [
      "brush",
      "calm",
      "care",
      "chi",
      "comb",
      "improvement",
      "mirror",
      "relax",
      "self",
    ],
  },
  self_improvement: {
    name: "self_improvement",
    version: 11,
    popularity: 17210,
    codepoint: 60024,
    categories: ["social"],
    tags: [
      "body",
      "calm",
      "care",
      "chi",
      "human",
      "improvement",
      "meditate",
      "meditation",
      "people",
      "person",
      "relax",
      "self",
      "sitting",
      "wellbeing",
      "yoga",
      "zen",
    ],
  },
  sell: {
    name: "sell",
    version: 10,
    popularity: 34078,
    codepoint: 61531,
    categories: ["device"],
    tags: [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "sell",
      "shopping",
      "tag",
    ],
  },
  send: {
    name: "send",
    version: 20,
    popularity: 119156,
    codepoint: 57699,
    categories: ["content"],
    tags: [
      "email",
      "mail",
      "message",
      "paper",
      "plane",
      "reply",
      "right",
      "send",
      "share",
    ],
  },
  send_and_archive: {
    name: "send_and_archive",
    version: 11,
    popularity: 4433,
    codepoint: 59916,
    categories: ["action"],
    tags: [
      "archive",
      "arrow",
      "down",
      "download",
      "email",
      "letter",
      "mail",
      "save",
      "send",
      "share",
    ],
  },
  send_money: {
    name: "send_money",
    version: 229,
    popularity: 32,
    codepoint: 59575,
    categories: ["Business&Payments"],
    tags: [
      "arrow",
      "card",
      "cash",
      "coin",
      "coins",
      "commerce",
      "credit",
      "currency",
      "direction",
      "dollars",
      "export",
      "money",
      "online",
      "pay",
      "payment",
      "right",
      "send",
    ],
  },
  send_time_extension: {
    name: "send_time_extension",
    version: 2,
    popularity: 1509,
    codepoint: 60123,
    categories: ["communication"],
    tags: [
      "deliver",
      "dispatch",
      "envelop",
      "extension",
      "mail",
      "message",
      "schedule",
      "send",
      "time",
    ],
  },
  send_to_mobile: {
    name: "send_to_mobile",
    version: 11,
    popularity: 4435,
    codepoint: 61532,
    categories: ["device"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "device",
      "export",
      "forward",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "right",
      "send",
      "share",
      "tablet",
      "to",
    ],
  },
  sensor_door: {
    name: "sensor_door",
    version: 8,
    popularity: 6707,
    codepoint: 61877,
    categories: ["home"],
    tags: ["alarm", "security", "security system"],
  },
  sensor_occupied: {
    name: "sensor_occupied",
    version: 1,
    popularity: 1723,
    codepoint: 60432,
    categories: ["home"],
    tags: [
      "body",
      "body response",
      "connection",
      "fitbit",
      "human",
      "network",
      "people",
      "person",
      "scan",
      "sensors",
      "signal",
      "smart body scan sensor",
      "wireless",
    ],
  },
  sensor_window: {
    name: "sensor_window",
    version: 8,
    popularity: 3546,
    codepoint: 61876,
    categories: ["home"],
    tags: ["alarm", "security", "security system"],
  },
  sensors: {
    name: "sensors",
    version: 4,
    popularity: 19709,
    codepoint: 58654,
    categories: ["action"],
    tags: ["connection", "network", "scan", "sensors", "signal", "wireless"],
  },
  sensors_krx: {
    name: "sensors_krx",
    version: 229,
    popularity: 36,
    codepoint: 62806,
    categories: ["Home"],
    tags: ["bismuth"],
  },
  sensors_off: {
    name: "sensors_off",
    version: 5,
    popularity: 3438,
    codepoint: 58655,
    categories: ["action"],
    tags: [
      "connection",
      "disabled",
      "enabled",
      "network",
      "off",
      "on",
      "scan",
      "sensors",
      "signal",
      "slash",
      "wireless",
    ],
  },
  sentiment_calm: {
    name: "sentiment_calm",
    version: 229,
    popularity: 31,
    codepoint: 63143,
    categories: ["Social"],
    tags: ["face", "mood", "peaceful", "response"],
  },
  sentiment_content: {
    name: "sentiment_content",
    version: 229,
    popularity: 18,
    codepoint: 63142,
    categories: ["Social"],
    tags: ["face", "mood", "response", "satisfied", "satisfy"],
  },
  sentiment_dissatisfied: {
    name: "sentiment_dissatisfied",
    version: 11,
    popularity: 20811,
    codepoint: 59409,
    categories: ["social"],
    tags: [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "frown",
      "mood",
      "person",
      "sad",
      "sentiment",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset",
    ],
  },
  sentiment_excited: {
    name: "sentiment_excited",
    version: 229,
    popularity: 40,
    codepoint: 63141,
    categories: ["Social"],
    tags: ["excite", "excitement", "face", "mood", "passionate", "thrilled"],
  },
  sentiment_extremely_dissatisfied: {
    name: "sentiment_extremely_dissatisfied",
    version: 229,
    popularity: 1532,
    codepoint: 61844,
    categories: ["Social"],
    tags: [
      "angry",
      "dissatisfied",
      "emotions",
      "expressions",
      "extremely",
      "face",
      "feelings",
      "furious",
      "mad",
      "mood",
      "person",
      "sentiment",
      "survey",
      "unhappy",
      "upset",
    ],
  },
  sentiment_frustrated: {
    name: "sentiment_frustrated",
    version: 229,
    popularity: 18,
    codepoint: 63140,
    categories: ["Social"],
    tags: ["disappointed", "face", "frustration", "mood"],
  },
  sentiment_neutral: {
    name: "sentiment_neutral",
    version: 11,
    popularity: 10987,
    codepoint: 59410,
    categories: ["social"],
    tags: [
      "emotionless",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fine",
      "indifference",
      "mood",
      "neutral",
      "okay",
      "person",
      "sentiment",
      "survey",
    ],
  },
  sentiment_sad: {
    name: "sentiment_sad",
    version: 229,
    popularity: 26,
    codepoint: 63139,
    categories: ["Social"],
    tags: ["face", "mood", "unhappy"],
  },
  sentiment_satisfied: {
    name: "sentiment_satisfied",
    version: 11,
    popularity: 26034,
    codepoint: 59411,
    categories: ["social"],
    tags: [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey",
    ],
  },
  sentiment_stressed: {
    name: "sentiment_stressed",
    version: 229,
    popularity: 34,
    codepoint: 63138,
    categories: ["Social"],
    tags: ["face", "mood", "stress", "tense"],
  },
  sentiment_very_dissatisfied: {
    name: "sentiment_very_dissatisfied",
    version: 11,
    popularity: 19192,
    codepoint: 59412,
    categories: ["social"],
    tags: [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "sad",
      "sentiment",
      "sorrow",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset",
      "very",
    ],
  },
  sentiment_very_satisfied: {
    name: "sentiment_very_satisfied",
    version: 11,
    popularity: 27828,
    codepoint: 59413,
    categories: ["social"],
    tags: [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey",
      "very",
    ],
  },
  sentiment_worried: {
    name: "sentiment_worried",
    version: 229,
    popularity: 12,
    codepoint: 63137,
    categories: ["Social"],
    tags: ["anxious", "face", "mood", "worry"],
  },
  service_toolbox: {
    name: "service_toolbox",
    version: 229,
    popularity: 83,
    codepoint: 59159,
    categories: ["Activities"],
    tags: [
      "box",
      "equipment",
      "kit",
      "mechanic",
      "repairing",
      "service",
      "tool",
      "toolbox",
      "tools",
      "workshop",
    ],
  },
  set_meal: {
    name: "set_meal",
    version: 6,
    popularity: 4655,
    codepoint: 61930,
    categories: ["maps"],
    tags: [
      "chopsticks",
      "dinner",
      "fish",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "set",
      "teishoku",
    ],
  },
  settings: {
    name: "settings",
    version: 19,
    popularity: 553375,
    codepoint: 59576,
    categories: ["action"],
    tags: [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings",
    ],
  },
  settings_accessibility: {
    name: "settings_accessibility",
    version: 10,
    popularity: 13275,
    codepoint: 61533,
    categories: ["action"],
    tags: [
      "accessibility",
      "body",
      "details",
      "human",
      "information",
      "people",
      "person",
      "personal",
      "preferences",
      "profile",
      "settings",
      "user",
    ],
  },
  settings_account_box: {
    name: "settings_account_box",
    version: 229,
    popularity: 38,
    codepoint: 63541,
    categories: ["Common actions"],
    tags: [
      "account",
      "application",
      "avatar",
      "box",
      "custom",
      "customize",
      "details",
      "face",
      "gear",
      "human",
      "info",
      "information",
      "options",
      "people",
      "person",
      "personal",
      "portrait",
      "preferences",
      "profile",
      "service",
      "square",
      "thumbnail",
      "user",
    ],
  },
  settings_alert: {
    name: "settings_alert",
    version: 229,
    popularity: 358,
    codepoint: 61763,
    categories: ["Home"],
    tags: ["alert", "gear", "home", "nest", "settings"],
  },
  settings_applications: {
    name: "settings_applications",
    version: 11,
    popularity: 22353,
    codepoint: 59577,
    categories: ["action"],
    tags: [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings",
    ],
  },
  settings_b_roll: {
    name: "settings_b_roll",
    version: 229,
    popularity: 48,
    codepoint: 63013,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "arrow",
      "custom",
      "customize",
      "details",
      "forward",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "preferences",
      "right",
      "service",
      "setting",
    ],
  },
  settings_backup_restore: {
    name: "settings_backup_restore",
    version: 11,
    popularity: 12932,
    codepoint: 59578,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backup",
      "backwards",
      "refresh",
      "restore",
      "reverse",
      "rotate",
      "settings",
    ],
  },
  settings_bluetooth: {
    name: "settings_bluetooth",
    version: 11,
    popularity: 4214,
    codepoint: 59579,
    categories: ["action"],
    tags: [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "settings",
      "signal",
      "symbol",
    ],
  },
  settings_brightness: {
    name: "settings_brightness",
    version: 12,
    popularity: 5658,
    codepoint: 59581,
    categories: ["action"],
    tags: [
      "brightness",
      "dark",
      "filter",
      "light",
      "mode",
      "setting",
      "settings",
    ],
  },
  settings_cell: {
    name: "settings_cell",
    version: 11,
    popularity: 3408,
    codepoint: 59580,
    categories: ["action"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "settings",
      "tablet",
    ],
  },
  settings_cinematic_blur: {
    name: "settings_cinematic_blur",
    version: 229,
    popularity: 11,
    codepoint: 63012,
    categories: ["Photo&Image"],
    tags: [
      "account",
      "application",
      "blur",
      "cinema",
      "custom",
      "customize",
      "details",
      "gear",
      "human",
      "info",
      "information",
      "movie",
      "options",
      "person",
      "personal",
      "preferences",
      "profile",
      "service",
      "setting",
      "user",
      "video",
    ],
  },
  settings_ethernet: {
    name: "settings_ethernet",
    version: 11,
    popularity: 11284,
    codepoint: 59582,
    categories: ["action"],
    tags: [
      "arrows",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "ethernet",
      "internet",
      "network",
      "settings",
      "wifi",
    ],
  },
  settings_heart: {
    name: "settings_heart",
    version: 229,
    popularity: 0,
    codepoint: 62754,
    categories: ["UI actions"],
    tags: [
      "application",
      "change",
      "details",
      "favorite",
      "gear",
      "heart",
      "info",
      "information",
      "like",
      "love",
      "options",
      "personal",
      "remember",
      "save",
      "service",
      "settings",
      "symbol",
    ],
  },
  settings_input_antenna: {
    name: "settings_input_antenna",
    version: 11,
    popularity: 9018,
    codepoint: 59583,
    categories: ["action"],
    tags: [
      "airplay",
      "antenna",
      "arrows",
      "cast",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "input",
      "internet",
      "network",
      "screencast",
      "settings",
      "stream",
      "wifi",
      "wireless",
    ],
  },
  settings_input_component: {
    name: "settings_input_component",
    version: 11,
    popularity: 8286,
    codepoint: 59584,
    categories: ["action"],
    tags: [
      "audio",
      "av",
      "cable",
      "cables",
      "component",
      "connect",
      "connection",
      "connectivity",
      "input",
      "internet",
      "plug",
      "points",
      "settings",
      "video",
      "wifi",
    ],
  },
  settings_input_hdmi: {
    name: "settings_input_hdmi",
    version: 11,
    popularity: 3578,
    codepoint: 59586,
    categories: ["action"],
    tags: [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "hdmi",
      "high",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "video",
      "wire",
    ],
  },
  settings_input_svideo: {
    name: "settings_input_svideo",
    version: 11,
    popularity: 2802,
    codepoint: 59587,
    categories: ["action"],
    tags: [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "standard",
      "svideo",
      "video",
    ],
  },
  settings_motion_mode: {
    name: "settings_motion_mode",
    version: 229,
    popularity: 12,
    codepoint: 63539,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "blur",
      "camera",
      "custom",
      "customize",
      "details",
      "feature",
      "gear",
      "image",
      "info",
      "information",
      "mode",
      "motion",
      "options",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings",
    ],
  },
  settings_night_sight: {
    name: "settings_night_sight",
    version: 229,
    popularity: 15,
    codepoint: 63538,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "camera",
      "custom",
      "customize",
      "dark",
      "details",
      "feature",
      "gear",
      "info",
      "information",
      "moon",
      "night",
      "options",
      "personal",
      "photo",
      "photography",
      "preferences",
      "service",
      "setting",
      "settings",
      "sight",
    ],
  },
  settings_overscan: {
    name: "settings_overscan",
    version: 11,
    popularity: 5148,
    codepoint: 59588,
    categories: ["action"],
    tags: ["arrows", "expand", "image", "photo", "picture", "scan", "settings"],
  },
  settings_panorama: {
    name: "settings_panorama",
    version: 229,
    popularity: 18,
    codepoint: 63537,
    categories: ["Photo&Image"],
    tags: [
      "angle",
      "application",
      "custom",
      "customize",
      "details",
      "gear",
      "image",
      "info",
      "information",
      "landscape",
      "mountain",
      "mountains",
      "options",
      "panorama",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings",
      "view",
      "vrpano",
      "wide",
    ],
  },
  settings_phone: {
    name: "settings_phone",
    version: 15,
    popularity: 15996,
    codepoint: 59589,
    categories: ["action"],
    tags: [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "settings",
      "telephone",
    ],
  },
  settings_photo_camera: {
    name: "settings_photo_camera",
    version: 229,
    popularity: 27,
    codepoint: 63540,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "camera",
      "custom",
      "customize",
      "details",
      "gear",
      "info",
      "information",
      "lens",
      "options",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings",
    ],
  },
  settings_power: {
    name: "settings_power",
    version: 11,
    popularity: 6417,
    codepoint: 59590,
    categories: ["action"],
    tags: [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown",
    ],
  },
  settings_remote: {
    name: "settings_remote",
    version: 11,
    popularity: 8001,
    codepoint: 59591,
    categories: ["action"],
    tags: [
      "bluetooth",
      "connection",
      "connectivity",
      "device",
      "remote",
      "settings",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  settings_slow_motion: {
    name: "settings_slow_motion",
    version: 229,
    popularity: 5,
    codepoint: 63011,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "arrow",
      "control",
      "controls",
      "custom",
      "customize",
      "dashed",
      "details",
      "gear",
      "info",
      "information",
      "motion",
      "music",
      "options",
      "personal",
      "play",
      "preferences",
      "service",
      "setting",
      "slow",
      "speed",
      "video",
    ],
  },
  settings_system_daydream: {
    name: "settings_system_daydream",
    version: 12,
    popularity: 2405,
    codepoint: 57795,
    categories: ["device"],
    tags: [
      "backup",
      "cloud",
      "daydream",
      "drive",
      "settings",
      "storage",
      "system",
    ],
  },
  settings_timelapse: {
    name: "settings_timelapse",
    version: 229,
    popularity: 17,
    codepoint: 63010,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "control",
      "custom",
      "customize",
      "details",
      "fast",
      "forward",
      "gear",
      "info",
      "information",
      "media",
      "music",
      "options",
      "personal",
      "play",
      "preferences",
      "service",
      "setting",
      "speed",
      "time",
      "tv",
      "video",
    ],
  },
  settings_video_camera: {
    name: "settings_video_camera",
    version: 229,
    popularity: 25,
    codepoint: 63009,
    categories: ["Photo&Image"],
    tags: [
      "application",
      "cam",
      "camera",
      "conference",
      "custom",
      "customize",
      "details",
      "film",
      "filming",
      "gear",
      "hardware",
      "image",
      "info",
      "information",
      "motion",
      "options",
      "personal",
      "picture",
      "preferences",
      "service",
      "setting",
      "video",
      "videography",
    ],
  },
  settings_voice: {
    name: "settings_voice",
    version: 11,
    popularity: 7429,
    codepoint: 59592,
    categories: ["action"],
    tags: [
      "mic",
      "microphone",
      "record",
      "recorder",
      "settings",
      "speaker",
      "voice",
    ],
  },
  settop_component: {
    name: "settop_component",
    version: 229,
    popularity: 233,
    codepoint: 58028,
    categories: ["Hardware"],
    tags: [
      "app",
      "application",
      "components",
      "interface",
      "nest",
      "screen",
      "settop",
      "website",
    ],
  },
  severe_cold: {
    name: "severe_cold",
    version: 1,
    popularity: 884,
    codepoint: 60371,
    categories: ["social"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "climate",
      "cold",
      "crisis",
      "danger",
      "disaster",
      "error",
      "exclamation",
      "important",
      "notification",
      "severe",
      "snow",
      "snowflake",
      "warning",
      "weather",
      "winter",
    ],
  },
  shadow: {
    name: "shadow",
    version: 229,
    popularity: 26,
    codepoint: 59871,
    categories: ["Common actions"],
    tags: [
      "adjustment",
      "edit",
      "editing",
      "effect",
      "image",
      "photo",
      "photography",
      "picture",
      "shadow",
    ],
  },
  shadow_add: {
    name: "shadow_add",
    version: 229,
    popularity: 0,
    codepoint: 62852,
    categories: ["Common actions"],
    tags: [
      "+",
      "add",
      "adjustment",
      "edit",
      "editing",
      "effect",
      "image",
      "new",
      "photo",
      "photography",
      "picture",
      "plus",
      "shadow",
    ],
  },
  shadow_minus: {
    name: "shadow_minus",
    version: 229,
    popularity: 1,
    codepoint: 62851,
    categories: ["Common actions"],
    tags: [
      "-",
      "adjustment",
      "delete",
      "edit",
      "editing",
      "effect",
      "image",
      "minus",
      "photo",
      "photography",
      "picture",
      "remove",
      "shadow",
      "subtract",
    ],
  },
  shape_line: {
    name: "shape_line",
    version: 1,
    popularity: 742,
    codepoint: 63699,
    categories: ["editor"],
    tags: ["circle", "draw", "edit", "editing", "line", "shape", "square"],
  },
  shapes: {
    name: "shapes",
    version: 229,
    popularity: 34,
    codepoint: 58882,
    categories: ["Text Formatting"],
    tags: ["circle", "draw", "edit", "editing", "shape", "shapes", "square"],
  },
  share: {
    name: "share",
    version: 18,
    popularity: 115279,
    codepoint: 59405,
    categories: ["social"],
    tags: [
      "DISABLE_IOS",
      "android",
      "connect",
      "contect",
      "disable_ios",
      "link",
      "media",
      "multimedia",
      "multiple",
      "network",
      "options",
      "share",
      "shared",
      "sharing",
      "social",
    ],
  },
  share_location: {
    name: "share_location",
    version: 17,
    popularity: 8562,
    codepoint: 61535,
    categories: ["device"],
    tags: [
      "dash",
      "dashed",
      "destination",
      "direction",
      "gps",
      "location",
      "maps",
      "pin",
      "place",
      "share",
      "stop",
      "tracking",
    ],
  },
  share_off: {
    name: "share_off",
    version: 229,
    popularity: 18,
    codepoint: 63179,
    categories: ["Social"],
    tags: [
      "android",
      "cancel",
      "clear",
      "close",
      "connect",
      "contect",
      "disabled",
      "link",
      "media",
      "multimedia",
      "multiple",
      "network",
      "off",
      "options",
      "remove",
      "share",
      "shared",
      "sharing",
      "social",
      "stop",
      "x",
    ],
  },
  share_reviews: {
    name: "share_reviews",
    version: 229,
    popularity: 762,
    codepoint: 63652,
    categories: ["UI actions"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "review",
      "reviews",
      "share",
      "speech",
    ],
  },
  share_windows: {
    name: "share_windows",
    version: 229,
    popularity: 28,
    codepoint: 62995,
    categories: ["UI actions"],
    tags: ["arrow", "export", "move", "right", "send"],
  },
  sheets_rtl: {
    name: "sheets_rtl",
    version: 229,
    popularity: 3,
    codepoint: 63523,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "back",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "left",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "switch",
      "text",
      "writing",
    ],
  },
  shelf_auto_hide: {
    name: "shelf_auto_hide",
    version: 229,
    popularity: 6,
    codepoint: 63235,
    categories: ["UI actions"],
    tags: ["close", "dots", "dotted", "layout", "ui", "window"],
  },
  shelf_position: {
    name: "shelf_position",
    version: 229,
    popularity: 18,
    codepoint: 63234,
    categories: ["UI actions"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking",
    ],
  },
  shelves: {
    name: "shelves",
    version: 1,
    popularity: 1696,
    codepoint: 63598,
    categories: ["home"],
    tags: [
      "box",
      "factory",
      "furniture",
      "home",
      "manufactory",
      "shelf",
      "storage",
    ],
  },
  shield: {
    name: "shield",
    version: 11,
    popularity: 25323,
    codepoint: 59872,
    categories: ["content"],
    tags: [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified",
    ],
  },
  shield_lock: {
    name: "shield_lock",
    version: 229,
    popularity: 121,
    codepoint: 63110,
    categories: ["Privacy&Security"],
    tags: [
      "locked",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
    ],
  },
  shield_locked: {
    name: "shield_locked",
    version: 229,
    popularity: 18,
    codepoint: 62866,
    categories: ["Privacy&Security"],
    tags: [
      "locked",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
    ],
  },
  shield_moon: {
    name: "shield_moon",
    version: 2,
    popularity: 2719,
    codepoint: 60073,
    categories: ["home"],
    tags: [
      "certified",
      "do not disturb",
      "moon",
      "night",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified",
    ],
  },
  shield_person: {
    name: "shield_person",
    version: 229,
    popularity: 82,
    codepoint: 63056,
    categories: ["Privacy&Security"],
    tags: [
      "account",
      "admin",
      "avatar",
      "certified",
      "face",
      "human",
      "panel",
      "people",
      "person",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
      "user",
      "verified",
    ],
  },
  shield_question: {
    name: "shield_question",
    version: 229,
    popularity: 0,
    codepoint: 62761,
    categories: ["Privacy&Security"],
    tags: [
      "?",
      "certified",
      "encryption",
      "help",
      "info",
      "information",
      "privacy",
      "private",
      "protect",
      "protection",
      "question",
      "question mark",
      "safe search",
      "search",
      "security",
      "shield",
      "support",
      "symbol",
      "verified",
    ],
  },
  shield_with_heart: {
    name: "shield_with_heart",
    version: 229,
    popularity: 738,
    codepoint: 59279,
    categories: ["Home"],
    tags: ["heart", "home", "nest", "security", "shield"],
  },
  shield_with_house: {
    name: "shield_with_house",
    version: 229,
    popularity: 189,
    codepoint: 59277,
    categories: ["Home"],
    tags: ["home", "house", "nest", "security", "shield"],
  },
  shift: {
    name: "shift",
    version: 229,
    popularity: 15,
    codepoint: 58866,
    categories: ["Common actions"],
    tags: ["arrow", "function", "key", "type", "up"],
  },
  shift_lock: {
    name: "shift_lock",
    version: 229,
    popularity: 2,
    codepoint: 63406,
    categories: ["Common actions"],
    tags: ["arrow", "function", "key", "type", "up"],
  },
  shop: {
    name: "shop",
    version: 12,
    popularity: 9029,
    codepoint: 59593,
    categories: ["action"],
    tags: [
      "bag",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "google",
      "money",
      "online",
      "pay",
      "payment",
      "play",
      "shop",
      "shopping",
      "store",
    ],
  },
  shop_two: {
    name: "shop_two",
    version: 11,
    popularity: 3994,
    codepoint: 59594,
    categories: ["action"],
    tags: [
      "add",
      "arrow",
      "buy",
      "cart",
      "google",
      "play",
      "purchase",
      "shop",
      "shopping",
      "two",
    ],
  },
  shopping_bag: {
    name: "shopping_bag",
    version: 9,
    popularity: 92753,
    codepoint: 61900,
    categories: ["action"],
    tags: [
      "bag",
      "bill",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront",
    ],
  },
  shopping_basket: {
    name: "shopping_basket",
    version: 12,
    popularity: 42904,
    codepoint: 59595,
    categories: ["action"],
    tags: [
      "add",
      "basket",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
    ],
  },
  shopping_cart: {
    name: "shopping_cart",
    version: 18,
    popularity: 293471,
    codepoint: 59596,
    categories: ["action"],
    tags: [
      "add",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
    ],
  },
  shopping_cart_checkout: {
    name: "shopping_cart_checkout",
    version: 1,
    popularity: 9232,
    codepoint: 60296,
    categories: ["action"],
    tags: [
      "arrow",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "right",
      "shopping",
    ],
  },
  shoppingmode: {
    name: "shoppingmode",
    version: 229,
    popularity: 238,
    codepoint: 61367,
    categories: ["Business&Payments"],
    tags: [
      "clothing",
      "ecommerce",
      "mode",
      "price",
      "shopping",
      "shoppingmode",
      "tag",
    ],
  },
  short_stay: {
    name: "short_stay",
    version: 229,
    popularity: 4,
    codepoint: 58576,
    categories: ["Social"],
    tags: [
      "bed",
      "body",
      "clinic",
      "clock",
      "date",
      "health",
      "hospital",
      "human",
      "patient",
      "people",
      "person",
      "schedule",
      "time",
    ],
  },
  short_text: {
    name: "short_text",
    version: 15,
    popularity: 3440,
    codepoint: 57953,
    categories: ["editor"],
    tags: [
      "brief",
      "comment",
      "doc",
      "document",
      "note",
      "short",
      "text",
      "write",
      "writing",
    ],
  },
  show_chart: {
    name: "show_chart",
    version: 13,
    popularity: 24953,
    codepoint: 59105,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "presentation",
      "show chart",
      "statistics",
      "tracking",
    ],
  },
  shower: {
    name: "shower",
    version: 9,
    popularity: 9732,
    codepoint: 61537,
    categories: ["search"],
    tags: [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc",
    ],
  },
  shuffle: {
    name: "shuffle",
    version: 14,
    popularity: 12394,
    codepoint: 57411,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "random",
      "shuffle",
      "video",
    ],
  },
  shuffle_on: {
    name: "shuffle_on",
    version: 12,
    popularity: 2529,
    codepoint: 59873,
    categories: ["av"],
    tags: [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "on",
      "random",
      "shuffle",
      "video",
    ],
  },
  shutter_speed: {
    name: "shutter_speed",
    version: 11,
    popularity: 2608,
    codepoint: 58429,
    categories: ["image"],
    tags: [
      "aperture",
      "camera",
      "duration",
      "image",
      "lens",
      "photo",
      "photography",
      "photos",
      "picture",
      "setting",
      "shutter",
      "speed",
      "stop",
      "time",
      "timer",
      "watch",
    ],
  },
  shutter_speed_add: {
    name: "shutter_speed_add",
    version: 229,
    popularity: 4,
    codepoint: 62846,
    categories: ["Photo&Image"],
    tags: [
      "+",
      "add",
      "aperture",
      "camera",
      "duration",
      "image",
      "lens",
      "new",
      "photo",
      "photography",
      "photos",
      "picture",
      "plus",
      "setting",
      "shutter",
      "speed",
      "stop",
      "time",
      "timer",
      "watch",
    ],
  },
  shutter_speed_minus: {
    name: "shutter_speed_minus",
    version: 229,
    popularity: 2,
    codepoint: 62845,
    categories: ["Photo&Image"],
    tags: [
      "-",
      "aperture",
      "camera",
      "delete",
      "duration",
      "image",
      "lens",
      "minus",
      "photo",
      "photography",
      "photos",
      "picture",
      "remove",
      "setting",
      "shutter",
      "speed",
      "stop",
      "subtract",
      "time",
      "timer",
      "watch",
    ],
  },
  sick: {
    name: "sick",
    version: 6,
    popularity: 6045,
    codepoint: 61984,
    categories: ["social"],
    tags: [
      "covid",
      "discomfort",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fever",
      "flu",
      "ill",
      "mood",
      "pain",
      "person",
      "sick",
      "survey",
      "upset",
    ],
  },
  side_navigation: {
    name: "side_navigation",
    version: 229,
    popularity: 75,
    codepoint: 59874,
    categories: ["UI actions"],
    tags: [
      "components",
      "design",
      "half",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "navigation",
      "phone",
      "screen",
      "side",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  sign_language: {
    name: "sign_language",
    version: 1,
    popularity: 1321,
    codepoint: 60389,
    categories: ["social"],
    tags: [
      "communication",
      "deaf",
      "fingers",
      "gesture",
      "hand",
      "language",
      "sign",
    ],
  },
  signal_cellular_0_bar: {
    name: "signal_cellular_0_bar",
    version: 15,
    popularity: 2057,
    codepoint: 61608,
    categories: ["device"],
    tags: [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_1_bar: {
    name: "signal_cellular_1_bar",
    version: 11,
    popularity: 15,
    codepoint: 61609,
    categories: ["device"],
    tags: [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_2_bar: {
    name: "signal_cellular_2_bar",
    version: 12,
    popularity: 18,
    codepoint: 61610,
    categories: ["device"],
    tags: [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_3_bar: {
    name: "signal_cellular_3_bar",
    version: 12,
    popularity: 15,
    codepoint: 61611,
    categories: ["device"],
    tags: [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_4_bar: {
    name: "signal_cellular_4_bar",
    version: 12,
    popularity: 5347,
    codepoint: 57800,
    categories: ["device"],
    tags: [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_add: {
    name: "signal_cellular_add",
    version: 229,
    popularity: 10,
    codepoint: 63401,
    categories: ["Communication"],
    tags: [
      "+",
      "add",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "new",
      "phone",
      "plus",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_alt: {
    name: "signal_cellular_alt",
    version: 12,
    popularity: 31711,
    codepoint: 57858,
    categories: ["device"],
    tags: [
      "alt",
      "analytics",
      "bar",
      "cell",
      "cellular",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "internet",
      "measure",
      "metrics",
      "mobile",
      "network",
      "phone",
      "signal",
      "statistics",
      "tracking",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_alt_1_bar: {
    name: "signal_cellular_alt_1_bar",
    version: 1,
    popularity: 878,
    codepoint: 60383,
    categories: ["device"],
    tags: [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_alt_2_bar: {
    name: "signal_cellular_alt_2_bar",
    version: 1,
    popularity: 881,
    codepoint: 60387,
    categories: ["device"],
    tags: [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_connected_no_internet_0_bar: {
    name: "signal_cellular_connected_no_internet_0_bar",
    version: 13,
    popularity: 1549,
    codepoint: 61612,
    categories: ["device"],
    tags: [
      "!",
      "0",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_connected_no_internet_4_bar: {
    name: "signal_cellular_connected_no_internet_4_bar",
    version: 13,
    popularity: 1741,
    codepoint: 57805,
    categories: ["device"],
    tags: [
      "!",
      "4",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_nodata: {
    name: "signal_cellular_nodata",
    version: 10,
    popularity: 1250,
    codepoint: 61538,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "clear",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "nodata",
      "offline",
      "phone",
      "quit",
      "remove",
      "signal",
      "wifi",
      "wireless",
      "x",
    ],
  },
  signal_cellular_null: {
    name: "signal_cellular_null",
    version: 12,
    popularity: 1190,
    codepoint: 57807,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_off: {
    name: "signal_cellular_off",
    version: 12,
    popularity: 1243,
    codepoint: 57808,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "signal",
      "slash",
      "wifi",
      "wireless",
    ],
  },
  signal_cellular_pause: {
    name: "signal_cellular_pause",
    version: 229,
    popularity: 0,
    codepoint: 62887,
    categories: ["Android"],
    tags: [
      "cell",
      "cellular",
      "data",
      "end",
      "internet",
      "mobile",
      "network",
      "phone",
      "rest",
      "signal",
      "speed",
      "stop",
      "wifi",
      "wireless",
    ],
  },
  signal_disconnected: {
    name: "signal_disconnected",
    version: 229,
    popularity: 1024,
    codepoint: 62009,
    categories: ["Android"],
    tags: [
      "connection",
      "disconnected",
      "internet",
      "network",
      "signal",
      "updates",
      "wifi",
      "wireless",
    ],
  },
  signal_wifi_0_bar: {
    name: "signal_wifi_0_bar",
    version: 15,
    popularity: 2884,
    codepoint: 61616,
    categories: ["device"],
    tags: [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  signal_wifi_4_bar: {
    name: "signal_wifi_4_bar",
    version: 12,
    popularity: 6688,
    codepoint: 57816,
    categories: ["device"],
    tags: [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  signal_wifi_bad: {
    name: "signal_wifi_bad",
    version: 10,
    popularity: 2327,
    codepoint: 61539,
    categories: ["device"],
    tags: [
      "bad",
      "bar",
      "cancel",
      "cell",
      "cellular",
      "clear",
      "close",
      "data",
      "exit",
      "internet",
      "mobile",
      "network",
      "no",
      "phone",
      "quit",
      "remove",
      "signal",
      "stop",
      "wifi",
      "wireless",
      "x",
    ],
  },
  signal_wifi_off: {
    name: "signal_wifi_off",
    version: 17,
    popularity: 2498,
    codepoint: 57818,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "phone",
      "signal",
      "slash",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  signal_wifi_statusbar_not_connected: {
    name: "signal_wifi_statusbar_not_connected",
    version: 9,
    popularity: 8,
    codepoint: 61679,
    categories: ["device"],
    tags: [
      "?",
      "assistance",
      "cell",
      "cellular",
      "connected",
      "data",
      "help",
      "info",
      "information",
      "internet",
      "mobile",
      "network",
      "no",
      "not",
      "phone",
      "punctuation",
      "question mark",
      "signal",
      "speed",
      "statusbar",
      "support",
      "symbol",
      "wifi",
      "wireless",
    ],
  },
  signal_wifi_statusbar_null: {
    name: "signal_wifi_statusbar_null",
    version: 9,
    popularity: 1949,
    codepoint: 61543,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless",
    ],
  },
  signature: {
    name: "signature",
    version: 229,
    popularity: 71,
    codepoint: 63308,
    categories: ["Text Formatting"],
    tags: [
      "authorize",
      "autograph",
      "cursive",
      "name",
      "sign",
      "signatory",
      "signing",
    ],
  },
  signpost: {
    name: "signpost",
    version: 1,
    popularity: 2961,
    codepoint: 60305,
    categories: ["maps"],
    tags: [
      "arrow",
      "direction",
      "left",
      "maps",
      "right",
      "signal",
      "signs",
      "street",
      "traffic",
    ],
  },
  sim_card: {
    name: "sim_card",
    version: 12,
    popularity: 3969,
    codepoint: 58155,
    categories: ["hardware"],
    tags: [
      "camera",
      "card",
      "chip",
      "device",
      "memory",
      "phone",
      "sim",
      "storage",
    ],
  },
  sim_card_download: {
    name: "sim_card_download",
    version: 9,
    popularity: 4800,
    codepoint: 61544,
    categories: ["device"],
    tags: [
      "arrow",
      "camera",
      "card",
      "chip",
      "device",
      "down",
      "download",
      "memory",
      "phone",
      "sim",
      "storage",
    ],
  },
  single_bed: {
    name: "single_bed",
    version: 11,
    popularity: 3316,
    codepoint: 59976,
    categories: ["social"],
    tags: [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "single",
      "sleep",
      "twin",
    ],
  },
  sip: {
    name: "sip",
    version: 10,
    popularity: 1363,
    codepoint: 61545,
    categories: ["communication"],
    tags: [
      "alphabet",
      "call",
      "character",
      "dialer",
      "font",
      "initiation",
      "internet",
      "letter",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "text",
      "type",
      "voice",
    ],
  },
  skateboarding: {
    name: "skateboarding",
    version: 4,
    popularity: 3245,
    codepoint: 58641,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "skate",
      "skateboarder",
      "skateboarding",
      "social",
      "sports",
    ],
  },
  skeleton: {
    name: "skeleton",
    version: 229,
    popularity: 205,
    codepoint: 63641,
    categories: ["Social"],
    tags: ["body", "bone", "bones", "cage", "health", "hip", "medical", "rib"],
  },
  skillet: {
    name: "skillet",
    version: 229,
    popularity: 4,
    codepoint: 62787,
    categories: ["Household"],
    tags: [
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "food",
      "fry pan",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "oven",
      "pan",
      "pot",
      "saucepan",
      "stockpot",
      "stove",
      "stovetop",
    ],
  },
  skillet_cooktop: {
    name: "skillet_cooktop",
    version: 229,
    popularity: 1,
    codepoint: 62788,
    categories: ["Household"],
    tags: [
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "food",
      "fry pan",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "oven",
      "pan",
      "pot",
      "saucepan",
      "stockpot",
      "stove",
      "stovetop",
    ],
  },
  skip_next: {
    name: "skip_next",
    version: 16,
    popularity: 31141,
    codepoint: 57412,
    categories: ["av"],
    tags: [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video",
    ],
  },
  skip_previous: {
    name: "skip_previous",
    version: 16,
    popularity: 22577,
    codepoint: 57413,
    categories: ["av"],
    tags: [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video",
    ],
  },
  skull: {
    name: "skull",
    version: 229,
    popularity: 1065,
    codepoint: 63642,
    categories: ["Social"],
    tags: [
      "body",
      "bone",
      "bones",
      "cranium",
      "head",
      "health",
      "medical",
      "skeleton",
    ],
  },
  sledding: {
    name: "sledding",
    version: 5,
    popularity: 1730,
    codepoint: 58642,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sled",
      "sledding",
      "sledge",
      "snow",
      "social",
      "sports",
      "travel",
      "winter",
    ],
  },
  sleep_score: {
    name: "sleep_score",
    version: 229,
    popularity: 5,
    codepoint: 63159,
    categories: ["Activities"],
    tags: [
      "crescent",
      "dark",
      "lunar",
      "moon",
      "night",
      "nighttime",
      "stat",
      "stats",
    ],
  },
  slide_library: {
    name: "slide_library",
    version: 229,
    popularity: 68,
    codepoint: 63522,
    categories: ["Text Formatting"],
    tags: ["rectangle", "slides"],
  },
  sliders: {
    name: "sliders",
    version: 229,
    popularity: 22,
    codepoint: 59875,
    categories: ["UI actions"],
    tags: ["button", "half", "sliders"],
  },
  slideshow: {
    name: "slideshow",
    version: 12,
    popularity: 9211,
    codepoint: 58395,
    categories: ["image"],
    tags: ["movie", "photos", "play", "slideshow", "square", "video", "view"],
  },
  slow_motion_video: {
    name: "slow_motion_video",
    version: 11,
    popularity: 3539,
    codepoint: 57448,
    categories: ["av"],
    tags: [
      "arrow",
      "control",
      "controls",
      "dash",
      "dashed",
      "motion",
      "music",
      "play",
      "slow",
      "speed",
      "video",
    ],
  },
  smart_display: {
    name: "smart_display",
    version: 9,
    popularity: 25979,
    codepoint: 61546,
    categories: ["hardware"],
    tags: [
      "airplay",
      "cast",
      "chrome",
      "connect",
      "device",
      "display",
      "play",
      "screen",
      "screencast",
      "smart",
      "stream",
      "television",
      "tv",
      "video",
      "wireless",
    ],
  },
  smart_outlet: {
    name: "smart_outlet",
    version: 229,
    popularity: 236,
    codepoint: 59460,
    categories: ["Household"],
    tags: [
      "electronic",
      "home",
      "house",
      "nest",
      "outlet",
      "power",
      "smart",
      "wireless",
    ],
  },
  smart_screen: {
    name: "smart_screen",
    version: 9,
    popularity: 1345,
    codepoint: 61547,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "airplay",
      "cast",
      "cell",
      "connect",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screencast",
      "smart",
      "stream",
      "tablet",
      "video",
    ],
  },
  smart_toy: {
    name: "smart_toy",
    version: 9,
    popularity: 17452,
    codepoint: 61548,
    categories: ["hardware"],
    tags: ["bot", "droid", "games", "robot", "smart", "toy"],
  },
  smartphone: {
    name: "smartphone",
    version: 16,
    popularity: 43636,
    codepoint: 58156,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "call",
      "cell",
      "chat",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "smartphone",
      "tablet",
      "text",
    ],
  },
  smb_share: {
    name: "smb_share",
    version: 229,
    popularity: 19,
    codepoint: 63307,
    categories: ["Text Formatting"],
    tags: [
      "cloud",
      "connection",
      "content",
      "copy",
      "cut",
      "data",
      "doc",
      "document",
      "drive",
      "duplicate",
      "file",
      "folder",
      "folders",
      "internet",
      "multiple",
      "network",
      "paste",
      "server",
      "sheet",
      "sky",
      "slide",
      "stack",
      "storage",
      "upload",
    ],
  },
  smoke_free: {
    name: "smoke_free",
    version: 12,
    popularity: 2602,
    codepoint: 60234,
    categories: ["places"],
    tags: [
      "cigarette",
      "disabled",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "warning",
      "zone",
    ],
  },
  smoking_rooms: {
    name: "smoking_rooms",
    version: 12,
    popularity: 3270,
    codepoint: 60235,
    categories: ["places"],
    tags: [
      "allowed",
      "cigarette",
      "places",
      "rooms",
      "smoke",
      "smoking",
      "tobacco",
      "zone",
    ],
  },
  sms: {
    name: "sms",
    version: 12,
    popularity: 20469,
    codepoint: 58917,
    categories: ["notification"],
    tags: [
      "3",
      "bubble",
      "chat",
      "communication",
      "conversation",
      "dots",
      "message",
      "more",
      "service",
      "sms",
      "speech",
      "three",
    ],
  },
  snippet_folder: {
    name: "snippet_folder",
    version: 7,
    popularity: 3636,
    codepoint: 61895,
    categories: ["file"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "snippet",
      "storage",
    ],
  },
  snooze: {
    name: "snooze",
    version: 12,
    popularity: 3098,
    codepoint: 57414,
    categories: ["av"],
    tags: [
      "alarm",
      "bell",
      "clock",
      "duration",
      "notification",
      "snooze",
      "time",
      "timer",
      "watch",
      "z",
    ],
  },
  snowboarding: {
    name: "snowboarding",
    version: 4,
    popularity: 2317,
    codepoint: 58643,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "snow",
      "snowboarding",
      "social",
      "sports",
      "travel",
      "winter",
    ],
  },
  snowing: {
    name: "snowing",
    version: 3,
    popularity: 619,
    codepoint: 59407,
    categories: ["home"],
    tags: ["climate", "cold", "snow", "temperature", "weather", "winter"],
  },
  snowing_heavy: {
    name: "snowing_heavy",
    version: 229,
    popularity: 4,
    codepoint: 63004,
    categories: ["Social"],
    tags: ["climate", "cold", "snow", "temperature", "weather", "winter"],
  },
  snowmobile: {
    name: "snowmobile",
    version: 4,
    popularity: 1263,
    codepoint: 58627,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "direction",
      "skimobile",
      "snow",
      "snowmobile",
      "social",
      "sports",
      "transportation",
      "travel",
      "vehicle",
      "winter",
    ],
  },
  snowshoeing: {
    name: "snowshoeing",
    version: 4,
    popularity: 2045,
    codepoint: 58644,
    categories: ["social"],
    tags: [
      "body",
      "human",
      "people",
      "person",
      "snow",
      "snowshoe",
      "snowshoeing",
      "sports",
      "travel",
      "walking",
      "winter",
    ],
  },
  soap: {
    name: "soap",
    version: 8,
    popularity: 2785,
    codepoint: 61874,
    categories: ["places"],
    tags: [
      "bathroom",
      "clean",
      "fingers",
      "gesture",
      "hand",
      "soap",
      "wash",
      "wc",
    ],
  },
  social_distance: {
    name: "social_distance",
    version: 7,
    popularity: 5175,
    codepoint: 57803,
    categories: ["social"],
    tags: [
      "6",
      "apart",
      "body",
      "distance",
      "ft",
      "human",
      "people",
      "person",
      "social",
      "space",
    ],
  },
  social_leaderboard: {
    name: "social_leaderboard",
    version: 229,
    popularity: 72,
    codepoint: 63136,
    categories: ["Social"],
    tags: [
      "award",
      "medallion",
      "medals",
      "reward",
      "ribbon",
      "ribbons",
      "star",
    ],
  },
  solar_power: {
    name: "solar_power",
    version: 1,
    popularity: 2851,
    codepoint: 60431,
    categories: ["home"],
    tags: ["eco", "energy", "heat", "nest", "power", "solar", "sun", "sunny"],
  },
  sort: {
    name: "sort",
    version: 13,
    popularity: 48583,
    codepoint: 57700,
    categories: ["content"],
    tags: ["filter", "find", "lines", "list", "organize", "sort"],
  },
  sort_by_alpha: {
    name: "sort_by_alpha",
    version: 11,
    popularity: 11186,
    codepoint: 57427,
    categories: ["av"],
    tags: [
      "alphabet",
      "alphabetize",
      "az",
      "by alpha",
      "character",
      "font",
      "letter",
      "list",
      "order",
      "organize",
      "sort",
      "symbol",
      "text",
      "type",
    ],
  },
  sos: {
    name: "sos",
    version: 1,
    popularity: 1034,
    codepoint: 60407,
    categories: ["maps"],
    tags: ["font", "help", "letters", "save", "sos", "text", "type"],
  },
  sound_detection_dog_barking: {
    name: "sound_detection_dog_barking",
    version: 229,
    popularity: 1521,
    codepoint: 61769,
    categories: ["Audio&Video"],
    tags: [
      "animal",
      "barking",
      "detection",
      "dog",
      "hound",
      "loyalty",
      "pet",
      "protection",
      "security",
      "sound",
    ],
  },
  sound_detection_glass_break: {
    name: "sound_detection_glass_break",
    version: 229,
    popularity: 188,
    codepoint: 61770,
    categories: ["Audio&Video"],
    tags: ["break", "broken", "detection", "glass", "shatter", "sound"],
  },
  sound_detection_loud_sound: {
    name: "sound_detection_loud_sound",
    version: 229,
    popularity: 656,
    codepoint: 61771,
    categories: ["Audio&Video"],
    tags: ["audio", "detection", "loud", "music", "sound", "volume"],
  },
  sound_sampler: {
    name: "sound_sampler",
    version: 229,
    popularity: 2,
    codepoint: 63156,
    categories: ["Audio&Video"],
    tags: ["control", "controls", "device", "music", "play"],
  },
  soup_kitchen: {
    name: "soup_kitchen",
    version: 2,
    popularity: 3016,
    codepoint: 59347,
    categories: ["maps"],
    tags: [
      "breakfast",
      "brunch",
      "dining",
      "food",
      "kitchen",
      "lunch",
      "meal",
      "soup",
    ],
  },
  source_environment: {
    name: "source_environment",
    version: 229,
    popularity: 122,
    codepoint: 58663,
    categories: ["Business&Payments"],
    tags: [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "environment",
      "estate",
      "home",
      "house",
      "maps",
      "office",
      "place",
      "real",
      "residence",
      "residential",
      "source",
    ],
  },
  source_notes: {
    name: "source_notes",
    version: 229,
    popularity: 17,
    codepoint: 57645,
    categories: ["Text Formatting"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "direction",
      "doc",
      "document",
      "file",
      "health",
      "inprogress",
      "left",
      "load",
      "loading refresh",
      "navigation",
      "note",
      "page",
      "paper",
      "renew",
      "rotate",
      "turn",
    ],
  },
  south: {
    name: "south",
    version: 7,
    popularity: 19160,
    codepoint: 61923,
    categories: ["navigation"],
    tags: ["arrow", "directional", "down", "maps", "navigation", "south"],
  },
  south_america: {
    name: "south_america",
    version: 2,
    popularity: 1919,
    codepoint: 59364,
    categories: ["social"],
    tags: ["continent", "landscape", "place", "region", "south america"],
  },
  south_east: {
    name: "south_east",
    version: 7,
    popularity: 5507,
    codepoint: 61924,
    categories: ["navigation"],
    tags: [
      "arrow",
      "directional",
      "down",
      "east",
      "maps",
      "navigation",
      "right",
      "south",
    ],
  },
  south_west: {
    name: "south_west",
    version: 7,
    popularity: 4102,
    codepoint: 61925,
    categories: ["navigation"],
    tags: [
      "arrow",
      "directional",
      "down",
      "left",
      "maps",
      "navigation",
      "south",
      "west",
    ],
  },
  spa: {
    name: "spa",
    version: 12,
    popularity: 21536,
    codepoint: 60236,
    categories: ["places"],
    tags: [
      "aromatherapy",
      "flower",
      "healthcare",
      "leaf",
      "massage",
      "meditation",
      "nature",
      "petals",
      "places",
      "relax",
      "spa",
      "wellbeing",
      "wellness",
    ],
  },
  space_bar: {
    name: "space_bar",
    version: 11,
    popularity: 2284,
    codepoint: 57942,
    categories: ["editor"],
    tags: ["bar", "keyboard", "line", "space"],
  },
  space_dashboard: {
    name: "space_dashboard",
    version: 3,
    popularity: 28814,
    codepoint: 58987,
    categories: ["action"],
    tags: [
      "cards",
      "dashboard",
      "format",
      "grid",
      "layout",
      "rectangle",
      "shapes",
      "space",
      "squares",
      "web",
      "website",
    ],
  },
  spatial_audio: {
    name: "spatial_audio",
    version: 1,
    popularity: 1058,
    codepoint: 60395,
    categories: ["action"],
    tags: ["audio", "music", "note", "sound", "spatial"],
  },
  spatial_audio_off: {
    name: "spatial_audio_off",
    version: 1,
    popularity: 1940,
    codepoint: 60392,
    categories: ["action"],
    tags: [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial",
    ],
  },
  spatial_tracking: {
    name: "spatial_tracking",
    version: 1,
    popularity: 1127,
    codepoint: 60394,
    categories: ["action"],
    tags: [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial",
      "tracking",
    ],
  },
  speaker: {
    name: "speaker",
    version: 16,
    popularity: 4398,
    codepoint: 58157,
    categories: ["hardware"],
    tags: [
      "box",
      "electronic",
      "loud",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video",
    ],
  },
  speaker_group: {
    name: "speaker_group",
    version: 12,
    popularity: 2020,
    codepoint: 58158,
    categories: ["hardware"],
    tags: [
      "box",
      "electronic",
      "group",
      "loud",
      "multiple",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video",
    ],
  },
  speaker_notes: {
    name: "speaker_notes",
    version: 13,
    popularity: 15314,
    codepoint: 59597,
    categories: ["action"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "format",
      "list",
      "message",
      "notes",
      "speaker",
      "speech",
      "text",
    ],
  },
  speaker_notes_off: {
    name: "speaker_notes_off",
    version: 13,
    popularity: 3771,
    codepoint: 59690,
    categories: ["action"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "disabled",
      "enabled",
      "format",
      "list",
      "message",
      "notes",
      "off",
      "on",
      "slash",
      "speaker",
      "speech",
      "text",
    ],
  },
  speaker_phone: {
    name: "speaker_phone",
    version: 15,
    popularity: 2231,
    codepoint: 57554,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "sound",
      "speaker",
      "tablet",
      "volume",
    ],
  },
  special_character: {
    name: "special_character",
    version: 229,
    popularity: 5,
    codepoint: 63306,
    categories: ["Text Formatting"],
    tags: ["characters", "symbol", "type"],
  },
  specific_gravity: {
    name: "specific_gravity",
    version: 229,
    popularity: 123,
    codepoint: 63602,
    categories: ["Social"],
    tags: ["cup", "density", "gravity", "specific", "water"],
  },
  speech_to_text: {
    name: "speech_to_text",
    version: 229,
    popularity: 386,
    codepoint: 63655,
    categories: ["Audio&Video"],
    tags: [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "doc",
      "document",
      "feedback",
      "file",
      "message",
      "mic",
      "microphone",
      "page",
      "paper",
      "speech",
      "text",
    ],
  },
  speed: {
    name: "speed",
    version: 12,
    popularity: 28844,
    codepoint: 59876,
    categories: ["av"],
    tags: [
      "arrow",
      "control",
      "controls",
      "fast",
      "gauge",
      "meter",
      "motion",
      "music",
      "slow",
      "speed",
      "speedometer",
      "velocity",
      "video",
    ],
  },
  spellcheck: {
    name: "spellcheck",
    version: 12,
    popularity: 5440,
    codepoint: 59598,
    categories: ["action"],
    tags: [
      "a",
      "alphabet",
      "approve",
      "character",
      "check",
      "font",
      "letter",
      "mark",
      "ok",
      "processor",
      "select",
      "spell",
      "spellcheck",
      "symbol",
      "text",
      "tick",
      "type",
      "word",
      "write",
      "yes",
    ],
  },
  splitscreen: {
    name: "splitscreen",
    version: 11,
    popularity: 4976,
    codepoint: 61549,
    categories: ["device"],
    tags: [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two",
    ],
  },
  splitscreen_bottom: {
    name: "splitscreen_bottom",
    version: 229,
    popularity: 2,
    codepoint: 63094,
    categories: ["Android"],
    tags: [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two",
    ],
  },
  splitscreen_left: {
    name: "splitscreen_left",
    version: 229,
    popularity: 12,
    codepoint: 63093,
    categories: ["Android"],
    tags: [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two",
    ],
  },
  splitscreen_right: {
    name: "splitscreen_right",
    version: 229,
    popularity: 12,
    codepoint: 63092,
    categories: ["Android"],
    tags: [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two",
    ],
  },
  splitscreen_top: {
    name: "splitscreen_top",
    version: 229,
    popularity: 2,
    codepoint: 63091,
    categories: ["Android"],
    tags: [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two",
    ],
  },
  spo2: {
    name: "spo2",
    version: 229,
    popularity: 19,
    codepoint: 63195,
    categories: ["Activities"],
    tags: ["blood", "fitbit", "health", "oxygen", "oxygen saturation"],
  },
  spoke: {
    name: "spoke",
    version: 2,
    popularity: 2213,
    codepoint: 59815,
    categories: ["communication"],
    tags: ["connection", "network", "radius", "spoke"],
  },
  sports: {
    name: "sports",
    version: 11,
    popularity: 6237,
    codepoint: 59952,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "blowing",
      "coach",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "instrument",
      "referee",
      "social",
      "sound",
      "sports",
      "warning",
      "whistle",
    ],
  },
  sports_and_outdoors: {
    name: "sports_and_outdoors",
    version: 229,
    popularity: 53,
    codepoint: 61368,
    categories: ["Activities"],
    tags: [
      "and outdoors",
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "hobby",
      "soccer",
      "sports",
    ],
  },
  sports_bar: {
    name: "sports_bar",
    version: 7,
    popularity: 6498,
    codepoint: 61939,
    categories: ["places"],
    tags: [
      "alcohol",
      "bar",
      "beer",
      "drink",
      "liquor",
      "pint",
      "places",
      "pub",
      "sports",
    ],
  },
  sports_baseball: {
    name: "sports_baseball",
    version: 11,
    popularity: 4238,
    codepoint: 59985,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "baseball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_basketball: {
    name: "sports_basketball",
    version: 11,
    popularity: 7168,
    codepoint: 59942,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "basketball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_cricket: {
    name: "sports_cricket",
    version: 11,
    popularity: 2799,
    codepoint: 59943,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "cricket",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_esports: {
    name: "sports_esports",
    version: 11,
    popularity: 29583,
    codepoint: 59944,
    categories: ["social"],
    tags: [
      "controller",
      "entertainment",
      "esports",
      "game",
      "gamepad",
      "gaming",
      "hobby",
      "online",
      "social",
      "sports",
      "video",
    ],
  },
  sports_football: {
    name: "sports_football",
    version: 12,
    popularity: 3659,
    codepoint: 59945,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_golf: {
    name: "sports_golf",
    version: 11,
    popularity: 2571,
    codepoint: 59946,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "club",
      "entertainment",
      "exercise",
      "game",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_gymnastics: {
    name: "sports_gymnastics",
    version: 1,
    popularity: 2256,
    codepoint: 60356,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "gymnastics",
      "hobby",
      "social",
      "sports",
    ],
  },
  sports_handball: {
    name: "sports_handball",
    version: 11,
    popularity: 4347,
    codepoint: 59955,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "body",
      "entertainment",
      "exercise",
      "game",
      "handball",
      "hobby",
      "human",
      "people",
      "person",
      "social",
      "sports",
    ],
  },
  sports_hockey: {
    name: "sports_hockey",
    version: 11,
    popularity: 1975,
    codepoint: 59947,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "hockey",
      "social",
      "sports",
      "sticks",
    ],
  },
  sports_kabaddi: {
    name: "sports_kabaddi",
    version: 11,
    popularity: 6271,
    codepoint: 59956,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "body",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "hobby",
      "human",
      "kabaddi",
      "people",
      "person",
      "social",
      "sports",
      "wrestle",
      "wrestling",
    ],
  },
  sports_martial_arts: {
    name: "sports_martial_arts",
    version: 1,
    popularity: 2613,
    codepoint: 60137,
    categories: ["social"],
    tags: [
      "arts",
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "karate",
      "martial",
      "people",
      "person",
      "social",
      "sports",
    ],
  },
  sports_mma: {
    name: "sports_mma",
    version: 11,
    popularity: 2684,
    codepoint: 59948,
    categories: ["social"],
    tags: [
      "arts",
      "athlete",
      "athletic",
      "boxing",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "glove",
      "hobby",
      "martial",
      "mixed",
      "mma",
      "social",
      "sports",
    ],
  },
  sports_motorsports: {
    name: "sports_motorsports",
    version: 11,
    popularity: 4540,
    codepoint: 59949,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "automobile",
      "bike",
      "drive",
      "driving",
      "entertainment",
      "helmet",
      "hobby",
      "motorcycle",
      "motorsports",
      "protect",
      "social",
      "sports",
      "vehicle",
    ],
  },
  sports_rugby: {
    name: "sports_rugby",
    version: 11,
    popularity: 1896,
    codepoint: 59950,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "rugby",
      "social",
      "sports",
    ],
  },
  sports_score: {
    name: "sports_score",
    version: 10,
    popularity: 10011,
    codepoint: 61550,
    categories: ["device"],
    tags: ["destination", "flag", "goal", "score", "sports"],
  },
  sports_soccer: {
    name: "sports_soccer",
    version: 11,
    popularity: 14937,
    codepoint: 59951,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "soccer",
      "social",
      "sports",
    ],
  },
  sports_tennis: {
    name: "sports_tennis",
    version: 11,
    popularity: 5713,
    codepoint: 59954,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "racket",
      "social",
      "sports",
      "tennis",
    ],
  },
  sports_volleyball: {
    name: "sports_volleyball",
    version: 11,
    popularity: 3950,
    codepoint: 59953,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
      "volleyball",
    ],
  },
  sprinkler: {
    name: "sprinkler",
    version: 229,
    popularity: 500,
    codepoint: 58010,
    categories: ["Household"],
    tags: [
      "drizzle",
      "drops",
      "irrigation",
      "nest",
      "spray",
      "sprinkler",
      "water",
    ],
  },
  sprint: {
    name: "sprint",
    version: 229,
    popularity: 116,
    codepoint: 63519,
    categories: ["Activities"],
    tags: [
      "activity",
      "athlete",
      "athletic",
      "exercise",
      "game",
      "hobby",
      "run",
      "running",
      "social",
      "sport",
      "sports",
      "track",
    ],
  },
  square: {
    name: "square",
    version: 1,
    popularity: 5836,
    codepoint: 60214,
    categories: ["editor"],
    tags: ["draw", "four", "shape quadrangle", "sides", "square"],
  },
  square_foot: {
    name: "square_foot",
    version: 11,
    popularity: 11298,
    codepoint: 59977,
    categories: ["content"],
    tags: [
      "construction",
      "feet",
      "foot",
      "inches",
      "length",
      "measurement",
      "ruler",
      "school",
      "set",
      "square",
      "tools",
    ],
  },
  ssid_chart: {
    name: "ssid_chart",
    version: 1,
    popularity: 2656,
    codepoint: 60262,
    categories: ["device"],
    tags: ["chart", "graph", "lines", "network", "ssid", "wifi"],
  },
  stack: {
    name: "stack",
    version: 229,
    popularity: 123,
    codepoint: 62985,
    categories: ["UI actions"],
    tags: ["multiple", "square", "stacked"],
  },
  stack_off: {
    name: "stack_off",
    version: 229,
    popularity: 26,
    codepoint: 62984,
    categories: ["UI actions"],
    tags: [
      "disabled",
      "enabled",
      "multiple",
      "off",
      "offline",
      "slash",
      "square",
      "stacked",
    ],
  },
  stack_star: {
    name: "stack_star",
    version: 229,
    popularity: 55,
    codepoint: 62983,
    categories: ["UI actions"],
    tags: [
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "multiple",
      "save",
      "special",
      "square",
      "stacked",
    ],
  },
  stacked_bar_chart: {
    name: "stacked_bar_chart",
    version: 12,
    popularity: 11666,
    codepoint: 59878,
    categories: ["content"],
    tags: [
      "analytics",
      "bar",
      "chart-chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking",
    ],
  },
  stacked_email: {
    name: "stacked_email",
    version: 229,
    popularity: 94,
    codepoint: 59079,
    categories: ["Communication"],
    tags: ["archive", "email", "mail", "send", "stacked"],
  },
  stacked_inbox: {
    name: "stacked_inbox",
    version: 229,
    popularity: 6,
    codepoint: 59081,
    categories: ["Communication"],
    tags: ["box", "email", "in", "inbox", "mail", "stacked"],
  },
  stacked_line_chart: {
    name: "stacked_line_chart",
    version: 6,
    popularity: 7763,
    codepoint: 61995,
    categories: ["editor"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking",
    ],
  },
  stadia_controller: {
    name: "stadia_controller",
    version: 229,
    popularity: 4929,
    codepoint: 61749,
    categories: ["Home"],
    tags: [
      "console",
      "control",
      "controller",
      "device",
      "esports",
      "game",
      "gaming",
      "google",
      "handheld",
      "hardware",
      "playstation",
      "remote",
      "stadia",
      "video",
      "xbox",
    ],
  },
  stadium: {
    name: "stadium",
    version: 1,
    popularity: 2139,
    codepoint: 60304,
    categories: ["maps"],
    tags: [
      "activity",
      "amphitheater",
      "arena",
      "coliseum",
      "event",
      "local",
      "stadium",
      "star",
      "things",
      "ticket",
    ],
  },
  stairs: {
    name: "stairs",
    version: 8,
    popularity: 3909,
    codepoint: 61865,
    categories: ["places"],
    tags: ["down", "staircase", "stairs", "up"],
  },
  star: {
    name: "star",
    version: 22,
    popularity: 131482,
    codepoint: 59448,
    categories: ["toggle"],
    tags: [
      "best",
      "bookmark",
      "favorite",
      "highlight",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle",
    ],
  },
  star_half: {
    name: "star_half",
    version: 22,
    popularity: 16687,
    codepoint: 59449,
    categories: ["toggle"],
    tags: [
      "achievement",
      "bookmark",
      "favorite",
      "half",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
      "toggle",
    ],
  },
  star_rate: {
    name: "star_rate",
    version: 12,
    popularity: 72398,
    codepoint: 61676,
    categories: ["action"],
    tags: [
      "achievement",
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
    ],
  },
  star_rate_half: {
    name: "star_rate_half",
    version: 229,
    popularity: 51,
    codepoint: 60485,
    categories: ["UI actions"],
    tags: [
      "achievement",
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
    ],
  },
  stars: {
    name: "stars",
    version: 12,
    popularity: 31320,
    codepoint: 59600,
    categories: ["action"],
    tags: [
      "achievement",
      "bookmark",
      "circle",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
    ],
  },
  start: {
    name: "start",
    version: 1,
    popularity: 8379,
    codepoint: 57481,
    categories: ["hardware"],
    tags: ["arrow", "keyboard", "next", "right", "start"],
  },
  stat_0: {
    name: "stat_0",
    version: 229,
    popularity: 119,
    codepoint: 59031,
    categories: ["UI actions"],
    tags: ["0", "diamond", "square", "stat"],
  },
  stat_1: {
    name: "stat_1",
    version: 229,
    popularity: 26,
    codepoint: 59032,
    categories: ["UI actions"],
    tags: ["1 arrow", "arrows", "direction", "stat", "up", "upward"],
  },
  stat_2: {
    name: "stat_2",
    version: 229,
    popularity: 31,
    codepoint: 59033,
    categories: ["UI actions"],
    tags: ["2", "arrow", "arrows", "direction", "stat", "two", "up", "upward"],
  },
  stat_3: {
    name: "stat_3",
    version: 229,
    popularity: 54,
    codepoint: 59034,
    categories: ["UI actions"],
    tags: [
      "3",
      "arrow",
      "arrows",
      "direction",
      "stat",
      "three",
      "up",
      "upward",
    ],
  },
  stat_minus_1: {
    name: "stat_minus_1",
    version: 229,
    popularity: 36,
    codepoint: 59035,
    categories: ["UI actions"],
    tags: [
      "1",
      "arrow",
      "arrows",
      "direction",
      "down",
      "downward",
      "minus",
      "stat",
    ],
  },
  stat_minus_2: {
    name: "stat_minus_2",
    version: 229,
    popularity: 30,
    codepoint: 59036,
    categories: ["UI actions"],
    tags: [
      "2",
      "arrow",
      "arrows",
      "direction",
      "down",
      "downward",
      "minus",
      "stat",
      "two",
    ],
  },
  stat_minus_3: {
    name: "stat_minus_3",
    version: 229,
    popularity: 18,
    codepoint: 59037,
    categories: ["UI actions"],
    tags: [
      "3",
      "arrow",
      "arrows",
      "direction",
      "doward",
      "down",
      "minus",
      "stat",
      "three",
    ],
  },
  stay_current_landscape: {
    name: "stay_current_landscape",
    version: 12,
    popularity: 1368,
    codepoint: 57555,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "stay",
      "tablet",
    ],
  },
  stay_current_portrait: {
    name: "stay_current_portrait",
    version: 12,
    popularity: 4461,
    codepoint: 57556,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "stay",
      "tablet",
    ],
  },
  stay_primary_landscape: {
    name: "stay_primary_landscape",
    version: 12,
    popularity: 1275,
    codepoint: 57557,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "primary",
      "stay",
      "tablet",
    ],
  },
  stay_primary_portrait: {
    name: "stay_primary_portrait",
    version: 12,
    popularity: 3364,
    codepoint: 57558,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "primary",
      "stay",
      "tablet",
    ],
  },
  step: {
    name: "step",
    version: 229,
    popularity: 39,
    codepoint: 63230,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "east", "left", "navigation"],
  },
  step_into: {
    name: "step_into",
    version: 229,
    popularity: 28,
    codepoint: 63233,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "down", "navigation", "south"],
  },
  step_out: {
    name: "step_out",
    version: 229,
    popularity: 11,
    codepoint: 63232,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "direction", "navigation", "north", "up"],
  },
  step_over: {
    name: "step_over",
    version: 229,
    popularity: 17,
    codepoint: 63231,
    categories: ["UI actions"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "refresh",
      "renew",
      "rotate",
      "turn",
    ],
  },
  steppers: {
    name: "steppers",
    version: 229,
    popularity: 62,
    codepoint: 59879,
    categories: ["UI actions"],
    tags: [
      "circles",
      "content",
      "control",
      "dots",
      "media",
      "more",
      "page",
      "scroll",
      "steppers",
      "swipe",
      "web",
    ],
  },
  steps: {
    name: "steps",
    version: 229,
    popularity: 126,
    codepoint: 63194,
    categories: ["Activities"],
    tags: ["fitbit", "shoe", "sneaker", "step"],
  },
  stethoscope: {
    name: "stethoscope",
    version: 229,
    popularity: 51,
    codepoint: 63493,
    categories: ["Social"],
    tags: ["admission", "doctor", "health", "med", "medical", "medicine"],
  },
  stethoscope_arrow: {
    name: "stethoscope_arrow",
    version: 229,
    popularity: 6,
    codepoint: 63495,
    categories: ["Social"],
    tags: [
      "admission",
      "arrow",
      "doctor",
      "health",
      "med",
      "medical",
      "medicine",
      "out",
      "poa",
      "right",
    ],
  },
  stethoscope_check: {
    name: "stethoscope_check",
    version: 229,
    popularity: 8,
    codepoint: 63494,
    categories: ["Social"],
    tags: [
      "admission",
      "approve",
      "check",
      "complete",
      "doctor",
      "done",
      "health",
      "mark",
      "med",
      "medical",
      "medicine",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  sticky_note: {
    name: "sticky_note",
    version: 229,
    popularity: 27,
    codepoint: 59880,
    categories: ["Text Formatting"],
    tags: ["note", "paper", "post", "sticky", "t", "write"],
  },
  sticky_note_2: {
    name: "sticky_note_2",
    version: 7,
    popularity: 22795,
    codepoint: 61948,
    categories: ["action"],
    tags: [
      "2",
      "bookmark",
      "mark",
      "message",
      "note",
      "paper",
      "sticky",
      "text",
      "writing",
    ],
  },
  stock_media: {
    name: "stock_media",
    version: 229,
    popularity: 18,
    codepoint: 62832,
    categories: ["Text Formatting"],
    tags: [
      "audio",
      "audiotrack",
      "clip",
      "clip art",
      "image",
      "key",
      "landscape",
      "mountain",
      "mountains",
      "music",
      "note",
      "photo",
      "photography",
      "picture",
      "sound",
      "track",
    ],
  },
  stockpot: {
    name: "stockpot",
    version: 229,
    popularity: 0,
    codepoint: 62789,
    categories: ["Household"],
    tags: [
      "braiser",
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "dutch oven",
      "food",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "nest",
      "oven",
      "pan",
      "pot",
      "stockpot",
      "stove",
      "stovetop",
    ],
  },
  stop: {
    name: "stop",
    version: 12,
    popularity: 27869,
    codepoint: 57415,
    categories: ["av"],
    tags: [
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video",
    ],
  },
  stop_circle: {
    name: "stop_circle",
    version: 15,
    popularity: 13530,
    codepoint: 61297,
    categories: ["av"],
    tags: [
      "circle",
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video",
    ],
  },
  stop_screen_share: {
    name: "stop_screen_share",
    version: 13,
    popularity: 2106,
    codepoint: 57571,
    categories: ["communication"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "share",
      "slash",
      "stop",
      "stream",
      "streaming",
      "web",
      "window",
    ],
  },
  storage: {
    name: "storage",
    version: 13,
    popularity: 24439,
    codepoint: 57819,
    categories: ["device"],
    tags: ["computer", "data", "drive", "memory", "storage"],
  },
  store: {
    name: "store",
    version: 11,
    popularity: 63891,
    codepoint: 59601,
    categories: ["action"],
    tags: [
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront",
    ],
  },
  storefront: {
    name: "storefront",
    version: 17,
    popularity: 49092,
    codepoint: 59922,
    categories: ["places"],
    tags: [
      "business",
      "buy",
      "cafe",
      "commerce",
      "front",
      "market",
      "places",
      "restaurant",
      "retail",
      "sell",
      "shop",
      "shopping",
      "store",
      "storefront",
    ],
  },
  storm: {
    name: "storm",
    version: 10,
    popularity: 3025,
    codepoint: 61552,
    categories: ["device"],
    tags: [
      "forecast",
      "hurricane",
      "storm",
      "temperature",
      "twister",
      "weather",
      "wind",
    ],
  },
  straight: {
    name: "straight",
    version: 1,
    popularity: 2732,
    codepoint: 60309,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "straight",
      "traffic",
      "up",
    ],
  },
  straighten: {
    name: "straighten",
    version: 12,
    popularity: 13802,
    codepoint: 58396,
    categories: ["image"],
    tags: ["length", "measure", "measurement", "ruler", "size", "straighten"],
  },
  strategy: {
    name: "strategy",
    version: 229,
    popularity: 54,
    codepoint: 62943,
    categories: ["Social"],
    tags: ["flag", "games", "golf", "google play"],
  },
  stream: {
    name: "stream",
    version: 11,
    popularity: 9095,
    codepoint: 59881,
    categories: ["content"],
    tags: [
      "cast",
      "connected",
      "feed",
      "live",
      "network",
      "signal",
      "stream",
      "wireless",
    ],
  },
  stream_apps: {
    name: "stream_apps",
    version: 229,
    popularity: 11,
    codepoint: 63391,
    categories: ["Hardware"],
    tags: [
      "app",
      "bubble",
      "cell",
      "chat",
      "comment",
      "communicate",
      "device",
      "feedback",
      "hardware",
      "message",
      "mobile",
      "phone",
      "speech",
    ],
  },
  streetview: {
    name: "streetview",
    version: 12,
    popularity: 2001,
    codepoint: 58734,
    categories: ["maps"],
    tags: ["maps", "street", "streetview", "view"],
  },
  stress_management: {
    name: "stress_management",
    version: 229,
    popularity: 56,
    codepoint: 63193,
    categories: ["Activities"],
    tags: [
      "emotional",
      "fitbit",
      "mental",
      "mood",
      "physical",
      "psychological",
      "response",
      "stress",
      "wellness",
    ],
  },
  strikethrough_s: {
    name: "strikethrough_s",
    version: 14,
    popularity: 3194,
    codepoint: 57943,
    categories: ["editor"],
    tags: [
      "alphabet",
      "character",
      "cross",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "letter",
      "out",
      "s",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "styles",
      "symbol",
      "text",
      "type",
      "writing",
    ],
  },
  stroke_full: {
    name: "stroke_full",
    version: 229,
    popularity: 10,
    codepoint: 63305,
    categories: ["Text Formatting"],
    tags: [
      "diagonal",
      "lines",
      "pattern",
      "shade",
      "stripes",
      "strokes",
      "texture",
    ],
  },
  stroke_partial: {
    name: "stroke_partial",
    version: 229,
    popularity: 8,
    codepoint: 63304,
    categories: ["Text Formatting"],
    tags: [
      "diagonal",
      "lines",
      "pattern",
      "shade",
      "stripes",
      "strokes",
      "texture",
    ],
  },
  stroller: {
    name: "stroller",
    version: 8,
    popularity: 1433,
    codepoint: 61870,
    categories: ["places"],
    tags: [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young",
    ],
  },
  style: {
    name: "style",
    version: 12,
    popularity: 15124,
    codepoint: 58397,
    categories: ["image"],
    tags: ["booklet", "cards", "filters", "options", "style", "tags"],
  },
  styler: {
    name: "styler",
    version: 229,
    popularity: 1420,
    codepoint: 57971,
    categories: ["Household"],
    tags: [
      "appliance",
      "clothes",
      "fashion",
      "hairdresser",
      "hanger",
      "nest",
      "organization",
      "style",
      "styler",
    ],
  },
  stylus: {
    name: "stylus",
    version: 229,
    popularity: 41,
    codepoint: 62980,
    categories: ["Android"],
    tags: [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  stylus_laser_pointer: {
    name: "stylus_laser_pointer",
    version: 229,
    popularity: 30,
    codepoint: 63303,
    categories: ["Text Formatting"],
    tags: ["beam", "glow", "point", "ray"],
  },
  stylus_note: {
    name: "stylus_note",
    version: 229,
    popularity: 46,
    codepoint: 62979,
    categories: ["Android"],
    tags: [
      "compose",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing",
    ],
  },
  subdirectory_arrow_left: {
    name: "subdirectory_arrow_left",
    version: 11,
    popularity: 5203,
    codepoint: 58841,
    categories: ["navigation"],
    tags: [
      "arrow",
      "directory",
      "down",
      "left",
      "navigation",
      "sub",
      "subdirectory",
    ],
  },
  subdirectory_arrow_right: {
    name: "subdirectory_arrow_right",
    version: 11,
    popularity: 10952,
    codepoint: 58842,
    categories: ["navigation"],
    tags: [
      "arrow",
      "directory",
      "down",
      "navigation",
      "right",
      "sub",
      "subdirectory",
    ],
  },
  subheader: {
    name: "subheader",
    version: 229,
    popularity: 13,
    codepoint: 59882,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "subheader",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  subject: {
    name: "subject",
    version: 13,
    popularity: 18959,
    codepoint: 59602,
    categories: ["action"],
    tags: [
      "alignment",
      "doc",
      "document",
      "email",
      "full",
      "justify",
      "list",
      "note",
      "subject",
      "text",
      "writing",
    ],
  },
  subscript: {
    name: "subscript",
    version: 13,
    popularity: 1563,
    codepoint: 61713,
    categories: ["editor"],
    tags: [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "subscript",
      "symbol",
      "text",
      "writing",
      "x",
    ],
  },
  subscriptions: {
    name: "subscriptions",
    version: 11,
    popularity: 13714,
    codepoint: 57444,
    categories: ["av"],
    tags: [
      "enroll",
      "list",
      "media",
      "order",
      "play",
      "signup",
      "subscribe",
      "subscriptions",
    ],
  },
  subtitles: {
    name: "subtitles",
    version: 12,
    popularity: 7760,
    codepoint: 57416,
    categories: ["av"],
    tags: [
      "accessible",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "language",
      "live caption",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "tv",
    ],
  },
  subtitles_off: {
    name: "subtitles_off",
    version: 11,
    popularity: 3158,
    codepoint: 61298,
    categories: ["action"],
    tags: [
      "accessibility",
      "accessible",
      "caption",
      "cc",
      "closed",
      "disabled",
      "enabled",
      "language",
      "live caption",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "translate",
      "video",
    ],
  },
  subway: {
    name: "subway",
    version: 11,
    popularity: 2775,
    codepoint: 58735,
    categories: ["maps"],
    tags: [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "rail",
      "scooter",
      "subway",
      "train",
      "transportation",
      "travel",
      "tunnel",
      "underground",
      "vehicle",
      "vespa",
    ],
  },
  summarize: {
    name: "summarize",
    version: 10,
    popularity: 38830,
    codepoint: 61553,
    categories: ["device"],
    tags: [
      "doc",
      "document",
      "form",
      "list",
      "menu",
      "note",
      "report",
      "summary",
    ],
  },
  sunny: {
    name: "sunny",
    version: 2,
    popularity: 3119,
    codepoint: 59418,
    categories: ["home"],
    tags: [
      "climate",
      "hot",
      "summer",
      "sun",
      "sunny",
      "temperature",
      "warm",
      "weather",
    ],
  },
  sunny_snowing: {
    name: "sunny_snowing",
    version: 3,
    popularity: 798,
    codepoint: 59417,
    categories: ["home"],
    tags: [
      "climate",
      "cold",
      "snow",
      "sun",
      "sunny",
      "temperature",
      "weather",
      "winter",
    ],
  },
  superscript: {
    name: "superscript",
    version: 12,
    popularity: 1886,
    codepoint: 61714,
    categories: ["editor"],
    tags: [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "superscript",
      "symbol",
      "text",
      "writing",
      "x",
    ],
  },
  supervised_user_circle: {
    name: "supervised_user_circle",
    version: 12,
    popularity: 27857,
    codepoint: 59705,
    categories: ["action"],
    tags: [
      "account",
      "avatar",
      "circle",
      "control",
      "face",
      "human",
      "parental",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user",
    ],
  },
  supervised_user_circle_off: {
    name: "supervised_user_circle_off",
    version: 229,
    popularity: 6,
    codepoint: 62990,
    categories: ["Common actions"],
    tags: [
      "account",
      "avatar",
      "circle",
      "control",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "offline",
      "on",
      "parental",
      "parents",
      "people",
      "person",
      "profile",
      "slash",
      "supervised",
      "supervisor",
      "user",
    ],
  },
  supervisor_account: {
    name: "supervisor_account",
    version: 13,
    popularity: 40539,
    codepoint: 59603,
    categories: ["action"],
    tags: [
      "account",
      "avatar",
      "control",
      "face",
      "human",
      "parental",
      "parental control",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user",
    ],
  },
  support: {
    name: "support",
    version: 11,
    popularity: 21989,
    codepoint: 61299,
    categories: ["action"],
    tags: [
      "assist",
      "buoy",
      "help",
      "life",
      "lifebuoy",
      "rescue",
      "safe",
      "safety",
      "support",
    ],
  },
  support_agent: {
    name: "support_agent",
    version: 12,
    popularity: 85561,
    codepoint: 61666,
    categories: ["notification"],
    tags: [
      "agent",
      "care",
      "customer",
      "face",
      "headphone",
      "person",
      "representative",
      "service",
      "support",
    ],
  },
  surfing: {
    name: "surfing",
    version: 4,
    popularity: 4561,
    codepoint: 58645,
    categories: ["social"],
    tags: [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sea",
      "social sports",
      "sports",
      "summer",
      "surfing",
      "water",
    ],
  },
  surgical: {
    name: "surgical",
    version: 229,
    popularity: 2,
    codepoint: 57649,
    categories: ["Social"],
    tags: ["health", "knife", "medical", "procedure", "surgery"],
  },
  surround_sound: {
    name: "surround_sound",
    version: 13,
    popularity: 1466,
    codepoint: 57417,
    categories: ["av"],
    tags: [
      "circle",
      "signal",
      "sound",
      "speaker",
      "surround",
      "system",
      "volumn",
      "wireless",
    ],
  },
  swap_calls: {
    name: "swap_calls",
    version: 12,
    popularity: 3306,
    codepoint: 57559,
    categories: ["communication"],
    tags: [
      "arrow",
      "arrows",
      "calls",
      "device",
      "direction",
      "mobile",
      "share",
      "swap",
    ],
  },
  swap_driving_apps: {
    name: "swap_driving_apps",
    version: 229,
    popularity: 36,
    codepoint: 59038,
    categories: ["Transportation"],
    tags: [
      "applications",
      "apps",
      "automobile",
      "car",
      "cars",
      "change",
      "direction",
      "directions",
      "driving",
      "maps",
      "swap",
      "switch",
      "vehicle",
    ],
  },
  swap_driving_apps_wheel: {
    name: "swap_driving_apps_wheel",
    version: 229,
    popularity: 68,
    codepoint: 59039,
    categories: ["Transportation"],
    tags: [
      "apps",
      "automobile",
      "car",
      "cars",
      "direction",
      "driving",
      "maps",
      "speedometer",
      "swap",
      "vehicle",
      "wheel",
    ],
  },
  swap_horiz: {
    name: "swap_horiz",
    version: 12,
    popularity: 31806,
    codepoint: 59604,
    categories: ["action"],
    tags: ["arrow", "arrows", "back", "forward", "horizontal", "swap"],
  },
  swap_horizontal_circle: {
    name: "swap_horizontal_circle",
    version: 12,
    popularity: 7528,
    codepoint: 59699,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "back",
      "circle",
      "forward",
      "horizontal",
      "swap",
    ],
  },
  swap_vert: {
    name: "swap_vert",
    version: 12,
    popularity: 21209,
    codepoint: 59605,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "down",
      "navigation",
      "sort",
      "sorting",
      "swap",
      "up",
      "vert",
      "vertical",
    ],
  },
  swap_vertical_circle: {
    name: "swap_vertical_circle",
    version: 13,
    popularity: 4886,
    codepoint: 59606,
    categories: ["action"],
    tags: ["arrow", "arrows", "circle", "down", "swap", "up", "vertical"],
  },
  sweep: {
    name: "sweep",
    version: 229,
    popularity: 39,
    codepoint: 59052,
    categories: ["UI actions"],
    tags: ["approve", "check", "select", "sweep", "tick", "yes"],
  },
  swipe: {
    name: "swipe",
    version: 12,
    popularity: 13666,
    codepoint: 59884,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "swipe",
      "touch",
    ],
  },
  swipe_down: {
    name: "swipe_down",
    version: 1,
    popularity: 2032,
    codepoint: 60243,
    categories: ["action"],
    tags: [
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
    ],
  },
  swipe_down_alt: {
    name: "swipe_down_alt",
    version: 1,
    popularity: 1302,
    codepoint: 60208,
    categories: ["action"],
    tags: [
      "alt",
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
    ],
  },
  swipe_left: {
    name: "swipe_left",
    version: 1,
    popularity: 3013,
    codepoint: 60249,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take",
    ],
  },
  swipe_left_alt: {
    name: "swipe_left_alt",
    version: 1,
    popularity: 1164,
    codepoint: 60211,
    categories: ["action"],
    tags: [
      "alt",
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take",
    ],
  },
  swipe_right: {
    name: "swipe_right",
    version: 1,
    popularity: 2691,
    codepoint: 60242,
    categories: ["action"],
    tags: [
      "accept",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take",
    ],
  },
  swipe_right_alt: {
    name: "swipe_right_alt",
    version: 1,
    popularity: 1609,
    codepoint: 60246,
    categories: ["action"],
    tags: [
      "accept",
      "alt",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take",
    ],
  },
  swipe_up: {
    name: "swipe_up",
    version: 1,
    popularity: 2645,
    codepoint: 60206,
    categories: ["action"],
    tags: [
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up",
    ],
  },
  swipe_up_alt: {
    name: "swipe_up_alt",
    version: 1,
    popularity: 1111,
    codepoint: 60213,
    categories: ["action"],
    tags: [
      "alt",
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up",
    ],
  },
  swipe_vertical: {
    name: "swipe_vertical",
    version: 1,
    popularity: 1847,
    codepoint: 60241,
    categories: ["action"],
    tags: [
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "verticle",
    ],
  },
  switch: {
    name: "switch",
    version: 229,
    popularity: 387,
    codepoint: 57844,
    categories: ["Household"],
    tags: [
      "button",
      "key",
      "nest",
      "off",
      "on",
      "rectangle",
      "switch",
      "transition",
    ],
  },
  switch_access: {
    name: "switch_access",
    version: 229,
    popularity: 11,
    codepoint: 63229,
    categories: ["UI actions"],
    tags: ["abstract", "select", "squares"],
  },
  switch_access_shortcut: {
    name: "switch_access_shortcut",
    version: 3,
    popularity: 4758,
    codepoint: 59361,
    categories: ["action"],
    tags: [
      "access",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "shortcut",
      "switch",
      "symbol",
      "up",
    ],
  },
  switch_access_shortcut_add: {
    name: "switch_access_shortcut_add",
    version: 3,
    popularity: 3473,
    codepoint: 59362,
    categories: ["action"],
    tags: [
      "+",
      "access",
      "add",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "plus",
      "shortcut",
      "switch",
      "symbol",
      "up",
    ],
  },
  switch_account: {
    name: "switch_account",
    version: 11,
    popularity: 8523,
    codepoint: 59885,
    categories: ["social"],
    tags: [
      "account",
      "choices",
      "face",
      "human",
      "multiple",
      "options",
      "people",
      "person",
      "profile",
      "social",
      "switch",
      "user",
    ],
  },
  switch_camera: {
    name: "switch_camera",
    version: 12,
    popularity: 1573,
    codepoint: 58398,
    categories: ["image"],
    tags: [
      "arrow",
      "arrows",
      "camera",
      "photo",
      "photography",
      "picture",
      "switch",
    ],
  },
  switch_left: {
    name: "switch_left",
    version: 8,
    popularity: 4894,
    codepoint: 61905,
    categories: ["navigation"],
    tags: ["arrows", "directional", "left", "navigation", "switch", "toggle"],
  },
  switch_right: {
    name: "switch_right",
    version: 9,
    popularity: 3784,
    codepoint: 61906,
    categories: ["navigation"],
    tags: ["arrows", "directional", "navigation", "right", "switch", "toggle"],
  },
  switch_video: {
    name: "switch_video",
    version: 12,
    popularity: 1560,
    codepoint: 58399,
    categories: ["image"],
    tags: [
      "arrow",
      "arrows",
      "camera",
      "photography",
      "switch",
      "video",
      "videos",
    ],
  },
  switches: {
    name: "switches",
    version: 229,
    popularity: 96,
    codepoint: 59187,
    categories: ["UI actions"],
    tags: [
      "activated",
      "button",
      "deactivated",
      "design",
      "disabled",
      "enabled",
      "off",
      "on",
      "switch",
      "switches",
      "toggle",
      "ui",
      "ux",
    ],
  },
  sword_rose: {
    name: "sword_rose",
    version: 229,
    popularity: 16,
    codepoint: 62942,
    categories: ["Social"],
    tags: ["blade", "dagger", "flower", "google play", "young adult"],
  },
  swords: {
    name: "swords",
    version: 229,
    popularity: 709,
    codepoint: 63625,
    categories: ["Activities"],
    tags: ["battle", "combat", "duo", "fencing", "fight", "sword", "two"],
  },
  symptoms: {
    name: "symptoms",
    version: 229,
    popularity: 4,
    codepoint: 57650,
    categories: ["Social"],
    tags: ["drop", "droplet", "eye", "eyes", "health", "symptom"],
  },
  synagogue: {
    name: "synagogue",
    version: 2,
    popularity: 872,
    codepoint: 60080,
    categories: ["maps"],
    tags: [
      "ideology",
      "jew",
      "jewish",
      "religion",
      "shul",
      "spiritual",
      "temple",
      "worship",
    ],
  },
  sync: {
    name: "sync",
    version: 17,
    popularity: 41973,
    codepoint: 58919,
    categories: ["notification"],
    tags: [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "sync",
      "turn",
    ],
  },
  sync_alt: {
    name: "sync_alt",
    version: 12,
    popularity: 31123,
    codepoint: 59928,
    categories: ["action"],
    tags: [
      "alt",
      "arrow",
      "arrows",
      "horizontal",
      "internet",
      "sync",
      "technology",
      "up",
      "update",
      "wifi",
    ],
  },
  sync_disabled: {
    name: "sync_disabled",
    version: 16,
    popularity: 3066,
    codepoint: 58920,
    categories: ["notification"],
    tags: [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "disabled",
      "enabled",
      "inprogress",
      "load",
      "loading refresh",
      "off",
      "on",
      "renew",
      "rotate",
      "slash",
      "sync",
      "turn",
    ],
  },
  sync_lock: {
    name: "sync_lock",
    version: 1,
    popularity: 1630,
    codepoint: 60142,
    categories: ["notification"],
    tags: [
      "around",
      "arrow",
      "arrows",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "sync",
      "turn",
    ],
  },
  sync_problem: {
    name: "sync_problem",
    version: 21,
    popularity: 8087,
    codepoint: 58921,
    categories: ["notification"],
    tags: [
      "!",
      "360",
      "alert",
      "around",
      "arrow",
      "arrows",
      "attention",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "inprogress",
      "load",
      "loading refresh",
      "mark",
      "notification",
      "problem",
      "renew",
      "rotate",
      "symbol",
      "sync",
      "turn",
      "warning",
    ],
  },
  sync_saved_locally: {
    name: "sync_saved_locally",
    version: 229,
    popularity: 80,
    codepoint: 63520,
    categories: ["UI actions"],
    tags: [
      "OS",
      "accept",
      "android",
      "approve",
      "checkmark",
      "chrome",
      "complete",
      "computer",
      "device",
      "display",
      "done",
      "hardware",
      "ios",
      "laptop",
      "mac",
      "monitor",
      "ok",
      "screen",
      "select",
      "tick",
      "validate",
      "verified",
      "web",
      "window",
      "yes",
    ],
  },
  syringe: {
    name: "syringe",
    version: 229,
    popularity: 4,
    codepoint: 57651,
    categories: ["Social"],
    tags: [
      "booster",
      "health",
      "med",
      "medicine",
      "shot",
      "syringe",
      "vaccine",
      "vaccines",
    ],
  },
  system_update: {
    name: "system_update",
    version: 12,
    popularity: 4367,
    codepoint: 58922,
    categories: ["notification"],
    tags: [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "cell",
      "device",
      "direction",
      "down",
      "download",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "system",
      "tablet",
      "update",
    ],
  },
  system_update_alt: {
    name: "system_update_alt",
    version: 13,
    popularity: 10100,
    codepoint: 59607,
    categories: ["action"],
    tags: ["arrow", "down", "download", "export", "system", "update"],
  },
  tab: {
    name: "tab",
    version: 12,
    popularity: 6812,
    codepoint: 59608,
    categories: ["action"],
    tags: [
      "browser",
      "computer",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "web",
      "website",
      "window",
      "windows",
    ],
  },
  tab_close: {
    name: "tab_close",
    version: 229,
    popularity: 10,
    codepoint: 63301,
    categories: ["Text Formatting"],
    tags: [
      "cancel",
      "clear",
      "close",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "exit",
      "file",
      "multiple",
      "no",
      "off",
      "remove",
      "stack",
      "stop",
      "tabs",
      "x",
    ],
  },
  tab_close_right: {
    name: "tab_close_right",
    version: 229,
    popularity: 3,
    codepoint: 63302,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "cancel",
      "clear",
      "close",
      "doc",
      "document",
      "duplicate",
      "exit",
      "file",
      "move",
      "off",
      "out",
      "stop",
      "x",
    ],
  },
  tab_duplicate: {
    name: "tab_duplicate",
    version: 229,
    popularity: 24,
    codepoint: 63300,
    categories: ["Text Formatting"],
    tags: [
      "content",
      "copy",
      "cut",
      "dash",
      "dashed",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "select",
      "selection",
      "tabs",
    ],
  },
  tab_group: {
    name: "tab_group",
    version: 229,
    popularity: 10,
    codepoint: 63299,
    categories: ["Text Formatting"],
    tags: [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "stack",
      "tabs",
    ],
  },
  tab_move: {
    name: "tab_move",
    version: 229,
    popularity: 5,
    codepoint: 63298,
    categories: ["Text Formatting"],
    tags: ["arrow", "exit", "move", "out", "right", "tabs", "window"],
  },
  tab_new_right: {
    name: "tab_new_right",
    version: 229,
    popularity: 4,
    codepoint: 63297,
    categories: ["Text Formatting"],
    tags: [
      "add",
      "arrow",
      "box",
      "exit",
      "move",
      "new square",
      "out",
      "plus",
      "right",
      "symbol",
      "window",
    ],
  },
  tab_recent: {
    name: "tab_recent",
    version: 229,
    popularity: 3,
    codepoint: 63296,
    categories: ["Text Formatting"],
    tags: ["clock", "date", "schedule", "tabs", "time"],
  },
  tab_unselected: {
    name: "tab_unselected",
    version: 12,
    popularity: 2387,
    codepoint: 59609,
    categories: ["action"],
    tags: [
      "browser",
      "computer",
      "dash",
      "dashed",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "unselected",
      "web",
      "website",
      "window",
      "windows",
    ],
  },
  table: {
    name: "table",
    version: 229,
    popularity: 3530,
    codepoint: 61841,
    categories: ["Text Formatting"],
    tags: ["database", "grid", "layout", "squares", "table"],
  },
  table_bar: {
    name: "table_bar",
    version: 2,
    popularity: 2815,
    codepoint: 60114,
    categories: ["search"],
    tags: ["bar", "cafe", "round", "table"],
  },
  table_chart: {
    name: "table_chart",
    version: 12,
    popularity: 19459,
    codepoint: 57957,
    categories: ["editor"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking",
    ],
  },
  table_chart_view: {
    name: "table_chart_view",
    version: 229,
    popularity: 23,
    codepoint: 63215,
    categories: ["Text Formatting"],
    tags: [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking",
    ],
  },
  table_lamp: {
    name: "table_lamp",
    version: 229,
    popularity: 119,
    codepoint: 57842,
    categories: ["Home"],
    tags: ["home", "lamp", "light", "table"],
  },
  table_restaurant: {
    name: "table_restaurant",
    version: 2,
    popularity: 3835,
    codepoint: 60102,
    categories: ["search"],
    tags: ["bar", "dining", "table"],
  },
  table_rows: {
    name: "table_rows",
    version: 17,
    popularity: 13487,
    codepoint: 61697,
    categories: ["editor"],
    tags: ["grid", "layout", "lines", "rows", "stacked", "table"],
  },
  table_rows_narrow: {
    name: "table_rows_narrow",
    version: 229,
    popularity: 19,
    codepoint: 63295,
    categories: ["Text Formatting"],
    tags: [
      "bars",
      "columns",
      "design",
      "format",
      "grid",
      "layout",
      "row",
      "view",
    ],
  },
  table_view: {
    name: "table_view",
    version: 8,
    popularity: 18917,
    codepoint: 61886,
    categories: ["action"],
    tags: ["format", "grid", "group", "layout", "multiple", "table", "view"],
  },
  tablet: {
    name: "tablet",
    version: 12,
    popularity: 2426,
    codepoint: 58159,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web",
    ],
  },
  tablet_android: {
    name: "tablet_android",
    version: 14,
    popularity: 3211,
    codepoint: 58160,
    categories: ["hardware"],
    tags: [
      "OS",
      "android",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web",
    ],
  },
  tablet_mac: {
    name: "tablet_mac",
    version: 12,
    popularity: 6169,
    codepoint: 58161,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet mac",
      "web",
    ],
  },
  tabs: {
    name: "tabs",
    version: 229,
    popularity: 18,
    codepoint: 59886,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "tabs",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "websites",
      "window",
    ],
  },
  tactic: {
    name: "tactic",
    version: 229,
    popularity: 56,
    codepoint: 62820,
    categories: ["Social"],
    tags: [
      "arrow",
      "gameplan",
      "games",
      "method",
      "plan",
      "project",
      "right",
      "scheme",
      "strategy",
    ],
  },
  tag: {
    name: "tag",
    version: 10,
    popularity: 18985,
    codepoint: 59887,
    categories: ["content"],
    tags: [
      "hash",
      "hashtag",
      "key",
      "media",
      "number",
      "pound",
      "social",
      "tag",
      "trend",
    ],
  },
  takeout_dining: {
    name: "takeout_dining",
    version: 16,
    popularity: 5295,
    codepoint: 60020,
    categories: ["maps"],
    tags: [
      "box",
      "container",
      "delivery",
      "dining",
      "food",
      "meal",
      "restaurant",
      "takeout",
    ],
  },
  tamper_detection_off: {
    name: "tamper_detection_off",
    version: 229,
    popularity: 83,
    codepoint: 59438,
    categories: ["Household"],
    tags: ["device", "nest", "off", "smart", "tamper detection"],
  },
  tamper_detection_on: {
    name: "tamper_detection_on",
    version: 229,
    popularity: 108,
    codepoint: 63688,
    categories: ["Home"],
    tags: ["camera", "detection", "home", "nest", "on", "security", "tamper"],
  },
  tap_and_play: {
    name: "tap_and_play",
    version: 12,
    popularity: 2882,
    codepoint: 58923,
    categories: ["notification"],
    tags: [
      "Android",
      "OS wifi",
      "cell",
      "connection",
      "device",
      "hardware",
      "iOS",
      "internet",
      "mobile",
      "network",
      "phone",
      "play",
      "signal",
      "tablet",
      "tap",
      "to",
      "wireless",
    ],
  },
  tapas: {
    name: "tapas",
    version: 6,
    popularity: 2393,
    codepoint: 61929,
    categories: ["places"],
    tags: [
      "appetizer",
      "brunch",
      "dinner",
      "food",
      "lunch",
      "restaurant",
      "snack",
      "tapas",
    ],
  },
  target: {
    name: "target",
    version: 229,
    popularity: 587,
    codepoint: 59161,
    categories: ["Common actions"],
    tags: ["aim", "average", "bulls", "eye", "target"],
  },
  task: {
    name: "task",
    version: 10,
    popularity: 41483,
    codepoint: 61557,
    categories: ["device"],
    tags: [
      "approve",
      "check",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "file",
      "folder",
      "folders",
      "mark",
      "ok",
      "page",
      "paper",
      "select",
      "sheet",
      "slide",
      "task",
      "tick",
      "validate",
      "verified",
      "writing",
      "yes",
    ],
  },
  task_alt: {
    name: "task_alt",
    version: 6,
    popularity: 103474,
    codepoint: 58086,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "task",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  taunt: {
    name: "taunt",
    version: 229,
    popularity: 35,
    codepoint: 63135,
    categories: ["Social"],
    tags: ["body", "gesture", "human", "person", "tease"],
  },
  taxi_alert: {
    name: "taxi_alert",
    version: 11,
    popularity: 2984,
    codepoint: 61300,
    categories: ["maps"],
    tags: [
      "!",
      "alert",
      "attention",
      "automobile",
      "cab",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "lyft",
      "maps",
      "mark",
      "notification",
      "public",
      "symbol",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "warning",
      "yellow",
    ],
  },
  team_dashboard: {
    name: "team_dashboard",
    version: 229,
    popularity: 1391,
    codepoint: 57363,
    categories: ["Text Formatting"],
    tags: ["cards", "dashboard", "shapes", "square", "team"],
  },
  temp_preferences_eco: {
    name: "temp_preferences_eco",
    version: 229,
    popularity: 670,
    codepoint: 63690,
    categories: ["Home"],
    tags: [
      "climate",
      "eco",
      "energy",
      "home",
      "leaf",
      "nest",
      "preferences",
      "temperature",
      "thermostat",
    ],
  },
  temple_buddhist: {
    name: "temple_buddhist",
    version: 2,
    popularity: 1062,
    codepoint: 60083,
    categories: ["maps"],
    tags: [
      "buddha",
      "buddhism",
      "buddhist",
      "ideology",
      "monastery",
      "religion",
      "spiritual",
      "temple",
      "worship",
    ],
  },
  temple_hindu: {
    name: "temple_hindu",
    version: 3,
    popularity: 898,
    codepoint: 60079,
    categories: ["maps"],
    tags: [
      "hindu",
      "hinduism",
      "hindus",
      "ideology",
      "mandir",
      "religion",
      "spiritual",
      "temple",
      "worship",
    ],
  },
  tenancy: {
    name: "tenancy",
    version: 229,
    popularity: 1472,
    codepoint: 61667,
    categories: ["Business&Payments"],
    tags: ["connect", "data", "tenancy"],
  },
  terminal: {
    name: "terminal",
    version: 1,
    popularity: 7143,
    codepoint: 60302,
    categories: ["action"],
    tags: [
      "application",
      "code",
      "emulator",
      "program",
      "software",
      "terminal",
    ],
  },
  text_ad: {
    name: "text_ad",
    version: 229,
    popularity: 23,
    codepoint: 59176,
    categories: ["Text Formatting"],
    tags: ["ad", "doc", "document", "file", "page", "text", "writing"],
  },
  text_decrease: {
    name: "text_decrease",
    version: 1,
    popularity: 1414,
    codepoint: 60125,
    categories: ["editor"],
    tags: [
      "-",
      "alphabet",
      "character",
      "decrease",
      "font",
      "letter",
      "minus",
      "remove",
      "resize",
      "subtract",
      "symbol",
      "text",
      "type",
    ],
  },
  text_fields: {
    name: "text_fields",
    version: 14,
    popularity: 13817,
    codepoint: 57954,
    categories: ["editor"],
    tags: [
      "T",
      "add",
      "alphabet",
      "character",
      "field",
      "fields",
      "font",
      "input",
      "letter",
      "symbol",
      "text",
      "type",
    ],
  },
  text_fields_alt: {
    name: "text_fields_alt",
    version: 229,
    popularity: 38,
    codepoint: 59889,
    categories: ["Text Formatting"],
    tags: [
      "A",
      "alphabet",
      "alt",
      "character",
      "cursor",
      "field",
      "font",
      "letter",
      "symbol",
      "text",
      "type",
      "typing",
    ],
  },
  text_format: {
    name: "text_format",
    version: 12,
    popularity: 6537,
    codepoint: 57701,
    categories: ["content"],
    tags: [
      "alphabet",
      "character",
      "font",
      "format",
      "letter",
      "square A",
      "style",
      "symbol",
      "text",
      "type",
    ],
  },
  text_increase: {
    name: "text_increase",
    version: 1,
    popularity: 2366,
    codepoint: 60130,
    categories: ["editor"],
    tags: [
      "+",
      "add",
      "alphabet",
      "character",
      "font",
      "increase",
      "letter",
      "new",
      "plus",
      "resize",
      "symbol",
      "text",
      "type",
    ],
  },
  text_rotate_up: {
    name: "text_rotate_up",
    version: 12,
    popularity: 1562,
    codepoint: 59706,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "up",
    ],
  },
  text_rotate_vertical: {
    name: "text_rotate_vertical",
    version: 12,
    popularity: 2286,
    codepoint: 59707,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "arrow",
      "character",
      "down",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "vertical",
    ],
  },
  text_rotation_angledown: {
    name: "text_rotation_angledown",
    version: 12,
    popularity: 1482,
    codepoint: 59708,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "angledown",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
    ],
  },
  text_rotation_angleup: {
    name: "text_rotation_angleup",
    version: 12,
    popularity: 1521,
    codepoint: 59709,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "angleup",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
    ],
  },
  text_rotation_down: {
    name: "text_rotation_down",
    version: 12,
    popularity: 1516,
    codepoint: 59710,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "arrow",
      "character",
      "dow",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
    ],
  },
  text_rotation_none: {
    name: "text_rotation_none",
    version: 12,
    popularity: 2119,
    codepoint: 59711,
    categories: ["action"],
    tags: [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "none",
      "rotate",
      "symbol",
      "text",
      "type",
    ],
  },
  text_select_end: {
    name: "text_select_end",
    version: 229,
    popularity: 1,
    codepoint: 63294,
    categories: ["Text Formatting"],
    tags: [
      "cursor",
      "dash",
      "dashed",
      "format",
      "selection",
      "write",
      "writing",
    ],
  },
  text_select_jump_to_beginning: {
    name: "text_select_jump_to_beginning",
    version: 229,
    popularity: 6,
    codepoint: 63293,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "back",
      "dash",
      "dashes",
      "format",
      "left",
      "selection",
      "write",
      "writing",
    ],
  },
  text_select_jump_to_end: {
    name: "text_select_jump_to_end",
    version: 229,
    popularity: 4,
    codepoint: 63292,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "dash",
      "dashes",
      "format",
      "right",
      "selection",
      "write",
      "writing",
    ],
  },
  text_select_move_back_character: {
    name: "text_select_move_back_character",
    version: 229,
    popularity: 1,
    codepoint: 63291,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "cursor",
      "dash",
      "dashed",
      "dashes",
      "format",
      "left",
      "selection",
      "write",
      "writing",
    ],
  },
  text_select_move_back_word: {
    name: "text_select_move_back_word",
    version: 229,
    popularity: 3,
    codepoint: 63290,
    categories: ["Text Formatting"],
    tags: ["arrow", "back", "format", "left", "selection"],
  },
  text_select_move_down: {
    name: "text_select_move_down",
    version: 229,
    popularity: 2,
    codepoint: 63289,
    categories: ["Text Formatting"],
    tags: ["arrow", "down", "format", "selection"],
  },
  text_select_move_forward_character: {
    name: "text_select_move_forward_character",
    version: 229,
    popularity: 17,
    codepoint: 63288,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "cursor",
      "dash",
      "dashes",
      "format",
      "right",
      "selection",
      "write",
      "writing",
    ],
  },
  text_select_move_forward_word: {
    name: "text_select_move_forward_word",
    version: 229,
    popularity: 4,
    codepoint: 63287,
    categories: ["Text Formatting"],
    tags: ["arrow", "format", "move", "right", "selection"],
  },
  text_select_move_up: {
    name: "text_select_move_up",
    version: 229,
    popularity: 3,
    codepoint: 63286,
    categories: ["Text Formatting"],
    tags: ["arrow", "format", "selection", "up"],
  },
  text_select_start: {
    name: "text_select_start",
    version: 229,
    popularity: 9,
    codepoint: 63285,
    categories: ["Text Formatting"],
    tags: [
      "cursor",
      "dash",
      "dashed",
      "format",
      "selection",
      "write",
      "writing",
    ],
  },
  text_snippet: {
    name: "text_snippet",
    version: 8,
    popularity: 30092,
    codepoint: 61894,
    categories: ["file"],
    tags: [
      "data",
      "doc",
      "document",
      "file",
      "note",
      "notes",
      "snippet",
      "storage",
      "text",
      "writing",
    ],
  },
  text_to_speech: {
    name: "text_to_speech",
    version: 229,
    popularity: 933,
    codepoint: 61884,
    categories: ["Audio&Video"],
    tags: [
      "doc data",
      "document",
      "file",
      "listen",
      "speak",
      "speaker",
      "speech",
      "talk",
      "text",
      "tts",
      "volume",
      "writing",
    ],
  },
  texture: {
    name: "texture",
    version: 12,
    popularity: 3553,
    codepoint: 58401,
    categories: ["image"],
    tags: ["diagonal", "lines", "pattern", "stripes", "texture"],
  },
  texture_add: {
    name: "texture_add",
    version: 229,
    popularity: 3,
    codepoint: 62844,
    categories: ["Photo&Image"],
    tags: [
      "+",
      "add",
      "diagonal",
      "lines",
      "new",
      "pattern",
      "plus",
      "stripes",
      "texture",
    ],
  },
  texture_minus: {
    name: "texture_minus",
    version: 229,
    popularity: 1,
    codepoint: 62843,
    categories: ["Photo&Image"],
    tags: [
      "-",
      "delete",
      "diagonal",
      "lines",
      "pattern",
      "remove",
      "stripes",
      "subtract",
      "texture",
    ],
  },
  theater_comedy: {
    name: "theater_comedy",
    version: 11,
    popularity: 6571,
    codepoint: 60006,
    categories: ["maps"],
    tags: [
      "broadway",
      "comedy",
      "event",
      "movie",
      "musical",
      "places",
      "show",
      "standup",
      "theater",
      "tour",
      "watch",
    ],
  },
  theaters: {
    name: "theaters",
    version: 12,
    popularity: 11151,
    codepoint: 59610,
    categories: ["action"],
    tags: [
      "film",
      "movie",
      "movies",
      "show",
      "showtimes",
      "theater",
      "theaters",
      "watch",
    ],
  },
  thermometer: {
    name: "thermometer",
    version: 229,
    popularity: 1447,
    codepoint: 59462,
    categories: ["Household"],
    tags: [
      "celsius",
      "fahrenheit",
      "meter",
      "nest",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
    ],
  },
  thermometer_add: {
    name: "thermometer_add",
    version: 229,
    popularity: 16,
    codepoint: 62850,
    categories: ["Household"],
    tags: [
      "+",
      "add",
      "celsius",
      "fahrenheit",
      "meter",
      "nest",
      "new",
      "plus",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
    ],
  },
  thermometer_gain: {
    name: "thermometer_gain",
    version: 229,
    popularity: 17,
    codepoint: 63192,
    categories: ["Household"],
    tags: [
      "+",
      "add",
      "body",
      "celsius",
      "fahrenheit",
      "fitbit",
      "health",
      "high",
      "meter",
      "nest",
      "new",
      "plus",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
      "warm",
    ],
  },
  thermometer_loss: {
    name: "thermometer_loss",
    version: 229,
    popularity: 9,
    codepoint: 63191,
    categories: ["Household"],
    tags: [
      "body",
      "celsius",
      "cold",
      "cool",
      "fahrenheit",
      "fitbit",
      "health",
      "high",
      "low",
      "meter",
      "minus",
      "nest",
      "remove",
      "subtract",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
      "warm",
    ],
  },
  thermometer_minus: {
    name: "thermometer_minus",
    version: 229,
    popularity: 5,
    codepoint: 62849,
    categories: ["Household"],
    tags: [
      "-",
      "celsius",
      "delete",
      "fahrenheit",
      "meter",
      "minus",
      "nest",
      "remove",
      "subtract",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
    ],
  },
  thermostat: {
    name: "thermostat",
    version: 11,
    popularity: 21415,
    codepoint: 61558,
    categories: ["device"],
    tags: ["climate", "forecast", "temperature", "thermostat", "weather"],
  },
  thermostat_auto: {
    name: "thermostat_auto",
    version: 10,
    popularity: 2648,
    codepoint: 61559,
    categories: ["image"],
    tags: [
      "A",
      "auto",
      "celsius",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
    ],
  },
  thermostat_carbon: {
    name: "thermostat_carbon",
    version: 229,
    popularity: 172,
    codepoint: 61816,
    categories: ["Household"],
    tags: [],
  },
  things_to_do: {
    name: "things_to_do",
    version: 229,
    popularity: 67,
    codepoint: 60202,
    categories: ["Maps"],
    tags: [
      "architecture",
      "building",
      "capital",
      "do",
      "estate",
      "flag",
      "important",
      "landmark",
      "real",
      "things",
      "to",
    ],
  },
  thumb_down: {
    name: "thumb_down",
    version: 19,
    popularity: 23799,
    codepoint: 59611,
    categories: ["action"],
    tags: [
      "ate",
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rating",
      "thumb",
    ],
  },
  thumb_up: {
    name: "thumb_up",
    version: 19,
    popularity: 134138,
    codepoint: 59612,
    categories: ["action"],
    tags: [
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rate",
      "rating",
      "thumb",
      "up",
    ],
  },
  thumbnail_bar: {
    name: "thumbnail_bar",
    version: 229,
    popularity: 12,
    codepoint: 63284,
    categories: ["Text Formatting"],
    tags: ["layout", "sidebar", "ui", "window"],
  },
  thumbs_up_down: {
    name: "thumbs_up_down",
    version: 12,
    popularity: 11277,
    codepoint: 59613,
    categories: ["action"],
    tags: [
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hands",
      "like",
      "rate",
      "rating",
      "thumbs",
      "up",
    ],
  },
  thunderstorm: {
    name: "thunderstorm",
    version: 1,
    popularity: 2461,
    codepoint: 60379,
    categories: ["social"],
    tags: [
      "bolt",
      "climate",
      "cloud",
      "cloudy",
      "lightning",
      "rain",
      "rainfall",
      "rainstorm",
      "storm",
      "thunder",
      "thunderstorm",
      "weather",
    ],
  },
  tibia: {
    name: "tibia",
    version: 229,
    popularity: 210,
    codepoint: 63643,
    categories: ["Social"],
    tags: [
      "ankle",
      "body",
      "bone",
      "bones",
      "health",
      "knee",
      "leg",
      "medical",
      "skeleton",
      "tibia",
    ],
  },
  tibia_alt: {
    name: "tibia_alt",
    version: 229,
    popularity: 168,
    codepoint: 63644,
    categories: ["Social"],
    tags: [
      "ankle",
      "body",
      "bone",
      "bones",
      "health",
      "knee",
      "leg",
      "medical",
      "skeleton",
      "tibia",
    ],
  },
  time_auto: {
    name: "time_auto",
    version: 229,
    popularity: 457,
    codepoint: 61668,
    categories: ["Common actions"],
    tags: [
      "alarm",
      "auto timer",
      "automatic",
      "duration",
      "stop",
      "time",
      "watch",
    ],
  },
  timelapse: {
    name: "timelapse",
    version: 12,
    popularity: 9740,
    codepoint: 58402,
    categories: ["image"],
    tags: [
      "duration",
      "motion",
      "photo",
      "time",
      "timelapse",
      "timer",
      "video",
    ],
  },
  timeline: {
    name: "timeline",
    version: 13,
    popularity: 31951,
    codepoint: 59682,
    categories: ["action"],
    tags: [
      "data",
      "history",
      "line",
      "movement",
      "point",
      "points",
      "timeline",
      "tracking",
      "trending",
      "zigzag",
    ],
  },
  timer: {
    name: "timer",
    version: 13,
    popularity: 45595,
    codepoint: 58405,
    categories: ["image"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch",
    ],
  },
  timer_10: {
    name: "timer_10",
    version: 12,
    popularity: 1125,
    codepoint: 58403,
    categories: ["image"],
    tags: [
      "10",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer",
    ],
  },
  timer_10_alt_1: {
    name: "timer_10_alt_1",
    version: 229,
    popularity: 443,
    codepoint: 61375,
    categories: ["Common actions"],
    tags: [],
  },
  timer_10_select: {
    name: "timer_10_select",
    version: 15,
    popularity: 969,
    codepoint: 61562,
    categories: ["device"],
    tags: [
      "10",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type",
    ],
  },
  timer_3: {
    name: "timer_3",
    version: 12,
    popularity: 947,
    codepoint: 58404,
    categories: ["image"],
    tags: [
      "3",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer",
    ],
  },
  timer_3_alt_1: {
    name: "timer_3_alt_1",
    version: 229,
    popularity: 408,
    codepoint: 61376,
    categories: ["Common actions"],
    tags: [],
  },
  timer_3_select: {
    name: "timer_3_select",
    version: 15,
    popularity: 794,
    codepoint: 61563,
    categories: ["device"],
    tags: [
      "3",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type",
    ],
  },
  timer_off: {
    name: "timer_off",
    version: 13,
    popularity: 3465,
    codepoint: 58406,
    categories: ["image"],
    tags: [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch",
    ],
  },
  tire_repair: {
    name: "tire_repair",
    version: 1,
    popularity: 1145,
    codepoint: 60360,
    categories: ["maps"],
    tags: [
      "auto",
      "automobile",
      "car",
      "cars",
      "gauge",
      "mechanic",
      "pressure",
      "repair",
      "tire",
      "vehicle",
    ],
  },
  title: {
    name: "title",
    version: 11,
    popularity: 13418,
    codepoint: 57956,
    categories: ["editor"],
    tags: [
      "T",
      "alphabet",
      "character",
      "font",
      "header",
      "letter",
      "subject",
      "symbol",
      "text",
      "title",
      "type",
    ],
  },
  toast: {
    name: "toast",
    version: 229,
    popularity: 14,
    codepoint: 61377,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "toast",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  toc: {
    name: "toc",
    version: 13,
    popularity: 17771,
    codepoint: 59614,
    categories: ["action"],
    tags: [
      "content",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "table",
      "title",
      "titles",
      "toc",
    ],
  },
  today: {
    name: "today",
    version: 17,
    popularity: 61556,
    codepoint: 59615,
    categories: ["action"],
    tags: [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today",
    ],
  },
  toggle_off: {
    name: "toggle_off",
    version: 12,
    popularity: 29287,
    codepoint: 59893,
    categories: ["toggle"],
    tags: [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  toggle_on: {
    name: "toggle_on",
    version: 12,
    popularity: 48105,
    codepoint: 59894,
    categories: ["toggle"],
    tags: [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website",
    ],
  },
  token: {
    name: "token",
    version: 2,
    popularity: 6626,
    codepoint: 59941,
    categories: ["action"],
    tags: ["badge", "hexagon", "mark", "shield", "sign", "symbol"],
  },
  toll: {
    name: "toll",
    version: 12,
    popularity: 7737,
    codepoint: 59616,
    categories: ["action"],
    tags: [
      "bill",
      "booth",
      "car",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "highway",
      "money",
      "online",
      "pay",
      "payment",
      "ticket",
      "toll",
    ],
  },
  tonality: {
    name: "tonality",
    version: 12,
    popularity: 2182,
    codepoint: 58407,
    categories: ["image"],
    tags: [
      "circle",
      "edit",
      "editing",
      "filter",
      "image",
      "photography",
      "picture",
      "tonality",
    ],
  },
  toolbar: {
    name: "toolbar",
    version: 229,
    popularity: 46,
    codepoint: 59895,
    categories: ["UI actions"],
    tags: [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "toolbar",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window",
    ],
  },
  tools_flat_head: {
    name: "tools_flat_head",
    version: 229,
    popularity: 118,
    codepoint: 63691,
    categories: ["Home"],
    tags: ["flat", "head", "home", "nest", "screwdriver", "tools"],
  },
  tools_installation_kit: {
    name: "tools_installation_kit",
    version: 229,
    popularity: 138,
    codepoint: 58027,
    categories: ["Home"],
    tags: ["home", "installation", "kit", "nest", "tools"],
  },
  tools_ladder: {
    name: "tools_ladder",
    version: 229,
    popularity: 236,
    codepoint: 58059,
    categories: ["Home"],
    tags: ["home", "ladder", "nest", "tools"],
  },
  tools_level: {
    name: "tools_level",
    version: 229,
    popularity: 162,
    codepoint: 59259,
    categories: ["Home"],
    tags: ["home", "level", "nest", "tools"],
  },
  tools_phillips: {
    name: "tools_phillips",
    version: 229,
    popularity: 132,
    codepoint: 63692,
    categories: ["Home"],
    tags: ["home", "nest", "phillips", "screwdriver", "tools"],
  },
  tools_pliers_wire_stripper: {
    name: "tools_pliers_wire_stripper",
    version: 229,
    popularity: 269,
    codepoint: 58026,
    categories: ["Home"],
    tags: ["home", "nest", "pliers", "stripper", "tools", "wire"],
  },
  tools_power_drill: {
    name: "tools_power_drill",
    version: 229,
    popularity: 611,
    codepoint: 57833,
    categories: ["Home"],
    tags: ["drill", "home", "nest", "power", "tools"],
  },
  tooltip: {
    name: "tooltip",
    version: 229,
    popularity: 39,
    codepoint: 59896,
    categories: ["Communication"],
    tags: [
      "bubbletool",
      "chat",
      "comment",
      "information",
      "say",
      "speech",
      "talk",
      "tip",
      "tooltip",
    ],
  },
  top_panel_close: {
    name: "top_panel_close",
    version: 229,
    popularity: 9,
    codepoint: 63283,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "up",
      "window",
      "workflow",
    ],
  },
  top_panel_open: {
    name: "top_panel_open",
    version: 229,
    popularity: 5,
    codepoint: 63282,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "direction",
      "down",
      "expand",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow",
    ],
  },
  topic: {
    name: "topic",
    version: 7,
    popularity: 9727,
    codepoint: 61896,
    categories: ["file"],
    tags: [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "topic",
    ],
  },
  tornado: {
    name: "tornado",
    version: 1,
    popularity: 782,
    codepoint: 57753,
    categories: ["social"],
    tags: [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "tornado",
      "weather",
      "wind",
    ],
  },
  total_dissolved_solids: {
    name: "total_dissolved_solids",
    version: 229,
    popularity: 2,
    codepoint: 63607,
    categories: ["Social"],
    tags: ["dissolved", "liquid", "organic", "substances", "water"],
  },
  touch_app: {
    name: "touch_app",
    version: 13,
    popularity: 40532,
    codepoint: 59667,
    categories: ["action"],
    tags: [
      "app",
      "command",
      "fingers",
      "gesture",
      "hand",
      "long press",
      "press",
      "tap",
      "touch",
    ],
  },
  touchpad_mouse: {
    name: "touchpad_mouse",
    version: 229,
    popularity: 31,
    codepoint: 63111,
    categories: ["Hardware"],
    tags: [
      "click",
      "computer",
      "cursor",
      "device",
      "hardware",
      "mouse",
      "wireless",
    ],
  },
  tour: {
    name: "tour",
    version: 12,
    popularity: 9520,
    codepoint: 61301,
    categories: ["action"],
    tags: ["destination", "flag", "places", "tour", "travel", "visit"],
  },
  toys: {
    name: "toys",
    version: 14,
    popularity: 4186,
    codepoint: 58162,
    categories: ["hardware"],
    tags: ["car", "games", "kids", "toy", "toys", "windmill"],
  },
  toys_and_games: {
    name: "toys_and_games",
    version: 229,
    popularity: 99,
    codepoint: 61378,
    categories: ["Activities"],
    tags: ["and", "games", "piece", "puzzle", "puzzles", "toy", "toys"],
  },
  toys_fan: {
    name: "toys_fan",
    version: 229,
    popularity: 446,
    codepoint: 63623,
    categories: ["Activities"],
    tags: ["fan", "games", "kids", "pinwheel", "toy", "toys"],
  },
  track_changes: {
    name: "track_changes",
    version: 11,
    popularity: 17719,
    codepoint: 59617,
    categories: ["action"],
    tags: [
      "bullseye",
      "changes",
      "circle",
      "evolve",
      "lines",
      "movement",
      "rotate",
      "shift",
      "target",
      "track",
    ],
  },
  traffic: {
    name: "traffic",
    version: 12,
    popularity: 9667,
    codepoint: 58725,
    categories: ["maps"],
    tags: ["direction", "light", "maps", "signal", "street", "traffic"],
  },
  trail_length: {
    name: "trail_length",
    version: 229,
    popularity: 176,
    codepoint: 60254,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "motionblur",
      "photo",
      "photography",
      "picture",
    ],
  },
  trail_length_medium: {
    name: "trail_length_medium",
    version: 229,
    popularity: 149,
    codepoint: 60259,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "camera",
      "feature",
      "image",
      "medium",
      "mode",
      "motionblur",
      "photo",
      "photography",
      "picture",
    ],
  },
  trail_length_short: {
    name: "trail_length_short",
    version: 229,
    popularity: 147,
    codepoint: 60269,
    categories: ["Photo&Image"],
    tags: [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motionblur",
      "photo",
      "photography",
      "picture",
      "short",
    ],
  },
  train: {
    name: "train",
    version: 11,
    popularity: 11432,
    codepoint: 58736,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle",
    ],
  },
  tram: {
    name: "tram",
    version: 11,
    popularity: 3032,
    codepoint: 58737,
    categories: ["maps"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "tram",
      "transportation",
      "vehicle",
    ],
  },
  transcribe: {
    name: "transcribe",
    version: 1,
    popularity: 674,
    codepoint: 63724,
    categories: ["action"],
    tags: [],
  },
  transfer_within_a_station: {
    name: "transfer_within_a_station",
    version: 12,
    popularity: 6028,
    codepoint: 58738,
    categories: ["maps"],
    tags: [
      "a",
      "arrow",
      "arrows",
      "body",
      "direction",
      "human",
      "left",
      "maps",
      "people",
      "person",
      "public",
      "right",
      "route",
      "station",
      "stop",
      "transfer",
      "transportation",
      "vehicle",
      "walk",
      "within",
    ],
  },
  transform: {
    name: "transform",
    version: 12,
    popularity: 3318,
    codepoint: 58408,
    categories: ["image"],
    tags: [
      "adjust",
      "crop",
      "edit",
      "editing",
      "image",
      "photo",
      "picture",
      "transform",
    ],
  },
  transgender: {
    name: "transgender",
    version: 3,
    popularity: 5531,
    codepoint: 58765,
    categories: ["social"],
    tags: [
      "female",
      "gender",
      "lgbt",
      "male",
      "neutral",
      "social",
      "symbol",
      "transgender",
    ],
  },
  transit_enterexit: {
    name: "transit_enterexit",
    version: 12,
    popularity: 2748,
    codepoint: 58745,
    categories: ["maps"],
    tags: [
      "arrow",
      "direction",
      "enterexit",
      "maps",
      "navigation",
      "route",
      "transit",
      "transportation",
    ],
  },
  translate: {
    name: "translate",
    version: 16,
    popularity: 28228,
    codepoint: 59618,
    categories: ["action"],
    tags: [
      "language",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words",
    ],
  },
  transportation: {
    name: "transportation",
    version: 229,
    popularity: 435,
    codepoint: 57885,
    categories: ["Transportation"],
    tags: [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "nest",
      "public",
      "transportation",
      "trip",
      "vehicle",
    ],
  },
  travel: {
    name: "travel",
    version: 229,
    popularity: 157,
    codepoint: 59082,
    categories: ["Travel"],
    tags: [
      "air",
      "airplane",
      "airport",
      "plane",
      "transportation",
      "travel",
      "trip",
    ],
  },
  travel_explore: {
    name: "travel_explore",
    version: 8,
    popularity: 32408,
    codepoint: 58075,
    categories: ["social"],
    tags: [
      "browser",
      "earth",
      "explore",
      "find",
      "glass",
      "global",
      "globe",
      "look",
      "magnify",
      "magnifying",
      "map",
      "network",
      "planet",
      "search",
      "see",
      "social",
      "space",
      "travel",
      "web",
      "world",
    ],
  },
  travel_luggage_and_bags: {
    name: "travel_luggage_and_bags",
    version: 229,
    popularity: 22,
    codepoint: 61379,
    categories: ["Travel"],
    tags: [
      "and",
      "bag",
      "bags",
      "carry",
      "luggage",
      "on",
      "suitcase",
      "travel",
    ],
  },
  trending_down: {
    name: "trending_down",
    version: 18,
    popularity: 13972,
    codepoint: 59619,
    categories: ["action"],
    tags: [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "down",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending",
    ],
  },
  trending_flat: {
    name: "trending_flat",
    version: 19,
    popularity: 24866,
    codepoint: 59620,
    categories: ["action"],
    tags: [
      "arrow",
      "change",
      "data",
      "flat",
      "metric",
      "movement",
      "rate",
      "right",
      "track",
      "tracking",
      "trending",
    ],
  },
  trending_up: {
    name: "trending_up",
    version: 19,
    popularity: 83945,
    codepoint: 59621,
    categories: ["action"],
    tags: [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending",
      "up",
    ],
  },
  trip: {
    name: "trip",
    version: 229,
    popularity: 29,
    codepoint: 59131,
    categories: ["Travel"],
    tags: ["bag", "briefcase", "purse", "suitcase", "travel", "trip"],
  },
  trip_origin: {
    name: "trip_origin",
    version: 12,
    popularity: 6808,
    codepoint: 58747,
    categories: ["maps"],
    tags: ["circle", "departure", "origin", "trip"],
  },
  trolley: {
    name: "trolley",
    version: 1,
    popularity: 1073,
    codepoint: 63595,
    categories: ["hardware"],
    tags: ["carry", "factory", "manufactory", "move", "transport"],
  },
  trophy: {
    name: "trophy",
    version: 229,
    popularity: 508,
    codepoint: 59162,
    categories: ["Activities"],
    tags: [
      "achievement",
      "award",
      "chalice",
      "champion",
      "cup",
      "first",
      "prize",
      "reward",
      "sport",
      "trophy",
      "winner",
    ],
  },
  troubleshoot: {
    name: "troubleshoot",
    version: 10,
    popularity: 3555,
    codepoint: 57810,
    categories: ["action"],
    tags: [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "search",
      "see",
      "statistics",
      "tracking",
      "troubleshoot",
    ],
  },
  tsunami: {
    name: "tsunami",
    version: 1,
    popularity: 850,
    codepoint: 60376,
    categories: ["social"],
    tags: [
      "crisis",
      "disaster",
      "flood",
      "rain",
      "storm",
      "tsunami",
      "weather",
    ],
  },
  tsv: {
    name: "tsv",
    version: 229,
    popularity: 5,
    codepoint: 59094,
    categories: ["Text Formatting"],
    tags: [
      "data",
      "file",
      "information",
      "separated",
      "tab",
      "text",
      "tsv",
      "values",
    ],
  },
  tty: {
    name: "tty",
    version: 8,
    popularity: 2280,
    codepoint: 61866,
    categories: ["places"],
    tags: [
      "call",
      "cell",
      "contact",
      "deaf",
      "device",
      "hardware",
      "impaired",
      "mobile",
      "phone",
      "speech",
      "talk",
      "telephone",
      "text",
      "tty",
    ],
  },
  tune: {
    name: "tune",
    version: 12,
    popularity: 62528,
    codepoint: 58409,
    categories: ["image"],
    tags: [
      "adjust",
      "audio",
      "controls",
      "custom",
      "customize",
      "edit",
      "editing",
      "filter",
      "filters",
      "instant",
      "mix",
      "music",
      "options",
      "setting",
      "settings",
      "slider",
      "sliders",
      "switches",
      "tune",
    ],
  },
  turn_left: {
    name: "turn_left",
    version: 1,
    popularity: 1321,
    codepoint: 60326,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "turn",
    ],
  },
  turn_right: {
    name: "turn_right",
    version: 1,
    popularity: 1689,
    codepoint: 60331,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "turn",
    ],
  },
  turn_sharp_left: {
    name: "turn_sharp_left",
    version: 1,
    popularity: 577,
    codepoint: 60327,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn",
    ],
  },
  turn_sharp_right: {
    name: "turn_sharp_right",
    version: 1,
    popularity: 825,
    codepoint: 60330,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn",
    ],
  },
  turn_slight_left: {
    name: "turn_slight_left",
    version: 1,
    popularity: 752,
    codepoint: 60324,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "slight",
      "traffic",
      "turn",
    ],
  },
  turn_slight_right: {
    name: "turn_slight_right",
    version: 1,
    popularity: 768,
    codepoint: 60314,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "slight",
      "traffic",
      "turn",
    ],
  },
  tv: {
    name: "tv",
    version: 16,
    popularity: 14644,
    codepoint: 58163,
    categories: ["hardware"],
    tags: [
      "device",
      "display",
      "monitor",
      "screen",
      "screencast",
      "stream",
      "television",
      "tv",
      "video",
      "wireless",
    ],
  },
  tv_gen: {
    name: "tv_gen",
    version: 229,
    popularity: 687,
    codepoint: 59440,
    categories: ["Household"],
    tags: [
      "device",
      "electronic",
      "hardware",
      "home",
      "house",
      "monitor",
      "nest",
      "tv",
      "videos",
    ],
  },
  tv_guide: {
    name: "tv_guide",
    version: 229,
    popularity: 19,
    codepoint: 57820,
    categories: ["Hardware"],
    tags: ["device", "guide", "hardware", "television", "tv"],
  },
  tv_off: {
    name: "tv_off",
    version: 11,
    popularity: 1072,
    codepoint: 58951,
    categories: ["notification"],
    tags: [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "on",
      "slash",
      "television",
      "tv",
      "web",
      "window",
    ],
  },
  tv_options_edit_channels: {
    name: "tv_options_edit_channels",
    version: 229,
    popularity: 45,
    codepoint: 57821,
    categories: ["Hardware"],
    tags: [
      "approve",
      "bullet",
      "channel",
      "check",
      "do",
      "edit",
      "lists",
      "notes",
      "ok",
      "options",
      "select",
      "to",
      "tv",
      "yes",
    ],
  },
  tv_options_input_settings: {
    name: "tv_options_input_settings",
    version: 229,
    popularity: 29,
    codepoint: 57822,
    categories: ["Hardware"],
    tags: [
      "adjustments",
      "clockwork",
      "cog",
      "configuration",
      "factory",
      "gear",
      "gears",
      "industry",
      "input",
      "machine",
      "mechanical",
      "options",
      "refinery",
      "repair",
      "settings",
      "tv",
    ],
  },
  tv_remote: {
    name: "tv_remote",
    version: 229,
    popularity: 7,
    codepoint: 62937,
    categories: ["Hardware"],
    tags: ["connect", "google connect", "google tv"],
  },
  tv_signin: {
    name: "tv_signin",
    version: 229,
    popularity: 25,
    codepoint: 59163,
    categories: ["Hardware"],
    tags: [
      "Android",
      "OS",
      "account",
      "chrome",
      "desktop",
      "device",
      "face",
      "hardware",
      "human",
      "iOS",
      "login",
      "mac",
      "monitor",
      "people",
      "person",
      "profile",
      "signin",
      "tv",
      "user",
      "web",
      "window",
    ],
  },
  tv_with_assistant: {
    name: "tv_with_assistant",
    version: 229,
    popularity: 157,
    codepoint: 59269,
    categories: ["Household"],
    tags: [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "tv",
      "wireless",
    ],
  },
  two_pager: {
    name: "two_pager",
    version: 229,
    popularity: 2,
    codepoint: 62751,
    categories: ["Text Formatting"],
    tags: [
      "book",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "library",
      "page",
      "paper",
      "read",
      "reading",
      "sheet",
      "slide",
      "writing",
    ],
  },
  two_wheeler: {
    name: "two_wheeler",
    version: 14,
    popularity: 10366,
    codepoint: 59897,
    categories: ["maps"],
    tags: [
      "automobile",
      "bike",
      "car",
      "cars",
      "direction",
      "maps",
      "motorcycle",
      "public",
      "scooter",
      "sport",
      "transportation",
      "travel",
      "two wheeler",
      "vehicle",
    ],
  },
  type_specimen: {
    name: "type_specimen",
    version: 1,
    popularity: 405,
    codepoint: 63728,
    categories: ["editor"],
    tags: [],
  },
  u_turn_left: {
    name: "u_turn_left",
    version: 1,
    popularity: 1257,
    codepoint: 60321,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "u-turn",
    ],
  },
  u_turn_right: {
    name: "u_turn_right",
    version: 1,
    popularity: 918,
    codepoint: 60322,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "u-turn",
    ],
  },
  ulna_radius: {
    name: "ulna_radius",
    version: 229,
    popularity: 138,
    codepoint: 63645,
    categories: ["Social"],
    tags: [
      "arm",
      "body",
      "bone",
      "bones",
      "forearm",
      "health",
      "medical",
      "radius",
      "skeleton",
      "ulna",
    ],
  },
  ulna_radius_alt: {
    name: "ulna_radius_alt",
    version: 229,
    popularity: 130,
    codepoint: 63646,
    categories: ["Social"],
    tags: [
      "arm",
      "body",
      "bone",
      "bones",
      "forearm",
      "health",
      "medical",
      "radius",
      "skeleton",
      "ulna",
    ],
  },
  umbrella: {
    name: "umbrella",
    version: 8,
    popularity: 2579,
    codepoint: 61869,
    categories: ["places"],
    tags: ["beach", "protection", "rain", "sun", "sunny", "umbrella"],
  },
  unarchive: {
    name: "unarchive",
    version: 13,
    popularity: 7752,
    codepoint: 57705,
    categories: ["content"],
    tags: [
      "archive",
      "arrow",
      "inbox",
      "mail",
      "store",
      "unarchive",
      "undo",
      "up",
    ],
  },
  undo: {
    name: "undo",
    version: 13,
    popularity: 33751,
    codepoint: 57702,
    categories: ["content"],
    tags: [
      "arrow",
      "backward",
      "mail",
      "previous",
      "redo",
      "repeat",
      "rotate",
      "undo",
    ],
  },
  unfold_less: {
    name: "unfold_less",
    version: 12,
    popularity: 12353,
    codepoint: 58838,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up",
    ],
  },
  unfold_less_double: {
    name: "unfold_less_double",
    version: 1,
    popularity: 785,
    codepoint: 63695,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up",
    ],
  },
  unfold_more: {
    name: "unfold_more",
    version: 12,
    popularity: 32462,
    codepoint: 58839,
    categories: ["navigation"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold",
    ],
  },
  unfold_more_double: {
    name: "unfold_more_double",
    version: 1,
    popularity: 1098,
    codepoint: 63696,
    categories: ["action"],
    tags: [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold",
    ],
  },
  ungroup: {
    name: "ungroup",
    version: 229,
    popularity: 16,
    codepoint: 63281,
    categories: ["Text Formatting"],
    tags: ["arrow", "export", "external", "out", "right"],
  },
  universal_currency: {
    name: "universal_currency",
    version: 229,
    popularity: 35,
    codepoint: 59898,
    categories: ["Business&Payments"],
    tags: [
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "universal",
    ],
  },
  universal_currency_alt: {
    name: "universal_currency_alt",
    version: 229,
    popularity: 58,
    codepoint: 59188,
    categories: ["Business&Payments"],
    tags: [
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "universal",
    ],
  },
  universal_local: {
    name: "universal_local",
    version: 229,
    popularity: 27,
    codepoint: 59899,
    categories: ["Maps"],
    tags: [
      "destination",
      "direction",
      "local",
      "location",
      "map",
      "mark",
      "marker",
      "pin",
      "place",
      "spot",
      "universal",
    ],
  },
  unknown_2: {
    name: "unknown_2",
    version: 229,
    popularity: 15,
    codepoint: 59042,
    categories: ["Photo&Image"],
    tags: [
      "+",
      "2",
      "add",
      "adjust",
      "adjustments",
      "decrease",
      "edit",
      "editier",
      "editing",
      "filters",
      "increases",
      "plus",
      "subtract",
      "unknown",
    ],
  },
  unknown_5: {
    name: "unknown_5",
    version: 229,
    popularity: 13,
    codepoint: 59045,
    categories: ["UI actions"],
    tags: [
      "5",
      "adjust",
      "adjustments",
      "circle",
      "decrease",
      "edit",
      "editing",
      "filters",
      "minus",
      "remove",
      "subtract",
      "unknown",
    ],
  },
  unknown_document: {
    name: "unknown_document",
    version: 229,
    popularity: 34,
    codepoint: 63492,
    categories: ["Text Formatting"],
    tags: [
      "?",
      "assistance",
      "doc",
      "document",
      "file",
      "health",
      "help",
      "info",
      "information",
      "page",
      "paper",
      "punctuation",
      "question mark",
      "support",
      "symbol",
    ],
  },
  unknown_med: {
    name: "unknown_med",
    version: 229,
    popularity: 46,
    codepoint: 60093,
    categories: ["UI actions"],
    tags: ["dash", "dashes", "health", "miss", "missing", "unavailable"],
  },
  unlicense: {
    name: "unlicense",
    version: 229,
    popularity: 12,
    codepoint: 60165,
    categories: ["Common actions"],
    tags: [
      "certification",
      "degree",
      "disabled",
      "ecommerce",
      "enabled",
      "guarantee",
      "license",
      "medal",
      "off",
      "on",
      "permit",
      "premium",
      "ribbon",
      "slash",
      "verification",
    ],
  },
  unpublished: {
    name: "unpublished",
    version: 7,
    popularity: 10490,
    codepoint: 62006,
    categories: ["action"],
    tags: [
      "approve",
      "check",
      "circle",
      "complete",
      "disabled",
      "done",
      "enabled",
      "mark",
      "off",
      "ok",
      "on",
      "select",
      "slash",
      "tick",
      "unpublished",
      "validate",
      "verified",
      "yes",
    ],
  },
  unsubscribe: {
    name: "unsubscribe",
    version: 12,
    popularity: 5458,
    codepoint: 57579,
    categories: ["communication"],
    tags: [
      "cancel",
      "close",
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "newsletter",
      "off",
      "remove",
      "send",
      "subscribe",
      "unsubscribe",
    ],
  },
  upcoming: {
    name: "upcoming",
    version: 9,
    popularity: 5681,
    codepoint: 61566,
    categories: ["content"],
    tags: ["alarm", "calendar", "mail", "message", "notification", "upcoming"],
  },
  update: {
    name: "update",
    version: 13,
    popularity: 56247,
    codepoint: 59683,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "clock",
      "forward",
      "history",
      "load",
      "refresh",
      "reverse",
      "schedule",
      "time",
      "update",
    ],
  },
  update_disabled: {
    name: "update_disabled",
    version: 8,
    popularity: 3143,
    codepoint: 57461,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "disabled",
      "enabled",
      "forward",
      "history",
      "load",
      "off",
      "on",
      "refresh",
      "reverse",
      "rotate",
      "schedule",
      "slash",
      "time",
      "update",
    ],
  },
  upgrade: {
    name: "upgrade",
    version: 12,
    popularity: 14186,
    codepoint: 61691,
    categories: ["action"],
    tags: [
      "arrow",
      "export",
      "instal",
      "line",
      "replace",
      "up",
      "update",
      "upgrade",
    ],
  },
  upload: {
    name: "upload",
    version: 10,
    popularity: 22618,
    codepoint: 61595,
    categories: ["file"],
    tags: ["arrow", "arrows", "download", "drive", "up", "upload"],
  },
  upload_2: {
    name: "upload_2",
    version: 229,
    popularity: 2,
    codepoint: 62753,
    categories: ["UI actions"],
    tags: ["arrow", "arrows", "download", "drive", "up", "upload"],
  },
  upload_file: {
    name: "upload_file",
    version: 10,
    popularity: 43518,
    codepoint: 59900,
    categories: ["file"],
    tags: [
      "arrow",
      "data",
      "doc",
      "document",
      "download",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "sheet",
      "slide",
      "up",
      "upload",
      "writing",
    ],
  },
  urology: {
    name: "urology",
    version: 229,
    popularity: 2,
    codepoint: 57655,
    categories: ["Social"],
    tags: [
      "body",
      "health",
      "human",
      "medical",
      "organ",
      "organs",
      "tract",
      "urinary",
    ],
  },
  usb: {
    name: "usb",
    version: 12,
    popularity: 4480,
    codepoint: 57824,
    categories: ["device"],
    tags: ["cable", "connection", "device", "usb", "wire"],
  },
  usb_off: {
    name: "usb_off",
    version: 4,
    popularity: 1043,
    codepoint: 58618,
    categories: ["device"],
    tags: ["cable", "connection", "device", "off", "usb", "wire"],
  },
  user_attributes: {
    name: "user_attributes",
    version: 229,
    popularity: 101,
    codepoint: 59144,
    categories: ["Common actions"],
    tags: [
      "account",
      "attributes",
      "avatar",
      "face",
      "human",
      "list",
      "lists",
      "people",
      "person",
      "profile",
      "user",
    ],
  },
  vaccines: {
    name: "vaccines",
    version: 2,
    popularity: 9246,
    codepoint: 57656,
    categories: ["social"],
    tags: [
      "aid",
      "covid",
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "immunity",
      "injection",
      "medical",
      "medication",
      "medicine",
      "needle",
      "pharmacy",
      "sick",
      "syringe",
      "vaccination",
      "vaccines",
      "vial",
    ],
  },
  vacuum: {
    name: "vacuum",
    version: 229,
    popularity: 20,
    codepoint: 61381,
    categories: ["Household"],
    tags: [
      "canister",
      "clean",
      "cleaner",
      "cleaning",
      "home",
      "housekeeping",
      "machine",
      "upright",
      "vacuum",
      "vacuuming",
    ],
  },
  valve: {
    name: "valve",
    version: 229,
    popularity: 1084,
    codepoint: 57892,
    categories: ["Household"],
    tags: [
      "controller",
      "faucet",
      "gas",
      "lid",
      "nest",
      "pipe",
      "valve",
      "water",
    ],
  },
  vape_free: {
    name: "vape_free",
    version: 1,
    popularity: 481,
    codepoint: 60358,
    categories: ["places"],
    tags: [
      "disabled",
      "e-cigarette",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone",
    ],
  },
  vaping_rooms: {
    name: "vaping_rooms",
    version: 1,
    popularity: 615,
    codepoint: 60367,
    categories: ["places"],
    tags: [
      "allowed",
      "e-cigarette",
      "never",
      "no",
      "places",
      "prohibited",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone",
    ],
  },
  variable_add: {
    name: "variable_add",
    version: 229,
    popularity: 0,
    codepoint: 62750,
    categories: ["Text Formatting"],
    tags: [
      "+",
      "add",
      "building block",
      "chips",
      "data",
      "docs",
      "dynamic",
      "form",
      "new symbol",
      "plus",
      "rectangle",
      "symbol",
      "text fields",
      "variables",
    ],
  },
  variable_insert: {
    name: "variable_insert",
    version: 229,
    popularity: 0,
    codepoint: 62749,
    categories: ["Text Formatting"],
    tags: [
      "arrow",
      "arrows",
      "building block",
      "chips",
      "direction",
      "docs",
      "dynamic",
      "form",
      "insert",
      "left",
      "navigation",
      "rectangle",
      "text fields",
      "variables",
      "west",
    ],
  },
  variable_remove: {
    name: "variable_remove",
    version: 229,
    popularity: 0,
    codepoint: 62748,
    categories: ["Text Formatting"],
    tags: [
      "-",
      "building block",
      "chips",
      "data",
      "delete",
      "docs",
      "dynamic",
      "form",
      "minus",
      "rectangle",
      "remove",
      "subtract",
      "text fields",
      "variables",
    ],
  },
  variables: {
    name: "variables",
    version: 229,
    popularity: 57,
    codepoint: 63569,
    categories: ["Text Formatting"],
    tags: [
      "building block",
      "chips",
      "data",
      "docs",
      "dynamic",
      "form",
      "rectangle",
      "text fields",
    ],
  },
  ventilator: {
    name: "ventilator",
    version: 229,
    popularity: 1,
    codepoint: 57657,
    categories: ["Hardware"],
    tags: ["health", "lung", "lungs", "medical"],
  },
  verified: {
    name: "verified",
    version: 11,
    popularity: 141602,
    codepoint: 61302,
    categories: ["action"],
    tags: [
      "approve",
      "badge",
      "burst",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "star",
      "tick",
      "validate",
      "verified",
      "yes",
    ],
  },
  verified_user: {
    name: "verified_user",
    version: 18,
    popularity: 78345,
    codepoint: 59624,
    categories: ["action"],
    tags: [
      "approve",
      "certified",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "select",
      "shield",
      "tick",
      "user",
      "validate",
      "verified",
      "yes",
    ],
  },
  vertical_align_bottom: {
    name: "vertical_align_bottom",
    version: 12,
    popularity: 7369,
    codepoint: 57944,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "arrow",
      "bottom",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "vertical",
      "writing",
    ],
  },
  vertical_align_center: {
    name: "vertical_align_center",
    version: 12,
    popularity: 3148,
    codepoint: 57945,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "arrow",
      "center",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "up",
      "vertical",
      "writing",
    ],
  },
  vertical_align_top: {
    name: "vertical_align_top",
    version: 12,
    popularity: 6915,
    codepoint: 57946,
    categories: ["editor"],
    tags: [
      "align",
      "alignment",
      "arrow",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "top",
      "type",
      "up",
      "vertical",
      "writing",
    ],
  },
  vertical_distribute: {
    name: "vertical_distribute",
    version: 6,
    popularity: 1958,
    codepoint: 57462,
    categories: ["editor"],
    tags: [
      "alignment",
      "distribute",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical",
    ],
  },
  vertical_shades: {
    name: "vertical_shades",
    version: 1,
    popularity: 534,
    codepoint: 60430,
    categories: ["home"],
    tags: [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade",
      "vertical",
    ],
  },
  vertical_shades_closed: {
    name: "vertical_shades_closed",
    version: 1,
    popularity: 528,
    codepoint: 60429,
    categories: ["home"],
    tags: [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade",
    ],
  },
  vertical_split: {
    name: "vertical_split",
    version: 15,
    popularity: 7251,
    codepoint: 59721,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "layout",
      "paragraph",
      "split",
      "text",
      "vertical",
      "website",
      "writing",
    ],
  },
  vibration: {
    name: "vibration",
    version: 12,
    popularity: 3639,
    codepoint: 58925,
    categories: ["notification"],
    tags: [
      "Android",
      "OS",
      "alert",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "motion",
      "notification",
      "phone",
      "silence",
      "silent",
      "tablet",
      "vibrate",
      "vibration",
    ],
  },
  video_call: {
    name: "video_call",
    version: 11,
    popularity: 13668,
    codepoint: 57456,
    categories: ["av"],
    tags: [
      "+",
      "add",
      "call",
      "camera",
      "chat",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "new",
      "picture",
      "plus",
      "symbol",
      "video",
      "videography",
    ],
  },
  video_camera_back: {
    name: "video_camera_back",
    version: 10,
    popularity: 3422,
    codepoint: 61567,
    categories: ["image"],
    tags: [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear",
      "video",
    ],
  },
  video_camera_front: {
    name: "video_camera_front",
    version: 10,
    popularity: 10023,
    codepoint: 61568,
    categories: ["image"],
    tags: [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "user",
      "video",
    ],
  },
  video_camera_front_off: {
    name: "video_camera_front_off",
    version: 229,
    popularity: 29,
    codepoint: 63547,
    categories: ["Audio&Video"],
    tags: [
      "account",
      "camera",
      "disabled",
      "enabled",
      "face",
      "front",
      "human",
      "image",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "slash",
      "user",
      "video",
    ],
  },
  video_chat: {
    name: "video_chat",
    version: 1,
    popularity: 502,
    codepoint: 63648,
    categories: ["notification"],
    tags: [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice",
    ],
  },
  video_file: {
    name: "video_file",
    version: 1,
    popularity: 2117,
    codepoint: 60295,
    categories: ["av"],
    tags: [
      "camera",
      "doc",
      "document",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography",
    ],
  },
  video_label: {
    name: "video_label",
    version: 11,
    popularity: 1805,
    codepoint: 57457,
    categories: ["av"],
    tags: ["label", "screen", "video", "window"],
  },
  video_library: {
    name: "video_library",
    version: 13,
    popularity: 16294,
    codepoint: 57418,
    categories: ["av"],
    tags: ["arrow", "collection", "library", "play", "video"],
  },
  video_search: {
    name: "video_search",
    version: 229,
    popularity: 35,
    codepoint: 61382,
    categories: ["Audio&Video"],
    tags: [
      "find",
      "glass",
      "look",
      "magnifying",
      "play",
      "search",
      "see",
      "video",
    ],
  },
  video_settings: {
    name: "video_settings",
    version: 12,
    popularity: 4864,
    codepoint: 60021,
    categories: ["av"],
    tags: [
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "play",
      "screen",
      "service",
      "setting",
      "settings",
      "video",
      "window",
    ],
  },
  video_stable: {
    name: "video_stable",
    version: 10,
    popularity: 1141,
    codepoint: 61569,
    categories: ["image"],
    tags: [
      "film",
      "filming",
      "recording",
      "setting",
      "stability",
      "stable",
      "taping",
      "video",
    ],
  },
  videocam: {
    name: "videocam",
    version: 16,
    popularity: 64373,
    codepoint: 57419,
    categories: ["av"],
    tags: [
      "cam",
      "camera",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography",
    ],
  },
  videocam_off: {
    name: "videocam_off",
    version: 12,
    popularity: 9091,
    codepoint: 57420,
    categories: ["av"],
    tags: [
      "cam",
      "camera",
      "conference",
      "disabled",
      "enabled",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "off",
      "offline",
      "on",
      "picture",
      "slash",
      "video",
      "videography",
    ],
  },
  videogame_asset: {
    name: "videogame_asset",
    version: 11,
    popularity: 8707,
    codepoint: 58168,
    categories: ["hardware"],
    tags: [
      "asset",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video",
    ],
  },
  videogame_asset_off: {
    name: "videogame_asset_off",
    version: 4,
    popularity: 962,
    codepoint: 58624,
    categories: ["hardware"],
    tags: [
      "asset",
      "console",
      "controller",
      "device",
      "disabled",
      "enabled",
      "game",
      "gamepad",
      "gaming",
      "off",
      "on",
      "playstation",
      "slash",
      "video",
      "videogame",
    ],
  },
  view_agenda: {
    name: "view_agenda",
    version: 14,
    popularity: 11091,
    codepoint: 59625,
    categories: ["action"],
    tags: [
      "agenda",
      "cards",
      "design",
      "format",
      "grid",
      "layout",
      "stacked",
      "view",
      "website",
    ],
  },
  view_array: {
    name: "view_array",
    version: 13,
    popularity: 2686,
    codepoint: 59626,
    categories: ["action"],
    tags: ["array", "design", "format", "grid", "layout", "view", "website"],
  },
  view_carousel: {
    name: "view_carousel",
    version: 13,
    popularity: 8199,
    codepoint: 59627,
    categories: ["action"],
    tags: [
      "cards",
      "carousel",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website",
    ],
  },
  view_column: {
    name: "view_column",
    version: 13,
    popularity: 12349,
    codepoint: 59628,
    categories: ["action"],
    tags: [
      "column",
      "design",
      "format",
      "grid",
      "layout",
      "vertical",
      "view",
      "website",
    ],
  },
  view_column_2: {
    name: "view_column_2",
    version: 229,
    popularity: 87,
    codepoint: 63559,
    categories: ["Text Formatting"],
    tags: [
      "column",
      "design",
      "format",
      "grid",
      "layout",
      "vertical",
      "view",
      "website",
    ],
  },
  view_comfy: {
    name: "view_comfy",
    version: 14,
    popularity: 4609,
    codepoint: 58410,
    categories: ["image"],
    tags: ["comfy", "grid", "layout", "pattern", "squares", "view"],
  },
  view_comfy_alt: {
    name: "view_comfy_alt",
    version: 1,
    popularity: 1424,
    codepoint: 60275,
    categories: ["action"],
    tags: ["alt", "comfy", "cozy", "design", "format", "layout", "view", "web"],
  },
  view_compact: {
    name: "view_compact",
    version: 13,
    popularity: 3540,
    codepoint: 58411,
    categories: ["image"],
    tags: ["compact", "grid", "layout", "pattern", "squares", "view"],
  },
  view_compact_alt: {
    name: "view_compact_alt",
    version: 1,
    popularity: 1335,
    codepoint: 60276,
    categories: ["action"],
    tags: ["alt", "compact", "design", "format", "layout dense", "view", "web"],
  },
  view_cozy: {
    name: "view_cozy",
    version: 1,
    popularity: 1751,
    codepoint: 60277,
    categories: ["action"],
    tags: ["comfy", "cozy", "design", "format", "layout", "view", "web"],
  },
  view_day: {
    name: "view_day",
    version: 13,
    popularity: 4799,
    codepoint: 59629,
    categories: ["action"],
    tags: [
      "cards",
      "carousel",
      "day",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website",
    ],
  },
  view_headline: {
    name: "view_headline",
    version: 11,
    popularity: 20950,
    codepoint: 59630,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "headline",
      "layout",
      "paragraph",
      "text",
      "view",
      "website",
    ],
  },
  view_in_ar: {
    name: "view_in_ar",
    version: 11,
    popularity: 33760,
    codepoint: 59902,
    categories: ["action"],
    tags: [
      "3d",
      "ar",
      "augmented",
      "cube",
      "daydream",
      "headset",
      "in",
      "reality",
      "square",
      "view",
      "vr",
    ],
  },
  view_in_ar_off: {
    name: "view_in_ar_off",
    version: 229,
    popularity: 2,
    codepoint: 63003,
    categories: ["Audio&Video"],
    tags: [
      "3d",
      "ar",
      "augmented",
      "cube",
      "daydream",
      "disabled",
      "enabled",
      "headset",
      "in",
      "off",
      "offline",
      "on",
      "reality",
      "slash",
      "square",
      "view",
      "vr",
    ],
  },
  view_kanban: {
    name: "view_kanban",
    version: 1,
    popularity: 2091,
    codepoint: 60287,
    categories: ["action"],
    tags: ["grid", "kanban", "layout", "pattern", "squares", "view"],
  },
  view_list: {
    name: "view_list",
    version: 14,
    popularity: 64774,
    codepoint: 59631,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "view",
      "website",
    ],
  },
  view_module: {
    name: "view_module",
    version: 13,
    popularity: 17163,
    codepoint: 59632,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "layout",
      "module",
      "square",
      "squares",
      "stacked",
      "view",
      "website",
    ],
  },
  view_quilt: {
    name: "view_quilt",
    version: 14,
    popularity: 8166,
    codepoint: 59633,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "layout",
      "quilt",
      "square",
      "squares",
      "stacked",
      "view",
      "website",
    ],
  },
  view_sidebar: {
    name: "view_sidebar",
    version: 13,
    popularity: 7381,
    codepoint: 61716,
    categories: ["action"],
    tags: ["design", "format", "grid", "layout", "sidebar", "view", "web"],
  },
  view_stream: {
    name: "view_stream",
    version: 13,
    popularity: 6774,
    codepoint: 59634,
    categories: ["action"],
    tags: [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "stream",
      "view",
      "website",
    ],
  },
  view_timeline: {
    name: "view_timeline",
    version: 1,
    popularity: 4728,
    codepoint: 60293,
    categories: ["action"],
    tags: ["grid", "layout", "pattern", "squares", "timeline", "view"],
  },
  view_week: {
    name: "view_week",
    version: 13,
    popularity: 9847,
    codepoint: 59635,
    categories: ["action"],
    tags: [
      "bars",
      "columns",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website",
      "week",
    ],
  },
  vignette: {
    name: "vignette",
    version: 12,
    popularity: 1049,
    codepoint: 58421,
    categories: ["image"],
    tags: [
      "border",
      "edit",
      "editing",
      "filter",
      "gradient",
      "image",
      "photo",
      "photography",
      "setting",
      "vignette",
    ],
  },
  villa: {
    name: "villa",
    version: 4,
    popularity: 4959,
    codepoint: 58758,
    categories: ["places"],
    tags: [
      "architecture",
      "beach",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "traveling",
      "vacation stay",
      "villa",
    ],
  },
  visibility: {
    name: "visibility",
    version: 16,
    popularity: 299781,
    codepoint: 59636,
    categories: ["action"],
    tags: ["eye", "on", "reveal", "see", "show", "view", "visibility"],
  },
  visibility_lock: {
    name: "visibility_lock",
    version: 229,
    popularity: 80,
    codepoint: 63059,
    categories: ["Common actions"],
    tags: [
      "eye",
      "lock",
      "locked",
      "on",
      "password",
      "privacy",
      "private",
      "protect",
      "protection",
      "reveal",
      "safety",
      "secure",
      "security",
      "see",
      "show",
      "view",
      "visibility",
    ],
  },
  visibility_off: {
    name: "visibility_off",
    version: 12,
    popularity: 121929,
    codepoint: 59637,
    categories: ["action"],
    tags: [
      "disabled",
      "enabled",
      "eye",
      "off",
      "on",
      "reveal",
      "see",
      "show",
      "slash",
      "view",
      "visibility",
    ],
  },
  vital_signs: {
    name: "vital_signs",
    version: 229,
    popularity: 78,
    codepoint: 58960,
    categories: ["Social"],
    tags: [
      "baseline",
      "device",
      "ecc",
      "ecg",
      "fitness",
      "health",
      "heart",
      "medical",
      "monitor",
      "track",
    ],
  },
  voice_chat: {
    name: "voice_chat",
    version: 13,
    popularity: 3814,
    codepoint: 58926,
    categories: ["notification"],
    tags: [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice",
    ],
  },
  voice_over_off: {
    name: "voice_over_off",
    version: 11,
    popularity: 2973,
    codepoint: 59722,
    categories: ["action"],
    tags: [
      "account",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "over",
      "people",
      "person",
      "profile",
      "recording",
      "slash",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice",
    ],
  },
  voice_selection: {
    name: "voice_selection",
    version: 229,
    popularity: 66,
    codepoint: 62858,
    categories: ["Audio&Video"],
    tags: [
      "chat",
      "face",
      "human",
      "mouth",
      "person",
      "read",
      "read aloud",
      "social",
      "talk",
      "talking",
    ],
  },
  voicemail: {
    name: "voicemail",
    version: 12,
    popularity: 3102,
    codepoint: 57561,
    categories: ["communication"],
    tags: [
      "call",
      "device",
      "message",
      "missed",
      "mobile",
      "phone",
      "recording",
      "voice",
      "voicemail",
    ],
  },
  volcano: {
    name: "volcano",
    version: 1,
    popularity: 617,
    codepoint: 60378,
    categories: ["social"],
    tags: [
      "crisis",
      "disaster",
      "eruption",
      "lava",
      "magma",
      "natural",
      "volcano",
    ],
  },
  volume_down: {
    name: "volume_down",
    version: 13,
    popularity: 9107,
    codepoint: 57421,
    categories: ["av"],
    tags: [
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume",
    ],
  },
  volume_down_alt: {
    name: "volume_down_alt",
    version: 3,
    popularity: 547,
    codepoint: 59292,
    categories: ["av"],
    tags: [
      "alt",
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume",
    ],
  },
  volume_mute: {
    name: "volume_mute",
    version: 13,
    popularity: 13581,
    codepoint: 57422,
    categories: ["av"],
    tags: [
      "audio",
      "control",
      "music",
      "mute",
      "sound",
      "speaker",
      "tv",
      "volume",
    ],
  },
  volume_off: {
    name: "volume_off",
    version: 17,
    popularity: 31533,
    codepoint: 57423,
    categories: ["av"],
    tags: [
      "audio",
      "control",
      "disabled",
      "enabled",
      "low",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "speaker",
      "tv",
      "volume",
    ],
  },
  volume_up: {
    name: "volume_up",
    version: 14,
    popularity: 57281,
    codepoint: 57424,
    categories: ["av"],
    tags: [
      "audio",
      "control",
      "music",
      "sound",
      "speaker",
      "tv",
      "up",
      "volume",
    ],
  },
  volunteer_activism: {
    name: "volunteer_activism",
    version: 10,
    popularity: 34882,
    codepoint: 60016,
    categories: ["maps"],
    tags: [
      "activism",
      "donation",
      "fingers",
      "gesture",
      "giving",
      "hand",
      "hands",
      "heart",
      "love",
      "sharing",
      "volunteer",
    ],
  },
  voting_chip: {
    name: "voting_chip",
    version: 229,
    popularity: 34,
    codepoint: 63570,
    categories: ["Text Formatting"],
    tags: ["+", "1", "add", "chip", "new", "one", "plus", "vote"],
  },
  vpn_key: {
    name: "vpn_key",
    version: 12,
    popularity: 46050,
    codepoint: 57562,
    categories: ["communication"],
    tags: [
      "access",
      "code",
      "door",
      "entry",
      "key",
      "lock",
      "network",
      "passcode",
      "password",
      "unlock",
      "vpn",
    ],
  },
  vpn_key_alert: {
    name: "vpn_key_alert",
    version: 229,
    popularity: 8,
    codepoint: 63180,
    categories: ["Privacy&Security"],
    tags: [
      "!",
      "access",
      "alert",
      "attention",
      "caution",
      "code",
      "danger",
      "door",
      "entry",
      "error",
      "exclamation",
      "feedback",
      "important",
      "key",
      "lock",
      "mark",
      "network",
      "notification",
      "passcode",
      "password",
      "problem",
      "report",
      "unlock",
      "vpn",
      "warning",
    ],
  },
  vpn_key_off: {
    name: "vpn_key_off",
    version: 1,
    popularity: 986,
    codepoint: 60282,
    categories: ["communication"],
    tags: [
      "access",
      "code",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "network",
      "off",
      "offline",
      "on",
      "passcode",
      "password",
      "slash",
      "unlock",
      "vpn",
    ],
  },
  vpn_lock: {
    name: "vpn_lock",
    version: 17,
    popularity: 6620,
    codepoint: 58927,
    categories: ["notification"],
    tags: [
      "earth",
      "globe",
      "lock",
      "locked",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "virtual",
      "vpn",
      "world",
    ],
  },
  vr180_create2d: {
    name: "vr180_create2d",
    version: 229,
    popularity: 43,
    codepoint: 61386,
    categories: ["Photo&Image"],
    tags: [
      "180",
      "2d",
      "artificial",
      "circle",
      "create",
      "create2d",
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "offline",
      "on",
      "photo",
      "photography",
      "picture",
      "reality",
      "slash",
      "vertical",
      "vr",
      "vr180",
    ],
  },
  vr180_create2d_off: {
    name: "vr180_create2d_off",
    version: 229,
    popularity: 11,
    codepoint: 62833,
    categories: ["Photo&Image"],
    tags: [
      "180",
      "2d",
      "artificial",
      "circle",
      "create",
      "create2d",
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "offline",
      "on",
      "photo",
      "photography",
      "picture",
      "reality",
      "slash",
      "vertical",
      "vr",
      "vr180",
    ],
  },
  vrpano: {
    name: "vrpano",
    version: 10,
    popularity: 2435,
    codepoint: 61570,
    categories: ["image"],
    tags: [
      "angle",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "vrpano",
      "wide",
    ],
  },
  wall_art: {
    name: "wall_art",
    version: 229,
    popularity: 16,
    codepoint: 61387,
    categories: ["Household"],
    tags: [
      "art",
      "frame",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "wall",
    ],
  },
  wall_lamp: {
    name: "wall_lamp",
    version: 229,
    popularity: 96,
    codepoint: 58036,
    categories: ["Home"],
    tags: ["home", "lamp", "light", "sconce", "wall"],
  },
  wallet: {
    name: "wallet",
    version: 1,
    popularity: 4906,
    codepoint: 63743,
    categories: ["social"],
    tags: [],
  },
  wallpaper: {
    name: "wallpaper",
    version: 13,
    popularity: 7159,
    codepoint: 57788,
    categories: ["device"],
    tags: [
      "background",
      "dash",
      "dashed",
      "image",
      "landscape",
      "photo",
      "photography",
      "picture",
      "wallpaper",
    ],
  },
  wallpaper_slideshow: {
    name: "wallpaper_slideshow",
    version: 229,
    popularity: 8,
    codepoint: 63090,
    categories: ["Android"],
    tags: [
      "background",
      "image",
      "landscape",
      "layers",
      "multiple",
      "photo",
      "photography",
      "picture",
      "slides",
      "stack",
      "wallpaper",
    ],
  },
  ward: {
    name: "ward",
    version: 229,
    popularity: 7,
    codepoint: 57660,
    categories: ["Social"],
    tags: [
      "bed",
      "body",
      "clinic",
      "health",
      "hospital",
      "human",
      "patient",
      "people",
      "person",
    ],
  },
  warehouse: {
    name: "warehouse",
    version: 1,
    popularity: 8597,
    codepoint: 60344,
    categories: ["maps"],
    tags: ["garage", "industry", "manufacturing", "storage", "warehouse"],
  },
  warning: {
    name: "warning",
    version: 17,
    popularity: 115764,
    codepoint: 57346,
    categories: ["alert"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "triangle",
      "warning",
    ],
  },
  warning_off: {
    name: "warning_off",
    version: 229,
    popularity: 7,
    codepoint: 63405,
    categories: ["Common actions"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "enabled",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "off",
      "offline",
      "on",
      "slash",
      "symbol",
      "triangle",
      "warning",
    ],
  },
  wash: {
    name: "wash",
    version: 8,
    popularity: 3288,
    codepoint: 61873,
    categories: ["places"],
    tags: ["bathroom", "clean", "fingers", "gesture", "hand", "wash", "wc"],
  },
  watch: {
    name: "watch",
    version: 12,
    popularity: 6465,
    codepoint: 58164,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "ar",
      "clock",
      "gadget",
      "iOS",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  watch_button_press: {
    name: "watch_button_press",
    version: 229,
    popularity: 1,
    codepoint: 63146,
    categories: ["Hardware"],
    tags: [
      "ar",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  watch_off: {
    name: "watch_off",
    version: 1,
    popularity: 616,
    codepoint: 60131,
    categories: ["hardware"],
    tags: [
      "Android",
      "OS",
      "ar",
      "clock",
      "close",
      "gadget",
      "iOS",
      "off",
      "shut",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  watch_screentime: {
    name: "watch_screentime",
    version: 229,
    popularity: 20,
    codepoint: 63150,
    categories: ["Common actions"],
    tags: [
      "ar",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  watch_wake: {
    name: "watch_wake",
    version: 229,
    popularity: 6,
    codepoint: 63145,
    categories: ["Hardware"],
    tags: [
      "ar",
      "awaken",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch",
    ],
  },
  water: {
    name: "water",
    version: 11,
    popularity: 8953,
    codepoint: 61572,
    categories: ["device"],
    tags: [
      "aqua",
      "beach",
      "lake",
      "ocean",
      "river",
      "water",
      "waves",
      "weather",
    ],
  },
  water_bottle: {
    name: "water_bottle",
    version: 229,
    popularity: 15,
    codepoint: 63133,
    categories: ["Social"],
    tags: ["hydrate", "hydration"],
  },
  water_bottle_large: {
    name: "water_bottle_large",
    version: 229,
    popularity: 4,
    codepoint: 63134,
    categories: ["Social"],
    tags: ["hydrate", "hydration"],
  },
  water_damage: {
    name: "water_damage",
    version: 6,
    popularity: 2953,
    codepoint: 61955,
    categories: ["places"],
    tags: [
      "architecture",
      "building",
      "damage",
      "drop",
      "droplet",
      "estate",
      "house",
      "leak",
      "plumbing",
      "real",
      "residence",
      "residential",
      "shelter",
      "water",
    ],
  },
  water_do: {
    name: "water_do",
    version: 229,
    popularity: 5,
    codepoint: 63600,
    categories: ["Social"],
    tags: ["dissolved", "droplets", "oxygen", "water"],
  },
  water_drop: {
    name: "water_drop",
    version: 3,
    popularity: 31455,
    codepoint: 59288,
    categories: ["social"],
    tags: [
      "drink",
      "drop",
      "droplet",
      "eco",
      "liquid",
      "nature",
      "ocean",
      "rain",
      "social",
      "water",
    ],
  },
  water_ec: {
    name: "water_ec",
    version: 229,
    popularity: 2,
    codepoint: 63605,
    categories: ["Social"],
    tags: ["current", "droplet", "electrical", "electricity", "water"],
  },
  water_full: {
    name: "water_full",
    version: 229,
    popularity: 22,
    codepoint: 63190,
    categories: ["Activities"],
    tags: ["drink", "drinking", "fitbit", "water", "waterglass"],
  },
  water_heater: {
    name: "water_heater",
    version: 229,
    popularity: 350,
    codepoint: 57988,
    categories: ["Household"],
    tags: [
      "appliance",
      "device",
      "home",
      "hot",
      "house",
      "nest",
      "water heater",
    ],
  },
  water_lock: {
    name: "water_lock",
    version: 229,
    popularity: 11,
    codepoint: 63149,
    categories: ["Common actions"],
    tags: [
      "device",
      "droplet",
      "fitbit",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "tracker",
      "watch",
    ],
  },
  water_loss: {
    name: "water_loss",
    version: 229,
    popularity: 9,
    codepoint: 63189,
    categories: ["Activities"],
    tags: ["drink", "drinking", "fitbit", "water", "waterglass"],
  },
  water_lux: {
    name: "water_lux",
    version: 229,
    popularity: 8,
    codepoint: 63604,
    categories: ["Social"],
    tags: [
      "droplets",
      "lighting",
      "reflection",
      "refraction",
      "sun",
      "sunny",
      "sunrise",
      "water",
      "waves",
    ],
  },
  water_medium: {
    name: "water_medium",
    version: 229,
    popularity: 25,
    codepoint: 63188,
    categories: ["Activities"],
    tags: ["drink", "drinking", "fitbit", "water", "waterglass"],
  },
  water_orp: {
    name: "water_orp",
    version: 229,
    popularity: 4,
    codepoint: 63608,
    categories: ["Social"],
    tags: ["droplet", "oxidation", "oxygen", "potential", "reduction", "water"],
  },
  water_ph: {
    name: "water_ph",
    version: 229,
    popularity: 6,
    codepoint: 63610,
    categories: ["Social"],
    tags: ["acidic", "droplet", "hydrogen", "water"],
  },
  water_pump: {
    name: "water_pump",
    version: 229,
    popularity: 51,
    codepoint: 62936,
    categories: ["Home"],
    tags: [
      "fluids",
      "groundwater intake",
      "heating",
      "pumping",
      "rainwater harvesting",
      "wastewater",
      "water pressure",
      "water pressure boosting",
    ],
  },
  water_voc: {
    name: "water_voc",
    version: 229,
    popularity: 6,
    codepoint: 63611,
    categories: ["Social"],
    tags: [
      "chemicals",
      "compounds",
      "droplet",
      "organic",
      "vapor",
      "voc",
      "volatile",
      "water",
    ],
  },
  waterfall_chart: {
    name: "waterfall_chart",
    version: 11,
    popularity: 4920,
    codepoint: 59904,
    categories: ["navigation"],
    tags: [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "waterfall",
    ],
  },
  waves: {
    name: "waves",
    version: 11,
    popularity: 8279,
    codepoint: 57718,
    categories: ["content"],
    tags: [
      "beach",
      "lake",
      "ocean",
      "pool",
      "river",
      "sea",
      "swim",
      "water",
      "wave",
      "waves",
    ],
  },
  waving_hand: {
    name: "waving_hand",
    version: 3,
    popularity: 10644,
    codepoint: 59238,
    categories: ["social"],
    tags: [
      "bye",
      "fingers",
      "gesture",
      "goodbye",
      "greetings",
      "hand",
      "hello",
      "palm",
      "wave",
      "waving",
    ],
  },
  wb_auto: {
    name: "wb_auto",
    version: 12,
    popularity: 766,
    codepoint: 58412,
    categories: ["image"],
    tags: [
      "A",
      "W",
      "alphabet",
      "auto",
      "automatic",
      "balance",
      "character",
      "edit",
      "editing",
      "font",
      "image",
      "letter",
      "photo",
      "photography",
      "symbol",
      "text",
      "type",
      "white",
      "wp",
    ],
  },
  wb_incandescent: {
    name: "wb_incandescent",
    version: 12,
    popularity: 6673,
    codepoint: 58414,
    categories: ["image"],
    tags: [
      "balance",
      "bright",
      "edit",
      "editing",
      "incandescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp",
    ],
  },
  wb_iridescent: {
    name: "wb_iridescent",
    version: 11,
    popularity: 1426,
    codepoint: 58422,
    categories: ["image"],
    tags: [
      "balance",
      "bright",
      "edit",
      "editing",
      "iridescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp",
    ],
  },
  wb_shade: {
    name: "wb_shade",
    version: 10,
    popularity: 1111,
    codepoint: 59905,
    categories: ["image"],
    tags: ["balance", "house", "light", "lighting", "shade", "wb", "white"],
  },
  wb_sunny: {
    name: "wb_sunny",
    version: 12,
    popularity: 22277,
    codepoint: 58416,
    categories: ["image"],
    tags: [
      "balance",
      "bright",
      "light",
      "lighting",
      "sun",
      "sunny",
      "wb",
      "white",
    ],
  },
  wb_twilight: {
    name: "wb_twilight",
    version: 9,
    popularity: 2845,
    codepoint: 57798,
    categories: ["image"],
    tags: [
      "balance",
      "light",
      "lighting",
      "noon",
      "sun",
      "sunset",
      "twilight",
      "wb",
      "white",
    ],
  },
  wc: {
    name: "wc",
    version: 11,
    popularity: 10686,
    codepoint: 58941,
    categories: ["notification"],
    tags: [
      "bathroom",
      "closet",
      "female",
      "male",
      "man",
      "restroom",
      "room",
      "wash",
      "water",
      "wc",
      "women",
    ],
  },
  weather_hail: {
    name: "weather_hail",
    version: 229,
    popularity: 23,
    codepoint: 63103,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "hailstones",
      "hailstorm",
      "home",
      "nest",
      "sleet",
      "snow",
      "snowy",
      "thermostat",
      "weather",
    ],
  },
  weather_mix: {
    name: "weather_mix",
    version: 229,
    popularity: 20,
    codepoint: 62987,
    categories: ["Social"],
    tags: [
      "climate",
      "cloud",
      "cloudy",
      "cold",
      "hail",
      "mixed conditions",
      "rain",
      "snow",
      "temperature",
      "weather",
      "winter",
    ],
  },
  weather_snowy: {
    name: "weather_snowy",
    version: 229,
    popularity: 398,
    codepoint: 58061,
    categories: ["Home"],
    tags: ["climate", "cloud", "home", "nest", "snow", "thermostat", "weather"],
  },
  web: {
    name: "web",
    version: 13,
    popularity: 16621,
    codepoint: 57425,
    categories: ["av"],
    tags: [
      "browser",
      "internet",
      "page",
      "screen",
      "site",
      "web",
      "website",
      "www",
    ],
  },
  web_asset: {
    name: "web_asset",
    version: 12,
    popularity: 8195,
    codepoint: 57449,
    categories: ["av"],
    tags: [
      "-website",
      "app",
      "application desktop",
      "asset",
      "browser",
      "design",
      "download",
      "image",
      "interface",
      "internet",
      "layout",
      "screen",
      "site",
      "ui",
      "ux",
      "video",
      "web",
      "website",
      "window",
      "www",
    ],
  },
  web_asset_off: {
    name: "web_asset_off",
    version: 4,
    popularity: 1393,
    codepoint: 58615,
    categories: ["av"],
    tags: [
      "asset",
      "browser",
      "disabled",
      "enabled",
      "internet",
      "off",
      "on",
      "page",
      "screen",
      "slash",
      "web",
      "webpage",
      "website",
      "windows",
      "www",
    ],
  },
  web_stories: {
    name: "web_stories",
    version: 4,
    popularity: 2072,
    codepoint: 58773,
    categories: ["content"],
    tags: ["google", "images", "logo", "stories", "web"],
  },
  web_traffic: {
    name: "web_traffic",
    version: 229,
    popularity: 363,
    codepoint: 59907,
    categories: ["Common actions"],
    tags: [
      "browser",
      "click",
      "clicks",
      "cursor",
      "internet",
      "traffic",
      "web",
    ],
  },
  webhook: {
    name: "webhook",
    version: 1,
    popularity: 4529,
    codepoint: 60306,
    categories: ["action"],
    tags: [
      "api",
      "developer",
      "development",
      "enterprise",
      "software",
      "webhook",
    ],
  },
  weekend: {
    name: "weekend",
    version: 12,
    popularity: 5967,
    codepoint: 57707,
    categories: ["content"],
    tags: [
      "chair",
      "couch",
      "furniture",
      "home",
      "living",
      "lounge",
      "relax",
      "room",
      "weekend",
    ],
  },
  weight: {
    name: "weight",
    version: 229,
    popularity: 1023,
    codepoint: 57661,
    categories: ["Social"],
    tags: ["grams", "kg", "lbs", "measure", "pounds", "stones", "tonne"],
  },
  west: {
    name: "west",
    version: 7,
    popularity: 25697,
    codepoint: 61926,
    categories: ["navigation"],
    tags: ["arrow", "directional", "left", "maps", "navigation", "west"],
  },
  whatshot: {
    name: "whatshot",
    version: 17,
    popularity: 15048,
    codepoint: 59406,
    categories: ["social"],
    tags: [
      "arrow",
      "circle",
      "direction",
      "fire",
      "frames",
      "hot",
      "round",
      "whatshot",
    ],
  },
  wheelchair_pickup: {
    name: "wheelchair_pickup",
    version: 8,
    popularity: 2086,
    codepoint: 61867,
    categories: ["places"],
    tags: [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "person",
      "pickup",
      "wheelchair",
    ],
  },
  where_to_vote: {
    name: "where_to_vote",
    version: 16,
    popularity: 8814,
    codepoint: 57719,
    categories: ["content"],
    tags: [
      "approve",
      "ballot",
      "check",
      "complete",
      "destination",
      "direction",
      "done",
      "location",
      "maps",
      "mark",
      "ok",
      "pin",
      "place",
      "poll",
      "select",
      "stop",
      "tick",
      "to",
      "validate election",
      "verified",
      "vote",
      "where",
      "yes",
    ],
  },
  widgets: {
    name: "widgets",
    version: 13,
    popularity: 27228,
    codepoint: 57789,
    categories: ["device"],
    tags: ["app", "box", "menu", "setting", "squares", "ui", "widgets"],
  },
  width: {
    name: "width",
    version: 229,
    popularity: 39,
    codepoint: 63280,
    categories: ["Text Formatting"],
    tags: ["arrow", "arrows", "double", "expand", "resize", "size"],
  },
  width_full: {
    name: "width_full",
    version: 1,
    popularity: 816,
    codepoint: 63733,
    categories: ["action"],
    tags: [],
  },
  width_normal: {
    name: "width_normal",
    version: 1,
    popularity: 716,
    codepoint: 63734,
    categories: ["action"],
    tags: [],
  },
  width_wide: {
    name: "width_wide",
    version: 1,
    popularity: 673,
    codepoint: 63735,
    categories: ["action"],
    tags: [],
  },
  wifi: {
    name: "wifi",
    version: 12,
    popularity: 49731,
    codepoint: 58942,
    categories: ["notification"],
    tags: [
      "connection",
      "data",
      "internet",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_1_bar: {
    name: "wifi_1_bar",
    version: 1,
    popularity: 807,
    codepoint: 58570,
    categories: ["device"],
    tags: [
      "1",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_2_bar: {
    name: "wifi_2_bar",
    version: 1,
    popularity: 918,
    codepoint: 58585,
    categories: ["device"],
    tags: [
      "2",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_add: {
    name: "wifi_add",
    version: 229,
    popularity: 5,
    codepoint: 63400,
    categories: ["Communication"],
    tags: [
      "+",
      "add",
      "connection",
      "data",
      "internet",
      "network",
      "new symbol",
      "plus",
      "scan",
      "service",
      "signal",
      "symbol",
      "wifi",
      "wireless",
    ],
  },
  wifi_calling: {
    name: "wifi_calling",
    version: 11,
    popularity: 1689,
    codepoint: 61303,
    categories: ["communication"],
    tags: [
      "call",
      "calling",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "telephone",
      "wifi",
      "wireless",
    ],
  },
  wifi_calling_1: {
    name: "wifi_calling_1",
    version: 10,
    popularity: 1,
    codepoint: 61671,
    categories: ["device"],
    tags: [
      "1",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  wifi_calling_2: {
    name: "wifi_calling_2",
    version: 10,
    popularity: 3,
    codepoint: 61686,
    categories: ["device"],
    tags: [
      "2",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  wifi_calling_3: {
    name: "wifi_calling_3",
    version: 10,
    popularity: 2567,
    codepoint: 61573,
    categories: ["device"],
    tags: [
      "3",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless",
    ],
  },
  wifi_channel: {
    name: "wifi_channel",
    version: 1,
    popularity: 799,
    codepoint: 60266,
    categories: ["device"],
    tags: [
      "cellular",
      "channel",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_find: {
    name: "wifi_find",
    version: 1,
    popularity: 1133,
    codepoint: 60209,
    categories: ["device"],
    tags: [
      "cellular",
      "connection",
      "data",
      "detect",
      "discover",
      "find",
      "internet",
      "look",
      "magnifying glass",
      "mobile",
      "network",
      "notice",
      "scan",
      "search",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_home: {
    name: "wifi_home",
    version: 229,
    popularity: 3,
    codepoint: 63089,
    categories: ["Android"],
    tags: [
      "architecture",
      "building",
      "connection",
      "data",
      "estate",
      "home",
      "house",
      "internet",
      "location",
      "network",
      "place",
      "residence",
      "residential",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_lock: {
    name: "wifi_lock",
    version: 13,
    popularity: 1737,
    codepoint: 57825,
    categories: ["device"],
    tags: [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_notification: {
    name: "wifi_notification",
    version: 229,
    popularity: 3,
    codepoint: 63088,
    categories: ["Android"],
    tags: [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "connection",
      "data",
      "internet",
      "network",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "scan",
      "service",
      "signal",
      "sound",
      "wifi",
      "wireless",
    ],
  },
  wifi_off: {
    name: "wifi_off",
    version: 12,
    popularity: 13303,
    codepoint: 58952,
    categories: ["notification"],
    tags: [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "scan",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless",
    ],
  },
  wifi_password: {
    name: "wifi_password",
    version: 1,
    popularity: 1450,
    codepoint: 60267,
    categories: ["device"],
    tags: [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "password",
      "scan",
      "secure",
      "service",
      "signal",
      "wifi",
      "wireless",
    ],
  },
  wifi_protected_setup: {
    name: "wifi_protected_setup",
    version: 12,
    popularity: 5355,
    codepoint: 61692,
    categories: ["action"],
    tags: ["around", "arrow", "arrows", "protected", "rotate", "setup", "wifi"],
  },
  wifi_proxy: {
    name: "wifi_proxy",
    version: 229,
    popularity: 4,
    codepoint: 63399,
    categories: ["Communication"],
    tags: [
      "apartment",
      "architecture",
      "building",
      "business",
      "connection",
      "data",
      "domain",
      "estate",
      "home",
      "internet",
      "network",
      "place",
      "real",
      "residence",
      "residential",
      "scan",
      "service",
      "shelter",
      "signal",
      "web",
      "wifi",
      "wireless",
      "www",
    ],
  },
  wifi_tethering: {
    name: "wifi_tethering",
    version: 12,
    popularity: 6119,
    codepoint: 57826,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "speed",
      "tethering",
      "wifi",
      "wireless",
    ],
  },
  wifi_tethering_error: {
    name: "wifi_tethering_error",
    version: 3,
    popularity: 889,
    codepoint: 60121,
    categories: ["device"],
    tags: [
      "!",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connection",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "notification",
      "phone",
      "rounded",
      "scan",
      "service",
      "signal",
      "speed",
      "symbol",
      "tethering",
      "warning",
      "wifi",
      "wireless",
    ],
  },
  wifi_tethering_off: {
    name: "wifi_tethering_off",
    version: 10,
    popularity: 1386,
    codepoint: 61575,
    categories: ["device"],
    tags: [
      "cell",
      "cellular",
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "scan",
      "service",
      "signal",
      "slash",
      "speed",
      "tethering",
      "wifi",
      "wireless",
    ],
  },
  wind_power: {
    name: "wind_power",
    version: 1,
    popularity: 1858,
    codepoint: 60428,
    categories: ["home"],
    tags: ["eco", "energy", "nest", "power", "wind", "windy"],
  },
  window: {
    name: "window",
    version: 10,
    popularity: 7254,
    codepoint: 61576,
    categories: ["search"],
    tags: [
      "close",
      "glass",
      "grid",
      "home",
      "house",
      "interior",
      "layout",
      "outside",
      "window",
    ],
  },
  window_closed: {
    name: "window_closed",
    version: 229,
    popularity: 177,
    codepoint: 59262,
    categories: ["Home"],
    tags: ["closed", "home", "nest", "security", "window"],
  },
  window_open: {
    name: "window_open",
    version: 229,
    popularity: 164,
    codepoint: 59276,
    categories: ["Home"],
    tags: ["home", "nest", "open", "security", "window"],
  },
  window_sensor: {
    name: "window_sensor",
    version: 229,
    popularity: 109,
    codepoint: 58043,
    categories: ["Home"],
    tags: ["home", "nest", "security", "sensor", "window"],
  },
  wine_bar: {
    name: "wine_bar",
    version: 6,
    popularity: 5636,
    codepoint: 61928,
    categories: ["maps"],
    tags: [
      "alcohol",
      "bar",
      "cocktail",
      "cup",
      "drink",
      "glass",
      "liquor",
      "wine",
    ],
  },
  woman: {
    name: "woman",
    version: 2,
    popularity: 5338,
    codepoint: 57662,
    categories: ["social"],
    tags: [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women",
    ],
  },
  woman_2: {
    name: "woman_2",
    version: 1,
    popularity: 656,
    codepoint: 63719,
    categories: ["social"],
    tags: [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women",
    ],
  },
  work: {
    name: "work",
    version: 12,
    popularity: 63878,
    codepoint: 59641,
    categories: ["action"],
    tags: [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "job",
      "suitcase",
      "work",
    ],
  },
  work_alert: {
    name: "work_alert",
    version: 229,
    popularity: 4,
    codepoint: 62967,
    categories: ["Business&Payments"],
    tags: [
      "!",
      "alert",
      "attention",
      "bag",
      "baggage",
      "borg",
      "briefcase",
      "business",
      "case",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "job",
      "mark",
      "notification",
      "suitcase",
      "symbol",
      "warning",
      "work",
    ],
  },
  work_history: {
    name: "work_history",
    version: 1,
    popularity: 5188,
    codepoint: 60425,
    categories: ["action"],
    tags: [
      "back",
      "backwards",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "clock",
      "date",
      "history",
      "job",
      "pending",
      "recent",
      "schedule",
      "suitcase",
      "time",
      "updates",
      "work",
    ],
  },
  work_update: {
    name: "work_update",
    version: 229,
    popularity: 4,
    codepoint: 62968,
    categories: ["Business&Payments"],
    tags: [
      "arrow",
      "arrows",
      "bag",
      "baggage",
      "borg",
      "briefcase",
      "business",
      "case",
      "down",
      "download",
      "install",
      "job",
      "suitcase",
      "work",
    ],
  },
  workspace_premium: {
    name: "workspace_premium",
    version: 3,
    popularity: 25642,
    codepoint: 59311,
    categories: ["social"],
    tags: [
      "certification",
      "degree",
      "ecommerce",
      "guarantee",
      "medal",
      "permit",
      "premium",
      "ribbon",
      "verification",
      "workspace",
    ],
  },
  workspaces: {
    name: "workspaces",
    version: 9,
    popularity: 10120,
    codepoint: 57760,
    categories: ["file"],
    tags: [
      "circles",
      "collaboration",
      "dot",
      "filled",
      "group",
      "outline",
      "space",
      "team",
      "work",
      "workspaces",
    ],
  },
  wounds_injuries: {
    name: "wounds_injuries",
    version: 229,
    popularity: 4,
    codepoint: 57663,
    categories: ["Social"],
    tags: ["health", "medical", "staples", "stitch", "stitches"],
  },
  wrap_text: {
    name: "wrap_text",
    version: 13,
    popularity: 1593,
    codepoint: 57947,
    categories: ["editor"],
    tags: [
      "arrow writing",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "wrap",
      "write",
      "writing",
    ],
  },
  wrist: {
    name: "wrist",
    version: 229,
    popularity: 24,
    codepoint: 63132,
    categories: ["Social"],
    tags: ["device", "hand", "tracker", "watch"],
  },
  wrong_location: {
    name: "wrong_location",
    version: 17,
    popularity: 3361,
    codepoint: 61304,
    categories: ["maps"],
    tags: [
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "location",
      "maps",
      "no",
      "pin",
      "place",
      "quit",
      "remove",
      "stop",
      "wrong",
      "x",
    ],
  },
  wysiwyg: {
    name: "wysiwyg",
    version: 8,
    popularity: 15194,
    codepoint: 61891,
    categories: ["action"],
    tags: [
      "composer",
      "mode",
      "screen",
      "site",
      "software",
      "system",
      "text",
      "view",
      "visibility",
      "web",
      "website",
      "window",
      "wysiwyg",
    ],
  },
  yard: {
    name: "yard",
    version: 9,
    popularity: 9527,
    codepoint: 61577,
    categories: ["search"],
    tags: [
      "backyard",
      "flower",
      "garden",
      "home",
      "house",
      "nature",
      "pettle",
      "plants",
      "yard",
    ],
  },
  your_trips: {
    name: "your_trips",
    version: 229,
    popularity: 7,
    codepoint: 60203,
    categories: ["Travel"],
    tags: [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "pack",
      "personal",
      "star",
      "storage",
      "travel",
      "trips",
      "your",
    ],
  },
  youtube_activity: {
    name: "youtube_activity",
    version: 229,
    popularity: 500,
    codepoint: 63578,
    categories: ["Common actions"],
    tags: ["activity", "add", "plus", "youtube"],
  },
  youtube_searched_for: {
    name: "youtube_searched_for",
    version: 12,
    popularity: 9667,
    codepoint: 59642,
    categories: ["action"],
    tags: [
      "arrow",
      "back",
      "backwards",
      "find",
      "glass",
      "history",
      "inprogress",
      "load",
      "loading",
      "look",
      "magnify",
      "magnifying",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "search",
      "see",
      "youtube",
    ],
  },
  zone_person_alert: {
    name: "zone_person_alert",
    version: 229,
    popularity: 156,
    codepoint: 59265,
    categories: ["Home"],
    tags: ["activity", "alert", "home", "nest", "person", "security", "zone"],
  },
  zone_person_idle: {
    name: "zone_person_idle",
    version: 229,
    popularity: 103,
    codepoint: 59258,
    categories: ["Home"],
    tags: ["activity", "home", "idle", "nest", "person", "security", "zone"],
  },
  zone_person_urgent: {
    name: "zone_person_urgent",
    version: 229,
    popularity: 197,
    codepoint: 59272,
    categories: ["Home"],
    tags: ["activity", "home", "nest", "person", "security", "urgent", "zone"],
  },
  zoom_in: {
    name: "zoom_in",
    version: 11,
    popularity: 40076,
    codepoint: 59647,
    categories: ["action"],
    tags: [
      "big",
      "bigger",
      "find",
      "glass",
      "grow",
      "in",
      "look",
      "magnify",
      "magnifying",
      "plus",
      "scale",
      "search",
      "see",
      "size",
      "zoom",
    ],
  },
  zoom_in_map: {
    name: "zoom_in_map",
    version: 1,
    popularity: 3788,
    codepoint: 60205,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "destination",
      "in",
      "location",
      "maps",
      "move",
      "place",
      "stop",
      "zoom",
    ],
  },
  zoom_out: {
    name: "zoom_out",
    version: 11,
    popularity: 16196,
    codepoint: 59648,
    categories: ["action"],
    tags: [
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "minus",
      "negative",
      "out",
      "scale",
      "search",
      "see",
      "size",
      "small",
      "smaller",
      "zoom",
    ],
  },
  zoom_out_map: {
    name: "zoom_out_map",
    version: 17,
    popularity: 13678,
    codepoint: 58731,
    categories: ["maps"],
    tags: [
      "arrow",
      "arrows",
      "destination",
      "location",
      "maps",
      "move",
      "out",
      "place",
      "stop",
      "zoom",
    ],
  },
};
export const iconCategories = {
  "ui actions": [
    "123",
    "3d_rotation",
    "abc",
    "accessibility",
    "accessibility_new",
    "accessible",
    "accessible_forward",
    "account_balance",
    "account_balance_wallet",
    "account_box",
    "account_circle",
    "acute",
    "add_card",
    "add_home",
    "add_home_work",
    "add_shopping_cart",
    "add_task",
    "add_to_drive",
    "admin_panel_settings",
    "ads_click",
    "alarm",
    "alarm_add",
    "alarm_off",
    "alarm_on",
    "all_inbox",
    "all_match",
    "all_out",
    "amend",
    "analytics",
    "anchor",
    "android",
    "api",
    "app_badging",
    "app_blocking",
    "app_shortcut",
    "approval",
    "apps",
    "apps_outage",
    "arrow_and_edge",
    "arrow_back",
    "arrow_back_ios",
    "arrow_back_ios_new",
    "arrow_circle_down",
    "arrow_circle_left",
    "arrow_circle_right",
    "arrow_circle_up",
    "arrow_downward",
    "arrow_downward_alt",
    "arrow_drop_down",
    "arrow_drop_down_circle",
    "arrow_drop_up",
    "arrow_forward",
    "arrow_forward_ios",
    "arrow_insert",
    "arrow_left",
    "arrow_left_alt",
    "arrow_or_edge",
    "arrow_outward",
    "arrow_range",
    "arrow_right",
    "arrow_right_alt",
    "arrow_split",
    "arrow_top_left",
    "arrow_top_right",
    "arrow_upward",
    "arrow_upward_alt",
    "arrows_outward",
    "article",
    "aspect_ratio",
    "assignment",
    "assignment_add",
    "assignment_ind",
    "assignment_late",
    "assignment_return",
    "assignment_returned",
    "assignment_turned_in",
    "assistant_direction",
    "assistant_navigation",
    "assured_workload",
    "attach_email",
    "attachment",
    "autorenew",
    "back_to_tab",
    "backup",
    "backup_table",
    "balance",
    "barcode_reader",
    "batch_prediction",
    "book",
    "book_online",
    "bookmark",
    "bookmark_add",
    "bookmark_added",
    "bookmark_remove",
    "bookmarks",
    "bottom_app_bar",
    "bottom_drawer",
    "bottom_navigation",
    "bottom_panel_close",
    "bottom_panel_open",
    "bottom_right_click",
    "bottom_sheets",
    "browse_activity",
    "browse_gallery",
    "bubble",
    "bubbles",
    "bug_report",
    "build",
    "build_circle",
    "buttons_alt",
    "cached",
    "calendar_month",
    "calendar_today",
    "calendar_view_day",
    "calendar_view_month",
    "calendar_view_week",
    "campaign",
    "cancel",
    "cancel_schedule_send",
    "captive_portal",
    "capture",
    "card_membership",
    "card_travel",
    "cards",
    "change_history",
    "check",
    "check_box",
    "check_box_outline_blank",
    "check_circle",
    "check_indeterminate_small",
    "check_small",
    "chevron_left",
    "chevron_right",
    "chip_extraction",
    "chips",
    "chrome_reader_mode",
    "chronic",
    "circle_notifications",
    "clock_loader_10",
    "clock_loader_20",
    "clock_loader_40",
    "clock_loader_60",
    "clock_loader_80",
    "clock_loader_90",
    "close",
    "close_fullscreen",
    "cloud",
    "cloud_circle",
    "cloud_done",
    "cloud_download",
    "cloud_off",
    "cloud_sync",
    "cloud_upload",
    "code",
    "code_off",
    "collapse_all",
    "comment_bank",
    "commit",
    "commute",
    "compare_arrows",
    "compress",
    "contact_page",
    "contact_support",
    "contactless",
    "copyright",
    "create_new_folder",
    "credit_card",
    "credit_card_off",
    "css",
    "currency_exchange",
    "cycle",
    "dangerous",
    "dashboard",
    "dashboard_customize",
    "data_alert",
    "data_check",
    "data_exploration",
    "data_info_alert",
    "data_thresholding",
    "date_range",
    "delete",
    "delete_forever",
    "density_large",
    "density_medium",
    "density_small",
    "deployed_code",
    "deployed_code_account",
    "deployed_code_alert",
    "deployed_code_history",
    "deployed_code_update",
    "description",
    "dialogs",
    "difference",
    "directory_sync",
    "disabled_by_default",
    "disabled_visible",
    "display_settings",
    "dns",
    "done",
    "done_all",
    "done_outline",
    "donut_large",
    "donut_small",
    "double_arrow",
    "download",
    "download_2",
    "download_done",
    "download_for_offline",
    "downloading",
    "drag_click",
    "drag_indicator",
    "drag_pan",
    "drive_file_move",
    "drive_folder_upload",
    "dropdown",
    "dynamic_form",
    "east",
    "edit_calendar",
    "edit_document",
    "edit_off",
    "edit_square",
    "eject",
    "empty_dashboard",
    "enable",
    "error_med",
    "euro_symbol",
    "event",
    "event_list",
    "event_repeat",
    "event_seat",
    "exit_to_app",
    "expand",
    "expand_all",
    "expand_circle_down",
    "expand_circle_right",
    "expand_circle_up",
    "expand_content",
    "expand_less",
    "expand_more",
    "explore",
    "explore_off",
    "extension",
    "extension_off",
    "face",
    "fact_check",
    "favorite",
    "fax",
    "feedback",
    "file_download_done",
    "file_download_off",
    "file_open",
    "file_present",
    "file_upload_off",
    "filter_alt",
    "filter_alt_off",
    "find_in_page",
    "find_replace",
    "fingerprint",
    "first_page",
    "fit_screen",
    "flaky",
    "flight_land",
    "flight_takeoff",
    "flip_to_back",
    "flip_to_front",
    "flutter_dash",
    "folder",
    "folder_copy",
    "folder_delete",
    "folder_off",
    "folder_open",
    "folder_shared",
    "folder_zip",
    "format_overline",
    "free_cancellation",
    "fullscreen",
    "fullscreen_exit",
    "g_translate",
    "gavel",
    "gif",
    "gif_box",
    "go_to_line",
    "grade",
    "grading",
    "grid_view",
    "group_work",
    "heart_check",
    "heart_minus",
    "heart_plus",
    "help",
    "help_center",
    "hide",
    "hide_source",
    "history",
    "history_toggle_off",
    "hls",
    "hls_off",
    "home",
    "home_work",
    "horizontal_split",
    "hotel_class",
    "hourglass_disabled",
    "hourglass_empty",
    "html",
    "http",
    "iframe",
    "iframe_off",
    "important_devices",
    "indeterminate_check_box",
    "info",
    "input",
    "input_circle",
    "install_desktop",
    "install_mobile",
    "integration_instructions",
    "invert_colors",
    "javascript",
    "join_inner",
    "join_left",
    "join_right",
    "jump_to_element",
    "key_vertical",
    "label",
    "label_important",
    "label_off",
    "language",
    "last_page",
    "leaderboard",
    "left_click",
    "left_panel_close",
    "left_panel_open",
    "legend_toggle",
    "lightbulb",
    "lightbulb_circle",
    "line_style",
    "line_weight",
    "linked_services",
    "list",
    "lock",
    "lock_clock",
    "lock_open",
    "lock_person",
    "lock_reset",
    "login",
    "logout",
    "loyalty",
    "magnification_large",
    "magnification_small",
    "manage_accounts",
    "manage_history",
    "mark_as_unread",
    "markunread_mailbox",
    "maximize",
    "mediation",
    "menu",
    "menu_open",
    "minimize",
    "model_training",
    "more_down",
    "more_horiz",
    "more_up",
    "more_vert",
    "move_group",
    "move_item",
    "move_selection_down",
    "move_selection_left",
    "move_selection_right",
    "move_selection_up",
    "network_ping",
    "new_label",
    "new_window",
    "newspaper",
    "next_plan",
    "no_accounts",
    "noise_aware",
    "noise_control_off",
    "north",
    "north_east",
    "north_west",
    "not_accessible",
    "not_started",
    "note_add",
    "offline_bolt",
    "offline_pin",
    "offline_share",
    "on_device_training",
    "online_prediction",
    "opacity",
    "open_in_browser",
    "open_in_full",
    "open_in_new",
    "open_in_new_down",
    "open_in_new_off",
    "open_with",
    "outbound",
    "outbox",
    "outgoing_mail",
    "outlet",
    "output",
    "output_circle",
    "page_control",
    "page_info",
    "pageview",
    "paid",
    "pan_tool",
    "pan_tool_alt",
    "partner_reports",
    "patient_list",
    "payments",
    "pending",
    "pending_actions",
    "percent",
    "perm_camera_mic",
    "perm_contact_calendar",
    "perm_data_setting",
    "perm_device_information",
    "perm_media",
    "perm_phone_msg",
    "perm_scan_wifi",
    "pets",
    "php",
    "picture_in_picture",
    "picture_in_picture_alt",
    "pin_end",
    "pin_invoke",
    "pinch",
    "pip",
    "pip_exit",
    "pivot_table_chart",
    "place_item",
    "plagiarism",
    "play_for_work",
    "point_scan",
    "polymer",
    "position_bottom_left",
    "position_bottom_right",
    "position_top_right",
    "power_settings_new",
    "pregnant_woman",
    "preliminary",
    "preview",
    "print",
    "privacy_tip",
    "private_connectivity",
    "production_quantity_limits",
    "progress_activity",
    "published_with_changes",
    "question_exchange",
    "question_mark",
    "quick_reference",
    "quick_reference_all",
    "quickreply",
    "radio_button_checked",
    "radio_button_partial",
    "radio_button_unchecked",
    "rebase",
    "rebase_edit",
    "receipt",
    "record_voice_over",
    "redeem",
    "refresh",
    "remove_done",
    "remove_shopping_cart",
    "reopen_window",
    "reorder",
    "repartition",
    "request_page",
    "request_quote",
    "resize",
    "responsive_layout",
    "restore_from_trash",
    "restore_page",
    "right_click",
    "right_panel_close",
    "right_panel_open",
    "ripples",
    "rocket",
    "rocket_launch",
    "rounded_corner",
    "rowing",
    "rule",
    "rule_folder",
    "rule_settings",
    "satellite_alt",
    "saved_search",
    "savings",
    "schedule",
    "schedule_send",
    "search",
    "search_check",
    "search_off",
    "segment",
    "select_check_box",
    "send_and_archive",
    "sensors",
    "sensors_off",
    "settings",
    "settings_accessibility",
    "settings_applications",
    "settings_backup_restore",
    "settings_bluetooth",
    "settings_brightness",
    "settings_cell",
    "settings_ethernet",
    "settings_heart",
    "settings_input_antenna",
    "settings_input_component",
    "settings_input_hdmi",
    "settings_input_svideo",
    "settings_overscan",
    "settings_phone",
    "settings_power",
    "settings_remote",
    "settings_voice",
    "share_reviews",
    "share_windows",
    "shelf_auto_hide",
    "shelf_position",
    "shop",
    "shop_two",
    "shopping_bag",
    "shopping_basket",
    "shopping_cart",
    "shopping_cart_checkout",
    "side_navigation",
    "sliders",
    "snippet_folder",
    "south",
    "south_east",
    "south_west",
    "space_dashboard",
    "spatial_audio",
    "spatial_audio_off",
    "spatial_tracking",
    "speaker_notes",
    "speaker_notes_off",
    "spellcheck",
    "stack",
    "stack_off",
    "stack_star",
    "star",
    "star_half",
    "star_rate",
    "star_rate_half",
    "stars",
    "stat_0",
    "stat_1",
    "stat_2",
    "stat_3",
    "stat_minus_1",
    "stat_minus_2",
    "stat_minus_3",
    "step",
    "step_into",
    "step_out",
    "step_over",
    "steppers",
    "sticky_note_2",
    "store",
    "subdirectory_arrow_left",
    "subdirectory_arrow_right",
    "subheader",
    "subject",
    "subtitles_off",
    "supervised_user_circle",
    "supervisor_account",
    "support",
    "swap_horiz",
    "swap_horizontal_circle",
    "swap_vert",
    "swap_vertical_circle",
    "sweep",
    "swipe",
    "swipe_down",
    "swipe_down_alt",
    "swipe_left",
    "swipe_left_alt",
    "swipe_right",
    "swipe_right_alt",
    "swipe_up",
    "swipe_up_alt",
    "swipe_vertical",
    "switch_access",
    "switch_access_shortcut",
    "switch_access_shortcut_add",
    "switch_left",
    "switch_right",
    "switches",
    "sync_alt",
    "sync_saved_locally",
    "system_update_alt",
    "tab",
    "tab_unselected",
    "table_view",
    "tabs",
    "task_alt",
    "terminal",
    "text_rotate_up",
    "text_rotate_vertical",
    "text_rotation_angledown",
    "text_rotation_angleup",
    "text_rotation_down",
    "text_rotation_none",
    "text_snippet",
    "theaters",
    "thumb_down",
    "thumb_up",
    "thumbs_up_down",
    "timeline",
    "toast",
    "toc",
    "today",
    "toggle_off",
    "toggle_on",
    "token",
    "toll",
    "toolbar",
    "topic",
    "touch_app",
    "tour",
    "track_changes",
    "transcribe",
    "translate",
    "trending_down",
    "trending_flat",
    "trending_up",
    "troubleshoot",
    "unfold_less",
    "unfold_less_double",
    "unfold_more",
    "unfold_more_double",
    "unknown_5",
    "unknown_med",
    "unpublished",
    "update",
    "update_disabled",
    "upgrade",
    "upload",
    "upload_2",
    "upload_file",
    "verified",
    "verified_user",
    "vertical_split",
    "view_agenda",
    "view_array",
    "view_carousel",
    "view_column",
    "view_comfy_alt",
    "view_compact_alt",
    "view_cozy",
    "view_day",
    "view_headline",
    "view_in_ar",
    "view_kanban",
    "view_list",
    "view_module",
    "view_quilt",
    "view_sidebar",
    "view_stream",
    "view_timeline",
    "view_week",
    "visibility",
    "visibility_off",
    "voice_over_off",
    "waterfall_chart",
    "webhook",
    "west",
    "width_full",
    "width_normal",
    "width_wide",
    "wifi_protected_setup",
    "work",
    "work_history",
    "workspaces",
    "wysiwyg",
    "youtube_searched_for",
    "zoom_in",
    "zoom_out",
  ],
  maps: [
    "360",
    "add_business",
    "add_location",
    "add_location_alt",
    "add_road",
    "agriculture",
    "airline_stops",
    "airlines",
    "alt_route",
    "atm",
    "attractions",
    "badge",
    "bakery_dining",
    "beenhere",
    "bike_scooter",
    "breakfast_dining",
    "brunch_dining",
    "bus_alert",
    "car_crash",
    "car_rental",
    "car_repair",
    "castle",
    "category",
    "celebration",
    "church",
    "cleaning_services",
    "compass_calibration",
    "connecting_airports",
    "crisis_alert",
    "departure_board",
    "design_services",
    "diamond",
    "dinner_dining",
    "directions",
    "directions_alt",
    "directions_alt_off",
    "directions_bike",
    "directions_boat",
    "directions_bus",
    "directions_car",
    "directions_railway",
    "directions_run",
    "directions_subway",
    "directions_walk",
    "dry_cleaning",
    "edit_attributes",
    "edit_location",
    "edit_location_alt",
    "edit_road",
    "egg",
    "egg_alt",
    "electric_bike",
    "electric_car",
    "electric_moped",
    "electric_rickshaw",
    "electric_scooter",
    "electrical_services",
    "emergency",
    "emergency_recording",
    "emergency_share",
    "ev_station",
    "explore_nearby",
    "factory",
    "fastfood",
    "festival",
    "file_map",
    "fire_hydrant",
    "fire_truck",
    "flight",
    "flight_class",
    "forest",
    "fork_left",
    "fork_right",
    "fort",
    "hail",
    "handyman",
    "hardware",
    "home_pin",
    "home_repair_service",
    "hotel",
    "hvac",
    "icecream",
    "kebab_dining",
    "layers",
    "layers_clear",
    "liquor",
    "local_activity",
    "local_atm",
    "local_bar",
    "local_cafe",
    "local_car_wash",
    "local_convenience_store",
    "local_dining",
    "local_drink",
    "local_fire_department",
    "local_florist",
    "local_gas_station",
    "local_hospital",
    "local_laundry_service",
    "local_library",
    "local_mall",
    "local_parking",
    "local_pharmacy",
    "local_pizza",
    "local_police",
    "local_post_office",
    "local_see",
    "local_shipping",
    "local_taxi",
    "location_away",
    "location_home",
    "lunch_dining",
    "map",
    "maps_ugc",
    "medical_information",
    "medical_services",
    "menu_book",
    "merge",
    "minor_crash",
    "mode_of_travel",
    "money",
    "mosque",
    "move",
    "move_location",
    "moved_location",
    "moving",
    "moving_ministry",
    "multiple_stop",
    "museum",
    "my_location",
    "navigation",
    "near_me",
    "near_me_disabled",
    "nightlife",
    "no_crash",
    "no_meals",
    "no_transfer",
    "not_listed_location",
    "package",
    "package_2",
    "park",
    "pedal_bike",
    "pergola",
    "person_pin",
    "person_pin_circle",
    "pest_control",
    "pest_control_rodent",
    "pet_supplies",
    "pin_drop",
    "plumbing",
    "railway_alert",
    "ramen_dining",
    "ramp_left",
    "ramp_right",
    "rate_review",
    "remove_road",
    "restaurant",
    "restaurant_menu",
    "roundabout_left",
    "roundabout_right",
    "route",
    "run_circle",
    "safety_check",
    "safety_check_off",
    "sailing",
    "satellite",
    "screen_rotation_alt",
    "set_meal",
    "signpost",
    "snowmobile",
    "sos",
    "soup_kitchen",
    "stadium",
    "straight",
    "streetview",
    "subway",
    "synagogue",
    "takeout_dining",
    "taxi_alert",
    "temple_buddhist",
    "temple_hindu",
    "theater_comedy",
    "things_to_do",
    "tire_repair",
    "traffic",
    "train",
    "tram",
    "transfer_within_a_station",
    "transit_enterexit",
    "trip_origin",
    "turn_left",
    "turn_right",
    "turn_sharp_left",
    "turn_sharp_right",
    "turn_slight_left",
    "turn_slight_right",
    "two_wheeler",
    "u_turn_left",
    "u_turn_right",
    "universal_local",
    "volunteer_activism",
    "warehouse",
    "wine_bar",
    "wrong_location",
    "zoom_in_map",
    "zoom_out_map",
  ],
  "audio&video": [
    "10k",
    "1k",
    "1k_plus",
    "2d",
    "2k",
    "2k_plus",
    "3k",
    "3k_plus",
    "4k",
    "4k_plus",
    "5g",
    "5k",
    "5k_plus",
    "6k",
    "6k_plus",
    "7k",
    "7k_plus",
    "8k",
    "8k_plus",
    "9k",
    "9k_plus",
    "add_to_queue",
    "airplay",
    "album",
    "ar_on_you",
    "ar_stickers",
    "art_track",
    "artist",
    "audio_description",
    "audio_file",
    "autopause",
    "autoplay",
    "autostop",
    "av_timer",
    "brand_awareness",
    "branding_watermark",
    "call_to_action",
    "cinematic_blur",
    "closed_caption",
    "closed_caption_disabled",
    "control_camera",
    "digital_out_of_home",
    "discover_tune",
    "equalizer",
    "explicit",
    "fast_forward",
    "fast_rewind",
    "featured_play_list",
    "featured_video",
    "fiber_dvr",
    "fiber_manual_record",
    "fiber_new",
    "fiber_pin",
    "fiber_smart_record",
    "forward_10",
    "forward_30",
    "forward_5",
    "forward_circle",
    "forward_media",
    "frame_person",
    "frame_person_off",
    "full_hd",
    "genres",
    "hangout_video",
    "hangout_video_off",
    "hd",
    "hearing",
    "hearing_disabled",
    "high_quality",
    "instant_mix",
    "interpreter_mode",
    "library_add",
    "library_add_check",
    "library_books",
    "library_music",
    "lyrics",
    "media_link",
    "mic",
    "mic_double",
    "mic_off",
    "missed_video_call",
    "movie",
    "movie_edit",
    "movie_info",
    "music_cast",
    "music_video",
    "new_releases",
    "no_sound",
    "note",
    "pause",
    "pause_circle",
    "play_arrow",
    "play_circle",
    "play_disabled",
    "play_pause",
    "playlist_add",
    "playlist_add_check",
    "playlist_add_check_circle",
    "playlist_add_circle",
    "playlist_play",
    "playlist_remove",
    "privacy",
    "queue_music",
    "queue_play_next",
    "radio",
    "recent_actors",
    "remove_from_queue",
    "repeat",
    "repeat_on",
    "repeat_one",
    "repeat_one_on",
    "replay",
    "replay_10",
    "replay_30",
    "replay_5",
    "resume",
    "sd",
    "select_to_speak",
    "shuffle",
    "shuffle_on",
    "skip_next",
    "skip_previous",
    "slow_motion_video",
    "snooze",
    "sort_by_alpha",
    "sound_detection_dog_barking",
    "sound_detection_glass_break",
    "sound_detection_loud_sound",
    "sound_sampler",
    "speech_to_text",
    "speed",
    "stop",
    "stop_circle",
    "subscriptions",
    "subtitles",
    "surround_sound",
    "text_to_speech",
    "video_call",
    "video_camera_front_off",
    "video_file",
    "video_label",
    "video_library",
    "video_search",
    "video_settings",
    "videocam",
    "videocam_off",
    "view_in_ar_off",
    "voice_selection",
    "volume_down",
    "volume_down_alt",
    "volume_mute",
    "volume_off",
    "volume_up",
    "web",
    "web_asset",
    "web_asset_off",
  ],
  "photo&image": [
    "10mp",
    "11mp",
    "12mp",
    "13mp",
    "14mp",
    "15mp",
    "16mp",
    "17mp",
    "18mp",
    "19mp",
    "20mp",
    "21mp",
    "22mp",
    "23mp",
    "24mp",
    "2mp",
    "30fps_select",
    "3mp",
    "4mp",
    "50mp",
    "5mp",
    "60fps_select",
    "6mp",
    "7mp",
    "8mp",
    "9mp",
    "add_a_photo",
    "add_photo_alternate",
    "add_to_photos",
    "adjust",
    "animation",
    "auto_awesome_mosaic",
    "auto_awesome_motion",
    "auto_stories",
    "autofps_select",
    "background_dot_large",
    "background_grid_small",
    "bedtime",
    "bedtime_off",
    "blur_circular",
    "blur_linear",
    "blur_medium",
    "blur_off",
    "blur_on",
    "blur_short",
    "brightness_1",
    "brightness_2",
    "brightness_3",
    "brightness_4",
    "brightness_5",
    "brightness_6",
    "brightness_7",
    "broken_image",
    "brush",
    "burst_mode",
    "camera",
    "camera_front",
    "camera_rear",
    "camera_roll",
    "cases",
    "center_focus_strong",
    "center_focus_weak",
    "circle",
    "collections_bookmark",
    "colorize",
    "compare",
    "contrast",
    "contrast_rtl_off",
    "control_point_duplicate",
    "crop",
    "crop_16_9",
    "crop_3_2",
    "crop_5_4",
    "crop_7_5",
    "crop_9_16",
    "crop_free",
    "crop_landscape",
    "crop_portrait",
    "crop_rotate",
    "crop_square",
    "currency_bitcoin",
    "currency_franc",
    "currency_lira",
    "currency_pound",
    "currency_ruble",
    "currency_rupee",
    "currency_yen",
    "currency_yuan",
    "deblur",
    "dehaze",
    "details",
    "dirty_lens",
    "edit",
    "euro",
    "ev_shadow",
    "ev_shadow_add",
    "ev_shadow_minus",
    "exposure",
    "exposure_neg_1",
    "exposure_neg_2",
    "exposure_plus_1",
    "exposure_plus_2",
    "exposure_zero",
    "face_retouching_off",
    "filter",
    "filter_1",
    "filter_2",
    "filter_3",
    "filter_4",
    "filter_5",
    "filter_6",
    "filter_7",
    "filter_8",
    "filter_9",
    "filter_9_plus",
    "filter_b_and_w",
    "filter_center_focus",
    "filter_drama",
    "filter_frames",
    "filter_hdr",
    "filter_none",
    "filter_retrolux",
    "filter_tilt_shift",
    "filter_vintage",
    "flare",
    "flash_auto",
    "flash_off",
    "flash_on",
    "flip",
    "flip_camera_android",
    "flip_camera_ios",
    "gallery_thumbnail",
    "gradient",
    "grain",
    "grid_off",
    "grid_on",
    "hdr_enhanced_select",
    "hdr_off",
    "hdr_on",
    "hdr_plus",
    "hdr_plus_off",
    "hdr_strong",
    "hdr_weak",
    "healing",
    "hevc",
    "hide_image",
    "high_density",
    "high_res",
    "image",
    "image_aspect_ratio",
    "image_not_supported",
    "image_search",
    "imagesmode",
    "incomplete_circle",
    "landscape",
    "leak_add",
    "leak_remove",
    "lens",
    "linked_camera",
    "logo_dev",
    "looks",
    "looks_3",
    "looks_4",
    "looks_5",
    "looks_6",
    "looks_one",
    "looks_two",
    "loupe",
    "low_density",
    "macro_auto",
    "masked_transitions",
    "mic_external_off",
    "mic_external_on",
    "monochrome_photos",
    "motion_blur",
    "motion_mode",
    "motion_photos_auto",
    "motion_photos_off",
    "motion_photos_on",
    "motion_photos_paused",
    "mp",
    "music_note",
    "music_off",
    "nature",
    "nature_people",
    "navigate_before",
    "navigate_next",
    "night_sight_auto",
    "night_sight_auto_off",
    "palette",
    "panorama",
    "panorama_fish_eye",
    "panorama_horizontal",
    "panorama_photosphere",
    "panorama_vertical",
    "panorama_wide_angle",
    "photo",
    "photo_album",
    "photo_auto_merge",
    "photo_camera",
    "photo_camera_back",
    "photo_camera_front",
    "photo_frame",
    "photo_library",
    "photo_prints",
    "photo_size_select_large",
    "photo_size_select_small",
    "picture_as_pdf",
    "planner_banner_ad_pt",
    "raw_off",
    "raw_on",
    "receipt_long",
    "rotate_90_degrees_ccw",
    "rotate_90_degrees_cw",
    "rotate_left",
    "rotate_right",
    "settings_b_roll",
    "settings_cinematic_blur",
    "settings_motion_mode",
    "settings_night_sight",
    "settings_panorama",
    "settings_photo_camera",
    "settings_slow_motion",
    "settings_timelapse",
    "settings_video_camera",
    "shutter_speed",
    "shutter_speed_add",
    "shutter_speed_minus",
    "slideshow",
    "straighten",
    "style",
    "switch_camera",
    "switch_video",
    "texture",
    "texture_add",
    "texture_minus",
    "thermostat_auto",
    "timelapse",
    "timer",
    "timer_10",
    "timer_3",
    "timer_off",
    "tonality",
    "trail_length",
    "trail_length_medium",
    "trail_length_short",
    "transform",
    "tune",
    "unknown_2",
    "video_camera_back",
    "video_camera_front",
    "video_stable",
    "view_comfy",
    "view_compact",
    "vignette",
    "vr180_create2d",
    "vr180_create2d_off",
    "vrpano",
    "wb_auto",
    "wb_incandescent",
    "wb_iridescent",
    "wb_shade",
    "wb_sunny",
    "wb_twilight",
  ],
  social: [
    "18_up_rating",
    "6_ft_apart",
    "add_moderator",
    "add_reaction",
    "admin_meds",
    "agender",
    "allergies",
    "allergy",
    "altitude",
    "architecture",
    "assist_walker",
    "back_hand",
    "barefoot",
    "blind",
    "blood_pressure",
    "body_fat",
    "body_system",
    "bomb",
    "boy",
    "breastfeeding",
    "bring_your_own_ip",
    "cake",
    "candle",
    "cardiology",
    "cheer",
    "chess",
    "clean_hands",
    "clear_day",
    "clear_night",
    "clinical_notes",
    "co2",
    "cognition",
    "comic_bubble",
    "communication",
    "communities",
    "compost",
    "conditions",
    "congenital",
    "connect_without_contact",
    "construction",
    "cookie",
    "cookie_off",
    "coronavirus",
    "crossword",
    "crowdsource",
    "cruelty_free",
    "cyclone",
    "deceased",
    "deck",
    "demography",
    "dentistry",
    "dermatology",
    "destruction",
    "dew_point",
    "digital_wellbeing",
    "diversity_1",
    "diversity_2",
    "diversity_3",
    "diversity_4",
    "domain",
    "domain_add",
    "domino_mask",
    "downhill_skiing",
    "earthquake",
    "eco",
    "edit_notifications",
    "editor_choice",
    "elderly",
    "elderly_woman",
    "emoji_events",
    "emoji_flags",
    "emoji_food_beverage",
    "emoji_nature",
    "emoji_objects",
    "emoji_people",
    "emoji_symbols",
    "emoji_transportation",
    "emoticon",
    "endocrinology",
    "engineering",
    "ent",
    "explosion",
    "eyeglasses",
    "face_2",
    "face_3",
    "face_4",
    "face_5",
    "face_6",
    "falling",
    "family_star",
    "female",
    "femur",
    "femur_alt",
    "fireplace",
    "flood",
    "fluid",
    "fluid_balance",
    "fluid_med",
    "folded_hands",
    "follow_the_signs",
    "foot_bones",
    "footprint",
    "front_hand",
    "garden_cart",
    "gastroenterology",
    "genetics",
    "girl",
    "globe",
    "globe_asia",
    "globe_uk",
    "glucose",
    "group",
    "group_add",
    "group_off",
    "group_remove",
    "groups",
    "groups_2",
    "groups_3",
    "gynecology",
    "hand_bones",
    "handshake",
    "health_and_safety",
    "heart_broken",
    "helicopter",
    "hematology",
    "hiking",
    "history_edu",
    "hive",
    "home_health",
    "humerus",
    "humerus_alt",
    "humidity_percentage",
    "ice_skating",
    "immunology",
    "infrared",
    "inpatient",
    "interests",
    "ios_share",
    "kayaking",
    "kid_star",
    "king_bed",
    "kitesurfing",
    "lab_panel",
    "lab_research",
    "labs",
    "landslide",
    "location_city",
    "luggage",
    "male",
    "man",
    "man_2",
    "man_3",
    "man_4",
    "manga",
    "masks",
    "medical_mask",
    "metabolism",
    "microbiology",
    "military_tech",
    "mist",
    "mixture_med",
    "mood",
    "mood_bad",
    "mountain_flag",
    "moving_beds",
    "mystery",
    "nephrology",
    "neurology",
    "nights_stay",
    "no_adult_content",
    "no_luggage",
    "nordic_walking",
    "not_accessible_forward",
    "notification_add",
    "notifications",
    "notifications_active",
    "notifications_off",
    "notifications_paused",
    "nutrition",
    "oncology",
    "ophthalmology",
    "oral_disease",
    "orthopedics",
    "outdoor_garden",
    "outdoor_grill",
    "outpatient",
    "outpatient_med",
    "oxygen_saturation",
    "pages",
    "paragliding",
    "partly_cloudy_day",
    "partly_cloudy_night",
    "partner_exchange",
    "party_mode",
    "pediatrics",
    "person",
    "person_2",
    "person_3",
    "person_4",
    "person_add",
    "person_alert",
    "person_apron",
    "person_cancel",
    "person_check",
    "person_off",
    "person_raised_hand",
    "person_remove",
    "personal_injury",
    "piano",
    "piano_off",
    "pill",
    "pill_off",
    "playing_cards",
    "potted_plant",
    "prayer_times",
    "precision_manufacturing",
    "pregnancy",
    "prescriptions",
    "procedure",
    "psychiatry",
    "psychology",
    "psychology_alt",
    "public",
    "public_off",
    "pulmonology",
    "radiology",
    "rainy",
    "rainy_heavy",
    "rainy_light",
    "rainy_snow",
    "raven",
    "real_estate_agent",
    "recent_patient",
    "recommend",
    "recycling",
    "reduce_capacity",
    "remove_moderator",
    "respiratory_rate",
    "rheumatology",
    "rib_cage",
    "roller_skating",
    "routine",
    "safety_divider",
    "salinity",
    "sanitizer",
    "scale",
    "school",
    "science",
    "scoreboard",
    "scuba_diving",
    "self_improvement",
    "sentiment_calm",
    "sentiment_content",
    "sentiment_dissatisfied",
    "sentiment_excited",
    "sentiment_extremely_dissatisfied",
    "sentiment_frustrated",
    "sentiment_neutral",
    "sentiment_sad",
    "sentiment_satisfied",
    "sentiment_stressed",
    "sentiment_very_dissatisfied",
    "sentiment_very_satisfied",
    "sentiment_worried",
    "severe_cold",
    "share",
    "share_off",
    "short_stay",
    "sick",
    "sign_language",
    "single_bed",
    "skateboarding",
    "skeleton",
    "skull",
    "sledding",
    "snowboarding",
    "snowing_heavy",
    "snowshoeing",
    "social_distance",
    "social_leaderboard",
    "south_america",
    "specific_gravity",
    "sports",
    "sports_baseball",
    "sports_basketball",
    "sports_cricket",
    "sports_esports",
    "sports_football",
    "sports_golf",
    "sports_gymnastics",
    "sports_handball",
    "sports_hockey",
    "sports_kabaddi",
    "sports_martial_arts",
    "sports_mma",
    "sports_motorsports",
    "sports_rugby",
    "sports_soccer",
    "sports_tennis",
    "sports_volleyball",
    "stethoscope",
    "stethoscope_arrow",
    "stethoscope_check",
    "strategy",
    "surfing",
    "surgical",
    "switch_account",
    "sword_rose",
    "symptoms",
    "syringe",
    "tactic",
    "taunt",
    "thunderstorm",
    "tibia",
    "tibia_alt",
    "tornado",
    "total_dissolved_solids",
    "transgender",
    "travel_explore",
    "tsunami",
    "ulna_radius",
    "ulna_radius_alt",
    "urology",
    "vaccines",
    "vital_signs",
    "volcano",
    "wallet",
    "ward",
    "water_bottle",
    "water_bottle_large",
    "water_do",
    "water_drop",
    "water_ec",
    "water_lux",
    "water_orp",
    "water_ph",
    "water_voc",
    "waving_hand",
    "weather_hail",
    "weather_mix",
    "weight",
    "whatshot",
    "woman",
    "woman_2",
    "workspace_premium",
    "wounds_injuries",
    "wrist",
  ],
  hardware: [
    "1x_mobiledata",
    "30fps",
    "3g_mobiledata",
    "4g_mobiledata",
    "4g_plus_mobiledata",
    "60fps",
    "ad_units",
    "add_to_home_screen",
    "adf_scanner",
    "air",
    "airplane_ticket",
    "airplanemode_active",
    "airplanemode_inactive",
    "aod",
    "aod_tablet",
    "aod_watch",
    "app_promo",
    "assistant_device",
    "audio_video_receiver",
    "battery_0_bar",
    "battery_1_bar",
    "battery_2_bar",
    "battery_3_bar",
    "battery_4_bar",
    "battery_5_bar",
    "battery_6_bar",
    "battery_alert",
    "battery_charging_20",
    "battery_charging_30",
    "battery_charging_50",
    "battery_charging_60",
    "battery_charging_80",
    "battery_charging_90",
    "battery_charging_full",
    "battery_full",
    "battery_saver",
    "battery_unknown",
    "bloodtype",
    "bluetooth",
    "bluetooth_connected",
    "bluetooth_disabled",
    "bluetooth_drive",
    "bluetooth_searching",
    "brightness_auto",
    "brightness_high",
    "brightness_low",
    "brightness_medium",
    "browser_updated",
    "cable",
    "camera_video",
    "cameraswitch",
    "cast",
    "cast_connected",
    "cast_for_education",
    "cast_pause",
    "cast_warning",
    "chromecast_device",
    "computer",
    "connected_tv",
    "conveyor_belt",
    "credit_score",
    "dark_mode",
    "data_saver_on",
    "data_usage",
    "dataset",
    "dataset_linked",
    "deskphone",
    "desktop_mac",
    "desktop_windows",
    "developer_board",
    "developer_board_off",
    "developer_mode",
    "developer_mode_tv",
    "device_hub",
    "device_thermostat",
    "device_unknown",
    "devices",
    "devices_fold",
    "devices_off",
    "devices_other",
    "devices_wearables",
    "do_not_disturb_on_total_silence",
    "dock",
    "dvr",
    "e_mobiledata",
    "earbuds",
    "earbuds_battery",
    "ecg",
    "edgesensor_high",
    "edgesensor_low",
    "flashlight_off",
    "flashlight_on",
    "fluorescent",
    "fmd_bad",
    "forklift",
    "front_loader",
    "g_mobiledata",
    "gamepad",
    "general_device",
    "google_home_devices",
    "gpp_bad",
    "gpp_maybe",
    "graphic_eq",
    "grid_3x3",
    "grid_4x4",
    "grid_goldenratio",
    "h_mobiledata",
    "h_plus_mobiledata",
    "hard_drive",
    "hard_drive_2",
    "hdr_auto",
    "hdr_auto_select",
    "hdr_off_select",
    "hdr_on_select",
    "headphones",
    "headphones_battery",
    "headset_mic",
    "headset_off",
    "home_max",
    "home_mini",
    "jamboard_kiosk",
    "joystick",
    "keyboard",
    "keyboard_alt",
    "keyboard_arrow_down",
    "keyboard_arrow_left",
    "keyboard_arrow_right",
    "keyboard_arrow_up",
    "keyboard_backspace",
    "keyboard_capslock",
    "keyboard_command_key",
    "keyboard_control_key",
    "keyboard_double_arrow_down",
    "keyboard_double_arrow_left",
    "keyboard_double_arrow_right",
    "keyboard_double_arrow_up",
    "keyboard_hide",
    "keyboard_option_key",
    "keyboard_return",
    "keyboard_tab",
    "keyboard_tab_rtl",
    "keyboard_voice",
    "lan",
    "laptop_chromebook",
    "laptop_mac",
    "laptop_windows",
    "lda",
    "lens_blur",
    "lift_to_talk",
    "light_mode",
    "lightning_stand",
    "location_disabled",
    "location_searching",
    "lte_mobiledata",
    "lte_plus_mobiledata",
    "macro_off",
    "media_bluetooth_off",
    "media_bluetooth_on",
    "medication",
    "medication_liquid",
    "memory",
    "memory_alt",
    "mimo",
    "mimo_disconnect",
    "missing_controller",
    "mobile_friendly",
    "mobile_off",
    "mobiledata_off",
    "mode_night",
    "mode_standby",
    "monitor",
    "monitor_heart",
    "monitor_weight",
    "mouse",
    "nearby_error",
    "nearby_off",
    "network_cell",
    "network_wifi",
    "network_wifi_1_bar",
    "network_wifi_2_bar",
    "network_wifi_3_bar",
    "nfc",
    "night_sight_max",
    "nightlight",
    "note_alt",
    "open_in_phone",
    "open_jam",
    "p2p",
    "pacemaker",
    "pallet",
    "password",
    "pattern",
    "phishing",
    "phone_android",
    "phone_iphone",
    "phonelink_off",
    "pin",
    "play_lesson",
    "point_of_sale",
    "power_input",
    "price_change",
    "price_check",
    "print_add",
    "print_connect",
    "print_error",
    "print_lock",
    "punch_clock",
    "quiz",
    "r_mobiledata",
    "radar",
    "rear_camera",
    "remember_me",
    "reset_tv",
    "reset_wrench",
    "restart_alt",
    "reviews",
    "robot",
    "robot_2",
    "router",
    "rsvp",
    "scanner",
    "screen_lock_landscape",
    "screen_lock_portrait",
    "screen_lock_rotation",
    "screen_rotation",
    "screen_search_desktop",
    "screenshot",
    "screenshot_monitor",
    "screenshot_tablet",
    "security",
    "security_update_good",
    "security_update_warning",
    "sell",
    "send_to_mobile",
    "settings_system_daydream",
    "settop_component",
    "share_location",
    "signal_cellular_0_bar",
    "signal_cellular_1_bar",
    "signal_cellular_2_bar",
    "signal_cellular_3_bar",
    "signal_cellular_4_bar",
    "signal_cellular_alt",
    "signal_cellular_alt_1_bar",
    "signal_cellular_alt_2_bar",
    "signal_cellular_connected_no_internet_0_bar",
    "signal_cellular_connected_no_internet_4_bar",
    "signal_cellular_nodata",
    "signal_cellular_null",
    "signal_cellular_off",
    "signal_wifi_0_bar",
    "signal_wifi_4_bar",
    "signal_wifi_bad",
    "signal_wifi_off",
    "signal_wifi_statusbar_not_connected",
    "signal_wifi_statusbar_null",
    "sim_card",
    "sim_card_download",
    "smart_display",
    "smart_screen",
    "smart_toy",
    "smartphone",
    "speaker",
    "speaker_group",
    "splitscreen",
    "sports_score",
    "ssid_chart",
    "start",
    "storage",
    "storm",
    "stream_apps",
    "summarize",
    "tablet",
    "tablet_android",
    "tablet_mac",
    "task",
    "thermostat",
    "timer_10_select",
    "timer_3_select",
    "touchpad_mouse",
    "toys",
    "trolley",
    "tv",
    "tv_guide",
    "tv_options_edit_channels",
    "tv_options_input_settings",
    "tv_remote",
    "tv_signin",
    "usb",
    "usb_off",
    "ventilator",
    "videogame_asset",
    "videogame_asset_off",
    "wallpaper",
    "watch",
    "watch_button_press",
    "watch_off",
    "watch_wake",
    "water",
    "widgets",
    "wifi_1_bar",
    "wifi_2_bar",
    "wifi_calling_1",
    "wifi_calling_2",
    "wifi_calling_3",
    "wifi_channel",
    "wifi_find",
    "wifi_lock",
    "wifi_password",
    "wifi_tethering",
    "wifi_tethering_error",
    "wifi_tethering_off",
  ],
  communication: [
    "3p",
    "alternate_email",
    "app_registration",
    "auto_read_pause",
    "auto_read_play",
    "business_messages",
    "calendar_add_on",
    "calendar_apps_script",
    "call",
    "call_end",
    "call_log",
    "call_made",
    "call_merge",
    "call_missed",
    "call_missed_outgoing",
    "call_quality",
    "call_received",
    "call_split",
    "cancel_presentation",
    "cell_tower",
    "cell_wifi",
    "chat",
    "chat_add_on",
    "chat_apps_script",
    "chat_bubble",
    "chat_error",
    "chat_info",
    "chat_paste_go",
    "clear_all",
    "co_present",
    "comment",
    "comments_disabled",
    "contact_emergency",
    "contact_mail",
    "contact_phone",
    "contacts",
    "desktop_access_disabled",
    "dialer_sip",
    "dialpad",
    "document_scanner",
    "domain_disabled",
    "domain_verification",
    "duo",
    "e911_avatar",
    "for_you",
    "forum",
    "forward_to_inbox",
    "hourglass_bottom",
    "hourglass_top",
    "hub",
    "import_contacts",
    "inbox_customize",
    "invert_colors_off",
    "key",
    "key_off",
    "list_alt",
    "live_help",
    "location_off",
    "location_on",
    "mail_lock",
    "mark_chat_read",
    "mark_chat_unread",
    "mark_email_read",
    "mark_email_unread",
    "mark_unread_chat_alt",
    "mobile_screen_share",
    "more_time",
    "nat",
    "network_intelligence_history",
    "network_intelligence_update",
    "network_manage",
    "no_sim",
    "notification_multiple",
    "ods",
    "odt",
    "outbox_alt",
    "pause_presentation",
    "person_add_disabled",
    "person_search",
    "phone_disabled",
    "phone_enabled",
    "phone_in_talk",
    "phonelink_erase",
    "phonelink_lock",
    "phonelink_ring",
    "phonelink_ring_off",
    "phonelink_setup",
    "picture_in_picture_center",
    "picture_in_picture_large",
    "picture_in_picture_medium",
    "picture_in_picture_mobile",
    "picture_in_picture_off",
    "picture_in_picture_small",
    "portable_wifi_off",
    "present_to_all",
    "print_disabled",
    "qr_code",
    "qr_code_2",
    "qr_code_scanner",
    "read_more",
    "ring_volume",
    "rss_feed",
    "rtt",
    "screen_share",
    "send_time_extension",
    "signal_cellular_add",
    "sip",
    "speaker_phone",
    "spoke",
    "stacked_email",
    "stacked_inbox",
    "stay_current_landscape",
    "stay_current_portrait",
    "stay_primary_landscape",
    "stay_primary_portrait",
    "stop_screen_share",
    "swap_calls",
    "tooltip",
    "unsubscribe",
    "voicemail",
    "vpn_key",
    "vpn_key_off",
    "wifi_add",
    "wifi_calling",
    "wifi_proxy",
  ],
  travel: [
    "ac_unit",
    "airport_shuttle",
    "all_inclusive",
    "ambulance",
    "apartment",
    "auto_towing",
    "auto_transmission",
    "baby_changing_station",
    "backpack",
    "balcony",
    "bathtub",
    "beach_access",
    "bento",
    "bungalow",
    "business_center",
    "cabin",
    "carpenter",
    "carry_on_bag",
    "carry_on_bag_checked",
    "carry_on_bag_inactive",
    "carry_on_bag_question",
    "casino",
    "chalet",
    "charging_station",
    "checked_bag",
    "checked_bag_question",
    "checkroom",
    "child_care",
    "child_friendly",
    "concierge",
    "corporate_fare",
    "cottage",
    "countertops",
    "crib",
    "desk",
    "do_not_step",
    "do_not_touch",
    "dry",
    "elevator",
    "escalator",
    "escalator_warning",
    "family_restroom",
    "fence",
    "fire_extinguisher",
    "fitness_center",
    "flights_and_hotels",
    "flightsmode",
    "food_bank",
    "foundation",
    "gite",
    "golf_course",
    "grass",
    "holiday_village",
    "hot_tub",
    "house",
    "house_siding",
    "houseboat",
    "iron",
    "kitchen",
    "meeting_room",
    "microwave",
    "motorcycle",
    "night_shelter",
    "no_backpack",
    "no_drinks",
    "no_flash",
    "no_food",
    "no_meeting_room",
    "no_photography",
    "no_stroller",
    "other_houses",
    "personal_bag",
    "personal_bag_off",
    "personal_bag_question",
    "personal_places",
    "pool",
    "rice_bowl",
    "roofing",
    "room_preferences",
    "room_service",
    "rv_hookup",
    "smoke_free",
    "smoking_rooms",
    "soap",
    "spa",
    "sports_bar",
    "stairs",
    "storefront",
    "stroller",
    "swap_driving_apps",
    "swap_driving_apps_wheel",
    "tapas",
    "transportation",
    "travel",
    "travel_luggage_and_bags",
    "trip",
    "tty",
    "umbrella",
    "vape_free",
    "vaping_rooms",
    "villa",
    "wash",
    "water_damage",
    "wheelchair_pickup",
    "your_trips",
  ],
  notification: [
    "account_tree",
    "adb",
    "add_call",
    "airline_seat_flat",
    "airline_seat_flat_angled",
    "airline_seat_individual_suite",
    "airline_seat_legroom_extra",
    "airline_seat_legroom_normal",
    "airline_seat_legroom_reduced",
    "airline_seat_recline_extra",
    "airline_seat_recline_normal",
    "confirmation_number",
    "directions_off",
    "disc_full",
    "do_not_disturb_off",
    "do_not_disturb_on",
    "enhanced_encryption",
    "event_available",
    "event_busy",
    "event_note",
    "folder_special",
    "imagesearch_roller",
    "live_tv",
    "mms",
    "more",
    "network_check",
    "network_locked",
    "no_encryption",
    "phone_bluetooth_speaker",
    "phone_callback",
    "phone_forwarded",
    "phone_locked",
    "phone_missed",
    "phone_paused",
    "power",
    "power_off",
    "priority_high",
    "running_with_errors",
    "sd_card",
    "sd_card_alert",
    "sms",
    "support_agent",
    "sync",
    "sync_disabled",
    "sync_lock",
    "sync_problem",
    "system_update",
    "tap_and_play",
    "tv_off",
    "vibration",
    "video_chat",
    "voice_chat",
    "vpn_lock",
    "wc",
    "wifi",
    "wifi_off",
  ],
  home: [
    "activity_zone",
    "air_freshener",
    "air_purifier",
    "air_purifier_gen",
    "airware",
    "airwave",
    "apparel",
    "aq",
    "aq_indoor",
    "arming_countdown",
    "arrows_more_down",
    "arrows_more_up",
    "assistant_on_hub",
    "battery_horiz_000",
    "battery_horiz_050",
    "battery_horiz_075",
    "battery_profile",
    "blanket",
    "blinds",
    "blinds_closed",
    "broadcast_on_home",
    "broadcast_on_personal",
    "chromecast_2",
    "cleaning_bucket",
    "climate_mini_split",
    "cloudy_snowing",
    "controller_gen",
    "cooking",
    "cool_to_dry",
    "curtains",
    "curtains_closed",
    "detection_and_zone",
    "detector",
    "detector_alarm",
    "detector_battery",
    "detector_co",
    "detector_offline",
    "detector_smoke",
    "detector_status",
    "device_reset",
    "dishwasher",
    "dishwasher_gen",
    "door_open",
    "door_sensor",
    "doorbell_3p",
    "doorbell_chime",
    "dresser",
    "early_on",
    "electric_bolt",
    "electric_meter",
    "emergency_heat",
    "emergency_home",
    "emergency_share_off",
    "energy_program_saving",
    "energy_program_time_used",
    "energy_savings_leaf",
    "ev_charger",
    "familiar_face_and_zone",
    "family_home",
    "farsight_digital",
    "faucet",
    "floor_lamp",
    "foggy",
    "garage_door",
    "garage_home",
    "gas_meter",
    "gate",
    "google_tv_remote",
    "google_wifi",
    "grocery",
    "hallway",
    "health_and_beauty",
    "heat",
    "heat_pump",
    "heat_pump_balance",
    "home_and_garden",
    "home_improvement_and_tools",
    "home_iot_device",
    "home_max_dots",
    "home_speaker",
    "home_storage",
    "house_with_shield",
    "household_supplies",
    "humidity_high",
    "humidity_indoor",
    "humidity_low",
    "humidity_mid",
    "in_home_mode",
    "kettle",
    "laundry",
    "light_group",
    "light_off",
    "matter",
    "mfg_nest_yale_lock",
    "microwave_gen",
    "mode_cool",
    "mode_cool_off",
    "mode_dual",
    "mode_fan",
    "mode_fan_off",
    "mode_heat",
    "mode_heat_cool",
    "mode_heat_off",
    "mode_off_on",
    "mop",
    "motion_sensor_active",
    "motion_sensor_alert",
    "motion_sensor_idle",
    "motion_sensor_urgent",
    "multicooker",
    "nest_audio",
    "nest_cam_floodlight",
    "nest_cam_indoor",
    "nest_cam_iq",
    "nest_cam_iq_outdoor",
    "nest_cam_magnet_mount",
    "nest_cam_outdoor",
    "nest_cam_stand",
    "nest_cam_wall_mount",
    "nest_cam_wired_stand",
    "nest_clock_farsight_analog",
    "nest_clock_farsight_digital",
    "nest_connect",
    "nest_detect",
    "nest_display",
    "nest_display_max",
    "nest_doorbell_visitor",
    "nest_eco_leaf",
    "nest_farsight_weather",
    "nest_found_savings",
    "nest_heat_link_e",
    "nest_heat_link_gen_3",
    "nest_hello_doorbell",
    "nest_mini",
    "nest_multi_room",
    "nest_protect",
    "nest_remote",
    "nest_remote_comfort_sensor",
    "nest_secure_alarm",
    "nest_sunblock",
    "nest_tag",
    "nest_thermostat",
    "nest_thermostat_e_eu",
    "nest_thermostat_gen_3",
    "nest_thermostat_sensor",
    "nest_thermostat_sensor_eu",
    "nest_thermostat_zirconium_eu",
    "nest_true_radiant",
    "nest_wake_on_approach",
    "nest_wake_on_press",
    "nest_wifi_point",
    "nest_wifi_pro",
    "nest_wifi_pro_2",
    "nest_wifi_router",
    "oil_barrel",
    "on_hub_device",
    "oven",
    "oven_gen",
    "productivity",
    "propane",
    "propane_tank",
    "quiet_time",
    "quiet_time_active",
    "range_hood",
    "remote_gen",
    "roller_shades",
    "roller_shades_closed",
    "scene",
    "self_care",
    "sensor_door",
    "sensor_occupied",
    "sensor_window",
    "sensors_krx",
    "settings_alert",
    "shelves",
    "shield_moon",
    "shield_with_heart",
    "shield_with_house",
    "skillet",
    "skillet_cooktop",
    "smart_outlet",
    "snowing",
    "solar_power",
    "sprinkler",
    "stadia_controller",
    "stockpot",
    "styler",
    "sunny",
    "sunny_snowing",
    "switch",
    "table_lamp",
    "tamper_detection_off",
    "tamper_detection_on",
    "temp_preferences_eco",
    "thermometer",
    "thermometer_add",
    "thermometer_gain",
    "thermometer_loss",
    "thermometer_minus",
    "thermostat_carbon",
    "tools_flat_head",
    "tools_installation_kit",
    "tools_ladder",
    "tools_level",
    "tools_phillips",
    "tools_pliers_wire_stripper",
    "tools_power_drill",
    "tv_gen",
    "tv_with_assistant",
    "vacuum",
    "valve",
    "vertical_shades",
    "vertical_shades_closed",
    "wall_art",
    "wall_lamp",
    "water_heater",
    "water_pump",
    "weather_snowy",
    "wind_power",
    "window_closed",
    "window_open",
    "window_sensor",
    "zone_person_alert",
    "zone_person_idle",
    "zone_person_urgent",
  ],
  notifications: [
    "add_alert",
    "auto_delete",
    "error",
    "notification_important",
    "warning",
  ],
  "text formatting": [
    "add_chart",
    "add_comment",
    "add_notes",
    "align_center",
    "align_end",
    "align_flex_center",
    "align_flex_end",
    "align_flex_start",
    "align_horizontal_center",
    "align_horizontal_left",
    "align_horizontal_right",
    "align_items_stretch",
    "align_justify_center",
    "align_justify_flex_end",
    "align_justify_flex_start",
    "align_justify_space_around",
    "align_justify_space_between",
    "align_justify_space_even",
    "align_justify_stretch",
    "align_self_stretch",
    "align_space_around",
    "align_space_between",
    "align_space_even",
    "align_start",
    "align_stretch",
    "align_vertical_bottom",
    "align_vertical_center",
    "align_vertical_top",
    "amp_stories",
    "area_chart",
    "article_shortcut",
    "asterisk",
    "attach_file",
    "attach_file_add",
    "attach_money",
    "bar_chart",
    "book_2",
    "book_3",
    "book_4",
    "book_5",
    "border_all",
    "border_bottom",
    "border_clear",
    "border_color",
    "border_horizontal",
    "border_inner",
    "border_left",
    "border_outer",
    "border_right",
    "border_style",
    "border_top",
    "border_vertical",
    "breaking_news",
    "breaking_news_alt_1",
    "bubble_chart",
    "business_chip",
    "candlestick_chart",
    "cell_merge",
    "checklist",
    "checklist_rtl",
    "clarify",
    "colors",
    "contract",
    "contract_delete",
    "contract_edit",
    "counter_0",
    "counter_1",
    "counter_2",
    "counter_3",
    "counter_4",
    "counter_5",
    "counter_6",
    "counter_7",
    "counter_8",
    "counter_9",
    "csv",
    "custom_typography",
    "cut",
    "data_array",
    "data_object",
    "decimal_decrease",
    "decimal_increase",
    "diagnosis",
    "dictionary",
    "docs_add_on",
    "docs_apps_script",
    "draft",
    "drag_handle",
    "draw",
    "draw_abstract",
    "draw_collage",
    "edit_note",
    "equal",
    "export_notes",
    "file_save",
    "file_save_off",
    "finance_chip",
    "fit_page",
    "fit_width",
    "flex_direction",
    "flex_no_wrap",
    "flex_wrap",
    "folder_data",
    "folder_managed",
    "folder_supervised",
    "format_align_center",
    "format_align_justify",
    "format_align_left",
    "format_align_right",
    "format_bold",
    "format_clear",
    "format_color_fill",
    "format_color_reset",
    "format_color_text",
    "format_h1",
    "format_h2",
    "format_h3",
    "format_h4",
    "format_h5",
    "format_h6",
    "format_image_left",
    "format_image_right",
    "format_indent_decrease",
    "format_indent_increase",
    "format_ink_highlighter",
    "format_italic",
    "format_letter_spacing",
    "format_letter_spacing_2",
    "format_letter_spacing_standard",
    "format_letter_spacing_wide",
    "format_letter_spacing_wider",
    "format_line_spacing",
    "format_list_bulleted",
    "format_list_bulleted_add",
    "format_list_numbered",
    "format_list_numbered_rtl",
    "format_paint",
    "format_paragraph",
    "format_quote",
    "format_shapes",
    "format_size",
    "format_strikethrough",
    "format_text_clip",
    "format_text_overflow",
    "format_text_wrap",
    "format_textdirection_l_to_r",
    "format_textdirection_r_to_l",
    "format_underlined",
    "format_underlined_squiggle",
    "forms_add_on",
    "forms_apps_script",
    "frame_inspect",
    "frame_reload",
    "frame_source",
    "full_coverage",
    "function",
    "functions",
    "glyphs",
    "grid_guides",
    "heap_snapshot_large",
    "heap_snapshot_multiple",
    "heap_snapshot_thumbnail",
    "height",
    "hexagon",
    "highlight",
    "highlighter_size_1",
    "highlighter_size_2",
    "highlighter_size_3",
    "highlighter_size_4",
    "highlighter_size_5",
    "horizontal_distribute",
    "horizontal_rule",
    "ink_eraser",
    "ink_eraser_off",
    "ink_highlighter",
    "ink_highlighter_move",
    "ink_marker",
    "ink_pen",
    "insert_chart",
    "insert_page_break",
    "insert_text",
    "join",
    "lab_profile",
    "language_chinese_array",
    "language_chinese_cangjie",
    "language_chinese_dayi",
    "language_chinese_pinyin",
    "language_chinese_quick",
    "language_chinese_wubi",
    "language_french",
    "language_gb_english",
    "language_international",
    "language_korean_latin",
    "language_pinyin",
    "language_spanish",
    "language_us",
    "language_us_colemak",
    "language_us_dvorak",
    "lasso_select",
    "letter_switch",
    "line_axis",
    "line_curve",
    "line_end",
    "line_end_arrow",
    "line_end_arrow_notch",
    "line_end_circle",
    "line_end_diamond",
    "line_end_square",
    "line_start",
    "line_start_arrow",
    "line_start_arrow_notch",
    "line_start_circle",
    "line_start_diamond",
    "line_start_square",
    "linear_scale",
    "list_alt_add",
    "location_chip",
    "margin",
    "markdown",
    "markdown_copy",
    "markdown_paste",
    "match_case",
    "match_word",
    "merge_type",
    "mode_comment",
    "monetization_on",
    "money_off",
    "move_down",
    "move_up",
    "multiline_chart",
    "news",
    "newsmode",
    "note_stack",
    "note_stack_add",
    "notes",
    "numbers",
    "other_admission",
    "overview",
    "padding",
    "pen_size_1",
    "pen_size_2",
    "pen_size_3",
    "pen_size_4",
    "pen_size_5",
    "pentagon",
    "person_book",
    "pie_chart",
    "polyline",
    "post",
    "post_add",
    "process_chart",
    "publish",
    "query_stats",
    "rectangle",
    "regular_expression",
    "remove_selection",
    "reset_image",
    "rubric",
    "scan",
    "scan_delete",
    "scatter_plot",
    "schema",
    "score",
    "select",
    "shape_line",
    "shapes",
    "sheets_rtl",
    "short_text",
    "show_chart",
    "signature",
    "slide_library",
    "smb_share",
    "source_notes",
    "space_bar",
    "special_character",
    "square",
    "stacked_line_chart",
    "sticky_note",
    "stock_media",
    "strikethrough_s",
    "stroke_full",
    "stroke_partial",
    "stylus_laser_pointer",
    "subscript",
    "superscript",
    "tab_close",
    "tab_close_right",
    "tab_duplicate",
    "tab_group",
    "tab_move",
    "tab_new_right",
    "tab_recent",
    "table",
    "table_chart",
    "table_chart_view",
    "table_rows",
    "table_rows_narrow",
    "team_dashboard",
    "text_ad",
    "text_decrease",
    "text_fields",
    "text_fields_alt",
    "text_increase",
    "text_select_end",
    "text_select_jump_to_beginning",
    "text_select_jump_to_end",
    "text_select_move_back_character",
    "text_select_move_back_word",
    "text_select_move_down",
    "text_select_move_forward_character",
    "text_select_move_forward_word",
    "text_select_move_up",
    "text_select_start",
    "thumbnail_bar",
    "title",
    "top_panel_close",
    "top_panel_open",
    "tsv",
    "two_pager",
    "type_specimen",
    "ungroup",
    "unknown_document",
    "variable_add",
    "variable_insert",
    "variable_remove",
    "variables",
    "vertical_align_bottom",
    "vertical_align_center",
    "vertical_align_top",
    "vertical_distribute",
    "view_column_2",
    "voting_chip",
    "width",
    "wrap_text",
  ],
  "business&payments": [
    "atr",
    "bar_chart_4_bars",
    "barcode",
    "barcode_scanner",
    "bid_landscape",
    "bid_landscape_disabled",
    "box",
    "box_add",
    "box_edit",
    "chart_data",
    "checkbook",
    "contactless_off",
    "conversion_path",
    "conversion_path_off",
    "credit_card_gear",
    "credit_card_heart",
    "data_table",
    "database",
    "energy",
    "enterprise",
    "enterprise_off",
    "family_history",
    "finance",
    "finance_mode",
    "flowsheet",
    "full_stacked_bar_chart",
    "grouped_bar_chart",
    "inactive_order",
    "mintmark",
    "mitre",
    "monitoring",
    "network_node",
    "order_approve",
    "order_play",
    "orders",
    "planner_review",
    "podium",
    "qr_code_2_add",
    "quick_reorder",
    "send_money",
    "shoppingmode",
    "source_environment",
    "tenancy",
    "universal_currency",
    "universal_currency_alt",
    "work_alert",
    "work_update",
  ],
  activities: [
    "avg_pace",
    "avg_time",
    "azm",
    "bath_outdoor",
    "bath_private",
    "bath_public_large",
    "bia",
    "cake_add",
    "camping",
    "check_in_out",
    "cleaning",
    "distance",
    "ecg_heart",
    "eda",
    "elevation",
    "exercise",
    "experiment",
    "family_link",
    "featured_seasonal_and_gifts",
    "fertile",
    "floor",
    "glass_cup",
    "health_metrics",
    "hr_resting",
    "ifl",
    "interactive_space",
    "laps",
    "menstrual_health",
    "mindfulness",
    "monitor_weight_gain",
    "monitor_weight_loss",
    "newsstand",
    "onsen",
    "pace",
    "person_celebrate",
    "person_play",
    "physical_therapy",
    "play_shapes",
    "podiatry",
    "readiness_score",
    "relax",
    "rewarded_ads",
    "sauna",
    "science_off",
    "service_toolbox",
    "sleep_score",
    "spo2",
    "sports_and_outdoors",
    "sprint",
    "steps",
    "stress_management",
    "swords",
    "toys_and_games",
    "toys_fan",
    "trophy",
    "water_full",
    "water_loss",
    "water_medium",
  ],
  "privacy&security": [
    "e911_emergency",
    "encrypted",
    "exclamation",
    "key_visualizer",
    "passkey",
    "shield_lock",
    "shield_locked",
    "shield_person",
    "shield_question",
    "vpn_key_alert",
  ],
};
