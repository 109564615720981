import { IntegrationKind } from "@superblocksteam/shared";
import { select } from "redux-saga/effects";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { callSagas, createSaga } from "../utils/saga";

function* initializeWorkflowEditorSaga() {
  const orgId = (yield select(selectOnlyOrganizationId) as unknown) as string;
  yield callSagas(
    [
      getSupersetDatasourcesSaga.apply({
        organizationId: orgId,
        kind: IntegrationKind.PLUGIN,
      }),
    ],
    { throwOnFailure: true },
  );
}

export default createSaga(
  initializeWorkflowEditorSaga,
  "initializeWorkflowEditorSaga",
);
