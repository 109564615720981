// State that tracks ephemeral info like what blocks are currently hovered
import { isEqual, memoize } from "lodash";
import { useReducer, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { BlockPositionLocator } from "store/slices/apisV2";
import {
  ControlFlowFrontendDSL,
  GenericBlock,
} from "store/slices/apisV2/control-flow/types";

import {
  getLocalBlockState,
  LocalBlockStateMap,
  setLocalBlockState,
} from "./local-block-state";

type BlockName = GenericBlock["name"];

interface InternalState {
  currentApiId: string;
  hoveredBlock: undefined | null | string;
  localBlockState: LocalBlockStateMap;
  redirectToRenamedBlock?: string;
  selectedBlocks: {
    locator: null | BlockPositionLocator;
    blockNames: Array<BlockName>;
  };
  isDraggingMultiple: boolean;
}

type InternalActions =
  | {
      type: "hoverBlock";
      payload: {
        blockName: BlockName;
        blocks: ControlFlowFrontendDSL["blocks"];
      };
    }
  | {
      type: "unhoverBlock";
      payload: {
        blockName: BlockName;
        blocks: ControlFlowFrontendDSL["blocks"];
      };
    }
  | {
      type: "resetState";
      payload: InternalState;
    }
  | {
      type: "setRedirectToRenamedBlock";
      payload: {
        updatedBlockName: BlockName;
      };
    }
  | {
      type: "setSelectedBlocks";
      payload: {
        blockNames: Array<BlockName>;
        locator: null | BlockPositionLocator;
      };
    }
  | {
      type: "setIsDraggingMultiple";
      payload: {
        isDraggingMultiple: boolean;
      };
    }
  | {
      type: "toggleBlockCollapsed";
      payload: {
        blockName: BlockName;
        collapsed?: boolean;
      };
    };

function reducer(state: InternalState, action: InternalActions): InternalState {
  const actionType = action.type;
  switch (actionType) {
    case "hoverBlock": {
      const { blockName } = action.payload;
      return {
        ...state,
        hoveredBlock: blockName,
      };
    }
    case "unhoverBlock": {
      const { blockName, blocks } = action.payload;
      const unhoveredBlock = blocks[blockName];
      const newHoveredBlockName = unhoveredBlock?.parentId;

      return {
        ...state,
        hoveredBlock: newHoveredBlockName,
      };
    }
    case "setRedirectToRenamedBlock": {
      const { updatedBlockName } = action.payload;
      return {
        ...state,
        redirectToRenamedBlock: updatedBlockName,
      };
    }
    case "resetState": {
      return action.payload;
    }
    case "setSelectedBlocks": {
      const { blockNames, locator } = action.payload;

      if (
        isEqual(blockNames, state.selectedBlocks.blockNames) &&
        isEqual(locator, state.selectedBlocks.locator)
      ) {
        return state;
      }

      return {
        ...state,
        selectedBlocks: { locator, blockNames },
      };
    }
    case "setIsDraggingMultiple": {
      const { isDraggingMultiple } = action.payload;
      return { ...state, isDraggingMultiple: isDraggingMultiple };
    }
    case "toggleBlockCollapsed": {
      const { blockName, collapsed } = action.payload;
      const localBlockState = getLocalBlockState(state.currentApiId);
      localBlockState[blockName] = localBlockState[blockName]
        ? {
            ...localBlockState[blockName],
            collapsed: !localBlockState[blockName].collapsed,
          }
        : {
            collapsed: collapsed !== undefined ? collapsed : true,
          };
      setLocalBlockState(state.currentApiId, localBlockState);
      return {
        ...state,
        localBlockState,
      };
    }
    default: {
      const exhaustiveCheck: never = actionType;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
}

const getInitialState = (params: { apiId: string }) => {
  const { apiId } = params;
  const localBlockState = getLocalBlockState(apiId);
  const initialState: InternalState = {
    hoveredBlock: null,
    localBlockState,
    currentApiId: apiId,
    selectedBlocks: { locator: null, blockNames: [] },
    isDraggingMultiple: false,
  };
  return initialState;
};

// This state is derived from the internal state
export interface ControlFlowExplorerState {
  hoveredBlockName: null | undefined | BlockName;
  cachedGetAncestors: (blockName: string | null) => Record<string, number>;
  localBlockState: InternalState["localBlockState"];
  focusedAncestors: Record<BlockName, number>; // ID to distance map. Focused item dist=0
  redirectToRenamedBlock: InternalState["redirectToRenamedBlock"];
  selectedBlocks: InternalState["selectedBlocks"];
  isDraggingMultiple: InternalState["isDraggingMultiple"];
}

type SetId = (id: BlockName) => void;
export interface ActionDispatchers {
  hoverBlock: SetId;
  unhoverBlock: SetId;
  setRedirectToRenamedBlock: (params: { updatedBlockName: BlockName }) => void;
  setSelectedBlocks: (params: {
    locator: null | BlockPositionLocator;
    blockNames: Array<BlockName>;
  }) => void;
  setIsDraggingMultiple: (params: { isDraggingMultiple: boolean }) => void;
  toggleBlockCollapsed: (blockName: BlockName, collapsed?: boolean) => void;
}

const getAncestors = (
  blockName: null | string,
  dsl: ControlFlowFrontendDSL,
) => {
  if (blockName == null) {
    return {};
  }
  const ancestryMap: Record<string, number> = {};
  const visited = new Set<string>();
  let currId: undefined | string = blockName;
  let distance = 0;
  while (currId != null) {
    const currentBlock: undefined | GenericBlock = dsl.blocks[currId];
    if (currentBlock == null) {
      break;
    }
    if (visited.has(currId)) {
      throw new Error("Cycle detected in Control Flow");
    }
    visited.add(currId);
    ancestryMap[currId] = distance;
    distance += 1;
    currId = currentBlock.parentId;
  }
  return ancestryMap;
};

export const useControlFlowExplorer = (params: {
  dsl: ControlFlowFrontendDSL;
  apiId: string;
}): [ControlFlowExplorerState, ActionDispatchers] => {
  const { dsl, apiId } = params;
  const initialStateFromApiId = useMemo(() => {
    return getInitialState({ apiId });
  }, [apiId]);
  const [_internalState, dispatch] = useReducer(reducer, initialStateFromApiId);
  const internalState =
    _internalState.currentApiId === apiId
      ? _internalState
      : initialStateFromApiId;

  useEffect(() => {
    dispatch({
      type: "resetState",
      payload: initialStateFromApiId,
    });
  }, [initialStateFromApiId]);

  // TODO, can be more typesafe
  const urlParams = useParams<{ actionId?: string }>();
  const focusedBlockName =
    typeof urlParams.actionId === "string" ? urlParams.actionId : null;

  // useMemo effectively acts as a memoizeOne
  const cachedGetAncestors = useMemo(() => {
    return memoize((id) => {
      return getAncestors(id, dsl);
    });
  }, [dsl]);

  const focusedAncestors = useMemo(() => {
    return cachedGetAncestors(focusedBlockName);
  }, [focusedBlockName, cachedGetAncestors]);

  const state: ControlFlowExplorerState = useMemo(() => {
    return {
      focusedAncestors,
      cachedGetAncestors,
      hoveredBlockName: internalState.hoveredBlock,
      localBlockState: internalState.localBlockState,
      redirectToRenamedBlock: internalState.redirectToRenamedBlock,
      selectedBlocks: internalState.selectedBlocks,
      isDraggingMultiple: internalState.isDraggingMultiple,
    };
  }, [
    focusedAncestors,
    cachedGetAncestors,
    internalState.hoveredBlock,
    internalState.localBlockState,
    internalState.redirectToRenamedBlock,
    internalState.selectedBlocks,
    internalState.isDraggingMultiple,
  ]);

  const actions: ActionDispatchers = useMemo(() => {
    return {
      hoverBlock: (blockName) =>
        dispatch({
          type: "hoverBlock",
          payload: { blockName, blocks: dsl.blocks },
        }),
      unhoverBlock: (blockName) =>
        dispatch({
          type: "unhoverBlock",
          payload: { blockName, blocks: dsl.blocks },
        }),
      setRedirectToRenamedBlock: (payload) =>
        dispatch({ type: "setRedirectToRenamedBlock", payload }),
      setSelectedBlocks: (payload) =>
        dispatch({ type: "setSelectedBlocks", payload }),
      setIsDraggingMultiple: (payload) =>
        dispatch({ type: "setIsDraggingMultiple", payload }),
      toggleBlockCollapsed: (blockName, collapsed) =>
        dispatch({
          type: "toggleBlockCollapsed",
          payload: { blockName, collapsed },
        }),
    };
  }, [dispatch, dsl.blocks]);

  return [state, actions];
};

// some shorthands
export const isBlockFocused = (
  blockName: BlockName,
  state: ControlFlowExplorerState,
) => {
  return (
    state.focusedAncestors[blockName] === 0 ||
    state.selectedBlocks.blockNames.includes(blockName)
  );
};

export const isBlockCollapsed = (
  blockName: string,
  state: ControlFlowExplorerState,
) => {
  return state.localBlockState?.[blockName]?.collapsed;
};
