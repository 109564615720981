import {
  FormItemDisplay,
  FormSectionLayout,
  FormTemplate,
  InitialValue,
  KVPair,
  getRowItemsFromSectionItem,
  isFormItem,
} from "@superblocksteam/shared";
import { Form, FormInstance, FormProps, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import { get, isEqual } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useElementRect } from "hooks/ui";
import { useGlobalHotkeys } from "hooks/ui/useGlobalHotkeys";
import { DynamicFormItem } from "./Item";
import { shouldShowItem } from "./utils";

interface Props {
  formProps: FormProps;
  formTemplate?: FormTemplate;
  children?: React.ReactNode;
  disabled?: boolean;
}

const DynamicFormWrapper = styled.div<{ $overflow?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${(props) => (props.$overflow ? `overflow: auto;` : "")}
`;

function isKVPair(item: InitialValue): item is KVPair[] {
  if (!Array.isArray(item)) {
    return false;
  }

  if (item.length === 0) {
    return true;
  }
  return item.every((o) => "key" in o && "value" in o);
}

const DynamicForm = React.forwardRef<FormInstance, Props>(
  ({ formProps, formTemplate, children, disabled }, ref) => {
    const [form] = useForm();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const wrapperRect = useElementRect(wrapperRef);
    const [formValues, setFormValues] = useState(formProps.initialValues);
    const [activeFormValues, setActiveFormValues] = useState(
      formProps.initialValues,
    );

    useGlobalHotkeys(
      "ctrl+enter, command+enter",
      () => {
        form.submit();
      },
      { enableOnTags: ["TEXTAREA", "INPUT", "SELECT"] },
    );

    const handleFormValuesChange = useCallback(() => {
      setFormValues(form.getFieldsValue());
    }, [form, setFormValues]);

    const shouldShowFormField = useCallback(
      (formItemDisplay?: FormItemDisplay): boolean => {
        return shouldShowItem(formValues, formItemDisplay);
      },
      [formValues],
    );

    // TODO The better way is to pass in the resolved fields
    // instead of dynamically setting values as local state during rendering
    const formItems = useMemo(() => {
      if (!formTemplate) {
        return null;
      }

      const formValuesToShow: Record<string, InitialValue> = {};
      const formSections = [];
      for (const section of formTemplate.sections) {
        if (!shouldShowFormField(section.display)) {
          continue;
        }

        const formItems = [];
        for (const sectionItem of section.items) {
          const rowItems = getRowItemsFromSectionItem(sectionItem);
          // TODO: add support for formRow rendering in DynamicForm
          for (const item of rowItems) {
            item.disabled = item.disabled || disabled;
            if (!shouldShowFormField(item.display) || item.hidden) {
              continue;
            }

            const formItemValue = get(formValues, item.name);
            if (
              // If the user deletes all the form rows, the form library sometimes
              // maintains the first entry in the array with all undefined keys.
              // So we detect both cases: the array entry is deleted, or all the keys are undefined.
              isKVPair(formItemValue) &&
              (formItemValue.length === 0 ||
                (formItemValue.length === 1 &&
                  formItemValue[0].key === undefined &&
                  formItemValue[0].value === undefined))
            ) {
              const emptyKVPair: KVPair[] = [{ key: "", value: "" }];
              formValuesToShow[item.name] = emptyKVPair;
              // Also need to update the form state so the UI is synced with our db
              form.setFieldsValue({ [item.name]: emptyKVPair });
            } else if (formItemValue !== undefined) {
              formValuesToShow[item.name] = formItemValue;
            }

            // TODO Each tab should support more than one field
            if (section.layout === FormSectionLayout.TABS) {
              formItems.push(
                <Tabs.TabPane tab={item.label} key={item.name}>
                  <DynamicFormItem formItem={item} />
                </Tabs.TabPane>,
              );
            } else {
              formItems.push(
                <DynamicFormItem
                  key={item.name}
                  formItem={item}
                  wrapperRect={
                    // Only resize if it's the only item
                    formTemplate.sections.length === 1 &&
                    section.items.length === 1
                      ? wrapperRect
                      : undefined
                  }
                />,
              );
            }
          }
        }

        if (section.layout === FormSectionLayout.TABS) {
          formSections.push(
            <Tabs
              defaultActiveKey={
                isFormItem(section.items[0])
                  ? section.items[0].label
                  : section.items[0]?.rowItems[0]?.label
              }
              size="middle"
              type="line"
              key={section.name}
              data-test={`dynamic-tab-${section.name}`}
            >
              {formItems}
            </Tabs>,
          );
        } else {
          formSections.push(formItems);
        }
      }
      if (!isEqual(formValuesToShow, activeFormValues)) {
        setActiveFormValues(formValuesToShow);
        if (formProps.onValuesChange) {
          formProps.onValuesChange(formValuesToShow, formValuesToShow);
        }
      }
      return formSections;
    }, [
      formTemplate,
      activeFormValues,
      shouldShowFormField,
      disabled,
      formValues,
      form,
      wrapperRect,
      formProps,
    ]);

    return (
      <DynamicFormWrapper ref={wrapperRef} $overflow={formItems?.length === 1}>
        <Form
          form={form}
          validateTrigger="onBlur"
          {...formProps}
          onValuesChange={handleFormValuesChange}
          ref={ref}
          requiredMark={false}
        >
          {formItems}
          {children}
        </Form>
      </DynamicFormWrapper>
    );
  },
);

DynamicForm.displayName = "DynamicForm";
export default DynamicForm;
