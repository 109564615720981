import { EvalErrorTypes } from "legacy/utils/DynamicBindingUtils";

type Edge = [string, string];
type Error = { type: EvalErrorTypes; message: string; nodes: string[] };
type Result = { result: string[]; errors: Error[] };

export default function toposort(edges: Edge[]): Result {
  return _toposort(uniqueNodes(edges), edges);
}

function _toposort(nodes: string[], edges: Edge[]): Result {
  let cursor = nodes.length;
  const sorted = new Array<string>(cursor);
  const visited = new Set<number>();
  const errors: Error[] = [];

  let i = cursor;
  // Better data structures make algorithm much faster.
  const outgoingEdges = makeOutgoingEdges(edges);
  const nodeToIdx = mapNodesToIndex(nodes);

  // check for unknown nodes
  edges.forEach((edge) => {
    if (!nodeToIdx.has(edge[0]) || !nodeToIdx.has(edge[1])) {
      throw new Error(
        "Unknown node. There is an unknown node in the supplied edges.",
      );
    }
  });

  while (i--) {
    if (!visited.has(i)) visit(nodes[i], i, [], errors);
  }

  return { result: sorted, errors: errors };

  function visit(
    node: string,
    i: number,
    predecessors: Array<string>,
    errors: Error[],
  ) {
    if (predecessors.includes(node)) {
      errors.push(
        createError(
          EvalErrorTypes.DEPENDENCY_ERROR,
          predecessors
            .slice(predecessors.indexOf(node))
            .concat(node)
            .join(" ➜ "),
          predecessors.slice(predecessors.indexOf(node)),
        ),
      );
    }

    if (!nodeToIdx.has(node)) {
      throw new Error(
        "Found unknown node. Make sure to provided all involved nodes. Unknown node: " +
          JSON.stringify(node),
      );
    }

    if (visited.has(i)) return;
    visited.add(i);

    const outgoing = Array.from(outgoingEdges.get(node) || new Set<string>());

    let remainingIdxs = outgoing.length;
    if (remainingIdxs) {
      predecessors.push(node);
      do {
        const child = outgoing[--remainingIdxs];

        visit(child, nodeToIdx.get(child)!, predecessors, errors);
      } while (remainingIdxs);
      predecessors.splice(predecessors.indexOf(node), 1);
    }

    sorted[--cursor] = node;
  }
}

function uniqueNodes(arr: Edge[]): string[] {
  const res = new Set<string>();
  for (let i = 0, len = arr.length; i < len; i++) {
    const edge = arr[i];
    res.add(edge[0]);
    res.add(edge[1]);
  }
  return Array.from(res);
}

function makeOutgoingEdges(arr: Edge[]) {
  const edges = new Map<string, Set<string>>();
  for (let i = 0, len = arr.length; i < len; i++) {
    const edge = arr[i];
    if (!edges.has(edge[0])) edges.set(edge[0], new Set());
    if (!edges.has(edge[1])) edges.set(edge[1], new Set());
    edges.get(edge[0])?.add(edge[1]);
  }
  return edges;
}

function mapNodesToIndex(arr: string[]) {
  const res = new Map<string, number>();
  for (let i = 0, len = arr.length; i < len; i++) {
    res.set(arr[i], i);
  }
  return res;
}

function createError(type: EvalErrorTypes, message: string, nodes: string[]) {
  return { type, message, nodes };
}
