import { FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';
import {
  AUDIO_AI_MODELS_AND_DESCRIPTIONS,
  CHAT_COMPLETION_AI_MODELS_AND_DESCRIPTIONS,
  DEPRECATED_MODELS_AND_RECOMMENDED_REPLACEMENT_MODELS,
  EDIT_CODE_AI_MODELS_AND_DESCRIPTIONS,
  EDIT_TEXT_AI_MODELS_AND_DESCRIPTIONS,
  EMBEDDING_AI_MODELS_AND_DESCRIPTIONS,
  MODERATION_AI_MODELS_AND_DESCRIPTIONS,
  OPEN_AI_ACTION,
  OPEN_AI_DEFAULT_MAX_TOKENS,
  OPEN_AI_DEFAULT_TEMPERATURE,
  OPEN_AI_DOC_LINKS,
  OPEN_AI_GENERATE_CODE_TYPES,
  OPEN_AI_GENERATE_CODE_TYPES_AND_DESCRIPTIONS,
  OPEN_AI_GENERATE_IMAGE_TYPES,
  OPEN_AI_GENERATE_IMAGE_TYPES_AND_DESCRIPTIONS,
  OPEN_AI_GENERATE_TEXT_TYPES,
  OPEN_AI_GENERATE_TEXT_TYPES_AND_DESCRIPTIONS,
  OPEN_AI_MODEL,
  OPEN_AI_SUPPORTED_IMAGE_SIZES,
  OPEN_AI_TRANSCRIBE_LANGUAGES_AND_ISO_639_1_CODES,
  TEXT_COMPLETION_AI_MODELS_AND_DESCRIPTIONS
} from './constants';

export const OpenAiPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2',
  V3: '0.0.3'
};

export const OPEN_AI_ACTION_AND_DESCRIPTION = {
  [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]: 'Given a prompt, the model will return a chat completion response',
  [OPEN_AI_ACTION.GENERATE_TEXT]: 'Given a prompt, the model will return a text completion response',
  [OPEN_AI_ACTION.GENERATE_CODE]: 'Given a prompt or code, the model will return a code completion response',
  [OPEN_AI_ACTION.GENERATE_IMAGE]: 'Given a prompt or image, the model will return a new image',
  [OPEN_AI_ACTION.CHECK_MODERATION]:
    'Given text, the model will check for hate, hate/threatening, self-harm, sexual, sexual/minors, violence, or violence/graphic content',
  [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT]: 'Given an audio file, convert to text',
  [OPEN_AI_ACTION.GENERATE_TEXT_EMBEDDING]:
    'Given text, will generate a vector representation of the text that can be consumed by ML models and algorithms'
};

const makeDropdownItem = (value: string, subText?: string) => ({
  key: value,
  value: value,
  displayName: DEPRECATED_MODELS_AND_RECOMMENDED_REPLACEMENT_MODELS[value] ? `${value} (deprecated)` : value,
  subText: subText ? subText : ''
});

export const OpenAiPlugin: Plugin = {
  id: 'openai',
  name: 'OpenAI',
  moduleName: 'OpenAiPlugin',
  modulePath: 'plugins/openai/OpenAiPlugin',
  iconLocation: 'https://superblocks.s3.us-west-2.amazonaws.com/img/integrations/openai_logo.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/openai',
  isStreamable: true,
  actionFormDocLinks: [
    {
      name: 'View Superblocks Docs',
      url: 'https://docs.superblocks.com/integrations/integrations-library/openai'
    },
    // Chat GPT
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_CHATGPT_RESPONSE,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
        }
      }
    },
    // Generate Text -- New Text
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_TEXT_NEW,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_TEXT],
          generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.NEW_TEXT]
        }
      }
    },
    // Generate Text -- Edit Text
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_CODE_EDIT,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_TEXT],
          generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.EDIT_TEXT]
        }
      }
    },
    // Generate Code -- New Code
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_CODE_NEW,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_CODE],
          generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.NEW_CODE]
        }
      }
    },
    // Generate Code -- Edit Code
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_CODE_EDIT,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_CODE],
          generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.EDIT_CODE]
        }
      }
    },
    // Generate Image -- New Image
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_IMAGE_NEW,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_IMAGE],
          generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.GENERATE_FROM_PROMPT]
        }
      }
    },
    // Generate Image -- Edit Image
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_IMAGE_EDIT,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_IMAGE],
          generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.EDIT_IMAGE]
        }
      }
    },
    // Generate Image -- Vary Image
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.GENERATE_IMAGE_VARY,
      display: {
        show: {
          action: [OPEN_AI_ACTION.GENERATE_IMAGE],
          generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.VARY_IMAGE]
        }
      }
    },
    // Moderation
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.MODERATION,
      display: {
        show: {
          action: [OPEN_AI_ACTION.CHECK_MODERATION]
        }
      }
    },
    // Transcribe Audio to Text
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.TRANSCRIBE_AUDIO,
      display: {
        show: {
          action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT],
          transcribeAudioToTextTranslateToEnglish: ['false']
        }
      }
    },
    // Transcribe Audio to Text (Translate)
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.TRANSCRIBE_AUDIO_TRANSLATE,
      display: {
        show: {
          action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT],
          transcribeAudioToTextTranslateToEnglish: ['true']
        }
      }
    },
    // Text Embedding
    {
      name: 'View OpenAI Docs',
      url: OPEN_AI_DOC_LINKS.EMBEDDINGS,
      display: {
        show: {
          action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT],
          transcribeAudioToTextTranslateToEnglish: ['true']
        }
      }
    }
  ],
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  rawRequestName: 'Executed request',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'OpenAI Prod',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'API key',
            name: 'bearerToken',
            startVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'API key is required' }]
          },
          {
            label: 'Organization ID (optional)',
            name: 'organizationId',
            startVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            subtitle:
              'Specify which organization should be used for requests. [Read more](https://platform.openai.com/docs/api-reference/requesting-organization)',
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Prompt',
            name: 'textPrompt',
            startVersion: OpenAiPluginVersions.V1,
            endVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'How do you select all rows in a SQL table?',
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          {
            label: 'AI model',
            name: 'aiModel',
            startVersion: OpenAiPluginVersions.V1,
            endVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.TEXT_DAVINCI_003,
            subtitle: 'The AI model to use when generating responses. [Read More](https://platform.openai.com/docs/models)',
            rules: [{ required: true }],
            options: [
              {
                key: OPEN_AI_MODEL.TEXT_DAVINCI_003,
                value: OPEN_AI_MODEL.TEXT_DAVINCI_003,
                displayName: OPEN_AI_MODEL.TEXT_DAVINCI_003
              },
              {
                key: OPEN_AI_MODEL.TEXT_CURIE_001,
                value: OPEN_AI_MODEL.TEXT_CURIE_001,
                displayName: OPEN_AI_MODEL.TEXT_CURIE_001
              },
              {
                key: OPEN_AI_MODEL.TEXT_BABBAGE_001,
                value: OPEN_AI_MODEL.TEXT_BABBAGE_001,
                displayName: OPEN_AI_MODEL.TEXT_BABBAGE_001
              },
              {
                key: OPEN_AI_MODEL.TEXT_ADA_001,
                value: OPEN_AI_MODEL.TEXT_ADA_001,
                displayName: OPEN_AI_MODEL.TEXT_ADA_001
              },
              {
                key: OPEN_AI_MODEL.CODE_DAVINCI_002,
                value: OPEN_AI_MODEL.CODE_DAVINCI_002,
                displayName: OPEN_AI_MODEL.CODE_DAVINCI_002
              },
              {
                key: OPEN_AI_MODEL.CODE_CUSHMAN_001,
                value: OPEN_AI_MODEL.CODE_CUSHMAN_001,
                displayName: OPEN_AI_MODEL.CODE_CUSHMAN_001
              }
            ]
          },
          {
            label: 'Max tokens (optional)',
            name: 'maxTokensToUse',
            startVersion: OpenAiPluginVersions.V1,
            endVersion: OpenAiPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `${OPEN_AI_DEFAULT_MAX_TOKENS}`,
            subtitle:
              'Maximum number of tokens that will be included in the API response. [Read More](https://openai.com/api/pricing/#faq-token)',
            rules: [{ required: false }]
          },
          {
            label: 'Action',
            name: 'action',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE,
            rules: [{ required: true }],
            options: Object.entries(OPEN_AI_ACTION_AND_DESCRIPTION).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Generate ChatGPT Response
          {
            label: 'Prompt',
            name: 'generateChatGptResponsePrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'When was the last World Cup?',
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
              }
            },
            rules: [{ required: true, message: 'A prompt is required' }],
            tooltip: {
              markdownText:
                'The user input or question used to prompt the OpenAI API to generate a response. [Read more](https://platform.openai.com/docs/guides/chat/introduction)'
            },
            subtitle: 'E.g. for a Chat Component, it will be {{Chat1.userMessageText}}'
          },
          {
            label: 'Message history',
            name: 'generateChatGptResponseMessageHistory',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: '[{"role": "user", "content": "When was the last olympics?"}]',
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
              }
            },
            tooltip: {
              markdownText:
                'Previous conversation history between the user and OpenAI. This enables users to reference prior messages in their prompt. [Read more](https://platform.openai.com/docs/guides/chat/introduction)'
            },
            subtitle: 'E.g. for a Chat Component, it will be {{Chat1.messageHistory}}'
          },
          // Generate Text
          {
            label: 'Type',
            name: 'generateTextType',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_GENERATE_TEXT_TYPES.NEW_TEXT,
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT]
              }
            },
            options: Object.entries(OPEN_AI_GENERATE_TEXT_TYPES_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          {
            label: 'Prompt',
            name: 'generateTextNewTextPrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Generate a report summarizing customer feedback for Q4 in {{Table1.tableData}}',
            tooltip: {
              markdownText:
                'The text or question describing the text to generate. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/completions/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.NEW_TEXT]
              }
            },
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          {
            label: 'Text to edit',
            name: 'generateTextEditTextTextToEdit',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'What day of the wek is it?',
            tooltip: {
              markdownText:
                'The original text that requires editing. Used as the basis for generating a revised version of the text. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/edits/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.EDIT_TEXT]
              }
            },
            rules: [{ required: true, message: 'Text to Edit is required' }]
          },
          {
            label: 'Prompt',
            name: 'generateTextEditTextPrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Fix the spelling mistake',
            tooltip: {
              markdownText:
                'The instruction that tells the model how to edit the original text. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/edits/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.EDIT_TEXT]
              }
            },
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          // Generate Code
          {
            label: 'Type',
            name: 'generateCodeType',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_GENERATE_CODE_TYPES.NEW_CODE,
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE]
              }
            },
            options: Object.entries(OPEN_AI_GENERATE_CODE_TYPES_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          {
            label: 'Prompt',
            name: 'generateCodeNewCodePrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Generate a JavaScript function to validate an email address',
            tooltip: {
              markdownText:
                'The text or question describing the code to generate. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/completions/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE],
                generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.NEW_CODE]
              }
            },
            rules: [{ required: true, message: 'New Code is required' }]
          },
          {
            label: 'Code to edit',
            name: 'generateCodeEditCodeCodeToEdit',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            tooltip: {
              markdownText:
                'The original code that requires editing. Used as the basis for generating a revised version of the code. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/edits/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE],
                generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.EDIT_CODE]
              }
            },
            rules: [{ required: true, message: 'Code to Edit is required' }]
          },
          {
            label: 'Prompt',
            name: 'generateCodeEditCodePrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Refactor for performance',
            tooltip: {
              markdownText:
                'The instruction that tells the model how to edit the original code. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/edits/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE],
                generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.EDIT_CODE]
              }
            },
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          // Check Moderation
          {
            label: 'Text',
            name: 'checkModerationText',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Koalas are the coolest!',
            tooltip: {
              markdownText:
                'The text to run a moderation check on. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/moderations/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.CHECK_MODERATION]
              }
            },
            rules: [{ required: true, message: 'Text is required' }]
          },
          // Generate Text Embedding
          {
            label: 'Input',
            name: 'embeddingText',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: "Koalas aren't bears, they're marsupials!",
            tooltip: {
              markdownText:
                'Input text to get embeddings for. Must not exceed 8,192 tokens. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/embeddings/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT_EMBEDDING]
              }
            },
            rules: [{ required: true, message: 'Input is required' }]
          },
          // Generate image
          {
            label: 'Method',
            name: 'generateImageMethod',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_GENERATE_IMAGE_TYPES.GENERATE_FROM_PROMPT,
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE]
              }
            },
            options: Object.entries(OPEN_AI_GENERATE_IMAGE_TYPES_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          {
            label: 'Prompt',
            name: 'generateImageGenerateFromPromptPrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'A cute koala logo',
            tooltip: {
              markdownText:
                'A text description of the desired image. The maximum length is 1000 characters. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.GENERATE_FROM_PROMPT]
              }
            },
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          {
            label: 'Image size',
            name: 'generateImageGenerateFromPromptImageImageSize',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_SUPPORTED_IMAGE_SIZES[0],
            tooltip: {
              markdownText:
                'The size of the generated images. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.GENERATE_FROM_PROMPT]
              }
            },
            options: OPEN_AI_SUPPORTED_IMAGE_SIZES.map((value) => makeDropdownItem(value))
          },
          {
            label: 'Prompt',
            name: 'generateImageEditImagePrompt',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Change the image background to blue',
            tooltip: {
              markdownText:
                'A text description of the desired image. The maximum length is 1000 characters. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-edit)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.EDIT_IMAGE]
              }
            },
            rules: [{ required: true, message: 'Prompt is required' }]
          },
          {
            label: 'Image file to edit',
            name: 'generateImageEditImageImageFileToEdit',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            subtitle: 'E.g. for a File Picker, it will be {{FilePicker1.files[0]}}',
            tooltip: {
              markdownText:
                'The image to edit. Must be a valid PNG file, less than 4MB, and square. If mask is not provided, image must have transparency, which will be used as the mask. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-edit)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.EDIT_IMAGE]
              }
            },
            rules: [{ required: true, message: 'Image File to Edit is required' }]
          },
          {
            label: 'Image mask (optional)',
            name: 'generateImageEditImageImageMask',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            subtitle: 'E.g. for a File Picker, it will be {{FilePicker1.files[0]}}',
            tooltip: {
              markdownText:
                'An additional image whose fully transparent areas (e.g. where alpha is zero) indicate where image should be edited. Must be a valid PNG file, less than 4MB, and have the same dimensions as image. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-edit)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.EDIT_IMAGE]
              }
            },
            rules: [{ required: false }]
          },
          {
            label: 'Generated image size',
            name: 'generateImageEditImageImageSizes',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_SUPPORTED_IMAGE_SIZES[0],
            tooltip: {
              markdownText:
                'The size of the generated images. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-edit)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.EDIT_IMAGE]
              }
            },
            options: OPEN_AI_SUPPORTED_IMAGE_SIZES.map((value) => makeDropdownItem(value))
          },
          {
            label: 'Image file',
            name: 'generateImageVaryImageImageFile',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            subtitle: 'E.g. for a File Picker, it will be {{FilePicker1.files[0]}}',
            tooltip: {
              markdownText:
                'The image to use as the basis for the variation. Must be a valid PNG file, less than 4MB, and square. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-variation)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.VARY_IMAGE]
              }
            },
            rules: [{ required: true, message: 'Image File is required' }]
          },
          {
            label: 'Image size',
            name: 'generateImageVaryImageImageSize',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_SUPPORTED_IMAGE_SIZES[0],
            tooltip: {
              markdownText:
                'The size of the generated image. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/images/create-variation)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_IMAGE],
                generateImageMethod: [OPEN_AI_GENERATE_IMAGE_TYPES.VARY_IMAGE]
              }
            },
            options: OPEN_AI_SUPPORTED_IMAGE_SIZES.map((value) => makeDropdownItem(value))
          },
          // Transcribe Audio
          {
            label: 'Audio file',
            name: 'transcribeAudioToTextAudioFile',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            subtitle: 'E.g. for a File Picker, it will be {{FilePicker1.files[0]}}',
            tooltip: {
              markdownText:
                'The audio file to transcribe, in one of these formats: mp3, mp4, mpeg, mpga, m4a, wav, or webm. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/audio/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT]
              }
            },
            rules: [{ required: true, message: 'Audio File is required' }]
          },
          {
            label: 'Translate to english',
            name: 'transcribeAudioToTextTranslateToEnglish',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.CHECKBOX,
            // TODO: make this default to unchecked in the future.
            initialValue: 'checked',
            tooltip: {
              markdownText:
                'Translates the input audio to english. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/audio/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT]
              }
            }
          },
          {
            label: 'Input language',
            name: 'transcribeAudioToTextInputLanguage',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'Auto Detect',
            tooltip: {
              markdownText:
                'The language the input audio is in. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/audio/create)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT],
                transcribeAudioToTextTranslateToEnglish: ['false']
              }
            },
            options: Object.keys(OPEN_AI_TRANSCRIBE_LANGUAGES_AND_ISO_639_1_CODES).map((key) => makeDropdownItem(key))
          }
        ]
      },
      {
        name: 'advancedTuningParameters',
        sectionHeader: 'Additional Settings',
        defaultCollapsed: true,
        isCollapsible: true,
        items: [
          // AI MODELS
          // Generate ChatGPT Response AI models
          {
            label: 'AI model',
            name: 'generateChatGPTResponseAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.GPT_3_5_TURBO,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
              }
            },
            options: Object.entries(CHAT_COMPLETION_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Generate Text AI models
          // New Text
          {
            label: 'AI model',
            name: 'generateTextNewTextAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.TEXT_DAVINCI_003,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.NEW_TEXT]
              }
            },
            options: Object.entries(TEXT_COMPLETION_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Edit Text
          {
            label: 'AI model',
            name: 'generateTextEditTextAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.TEXT_DAVINCI_EDIT_001,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.EDIT_TEXT]
              }
            },
            options: Object.entries(EDIT_TEXT_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Generate Code AI models
          // New Code
          {
            label: 'AI model',
            name: 'generateCodeNewCodeAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.GPT_3_5_TURBO,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE],
                generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.NEW_CODE]
              }
            },
            options: Object.entries(CHAT_COMPLETION_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Edit Code
          {
            label: 'AI model',
            name: 'generateCodeEditCodeAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.CODE_DAVINCI_EDIT_001,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CODE],
                generateCodeType: [OPEN_AI_GENERATE_CODE_TYPES.EDIT_CODE]
              }
            },
            options: Object.entries(EDIT_CODE_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Moderate AI models
          {
            label: 'AI model',
            name: 'checkModerationAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.TEXT_MODERATION_LATEST,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            subtitle: 'The AI model to use when generating responses. [Read more](https://platform.openai.com/docs/models)',
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.CHECK_MODERATION]
              }
            },
            options: Object.entries(MODERATION_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Text Embedding AI models
          {
            label: 'AI model',
            name: 'generateTextEmbeddingAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.TEXT_EMBEDDING_ADA_002,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT_EMBEDDING]
              }
            },
            options: Object.entries(EMBEDDING_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // AUDIO AI MODELS
          {
            label: 'AI model',
            name: 'transcribeAudioToTextAiModel',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: OPEN_AI_MODEL.WHISPER_1,
            tooltip: {
              markdownText:
                'The AI model to use when generating responses. [View OpenAI Docs](https://platform.openai.com/docs/models/overview)'
            },
            subtitle: 'The AI model to use when generating responses. [Read more](https://platform.openai.com/docs/models)',
            rules: [{ required: true }],
            display: {
              show: {
                action: [OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT]
              }
            },
            options: Object.entries(AUDIO_AI_MODELS_AND_DESCRIPTIONS).map(([key, value]) => makeDropdownItem(key, value))
          },
          // Max tokens
          {
            label: 'Max tokens (optional)',
            name: 'generateChatGptResponseMaxTokens',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `${OPEN_AI_DEFAULT_MAX_TOKENS}`,
            initialValue: `${OPEN_AI_DEFAULT_MAX_TOKENS}`, // must be a string to match proto
            tooltip: {
              markdownText:
                'Maximum number of tokens (words or symbols) the generated response can contain. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/chat)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
              }
            },
            rules: [{ required: false }]
          },
          {
            label: 'Max tokens (optional)',
            name: 'generateTextNewTextMaxTokens',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `${OPEN_AI_DEFAULT_MAX_TOKENS}`,
            initialValue: `${OPEN_AI_DEFAULT_MAX_TOKENS}`, // must be a string to match proto
            tooltip: {
              markdownText:
                'Maximum number of tokens (words or symbols) the generated response can contain. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/chat)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_TEXT],
                generateTextType: [OPEN_AI_GENERATE_TEXT_TYPES.NEW_TEXT]
              }
            },
            rules: [{ required: false }]
          },
          {
            label: 'System instruction (optional)',
            name: 'generateChatGptResponseSystemInstruction',
            startVersion: OpenAiPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'Be a helpful assistant',
            tooltip: {
              markdownText:
                'Specific instruction to set the intended behavior of the AI language model, such as “Be a helpful assistant”, “Speak as F500 CEO”, “Make responses funny”. [View OpenAI Docs](https://platform.openai.com/docs/api-reference/chat)'
            },
            display: {
              show: {
                action: [OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE]
              }
            },
            rules: [{ required: false }]
          },
          {
            label: 'Temperature (optional)',
            name: 'temperature',
            startVersion: OpenAiPluginVersions.V3,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `${OPEN_AI_DEFAULT_TEMPERATURE}`,
            tooltip: {
              markdownText:
                'The sampling temperature. Higher values make the output more random, lower values will make it more focused. [View OpenAI Docs](https://platform.openai.com/docs/guides/gpt/how-should-i-set-the-temperature-parameter)'
            },
            display: {
              show: {
                action: [
                  OPEN_AI_ACTION.GENERATE_CHATGPT_RESPONSE,
                  OPEN_AI_ACTION.GENERATE_TEXT,
                  OPEN_AI_ACTION.GENERATE_CODE,
                  OPEN_AI_ACTION.TRANSCRIBE_AUDIO_TO_TEXT
                ]
              }
            },
            rules: [{ required: false }]
          }
        ]
      }
    ]
  }
};
