import React, { useCallback, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import SplitPane from "components/app/SplitPane";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import ExpandIconButton from "./IconButtons/ExpandIconButton";
import { SectionTitle } from "./PropertyPanelComponents";
import { ScrollContainer } from "./ScrollContainer";

const PropertyContentWrapperClass = styleAsClass`
  overflow-y: auto;
`;

const DrawerPaneStyle = styleAsClass`
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.05);
  border-top: 1px solid ${colors.GREY_100};
  top: -1px; // Otherwise there's a weird 1px gap
`;

const EventContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DrawerTitleSection = styled(SectionTitle)`
  padding: 12px 16px;
  height: 48px;
  box-sizing: border-box;
`;

// "auto" means the drawer fits the height of the content
const DRAWER_AUTO_HEIGHT = "auto";

// Min height is just enough to ensure the header for the drawer is visible
// so this is also the exact height of the header
const DRAWER_MIN_HEIGHT = 48;

// How much space do we want to leave at the top of the bottom drawer so that it doesn't cover the entire property pane?
// 50px seems to be enough to show the full first section header
const TOP_BUFFER_PX = 50;

const isNumber = (value: string | number): value is number =>
  !isNaN(Number(value));

export const persistBottomDrawerSize = (size: number | "auto") => {
  sessionStorage.setItem(
    SessionStorageKey.PROPERTY_PANE_BOTTOM_DRAWER_SIZE,
    size.toString(),
  );
};

const clearBottomDrawerSize = () => {
  sessionStorage.removeItem(SessionStorageKey.PROPERTY_PANE_BOTTOM_DRAWER_SIZE);
};

const getPersistedBottomDrawerSize = () => {
  const persistedSize = sessionStorage.getItem(
    SessionStorageKey.PROPERTY_PANE_BOTTOM_DRAWER_SIZE,
  );
  if (persistedSize && isNumber(persistedSize)) {
    return parseInt(persistedSize, 10);
  }
  return DRAWER_AUTO_HEIGHT;
};

const useResizeHandler = () => {
  const [bottomDrawerSize, setBottomDrawerSize] = useState<number | "auto">(
    getPersistedBottomDrawerSize(),
  );
  const lastOpenSize = useRef(bottomDrawerSize);

  const isOpen =
    bottomDrawerSize === "auto" || bottomDrawerSize > DRAWER_MIN_HEIGHT;

  const setAutoHeight = useCallback(() => {
    setBottomDrawerSize(DRAWER_AUTO_HEIGHT);
    clearBottomDrawerSize();
  }, [setBottomDrawerSize]);

  const handleResize = useCallback(
    (_size: unknown, isToggleClose = false) => {
      let size = Number(_size);

      if (isNaN(size)) {
        setAutoHeight();
        return;
      }

      // do not allow the drawer to be smaller than the min height
      if (size <= DRAWER_MIN_HEIGHT) {
        size = DRAWER_MIN_HEIGHT;
      }

      // if the drawer is being opened, update the last open size
      if (isToggleClose === false) {
        lastOpenSize.current = size;
      }
      setBottomDrawerSize(size);
      persistBottomDrawerSize(size);
    },
    [setAutoHeight],
  );

  const handleToggle = useCallback(() => {
    if (isOpen) {
      handleResize(DRAWER_MIN_HEIGHT, true);
    } else {
      setAutoHeight();
    }
  }, [handleResize, isOpen, setAutoHeight]);

  return {
    isOpen,
    bottomDrawerSize,
    handleResize,
    handleToggle,
    setAutoHeight,
  };
};

// This component is currently tied to Event handlers being the bottom drawer.
// Make this component generic if we want to use this pattern in other places
function PropertiesPanelSplitPane(props: {
  mainContent: React.ReactNode;
  eventHandlerContent: React.ReactNode;
}) {
  const { mainContent, eventHandlerContent } = props;

  const contentScrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    isOpen,
    bottomDrawerSize,
    handleResize,
    handleToggle,
    setAutoHeight,
  } = useResizeHandler();

  const resetToAutoHeight = useCallback(() => {
    handleResize(DRAWER_AUTO_HEIGHT);
  }, [handleResize]);

  const onHeaderClicked = useCallback(() => {
    handleToggle();
  }, [handleToggle]);

  const handleDragFinished = useCallback(
    (newSize?: string | number) => {
      // check if the size is equal to the total content height, if so, we're at the max height
      // and want to set the size to auto
      const totalContentHeight =
        (contentScrollContainerRef.current?.scrollHeight || 0) +
        DRAWER_MIN_HEIGHT;

      if (
        !isNaN(Number(newSize)) &&
        Number(newSize) > DRAWER_MIN_HEIGHT &&
        Math.abs(Number(newSize) - totalContentHeight) <= 3
      ) {
        setAutoHeight();
      }
    },
    [contentScrollContainerRef, setAutoHeight],
  );

  const pane2Style = useMemo(() => {
    // If the drawer height is auto, we want it to fit the content size but have a max height
    // that doesn't cover the entire property pane
    if (bottomDrawerSize === DRAWER_AUTO_HEIGHT) {
      return {
        height: "auto",
        maxHeight: `calc(100% - ${TOP_BUFFER_PX}px)`,
      };
    }

    // If the drawer height has been set by thge user, we want it to be: min(content height, user set height)
    // While I know this CSS below doesn't quite read as if it does that, it does.
    return {
      minHeight: "auto",
      height: "auto",
      maxHeight: bottomDrawerSize,
    };
  }, [bottomDrawerSize]);

  return (
    <SplitPane
      split="horizontal"
      primary="second"
      pane1ClassName={PropertyContentWrapperClass}
      pane2ClassName={`${PropertyContentWrapperClass} ${DrawerPaneStyle}`}
      pane2Style={pane2Style}
      onResizerDoubleClick={resetToAutoHeight}
      onChange={handleResize}
      onDragFinished={handleDragFinished}
      minSize={DRAWER_MIN_HEIGHT}
      size={bottomDrawerSize}
      defaultSize={bottomDrawerSize}
    >
      <ScrollContainer style={{ overflowX: "hidden" }}>
        {mainContent}
      </ScrollContainer>
      <EventContentWrapper
        style={{
          overflowY: isOpen ? "auto" : "hidden",
        }}
      >
        <DrawerTitleSection
          onClick={onHeaderClicked}
          data-header="Collapse"
          data-test={`property-section-header-EventHandlers-${
            isOpen ? "open" : "closed"
          }`}
        >
          <span>Event handlers</span>
          <ExpandIconButton isExpanded={isOpen} />
        </DrawerTitleSection>
        <ScrollContainer
          className="EventHandlersContentScrollWrapper"
          ref={contentScrollContainerRef}
          scroll={isOpen}
        >
          {eventHandlerContent}
        </ScrollContainer>
      </EventContentWrapper>
    </SplitPane>
  );
}

export default PropertiesPanelSplitPane;
