import CodeMirror from "codemirror";
import "codemirror/addon/mode/multiplex";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/sql-hint";

import { EditorModes } from "./EditorConfig";

const BINDING_PREFIX = "binding";

export const BASE_MODE_ERROR = `sb-error`;

CodeMirror.defineMode(EditorModes.TEXT_WITH_BINDING, function (config) {
  return CodeMirror.multiplexingMode(
    CodeMirror.getMode(config, EditorModes.TEXT),
    {
      open: "{{",
      close: "}}",
      delimStyle: BINDING_PREFIX,
      mode: CodeMirror.getMode(config, {
        name: "javascript",
      }),
    },
  );
});

CodeMirror.defineMode(EditorModes.JSON_WITH_BINDING, function (config) {
  return CodeMirror.multiplexingMode(
    CodeMirror.getMode(config, { name: "javascript", json: true }),
    {
      open: "{{",
      close: "}}",
      delimStyle: BINDING_PREFIX,
      mode: CodeMirror.getMode(config, {
        name: "javascript",
      }),
    },
  );
});

CodeMirror.defineMode(EditorModes.SQL_WITH_BINDING, function (config) {
  return CodeMirror.multiplexingMode(
    CodeMirror.getMode(config, EditorModes.SQL),
    {
      open: "{{",
      close: "}}",
      delimStyle: BINDING_PREFIX,
      mode: CodeMirror.getMode(config, {
        name: "javascript",
      }),
    },
  );
});

CodeMirror.defineMode(EditorModes.JAVASCRIPT, function (config) {
  return CodeMirror.multiplexingMode(CodeMirror.getMode(config, "javascript"), {
    open: "{{",
    close: "}}",
    delimStyle: BASE_MODE_ERROR,
    innerStyle: `${BASE_MODE_ERROR}-binding`,
    mode: CodeMirror.getMode(config, {
      name: "text/plain",
    }),
  });
});

// This is a placeholder so that we can define our own Python mode that potentially
// warns when the user writes {{ but we've disabled the separate mode because {{
// is the escape code inside format strings.
// See the EditorModes.JAVASCRIPT mode definition above for reference of what was
// initially attempted for python.
CodeMirror.defineMode(EditorModes.PYTHON, function (config) {
  return CodeMirror.getMode(config, "python");
});
