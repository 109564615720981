import { ParallelConfig } from "@superblocksteam/shared";
import * as BackendTypes from "../backend-types";
import {
  GenericBlock,
  BreakControl,
  ReturnControl,
  WaitControl,
  ParallelControl,
  ConditionControl,
  LoopControl,
  LoopType,
  TryCatchControl,
  VariablesControl,
  ParallelWait,
  StepConfig,
  ThrowControl,
  BlockType,
  SendControl,
  StreamControl,
} from "./types";

export const convertBlockToBackend = (
  block: GenericBlock,
  generateChild: (childName: string) => BackendTypes.Block,
): BackendTypes.Block => {
  const { type, config } = block;
  const baseBlock = {
    name: block.name,
  };
  if (type === BlockType.STEP) {
    const stepConfig = block.config as StepConfig;
    if (stepConfig.pluginId == null) {
      throw new Error("Step must have a plugin");
    }
    return {
      ...baseBlock,
      step: {
        integration: stepConfig.datasourceId,
        [stepConfig.pluginId]: stepConfig.configuration,
      },
    };
  }
  switch (type) {
    case BlockType.BREAK: {
      const controlConfig = config as BreakControl;
      return {
        ...baseBlock,
        break: {
          condition: controlConfig.condition,
        },
      };
    }
    case BlockType.THROW: {
      const controlConfig = config as ThrowControl;
      return {
        ...baseBlock,
        throw: {
          error: controlConfig.error,
        },
      };
    }
    case BlockType.RETURN: {
      const controlConfig = config as ReturnControl;
      return {
        ...baseBlock,
        return: {
          data: controlConfig.data,
        },
      };
    }
    case BlockType.WAIT: {
      const controlConfig = config as WaitControl;
      return {
        ...baseBlock,
        wait: {
          condition: controlConfig.condition,
        },
      };
    }
    case BlockType.PARALLEL: {
      const controlConfig = config as ParallelControl;
      const convertedWait = {
        [ParallelWait.WAIT_ALL]: BackendTypes.WAIT_STR.WAIT_ALL,
        [ParallelWait.WAIT_NONE]: BackendTypes.WAIT_STR.WAIT_NONE,
        [ParallelWait.WAIT_UNSPECIFIED]: BackendTypes.WAIT_NUM.WAIT_UNSPECIFIED,
      }[controlConfig.wait];

      const parallelConfig: ParallelConfig = {
        wait: convertedWait,
        poolSize: controlConfig.poolSize,
      };

      if (controlConfig.static != null) {
        const paths = controlConfig.static.paths ?? {};
        parallelConfig.static = {
          paths: Object.keys(paths).reduce(
            (
              accum: Record<string, { blocks: BackendTypes.Block[] }>,
              pathName,
            ) => {
              accum[pathName] = {
                blocks: paths[pathName].map(generateChild),
              };
              return accum;
            },
            {},
          ),
        };
      } else if (controlConfig.dynamic != null) {
        const blocks = controlConfig.dynamic.blocks ?? [];
        parallelConfig.dynamic = {
          blocks: blocks.map(generateChild),
          variables: controlConfig.dynamic.variables,
          paths: controlConfig.dynamic.paths,
        };
      }

      return {
        ...baseBlock,
        parallel: parallelConfig,
      };
    }
    case BlockType.CONDITION: {
      const controlConfig = config as ConditionControl;

      return {
        ...baseBlock,
        conditional: {
          if: {
            condition: controlConfig.if.condition,
            blocks: controlConfig.if.blocks.map(generateChild),
          },
          elseIf: (controlConfig.elseIf ?? []).map((cond) => ({
            condition: cond.condition,
            blocks: cond.blocks.map(generateChild),
          })),
          ...(controlConfig.else && {
            else: {
              blocks:
                controlConfig.else && controlConfig.else.map(generateChild),
            },
          }),
        },
      };
    }
    case BlockType.LOOP: {
      const controlConfig = config as LoopControl;
      const convertedType = {
        [LoopType.UNSPECIFIED]: BackendTypes.LOOP_TYPE.UNSPECIFIED,
        [LoopType.FOR]: BackendTypes.LOOP_TYPE.FOR,
        [LoopType.FOREACH]: BackendTypes.LOOP_TYPE.FOREACH,
        [LoopType.WHILE]: BackendTypes.LOOP_TYPE.WHILE,
      }[controlConfig.type];

      return {
        ...baseBlock,
        loop: {
          type: convertedType,
          variables: controlConfig.variables,
          range: controlConfig.range,
          blocks: controlConfig.blocks.map(generateChild),
        },
      };
    }
    case BlockType.TRY_CATCH: {
      const controlConfig = config as TryCatchControl;

      return {
        ...baseBlock,
        tryCatch: {
          variables: controlConfig.variables,
          try: { blocks: controlConfig.try.map(generateChild) },
          catch: { blocks: controlConfig.catch.map(generateChild) },
          finally: controlConfig.finally && {
            blocks: controlConfig.finally.map(generateChild),
          },
        },
      };
    }
    case BlockType.VARIABLES: {
      const controlConfig = config as VariablesControl;

      return {
        ...baseBlock,
        variables: {
          items: controlConfig.variables,
        },
      };
    }
    case BlockType.SEND: {
      const controlConfig = config as SendControl;
      return {
        ...baseBlock,
        send: {
          message: controlConfig.message,
        },
      };
    }
    case BlockType.STREAM: {
      const controlConfig = config as StreamControl;
      return {
        ...baseBlock,
        stream: {
          trigger: controlConfig.trigger?.length
            ? generateChild(controlConfig.trigger[0])
            : undefined,
          process: controlConfig.process
            ? {
                blocks: controlConfig.process.map(generateChild),
              }
            : undefined,
          variables: controlConfig.variables,
          options: {
            disable_auto_send: !controlConfig.autoSend,
          },
        },
      };
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};
