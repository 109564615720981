import { PageDSL8, WidgetProps8 } from "@superblocksteam/shared";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 2. Never update a migration. Instead, create a new one.
 ***/

const toFitContentSections = (page: PageDSL8) => {
  const updateSectionWidgets = (children: WidgetProps8[]) => {
    children.forEach((widget) => {
      if (widget.type === "SECTION_WIDGET") {
        // Update the height for all sections
        widget.height.mode = "fitContent";
      }
      if (widget.children) {
        updateSectionWidgets(widget.children);
      }
    });
  };

  updateSectionWidgets(page.children ?? []);
  return page;
};

export default toFitContentSections;
