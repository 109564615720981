import { BillingPlan } from "@superblocksteam/shared";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { call } from "redux-saga/effects";
import { SessionStorageKey } from "legacy/utils/sessionStorage";
import { createSaga } from "store/utils/saga";
import { ROOT } from "store/utils/types";
import flagClient from "../client";
import { Flag, Flags } from "../models/Flags";
import slice from "../slice";

interface GetFeatureFlagsPayload {
  organizationId: string;
  organizationBillingPlan: BillingPlan;
  flags: LDFlagSet;
}

function* getFeatureFlagsInternal({
  organizationId,
  organizationBillingPlan,
  flags,
}: GetFeatureFlagsPayload) {
  const url = new URL(window.location.href);

  const rawFlagOverrides = sessionStorage.getItem(
    SessionStorageKey.FEATURE_OVERRIDES,
  );
  let flagOverrides: Record<string, unknown> = {};
  try {
    flagOverrides = rawFlagOverrides ? JSON.parse(rawFlagOverrides) : {};
    url.searchParams.forEach((flagName, op) => {
      if (typeof flags[flagName] !== "undefined") {
        if (op === "sb_ff_on") {
          flagOverrides[flagName] = true;
        } else if (op === "sb_ff_off") {
          flagOverrides[flagName] = false;
        }
      }
    });

    sessionStorage.setItem(
      SessionStorageKey.FEATURE_OVERRIDES,
      JSON.stringify(flagOverrides),
    );
  } catch (e) {
    // Noop
  }

  // Sales flags shouldn't be overwritten.
  if (Flag.SALES_SETTINGS_JSON in flagOverrides) {
    delete flagOverrides[Flag.SALES_SETTINGS_JSON];
  }

  const response: Flags = yield call(
    flagClient.getKeysForOrg,
    organizationId,
    organizationBillingPlan,
    {
      ...flags,
      ...flagOverrides,
    },
  );
  return response;
}

export const getFeatureFlagSaga = createSaga(
  getFeatureFlagsInternal,
  "getFeatureFlags",
  {
    sliceName: "featureFlags",
  },
);

slice.saga(getFeatureFlagSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { organizationId: payload.organizationId };
    delete state.errors[ROOT];
  },
  success(state, { payload }) {
    state.entities[ROOT] = payload;
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
});
