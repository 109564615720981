import { WidgetTypes } from "@superblocksteam/shared";
import { getGraphIdFromWidgetId } from "analysis/entityUtils";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { isWidget } from "legacy/workers/evaluationUtils";
import { ReferenceEdgeType, UsageEdgeType } from "../types";
import { UsageContext } from "./UsageContext";
import { type EdgeInfo } from "./types";

export const calculateWidgetUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const { referenceGraph, canvasWidgets } = context;
  const entity = referenceGraph.node(nodeId);
  if (!entity || !isWidget(entity)) {
    throw new Error(`Expected entity to be widget`);
  }
  const widgetProps: FlattenedWidgetProps = canvasWidgets[entity.widgetId];
  if (!widgetProps) {
    throw new Error(`Widget not found: ${entity.widgetId}`);
  }

  const widgetParent = canvasWidgets[widgetProps.parentId];

  const widgetType = widgetProps.type as WidgetType;
  switch (widgetType) {
    case WidgetTypes.SLIDEOUT_WIDGET:
    case WidgetTypes.MODAL_WIDGET:
      return calculateModalOrSlideoutWidgetUsageEdges(
        widgetProps,
        nodeId,
        context,
      );
    case WidgetTypes.CANVAS_WIDGET: {
      const parentIsTab = widgetParent?.type === WidgetTypes.TABS_WIDGET;
      if (parentIsTab) {
        return calculateTabCanvasUsageEdges(
          widgetProps,
          widgetParent,
          nodeId,
          context,
        );
      }
      return defaultCalculateWidgetUsage(widgetProps, nodeId, context);
    }
    default: {
      return defaultCalculateWidgetUsage(widgetProps, nodeId, context);
    }
  }
};

const calculateTabCanvasUsageEdges = (
  widget: FlattenedWidgetProps,
  parentWidget: FlattenedWidgetProps,
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const parentNodeId = getGraphIdFromWidgetId(
    context.canvasWidgets,
    parentWidget.widgetId,
  );
  if (!parentNodeId) {
    throw new Error(`Parent node not found for tab canvas: ${nodeId}`);
  }
  const isParentUsed = context.usageGraph.node(parentNodeId)?.isUsed;
  if (!isParentUsed) {
    return [];
  }

  // CH; EG-22287: deleting multiple tabs breaks the app because logic in WidgetOperationSagas yields individual delete action handlers for each deleted tab
  // Conservatively mark all tab canvases used.
  return [
    {
      usageSourceNodeId: parentNodeId,
      edgeType: UsageEdgeType.PARENT,
    },
  ];
  // const tabWidget = parentWidget as TabsWidgetProps<any>;
  // const tabHeaderExplicitlyHidden = tabWidget.shouldShowTabs === false;
  // const defaultTabIsDynamic =
  //   tabWidget.dynamicBindingPathList?.length &&
  //   tabWidget.dynamicBindingPathList?.some(({ key }) => {
  //     return key === "defaultTab";
  //   });

  // const childTabName = "tabName" in widget ? widget.tabName : undefined;
  // const isDefaultTab = defaultTabIsDynamic
  //   ? false
  //   : tabWidget.defaultTab === childTabName;

  // if (!tabHeaderExplicitlyHidden || defaultTabIsDynamic || isDefaultTab) {
  //   // the tab can be clicked on by the user or it could be the default tab, so we should consider it used
  //   return [
  //     {
  //       usageSourceNodeId: parentNodeId,
  //       edgeType: UsageEdgeType.PARENT,
  //     },
  //   ];
  // }

  // // we want to check if the parent tabs widget has any inbound event references, because this means that our tab could get selected
  // const usedPredecessorsOfParent = context.getUsedRefPredecessors(
  //   parentNodeId,
  //   false, // important - do not influence the used cache
  // );

  // const usageEdges: EdgeInfo[] = [];
  // for (const predecessorId of usedPredecessorsOfParent) {
  //   const hasEventEdge = context.referenceGraph.hasEdge(
  //     predecessorId,
  //     parentNodeId,
  //     ReferenceEdgeType.EVENT,
  //   );
  //   if (hasEventEdge) {
  //     usageEdges.push({
  //       usageSourceNodeId: predecessorId,
  //       edgeType: UsageEdgeType.ACTIVATION,
  //     });
  //   }
  // }

  // return usageEdges;
};

// Modals and Slideouts are used if they have an incoming event edge from a used entity
// technically they're also used if there's an incoming binding (e.g. Text1 references Modal1.isVisible)
const calculateModalOrSlideoutWidgetUsageEdges = (
  widget: FlattenedWidgetProps,
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;

  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);
  const usageEdges: EdgeInfo[] = [];

  for (const predecessorId of usedPredecessors) {
    const hasEventEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.EVENT,
    );
    const hasBindingEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.BINDING,
    );

    if (hasEventEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.ACTIVATION,
      });
    }
    if (hasBindingEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }
  }

  return usageEdges;
};

const defaultCalculateWidgetUsage = (
  widget: FlattenedWidgetProps,
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;

  const widgetVisibility = widget.isVisible;
  const isStrictlyInvisible = widgetVisibility === false;

  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);
  const usageEdges: EdgeInfo[] = [];

  for (const predecessorId of usedPredecessors) {
    const hasParentEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.PARENT,
    );

    const hasBindingEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.BINDING,
    );

    // event edges do not imply usage, so don't bother checking

    if (hasParentEdge && !isStrictlyInvisible) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.PARENT,
      });
    }
    if (hasBindingEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }
  }
  return usageEdges;
};
