import {
  NotificationPosition,
  ThemeMode,
  UserDefinedTheme,
} from "@superblocksteam/shared";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  copyCurrentUserDefinedTheme,
  pasteCopiedUserDefinedTheme,
} from "legacy/actions/themeActions";
import {
  ReduxActionTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { applyThemeToWrapper } from "legacy/themes";
import { generateTheme } from "legacy/themes/generateTheme";
import { getThemeWithVersion } from "legacy/themes/utils";
import {
  getCopiedUserDefinedTheme,
  saveCopiedUserDefinedTheme,
} from "legacy/utils/StorageUtils";
import {
  updateApplication,
  requestApplicationSave,
} from "store/slices/application/applicationActions";
import { getCurrentApplication } from "store/slices/application/selectors";
import { sendMessage } from "utils/iframe";
import logger from "utils/logger";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
  sendWarningUINotification,
} from "utils/notification";

function* generateAndSetTheme(params: {
  userDefinedTheme: UserDefinedTheme | undefined;
  overrideMode?: ThemeMode;
}) {
  const theme = getThemeWithVersion(params.userDefinedTheme);
  const generatedTheme = generateTheme(theme, params.overrideMode);
  // update in redux
  yield put({
    type: ReduxActionTypes.UPDATE_GENERATED_THEME,
    payload: { generatedTheme, storedTypographies: theme.typographies },
  });
  // update on the wrapper component
  applyThemeToWrapper(generatedTheme);
  // update on the iframe
  sendMessage({
    type: "set-theme",
    payload: {
      theme: generatedTheme,
      updateRedux: true,
      storedTypographies: theme.typographies,
    },
  });
}

// generate a new theme based on the mode
function* setOverrideThemeModeSaga(action: ReduxAction<{ mode?: ThemeMode }>) {
  const currentApplication: ReturnType<typeof getCurrentApplication> =
    yield select(getCurrentApplication);
  const userDefinedTheme = currentApplication?.settings?.theme;
  yield call(generateAndSetTheme, {
    userDefinedTheme,
    overrideMode: action.payload.mode,
  });
}

const COPY_THEME_NOTIFICATION_KEY = "copy-theme-notification";
const PASTE_THEME_NOTIFICATION_KEY = "paste-theme-notification";
const COPY_PASTE_ERROR_NOTIFICATION_DURATION = 10;
const COPY_PASTE_SUCCESS_NOTIFICATION_CONFIG: Partial<
  Parameters<typeof sendSuccessUINotification>[0]
> = {
  placement: NotificationPosition.bottomRight,
  duration: 2,
  style: {
    width: "210px",
  },
};

function* copyCurrentUserDefinedThemeSaga(
  action: ReturnType<typeof copyCurrentUserDefinedTheme>,
) {
  const currentApplication: ReturnType<typeof getCurrentApplication> =
    yield select(getCurrentApplication);
  const userDefinedTheme = currentApplication?.settings?.theme;

  if (!userDefinedTheme) {
    sendWarningUINotification({
      message: "There's no available theme to copy",
      duration: COPY_PASTE_ERROR_NOTIFICATION_DURATION,
    });
  }

  try {
    const generatedThemeJSON = JSON.stringify(userDefinedTheme);
    saveCopiedUserDefinedTheme(generatedThemeJSON);

    sendSuccessUINotification({
      ...COPY_PASTE_SUCCESS_NOTIFICATION_CONFIG,
      key: COPY_THEME_NOTIFICATION_KEY,
      message: "Theme copied",
    });
  } catch (error) {
    sendErrorUINotification({
      message: "The current theme cannot be copied",
      duration: COPY_PASTE_ERROR_NOTIFICATION_DURATION,
    });
    logger.error(`Failed to copy a theme: ${error}`);
  }
}

function* pasteCopiedUserDefinedThemeSaga(
  action: ReturnType<typeof pasteCopiedUserDefinedTheme>,
) {
  const currentApplication: ReturnType<typeof getCurrentApplication> =
    yield select(getCurrentApplication);

  const branch: ReturnType<typeof getCurrentBranch> =
    yield select(getCurrentBranch);

  if (!currentApplication || !currentApplication.settings) {
    throw new Error(
      "pasteCopiedUserDefinedThemeSaga can only be called in the context of an Application with existing settings",
    );
  }

  let userDefinedTheme: Awaited<ReturnType<typeof getCopiedUserDefinedTheme>>;
  try {
    userDefinedTheme = yield call(getCopiedUserDefinedTheme);
  } catch (error) {
    sendErrorUINotification({
      message: "The theme you are trying to paste seems broken",
      duration: COPY_PASTE_ERROR_NOTIFICATION_DURATION,
    });
    logger.error(`Failed to paste a theme: ${error}`);
  }

  yield put(
    updateApplication(currentApplication.id, branch?.name, {
      settings: {
        ...currentApplication.settings,
        theme: userDefinedTheme,
      },
    }),
  );

  yield put(requestApplicationSave({ hasUpdatedSettings: true }));

  yield call(generateAndSetTheme, { userDefinedTheme });

  sendSuccessUINotification({
    ...COPY_PASTE_SUCCESS_NOTIFICATION_CONFIG,
    key: PASTE_THEME_NOTIFICATION_KEY,
    message: "Theme pasted",
  });
}

export default function* themeSagas() {
  yield takeLatest(
    ReduxActionTypes.SET_OVERRIDE_THEME_MODE,
    setOverrideThemeModeSaga,
  );

  yield takeLatest(
    copyCurrentUserDefinedTheme.type,
    copyCurrentUserDefinedThemeSaga,
  );

  yield takeLatest(
    pasteCopiedUserDefinedTheme.type,
    pasteCopiedUserDefinedThemeSaga,
  );
}
