import React, { memo, useEffect, useState, ComponentType } from "react";
import { useParams } from "react-router";
import PageLoadingBar from "legacy/pages/common/PageLoadingSpinner";
import { retryPromise } from "legacy/utils/Utils";

const EditorLoader: ComponentType<React.PropsWithChildren<unknown>> = (
  props,
) => {
  const [Component, setComponent] = useState<ComponentType>(
    () => PageLoadingBar,
  );

  useEffect(() => {
    const abortController = new AbortController();

    retryPromise(
      () => import(/* webpackChunkName: "editor" */ "./main"),
      abortController.signal,
    ).then((module) => {
      setComponent(() => module.default);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  const params = useParams<{ applicationId: string }>();
  if (!Component) {
    return <PageLoadingBar />;
  }

  return <Component {...props} key={params.applicationId} />;
};

export default memo(EditorLoader);
