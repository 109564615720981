import {
  isValidMultiStepDef,
  isValidStepDef,
  TriggerStepType,
  ValidStepDef,
} from "@superblocksteam/shared";

// inspired by `rename.ts` in shared
export function createTriggerReferenceExtractor({
  addReference,
  getEvaluationReferences,
}: {
  addReference: (
    reference: string,
    scope: undefined | string,
    propertyName: string,
  ) => void;
  getEvaluationReferences: (value: unknown, isJS: boolean) => Promise<string[]>;
}) {
  const extractTriggerReferences = async (
    step: ValidStepDef,
    key: string,
    index: number,
  ) => {
    if (!isValidStepDef(step)) return;
    switch (step.type) {
      case TriggerStepType.RUN_APIS: {
        if (step.apiNames) {
          step.apiNames.forEach((name) => {
            addReference(name, undefined, `${key}[${index}].apiNames`);
          });
        }
        if (step.onSuccess && isValidMultiStepDef(step.onSuccess)) {
          let i = 0;
          for (const nestedStep of step.onSuccess) {
            await extractTriggerReferences(
              nestedStep,
              `${key}[${index}].onSuccess`,
              i++,
            );
          }
        }
        if (step.onError && isValidMultiStepDef(step.onError)) {
          let i = 0;
          for (const nestedStep of step.onError) {
            await extractTriggerReferences(
              nestedStep,
              `${key}[${index}].onError`,
              i++,
            );
          }
        }
        break;
      }
      case TriggerStepType.CANCEL_APIS: {
        if (step.apiNames) {
          step.apiNames.forEach((name) => {
            addReference(name, undefined, `${key}[${index}].apiNames`);
          });
        }
        break;
      }
      case TriggerStepType.RUN_JS: {
        const bindings = await getEvaluationReferences(step.code, true);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].code`);
        });
        break;
      }
      case TriggerStepType.CONTROL_SLIDEOUT:
      /* falls through */
      case TriggerStepType.CONTROL_MODAL:
        addReference(step.name, undefined, `${key}[${index}].name`);
        break;
      case TriggerStepType.CONTROL_TIMER: {
        addReference(
          step.state?.name ?? step.name,
          step.state?.scope,
          `${key}[${index}].state.name`,
        );
        break;
      }
      case TriggerStepType.NAVIGATE_TO: {
        const bindings = await getEvaluationReferences(step.url, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].url`);
        });
        break;
      }
      case TriggerStepType.NAVIGATE_TO_APP: {
        const bindings = await getEvaluationReferences(step.queryParams, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].queryParams`);
        });
        break;
      }
      case TriggerStepType.NAVIGATE_TO_ROUTE: {
        const queryBindings = await getEvaluationReferences(
          step.queryParams,
          false,
        );
        const routeBindings = await getEvaluationReferences(
          step.routeParams,
          false,
        );
        queryBindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].queryParams`);
        });
        routeBindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].routeParams`);
        });
        break;
      }
      case TriggerStepType.SET_QUERY_PARAMS: {
        const bindings = await getEvaluationReferences(step.queryParams, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].queryParams`);
        });
        break;
      }
      case TriggerStepType.SET_STATE_VAR: {
        const bindings = await getEvaluationReferences(step.value, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].value`);
        });

        if (step.state?.name) {
          addReference(
            step.state.name,
            step.state.scope,
            `${key}[${index}].state.name`,
          );
        }
        break;
      }
      case TriggerStepType.RESET_STATE_VAR: {
        addReference(
          step.state?.name,
          step.state?.scope,
          `${key}[${index}].state.name`,
        );
        break;
      }
      case TriggerStepType.RESET_COMPONENT: {
        addReference(
          step.widget?.name,
          undefined,
          `${key}[${index}].widget.name`,
        );

        break;
      }
      case TriggerStepType.SET_COMPONENT_PROPERTY: {
        addReference(
          step.widget?.name,
          undefined,
          `${key}[${index}].widget.name`,
        );
        const bindings = await getEvaluationReferences(
          step.propertyValue,
          false,
        );
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].propertyValue`);
        });
        break;
      }
      case TriggerStepType.SHOW_ALERT: {
        const bindings = await getEvaluationReferences(step.message, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].message`);
        });
        break;
      }
      case TriggerStepType.SET_PROFILE: {
        const bindings = await getEvaluationReferences(step.profileId, false);
        bindings.forEach((binding) => {
          addReference(binding, undefined, `${key}[${index}].profileId`);
        });
        break;
      }
      case TriggerStepType.TRIGGER_EVENT: {
        if (step.eventPayload) {
          const bindings = await getEvaluationReferences(
            step.eventPayload,
            false,
          );
          bindings.forEach((binding) => {
            addReference(binding, undefined, `${key}[${index}].eventPayload`);
          });
        }
        // check for old name
        if (step.event?.name) {
          addReference(
            step.event.name,
            undefined,
            `${key}[${index}].event.name`,
          );
        }
        break;
      }
      case TriggerStepType.RUN_SUPERBLOCKS_OPTIMIZED_APIS: {
        break;
      }
      default: {
        const exhaustiveCheck: never = step;
        throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
      }
    }
  };
  return extractTriggerReferences;
}
