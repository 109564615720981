import { ReferenceEdgeType, UsageEdgeType } from "analysis/types";
import { isStateVar } from "legacy/workers/evaluationUtils";
import { UsageContext } from "./UsageContext";
import { EdgeInfo } from "./types";

/**
 *  State Vars are considered to be used if and only if they are referenced by any used entity in a binding or event
 *
 *  TODO - we should attempt to differentiate between reading and writing the value of a state var in an event. e.g.
 *  if a state var is set in a runJS like Variable1.set... then this might not indicate the variable is used. We should
 *  only consider it to be used if it is read (e.g. Variable1.value)
 */
export const calculateStateVarUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;
  const stateVarEntity = referenceGraph.node(nodeId);

  if (!stateVarEntity || !isStateVar(stateVarEntity)) {
    throw new Error(`Expected entity to be stateVar`);
  }

  const usageEdges: EdgeInfo[] = [];

  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);
  for (const predecessorId of usedPredecessors) {
    const hasBindingEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.BINDING,
    );
    if (hasBindingEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }

    const hasEventEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.EVENT,
    );
    if (hasEventEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }
  }

  return usageEdges;
};
