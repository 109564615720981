import { Tooltip, TooltipProps } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { styleAsClass } from "styles/styleAsClass";

const TextStyle = styleAsClass`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`;

const TruncatedTextTooltip = ({
  text,
  className,
  placement,
  onClick,
  dataTest,
}: {
  text: string;
  className?: string;
  placement?: TooltipProps["placement"];
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  dataTest?: string;
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = debounce(() => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    }, 100);

    checkOverflow();

    const resizeObserver = new ResizeObserver(checkOverflow);
    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      checkOverflow.cancel();
    };
  }, [text]);

  return (
    <div style={{ width: "100%" }}>
      <Tooltip title={isOverflowing ? text : ""} placement={placement}>
        <div
          ref={textRef}
          className={`${TextStyle} ${className}`}
          onClick={onClick}
          data-test={dataTest}
        >
          {text}
        </div>
      </Tooltip>
    </div>
  );
};

export default TruncatedTextTooltip;
