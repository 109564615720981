import { ApplicationScope, BranchDto } from "@superblocksteam/shared";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSaga } from "hooks/store";
import { deleteV2ApiSaga } from "store/slices/apisV2";
import { ItemKinds } from "../../PropertyPane/ItemKindConstants";
import { ItemKindAccessors } from "../../PropertyPane/ItemKinds";

export const useDeleteEntity = (props: {
  entityKind?: ItemKinds;
  onDelete?: () => void;
  entityId: string;
  branch?: BranchDto;
  deleteAllSelected?: boolean;
  scope?: ApplicationScope;
}) => {
  const { entityKind, onDelete, entityId, branch, deleteAllSelected, scope } =
    props;

  const [deleteV2Api] = useSaga(deleteV2ApiSaga);
  const dispatch = useDispatch();
  // TEMP: remove this in favor of ItemKindAccessors[ItemKinds.API].deleteItem when it's implemented
  const handleDeleteApi = useCallback(async () => {
    await deleteV2Api({ id: entityId, branch: branch?.name });
  }, [deleteV2Api, entityId, branch]);

  const deleteEntity = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      if (entityKind === ItemKinds.API_V1 || entityKind === ItemKinds.API_V2) {
        handleDeleteApi();
        onDelete?.();
      } else if (entityKind) {
        ItemKindAccessors[entityKind].deleteItem(
          dispatch,
          entityId,
          scope ?? ApplicationScope.PAGE,
          deleteAllSelected,
        );
        onDelete?.();
      }
    },
    [
      entityKind,
      handleDeleteApi,
      onDelete,
      entityId,
      dispatch,
      scope,
      deleteAllSelected,
    ],
  );

  return deleteEntity;
};
