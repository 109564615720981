import {
  EntityPermissionsDto,
  GetGroupResponseDto,
  GetGroupsResponseDto,
  PostGroupRequestDto,
  PostGroupResponseDto,
  PostGroupsRequestDto,
  PostGroupsResponseDto,
} from "@superblocksteam/shared";
import {
  PERMISSION_ENTITIES_FOR_GROUP_URL_V2,
  PERMISSION_INTEGRATIONS_FOR_GROUP_URL,
} from "pages/routes";
import { callServer, HttpMethod } from "../../store/utils/client";

export function getGroups(organizationId: string) {
  return callServer<GetGroupsResponseDto>({
    method: HttpMethod.Get,
    url: `v1/organizations/${organizationId}/groups`,
  });
}

export function postGroups(
  organizationId: string,
  groupName: string,
  initialMemberEmails: string[],
) {
  const body: PostGroupsRequestDto = { name: groupName, initialMemberEmails };
  return callServer<PostGroupsResponseDto>({
    method: HttpMethod.Post,
    url: `v1/organizations/${organizationId}/groups`,
    body,
  });
}

export function deleteGroup(organizationId: string, groupId: string) {
  return callServer<PostGroupsResponseDto>({
    method: HttpMethod.Delete,
    url: `v1/organizations/${organizationId}/groups/${groupId}`,
  });
}

export function getGroup(organizationId: string, groupId: string) {
  return callServer<GetGroupResponseDto>({
    method: HttpMethod.Get,
    url: `v1/organizations/${organizationId}/groups/${groupId}`,
  });
}

export function postGroup(
  organizationId: string,
  groupId: string,
  body: PostGroupRequestDto,
) {
  return callServer<PostGroupResponseDto>({
    method: HttpMethod.Post,
    url: `v1/organizations/${organizationId}/groups/${groupId}`,
    body,
  });
}

export function getEntitiesForGroupIdV2(
  organizationId: string,
  groupId: string,
) {
  return callServer<EntityPermissionsDto[]>({
    method: HttpMethod.Get,
    url: PERMISSION_ENTITIES_FOR_GROUP_URL_V2(organizationId, groupId),
  });
}

export function getIntegrationsPermissionsForGroupId(
  organizationId: string,
  groupId: string,
) {
  return callServer<EntityPermissionsDto[]>({
    method: HttpMethod.Get,
    url: PERMISSION_INTEGRATIONS_FOR_GROUP_URL(organizationId, groupId),
  });
}
