import {
  CanvasLayout,
  GridDefaults,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import {
  ILayoutSystem,
  ConstraintLayoutSystem,
  OverlapLayoutSystem,
} from "./singleReflow";

export class LayoutSystemManager {
  // Each canvas will have a LayoutSystem instance
  // This is because each canvas can have different widgets
  // and different dynamic heights
  private layoutSystems: Record<string, ILayoutSystem> = {};

  get = (
    containerId: string,
    type: WidgetType,
    subType: CanvasLayout = CanvasLayout.FIXED,
  ) => {
    if (this.layoutSystems[containerId]) {
      return this.layoutSystems[containerId];
    }

    const useConstraints =
      type === WidgetTypes.CANVAS_WIDGET && subType === CanvasLayout.FIXED;

    // Only fixed-grid canvas widgets have a LayoutSystem that uses constraints
    // All other widgets use the OverlapLayoutSystem
    // TODO: We should also have systems for VStack and HStack
    const newSystem = useConstraints
      ? new ConstraintLayoutSystem(GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
      : new OverlapLayoutSystem();

    this.layoutSystems[containerId] = newSystem;
    return newSystem;
  };

  delete = (containerId: string) => {
    delete this.layoutSystems[containerId];
  };
}
