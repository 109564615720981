import { PageDSL8, WidgetProps8 } from "@superblocksteam/shared";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 2. Never update a migration. Instead, create a new one.
 ***/

const populateCanvasWidgetsVisibilityDefaults = (page: PageDSL8) => {
  const updateCanvasWidges = (children: WidgetProps8[]) => {
    children.forEach((widget) => {
      // update the visibility for all canvas widgets
      if (widget.type === "CANVAS_WIDGET") {
        if (widget.isVisible === undefined) {
          widget.isVisible = true;
        }
        if (widget.collapseWhenHidden === undefined) {
          widget.collapseWhenHidden = false;
        }
      }

      if (widget.children) {
        updateCanvasWidges(widget.children);
      }
    });
  };

  updateCanvasWidges(page.children ?? []);
  return page;
};

export default populateCanvasWidgetsVisibilityDefaults;
