import localStorage from "legacy/utils/localStorage";

const APP_STORE_NAMESPACE = "SUPERBLOCKS_LOCAL_STORE";

export const getAppStoreName = (appId: string) =>
  `${APP_STORE_NAMESPACE}-${appId}`;

export const getAppStore = (appId: string) => {
  const appStoreName = getAppStoreName(appId);
  let storeString = "{}";
  // Check if localStorage exists
  if (localStorage.isSupported()) {
    const appStore = localStorage.getItem(appStoreName);
    if (appStore) storeString = appStore;
  }
  let store;
  try {
    store = JSON.parse(storeString);
  } catch (e) {
    store = {};
  }
  return store;
};

export const EXPECTED_PAGE_DSL_VERSION = 8;
