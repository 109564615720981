import { ViewDatasourceDto } from "@superblocksteam/shared";
import { PLUGIN_INTEGRATIONS_IDS } from "utils/integrations";
import * as BackendTypes from "../backend-types";
import { ControlFlowFrontendDSL } from "../control-flow/types";
import { convertBlockToBackend } from "./convert-block-to-backend";
import {
  convertBlockToFrontend,
  ControlFlowPluginInfo,
} from "./convert-block-to-frontend";
export type { ControlFlowPluginInfo } from "./convert-block-to-frontend";

type ID = BackendTypes.Block["name"];

export const computeControlFlowPluginInfo = (
  datasources: Array<ViewDatasourceDto>,
) => {
  const pluginNames = PLUGIN_INTEGRATIONS_IDS;
  const datasourceLookup = datasources.reduce(
    (accum: Record<string, string>, datasource) => {
      if (datasource.pluginId) {
        accum[datasource.pluginId] = datasource.id;
      }
      return accum;
    },
    {},
  );

  return {
    pluginNames,
    datasourceLookup,
  };
};

export const convertDSLToFrontend = (
  backendApi: BackendTypes.Api,
  pluginInfo: ControlFlowPluginInfo,
): ControlFlowFrontendDSL => {
  const dsl: ControlFlowFrontendDSL = {
    rootBlocks: [],
    blocks: {},
  };

  const processBlocks = (blocks: BackendTypes.Block[], parentId?: ID): ID[] => {
    const processBlock = (backendBlock: BackendTypes.Block): ID => {
      const [convertedBlock, children] = convertBlockToFrontend(
        backendBlock,
        backendApi.metadata,
        pluginInfo,
      );
      if (convertedBlock.name in dsl.blocks) {
        throw new Error(`Duplicate block name found: ${convertedBlock.name}`);
      }
      dsl.blocks[convertedBlock.name] = {
        ...convertedBlock,
        parentId: parentId,
      };
      processBlocks(children ?? [], convertedBlock.name);
      return convertedBlock.name;
    };

    return blocks.map(processBlock);
  };
  dsl.rootBlocks = processBlocks(backendApi.blocks ?? []);

  return dsl;
};

// not sure how we're going to encode other api info just yet, so we are
// only returning the blocks portion for now
export const convertDSLToBackendBlocks = (
  dsl: ControlFlowFrontendDSL,
): BackendTypes.Api["blocks"] => {
  const processBlock = (blockName: string): BackendTypes.Block => {
    const block = dsl.blocks[blockName];
    if (block == null) {
      throw new Error("block not found");
    }
    return convertBlockToBackend(block, (name: string) => processBlock(name));
  };

  const blocks = dsl.rootBlocks.map((blockName) => {
    return processBlock(blockName);
  });

  return blocks;
};
