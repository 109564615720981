import { type Store } from "redux";
import { SagaMiddleware } from "redux-saga";
import { PayloadAction } from "./utils/action";

let store: Store<any, PayloadAction<any>> | null = null;
let sagaMiddleware: SagaMiddleware | null = null;

export function getStore() {
  if (!store) throw new Error("Circular dependency in store");
  return store;
}

export function setStore(s: Store<any, PayloadAction<any>>) {
  store = s;
}

export function getSagaMiddleware() {
  if (!sagaMiddleware) throw new Error("Circular dependency in sagaMiddleware");
  return sagaMiddleware;
}

export function setSagaMiddleware(s: SagaMiddleware) {
  sagaMiddleware = s;
}
