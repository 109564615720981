import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

export interface OpenPricingModalAction {
  autoLoaded: boolean; // if the modal is opened automatically
}
export const openPricingModal = (autoLoaded?: boolean) => {
  return {
    type: ReduxActionTypes.OPEN_PRICING_MODAL,
    payload: {
      autoLoaded: Boolean(autoLoaded),
    },
  };
};

export const closePricingModal = () => {
  return { type: ReduxActionTypes.CLOSE_PRICING_MODAL };
};

export const openQualificationModal = () => {
  return { type: ReduxActionTypes.OPEN_QUALIFICATION_MODAL };
};

export const openTrialExtensionModal = () => {
  return { type: ReduxActionTypes.OPEN_TRIAL_EXTENSION_MODAL };
};

export const closeQualificationModal = () => {
  return { type: ReduxActionTypes.CLOSE_QUALIFICATION_MODAL };
};

export const openPurchasedModal = (planName: string) => {
  return {
    type: ReduxActionTypes.OPEN_PURCHASED_MODAL,
    payload: { planName: planName },
  };
};

export const closePurchasedModal = () => {
  return { type: ReduxActionTypes.CLOSE_PURCHASED_MODAL };
};

export const openDowngradeModal = () => {
  return {
    type: ReduxActionTypes.OPEN_DOWNGRADE_MODAL,
  };
};

export const closeDowngradeModal = () => {
  return { type: ReduxActionTypes.CLOSE_DOWNGRADE_MODAL };
};
