import React, { useCallback, useRef, useState } from "react";
import { RecommendedMultiDropdown } from "components/ui/RecommendedMultiDropdown";
import { DropdownOption } from "components/ui/RecommendedMultiDropdown";

const toDropdownItem = (value_: string) => {
  const value = value_.trim();
  return {
    value,
    displayName: value,
    key: value,
  };
};

const AddItemsInput = ({
  items,
  setItems,
  placeholder,
  dataTest,
}: {
  items: string[];
  setItems: (items: string[]) => void;
  placeholder?: string;
  dataTest?: string;
}) => {
  const [itemsInternal, setItemsInternal] = useState<DropdownOption[]>([]);
  const [query, setQuery] = useState("");
  const handledByInputEvent = useRef(false);

  const handleInputValue = useCallback(
    (value: string) => {
      if (value) {
        if (value.includes(",")) {
          const newItems = value
            .split(",")
            .map(toDropdownItem)
            .filter((item) => item.value !== "");
          setItemsInternal([...itemsInternal, ...newItems]);
          setItems([...items, ...newItems.map((item) => item.value)]);
        } else {
          setItemsInternal([...itemsInternal, toDropdownItem(value)]);
          setItems([...items, value]);
        }
        setQuery("");
        handledByInputEvent.current = true;
      }
    },
    [items, itemsInternal, setItems],
  );

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value.trim();
      if (e.key === "Enter") {
        handleInputValue(value);
        handledByInputEvent.current = true;
      }
      if (e.key === "Backspace" && !value) {
        const newItems = itemsInternal.slice(0, -1);
        setItemsInternal(newItems);
        setItems(newItems.map((item) => item.value));
        handledByInputEvent.current = true;
      }
    },
    [handleInputValue, itemsInternal, setItems],
  );

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      handleInputValue(e.currentTarget.value.trim());
    },
    [handleInputValue],
  );

  const onChange = useCallback(
    (newItems: DropdownOption[]) => {
      if (handledByInputEvent.current) {
        handledByInputEvent.current = false;
        return;
      }
      setItemsInternal(newItems);
      setItems(newItems.map((item) => item.value));
    },
    [setItems],
  );

  const onQueryChange = useCallback(
    (queryFromInput: string) => {
      if (queryFromInput.includes(",")) {
        handleInputValue(queryFromInput);
        return;
      }
      setQuery(queryFromInput);
    },
    [handleInputValue],
  );

  return (
    <RecommendedMultiDropdown
      dataTest={dataTest}
      options={itemsInternal}
      selectedItems={itemsInternal}
      popoverProps={{ isOpen: false }}
      onInputKeyDown={onInputKeyDown}
      onInputBlur={onInputBlur}
      onChange={onChange}
      queryInput={query}
      onQueryChange={onQueryChange}
      rightElement={<></>}
      placeholder={placeholder ?? ""}
    />
  );
};

export default AddItemsInput;
