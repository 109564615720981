import { PageDSL8 } from "@superblocksteam/shared";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { Flag, Flags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import logger from "utils/logger";
import migrate0to1 from "./migrations/v0to1";
import migrate1to2 from "./migrations/v1to2";
import migrate2to3 from "./migrations/v2to3";
import migrate3to4 from "./migrations/v3to4";
import migrate4to5 from "./migrations/v4to5";
import migrate5to6 from "./migrations/v5to6";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 1. This is a temporary file and should be removed once signing is done. @gpoulios-sb will be removing this!
 * 2. Never update old migrations. If you need to update a migration, create a new one.
 ***/

// The first version was undefined
const CURRENT_VERSION = 5;
const CUSTOM_PAGE_LOAD_VERSION = 6;

const Migrations: Record<
  number,
  (serverDSL: PageDSL8, extraData: AuxiliaryData) => PageDSL8
> = {
  1: migrate0to1,
  2: migrate1to2,
  3: migrate2to3,
  4: migrate3to4,
  5: migrate4to5,
  6: migrate5to6,
};

export type AuxiliaryData = {
  apis: Record<string, ApiDtoWithPb>;
};

type MigrationState = {
  serverDSL: PageDSL8;
  flags: Flags;
  currentVersion?: number;
  extraData: AuxiliaryData;
};

export function migratePage({
  serverDSL,
  flags,
  currentVersion = flags[Flag.ENABLE_CUSTOM_PAGE_LOAD_ACTIONS]
    ? CUSTOM_PAGE_LOAD_VERSION
    : CURRENT_VERSION,
  extraData,
}: MigrationState): PageDSL8 {
  const startVersion = serverDSL.layoutVersion ?? 0;

  // If the page is already at the current version, no need to migrate
  if (startVersion === currentVersion) {
    return serverDSL;
  }

  const startTime = Date.now();
  // Create a copy of the serverDSL to avoid modifying the original
  let migratedDSL = fastClone(serverDSL);
  for (let version = startVersion; version < currentVersion; version++) {
    const nextVersion = version + 1;
    try {
      if (Migrations[nextVersion]) {
        migratedDSL = Migrations[nextVersion](migratedDSL, extraData);
        migratedDSL.layoutVersion = nextVersion;
      }
    } catch (error) {
      logger.error({
        message: `Error migrating page, tried migrating from ${version} to ${nextVersion}`,
        error,
        serverDSL,
        migratedDSL,
      });

      // We can still return the serverDSL as it was before migration
      // because we are not changing the structure of the page
      return serverDSL;
    }
  }

  const time = Date.now() - startTime;
  console.debug(
    `Layout Migration DSL from ${startVersion} to version ${currentVersion} took ${time}ms`,
  );
  return migratedDSL;
}
