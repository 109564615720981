import React, { useMemo } from "react";
import {
  AutocompleteConfiguration,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import CodeEditor from "components/app/CodeEditor/index";

const PromptEditor = ({
  onChange,
  placeholder,
  value,
  onSubmit,
}: {
  onChange: (val: unknown) => void;
  placeholder: string;
  value: string;
  onSubmit: (value: string) => void;
}) => {
  const input = useMemo(() => ({ value, onChange }), [value, onChange]);
  const autocompleteConfiguration = useMemo<AutocompleteConfiguration>(
    () => ({
      env: true,
    }),
    [],
  );

  return (
    <div className="drag-disabled">
      <CodeEditor
        data-test={"ai-assistant-prompt-editor"}
        showLineNumbers={false}
        mode={EditorModes.TEXT}
        key={EditorModes.TEXT}
        tabBehaviour={TabBehaviour.INDENT}
        onSubmit={onSubmit}
        disabled={false}
        theme={EditorTheme.LIGHT}
        size={EditorSize.EXTENDED}
        monospace={true}
        minHeight="32px"
        maxHeight="200px"
        placeholder={placeholder}
        showShortcutMenu={false}
        input={input}
        autocompleteConfiguration={autocompleteConfiguration}
        autoFocus={true}
        disableBindings={true}
      />
    </div>
  );
};

export default PromptEditor;
