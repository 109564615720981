import {
  Col,
  Divider,
  List,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Button } from "components/ui/Button";
import SbText from "components/ui/SbText";
import {
  closePricingModal,
  openDowngradeModal,
  openQualificationModal,
  openTrialExtensionModal,
} from "legacy/actions/modalActions";
import { CHECKOUT_URL } from "legacy/constants/routes";
import { getModalState } from "legacy/selectors/modalSelectors";
import { FeatureCheckMark, StyledListItem } from "pages/Checkout";
import { PlanDetails, PlanTypes } from "pages/Checkout/PlanDefinition";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { UIEvent } from "utils/event";
import logger from "utils/logger";

const BottomButtonSpaced = styled(Button)`
  margin: 10px 0;
  margin-top: 20px;
  padding-left: 0;
  padding-right: 0;
`;

// Call to action button in each column
const CTAButton = styled(Button)<{ color: string }>`
  width: 100%;
  margin-top: auto;
  background: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: #ffffff;

  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }
`;

const MiniPrice = styled(SbText)`
  display: inline-block;
  width: 25px;
  text-align: right;
`;

const TooltipText = styled(SbText)`
  color: ${(props) => props.theme.colors.GREY_500};
  text-decoration-style: dotted;
  text-decoration-line: underline;
  text-decoration-color: ${(props) => props.theme.colors.GREY_200};
`;

const ModalContent = styled.div`
  padding: 10px;
`;

const PricingBanner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 24px;
  padding-bottom: 24px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  background: ${(props) => props.theme.colors.WHITE};
  width: 100%;
  min-height: 610px;
`;

const PriceLabel = styled.div``;

const PricingColumn = ({
  plan,
  planName,
  prevPlanName,
}: {
  plan: PlanDetails;
  planName: string;
  prevPlanName: string | undefined;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <PricingBanner>
      <Space direction="vertical">
        <SbText style={{ color: plan.color, fontSize: 12 }}>
          {plan.displayName}
        </SbText>
        <PriceLabel>
          <Row>
            <SbText style={{ marginRight: 10, fontSize: 40, fontWeight: 600 }}>
              {plan.creatorCostPerMonth === null
                ? "Custom"
                : `$${plan.creatorCostPerMonth}`}
            </SbText>
            {plan.creatorCostPerMonth !== null && (
              <Space size={20}>
                <SbText color="GREY_500" style={{ paddingTop: 6 }}>
                  <Space direction="vertical" size={0}>
                    <div>
                      per{" "}
                      <Tooltip
                        overlay={"Develop Apps, Workflows and Scheduled Jobs"}
                      >
                        <TooltipText>Creator</TooltipText>
                      </Tooltip>
                    </div>
                    <div>per month</div>
                  </Space>
                </SbText>
                <Space direction="vertical" size={0}>
                  <div>
                    <MiniPrice weight={500}>$15</MiniPrice>{" "}
                    <SbText color="GREY_500">per </SbText>
                    <Tooltip
                      overlay={"Unlimited access to consume Superblocks apps"}
                    >
                      <TooltipText>End User</TooltipText>
                    </Tooltip>
                  </div>
                </Space>
              </Space>
            )}
          </Row>
        </PriceLabel>
        <Divider style={{ margin: 10 }} />
        <div style={{ marginBottom: 14 }}>
          <SbText color={"GREY_700"} sizePx={13}>
            {plan.description}
          </SbText>
        </div>
        {prevPlanName !== undefined && (
          <p style={{ marginBottom: 5 }}>
            <b>Everything in {prevPlanName} plus</b>
          </p>
        )}
        <List split={false}>
          {plan.summaryBulletPoints.map((point, i) => (
            <StyledListItem key={i}>
              <FeatureCheckMark color={plan.color} />
              <div>{point}</div>
            </StyledListItem>
          ))}
        </List>
      </Space>
      {plan.creatorCostPerMonth !== null ? (
        <CTAButton
          type="primary"
          onClick={() => {
            dispatch(closePricingModal());
            navigate({
              pathname: CHECKOUT_URL,
            });
          }}
          color={plan.color}
        >
          Upgrade
        </CTAButton>
      ) : (
        <CTAButton
          type="primary"
          onClick={() => {
            dispatch(openQualificationModal());
          }}
          color={plan.color}
        >
          Talk to Sales
        </CTAButton>
      )}
    </PricingBanner>
  );
};

const PricingModal = () => {
  const modalState = useAppSelector(getModalState);
  const dispatch = useAppDispatch();

  const requestExtension = useCallback(() => {
    dispatch(openTrialExtensionModal());
  }, [dispatch]);
  const downgradeToFree = useCallback(() => {
    dispatch(openDowngradeModal());
  }, [dispatch]);
  const onCancel = useCallback(() => {
    dispatch(closePricingModal());
    if (modalState.autoLoaded) {
      logger.event(UIEvent.DISMISSED_PRICING_MODAL_PROMPTED);
    }
  }, [dispatch, modalState.autoLoaded]);

  useEffect(() => {
    if (modalState.showPricingModal) {
      logger.event(UIEvent.VIEWED_PRICING_MODAL);
    }
  }, [modalState.showPricingModal]);

  return (
    <>
      <Modal
        title={
          <Space size={6}>
            <Typography.Text>Upgrade plan</Typography.Text>
          </Space>
        }
        centered
        open={modalState.showPricingModal}
        onCancel={onCancel}
        cancelText="Thanks"
        footer={null}
        destroyOnClose
        width={780}
        bodyStyle={{
          padding: 10,
        }}
        data-test="pricing-modal"
      >
        <ModalContent>
          <Row gutter={16}>
            {Object.values(PlanTypes).map((planType, i) => (
              <Col span={12} key={i}>
                <PricingColumn
                  key={i}
                  plan={planType}
                  planName={Object.keys(PlanTypes)[i]}
                  prevPlanName={
                    i > 0
                      ? Object.values(PlanTypes)[i - 1].displayName
                      : undefined
                  }
                />
              </Col>
            ))}
          </Row>

          <Row justify="space-between">
            <BottomButtonSpaced type="link" onClick={requestExtension}>
              Need more time? Request a trial extension
            </BottomButtonSpaced>
            <BottomButtonSpaced type="link" onClick={downgradeToFree}>
              Downgrade to free for fewer features
            </BottomButtonSpaced>
          </Row>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PricingModal;
