import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  copyStepOutput,
  markPageLoadApis,
  clearResponseApi,
  clearDirtyState,
} from "./actions";
import slice from "./slice";
import "./reducers";

slice.reducer(clearResponseApi, (state, { payload }) => {
  state.meta[payload.id] = {
    ...state.meta[payload.id],
    executionResult: undefined,
  };
});

slice.reducer(copyStepOutput, (state, { payload }) => {
  const executionResult = state.meta[payload.apiId].executionResult;
  if (
    executionResult?.context.outputs &&
    executionResult.context.outputs[payload.oldName]
  ) {
    executionResult.context.outputs[payload.newName] =
      executionResult.context.outputs[payload.oldName];
    delete executionResult.context.outputs[payload.oldName];
  }
});

slice.reducer(markPageLoadApis, (state, action) => {
  action.payload.forEach((id) => {
    state.loading[id] = true;
  });
});

slice.reducer(ReduxActionTypes.RESET_WIDGETS, (state) => {
  state.loading = {};
  state.entities = {};
  state.meta = {};
  state.errors = {};
});

slice.reducer(clearDirtyState, (state, { payload }) => {
  delete state.meta[payload.id].dirty;
});

export const markPageLoadApisSaga = markPageLoadApis;
export * from "./sagas/getApiToComponentDeps";

export * from "./selectors";
export * from "./types";
export * from "./actions";
export * from "./sagas/refactorName";
export * from "./sagas/executeApi";
export * from "./sagas/cloneApi";
export * from "./sagas/deleteApi";
export * from "./sagas/persistApi";
export * from "./sagas/updateApi";
export * from "./sagas/createApi";
export * from "./sagas/duplicateApi";
export * from "./sagas/fetchApi";
export * from "./sagas/getAllApis";
export * from "./sagas/saveFailedApis";
export * from "./sagas/markApiDoneEvaluating";

export default slice;
