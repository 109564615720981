import { XYCoord } from "react-dnd";
import {
  GridDefaults,
  WidgetType,
  MIN_WIDGET_SIZE,
} from "legacy/constants/WidgetConstants";
import { OccupiedSpace } from "legacy/constants/editorConstants";
import areIntersecting from "./areIntersecting";
import isWithin from "./isWithin";
import type { Rect } from "./ResizableUtils";

export const CantFit = undefined;

/**
 * "Get the available space for a widget in a drop zone, given the desired location, the occupied
 * spaces, and whether the widget can shrink to fit."
 *
 * @param {Rect} parent - The parent widget's rect
 * @param {Rect} desiredLocation - The location of the widget being dragged to
 * @param {string} widgetId - The id of the widget being dragged
 * @param {WidgetType} widgetType - The type of widget being dragged
 * @param {OccupiedSpace[]} [occupied] - An array of objects that represent the occupied space in the drop zone
 * @param {boolean} [cannotMoveToOriginalPosition] - true when the widget is being moved to a new location.
 * @param {boolean} [canShrink] - If true, the widget can be shrunk to fit within the parent.
 * @param {XYCoord} [mouseOffset] - The offset of the mouse from the top left corner of the widget.
 * @returns A Rect or CantFit
 */
const getAvailableRectInDropZone = (
  parent: Rect,
  desiredLocation: Rect,
  widgetId: string,
  widgetType: WidgetType,
  occupied?: OccupiedSpace[],
  cannotMoveToOriginalPosition?: boolean,
  canShrink?: boolean,
  mouseOffset?: XYCoord,
): typeof CantFit | Rect => {
  const offset = desiredLocation;
  const { minWidth, minHeight } = getMinSize(offset);

  if (canShrink) {
    // Shrink the widget to fit within the parent
    offset.left = Math.max(offset.left, parent.left);
    offset.right = Math.min(offset.right, parent.right);
    offset.top = Math.max(offset.top, parent.top);
    offset.bottom = Math.min(
      offset.bottom,
      parent.bottom || GridDefaults.DEFAULT_GRID_COLUMNS,
    );
  } else {
    // Check if the widget is within the parent
    if (!isWithin(offset, parent)) {
      return CantFit;
    }
  }

  if (
    offset.right - offset.left < minWidth ||
    offset.bottom - offset.top < minHeight
  ) {
    return CantFit;
  }

  if (occupied) {
    if (!cannotMoveToOriginalPosition) {
      occupied = occupied.filter((widgetDetails) => {
        return (
          widgetDetails.id !== widgetId && widgetDetails.parentId !== widgetId
        );
      });
    }
  }

  return _getAvailableRectInDropZone(
    widgetType,
    offset,
    occupied,
    canShrink,
    mouseOffset,
  );
};

const _getAvailableRectInDropZone = (
  widgetType: WidgetType,
  offset: Rect,
  occupied?: OccupiedSpace[],
  canShrink?: boolean,
  mouseOffset: XYCoord = {
    x: offset.left + offset.right / 2,
    y: offset.top + offset.bottom / 2,
  },
): typeof CantFit | Rect => {
  if (occupied) {
    const { minWidth, minHeight } = getMinSize(offset);
    const collisions: OccupiedSpace[] = [];
    for (let i = 0; i < occupied.length; i++) {
      if (areIntersecting(occupied[i], offset)) {
        // If the widget cannot shrink, we cannot fit it in the drop zone
        if (!canShrink) {
          return CantFit;
        }
        collisions.push(occupied[i]);
      }

      // If there are more than 5 collisions, it is not worth trying to fit the widget
      if (collisions.length > 5) {
        return CantFit;
      }
    }

    // If there are no collisions, we can fit the widget in the drop zone
    if (collisions.length === 0) {
      return offset;
    }

    const centerOffset = mouseOffset;

    // Recursively check if the widget can fit in the drop zone
    // Comparing the volume/centerPos to achieve the best fit
    const potentialOffsets = [];
    for (let i = 0; i < collisions.length; i++) {
      const collision = collisions[i];

      // Calculate the width that fits in the drop zone
      const offsetCenterX = centerOffset.x;
      const collisionCenterX = (collision.left + collision.right) / 2;
      const shouldGoLeft = offsetCenterX < collisionCenterX;

      const widthThatFits = shouldGoLeft
        ? collision.left - offset.left
        : offset.right - collision.right;
      const newOffsetHorizontal = {
        top: offset.top,
        left: shouldGoLeft ? offset.left : collision.right,
        right: shouldGoLeft ? collision.left : offset.right,
        bottom: offset.bottom,
      };

      // Calculate the height that fits in the drop zone
      const offsetCenterY = centerOffset.y;
      const collisionCenterY = (collision.top + collision.bottom) / 2;
      const shouldGoAbove = offsetCenterY < collisionCenterY;

      const heightThatFits = shouldGoAbove
        ? collision.top - offset.top
        : offset.bottom - collision.bottom;
      const newOffsetVertical = {
        top: shouldGoAbove ? offset.top : collision.bottom,
        left: offset.left,
        right: offset.right,
        bottom: shouldGoAbove ? collision.top : offset.bottom,
      };

      if (
        widthThatFits >= minWidth &&
        !areIntersecting(collision, newOffsetHorizontal)
      ) {
        potentialOffsets.push(
          _getAvailableRectInDropZone(
            widgetType,
            newOffsetHorizontal,
            collisions.filter((c) => c !== collision),
            canShrink,
          ),
        );
      }
      if (
        heightThatFits >= minHeight &&
        !areIntersecting(collision, newOffsetVertical)
      ) {
        potentialOffsets.push(
          _getAvailableRectInDropZone(
            widgetType,
            newOffsetVertical,
            collisions.filter((c) => c !== collision),
            canShrink,
          ),
        );
      }
    }

    // Sort the potential offsets by distance from the desired location
    // and a small percent of the volume of the widget to achieve the best fit
    potentialOffsets.sort((a, b) => {
      if (a === CantFit) return 1;
      if (b === CantFit) return -1;
      const aValue = positionScore(a, mouseOffset);
      const bValue = positionScore(b, mouseOffset);
      return -(aValue - bValue);
    });

    return potentialOffsets.length > 0 ? potentialOffsets[0] : CantFit;
  }
  return offset;
};

/**
 * Returns an available space near the desired location within the parent limits.
 * It won't return the desired exact spot given in params (use getAvailableRectInDropZone for that).
 * @param {Rect} parent - The parent widget's rect
 * @param {Rect} desiredLocation - The desired location to place the rect
 * @param {WidgetType} widgetType - Type of widget
 * @param {OccupiedSpace[]} occupied - An array of objects that represent the occupied space in the drop zone
 * @returns The value of the position of the rectangle
 */
export const getNearAvailableRectInDropZone = (
  parent: Rect,
  desiredLocation: Rect,
  widgetType: WidgetType,
  occupied: OccupiedSpace[] = [],
): typeof CantFit | Rect => {
  const centerOffset: XYCoord = {
    x: (desiredLocation.right - desiredLocation.left) / 2,
    y: (desiredLocation.top - desiredLocation.bottom) / 2,
  };

  const { left, top, right, bottom } = desiredLocation;
  const width = right - left;
  const height = bottom - top;
  const center: XYCoord = {
    x: left + centerOffset.x,
    y: top + centerOffset.y,
  };

  const maxRadius = Math.min(
    Math.max(parent.right - parent.left, parent.bottom - parent.top),
    GridDefaults.DEFAULT_GRID_COLUMNS / 3, // A third of the width screen
  );

  const minRadius = Math.min(width, height) / 2;

  for (let radius = minRadius; radius <= maxRadius; radius++) {
    for (let angle = 0; angle < 360; angle += 15) {
      const radians = (angle * Math.PI) / 180;
      const x = Math.round(center.x + radius * Math.cos(radians));
      const y = Math.round(center.y + radius * Math.sin(radians));

      const newRect: Rect = {
        top: y,
        left: x,
        right: x + width,
        bottom: y + height,
      };

      if (isWithin(newRect, parent)) {
        const validSpace = _getAvailableRectInDropZone(
          widgetType,
          newRect,
          occupied,
          false,
        );
        if (validSpace !== CantFit) {
          return validSpace;
        }
      }
    }
  }

  return CantFit;
};

const getMinSize = (currentSize: Rect) => {
  let { height: minHeight, width: minWidth } = MIN_WIDGET_SIZE;

  minWidth = Math.min(
    minWidth,
    Math.max(currentSize.right - currentSize.left, 1),
  );
  minHeight = Math.min(
    minHeight,
    Math.max(currentSize.bottom - currentSize.top, 1),
  );
  return { minWidth, minHeight };
};

/**
 * Returns a number that represents how good of a position/size the rectangle this is open to tweaking
 * @param {Rect} a - Rect - The rectangle to score
 * @param {XYCoord} mouseOffset - The position of the mouse relative to the top left of the viewport.
 * @returns The value of the position of the rectangle.
 */
function positionScore(a: Rect, mouseOffset: XYCoord) {
  const volume = (a.right - a.left) * (a.bottom - a.top);
  const center = {
    x: (a.left + a.right) / 2,
    y: (a.top + a.bottom) / 2,
  };
  const distanceToCenter = Math.sqrt(
    Math.pow(center.x - mouseOffset.x, 2) +
      Math.pow(center.y - mouseOffset.y, 2),
  );
  return Math.pow(volume, 0.5) - distanceToCenter;
}

export default getAvailableRectInDropZone;
