import { uniq } from "lodash";
import { put, select } from "redux-saga/effects";
import { selectAiComponentPropertiesToChange } from "../selectors";
import { processAiProperties, setPropertiesToChange } from "../slice";

export function* processAiPropertiesSaga(
  action: ReturnType<typeof processAiProperties>,
) {
  const { properties, widgetType } = action.payload;
  const previousProperties: string[] | undefined = yield select(
    selectAiComponentPropertiesToChange,
  );
  const processedProperties: string[] = [];
  properties.forEach((property: string) => {
    if (widgetType === "button" && property.includes("textColor")) {
      processedProperties.push("textColor");
    } else if (property.startsWith("eventListener")) {
      // get the second piece of the property
      processedProperties.push(property.split(".")[1]);
    } else if (property.startsWith("column.")) {
      processedProperties.push("primaryColumns");
      processedProperties.push(property.replace("column.", "primaryColumns."));
    } else {
      processedProperties.push(property);
    }
  });

  const allProperties = uniq([
    ...(previousProperties || []),
    ...processedProperties,
  ]);

  yield put(setPropertiesToChange(allProperties));
}
