import { call, select } from "@redux-saga/core/effects";
import { Agent } from "@superblocksteam/shared";
import { ApiResponse } from "legacy/api/ApiResponses";
import { createApiBranch, createAppBranch } from "legacy/api/GitBranches";
import {
  StdISocketRPCClient,
  connectToISocketRPCServer,
} from "legacy/api/ISocketRPC";
import { getOpaAgents } from "legacy/utils/getOpaAgents";
import { selectOnlyOrganization } from "store/slices/organizations/selectors";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { ErrorHandlingOptions } from "store/utils/client";
import { createSaga } from "store/utils/saga";
import { HttpError } from "store/utils/types";
import { EntityType } from "utils/entity";
import { getShouldSignAndVerify } from "utils/resource-signing";
import unreachable from "utils/unreachable";

type RPCMethodResponse = ApiResponse<{ name: string }>;

function extractResult(response: RPCMethodResponse): { name: string } {
  if (response.responseMeta.status !== 200) {
    throw new HttpError(
      response.responseMeta.status,
      false,
      response.responseMeta.error?.message ?? "Cannot create branch",
    );
  }
  return response.data;
}

interface CreateBranchPayload {
  entityId: string;
  entityType: EntityType;
  branchName: string;
  baseBranchName: string;
}

type CreateBranchResult =
  | { success: true; name: string }
  | { success: false; message: string; httpStatus?: number };

// just throw on error
const errorHandlingOptions: ErrorHandlingOptions = {
  notifyOnError: false,
  onError(err) {
    throw err;
  },
};

// export to allow testing
export function* createBranchInternal({
  entityId,
  entityType,
  branchName,
  baseBranchName,
}: CreateBranchPayload): Generator<any, CreateBranchResult, any> {
  try {
    let rpcClient: undefined | StdISocketRPCClient;
    let result: CreateBranchResult;

    try {
      const organization: ReturnType<typeof selectOnlyOrganization> =
        yield select(selectOnlyOrganization);
      if (orgIsOnPremise(organization)) {
        const agents: Agent[] = yield call(getOpaAgents);
        const shouldVerify: boolean = yield call(
          getShouldSignAndVerify,
          agents,
        );
        if (shouldVerify) {
          if (agents.length === 0) {
            throw new Error("Could not create branch, no OPA agents found");
          }
          rpcClient = yield call(
            connectToISocketRPCServer,
            agents,
            organization,
          );
        }
      }

      switch (entityType) {
        case EntityType.APPLICATION: {
          if (!rpcClient) {
            const res: Awaited<ReturnType<typeof createAppBranch>> = yield call(
              createAppBranch,
              {
                applicationId: entityId,
                name: branchName,
                baseBranchName,
                errorHandlingOptions,
              },
            );
            result = { success: true, name: res.name };
          } else {
            const response: Awaited<
              ReturnType<typeof rpcClient.call.v2.application.createBranch>
            > = yield call(rpcClient.call.v2.application.createBranch, {
              applicationId: entityId,
              branchName,
              baseBranchName,
            });
            const res = extractResult(response as unknown as RPCMethodResponse);
            result = { success: true, name: res.name };
          }
          break;
        }
        case EntityType.WORKFLOW:
        case EntityType.SCHEDULED_JOB: {
          if (!rpcClient) {
            const res: Awaited<ReturnType<typeof createApiBranch>> = yield call(
              createApiBranch,
              {
                apiId: entityId,
                name: branchName,
                baseBranchName,
                errorHandlingOptions,
              },
            );
            result = { success: true, name: res.name };
          } else {
            const response: Awaited<
              ReturnType<typeof rpcClient.call.v3.api.createBranch>
            > = yield call(rpcClient.call.v3.api.createBranch, {
              apiId: entityId,
              branchName,
              baseBranchName,
            });
            const res = extractResult(response as unknown as RPCMethodResponse);
            result = { success: true, name: res.name };
          }
          break;
        }
        default:
          unreachable(entityType);
      }
    } finally {
      rpcClient?.close();
    }
    return result;
  } catch (err) {
    if (err instanceof HttpError) {
      return { success: false, message: err.message, httpStatus: err.code };
    } else {
      return {
        success: false,
        message: err instanceof Error ? err.message : "Cannot create branch",
      };
    }
  }
}

export const createBranchSaga = createSaga(
  createBranchInternal,
  "createBranchSaga",
  { autoGenerateUniqueKey: true },
);
