import { Dimension, DimensionModes, Margin } from "@superblocksteam/shared";
import {
  CanvasDefaults,
  CanvasLayout,
  GridDefaults,
  MIN_MAX_BUFFER_PX,
} from "legacy/constants/WidgetConstants";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { isFixedHeight } from "legacy/widgets/base/sizing/dynamicLayoutUtils";
import { hackyIsWidgetCollapsed } from "./canvasSizingUtil";
import type {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
  WidgetLayoutProps,
} from "../../shared";

export function getCanvasMinHeightFlattened(
  props: Omit<FlattenedWidgetProps, "children"> | FlattenedWidgetLayoutProps,
  widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
) {
  const children = (widgets[props.widgetId]?.children ?? []).map(
    (id) => widgets[id],
  );
  return getCanvasMinHeight(props, children);
}

export function getCanvasMinHeightNested(widget: WidgetLayoutProps) {
  return getCanvasMinHeight(widget, widget.children ?? []);
}

export function getCanvasMinHeight(
  props:
    | Omit<FlattenedWidgetProps, "children">
    | WidgetLayoutProps
    | FlattenedWidgetLayoutProps,
  children: (
    | FlattenedWidgetProps
    | WidgetLayoutProps
    | FlattenedWidgetLayoutProps
  )[],
): Dimension<"px"> {
  const layout = props.layout ?? CanvasLayout.FIXED;

  switch (layout) {
    case CanvasLayout.FIXED:
      return Dimension.px(getMinHeightForFixedGrid(children));
    case CanvasLayout.VSTACK:
      return Dimension.px(getMinHeightForVStack(children, props));
    case CanvasLayout.HSTACK:
      return Dimension.px(getMinHeightForHStack(children, props));
  }
}

function getChildWidgetMinHeight(
  widget: FlattenedWidgetProps | WidgetLayoutProps | FlattenedWidgetLayoutProps,
) {
  const widgetHeightPx = Dimension.toPx(
    widget.height,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).value;
  const marginYPx = Margin.y(widget.margin).value ?? 0;

  if (hackyIsWidgetCollapsed(widget)) {
    return 0;
  }

  if (isFixedHeight(widget.height.mode)) {
    return widgetHeightPx + marginYPx;
  }

  const widgetMinHeightPx = widget.minHeight
    ? Dimension.toPx(widget.minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
        .value
    : undefined;

  if (widget.height.mode === "fillParent") {
    return (
      widgetMinHeightPx ??
      GridDefaults.FILL_PARENT_DEFAULT_MIN_HEIGHT *
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT
    );
  }
  const widgetMaxHeightPx = widget.maxHeight
    ? Dimension.toPx(widget.maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
        .value
    : undefined;

  // fit content
  let height = widgetHeightPx;
  if (widgetMinHeightPx !== undefined) {
    height = Math.max(widgetMinHeightPx, height);
  }
  if (widgetMaxHeightPx !== undefined) {
    height = Math.min(widgetMaxHeightPx, height);
  }
  return height + marginYPx;
}

export function getMinHeightForFixedGrid(
  children: (
    | FlattenedWidgetProps
    | WidgetLayoutProps
    | FlattenedWidgetLayoutProps
  )[],
): number {
  let bottomRowPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;
    const childBottomRow =
      Dimension.toPx(child.top, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value +
      getChildWidgetMinHeight(child);
    if (childBottomRow > bottomRowPx) {
      bottomRowPx = childBottomRow;
    }
  });
  return bottomRowPx;
}

export function getMinHeightForVStack(
  children: (
    | FlattenedWidgetProps
    | WidgetLayoutProps
    | FlattenedWidgetLayoutProps
  )[],
  props: {
    spacing?: Dimension<"px">;
  },
): number {
  let childCount = 0;
  let childHeightsPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;

    const height = getChildWidgetMinHeight(child);

    if (height === 0) {
      return;
    }

    childCount++;
    childHeightsPx += height;
  });

  // Currently only supporting px spacing
  const spacingPx = Dimension.toPx(
    props.spacing ?? CanvasDefaults.SPACING,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).value;
  const totalSpacing = childCount > 0 ? spacingPx * (childCount - 1) : 0;

  return childHeightsPx + totalSpacing;
}

function getMinHeightForHStack(
  children: (
    | FlattenedWidgetProps
    | WidgetLayoutProps
    | FlattenedWidgetLayoutProps
  )[],
  props: Omit<FlattenedWidgetProps | WidgetLayoutProps, "children">,
): number {
  // go through children and find the tallest one
  let tallestChildHeightPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;
    const childHeight = getChildWidgetMinHeight(child);
    if (childHeight > tallestChildHeightPx) {
      tallestChildHeightPx = childHeight;
    }
  });

  return tallestChildHeightPx;
}

type WithWidgetHeightProperties<T extends DimensionModes> = {
  height: Dimension<T>;
  minHeight?: Dimension<T>;
  maxHeight?: Dimension<T>;
};

// Widgets with a fixed (gridUnit) height mode ignore the min/max properties
// so any functions that clamp should pass through this first

export function getApplicableMaxHeight<T extends DimensionModes>(
  widget: WithWidgetHeightProperties<T>,
) {
  if (isFixedHeight(widget.height.mode)) {
    return undefined;
  }
  return widget.maxHeight;
}

export function getApplicableMinHeight<T extends DimensionModes>(
  widget: WithWidgetHeightProperties<T>,
) {
  if (!widget) {
    return undefined;
  }
  if (isFixedHeight(widget.height.mode)) {
    return undefined;
  }
  return widget.minHeight;
}

export const clampMinMax = (params: {
  constraintType: "minHeight" | "maxHeight" | "height";
  newHeight: Dimension<"px" | "gridUnit">;
  widget: CanvasWidgetsReduxState[string];
}) => {
  const { constraintType, widget } = params;

  // make sure to use grid unit value for this
  const newHeightGU = Dimension.toGridUnit(
    params.newHeight,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw();

  if (constraintType === "minHeight") {
    const newWidget = {
      ...widget,
    };

    if (
      widget.maxHeight &&
      newHeightGU.value >=
        Dimension.toGridUnit(
          widget.maxHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).raw().value
    ) {
      newWidget.maxHeight = Dimension.toPx(
        Dimension.gridUnit(
          newHeightGU.value +
            MIN_MAX_BUFFER_PX / GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      );
    }

    if (
      newHeightGU.value >=
      Dimension.toGridUnit(
        widget.height,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw().value
    ) {
      newWidget.height = Dimension.build(newHeightGU.value, widget.height.mode);
    }

    return newWidget;
  }

  if (constraintType === "maxHeight") {
    const newWidget = {
      ...widget,
    };

    if (
      widget.minHeight &&
      newHeightGU.value <=
        Dimension.toGridUnit(
          widget.minHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).raw().value
    ) {
      newWidget.minHeight = Dimension.toPx(
        Dimension.gridUnit(
          Math.max(
            newHeightGU.value -
              MIN_MAX_BUFFER_PX / GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
            1,
          ),
        ),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      );
    }

    if (
      newHeightGU.value <=
      Dimension.toGridUnit(
        widget.height,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).raw().value
    ) {
      newWidget.height = Dimension.build(newHeightGU.value, widget.height.mode);
    }

    return newWidget;
  }

  return widget;
};
