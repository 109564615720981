import { getDynamicBindings, isExactBinding } from "@superblocksteam/shared";
import {
  format as formatSql,
  FormatOptionsWithLanguage,
  SqlLanguage,
} from "sql-formatter";
import { SyntaxType } from "./constants";
import { formatOpts as formatJs } from "./js-formatter";
import { FormatCodeRequest } from "./worker/types";

const TOKEN_PREFIX = "SB_SQL_FORMATTING_TOKEN_";
const createUniqueToken = (
  code: string,
  index: number = 0,
): [string, number] => {
  const token = `${TOKEN_PREFIX}${index}`;
  const regex = new RegExp(token, "i");
  if (regex.test(code)) {
    return createUniqueToken(code, index + 1);
  }
  return [token, index + 1];
};

const BASE_CONFIG: FormatOptionsWithLanguage = {};

/**
 * Formats SQL with javascript bindings
 * @param {string} inputCode - code to be formatted
 * @returns {string} The formatted code if it's possible to format or the inputCode if not
 */

export function format(
  inputCode: string,
  options?: FormatCodeRequest["options"],
): string {
  if (!options?.syntax) {
    throw new Error("options.syntaxType is required to format SQL");
  }

  const sqlDialect = getSqlDialectForSyntax(options.syntax);
  if (!sqlDialect) {
    return inputCode;
  }
  const config: FormatOptionsWithLanguage = {
    ...BASE_CONFIG,
    language: sqlDialect,
  };

  const bindings = getDynamicBindings(inputCode);
  if (bindings.jsSnippets.length === 0) {
    return formatSql(inputCode, config);
  }

  let potentialSyntaxIssue = false;

  const stringSegments = [...bindings.stringSegments];

  const bindingsMap = new Map<string, string>();
  let tokenIndex = 0;
  for (let i = 0; i < bindings.stringSegments.length; i++) {
    const segment = bindings.stringSegments[i];

    if (!isExactBinding(segment)) {
      if (segment.startsWith("{{") || segment.endsWith("}}")) {
        // Avoid formatting at all when there are potential binding issues
        potentialSyntaxIssue = true;
        break;
      }

      continue;
    }

    const [token, newIndex] = createUniqueToken(inputCode, tokenIndex);
    tokenIndex = newIndex;

    stringSegments[i] = token;

    const jsFormat = formatJs(segment.substring(2, segment.length - 2), {
      semi: false,
    });

    // Remove potential starting semicolon automatically introduced because of `semi: false`.
    // Read https://prettier.io/docs/en/rationale.html#semicolons
    let start = 0;
    if (jsFormat[0] === ";") start++;

    const isMultilineJs = /\n./.test(jsFormat);

    let formatted: string;

    if (isMultilineJs) {
      const indentedJs = jsFormat
        .substring(start)
        .split(/\n/)
        .slice(0, -1) // Remove trailing newline "\n"
        .map((line) => `    ${line}\n`)
        .join("");

      formatted = "{{\n" + indentedJs + "  }}";
    } else {
      // Remove trailing newline and semicolon ";\n"
      let cutoff = 0;
      if (jsFormat[jsFormat.length - 1] === "\n") cutoff++;
      if (jsFormat[jsFormat.length - 2] === ";") cutoff++;

      formatted =
        "{{" + jsFormat.substring(start, jsFormat.length - cutoff) + "}}";
    }

    bindingsMap.set(token, formatted);
  }

  if (potentialSyntaxIssue) {
    return inputCode;
  }

  const tempCode = stringSegments.join("");
  let formattedCode = formatSql(tempCode, config);
  bindingsMap.forEach((binding, token) => {
    formattedCode = formattedCode.replace(token, binding);
  });

  return formattedCode;
}

export const getSqlDialectForSyntax = (
  syntax: SyntaxType,
): SqlLanguage | undefined => {
  switch (syntax) {
    case SyntaxType.POSTGRESQL:
      return "postgresql";
    case SyntaxType.MYSQL:
      return "mysql";
    case SyntaxType.MSSQL:
      return "transactsql";
    case SyntaxType.MARIADB:
      return "mariadb";
    case SyntaxType.COCKROACHDB:
      return "postgresql"; // Cockroach DB uses postgresql
    case SyntaxType.REDSHIFT:
      return "redshift";
    case SyntaxType.BIGQUERY:
      return "bigquery";
    case SyntaxType.ORACLEDB:
      return "plsql";
    case SyntaxType.SNOWFLAKE: // sql-formatter can handle the snowflake syntax, but not all of it.
    case SyntaxType.DYNAMODB:
    case SyntaxType.ROCKSET:
    default:
      return undefined;
  }
};
