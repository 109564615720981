import { Dimension, Padding } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { WidgetAddChild } from "legacy/actions/pageActions";
import {
  CanvasAlignment,
  CanvasLayout,
  GridDefaults,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  getFlattenedCanvasWidgets,
  getWidgetParentIdsHelper,
} from "legacy/selectors/editorSelectors";
import { getSelectedWidgetsIds } from "legacy/selectors/sagaSelectors";
import { GeneratedTheme } from "legacy/themes";
import { generateReactKey } from "legacy/utils/generators";
import { isStackLayout } from "legacy/widgets/StackLayout/utils";
import {
  getWidgetDefaultPadding,
  getWidgetWidth,
} from "legacy/widgets/base/sizing";
import { getCanvasMinHeight } from "legacy/widgets/base/sizing/canvasHeightUtil";
import {
  FlattenedWidgetLayoutMap,
  FlattenedWidgetLayoutProps,
  StaticWidgetProps,
} from "legacy/widgets/shared";

export const getCanSelectedWidgetsBeGrouped = createSelector(
  getFlattenedCanvasWidgets,
  getSelectedWidgetsIds,
  (flattenedWidgets, selectedWidgetIds) => {
    const validateWidgetGroupingResult = validateWidgetGrouping({
      selectedWidgetIds,
      flattenedWidgets,
    });
    return validateWidgetGroupingResult.canGroup;
  },
);

export const validateWidgetGrouping = (params: {
  selectedWidgetIds: string[];
  flattenedWidgets: FlattenedWidgetLayoutMap;
}): { canGroup: boolean; reason?: string; parentWidgetId?: string } => {
  const { flattenedWidgets, selectedWidgetIds } = params;

  if (selectedWidgetIds.length === 0) {
    return {
      canGroup: false,
      reason: "No widgets selected",
    };
  }

  const parentWidgetId = flattenedWidgets[selectedWidgetIds[0]]?.parentId;

  if (
    selectedWidgetIds.some(
      (id) => flattenedWidgets[id]?.parentId !== parentWidgetId,
    )
  ) {
    return {
      canGroup: false,
      reason: "Cannot group widgets with different parents",
    };
  }

  const parentWidget = flattenedWidgets[parentWidgetId];
  // make sure parentWidget is a canvas
  if (parentWidget?.type !== WidgetTypes.CANVAS_WIDGET) {
    return {
      canGroup: false,
      reason: "Selected widgets cannot be grouped",
    };
  }

  const widgetParents = getWidgetParentIdsHelper(
    flattenedWidgets,
    parentWidgetId,
  );
  const ancestorIsGrid = widgetParents?.some(
    (id: string) => flattenedWidgets[id]?.type === WidgetTypes.GRID_WIDGET,
  );

  if (ancestorIsGrid) {
    return {
      canGroup: false,
      reason: "Cannot group widgets inside a grid",
    };
  }

  return {
    canGroup: true,
    parentWidgetId,
  };
};

const widthModesToConsiderPadding = new Set(["px", "fitContent"]);

const makeFakeContainerCanvas = (): Omit<FlattenedWidgetProps, "children"> => ({
  widgetId: "FAKE_CONTAINER_CANVAS",
  widgetName: "fakeWidget",
  type: WidgetTypes.CANVAS_WIDGET,
  layout: CanvasLayout.VSTACK,
  alignment: CanvasAlignment.LEFT,

  top: Dimension.gridUnit(0),
  left: Dimension.gridUnit(0),
  width: Dimension.gridUnit(0),
  height: Dimension.gridUnit(1),
  parentId: "FAKE_PARENT",
  isLoading: false,
  appMode: APP_MODE.EDIT,
});

export const getAddChildPayload = (params: {
  selectedWidgetIds: string[];
  flattenedWidgets: FlattenedWidgetLayoutMap;
  parentWidgetId: string;
  theme: GeneratedTheme;
}): null | WidgetAddChild => {
  const { selectedWidgetIds, flattenedWidgets, parentWidgetId, theme } = params;

  const parentWidget = flattenedWidgets[parentWidgetId];
  const firstWidget = flattenedWidgets[selectedWidgetIds[0]];

  if (!firstWidget) {
    return null;
  }

  const parentPadding = getWidgetDefaultPadding(theme, parentWidget);

  const [widestWidget, maxWidthPx] = selectedWidgetIds.reduce<
    [FlattenedWidgetLayoutProps, number]
  >(
    (acc, widgetId) => {
      const widget = flattenedWidgets[widgetId];

      const prospectiveWidth =
        getWidgetWidth(widget) +
        (widthModesToConsiderPadding.has(widget.width.mode)
          ? Padding.x(parentPadding).value
          : 0);

      return prospectiveWidth > acc[1] ? [widget, prospectiveWidth] : acc;
    },
    [firstWidget, getWidgetWidth(firstWidget)],
  );
  const parentColumnSpace = firstWidget?.parentColumnSpace;
  const maxWidth = parentColumnSpace
    ? Dimension.gridUnit(Math.ceil(maxWidthPx / parentColumnSpace))
    : widestWidget.width;

  let size: WidgetAddChild["size"] = {
    height: Dimension.gridUnit(1),
    width: maxWidth,
  };
  let position: WidgetAddChild["position"] = {
    left: Dimension.gridUnit(0),
    top: Dimension.gridUnit(0),
  };
  let insertionIndex: number | undefined;

  const isParentStackLayout = isStackLayout(parentWidget.layout);

  if (isParentStackLayout) {
    const selectedWidgetsSet = new Set(selectedWidgetIds);
    // find first
    insertionIndex = parentWidget.children?.findIndex((childId) =>
      selectedWidgetsSet.has(childId),
    );
    const fakeCanvasForHeightCalc = makeFakeContainerCanvas();
    const minHeight = getCanvasMinHeight(
      fakeCanvasForHeightCalc,
      selectedWidgetIds.map((id) => flattenedWidgets[id]),
    );

    const width =
      parentWidget.layout === CanvasLayout.VSTACK
        ? maxWidth
        : Dimension.fitContent(maxWidthPx);

    const heightValue = Dimension.toGridUnit(
      minHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).roundUp().value;

    const grandParentWidget = flattenedWidgets[parentWidget.parentId];
    // If we are grouping into a column and the section fills viewport, we want to try and keep the height
    // so things appear visually consistent (useful for different distribution modes like space-between, space-around, etc.)
    const isParentFillColumn =
      parentWidget.type === WidgetTypes.CANVAS_WIDGET &&
      grandParentWidget.type === WidgetTypes.SECTION_WIDGET &&
      grandParentWidget?.height?.mode === "fillParent";

    const height =
      isParentFillColumn && parentWidget.layout === CanvasLayout.VSTACK
        ? Dimension.fillParent(heightValue)
        : Dimension.fitContent(heightValue);

    size = {
      height,
      width,
    };
  } else {
    // Because we want to offset the container to the left-most widget when gouping into a grid,
    // the width needs to account for the right-most widget and being shifted over to a 0,0 position
    // and adjust the sizing/positioning accordingly so things appear in the same spot visually
    let width = firstWidget.left.value + firstWidget.width.value;
    let parentLeft = Number.MAX_SAFE_INTEGER;
    let parentTop = Number.MAX_SAFE_INTEGER;
    let lowestWidget = 0;
    selectedWidgetIds.forEach((widgetId) => {
      const widget = flattenedWidgets[widgetId];
      if (widget.left.value + widget.width.value > width) {
        width = widget.left.value + widget.width.value;
      }
      if (widget.top.value < parentTop) {
        parentTop = widget.top.value;
      }
      if (widget.left.value < parentLeft) {
        parentLeft = widget.left.value;
      }

      if (widget.top.value + widget.height.value > lowestWidget) {
        lowestWidget = widget.top.value + widget.height.value;
      }
    });

    position = {
      left: Dimension.gridUnit(parentLeft),
      top: Dimension.gridUnit(parentTop),
    };

    // We try our hardest to get an accurate grid size by finding the bounds of the left-top most widget
    // and the widest right-most widget
    size = {
      height: Dimension.fitContent(lowestWidget - parentTop),
      width: Dimension.gridUnit(width - parentLeft),
    };
  }

  const parentLayoutProperties: Partial<StaticWidgetProps> = {
    layout: parentWidget.layout || CanvasLayout.FIXED,
    alignment: parentWidget.alignment || CanvasAlignment.LEFT,
    distribution: parentWidget.distribution,
    spacing: parentWidget.spacing,
    margin: parentWidget.margin,
  };

  const addChildPayload: WidgetAddChild = {
    widgetId: parentWidgetId,
    type: WidgetTypes.CONTAINER_WIDGET,
    position,
    size,
    newChildIndex: insertionIndex,
    newWidgetId: generateReactKey(),
    props: {
      containerStyle: "none",
    },
    childProps: {
      containerStyle: "none",
      ...parentLayoutProperties,
    },
  };

  return addChildPayload;
};
