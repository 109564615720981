import {
  EditorLanguage,
  ExtendedIntegrationPluginId,
  FormComponentType,
  FormSectionLayout,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { GraphQLPluginVersions } from './graphql';
import { authSections } from './shared';

export const GraphQLIntegrationPluginVersions = GraphQLPluginVersions;

export enum GraphQLIntegrationPluginMergedFieldNames {
  HEADERS = 'headers'
  // potentially variables too if converted to FieldList component
  // VARIABLES = 'custom.variables.value'
}

export const GraphQLIntegrationPlugin: Plugin = {
  id: ExtendedIntegrationPluginId.GRAPHQL,
  name: 'GraphQL',
  moduleName: 'GraphQLPlugin',
  modulePath: 'plugins/graphql/GraphQLPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/graphql.png',
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  rawRequestName: 'Executed request',
  hasTest: false,
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'Example API',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'URL',
            name: 'path',
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'https://swapi-graphql.netlify.app/.netlify/functions/index',
            rules: [{ required: true, message: 'URL is required' }]
          }
        ]
      },
      ...authSections({
        startVersion: GraphQLIntegrationPluginVersions.V8,
        pluginId: ExtendedIntegrationPluginId.GRAPHQL,
        pluginName: 'GraphQL',
        enabledMethods: {
          basic: true,
          oauth: true,
          bearer: true,
          apiKey: true
        }
      }),
      {
        name: 'tabs',
        layout: FormSectionLayout.TABS,
        items: [
          {
            label: 'Headers',
            name: GraphQLIntegrationPluginMergedFieldNames.HEADERS,
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.FIELD_LIST,
            secretsNames: ['Authorization']
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'URL',
            name: 'path',
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            disabled: true
          }
        ]
      },
      {
        name: 'tabs',
        layout: FormSectionLayout.TABS,
        items: [
          {
            label: 'Query',
            name: 'body',
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON
          },
          {
            label: 'Variables',
            name: 'custom.variables.value',
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON
          },
          {
            label: 'Headers',
            name: GraphQLIntegrationPluginMergedFieldNames.HEADERS,
            startVersion: GraphQLIntegrationPluginVersions.V1,
            componentType: FormComponentType.FIELD_LIST
          }
        ]
      },
      {
        name: 'additionalSettings',
        sectionHeader: 'Additional Settings',
        isCollapsible: true,
        defaultCollapsed: true,
        asTitle: false,
        items: [
          {
            name: 'verboseHttpOutput',
            label: 'Include full request response in step output',
            lightLabelText: true,
            startVersion: GraphQLIntegrationPluginVersions.V9,
            componentType: FormComponentType.SWITCH,
            initialValue: false,
            showConfirm: false,
            renderIconFirst: true
          },
          {
            name: 'failOnGraphqlErrors',
            label: 'Fail when request includes errors',
            lightLabelText: true,
            startVersion: GraphQLIntegrationPluginVersions.V10,
            componentType: FormComponentType.SWITCH,
            initialValue: false,
            showConfirm: false,
            renderIconFirst: true
          }
        ]
      }
    ]
  }
};
