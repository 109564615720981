import { Dimension } from "@superblocksteam/shared";
import {
  CanvasDefaults,
  GridDefaults,
  MIN_MAX_BUFFER_PX,
} from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { DynamicWidgetsLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import { clampMinMax, isDynamicSize } from "legacy/widgets/base/sizing";
import { clampMinMaxWidth } from "legacy/widgets/base/sizing/canvasWidthUtil";

export const handleDimensionConstraintUpdate = (params: {
  originalWidget: FlattenedWidgetProps;
  updates: Record<string, unknown>;
  dynamicWidgetLayout: DynamicWidgetsLayoutState;
  widget: FlattenedWidgetProps;
  widgetsWithChanges: Record<string, FlattenedWidgetProps>;
  fullChanges: Record<string, FlattenedWidgetProps>;
  parentColumnSpace?: number;
}) => {
  const {
    originalWidget,
    updates,
    dynamicWidgetLayout,
    widget,
    widgetsWithChanges,
    fullChanges,
  } = params;
  const widgetId = widget.widgetId;

  const originalMinHeight = originalWidget.minHeight;
  const originalMaxHeight = originalWidget.maxHeight;
  const originalMinWidth = originalWidget.minWidth;
  const originalMaxWidth = originalWidget.maxWidth;

  const currentHeight =
    widget.height.mode === "fillParent"
      ? (dynamicWidgetLayout[widgetId]?.height ?? widget.height)
      : widget.height;

  if (updates.minHeight && !originalMinHeight) {
    const maxHeightPx = widget.maxHeight
      ? Dimension.toPx(widget.maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
      : undefined;
    const heightPx = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    let newMinHeight = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    );
    if (maxHeightPx && maxHeightPx.value === heightPx.value) {
      newMinHeight = Dimension.px(
        Math.max(
          heightPx.value - MIN_MAX_BUFFER_PX,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ),
      );
    }

    widgetsWithChanges[widgetId].minHeight = newMinHeight as Dimension<"px">;
  }

  if (updates.maxHeight && !originalMaxHeight) {
    const minHeightPx = widget.minHeight
      ? (Dimension.toPx(
          widget.minHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ) as Dimension<"px">)
      : Dimension.px(0);

    const heightPx = Dimension.toPx(
      currentHeight,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ) as Dimension<"px">;

    const newMaxHeightPx =
      Math.max(minHeightPx.value, heightPx.value) + MIN_MAX_BUFFER_PX;
    const newMaxHeight = Dimension.px(newMaxHeightPx);

    widgetsWithChanges[widgetId].maxHeight = newMaxHeight as Dimension<"px">;
  }

  const currentWidth = isDynamicSize(widget.width.mode)
    ? (dynamicWidgetLayout[widgetId]?.width ?? widget.width)
    : widget.width;
  const parentColumnSpace =
    params.parentColumnSpace ?? GridDefaults.DEFAULT_GRID_COLUMN_WIDTH;
  if (updates.minWidth && !originalMinWidth) {
    const maxWidthPx = widget.maxWidth ? widget.maxWidth.value : undefined;
    const widthPx = Dimension.toPx(currentWidth, parentColumnSpace);
    let newMinWidth = Dimension.toPx(currentWidth, parentColumnSpace);
    if (maxWidthPx != null && maxWidthPx === widthPx.value) {
      newMinWidth = Dimension.px(
        Math.max(
          widthPx.value - MIN_MAX_BUFFER_PX,
          CanvasDefaults.MIN_GRID_UNIT_WIDTH,
        ),
      );
    }

    widgetsWithChanges[widgetId].minWidth = newMinWidth;
  }

  if (updates.maxWidth && !originalMaxWidth) {
    const minWidthPx = widget.minWidth ? widget.minWidth.value : 0;
    const widthPx = Dimension.toPx(currentWidth, parentColumnSpace);
    const newMaxWidthPx = Math.max(minWidthPx, widthPx.value); // don't add buffer because we can't render the label if its too big
    const newMaxWidth = Dimension.px(newMaxWidthPx);

    widgetsWithChanges[widgetId].maxWidth = newMaxWidth;
  }

  // make sure to make min/max respect each other when they are set
  if (updates.minHeight && widgetsWithChanges[widgetId].maxHeight != null) {
    fullChanges[widgetId] = clampMinMax({
      widget: widgetsWithChanges[widgetId],
      constraintType: "minHeight",
      newHeight: widgetsWithChanges[widgetId].minHeight as Dimension<
        "gridUnit" | "px"
      >,
    });
  }

  if (updates.maxHeight && widgetsWithChanges[widgetId].minHeight != null) {
    fullChanges[widgetId] = clampMinMax({
      widget: widgetsWithChanges[widgetId],
      constraintType: "maxHeight",
      newHeight: widgetsWithChanges[widgetId].maxHeight as Dimension<
        "gridUnit" | "px"
      >,
    });
  }

  if (updates.minWidth && widgetsWithChanges[widgetId].maxWidth != null) {
    fullChanges[widgetId] = clampMinMaxWidth({
      widget: widgetsWithChanges[widgetId],
      constraintType: "minWidth",
      newWidth: widgetsWithChanges[widgetId].minWidth as Dimension<"px">,
      parentLayout: widgetsWithChanges[widget.parentId]?.layout,
    });
  }

  if (updates.maxWidth && widgetsWithChanges[widgetId].minWidth != null) {
    fullChanges[widgetId] = clampMinMaxWidth({
      widget: widgetsWithChanges[widgetId],
      constraintType: "maxWidth",
      newWidth: widgetsWithChanges[widgetId].maxWidth as Dimension<"px">,
      parentLayout: widgetsWithChanges[widget.parentId]?.layout,
    });
  }
};
