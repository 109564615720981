import { json, Graph } from "@dagrejs/graphlib";
import { useCallback } from "react";
import { useDispatch, useStore } from "react-redux";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { selectAllV2Apis } from "store/slices/apisV2";
import { AppState } from "store/types";
import { setReferenceAnalysisState } from "./actions";
import { computeDeps } from "./computeDeps";
import { type ReferenceAnalysisMeta } from "./referenceAnalysisReducer";

export const useReferenceAnalysis = () => {
  const dispatch = useDispatch();
  const store = useStore<AppState>();
  const runCalc = useCallback(async () => {
    const state = store.getState();
    const evaluationsState = state.legacy.evaluations;
    const apis = selectAllV2Apis(state);
    const canvasWidgets = getCanvasWidgets(state);

    const result = await computeDeps({
      evaluationsState,
      canvasWidgets,
      apis,
    });

    const lastSuccessfulWrite = selectLastSuccessfulWrite(state);
    const now = Date.now();
    const meta: ReferenceAnalysisMeta = {
      lastSuccessfulWrite: lastSuccessfulWrite
        ? lastSuccessfulWrite.valueOf()
        : now,
      computedTime: now,
    };

    dispatch(
      setReferenceAnalysisState({
        referenceGraphJson: json.write(result.referenceGraph as Graph),
        usageGraphJson: json.write(result.usageGraph as Graph),
        meta,
      }),
    );

    console.log(
      "Reference analysis info",
      {
        state,
        evaluationsState,
        canvasWidgets,
        apis,
      },
      result,
    );
  }, [dispatch, store]);

  return runCalc;
};
