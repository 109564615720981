import styled, { css } from "styled-components";

export type IconProps = {
  width?: number;
  height?: number;
  color?: string;
  background?: string;
  padding?: number;
  rounded?: boolean;
  marginTop?: number;
  onClick?: (e?: any) => void;
  className?: string;
  keepColors?: boolean;
  disabled?: boolean;
};

export const IconWrapper = styled.div<IconProps>`
  &:focus {
    outline: none;
  }
  background-color: ${(props) => props.background || "transparent"};
  border-radius: ${(props) => (props.rounded ? "50%" : "0")};
  ${(props) => props.padding && `padding: ${props.padding}px;`};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`};
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => props.width}px;
  ${(props) =>
    !props.keepColors &&
    css`
      color: ${(props) => props.theme.colors.GREY_500};
    `}
  height: ${(props) => props.height}px;
  svg {
    width: ${(props) =>
      props.width
        ? props.width - (props.padding ?? 0) * 2
        : props.theme.legacy.fontSizes[7]}px;
    height: ${(props) =>
      props.height
        ? props.height - (props.padding ?? 0) * 2
        : props.theme.legacy.fontSizes[7]}px;
    ${(props) =>
      !props.keepColors &&
      css`
        path {
          fill: ${props.color || props.theme.colors.GREY_500};
        }
      `}
  }
`;

export type AppIconWithMetaType = object;
