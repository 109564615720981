import { useCallback } from "react";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant as ToasterVariant } from "legacy/components/ads/common";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import localStorage from "legacy/utils/localStorage";
import { getStore } from "store/dynamic";
import { useAppSelector } from "store/helpers";
import { BackendTypes } from "store/slices/apisV2";
import {
  renameBlock,
  insertBlocksIntoParent,
} from "store/slices/apisV2/control-flow/control-flow-manipulation";
import {
  convertDSLToBackendBlocks,
  convertDSLToFrontend,
} from "store/slices/apisV2/control-flow/dsl-converters";
import {
  findBlockLocation,
  getBlockListFromLocator,
  BlockPositionLocator,
} from "store/slices/apisV2/control-flow/locators";
import {
  RefactoredType,
  refactorEntityNameWithDataTree,
} from "store/slices/apisV2/control-flow/refactor-entity-name";
import {
  GenericBlock,
  ControlFlowFrontendDSL,
} from "store/slices/apisV2/control-flow/types";
import { selectV2ApiById } from "store/slices/apisV2/selectors";
import { pluralize } from "utils/string";
import { useControlFlowActions } from "../useControlFlowActions";

const COPY_PASTE_API_DSL_STORAGE_KEY = "superblocks-copy-paste-api-dsl";

const getStorage = () => {
  if (window.localStorage) {
    return localStorage;
  }
  return sessionStorage;
};

export const getCopiedBlocksFromLocalStorage = (): BackendTypes.Block[] => {
  const storage = getStorage();
  try {
    const data = JSON.parse(
      (storage.getItem(COPY_PASTE_API_DSL_STORAGE_KEY) as string) || "[]",
    );
    return Array.isArray(data) ? data : [];
  } catch (err) {
    console.error("Error parsing copied blocks: ", err);
    return [];
  }
};

export const clearCopiedBlocksFromLocalStorage = () => {
  const storage = getStorage();
  storage.removeItem(COPY_PASTE_API_DSL_STORAGE_KEY);
};

export const useCopyBlocks = ({
  controlFlowDSL,
}: {
  controlFlowDSL: ControlFlowFrontendDSL;
}) => {
  return useCallback(
    (blockNames: string[]) => {
      const blockLocations = blockNames.map((blockName) => {
        return findBlockLocation(controlFlowDSL, blockName);
      });
      if (blockLocations.length === 0) return;

      let orderedBlockNames: GenericBlock["name"][] = [];
      if (blockLocations[0]?.parentId === undefined) {
        // We're dealing with root blocks
        orderedBlockNames = blockLocations
          .sort((locA, locB) => {
            return (locA?.idx || 0) - (locB?.idx || 0);
          })
          .map((loc) =>
            loc?.idx !== undefined ? controlFlowDSL.rootBlocks[loc?.idx] : null,
          )
          .filter((name) => !!name) as string[];
      } else {
        // We're dealing with blocks that are nested, so find the list
        // they're in
        const blockList = getBlockListFromLocator(
          controlFlowDSL,
          blockLocations[0],
        );
        orderedBlockNames = blockLocations
          .sort((locA, locB) => {
            return (locA?.idx || 0) - (locB?.idx || 0);
          })
          .map((loc) => (loc?.idx != null ? blockList[loc?.idx] : null))
          .filter((name) => name != null) as string[];
      }
      const partialDSL: ControlFlowFrontendDSL = {
        ...controlFlowDSL,
        rootBlocks: orderedBlockNames,
      };
      const backendDSLToCopy = convertDSLToBackendBlocks(partialDSL);

      const storage = getStorage();
      storage.setItem(
        COPY_PASTE_API_DSL_STORAGE_KEY,
        JSON.stringify(backendDSLToCopy),
      );

      Toaster.show({
        text: `${blockNames.length} ${pluralize(
          blockNames.length,
          "block",
        )} copied`,
        variant: ToasterVariant.success,
      });
    },
    [controlFlowDSL],
  );
};

export const usePasteBlocks = ({
  apiId,
  pluginInfo,
  controlFlowDSL,
}: {
  apiId: BackendTypes.Api["metadata"]["id"];
  pluginInfo: {
    pluginNames: Set<string>;
    datasourceLookup: Record<string, string>;
  };
  controlFlowDSL: ControlFlowFrontendDSL;
}) => {
  const controlFlowActions = useControlFlowActions();
  const api = useAppSelector((state) => selectV2ApiById(state, apiId));

  return useCallback(
    async (blockToPasteAfter?: GenericBlock["name"]): Promise<number> => {
      if (!api) {
        console.error(`Can't paste blocks - No API`);
        return 0;
      }
      const store = getStore();
      const dataTree = getDataTree(store.getState());

      const copiedApiDsl: BackendTypes.Block[] =
        getCopiedBlocksFromLocalStorage();

      const pastedFrontendDSL = convertDSLToFrontend(
        {
          ...api.apiPb,
          blocks: copiedApiDsl,
        },
        pluginInfo,
      );
      // We need to rename the blocks if there are conflicts - "{name}_copy" and can
      // add numbers as needed
      for (const [blockName] of Object.entries(pastedFrontendDSL.blocks)) {
        if (controlFlowDSL.blocks[blockName] !== undefined) {
          // We must rename this block to prevent conflicts
          const location = findBlockLocation(
            pastedFrontendDSL,
            blockName,
          ) as BlockPositionLocator;

          let newName = `${blockName}_copy`;
          let suffixNumber = 0;
          while (controlFlowDSL.blocks[newName] !== undefined) {
            suffixNumber++;
            newName = `${newName}${suffixNumber}`;
          }

          // Refactor the name references first
          await refactorEntityNameWithDataTree(pastedFrontendDSL, {
            prevName: blockName,
            newName,
            refactoredType: RefactoredType.BLOCK,
            dataTree,
          });

          // Now update the block names
          renameBlock({
            prevBlockName: blockName,
            updatedBlockName: newName,
            location,
            state: pastedFrontendDSL,
          });
        }
      }

      // Splice the DSL into the current DSL
      const mergedDSL: ControlFlowFrontendDSL = {
        rootBlocks: [...controlFlowDSL.rootBlocks],
        blocks: {
          ...controlFlowDSL.blocks,
          ...pastedFrontendDSL.blocks,
        },
      };

      if (!blockToPasteAfter) {
        // no block to paste after, so just paste in root
        mergedDSL.rootBlocks = [...pastedFrontendDSL.rootBlocks];
      } else {
        // paste after the block passed in, which might be nested inside a parent block
        const location = findBlockLocation(
          controlFlowDSL,
          blockToPasteAfter,
        ) as BlockPositionLocator;

        if (location.parentId) {
          // We're nested inside a parent block, so paste the blocks after the selected block in the parent
          insertBlocksIntoParent({
            blockNames: pastedFrontendDSL.rootBlocks,
            location,
            state: mergedDSL,
          });
        } else {
          // Otherwise we're just in the root, so paste the blocks after the selected block in the root
          mergedDSL.rootBlocks = [
            ...controlFlowDSL.rootBlocks.slice(0, location.idx + 1),
            ...pastedFrontendDSL.rootBlocks,
            ...controlFlowDSL.rootBlocks.slice(location.idx + 1),
          ];
        }
      }

      // Update the control flow DSL
      controlFlowActions.updateDSL({
        newControlFlowDSL: mergedDSL,
      });

      const numPastedBlocks = pastedFrontendDSL.rootBlocks.length;
      Toaster.show({
        text: `${numPastedBlocks} ${pluralize(
          numPastedBlocks,
          "block",
        )} pasted`,
        variant: ToasterVariant.success,
      });

      return numPastedBlocks;
    },
    [api, controlFlowDSL, pluginInfo, controlFlowActions],
  );
};
