import {
  DB_SQL_INITIAL_TEXT,
  EditorLanguage,
  FormComponentType,
  FormItem,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';
import { CONNECTION_METHODS_AND_DISPLAY_NAMES, makeDropdownItem } from './shared/db';

export const CockroachDBPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2'
};

const BASE_HOST = {
  label: 'Host',
  name: 'endpoint.host',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'staging-database.amazonaws.com',
  rules: [{ required: true, message: 'Host is required' }]
};

const BASE_PORT = {
  label: 'Port',
  name: 'endpoint.port',
  componentType: FormComponentType.INPUT_TEXT,
  initialValue: '5432',
  rules: [{ required: true, message: 'Port is required' }]
};

const BASE_DATABASE_NAME = {
  label: 'Database name',
  name: 'authentication.custom.databaseName.value',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_USERNAME = {
  label: 'Database username',
  name: 'authentication.username',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_PASSWORD = {
  label: 'Database password',
  name: 'authentication.password',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.PASSWORD
};

const BASE_ENABLE_SSL = {
  label: 'Enable SSL',
  name: 'connection.useSsl',
  initialValue: 'checked',
  componentType: FormComponentType.CHECKBOX
};

const BASE_SELFSIGNED_SSL = {
  label: 'Use a self-signed SSL certificate',
  name: 'connection.useSelfSignedSsl',
  componentType: FormComponentType.CHECKBOX,
  tooltip: {
    markdownText: 'Use a self-signed SSL certificate'
  }
};

const BASE_SERVER_CA = {
  label: 'Server CA',
  name: 'connection.ca',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN CERTIFICATE-----\n' +
    'MIIC/jCCAeYCCQDLwS4pIwJC3zANBgkqhkiG9w0BAQsFADBBMQswCQYDVQQGEwJV\n' +
    '...\n' +
    '-----END CERTIFICATE-----'
};

const BASE_CLIENT_KEY = {
  label: 'Client key',
  name: 'connection.key',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN RSA PRIVATE KEY-----\n' +
    'BAoMBFRlc3QwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCpvozN05Ou\n' +
    '...\n' +
    '-----END RSA PRIVATE KEY-----'
};

const BASE_CLIENT_CERT = {
  label: 'Client cert',
  name: 'connection.cert',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN CERTIFICATE-----\n' +
    'Ur2LYWdrVjqlS/wJyVIze15FMf7sgl+RINsLbQDLwS4pIwJC3zANBgkqhkiG9w0B\n' +
    '...\n' +
    '-----END CERTIFICATE-----'
};

export const CockroachDBPlugin: Plugin = {
  id: 'cockroachdb',
  name: 'CockroachDB',
  moduleName: 'CockroachDBPlugin',
  modulePath: 'plugins/cockroachdb/CockroachDBPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/cockroach.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/cockroachdb',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: CockroachDBPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Connection method',
            name: 'connectionType',
            startVersion: CockroachDBPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          {
            label: 'URI',
            name: 'connectionUrl',
            startVersion: CockroachDBPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `postgres://[:user]:[:password]@[:host]:[:port]/[:database]`,
            tooltip: {
              markdownText:
                'Learn more about [CockRoachDB connection options](https://www.cockroachlabs.com/docs/v24.1/connection-parameters#connect-using-a-url)'
            },
            rules: [{ required: true, message: 'URI is required' }],
            display: {
              show: {
                connectionType: ['url']
              }
            }
          },
          // HOST
          { ...BASE_HOST, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_HOST,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // PORT
          { ...BASE_PORT, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_PORT,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE NAME
          { ...BASE_DATABASE_NAME, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_DATABASE_NAME,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE USERNAME
          { ...BASE_DATABASE_USERNAME, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_DATABASE_USERNAME,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE PASSWORD
          { ...BASE_DATABASE_PASSWORD, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_DATABASE_PASSWORD,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // ENABLE SSL
          { ...BASE_ENABLE_SSL, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_ENABLE_SSL,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // USE SELF-SIGNED SSL
          { ...BASE_SELFSIGNED_SSL, startVersion: CockroachDBPluginVersions.V1, endVersion: CockroachDBPluginVersions.V1 } as FormItem,
          {
            ...BASE_SELFSIGNED_SSL,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // BASE SERVER CA
          {
            ...BASE_SERVER_CA,
            startVersion: CockroachDBPluginVersions.V1,
            endVersion: CockroachDBPluginVersions.V1,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_SERVER_CA,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // CLIENT KEY
          {
            ...BASE_CLIENT_KEY,
            startVersion: CockroachDBPluginVersions.V1,
            endVersion: CockroachDBPluginVersions.V1,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_CLIENT_KEY,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // CLIENT CERT
          {
            ...BASE_CLIENT_CERT,
            startVersion: CockroachDBPluginVersions.V1,
            endVersion: CockroachDBPluginVersions.V1,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_CLIENT_CERT,
            startVersion: CockroachDBPluginVersions.V2,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'body',
            startVersion: CockroachDBPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'usePreparedSql',
            startVersion: CockroachDBPluginVersions.V1,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
