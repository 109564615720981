import {
  WorkflowExecutionParamsKey,
  ExecutionParam,
} from "@superblocksteam/shared";
import { InputRef } from "antd";
import { produce } from "immer";
import { set } from "lodash";
import React, { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { ApiDto } from "store/slices/apis";
import { ParameterBuilder } from "../../../../components/ParameterBuilder";
import { findKeyInExecutionParams } from "../utils";
import { tableDataToParams, VariableDataField } from "./common";
import type { ApiDtoWithPb } from "store/slices/apisV2/slice";

type WorkflowBodySelector = {
  apiId: ApiDto["id"];
  apiV1?: ApiDto;
  apiV2?: ApiDtoWithPb;
  params: ExecutionParam[];
  updateApi: (api: ApiDto) => Promise<void>;
  onQueryParamsUpdate?: (queryParamsMap: Record<string, string>) => void;
  environment?: string;
  disabled?: boolean;
};

const WorkflowQuerySelector = ({
  apiId,
  apiV1,
  apiV2,
  params,
  updateApi,
  onQueryParamsUpdate,
  environment,
  disabled,
}: WorkflowBodySelector) => {
  const setParams = useCallback(
    async (tableData: VariableDataField[]) => {
      const newParams = tableDataToParams(
        tableData,
        WorkflowExecutionParamsKey.QUERY_PARAMS,
      );

      let updated: ApiDto | undefined;
      if (apiV1) {
        updated = produce(apiV1, (toUpdate) => {
          if (toUpdate?.actions) toUpdate.actions.workflowQueries = [newParams];
        });
      } else if (apiV2) {
        updated = produce(apiV2, (toUpdate) => {
          set(toUpdate.apiPb, "trigger.workflow.parameters.query", {});
          if (toUpdate?.apiPb?.trigger?.workflow?.parameters?.query) {
            const reformattedParams = Object.entries(newParams.value).reduce(
              (acc, [key, value]) => {
                // this logic should closely reflect encodeWorkflowQueryParamsForPb
                if (Array.isArray(value)) {
                  acc[key] = {
                    values: value.map((v) =>
                      typeof v === "string" ? v : JSON.stringify(v),
                    ),
                  };
                } else {
                  acc[key] = {
                    values: [
                      typeof value === "string" ? value : JSON.stringify(value),
                    ],
                  };
                }
                return acc;
              },
              {} as Record<string, { values: string[] }>,
            );
            toUpdate.apiPb.trigger.workflow.parameters.query =
              reformattedParams;
          }
        });
      }
      if (updated) {
        await updateApi(updated);
      }

      if (onQueryParamsUpdate) {
        onQueryParamsUpdate(newParams.value);
      }
    },
    [apiV1, apiV2, onQueryParamsUpdate, updateApi],
  );

  const initialTableData = useMemo(() => {
    const queryParams = findKeyInExecutionParams(
      params,
      WorkflowExecutionParamsKey.QUERY_PARAMS,
    );
    if (!queryParams) {
      // Do not set state and rerender if value is not changed
      return [] as VariableDataField[];
    }
    if (onQueryParamsUpdate) {
      onQueryParamsUpdate(queryParams.value as any);
    }

    return (
      Object.entries((queryParams.value as any) ?? {}).map(
        ([key, value]) =>
          ({
            id: uuidv4(),
            name: key,
            value: value,
            ref: React.createRef<InputRef>(),
          }) as VariableDataField,
      ) ?? ([] as VariableDataField[])
    );
  }, [params, onQueryParamsUpdate]);

  return (
    <ParameterBuilder
      isV2={apiV2 != null}
      keyColumn={{
        toolTip: `
                Use variables in Workflow steps. I.e. to reference a
                userId variable, use params.userId in Python and JS
                steps and {{params.userId}} in all other steps.`,
        placeHolder: "Variable name",
      }}
      valueColumn={{
        toolTip: `
                Test values are used when you run a step or
                test your in-development workflow. Values can either be primitives or JSON`,
        placeHolder: `Value`,
      }}
      initialTableData={initialTableData}
      onUpdate={setParams}
      addVariableButtonDataTestString={"add-variable-query"}
      environment={environment}
      apiId={apiId}
      showBuiltInProfileVariable
      disabled={disabled}
    />
  );
};

export default WorkflowQuerySelector;
