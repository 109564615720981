import {
  OrganizationUserDto,
  PrincipalTypeEnum,
  ResourceTypeEnum,
} from "@superblocksteam/shared";
import { Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper, MainWrapperStyles } from "components/ui/Page";
import { Spinner } from "components/ui/Spinner";
import ResourceList from "pages/Permissions/ResourceList";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import { USER_PAGE_URL } from "pages/routes";
import {
  selectOnlyOrganization,
  selectOnlyOrganizationId,
} from "store/slices/organizations";
import { useListUserOrGroupAssignmentsQuery } from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendErrorUINotification } from "utils/notification";
import { truncate } from "utils/string";
import UserInfo from "./UserInfo";
import { getUserDetailsRequest } from "./client";
import { isValidPageType } from "./constants";

const RoleTag = styleAsClass`
    border-radius: 3px;
    border: 1px solid ${colors.GREY_100};
    opacity: 0px;
    background: ${colors.GREY_25};
    font-size: 12px;
    line-height: 16px;
    color: ${colors.GREY_700};
    padding: 4px 6px;
    height: 24px;
    font-weight: 400;
`;

const UserDetailPage = () => {
  const params = useParams();
  const userId = params.userId;

  const pageType = isValidPageType(params.pageType) ? params.pageType : "";

  const navigate = useNavigate();

  const [user, setUser] = useState<OrganizationUserDto>();
  const currentOrg = useSelector(selectOnlyOrganization);

  const [isUserLoading, setIsUserLoading] = useState(false);

  useEffect(() => {
    const getUserDetails = async () => {
      if (!currentOrg.id || !userId) {
        return;
      }
      try {
        setIsUserLoading(true);
        const userDetail = await getUserDetailsRequest({
          orgId: currentOrg.id,
          userId,
        });
        setUser(userDetail);
      } catch (e: any) {
        sendErrorUINotification({
          message: `Failed to get user details: ${e.message}`,
        });
      } finally {
        setIsUserLoading(false);
      }
    };
    getUserDetails();
  }, [currentOrg.id, userId]);

  const title = truncate(user?.email ?? "");

  const handleTabChange = useCallback(
    (key: string) => {
      navigate({ pathname: USER_PAGE_URL(userId, key) });
    },
    [userId, navigate],
  );

  const organizationId = useSelector(selectOnlyOrganizationId);

  const { data: assignments } = useListUserOrGroupAssignmentsQuery({
    isUser: true,
    principalId: userId ?? "",
    organizationId: organizationId ?? "",
  });

  const userOrgRole = assignments?.find(
    (assignment) =>
      assignment.resourceType === ResourceTypeEnum.ORGANIZATION &&
      assignment.principalType === PrincipalTypeEnum.USER,
  )?.role;

  return (
    <PageWrapper pageName="Users">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper className={MainWrapperStyles}>
          <BreadCrumb
            paths={[
              {
                title: "Users",
                link: "/users",
              },
              { title },
            ]}
          />
          <div className={HeaderWrapper}>
            <div className="page-header-title">
              {" "}
              {user?.name ?? ""}{" "}
              {userOrgRole ? (
                <span className={RoleTag}>{userOrgRole.name}</span>
              ) : null}{" "}
            </div>
          </div>

          <Tabs activeKey={pageType} onTabClick={handleTabChange}>
            <Tabs.TabPane
              tab={<span data-test="user-detail-details"> Details </span>}
              key="details"
            >
              {isUserLoading ? <Spinner /> : user && <UserInfo user={user} />}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span data-test="user-detail-permissions"> Permissions </span>
              }
              key="permissions"
            >
              {isUserLoading ? (
                <Spinner />
              ) : (
                user &&
                userId && (
                  <ResourceList
                    principalId={userId}
                    principalType={PrincipalTypeEnum.USER}
                    principalName={user.name}
                    roleName={userOrgRole?.name ?? ""}
                    userStatus={user.status}
                  />
                )
              )}
            </Tabs.TabPane>
          </Tabs>
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default UserDetailPage;
