import Modal from "antd/lib/modal";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LeftPointer } from "assets/images/promotion-modal/left-pointer.svg";
import { ReactComponent as ModalHeaderImage } from "assets/images/promotion-modal/producthunt-modal-header.svg";
import { ReactComponent as VoteImage } from "assets/images/promotion-modal/producthunt-vote.svg";
import { ReactComponent as RightPointer } from "assets/images/promotion-modal/right-pointer.svg";
import envs from "env";
import { STORAGE_KEYS } from "legacy/utils/StorageUtils";

const PHImageLink = envs.get("SUPERBLOCKS_UI_PRODUCTHUNT_URL");
const PHAvatarLink = envs.get("SUPERBLOCKS_UI_PRODUCTHUNT_AVATAR_URL");

const PromotionModalWidth = 545;
const PromotionModalHeight = 332;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const BottomRowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  margin-top: -72px;
  video {
    border-radius: 8px;
  }
`;

const PHImageWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  margin-top: 20px;
`;
const SubTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #6c7989;
`;

const PromotionModal = () => {
  const handleCancel = useCallback(() => {
    localStorage.setItem(STORAGE_KEYS.SHOW_PROMOTION_MODAL_KEY, "false");
    setIsVisible(false);
  }, []);

  const currentDate = new Date();
  const startDate = new Date("2022-06-29T00:00:00-0700");
  const endDate = new Date("2022-06-30T00:00:00-0700");
  const inPromotionTime =
    currentDate.getTime() >= startDate.getTime() &&
    currentDate.getTime() <= endDate.getTime();

  useEffect(() => {
    if (
      localStorage.getItem(STORAGE_KEYS.SHOW_PROMOTION_MODAL_KEY) !== "false" &&
      inPromotionTime
    ) {
      setIsVisible(true);
      localStorage.setItem(STORAGE_KEYS.SHOW_PROMOTION_MODAL_KEY, "false");
    }
  }, [inPromotionTime]);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Modal
      open={isVisible}
      destroyOnClose
      onCancel={handleCancel}
      title=""
      centered
      bodyStyle={{ padding: 0, height: PromotionModalHeight }}
      width={PromotionModalWidth}
      footer={null}
    >
      <HeaderWrapper>
        <ModalHeaderImage />
        <LogoWrapper>
          <video width="72" height="72" autoPlay loop muted>
            <source src={PHAvatarLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </LogoWrapper>
      </HeaderWrapper>
      <TextWrapper>{"We are live on Product Hunt"}</TextWrapper>
      <SubTextWrapper>{"We'd love your support 🙏"}</SubTextWrapper>
      <BottomRowWrapper>
        <LeftPointer />
        <PHImageWrapper>
          <a href={PHImageLink} target="_blank" rel="noopener noreferrer">
            <VoteImage />
          </a>
        </PHImageWrapper>
        <RightPointer />
      </BottomRowWrapper>
    </Modal>
  );
};

export default PromotionModal;
