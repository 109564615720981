import { isValidEntity } from "legacy/workers/evaluationUtils";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { UsageEdgeType } from "../types";
import { UsageContext } from "./UsageContext";
import { calculateApiUsageEdges } from "./apiUsage";
import { calculateCustomEventUsageEdges } from "./customEventUsage";
import { calculateStateVarUsageEdges } from "./stateVarUsage";
import { calculateTimerUsageEdges } from "./timerUsage";
import { calculateWidgetUsageEdges } from "./widgetUsage";
import type { EdgeInfo } from "./types";

// if this returns an empty array, then the entity is not used yet, based on what else is known to be used
export const getEntityUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const dtEntity = context.referenceGraph.node(nodeId);

  if (!dtEntity) {
    // CH: temporarily commented out throw and returned default to allow analysis to run on incomplete data trees
    //throw new Error(`Node not found: ${nodeId}`);
    console.error(`Node not found: ${nodeId}`);
    return defaultCalculateUsageEdges(nodeId, context);
  }

  if (!isValidEntity(dtEntity)) {
    return defaultCalculateUsageEdges(nodeId, context);
  }

  const type = dtEntity.ENTITY_TYPE;

  switch (type) {
    case ENTITY_TYPE.WIDGET:
      return calculateWidgetUsageEdges(nodeId, context);
    case ENTITY_TYPE.ACTION:
      return calculateApiUsageEdges(nodeId, context);
    case ENTITY_TYPE.TIMER:
      return calculateTimerUsageEdges(nodeId, context);
    case ENTITY_TYPE.STATE_VAR:
      return calculateStateVarUsageEdges(nodeId, context);
    case ENTITY_TYPE.CUSTOM_EVENT:
      return calculateCustomEventUsageEdges(nodeId, context);
    case ENTITY_TYPE.GLOBAL:
    case ENTITY_TYPE.THEME:
    case ENTITY_TYPE.ICONS:
    case ENTITY_TYPE.EMBEDDING:
      return defaultCalculateUsageEdges(nodeId, context);
    default: {
      const exhaustiveCheck: never = type;
      console.error(`Unhandled action case: ${exhaustiveCheck}`);
      return defaultCalculateUsageEdges(nodeId, context);
    }
  }
};

// by default, if any node in the reference graph points to us (nodeId), and they are used, then we are used too
const defaultCalculateUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);

  return usedPredecessors.map((usageSourceNodeId) => ({
    usageSourceNodeId,
    edgeType: UsageEdgeType.DEPENDENCY,
  }));
};
