import { ApplicationScope } from "@superblocksteam/shared";
import { useMemo } from "react";
import { getAnalysisStatus, getUsageGraph } from "analysis/selectors";
import { useAppSelector } from "store/helpers";

export const useEntityUsageInfo = (params: {
  entityName: string;
  entityScope?: ApplicationScope;
}) => {
  const { entityName, entityScope } = params;
  const usageGraph = useAppSelector(getUsageGraph);
  const canPrune = useMemo(() => {
    const entityNameWithScope = entityScope
      ? `${entityScope}.${entityName}`
      : entityName;
    const entityNode = usageGraph.node(entityNameWithScope);
    return Boolean(entityNode?.canPrune);
  }, [usageGraph, entityName, entityScope]);

  const analysisStatus = useAppSelector(getAnalysisStatus);

  return {
    canPrune,
    analysisStatus,
  };
};
