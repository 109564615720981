import { get } from "lodash";
import {
  PAGE_WIDGET_ID,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";

type WidgetTraversal = {
  widgetId: string;
  type: WidgetType;
  parentId: string;
  openParentPropertyPane?: boolean;
};

export function getParentToOpenIfAny<Widget extends WidgetTraversal>(
  widgetId: string | undefined,
  widgets: Record<string, Widget>,
): Widget | undefined {
  return getParentsToOpenIfAny<Widget>(widgetId, widgets)[0];
}

export function getParentsToOpenIfAny<Widget extends WidgetTraversal>(
  widgetId: string | undefined,
  widgets: Record<string, Widget>,
): Widget[] {
  let widget = get(widgets, widgetId ?? "", undefined);

  if (widget) {
    // loop through ancestry and collect non canvas & section widgets
    const parents = [];
    while (
      widget &&
      widget.type &&
      widget.parentId &&
      widget.parentId !== PAGE_WIDGET_ID
    ) {
      widget = get(widgets, widget.parentId, undefined);
      const parent = get(widgets, widget?.parentId ?? "", undefined);

      const isColumn =
        widget?.type === WidgetTypes.CANVAS_WIDGET &&
        parent?.type === WidgetTypes.SECTION_WIDGET;
      const isTab = parent?.type === WidgetTypes.TABS_WIDGET;
      const isRegularCanvas =
        widget?.type === WidgetTypes.CANVAS_WIDGET && !isColumn && !isTab;

      if (
        widget &&
        !isRegularCanvas &&
        // Make sure we aren't selecting the hidden containers inside the grid
        !widget?.openParentPropertyPane
      ) {
        parents.push(widget);
      }
    }
    return parents;
  }

  return [];
}
