import { ViewDatasourceDto } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useState } from "react";

import { getPluginById } from "utils/integrations";
import { DatasourceItem, DatasourceIcon } from "./CommonComponents";

export const ApiIntegrations = (props: {
  searchDatasources: ViewDatasourceDto[];
  handleClickIntegration: (ds: ViewDatasourceDto) => void;
}) => {
  const { searchDatasources, handleClickIntegration } = props;
  const [isDatasourceSelected, setIsDatasourceSelected] = useState(false);

  if (!searchDatasources.length) {
    return <React.Fragment />;
  }
  return (
    <>
      {searchDatasources.map((ds) => {
        const plugin = getPluginById(ds.pluginId);
        return (
          <DatasourceItem
            key={ds.id}
            onClick={() => {
              setIsDatasourceSelected(true);
              handleClickIntegration(ds);
            }}
            disabled={isDatasourceSelected}
          >
            <DatasourceIcon src={plugin?.iconLocation} />
            <Typography.Text>
              {ds.name} {!ds.isDefault && "(" + plugin?.name + ")"}
            </Typography.Text>
          </DatasourceItem>
        );
      })}
    </>
  );
};
