import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CrossIcon } from "assets/icons/common/cross.svg";

// TODO: Extend with additional sizes for other places in the UI
enum Size {
  SMALL = "SMALL",
}

const SIZE_STYLES = {
  [Size.SMALL]: css`
    border-radius: 2px;
    padding: 1px;

    > svg {
      width: 14px;
      height: 14px;
    }
  `,
};

type CloseButtonProps = {
  size?: Size;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
};

const StyledButton = styled.button<{ $size: Size }>`
  -webkit-appearance: none;
  text-transform: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $size }) => SIZE_STYLES[$size]}

  > svg {
    fill: ${({ theme }) => theme.colors.GREY_300};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_50};

    > svg {
      fill: ${({ theme }) => theme.colors.GREY_500};
    }
  }
`;

const CloseButton = ({ size, style, onClick }: CloseButtonProps) => {
  return (
    <StyledButton
      className="close-button"
      $size={size || Size.SMALL}
      style={style}
      onClick={onClick}
    >
      <CrossIcon />
    </StyledButton>
  );
};

export default CloseButton;
