import { Dimension, Padding } from "@superblocksteam/shared";
import { GridPosition, WidgetMove } from "legacy/actions/pageActions";
import {
  CanvasAlignment,
  CanvasLayout,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import {
  updateWidgetPosition,
  updateWidgetWidths,
} from "legacy/sagas/WidgetOperationsSagasUtils";
import { GeneratedTheme } from "legacy/themes";
import { type StackAdjustmentsInfo } from "legacy/widgets/StackLayout/types";
import {
  StackDragPositions,
  getOverridePos,
  isStackLayout,
} from "legacy/widgets/StackLayout/utils";
import { getWidgetDefaultPadding } from "legacy/widgets/base/sizing";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";
import type { WidgetProps } from "legacy/widgets";

type MoveProps = {
  selectedWidgetIds: string[];
  stateWidgets: CanvasWidgetsReduxState;
  movePayload: WidgetMove;
  widget: WidgetProps;
  widgets: CanvasWidgetsReduxState;
  oldParent: WidgetProps;
  stackDragPositions?: StackDragPositions;
  stackAdjustments?: StackAdjustmentsInfo;
  flattenedParent?: FlattenedWidgetLayoutProps;
  flattenedWidgets: FlattenedWidgetLayoutMap;
  theme: GeneratedTheme;
};

export const getSortedWidgetOrder = (
  widgetIds: string[],
  widgets: CanvasWidgetsReduxState,
  newParentLayout: CanvasLayout | undefined,
  sourceLayout: CanvasLayout | undefined = CanvasLayout.FIXED,
) => {
  if (widgetIds.length <= 1) {
    return widgetIds;
  }
  const parentWidgetId = widgets[widgetIds[0]].parentId;
  const parentWidget = widgets[parentWidgetId];
  const sourceLayoutToUse = sourceLayout;
  const isSourceFixed =
    sourceLayoutToUse == null || sourceLayoutToUse === CanvasLayout.FIXED;
  if (isSourceFixed) {
    return widgetIds.slice().sort((a, b) => {
      const widgetA = widgets[a];
      const widgetB = widgets[b];
      const primaryA =
        newParentLayout === CanvasLayout.VSTACK ? widgetA.top : widgetA.left;
      const primaryB =
        newParentLayout === CanvasLayout.VSTACK ? widgetB.top : widgetB.left;
      const secondaryA =
        newParentLayout === CanvasLayout.VSTACK ? widgetA.left : widgetA.top;
      const secondaryB =
        newParentLayout === CanvasLayout.VSTACK ? widgetB.left : widgetB.top;

      const primaryDiff = Dimension.minus(primaryA, primaryB).value;

      if (primaryDiff === 0) {
        return Dimension.minus(secondaryA, secondaryB).value;
      } else {
        return primaryDiff;
      }
    });
  } else {
    const children = parentWidget.children ?? [];
    // find original indices of selectedWidgets inside children
    const indexMap = children.reduce(
      (acc: Record<string, number>, childId, index) => {
        acc[childId] = index;
        return acc;
      },
      {},
    );
    return widgetIds.slice().sort((a, b) => {
      return indexMap[a] - indexMap[b];
    });
  }
};

const getNormalizedWidth = (
  width: Dimension<"px" | "gridUnit" | "fitContent" | "fillParent">,
  parentColumnSpace: number,
): Dimension<"gridUnit"> => {
  if (width.mode === "gridUnit") {
    return width as Dimension<"gridUnit">;
  }
  return Dimension.gridUnit(
    Dimension.toGridUnit(width, parentColumnSpace).roundUp().value,
  );
};

const MARKED_FOR_DELETION = Symbol("marked for deletion");
export const moveWidgetStackLayout = (params: MoveProps) => {
  const {
    selectedWidgetIds,
    stateWidgets,
    movePayload: { newParentId, newChildIndex },
    widgets,
    oldParent,
    stackAdjustments,
    flattenedParent,
    flattenedWidgets,
    theme,
  } = params;

  if (newChildIndex == null) {
    return;
  }

  const sortedSelectedWidgetIds = getSortedWidgetOrder(
    selectedWidgetIds,
    widgets,
    widgets[newParentId]?.layout,
    oldParent?.layout,
  );

  sortedSelectedWidgetIds.forEach((selectedWidgetId) => {
    if (oldParent.children && Array.isArray(oldParent.children)) {
      const indexOfChild = oldParent.children.indexOf(selectedWidgetId);
      if (indexOfChild > -1) {
        oldParent.children[indexOfChild] = MARKED_FOR_DELETION;
      }
    }
  });
  const newParent =
    newParentId === oldParent.widgetId
      ? oldParent
      : {
          ...widgets[newParentId],
          children: [...(widgets[newParentId].children ?? [])],
        };

  newParent?.children?.splice(newChildIndex, 0, ...sortedSelectedWidgetIds);

  sortedSelectedWidgetIds.forEach((selectedWidgetId) => {
    widgets[selectedWidgetId] = {
      ...stateWidgets[selectedWidgetId],
      parentId: newParentId,
    };
  });
  // go through parent and clear up marked for deletions
  oldParent.children = (oldParent.children ?? []).filter(
    (child) => child !== MARKED_FOR_DELETION,
  );

  const isHstack = newParent.layout === CanvasLayout.HSTACK;

  const newParentIsVStackWithStretch =
    newParent.layout === "VSTACK" &&
    (newParent.alignment || CanvasAlignment.STRETCH) ===
      CanvasAlignment.STRETCH;

  for (const child of newParent.children ?? []) {
    const childWidget = widgets[child];

    if (isHstack) {
      // apply adjustments
      const adjustment = stackAdjustments?.[childWidget?.widgetId];
      if (childWidget && childWidget.width.mode === "gridUnit" && adjustment) {
        const widthDiff = adjustment.newWidth.value - childWidget.width.value;
        updateWidgetWidths({
          widgets,
          flattenedWidgets,
          widget: childWidget,
          widthDiffGU: widthDiff,
        });
      }
    }

    // adjust vstack items
    if (
      !isHstack &&
      childWidget &&
      childWidget.type !== WidgetTypes.MODAL_WIDGET &&
      childWidget.type !== WidgetTypes.SLIDEOUT_WIDGET &&
      flattenedParent
    ) {
      // Child width could be px esp. if was in hstack
      const childWidthGridCols = Dimension.toGridUnit(
        childWidget.width,
        flattenedParent.parentColumnSpace ?? 1,
      ).raw().value;

      if (
        newParentIsVStackWithStretch ||
        childWidthGridCols >
          (flattenedParent.gridColumns ?? Number.MAX_VALUE) ||
        childWidget.width.mode === "fillParent"
      ) {
        // TODO: This could potentially use updateChildSizeModesForParentLayout from WidgetOperationsSagasUtils
        const newParentPadding =
          newParent.padding ?? getWidgetDefaultPadding(theme, newParent);

        const parentPaddingXGU = Dimension.toGridUnit(
          Padding.x(newParentPadding),
          flattenedWidgets[newParent.widgetId].parentColumnSpace,
        ).raw().value;

        const newWidthGU =
          (flattenedParent.gridColumns ?? 1) - parentPaddingXGU;

        const childWidthGU = Dimension.toGridUnit(
          childWidget.width,
          flattenedWidgets[childWidget.parentId].parentColumnSpace,
        ).raw().value;

        const widthDiffGU = newWidthGU - childWidthGU;

        updateWidgetWidths({
          widgets,
          flattenedWidgets,
          widget: childWidget,
          widthDiffGU,
        });
      }
    }
  }

  // TODO consider doing this ALWAYS.
  // We do this for hstacks because fill parent containers that get dragged into an hstack need their grid columns updated
  if (isHstack) {
    updateWidgetWidths({
      widgets,
      flattenedWidgets,
      widget: newParent,
      widthDiffGU: 0,
      rootCallOptions: {
        forceCheckChildren: true,
      },
    });
  }

  widgets[newParentId] = newParent;
  widgets[oldParent.widgetId] = oldParent;
};

export const moveWidgetFixedLayout = (params: MoveProps) => {
  const {
    selectedWidgetIds,
    stateWidgets,
    movePayload: { position, size, newParentId, offset },
    widget,
    widgets,
    oldParent,
    stackDragPositions,
    flattenedParent,
    flattenedWidgets,
  } = params;
  for (let i = 0; i < selectedWidgetIds.length; i++) {
    const selectedWidgetId = selectedWidgetIds[i];
    const originalSelectedWidget = Object.assign(
      {},
      stateWidgets[selectedWidgetId],
    );
    let selectedWidget = Object.assign({}, stateWidgets[selectedWidgetId]);
    // Get leftCol and topRow delta from the dragged widget's
    // original position to this new position. We'll use that to calculate
    // what the update should be, because other selected widgets we're moving
    // can't use the leftColumn and topRow value directly
    const leftColumnDelta = Dimension.minus(
      position.left,
      offset?.left ?? widget.left,
    ).asFirst();
    const topRowDelta = Dimension.minus(
      position.top,
      offset?.top ?? widget.top,
    ).asFirst();

    const selectedWidgetPos = getOverridePos(
      selectedWidget,
      stackDragPositions,
    );
    const updatedPosition = updateWidgetPosition(selectedWidget, {
      left: selectedWidgetPos.left
        ? Dimension.add(selectedWidgetPos.left, leftColumnDelta).asFirst()
        : leftColumnDelta,
      top: selectedWidgetPos.top
        ? Dimension.add(selectedWidgetPos.top, topRowDelta).asFirst()
        : topRowDelta,
      height: selectedWidgetPos.height ?? selectedWidget.height,
      width: selectedWidgetPos.width ?? selectedWidget.width,
    });

    // We currently only support resizing one widget at a time
    const onlyOneWidgetSelected = selectedWidgetIds.length === 1;
    if (size && onlyOneWidgetSelected) {
      updatedPosition.width = size.width;
      updatedPosition.height = size.height;
    }

    selectedWidget = { ...selectedWidget, ...updatedPosition };

    // fill parent height is not allowed inside a fixed canvas
    // TODO: This could potentially use updateChildSizeModesForParentLayout from WidgetOperationsSagasUtils
    if (selectedWidget.height.mode === "fillParent") {
      const overrideHeightValue = updatedPosition.height.value;
      selectedWidget.height = Dimension.gridUnit(overrideHeightValue);
    }
    // Replace widget with update widget props
    widgets[selectedWidgetId] = selectedWidget;

    // If the parent has changed i.e parentWidgetId is not parent.widgetId
    if (
      oldParent.widgetId !== newParentId &&
      selectedWidgetId !== newParentId
    ) {
      // Remove from the previous parent

      if (oldParent.children && Array.isArray(oldParent.children)) {
        const indexOfChild = oldParent.children.indexOf(selectedWidgetId);
        if (indexOfChild > -1) delete oldParent.children[indexOfChild];
        oldParent.children = oldParent.children.filter(Boolean);
      }

      // Add to new parent
      widgets[oldParent.widgetId] = oldParent;
      const newParent = {
        ...widgets[newParentId],
        children: widgets[newParentId].children
          ? [...(widgets[newParentId].children || []), selectedWidgetId]
          : [selectedWidgetId],
      };
      widgets[selectedWidgetId].parentId = newParentId;
      widgets[newParentId] = newParent;

      const oldWidth = originalSelectedWidget.gridColumns;
      const newWidth = getNormalizedWidth(
        selectedWidget.width,
        flattenedParent?.parentColumnSpace ?? 1,
      );
      // 1. ensure that the newly moved width has a grid unit width
      // 2. perform grid column scaling updates to children of the newly moved widget if necessary
      selectedWidget.width = Dimension.gridUnit(selectedWidget.width.value);

      if (oldWidth != null && newWidth != null) {
        // this is a bit awkward but we reset the width here so that updateWidgetWidths will know how to scale things
        selectedWidget.width = Dimension.gridUnit(oldWidth);
        const widthDiff = newWidth.value - oldWidth;
        updateWidgetWidths({
          widgets,
          flattenedWidgets,
          widget: selectedWidget,
          widthDiffGU: widthDiff,
        });
      }
    }
  }
};

export const shouldHaveRootAsParent = (type: WidgetTypes) => {
  return (
    type === WidgetTypes.MODAL_WIDGET || type === WidgetTypes.SLIDEOUT_WIDGET
  );
};

export const getPositionOffsetDuringGroup = ({
  selectedWidgetIds,
  layout = CanvasLayout.FIXED,
  flattenedWidgets,
}: {
  selectedWidgetIds: string[];
  layout?: CanvasLayout;
  flattenedWidgets: FlattenedWidgetLayoutMap;
}): GridPosition | undefined => {
  if (!layout) {
    return;
  }

  const widgetId = selectedWidgetIds[0];
  let offset: GridPosition | undefined = undefined;
  // If the container is a grid, our movement reference is the topmost widget,
  // simulating a drag and drop into a grid container
  if (!isStackLayout(layout)) {
    let left = flattenedWidgets[widgetId].left.value;
    let top = flattenedWidgets[widgetId].top.value;
    selectedWidgetIds.forEach((id) => {
      if (flattenedWidgets[id].top.value < top) {
        top = flattenedWidgets[id].top.value;
      }
      if (flattenedWidgets[id].left.value < left) {
        left = flattenedWidgets[id].left.value;
      }
    });
    offset = {
      top: Dimension.gridUnit(top),
      left: Dimension.gridUnit(left),
    };
  }

  return offset;
};
