import { PrimaryKey, Table } from "@superblocksteam/shared";
import { Space, Spin } from "antd";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useAppSelector } from "store/helpers";
import { selectDatasourceMetaById } from "store/slices/datasources";
import logger from "utils/logger";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormText } from "../FormText";
import { FormTooltip } from "../FormTooltip";

interface DynamicFormPrimaryKeyDisplayProps {
  datasourceId: string;
}

type Props = DynamicFormPrimaryKeyDisplayProps & DynamicFormItemProps;

export const DynamicFormPrimaryKeyDisplay = ({ datasourceId }: Props) => {
  const context = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(true);
  const [activePrimaryKey, setActiveKey] = useState<string[] | null>(null);

  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  const tables = useMemo(() => {
    const result: Table[] = [];
    try {
      datasourceMeta?.metadata?.dbSchema?.tables?.forEach((table: Table) => {
        result.push(table);
      });
      if (result.length > 0) {
        setIsLoading(false);
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [datasourceMeta?.metadata?.dbSchema?.tables]);

  useEffect(() => {
    const unsubscribe = context.subscribe("table", (dependencyValue) => {
      if (dependencyValue && tables) {
        const activeTable = tables.find((t) => t.name === dependencyValue);
        if (activeTable?.keys) {
          const primaryKey = activeTable.keys.find(
            (k) => k.type === "primary key",
          ) as PrimaryKey | undefined;
          if (primaryKey?.columns) {
            setActiveKey(primaryKey.columns);
            return;
          }
        }
        setActiveKey(null);
      }
    });
    return () => unsubscribe();
  }, [context, tables]);

  if (!isLoading && !context.getValue("table")) {
    return null;
  }

  return (
    <Space dir={"horizontal"}>
      <FormText>Primary key(s):</FormText>
      <FormLabel>
        {isLoading ? (
          <span>
            <Spin size="small" /> Loading metadata...
          </span>
        ) : null}
        <strong>{activePrimaryKey?.join(", ")}</strong>
        {!isLoading && !activePrimaryKey?.length && (
          <strong>
            {
              "Missing from this table. Use a different Operation to update this table."
            }
          </strong>
        )}
      </FormLabel>
      <FormTooltip
        tooltip={{
          markdownText: `Updates the table by matching the values of the primary key ${activePrimaryKey?.join(
            ", ",
          )} for every row that needs to be updated.`,
        }}
      />
    </Space>
  );
};
