import React from "react";
import {
  useParams,
  useLocation,
  Navigate,
  matchPath,
  useMatches,
} from "react-router";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  BUILDER_PAGE_URL,
  BUILDER_PAGE_TAB_API_URL,
  BUILDER_PAGE_TAB_API_ACTION_URL,
  BUILDER_PAGE_TAB_API_INPUTS_URL,
  BUILDER_PAGE_TAB_API_RESPONSE_URL,
  getApplicationPreviewURL,
  getApplicationDeployedURL,
} from "legacy/constants/routes";
import { getCurrentRoutePathWithParams } from "legacy/selectors/routeSelectors";
import { convertQueryStringToParams } from "legacy/utils/Utils";
import { useAppDispatch, useAppSelector } from "store/helpers";
import logger from "utils/logger";

// @deprecated Use BUILDER_PAGE_TAB_API_ACTION_URL instead
const DEPRECATED_APIS_EDITOR_URL = `/applications/:applicationId/page/:pageId/edit/apis`;

// @deprecated Use BUILDER_PAGE_TAB_API_ACTION_URL instead
const DEPRECATED_APIS_EDITOR_ACTION_URL = `/applications/:applicationId/page/:pageId/edit/apis/:apiId/action/:actionId`;

// @deprecated Use BUILDER_PAGE_TAB_API_INPUTS_URL instead
const DEPRECATED_APIS_EDITOR_INPUTS_URL = `/applications/:applicationId/page/:pageId/edit/apis/:apiId/inputs`;

// @deprecated Use BUILDER_PAGE_TAB_API_RESPONSE_URL instead
const DEPRECATED_APIS_EDITOR_RESPONSE_URL = `/applications/:applicationId/page/:pageId/edit/apis/:apiId/response`;

export const WidgetsEditorRedirect = () => {
  const params = useParams<Record<string, string | undefined>>();
  const location = useLocation();

  const apiRouteMatch = matchPath(
    DEPRECATED_APIS_EDITOR_URL,
    location.pathname,
  );
  const apiActionRouteMatch = matchPath(
    DEPRECATED_APIS_EDITOR_ACTION_URL,
    location.pathname,
  );
  const apiInputsRouteMatch = matchPath(
    DEPRECATED_APIS_EDITOR_INPUTS_URL,
    location.pathname,
  );
  const apiResponseRouteMatch = matchPath(
    DEPRECATED_APIS_EDITOR_RESPONSE_URL,
    location.pathname,
  );

  const queryParams = convertQueryStringToParams(location.search);

  if (apiRouteMatch) {
    return (
      <Navigate
        to={BUILDER_PAGE_TAB_API_URL(
          params.applicationId,
          params.apiId,
          params["*"],
          queryParams,
        )}
      />
    );
  }
  if (apiActionRouteMatch) {
    return (
      <Navigate
        to={BUILDER_PAGE_TAB_API_ACTION_URL(
          params.applicationId,
          params.apiId,
          params.actionId,
          params["*"],
          queryParams,
        )}
      />
    );
  }
  if (apiInputsRouteMatch) {
    return (
      <Navigate
        to={BUILDER_PAGE_TAB_API_INPUTS_URL(
          params.applicationId,
          params.apiId,
          params["*"],
          queryParams,
        )}
      />
    );
  }
  if (apiResponseRouteMatch) {
    return (
      <Navigate
        to={BUILDER_PAGE_TAB_API_RESPONSE_URL(
          params.applicationId,
          apiResponseRouteMatch.params.apiId,
          params["*"],
          queryParams,
        )}
      />
    );
  }

  return (
    <Navigate
      to={BUILDER_PAGE_URL(params.applicationId, params["*"], queryParams)}
    />
  );
};

/**
 * This component is used to catch any malformed routes and redirect them to the default app route
 * It also logs the error to data dog
 */
export const CatchRedirectEditor = () => {
  const location = useLocation();
  const matchedRoutes = useMatches();
  const dispatch = useAppDispatch();

  // log to data dog
  logger.debug(`Unsupported page url used: ${location.pathname}`);
  const lastMatch = matchedRoutes[matchedRoutes.length - 1];
  if (
    lastMatch.params.applicationId &&
    lastMatch.params["*"]?.startsWith("tab/")
  ) {
    return (
      <Navigate
        to={{
          pathname:
            BUILDER_PAGE_URL(lastMatch.params.applicationId) +
            "/sb_" +
            lastMatch.params["*"],
          search: location.search,
        }}
        replace={true}
      />
    );
  } else if (lastMatch.params.applicationId) {
    const queryParams = convertQueryStringToParams(location.search);
    return (
      <Navigate
        to={BUILDER_PAGE_URL(lastMatch.params.applicationId, "", queryParams)}
        replace={true}
      />
    );
  }

  dispatch({
    type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
    payload: {
      code: 404,
    },
  });
  return null;
};

export const WidgetsPreviewRedirect = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const previewMatch = matchPath(
    "/applications/:applicationId/pages/:pageId/preview",
    location.pathname,
  );

  const queryParams = convertQueryStringToParams(location.search);
  const currentRoute = useAppSelector(getCurrentRoutePathWithParams);

  if (previewMatch) {
    return (
      <Navigate
        to={getApplicationPreviewURL(
          previewMatch.params.applicationId,
          currentRoute,
          queryParams,
        )}
        replace={true}
      />
    );
  }
  dispatch({
    type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
    payload: {
      code: 404,
    },
  });
  return null;
};

export const WidgetsDeployedRedirect = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const deployedMatch = matchPath(
    "/applications/:applicationId/pages/:pageId",
    location.pathname,
  );

  if (deployedMatch) {
    return (
      <Navigate
        to={{
          pathname: getApplicationDeployedURL(
            deployedMatch.params.applicationId,
          ),
          search: location.search,
        }}
        replace={true}
      />
    );
  }
  dispatch({
    type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
    payload: {
      code: 404,
    },
  });
  return null;
};
