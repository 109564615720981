import { Classes, MenuItem as BaseMenuItem } from "@blueprintjs/core";
import { ItemRendererProps } from "@blueprintjs/select";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronRightIcon } from "assets/icons/common/chevron-right-dropdown.svg";
import { colors } from "styles/colors";
import { Checkbox } from "../Checkbox";

const MenuItemWrapper = styled(BaseMenuItem)`
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 32px;
  padding: 0;
  background: none !important; // to ensure that the submenu active styling is not on the parent by default

  &:hover {
    background: none !important;
  }

  &&&&.${Classes.INTENT_PRIMARY} {
    color: ${colors.ACCENT_BLUE_500};

    &&&&:hover {
      color: ${colors.ACCENT_BLUE_500};

      .menu-text-container {
        background-color: ${colors.ACCENT_BLUE_500_50};
      }
    }

    svg path {
      color: ${colors.ACCENT_BLUE_500};
      stroke: ${colors.ACCENT_BLUE_500};
    }
  }

  .${Classes.TEXT_OVERFLOW_ELLIPSIS} {
    height: 100%;
    padding: 0 6px;
    margin: 0;
  }

  .${Classes.MENU_SUBMENU_ICON} {
    display: none;
  }

  &[data-selected="true"],
  .${Classes.POPOVER_OPEN} &&&&.${Classes.INTENT_PRIMARY} {
    .menu-text-container {
      background-color: ${colors.ACCENT_BLUE_500_50};
    }
  }

  &&&&:hover:not(.${Classes.DISABLED}) {
    .menu-text-container {
      background-color: ${colors.GREY_50};
    }
  }

  .menu-text-container {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
    width: auto;
    height: 100%;
    padding: 8px 10px;
    border-radius: 4px;
    line-height: 16px;
    overflow: hidden;

    &[data-has-subtitle="true"] {
      .label-text {
        font-weight: 500;
      }

      .menu-icon {
        align-self: baseline;
      }
    }
  }

  .menu-icon {
    width: 16px;
    height: 16px;
    padding: 0;
  }

  .content-container {
    width: auto;
    flex: 1;
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label-container {
      display: flex;
      gap: 2px;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .label-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label-subtitle {
        color: ${colors.GREY_500};
        overflow: hidden;
        text-wrap: wrap;
      }
    }

    .right-label {
      color: ${colors.GREY_500};
    }
  }
`;

type BaseItem = {
  label: string;
  subtitle?: string;
  rightLabel?: string;
  icon?: React.ReactNode;
};

export function ItemRenderer<T extends BaseItem>(
  item: T,
  { modifiers, ...props }: ItemRendererProps,
  menuItemProps?: Pick<
    MenuItemProps,
    "hasCheckbox" | "isPartialCheck" | "selected"
  >,
) {
  return (
    <MenuItem
      {...props}
      onClick={props.handleClick}
      onFocus={props.handleFocus}
      label={item.label}
      subtitle={item.subtitle}
      icon={item.icon}
      rightLabel={item.rightLabel}
      disabled={modifiers.disabled}
      {...menuItemProps}
    />
  );
}

type MenuItemProps = {
  label: string;
  subtitle?: string;
  icon?: React.ReactNode;
  rightLabel?: string;
  hasCheckbox?: boolean;
  isPartialCheck?: boolean;
  dataTest?: string;
} & Omit<React.ComponentProps<typeof BaseMenuItem>, "text" | "icon">;

export function MenuItem({
  label,
  subtitle,
  icon,
  rightLabel,
  selected,
  dataTest,
  hasCheckbox = false,
  isPartialCheck = false,
  ...props
}: MenuItemProps) {
  const hasSubmenu = !!props.children;
  const hasSubtitle = !!subtitle;

  const Text = (
    <div className="menu-text-container" data-has-subtitle={hasSubtitle}>
      {hasCheckbox && !icon ? (
        <Checkbox
          checked={selected ?? false}
          partialChecked={isPartialCheck}
          style={{ width: "20px", height: "20px", padding: 0 }}
        />
      ) : null}

      {icon ? <div className="menu-icon">{icon}</div> : null}

      <div className="content-container" data-has-submenu={hasSubmenu}>
        <div className="label-container">
          <span className="label-text" title={label}>
            {label}
          </span>
          {hasSubtitle ? (
            <span className="label-subtitle">{subtitle}</span>
          ) : null}
        </div>

        {hasSubmenu ? <ChevronRightIcon width={16} height={16} /> : null}
        {rightLabel ? <span className="right-label">{rightLabel}</span> : null}
      </div>
    </div>
  );

  return (
    <MenuItemWrapper
      text={Text}
      {...props}
      data-selected={selected}
      data-test={dataTest ?? `menu-item-${label}`}
    />
  );
}
