import { UIErrorType } from "@superblocksteam/shared";
import { get, isObject } from "lodash";
import { put, takeLatest, call, select, getContext } from "redux-saga/effects";
import { flushErrors } from "legacy/actions/errorActions";
import { ApiResponse } from "legacy/api/ApiResponses";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";
import {
  ERROR_401,
  ERROR_500,
  ERROR_0,
  DEFAULT_ERROR_MESSAGE,
} from "legacy/constants/messages";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getSafeCrash } from "legacy/selectors/errorSelectors";
import { HttpError } from "store/utils/types";
import UITracing from "tracing/UITracing";
import logger, { stringifyError } from "utils/logger";
import { sendErrorUINotification } from "utils/notification";

/**
 * making with error message with action name
 *
 * @param action
 */
const getDefaultActionError = (action: string) =>
  `Incurred an error when ${action}`;

/**
 * transforn server errors to client error codes
 *
 * @param code
 */
const getErrorMessage = (code: number) => {
  switch (code) {
    case 401:
      return ERROR_401;
    case 500:
      return ERROR_500;
    case 0:
      return ERROR_0;
  }
};

/**
 * validates if response does have any errors
 *
 * @param response
 * @param show
 */
export function* validateResponse(response: ApiResponse | any, show = true) {
  if (!response) {
    throw Error("Empty response to validate");
  }
  if (!response.responseMeta && !response.status) {
    throw Error(getErrorMessage(0));
  }
  if (!response.responseMeta && response.status) {
    throw Error(getErrorMessage(response.status));
  }
  if (response.responseMeta.success) {
    return true;
  } else {
    yield put({
      type: ReduxActionErrorTypes.API_ERROR,
      payload: {
        error: response.responseMeta.error,
        show,
      },
    });
    throw new HttpError(
      response.responseMeta.error.code,
      false,
      response.responseMeta.error.message,
    );
  }
}

type ErrorPayloadType = {
  code?: number | string;
  message?: string;
  crash?: boolean;
};
const ActionErrorDisplayMap: {
  [key: string]: (error: ErrorPayloadType) => string;
} = {
  [ReduxActionErrorTypes.API_ERROR]: (error) =>
    get(error, "message", DEFAULT_ERROR_MESSAGE),
  [ReduxActionErrorTypes.FETCH_PAGE_ERROR]: () =>
    getDefaultActionError("fetching the page"),
  [ReduxActionErrorTypes.SAVE_PAGE_ERROR]: () =>
    getDefaultActionError("saving the page"),
};

const getErrorMessageFromActionType = (
  type: string,
  error: ErrorPayloadType,
): string => {
  const actionErrorMessage = get(error, "message");
  if (actionErrorMessage === undefined) {
    if (type in ActionErrorDisplayMap) {
      return ActionErrorDisplayMap[type](error);
    }
    return DEFAULT_ERROR_MESSAGE;
  }
  return actionErrorMessage;
};

enum ErrorEffectTypes {
  SHOW_ALERT = "SHOW_ALERT",
  SAFE_CRASH = "SAFE_CRASH",
  LOG_ERROR = "LOG_ERROR",
}
interface ErrorActionPayload {
  error: ErrorPayloadType;
  show?: boolean;
  crash?: boolean;
  spanId?: string;
}

function* errorSaga(errorAction: ReduxAction<ErrorActionPayload>) {
  const effects = [ErrorEffectTypes.LOG_ERROR];
  const { type, payload } = errorAction;
  const { show = false, error, spanId } = payload || {};
  const message = getErrorMessageFromActionType(type, error);
  const appMode: APP_MODE = yield select(getAppMode);
  error?.message &&
    UITracing.addEvent(
      spanId,
      error.message,
      error?.crash ? UIErrorType.CRASH_APP_ERROR : UIErrorType.VALIDATION_ERROR,
    );

  if (show && appMode === APP_MODE.EDIT) {
    effects.push(ErrorEffectTypes.SHOW_ALERT);
  }

  if (error && error.crash) {
    logger.error(
      `Page crashed, error:
${stringifyError(error)}`,
      {
        superblocks_ui_error_type: UIErrorType.CRASH_APP_ERROR,
      },
    );
    effects.push(ErrorEffectTypes.SAFE_CRASH);
  }
  for (const effect of effects) {
    switch (effect) {
      case ErrorEffectTypes.LOG_ERROR: {
        logErrorSaga(errorAction);
        break;
      }
      case ErrorEffectTypes.SHOW_ALERT: {
        showAlertAboutError(message);
        break;
      }
      case ErrorEffectTypes.SAFE_CRASH: {
        yield call(crashAppSaga);
        break;
      }
    }
  }

  yield put({
    type: ReduxActionTypes.REPORT_ERROR,
    payload: {
      source: errorAction.type,
      message,
    },
  });
}

function logErrorSaga(action: ReduxAction<{ error: ErrorPayloadType }>) {
  if (action.payload?.error) {
    if (isObject(action.payload.error)) {
      logger.error(
        `Error in action ${action.type} ${action.payload.error.message}`,
      );
    } else {
      logger.error(`Error in action ${action.type} ${action.payload.error}`);
    }
  } else {
    logger.debug(`Error in action ${action.type}`);
  }
}

function showAlertAboutError(message: string) {
  sendErrorUINotification({ message });
}

function* crashAppSaga() {
  yield put({
    type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS,
  });
}

/**
 * this saga do some logic before actually setting safeCrash to true
 */
function* safeCrashSagaRequest(
  action: ReduxAction<{ code?: string; reason?: string }>,
) {
  const code = get(action, "payload.code");
  const reason = get(action, "payload.reason");
  // if there is no action to be done, just calling the safe crash action
  yield put({
    type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS,
    payload: {
      code,
      reason,
    },
  });
}

/**
 * flush errors and redirect users to a url
 *
 * @param action
 */
function* flushErrorsAndRedirectSaga(
  action: ReduxAction<{ url?: string }>,
): Generator<any, any, any> {
  const safeCrash = yield select(getSafeCrash);

  if (safeCrash) {
    yield put(flushErrors());
  }

  const navigate = yield getContext("navigate");
  navigate(action.payload.url);
}

export default function* errorSagas() {
  yield takeLatest(Object.values(ReduxActionErrorTypes), errorSaga);
  yield takeLatest(
    ReduxActionTypes.FLUSH_AND_REDIRECT,
    flushErrorsAndRedirectSaga,
  );
  yield takeLatest(
    ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
    safeCrashSagaRequest,
  );
}
