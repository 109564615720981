import { CheckIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { colors } from "styles/colors";

const StyledDropdown = styled(Select.Root)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4;
  padding: 0 15px;
  line-height: 1.3;
  height: 35;
  gap: 5;
  background-color: white;
  color: black;
`;

const StyledContent = styled(Select.Content)<{ heavy: boolean }>`
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  font-size: ${(props) => (props.heavy ? "14px" : "12px")};
  font-weight: ${(props) => (props.heavy ? 500 : 400)};
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 9999;
`;

const StyledViewport = styled(Select.Viewport)`
  padding: 5px;
  max-height: 340px;
`;

const StyledItem = styled(Select.Item)`
  all: unset;
  line-height: 35px;
  color: black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;

  &.--no-checkmark {
    padding: 0 15px;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.ACCENT_BLUE_500_25};
  }
  &[data-state="checked"] {
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
`;

const StyledTrigger = styled(Select.SelectTrigger)<{ heavy: boolean }>`
  font-size: ${(props) => (props.heavy ? "14px" : "12px")};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 7px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  border: 1px solid white;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.GREY_200};
  }
`;

const StyledSeparator = styled(Select.Separator)`
  height: 1px;
  margin: 5px;
  background-color: ${({ theme }) => theme.colors.GREY_200};
`;

const StyledItemIndicator = styled(Select.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
`;

const SelectTrigger = StyledTrigger;
const SelectValue = Select.Value;
const SelectIcon = Select.Icon;
const SelectContent = StyledContent;
const SelectViewport = StyledViewport;
const SelectSeparator = StyledSeparator;
const SelectGroup = Select.Group;
const SelectItemIndicator = StyledItemIndicator;

const SelectItemText = Select.ItemText;

type DropdownOption = {
  key: string;
  value: string;
  dataTest?: string;
  groupId?: string;
  text: string;
};

type Props = {
  defaultValue?: string;
  value: string;
  onValueChange: (value: any) => void;
  options: DropdownOption[];
  heavyStyle?: boolean;
  dataTest?: string;
  triggerComponent?: React.ReactNode;
  position?: "item-aligned" | "popper";
  hideSelectedCheckmark?: boolean;
  contentClassName?: string;
};

const DropdownButtonMinimal = ({
  defaultValue,
  value,
  onValueChange,
  options,
  heavyStyle = false,
  dataTest,
  triggerComponent,
  position = "item-aligned",
  hideSelectedCheckmark,
  contentClassName,
}: Props) => {
  const groupedOptions = useMemo(() => {
    return options.reduce(
      (groupedOptions, option) => {
        const groupKey = option.groupId ?? "undefined";
        if (!groupedOptions[groupKey]) {
          groupedOptions[groupKey] = [];
        }
        groupedOptions[groupKey].push(option);
        return groupedOptions;
      },
      {} as Record<string, DropdownOption[]>,
    );
  }, [options]);

  return (
    <StyledDropdown
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      {triggerComponent ? (
        triggerComponent
      ) : (
        <SelectTrigger heavy={heavyStyle} data-test={dataTest}>
          <SelectValue />
          <SelectIcon>
            <ChevronDown color={colors.GREY_300} />
          </SelectIcon>
        </SelectTrigger>
      )}
      <Select.Portal>
        <SelectContent
          heavy={heavyStyle}
          position={position}
          className={contentClassName}
          sideOffset={5}
          align="end"
          avoidCollisions
        >
          <SelectViewport>
            {Object.entries(groupedOptions).map(([groupId, options], i) => (
              <>
                {i > 0 && <SelectSeparator />}
                <SelectGroup key={groupId}>
                  {options.map((option) => (
                    <StyledItem
                      data-test={option.dataTest}
                      key={option.key}
                      value={option.value}
                      className={hideSelectedCheckmark ? "--no-checkmark" : ""}
                    >
                      <SelectItemText>{option.text}</SelectItemText>
                      {!hideSelectedCheckmark && (
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                      )}
                    </StyledItem>
                  ))}
                </SelectGroup>
              </>
            ))}
          </SelectViewport>
        </SelectContent>
      </Select.Portal>
    </StyledDropdown>
  );
};

export default DropdownButtonMinimal;
