import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import { openEditorTab } from "legacy/actions/editorPreferencesActions";
import { ItemKindToEditorTabType } from "legacy/constants/EditorPreferencesConstants";
import { BuilderRouteParams, EditorRoute } from "legacy/constants/routes";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { useAppDispatch } from "store/helpers";

const useExpandEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<BuilderRouteParams>();
  const dispatch = useAppDispatch();
  const getEditorPath = useGetEditorPath();

  const expandEditor = useCallback(
    ({
      entityId,
      property,
      itemKind,
    }: {
      entityId: string;
      property: string;
      itemKind: ItemKinds;
    }) => {
      const entityType = ItemKindToEditorTabType[itemKind];

      if (!entityType) return;

      // Open the tab and navigate to it
      dispatch(
        openEditorTab({
          tabType: entityType,
          entityId,
          property,
        }),
      );
      const encodedProperty = encodeURIComponent(property);
      navigate({
        pathname: getEditorPath(EditorRoute.EditEntityProperty, {
          applicationId: params.applicationId,
          entityId: entityId,
          property: encodedProperty,
          entityType,
        }),
        search: location.search,
      });
    },
    [dispatch, navigate, getEditorPath, params.applicationId, location.search],
  );

  return expandEditor;
};

export default useExpandEditor;
