import { TemplateResponseDto } from "@superblocksteam/shared";
import { callServer, HttpMethod } from "../../utils/client";

export function getTemplateByPlugin(pluginId: string) {
  return callServer<TemplateResponseDto[]>({
    method: HttpMethod.Get,
    url: "v1/templateInstance/:pluginId",
    params: { pluginId },
  });
}

export function createAppFromTemplate(
  templateInstanceId: string,
  applicationName: string,
  folderId: string,
  integrationId: string,
) {
  return callServer<any>({
    method: HttpMethod.Post,
    url: "v2/applications/template",
    body: { templateInstanceId, applicationName, folderId, integrationId },
  });
}
