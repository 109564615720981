import clarinet from "clarinet";
import { set } from "lodash";
import { fastClone } from "utils/clone";

export class JsonStreamParser {
  parser;
  currentPath: Array<string | number> = [];
  currJsonObject: any;
  onChunkParsed: (jsonObject: any) => void;

  constructor(onChunkParsed: (jsonObject: Record<string, unknown>) => void) {
    this.onChunkParsed = onChunkParsed;
    this.parser = clarinet.parser();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.parser.onerror = function (e) {
      console.log("Error encountered in JSON stream: ", e);
      this.resume();
    };
    this.parser.onvalue = function (v) {
      set(self.currJsonObject, self.currentPath, v);
      const key = self.currentPath.pop();
      if (typeof key === "number") {
        self.currentPath.push(key + 1);
      }
    };
    // got some value.  v is the value. can be string, double, bool, or null.
    this.parser.onopenobject = function (key) {
      // opened an object. key is the first key.
      if (!self.currJsonObject) {
        self.currJsonObject = {};
      } else {
        set(self.currJsonObject, self.currentPath, {});
      }
      self.currentPath.push(key);
    };
    this.parser.onkey = function (key) {
      // got a subsequent key in an object.
      self.currentPath.push(key);
    };
    this.parser.oncloseobject = function () {
      // closed an object.
      const key = self.currentPath.pop();
      if (typeof key === "number") {
        self.currentPath.push(key + 1);
      }
    };
    this.parser.onopenarray = function () {
      // opened an array.
      if (!self.currJsonObject) {
        self.currJsonObject = [];
      } else {
        set(self.currJsonObject, self.currentPath, []);
      }
      self.currentPath.push(0); //????
    };
    this.parser.onclosearray = function () {
      // closed an array.
      self.currentPath.pop(); // pop the array index
      self.currentPath.pop(); // pop the array key
    };
    this.parser.onend = function () {
      // parser stream is done, and ready to have more stuff written to it.
    };
  }

  addChunk(chunk: string) {
    // parse the json string
    try {
      this.parser.write(chunk);
      const parsed = fastClone(this.currJsonObject);
      this.onChunkParsed(parsed);
    } catch (e) {
      console.log("error", e);
    }
  }

  reset() {
    this.parser.close();
    this.currentPath = [];
  }
}
