/** use the monotonic clock (performance.now()) to track the time of the next invocation */
export type Timestamp = number;

function now(): Timestamp {
  return performance.now();
}

/**
 * Returns the number of milliseconds until the given time.
 * @param timestamp The target time
 */
export function timeRemaining(timestamp: Timestamp): number {
  return timestamp - now();
}

/**
 * A slightly more advanced version of `setInterval`.
 * Differences from `setInterval`:
 * * Supports querying the time left.
 * * The order of arguments is the inverse `setInterval` for greater ergonomics (yay!)
 * * No drift.
 */
export class Interval {
  private readonly handler: () => void;
  private readonly intervalDuration: number;
  private readonly startTimeTs: Timestamp;
  private nextInvocationTs: Timestamp;
  /** `undefined` means the timer is not running */
  private timeoutId?: ReturnType<typeof setTimeout>;

  constructor(intervalDuration: number, handler: () => void) {
    this.intervalDuration = intervalDuration;
    this.handler = handler;
    this.startTimeTs = now();
    this.nextInvocationTs = this.startTimeTs; // will be set properly by schedule
    this.schedule();
  }

  private schedule() {
    // use a consistent value of now() throughout this function call
    const nowTs = now();

    // calculation to prevent drift
    const c = Math.max(
      1, // make sure that c >= 1 so that the timer first fires at least 1 intervalDuration after it was started
      Math.ceil((nowTs - this.startTimeTs) / this.intervalDuration),
    );
    let nextTs = this.startTimeTs + c * this.intervalDuration;
    if (nextTs < this.nextInvocationTs) {
      // in case of rounding errors
      nextTs += this.intervalDuration;
    }
    this.nextInvocationTs = nextTs;

    this.timeoutId = setTimeout(() => {
      this.schedule();
      this.handler.call(undefined);
    }, nextTs - nowTs);
  }

  stop() {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
      this.nextInvocationTs = Infinity;
    }
  }

  get nextInvocation() {
    return this.nextInvocationTs;
  }
}
