export const ERROR_MESSAGE_RESET_APPLICATION =
  "We could not reset the Application to a requested version";
export const ENTER_VIDEO_URL = "Please provide a valid URL";

export const ERROR_500 =
  "Whoops, something went wrong. This is unexpected, and we'll look into this.";
export const ERROR_0 =
  "We could not connect to our servers. Please check your network connection";
export const ERROR_401 =
  "We are unable to verify your identity. Please login again.";
export const WIDGET_TYPE_VALIDATION_ERROR =
  "Value does not match Required Data Format";
export const WIDGET_CIRCULAR_DEPENDENCY_ERROR =
  "This property is part of a circular dependency and cannot be evaluated";

export const DELETING_APPLICATION = "Deleting application...";
export const DUPLICATING_APPLICATION = "Cloning application...";

export const SERVER_API_TIMEOUT_ERROR =
  "Oops, Server timed out. Our team is on it, sorry for the inconvenience!";
export const DEFAULT_ERROR_MESSAGE = "There was an unexpected error";

export const LOCAL_STORAGE_QUOTA_EXCEEDED_MESSAGE =
  "Error saving a key in localStorage. You have exceeded the allowed storage size limit";
export const LOCAL_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE =
  "Error saving a key in localStorage. You have run out of disk space";

export const SESSION_STORAGE_QUOTA_EXCEEDED_MESSAGE =
  "Error saving a key in sessionStorage. You have exceeded the allowed storage size limit";
export const SESSION_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE =
  "Error saving a key in sessionStorage. You have run out of disk space";
