import { Collapse } from "antd";
import styled from "styled-components";

const HiddenCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    margin-top: 24px;
    padding: 12px 0 !important;
    align-items: center;
    height: 50px;

    & > div:first-child {
      height: 20px;
      span {
        font-size: 20px;
      }
    }

    .ant-typography {
      margin: 0;
    }
  }
`;

export default HiddenCollapse;
