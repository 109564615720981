import { noop } from "lodash";
import { createContext } from "react";

type LayoutContextType = {
  collapseBottomPanel: () => void;
  expandBottomPanel: () => void;
};

export const LayoutContext = createContext<LayoutContextType>({
  collapseBottomPanel: noop,
  expandBottomPanel: noop,
});
