import { Tabs } from "antd";
import styled from "styled-components";

const StyledTabs = styled(Tabs)<{ $padding?: string }>`
  overflow: initial;
  & .ant-tabs-tab {
    padding: 6px 12px;
    height: 32px;
    z-index: 2;
    .ant-tabs-tab-btn {
      font-weight: 500;
      font-size: 12px;
      color: ${(props) => props.theme.colors.GREY_900};
      .ant-badge {
        margin-right: -9px;
        .ant-badge-count {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.GREY_900};
  }
  & .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.GREY_400};
  }
  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 8px;
  }
  & > .ant-tabs-nav {
    padding: ${(props) => (props.$padding ? props.$padding : "12px 0")};
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${(props) => props.theme.colors.WHITE};
    &::before {
      border-bottom: none;
    }
    .ant-tabs-nav-list {
      background-color: ${(props) => props.theme.colors.GREY_50};
      border-radius: 50px;
      padding: 0 5px;
    }
    .ant-tabs-ink-bar {
      height: 24px;
      margin-bottom: 4px;
      border-radius: 12px;
      box-shadow: 0px 1px 3px rgba(27, 30, 34, 0.12);
      background: ${(props) => props.theme.colors.WHITE};
      z-index: 1;
    }
  }
`;
export default StyledTabs;
