import { Graph } from "@dagrejs/graphlib";
import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { EvaluationsState } from "legacy/reducers/evaluationReducers";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { isTimer, isWidget } from "legacy/workers/evaluationUtils";
import { ApiV2State } from "store/slices/apisV2/slice";
import getApiExecuteOnPageLoad from "store/slices/apisV2/utils/get-api-execute-on-pageload";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import {
  getGraphIdFromDataTreeEntity,
  getGraphIdFromWidgetId,
} from "../entityUtils";
import {
  ReferenceEdgeType,
  ReferenceGraph,
  UsageGraph,
  UsageNodeState,
} from "../types";
import { UsageContext } from "./UsageContext";
import { getEntityUsageEdges } from "./entityUsage";

/**
 *  Core assumptions of the usage graph:
 *  1. The reference graph will not be modified once this is constructed
 *  2. whether or not a node is used never affects whether or not predecessors are used
 *    Example. Button1 has an onClick that affects Modal1. In the reference graph, Modal1 is a successor of Button1.
 *    Whether or not Modal1 is used should not affect whether or not Button1 is used. Only the reverse is true.
 */
export class UsageGraphBuilder {
  static SYNC_TRAVERSAL_LIMIT = 1000;
  static MAX_TRAVERSAL_LIMIT = 1e6;

  private graph: UsageGraph = new Graph({
    directed: true,
    multigraph: true,
  }) as UsageGraph;
  private canvasWidgets: CanvasWidgetsReduxState;
  private evaluationsState: EvaluationsState;
  private referenceGraph: ReferenceGraph;
  private apis: ApiV2State["entities"];
  private usageContext: UsageContext;

  // these nodes require traversal - nodes that are known to be used should not be placed in this set
  private dirtyNodes: Set<string> = new Set();

  constructor(params: {
    canvasWidgets: CanvasWidgetsReduxState;
    evaluationsState: EvaluationsState;
    referenceGraph: ReferenceGraph;
    apis: ApiV2State["entities"];
  }) {
    this.canvasWidgets = params.canvasWidgets;
    this.evaluationsState = params.evaluationsState;
    this.referenceGraph = params.referenceGraph;
    this.apis = params.apis;
    this.usageContext = new UsageContext({
      usageGraph: this.graph,
      referenceGraph: this.referenceGraph,
      canvasWidgets: this.canvasWidgets,
      apis: this.apis,
    });
  }

  private populateNodes() {
    this.referenceGraph.nodes().forEach((nodeId) => {
      const dtEntity = this.referenceGraph.node(nodeId);
      this.graph.setNode(nodeId, {
        isUsed: false,
        entity: dtEntity,
      } satisfies UsageNodeState);
    });
  }

  // Core assumption: whether or not a node is used never affects whether or not predecessors are used
  private markNodeAsUsed(nodeId: string, usageExplanation?: string) {
    const node = this.graph.node(nodeId);
    if (!node) {
      throw new Error(`Node not found: ${nodeId}`);
    }
    if (node.isUsed) {
      return;
    }
    node.isUsed = true;
    node.usageExplanation = usageExplanation;

    const processSuccessorNode = (successorId: string) => {
      const successorNode = this.graph.node(successorId);
      if (!successorNode) {
        throw new Error(`Node not found: ${successorId}`);
      }
      if (!successorNode.isUsed) {
        this.dirtyNodes.add(successorId);
      }

      // Special handling for widgets where there usage can be dependent on not just siblings
      // currently this only applies to tab widgets
      // TODO this needs testing
      const entity = successorNode.entity;
      if (entity && isWidget(entity)) {
        const widgetType = entity.type;
        if (widgetType === WidgetTypes.TABS_WIDGET) {
          const tabChildrenNodeIds = (entity.children ?? []).map((child) => {
            const childWidgetId =
              typeof child === "string" ? child : child.widgetId;
            return getGraphIdFromWidgetId(this.canvasWidgets, childWidgetId);
          });
          tabChildrenNodeIds.forEach((childNodeId) => {
            if (childNodeId) {
              processSuccessorNode(childNodeId);
            }
          });
        }
      }
    };

    // mark all subsequent nodes in the reference graph that haven't been marked used as dirty
    this.referenceGraph.successors(nodeId)?.forEach((siblingId) => {
      processSuccessorNode(siblingId);
    });
  }

  private markGloballyUsedNodes() {
    // the page is always used
    const pageNodeId = getGraphIdFromWidgetId(
      this.canvasWidgets,
      PAGE_WIDGET_ID,
    );
    if (!pageNodeId) {
      throw new Error("Page node not found");
    }
    this.markNodeAsUsed(
      pageNodeId,
      "the page component is always considered used",
    );

    // explicit page-load APIs are always used
    Object.entries(this.apis).forEach(([apiId, api]) => {
      const apiName = getV2ApiName(api);
      const apiNodeId = `${ApplicationScope.PAGE}.${apiName}`;
      if (getApiExecuteOnPageLoad(api.apiPb) === true) {
        this.markNodeAsUsed(
          apiNodeId,
          `this API is configured to always execute on page load`,
        );
      }
    });

    // app scope entities are always used (more specifically, it's out of scope to determine if another page uses it)
    Object.entries(this.evaluationsState.tree[ApplicationScope.APP]).forEach(
      ([key, entity]) => {
        const nodeId = getGraphIdFromDataTreeEntity(
          ApplicationScope.APP,
          key,
          entity,
        );
        if (!nodeId) {
          console.error(`Could not resolve node for ${key}`);
          return;
        }
        this.markNodeAsUsed(
          nodeId,
          `app scope entities are always considered used`,
        );
      },
    );
  }

  private markInitialUnusedDirtyNodes() {
    // mark initial nodes that are not necessarily used but might need checking. Currently only Timer nodes
    this.referenceGraph.nodes().forEach((nodeId) => {
      const entity = this.referenceGraph.node(nodeId);
      const existingNode = this.graph.node(nodeId);
      if (entity && isTimer(entity) && !existingNode?.isUsed) {
        this.dirtyNodes.add(nodeId);
      }
    });
  }

  /**
   *  Check if the node is used.
   *  This can be run multiple times on the same node, but this should only ever return true once per node.
   */
  private checkIfNodeIsUsed(nodeId: string) {
    const node = this.graph.node(nodeId);

    if (!node) {
      throw new Error(`Node not found: ${nodeId}`);
    }
    if (node.isUsed === true) {
      // CH: changed this to a logging error. It would throw on used state vars marked as used for EG-22172 above.
      // throw new Error(
      //   `Illegal check, node ${nodeId} is already marked as used`,
      // );
      console.error(`Illegal check, node ${nodeId} is already marked as used`);
      return true;
    }
    const usageEdges = getEntityUsageEdges(nodeId, this.usageContext);
    const isUsed = usageEdges.length > 0;
    usageEdges.forEach((edge) => {
      const { usageSourceNodeId, edgeType } = edge;
      this.graph.setEdge(usageSourceNodeId, nodeId, undefined, edgeType);
    });
    return isUsed;
  }

  private numTotalIterations = 0;
  public getNumTotalIterations() {
    return this.numTotalIterations;
  }

  private async traverseUntilDone() {
    // for now, not in a worker, so we do this to avoid locking up the main thread
    const syncTraversalBlock = () => {
      let numSyncIterations = 0;
      while (this.dirtyNodes.size > 0) {
        const nodeId = this.dirtyNodes.values().next().value;
        if (nodeId == null) {
          console.error("Dirty note iterator returned undefined");
          return;
        }
        const nodeIsUsed = this.checkIfNodeIsUsed(nodeId);
        if (nodeIsUsed) {
          this.markNodeAsUsed(nodeId);
        }
        this.dirtyNodes.delete(nodeId);
        this.numTotalIterations++;
        numSyncIterations++;

        if (numSyncIterations > UsageGraphBuilder.SYNC_TRAVERSAL_LIMIT) {
          return;
        }
      }
    };

    while (this.dirtyNodes.size > 0) {
      await syncTraversalBlock();
      if (this.numTotalIterations > UsageGraphBuilder.MAX_TRAVERSAL_LIMIT) {
        throw new Error("Too many traversals");
      }
    }
  }

  // by default, all nodes that are not used are prunable, except for widgets that are parents of used widgets
  private markPrunableNodes() {
    // first, set all nodes that are unused as prunable
    // place all used nodes into a stack
    const toVisit: string[] = [];
    this.graph.nodes().forEach((nodeId) => {
      const node = this.graph.node(nodeId);
      if (node) {
        if (node.isUsed) {
          node.canPrune = false;
          toVisit.push(nodeId);
        } else {
          node.canPrune = true;
        }
      }
    });

    const climbParentTreeOfUsedNode = (nodeId: string) => {
      const parentIds = this.referenceGraph.predecessors(nodeId);
      const actualParent = parentIds?.find((parentId) => {
        const hasParentEdge = this.referenceGraph.hasEdge(
          parentId,
          nodeId,
          ReferenceEdgeType.PARENT,
        );
        return hasParentEdge;
      });
      if (!actualParent) {
        return;
      }
      const parentNode = actualParent ? this.graph.node(actualParent) : null;
      // if parent node is already marked as not prunable, we don't need to continue traversal
      if (parentNode?.canPrune === false) {
        return;
      }
      if (parentNode) {
        parentNode.canPrune = false;
        toVisit.push(actualParent);
      }
    };

    let currentNodeId = toVisit.pop();
    while (currentNodeId) {
      climbParentTreeOfUsedNode(currentNodeId);
      currentNodeId = toVisit.pop();
    }
  }

  public async buildGraph() {
    this.populateNodes();
    this.markGloballyUsedNodes();
    this.markInitialUnusedDirtyNodes();
    await this.traverseUntilDone();

    this.markPrunableNodes();

    return this.graph;
  }
}
