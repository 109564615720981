import React, { JSXElementConstructor } from "react";
import { ReactComponent as LocalStorageIcon } from "assets/icons/stateVars/local_storage.svg";
import { ReactComponent as TemporaryIcon } from "assets/icons/stateVars/temporary.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";
import { AppStateVarPersistance } from "store/slices/application/stateVars/StateConstants";

/* eslint-disable react/display-name */

export const getStateVarIcon = (
  persistance: AppStateVarPersistance,
): JSXElementConstructor<IconProps> => {
  switch (persistance) {
    case AppStateVarPersistance.LOCAL_STORAGE:
      return (props: IconProps) => (
        <IconWrapper {...props}>
          <LocalStorageIcon />
        </IconWrapper>
      );

    case AppStateVarPersistance.TEMPORARY:
      return (props: IconProps) => (
        <IconWrapper {...props}>
          <TemporaryIcon />
        </IconWrapper>
      );
  }
};
