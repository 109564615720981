import { call } from "redux-saga/effects";
import { WidgetAddChild } from "legacy/actions/pageActions";
import { buildView } from "legacy/utils/WidgetPropsUtils";
import { WidgetProps } from "legacy/widgets";
import type { WidgetBlueprint } from "legacy/mockResponses/WidgetConfigResponse";

export function* buildWidgetBlueprint(
  blueprint: WidgetBlueprint,
  widgetId: string,
  parent: WidgetProps,
): Generator<any, WidgetAddChild[], any> {
  const widgetProps = yield call(buildView, blueprint.view, widgetId, parent);
  return widgetProps;
}
