import { DataNode, TreeProps } from "antd/lib/tree";
import React, { Key, useCallback, useState } from "react";

// this is not imported at runtime, only compile-time
type NodeType = DataNode | import("rc-tree").BasicDataNode;

export function useTreeTitleExpand(
  initialKeys?: string[],
): [
  (string | number | Key)[],
  Required<TreeProps>["onExpand"],
  (node: NodeType) => React.ReactNode,
] {
  const [expandedKeys, setExpandedKeys] = useState<(string | number | Key)[]>(
    initialKeys || [],
  );

  const handleExpand = useCallback<Required<TreeProps>["onExpand"]>(
    (expandedKeys, { node }) => {
      if (node.children?.length) {
        setExpandedKeys((expandedKeys) =>
          expandedKeys.includes(node.key.toString())
            ? expandedKeys.filter((key) => key !== node.key.toString())
            : [...expandedKeys, node.key.toString()],
        );
      }
    },
    [],
  );

  const titleRender = useCallback(
    (node: NodeType): React.ReactNode =>
      "key" in node ? (
        <span
          onClick={() =>
            setExpandedKeys((expandedKeys) =>
              expandedKeys.includes(node.key)
                ? expandedKeys.filter((key) => key !== node.key)
                : [...expandedKeys, node.key],
            )
          }
        >
          {typeof node.title === "function" ? node.title(node) : node.title}
        </span>
      ) : null,
    [],
  );

  return [expandedKeys, handleExpand, titleRender];
}
