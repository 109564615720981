import { Input } from "antd";
import styled from "styled-components";

const DisplayRequestURL = styled(Input)`
  .ant-input-group-addon:first-child {
    width: 100px;
  }
  strong {
    color: ${(props) => props.theme.palettes.primary[4]};
  }
  .ant-input,
  .ant-input[disabled] {
    cursor: default;
  }
`;

export default DisplayRequestURL;
