import { getDataType } from "./getDataType";
import { TypeDef, TypeInfo, TypeInfoDocType } from "./types";

function functionHasParams(signature?: string): boolean {
  if (!signature) {
    return false;
  }

  const cleanSignature = signature.replace(/\s/g, "");
  const paramsSection = cleanSignature.match(/\(.*\)/);
  if (paramsSection) {
    const params = paramsSection[0].slice(1, -1);
    // Check if there are any non-optional parameters
    return params.length > 0 && !/^\[.*\]$/.test(params);
  }

  // If there's no parameter section at all, assume no parameters are needed
  return false;
}

type NoBangTypeInfo = Record<string, unknown> & {
  doc?: TypeInfoDocType;
  type?: TypeDef;
};

function functionInfo(info: TypeInfo | NoBangTypeInfo): {
  isFunction: boolean;
  hasParams: boolean;
} {
  const doc = (info["!doc"] ?? info.doc) as TypeInfoDocType;
  const type = (info["!type"] ?? info.type) as TypeDef;

  const typeS = typeof type === "string" ? type : undefined;
  const isFunction = getDataType(typeS ?? "?") === "FUNCTION";
  const hasParams = isFunction
    ? functionHasParams(doc?.expanded?.customFnDef ?? typeS)
    : false;
  return { isFunction, hasParams };
}

export { functionInfo, functionHasParams };
