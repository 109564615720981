import { IntegrationKind } from "@superblocksteam/shared";
import { Button, Modal } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { ModalWrapClass, ModalInnerWrapper } from "components/ui/Modal";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { HOME_URL } from "legacy/constants/routes";
import { INTEGRATION_URL, SECRETS_MANAGEMENT_URL } from "pages/routes";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const NewAppOptionContainer = styleAsClass`
display: flex;
flex-direction:column;
border: 1px solid ${colors.GREY_100};
border-radius: 4px;
cursor: pointer;
margin-bottom: 12px;
`;

const ImgDescriptionContainer = styleAsClass`
    &[data-create-blank="false"] {
        border-color: ${colors.ACCENT_BLUE_500};
        box-shadow: 0px 0px 0px 5px ${colors.SUBTLE_BLUE};
    }
`;

const BlankAppContainer = styleAsClass`
    &[data-create-blank="true"] {
        border-color: ${colors.ACCENT_BLUE_500};
        box-shadow: 0px 0px 0px 5px ${colors.SUBTLE_BLUE};
    }
`;

const FooterWrapperSpaceBetween = styleAsClass`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
`;

const ImgContainer = styleAsClass`
padding-left: 30px;
padding-top: 10px;
padding-right: 30px; 
background-color: ${colors.GREY_50}; 
border-radius: 4px;
`;

const ImgDimension = styleAsClass`
max-height: 100%;
max-width: 100%;
height: 100%;
`;

const ItemContainer = styleAsClass`
line-height: 16px;
gap: 6px;
`;

const GrayDarkText = styleAsClass`
color: ${LegacyNamedColors.GRAY_DARK};
font-size: 12px;
`;

const Gray900Text = styleAsClass`
color: ${colors.GREY_900};
font-size: 13px;
margin-bottom: 6px;
`;

const CreateFromTemplateOptionModal = ({
  createFromTemplateOptionModalVisible,
  setCreateFromTemplateOptionModalVisible,
  setCreateAppModalVisible,
  kind,
  pluginId,
  datasourceId,
  pluginName,
  createAppModeBlank,
  setCreateAppModeBlank,
  templateDescription,
  templateCoverImgUrl,
  templateName,
}: {
  createFromTemplateOptionModalVisible: boolean;
  setCreateFromTemplateOptionModalVisible: (visible: boolean) => void;
  setCreateAppModalVisible: (visible: boolean) => void;
  kind: string;
  pluginId: string;
  datasourceId: string;
  pluginName: string | undefined;
  createAppModeBlank: boolean;
  setCreateAppModeBlank: (visible: boolean) => void;
  templateDescription: string;
  templateCoverImgUrl: string;
  templateName: string;
}) => {
  const navigate = useNavigate();

  const onGoHome = useCallback(() => {
    setCreateFromTemplateOptionModalVisible(false);
    navigate({ pathname: HOME_URL });
  }, [setCreateFromTemplateOptionModalVisible, navigate]);

  const onNext = useCallback(() => {
    setCreateFromTemplateOptionModalVisible(false);
    setCreateAppModalVisible(true);
  }, [setCreateFromTemplateOptionModalVisible, setCreateAppModalVisible]);

  const handleSelectBlank = useCallback(() => {
    setCreateAppModeBlank(true);
  }, [setCreateAppModeBlank]);

  const handleSelectTemplate = useCallback(() => {
    setCreateAppModeBlank(false);
  }, [setCreateAppModeBlank]);

  return (
    <Modal
      open={createFromTemplateOptionModalVisible}
      closable
      onCancel={() => {
        setCreateFromTemplateOptionModalVisible(false);
        setCreateAppModeBlank(false);
        navigate({
          pathname:
            kind === IntegrationKind.SECRET
              ? `${SECRETS_MANAGEMENT_URL}/${pluginId}/${datasourceId}`
              : `${INTEGRATION_URL}${pluginId}/${datasourceId}`,
        });
      }}
      wrapClassName={ModalWrapClass}
      title={`Integration Connected! Try it out`}
      footer={null}
      width={450}
    >
      <div className={ModalInnerWrapper}>
        <div
          className={`${NewAppOptionContainer} ${ImgDescriptionContainer}`}
          data-create-blank={createAppModeBlank}
          onClick={handleSelectTemplate}
        >
          <div className={ImgContainer}>
            <img src={templateCoverImgUrl} alt="" className={ImgDimension} />
          </div>
          <div style={{ padding: "12px" }}>
            <div className={`${ItemContainer} ${Gray900Text}`}>
              <b>
                Use {pluginName} {templateName} template
              </b>
            </div>
            <div className={`${ItemContainer} ${GrayDarkText}`}>
              {templateDescription}
            </div>
          </div>
        </div>
        <div
          className={`${NewAppOptionContainer} ${BlankAppContainer}`}
          data-create-blank={createAppModeBlank}
          onClick={handleSelectBlank}
        >
          <div style={{ padding: "12px" }}>
            <div className={`${ItemContainer} ${Gray900Text}`}>
              <b>Start from blank app</b>
            </div>
            <div className={`${ItemContainer} ${GrayDarkText}`}>
              {`Build your own app to display ${pluginName} data`}
            </div>
          </div>
        </div>
        <div className={FooterWrapperSpaceBetween}>
          <Button
            data-test="integration-created-go-home"
            key="gohome"
            type="default"
            onClick={onGoHome}
          >
            Go home
          </Button>
          <Button
            data-test="integration-created-next"
            key="submit"
            type="primary"
            onClick={onNext}
          >
            Next →
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFromTemplateOptionModal;
