import { produce } from "immer";
import {
  select,
  all,
  call,
  cancelled,
  put,
  race,
  take,
} from "redux-saga/effects";
import {
  isSupportedFormatter,
  SUPPORTED_FORMATTER_TYPE,
} from "code-formatting/constants";
import { getSqlDialectForSyntax } from "code-formatting/sql-formatter";
import { getSyntaxForPlugin } from "code-formatting/utils";
import FormatWorkerService from "code-formatting/worker/FormatWorkerService";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import { getSharedDeveloperPreferences } from "legacy/selectors/sagaSelectors";
import renameApplicationEntity from "store/sagas/renameApplicationEntity";
import { MutationPayloadMap } from "store/slices/apisV2/control-flow/ControlFlowMutator";
import {
  BlockType,
  GenericBlock,
  StepConfig,
} from "store/slices/apisV2/control-flow/types";
import { createSaga } from "store/utils/saga";
import { setBlockCodeFormatting } from "./actions";
import { selectCachedControlFlowById } from "./control-flow/control-flow-selectors";
import { updateV2FromControlFlowSaga } from "./sagas/updateV2FromControlFlow";
import slice from "./slice";
import { FormattingStatus } from "./types";

type Props = {
  apiId: string;
  blockName?: string;
  manualRun?: boolean;
};

const formattingService = new FormatWorkerService();

function* formatSingleStepBlock({
  apiId,
  block,
}: {
  apiId: string;
  block: GenericBlock;
}) {
  const config = block.config as StepConfig;
  const supportedFormatterType = getSupportedFormatterTypeFromPluginId(
    config.pluginId,
  );
  const syntax = getSyntaxForPlugin(config.pluginId);

  const {
    languagesToFormat,
  }: ReturnType<typeof getSharedDeveloperPreferences> = yield select(
    getSharedDeveloperPreferences,
  );

  if (
    !config.configuration.body ||
    !supportedFormatterType ||
    !languagesToFormat.includes(supportedFormatterType)
  ) {
    return;
  }

  const codeToFormat = config.configuration.body;

  // Cancel code formatting if the user is renames an entity
  // This can happen if a code takes a long time to format, or in our tests
  // renameApplicationEntity and updateV2FromControlFlowSaga, are legacy actions,
  // and not redux toolkit actions so there's more code overhead to make this work:
  yield race({
    cancelled: take(renameApplicationEntity.start.type),
    success: call(function* () {
      try {
        yield put(
          setBlockCodeFormatting.create({
            apiId,
            blockName: block.name,
            status: FormattingStatus.STARTED,
          }),
        );
        const response: Awaited<
          ReturnType<typeof formattingService.formatCode>
        > = yield call(
          [formattingService, formattingService.formatCode],
          codeToFormat,
          supportedFormatterType,
          { syntax },
        );

        if (response.type === "success") {
          if (config.configuration.body !== response.formattedCode) {
            const newBlock = produce(block, (draft) => {
              const cfg = draft.config as StepConfig;
              cfg.configuration.body = response.formattedCode;
            });

            const mutationPayload: MutationPayloadMap[keyof MutationPayloadMap] =
              {
                type: "updateBlockConfig",
                payload: {
                  updatedBlock: newBlock,
                },
              };

            const payload = {
              mutationPayload,
              apiId,
            };

            yield put(updateV2FromControlFlowSaga.apply(payload).start);
          }

          yield put(
            setBlockCodeFormatting.create({
              apiId,
              blockName: block.name,
              status: undefined,
            }),
          );
        } else {
          yield put(
            setBlockCodeFormatting.create({
              apiId,
              blockName: block.name,
              status: FormattingStatus.FAILED,
              error: response.error,
            }),
          );
        }
      } finally {
        if (yield cancelled()) {
          yield put(
            setBlockCodeFormatting.create({
              apiId,
              blockName: block.name,
              status: undefined,
            }),
          );
        }
      }
    }),
  });
}

export function* formatV2APIStepsInternal({ apiId, blockName }: Props) {
  const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select(selectCachedControlFlowById, apiId);

  const blocks = controlFlow?.blocks;
  if (!controlFlow || !blocks) {
    return;
  }

  const formattingSagas = Object.values(blocks)
    .filter(
      (block) =>
        block.type === BlockType.STEP &&
        (!blockName || block.name === blockName),
    )
    .map((block) => formatSingleStepBlock({ apiId, block }));

  yield all(formattingSagas);
}

type FormatCodeWithCancelInternalResponse =
  | {
      type: "doNothing";
    }
  | Awaited<ReturnType<typeof formattingService.formatCode>>;

function* formatCodeWithCancelInternal({
  editorMode,
  codeToFormat,
}: {
  editorMode: EditorModes;
  codeToFormat: string;
}): Generator<any, FormatCodeWithCancelInternalResponse, any> {
  const {
    languagesToFormat,
  }: ReturnType<typeof getSharedDeveloperPreferences> = yield select(
    getSharedDeveloperPreferences,
  );

  let response: FormatCodeWithCancelInternalResponse = { type: "doNothing" };

  if (
    !isSupportedFormatter(editorMode) ||
    !languagesToFormat.includes(editorMode)
  ) {
    return response;
  }

  // Cancel code formatting if the user is renames an entity
  // This can happen if a code takes a long time to format, or in our tests
  yield race({
    cancelled: take(renameApplicationEntity.start.type),
    success: call(function* () {
      try {
        response = yield call(
          [formattingService, formattingService.formatCode],
          codeToFormat,
          editorMode,
        );
        if (
          response.type === "success" &&
          codeToFormat === response.formattedCode
        ) {
          response = { type: "doNothing" };
        }
      } finally {
        if (yield cancelled()) {
          response = { type: "doNothing" };
        }
      }
    }),
  });

  return response;
}

export const formatCodeWithCancel = createSaga(
  formatCodeWithCancelInternal,
  "formatCodeWithCancel",
);

const formatV2ApiStepsSaga = createSaga(
  formatV2APIStepsInternal,
  "formatV2APIStepsSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => payload.apiId,
  },
);

const getSupportedFormatterTypeFromPluginId = (
  pluginId?: string,
): SUPPORTED_FORMATTER_TYPE | undefined => {
  if (getSqlDialectForSyntax(getSyntaxForPlugin(pluginId))) {
    return EditorModes.SQL_WITH_BINDING;
  }

  switch (pluginId) {
    case "python":
      return EditorModes.PYTHON;
    case "javascript":
      return EditorModes.JAVASCRIPT;
    default:
      return undefined;
  }
};

slice.saga(formatV2ApiStepsSaga, {
  start(state, { payload }) {
    state.formatting[payload.apiId] = {
      blocks: {},
      manualRun: !!payload.manualRun,
    };
  },
  success(state, { payload, meta }) {
    delete state.formatting[meta.args.apiId];
  },
  error(state, { payload, meta }) {
    delete state.formatting[meta.args.apiId];
  },

  cancel(state, { payload }) {
    delete state.formatting[payload.apiId];
  },
});

export default formatV2ApiStepsSaga;
