import { SurveyToShow } from "@superblocksteam/shared";

type AdditionalQuestionType = "Unconditional" | "CompanyName";

export const defaultAdditionalQuestionType: AdditionalQuestionType =
  "Unconditional";

export interface AdditionalQuestion {
  prompt: string;
  placeholder?: string;
  name: string;
  type?: AdditionalQuestionType;
  options?: string[]; // if undefined, an input will be shown, otherwise a drop down with these options
}

type FormElementOption = {
  option: string;
  url?: string;
  condition?: {
    questionId: string;
    answerMatch: string;
  };
  requireAdditionalAnswer?: AdditionalQuestion;
};

export type FormElement = {
  questionId: string;
  questionDisplay: string;
  options: FormElementOption[];
  isMultiselect?: boolean;
  type: "STRING_OPTIONS";
};

export interface FormAnswers {
  version: "1.0";
  survey: SurveyToShow;
  answers: {
    questionId: string;
    selectedOptions: string[];
    additionalAnswers?: Record<string, string>;
  }[];
}
