import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Alert, Col, Form, Input, Row, Space, Typography } from "antd";
import { debounce } from "lodash";
import React from "react";
import styled from "styled-components";
import { Button } from "components/ui/Button";
import { AgentConfigStatus, AgentFormDefault } from "./common";

const { Link, Paragraph, Text } = Typography;

export const StepParagraph = styled(Text)`
  &.ant-typography-secondary {
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

type StepDefineHostProps = {
  title: string;
  handleValueChange: (_: any, allValues: any) => void;
  needPortConf: boolean;
  domain: string;
  subdomain: string;
  generateConfig: () => void;
  configStatus: AgentConfigStatus;
  showNextStep: boolean;
};

export const StepDefineHost = ({
  title,
  handleValueChange,
  needPortConf,
  domain,
  subdomain,
  generateConfig,
  configStatus,
  showNextStep,
}: StepDefineHostProps) => {
  const [form] = Form.useForm();

  if (showNextStep) {
    return (
      <Space direction="vertical">
        <StepParagraph type="secondary">
          Set the <Text strong>subdomain</Text> and <Text strong>domain</Text>{" "}
          for your agent. Superblocks will use this URL when sending API
          execution requests.
          <br />
          For more info, refer to the{" "}
          <Link
            href={"https://docs.superblocks.com/on-premise-agent/overview"}
            target="_blank"
          >
            On-premise Agent Guide
          </Link>
        </StepParagraph>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onValuesChange={debounce(handleValueChange, 500)}
          preserve={false}
          style={{ marginTop: 15 }}
        >
          <Row gutter={24}>
            <Col className="gutter-row" span={needPortConf ? 10 : 12}>
              <Form.Item
                name="subdomain"
                label={<Text strong>Subdomain</Text>}
                initialValue={subdomain}
              >
                <Input
                  addonBefore="https://"
                  placeholder={AgentFormDefault.SUBDOMAIN}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={needPortConf ? 10 : 12}>
              <Form.Item
                name="domain"
                label={<Text strong>Domain</Text>}
                initialValue={domain}
              >
                <Input placeholder={AgentFormDefault.DOMAIN} />
              </Form.Item>
            </Col>
            {needPortConf === true ? (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  name="port"
                  label={<Text strong>Port</Text>}
                  initialValue={AgentFormDefault.PORT}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
        <Space direction="horizontal">
          <Button type="primary" size="middle" onClick={generateConfig}>
            Generate config
          </Button>

          {configStatus === AgentConfigStatus.SUCCESS ? (
            <Alert
              message="Configuration generated successfully"
              type="success"
              showIcon
            />
          ) : null}
          {configStatus === AgentConfigStatus.ERROR ? (
            <Alert
              message="Oops...Seems like this domain isn't real!"
              type="error"
              showIcon
            />
          ) : null}
        </Space>
      </Space>
    );
  } else {
    return null;
  }
};

type StepHealthCheckFailedProps = {
  retry: () => void;
};

export const StepHealthCheckFailed = ({
  retry,
}: StepHealthCheckFailedProps) => {
  return (
    <Space direction="vertical" size={5}>
      <Text>
        <CheckOutlined style={{ color: "#27bbff", marginRight: "10px" }} />
        Agent registered with Superblocks
      </Text>
      <Text strong>
        <CloseOutlined style={{ color: "#ff4848", marginRight: "10px" }} />
        Browser connected to agent
      </Text>
      <Paragraph type="secondary" style={{ marginLeft: "25px" }}>
        Your browser cannot successfully reach the agent.
        <br />
        Make sure you are connected to your VPN and retry connecting.
        <br />
        See{" "}
        <Link
          href={
            "https://docs.superblocks.com/on-premise-agent/manage/troubleshooting"
          }
          target="_blank"
        >
          {" "}
          Troubleshooting OPA Connections{" "}
        </Link>
        if you continue to have issues.
      </Paragraph>
      <Button
        key="retry-health-check"
        size="large"
        type="primary"
        onClick={retry}
      >
        Retry Health Check
      </Button>
    </Space>
  );
};

export const StepHealthCheckSucceeded = () => {
  return (
    <Space direction="vertical" size={5}>
      <Text>
        <CheckOutlined style={{ color: "#27bbff", marginRight: "10px" }} />
        Agent registered with Superblocks
      </Text>
      <Text>
        <CheckOutlined style={{ color: "#27bbff", marginRight: "10px" }} />
        Browser connected to agent
      </Text>
    </Space>
  );
};
