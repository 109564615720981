import { ApiKeyScope, OrganizationTokenDto } from "@superblocksteam/shared";
import { DatePicker, Input, Modal } from "antd";
import moment, { Moment } from "moment-timezone";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ReactComponent as WarningIcon } from "assets/icons/common/system-danger.svg";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { DatePickerStyle } from "components/ui/Datepicker";
import { FormItem, FormWrapper } from "components/ui/Form";
import { FooterWrapper, ModalInnerWrapper } from "components/ui/Modal";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { useFeatureFlag } from "hooks/ui";
import { tokenTypeOptions } from "pages/Agents/common";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { HttpMethod, callServer } from "store/utils/client";
import { colors } from "styles/colors";
import TokenGeneratedModal from "./TokenGeneratedModal";
import { AccessToken, convertOrgToken } from "./constants";

type ExpirationOption = "30d" | "60d" | "90d" | "custom";

const expirationOptions: DropdownOption[] = [
  { displayName: "30 days", value: "30d", key: "30d" },
  { displayName: "60 days", value: "60d", key: "60d" },
  { displayName: "90 days", value: "90d", key: "90d" },
  { displayName: "Custom", value: "custom", key: "custom" },
];

const CreateAccessTokenModalClassName = "create-access-token-modal";

const CreateTokenModal = ({
  isModalOpen,
  setIsModalOpen,
  isOpaPage,
  setKeyCreated,
  setTokens,
  setShowNextStep,
  setCurrentStep,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isOpaPage: boolean;
  setKeyCreated?: (value: boolean) => void;
  setTokens?: React.Dispatch<React.SetStateAction<AccessToken[]>>;
  setShowNextStep?: (value: boolean) => void;
  setCurrentStep?: (value: number) => void;
}) => {
  const [nameError, setNameError] = useState<string>();
  const [error, setError] = useState<string>();
  const [newTokenName, setNewTokenName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [expirationOption, setExpirationOption] =
    useState<ExpirationOption>("90d");
  const [customExpiration, setCustomExpiration] = useState<Moment | null>(null);

  const expirationDate = useMemo(() => {
    if (expirationOption === "custom" && customExpiration) {
      return customExpiration.endOf("day").toDate();
    } else {
      switch (expirationOption) {
        case "30d":
          return moment().add(30, "days").endOf("day").toDate();
        case "60d":
          return moment().add(60, "days").endOf("day").toDate();
        case "90d":
          return moment().add(90, "days").endOf("day").toDate();
      }
    }
    return moment().add(90, "days").endOf("day").toDate();
  }, [customExpiration, expirationOption]);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewTokenName(event?.target?.value || "");
    },
    [],
  );

  const onExpirationOptionChange = useCallback((val: DropdownOption) => {
    setExpirationOption(val.value as ExpirationOption); // when user updates the value
  }, []);

  const [tokenType, setTokenType] = useState<DropdownOption | null>(null);

  const [generatedModalOpen, setGeneratedModalOpen] = useState(false);

  const orgId = useSelector(selectOnlyOrganizationId);

  useEffect(() => {
    if (isOpaPage) {
      setTokenType(tokenTypeOptions[2]);
    }
  }, [isOpaPage, setTokenType]);

  const validate = useCallback(() => {
    if (newTokenName === "") {
      setNameError("Name is required");
      return false;
    }
    if (isOpaPage) {
      setTokenType(tokenTypeOptions[2]);
    }
    if (!tokenType) {
      setError("Token type is required");
      return false;
    }
    return true;
  }, [tokenType, setTokenType, newTokenName, isOpaPage]);

  const cleanStates = useCallback(() => {
    setNewTokenName("");
    setCustomExpiration(null);
    setExpirationOption("90d");
    setError("");
    setNameError("");
  }, []);

  const onCreate = useCallback(async () => {
    if (!validate()) {
      return;
    }
    setIsLoading(true);
    try {
      const tokenCreated = await callServer<OrganizationTokenDto>(
        {
          url: `/v1/organizations/${orgId}/tokens`,
          method: HttpMethod.Post,
          body: {
            name: newTokenName,
            expiresAt: expirationDate,
            scope: tokenType?.value as ApiKeyScope,
          },
        },
        {
          notifyOnError: false,
          onError: (e) => {
            setError(e?.message ?? "Failed to create token");
          },
        },
      );
      if (tokenCreated) {
        setTokenCreated(tokenCreated?.key || "");
        setTokens?.((tokens: AccessToken[]) => [
          ...tokens,
          convertOrgToken(tokenCreated),
        ]);

        setIsModalOpen(false);
        cleanStates();
        setGeneratedModalOpen(true);
      }
    } catch (e: any) {
      setError(e?.message ?? "Failed to create token");
    } finally {
      if (isOpaPage) {
        setKeyCreated?.(true);
      }
      setShowNextStep?.(true);

      if (setCurrentStep !== undefined) {
        setCurrentStep(1);
      }
      setIsLoading(false);
    }
  }, [
    cleanStates,
    expirationDate,
    newTokenName,
    orgId,
    setIsModalOpen,
    setTokens,
    tokenType?.value,
    validate,
    isOpaPage,
    setKeyCreated,
    setShowNextStep,
    setCurrentStep,
  ]);

  const [tokenCreated, setTokenCreated] = useState<string>("");

  const onDone = useCallback(() => {
    setGeneratedModalOpen(false);
  }, []);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        validate() && onCreate();
        e.stopPropagation();
      }
    },
    [onCreate, validate],
  );

  const onCancel = useCallback(() => {
    setIsModalOpen(false);
    cleanStates();
  }, [cleanStates, setIsModalOpen]);

  const accessTokenMaxExpirationInSeconds = useFeatureFlag(
    Flag.ACCESS_TOKEN_MAX_EXPIRATION_SECONDS,
  );

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        title={"Create access token"}
        footer={null}
        destroyOnClose={true}
        wrapClassName={CreateAccessTokenModalClassName}
      >
        <div className={ModalInnerWrapper} ref={parentRef}>
          <div className={FormWrapper}>
            <FormItem label="Name" required={true} error={nameError}>
              <Input
                data-test="token-name-input"
                value={newTokenName}
                autoFocus={true}
                onChange={onNameChange}
                suffix={
                  nameError ? (
                    <WarningIcon color={colors.RED_500} />
                  ) : (
                    <div></div>
                  ) //need to add this to avoid relayout
                }
                onKeyDown={handleKeyPress}
              />
            </FormItem>
            <FormItem
              label="Expiration"
              required={true}
              subText={`This token will expire on ${moment(
                expirationDate,
              ).format("ddd, MMM DD YYYY")}.`}
            >
              <RecommendedSingleDropdown
                value={expirationOption}
                onChange={onExpirationOptionChange}
                options={expirationOptions}
                parentRef={parentRef}
              />
              {expirationOption === "custom" && (
                <DatePicker
                  className={DatePickerStyle}
                  onChange={(date) => setCustomExpiration(date)}
                  defaultValue={customExpiration ?? moment().add(90, "days")}
                  format={"MM/DD/YYYY"}
                  disabledDate={(current) =>
                    current &&
                    (current < moment().startOf("day") ||
                      current >
                        moment()
                          .add(accessTokenMaxExpirationInSeconds, "seconds")
                          .startOf("day"))
                  }
                  allowClear={false}
                />
              )}
            </FormItem>
            <FormItem label="Token type">
              <RecommendedSingleDropdown
                value={tokenType ?? undefined}
                onChange={setTokenType}
                options={tokenTypeOptions}
                disabled={isOpaPage}
                parentRef={parentRef}
                placeholder={isOpaPage ? "Agent key" : "Select token type"}
                data-test="token-type-select"
              />
            </FormItem>
            <FormItem error={error} hidden={!error} />
          </div>
          <div className={FooterWrapper}>
            <SecondaryButton
              data-test="cancel-create-token-button"
              onClick={onCancel}
              loading={isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              type="primary"
              onClick={onCreate}
              data-test="create-token-button"
              loading={isLoading}
            >
              Create
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <TokenGeneratedModal
        isNew={true}
        modalOpen={generatedModalOpen}
        token={tokenCreated}
        onDone={onDone}
      />
    </>
  );
};

export default CreateTokenModal;
