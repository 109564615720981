class TokenProvider {
  private tokenType?: "embed" | "superblocks";
  private token?: string;
  private static instance: TokenProvider;

  private constructor() {}

  setToken(token: string, tokenType: "embed" | "superblocks" = "superblocks") {
    this.token = token;
    this.tokenType = tokenType;
  }

  getToken(): string | undefined {
    return this.token;
  }

  clearToken() {
    this.token = undefined;
    this.tokenType = undefined;
  }

  getTokenType() {
    return this.tokenType;
  }

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }
}

export default TokenProvider.Instance;
