import { GroupBrief, GroupType } from "@superblocksteam/shared";
import {
  OrganizationUserDto,
  OrganizationUserStatus,
} from "@superblocksteam/shared/src/types/user";
import { Input } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DangerButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import { DeleteModal } from "components/ui/Modal";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { selectOnlyOrganization } from "store/slices/organizations";
import { Heading3 } from "styles/Typography";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deactivateUserRequest } from "./client";

const SplitRow = styleAsClass`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
`;

const DangerZone = styleAsClass`
  border: 1px solid ${colors.RED_500}14;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserInfo = ({ user }: { user: OrganizationUserDto }) => {
  const navigate = useNavigate();

  const currentOrg = useSelector(selectOnlyOrganization);
  const currentUser = useSelector(getCurrentUser);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string>();
  const allUsersGroup = currentUser?.groups.find(
    (group: GroupBrief) => group.type === GroupType.ALL_USERS,
  );

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  const onDelete = useCallback(async () => {
    if (!user.email || !allUsersGroup?.id) {
      return;
    }
    setIsDeleting(true);
    try {
      const { group, error } = await deactivateUserRequest({
        orgId: currentOrg.id,
        userEmail: user.email,
        groupId: allUsersGroup?.id,
      });

      // success
      if (group) {
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message: `User ${user.name} was deactivated`,
        });
        // go to users page
        navigate("/users");
      }

      if (error) {
        setDeleteError(error);
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to deactivate user");
    }
    setIsDeleting(false);
  }, [allUsersGroup?.id, currentOrg.id, navigate, user.email, user.name]);

  const onDeleteClick = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  return (
    <div className={FormWrapper}>
      <div className={FormWrapper}>
        <div className={SplitRow}>
          <FormItem label="Name">
            <Input value={user.name} disabled />
          </FormItem>
          <FormItem label="Email">
            <Input value={user.email} disabled />
          </FormItem>
        </div>
      </div>
      {user.status !== OrganizationUserStatus.INACTIVE && (
        <div className={FormWrapper}>
          <div className={Heading3}>Danger Zone</div>
          <div className={DangerZone}>
            {user.status === OrganizationUserStatus.ACTIVE ? (
              <FormItem
                label="Deactivate User"
                subText="The user will be deactivated and no longer have access to Superblocks."
              />
            ) : (
              <FormItem
                label="Remove invite"
                subText="The user's invite will be removed from your organization."
              />
            )}
            <DangerButton onClick={onDeleteClick}>
              {user.status === OrganizationUserStatus.ACTIVE
                ? "Deactivate"
                : "Remove invite"}
            </DangerButton>
          </div>
        </div>
      )}
      <DeleteModal
        open={deleteModalOpen}
        title={
          user.status === OrganizationUserStatus.ACTIVE
            ? "Deactivate user"
            : "Remove invite"
        }
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="user"
        confirmText={
          user.status === OrganizationUserStatus.ACTIVE
            ? "Deactivate"
            : "Remove"
        }
      >
        User <b>{user?.name}</b>
        {user?.status === OrganizationUserStatus.ACTIVE
          ? ` will be deactivated from your
        organization.`
          : `'s invite will be removed from your organization.`}
      </DeleteModal>
    </div>
  );
};

export default UserInfo;
