import {
  DropdownOption,
  GroupBrief,
  GroupType,
  OrganizationUserDto,
  RoleTypeEnum,
  UserType,
} from "@superblocksteam/shared";
import { Dropdown, Tabs } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { ReactComponent as UserGearIcon } from "assets/icons/common/user-gear.svg";
import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { SecondaryButton } from "components/ui/Button";
import { MultiFilter } from "components/ui/Filter";
import { HeaderWrapper } from "components/ui/Page";
import { RecommendedTableRef } from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  SearchInputWrapper,
  filterBySearch,
} from "components/ui/SearchSection";
import { MANAGE_USERS, VIEW_ROLES } from "constants/rbac";
import { useDebounce, useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getGroupsForOrg } from "pages/Permissions/client";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import InviteOrgUserModal from "pages/components/invitation/InviteOrgUserModal";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { useListRolesQuery } from "store/slices/reduxApi/rbac";
import { useLazyListUsersQuery } from "store/slices/reduxApi/users";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import ChangeRoleModal from "./ChangeRoleModal";
import UserList from "./UserList";
import {
  StatusType,
  UserToRender,
  convertToUserToRender,
  userCompareFn,
} from "./constants";

type UsersTabKey = "superblocks" | "embed";

const HalfRow = styleAsClass`
  display: flex;
  gap: 8px;
`;

const MenuItemWrapper = styleAsClass`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${colors.GREY_500};
  }
`;

const ChevronButtonStyle = styleAsClass`
  &.dropdown-open {
    transform: rotate(180deg);
  }
`;

const SelectedSection = styleAsClass`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 40px;
  align-items: center;
`;

const statusOptions: (DropdownOption & { value: StatusType })[] = [
  {
    displayName: "Active",
    value: "active",
    key: "active",
  },
  {
    displayName: "Deactivated",
    value: "deactivated",
    key: "desactivated",
  },
  {
    displayName: "Pending",
    value: "pending",
    key: "pending",
  },
];

const UsersPage = () => {
  const userTableRef = useRef<RecommendedTableRef<UserToRender>>(null);
  const [canManageUsers, canViewRoles] = useAuthorizationCheck([
    MANAGE_USERS,
    VIEW_ROLES,
  ]);
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS);
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [users, setUsers] = useState<UserToRender[]>([]);
  const currentOrg = useSelector(selectOnlyOrganization);
  const [usersLoading, setUsersLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined,
  );
  const [openedTab, setOpenedTab] = useState<UsersTabKey>("superblocks");
  const userType =
    openedTab === "embed" ? UserType.EXTERNAL : UserType.SUPERBLOCKS;

  const onTabChange = useCallback((key: string) => {
    setOpenedTab(key as UsersTabKey);
  }, []);

  const [groups, setGroups] = useState<GroupBrief[]>([]);
  const allUsersGroup: GroupBrief | undefined = useMemo(() => {
    return groups.find(
      (group: GroupBrief) => group.type === GroupType.ALL_USERS,
    );
  }, [groups]);

  const [statusFilter, setStatusFilter] =
    useState<DropdownOption[]>(statusOptions);

  const { data: fetchedRoles } = useListRolesQuery(
    {
      type: RoleTypeEnum.ORGANIZATION,
      organizationId: currentOrg.id,
    },
    {
      skip: !rbacV2Enabled || !canViewRoles,
    },
  );

  const roleOptions = useMemo(() => {
    return fetchedRoles?.map((role) => ({
      key: role.id,
      value: role.id,
      displayName: role.name,
    }));
  }, [fetchedRoles]);

  const [roleFilter, setRoleFilter] = useState<DropdownOption[]>(
    roleOptions ?? [],
  );

  // set role filter to all options after all roles are loading
  useEffect(() => {
    setRoleFilter(roleOptions ?? []);
  }, [roleOptions]);

  const [triggerListUsersQuery] = useLazyListUsersQuery();

  useEffect(() => {
    async function getUsers() {
      try {
        setSearchTerm("");
        setUsers([]);
        setUsersLoading(true);

        const users = await triggerListUsersQuery({
          organizationId: currentOrg.id,
          type: userType,
        }).unwrap();
        if (users) {
          setUsers(users.map(convertToUserToRender));
        }

        const { groups } = await getGroupsForOrg(currentOrg.id);
        if (groups) {
          setGroups(groups);
        }
        setLoadingError(undefined);
      } catch (e: any) {
        setLoadingError(e.message ?? "Failed to fetch users");
      } finally {
        setUsersLoading(false);
      }
    }
    getUsers();
  }, [currentOrg.id, triggerListUsersQuery, userType]);

  const onUserUpdated = useCallback(
    (userId: string, updates: Partial<UserToRender>) => {
      setUsers((users) =>
        users.map((user) =>
          user.id === userId ? { ...user, ...updates } : user,
        ),
      );
    },
    [],
  );

  const onUsersAdded = useCallback(
    (newMembers: OrganizationUserDto[], operation: "replace" | "concat") => {
      setUsers((prev: UserToRender[]) =>
        operation === "replace"
          ? newMembers.map(convertToUserToRender)
          : [...prev, ...newMembers.map(convertToUserToRender)],
      );
    },
    [],
  );

  const roleFilterEnabled = rbacV2Enabled && canViewRoles;

  const filterUsers = useMemo(() => {
    const selectedStatuses = statusFilter.map(
      (option) => option.value,
    ) as StatusType[];

    const selectedRoles = roleFilter.map((option) => option.value) as string[];

    let filtered =
      searchTerm.length > 1
        ? users.filter((user) =>
            filterBySearch<UserToRender>(user, searchTerm, ["name", "email"]),
          )
        : users;
    // TODO: remove this check once we handle it from backend. We should not return superblocks super user when customer call org users endpoint
    filtered = filtered.filter(
      (user) =>
        !(
          currentOrg.name !== "superblockshq.com" &&
          user.email.endsWith("@superblockshq.com")
        ),
    );

    filtered =
      statusFilter.length === statusOptions.length
        ? filtered
        : filtered.filter((user) => selectedStatuses.includes(user.status));

    filtered =
      roleFilterEnabled && roleFilter.length !== roleOptions?.length
        ? filtered.filter((user) => selectedRoles.includes(user.roleId))
        : filtered;

    return filtered.sort(userCompareFn);
  }, [
    currentOrg.name,
    roleFilter,
    roleFilterEnabled,
    roleOptions?.length,
    searchTerm,
    statusFilter,
    users,
  ]);

  const handleAddUserCancel = useCallback(() => {
    setIsInviteModalOpen(false);
  }, []);

  const embedEnabled = useFeatureFlag(Flag.ENABLE_EMBED);

  const [selectedUsers, setSelectedUsers] = useState<UserToRender[]>([]);

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const clearSelectedUsers = useCallback(() => {
    setSelectedUsers([]);
    userTableRef?.current?.setSelectedRows({});
  }, []);

  const SbUsersList = useMemo(
    () => (
      <>
        <div className={`${SearchContainer} ${SearchInputWrapper}`}>
          <div className={HalfRow}>
            <SearchInput
              placeholder="Search"
              onChange={onSearchChangeDebounced}
              data-test="users-search-input"
            />
            <MultiFilter
              selectedItems={statusFilter}
              options={statusOptions}
              onChange={(selectedOptions) => setStatusFilter(selectedOptions)}
              label="Status"
              width={163}
              placeholder="Filter by status"
              enableSelectAll={true}
              defaultSelectAll={true}
              disableSearch={true}
            />
            {roleFilterEnabled && (
              <MultiFilter
                selectedItems={roleFilter}
                options={roleOptions ?? []}
                onChange={(selectedOptions) => setRoleFilter(selectedOptions)}
                label="Role"
                width={163}
                placeholder="Filter by role"
                enableSelectAll={true}
                defaultSelectAll={true}
                disableSearch={true}
              />
            )}
          </div>
          <div className={HalfRow} style={{ justifyContent: "flex-end" }}>
            {/* TODO(rbac): remove roleAssignmentsEnabled check once we ga rbac v2 */}
            {selectedUsers.length > 0 && rbacV2Enabled && (
              <div className={SelectedSection}>
                <div style={{ whiteSpace: "nowrap", fontSize: 12 }}>
                  {`${selectedUsers.length} selected`}{" "}
                </div>
                <div>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <div className={MenuItemWrapper}>
                              <UserGearIcon /> Change role
                            </div>
                          ),
                          key: "change-role",
                          onClick: () => {
                            setIsChangeRoleModalOpen(true);
                          },
                        },
                      ],
                    }}
                    trigger={["click"]}
                    placement="bottomRight"
                    open={isActionsDropdownOpen}
                    onOpenChange={setIsActionsDropdownOpen}
                    overlayStyle={{
                      zIndex: 100,
                    }}
                  >
                    <SecondaryButton style={{ fontWeight: 500, gap: 4 }}>
                      Actions{" "}
                      <ChevronDown
                        className={`${ChevronButtonStyle} ${
                          isActionsDropdownOpen
                            ? "dropdown-open"
                            : "dropdown-close"
                        }`}
                      />
                    </SecondaryButton>
                  </Dropdown>
                </div>
              </div>
            )}
            <AddButton
              text="Invite user"
              onAdd={() => setIsInviteModalOpen(true)}
              disabled={!canManageUsers}
              disabledText="You do not have permission to invite users"
              dataTest="invite-user-button"
            />
          </div>
        </div>
        {loadingError ? (
          <div style={{ color: colors.RED_500 }}>{loadingError}</div>
        ) : (
          allUsersGroup && (
            <UserList
              type={
                openedTab === "embed" ? UserType.EXTERNAL : UserType.SUPERBLOCKS
              }
              users={filterUsers}
              setUsers={setUsers}
              loading={usersLoading}
              totalCount={users.length}
              groupId={allUsersGroup.id}
              updateUser={onUserUpdated}
              onMultiRowsSelectChange={setSelectedUsers}
              userTableRef={userTableRef}
            />
          )
        )}
      </>
    ),
    [
      onSearchChangeDebounced,
      statusFilter,
      roleFilterEnabled,
      roleFilter,
      roleOptions,
      selectedUsers.length,
      rbacV2Enabled,
      isActionsDropdownOpen,
      canManageUsers,
      loadingError,
      allUsersGroup,
      openedTab,
      filterUsers,
      usersLoading,
      users.length,
      onUserUpdated,
    ],
  );

  const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false); // State to manage modal visibility

  const handleChangeRoleCancel = useCallback(() => {
    setIsChangeRoleModalOpen(false);
  }, []);

  const onUsersUpdated = useCallback((updatedUsers: UserToRender[]) => {
    const updatedUsersMap = new Map(
      updatedUsers.map((user) => [user.id, user]),
    );

    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        const updatedUser = updatedUsersMap.get(user.id);
        return updatedUser ? { ...user, ...updatedUser } : user;
      }),
    );
  }, []);

  return (
    <PageWrapper pageName="Users">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> Users </div>
          </div>
          {embedEnabled ? (
            <Tabs
              defaultActiveKey="superblocks"
              activeKey={openedTab}
              onChange={onTabChange}
            >
              <Tabs.TabPane tab="Superblocks users" key="superblocks">
                {SbUsersList}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Embed users" key="embed">
                <div className={SearchContainer}>
                  <SearchInput
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    data-test="users-search-input"
                  />
                </div>
                {loadingError ? (
                  <div style={{ color: colors.RED_500 }}>{loadingError}</div>
                ) : (
                  allUsersGroup && (
                    <UserList
                      type={
                        openedTab === "embed"
                          ? UserType.EXTERNAL
                          : UserType.SUPERBLOCKS
                      }
                      users={filterUsers}
                      setUsers={setUsers}
                      loading={usersLoading}
                      totalCount={users.length}
                      groupId={allUsersGroup.id}
                      updateUser={onUserUpdated}
                      onMultiRowsSelectChange={setSelectedUsers}
                      userTableRef={userTableRef}
                    />
                  )
                )}
              </Tabs.TabPane>
            </Tabs>
          ) : (
            SbUsersList
          )}
          <InviteOrgUserModal
            isVisible={isInviteModalOpen}
            onClose={handleAddUserCancel}
            onUsersAdded={onUsersAdded}
          />
          <ChangeRoleModal
            isOpen={isChangeRoleModalOpen}
            setIsOpen={handleChangeRoleCancel}
            selectedUsers={selectedUsers}
            onUsersUpdated={onUsersUpdated}
            clearSelectedUsers={clearSelectedUsers}
          />
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default UsersPage;
