import React, { useMemo, useCallback, useContext } from "react";
import styled from "styled-components";
import { Button } from "components/ui/Button";

const ContentWrapper = styled.div`
  max-height: 35em;
  overflow-y: auto;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 12px;
    margin-top: 12px;
  }
`;

type StepType = any;

type WizardFlowProps = {
  activeStep: StepType;
  setActiveStep: (step: StepType) => void;
  onWizardFinish?: () => void;
  children?: React.ReactNode;
};

type WizardStepConfig = {
  canSkip?: boolean;
  noControls?: boolean;
  noBack?: boolean;
  onSkip?: () => void;
  nextText?: string;
  stepId: StepType;
  nextStepId?: StepType;
  prevStepId?: StepType;
  children?: React.ReactNode;
};

type WizardContextType = {
  activeStep: StepType;
  setActiveStep: (step: StepType) => void;
  onWizardFinish?: () => void;
};

const Context = React.createContext<WizardContextType>({
  activeStep: 0,
  setActiveStep: () => undefined,
});

export const WizardStep = (props: WizardStepConfig) => {
  const {
    children,
    stepId,
    onSkip,
    noBack,
    canSkip,
    noControls,
    nextText = "Next",
    nextStepId,
    prevStepId,
  } = props;

  const isFirstStep = prevStepId == null;
  const isLastStep = nextStepId == null;
  const showPrevious = !isFirstStep && !noBack;
  const showNext = !isLastStep && !canSkip;
  const showFinish = isLastStep && !canSkip;

  const { activeStep, setActiveStep, onWizardFinish } = useContext(Context);

  const handleFinish = useCallback(() => {
    if (onWizardFinish) onWizardFinish();
  }, [onWizardFinish]);

  const handleSkip = useCallback(() => {
    onSkip && onSkip();
    if (isLastStep) {
      if (onWizardFinish) onWizardFinish();
    }
    if (nextStepId != null) setActiveStep(nextStepId);
  }, [onSkip, isLastStep, nextStepId, setActiveStep, onWizardFinish]);

  const handleNext = useCallback(() => {
    if (isLastStep) {
      if (onWizardFinish) onWizardFinish();
    }
    nextStepId != null && setActiveStep(nextStepId);
  }, [nextStepId, isLastStep, setActiveStep, onWizardFinish]);

  const handlePrevious = useCallback(() => {
    prevStepId != null && setActiveStep(prevStepId);
  }, [prevStepId, setActiveStep]);

  if (activeStep !== stepId) return null;
  return (
    <div>
      <ContentWrapper>{children}</ContentWrapper>

      {!noControls && (
        <ActionsWrapper>
          {showPrevious && <Button onClick={handlePrevious}>Back</Button>}
          {showNext && (
            <Button type="primary" onClick={handleNext} data-test="wizard-next">
              {nextText}
            </Button>
          )}
          {showFinish && (
            <Button type="primary" onClick={handleFinish}>
              Finish
            </Button>
          )}
          {canSkip && (
            <Button type="primary" onClick={handleSkip} data-test="wizard-skip">
              Skip
            </Button>
          )}
        </ActionsWrapper>
      )}
    </div>
  );
};

export const WizardFlow = (props: WizardFlowProps) => {
  const { activeStep, setActiveStep, onWizardFinish } = props;

  const contextValue = useMemo(() => {
    return {
      setActiveStep,
      activeStep,
      onWizardFinish,
    };
  }, [activeStep, setActiveStep, onWizardFinish]);
  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};
