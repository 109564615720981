import { call, put, select } from "redux-saga/effects";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { AllFlags, selectFlags } from "store/slices/featureFlags";
import { AppState } from "store/types";
import {
  DiscardedEdit,
  processActionsIntoChanges,
} from "../processActionsIntoChanges";
import { processAiActions, setAiChanges } from "../slice";

export function* processAiActionsSaga(
  action: ReturnType<typeof processAiActions>,
) {
  const theme: ReturnType<typeof selectGeneratedTheme> =
    yield select(selectGeneratedTheme);
  const dataTree: DataTree = yield select(
    (state) => state.legacy.evaluations.tree,
  );
  const routes: ReturnType<typeof getRoutesList> = yield select(getRoutesList);
  const state: AppState = yield select((state) => state);
  const featureFlags: Partial<AllFlags> = yield select(selectFlags);
  const existingWidget: WidgetProps | undefined = yield select((state) =>
    getWidget(state, action.payload.existingWidgetId),
  );
  const validator = createNameValidator(state);
  if (!existingWidget) {
    return;
  }

  const {
    changedKeys,
    rename,
    dataTreeChanges,
    discardedEdits,
  }: {
    changedKeys: Array<string>;
    rename: string;
    dataTreeChanges: Record<string, unknown>;
    discardedEdits: DiscardedEdit[];
  } = yield call(processActionsIntoChanges, {
    actions: action.payload.actions,
    existingWidget,
    dataTree,
    routes,
    theme,
    nameValidator: validator,
    featureFlags,
  });

  yield put(
    setAiChanges({
      changedKeys,
      dataTreeChanges,
      rename,
      discardedEdits,
    }),
  );
}
