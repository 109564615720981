import { ScheduleState } from "@superblocksteam/shared";
import { EntitiesErrorType } from "store/utils/types";
import { createSaga, forkSagas } from "../../../utils/saga";
import * as BackendTypes from "../backend-types";
import slice from "../slice";
import { hydrateBlockSaga } from "./hydrateBlock";
import { persistV2ApiSaga } from "./persistV2Api";

type UpdateV2ApiPayload = {
  apiPb: BackendTypes.Api;
  scheduleState?: ScheduleState;
  hydrateBlockId?: string;
  updated?: Date;
};

export function* updateV2Api(
  payload: UpdateV2ApiPayload,
  callId: number,
): Generator<unknown, void, any> {
  if (payload?.hydrateBlockId) {
    yield forkSagas([
      hydrateBlockSaga.apply({
        apiId: payload.apiPb.metadata.id,
        blockId: payload.hydrateBlockId,
        updatingApiCallId: callId,
      }),
    ]);
  }

  yield forkSagas([
    persistV2ApiSaga.apply({
      api: payload.apiPb,
      scheduleState: payload.scheduleState,
      updatingApiCallId: callId,
      updated: payload.updated,
    }),
  ]);
}

export const updateV2ApiSaga = createSaga(updateV2Api, "updateV2ApiSaga", {
  sliceName: slice.name,
});

slice.saga(updateV2ApiSaga, {
  start(state, { payload }) {
    const apiId = payload.apiPb.metadata.id;
    state.meta[apiId] = state.meta[apiId] ?? {};
    state.meta[apiId].dirty = true;
    state.meta[apiId].needsBindingExtraction = true;
    state.entities[apiId] = {
      ...state.entities[apiId],
      apiPb: payload.apiPb,
      updated: payload.updated,
    };
    state.meta[apiId].isUpdating = true;
    delete state.errors[apiId];
  },
  success(state, { payload, meta }) {
    // TODO, we may want to clear API results depending on the situation.
    // For example, a deleted block should have its results cleared.
    // If it's renamed, the output should be renamed. This is complex though and the responsibility
    // likely does not fall on this success handler like it does for V1 APIs.
    state.meta[meta.args.apiPb.metadata.id].isUpdating = false;
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiPb.metadata.id] = {
      type: EntitiesErrorType.SAVE_ERROR,
      error: payload,
    };
    state.meta[meta.args.apiPb.metadata.id].isUpdating = false;
  },
});
