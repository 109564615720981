import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { createMarkedSelector } from "./markedSelector";
import { getWidgetsMeta } from "./sagaSelectors";
import type { AppState } from "store/types";

const getCanvasWidgets = (state: AppState) =>
  state.legacy.entities.canvasWidgets;

export const getModalDropdownList = createMarkedSelector(
  "getModalDropdownList",
)(getCanvasWidgets, (widgets) => {
  const modalWidgets = Object.values(widgets).filter(
    (widget: FlattenedWidgetProps) =>
      widget.type === WidgetTypes.MODAL_WIDGET ||
      widget.type === WidgetTypes.SLIDEOUT_WIDGET,
  );
  if (modalWidgets.length === 0) return undefined;

  return modalWidgets.map((widget: FlattenedWidgetProps) => ({
    id: widget.widgetId,
    label: widget.widgetName,
    value: `${widget.widgetName}`,
  }));
});

export const getOpenModals = createMarkedSelector("getOpenModals")(
  getCanvasWidgets,
  getWidgetsMeta,
  (widgets, metaProps) => {
    return Object.values(widgets).filter(
      (widget: FlattenedWidgetProps) =>
        widget.type === WidgetTypes.MODAL_WIDGET &&
        metaProps[widget.widgetId]?.isVisible,
    );
  },
);

export const getOpenModalOrSlideout = createMarkedSelector(
  "getOpenModalOrSlideout",
)(getCanvasWidgets, getWidgetsMeta, (widgets, metaProps) => {
  return Object.values(widgets).find(
    (widget: FlattenedWidgetProps) =>
      (widget.type === WidgetTypes.MODAL_WIDGET ||
        widget.type === WidgetTypes.SLIDEOUT_WIDGET) &&
      metaProps[widget.widgetId]?.isVisible,
  );
});
