import { Table, Column, Schema, FormItemStyle } from "@superblocksteam/shared";
import { Typography } from "antd";
import { get } from "lodash";
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { PresetOption } from "components/app/CodeEditor/types";
import { useAppSelector } from "store/helpers";
import {
  selectMetadataLoadingById,
  selectDatasourceMetaById,
} from "store/slices/datasources";
import logger from "utils/logger";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormSubheading } from "../FormSubheading";
import { ruleParser } from "../utils";
import { DynamicFormCodeEditor } from "./DynamicFormCodeEditor";

interface DynamicFormInputWithMetadataOptionsProps {
  dependencyPath?: string;
  datasourceId: string;
  setFormError: (error: string) => void;
  optionFilterProp?: string;
}

type Props = DynamicFormInputWithMetadataOptionsProps &
  DynamicFormItemProps & {
    valueAccessor: string;
    listAccessor?: string;
    defaultToFirstOption?: boolean;
    clearDependentFieldsOnChange?: string[];
    filterDependency?: string;
    filterFieldName?: string;
    filterDependencyValue?: string;
    formValue?: string;
    placeHolder?: string;
    style?: FormItemStyle;
    onEditorFocus: () => void;
    onEditorBlur: () => void;
  };

const DEFAULT_LIST_ACCESSOR = "metadata.dbSchema.tables";

const DynamicFormInputWithMetadataOptions = ({
  path,
  label,
  placeholder,
  initialValue,
  rules,
  dependencyPath,
  datasourceId,
  setFormError,
  valueAccessor,
  listAccessor,
  defaultToFirstOption,
  filterDependency,
  filterFieldName,
  tooltip,
  subHeading,
  filterDependencyValue,
  formValue,
  clearDependentFieldsOnChange,
  placeHolder,
  style,
  onEditorFocus,
  onEditorBlur,
  subtitle,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const { onChange: contextOnChange, getValue } = context;

  const onChange = useCallback(
    (val?: string, skipClear?: boolean) => {
      if (clearDependentFieldsOnChange && !skipClear) {
        clearDependentFieldsOnChange.forEach((field) => {
          contextOnChange(field, "", { debounced: true });
        });
      }
      contextOnChange(path, val, { debounced: false }); // when user updates the value
    },
    [path, contextOnChange, clearDependentFieldsOnChange],
  );
  const [validationMessage, setValidationMessage] = useState("");

  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  const metadataLoading = useAppSelector((state) =>
    selectMetadataLoadingById(state, datasourceId),
  );

  const filterFn = useCallback(
    (entity: Table | Schema) => {
      if (!filterDependency || !filterFieldName) {
        return true;
      }
      const filterFieldValue = (entity as Record<string, any>)[
        filterFieldName
      ] as string;
      const depValue = filterDependencyValue ?? getValue(filterDependency);
      if (!depValue && !filterFieldValue) {
        return true;
      }
      return filterFieldValue === depValue;
    },
    [filterDependencyValue, filterDependency, filterFieldName, getValue],
  );

  const options = useMemo(() => {
    const result: (PresetOption & { parentKey?: string })[] = [];
    const rawOptions = get(
      datasourceMeta,
      listAccessor ?? DEFAULT_LIST_ACCESSOR,
      [],
    );
    try {
      if (dependencyPath) {
        const parentValue = getValue(dependencyPath);
        rawOptions.filter(filterFn).forEach((entity: Table | Schema) => {
          // TODO:(pbardea)
          // "columns" in entity is a placeholder for `entity instanceof Table`.
          // The gsheet plugin doesn't strongly type it's metadata as a Table
          // type. This is the fastest fix.
          if ("columns" in entity && entity.id === parentValue) {
            entity.columns.forEach((column: Column) => {
              result.push({
                value: column.name as string,
                parentKey: entity.id,
                // we can also add doc or type to rawOptions and set it here
              });
            });
          }
        });
        const found = result?.find((option) => option.value === formValue);
        if (formValue && rawOptions.length > 0 && !found) {
          // invalidate child dropdown's value
          contextOnChange(path, "");
        }
      } else {
        rawOptions.filter(filterFn).forEach((entity: Table | Schema) => {
          result.push({
            value: get(entity, valueAccessor),
          });
        });
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [
    listAccessor,
    dependencyPath,
    contextOnChange,
    formValue,
    valueAccessor,
    path,
    datasourceMeta,
    filterFn,
    getValue,
  ]);

  // if defaultToFirstOption is true, set the value to the first option if it's not set
  useEffect(() => {
    if (defaultToFirstOption && !formValue && options.length > 0) {
      onChange(options[0].value, true);
    }
  }, [formValue, options, defaultToFirstOption, onChange]);

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [path, rules, context.registerValidation, context]);

  return (
    <>
      <label>
        <DynamicFormCodeEditor
          showLineNumbers={false}
          mode={EditorModes.TEXT_WITH_BINDING}
          key={EditorModes.TEXT_WITH_BINDING}
          tabBehaviour={TabBehaviour.INDENT}
          disabled={Boolean(otherProps.disabled)}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          minHeight={style?.minHeight ?? "28px"}
          maxHeight="350px"
          placeholder={placeholder}
          onEditorFocus={onEditorFocus}
          onEditorBlur={onEditorBlur}
          path={path}
          label={label}
          rules={rules}
          tooltip={tooltip}
          subHeading={subHeading}
          datasourceId={datasourceId}
          presetOptions={options}
          autoFocus={false}
          isLoading={metadataLoading}
          data-test={`dynamic-text-${path}`}
          subtitle={subtitle}
        />
      </label>
      {subHeading && <FormSubheading text={subHeading} />}
      <Typography.Text type="danger">{validationMessage}</Typography.Text>
    </>
  );
};

const DynamicFormFieldWrapper = (props: Props) => {
  const { datasourceId, filterDependency, path } = props;
  // this wrapper will subscribe to the relevent context and pass the value to the child component
  // We won't render the child until the relevant context is available, because otherwise there are race conditions
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(FormContext);
  const [filterDependencyValue, setFilterDependencyValue] = useState<any>(null);
  const [value, setValue] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const subscribe = context.subscribe;
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  useEffect(() => {
    if (isLoading && datasourceMeta) {
      setIsLoading(false);
    }
  }, [datasourceMeta, isLoading]);

  useEffect(() => {
    if (filterDependency) {
      const unsubscribe = subscribe(filterDependency, (dependencyValue) => {
        setFilterDependencyValue(dependencyValue);
      });
      return () => unsubscribe();
    }
  }, [filterDependency, subscribe]);

  useEffect(() => {
    const unsubscribe = subscribe(path, (value: any) => {
      setValue(value as string); //update UI
    });
    setIsSubscribed(true);
    return () => unsubscribe();
  }, [path, subscribe]);

  if (!isSubscribed) {
    // Not adding isLoading as check because we can show the input even if options are still loading and the autocomplete is not ready
    return null;
  }
  return (
    <DynamicFormInputWithMetadataOptions
      filterDependencyValue={filterDependencyValue}
      formValue={value}
      {...props}
    />
  );
};

export default DynamicFormFieldWrapper;
