import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ChevronLeft } from "assets/icons/common/chevron-left.svg";
import { Layers } from "legacy/constants/Layers";

const ButtonWidth = 12;
const ButtonHeight = 36;

const getStylesByDirection = (
  direction: string,
  isCollapsed: boolean,
  hideWhenCollapsed?: boolean,
) => {
  // if button is hidden when collapsed, it means collapse size is not zero, we can hide button and we need to align it to center of border
  const shouldAlignToCenter =
    !isCollapsed || (isCollapsed && hideWhenCollapsed);
  switch (direction) {
    case "left":
      return css`
        transform: rotate(${isCollapsed ? 180 : 0}deg);
        top: calc(50% - ${ButtonHeight / 2}px);
        left: ${-(shouldAlignToCenter ? ButtonWidth / 2 : 0)}px;
      `;
    case "up":
      return css`
        transform: rotate(${isCollapsed ? 270 : 90}deg);
        left: calc(50% - ${ButtonHeight / 2}px);
        top: ${-(shouldAlignToCenter ? ButtonHeight / 2 : ButtonWidth)}px;
      `;
    case "right":
      return css`
        transform: rotate(${isCollapsed ? 0 : 180}deg);
        top: calc(50% - ${ButtonHeight / 2}px);
        right: ${-(shouldAlignToCenter ? ButtonWidth / 2 : 0)}px;
      `;
    case "down":
      return css`
        transform: rotate(${isCollapsed ? 90 : 270}deg);
        left: calc(50% - ${ButtonHeight / 2}px);
        bottom: ${-(shouldAlignToCenter ? ButtonHeight / 2 : ButtonWidth)}px;
      `;
    default:
      return "";
  }
};

type Props = {
  direction: "left" | "right" | "up" | "down";
  paneSize: number;
  dataTest?: string;
  onMouseOver?: (e?: React.MouseEvent) => void;
  onMouseOut?: (e?: React.MouseEvent) => void;
  onClick?: (e?: React.MouseEvent) => void;
  isCollapsed: boolean;
  hideWhenCollapsed?: boolean;
};

const ButtonWrapper = styled.div<{
  direction: string;
  paneSize: number;
  isCollapsed: boolean;
  hideWhenCollapsed?: boolean;
}>`
  z-index: ${Layers.panelResizer};
  position: absolute;
  width: ${ButtonWidth}px;
  height: ${ButtonHeight}px;
  ${(props) =>
    getStylesByDirection(
      props.direction,
      props.isCollapsed,
      props.hideWhenCollapsed,
    )};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin-left: -1px;
  }
  cursor: pointer;
`;

const CollapseButton = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <ButtonWrapper
      ref={ref}
      className="CollapseButton"
      direction={props.direction}
      paneSize={props.paneSize}
      data-test={props.dataTest}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
      isCollapsed={props.isCollapsed}
      hideWhenCollapsed={props.hideWhenCollapsed}
    >
      <ChevronLeft color="white" onMouseOver={props.onMouseOver} />
    </ButtonWrapper>
  );
});

CollapseButton.displayName = "CollapseButton";

export default CollapseButton;
