import Icon from "@ant-design/icons";
import { BillingPlan } from "@superblocksteam/shared";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as IntercomPaidIcon } from "assets/icons/home/intercom-paid.svg";
import { ReactComponent as IntercomUnpaidIcon } from "assets/icons/home/intercom-unpaid.svg";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { Layers } from "legacy/constants/Layers";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { IntercomPopover } from "./IntercomPopover";
import { INTERCOM_LAUNCHER_CLASSNAME } from "./constants";

const Circle = styled.div`
  height: 36px;
  width: 36px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.PLATFORM_BLACK};
  border-radius: 50%;
  display: inline-block;
  z-index: ${Layers.intercom};
  position: fixed;
  bottom: 12px;
  left: 12px;
  filter: drop-shadow(0 0 0.3rem rgba(85, 90, 99, 0.6));
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IntercomOverlay = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const organization = useSelector(selectOnlyOrganization);
  const isPaidPlan = organization.billing.plan === BillingPlan.ENTERPRISE;
  const enableIntercomLiveChat = useFeatureFlag(Flag.ENABLE_INTERCOM_LIVE_CHAT);

  const isPaidPlanOrEnabledChat = isPaidPlan || enableIntercomLiveChat;

  const iconComponent = isPaidPlanOrEnabledChat
    ? IntercomPaidIcon
    : IntercomUnpaidIcon;

  return (
    <div style={{ width: `100%`, height: "100%" }}>
      {children}
      <IntercomPopover isPaidPlan={isPaidPlanOrEnabledChat}>
        <Circle
          className={INTERCOM_LAUNCHER_CLASSNAME}
          onClick={(e) => {
            if (!isPaidPlanOrEnabledChat) {
              e.stopPropagation(); // Prevent the live chat window from opening
            }
          }}
        >
          <Icon component={iconComponent} style={{ fontSize: 18 }} />
        </Circle>
      </IntercomPopover>
    </div>
  );
};
