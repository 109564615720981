import { Profile } from "@superblocksteam/shared";
import { Button, Modal, Tooltip } from "antd";
import { produce } from "immer";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { InitialValueFn } from "components/app/SBDynamicForm/utils";
import { fastClone } from "utils/clone";
import ProfilesMultiSelect from "./ProfilesMultiSelect";
import { ConfigMeta } from "./utils";

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

export const ConfigurationProfilesModal = ({
  isNew,
  profiles,
  configMetaToClone,
  configIdToFormData,
  setConfigIdToFormData,
  configMeta,
  configMetas,
  setConfigIdToConfigMeta,
  isModalVisible,
  setIsModalVisible,
  setCurrentConfigurationId,
  initialValuesFn,
}: {
  isNew: boolean;
  profiles: Profile[];
  configMetaToClone: ConfigMeta | undefined;
  configIdToFormData: Record<string, any>;
  setConfigIdToFormData: (configIdToFormData: Record<string, any>) => void;
  configMeta?: ConfigMeta; // configuration to modify
  configMetas: ConfigMeta[];
  setConfigIdToConfigMeta: (
    value:
      | Record<string, ConfigMeta>
      | ((configIdToConfigMeta: Record<string, ConfigMeta>) => void),
  ) => void;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  setCurrentConfigurationId?: (id: string) => void;
  initialValuesFn: InitialValueFn;
}) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const [selectedProfileIds, setSelectedProfileIds] = React.useState<string[]>(
    configMeta?.profileIds ?? [],
  );

  useEffect(() => {
    if (!isNew && isModalVisible) {
      setSelectedProfileIds(configMeta?.profileIds ?? []);
    }
  }, [isNew, isModalVisible, configMeta?.profileIds]);

  const onCancel = useCallback(() => {
    setIsModalVisible(false);
    setSelectedProfileIds([]);
  }, [setIsModalVisible]);

  const defaultConfigId = configMetas.find((config) => config.isDefault)?.id;

  const onUpdate = useCallback(() => {
    if (configMeta?.id) {
      setConfigIdToConfigMeta(
        produce((draft: Record<string, ConfigMeta>) => {
          // calculate the deleted profiles, and add it back to default configuration
          const deletedProfileIds = draft[configMeta.id].profileIds.filter(
            (profileId) => !selectedProfileIds.includes(profileId),
          );
          if (defaultConfigId) {
            draft[defaultConfigId].profileIds = [
              ...draft[defaultConfigId].profileIds,
              ...deletedProfileIds,
            ];
          }
          // calculate the added profiles and remove it from other configurations
          Object.values(draft).forEach((config) => {
            if (config.id === configMeta.id) return;
            const profileIdsAfterRemove = config.profileIds.filter(
              (profileId) => !selectedProfileIds.includes(profileId),
            );
            draft[config.id].profileIds = profileIdsAfterRemove;
          });
          draft[configMeta?.id].profileIds = [...selectedProfileIds];
        }),
      );
    }
    setIsModalVisible(false);
    setSelectedProfileIds([]);
  }, [
    configMeta?.id,
    defaultConfigId,
    selectedProfileIds,
    setConfigIdToConfigMeta,
    setIsModalVisible,
  ]);

  const onCreate = useCallback(() => {
    let newConfigFormData: any;
    if (!configMetaToClone) {
      const initialValuesWithResolvedTemplate = initialValuesFn();
      newConfigFormData = initialValuesWithResolvedTemplate[0];
    } else {
      newConfigFormData = fastClone(configIdToFormData[configMetaToClone.id]);
    }
    const newConfigId = uuidv4();
    setConfigIdToConfigMeta(
      produce((draft: Record<string, ConfigMeta>) => {
        // find configuration that has the profiles in new configuration and remove the profiles
        Object.values(draft).forEach((config) => {
          const profileIdsAfterRemove = config.profileIds.filter(
            (profileId) => !selectedProfileIds.includes(profileId),
          );
          draft[config.id].profileIds = profileIdsAfterRemove;
        });
        // add new configuration
        draft[newConfigId] = {
          id: newConfigId,
          isDefault: false,
          profileIds: [...selectedProfileIds],
          created: new Date(),
          originalProfileIds: [],
        };
      }),
    );
    setConfigIdToFormData(
      produce((draft) => {
        draft[newConfigId] = newConfigFormData;
      }),
    );
    setIsModalVisible(false);
    setSelectedProfileIds([]);
    setCurrentConfigurationId?.(newConfigId);
  }, [
    configMetaToClone,
    setConfigIdToConfigMeta,
    setConfigIdToFormData,
    setIsModalVisible,
    setCurrentConfigurationId,
    initialValuesFn,
    configIdToFormData,
    selectedProfileIds,
  ]);

  return (
    <Modal
      open={isModalVisible}
      onCancel={onCancel}
      title={
        isNew ? "Add new configuration" : "Edit profiles for configuration"
      }
      footer={null}
      destroyOnClose={true}
      zIndex={9999}
    >
      <div ref={parentRef}>
        <div data-test="add-config-description">
          Which profile will this configuration be used for?
        </div>
        <ProfilesMultiSelect
          profiles={profiles}
          parentRef={parentRef}
          configMeta={configMeta}
          configMetas={configMetas}
          selectedProfileIds={selectedProfileIds}
          setSelectedProfileIds={setSelectedProfileIds}
        />
        <FooterWrapper>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Tooltip
            title={
              selectedProfileIds.length === 0 && isNew
                ? "Please select at least one profile"
                : ""
            }
          >
            <Button
              type="primary"
              onClick={isNew ? onCreate : onUpdate}
              data-test="create-config-button"
              disabled={selectedProfileIds.length === 0 && isNew}
            >
              {isNew ? "Create" : "Confirm"}
            </Button>
          </Tooltip>
        </FooterWrapper>
      </div>
    </Modal>
  );
};
