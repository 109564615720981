import React from "react";
import styled from "styled-components";
import CollapseButton from "./CollapseButton";

export const RESIZER_DEFAULT_CLASSNAME = "Resizer";

const Wrapper = styled.div`
  position: relative;
`;

type ResizerProps = {
  className: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onDoubleClick?: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void;
  onMouseDown: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onTouchStart: (event: React.TouchEvent<HTMLSpanElement>) => void;
  onTouchEnd: (event: React.TouchEvent<HTMLSpanElement>) => void;
  split?: "vertical" | "horizontal";
  style: React.CSSProperties;
  resizerClassName?: string;
  direction: "left" | "right" | "up" | "down";
  onPaneToggle?: (e?: React.MouseEvent) => void;
  isCollapsed: boolean;
  isCollapsable?: boolean;
  isActive: boolean;
  hideWhenCollapsed?: boolean;
};

const Resizer = ({
  className,
  onClick,
  onDoubleClick,
  onMouseDown,
  onTouchEnd,
  onTouchStart,
  resizerClassName = RESIZER_DEFAULT_CLASSNAME,
  split = "vertical",
  style,
  direction,
  onPaneToggle,
  isCollapsed,
  isCollapsable,
  hideWhenCollapsed,
  isActive,
}: ResizerProps) => {
  const classes = [
    resizerClassName,
    split,
    className,
    isActive ? "active" : "",
  ];
  const wrapperClasses = [
    "ResizerWrapper",
    split,
    className,
    isActive ? "active" : "",
    hideWhenCollapsed ? "hideWhenCollapsed" : "",
  ];
  return (
    <Wrapper className={wrapperClasses.join(" ")}>
      <div
        role="presentation"
        className={classes.join(" ")}
        style={style}
        onMouseDown={(event) => onMouseDown(event)}
        onTouchStart={(event) => {
          event.preventDefault();
          onTouchStart(event);
        }}
        onTouchEnd={(event) => {
          event.preventDefault();
          onTouchEnd(event);
        }}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick(event);
          }
        }}
        onDoubleClick={(event) => {
          if (onDoubleClick) {
            event.preventDefault();
            onDoubleClick(event);
          }
        }}
      >
        <div className="middle-line"></div>
      </div>
      {isCollapsable && (
        <CollapseButton
          direction={direction}
          dataTest={`collapse-button`}
          isCollapsed={isCollapsed}
          paneSize={0}
          onClick={onPaneToggle}
          hideWhenCollapsed={hideWhenCollapsed}
        />
      )}
    </Wrapper>
  );
};

export default Resizer;
