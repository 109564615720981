import { AccessMode } from "@superblocksteam/shared";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import { BuildInSuperblocksOverlay } from "components/app/Popover/BuildInSuperblocksOverlay";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import { retryPromise } from "legacy/utils/Utils";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

function AppViewerLoader(props: {
  previewMode?: boolean;
  embedMode?: boolean;
}) {
  const [Component, setState] = React.useState<React.ComponentType<any>>();
  const accessMode = useSelector(getAccessMode);

  useEffect(() => {
    retryPromise(
      () => import(/* webpackChunkName: "AppViewer" */ "./index"),
    ).then((module) => {
      setState(() => module.default);
    });
  }, []);

  const params = useParams<{ applicationId: string }>();

  if (!Component) {
    return (
      <PageWrapper key={params.applicationId}>
        <FullPageSpinner />
      </PageWrapper>
    );
  }

  if (
    accessMode === AccessMode.AUTH_USER ||
    accessMode === AccessMode.EXTERNAL_USER
  ) {
    return (
      <Component
        previewMode={props.previewMode}
        embedMode={props.embedMode}
        key={params.applicationId}
        params={params}
      />
    );
  }

  return (
    <BuildInSuperblocksOverlay>
      <Component
        previewMode={props.previewMode}
        embedMode={props.embedMode}
        key={params.applicationId}
        params={params}
      />
    </BuildInSuperblocksOverlay>
  );
}

export default AppViewerLoader;
