// Used for tracking the last pasted widgetId in the canvas
// so we can make decisions about paste behavior
let lastPastedSingleWidgetId: string | undefined = undefined;

export const setLastPastedSingleWidgetId = (widgetId: string | undefined) => {
  lastPastedSingleWidgetId = widgetId;
};

export const getLastPastedSingleWidgetId = () => {
  return lastPastedSingleWidgetId;
};
