// Copyright Exafunction, Inc.
// Based on https://github.com/Exafunction/codeium-chrome/tree/662b746a7e2fa5aa66ae0335dcaaedab95842f9b/exa

// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts,import_extension=none"
// @generated from file exa/language_server.proto (package exa.language_server_pb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AcceptCompletionRequest, AcceptCompletionResponse, GetAuthTokenRequest, GetAuthTokenResponse, GetCompletionsRequest, GetCompletionsResponse } from "./language_server_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service exa.language_server_pb.LanguageServerService
 */
export const LanguageServerService = {
  typeName: "exa.language_server_pb.LanguageServerService",
  methods: {
    /**
     * @generated from rpc exa.language_server_pb.LanguageServerService.GetCompletions
     */
    getCompletions: {
      name: "GetCompletions",
      I: GetCompletionsRequest,
      O: GetCompletionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc exa.language_server_pb.LanguageServerService.AcceptCompletion
     */
    acceptCompletion: {
      name: "AcceptCompletion",
      I: AcceptCompletionRequest,
      O: AcceptCompletionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc exa.language_server_pb.LanguageServerService.GetAuthToken
     */
    getAuthToken: {
      name: "GetAuthToken",
      I: GetAuthTokenRequest,
      O: GetAuthTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

