import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "hooks/ui";
import { selectV2ApiLoadingById } from "store/slices/apisV2";
import { RESULTS_PANE_MIN_SIZE } from "../ActionEditor/ResultPane";
import type { AppState } from "store/types";

const useOnApiExecutionComplete = (apiId: string, onComplete: () => void) => {
  const isRunning = useSelector((state: AppState) =>
    selectV2ApiLoadingById(state, apiId),
  );
  const prevIsRunning = usePrevious(isRunning);
  useEffect(() => {
    // Trigger onComplete when isRunning goes from true to false
    if (prevIsRunning && !isRunning) {
      onComplete();
    }
    // We purposely don't want to trigger when onComplete changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, prevIsRunning]);
};
export const useResultsPaneState = (apiId: string) => {
  const [paneSize, setPaneSize] = useState<number | string>("50%");
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [hasBeenDragged, setHasBeenDragged] = useState<boolean>(false);

  useOnApiExecutionComplete(apiId, () => {
    const hasNotBeenDragged = !hasBeenDragged;
    if (isCollapsed || hasNotBeenDragged) {
      setPaneSize("50%");
      setIsCollapsed(false);
    }
  });

  const updateResultsPaneSize = useCallback(
    (newSize: number, wrapperRect: DOMRect | undefined) => {
      if (!wrapperRect) {
        return;
      }

      setPaneSize(Math.round((100 * newSize) / wrapperRect.height) + "%");
      setHasBeenDragged(true);
      setIsCollapsed(newSize <= RESULTS_PANE_MIN_SIZE);
    },
    [],
  );

  return useMemo(
    () => ({
      paneSize,
      isCollapsed,
      hasBeenDragged,
      setPaneSize: updateResultsPaneSize,
      setIsCollapsed,
    }),
    [paneSize, isCollapsed, hasBeenDragged, updateResultsPaneSize],
  );
};
