import useResizeObserver from "@react-hook/resize-observer";
import { IntegrationDto } from "@superblocksteam/shared";
import CodeMirror from "codemirror";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  AiAssistantContext,
  AiAssistantPositioner,
  AiAssistantOptionType,
  FlowType,
  ScopeType,
  makeCommentForSyntax,
} from "ai/AiAssistant";
import {
  SUPPORTED_FORMATTER_TYPE,
  SyntaxType,
} from "code-formatting/constants";
import { getSyntaxForPlugin } from "code-formatting/utils";
import { useFeatureFlag } from "hooks/ui";
import { AiContextMode } from "legacy/constants/EditorPreferencesConstants";
import { CodeMirrorState } from "legacy/pages/Editor/Codeium/codemirrorInject";
import { getSelectedProfileId } from "legacy/selectors/applicationSelectors";
import { getCurrentApplicationId } from "legacy/selectors/editorSelectors";
import { getDeveloperPreferences } from "legacy/selectors/sagaSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import PerformanceTracker, {
  PerformanceName,
} from "legacy/utils/PerformanceTracker";
import { useAppSelector } from "store/helpers";
import {
  selectDatasourceById,
  selectDatasourceMetaById,
} from "store/slices/datasources";
import { getConfigIdFromIntegrationWithProfileId } from "store/slices/datasources/utils";
import { Flag } from "store/slices/featureFlags";
import { EditorModes } from "./EditorConfig";
import { cursorBetweenBinding } from "./hintHelpers";
import { EditorProps } from "./types";
import CodeEditor, { PLACEHOLDER_TEXT, SQL_MODES } from "./index";
import type { AppState } from "store/types";

type AiAssistantState = {
  isOpen: boolean;
  position: { left: number; top: number };
  cursorPositionStart: { line: number; ch: number };
  cursorPositionEnd: { line: number; ch: number };
  selectedText: string;
  editorContents: string;
  availableOptions: AiAssistantOptionType[];
  overrideCharacter: number | undefined;
  maxWidth: number;
  maxHeight: number;
  isPinned: boolean;
  canResize: boolean;
};

const PROPERTY_PANEL_MAX_WIDTH = 450;
const EDITOR_MAX_WIDTH = 1200;
const OPTIONS_LIST_WIDTH = 150;
const VERTICAL_PADDING_AROUND_ASSISTANT = 20;
const HORIZONTAL_PADDING_AROUND_ASSISTANT = 60;
const CHAR_WIDTH = 7;
const OPTIONS_LIST_HEIGHT = 150;

// We need to manage the AiAssistant here because it needs to render a CodeEditor itself (which will cause a
// circular dependency if we import it in the CodeEditor component)
const CodeEditorWithAiAssistant = (
  props: {
    datasourceId?: string;
    isInPropertyPanel: boolean;
    shouldOpenIconSelectorForIconBindings?: boolean;
    isIconSelectorOpen?: boolean;
  } & EditorProps,
) => {
  const { mode, isInPropertyPanel, expected, datasourceId } = props;
  const editorRef = useRef<CodeMirror.Editor>();
  const editorWrapperElementRef = useRef<HTMLElement | null>(null);
  // generate unique id for editor
  const editorId = useMemo(() => {
    return uuidv4();
  }, []);
  const currentUser = useSelector(getCurrentUser);
  const currentApplicationId = useSelector(getCurrentApplicationId);
  const codeCompletionEnabled = useFeatureFlag(
    Flag.UI_AI_CODE_COMPLETION_ENABLED,
  );
  const aiContextModeOverride = useFeatureFlag(
    Flag.UI_AI_CODE_COMPLETION_CONTEXT_MODE_OVERRIDE,
  );
  const aiCodeCompletionEnabledConfig = useFeatureFlag(
    Flag.AI_CODE_COMPLETION_ENABLED_CONFIG,
  ) as { users: string[]; applicationIds: string[] };

  const { activeEditorId, setActiveEditor } = useContext(AiAssistantContext);
  useEffect(
    () => {
      return () => {
        if (activeEditorId === editorId) {
          // clear active editor on unmount
          setActiveEditor(null);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ); // runs only when the component unmounts

  const [aiAssistantState, updateAiAssistantState] = useReducer(
    (prev: AiAssistantState, next: Partial<AiAssistantState>) => {
      return { ...prev, ...next };
    },
    {
      isOpen: false,
      position: { left: 0, top: 0 },
      cursorPositionStart: { line: 0, ch: 0 },
      cursorPositionEnd: { line: 0, ch: 0 },
      selectedText: "",
      editorContents: "",
      availableOptions: [] as AiAssistantOptionType[],
      overrideCharacter: undefined,
      maxWidth: PROPERTY_PANEL_MAX_WIDTH,
      maxHeight: PROPERTY_PANEL_MAX_WIDTH,
      isPinned: true,
      canResize: false,
    },
  );
  const isAiAssistantEnabled = useFeatureFlag(Flag.ENABLE_AI_ASSISTANT);

  const showAiAssistant =
    aiAssistantState.isOpen && activeEditorId === editorId;

  const datasource = useSelector((state: AppState) =>
    selectDatasourceById(state, datasourceId),
  );
  const pluginId = datasource?.pluginId;
  const profileId = useSelector(getSelectedProfileId);

  const configurationId = useSelector(
    profileId && (datasource as IntegrationDto)?.configurations
      ? (state: AppState) =>
          getConfigIdFromIntegrationWithProfileId(
            datasource as IntegrationDto,
            profileId,
          )
      : () => null,
  );
  const datasourceMeta = useAppSelector(
    datasourceId != null
      ? (state) => selectDatasourceMetaById(state, datasourceId)
      : () => null,
  );

  const onOptionSelected = useCallback(() => {
    updateAiAssistantState({ canResize: true });
  }, []);

  const developerPreferences = useSelector(getDeveloperPreferences);
  const languagesToAiCompletion = developerPreferences.shared
    .languagesToAiCompletion as SUPPORTED_FORMATTER_TYPE[];
  const aiContextMode = useMemo(
    () =>
      !isEmpty(aiContextModeOverride)
        ? (aiContextModeOverride as AiContextMode)
        : developerPreferences.shared.aiContextMode,
    [aiContextModeOverride, developerPreferences.shared.aiContextMode],
  );

  const aiOptions = useMemo(() => {
    if (mode === EditorModes.PYTHON) {
      // Generate Python + Explain Python
      return [
        {
          flowType: FlowType.GENERATE,
          syntax: SyntaxType.PYTHON,
        },
        {
          flowType: FlowType.EDIT,
          syntax: SyntaxType.PYTHON,
        },
        {
          flowType: FlowType.EXPLAIN,
          syntax: SyntaxType.PYTHON,
        },
      ];
    }
    if (
      SQL_MODES.includes(mode) ||
      mode === EditorModes.JSON ||
      mode === EditorModes.JSON_WITH_BINDING
    ) {
      const syntax = getSyntaxForPlugin(pluginId);
      if (!syntax) return [];
      // Generate SQL, Explain SQL, Generate JS (in binding)
      return [
        {
          flowType: FlowType.GENERATE,
          syntax,
        },
        {
          flowType: FlowType.EDIT,
          syntax,
        },
        {
          flowType: FlowType.EXPLAIN,
          syntax,
        },
        {
          flowType: FlowType.GENERATE,
          syntax: SyntaxType.BINDING,
        },
      ];
    }
    if (isInPropertyPanel && mode !== EditorModes.JAVASCRIPT) {
      // special case for iframe HTML
      if (
        expected &&
        typeof expected === "string" &&
        expected.toUpperCase().includes("HTML")
      ) {
        // Generate HTML, explain HTML, generate JS (bindings)
        return [
          {
            flowType: FlowType.GENERATE,
            syntax: SyntaxType.HTML,
          },
          {
            flowType: FlowType.EDIT,
            syntax: SyntaxType.HTML,
          },
          {
            flowType: FlowType.EXPLAIN,
            syntax: SyntaxType.HTML,
          },
          {
            flowType: FlowType.GENERATE,
            syntax: SyntaxType.BINDING,
          },
        ];
      }

      // Generate JS (binding), mock data, explain JS
      return [
        {
          flowType: FlowType.GENERATE,
          syntax: SyntaxType.BINDING,
        },
        {
          flowType: FlowType.MOCK,
          syntax: SyntaxType.JSON,
        },
        {
          flowType: FlowType.EXPLAIN,
          syntax: SyntaxType.BINDING,
        },
      ];
    }

    if (mode === EditorModes.TEXT_WITH_BINDING) {
      // Generate JS (binding), explain JS
      return [
        {
          flowType: FlowType.GENERATE,
          syntax: SyntaxType.BINDING,
        },
        { flowType: FlowType.EDIT, syntax: SyntaxType.BINDING },
        {
          flowType: FlowType.EXPLAIN,
          syntax: SyntaxType.BINDING,
        },
      ];
    }
    if (mode === EditorModes.JAVASCRIPT) {
      return [
        // Generate JS, explain JS
        {
          flowType: FlowType.GENERATE,
          syntax: SyntaxType.JAVASCRIPT,
        },
        { flowType: FlowType.EDIT, syntax: SyntaxType.JAVASCRIPT },
        {
          flowType: FlowType.EXPLAIN,
          syntax: SyntaxType.JAVASCRIPT,
        },
      ];
    }
    return [];
  }, [mode, isInPropertyPanel, expected, pluginId]);

  const editorRefresh = useRef<undefined | (() => void)>(undefined);

  // This takes into account the feature flag and the config.
  const aiCompletionFlaggedOn = useMemo(() => {
    // If the flag is off for the entire org, don't enable it ever
    if (!codeCompletionEnabled) return false;

    if (!isEmpty(aiCodeCompletionEnabledConfig)) {
      // If users are specified, only enable for the users in the list
      if (
        aiCodeCompletionEnabledConfig.users &&
        !aiCodeCompletionEnabledConfig.users.includes(currentUser?.email ?? "")
      ) {
        return false;
      }

      // If only certain apps are allowed, check that the current app is in the
      // list if we're in an app.
      // Note: right now if only specific apps are allowed, we don't allow it in
      // workflows/jobs.
      if (
        aiCodeCompletionEnabledConfig.applicationIds &&
        !aiCodeCompletionEnabledConfig.applicationIds.includes(
          currentApplicationId ?? "",
        )
      ) {
        return false;
      }
    }

    return true;
  }, [
    codeCompletionEnabled,
    aiCodeCompletionEnabledConfig,
    currentApplicationId,
    currentUser?.email,
  ]);

  const canCompleteCode = useMemo(() => {
    if (!aiCompletionFlaggedOn) return false;

    const isPropertyPane =
      props.dataTreePath != null || props.isInPropertyPanel;
    if (isPropertyPane) return false;
    if (props.mode.toLowerCase() === EditorModes.PYTHON) {
      return languagesToAiCompletion.includes(EditorModes.PYTHON);
    }
    if (SQL_MODES.includes(props.mode.toLowerCase())) {
      return languagesToAiCompletion.includes(EditorModes.SQL_WITH_BINDING);
    }
    if (props.mode.toLowerCase() === EditorModes.JAVASCRIPT) {
      return languagesToAiCompletion.includes(EditorModes.JAVASCRIPT);
    }

    return false;
  }, [
    props.dataTreePath,
    props.isInPropertyPanel,
    props.mode,
    aiCompletionFlaggedOn,
    languagesToAiCompletion,
  ]);

  const codeMirrorState = useMemo(() => {
    if (canCompleteCode) {
      return new CodeMirrorState(currentUser?.id ?? "", undefined, false);
    }
    return undefined;
  }, [currentUser?.id, canCompleteCode]);

  useEffect(() => {
    if (canCompleteCode && codeMirrorState) {
      codeMirrorState.metadata = datasourceMeta?.metadata;
    }
  }, [datasourceMeta?.metadata, codeMirrorState, canCompleteCode]);

  const { onEditorMount: propsOnEditorMount } = props;
  const onEditorMount = useCallback(
    (editor: CodeMirror.Editor) => {
      if (canCompleteCode && codeMirrorState) {
        // TODO: Can get rid of this arg if the useEffect above works.
        const codeCompletionHook = codeMirrorState.editorHook(
          datasourceMeta?.metadata,
        );
        codeCompletionHook(editor);
      }
      props.onEditorMount?.(editor);
      editorRef.current = editor;
      // get element for editor to track resizing
      editorWrapperElementRef.current = editor.getWrapperElement();
      editorRefresh.current = () => {
        editor.refresh();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propsOnEditorMount, canCompleteCode, datasourceMeta?.metadata],
  );

  const calculateAssistantPosition = useCallback(
    (overrideCharacter: number | undefined) => {
      if (!editorRef.current) {
        return;
      }
      const cursor = editorRef.current.getCursor("to");
      const cursorToUse =
        overrideCharacter != null ? { line: cursor.line, ch: 0 } : cursor;
      const position = editorRef.current.cursorCoords(cursorToUse, "page");
      const relativePosition = editorRef.current.cursorCoords(
        cursorToUse,
        "local",
      );
      if (overrideCharacter != null && overrideCharacter !== 0) {
        // if the override character is not 0, we need to move the position to the right
        // we do this manually because the cursor might not be available at the desired char position
        // if the line isn't long enough
        position.left += overrideCharacter * CHAR_WIDTH;
        relativePosition.left += overrideCharacter * CHAR_WIDTH;
      }
      const maxWidth =
        editorRef.current.getWrapperElement()?.clientWidth -
        relativePosition.left -
        HORIZONTAL_PADDING_AROUND_ASSISTANT;
      let maxHeight =
        window.innerHeight - position.top - VERTICAL_PADDING_AROUND_ASSISTANT;
      if (maxHeight < OPTIONS_LIST_HEIGHT) {
        // update the position and set the max height to be the height of the options list
        position.top -= OPTIONS_LIST_HEIGHT - maxHeight;
        maxHeight = OPTIONS_LIST_HEIGHT;
      }
      return { position, maxWidth, maxHeight };
    },
    [],
  );

  const handleResizeDragStop = useCallback((data: any) => {
    const maxHeight =
      window.innerHeight - data.y - VERTICAL_PADDING_AROUND_ASSISTANT;
    updateAiAssistantState({ isPinned: false, maxHeight });
  }, []);

  useResizeObserver(editorWrapperElementRef, () => {
    // move the ai assistant to keep it aligned with the editor
    // if it has not been manually moved/resized by the user yet
    if (showAiAssistant && editorRef.current && aiAssistantState.isPinned) {
      const result = calculateAssistantPosition(
        aiAssistantState.overrideCharacter,
      );
      if (result) {
        updateAiAssistantState(result);
      }
    }

    // if the editor is resized due to an orientation switch, this prevents the cursor being in a bad spot
    editorRefresh.current?.();
  });

  const openAiAssistant = useCallback(
    (from?: "gutter" | "placeholder") => {
      // remove focus from editor
      if (editorRef.current) {
        editorRef.current.getInputField().blur();
        const overrideCharacter =
          from === "gutter" || isInPropertyPanel
            ? 0
            : from === "placeholder"
              ? PLACEHOLDER_TEXT.length
              : undefined;
        const pos = calculateAssistantPosition(overrideCharacter);
        if (!pos) return;
        const { position, maxWidth, maxHeight } = pos;
        const editorContents = editorRef.current.getValue();
        const selectedText = editorRef.current.getSelection();
        const editorIncludesBindings = editorContents.includes("{{");
        let availableOptions = aiOptions;
        if (!editorIncludesBindings) {
          availableOptions = availableOptions.filter((opt) => {
            if (
              opt.syntax === SyntaxType.BINDING &&
              opt.flowType !== FlowType.GENERATE
            ) {
              return false;
            }
            return true;
          });
        }
        if (selectedText) {
          // hoist edit option to top
          availableOptions.sort((a, b) => {
            if (a.flowType === FlowType.EDIT) {
              return -1;
            } else {
              return 0;
            }
          });
        }

        updateAiAssistantState({
          isOpen: true,
          position,
          cursorPositionStart: editorRef.current.getCursor("from"),
          cursorPositionEnd: editorRef.current.getCursor("to"),
          selectedText,
          availableOptions,
          editorContents,
          overrideCharacter,
          maxWidth,
          isPinned: true,
          maxHeight,
          canResize: false,
        });
        setActiveEditor(editorId);
      }
    },
    [
      aiOptions,
      isInPropertyPanel,
      editorId,
      calculateAssistantPosition,
      setActiveEditor,
    ],
  );

  const closeAiAssistant = useCallback(() => {
    // put focus back to cursor position
    editorRef.current?.focus();
    editorRef.current?.setCursor(aiAssistantState.cursorPositionEnd);
    updateAiAssistantState({
      isOpen: false,
      isPinned: true,
      canResize: false,
    });
  }, [aiAssistantState.cursorPositionEnd]);

  const pasteAiWizardCode = useCallback(
    (aiOptionType: AiAssistantOptionType, code?: null | string) => {
      if (code && editorRef.current) {
        PerformanceTracker.track(PerformanceName.AI_RESPONSE_USED, {
          type: `${aiOptionType.flowType} ${aiOptionType.syntax}`,
        });

        let { cursorPositionEnd, cursorPositionStart } = aiAssistantState;
        // If it's an explanation, we need to insert it at the beginning of the selection as a comment
        if (aiOptionType.flowType === FlowType.EXPLAIN) {
          const comment = makeCommentForSyntax(aiOptionType.syntax, code ?? "");
          editorRef.current.replaceRange(comment, {
            ch: 0,
            line: cursorPositionStart.line,
          });
        } else {
          const requiresBindings = aiOptionType.syntax === SyntaxType.BINDING;
          const shouldReplaceEverything =
            aiOptionType.flowType === FlowType.EDIT &&
            !aiAssistantState.selectedText;
          if (shouldReplaceEverything) {
            // update the range to be the entire editor contents
            cursorPositionStart = { line: 0, ch: 0 };
            cursorPositionEnd = {
              line: editorRef.current.lineCount(),
              ch: editorRef.current.getLine(editorRef.current.lineCount() - 1)
                .length,
            };
          }
          // if the cursor is already between {{}}, just paste the code as is
          const isCursorBetweenBinding = cursorBetweenBinding(
            editorRef.current,
            cursorPositionEnd,
          );
          if (requiresBindings) {
            if (isCursorBetweenBinding) {
              editorRef.current.replaceRange(
                code,
                cursorPositionStart,
                cursorPositionEnd,
              );
            } else {
              // if the cursor is not between {{}}, add the binding
              editorRef.current.replaceRange(
                `{{${code}}}`,
                cursorPositionStart,
                cursorPositionEnd,
              );
            }
          } else {
            if (!isCursorBetweenBinding) {
              editorRef.current.replaceRange(
                code,
                cursorPositionStart,
                cursorPositionEnd,
              );
            } else {
              // remove bindings and paste code
              editorRef.current.replaceRange(
                code,
                { line: cursorPositionEnd.line, ch: cursorPositionEnd.ch - 2 },
                { line: cursorPositionEnd.line, ch: cursorPositionEnd.ch + 2 },
              );
            }
          }
        }
      }
      // use a set timeout to close the assistant after the editor has update with the new code
      setTimeout(() => {
        closeAiAssistant();
      }, 50);
    },
    [aiAssistantState, closeAiAssistant],
  );

  const [aiAssistantX, aiAssistantY, aiAssistantWidth] = useMemo(() => {
    let x, y;
    if (isInPropertyPanel) {
      y = aiAssistantState.position.top;
      // shift over to the left. if we can't resize, shift by the options width
      // otherwise, shift by the regular modal width
      if (!aiAssistantState.canResize) {
        x =
          aiAssistantState.position.left -
          VERTICAL_PADDING_AROUND_ASSISTANT -
          OPTIONS_LIST_WIDTH;
      } else {
        x =
          aiAssistantState.position.left -
          VERTICAL_PADDING_AROUND_ASSISTANT -
          PROPERTY_PANEL_MAX_WIDTH;
      }
    } else {
      x = aiAssistantState.position.left;
      y = aiAssistantState.position.top + VERTICAL_PADDING_AROUND_ASSISTANT;
    }
    const width = isInPropertyPanel
      ? PROPERTY_PANEL_MAX_WIDTH
      : Math.min(EDITOR_MAX_WIDTH, aiAssistantState.maxWidth);
    return [x, y, width];
  }, [
    aiAssistantState.position.left,
    aiAssistantState.position.top,
    isInPropertyPanel,
    aiAssistantState.canResize,
    aiAssistantState.maxWidth,
  ]);

  const contextProps = useMemo(() => {
    return {
      selectedText: aiAssistantState.selectedText,
      editorContents: aiAssistantState.editorContents,
      expectedDataFormat: props.expected,
      integrationId: props.datasourceId,
      configurationId: configurationId ?? undefined,
      pluginId,
      firstLineNumber: aiAssistantState.selectedText
        ? aiAssistantState.cursorPositionStart.line
        : 1,
      datasourceMeta: datasourceMeta?.metadata ?? undefined,
    };
  }, [
    aiAssistantState.selectedText,
    aiAssistantState.editorContents,
    props.expected,
    props.datasourceId,
    configurationId,
    pluginId,
    aiAssistantState.cursorPositionStart.line,
    datasourceMeta,
  ]);

  if (
    !isAiAssistantEnabled ||
    !aiOptions.length ||
    props.shouldOpenIconSelectorForIconBindings
  ) {
    return <CodeEditor {...props} />;
  }

  return (
    <div>
      <CodeEditor
        {...props}
        codeMirrorState={codeMirrorState}
        aiContextMode={aiContextMode}
        onEditorMount={onEditorMount}
        openAiAssistant={openAiAssistant}
        maintainSelectionOnBlur={true}
        aiMenuOpen={showAiAssistant}
        isIconSelectorOpen={props.isIconSelectorOpen}
      />
      {showAiAssistant && (
        <AiAssistantPositioner
          x={aiAssistantX}
          y={aiAssistantY}
          width={aiAssistantWidth}
          isPinned={aiAssistantState.isPinned}
          maxHeight={aiAssistantState.maxHeight}
          canResizeAssisant={aiAssistantState.canResize}
          onDragResizeStop={handleResizeDragStop}
          availableOptions={aiAssistantState.availableOptions}
          onClose={closeAiAssistant}
          onConfirm={pasteAiWizardCode}
          onOptionSelected={onOptionSelected}
          contextProps={contextProps}
          scope={ScopeType.EDITOR}
        />
      )}
    </div>
  );
};

export default CodeEditorWithAiAssistant;
