import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type PasteInsertionIndexes } from "hooks/ui/usePasteWidget";

type InsertionContext = {
  mousePosition?: {
    x: number;
    y: number;
  };
  cursorInsertionIndexes?: PasteInsertionIndexes;
  insertionTargetId?: string;
};

const initialState: {
  showInsertUIBlocksModal: boolean;
  insertionContext: InsertionContext;
} = {
  showInsertUIBlocksModal: false,
  insertionContext: {},
};

export const uiBlocksSlice = createSlice({
  name: "uiBlocks",
  initialState,
  reducers: {
    showUiBlocksModal: (state) => {
      state.showInsertUIBlocksModal = true;
    },
    closeUiBlocksModal: (state) => {
      state.showInsertUIBlocksModal = false;
    },
    setInsertionContext: (state, action: PayloadAction<InsertionContext>) => {
      state.insertionContext = action.payload;
    },
  },
});

export const { showUiBlocksModal, closeUiBlocksModal, setInsertionContext } =
  uiBlocksSlice.actions;
