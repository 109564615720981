import React, { useMemo } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  position: relative;

  &[data-size="large"] {
    width: 14px;
    height: 14px;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 0px;
    border: 1px solid transparent;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &[data-size="large"] div {
    width: 14px;
    height: 14px;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Background = styled.div<{ size: "large" | "small" }>`
  height: ${(props) => (props.size === "large" ? "18px" : "14px")};
  width: ${(props) => (props.size === "large" ? "18px" : "14px")};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export default function LoadingIndicator({
  useWhiteBackground,
  size = "large",
}: {
  useWhiteBackground?: boolean;
  size?: "large" | "small";
}) {
  const [bgStyle, spinnerStyle] = useMemo(() => {
    return [
      {
        backgroundColor: useWhiteBackground
          ? "transparent"
          : colors.ACCENT_BLUE_500,
      },
      {
        borderTopColor: useWhiteBackground
          ? colors.ACCENT_BLUE_500
          : colors.WHITE,
      },
    ];
  }, [useWhiteBackground]);
  return (
    <Background className="loader" style={bgStyle} size={size}>
      <Spinner data-size={size}>
        <div style={spinnerStyle}></div>
        <div style={spinnerStyle}></div>
        <div style={spinnerStyle}></div>
        <div style={spinnerStyle}></div>
      </Spinner>
    </Background>
  );
}
