import { useEffect, useRef, useCallback } from "react";
import { useAppDispatch } from "store/helpers";
import { addNewPromise } from "store/utils/resolveIdSingleton";

import type {
  SagaPayload,
  SagaResult,
  FullSagaDefinition,
} from "../../store/utils/saga";

// Starts a saga and returns a promise that resolves when the saga is done
export default function useSaga<
  TDefinition extends FullSagaDefinition<any, any>,
  TResult extends SagaResult<TDefinition>,
  TPayload extends SagaPayload<TDefinition>,
>(
  saga: TDefinition,
  options?: {
    resolveAfterUnmounted?: boolean;
    throwErrorOutsideSaga?: boolean;
  },
): [(payload: TPayload) => Promise<TResult>] {
  const dispatch = useAppDispatch();
  const shouldResolvePromiseRef = useRef(true);

  useEffect(() => {
    return () => {
      // Default behavior is that after the component is unmounted, it will never resolve or reject the promise
      // This means that any callbacks won't fire)
      if (!options?.resolveAfterUnmounted) {
        shouldResolvePromiseRef.current = false;
      }
    };
  }, [saga.name, dispatch, options?.resolveAfterUnmounted]);

  const execute = useCallback(
    (payload: TPayload): Promise<TResult> => {
      return new Promise<TResult>((resolve, reject) => {
        const augmentedResolve = (payload: TResult) => {
          if (shouldResolvePromiseRef.current) {
            resolve(payload);
          }
        };

        const augmentedReject = (e: Error) => {
          if (
            options?.throwErrorOutsideSaga &&
            shouldResolvePromiseRef.current
          ) {
            reject(e);
          }
        };

        const promiseId = addNewPromise(
          augmentedResolve,
          false,
          options?.throwErrorOutsideSaga ? augmentedReject : undefined,
        );

        dispatch(
          saga.start.create({
            ...payload,
            // In most cases, we do not need to handle failures for promises returned from useSaga in React
            // the saga error helper and call helpers in utils/client.ts should have handled it
            // Keeping the reject will result in unhandled rejection in React
            resolveId: promiseId,
            throwErrorOutsideSaga: options?.throwErrorOutsideSaga,
          }),
        );
      });
    },
    [dispatch, options?.throwErrorOutsideSaga, saga.start],
  );

  return [execute];
}
