import {
  FlattenedWidgetProps,
  WidgetProps,
  WidgetTypes,
} from "@superblocksteam/shared";
import { WidgetAddChild } from "legacy/actions/pageActions";
import WidgetConfigResponse from "legacy/mockResponses/WidgetConfigResponse";
import { buildView } from "legacy/utils/WidgetPropsUtils";
import { generateReactKey } from "legacy/utils/generators";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { Flags } from "store/slices/featureFlags/models/Flags";
import type { WidgetTypeToPropType } from "legacy/widgets";

const PAGE_LAYOUT_WIDGETS: WidgetTypes[] = [
  WidgetTypes.PAGE_WIDGET,
  WidgetTypes.CANVAS_WIDGET,
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.SECTION_WIDGET,
  WidgetTypes.FORM_WIDGET,
];

const OVERLAY_WIDGETS: WidgetTypes[] = [
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
];

/**
 * Checks if a widget is empty.
 *
 * A widget is considered empty if it is one of the layout widgets (e.g., Canvas, Container, Tabs, Section, Form, Modal, Slideout)
 * and it has no children or all its children are also empty.
 *
 * @param widget - The widget to check.
 * @returns {boolean} - Returns true if the widget is empty, false otherwise.
 */
export const isWidgetEmpty = (
  widget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
  ignoreOverlayWidgets = false,
): boolean => {
  // this is when we want to consider only layout components without widgets inside modals or slideouts
  if (
    ignoreOverlayWidgets &&
    OVERLAY_WIDGETS.includes(widget.type as WidgetTypes)
  ) {
    return true;
  }

  if (
    [...PAGE_LAYOUT_WIDGETS, ...OVERLAY_WIDGETS].includes(
      widget.type as WidgetTypes,
    )
  ) {
    return (
      !widget.children ||
      widget.children?.every((childId) =>
        widgets[childId]
          ? isWidgetEmpty(widgets[childId], widgets, ignoreOverlayWidgets)
          : true,
      )
    );
  }

  return false;
};

/**
 * Checks if a given widget is the first child withing its parent.
 *
 * @param widget - The widget to check.
 * @param widgets - The state of all widgets.
 * @returns `true` if the widget is the first child.
 */
export const isFirstChild = (
  widget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
) => {
  const parentId = widget.parentId as string;
  return !!(
    parentId &&
    widgets[parentId] &&
    widgets[parentId].children?.[0] === widget.widgetId
  );
};

/**
 * Checks if a given widget is inside a specific type of widget.
 *
 * @param widget - The widget to check.
 * @param widgets - The state of all widgets.
 * @param type - The type of widget to check against.
 * @returns `true` if the widget is inside the specified type of widget, otherwise `false`.
 */
export function isChildOfType(
  widget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
  type: WidgetTypes,
): boolean {
  if (widget.type === type) return true;
  const parentId = widget.parentId as string;
  return parentId && widgets[parentId]
    ? isChildOfType(widgets[parentId], widgets, type)
    : false;
}

/**
 * Generates a sample widget that complies with the blueprint configuration for a specific
 * widget type.
 *
 * @param type - The type of the widget to generate.
 * @param flags - Optional feature flags to customize the widget generation.
 * @param props - Optional additional properties to apply to the generated widget.
 * @returns A sample widget with the specified type and properties.
 */
const getSampleWidgetFromBlueprint = (
  type: WidgetTypes,
  flags: Flags = {},
  props?: Record<string, any>,
): WidgetProps => {
  const configProps = WidgetConfigResponse.getCreateConfig(
    type as keyof WidgetTypeToPropType,
    flags,
  );

  const sampleWidget: WidgetProps = {
    ...configProps,
    widgetId: generateReactKey(),
    children: [],
    left: { mode: "gridUnit" as const, value: 0 },
    top: { mode: "gridUnit" as const, value: 0 },
    type,
    ...props,
  };

  const widgetBlueprint = props?.blueprint || configProps.blueprint;

  if (widgetBlueprint && widgetBlueprint.view) {
    const childWidgetList = buildView(
      widgetBlueprint.view,
      sampleWidget.widgetId,
      sampleWidget as any,
    );

    sampleWidget.children = childWidgetList.map((child: WidgetAddChild) =>
      getSampleWidgetFromBlueprint(
        child.type as WidgetTypes,
        flags,
        child.props,
      ),
    );
  }

  delete sampleWidget.blueprint;
  return sampleWidget;
};

/**
 * Checks if a widget is in its default state.
 *
 * A widget is considered to be in its default state if it matches the default structure
 * of the widget type.
 *
 * @param widget - The widget to check.
 * @param widgets - The state of all widgets.
 * @param flags - Optional feature flags to customize the widget generation.
 * @returns {boolean} - Returns true if the widget is in its default state, false otherwise.
 */
export const isInDefaultState = (
  widget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
  flags?: Flags,
) => {
  const sampleWidget = getSampleWidgetFromBlueprint(
    widget.type as WidgetTypes,
    flags,
  );

  return areWidgetsStructuresEqual(
    sampleWidget,
    getWidgetPropsFromFlattenedWidget(widget, widgets),
  );
};

/**
 * Converts a flattened widget representation into a nested widget structure.
 *
 * @param flattenedWidget - The flattened widget to convert.
 * @param widgets - The state of all widgets in the canvas.
 * @returns {WidgetProps} - The nested widget structure.
 */
const getWidgetPropsFromFlattenedWidget = (
  flattenedWidget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
): WidgetProps => {
  return {
    ...flattenedWidget,
    children:
      flattenedWidget.children?.map((child) =>
        getWidgetPropsFromFlattenedWidget(widgets[child], widgets),
      ) ?? [],
  } as WidgetProps;
};

/**
 * Compares the structure of two widgets to determine if they are equal.
 * The comparison includes the hierarchy of the widgets and their types.
 *
 * @param widget1 - The first widget to compare.
 * @param widget2 - The second widget to compare.
 * @returns {boolean} - Returns true if the widget structures are equal, false otherwise.
 */
const areWidgetsStructuresEqual = (
  widget1: WidgetProps,
  widget2: WidgetProps,
): boolean => {
  const fieldsToCompare = ["type"];
  fieldsToCompare.forEach((field) => {
    if (widget1[field] !== widget2[field]) {
      return false;
    }
  });

  if (widget1.children?.length !== widget2.children?.length) {
    return false;
  }
  return !!widget1.children?.every((child) =>
    widget2.children?.find((child2) =>
      areWidgetsStructuresEqual(child, child2),
    ),
  );
};
