import { EmailRegexString } from "@superblocksteam/shared";
import { Interweave } from "interweave";
import { ChildrenNode, Matcher, MatchResponse, Node } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import React from "react";
import { NavigationEvent } from "utils/polyfills/interceptNavigation";
import { isRelativeURL } from "utils/url";

// Custom class fixes a bug with emails like a@example.com
class CustomMatcher extends Matcher {
  match(string: string): MatchResponse<unknown> | null {
    const emailRegex = new RegExp(EmailRegexString);
    const result = string.match(emailRegex);

    if (!result) {
      return null;
    }

    return {
      index: result.index ?? 0,
      length: result[0].length,
      match: result[0],
      valid: true,
    };
  }

  replaceWith(children: ChildrenNode, props: any): Node {
    //even though EmailMatcher is put before UrlMatcher, the single character email is still parsed by UrlMatcher first
    const emailString = (children?.[0] as any)?.props?.url ?? children;
    return (
      <a
        href={`mailto:${emailString}`}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {emailString}
      </a>
    );
  }

  asTag(): string {
    return "a";
  }
}

function Link({
  href,
  target,
  children,
}: {
  href: string | null;
  target: string | null;
  children: React.ReactNode;
}) {
  return (
    <a
      href={href ?? undefined}
      target={target ?? undefined}
      rel="noopener noreferrer"
      onClick={(e) => {
        if (!href) {
          return;
        }
        const isRelative = isRelativeURL(href);
        if (target === "_blank" && "navigation" in window) {
          e.preventDefault();
          // using the full URL lets our internal navigation handle the link + new window behavior
          const targetUrl = isRelative
            ? new URL(href, document.baseURI).toString()
            : href;

          (window.navigation as any).dispatchEvent(
            new NavigationEvent(isRelative, targetUrl, undefined, false, true),
          );
        }
      }}
    >
      {children}
    </a>
  );
}

export const AutolinkedText = (props: {
  text?: string;
  target?: "_parent" | "_blank";
}) => {
  return (
    <Interweave
      content={props.text}
      transform={(node: HTMLElement, children: Node[]) => {
        if (node.tagName === "A") {
          return (
            <Link
              href={node.getAttribute("href")}
              target={node.getAttribute("target")}
            >
              {children}
            </Link>
          );
        }
      }}
      matchers={[
        new CustomMatcher("email"),
        new UrlMatcher("url", undefined, ({ url, children }) => {
          let href = url;

          if (!href.match(/^https?:\/\//)) {
            href = `http://${href}`;
          }
          return (
            <a href={href} target={props.target} rel="noopener noreferrer">
              {children}
            </a>
          );
        }),
      ]}
    />
  );
};
