import styled from "styled-components";
import { colors } from "styles/colors";

export const PanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.WHITE};
`;

export const PanelSection = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: ${colors.WHITE};

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.GREY_100};
  }
`;

export const PanelHeaderSection = styled(PanelSection)`
  padding: 16px;
  background: ${colors.GREY_25};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: ${colors.GREY_800};
  font-size: 13px;
`;

export const InputLabel = styled.label`
  color: ${colors.GREY_500};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
