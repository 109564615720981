import { cloneDeep } from 'lodash';
import { PageDSL8 } from '../types';
import page7 from './pageV7';

const page: PageDSL8 = cloneDeep(page7) as unknown as PageDSL8;
page.version = 8;
page.embedding = {
  propertyMap: {}
};
delete page?.children?.[0]?.children?.[0].padding;

export default page as PageDSL8;
