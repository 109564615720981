import {
  EntityModeProfileSettings,
  EntityProfileSettings,
  IHomepageApplicationV2Dto,
  Profile,
  ViewMode,
} from "@superblocksteam/shared";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import {
  selectTestProfile as selectV1TestProfile,
  selectApiById as selectV1ApiById,
} from "store/slices/apis/selectors";
import { ApiTriggerType } from "store/slices/apis/types";
import {
  selectV2ApiById,
  selectV2TestProfile,
} from "store/slices/apisV2/selectors";
import { getApplicationSettings } from "store/slices/application/selectors";
import { getApplicationList } from "store/slices/homepage/selectors";
import { selectOnlyOrganization } from "store/slices/organizations/selectors";
import { APP_MODE, ModeMap } from "../reducers/types";
import type { AppState } from "store/types";

export const getAppMode = (state: AppState) => state.legacy.entities.app.mode;

export const getResponsiveCanvasScaleFactor = (state: AppState) =>
  state.legacy.entities.app.mode !== APP_MODE.EDIT
    ? 1
    : state.legacy.entities.pageList.responsiveCanvasMetadata.canvasScaleFactor;

export const getResponsiveCanvasUnscaledWidth = (state: AppState) =>
  state.legacy.entities.pageList.responsiveCanvasMetadata.unscaledWidth;

const getResponsiveCanvasScaledWidth = (state: AppState) =>
  state.legacy.entities.pageList.responsiveCanvasMetadata.scaledWidth;

export const getScrollbarWidth = (state: AppState) =>
  state.legacy.entities.pageList.responsiveCanvasMetadata.scrollbarWidth;

export const getResponsiveCanvasWidth = createSelector(
  getResponsiveCanvasUnscaledWidth,
  getResponsiveCanvasScaledWidth,
  getAppMode,
  (unscaledWidth, scaledWidth, appMode) => {
    return appMode === APP_MODE.EDIT ? scaledWidth : unscaledWidth;
  },
);

// TODO: Demo apps currently don't have anything in their schema to indicate
// that they are a demo app, so we just look at the name.
const demoPrefix = "[Demo]";
export const getDemoApplication = createSelector(
  getApplicationList,
  (applications): IHomepageApplicationV2Dto | undefined => {
    const apps =
      applications.filter((app) => app.name.startsWith(demoPrefix)) ?? [];
    return apps.length > 0 ? apps[0] : undefined;
  },
);

export const getAppViewMode = (state: AppState) => {
  const appMode = state.legacy.entities.app.mode;
  return appMode ? ModeMap[appMode] : undefined;
};

export const getAppProfilesInCurrentMode = (state: AppState) =>
  state.legacy.entities.app.profiles;

export const getSelectedProfileId = (state: AppState) =>
  state.legacy.entities.app.selectedProfileId;

export type ProfilesWithModes = {
  [ViewMode.EDITOR]: {
    default?: Profile;
    available?: Profile[];
  };
  [ViewMode.PREVIEW]?: {
    default?: Profile;
    available?: Profile[];
  };
  [ViewMode.DEPLOYED]: {
    default?: Profile;
    available?: Profile[];
  };
};

const getDefaultAndAvailableProfilesByMode = (
  profileIds:
    | {
        editor: EntityModeProfileSettings;
        preview?: EntityModeProfileSettings;
        deployed: EntityModeProfileSettings;
      }
    | undefined,
  orgProfiles: Profile[] | undefined,
  mode: ViewMode,
) => {
  return {
    default: orgProfiles?.find(
      (p) => p.id === profileIds?.[mode]?.defaultProfileId,
    ),
    available: profileIds?.[mode]?.availableProfileIds
      ?.map((id: string) => orgProfiles?.find((p) => p.id === id))
      ?.filter(Boolean) as Profile[],
  };
};
export const getAppProfiles = createSelector(
  getApplicationSettings,
  selectOnlyOrganization,
  (settings, orgData) => {
    const profileIds = settings?.profiles;
    const orgProfiles = orgData?.profiles;
    return {
      editor: getDefaultAndAvailableProfilesByMode(
        profileIds,
        orgProfiles,
        ViewMode.EDITOR,
      ),
      preview: getDefaultAndAvailableProfilesByMode(
        profileIds,
        orgProfiles,
        ViewMode.PREVIEW,
      ),
      deployed: getDefaultAndAvailableProfilesByMode(
        profileIds,
        orgProfiles,
        ViewMode.DEPLOYED,
      ),
    };
  },
);

const convertV2ProfilesToV1Profiles = (v2Profiles: {
  editor?: {
    default?: string;
    available?: string[];
  };
  deployed?: {
    default?: string;
    available?: string[];
  };
  preview?: {
    default?: string;
    available?: string[];
  };
}): EntityProfileSettings["profiles"] => {
  return {
    [ViewMode.EDITOR]: {
      availableProfileIds: v2Profiles.editor?.available ?? [],
      defaultProfileId: v2Profiles.editor?.default ?? "",
    },
    [ViewMode.DEPLOYED]: {
      availableProfileIds: v2Profiles.deployed?.available ?? [],
      defaultProfileId: v2Profiles.deployed?.default ?? "",
    },
    ...(v2Profiles.preview
      ? {
          [ViewMode.PREVIEW]: {
            availableProfileIds: v2Profiles.preview.available ?? [],
            defaultProfileId: v2Profiles.preview.default ?? "",
          },
        }
      : {}),
  };
};

export const getWorkflowProfiles = createCachedSelector(
  selectV1ApiById,
  selectV2ApiById,
  selectOnlyOrganization,
  (v1Api, v2Api, orgData) => {
    let profileIds: any = (v2Api ?? v1Api)?.settings?.profiles;
    if (
      v2Api &&
      v2Api.triggerType === ApiTriggerType.SCHEDULE &&
      v2Api.apiPb?.trigger?.job?.options?.profiles?.modes
    ) {
      const jobProfileIds = v2Api.apiPb?.trigger?.job?.options?.profiles?.modes;
      profileIds = convertV2ProfilesToV1Profiles(jobProfileIds);
    }
    const orgProfiles = orgData?.profiles;
    return {
      editor: getDefaultAndAvailableProfilesByMode(
        profileIds,
        orgProfiles,
        ViewMode.EDITOR,
      ),
      deployed: getDefaultAndAvailableProfilesByMode(
        profileIds,
        orgProfiles,
        ViewMode.DEPLOYED,
      ),
    };
  },
)((state, apiId) => apiId);

export const getProfileForTest = createCachedSelector(
  selectV1TestProfile,
  selectV2TestProfile,
  getWorkflowProfiles,
  (
    v1TestProfile: string | undefined,
    v2TestProfile: string | undefined,
    profiles: ProfilesWithModes | undefined,
  ) => {
    const testProfile = v1TestProfile ?? v2TestProfile;
    // testProfile is what user typed in the configuration form
    // this selector is used in edit mode only
    const validTestProfile = profiles?.editor?.available?.find(
      (profile) => profile.key === testProfile,
    );
    return testProfile
      ? (validTestProfile ?? { key: testProfile })
      : profiles?.editor?.default;
  },
)((state, apiId) => apiId);
