import { Plugin } from '../types';

import {
  // AdlsPlugin,
  AirtablePlugin,
  AnthropicPlugin,
  AsanaPlugin,
  AthenaPlugin,
  BigQueryPlugin,
  BitbucketPlugin,
  BoxPlugin,
  CircleCIPlugin,
  CockroachDBPlugin,
  CoherePlugin,
  ConfluentPlugin,
  CosmosDbPlugin,
  CouchbasePlugin,
  DatabricksPlugin,
  DatadogPlugin,
  DropboxPlugin,
  DynamoDBPlugin,
  ElasticSearchPlugin,
  EmailPlugin,
  FireworksPlugin,
  FrontPlugin,
  GCSPlugin,
  GeminiPlugin,
  GitHubPlugin,
  GoogleAnalyticsPlugin,
  GoogleDrivePlugin,
  GoogleSheetsPlugin,
  GraphQLIntegrationPlugin,
  GraphQLPlugin,
  GroqPlugin,
  HubSpotPlugin,
  IntercomPlugin,
  JavascriptPlugin,
  JiraPlugin,
  KafkaPlugin,
  KinesisPlugin,
  LaunchDarklyPlugin,
  MariaDBPlugin,
  MicrosoftSQLPlugin,
  MistralPlugin,
  MongoDBPlugin,
  MySQLPlugin,
  NotionPlugin,
  OpenAiPlugin,
  OracleDbPlugin,
  PagerDutyPlugin,
  PerplexityPlugin,
  PostgresPlugin,
  PythonPlugin,
  RedisPlugin,
  RedpandaPlugin,
  RedshiftPlugin,
  RestApiIntegrationPlugin,
  RestApiPlugin,
  RocksetPlugin,
  S3Plugin,
  SalesforcePlugin,
  SegmentPlugin,
  SendGridPlugin,
  SlackPlugin,
  SmtpPlugin,
  SnowflakePlugin,
  StabilityAiPlugin,
  StripePlugin,
  SuperblocksOcrPlugin,
  TwilioPlugin,
  WorkflowPlugin,
  ZendeskPlugin,
  ZoomPlugin
} from './templates';
import { AkeylessSecretsManagerPlugin, AWSSecretsManagerPlugin, GCPSecretsManagerPlugin, HashicorpVaultPlugin } from './templates/secrets';

export class PluginRegistry {
  plugins: Map<string, Plugin>;

  constructor(plugins: Record<string, Plugin>) {
    this.plugins = new Map<string, Plugin>(Object.entries(plugins));
  }

  get(pluginId: string): Plugin | undefined {
    return this.plugins.get(pluginId);
  }

  getAll(): Plugin[] {
    const plugins = Array.from(this.plugins.values());
    return plugins;
  }

  getByIDs(ids: string[]): Plugin[] {
    const plugins: Plugin[] = [];
    for (const plugin of this.plugins.values()) {
      if (ids.includes(plugin.id)) {
        plugins.push(plugin);
      }
    }
    return plugins;
  }

  defaultPlugins(): Plugin[] {
    return [EmailPlugin, GraphQLPlugin, JavascriptPlugin, PythonPlugin, RestApiPlugin, SuperblocksOcrPlugin, WorkflowPlugin];
  }
}

// TODO(alex): get rid of googleSheetsClientId and googleSheetsRedirectPath,
// read this from a shared config
export const RegisteredPlugins = (googleSheetsClientId: string): PluginRegistry =>
  new PluginRegistry({
    // TODO: (joey) enable these when ready to release
    // [AivenPlugin.id]: AivenPlugin,
    [ConfluentPlugin.id]: ConfluentPlugin,
    [RedpandaPlugin.id]: RedpandaPlugin,
    [KafkaPlugin.id]: KafkaPlugin,
    [KinesisPlugin.id]: KinesisPlugin,
    // [MskPlugin.id]: MskPlugin,
    // [AdlsPlugin.id]: AdlsPlugin,
    [RedisPlugin.id]: RedisPlugin,
    [CosmosDbPlugin.id]: CosmosDbPlugin,
    [AthenaPlugin.id]: AthenaPlugin,
    [AirtablePlugin.id]: AirtablePlugin,
    [AsanaPlugin.id]: AsanaPlugin,
    [BigQueryPlugin.id]: BigQueryPlugin,
    [BitbucketPlugin.id]: BitbucketPlugin,
    [BoxPlugin.id]: BoxPlugin,
    [CircleCIPlugin.id]: CircleCIPlugin,
    [CouchbasePlugin.id]: CouchbasePlugin,
    [CockroachDBPlugin.id]: CockroachDBPlugin,
    [DatabricksPlugin.id]: DatabricksPlugin,
    [DatadogPlugin.id]: DatadogPlugin,
    [DropboxPlugin.id]: DropboxPlugin,
    [DynamoDBPlugin.id]: DynamoDBPlugin,
    [ElasticSearchPlugin.id]: ElasticSearchPlugin,
    [EmailPlugin.id]: EmailPlugin,
    [FrontPlugin.id]: FrontPlugin,
    [GCSPlugin.id]: GCSPlugin,
    [GitHubPlugin.id]: GitHubPlugin,
    [GoogleAnalyticsPlugin.id]: GoogleAnalyticsPlugin,
    [GoogleDrivePlugin.id]: GoogleDrivePlugin,
    [GraphQLIntegrationPlugin.id]: GraphQLIntegrationPlugin,
    [GraphQLPlugin.id]: GraphQLPlugin,
    [HubSpotPlugin.id]: HubSpotPlugin,
    [IntercomPlugin.id]: IntercomPlugin,
    [JavascriptPlugin.id]: JavascriptPlugin,
    [JiraPlugin.id]: JiraPlugin,
    [LaunchDarklyPlugin.id]: LaunchDarklyPlugin,
    [MariaDBPlugin.id]: MariaDBPlugin,
    [MicrosoftSQLPlugin.id]: MicrosoftSQLPlugin,
    [MongoDBPlugin.id]: MongoDBPlugin,
    [MySQLPlugin.id]: MySQLPlugin,
    [NotionPlugin.id]: NotionPlugin,
    [OpenAiPlugin.id]: OpenAiPlugin,
    [OracleDbPlugin.id]: OracleDbPlugin,
    [PagerDutyPlugin.id]: PagerDutyPlugin,
    [PostgresPlugin.id]: PostgresPlugin,
    [PythonPlugin.id]: PythonPlugin,
    [RedisPlugin.id]: RedisPlugin,
    // TODO: (joey) enable these after discussion around plugin logos
    // [RedisCloudPlugin.id]: RedisCloudPlugin,
    // [AwsElasticachePlugin.id]: AwsElasticachePlugin,
    // [AivenRedisPlugin.id]: AivenRedisPlugin,
    [RedshiftPlugin.id]: RedshiftPlugin,
    [RestApiIntegrationPlugin.id]: RestApiIntegrationPlugin,
    [RestApiPlugin.id]: RestApiPlugin,
    [RocksetPlugin.id]: RocksetPlugin,
    [S3Plugin.id]: S3Plugin,
    [SalesforcePlugin.id]: SalesforcePlugin,
    [SegmentPlugin.id]: SegmentPlugin,
    [SendGridPlugin.id]: SendGridPlugin,
    [SlackPlugin.id]: SlackPlugin,
    [SmtpPlugin.id]: SmtpPlugin,
    [SnowflakePlugin.id]: SnowflakePlugin,
    [StripePlugin.id]: StripePlugin,
    [SuperblocksOcrPlugin.id]: SuperblocksOcrPlugin,
    [TwilioPlugin.id]: TwilioPlugin,
    [WorkflowPlugin.id]: WorkflowPlugin,
    [ZendeskPlugin.id]: ZendeskPlugin,
    [ZoomPlugin.id]: ZoomPlugin,
    [AnthropicPlugin.id]: AnthropicPlugin,
    [GroqPlugin.id]: GroqPlugin,
    [MistralPlugin.id]: MistralPlugin,
    [PerplexityPlugin.id]: PerplexityPlugin,
    [FireworksPlugin.id]: FireworksPlugin,
    [StabilityAiPlugin.id]: StabilityAiPlugin,
    [CoherePlugin.id]: CoherePlugin,
    [GeminiPlugin.id]: GeminiPlugin,
    [GoogleSheetsPlugin(googleSheetsClientId).id]: GoogleSheetsPlugin(googleSheetsClientId)
  });

export const RegisteredSecrets = (): PluginRegistry => {
  return new PluginRegistry({
    [AkeylessSecretsManagerPlugin.id]: AkeylessSecretsManagerPlugin,
    [AWSSecretsManagerPlugin.id]: AWSSecretsManagerPlugin,
    [GCPSecretsManagerPlugin.id]: GCPSecretsManagerPlugin,
    [HashicorpVaultPlugin.id]: HashicorpVaultPlugin
  });
};
