import { Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { PrimaryButton } from "components/ui/Button";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { AvailablePlugin } from "./utils";

const PluginCardWrapper = styleAsClass`
    display: flex;
    border-radius: 4px;
    border: 1px solid ${colors.GREY_100};
    padding: 10px 8px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    img.plugin-img {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
    div.plugin-name {
        color: ${colors.GREY_700};
        font-weight: 500;
        font-size: 12px;
    }
    button.plugin-add {
        height: 24px;
        width: 24px;
        padding: 3px 4px 4px 3px;
    }
`;

export const PluginCard = ({
  pluginInfo,
  buttonStyle,
  isDisabled,
}: {
  pluginInfo: AvailablePlugin;
  buttonStyle: "PRIMARY" | "SECONDARY";
  isDisabled: boolean;
}) => {
  const content = (
    <div className={PluginCardWrapper}>
      <div style={{ display: "flex" }}>
        <img
          className="plugin-img"
          src={pluginInfo.iconLocation ?? ""}
          alt=""
        />
        <div className="plugin-name">{pluginInfo.pluginName}</div>
      </div>
      <PrimaryButton
        type={buttonStyle === "PRIMARY" ? "primary" : "ghost"}
        className="plugin-add"
        data-test={`create-new-integration-${pluginInfo.pluginId}`}
        disabled={isDisabled}
      >
        <PlusIcon />
      </PrimaryButton>
    </div>
  );
  if (isDisabled) {
    return (
      <Tooltip title="You do not have permission to create integration">
        {content}
      </Tooltip>
    );
  }
  return <Link to={`/integrations/${pluginInfo.pluginId}`}>{content}</Link>;
};
