import { Typography } from "antd";
import React from "react";

import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import Helm from "./Helm";

const GKE = ({
  agentHost,
  agentUrl,
  agentEnvironment,
  agentDataDomain,
  showInstruction,
}: AgentDeploymentInstructionsProps) => {
  return (
    <Typography.Paragraph>
      <Typography.Text strong>
        Create an GKE cluster using the{" "}
        <Typography.Link
          href={"https://cloud.google.com/sdk/docs/install-sdk"}
          target="_blank"
        >
          Google Cloud CLI
        </Typography.Link>
      </Typography.Text>
      <CodeBlock content={`gcloud config set project < GCP Project ID >`} />
      <CodeBlock content={`gcloud services enable container.googleapis.com`} />
      <CodeBlock
        content={`gcloud container clusters create-auto superblocks --region us-central1`}
      />
      <CodeBlock
        content={`gcloud container clusters get-credentials superblocks --region us-central1`}
      />
      <Helm
        agentHost={agentHost}
        agentUrl={agentUrl}
        agentEnvironment={agentEnvironment}
        agentDataDomain={agentDataDomain}
        showInstruction={showInstruction}
      />
    </Typography.Paragraph>
  );
};

export default GKE;
