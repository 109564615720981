import {
  SUPPORTED_FORMATTER_TYPE,
  SyntaxType,
} from "code-formatting/constants";

export enum FormatWorkerActions {
  FORMAT = "FORMAT",
}

export type FormatCodeRequest = {
  type: FormatWorkerActions;
  mode: SUPPORTED_FORMATTER_TYPE;
  id: number;
  codeToFormat: string;
  options?: { syntax?: SyntaxType };
};

export type SuccessfulResponse = {
  type: "success";
  id: number;
  formattedCode: string;
};

export type ErrorResponse = {
  type: "error";
  id: number;
  error: string;
};

export type CodeFormattingResponse = SuccessfulResponse | ErrorResponse;

export type Formatters = {
  [KEY in SUPPORTED_FORMATTER_TYPE]: {
    init?: () => Promise<unknown>;
    format: (code: string, options?: { syntax?: SyntaxType }) => string;
  };
};
