import { ApiResponseType } from "@superblocksteam/shared";
import { Typography } from "antd";
import React from "react";
import useOpenSidebar from "hooks/ui/useOpenSidebar";
import { ApiInfo } from "legacy/constants/ApiConstants";
import {
  PropertyPaneConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { getPopoverConfig } from "legacy/widgets/eventHandlerPanel";
import { VarCodeText } from "pages/Editors/ApiEditor/ControlEditor/FormComponents/common/StyledComponents";
import { colors } from "styles/colors";
import { SideBarKeys } from "../constants";

export const apiV1ResponsePropertyPane: PropertyPaneConfig<ApiInfo>[] = [
  {
    sectionName: "Event handlers",
    children: [
      getPopoverConfig<ApiInfo>(
        "onSuccess",
        "Triggers an action whenever the API is run successfully",
      ),
      getPopoverConfig<ApiInfo>(
        "onError",
        "Triggers an action whenever the API has an error",
      ),
      getPopoverConfig<ApiInfo>(
        "onCancel",
        "Triggers an action whenever the API is cancelled",
      ),
    ],
  },
];

const ToggleDeveloperPreferencesLink = () => {
  const openDeveloperPreferencesPanel = useOpenSidebar(
    SideBarKeys.DEVELOPER_PREFERENCES,
  );
  return (
    <Typography.Link onClick={openDeveloperPreferencesPanel}>
      developer preferences
    </Typography.Link>
  );
};

const SectionStyleWithBottomDivider: React.CSSProperties = {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderImage:
    "linear-gradient(to right, rgba(232, 234, 237, 1), rgba(232, 234, 237, 0.00) 100%) 1",
  maxWidth: "600px",
};

const EventHandlersChildrenStyle: React.CSSProperties = {
  padding: "6px 16px",
  border: `1px solid ${colors.GREY_100}`,
  borderRadius: "4px",
  width: "500px",
  marginTop: "16px",
  marginBottom: "20px",
};

export const apiV2ResponsePropertyPane: PropertyPaneConfig<ApiInfo>[] = [
  {
    categoryName: "",
    propertyCategory: PropsPanelCategory.Uncategorized,
    children: [
      {
        sectionName: "Response type",
        sectionStyle: {
          ...SectionStyleWithBottomDivider,
          paddingBottom: "20px",
        },
        showHeader: true,
        headerType: "Large",
        children: [
          {
            propertyName: "responseType",
            label: "",
            hideLabel: true,
            controlType: "RADIO_BUTTON",
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: ApiResponseType.SYNC,
            radioType: "RADIO",
            options: [
              {
                name: "Synchronous",
                value: ApiResponseType.SYNC,
                subtitle: "Each API run returns a single response or error",
              },
              {
                name: "Streaming",
                value: ApiResponseType.STREAM,
                subtitle: (
                  <span>
                    Each API run streams messages that can be processed using
                    the
                    <VarCodeText code>onMessage</VarCodeText> event handler
                  </span>
                ),
                tooltip:
                  "To access the API output in front-end components, write the output to a variable using the onMessage handler",
              },
            ],
          },
        ],
      },
      {
        sectionName: "Frontend event handlers",
        showHeader: true,
        headerType: "Large",
        sectionStyle: {
          ...SectionStyleWithBottomDivider,
          marginTop: "20px",
        },
        childrenStyle: EventHandlersChildrenStyle,
        subHeader:
          "Configure frontend event handlers to run in response to the API",
        helpText: (
          <>
            Use <ToggleDeveloperPreferencesLink /> to configure whether these
            handlers are triggered when you run APIs manually
          </>
        ),
        children: [
          getPopoverConfig<ApiInfo>(
            "onMessage",
            <span>
              Triggers an action whenever the streaming API sends a message.{" "}
              <a
                href="https://docs.superblocks.com/applications/streaming-applications/overview#onmessage-event-handler"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>{" "}
              about using the onMessage handler
            </span>,
            {
              hidden: (props) => props.responseType !== ApiResponseType.STREAM,
              subheader: (
                <div
                  style={{
                    fontWeight: 400,
                    color: colors.GREY_500,
                    lineHeight: "16px",
                  }}
                >
                  Access the message with{" "}
                  <VarCodeText code>message.value</VarCodeText>{" "}
                </div>
              ),
            },
            {
              additionalAutoComplete: {
                message: {
                  value: null,
                },
              },
            },
          ),
          getPopoverConfig<ApiInfo>(
            "onSuccess",
            "Triggers an action whenever the API is run successfully",
          ),
          getPopoverConfig<ApiInfo>(
            "onError",
            "Triggers an action whenever the API has an error",
          ),
          getPopoverConfig<ApiInfo>(
            "onCancel",
            "Triggers an action whenever the API is cancelled",
          ),
        ],
      },
    ],
  },
];
