import { CustomComponentProperty } from "@superblocksteam/shared";
import FIELD_EXPECTED_VALUE from "legacy/constants/FieldExpectedValue";
import { EXPECTED_DATA_EXAMPLE } from "legacy/constants/FieldExpectedValue";
import { type WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { ValidationType, VALIDATION_TYPES } from "./WidgetValidation";

export const ALL_PROPERTIES_PROP = "allProperties";
export const ALL_CHILDREN_PROP = "allChildren";

export const ContainerWidgets = [
  WidgetTypes.FORM_WIDGET,
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
] as const;

export const ResettableWidgets = [
  WidgetTypes.TABLE_WIDGET,
  WidgetTypes.DROP_DOWN_WIDGET,
  WidgetTypes.INPUT_WIDGET,
  WidgetTypes.GRID_WIDGET,
  WidgetTypes.DATE_PICKER_WIDGET,
  WidgetTypes.CHECKBOX_WIDGET,
  WidgetTypes.SWITCH_WIDGET,
  WidgetTypes.RADIO_GROUP_WIDGET,
  // WidgetTypes.CHART_WIDGET, selectedData is just for read, cannot affect chart state
  WidgetTypes.FILE_PICKER_WIDGET,
  WidgetTypes.MAP_WIDGET, //only reset center
  WidgetTypes.CODE_WIDGET,
  WidgetTypes.RICH_TEXT_EDITOR_WIDGET,
  WidgetTypes.CALLOUT_WIDGET,
  // WidgetTypes.VIDEO_WIDGET, Nothing is working on video meta
  ...ContainerWidgets,
] as const;

export const SettableWidgets = [
  // WidgetTypes.CHART_WIDGET, selectedData is just for read, cannot affect chart state by setting this property
  WidgetTypes.CHECKBOX_WIDGET,
  WidgetTypes.SWITCH_WIDGET,
  WidgetTypes.DATE_PICKER_WIDGET,
  WidgetTypes.DROP_DOWN_WIDGET,
  // WidgetTypes.FILE_PICKER_WIDGET,
  WidgetTypes.INPUT_WIDGET,
  WidgetTypes.MAP_WIDGET,
  WidgetTypes.RADIO_GROUP_WIDGET,
  // WidgetTypes.VIDEO_WIDGET, playState is a read property, setting it does not change the state of the video player
  WidgetTypes.CODE_WIDGET,
  WidgetTypes.RICH_TEXT_EDITOR_WIDGET,
  WidgetTypes.GRID_WIDGET,
  WidgetTypes.TABLE_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.CALLOUT_WIDGET,
] as const;

// Source of truth of labels based on property names,
// for consistency across Set/Reset forms and widget types
export const WidgetPropertyToLabel: Record<string, string> = {
  [ALL_PROPERTIES_PROP]: "All Properties",
  [ALL_CHILDREN_PROP]: "Children",
  selectedRowIndex: "Selected Row",
  selectedRowIndices: "Selected Rows",
  deletedRows: "Deleted Rows",
  updatedRows: "Updated Rows",
  insertedRows: "Inserted Rows",
  filters: "Filters",
  searchText: "Search Text",
  pageNo: "Page Number",
  hiddenColumns: "Hidden Columns",
  selectedOptionValue: "Selected Option",
  text: "Text",
  selectedCellIndex: "Selected Cell",
  selectedDate: "Selected Date",
  isChecked: "Checked",
  isVisible: "Visible",
  isToggledOn: "Toggled On",
  files: "Selected Files",
  centerPin: "Center Pin",
  center: "Center",
  markers: "Markers",
  selectedMarker: "Selected Marker",
  [`${ALL_CHILDREN_PROP}AndSelectedTab`]: "Children & Selected Tab",
  selectedTabWidgetId: "Selected Tab",
  selectedTab: "Selected Tab",
  stringValue: "Current Value",
  value: "Value",
};

// WidgetPropertyToLabel contains a name to label map that is used for all widget types
// WidgetPropertyToLabelByType contains a set of name to label maps that are specific for each widget type
export const WidgetPropertyToLabelByType: Partial<
  Record<WidgetType, Record<string, string>>
> = {};

export const ComponentToResettableProperties: Record<
  (typeof ResettableWidgets)[number],
  Array<{ label: string; value: string; propertiesToReset?: string[] }>
> = {
  [WidgetTypes.TABLE_WIDGET]: [
    {
      label: WidgetPropertyToLabel[ALL_PROPERTIES_PROP],
      value: ALL_PROPERTIES_PROP,
    },
    {
      label: WidgetPropertyToLabel.selectedRowIndex,
      value: "selectedRowIndex",
      propertiesToReset: [
        "selectedRowIndex",
        "selectedRowIndices",
        "selectedRow",
        "selectedRows",
      ],
    },
    {
      label: WidgetPropertyToLabel.filters,
      value: "filters",
      propertiesToReset: ["filters"],
    },
    {
      label: WidgetPropertyToLabel.searchText,
      value: "searchText",
      propertiesToReset: ["searchText"],
    },
    {
      label: WidgetPropertyToLabel.pageNo,
      value: "pageNo",
      propertiesToReset: ["pageNo"],
    },
  ],
  [WidgetTypes.DROP_DOWN_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedOptionValue,
      value: "selectedOptionValue",
      propertiesToReset: [
        "metaSelectedOptionValue",
        "metaSelectedOptionValueArr",
      ],
    },
  ],
  [WidgetTypes.INPUT_WIDGET]: [
    {
      label: WidgetPropertyToLabel.text,
      value: "text",
      propertiesToReset: ["text"],
    },
  ],
  [WidgetTypes.GRID_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedCellIndex,
      value: "selectedCellIndex",
      propertiesToReset: ["selectedCellIndex"],
    },
    //TODO: Add searchText to meta
  ],
  [WidgetTypes.DATE_PICKER_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedDate,
      value: "selectedDate",
      propertiesToReset: ["selectedDate"],
    },
  ],
  [WidgetTypes.CHECKBOX_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isChecked,
      value: "isChecked",
      propertiesToReset: ["isChecked"],
    },
  ],
  [WidgetTypes.SWITCH_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isToggledOn,
      value: "isToggledOn",
      propertiesToReset: ["isToggledOn"],
    },
  ],
  [WidgetTypes.RADIO_GROUP_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedOptionValue,
      value: "selectedOptionValue",
      propertiesToReset: ["selectedOptionValue"],
    },
  ],
  [WidgetTypes.FILE_PICKER_WIDGET]: [
    {
      label: WidgetPropertyToLabel.files,
      value: "files",
      propertiesToReset: ["files"],
    },
  ],
  [WidgetTypes.MAP_WIDGET]: [
    {
      label: WidgetPropertyToLabel.centerPin,
      value: ALL_PROPERTIES_PROP, //TODO: actually map has no meta in redux, but resetAll will somehow trigger it to go back to default state (only center pin but not zoom)
    },
  ],
  //container widgets
  [WidgetTypes.FORM_WIDGET]: [
    {
      label: WidgetPropertyToLabel[ALL_CHILDREN_PROP],
      value: ALL_CHILDREN_PROP,
      propertiesToReset: [ALL_CHILDREN_PROP],
    },
  ],
  [WidgetTypes.CONTAINER_WIDGET]: [
    {
      label: WidgetPropertyToLabel[ALL_CHILDREN_PROP],
      value: ALL_CHILDREN_PROP,
      propertiesToReset: [],
    },
  ],
  [WidgetTypes.TABS_WIDGET]: [
    {
      label: WidgetPropertyToLabel[`${ALL_CHILDREN_PROP}AndSelectedTab`],
      value: `${ALL_CHILDREN_PROP}AndSelectedTab`,
      propertiesToReset: ["selectedTabWidgetId"],
    },
    {
      label: WidgetPropertyToLabel[ALL_CHILDREN_PROP],
      value: ALL_CHILDREN_PROP,
      propertiesToReset: [],
    },
    {
      label: WidgetPropertyToLabel.selectedTabWidgetId,
      value: "selectedTabWidgetId",
      propertiesToReset: ["selectedTabWidgetId"],
    },
  ],
  [WidgetTypes.MODAL_WIDGET]: [
    {
      label: WidgetPropertyToLabel[ALL_CHILDREN_PROP],
      value: ALL_CHILDREN_PROP,
      propertiesToReset: [],
    },
  ],
  [WidgetTypes.SLIDEOUT_WIDGET]: [
    {
      label: WidgetPropertyToLabel[ALL_CHILDREN_PROP],
      value: ALL_CHILDREN_PROP,
      propertiesToReset: [],
    },
  ],
  [WidgetTypes.CODE_WIDGET]: [
    {
      label: WidgetPropertyToLabel.stringValue,
      value: ALL_PROPERTIES_PROP,
      propertiesToReset: ["stringValue"],
    },
  ],
  [WidgetTypes.RICH_TEXT_EDITOR_WIDGET]: [
    {
      label: WidgetPropertyToLabel.value,
      value: "value",
      propertiesToReset: ["value"],
    },
  ],
  [WidgetTypes.CALLOUT_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isVisible,
      value: "isVisible",
      propertiesToReset: ["isVisible"],
    },
  ],
};

type SettablePropertiesConfig<T = any> = Array<{
  label: string;
  value: string;
  propertiesToSet: Array<keyof T>;
  // dynamicProperties are passed to the property value input and based on the selected widget type/property name
  dynamicProperties?: {
    expected?: string;
    exampleData?: string;
    docLink?: string;
    placeholder: string;
  };
  validationType: ValidationType;
  mapValue?: (widget: T, propertyValue: any, evaluatedWidget: any) => any;
}>;

export const ComponentToSettableProperties: Record<
  (typeof SettableWidgets)[number],
  SettablePropertiesConfig
> = {
  // [WidgetTypes.CHART_WIDGET]: [
  //  selectedData is just for read, cannot affect chart state by setting this property
  //   { label: "Selected data", value: "selectedData" },
  // ],
  [WidgetTypes.CHECKBOX_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isChecked,
      value: "isChecked",
      propertiesToSet: ["isChecked"],
      dynamicProperties: {
        expected: "boolean",
        exampleData: "false",
        placeholder: "false",
      },
      validationType: VALIDATION_TYPES.BOOLEAN,
    },
  ],
  [WidgetTypes.SWITCH_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isToggledOn,
      value: "isToggledOn",
      propertiesToSet: ["isToggledOn"],
      dynamicProperties: {
        expected: "boolean",
        exampleData: "false",
        placeholder: "false",
      },
      validationType: VALIDATION_TYPES.BOOLEAN,
    },
  ],
  [WidgetTypes.DATE_PICKER_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedDate,
      value: "selectedDate",
      propertiesToSet: ["selectedDate"],
      dynamicProperties: {
        expected: "string",
        exampleData: '"07/04/2022"',
        placeholder: "07/04/2022",
      },
      validationType: VALIDATION_TYPES.TEXT,
    },
  ],
  [WidgetTypes.DROP_DOWN_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedOptionValue,
      value: "selectedOptionValue",
      propertiesToSet: [
        "metaSelectedOptionValue",
        "metaSelectedOptionValueArr",
      ],
      dynamicProperties: {
        expected:
          FIELD_EXPECTED_VALUE[WidgetTypes.DROP_DOWN_WIDGET].defaultOptionValue,
        exampleData: '"TORONTO" | ["TORONTO", "NEW YORK"]',
        placeholder: "TORONTO",
      },
      validationType: VALIDATION_TYPES.DEFAULT_OPTION_VALUE,
    },
  ],
  // Currently can't set files to anything other than []
  // [WidgetTypes.FILE_PICKER_WIDGET]: [
  //   {
  //     label: "Selected File(s)",
  //     value: "files",
  //     propertiesToSet: ["files"]
  //   },
  // ],
  [WidgetTypes.INPUT_WIDGET]: [
    {
      label: WidgetPropertyToLabel.text,
      value: "text",
      propertiesToSet: ["text"],
      dynamicProperties: {
        expected: "string",
        exampleData: '"value"',
        placeholder: "Value",
      },
      validationType: VALIDATION_TYPES.TEXT,
    },
  ],
  [WidgetTypes.MAP_WIDGET]: [
    {
      label: WidgetPropertyToLabel.center,
      value: "center",
      propertiesToSet: ["center"],
      dynamicProperties: {
        expected: FIELD_EXPECTED_VALUE[WidgetTypes.MAP_WIDGET].mapCenter,
        exampleData: "{lat: -34.3, long: 150.6}",
        placeholder: "{{ {lat: -34.3, long: 150.6} }}",
      },
      validationType: VALIDATION_TYPES.LAT_LONG,
    },
    {
      label: WidgetPropertyToLabel.markers,
      value: "markers",
      propertiesToSet: ["markers"],
      dynamicProperties: {
        expected: FIELD_EXPECTED_VALUE[WidgetTypes.MAP_WIDGET].defaultMarkers,
        exampleData:
          EXPECTED_DATA_EXAMPLE[WidgetTypes.MAP_WIDGET].defaultMarkers.example,
        docLink:
          EXPECTED_DATA_EXAMPLE[WidgetTypes.MAP_WIDGET].defaultMarkers.docLink,
        placeholder: "[{lat: -34.3, long: 150.6}]",
      },
      validationType: VALIDATION_TYPES.MARKERS,
    },
    {
      label: WidgetPropertyToLabel.selectedMarker,
      value: "selectedMarker",
      propertiesToSet: ["selectedMarker"],
      dynamicProperties: {
        expected: FIELD_EXPECTED_VALUE[WidgetTypes.MAP_WIDGET].mapCenter,
        exampleData: "{lat: -34.3, long: 150.6}",
        placeholder: "{{ {lat: -34.3, long: 150.6} }}",
      },
      validationType: VALIDATION_TYPES.LAT_LONG,
    },
  ],
  [WidgetTypes.RADIO_GROUP_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedOptionValue,
      value: "selectedOptionValue",
      propertiesToSet: ["selectedOptionValue"],
      dynamicProperties: {
        expected: "string",
        exampleData: '"NY"',
        placeholder: "NY",
      },
      validationType: VALIDATION_TYPES.TEXT_EMPTY_NULL,
    },
  ],
  // [WidgetTypes.VIDEO_WIDGET]: [
  // playState is read only and will not play/pause the video
  //   {
  //     label: "Play state",
  //     value: "playState",
  //     propertiesToSet: ["playState"]
  //   },
  // ],
  [WidgetTypes.CODE_WIDGET]: [
    {
      label: WidgetPropertyToLabel.stringValue,
      value: "value",
      propertiesToSet: ["stringValue"],
      dynamicProperties: {
        expected: FIELD_EXPECTED_VALUE[WidgetTypes.CODE_WIDGET].stringValue,
        exampleData: '"const count = 3;"',
        placeholder: "Code",
      },
      validationType: VALIDATION_TYPES.TEXT,
    },
  ],
  [WidgetTypes.RICH_TEXT_EDITOR_WIDGET]: [
    {
      label: WidgetPropertyToLabel.value,
      value: "value",
      propertiesToSet: ["value"],
      dynamicProperties: {
        expected:
          FIELD_EXPECTED_VALUE[WidgetTypes.RICH_TEXT_EDITOR_WIDGET].value,
        exampleData: '"<b>Hello!</b>"',
        placeholder: "Value",
      },
      validationType: VALIDATION_TYPES.TEXT,
    },
  ],
  [WidgetTypes.GRID_WIDGET]: [
    // Doesn't currentlty work because grid's search text is managed by GridWidget state,
    // not meta property
    // {
    //   label: "Search text",
    //   value: "searchText",
    //   propertiesToSet: ["searchText"],
    // },
    {
      label: WidgetPropertyToLabel.selectedCellIndex,
      value: "selectedCellIndex",
      propertiesToSet: ["selectedCellIndex"],
      dynamicProperties: {
        expected: "number",
        exampleData: "0",
        placeholder: "0",
      },
      validationType: VALIDATION_TYPES.NUMBER,
    },
  ],

  [WidgetTypes.TABLE_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedRowIndex,
      value: "selectedRowIndex",
      propertiesToSet: ["selectedRowIndex"],
      dynamicProperties: {
        expected: "number",
        exampleData: "0",
        placeholder: "0",
      },
      validationType: VALIDATION_TYPES.NUMBER,
    },
    {
      label: WidgetPropertyToLabel.selectedRowIndices,
      value: "selectedRowIndices",
      propertiesToSet: ["selectedRowIndices"],
      dynamicProperties: {
        expected: "Array<number>",
        exampleData: "[0, 1]",
        placeholder: "[0, 1]",
      },
      validationType: VALIDATION_TYPES.NUMBER_ARRAY,
    },
    {
      label: WidgetPropertyToLabel.filters,
      value: "filters",
      propertiesToSet: ["filters"],
      dynamicProperties: {
        expected: FIELD_EXPECTED_VALUE[WidgetTypes.TABLE_WIDGET].defaultFilters,
        exampleData:
          EXPECTED_DATA_EXAMPLE[WidgetTypes.TABLE_WIDGET].defaultFilters
            .example,
        docLink:
          EXPECTED_DATA_EXAMPLE[WidgetTypes.TABLE_WIDGET].defaultFilters
            .docLink,
        placeholder: "{{ {byColumn: {...}} }}",
      },
      validationType: VALIDATION_TYPES.FILTERS_DATA,
    },
    {
      label: WidgetPropertyToLabel.searchText,
      value: "searchText",
      propertiesToSet: ["searchText"],
      dynamicProperties: {
        expected: "string",
        exampleData: '"Search value"',
        placeholder: "Search value",
      },
      validationType: VALIDATION_TYPES.TEXT,
    },
    {
      label: WidgetPropertyToLabel.pageNo,
      value: "pageNo",
      propertiesToSet: ["pageNo"],
      dynamicProperties: {
        expected: "number",
        exampleData: "2",
        placeholder: "2",
      },

      validationType: VALIDATION_TYPES.NUMBER,
    },
    {
      label: WidgetPropertyToLabel.hiddenColumns,
      value: "hiddenColumns",
      propertiesToSet: ["hiddenColumns"],
      dynamicProperties: {
        expected: "Array<string>",
        exampleData: '["name", "date"]',
        placeholder: '["name"]',
      },
      validationType: VALIDATION_TYPES.ARRAY,
    },
  ],
  [WidgetTypes.TABS_WIDGET]: [
    {
      label: WidgetPropertyToLabel.selectedTab,
      value: "selectedTab",
      propertiesToSet: ["selectedTabWidgetId"],
      mapValue: (
        widget: {
          tabs: Array<{ id: string; label: string; widgetId: string }>;
          // TODO: Use TabsWidgetProps type (causes dependency cycles)
        },
        propertyValue,
      ) => {
        if (widget && widget.tabs) {
          return (
            widget.tabs.find((tab) => tab.label === propertyValue)?.widgetId ??
            propertyValue
          );
        }
        return propertyValue;
      },
      dynamicProperties: {
        expected: "string",
        placeholder: "Tab Name",
        exampleData: '"Tab Name"',
      },
      validationType: VALIDATION_TYPES.SELECTED_TAB,
    },
  ],
  [WidgetTypes.CALLOUT_WIDGET]: [
    {
      label: WidgetPropertyToLabel.isVisible,
      value: "isVisible",
      propertiesToSet: ["isVisible"],
      dynamicProperties: {
        expected: "boolean",
        exampleData: "false",
        placeholder: "false",
      },
      validationType: VALIDATION_TYPES.BOOLEAN,
    },
  ],
};

export function registerCustomComponentSettableProperties(
  widgetType: WidgetType,
  properties: CustomComponentProperty[],
) {
  if (properties.length === 0) return;
  if (!(ResettableWidgets as unknown as WidgetType[]).includes(widgetType)) {
    (ResettableWidgets as unknown as WidgetType[]).push(widgetType);
  }
  if (!(SettableWidgets as unknown as WidgetType[]).includes(widgetType)) {
    (SettableWidgets as unknown as WidgetType[]).push(widgetType);
  }
  const resettableProperties: {
    label: string;
    value: string;
    propertiesToReset?: string[];
  }[] = [];
  const settableProperties: {
    label: string;
    value: string;
    propertiesToSet?: string[];
    validationType: ValidationType;
  }[] = [];
  const propNameToLabelMap: Record<string, string> = {};
  properties.forEach((property) => {
    // Only add properties that are externally settable
    if (
      property.isExternallySettable ||
      typeof property.isExternallySettable === "undefined"
    ) {
      resettableProperties.push({
        label: property.path,
        value: property.path,
        propertiesToReset: [property.path],
      });
      let validationType: ValidationType;
      switch (property.dataType) {
        case "number":
          validationType = VALIDATION_TYPES.NUMBER;
          break;
        case "boolean":
          validationType = VALIDATION_TYPES.BOOLEAN;
          break;
        case "any":
          validationType = VALIDATION_TYPES.ANY;
          break;
        default:
          validationType = VALIDATION_TYPES.TEXT;
      }
      settableProperties.push({
        label: property.path,
        value: property.path,
        propertiesToSet: [property.path],
        validationType: validationType,
      });
      propNameToLabelMap[property.path] = property.path;
    }
  });
  resettableProperties.push({
    label: WidgetPropertyToLabel[ALL_PROPERTIES_PROP],
    value: ALL_PROPERTIES_PROP,
  });
  ComponentToResettableProperties[
    widgetType as (typeof ResettableWidgets)[number]
  ] = resettableProperties;
  ComponentToSettableProperties[
    widgetType as (typeof SettableWidgets)[number]
  ] = settableProperties as SettablePropertiesConfig;
  WidgetPropertyToLabelByType[widgetType] = propNameToLabelMap;
}
