import {
  DEMO_ENTITIES_FOLDER_NAME,
  FolderDto,
  Organization,
} from "@superblocksteam/shared";
import { Col, Form, FormInstance, Input, Modal, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DropdownSelect from "components/ui/DropdownSelect";
import { WizardFlow, WizardStep } from "components/ui/WizardFlow";
import {
  CREATE_APPLICATIONS,
  CREATE_JOBS,
  CREATE_WORKFLOWS,
} from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useEntityNameGenerator } from "hooks/ui/useEntityNameGenerator";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { getQueryParams } from "legacy/utils/Utils";
import { EntityType } from "utils/entity";
import { CreateApplicationCard } from "./CreateApplication";
import { CreateJobCard } from "./CreateJob";
import { CreateJobSteps } from "./CreateJobSteps";
import { CreateWorkflowCard } from "./CreateWorkflow";
import { CreateWorkflowSteps } from "./CreateWorkflowSteps";
import { CreateSteps, useCreateWorkflowProps } from "./common";

const CardsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

type CreateEntityModalProps = {
  organization: Organization;
  isVisible: boolean;
  createForm: FormInstance;
  folders: FolderDto[];
  onCancel: () => void;
  refreshEntities: () => void;
};

const CreateEntityModal = ({
  organization,
  folders,
  onCancel,
  refreshEntities,
  isVisible,
  createForm,
}: CreateEntityModalProps) => {
  const user = useSelector(getCurrentUser);

  const [activeStep, setActiveStep] = useState(CreateSteps.CHOOSE_ENTITY);
  const [selectedName, setSelectedName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState<string>();

  const [generatedEntityType, setgeneratedEntityType] = useState<EntityType>(
    EntityType.APPLICATION,
  );
  const [canCreateApps, canCreateWorkflows, canCreateJobs] =
    useAuthorizationCheck([CREATE_APPLICATIONS, CREATE_WORKFLOWS, CREATE_JOBS]);

  const defaultEntityName = useEntityNameGenerator(
    generatedEntityType,
    isVisible,
  );
  const workflowProps = useCreateWorkflowProps();

  const resetFields = useCallback(() => {
    createForm.resetFields();
  }, [createForm]);
  const processFieldValues = useCallback(async () => {
    const values = await createForm.validateFields();
    const name = isEmpty(values.name) ? defaultEntityName : values.name;
    setSelectedName(name);
    setSelectedFolderId(values.folder);
    return {
      name,
      folder: values.folder,
    };
  }, [createForm, defaultEntityName]);

  const filteredFolderOptions = useMemo(() => {
    return folders
      .filter((folder) => folder.name !== DEMO_ENTITIES_FOLDER_NAME)
      .map((folder) => ({
        value: folder.id,
        label: folder.name,
      }));
  }, [folders]);

  const params = getQueryParams();
  let highlightCreateApp = false;
  let highlightCreateWorkflow = false;
  let highlightCreateJob = false;
  const createEntityType = params["create-entity-type"];
  switch (createEntityType) {
    case EntityType.APPLICATION:
      highlightCreateApp = true;
      break;
    case EntityType.WORKFLOW:
      highlightCreateWorkflow = true;
      break;
    case EntityType.SCHEDULED_JOB:
      highlightCreateJob = true;
      break;
    default:
      highlightCreateApp = true;
      highlightCreateWorkflow = true;
      highlightCreateJob = true;
  }

  const chooseEntityTypeStep = (
    <Form
      form={createForm}
      layout="vertical"
      name="create_new_form"
      style={{ overflow: "hidden" }}
    >
      <Row gutter={8}>
        <Col span={18}>
          <Form.Item name="name" label="Name">
            <Input
              autoFocus
              placeholder={defaultEntityName}
              data-test="create-new-name"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label=" " name="folder">
            <DropdownSelect
              showSearch
              allowClear
              placeholder="No folder"
              options={filteredFolderOptions}
              filterOption={(input, option) => {
                const label = option?.label as string;
                return label?.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <CardsWrapper>
        <CreateApplicationCard
          highlight={highlightCreateApp}
          setGeneratedEntityType={setgeneratedEntityType}
          organizationId={organization.id}
          resetFields={resetFields}
          processFieldValues={processFieldValues}
          setActiveStep={setActiveStep}
          isDisabled={!canCreateApps}
        />
        <CreateWorkflowCard
          highlight={highlightCreateWorkflow}
          setGeneratedEntityType={setgeneratedEntityType}
          organizationId={organization.id}
          resetFields={resetFields}
          processFieldValues={processFieldValues}
          setActiveStep={setActiveStep}
          isDisabled={!canCreateWorkflows}
          {...workflowProps}
        />
        <CreateJobCard
          highlight={highlightCreateJob}
          setGeneratedEntityType={setgeneratedEntityType}
          resetFields={resetFields}
          setActiveStep={setActiveStep}
          processFieldValues={processFieldValues}
          organizationId={organization.id}
          isDisabled={!canCreateJobs}
        />
      </CardsWrapper>
    </Form>
  );

  const modalTitle = useMemo(() => {
    switch (activeStep) {
      case CreateSteps.CHOOSE_ENTITY:
        return "Create New";
      case CreateSteps.JOB_SCHEDULE:
        return "Step 1: Select a Schedule & Set Up Notifications";
      case CreateSteps.JOB_FIRST_ACTION:
        return "Step 2: Choose First Scheduled Job Step";
      case CreateSteps.WORKFLOW_VARIABLES:
        return "Step 1: Setup Workflow Body";
      case CreateSteps.WORKFLOW_CALL:
        return "Step 2: Trigger Workflow Test";
      case CreateSteps.WORKFLOW_FIRST_ACTION:
        return "Step 3: Choose First Workflow Step";
    }
  }, [activeStep]);

  return (
    <Modal
      title={modalTitle}
      open={isVisible}
      onCancel={() => {
        setActiveStep(CreateSteps.CHOOSE_ENTITY);
        onCancel && onCancel();
      }}
      destroyOnClose
      footer={null}
      width={960}
    >
      <WizardFlow activeStep={activeStep} setActiveStep={setActiveStep}>
        <WizardStep noControls stepId={CreateSteps.CHOOSE_ENTITY}>
          {chooseEntityTypeStep}
        </WizardStep>
        <CreateJobSteps
          activeStep={activeStep}
          userOrganizationId={user?.currentOrganizationId ?? ""}
          selectedFolderId={selectedFolderId}
          selectedName={selectedName}
          organizationId={organization.id}
        />
        <CreateWorkflowSteps
          activeStep={activeStep}
          organization={organization}
          userOrganizationId={user?.currentOrganizationId ?? ""}
          {...workflowProps}
        />
      </WizardFlow>
    </Modal>
  );
};

export default CreateEntityModal;
