import { Button } from "@blueprintjs/core";
import styled from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

//TODO: create a general datepick component here like recommended single dropdown
export const DatePickerStyle = styleAsClass`
  width: 100%;
  margin-top: 8px !important;
  height: 32px;
  &.ant-picker.ant-picker-disabled {
    background: ${colors.GREY_50};
    border-color: ${colors.GREY_50};
  }
  &.ant-picker.ant-picker-disabled .ant-picker-input > input {
    color: ${colors.GREY_300};
    
  }
  &.ant-picker .ant-picker-input > input {
    font-size: 12px;
  }
`;

export const DatePickerActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  left: 0;
  margin-top: 10px;
  position: absolute;
  bottom: -40px;
  width: 210px;

  &[data-wrapped="true"] {
    position: unset;
    margin-top: 0;
    width: 100%;
  }

  &[data-with-time="true"] {
    bottom: -80px;
  }
`;

export const DatePickerActionButton = styled(Button)`
  height: 32px;
  width: auto;

  &[data-full-width="true"] {
    width: 100%;
  }
`;
