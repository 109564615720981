import { sample } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { EntityType } from "utils/entity";
import { shortenUsername } from "utils/user";

const awesomeAdjectives = [
  "Awesome",
  "Great",
  "Fabulous",
  "Fantastic",
  "Extraordinary",
  "Stunning",
  "Astounding",
  "Incredible",
  "Magnificent",
  "Mighty",
  "Wonderful",
  "Spectacular",
];

export const useEntityNameGenerator = (
  entityType: EntityType,
  resampleDependency?: any,
) => {
  const [currentAdj, setCurrentAdj] = useState(sample(awesomeAdjectives));

  useEffect(() => {
    setCurrentAdj(sample(awesomeAdjectives));
  }, [resampleDependency]);

  const user = useSelector(getCurrentUser);
  const userName = useMemo(() => {
    return user?.name ?? "My";
  }, [user]);

  const getShortName = (entityType: EntityType): string => {
    switch (entityType) {
      case EntityType.APPLICATION:
        return "App";
      case EntityType.WORKFLOW:
        return "Workflow";
      case EntityType.SCHEDULED_JOB:
        return "Job";
    }
  };

  const defaultEntityName = useMemo(() => {
    if (userName) {
      return `${shortenUsername(userName)}'s ${currentAdj} ${getShortName(
        entityType,
      )}`;
    }
    return `My ${currentAdj} ${getShortName(entityType)}`;
  }, [currentAdj, userName, entityType]);
  return defaultEntityName;
};
