import { PrimaryKey, Table } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "assets/icons/common/cross.svg";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { Button } from "components/ui/Button";
import { FullWidthSpace } from "components/ui/Space";
import { useAppSelector } from "store/helpers";
import { selectDatasourceMetaById } from "store/slices/datasources";
import { fastClone } from "utils/clone";
import logger from "utils/logger";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";
import { StyledSelect } from "./StyledComponents";

interface DynamicFormFilterColumnsProps {
  datasourceId: string;
}

type Props = DynamicFormFilterColumnsProps & DynamicFormItemProps;

const StyledFullWidthSpace = styled(FullWidthSpace)`
  & * {
    flex: 1;
    align-items: center;
  }
`;

const Styled2Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 12px;
`;

const IconWrapper = styled.span`
  display: inline-block;
  height: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.GREY_500};

  & > svg {
    vertical-align: sub;
  }
  & > * + * {
    margin-right: 10px;
  }
`;

export const DynamicFormFilterColumns = ({
  datasourceId,
  path,
  label,
  rules,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTable, setActiveTable] = useState<Table | null>(null);
  const [currentMappings, setMappings] = useState<string[] | undefined>();

  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  const tables = useMemo(() => {
    const result: Table[] = [];
    try {
      datasourceMeta?.metadata?.dbSchema?.tables?.forEach((table: Table) => {
        result.push(table);
      });
      if (result.length > 0) {
        setIsLoading(false);
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [datasourceMeta?.metadata?.dbSchema?.tables]);

  useEffect(() => {
    const unsubscribe = context.subscribe("table", (dependencyValue) => {
      if (dependencyValue && tables) {
        const activeTable = tables.find((t) => t.name === dependencyValue);
        setActiveTable(activeTable ?? null);
      }
    });
    return () => unsubscribe();
  }, [context, tables]);

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (mappings) => {
      setMappings((mappings as string[]) ?? []);
    });
    return () => unsubscribe();
  }, [context, path]);

  const [validationMessage, setValidationMessage] = useState("");
  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [path, rules, context.registerValidation, context]);

  const primaryKeys = useMemo(() => {
    const potentialKey = activeTable?.keys?.find(
      (k) => k.type === "primary key",
    ) as PrimaryKey | undefined;
    return potentialKey?.columns;
  }, [activeTable]);

  const onChange = useCallback(
    (val: string, index: number) => {
      const newValue = fastClone(context.getValue(path) ?? []) as unknown[];
      newValue.splice(index, 1, val);
      // Because we can't use array-indexed paths
      context.onChange(path, newValue);
    },
    [context, path],
  );

  const options = useMemo(() => {
    return activeTable?.columns.map((tableCol) => {
      return {
        label: `${tableCol.name}${
          primaryKeys?.includes(tableCol.name) ? " (Primary key)" : ""
        }`,
        value: tableCol.name,
        disabled: currentMappings?.includes(tableCol.name),
      };
    });
  }, [activeTable?.columns, primaryKeys, currentMappings]);

  return (
    <StyledFullWidthSpace direction="vertical">
      <FormLabel>
        {label}
        <FormTooltip
          tooltip={{
            markdownText:
              "Specify which columns from the original rows are used in matching rows that need to be updated.",
          }}
        />
      </FormLabel>

      {(currentMappings?.length ? currentMappings : [""])?.map(
        (column, index) => {
          return (
            <Styled2Col key={index}>
              <StyledSelect
                value={column}
                onChange={(val) => {
                  onChange(val, index);
                }}
                options={options}
                {...otherProps}
                style={{ width: "100%" }}
                loading={isLoading}
                showSearch={true}
              />
              <Button
                icon={
                  <IconWrapper>
                    <CrossIcon />
                  </IconWrapper>
                }
                aria-label="Remove row mapping"
                onClick={(val) => {
                  context.onChange(
                    path,
                    (currentMappings ?? []).filter((m, i) => i !== index),
                  );
                }}
              />
            </Styled2Col>
          );
        },
      )}

      <Button
        aria-label="Add column as filter"
        onClick={() => {
          context.onChange(path, (currentMappings ?? [])?.concat([""]));
        }}
      >
        <IconWrapper>
          <PlusIcon />
          <span>Add column</span>
        </IconWrapper>
      </Button>

      <Typography.Text type="danger">{validationMessage}</Typography.Text>
    </StyledFullWidthSpace>
  );
};
