import { colors } from "./colors";
import { styleAsClass } from "./styleAsClass";

export const Heading1Css = `
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: ${colors.GREY_900};
`;

const Heading2Css = `
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.GREY_800};
`;

const Heading3Css = `
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: ${colors.GREY_900};
`;

export const Heading2 = styleAsClass`
  ${Heading2Css}
`;

export const Heading3 = styleAsClass`
  ${Heading3Css}
`;
