import { ApplicationScope } from "@superblocksteam/shared";
import {
  DataTree,
  DataTreeEntity,
} from "legacy/entities/DataTree/dataTreeFactory";
import { resolveScopes } from "./resolveScopes";

export type ScopeSpecificDataTree = Record<
  string | ApplicationScope,
  DataTreeEntity | Record<string, DataTreeEntity> | Record<string, unknown>
>;

export function getMergedDataTree(
  scope: ApplicationScope,
  dataTree: DataTree,
): ScopeSpecificDataTree {
  const scopes = resolveScopes(scope);
  const mergedDataTree: ScopeSpecificDataTree = {};
  for (const { scope, prefix } of scopes) {
    if (prefix) {
      mergedDataTree[prefix] = dataTree[scope];
    } else {
      Object.assign(mergedDataTree, dataTree[scope]);
    }
  }
  return mergedDataTree;
}

export function getMergedDataTreeWithKeys(
  scope: ApplicationScope,
  dataTree: DataTree,
) {
  const localTree = getMergedDataTree(scope, dataTree);
  const keys = new Set(Object.keys(localTree));
  return { localTree, keys, scope, dataTree };
}
