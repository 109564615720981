import * as Progress from "@radix-ui/react-progress";
import { Property } from "csstype";
import React from "react";
import styled from "styled-components";

const PROGRESS_BAR_HEIGHT_PX = 6;

export const ProgressBarContainer = styled.div`
  margin: 12px 0;
  height: ${PROGRESS_BAR_HEIGHT_PX}px;
`;

const StyledProgress = styled(Progress.Root)`
  height: ${PROGRESS_BAR_HEIGHT_PX}px;
  background-color: #e8eaed;
  border-radius: 6px;
  overflow: hidden;
`;

const StyledProgressIndicator = styled(
  ({
    transitionDurationMs,
    easingFunction,
    ...props
  }: Progress.ProgressIndicatorProps & {
    transitionDurationMs?: number;
    easingFunction?: Property.TransitionTimingFunction;
  }) => <Progress.Indicator {...props} />,
)`
  height: 100%;
  background: linear-gradient(to right, #47a9ff, #7c4ff8);
  ${({ transitionDurationMs, easingFunction }) =>
    transitionDurationMs &&
    `transition: width ${transitionDurationMs}ms ${
      easingFunction ?? "ease-in-out"
    };`}
`;

interface SurveyProgressBarProps {
  value: number;
  max: number;
  transitionDurationMs?: number;
  easingFunction?: Property.TransitionTimingFunction;
}

export const SurveyProgressBar = ({
  value,
  max,
  transitionDurationMs,
  easingFunction,
}: SurveyProgressBarProps) => {
  return (
    <StyledProgress value={value} max={max}>
      <StyledProgressIndicator
        transitionDurationMs={transitionDurationMs}
        easingFunction={easingFunction}
        style={{
          width: `${(value / max) * 100}%`,
        }}
      />
    </StyledProgress>
  );
};
