import { logger } from "@sentry/utils";
import {
  Interval,
  Plugin,
  ScheduleConfig,
  getRowItemsFromSectionItem,
} from "@superblocksteam/shared";
import * as BackendTypes from "store/slices/apisV2/backend-types";
import dot from "utils/dot";
import { prettyCount } from "utils/string";
import { createV1Schedule } from "./WorkflowEditor/api-version-utils";
import type {
  ActionConfigurationDto,
  ApiDto,
} from "../../../store/slices/apis";

/**
 * Returns a normalized name that is valid JS
 * Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Grammar_and_types#variables
 *
 * @param name - The input name
 * @returns The JS safe name
 */
export const getNormalizedName = (
  name: string,
  allowSpaces?: boolean,
): string => {
  if (name.length === 0) {
    return name;
  }

  const replacementChar = "_";
  // Prepend if the first character is illegal
  name = name.match(/^[A-Za-z_]/g) ? name : `${replacementChar}${name}`;
  if (!allowSpaces) {
    // Replace every whitespace group
    name = name.replace(/\s+/g, replacementChar);
  }
  const regexWithSpaces = /[^A-Za-z0-9_ ]/g;
  const regexWithoutSpaces = /[^A-Za-z0-9_]/g;
  // Replace all non alphanumeric characters - disallow all other unicode for sanity
  return name.replace(
    allowSpaces ? regexWithSpaces : regexWithoutSpaces,
    replacementChar,
  );
};

export const getInitialApiConfiguration = (
  plugin: { actionTemplate: Plugin["actionTemplate"] } | undefined,
): Partial<ActionConfigurationDto> => {
  const result: Partial<ActionConfigurationDto> = {};
  if (plugin) {
    plugin.actionTemplate.sections.forEach((section) => {
      section.items.forEach((sectionItem) => {
        const rowItems = getRowItemsFromSectionItem(sectionItem);
        for (const item of rowItems) {
          if (item.initialValue) {
            dot.copy("initialValue", item.name, item, result);
          }
        }
      });
    });
  }
  return result;
};

export const dayOfMonthSuffix = (dom: number): string => {
  if (dom === 1 || dom === 21 || dom === 31) {
    return "st";
  } else if (dom === 2 || dom === 22) {
    return "nd";
  } else if (dom === 3 || dom === 23) {
    return "rd";
  } else {
    return "th";
  }
};

const DAY_OF_WEEK_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const daysOfWeekString = (daysOfWeek: boolean[]): string => {
  if (!daysOfWeek) {
    return "no days of week";
  }
  if (daysOfWeek.length > DAY_OF_WEEK_NAMES.length) {
    logger.error(
      `Invalid days of week spec: ${daysOfWeek}, expected at most ${DAY_OF_WEEK_NAMES.length} elements`,
    );
  }
  const dowStrings: string[] = [];
  daysOfWeek.forEach((dow, i) => dow && dowStrings.push(DAY_OF_WEEK_NAMES[i]));
  return dowStrings.join(", ");
};

export const scheduleToHumanReadable = (
  schedule: ScheduleConfig | undefined,
): string => {
  if (!schedule) {
    return "No schedule";
  }
  const time = new Date(schedule.time);
  const timeString = time.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  switch (schedule.interval) {
    case Interval.Minute:
      return `Every ${prettyCount(schedule.frequency, "minute")}`;
    case Interval.Hour:
      return `Every ${prettyCount(
        schedule.frequency,
        "hour",
      )} at ${time.getMinutes()} minutes in`;
    case Interval.Day:
      return `Every day at ${timeString}`;
    case Interval.Week:
      return `Every week at ${timeString} on ${daysOfWeekString(
        schedule.daysOfWeek,
      )}`;
    case Interval.Month:
      return `Every ${prettyCount(
        schedule.frequency,
        "month",
      )} at ${timeString} on the ${schedule.dayOfMonth}${dayOfMonthSuffix(
        schedule.dayOfMonth,
      )}`;
  }
};

export const scheduleV2ToHumanReadable = (
  schedule: BackendTypes.Trigger["job"] | undefined,
): string => {
  if (!schedule) {
    return "No schedule";
  }
  return scheduleToHumanReadable(createV1Schedule(schedule));
};

export const getApiExecutionParams = (api: ApiDto) => {
  const params = [];
  if (api?.actions?.workflowParams) {
    params.push(...api.actions.workflowParams);
  }
  if (api?.actions?.workflowQueries) {
    params.push(...api.actions.workflowQueries);
  }
  return params;
};

export const isApiEditorFocused = () => {
  const apiEditor = document.querySelector(
    `[data-superblocks=api-editor-container]`,
  );
  return apiEditor === document.activeElement;
};
