import Text from "antd/lib/typography/Text";
import styled from "styled-components";
import type { SuperblocksColor } from "styles/colors";

export default styled(Text)<{
  color?: SuperblocksColor;
  sizePx?: number;
  weight?: number;
}>`
  ${(props) =>
    props.color ? `color: ${props.theme.colors[props.color]};` : ``}
  ${(props) => (props.sizePx ? `font-size: ${props.sizePx}px;` : ``)}
  ${(props) => (props.weight ? `font-weight: ${props.weight};` : ``)}
`;
