import { DropdownOption } from "@superblocksteam/shared";
import { Select } from "antd";
import React from "react";

interface Props {
  options?: DropdownOption[];
  value?: string;
  onChange?: (value: any) => void;
}

const Dropdown = ({ options, value, onChange, ...otherProps }: Props) => (
  <Select value={value} onChange={onChange} {...otherProps}>
    {options?.map((option) => (
      <Select.Option
        key={option.value}
        value={option.value}
        data-test={`dropdown-select-${option.value}`}
      >
        {option.displayName ?? option.value}
      </Select.Option>
    ))}
  </Select>
);

export default Dropdown;
