import { MinusCircleOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { DropdownOption, ENVIRONMENT_STAGING } from "@superblocksteam/shared";
import { Input, Row, Tooltip, Typography, Col, InputRef } from "antd";
import { isObject, isEqual } from "lodash";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { useFeatureFlag } from "hooks/ui";
import {
  getProfileForTest,
  getWorkflowProfiles,
} from "legacy/selectors/applicationSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { setV1TestProfile } from "store/slices/apisShared";
import { selectDoesControlFlowDependOnProfile } from "store/slices/apisShared/selectors/controlFlowEnabledDynamic";
import { setV2TestProfile } from "store/slices/apisV2";
import { Flag } from "store/slices/featureFlags";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "../../components/app/CodeEditor/EditorConfig";
import CodeEditor from "../../components/app/DynamicForm/components/CodeEditor";
import { Button } from "../../components/ui/Button";
import { FullWidthSpace } from "../../components/ui/Space";
import { convertParamToJSON } from "../Editors/ApiEditor/WorkflowEditor/utils";
import { getNormalizedName } from "../Editors/ApiEditor/utils";

const { Text } = Typography;

interface VariableDataField {
  id: string;
  name: string;
  isNameInvalid?: boolean;
  value: string;
  ref: React.RefObject<InputRef>;
}

type ColumnInfo = {
  toolTip: string;
  placeHolder: string;
};

type ParameterBuilderProps = {
  keyColumn: ColumnInfo;
  valueColumn: ColumnInfo;
  initialTableData: VariableDataField[];
  onUpdate: (tableData: VariableDataField[]) => any;
  addVariableButtonDataTestString: string;
  environment?: string;
  showBuiltInProfileVariable?: boolean;
  apiId?: string;
  isV2: boolean;
  disabled?: boolean;
};

const VariableTable = styled.div`
  margin-top: 16px;

  .ant-row:first-child {
    margin-bottom: 12px;
  }

  .ant-row {
    margin-bottom: 6px;
  }

  .name-invalid {
    border-color: red;
  }
`;

export const ParameterBuilder = ({
  keyColumn,
  valueColumn,
  initialTableData,
  onUpdate,
  addVariableButtonDataTestString,
  environment,
  showBuiltInProfileVariable,
  apiId,
  isV2,
  disabled,
}: ParameterBuilderProps) => {
  const dispatch = useAppDispatch();
  const [variableTableData, setVariableTableData] =
    useState<VariableDataField[]>(initialTableData);

  const [newInputRef, setNewInputRef] = useState<React.RefObject<InputRef>>();
  useEffect(() => {
    if (newInputRef?.current) {
      newInputRef.current.focus();
      setNewInputRef(undefined);
    }
  }, [newInputRef]);

  const handleAddVariable = useCallback(() => {
    const newInputRef = React.createRef<InputRef>();
    const newVariableTableData = [
      ...variableTableData,
      {
        id: uuidv4(),
        name: "",
        value: "",
        isNameInvalid: false,
        ref: newInputRef,
      },
    ];

    setVariableTableData(newVariableTableData);
    onUpdate(newVariableTableData);
    setNewInputRef(newInputRef);
  }, [variableTableData, onUpdate]);

  const handleSave = useCallback(
    (record: any, property: any, value: any) => {
      const normalizedValue = getNormalizedName(value);
      const newVariableTableData = variableTableData.map((variable) =>
        variable.id === record.id
          ? {
              ...record,
              [property]:
                property === "name" && value !== ""
                  ? normalizedValue
                  : convertParamToJSON(value),
              isNameInvalid:
                property === "name" &&
                value !== "" &&
                (normalizedValue !== value || "environment" === value),
            }
          : variable,
      );
      if (isEqual(variableTableData, newVariableTableData)) {
        // Do not set state and rerender if value is not changed
        return;
      }
      setVariableTableData(newVariableTableData);
      onUpdate(newVariableTableData);
    },
    [variableTableData, onUpdate],
  );

  const handleRemoveVariable = useCallback(
    (id: any) => {
      const newVariableTableData = variableTableData.filter(
        (variable) => variable.id !== id,
      );

      setVariableTableData(newVariableTableData);
      onUpdate(newVariableTableData);
    },
    [variableTableData, onUpdate],
  );

  const showVariableTable = variableTableData.length > 0 || environment;
  // profiles
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profiles = useAppSelector((state) => getWorkflowProfiles(state, apiId));
  const testProfile = useAppSelector((state) =>
    getProfileForTest(state, apiId),
  );
  const onChangeTestProfile = useCallback(
    (profileOption: DropdownOption) => {
      if (apiId) {
        const action = (isV2 ? setV2TestProfile : setV1TestProfile).create({
          apiId: apiId,
          profile: profileOption?.value,
        });
        dispatch(action);
      }
    },
    [apiId, dispatch, isV2],
  );
  const availableProfilesOptions = useMemo(() => {
    return (
      profiles?.editor?.available?.map((profile) => ({
        key: profile?.key,
        value: profile?.key,
        displayName: profile?.key,
      })) ?? []
    );
  }, [profiles?.editor?.available]);
  const controlFlowDependsOnProfile = useSelector(
    selectDoesControlFlowDependOnProfile,
  );

  return (
    <FullWidthSpace direction="vertical">
      {showVariableTable && (
        <VariableTable>
          <Row gutter={8}>
            <Col span={6}>
              <Text strong>
                Variable Name{" "}
                <Tooltip title={keyColumn.toolTip}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Text>
            </Col>
            <Col span={18}>
              <Text strong>
                Test Value{" "}
                <Tooltip title={valueColumn.toolTip}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Text>
            </Col>
          </Row>
          {environment && !enableProfiles && (
            <Row key={environment} wrap={false} gutter={8}>
              <Col span={6}>
                <Input value="environment" disabled={true} />
              </Col>
              <Col span={18} flex="auto">
                <CodeEditor
                  showLineNumbers={false}
                  mode={EditorModes.TEXT}
                  tabBehaviour={TabBehaviour.INDENT}
                  theme={EditorTheme.LIGHT}
                  size={EditorSize.EXTENDED}
                  minHeight="30px"
                  maxHeight="350px"
                  monospace={true}
                  value={environment}
                  disabled={true}
                  fontSize={"13"}
                />
              </Col>
              <Col flex="none">
                <Button key={environment} type="ghost" bordered={false} />
              </Col>
            </Row>
          )}
          {enableProfiles && showBuiltInProfileVariable && (
            <Row key="profile" wrap={false} gutter={8}>
              <Col span={6}>
                <Input value="profile" disabled={true} />
              </Col>
              <Col span={18} flex="auto">
                <RecommendedSingleDropdown
                  options={availableProfilesOptions}
                  onChange={onChangeTestProfile}
                  value={testProfile?.key ?? ENVIRONMENT_STAGING}
                  disabled={controlFlowDependsOnProfile || disabled}
                />
              </Col>
              <Col flex="none">
                <Button key="profile" type="ghost" bordered={false} />
              </Col>
            </Row>
          )}

          {variableTableData.map((variable) => (
            <Row key={variable.id} wrap={false} gutter={8}>
              <Col span={6}>
                <Input
                  placeholder={keyColumn.placeHolder}
                  defaultValue={variable.name}
                  className={variable.isNameInvalid ? "name-invalid" : ""}
                  onPressEnter={(event) =>
                    handleSave(variable, "name", (event.target as any).value)
                  }
                  onBlur={(event) =>
                    handleSave(variable, "name", event.target.value)
                  }
                  data-test="variable-name"
                  disabled={disabled}
                />
              </Col>
              <Col span={18} flex="auto">
                <CodeEditor
                  showLineNumbers={false}
                  mode={EditorModes.TEXT}
                  tabBehaviour={TabBehaviour.INDENT}
                  theme={EditorTheme.LIGHT}
                  size={EditorSize.EXTENDED}
                  minHeight="30px"
                  maxHeight="350px"
                  monospace={true}
                  placeholder={valueColumn.placeHolder}
                  onEditorBlur={(value: any) =>
                    handleSave(variable, "value", value)
                  }
                  value={
                    isObject(variable.value) || variable.value === null
                      ? variable.value
                      : variable.value.toString()
                  }
                  fontSize={"13"}
                  data-test="variable-value"
                  disabled={disabled}
                />
              </Col>
              <Col flex="none">
                <Button
                  key={variable.id}
                  type="ghost"
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveVariable(variable.id)}
                  bordered={false}
                  disabled={disabled}
                />
              </Col>
            </Row>
          ))}
        </VariableTable>
      )}

      {variableTableData.length === 0 ? (
        <Button
          onClick={handleAddVariable}
          data-test={addVariableButtonDataTestString}
          disabled={disabled}
        >
          + Add Variable
        </Button>
      ) : (
        <Typography.Link
          onClick={handleAddVariable}
          data-test={addVariableButtonDataTestString}
          disabled={disabled}
        >
          + Add Variable
        </Typography.Link>
      )}
    </FullWidthSpace>
  );
};
