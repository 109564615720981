import {
  GroupAction,
  GroupDetail,
  OrganizationUserDto,
} from "@superblocksteam/shared";
import { Modal } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { Invitee } from "pages/Permissions/constants";
import SearchAndInviteV2 from "pages/components/SearchAndInviteV2";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { sendSuccessUINotification } from "utils/notification";
import { postGroup } from "./client";
import { MemberToRender, convertToMemberToRender } from "./constants";

const NewMemberModal = ({
  isModalOpen,
  setIsModalOpen,
  setMembers,
  allUsers,
  group,
  invitees,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMembers: React.Dispatch<React.SetStateAction<MemberToRender[]>>;
  allUsers: OrganizationUserDto[];
  group: GroupDetail;
  invitees: Invitee[];
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const orgId = useSelector(selectOnlyOrganizationId);

  const cleanStates = useCallback(() => {
    setError(undefined);
    setSelectedUsers([]);
  }, []);

  const [selectedUsers, setSelectedUsers] = useState<Invitee[]>([]);

  const [isAdding, setIsAdding] = useState(false);
  const addMembers = useCallback(
    async (emails: string[]) => {
      if (!orgId) return;

      setIsAdding(true);
      const { group: updatedGroup, error } = await postGroup(orgId, group.id, {
        operations: emails.map((email) => ({
          email,
          action: GroupAction.ADD_MEMBER,
        })),
      });

      if (updatedGroup) {
        setMembers(updatedGroup.members.map(convertToMemberToRender));
        setIsModalOpen(false);
        sendSuccessUINotification({
          message: `${emails.join(", ")} added to ${updatedGroup?.name} group`,
        });
        cleanStates();
      }
      if (error) {
        setError(error);
      }
      setIsAdding(false);
    },
    [cleanStates, group.id, orgId, setIsModalOpen, setMembers],
  );

  const onCancel = useCallback(() => {
    cleanStates();
    setIsModalOpen(false);
  }, [cleanStates, setIsModalOpen]);

  const onAdd = useCallback(() => {
    addMembers(selectedUsers.map((user) => user.id));
  }, [addMembers, selectedUsers]);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      title={`Add users to ${group.name} group`}
      open={isModalOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          <FormItem label={"Group's members"} required={true}>
            <SearchAndInviteV2
              allInvitees={invitees}
              onSelectedInviteesChange={setSelectedUsers}
              inviteButtonText="Add"
              parentRef={parentRef}
              noGrouping={true}
              placeholder="Enter user names or emails"
              noResultsMessage={"No matching users"}
              popoverProps={{
                hasBackdrop: true,
              }}
            />
          </FormItem>
          <FormItem error={error} hidden={!error} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            onClick={onCancel}
            loading={isAdding}
            data-test={`cancel-add-members`}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={onAdd}
            loading={isAdding}
            data-test={`confirm-add-members`}
            disabled={isAdding || selectedUsers.length === 0}
          >
            Add users
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default NewMemberModal;
