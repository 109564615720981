import {
  Agent,
  AuthConfig,
  AuthType,
  ExchangeCodeRequest,
  ExchangeCodeResponse,
  Organization,
  Profile,
  RequestTokenRequest,
  RequestTokenResponse,
  ViewMode,
} from "@superblocksteam/shared";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { AgentApiPaths } from "store/utils/agent";
import { OrchestratorApiPaths } from "store/utils/orchestrator";
import { callServer, callAgent, HttpMethod } from "../../utils/client";
import { callWithExpectedError } from "./callWithExpectedError";
//TODO(alex): uncomment once https://github.com/superblocksteam/types/pull/128 is merged
//import { ExchangeOauthCodeForTokenRequest } from "@superblocksteam/types"

// for oauth-password
export async function requestTokenV3(
  params:
    | {
        orchestrator: false;
        agents: Agent[];
        organization: Organization;
        body: RequestTokenRequest;
        profile?: Profile;
        mode?: ViewMode;
      }
    | {
        orchestrator: true;
        integrationId: string;
        profile: Profile;
        username: string;
        password: string;
        agents: Agent[];
        organization: Organization;
      },
) {
  if (params.orchestrator) {
    const { agents, organization } = params;
    const resp = await callWithExpectedError<{
      accessToken: string;
      refreshToken: string;
      expiryTimestamp: number;
    }>({
      method: HttpMethod.Post,
      url: OrchestratorApiPaths.REQUEST_TOKEN,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      body: {
        integrationId: params.integrationId,
        profile: params.profile,
        username: params.username,
        password: params.password,
      },
      agents,
      organization,
    });
    return {
      success: resp.success,
      error: resp.error,
      ...("result" in resp ? resp.result : {}),
    };
  } else {
    const { agents, organization, body, profile, mode } = params;
    const requestTokenResponse = await callAgent<RequestTokenResponse>({
      method: HttpMethod.Post,
      agents,
      url: AgentApiPaths.REQUEST_TOKEN,
      body: body,
      organization,
      query: {
        ...(profile?.key ? { profile: profile?.key } : {}),
        ...(profile?.id ? { profileId: profile?.id } : {}),
        ...(mode ? { mode } : {}),
      },
    });
    return {
      success: requestTokenResponse?.error == null,
      error: `${requestTokenResponse.error_description} (Error code: ${requestTokenResponse.error})`,
      accessToken: requestTokenResponse?.access_token,
      refreshToken: requestTokenResponse?.refresh_token,
      expiryTimestamp: requestTokenResponse?.expirationTimestamp,
    };
  }
}

// for oauth-code
export async function exchangeCodeV3(
  params:
    | {
        orchestrator: false;
        agents: Agent[];
        organization: Organization;
        body: any;
        profile?: Profile;
        mode?: ViewMode;
      }
    | {
        orchestrator: true;
        agents: Agent[];
        organization: Organization;
        profile?: Profile;
        accessCode?: string;
        integrationId: string;
        configurationId: string;
        authType: AuthType;
        authConfig: AuthConfig;
      },
) {
  if (params.orchestrator) {
    const { agents, organization } = params;
    return callWithExpectedError<void>({
      method: HttpMethod.Post,
      url: OrchestratorApiPaths.EXCHANGE_CODE,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      body: {
        accessCode: params.accessCode,
        profile: params.profile,
        authType: params.authType,
        authConfig: params.authConfig,
        integrationId: params.integrationId,
        configurationId: params.configurationId,
      },
      agents,
      organization,
    });
  } else {
    const { agents, organization, body, profile, mode } = params;
    const resp = await callAgent<ExchangeCodeResponse>({
      method: HttpMethod.Post,
      url: AgentApiPaths.EXCHANGE_CODE,
      agents,
      organization,
      body: body,
      query: {
        ...(profile?.key ? { profile: profile?.key } : {}),
        ...(profile?.id ? { profileId: profile?.id } : {}),
        ...(mode ? { mode } : {}),
      },
    });
    return {
      success: resp?.successful,
      error: resp?.error,
    };
  }
}

// for oauth-code
export async function exchangeCodeOnServer(requestBody: ExchangeCodeRequest) {
  return callServer<ExchangeCodeResponse>({
    method: HttpMethod.Post,
    url: `v1/oauth2/exchange-code`,
    body: requestBody,
  });
}

// the old userTokenLogout and logoutAgentCookies were identical, so we consolidate
export async function logoutApisV3(params: {
  orchestrator: boolean;
  agents: Agent[];
  organization: Organization;
}) {
  if (params.orchestrator) {
    const { agents, organization } = params;
    return callWithExpectedError<void>({
      method: HttpMethod.Post,
      url: OrchestratorApiPaths.AUTH_LOGOUT,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      body: {},
      agents,
      organization,
    });
  } else {
    const { agents, organization } = params;
    const res = await callAgent<boolean>({
      method: HttpMethod.Post,
      agents,
      url: AgentApiPaths.AUTH_LOGOUT,
      organization,
    });
    return {
      success: typeof res === "boolean" && Boolean(res),
    };
  }
}

export function loginDatasourceV3(
  params:
    | {
        orchestrator: false;
        agents: Agent[];
        organization: Organization;
        body: any;
      }
    | {
        orchestrator: true;
        agents: Agent[];
        organization: Organization;
        integrationId: string;
        profile: Profile;
        token?: string;
        refreshToken?: string;
        idToken?: string;
        expiryTimestamp?: number;
      },
) {
  if (params.orchestrator) {
    // TODO: use agents and orchestrator if orgIsOnPremise(organization) === true
    const { orchestrator, agents, organization, ...body } = params;
    return callWithExpectedError<{ success: boolean }>({
      method: HttpMethod.Post,
      url: OrchestratorApiPaths.AUTH_LOGIN,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      body,
      agents,
      organization,
    });
  } else {
    const { agents, organization, body } = params;
    return callAgent<any>({
      method: HttpMethod.Post,
      agents,
      url: AgentApiPaths.AUTH_LOGIN,
      body: body,
      organization,
    });
  }
}

export async function checkAuthV3(
  params:
    | {
        orchestrator: false;
        agents: Agent[];
        organization: Organization;
        body: any;
        profile?: Profile;
        mode?: ViewMode;
      }
    | {
        orchestrator: true;
        profile?: Profile;
        integrationId: string;
        agents: Agent[];
        organization: Organization;
      },
) {
  if (params.orchestrator) {
    const { integrationId, profile, organization, agents } = params;
    const res = await callWithExpectedError<{
      authenticated: boolean;
    }>({
      method: HttpMethod.Post,
      url: OrchestratorApiPaths.CHECK_AUTH,
      baseUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
      body: {
        integrationId,
        profile,
      },
      organization,
      agents,
    });
    return {
      authenticated: res.success && res.result.authenticated,
    };
  } else {
    const { agents, organization, body, profile, mode } = params;
    return callAgent<{
      authenticated: boolean;
    }>({
      method: HttpMethod.Post,
      url: AgentApiPaths.CHECK_AUTH,
      agents,
      body: body,
      organization,
      diagnosticMetadata: { superblocks_org_id: organization.id },
      query: {
        ...(profile?.key ? { profile: profile?.key } : {}),
        ...(profile?.id ? { profileId: profile?.id } : {}),
        ...(mode ? { mode } : {}),
      },
    });
  }
}
