import CodeMirror from "codemirror";

export enum MarkerType {
  Null = "null",
  FunctionScopeError = "scope-error",
  AppScopeError = "app-scope-error",
}

export const nullMarker = (
  editor: CodeMirror.Editor,
  range: {
    start: number;
    end: number;
  },
  path: string,
): CodeMirror.TextMarker => {
  return editor.markText(
    editor.posFromIndex(range.start),
    editor.posFromIndex(range.end),
    {
      className: "null-highlight",
      attributes: {
        "data-type": MarkerType.Null,
        "data-content": `WARN: ${path} is null`,
      },
    },
  );
};

export const functionScopeErrorMarker = (
  editor: CodeMirror.Editor,
  range: {
    start: number;
    end: number;
  },
  methodName: string,
): CodeMirror.TextMarker => {
  return editor.markText(
    editor.posFromIndex(range.start),
    editor.posFromIndex(range.end),
    {
      className: "cm-sb-error",
      attributes: {
        "data-type": MarkerType.FunctionScopeError,
        "data-method-name": methodName,
        "data-content": `ERROR: ${methodName} is a frontend JavaScript-only method`, // not necessarily used downstream, but good for debugging
      },
    },
  );
};

export const appScopeErrorMarker = (
  editor: CodeMirror.Editor,
  range: {
    line: number;
    start: number;
    end: number;
  },
  scope: string,
  entityName: string,
): CodeMirror.TextMarker => {
  return editor.markText(
    CodeMirror.Pos(range.line, range.start),
    CodeMirror.Pos(range.line, range.end),
    {
      className: "cm-sb-error",
      attributes: {
        "data-type": MarkerType.AppScopeError,
        "data-scope": scope,
        "data-entity-name": entityName,
        "data-content": `The App. prefix is not needed to access other App-level resources from App scope. Please remove the prefix and type "${entityName}" instead.`,
      },
    },
  );
};
