import {
  Agent,
  DatasourceMetadataDto,
  Organization,
} from "@superblocksteam/shared";
import { all, select } from "redux-saga/effects";
import logger from "../../../../utils/logger";
import { createSaga } from "../../../utils/saga";
import { selectActiveAgents } from "../../agents";
import { selectOnlyOrganization } from "../../organizations";
import { getSecretMetadata } from "../client";
import { selectSecretDatasourcesAsList } from "../selectors";
import slice, { DatasourceDtoViews } from "../slice";

interface FetchMetadataPayload {
  environment: string;
  datasourceId?: string;
}

function* getSecretMetadataInternal({
  datasourceId,
  environment,
}: FetchMetadataPayload) {
  const organization: Organization = yield select(selectOnlyOrganization);
  const agents: Agent[] = yield select(
    selectActiveAgents(organization.agentType, environment),
  );
  const secretDatasources: ReturnType<typeof selectSecretDatasourcesAsList> =
    yield select(selectSecretDatasourcesAsList);
  const datasources: DatasourceDtoViews[] = datasourceId
    ? ([
        secretDatasources.find((ds) => ds.integration?.id === datasourceId),
      ].filter(Boolean) as DatasourceDtoViews[])
    : secretDatasources;
  if (!datasources || !datasources.length) return [];
  try {
    const results: Array<DatasourceMetadataDto | undefined> = yield all(
      datasources.map(async (ds) => {
        if (!ds?.integration?.slug) return { secrets: [] };
        return await getSecretMetadata(
          agents,
          organization,
          ds?.integration?.slug,
          environment,
        );
      }),
    );
    return results.map((result, i) => ({
      datasourceId: datasources[i]?.integration?.id,
      result,
    }));
  } catch (err) {
    logger.error(`Error while fetching metadata: ${err}`);
  }
}

export const getSecretMetadataSaga = createSaga(
  getSecretMetadataInternal,
  "getSecretMetadataSaga",
  {
    sliceName: "datasources",
  },
);

slice.saga(getSecretMetadataSaga, {
  start(state, { payload }) {
    //
  },
  success(state, { payload, meta }) {
    payload?.forEach((result) => {
      if (!result.datasourceId || !result.result) return;
      state.meta[result.datasourceId] = { metadata: result.result };
    });
  },
  error(state, { payload, meta }) {
    //
  },
});
