import * as Sentry from "@sentry/react";
import { ApplicationScope } from "@superblocksteam/shared";
import { call, put, select, take, throttle } from "redux-saga/effects";
import { startEvaluation } from "legacy/actions/evaluationActions";
import { LoadingEntitiesState } from "legacy/reducers/evaluationReducers/loadingEntitiesReducer";
import { getHasStartedPageLoad } from "legacy/selectors/evaluatorSelectors";
import { DependencyMap } from "legacy/utils/DynamicBindingTypes";
import { isWidget } from "legacy/workers/evaluationUtils";
import {
  executeV1ApiSaga,
  markPageLoadV1ApisSaga,
  markV1ApiDoneEvaluatingSaga,
} from "store/slices/apisShared";
import { selectAllLoadingApiUnionNames } from "store/slices/apisShared/selectors";
import {
  executeV2ApiSaga,
  markV2ApiDoneEvaluatingSaga,
} from "store/slices/apisV2";
import { markPageLoadV2Apis } from "store/slices/apisV2/actions";

import { getEntityName } from "utils/dottedPaths";
import invertDependencies from "utils/invertDependencies";
import log from "utils/logger";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import {
  getEvaluationEntityToApiDepMap,
  getLegacyDataTree,
} from "../selectors/dataTreeSelectors";

const API_EXECUTION_REDUX_ACTIONS = [
  executeV1ApiSaga.start.type,
  executeV1ApiSaga.error.type,
  executeV1ApiSaga.cancel.type,
  executeV2ApiSaga.start.type,
  executeV2ApiSaga.error.type,
  executeV2ApiSaga.cancel.type,
  markPageLoadV1ApisSaga.type,
  markPageLoadV2Apis.type,
  markV1ApiDoneEvaluatingSaga.success.type,
  markV2ApiDoneEvaluatingSaga.success.type,
];

export function* setLoadingEntitiesFromApis(
  apiNames: Array<string | undefined>,
) {
  const entityToApiDepMap: DependencyMap | undefined = yield select(
    getEvaluationEntityToApiDepMap,
  );
  if (!entityToApiDepMap) return;

  const entities: ReturnType<typeof getLegacyDataTree> =
    yield select(getLegacyDataTree);
  // We are using the recursive evaluator which returns `entityToApiDepMap`.
  // Use that as it is more efficient.
  const apiToEntityDepMap = invertDependencies(undefined, entityToApiDepMap);
  const loadingEntities = apiNames
    .filter(Boolean)
    // TODO(API_SCOPE): API names are currently un-scoped
    .flatMap((actionName) => {
      const scopedNames =
        apiToEntityDepMap[`${ApplicationScope.PAGE}.${actionName}`] || [];
      return scopedNames.map((name) => getEntityName(name as any));
    });
  const filteredLoadingEntityIds = loadingEntities.reduce((acc, entityName) => {
    const entity = entities[entityName];
    if (isWidget(entity) && (entity.animateLoading ?? true)) {
      acc[entity.widgetId] = true;
    }
    return acc;
  }, {} as LoadingEntitiesState);

  yield put({
    type: ReduxActionTypes.SET_LOADING_ENTITIES,
    payload: filteredLoadingEntityIds,
  });
}

export function* setWidgetsLoadingSaga() {
  const entityToApiDepMap: DependencyMap | undefined = yield select(
    getEvaluationEntityToApiDepMap,
  );
  const hasStartedApis: boolean = yield select(getHasStartedPageLoad);

  if (!(entityToApiDepMap && hasStartedApis)) {
    return;
  }

  const loadingActions: string[] = yield select(selectAllLoadingApiUnionNames);
  yield call(setLoadingEntitiesFromApis, loadingActions);
}

function* actionExecutionChangeListenerSaga() {
  yield throttle(5, API_EXECUTION_REDUX_ACTIONS, setWidgetsLoadingSaga);
}

export default function* actionExecutionChangeListeners() {
  yield take(startEvaluation.type);
  while (true) {
    try {
      yield call(actionExecutionChangeListenerSaga);
    } catch (e) {
      log.error(e);
      Sentry.captureException(e);
    }
  }
}
