import ecma from "tern/defs/ecmascript.json";

import { convertDef, PublicDef } from "autocomplete/convertPublicDefs";
import base64 from "legacy/constants/defs/base64-js.json";
import lodash from "legacy/constants/defs/lodash.json";
import moment from "legacy/constants/defs/moment.json";
import polished from "legacy/constants/defs/polished.json";
import xmlJs from "legacy/constants/defs/xmlParser.json";
import superblocksDef from "./customDefs.json";

// See public/json-view for actual js definition
const DEFS = (
  [
    ecma,
    lodash,
    base64,
    moment,
    xmlJs,
    polished,
    superblocksDef /* TODO Figure out a way to generate tern type defs from typescript instead of hard coding the modules */,
  ] as PublicDef[]
).map(convertDef);

export default DEFS;
