import { Breadcrumb } from "antd";
import React from "react";
import styled from "styled-components";
import Link from "../../../components/ui/Link";

const BreadcrumbWrapper = styled.div`
  margin-bottom: 15px;
`;

type Props = {
  paths: Path[];
};

type Path = {
  title: string;
  link?: string;
};

export const BreadCrumb = ({ paths }: Props) => {
  return (
    <BreadcrumbWrapper>
      <Breadcrumb separator="/">
        {paths.map((path) => (
          <Breadcrumb.Item key={path.title}>
            {path.link && <Link to={path.link}>{path.title}</Link>}
            {!path.link && <>{path.title}</>}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </BreadcrumbWrapper>
  );
};
