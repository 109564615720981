import {
  CommitDto,
  GetApiCommitsResponseBody,
  GetApplicationCommitsResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { ReduxActionErrorTypes } from "legacy/constants/ReduxActionConstants";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { HttpError } from "store/utils/types";
import { sendErrorUINotification } from "utils/notification";
import { callSagas, createSaga } from "../../../utils/saga";
import { createCommit as createCommitCall } from "../client";
import slice from "../slice";
import { fetchCommitsSaga } from "./fetchCommits";

function* createCommitSaga({
  entityId,
  entityType,
  commitMessage,
}: {
  entityId: string;
  entityType: VersionedEntityType;
  commitMessage: string;
}): Generator<
  any,
  GetApplicationCommitsResponseBody | GetApiCommitsResponseBody,
  any
> {
  const branch: ReturnType<typeof getCurrentBranch> =
    yield select(getCurrentBranch);
  const lastSuccessfulWrite: number = yield select(selectLastSuccessfulWrite);
  try {
    yield call(
      createCommitCall,
      entityId,
      entityType,
      commitMessage,
      lastSuccessfulWrite,
      branch?.name,
    );
    const [result] = yield callSagas([
      fetchCommitsSaga.apply({ entityId, entityType }),
    ]);
    return result;
  } catch (e: HttpError | unknown) {
    if (
      e instanceof HttpError &&
      e.code === API_STATUS_CODES.RESOURCE_CONFLICT
    ) {
      yield put({
        type: ReduxActionErrorTypes.CREATE_COMMIT_ERROR,
        payload: {
          error: {
            code: API_STATUS_CODES.RESOURCE_CONFLICT,
          },
        },
      });
    }
    throw e;
  }
}

const createCommit = createSaga(createCommitSaga, createCommitSaga.name, {
  sliceName: slice.name,
});
export { createCommit as createCommitSaga };

slice.saga(createCommit, {
  start(state) {
    state.loading.commits = true;
  },
  success(state, { payload }) {
    state.entity = { ...state.entity, autosaves: {} };
    payload.commits?.forEach((commit: CommitDto) => {
      state.entity.commits[commit.commitId] = commit;
    });
    state.loading.commits = false;
  },
  error(state, { payload }) {
    state.error = { autosaves: payload, commits: payload };
    state.loading.commits = false;
    if (
      !(
        payload instanceof HttpError &&
        payload.code === API_STATUS_CODES.RESOURCE_CONFLICT
      )
    ) {
      sendErrorUINotification({
        message: String(payload),
      });
    }
  },
});
