import { createAction } from "@reduxjs/toolkit";
import { ApiInfo, ApisMap } from "legacy/constants/ApiConstants";

type UpdatePayload = { id: ApiInfo["id"]; data: Partial<ApiInfo> };

export const updatePartialApiInfo = createAction(
  "UPDATE_PARTIAL_API_INFO",
  (payload: UpdatePayload) => ({
    payload,
  }),
);

export const updateApiInfo = createAction(
  "UPDATE_API_INFO",
  (payload: ApisMap) => ({
    payload,
  }),
);

export const deleteApiInfo = createAction(
  "DELETE_API_INFO",
  (payload: { id: ApiInfo["id"]; name: string }) => ({
    payload,
  }),
);
