import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { usePointerDownOutside } from "hooks/ui";
import PerformanceTracker, {
  PerformanceName,
} from "legacy/utils/PerformanceTracker";
import AiEditFlow from "./AiEditFlow";
import AiExplainFlow from "./AiExplainFlow";
import AiGenerateFlow from "./AiGenerateFlow";
import AiMockFlow from "./AiMockFlow";
import OptionList from "./OptionList";
import { AiAssistantProps, FlowType, AiAssistantOptionType } from "./constants";

const Wrapper = styled.div<{
  $hasSelectedOption: boolean;
  $maxHeight?: number;
}>`
  background: ${(props) => props.theme.colors.WHITE};
  position: relative;
  box-shadow:
    0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);
  border-radius: 4px;
  height: 100%;
  width: ${(props) => (props.$hasSelectedOption ? "100%" : "min-content")};
  overflow: auto;
  pointer-events: all;
  max-height: ${(props) => props.$maxHeight}px;
`;

function useEscapeKey(handleClose: () => void) {
  const handleEscKey = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);
}

const AiAssistant = (props: AiAssistantProps) => {
  const {
    options,
    onClose,
    onConfirm,
    selectedText,
    editorContents,
    expectedDataFormat,
    integrationId,
    pluginId,
    firstLineNumber,
    onOptionSelected,
    maxHeight,
    configurationId,
    initialConfig,
    datasourceMeta,
    scope,
  } = props;
  useEscapeKey(onClose);
  const aiAssistantElem = useRef<HTMLDivElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<
    undefined | AiAssistantOptionType
  >();
  // add event listener for cmd+enter to prevent it from submitting the api
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
        e.stopPropagation();
      }
    };
    const elem = aiAssistantElem.current;
    elem?.addEventListener?.("keydown", handleKeyDown);
    return () => elem?.removeEventListener?.("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (options.length === 1) {
      setSelectedOption(options[0]);
    }
  }, [options]);

  const onSelectOption = useCallback(
    (option: AiAssistantOptionType) => {
      setSelectedOption(option);
      onOptionSelected();
      PerformanceTracker.track(PerformanceName.AI_ASSISTANT_OPENED, {
        type: `${option.flowType} ${option.syntax}`,
      });
    },
    [onOptionSelected],
  );

  usePointerDownOutside({
    wrapperRefs: [aiAssistantElem],
    onClickOutside: () => {
      if (!selectedOption) {
        onClose();
      }
    },
  });

  const content = useMemo(() => {
    if (selectedOption) {
      const { flowType } = selectedOption;
      switch (flowType) {
        case FlowType.GENERATE:
          return (
            <AiGenerateFlow
              onConfirm={onConfirm}
              onClose={onClose}
              option={selectedOption}
              integrationId={integrationId}
              configurationId={configurationId}
              datasourceMeta={datasourceMeta}
              initialConfig={initialConfig}
              scope={scope}
            />
          );
        case FlowType.EXPLAIN:
          return (
            <AiExplainFlow
              onClose={onClose}
              codeToExplain={selectedText ?? ""}
              allCode={editorContents ?? ""}
              option={selectedOption}
              onConfirm={onConfirm}
            />
          );
        case FlowType.MOCK:
          return (
            <AiMockFlow
              onClose={onClose}
              onConfirm={onConfirm}
              option={selectedOption}
              expectedDataFormat={expectedDataFormat}
            />
          );
        case FlowType.EDIT:
          return (
            <AiEditFlow
              onConfirm={onConfirm}
              onClose={onClose}
              option={selectedOption}
              integrationId={integrationId}
              configurationId={configurationId}
              codeToEdit={selectedText ?? ""}
              allCode={editorContents ?? ""}
              datasourceMeta={datasourceMeta}
              firstLineNumber={firstLineNumber ?? 1}
            />
          );
        default: {
          const exhaustiveCheck: never = flowType;
          throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
        }
      }
    }

    return (
      <OptionList
        options={options}
        onSelect={onSelectOption}
        hasCode={(editorContents ?? "").trim().length > 0}
        pluginId={pluginId}
      />
    );
  }, [
    onConfirm,
    onClose,
    options,
    selectedText,
    selectedOption,
    onSelectOption,
    editorContents,
    expectedDataFormat,
    integrationId,
    pluginId,
    firstLineNumber,
    configurationId,
    initialConfig,
    datasourceMeta,
    scope,
  ]);

  return (
    <Wrapper
      data-test="ai-assistant"
      ref={aiAssistantElem}
      $hasSelectedOption={selectedOption != null}
      $maxHeight={maxHeight}
    >
      {content}
    </Wrapper>
  );
};

export default AiAssistant;
