import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import slice from "./slice";

export const selectCommits = createSelector(slice.selector, (state) =>
  Object.values(state.entity.commits),
);

export const selectAutosaves = createSelector(slice.selector, (state) =>
  Object.values(state.entity.autosaves),
);

export const selectLoadingState = createSelector(
  slice.selector,
  (state) => state.loading.autosaves || state.loading.commits,
);

export const selectLoadingCommits = createSelector(
  slice.selector,
  (state) => state.loading.commits,
);

export const selectLoadingCommitById = createCachedSelector(
  slice.selector,
  (state: unknown, commitId: string | undefined) => commitId,
  (state, commitId) =>
    commitId ? (state.loading as Record<string, boolean>)[commitId] : false,
)((state, commitId) => commitId ?? "undefined");
