import {
  DropdownOption,
  Table,
  Column,
  Schema,
  ENVIRONMENT_STAGING,
} from "@superblocksteam/shared";
import { Select, Typography } from "antd";
import { get } from "lodash";
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store/helpers";
import {
  getMetadataSaga,
  selectDatasourceMetaById,
} from "store/slices/datasources";
import logger from "utils/logger";
import { useSaga } from "../../../../hooks/store";
import { useDebounce, useFeatureFlag } from "../../../../hooks/ui";
import { useApiId } from "../../../../pages/Editors/ApiEditor/ControlFlow/controlFlowSelectors";
import { selectV2ApiById } from "../../../../store/slices/apisV2";
import { Flag } from "../../../../store/slices/featureFlags";
import { getOrSetWindowEnvironment } from "../../../../utils/environment";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormSubheading } from "../FormSubheading";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";
import { StyledSelect } from "./StyledComponents";

interface DynamicFormMetadataDropdownProps {
  dependencyPath?: string;
  datasourceId: string;
  setFormError: (error: string) => void;
  showSearch?: boolean;
  optionFilterProp?: string;
  enableGSheetsPagination?: boolean;
}

type Props = DynamicFormMetadataDropdownProps &
  DynamicFormItemProps & {
    keyAccessor: string;
    valueAccessor: string;
    displayNameAccessor: string;
    listAccessor?: string;
    defaultToFirstOption?: boolean;
    clearDependentFieldsOnChange?: string[];
    filterDependency?: string;
    filterFieldName?: string;
    filterDependencyValue?: string;
    formValue?: string;
  };

const DEFAULT_LIST_ACCESSOR = "metadata.dbSchema.tables";

// this helps to trigger scroll to bottom event sooner
const REACH_BOTTOM_BUFFER = 10;

const DynamicFormMetadataDropdown = ({
  path,
  label,
  placeholder,
  initialValue,
  rules,
  dependencyPath,
  datasourceId,
  setFormError,
  keyAccessor,
  valueAccessor,
  displayNameAccessor,
  listAccessor,
  defaultToFirstOption,
  filterDependency,
  filterFieldName,
  tooltip,
  subHeading,
  filterDependencyValue,
  formValue,
  clearDependentFieldsOnChange,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const { onChange: contextOnChange, getValue, subscribe } = context;
  const [getMetadata] = useSaga(getMetadataSaga);

  const apiId = useApiId();
  const v2Api = useAppSelector((state) => selectV2ApiById(state, apiId));
  const stepId = v2Api?.apiPb?.blocks?.[0]?.name ?? "";

  const navigate = useNavigate();
  const location = useLocation();
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const environment = getOrSetWindowEnvironment(
    navigate,
    location,
    ENVIRONMENT_STAGING,
    enableProfiles,
  );

  const fetchMetadata = useCallback(
    async (searchKeyword?: string) => {
      if (datasourceId) {
        try {
          await getMetadata({
            version: "v2",
            datasourceId: datasourceId,
            environment,
            apiId,
            searchKeyword,
            actionId: stepId,
          });
        } catch (e) {
          // Noop
          // Note: This is how v1 apis are also handled
        }
      }
    },
    [datasourceId, getMetadata, environment, apiId, stepId],
  );

  const onChange = useCallback(
    (val?: string, skipClear?: boolean) => {
      if (clearDependentFieldsOnChange && !skipClear) {
        clearDependentFieldsOnChange.forEach((field) => {
          contextOnChange(field, "", { debounced: true });
        });
      }
      contextOnChange(path, val, { debounced: false }); // when user updates the value
    },
    [path, contextOnChange, clearDependentFieldsOnChange],
  );
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  const filterFn = useCallback(
    (entity: Table | Schema) => {
      if (!filterDependency || !filterFieldName) {
        return true;
      }
      const filterFieldValue = (entity as Record<string, any>)[
        filterFieldName
      ] as string;
      const depValue = filterDependencyValue ?? getValue(filterDependency);
      if (!depValue && !filterFieldValue) {
        return true;
      }
      return filterFieldValue === depValue;
    },
    [filterDependencyValue, filterDependency, filterFieldName, getValue],
  );

  const options = useMemo(() => {
    const result: DropdownOption[] = [];
    const rawOptions = get(
      datasourceMeta,
      listAccessor ?? DEFAULT_LIST_ACCESSOR,
      [],
    );
    try {
      if (dependencyPath) {
        const parentValue = getValue(dependencyPath);
        rawOptions.filter(filterFn).forEach((entity: Table | Schema) => {
          // TODO:(pbardea)
          // "columns" in entity is a placeholder for `entity instanceof Table`.
          // The gsheet plugin doesn't strongly type it's metadata as a Table
          // type. This is the fastest fix.
          if ("columns" in entity && entity.id === parentValue) {
            entity.columns.forEach((column: Column) => {
              result.push({
                key: column.name as string,
                value: column.name as string,
                displayName: column.name as string,
                parentKey: entity.id,
              });
            });
          }
        });
        const found = result?.find((option) => option.value === formValue);
        if (formValue && rawOptions.length > 0 && !found) {
          // invalidate child dropdown's value
          contextOnChange(path, "");
        }
        const dependencyValue = getValue(dependencyPath);
        if (result.length > 0 || !dependencyValue) {
          setIsLoading(false);
        }
      } else {
        rawOptions.filter(filterFn).forEach((entity: Table | Schema) => {
          result.push({
            key: get(entity, keyAccessor),
            value: get(entity, valueAccessor),
            displayName: get(entity, displayNameAccessor),
          });
        });
        if (rawOptions.length > 0) {
          setIsLoading(false);
        }
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [
    listAccessor,
    dependencyPath,
    contextOnChange,
    formValue,
    keyAccessor,
    valueAccessor,
    displayNameAccessor,
    path,
    datasourceMeta,
    filterFn,
    getValue,
  ]);

  // if defaultToFirstOption is true, set the value to the first option if it's not set
  useEffect(() => {
    if (defaultToFirstOption && !formValue && options.length > 0) {
      onChange(options[0].value, true);
    }
  }, [formValue, options, defaultToFirstOption, onChange]);

  useEffect(() => {
    if (dependencyPath) {
      const unsubscribe = subscribe(dependencyPath, (dependencyValue) => {
        const found = options?.find(
          (option) => option.parentKey === dependencyValue,
        );
        if (!found) {
          // metadata loading is in progress
          setIsLoading(true);
          //TODO(alex): invalidate child dropdown's value
        }
      });
      return () => unsubscribe();
    }
  }, [path, subscribe, dependencyPath, options]);

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [path, rules, context.registerValidation, context]);

  const debouncedFetchMetadata = useDebounce(fetchMetadata, 200);
  const dispatch = useAppDispatch();
  const [spreadSheetSearch, setSpreadSheetSearch] = useState("");

  const handleSelectChange = useCallback(
    (value: any) => {
      if (otherProps.enableGSheetsPagination) {
        setSpreadSheetSearch("");
      }
      onChange(value);
    },
    [setSpreadSheetSearch, otherProps, onChange],
  );

  const handleScroll = (event: { target: any }) => {
    const { target } = event;
    // Check if the user has scrolled to the bottom and only trigger fetch for gsheet
    if (
      otherProps.enableGSheetsPagination &&
      datasourceMeta?.metadata?.gSheetsNextPageToken &&
      target.scrollTop + target.offsetHeight + REACH_BOTTOM_BUFFER >=
        target.scrollHeight
    ) {
      debouncedFetchMetadata?.(spreadSheetSearch);
    }
  };

  const handleSearch = (searchKeyword: any) => {
    // search value is changed, fetch metadata with this new search keyword and clear
    // existing metadata state. This is gsheet specific logic
    if (otherProps.enableGSheetsPagination) {
      // getMetadataSaga.cancel will clear the dropdown options
      // so dropdown will not temporarily show search result based on old state
      // while user is typing
      setSpreadSheetSearch(searchKeyword);
      dispatch({
        type: getMetadataSaga.cancel.type,
        payload: {
          datasourceId,
          searchKeyword,
        },
      });
      debouncedFetchMetadata?.(searchKeyword);
    }
  };

  return (
    <>
      <label>
        <FormLabel hidden={otherProps.hidden}>{label}</FormLabel>
        <FormTooltip tooltip={tooltip} />
        <StyledSelect
          value={isLoading ? "Loading..." : (formValue ?? initialValue)}
          onChange={handleSelectChange}
          onPopupScroll={handleScroll}
          onSearch={handleSearch}
          loading={isLoading}
          {...otherProps}
          style={{ width: "100%" }}
          disabled={isLoading || otherProps.disabled}
        >
          {options?.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              label={option.displayName ?? option.value}
              data-test={`dropdown-select-${option.value}`}
            >
              {option.displayName ?? option.value}
            </Select.Option>
          ))}
        </StyledSelect>
      </label>
      {subHeading && <FormSubheading text={subHeading} />}
      <Typography.Text type="danger">{validationMessage}</Typography.Text>
    </>
  );
};

const DynamicFormFieldWrapper = (props: Props) => {
  const { datasourceId, filterDependency, path } = props;
  // this wrapper will subscribe to the relevent context and pass the value to the child component
  // We won't render the child until the relevant context is available, because otherwise there are race conditions
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(FormContext);
  const [filterDependencyValue, setFilterDependencyValue] = useState<any>(null);
  const [value, setValue] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const subscribe = context.subscribe;
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  useEffect(() => {
    if (isLoading && datasourceMeta) {
      setIsLoading(false);
    }
  }, [datasourceMeta, isLoading]);

  useEffect(() => {
    if (filterDependency) {
      const unsubscribe = subscribe(filterDependency, (dependencyValue) => {
        setFilterDependencyValue(dependencyValue);
      });
      return () => unsubscribe();
    }
  }, [filterDependency, subscribe]);

  useEffect(() => {
    const unsubscribe = subscribe(path, (value: any) => {
      setValue(value as string); //update UI
    });
    setIsSubscribed(true);
    return () => unsubscribe();
  }, [path, subscribe]);

  if (isLoading || !isSubscribed) {
    return null;
  }
  return (
    <DynamicFormMetadataDropdown
      filterDependencyValue={filterDependencyValue}
      formValue={value}
      {...props}
    />
  );
};

export default DynamicFormFieldWrapper;
