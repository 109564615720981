import React from "react";
import styled from "styled-components";

const StyledButton = styled.span`
  display: inline-block;
  cursor: pointer;
`;

const TextButton = (props: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <StyledButton role="button" tabIndex={0} {...props}>
      {props.children}
    </StyledButton>
  );
};

export default TextButton;
