import {
  FormItemDisplay,
  FormItemTransformation,
  FormRow,
  FormTemplate,
  isFormItem,
} from "@superblocksteam/shared";
import { FormProps } from "antd";
import { set, get } from "lodash";
import dot from "utils/dot";

export const EMPTY_ON_CHANGE = () => {
  return;
};

const getFieldTransformationsFromFormTemplate = (
  formTemplate: FormTemplate,
) => {
  // return a map of field name to transformation function
  const fieldTransformations: Record<string, FormItemTransformation> = {};
  for (const section of formTemplate.sections) {
    for (const sectionItem of section.items) {
      const isRow = !isFormItem(sectionItem);
      const rowItems = isRow
        ? (sectionItem as FormRow).rowItems
        : [sectionItem];
      for (const item of rowItems) {
        if (item.transformation) {
          fieldTransformations[item.name] = item.transformation;
        }
      }
    }
  }
  return fieldTransformations;
};

const encodeField = (transformation: FormItemTransformation, value: any) => {
  try {
    switch (transformation) {
      case FormItemTransformation.BYTE_ARRAY: {
        const enc = new TextEncoder();
        return enc.encode(value);
      }
      default:
        return value;
    }
  } catch (e) {
    return value;
  }
};

const decodeField = (transformation: FormItemTransformation, value: any) => {
  try {
    switch (transformation) {
      case FormItemTransformation.BYTE_ARRAY: {
        const dec = new TextDecoder();
        return dec.decode(value);
      }
      default:
        return value;
    }
  } catch (e) {
    return value;
  }
};

export function mapObjectToFormValues(
  obj: Record<string, unknown>,
  formTemplate?: FormTemplate,
) {
  const fieldTransformations = formTemplate
    ? getFieldTransformationsFromFormTemplate(formTemplate)
    : undefined;
  const formValues = dot.dot(obj);
  for (const [key, value] of Object.entries(formValues)) {
    if (fieldTransformations?.[key]) {
      formValues[key] = decodeField(fieldTransformations[key], value);
    }
  }
  return formValues;
}

export function mapFormValuesToObject(
  formFields: Record<string, unknown>,
  protoClass: any = null,
  formTemplate?: FormTemplate,
): Record<string, unknown> {
  const fieldTransformations = formTemplate
    ? getFieldTransformationsFromFormTemplate(formTemplate)
    : undefined;
  const obj = {};

  for (const [key, value] of Object.entries(formFields)) {
    if (fieldTransformations?.[key]) {
      set(obj as any, key, encodeField(fieldTransformations[key], value));
    } else {
      set(obj as any, key, value);
    }
  }
  if (protoClass) {
    const proto = new protoClass(obj);
    const protoJson = proto.toJson();

    // TODO [ro] Auth framework relies on configuration to have top level authConfig
    // once we updated auth framework to support proto based auth payload, this should be removed
    protoJson["authConfig"] = get(obj, "authConfig");
    return protoJson;
  }
  return obj;
}

export function shouldShowItem(
  formValues: FormProps["initialValues"],
  formItemDisplay?: FormItemDisplay,
): boolean {
  let show = true;
  if (formItemDisplay?.show) {
    for (const [name, matchValues] of Object.entries(formItemDisplay.show)) {
      const value = get(formValues, name);
      show = show && matchValues.includes(String(value));
    }
  }
  return show;
}
