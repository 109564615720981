import {
  OBS_TAG_ORG_ID,
  OBS_TAG_ORG_NAME,
  OBS_TAG_RESOURCE_ID,
  OBS_TAG_RESOURCE_TYPE,
  OBS_TAG_USER_EMAIL,
} from "@superblocksteam/shared";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import {
  getCurrentOrgId,
  getCurrentOrgNameSelector,
} from "legacy/selectors/organizationSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { getCurrentApplication } from "store/slices/application/selectors";
import { TRACES_KEY, TRACE_LAST_UPDATED_KEY } from "./LocalStorageExporter";
import UITracing from "./UITracing";

export const useTracingManager = () => {
  // manage span attributes
  const user = useSelector(getCurrentUser);
  const organizationId = useSelector(getCurrentOrgId);
  const application = useSelector(getCurrentApplication);
  const organizationName = useSelector(getCurrentOrgNameSelector);
  const attributes = useMemo(
    () => ({
      [OBS_TAG_RESOURCE_ID]: application?.id,
      [OBS_TAG_RESOURCE_TYPE]: "application",
      [OBS_TAG_ORG_ID]: organizationId,
      [OBS_TAG_USER_EMAIL]: user?.email,
      [OBS_TAG_ORG_NAME]: organizationName,
    }),
    [application?.id, organizationId, user?.email, organizationName],
  );

  useEffect(() => {
    UITracing.setGlobalAttributes(attributes);
  }, [attributes]);

  // manage sessions based on app id
  const location = useLocation();
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const manageSession = useCallback(() => {
    const matched = matchPath(
      "/applications/:applicationId/*",
      location.pathname,
    );
    const appIdInUrl =
      matched && (matched.params as Record<string, string>).applicationId;
    if (appIdInUrl !== applicationId) {
      if (appIdInUrl) {
        UITracing.startSession(appIdInUrl);
      } else {
        UITracing.endSession();
      }

      setApplicationId(appIdInUrl);
    }
  }, [applicationId, location.pathname]);

  useEffect(() => {
    manageSession();
  }, [manageSession]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetStoredSpans = () => {
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const memoizedTraces = useRef();

  return useCallback(() => {
    const newLastUpdated = localStorage.getItem(TRACE_LAST_UPDATED_KEY);
    if (lastUpdated && newLastUpdated === lastUpdated) {
      return memoizedTraces.current;
    } else {
      const rawTraces = localStorage.getItem(TRACES_KEY);
      try {
        memoizedTraces.current = JSON.parse(rawTraces ?? "[]");
      } catch (e) {
        console.error(e);
      }
      setLastUpdated(newLastUpdated);
    }
  }, [lastUpdated]);
};
