import { Dimension, DimensionModes, Margin } from "@superblocksteam/shared";
import { type CSSProperties } from "react";
import {
  CanvasLayout,
  GridDefaults,
  PAGE_WIDGET_ID,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { FlattenedWidgetLayoutMap } from "legacy/widgets/shared";

const getMarginCSS = (margin?: Margin) => {
  return {
    marginTop: margin?.top?.value,
    marginRight: margin?.right?.value,
    marginBottom: margin?.bottom?.value,
    marginLeft: margin?.left?.value,
  } satisfies CSSProperties;
};

export const isFitContent = (
  dimensionMode: DimensionModes | undefined,
): boolean => {
  return dimensionMode === "fitContent";
};

export const isFillParent = (
  dimensionMode: DimensionModes | undefined,
): boolean => {
  return dimensionMode === "fillParent";
};

export const isDynamicSize = (
  dimensionMode: DimensionModes | undefined,
): boolean => {
  return isFitContent(dimensionMode) || isFillParent(dimensionMode);
};

export const isFixedHeight = (
  dimensionMode: DimensionModes | undefined,
): boolean => {
  return dimensionMode === "gridUnit" || dimensionMode === "px";
};

export const isFixedWidth = (
  dimensionMode: DimensionModes | undefined,
): boolean => {
  return dimensionMode === "px";
};

export const getFlexStyles = (params: {
  height: Dimension<WidgetHeightModes>;
  heightPx?: number;
  minHeight?: Dimension<"px" | "gridUnit"> | undefined;
  maxHeight?: Dimension<"px" | "gridUnit"> | undefined;
  minWidth?: Dimension<"px"> | undefined;
  maxWidth?: Dimension<"px"> | undefined;
  width: Dimension<WidgetWidthModes>;
  widthPx?: number;
  parentColumnSpace: number;
  parentDirection: "row" | "column";
  isDraggingOrResizingOtherWidget?: boolean;
  computedHeight?: number;
  margin?: Margin;
}) => {
  const {
    height,
    width,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    parentDirection,
    parentColumnSpace,
    isDraggingOrResizingOtherWidget,
    computedHeight,
    margin,
  } = params;
  // TODO (layouts): Refactor this code to be easier to understand, as it's bug prone
  let style: CSSProperties = {};

  if (parentDirection === "row") {
    if (width.mode === "fillParent") {
      const fillWeight = 1; // TODO - fill weight not configurable right now
      style = {
        flex: `${fillWeight} 0 ${GridDefaults.FILL_PARENT_DEFAULT_MIN_WIDTH_PX}px`,
      };
    } else {
      const widthPx =
        params.widthPx ?? Dimension.toPx(width, parentColumnSpace).value;

      style = {
        flex: `0 0 ${widthPx}px`,
      };
    }

    if (height.mode === "fillParent") {
      style.alignSelf = "stretch";
      style.height = ""; // we need to prevent the base height from applying because it really messes with with the flex layout
    }
  } else {
    if (isDraggingOrResizingOtherWidget && height.mode === "fillParent") {
      style = {
        flex: `0 0 ${computedHeight}px`,
      };
    } else if (height.mode === "fillParent") {
      const fillWeight = 1; // TODO - fill weight not configurable right now
      style = {
        flex: `${fillWeight} 0 ${
          GridDefaults.FILL_PARENT_DEFAULT_MIN_HEIGHT *
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT
        }px`,
      };
      if (minHeight != null) {
        style.minHeight = `${
          Dimension.toPx(minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
        }px`;
      }
      if (maxHeight != null) {
        style.maxHeight = `${
          Dimension.toPx(maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
        }px`;
      }
    } else {
      const heightPx =
        params.heightPx ??
        Dimension.toPx(height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value;
      style = {
        flex: `0 0 ${heightPx}px`,
      } as CSSProperties;
    }

    if (width.mode === "fillParent") {
      style.alignSelf = "stretch";
      style.width = ""; // we need to prevent the base width from applying because it really messes with with the flex layout
    }
  }

  if (minWidth != null) {
    style.minWidth = `${Dimension.toPx(minWidth, parentColumnSpace).value}px`;
  }
  if (maxWidth != null) {
    style.maxWidth = `${Dimension.toPx(maxWidth, parentColumnSpace).value}px`;
  }

  style = {
    ...style,
    ...getMarginCSS(margin),
  };

  return style;
};

export const doesWidgetContainFillParentSection = (
  widgets: FlattenedWidgetLayoutMap,
  rootId?: string,
) => {
  const parent = widgets[rootId ?? PAGE_WIDGET_ID];
  const children = parent?.children ?? [];
  if (parent?.layout === CanvasLayout.HSTACK) {
    return children.some(
      (childId) => widgets[childId]?.width.mode === "fillParent",
    );
  }
  return children.some(
    (childId) => widgets[childId]?.height.mode === "fillParent",
  );
};
