// A replacer function to be used with JSON.stringify.
// It sorts the keys of an object so that they are traversed by `JSON.stringify` in the canonical order.
function replacer(_key: string, value: unknown): unknown {
  if (typeof value !== 'object' || value === null || Array.isArray(value)) {
    return value;
  }
  // `value` is an object
  const sortedObj = {};
  Object.keys(value)
    .sort()
    .forEach((key) => {
      sortedObj[key] = value[key];
    });
  return sortedObj;
}

// export so that it can be used in tests
export function stableJSONStringify_fast(value: unknown): string | undefined {
  return JSON.stringify(value, replacer);
}
