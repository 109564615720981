import { Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";

import { CodeBlock } from "../CodeBlock";
import {
  AgentDeploymentInstructionsProps,
  AgentFormDefault,
  HELM_CHART_REPOSITORY_URL,
} from "../common";
import { Helm_AgentKey } from "../constants";

const { Paragraph, Text } = Typography;

const Helm = ({
  agentHost,
  agentUrl,
  agentEnvironment,
  agentDataDomain,
}: AgentDeploymentInstructionsProps) => {
  const agentHostUrlClause = `${
    isEmpty(agentUrl) ? AgentFormDefault.URL : agentUrl
  }`;
  const agentHelmReleaseName = "superblocks-agent";
  const controllerResource = `deploy/superblocks-agent`;

  return (
    <Paragraph>
      <Text strong>Add our Helm chart repository:</Text>
      <CodeBlock
        content={`helm repo add superblocks ${HELM_CHART_REPOSITORY_URL}`}
      />
      <Text strong>
        Configure <Text code>values.yaml</Text>
      </Text>
      <CodeBlock
        content={`#(...)
ingress:
  enabled: true
  class: "" # nginx
  annotations: {}
    # kubernetes.io/tls-acme: "true"
  hosts:
    - host: ${agentHost}
      paths:
        - path: /
          pathType: Prefix
  tls: []
  # - secretName: chart-example-tls
  #   hosts:
  #     - ${agentHost} `}
      />
      <Text strong>Deploy the Agent Platform Helm chart:</Text>
      <CodeBlock
        content={`helm upgrade -i -n superblocks ${agentHelmReleaseName} superblocks/${agentHelmReleaseName} \\
  --create-namespace \\
  -f values.yaml \\
  --set ${Helm_AgentKey}="<AGENT_KEY>" \\
  --set superblocks.agentHostUrl="${agentHostUrlClause}" \\
  --set superblocks.agentTags="profile:${agentEnvironment}" \\
  --set superblocks.agentDataDomain="${agentDataDomain}"`}
      />
      <CodeBlock
        content={`kubectl -n superblocks rollout status ${controllerResource} -w`}
      />
    </Paragraph>
  );
};

export default Helm;
