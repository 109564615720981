import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Alert, Space, Typography } from "antd";
import React, { FormEvent, useState } from "react";
import SbText from "components/ui/SbText";
import { Spinner } from "components/ui/Spinner";
// TODO: Implement this in a follow up.
// import { usePreventNavigation } from "hooks/ui";
import {
  CHECKOUT_COMPLETE_URL,
  MSA_URL,
  TERMS_URL,
} from "legacy/constants/routes";
import { colors } from "styles/colors";
import logger from "utils/logger";
import { ReactComponent as LockIcon } from "./lock.svg";

export default function CheckoutForm(props: {
  clientSecret: string;
  // Items is an array of length 1
  creatorSeats: {
    price: string;
    quantity: number;
  };
  standardSeats: {
    price: string;
    quantity: number;
  };
  planName: string;
  finalPrice: string;
  seatCount: number;
  paymentError?: string;
  promoCode?: string;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(
    props.paymentError ?? null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url:
          window.location.origin +
          CHECKOUT_COMPLETE_URL +
          `?creator_price=${props.creatorSeats.price}&creator_quantity=${props.creatorSeats.quantity}&standard_price=${props.standardSeats.price}&standard_quantity=${props.standardSeats.quantity}&plan_name=${props.planName}&promo_code=${props.promoCode}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error?.message ?? "Error in card");
    } else {
      setMessage("An unexpected error occurred.");
    }

    if (error) {
      setIsLoading(false);
      // Helps us debug stripe errors
      logger.warn(
        `Stripe error: ${error.type} ${error.code} ${error.doc_url} ${error.message}`,
      );
      return;
    }
    setIsLoading(false);
  };
  const checkoutDisabled =
    isLoading || !stripe || !elements || props.seatCount === 0;

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <PaymentElement id="payment-element" />
        <SbText style={{ color: "#6d6e78", fontSize: 12 }}>
          By clicking Upgrade and Pay you also agree to our{" "}
          <Typography.Link href={TERMS_URL} target="_blank" aria-label="Terms">
            Terms
          </Typography.Link>{" "}
          and{" "}
          <Typography.Link
            href={MSA_URL}
            target="_blank"
            aria-label="Master Services Agreemeent"
          >
            Master Services Agreement
          </Typography.Link>
          .
        </SbText>
        <button
          disabled={checkoutDisabled}
          id="submit"
          style={{
            background: checkoutDisabled
              ? colors.GREY_200
              : colors.ACCENT_BLUE_500,
            color: "white",
            border: "none",
            borderRadius: 4,
            fontSize: 14,
            fontWeight: 500,
            padding: "6px 20px",
            cursor: "pointer",
          }}
        >
          <span id="button-text">
            <Space size={6}>
              {isLoading ? (
                <Spinner color="white" />
              ) : (
                <LockIcon style={{ position: "relative", top: 4 }} />
              )}
              {`Upgrade and Pay ${props.finalPrice}`}
            </Space>
          </span>
        </button>
        {message && <Alert showIcon type="error" message={message} />}
      </Space>
    </form>
  );
}
