import { Tabs, Tooltip } from "antd";
import React, { useCallback } from "react";

import { useNavigate, useParams } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper } from "components/ui/Page";
import { VIEW_ROLES } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import {
  PageWrapper,
  ROLES_AND_PERMISSIONS_TITLE,
} from "pages/components/PageWrapper";
import { ROLES_AND_PERMISSIONS_URL } from "pages/routes";
import { OrganizationRoles } from "./OrganizationRoles";
import { ResourceRoles } from "./ResourceRoles";
import { RoleSettings } from "./RoleSettings";
import { isValidRolesPageType, RoleAndPermissionsPages } from "./constants";

const RolesAndPermissions = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pageType = isValidRolesPageType(params.pageType)
    ? params.pageType
    : RoleAndPermissionsPages.ORGANIZATION_ROLES;
  const handleTabChange = useCallback(
    (key: string) => {
      navigate({ pathname: ROLES_AND_PERMISSIONS_URL(key) });
    },
    [navigate],
  );

  const [canReadRoles] = useAuthorizationCheck([VIEW_ROLES]);

  return (
    <PageWrapper pageName={ROLES_AND_PERMISSIONS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title">
              {" "}
              {ROLES_AND_PERMISSIONS_TITLE}{" "}
            </div>
            <div className="page-header-description">
              Create and manage roles. Roles contain collections of permissions
              and can be assigned to users and groups.{" "}
              <a
                href="https://docs.superblocks.com/account-management-and-permissions/role-based-access-control"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <Tabs activeKey={pageType} onTabClick={handleTabChange}>
            <Tabs.TabPane
              tab={
                <div>
                  <Tooltip title="Assign roles to individuals and groups giving them different sets of permissions in the organization.">
                    <div>Organization Roles</div>
                  </Tooltip>
                </div>
              }
              key={RoleAndPermissionsPages.ORGANIZATION_ROLES}
            >
              <OrganizationRoles />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <Tooltip title="Customize access to Apps, Integrations, Jobs, and more by assigning granular roles.">
                    <div>Resource Roles</div>
                  </Tooltip>
                </div>
              }
              key={RoleAndPermissionsPages.RESOURCE_ROLES}
            >
              <ResourceRoles />
            </Tabs.TabPane>
            {canReadRoles && (
              <Tabs.TabPane
                tab="Settings"
                key={RoleAndPermissionsPages.SETTINGS}
              >
                <RoleSettings />
              </Tabs.TabPane>
            )}
          </Tabs>
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default RolesAndPermissions;
