import { createContext } from "react";
import { AutocompleteConfiguration } from "../CodeEditor/EditorConfig";

const stub = () => {
  throw new Error("You forgot to wrap your form in a provider");
};

export type Unsubscribe = () => void;

export type FormContextType = {
  autocompleteConfiguration: AutocompleteConfiguration;
  autocompleteAdditionalData?: Record<string, Record<string, unknown>>;
  subscribe: <T = unknown>(
    path: string,
    callback: (value: T) => void,
  ) => Unsubscribe;
  onChange: (
    path: string,
    newData: unknown,
    options?: {
      debounced?: boolean;
      isNested?: boolean;
    },
  ) => void;
  registerValidation: (
    path: string,
    validationFunction: (value: unknown) => boolean,
  ) => void;
  unregisterValidation: (path: string) => void;
  // we need to be able to get a value of an arbitrary path(e.g. dependency)
  getValue(path: string): unknown;
};

export const FormContext = createContext<FormContextType>({
  autocompleteConfiguration: {},
  subscribe: stub,
  onChange: stub,
  registerValidation: stub,
  unregisterValidation: stub,
  getValue: stub,
});
FormContext.displayName = "FormContext";
