import {
  NonCustomTypography,
  TextStyleBlock,
  Typographies,
} from "@superblocksteam/shared";

type PropertiesByTypography = Record<string, Array<keyof TextStyleBlock>>;

export const USER_SELECTABLE_VARIANTS: Array<NonCustomTypography> = [
  "heading1",
  "heading2",
  "heading3",
  "heading4",
  "heading5",
  "heading6",
  "body1",
  "body2",
  "body3",
  "label",
  "inputLabel",
  "inputText",
];

export const TYPOGRAPHY_KEYS: Array<keyof Omit<Typographies, "custom">> = [
  "heading1",
  "heading2",
  "heading3",
  "heading4",
  "heading5",
  "heading6",
  "body1",
  "body2",
  "body3",
  "label",
  "buttonLabel",
  "inputLabel",
  "inputText",
  "inputPlaceholder",
  "link",
  "code",
];

export const DEFAULT_INPUT_TEXT_STYLE_VARIANT: keyof Typographies = "inputText";
export const DEFAULT_HEADER_TEXT_STYLE_VARIANT: keyof Typographies = "heading3";
export const SB_CUSTOM_TEXT_STYLE = "SB_CUSTOM_TEXT_STYLE";

export const DISABLED_PROPERTIES_BY_TYPOGRAHY: PropertiesByTypography = {
  code: ["textColor"],
};

export const LOCKED_PROPERTIES_BY_TYPOGRAHY: PropertiesByTypography = {
  link: [
    "fontSize",
    "lineHeight",
    "fontFamily",
    "letterSpacing",
    "textTransform",
  ],
  inputPlaceholder: [
    "fontSize",
    "lineHeight",
    "fontFamily",
    "fontStyle",
    "letterSpacing",
    "textTransform",
  ],
  buttonLabel: ["textColor"],
};

export const LOCKED_TOOLTIP_BY_TYPOGRAPHY: Record<string, string> = {
  link: "Inherited from the text in which the link is included",
  inputPlaceholder:
    "Inherited from the input text where the placeholder is shown",
  buttonLabel: "Inherited based on the button variant",
};

export const DEFAULT_TYPOGRAPHIES_LEGACY: Typographies = {
  heading1: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "36px",
    fontWeight: 500,
    letterSpacing: "-0.01em",
    lineHeight: 1.2,
  },
  heading2: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "28px",
    fontWeight: 500,
    letterSpacing: "-0.01em",
    lineHeight: 1.2,
  },
  heading3: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "22px",
    fontWeight: 500,
    letterSpacing: "normal",
    lineHeight: "26px",
  },
  heading4: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,
    letterSpacing: "normal",
  },
  heading5: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "16px",
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: "0em",
  },
  heading6: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral900",
    },
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "normal",
  },
  body1: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
    },
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0em",
  },
  body2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
    },
    letterSpacing: "normal",
  },
  body3: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
    },
    letterSpacing: "normal",
  },
  label: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
    },
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "normal",
  },
  inputLabel: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
      disabled: "colors.neutral300",
      error: "colors.danger",
    },
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  buttonLabel: {
    fontFamily: "inherit",
    textColor: {
      default: "primary500",
    },
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "normal",
  },
  inputText: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.neutral700",
    },
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: "normal",
  },
  inputPlaceholder: {
    fontWeight: 400,
    textColor: {
      default: "colors.neutral300",
    },
    // everything else is inherited
    fontFamily: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    fontStyle: "inherit",
    textTransform: "inherit",
    textDecoration: "inherit",
  },
  link: {
    fontFamily: "inherit",
    textColor: {
      default: "colors.primary500",
    },
    fontSize: "inherit",
    fontWeight: 400,
    lineHeight: "inherit",
    textDecoration: "none",
    letterSpacing: "inherit",
    textTransform: "inherit",
  },
  code: {
    fontFamily: "Roboto Mono",
    textColor: {
      default: "colors.neutral700",
    },
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "normal",
  },
};

export const DEFAULT_TYPOGRAPHIES_NEW: Typographies = {
  ...DEFAULT_TYPOGRAPHIES_LEGACY,
  heading3: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.heading3,
    fontSize: "24px",
    letterSpacing: "-0.01em",
    lineHeight: 1.2,
  },
  heading4: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.heading4,
    fontSize: "20px",
    lineHeight: 1.2,
    letterSpacing: "-0.01em",
  },
  heading6: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.heading6,
    lineHeight: 1.2,
  },
  body2: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.body2,
    lineHeight: 1.5,
  },
  body3: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.body3,
    lineHeight: 1.5,
  },
  label: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.label,
    lineHeight: 1.5,
    fontSize: "14px",
  },
  inputLabel: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.inputLabel,
    lineHeight: 1.5,
    fontSize: "14px",
  },
  buttonLabel: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.buttonLabel,
    lineHeight: 1.1,
  },
  code: {
    ...DEFAULT_TYPOGRAPHIES_LEGACY.code,
    lineHeight: 1.2,
  },
};

export const DEFAULT_TYPOGRAPHIES_BY_VERSION: Record<number, Typographies> = {
  1: DEFAULT_TYPOGRAPHIES_LEGACY,
  2: DEFAULT_TYPOGRAPHIES_LEGACY,
  3: DEFAULT_TYPOGRAPHIES_LEGACY,
  4: DEFAULT_TYPOGRAPHIES_LEGACY,
  5: DEFAULT_TYPOGRAPHIES_NEW,
};
