import equal from "@superblocksteam/fast-deep-equal/es6";
import { call, delay, getContext, put, takeLatest } from "redux-saga/effects";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { EditorReduxState } from "legacy/reducers/uiReducers/editorReducer";
import {
  GitState,
  LocalDevServerState,
  LocalDevServerStatus,
} from "legacy/utils/LocalDevServerState";
import { concatenateQueryParams } from "legacy/utils/Utils";

const localServerTimeoutMs = 8_000;

const timeBetweenPingsMs = 30_000;

interface HealthResponse {
  status: "up" | "down";
  git: GitState;
}

async function pingLocalDevServer(): Promise<LocalDevServerState> {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), localServerTimeoutMs);
  try {
    const resp = await fetch("http://localhost:3002/health", {
      signal: abortController.signal,
    });
    if (resp.ok) {
      const respBody: HealthResponse = await resp.json();
      if (respBody.status === "up") {
        return { status: LocalDevServerStatus.CONNECTED, git: respBody.git };
      }
    }
  } catch {
    // ignore errors, let the fallback handle it
  }
  return { status: LocalDevServerStatus.NOT_CONNECTED };
}

function* localDevModeChanged(
  action: ReduxAction<EditorReduxState["localDevModeEnabled"]>,
): Generator<any, any, any> {
  const localDevMode = action.payload;
  if (!localDevMode) {
    // local dev mode is disabled, so we don't need to ping the server periodically
    yield put({
      type: ReduxActionTypes.SET_LOCAL_DEV_SERVER_STATUS,
      payload: LocalDevServerStatus.UNKNOWN,
    });
    return;
  } else {
    // make sure query param is always set correctly when local dev mode is enabled
    const navigate = yield getContext("navigate");
    navigate({
      search: concatenateQueryParams(window.location.search, "devMode=true"),
    });
  }
  let lastState: LocalDevServerState | undefined;
  while (true) {
    const curState: LocalDevServerState = yield call(pingLocalDevServer);
    if (!equal(lastState, curState)) {
      yield put({
        type: ReduxActionTypes.SET_LOCAL_DEV_SERVER_STATUS,
        payload: curState,
      });
      lastState = curState;
    }
    yield delay(timeBetweenPingsMs);
  }
}

export default function* localDevServerStatusSaga() {
  yield takeLatest(ReduxActionTypes.SET_LOCAL_DEV_MODE, localDevModeChanged);
}
