import { OrganizationUserDto, UserType } from "@superblocksteam/shared";
import { reduxApiSlice } from "./index";

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listUsers: builder.query<
      OrganizationUserDto[],
      { organizationId: string; type?: UserType }
    >({
      query: ({ organizationId, type }) =>
        `v1/organizations/${organizationId}/users${
          type ? `?${new URLSearchParams({ type }).toString()}` : ""
        }`,
      transformResponse: (response: { data: OrganizationUserDto[] }) =>
        response.data,
    }),
  }),
  overrideExisting: false,
});

export const { useListUsersQuery, useLazyListUsersQuery } = extendedApi;
