import { createSelector } from "reselect";
import { ROOT } from "store/utils/types";
import slice from "./slice";

export const selectDeployments = createSelector(slice.selector, (state) =>
  Object.values(state.entities),
);

export const selectLoadingState = createSelector(
  slice.selector,
  (state) => state.loading[ROOT] as boolean | undefined,
);
