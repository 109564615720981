import { CheckCircleFilled } from "@ant-design/icons";
import { Divider, List, Popover, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled, { useTheme } from "styled-components";
import { ReactComponent as Circle } from "assets/icons/common/circle.svg";
import FooterImage from "assets/images/checklist-footer.png";
import { useMarkTaskRead } from "hooks/ui/useCheckTask";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";
import { DocsPage, DOCS_PAGE_URL } from "legacy/constants/routes";
import {
  selectLastCompletedTask,
  selectVisibleChecklist,
} from "store/slices/user";
import ChecklistButton from "./ChecklistButton";
import ChecklistMiniStatus from "./ChecklistMiniStatus";
import { ChecklistItemTooltip } from "./ChecklistTooltip";

const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 404px;
`;

const BaseItemWrap = styled.div``;

const ListItemWrapper = styled(List.Item)<{ $complete?: boolean }>`
  margin-left: -16px;
  padding-left: 16px;
  margin-right: -16px;
  padding-right: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.HOVER_GREEN};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.CLICK_GREEN};
  }

  h4 {
    text-decoration: ${({ $complete }) => $complete === true && "line-through"};
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  margin-left: 10px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: -12px -16px;
  padding: 12px 16px;
  border-radius: 0 0 4px 4px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.HOVER_GREEN};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.CLICK_GREEN};
  }
`;

const FooterImageWrapper = styled.div`
  display: flex;
  width: 200px;
  margin-right: -16px;
  margin-bottom: -12px;
  img {
    width: 200px;
    border-radius: 4px;
  }
`;

const TextWrapper = styled.div`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

const SubTextWrapper = styled.div`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  a {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const Checkmark = (props: { enabled: Date | undefined }) => {
  const theme = useTheme();
  return (
    <IconWrapper
      width={20}
      height={20}
      color={props.enabled ? theme.colors.ACCENT_GREEN : undefined}
    >
      {props.enabled && <CheckCircleFilled />}
      {!props.enabled && <Circle />}
    </IconWrapper>
  );
};

const Icon = (item: {
  icon: (props: IconProps) => JSX.Element;
  completed: Date | undefined;
}) => {
  const theme = useTheme();
  return (
    <item.icon
      background={item.completed ? theme.colors.SUBTLE_GREEN : "#F9FAFB"}
      color={item.completed ? theme.colors.ACCENT_GREEN : undefined}
      padding={10}
      width={42}
      height={42}
      rounded={true}
    />
  );
};

const Footer = () => (
  <Typography.Link
    href={DOCS_PAGE_URL(DocsPage.FIVE_MIN_GUIDE_URL)}
    target="_blank"
    aria-label="Docs"
  >
    <FooterWrapper>
      <LeftWrapper>
        <TextWrapper>
          <span>Not sure where to Start?</span>
        </TextWrapper>
        <SubTextWrapper>Try the Quickstart Guide</SubTextWrapper>
      </LeftWrapper>
      <FooterImageWrapper>
        <img src={FooterImage} alt="Checklist footer"></img>
      </FooterImageWrapper>
    </FooterWrapper>
  </Typography.Link>
);

interface Props {
  hide: () => void;
}

const Checklist = (props: Props) => {
  const items = useSelector(selectVisibleChecklist);
  const navigate = useNavigate();
  return (
    <ChecklistWrapper>
      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => {
          const Wrap = item.Link ?? BaseItemWrap;
          return (
            <Wrap>
              <ListItemWrapper
                $complete={!!item.completed}
                onClick={() => {
                  props.hide();
                  if (item.url) {
                    navigate(item.url);
                  }
                }}
                data-test={`checklist-item-${item.id}`}
              >
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  title={item.title}
                  description={item.description}
                  avatar={Icon(item)}
                ></List.Item.Meta>
                <Checkmark enabled={item.completed} />
              </ListItemWrapper>
            </Wrap>
          );
        }}
      />
      <Divider />
      <Footer />
    </ChecklistWrapper>
  );
};

const PopoverChildWrapper = styled.div`
  margin: 0px 0;
`;

const ChecklistStatus = ({ type = "button" }: { type: "button" | "card" }) => {
  const lastTaskCompleted = useSelector(selectLastCompletedTask);
  const [markTaskRead] = useMarkTaskRead(lastTaskCompleted?.id);

  const [listVisible, setVisible] = useState(false);
  const hide = useCallback(() => {
    setVisible(false);
    markTaskRead();
  }, [markTaskRead]);

  const handleVisibleChange = useCallback(
    (newVisible: boolean) => {
      setVisible(newVisible);
      if (newVisible) {
        markTaskRead();
      }
    },
    [markTaskRead],
  );

  const content = useMemo(() => <Checklist hide={hide} />, [hide]);
  return (
    <ChecklistItemTooltip
      onClick={handleVisibleChange.bind(this, true)}
      open={listVisible ? false : undefined}
      placement={type === "card" ? "rightTop" : "bottomLeft"}
    >
      <Popover
        trigger="click"
        content={content}
        placement={type === "card" ? "rightTop" : "bottomLeft"}
        open={listVisible}
        onOpenChange={handleVisibleChange}
      >
        <PopoverChildWrapper>
          {type === "card" ? <ChecklistMiniStatus /> : <ChecklistButton />}
        </PopoverChildWrapper>
      </Popover>
    </ChecklistItemTooltip>
  );
};

export default ChecklistStatus;
