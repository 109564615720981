import { Modal } from "antd";
import React from "react";
import { PrimaryButton } from "components/ui/Button";
import { SecretInput } from "components/ui/SecretInput";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const ModalInnerWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const MainSection = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FooterWrapper = styleAsClass`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`;

const TokenGeneratedModal = ({
  modalOpen,
  token,
  onDone,
  isNew,
}: {
  modalOpen: boolean;
  token: string;
  onDone: () => void;
  isNew: boolean;
}) => {
  return (
    <Modal
      open={modalOpen}
      footer={null}
      title={isNew ? "Access token created" : "Your token has been regenerated"}
      destroyOnClose={true}
      onCancel={onDone}
    >
      <div className={ModalInnerWrapper}>
        <div className={MainSection} style={{ gap: 10 }}>
          <div style={{ color: colors.GREY_500 }}>
            This token will only be shown once. Copy it now and store it in a
            safe secure location.
          </div>
          <div>
            <SecretInput token={token} disableHide={true} />
          </div>
          <div className={FooterWrapper}>
            <PrimaryButton
              type="primary"
              onClick={onDone}
              data-test="token-generated-done"
            >
              Done
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TokenGeneratedModal;
