import { EditorLanguage, ExtendedIntegrationPluginId, FormComponentType, Plugin, PluginResponseType, PluginType } from '../../types';
import { authSections } from './shared';
export const SalesforcePluginVersions = {
  V1: '0.0.1'
};

const ACTION_DROPDOWN: { label: string; value: string; children: { label: string; value: string }[] }[] = [
  {
    label: 'Query with SOQL',
    value: 'soql',
    children: [{ label: 'SOQL', value: '1' }]
  },
  {
    label: 'Single object actions',
    value: 'crud',
    children: [
      { label: 'Create a single object', value: '1' },
      { label: 'Update a single object', value: '2' },
      { label: 'Delete a single object', value: '3' },
      { label: 'Read a single object', value: '4' }
    ]
  },
  {
    label: 'Bulk actions',
    value: 'bulk',
    children: [
      { label: 'Create several objects', value: '1' },
      { label: 'Update several objects', value: '2' },
      { label: 'Delete several objects', value: '3' },
      { label: 'Upsert (update or insert) several objects', value: '4' }
    ]
  }
];

export const SalesforcePlugin: Plugin = {
  id: 'salesforce',
  name: 'Salesforce',
  moduleName: 'SalesforcePlugin',
  modulePath: 'plugins/salesforce/SalesforcePlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/salesforce.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/salesforce',
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  hasMetadata: true,
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Instance URL',
            name: 'connection.instanceUrl',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'https://DOMAIN.my.salesforce.com',
            rules: [{ required: true, message: 'Instance URL is required' }]
          },
          {
            // it could be removed if we provide a way to pass token to plugin.metadata and plugin.test
            // plugin.execute have access to execution context, which already contain token
            label: 'Token',
            name: 'authConfig.authToken',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            initialValue: '{{oauth.token}}',
            hidden: true
          }
        ]
      },
      ...authSections({
        startVersion: SalesforcePluginVersions.V1,
        pluginId: ExtendedIntegrationPluginId.SALESFORCE,
        pluginName: 'Salesforce',
        defaultMethod: 'clientCredentialsFlow',
        enabledMethods: {
          clientCredentialsFlow: true
        },
        allowNone: false,
        fieldNamesToHide: [],
        isProto: true
      })
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'salesforceAction.value.action', // expects .value.action that is a enum
            secondaryName: 'salesforceAction.case', // expects proto top level oneof case
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.ONEOF_ACTION_DROPDOWN,
            actionOptions: ACTION_DROPDOWN,
            initialValue: '1',
            secondaryInitialValue: 'soql'
          },
          {
            label: '', // Query
            name: 'salesforceAction.value.sqlBody',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: '',
            rules: [{ required: true, message: 'SOQL Query is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['1'],
                'salesforceAction.case': ['soql']
              }
            }
          },
          {
            label: 'Resource type',
            name: 'salesforceAction.value.resourceType',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            rules: [{ required: true, message: 'Resource type is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['1', '2', '3', '4'],
                'salesforceAction.case': ['crud']
              }
            }
          },
          {
            label: 'Resource ID',
            name: 'salesforceAction.value.resourceId',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'Resource ID is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['2', '3', '4'],
                'salesforceAction.case': ['crud']
              }
            }
          },
          {
            label: 'Resource body',
            name: 'salesforceAction.value.resourceBody',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            rules: [{ required: true, message: 'Resource body is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['1', '2'],
                'salesforceAction.case': ['crud']
              }
            },
            placeholder: '{"Name": "Example Company Name", "Industry": "Health Care", "Website": "www.example.com"}'
          },
          {
            label: 'Resource type',
            name: 'salesforceAction.value.resourceType',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'Resource Type is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['1', '2', '3', '4'],
                'salesforceAction.case': ['bulk']
              }
            }
          },
          {
            label: 'External ID (optional)',
            name: 'salesforceAction.value.externalId',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            tooltip: {
              markdownText:
                'External ID is a field that uniquely identifies records outside of Salesforce. The upsert command will use this field to determine if a record should be created or updated'
            },
            placeholder: 'id',
            display: {
              show: {
                'salesforceAction.value.action': ['4'], // upsert
                'salesforceAction.case': ['bulk']
              }
            }
          },
          {
            label: 'Bulk body',
            name: 'salesforceAction.value.resourceBody',
            startVersion: SalesforcePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            rules: [{ required: true, message: 'Bulk body is required' }],
            display: {
              show: {
                'salesforceAction.value.action': ['1', '2', '3', '4'],
                'salesforceAction.case': ['bulk']
              }
            },
            placeholder:
              '[{"Name": "Example Company Name1", "Website": "www.example.com"}, {"Name": "Example Company Name2", "Website": "www.example2.com"}]'
          }
        ]
      }
    ]
  }
};
