import { ApplicationScope } from "@superblocksteam/shared";
import { has } from "lodash";
import { resolveScopes } from "utils/dataTree/resolveScopes";
import type {
  DataTree,
  EntityLocation,
  ScopedDataTree,
  ScopedDataTreePath,
} from "./dataTreeFactory";

export const emptyDataTree: () => DataTree = () => ({
  [ApplicationScope.PAGE]: {},
  [ApplicationScope.APP]: {},
  [ApplicationScope.GLOBAL]: {},
});

export const isEmptyDataTree = (dataTree: DataTree) =>
  Object.keys(dataTree[ApplicationScope.PAGE]).length === 0 &&
  Object.keys(dataTree[ApplicationScope.APP]).length === 0 &&
  Object.keys(dataTree[ApplicationScope.GLOBAL]).length === 0;

export const scopedDataTrees = (
  scope: DataTree,
): Array<[ApplicationScope, ScopedDataTree]> => [
  [ApplicationScope.PAGE, scope[ApplicationScope.PAGE]],
  [ApplicationScope.APP, scope[ApplicationScope.APP]],
  [ApplicationScope.GLOBAL, scope[ApplicationScope.GLOBAL]],
];

export const getEntityCount = (dataTree: DataTree) =>
  scopedDataTrees(dataTree).reduce(
    (acc, [, entities]) => acc + Object.keys(entities).length,
    0,
  );

// returns a list of all entities in the data tree with their EntityLocation and Entity
export const getDataEntities = (dataTree: DataTree) => {
  return scopedDataTrees(dataTree).flatMap(([scope, entities]) =>
    Object.entries(entities).map(([name, entity]) => ({
      location: { name, scope } as EntityLocation,
      entity,
    })),
  );
};

export const getEntity = (location: EntityLocation, dataTree: DataTree) => {
  return dataTree[location.scope][location.name];
};

/**
 * Converts a local binding to a data tree path
 * @param localBinding - the local binding to convert, e.g. `Var1.name`, `App.Var1.name`
 * @param scope - the scope of the local binding
 * @param dataTree - the data tree to search for the binding
 * @returns the data tree path of the local binding, e.g. `PAGE.Var1.name`, `APP.Var1.name`, or undefined if not found
 */
export const resolveDataTreePath = (
  localBinding: string,
  scope: ApplicationScope,
  dataTree: DataTree,
): ScopedDataTreePath | undefined => {
  const scopes = resolveScopes(scope);
  for (const scope of scopes) {
    const prefix: string | undefined = scope.prefix;
    const shouldSearchScope = prefix ? localBinding.startsWith(prefix) : true;
    if (shouldSearchScope) {
      const localBindingWithoutPrefix = prefix
        ? localBinding.slice(prefix.length + 1)
        : localBinding;
      const path = `${scope.scope}.${localBindingWithoutPrefix}` as const;
      if (has(dataTree, path)) {
        return path;
      }
    }
  }
  console.warn(`Could not find data tree path for binding ${localBinding}`);
  return undefined;
};
