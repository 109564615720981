import { EditorLanguage, FormComponentType, Plugin, PluginResponseType, PluginType } from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION, SERVICE_ACCOUNT_GHOST_TEXT, SQL_INITIAL_TEXT } from './constants';

export const BigQueryPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2',
  V6: '0.0.6'
};

export const BigQueryPlugin: Plugin = {
  id: 'bigquery',
  name: 'Google BigQuery',
  moduleName: 'BigqueryPlugin',
  modulePath: 'plugins/bigquery/BigqueryPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/bigquery.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/google-bigquery',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: BigQueryPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'BigQuery Production',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Service account key',
            name: 'authentication.custom.googleServiceAccount.value',
            startVersion: BigQueryPluginVersions.V1,
            // INPUT_AREA is broken (cannot be udpated after unfocus). Using
            // code editor for now.
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            placeholder: SERVICE_ACCOUNT_GHOST_TEXT,
            rules: [{ required: true, message: 'Service account key is required' }]
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'body',
            startVersion: BigQueryPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'usePreparedSql',
            startVersion: BigQueryPluginVersions.V6,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
