import { DropdownOption, Profile } from "@superblocksteam/shared";
import { Alert } from "antd";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning.svg";
import { Markdown } from "components/ui/Markdown";
import { RecommendedMultiDropdown } from "components/ui/RecommendedMultiDropdown";
import { ConfigMeta } from "./utils";

const AlertWrapper = styled.div`
  margin-top: 4px;
  .ant-alert-warning {
    background-color: ${(props) => props.theme.colors.ACCENT_ORANGE}14;
    border-color: ${(props) => props.theme.colors.ACCENT_ORANGE}80;
  }
`;

const ProfilesMultiSelect = ({
  profiles,
  parentRef,
  configMeta,
  configMetas,
  selectedProfileIds,
  setSelectedProfileIds,
}: {
  profiles: Profile[];
  parentRef: React.RefObject<HTMLDivElement>;
  configMeta?: ConfigMeta;
  configMetas: ConfigMeta[];
  selectedProfileIds: string[];
  setSelectedProfileIds: (selectedProfileIds: string[]) => void;
}) => {
  const [alertMessage, setAlertMessage] = React.useState<string>();
  const checkProfilesInNewConfiguration = useCallback(
    (profileIds: string[]) => {
      // show alert if new configuration contains profiles that are already in another configuration
      // will remove the profiles from the other configuration
      const profilesInOtherConfiguration: string[] = [];
      const configSet = new Set<string>();
      configMetas.forEach((config) => {
        // do not count default config for alerting message
        if (configMeta?.id === config.id || config.isDefault) return;
        config.profileIds.forEach((profileId: string) => {
          if (profileIds.includes(profileId)) {
            profilesInOtherConfiguration.push(profileId);
            configSet.add(config.id);
          }
        });
      });
      if (profilesInOtherConfiguration.length > 0) {
        const profileNames = profilesInOtherConfiguration
          .map((profileId: string) => {
            return `**${
              profiles.find((profile) => profile.id === profileId)?.displayName
            }**`;
          })
          .join(", ");
        setAlertMessage(
          `${profileNames} ${
            profilesInOtherConfiguration.length > 1 ? "are" : "is"
          } already part of ${
            configSet.size > 1
              ? "other configurations"
              : "another configuration"
          }. We'll move it to this configuration and remove it from the other one${
            configSet.size > 1 ? "s" : ""
          }.`,
        );
      } else {
        setAlertMessage(undefined);
      }
    },
    [configMeta?.id, configMetas, profiles],
  );

  const allProfilesOptions: DropdownOption[] = useMemo(
    () =>
      profiles
        .map((profile) => ({
          displayName: profile.displayName,
          value: profile.id,
          key: profile.key,
        }))
        .sort((a: DropdownOption, b: DropdownOption) =>
          a.key.localeCompare(b.key),
        ),
    [profiles],
  );

  const onItemsChange = (selectedItems: DropdownOption[]) => {
    const newSelectedProfileIds = selectedItems.map((item) => item.value);
    setSelectedProfileIds(newSelectedProfileIds);
    checkProfilesInNewConfiguration(newSelectedProfileIds);
  };

  const selectedItems = useMemo(() => {
    const items: DropdownOption[] = [];
    allProfilesOptions.forEach((option, index) => {
      if (selectedProfileIds?.includes(option.value)) {
        items.push(option);
      }
    });
    return items;
  }, [allProfilesOptions, selectedProfileIds]);

  return (
    <div>
      <RecommendedMultiDropdown
        dataTest={`dropdown-profiles`}
        onChange={onItemsChange}
        placeholder={"Select profiles"}
        options={allProfilesOptions}
        selectedItems={selectedItems}
        parentRef={parentRef}
        popoverProps={{
          hasBackdrop: true,
        }}
      />
      {alertMessage && (
        <AlertWrapper data-test="select-profiles-alert">
          <Alert
            message={<Markdown linkTarget="_blank">{alertMessage}</Markdown>}
            type="warning"
            showIcon
            icon={<WarningIcon />}
          />
        </AlertWrapper>
      )}
    </div>
  );
};

export default ProfilesMultiSelect;
