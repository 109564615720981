import {
  RestApiFields,
  SUPERBLOCKS_OPENAPI_TENANT_KEYWORD,
} from "@superblocksteam/shared";
import { Typography } from "antd";
import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import CodeEditorWithAiAssistant from "components/app/CodeEditor/CodeEditorWithAiAssistant";
import { EditorSize } from "components/app/CodeEditor/EditorConfig";
import { EditorProps } from "components/app/CodeEditor/types";
import { useElementRect } from "hooks/ui";
import { colors } from "styles/colors";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormSubheading } from "../FormSubheading";
import { ruleParser } from "../utils";

type UrlInputTextProps = {
  wrapperRef?: React.RefObject<HTMLElement>;
  integrationId?: string;
  isLoading?: boolean;
} & DynamicFormItemProps &
  EditorProps["input"] &
  Omit<
    EditorProps,
    "autocompleteConfiguration" | "autocompleteAdditionalData" | "input"
  >;

// Ant design uses 24px margins in forms, plus 2px for the borders
const DEFAULT_PADDING = 26;

const UrlInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  min-height: 32px;
  .EditorWrapper {
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
    min-height: 32px;
  }
  .CodeMirror-wrap {
    padding-left: 0px !important;
    line-height: 21px !important;
  }

  .CodeMirror-sizer {
    padding-bottom: 0px !important;
  }

  .CodeMirror-hscrollbar,
  .CodeMirror-vscrollbar,
  .CodeMirror-scrollbar-filler {
    display: none !important;
  }

  // TODO: make url input height to one line if not focused.
  /* .CodeMirror-sizer {
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  } */

  /* .CodeMirror-wrap:not(.CodeMirror-focused) {
    max-height: 32px;
    .CodeMirror-scroll {
      overflow: hidden !important;
    }
    .cm-m-null {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      word-wrap: normal;
    }
  } */
`;

const BaseUrlWrapper = styled.div`
  border: 1px solid ${colors.GREY_100};
  border-right: 0px;
  border-radius: 4px 0px 0px 4px;
  align-items: flex-start;
  display: flex;
  padding-left: 10px;
  padding-right: 0px;
  padding-top: 5.4px;
  font-family: var(--font-monospace);
  font-size: 12px;
  font-weight: normal;
  color: ${colors.GREY_500};
`;

const PathWrapper = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const UrlInputText = ({
  label,
  path,
  onBlur,
  onDragStart,
  onDrop,
  onFocus,
  name,
  rules,
  subHeading,
  showShortcutMenu = false,
  wrapperRef,
  integrationId,
  isLoading,
  ...otherProps
}: UrlInputTextProps) => {
  const context = useContext(FormContext);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) =>
      setValue(value as string),
    );
    return () => unsubscribe();
  }, [path, context]);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) =>
        setValidationMessage(value),
      );
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [path, rules, context]);
  const onChange = useCallback(
    (value: any) => {
      context.onChange(path, value);
    },
    [context, path],
  );

  const wrapperRect = useElementRect(wrapperRef?.current ?? null);

  const heightProps = useMemo(() => {
    if (wrapperRect) {
      return {
        height: `${wrapperRect.height - DEFAULT_PADDING}px`,
        minHeight: `${wrapperRect.height - DEFAULT_PADDING}px`,
        maxHeight: `${wrapperRect.height - DEFAULT_PADDING}px`,
      };
    }
    return {};
  }, [wrapperRect]);

  const input = useMemo(
    () => ({ name, value, onChange, onBlur, onDragStart, onDrop, onFocus }),
    [name, value, onChange, onBlur, onDragStart, onDrop, onFocus],
  );

  let baseUrl = context.getValue(RestApiFields.URL_BASE);
  const openApiTenantName = context.getValue(RestApiFields.OPENAPI_TENANT_NAME);
  if ((baseUrl as string)?.includes(SUPERBLOCKS_OPENAPI_TENANT_KEYWORD)) {
    baseUrl = (baseUrl as string)?.replace(
      SUPERBLOCKS_OPENAPI_TENANT_KEYWORD,
      openApiTenantName as string,
    );
  }

  // placeholder is /v1/users, remove the leading slash if baseUrl ends with a slash
  const placeholder =
    (baseUrl as string)?.endsWith("/") &&
    otherProps.placeholder?.startsWith("/")
      ? otherProps.placeholder?.substring(1)
      : otherProps.placeholder;

  return (
    <UrlInputWrapper>
      <BaseUrlWrapper>{baseUrl as string}</BaseUrlWrapper>
      <PathWrapper className="url-path-wrapper">
        <CodeEditorWithAiAssistant
          input={input}
          showShortcutMenu={showShortcutMenu}
          monospace={true}
          autocompleteConfiguration={context.autocompleteConfiguration}
          additionalDynamicData={context.autocompleteAdditionalData}
          isInPropertyPanel={false}
          datasourceId={integrationId}
          {...heightProps}
          {...otherProps}
          placeholder={placeholder}
          noNewlines={true}
          size={EditorSize.EXTENDED}
          enableSearch={false}
          autoFocus={false}
        />
        <Typography.Text type="danger">{validationMessage}</Typography.Text>
        {subHeading && <FormSubheading text={subHeading} />}
        <div style={{ color: colors.GREY_400 }}>
          <StyledReactMarkdown linkTarget="_blank">
            {otherProps.subtitle ?? ""}
          </StyledReactMarkdown>
        </div>
      </PathWrapper>
    </UrlInputWrapper>
  );
};
