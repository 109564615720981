import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as SuccessBadge } from "assets/icons/common/success-badge.svg";
import { closeQualificationModal } from "legacy/actions/modalActions";
import { CONTACT_SALES_URL } from "legacy/constants/routes";
import { getModalState } from "legacy/selectors/modalSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { useAppDispatch } from "store/helpers";
import { callServer } from "store/utils/client";
import logger from "utils/logger";

const { Option } = Select;

const SuccessModalContent = styled.div`
  padding: 16px;

  .success-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  h5 {
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    margin: 0;
    padding-left: 16px;
  }

  span.ant-typography {
    display: block;
    font-size: 15px;
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const ModalContent = styled.div`
  form .ant-form-item-label label {
    font-size: 15px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.GREY_800};
  }

  form#qualification-form label::before {
    display: none;
  }

  .description .ant-typography {
    font-size: 15px;
    color: ${({ theme }) => theme.colors.GREY_500};
    margin-bottom: 24px;
  }

  .extend-trial-note {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.GREY_500};

    span {
      color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .ant-checkbox-wrapper {
    margin-bottom: 6px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.GREY_800};
  }
`;

const QualificationModal = () => {
  const [qualificationForm] = Form.useForm();
  const [showTrialExtensionForm, setShowTrialExtensionForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const user = useSelector(getCurrentUser);
  const modalState = useSelector(getModalState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modalState.showQualificationModal === "TRIAL") {
      setShowTrialExtensionForm(true);
    }
  }, [modalState.showQualificationModal]);

  const handleFormSubmit = useCallback(() => {
    setLoading(true);

    qualificationForm
      .validateFields()
      .then(async (values) => {
        try {
          await callServer({
            method: "POST",
            url: CONTACT_SALES_URL,
            query: showTrialExtensionForm ? { extend: "true" } : undefined,
            body: values,
          });
          setSubmitted(true);
          setShowTrialExtensionForm(false);
        } catch (err) {
          logger.debug("Submission Failed:", err as any);
        }

        dispatch(closeQualificationModal());
        setLoading(false);
        qualificationForm.resetFields();
      })
      .catch((info) => {
        logger.debug("Validate Failed:", info);
        setLoading(false);
      });
  }, [qualificationForm, showTrialExtensionForm, dispatch]);

  const onCancel = useCallback(() => {
    qualificationForm.resetFields();
    dispatch(closeQualificationModal());
    setShowTrialExtensionForm(false);
  }, [qualificationForm, dispatch]);

  const numCreatorsOptions = useMemo(
    () => [
      { label: "Just me", value: "1" },
      { label: "2-3", value: "2-3" },
      { label: "4-6", value: "4-6" },
      { label: "7-10", value: "7-10" },
      { label: "11-15", value: "11-15" },
      { label: "16-20", value: "16-20" },
      { label: "21-50", value: "21-50" },
      { label: "50+", value: "50+" },
    ],
    [],
  );

  const numViewersOptions = useMemo(
    () => [
      { label: "Just me", value: "1" },
      { label: "2-5", value: "2-5" },
      { label: "6-10", value: "6-10" },
      { label: "11-20", value: "11-20" },
      { label: "21-50", value: "21-50" },
      { label: "51-100", value: "51-100" },
      { label: "101-250", value: "101-250" },
      { label: "250+", value: "250+" },
    ],
    [],
  );

  return (
    <>
      <Modal
        open={submitted && !loading}
        onCancel={() => {
          setSubmitted(false);
        }}
        onOk={() => {
          setSubmitted(false);
        }}
        destroyOnClose
        centered
        width={432}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setSubmitted(false);
            }}
          >
            Got it, thanks
          </Button>,
        ]}
      >
        <SuccessModalContent>
          <div className="success-title">
            <SuccessBadge />
            <Typography.Title level={5}>
              We’ve recieved your request
            </Typography.Title>
          </div>
          <Typography.Text>
            {showTrialExtensionForm
              ? "A member of our team will reach out to discuss your trial extension request within 24 hours over email "
              : "A member of our Sales team will reach out to discuss the best plan for you within 24 hours over email "}
            <a href={`mailto:${user?.email}`}>({user?.email})</a>
          </Typography.Text>
        </SuccessModalContent>
      </Modal>
      <Modal
        title={
          <Space size={6}>
            {showTrialExtensionForm ? (
              <Typography.Text>Request a trial extension</Typography.Text>
            ) : (
              <Typography.Text>
                Talk to Sales to find the right plan
              </Typography.Text>
            )}
          </Space>
        }
        centered
        open={modalState.showQualificationModal !== null}
        onCancel={onCancel}
        onOk={handleFormSubmit}
        destroyOnClose
        width={616}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleFormSubmit}
          >
            {showTrialExtensionForm ? "Submit extension request" : "Submit"}
          </Button>,
        ]}
      >
        <ModalContent>
          <Row className="description">
            <Typography.Text>
              Fill out the form below and a member from our team will reach out
              over email <a href={`mailto:${user?.email}`}>({user?.email})</a>{" "}
              to discuss the best plan for you.{" "}
              <a
                href="https://www.superblocks.com/pricing"
                target="_blank"
                rel="noreferrer"
              >
                Compare all plans
              </a>
            </Typography.Text>
          </Row>
          <Form
            form={qualificationForm}
            initialValues={{
              numCreators: numCreatorsOptions[4].value,
              numViewers: numViewersOptions[5].value,
              features: [
                "RBAC",
                "SSO",
                "ENVS",
                "AUDIT",
                "VERSION_CONTROL",
                "CUSTOM_COMPONENT",
                "USER_ANALYTICS",
                "INTEGRATION_PROFILES",
                "TIMERS",
                "OPA",
              ],
              extensionDuration: 7,
            }}
            validateMessages={{ required: "Required!" }}
            layout="vertical"
            name="qualification-form"
          >
            <Form.Item
              name="numCreators"
              label="Estimated number of Creators developing Superblocks apps on your team"
              rules={[{ required: true }]}
            >
              <Select>
                {numCreatorsOptions.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="numViewers"
              label="Estimated number of End Users consuming Superblocks apps"
              rules={[{ required: true }]}
            >
              <Select>
                {numViewersOptions.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="features" label="What features do you need?">
              <Checkbox.Group>
                <Row>
                  <Checkbox value="RBAC">
                    RBAC (permissions on Integrations, Applications, Workflows,
                    Scheduled Jobs)
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox value="SSO">SSO</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="ENVS">
                    Staging and Production Environments
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox value="VERSION_CONTROL">Version Control</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="AUDIT">Audit Logs</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="INTEGRATION_PROFILES">
                    Integration Profiles
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox value="TIMERS">Live Queries with Timers</Checkbox>
                </Row>
                <Row>
                  <Checkbox value="OPA">On-Premise Agent</Checkbox>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true }]}
            >
              <Input placeholder="(646) 555-1234" />
            </Form.Item>
            {showTrialExtensionForm && (
              <>
                <Form.Item
                  name="extensionDuration"
                  label="Trial extension duration"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value={7}>7 days</Radio>
                    <Radio value={14}>14 days</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="extensionReason"
                  label="What your team will be evaluating during the trial extension?"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={2}
                    placeholder="E.g. I need more time to build an application"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QualificationModal;
