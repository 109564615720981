import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { EditorModes } from "./EditorConfig";
import { CODEMIRROR_HINT_CLASS } from "./editorStyles";

interface NullMarkerHoverProps {
  message: string;
}

interface ScopeErrorMarkerHoverProps {
  methodName: string;
  editorMode: EditorModes;
}

const StyledPre = styled.pre`
  margin: 0;

  &:has(+ div) {
    margin-bottom: 16px;
  }
`;

const MarkerStyles = createGlobalStyle`
  .${CODEMIRROR_HINT_CLASS}:has(.app-scope-error) {
    width: 300px;
  }
`;

export const NullMarkerHover = ({ message }: NullMarkerHoverProps) => {
  return <StyledPre>{message}</StyledPre>;
};

export const FunctionScopeErrorMarkerError = ({
  methodName,
  editorMode,
}: ScopeErrorMarkerHoverProps) => {
  let language = "";
  let languageLink = "";
  switch (editorMode) {
    case EditorModes.PYTHON:
      language = "Python";
      languageLink = "https://docs.superblocks.com/writing-code/python";
      break;
    case EditorModes.SQL_WITH_BINDING:
      language = "SQL";
      languageLink = "https://docs.superblocks.com/writing-code/sql";
      break;
    default:
      language = "backend JS";
      languageLink =
        "https://docs.superblocks.com/writing-code/backend-javascript";
  }

  return (
    <StyledPre>
      ERROR: {methodName} is a{" "}
      <a
        href="https://docs.superblocks.com/writing-code/frontend-javascript"
        target="_blank"
        rel="noreferrer"
      >
        frontend JavaScript
      </a>
      -only method &mdash; it cannot be called from{" "}
      <a href={languageLink} target="_blank" rel="noreferrer">
        {language}
      </a>
      . Instead, set up a frontend event handler from the{" "}
      <a
        href="https://docs.superblocks.com/applications/backend-apis#api-response-block"
        target="_blank"
        rel="noreferrer"
      >
        API response block
      </a>
      .
    </StyledPre>
  );
};

export const AppScopeErrorHoverMarker = ({
  entityName,
}: {
  entityName: string;
}) => {
  return (
    <>
      <StyledPre className="app-scope-error">
        The <i>App.</i> prefix is not needed to access other App-level resources
        from App scope. Please remove the prefix and type <i>{entityName}</i>{" "}
        instead.
      </StyledPre>
      <MarkerStyles />
    </>
  );
};

export const BindingErrorHover = () => {
  return (
    <StyledPre>
      ERROR: Surrounding references with double brackets is invalid in{" "}
      <a
        href="https://docs.superblocks.com/writing-code/backend-javascript"
        target="_blank"
        rel="noreferrer"
      >
        backend JavaScript
      </a>
      . Simply remove the brackets and type the variable or expression directly.
    </StyledPre>
  );
};
