import { memoize } from "lodash";
import memoizeOne from "memoize-one";
import { FifoMapCache } from "re-reselect";
import { createSelector } from "reselect";
import { selectBuildPluginDatasources } from "../../datasources/selectors";
import * as BackendTypes from "../backend-types";
import { selectAllV2Apis } from "../selectors";
import { getV2ApiName } from "../utils/getApiIdAndName";
import {
  ControlFlowPluginInfo,
  convertDSLToFrontend,
  computeControlFlowPluginInfo,
} from "./dsl-converters";
import type { ControlFlowFrontendDSL } from "./types";

/**
 * This is a layered cache:
 * 1. The outer cache is keyed by API ID (unbounded size)
 * 2. The next cache is keyed on the plugin info (size 1)
 *    - Size 1 because the plugin info should remain stable once loaded
 * 3. The final cache is keyed on the backend DSL (size CACHE_SIZE)
 *
 * We want to avoid recomputing the control flow DSL for the same API unless it changes
 * e.g. If user switches between APIs in the editor, each one should only be computed once
 */

// In practice, at most we will need to use current, prev, and next.
const CACHE_SIZE = 3;

const dslConverterFactory = () => {
  const getDslCache = memoizeOne((pluginInfo: ControlFlowPluginInfo) => {
    // fifo has less overhead than lru, and access order is generally going to
    // be linear in order of creation
    return new FifoMapCache({ cacheSize: CACHE_SIZE });
  });
  return (beDSL: BackendTypes.Api, pluginInfo: ControlFlowPluginInfo) => {
    const cache = getDslCache(pluginInfo);
    const dsl = cache.get(beDSL);
    if (dsl) {
      return dsl;
    } else {
      const newDsl = convertDSLToFrontend(beDSL, pluginInfo);
      cache.set(beDSL, newDsl);
      return newDsl;
    }
  };
};

const getConverterById = memoize((apiId: string) => {
  return dslConverterFactory();
});

export const getCachedControlFlowDSL = (
  beDSL: BackendTypes.Api,
  pluginInfo: ControlFlowPluginInfo,
): ControlFlowFrontendDSL => {
  return getConverterById(beDSL.metadata.id)(beDSL, pluginInfo);
};

export const selectControlFlowPluginInfo = createSelector(
  selectBuildPluginDatasources,
  (datasources) => {
    return computeControlFlowPluginInfo(Object.values(datasources));
  },
);

export const selectApiName = createSelector(
  selectAllV2Apis,
  (_state: unknown, apiId: undefined | string) => apiId,
  (apis, apiId) => {
    if (apiId == null || apis[apiId] == null) {
      return undefined;
    }
    return getV2ApiName(apis[apiId]);
  },
);

export const selectCachedControlFlowById = createSelector(
  selectAllV2Apis,
  selectControlFlowPluginInfo,
  (state: unknown, apiId: undefined | string) => apiId,
  (apis, pluginInfo, apiId): undefined | ControlFlowFrontendDSL => {
    if (apiId == null || apis[apiId] == null) {
      return undefined;
    }
    return getCachedControlFlowDSL(apis[apiId]?.apiPb, pluginInfo);
  },
);

export const selectV2BlocksById = createSelector(
  (state: unknown, apiId: undefined | string) =>
    selectCachedControlFlowById(state as any, apiId),
  (dsl) => {
    return dsl?.blocks;
  },
);

export const selectV2BlockById = createSelector(
  (state: unknown, apiId: undefined | string) =>
    selectCachedControlFlowById(state as any, apiId),
  (_state: unknown, _apiId: undefined | string, blockId: string | undefined) =>
    blockId,
  (dsl, blockId) => {
    return dsl?.blocks[blockId ?? ""];
  },
);
