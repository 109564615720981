import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { deleteProfile } from "../client";
import slice from "../slice";

interface DeleteProfilePayload {
  orgId: string;
  profileId: string;
}
function* deleteProfileInternal({ orgId, profileId }: DeleteProfilePayload) {
  yield call(deleteProfile, orgId, profileId);
  return {
    orgId: orgId,
    profileId: profileId,
  };
}

export const deleteProfileSaga = createSaga(
  deleteProfileInternal,
  "deleteProfileSaga",
  { sliceName: "organizations" },
);

slice.saga(deleteProfileSaga, {
  start(state, payload) {
    state.loading[payload.payload.orgId] = true;
    delete state.errors[payload.payload.orgId];
  },
  success(state, { payload }) {
    state.entities[payload.orgId] = state.entities[payload.orgId] ?? {};
    const existingProfiles = state.entities[payload.orgId].profiles ?? [];
    state.entities[payload.orgId].profiles = existingProfiles.filter((p) => {
      return p.id !== payload.profileId;
    });
    delete state.loading[payload.orgId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.orgId] = { error: payload };
    delete state.loading[meta.args.orgId];
  },
});
