import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import {
  AppStateVar,
  AppStateVarPersistance,
} from "store/slices/application/stateVars/StateConstants";
import { showItemPropertyPane } from "../../../../legacy/actions/propertyPaneActions";

export const initPageScopedStorageVars = createAction(
  "INIT_LOCAL_STORE_VARS",
  (applicationId: string, pageId: string | undefined) => ({
    payload: {
      applicationId,
      pageId,
    },
  }),
);

export const initAppScopedStorageVars = createAction<{ applicationId: string }>(
  "INIT_APP_SCOPED_STORAGE_VARS",
);

export const createAndOpenStateVar = (
  persistance: AppStateVarPersistance,
  scope: ApplicationScope,
  id?: string,
  name?: string,
) => createStateVar(persistance, scope, id, name, true);

export const createStateVar = createAction(
  "CREATE_STATE_VAR",
  (
    persistance: AppStateVarPersistance,
    scope: ApplicationScope,
    id?: string,
    name?: string,
    open = false,
  ) => ({
    payload: {
      scope,
      id,
      name,
      persistance,
      open,
    },
  }),
);

export const duplicateStateVar = createAction<{
  fromScope: ApplicationScope;
  toScope: ApplicationScope;
  stateVarId: string;
}>("DUPLICATE_STATE_VAR");

export const deleteStateVar = createAction(
  "DELETE_STATE_VAR",
  (scope: ApplicationScope, id: AppStateVar["id"]) => ({
    payload: { scope, id },
  }),
);

export const updateStateVars = createAction<{
  scope: ApplicationScope;
  stateVars: Record<
    AppStateVar["id"],
    Partial<Pick<AppStateVar, "defaultValue">>
  >;
}>("UPDATE_STATE_VAR");

export const editStateVarPropertyPane = (
  stateVarId: string,
  scope: ApplicationScope,
) => showItemPropertyPane({ kind: ItemKinds.STATE_VAR, id: stateVarId, scope });
