import { DropdownOption } from "@superblocksteam/shared";
import { Radio, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";

interface RadioProps {
  options?: DropdownOption[];
}

type Props = RadioProps & DynamicFormItemProps;

const RadioGroupWrapper = styled.div`
  display: flex;
  .ant-radio-wrapper {
    font-size: 12px;
  }
`;

const DynamicFormRadio = ({
  path,
  options,
  initialValue,
  label,
  isNew,
  immutable,
  disabled,
}: Props) => {
  const context = useContext(FormContext);
  const onRadioChange = (val: any) => {
    context.onChange(path, val.target.value);
    setValue(val.target.value);
  };

  const [value, setValue] = useState<string>();

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) => {
      setValue(value as string);
    });

    return () => unsubscribe();
  }, [path, context.subscribe, context]);
  return (
    <div>
      <RadioGroupWrapper>
        <Radio.Group
          onChange={onRadioChange}
          defaultValue={initialValue}
          value={value}
        >
          <Space direction="vertical" align="start">
            <FormLabel>{label}</FormLabel>
            {options?.map((option: DropdownOption) => {
              return (
                <Radio
                  key={option.key}
                  value={option.value}
                  disabled={(!isNew && immutable) || disabled}
                >
                  {option.displayName}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </RadioGroupWrapper>
    </div>
  );
};

export default DynamicFormRadio;
