import React, { ReactElement } from "react";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import IntegrationHome from "./IntegrationHome";
import IntegrationHomeLegacy from "./IntegrationHomeLegacy";

export default function Integrations(): ReactElement {
  const enableNewIntegrationHome = useFeatureFlag(
    Flag.ENABLE_NEW_INTEGRATION_HOME,
  );

  return enableNewIntegrationHome ? (
    <IntegrationHome />
  ) : (
    <IntegrationHomeLegacy />
  );
}
