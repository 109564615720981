import {
  PostApiRemoteCommitCreateResponseBody,
  PostApplicationRemoteCommitCreateResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { createCommitRemote } from "../client";
import slice from "../slice";

function* createRemoteCommitSaga({
  entityId,
  entityType,
  commitId,
}: {
  entityId: string;
  entityType: VersionedEntityType;
  commitId: string;
}) {
  const result:
    | PostApplicationRemoteCommitCreateResponseBody
    | PostApiRemoteCommitCreateResponseBody = yield call(
    createCommitRemote,
    entityId,
    entityType,
    commitId,
    {
      // UI will handle with top notification
      notifyOnError: false,
      onError: (err) => {
        // prevent crashing page, but also need to throw for saga error handling
        err.critical = false;
        throw err;
      },
    },
  );
  return result;
}

const createRemoteCommit = createSaga(
  createRemoteCommitSaga,
  createRemoteCommitSaga.name,
  {
    sliceName: slice.name,
  },
);
export { createRemoteCommit as createRemoteCommitSaga };

slice.saga(createRemoteCommit, {
  start(state, { payload }) {
    state.loading.commits = true;
    (state.loading as Record<string, boolean>)[payload.commitId] = true;
  },
  success(state, { payload }) {
    state.entity = { ...state.entity, autosaves: {} };
    if (payload && payload.commitId && payload.remoteCommitId)
      state.entity.commits[payload.commitId].externalCommitId =
        payload.remoteCommitId;
    state.loading.commits = false;
    (state.loading as Record<string, boolean>)[payload.commitId] = false;
  },
  error(state, { payload, meta }) {
    state.error = { autosaves: payload, commits: payload };
    state.loading.commits = false;
    (state.loading as Record<string, boolean>)[meta.args.commitId] = false;
  },
});
