/**
 * You can generally use `sendSuccessUINotification` or `sendErrorUINotification`
 * directly, instead of using this saga.
 *
 * This saga is useful when having to send notifications from inside widgets (in Edit mode),
 * because widgets are inside the canvas iframe.
 *
 **/

import { takeEvery } from "redux-saga/effects";
import { createErrorUINotification } from "store/slices/notifications/actions";
import { sendErrorUINotification } from "utils/notification";

function* createErrorUINotificationSaga(
  action: ReturnType<typeof createErrorUINotification>,
) {
  yield sendErrorUINotification(action.payload);
}

export default function* notificationSagas() {
  yield takeEvery(
    createErrorUINotification.type,
    createErrorUINotificationSaga,
  );
}
