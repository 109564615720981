import { CodeMirrorManager } from "./codemirror";
import { ContextFile } from "./context_utils";
import { EditorOptions } from "./rpc/gen/exa/codeium_common_pb";

declare type CodeMirror = typeof import("codemirror");

export class CodeMirrorState {
  codeMirrorManager: CodeMirrorManager;
  metadata?: any;
  stepName: string = "";
  variableNames: string[] = [];
  prefix: string = "";
  suffix: string = "";
  previousSteps: Record<string, ContextFile> = {};
  docs: CodeMirror.Doc[] = [];
  hookedEditors = new WeakSet<CodeMirror.Editor>();

  constructor(
    userId: string,
    cm: CodeMirror | undefined,
    readonly multiplayer: boolean,
  ) {
    this.codeMirrorManager = new CodeMirrorManager(userId, {
      ideName: "codemirror",
      ideVersion: `${cm?.version ?? "unknown"}-${window.location.hostname}`,
    });
    if (cm !== undefined) {
      cm.defineInitHook(this.editorHook());
    }
  }

  updateContext(context: {
    prefix: string;
    suffix: string;
    previousSteps: Record<string, ContextFile>;
    stepName: string;
    variableNames: string[];
  }) {
    this.prefix = context.prefix;
    this.previousSteps = context.previousSteps;
    this.stepName = context.stepName;
    this.suffix = context.suffix;
    this.variableNames = context.variableNames;
  }

  editorHook(metadata?: any): (editor: CodeMirror.Editor) => void {
    this.metadata = metadata;
    const hook = this.codeMirrorManager.clearCompletionInitHook();
    return (editor) => {
      if (this.hookedEditors.has(editor)) {
        return;
      }
      this.hookedEditors.add(editor);
      this.addKeydownListener(editor, this.multiplayer);
      hook(editor);
    };
  }

  addKeydownListener(editor: CodeMirror.Editor, multiplayer: boolean) {
    const el = editor.getInputField().closest(".CodeMirror");
    if (el === null) {
      return;
    }
    editor.on("keydown", (editor: CodeMirror.Editor, event: KeyboardEvent) => {
      const { consumeEvent, forceTriggerCompletion } =
        this.codeMirrorManager.beforeMainKeyHandler(
          editor.getDoc(),
          () => {
            editor.closeHint();
          },
          event,
          {
            tab: true,
            escape: true,
          },
        );
      const doc = editor.getDoc();
      const oldString = doc.getValue();
      setTimeout(async () => {
        if (!forceTriggerCompletion) {
          const newString = doc.getValue();
          if (newString === oldString) {
            // Cases like arrow keys, page up/down, etc. should fall here.
            return;
          }
        }

        // TODO: Clean up context passing.
        await this.codeMirrorManager.triggerCompletion(
          editor,
          this.previousSteps,
          this.metadata,
          this.stepName,
          this.variableNames,
          this.prefix,
          this.suffix,
          editor.getOption("mode")?.toString() ?? "javascript",
          this.docs,
          editor.getDoc(),
          undefined,
          new EditorOptions({
            tabSize: BigInt(editor.getOption("tabSize") ?? 4), // TODO: This needs to be set based on language.
            insertSpaces: !(editor.getOption("indentWithTabs") ?? false),
          }),
          undefined,
          // undefined,
        );
      });
      if (consumeEvent !== undefined) {
        if (consumeEvent) {
          event.preventDefault();
        }
        return;
      }
    });
  }
}
