import { put, select } from "redux-saga/effects";
import { hasUpdatedEntityNames } from "legacy/actions/evaluationActions";
import {
  getEntityCount,
  scopedDataTrees,
} from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";

export function* checkEvaluationNamesDirty(
  dataTreeResponse: DataTree,
): Generator<any, { updateCount: number; deleteCount: number }, any> {
  const { deletedEntities: deletedEntityNames, ...dataTreeUpdates } =
    dataTreeResponse;
  const updateCount = getEntityCount(dataTreeUpdates);
  const deleteCount = (deletedEntityNames ?? []).length;

  // Post-evaluation, determine if any entity name has changed for API dep caching
  // we don't care which names are changed, just that there are changes
  if (deleteCount) {
    yield put(hasUpdatedEntityNames());
  } else if (updateCount) {
    const currentDataTree: ReturnType<typeof getDataTree> =
      yield select(getDataTree);
    // Look for any keys that are not in the current data tree, meaning they've been added or renamed
    const hasRename = scopedDataTrees(dataTreeUpdates).some(([scope, tree]) => {
      return Object.keys(tree).some(
        (entityName) => !currentDataTree[scope][entityName],
      );
    });
    if (hasRename) {
      yield put(hasUpdatedEntityNames());
    }
  }

  return { updateCount, deleteCount };
}
