import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import {
  getDataEntities,
  getEntity,
} from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { isWidget } from "legacy/workers/evaluationUtils";
import {
  DynamicChanges,
  dynamicVisibility,
} from "./autoHeight/compositeReflowTypes";

export function getVisibilityChanges(
  dataTreeChanges: ReduxAction<DataTree>[],
  deletedWidgetIds: Set<string>,
  hiddenWidgets: Set<string>,
  reflowDisabled: Set<string> = new Set<string>(),
): DynamicChanges {
  const changedWidgetVisibility: DynamicChanges = {};
  dataTreeChanges.forEach((action) => {
    const { deletedEntities: _, ...dataTree } = action.payload;
    const entities = getDataEntities(dataTree);
    entities.forEach(({ entity }) => {
      if (isWidget(entity)) {
        const isDeleted = deletedWidgetIds.has(entity.widgetId);
        if (isDeleted || entity.skippedEvaluation) return;
        const disable = reflowDisabled.has(entity.widgetId);
        if (disable) return;

        const entityIsVisible = entity.isVisible ?? true;
        const collapseWhenHidden = entity.collapseWhenHidden ?? false;

        const isVisible = entityIsVisible || collapseWhenHidden === false;
        if (isVisible && hiddenWidgets.has(entity.widgetId)) {
          changedWidgetVisibility[entity.widgetId] = dynamicVisibility(true);
          hiddenWidgets.delete(entity.widgetId);
        } else if (!isVisible && !hiddenWidgets.has(entity.widgetId)) {
          changedWidgetVisibility[entity.widgetId] = dynamicVisibility(false);
          hiddenWidgets.add(entity.widgetId);
        }
      }
    });
  });
  return changedWidgetVisibility;
}

export function getDeletedWidgetIds(
  dataTreeChanges: ReduxAction<DataTree>[],
): Set<string> {
  const deletedWidgetIds = new Set<string>();
  dataTreeChanges.forEach((action) => {
    const { deletedEntities, ...dataTree } = action.payload;
    deletedEntities?.forEach((loc) => {
      const entity = getEntity(loc, dataTree);
      if (isWidget(entity)) {
        deletedWidgetIds.add(entity.widgetId);
      }
    });
  });
  return deletedWidgetIds;
}
