import { DropdownOption } from "@superblocksteam/shared";
import { Select, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import logger from "utils/logger";

interface Props {
  fetchOptions: () => Promise<DropdownOption[]>;
  extraOptions?: Record<string, DropdownOption & { onClick: () => void }>;
  value?: string;
  onChange?: (value: any) => void;
  dataTest: string | undefined;
}

const DynamicDropdown = ({
  fetchOptions,
  extraOptions,
  value,
  onChange,
  ...otherProps
}: Props) => {
  const [isFetching, setFetching] = useState(false);
  const [options, setOptions] = useState<DropdownOption[]>();
  const refreshOptions = useCallback(() => {
    setFetching(true);
    fetchOptions()
      .then((options) => {
        setOptions(options);
        setFetching(false);
      })
      .catch((error) => {
        logger.error(error);
        setFetching(false);
      });
  }, [fetchOptions, setFetching, setOptions]);
  useEffect(() => {
    refreshOptions();
  }, [refreshOptions]);

  return (
    <Select
      value={value}
      {...otherProps}
      notFoundContent={isFetching ? <Spin /> : null}
      onDropdownVisibleChange={(open) => open && refreshOptions()}
      onChange={(newValue) => {
        const oldValue = value;
        if (extraOptions && newValue in extraOptions) {
          extraOptions[newValue].onClick();
          onChange && onChange(oldValue ?? null);
          return;
        }
        onChange && onChange(newValue);
      }}
    >
      {options?.map((option) => (
        <Select.Option key={option.key} value={option.value}>
          {option.displayName ?? option.value}
        </Select.Option>
      ))}
      {Object.values(extraOptions ?? {}).map((option) => (
        <Select.Option key={option.key} value={option.value}>
          {option.displayName ?? option.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DynamicDropdown;
