import { Classes } from "@blueprintjs/core";
import { isArray } from "lodash";
import React from "react";
import { RADIO_GROUP_LABEL_PADDING } from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";
import { TypographyProps, useTypographyStyling } from "../typographyHooks";
import { createStyleClassName } from "../typographyUtils";
import { RadioInput } from "./RadioInput";
import {
  DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
} from "./constants";
import type {
  RadioGroupComponentWithLayoutManagedProps,
  RadioGroupComponentProps,
} from "./types";

const RadioGroupWrapper = styleAsClass`
  display: flex;
  label.radio-group-label {
    flex: 0 1 auto;
    flex-shrink: 0;
    margin: 7px ${String(RADIO_GROUP_LABEL_PADDING)}px 7px 0;
    align-self: flex-start;
    white-space: normal;
  }

  .radio-group-list {
    display: flex;
    flex-direction: column;
  }
`;

export const StaticTypographyRadioGroupLabelProps: TypographyProps = {
  textStyleVariant: DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
};

export const StaticTypographyRadioGroupOptionProps: TypographyProps = {
  textStyleVariant: DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
};

class RadioGroupComponent extends React.Component<RadioGroupComponentProps> {
  render() {
    if (!isArray(this.props.options)) {
      try {
        // this error occurs very rarely, will keep this log until we can find the root cause
        logger.error(
          `RadioGroupComponent: options is not an array: ${JSON.stringify(
            this.props.options,
          )}`,
        );
      } catch (error: any) {
        logger.error(
          `RadioGroupComponent: options cannot be serialized, ${error?.message}`,
        );
      }
    }

    const labelClass = createStyleClassName({
      textStyleVariant: this.props.labelProps.textStyleVariant,
      isDisabled: this.props.isDisabled,
      type: "label",
    });

    const optionClass = getTextCssClassFromVariant(
      this.props.optionProps.textStyleVariant,
    );

    return (
      <div
        style={
          this.props.vertical
            ? { flexDirection: "column" }
            : { flexDirection: "row" }
        }
        className={this.props.isLoading ? Classes.SKELETON : RadioGroupWrapper}
      >
        {this.props.label && (
          <label
            className={`${labelClass} radio-group-label`}
            style={this.props.labelProps.style}
          >
            {this.props.isRequired && this.props.label.indexOf("*") === -1 && (
              <span className={`${CLASS_NAMES.ERROR_MODIFIER}`}>* </span>
            )}
            {this.props.label}
          </label>
        )}
        <div
          className={`radio-group-list`}
          tabIndex={0}
          style={
            this.props.vertical
              ? { flexDirection: "column" }
              : {
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                  height: "32px",
                }
          }
        >
          {isArray(this.props.options) &&
            this.props.options.map((option, idx) => {
              return (
                <RadioInput
                  isSelected={this.props.selectedOptionValue === option.value}
                  isDisabled={this.props.isDisabled === true}
                  onClick={this.props.onRadioSelectionChange}
                  option={option}
                  radioGroupName={this.props.widgetId}
                  key={idx}
                  textClassName={optionClass}
                  textStyle={this.props.optionProps.style}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export const RadioGroupComponentWithLayoutManaged = (
  props: RadioGroupComponentWithLayoutManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant:
      DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });
  const optionProps = useTypographyStyling({
    textStyle: props.optionProps?.textStyle,
    defaultTextStyleVariant:
      DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
  });

  return (
    <RadioGroupComponent
      widgetId={props.widgetId}
      labelProps={labelProps}
      label={props.label}
      options={props.options}
      onRadioSelectionChange={props.onRadioSelectionChange}
      selectedOptionValue={props.selectedOptionValue}
      isLoading={props.isLoading}
      isRequired={props.isRequired}
      vertical={props.isVertical}
      optionProps={optionProps}
      isDisabled={props.isDisabled}
    />
  );
};

export default RadioGroupComponent;
