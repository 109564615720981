import { Dimension } from "@superblocksteam/shared";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { updateWidgetAutoHeight } from "legacy/actions/controlActions";
import { updateLayoutPosition } from "legacy/actions/pageActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { gridDescendants } from "legacy/selectors/editorSelectors";
import { getWidgets } from "legacy/selectors/sagaSelectors";
import { queuedBatchDebounce } from "store/utils/effects";
import {
  getDeletedWidgetIds,
  getVisibilityChanges,
} from "./WidgetResizeSagaHelpers";
import { Reflow } from "./autoHeight/compositeReflow";
import {
  DynamicChanges,
  dynamicHeight,
} from "./autoHeight/compositeReflowTypes";

let reflow = new Reflow();
let hiddenWidgets = new Set<string>();

// NOTE (Layouts Team):
// We don't disable auto height when the flag is off, setting the properties is behind a flag
// but once set, we don't want to disable it. On the other hand isVisible is disabled
// as updateVisible is called on every data tree change, and we don't want to cause performance issues

function* updateWidgetAutoHeightSaga(
  actions: Array<ReturnType<typeof updateWidgetAutoHeight>>,
) {
  const dynamicHeights: DynamicChanges = {};
  actions.forEach(({ payload: item }) => {
    dynamicHeights[item.widgetId] = dynamicHeight(
      item.heightInPx + WIDGET_PADDING * 2,
    );
  });

  yield call(reflowAndUpdate, dynamicHeights);
}

function* updateDataTree(dataTreeChanges: ReduxAction<DataTree>[]) {
  const deletedWidgetIds = getDeletedWidgetIds(dataTreeChanges);
  deletedWidgetIds.forEach((id) => {
    reflow.onDelete(id);
    hiddenWidgets.delete(id);
  });

  // We don't support hiding widgets in edit mode
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (appMode === APP_MODE.EDIT) return;

  const reflowDisabledWidgets: Set<string> = yield select(gridDescendants);
  const changedWidgetVisibility = getVisibilityChanges(
    dataTreeChanges,
    deletedWidgetIds,
    hiddenWidgets,
    reflowDisabledWidgets,
  );

  if (Object.keys(changedWidgetVisibility).length) {
    yield call(reflowAndUpdate, changedWidgetVisibility);
  }
}

function* reflowAndUpdate(dynamicChanges: DynamicChanges) {
  const result = yield* reflow.reflow(dynamicChanges);
  if (Object.keys(result.changes).length) {
    yield put(updateLayoutPosition(result.changes));
  }
}

export function* reflowWidgets(widgetIds: string[]) {
  const canvasWidgets: ReturnType<typeof getWidgets> = yield select(getWidgets);

  const dynamicHeights: DynamicChanges = {};
  widgetIds.forEach((id) => {
    const height = canvasWidgets[id]?.height;
    if (!height) return;

    const pixelHeight = Dimension.toPx(
      height,
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ).value;
    dynamicHeights[id] = dynamicHeight(pixelHeight);
  });

  yield call(reflowAndUpdate, dynamicHeights);
}

function reset() {
  reflow = new Reflow();
  hiddenWidgets = new Set<string>();
}

export default function* widgetResizeSagas() {
  yield all([
    queuedBatchDebounce(
      ReduxActionTypes.WIDGET_UPDATE_AUTO_HEIGHT,
      updateWidgetAutoHeightSaga,
    ),
    queuedBatchDebounce(ReduxActionTypes.SET_EVALUATED_TREE, updateDataTree),
    takeEvery(ReduxActionTypes.RESET_WIDGETS, reset),
  ]);
}
