import { PageDSL8 } from "@superblocksteam/shared";
import { generateReactKey } from "legacy/utils/generators";

export const SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE = "optimizedPageLoadApis";
type PageLoadAction = {
  id: string;
  type: typeof SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE;
};

export default function migrate5to6(page: PageDSL8): PageDSL8 {
  const pageLoadAction = createPageLoadAction();
  page.onPageLoad = [pageLoadAction];
  return page;
}

const createPageLoadAction = (): PageLoadAction => {
  return {
    id: generateReactKey(),
    type: SUPERBLOCKS_OPTIMIZED_APIS_ACTION_TYPE,
  };
};
