import {
  UserDefinedTheme,
  CUSTOM_THEME_TYPOGRAPHY_KEY,
} from "@superblocksteam/shared";
import { merge } from "lodash";
import { CLASS_NAMES } from "legacy/themes/classnames";
import {
  BASE_THEME_EXISTING_APPS,
  UserDefinedThemeWithPalette,
  UserDefinedThemeWithVersion,
} from "legacy/themes/constants";
import { TextStyleWithVariant } from "legacy/themes/types";
import { DEFAULT_TYPOGRAPHIES_BY_VERSION } from "legacy/themes/typographyConstants";
import { fastClone } from "utils/clone";
/**
 * Retrieves the theme with version information.
 *
 * @param userDefinedTheme - The user-defined theme object.
 * @returns The theme object with the version property defined
 *
 * version === 1: existing apps, no theme stored
 * version === 2: new apps introduced since theming was launched, has a theme stored, but no version property has been set
 * version >= 3:  new apps created with a theme since we launched versioning of themes (when this code was introduced)
 */
export function getThemeWithVersion(
  userDefinedTheme: UserDefinedTheme | undefined,
): UserDefinedThemeWithVersion {
  let theme: UserDefinedTheme | UserDefinedThemeWithPalette =
    BASE_THEME_EXISTING_APPS;

  // has stored theme?
  if (userDefinedTheme !== undefined) {
    theme = userDefinedTheme!;

    // does the stored theme have a version?
    if (userDefinedTheme?.version === undefined) {
      // No version stored, so it must be version 2
      theme = {
        ...theme,
        version: 2,
      };
    }
  }

  return theme as UserDefinedThemeWithVersion;
}

export const getCustomTypographyAccessor = (typographyKey: string) => {
  return `${CUSTOM_THEME_TYPOGRAPHY_KEY}.${typographyKey}.styles`;
};

export const getCustomTypographyNameFromAccessor = (accessor: string) => {
  return accessor
    .replace(`${CUSTOM_THEME_TYPOGRAPHY_KEY}.`, "")
    .replace(".styles", "");
};

export const getCustomTypographyClassname = (typographyKey: string) => {
  return `sb-custom-${typographyKey}`;
};

export const getTextCssClassFromVariant = (
  textStyleVariant?: string,
): string => {
  switch (textStyleVariant) {
    case "heading1":
      return CLASS_NAMES.HEADING1;
    case "heading2":
      return CLASS_NAMES.HEADING2;
    case "heading3":
      return CLASS_NAMES.HEADING3;
    case "heading4":
      return CLASS_NAMES.HEADING4;
    case "heading5":
      return CLASS_NAMES.HEADING5;
    case "heading6":
      return CLASS_NAMES.HEADING6;
    case "body1":
      return CLASS_NAMES.BODY1;
    case "body2":
      return CLASS_NAMES.BODY2;
    case "body3":
      return CLASS_NAMES.BODY3;
    case "link":
      return CLASS_NAMES.LINK;
    case "label":
      return CLASS_NAMES.LABEL;
    case "inputLabel":
      return CLASS_NAMES.INPUT_LABEL;
    case "inputText":
      return CLASS_NAMES.INPUT_TEXT;
    case "inputPlaceholder":
      return CLASS_NAMES.INPUT_PLACEHOLDER;
    case "buttonLabel":
      return CLASS_NAMES.BUTTON_LABEL;
    case "code":
      return CLASS_NAMES.CODE_TEXT;
    case undefined:
      return CLASS_NAMES.BODY1;
    default:
      // custom items are keyed like `custom.custom-item.styles`
      return getCustomTypographyClassname(
        getCustomTypographyNameFromAccessor(textStyleVariant),
      );
  }
};

export const camelCaseToSentenceCase = (input: string): string => {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert spaces before uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle cases where there are consecutive uppercase letters
    .replace(/([a-zA-Z])([0-9])/g, "$1 $2") // Add spaces between letters and numbers
    .toLowerCase() // Convert the entire string to lower case
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

export const extractPixels = (pxValue: string): number => {
  const pixels = parseInt(pxValue.replace("px", ""), 10);
  if (isNaN(pixels)) {
    return 14; // return some reasonable default
  }
  return pixels;
};

const isPixelString = (value: any): boolean => {
  if (typeof value !== "string") return false;
  const pixelRegex = /^\d+px$/;
  return pixelRegex.test(value);
};

export const getLineHeightInPixels = (
  lineHeight: string | number,
  fontSize: number,
): number => {
  if (isPixelString(lineHeight)) {
    return extractPixels(String(lineHeight));
  }
  if (lineHeight === "normal") {
    return 1.2 * fontSize; // depends on browser & font family, but should be close enough
  }
  // otherwise it's a number so multiply
  return Number(lineHeight) * fontSize;
};

export const mergeTextStyles = (
  base: TextStyleWithVariant | undefined,
  override: TextStyleWithVariant | undefined,
): TextStyleWithVariant | undefined => {
  if (!base && !override) {
    return undefined;
  }

  if (override?.variant !== undefined) {
    // If override has a variant set, use its properties entirely
    return override;
  } else {
    // Otherwise, merge normally
    return merge(fastClone(base), override);
  }
};

export const isColorToken = (value?: string) => {
  if (!value) return false;
  // if it matches the format "colors.xxx"  then it's a color token
  return value.startsWith("colors.");
};

export function getDefaultTypographies(themeVersion?: number) {
  return DEFAULT_TYPOGRAPHIES_BY_VERSION[themeVersion || 2];
}
