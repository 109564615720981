import { RouteDef } from "@superblocksteam/shared";
import { get, set } from "lodash";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { unsetPropertyPath } from "legacy/utils/DynamicBindingUtils";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  getDefaultColumnProperties,
  getTableStyles,
} from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { updateDerivedColumnsHook } from "legacy/widgets/TableWidget/TablePropertyPaneConfig";
import { fastClone } from "utils/clone";
import { getDottedPathTo } from "utils/dottedPaths";
import { getEventHandlers } from "./eventHandlers";
import {
  AddActionEvent,
  SetColumnAction,
  AddEventAction,
  RemoveColumnAction,
  RemoveEventAction,
  SetAction,
} from "./types";

const PROPERTIES_TO_CHANGE = ["columnOrder"];

export const getColumnIdForAi = (
  columnId: string,
  columnConfig: ColumnProperties,
) => {
  if (!columnConfig.isDerived) {
    return columnId;
  }
  // use the label or buton label
  const label = (columnConfig.label || columnConfig.buttonLabel) ?? columnId;
  // replace spaces with underscores
  const cleanedLabel = label.replace(/\s+/g, "_");
  return cleanedLabel;
};

export const getColumnIdFromAi = (
  columnId: string,
  columns: Record<string, ColumnProperties>,
) => {
  if (!columns[columnId].isDerived) {
    return columnId;
  }
  const parsedId = Object.keys(columns).find((key) => {
    const column = columns[key];
    return column.id === getColumnIdForAi(columnId, column);
  });
  return parsedId ?? columnId;
};

const initializeProperties = (changes: Record<string, any>, widget: any) => {
  PROPERTIES_TO_CHANGE.forEach((property) => {
    set(
      changes,
      property,
      fastClone(changes[property] ?? widget[property] ?? {}),
    );
  });
};

export const addColumns = (
  action: SetColumnAction,
  widget: any,
  changes: Record<string, any>,
  numColumns: number,
) => {
  // update derived and primary columns
  initializeProperties(changes, widget);
  const newColumnName =
    "column" in action ? (action.column as string) : undefined;

  if (!newColumnName) return;

  const columnExists =
    widget.primaryColumns?.[newColumnName] ??
    changes.primaryColumns?.[newColumnName];
  if (!newColumnName || typeof newColumnName !== "string" || columnExists) {
    // column with this name already exists
    return;
  }

  const columnProps: ColumnProperties = getDefaultColumnProperties(
    newColumnName,
    numColumns,
    widget.widgetName,
    true,
  );
  const tableStyles = getTableStyles(widget);
  const column = {
    ...columnProps,
    ...tableStyles,
  };
  const path = `primaryColumns${getDottedPathTo(column.id)}`;
  changes[path] = column;
  const additionalUpdates = updateDerivedColumnsHook({
    props: widget,
    propertyPath: path,
    propertyValue: column,
  });
  if (additionalUpdates) {
    additionalUpdates.forEach(
      (update: { propertyPath: string; propertyValue: any }) => {
        changes[update.propertyPath] = update.propertyValue;
      },
    );
  }
};

export const removeColumns = (
  action: RemoveColumnAction,
  widget: any,
  changes: Record<string, any>,
) => {
  const { column } = action;
  initializeProperties(changes, widget);

  const columnToRemove =
    widget.primaryColumns?.[column] ?? changes.primaryColumns?.[column];

  if (!column || typeof column !== "string" || !columnToRemove) {
    return;
  }

  const isDerived = widget.derivedColumns?.[columnToRemove.id] != null;

  if (isDerived) {
    const columnOrderIndex = widget.columnOrder.findIndex(
      (columnName: string) => columnName === columnToRemove.id,
    );
    changes[`derivedColumns.${column}`] = null;
    changes[`primaryColumns.${column}`] = null;
    unsetPropertyPath(changes, `columnOrder[${columnOrderIndex}]`);
  } else {
    // If not derived, we just hide the column as we can't delete it
    changes[`primaryColumns.${column}.isVisible`] = false;
  }
};

export const updateColumns = ({
  action,
  widget,
  changes,
  dataTree,
  routes,
}: {
  action: SetAction | AddEventAction | RemoveEventAction;
  widget: any;
  changes: Record<string, any>;
  dataTree: DataTree;
  routes: RouteDef[];
}) => {
  initializeProperties(changes, widget);
  const updates: { path: string; value: any }[] = [];

  const addUpdate = (
    property: string,
    column: string,
    value: any,
    isDerived: boolean,
  ) => {
    updates.push({
      path: `primaryColumns.${column}.${property}`,
      value,
    });
    if (isDerived) {
      updates.push({
        path: `derivedColumns.${column}.${property}`,
        value,
      });
    }
  };
  const column = "column" in action ? (action.column as string) : undefined;
  if (!column) return;

  const value = action.value;

  const columnExists =
    widget.primaryColumns?.[column] ?? get(changes, `primaryColumns.${column}`);
  if (!column || typeof column !== "string" || !columnExists) {
    return;
  }
  const isDerived =
    (widget.derivedColumns?.[column] ??
      get(changes, `derivedColumns${getDottedPathTo(column)}`)) != null;

  switch (action.action) {
    case "set": {
      addUpdate(action.property, column, value, isDerived);
      break;
    }
    case "add": {
      const prevValue =
        get(changes, action.property) || get(widget, action.property);
      const newValue = getEventHandlers({
        prevValue,
        value: action.value as AddActionEvent,
        dataTree,
        routes,
      });
      addUpdate(action.property, column, newValue, isDerived);
      break;
    }
    case "remove": {
      const prevValue =
        get(changes, action.property) || get(widget, action.property);
      const newValue = Array.isArray(prevValue)
        ? prevValue.filter(
            (item) =>
              item.id !== (action.value as RemoveEventAction["value"]).id,
          )
        : prevValue;
      addUpdate(action.property, column, newValue, isDerived);
    }
  }

  updates.forEach((update) => {
    changes[update.path] = update.value;
  });
};
