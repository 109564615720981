import { Alert } from "antd";
import React from "react";

type WarningsContainerProps = {
  warnings?: string[];
  errors?: string[];
  closable?: boolean;
};

const WarningsContainer = ({
  warnings,
  errors,
  closable,
}: WarningsContainerProps) => {
  return (
    <>
      {warnings?.map((warning, index) => {
        return (
          <Alert
            key={index}
            message={warning}
            type="warning"
            closable={closable}
          />
        );
      })}
      {errors?.map((error, index) => {
        return (
          <Alert key={index} message={error} type="error" closable={closable} />
        );
      })}
    </>
  );
};

export default WarningsContainer;
