import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { deleteRepository } from "../client";
import slice from "../slice";

type DeleteRepositoryPayload = {
  repositoryId: string;
};

function* deleteRepositoryInternal({ repositoryId }: DeleteRepositoryPayload) {
  yield call(deleteRepository, repositoryId);
  return {
    repositoryId,
  };
}

export const deleteRepositorySaga = createSaga(
  deleteRepositoryInternal,
  "deleteRepositorySaga",
  {
    sliceName: "repositories",
  },
);

slice.saga(deleteRepositorySaga, {
  start(state, { payload }) {
    state.loading[payload.repositoryId] = true;
    delete state.errors[payload.repositoryId];
  },
  success(state, { payload }) {
    delete state.entities[payload.repositoryId];
    delete state.loading[payload.repositoryId];
  },
  error(state, { payload, meta }) {
    delete state.loading[meta.args.repositoryId];
    state.errors[meta.args.repositoryId] = { error: payload };
  },
});
