import { Form, Input, Modal } from "antd";
import { useCallback, useEffect } from "react";
import React from "react";
import { useAppDispatch } from "store/helpers";
import { updateApplicationMetadata } from "store/slices/application/applicationActions";
import logger from "utils/logger";

type RenameFolderModalProps = {
  renamingApplicationId?: string;
  renamingApplicationName?: string;
  closeModal: () => void;
};

const RenameEntityModal = ({
  renamingApplicationId,
  renamingApplicationName,
  closeModal,
}: RenameFolderModalProps) => {
  const dispatch = useAppDispatch();
  const [renameForm] = Form.useForm();

  const handleApplicationRename = useCallback(() => {
    if (!renamingApplicationId) {
      return;
    }
    renameForm
      .validateFields()
      .then((values) => {
        dispatch(
          //TODO(alex): this can be used to update profile settings
          updateApplicationMetadata(renamingApplicationId, {
            name: values.name,
          }),
        );
        closeModal();
        renameForm.resetFields();
      })
      .catch((info) => {
        logger.debug("Validate Failed:", info);
      });
  }, [renamingApplicationId, renameForm, dispatch, closeModal]);

  const onCancelRename = useCallback(() => {
    renameForm.resetFields();
    closeModal();
  }, [closeModal, renameForm]);

  useEffect(() => {
    if (renamingApplicationId === undefined) {
      closeModal();
    }
  }, [closeModal, renamingApplicationId]);

  return (
    <Modal
      title={`Renaming application: ${renamingApplicationName}`}
      open={true}
      onOk={handleApplicationRename}
      onCancel={onCancelRename}
      okText="Rename"
      destroyOnClose
      data-test="rename-form"
    >
      <Form
        form={renameForm}
        layout="vertical"
        name="rename_form"
        initialValues={{ name: renamingApplicationName }}
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a valid application name",
            },
          ]}
        >
          <Input placeholder="Application name..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameEntityModal;
