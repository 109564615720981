import {
  EllipsisOutlined,
  ExclamationCircleFilled,
  WarningTwoTone,
} from "@ant-design/icons";
import {
  AccessMode,
  Agent,
  AgentStatus,
  AgentType,
  Organization,
  SignatureVerificationKey,
} from "@superblocksteam/shared";
import {
  Alert,
  Badge,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table/interface";

import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as CheckCircle } from "assets/icons/common/check-circle-filled.svg";
import { ReactComponent as ErrorCircle } from "assets/icons/common/error-circle-filled.svg";
import { ReactComponent as RotateKeysIcon } from "assets/icons/common/rotate-keys.svg";
import { Layout, MainWrapper } from "components/app";
import { Button } from "components/ui/Button";
import LoadingIndicator from "components/ui/LoadingIndicator";
import { ConfirmModalClass } from "components/ui/Modal";
import { HeaderWrapper } from "components/ui/Page";
import { FullWidthSpace } from "components/ui/Space";
import { Table, TableClass } from "components/ui/Table";
import { MANAGE_AGENTS } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import {
  KeyRotationStatus,
  KeyRotationWithCounts,
} from "legacy/api/SigningKeyRotationApi";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { TagsColorAssignment } from "legacy/widgets/TableWidget/TableComponent/Constants";
import {
  sanitizeCellValue,
  TagsCell,
} from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { AGENTS_TITLE, PageWrapper } from "pages/components/PageWrapper";
import {
  getAgentsSaga,
  startKeyRotation as startKeyRotationSaga,
  getKeyRotation as getKeyRotationSaga,
  getKeyRotations as getKeyRotationsSaga,
  cancelKeyRotation as cancelKeyRotationSaga,
} from "store/slices/agents";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { selectOrganizations } from "store/slices/organizations";
import { updateOrganization } from "store/slices/organizations/client";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { colors } from "styles/colors";
import { useSaga } from "../../hooks/store";
import { LegacyNamedColors } from "../../legacy/constants/LegacyNamedColors";
import { AGENTS_DEPLOYMENT_INSTRUCTIONS_URL } from "../../legacy/constants/routes";
import initializeUser from "../../store/sagas/initializeUser";
import { selectOnPremAgentWithHealths } from "../../store/slices/agents";
import { AgentWithHealth, removeAgent } from "../../store/slices/agents/client";
import { AgentDetailModal } from "./AgentDetailModal";
import AgentLandingPage from "./AgentLandingPage";
import {
  AgentSwitchDeploymentModal,
  AgentSwitchDeploymentModalType,
} from "./AgentSwitchDeploymentModal";
import { AgentUpgradeNotice } from "./AgentUpgradeNotice";
import { getLatestAgentRelease, isAgentOutdated } from "./common";

// we only export this so that it can be referenced by the tests
export const KEY_ROTATION_POLLING_INTERVAL = 10_000;

const { Link, Text } = Typography;
const { confirm } = Modal;

const ProgressModal = styled.div`
  position: fixed;
  z-index: 10;
  background: ${colors.WHITE};
  bottom: 0;
  right: 16px;
  width: 380px;
  border: 1px solid ${colors.GREY_100};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none;
  font-size: 12px;
  box-shadow:
    0px 0px 1px 0px rgba(34, 39, 47, 0.32),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 1px 3px 0px rgba(34, 39, 47, 0.12);
`;

const StyledTagsCellWrapper = styled.div`
  & > div {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const FiltersRow = styled(Row)`
  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.GREY_600};
    font-weight: 500;
    font-size: 12px;
  }
`;

function showRemoveAgentConfirm(
  agentId: string,
  agentUrl: string,
  handleRemoveAgent: (agentId: string) => void,
) {
  confirm({
    title: `Are you sure you want to remove agent ${agentId} with agent url ${agentUrl}?`,
    icon: <></>,
    okText: "Remove",
    okType: "danger",
    onOk() {
      handleRemoveAgent(agentId);
    },
    className: `${ConfirmModalClass}`,
  });
}

const renderTags = (
  tags: string | string[],
  tagsColorAssignment: TagsColorAssignment,
) => {
  const tagsArr = typeof tags === "string" ? tags.split(",") : tags;
  return (
    <StyledTagsCellWrapper>
      <TagsCell
        isHidden={false}
        cellProperties={{}}
        tagValues={tagsArr}
        compactMode={"VERY_SHORT"}
        tagsColorAssignment={tagsColorAssignment}
        maxWidth={400}
      />
    </StyledTagsCellWrapper>
  );
};

function getSigningStatusTitle(status: KeyRotationStatus) {
  switch (status) {
    case KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS:
      return "Signing resources";
    case KeyRotationStatus.KEY_ROTATION_STATUS_COMPLETED:
      return "Signing complete";
    case KeyRotationStatus.KEY_ROTATION_STATUS_FAILED:
      return "Signing failed";
    case KeyRotationStatus.KEY_ROTATION_STATUS_CANCELED:
      return "Signing canceled";
    default:
      return "Signing resources";
  }
}

function getSigningStatusIcon(status: KeyRotationStatus) {
  switch (status) {
    case KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS:
      return <LoadingIndicator useWhiteBackground={true} />;
    case KeyRotationStatus.KEY_ROTATION_STATUS_COMPLETED:
      return <CheckCircle color={colors.ACCENT_GREEN} />;
    case KeyRotationStatus.KEY_ROTATION_STATUS_FAILED:
      return <ErrorCircle color={colors.DANGER} />;
    case KeyRotationStatus.KEY_ROTATION_STATUS_CANCELED:
      return <ErrorCircle color={colors.DANGER} />;
    default:
      return <LoadingIndicator useWhiteBackground={true} />;
  }
}

function getSigningStatusMessage(keyRotationState: KeyRotationWithCounts) {
  const { status, resourcesCompleted, resourcesTotal } = keyRotationState;
  const formattedResourcesCompleted = (
    resourcesCompleted ?? 0
  ).toLocaleString();
  const formattedResourcesTotal = (resourcesTotal ?? 0).toLocaleString();
  switch (status) {
    case KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS:
      return `${formattedResourcesCompleted}/${formattedResourcesTotal} resources signed`;
    case KeyRotationStatus.KEY_ROTATION_STATUS_COMPLETED:
      return `${formattedResourcesTotal} resources signed`;
    case KeyRotationStatus.KEY_ROTATION_STATUS_FAILED:
      return `${formattedResourcesCompleted}/${formattedResourcesTotal} resources signed`;
    case KeyRotationStatus.KEY_ROTATION_STATUS_CANCELED:
      return `${formattedResourcesCompleted}/${formattedResourcesTotal} resources signed`;
    default:
      return `${formattedResourcesCompleted}/${formattedResourcesTotal} resources signed`;
  }
}

const agentStatusToColor: Record<string, string> = {
  [AgentStatus.ACTIVE]: LegacyNamedColors.JUNGLE_GREEN,
  [AgentStatus.BROWSER_UNREACHABLE]: LegacyNamedColors.RED_LIGHT,
  [AgentStatus.DISCONNECTED]: LegacyNamedColors.GRAY,
};

const allStatusOptions = [
  {
    value: "all",
    label: "All Statuses",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "unreachable",
    label: "Unreachable",
  },
  {
    value: "disconnected",
    label: "Disconnected",
  },
];

const allEnvsOptions = [
  { value: "all", label: "All Tags" },
  { value: "*", label: "*" },
  { value: "staging", label: "staging" },
  { value: "production", label: "production" },
];

const allVersionsOptions = [
  { value: "all", label: "All Versions" },
  { value: "latest", label: "Latest" },
  { value: "outdated", label: "Outdated" },
];

const Agents = () => {
  moment.locale("en", {
    longDateFormat: {
      LT: "HH:mm:ss",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
  });

  const organizations = useSelector(selectOrganizations);
  const agentWithHealths = useSelector(selectOnPremAgentWithHealths);
  const agents = agentWithHealths.map(
    (agentWithHealth) => agentWithHealth.agent,
  );
  const allTagsOptions = useMemo(() => {
    const allTags = new Set<string>();
    agents.forEach((agent) => {
      if (agent.tags?.profile?.length > 0) {
        agent.tags?.profile?.forEach((profile) => {
          allTags.add(profile);
        });
      } else {
        allTags.add(agent.environment);
      }
    });
    return Array.from(allTags)
      .sort((a, b) =>
        a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      )
      .map((tag) => ({
        key: tag,
        value: tag,
        label: tag,
      }));
  }, [agents]);
  const allKeyOptions = useMemo(() => {
    const allKeys = new Set<string>();
    agents.forEach((agent) => {
      if (agent.verificationKeys) {
        Object.keys(agent.verificationKeys).forEach((key) => allKeys.add(key));
      }
    });
    return Array.from(allKeys)
      .sort((a, b) =>
        a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      )
      .map((tag) => ({
        key: tag,
        value: tag,
        label: tag,
      }));
  }, [agents]);

  const organizationId = useMemo(() => {
    return Object.keys(organizations)[0];
  }, [organizations]);
  const organization = useMemo(() => {
    return Object.values(organizations)[0];
  }, [organizations]);

  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);

  const tagsColorAssignment = useMemo(() => {
    const mapping: Record<string, number> = {};
    allTagsOptions?.forEach((tagOption, index) => {
      mapping[sanitizeCellValue(tagOption?.value)] = index;
    });
    return {
      uniqueTagsCount: allTagsOptions.length,
      mapping,
    };
  }, [allTagsOptions]);

  const keyTagsColorAssignment = useMemo(() => {
    const mapping: Record<string, number> = {};
    allKeyOptions?.forEach((tagOption, index) => {
      mapping[sanitizeCellValue(tagOption?.value)] = index;
    });
    return {
      uniqueTagsCount: allKeyOptions.length,
      mapping,
    };
  }, [allKeyOptions]);

  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isOnPremInUse = useMemo(() => {
    return organization.agentType === AgentType.ONPREMISE;
  }, [organization.agentType]);
  const [latestOpaVersion, setLatestOpaVersion] = useState("");
  const [showUpgradeNotice, setShowUpgradeNotice] = useState(false);
  const [selectedAgentHealthInTable, setSelectedAgentHealthInTable] = useState<
    AgentWithHealth | undefined
  >(undefined);

  useEffect(() => {
    if (isEmpty(latestOpaVersion)) {
      getLatestAgentRelease().then((version) => setLatestOpaVersion(version));
    }
  }, [latestOpaVersion, agents]);

  // Check that at least one OPA is outdated
  useEffect(() => {
    if (!isEmpty(latestOpaVersion)) {
      for (const agent of agents) {
        if (isAgentOutdated(agent.versionExternal, latestOpaVersion)) {
          setShowUpgradeNotice(true);
          return;
        }
      }
      setShowUpgradeNotice(false);
    }
  }, [latestOpaVersion, agents]);

  const [initializeUserSaga] = useSaga(initializeUser);
  const [getAgents] = useSaga(getAgentsSaga);
  useEffect(() => {
    getAgents({ organization, probeAll: true });
  }, [organization, getAgents]);

  const navigate = useNavigate();
  const viewDeploymentInstructions = () => {
    navigate({
      pathname: AGENTS_DEPLOYMENT_INSTRUCTIONS_URL,
    });
  };

  const handleCancel = () => {
    setIsDetailModalVisible(false);
  };

  const [showRemoveAgentInfo, setShowRemoveAgentInfo] =
    useState<boolean>(false);
  const handleRemoveAgent = useCallback(
    async (agentId: string) => {
      setIsLoading(true);
      await removeAgent(organizationId, agentId);
      await getAgents({ organization, probeAll: true });
      setShowRemoveAgentInfo(true);
      setIsLoading(false);
    },
    [getAgents, organization, organizationId],
  );

  const handleToggle = async (checked: boolean) => {
    const agentType = checked ? AgentType.ONPREMISE : AgentType.MULTITENANT;

    const toUpdate: Partial<Organization> = {
      id: organizationId,
      agentType: agentType,
    };
    await updateOrganization(toUpdate);
    await initializeUserSaga({
      accessMode: AccessMode.AUTH_USER,
      probeAllAgents: true,
    });
  };
  const enableResourceSigning = useFeatureFlag(Flag.ENABLE_RESOURCE_SIGNING);
  const [canManageAgents] = useAuthorizationCheck([MANAGE_AGENTS]);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        width: 200,
        title: <Text strong>Status</Text>,
        dataIndex: "status",
        key: "status",

        render: (value) => (
          <Tooltip title={"Click to see the agent detail."}>
            <span onClick={() => setIsDetailModalVisible(true)}>
              <Badge
                status="processing"
                color={agentStatusToColor[value as string]}
                text={value}
                style={{ cursor: "pointer" }}
              />
            </span>
          </Tooltip>
        ),
      },
      {
        width: 200,
        title: <Text strong>Tags</Text>,
        dataIndex: "tags",
        key: "tags",

        render: (value) => renderTags(value, tagsColorAssignment),
      },
      {
        width: 400,
        title: <Text strong>Host URL</Text>,
        dataIndex: "url",
        key: "url",

        render: (value) => value ?? "-",
      },
      {
        width: 200,
        title: <Text strong>Version</Text>,
        dataIndex: "versionExternal",
        key: "versionExternal",

        render: (value) => {
          return isAgentOutdated(value ?? "", latestOpaVersion) ? (
            <Tooltip title={<AgentUpgradeNotice version={latestOpaVersion} />}>
              {value} <WarningTwoTone />
            </Tooltip>
          ) : (
            value
          );
        },
      },
      ...(enableResourceSigning
        ? [
            {
              width: 200,
              title: <Text strong>Verification Key Ids</Text>,
              dataIndex: "verificationKeys",
              key: "verificationKeys",
              render: (
                value: Record<string, SignatureVerificationKey> | undefined,
              ) => {
                if (!value) {
                  return "";
                }
                return renderTags(Object.keys(value), keyTagsColorAssignment);
              },
            },
            {
              width: 200,
              title: <Text strong>Signing Key Id</Text>,
              dataIndex: "signingKeyId",
              key: "signingKeyId",
              render: (value: string | undefined) => {
                if (!value) {
                  return "";
                }
                return value;
              },
            },
          ]
        : []),
      {
        width: 200,
        title: <Text strong>Last Activity</Text>,
        dataIndex: "updated",
        key: "updated",

        render: (value) => {
          return moment(value).calendar();
        },
      },
      ...(canManageAgents
        ? [
            {
              width: 120,
              title: (
                <Row justify="end">
                  <Text strong>Actions</Text>
                </Row>
              ),
              key: "actions",
              render: (
                _: any,
                record: { id: string; url: string },
                idx: React.Key | null | undefined,
              ) => (
                <Row justify="end">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="remove"
                          onClick={(e) => {
                            showRemoveAgentConfirm(
                              record.id,
                              record.url,
                              handleRemoveAgent,
                            );
                          }}
                        >
                          Remove this agent
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <EllipsisOutlined
                      rotate={90}
                      style={{ paddingLeft: 48 }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Dropdown>
                </Row>
              ),
            },
          ]
        : []),
    ],
    [
      canManageAgents,
      latestOpaVersion,
      tagsColorAssignment,
      keyTagsColorAssignment,
      handleRemoveAgent,
      enableResourceSigning,
    ],
  );

  const hasActiveAgent = useMemo(() => {
    return agents.find((agent) => agent.status === AgentStatus.ACTIVE);
  }, [agents]);

  const [agentDeploymentSwitched, setAgentDeploymentSwitched] =
    useState<boolean>(false);
  const showRunningOnCloudAlert = useMemo(() => {
    return (
      !isOnPremInUse &&
      agents.find((agent) => agent.status === AgentStatus.ACTIVE)
    );
  }, [isOnPremInUse, agents]);

  const showRunningOnPremInfo = useMemo(() => {
    return (
      agentDeploymentSwitched &&
      isOnPremInUse &&
      agents.find((agent) => agent.status === AgentStatus.ACTIVE)
    );
  }, [isOnPremInUse, agents, agentDeploymentSwitched]);

  const showDisconnectedError = useMemo(() => {
    return (
      isOnPremInUse &&
      !agents.find((agent) => agent.status === AgentStatus.ACTIVE)
    );
  }, [isOnPremInUse, agents]);

  const showAlert = useMemo(() => {
    if (showRunningOnCloudAlert) {
      return (
        <Alert
          message={
            <>
              You have active agents but are still using{" "}
              <Text strong>Superblocks Cloud</Text>. Switch to{" "}
              <Text strong>On-Premise Deployment</Text> by click the deployment
              method dropdown below.
            </>
          }
          type="warning"
          showIcon
        />
      );
    } else if (showRunningOnPremInfo) {
      return (
        <Alert
          message="Changed Superblocks deploy mode to On-Premise."
          type="success"
          showIcon
        />
      );
    } else if (showRemoveAgentInfo) {
      return (
        <Alert message="Agent successfully removed." type="success" showIcon />
      );
    } else if (showDisconnectedError) {
      return (
        <Alert
          message={
            <>
              All agents have <Text strong>Disconnected</Text> from Superblocks.{" "}
              Either click <Text strong>Add Agent</Text> to deploy a new agent,{" "}
              or switch from <Text strong>On-Premise</Text> to{" "}
              <Text strong>Cloud Deployment</Text>.
            </>
          }
          type="error"
          icon={<ExclamationCircleFilled />}
          showIcon
        />
      );
    } else if (showUpgradeNotice) {
      return (
        <Alert
          message={<AgentUpgradeNotice version={latestOpaVersion} />}
          type="info"
          icon={<ExclamationCircleFilled />}
          showIcon
        />
      );
    } else {
      return <></>;
    }
  }, [
    showUpgradeNotice,
    showRemoveAgentInfo,
    showRunningOnCloudAlert,
    showRunningOnPremInfo,
    showDisconnectedError,
    latestOpaVersion,
  ]);

  const [isAgentSwitchModalVisible, setIsAgentSwitchModalVisible] =
    useState(false);
  const [agentSwitchModalType, setAgentSwitchModalType] = useState(
    isOnPremInUse
      ? AgentSwitchDeploymentModalType.TO_OPA
      : AgentSwitchDeploymentModalType.TO_CLOUD,
  );
  const cancelAgentSwitchModal = () => {
    setIsAgentSwitchModalVisible(false);
  };
  const confirmAgentSwitchModal = () => {
    setIsAgentSwitchModalVisible(false);
    handleToggle(!isOnPremInUse);
    setAgentDeploymentSwitched(true);
  };
  const switchAgentDeployment = (modalType: AgentSwitchDeploymentModalType) => {
    if (modalType === AgentSwitchDeploymentModalType.TO_CLOUD) {
      setIsAgentSwitchModalVisible(true);
      setAgentSwitchModalType(AgentSwitchDeploymentModalType.TO_CLOUD);
    } else {
      if (hasActiveAgent) {
        setIsAgentSwitchModalVisible(true);
        setAgentSwitchModalType(AgentSwitchDeploymentModalType.TO_OPA);
      } else {
        setIsAgentSwitchModalVisible(true);
        setAgentSwitchModalType(
          AgentSwitchDeploymentModalType.TO_OPA_NO_AGENTS,
        );
      }
    }
  };

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  // status
  const statusFromUrl = searchParams.get("status");
  const validStatusFromUrl =
    allStatusOptions.find((option) => option?.value === statusFromUrl)?.value ??
    "all";
  const [selectedStatus, setSelectedStatus] =
    useState<string>(validStatusFromUrl);
  const filterAgentByStatus = (status: string) => {
    setSelectedStatus(status);
  };

  // tags single
  const tagFromUrl = searchParams.get("tag");
  const validTagFromUrl =
    allTagsOptions.find((option) => option?.value === tagFromUrl)?.value ??
    "all";
  const [selectedTag, setSelectedTag] = useState<string>(validTagFromUrl);
  const filterAgentByTag = (tag: string) => {
    setSelectedTag(tag);
  };

  // tags multi select
  const allTagsFromUrl = searchParams.getAll("tag");
  const validTagsFromUrl = allTagsFromUrl.filter((tag) =>
    allTagsOptions.some((option) => option?.value === tag),
  );
  const [selectedTags, setSelectedTags] = useState<string[]>(validTagsFromUrl);
  const filterAgentByTags = (tags: string[]) => {
    setSelectedTags(tags);
  };

  // version
  const versionFromUrl = searchParams.get("version");
  const validVersionFromUrl =
    allVersionsOptions.find((option) => option?.value === versionFromUrl)
      ?.value ?? "all";
  const [selectedVersion, setSelectedVersion] =
    useState<string>(validVersionFromUrl);
  const filterAgentByVersion = (tag: string) => {
    setSelectedVersion(tag);
  };

  // update url withouth reloading the page
  useEffect(() => {
    let allSearchParams = "";
    const statusParam =
      selectedStatus === "all" ? "" : `?status=${selectedStatus}`;
    allSearchParams = statusParam;

    let tagsQueryParams = enableProfiles
      ? selectedTags.join("&tag=")
      : selectedTag === "all"
        ? ""
        : selectedTag;
    if (tagsQueryParams) {
      tagsQueryParams = `tag=${tagsQueryParams}`;
      allSearchParams = `${
        allSearchParams ? `${allSearchParams}&` : "?"
      }${tagsQueryParams}`;
    }

    const versionParam =
      selectedVersion === "all" ? "" : `version=${selectedVersion}`;
    if (versionParam) {
      allSearchParams = `${
        allSearchParams ? `${allSearchParams}&` : "?"
      }${versionParam}`;
    }

    window.history.replaceState(
      null,
      "",
      `${window.location.origin}${window.location.pathname}${allSearchParams}`,
    );
  }, [
    enableProfiles,
    selectedStatus,
    selectedTag,
    selectedTags,
    selectedVersion,
  ]);

  const unselectAll = useCallback(() => {
    setSelectedStatus("all");
    setSelectedTag("");
    setSelectedTags([]);
    setSelectedVersion("all");
  }, []);

  const filterByTag = useCallback(
    (agent: Agent) => {
      return selectedTag === "all"
        ? true
        : agent.tags?.profile?.length > 0
          ? agent.tags?.profile?.includes(selectedTag)
          : agent.environment === selectedTag;
    },
    [selectedTag],
  );

  const filterByTags = useCallback(
    (agent: Agent) => {
      return isEmpty(selectedTags)
        ? true
        : selectedTags.some((tag) =>
            agent.tags?.profile?.length > 0
              ? agent.tags?.profile?.includes(tag)
              : !agent.tags?.profiles && agent.environment === tag,
          );
    },
    [selectedTags],
  );

  const filteredAgents = useMemo(() => {
    return agents
      .filter((agent) => {
        switch (selectedStatus) {
          case "active":
            return agent.status === AgentStatus.ACTIVE;
          case "unreachable":
            return agent.status === AgentStatus.BROWSER_UNREACHABLE;
          case "disconnected":
            return agent.status === AgentStatus.DISCONNECTED;
          default:
            return true;
        }
      })
      .filter(enableProfiles ? filterByTags : filterByTag)
      .filter((agent) => {
        const outdated = isAgentOutdated(
          agent.versionExternal,
          latestOpaVersion,
        );
        switch (selectedVersion) {
          case "latest":
            return !outdated;
          case "outdated":
            return outdated;
          default:
            return true;
        }
      })
      .map((agent) => ({
        ...agent,
        tags:
          agent.tags?.profile?.length > 0
            ? agent.tags?.profile?.join(",")
            : agent.environment,
      }));
  }, [
    agents,
    enableProfiles,
    filterByTags,
    filterByTag,
    selectedStatus,
    latestOpaVersion,
    selectedVersion,
  ]);

  const emptyText = useMemo(() => {
    return (
      <>
        No
        {selectedStatus !== "all" && (
          <>
            {" "}
            <Text type="secondary" strong>
              {selectedStatus}
            </Text>
          </>
        )}{" "}
        agents available
        {!isEmpty(selectedTags) && (
          <>
            {" with tags "}
            <Text type="secondary" strong>
              {enableProfiles ? selectedTags.join(", ") : selectedTag}
            </Text>
          </>
        )}
        {selectedVersion !== "all" && (
          <>
            {" that are running "}
            <Text type="secondary" strong>
              {selectedVersion}
            </Text>{" "}
            versions
          </>
        )}
        . <Link onClick={unselectAll}>Clear filter</Link>
      </>
    );
  }, [
    enableProfiles,
    selectedStatus,
    selectedTag,
    selectedTags,
    selectedVersion,
    unselectAll,
  ]);

  /* Key rotations */
  const [confirmRotationModalOpen, setConfirmRotationModalOpen] =
    useState(false);
  const [confirmCancelRotationModalOpen, setConfirmCancelRotationModalOpen] =
    useState(false);
  const [activeRotationJobId, setActiveRotationJobId] = useState<string>();
  const [keyRotationState, setKeyRotationState] =
    useState<KeyRotationWithCounts>();

  const [getKeyRotations] = useSaga(getKeyRotationsSaga);
  const [getKeyRotation] = useSaga(getKeyRotationSaga);
  const [startKeyRotation] = useSaga(startKeyRotationSaga);
  const [cancelKeyRotation] = useSaga(cancelKeyRotationSaga);
  useEffect(() => {
    // on page load, check if there is a rotation in progress and start tracking it if so
    if (isOnPremInUse && enableResourceSigning) {
      getKeyRotations({ onlyInProgress: true }).then((resp) => {
        setActiveRotationJobId(resp.keyRotations[0]?.id);
      });
    }
  }, [enableResourceSigning, getKeyRotations, isOnPremInUse]);

  const refreshKeyRotationState = useCallback(async () => {
    if (activeRotationJobId) {
      const keyRotation = await getKeyRotation({
        rotationJobId: activeRotationJobId,
      });
      setKeyRotationState(keyRotation);
      return keyRotation;
    }
  }, [activeRotationJobId, getKeyRotation]);

  useEffect(() => {
    if (enableResourceSigning && activeRotationJobId) {
      // If there is a rotation in progress, check the status every `KEY_ROTATION_POLLING_INTERVAL` ms
      let timeoutId: ReturnType<typeof setTimeout> | undefined;
      const loop = async () => {
        const keyRotation = await refreshKeyRotationState();
        if (
          keyRotation?.status ===
          KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS
        ) {
          // schedule the next refresh, but only after the current one completes, to ensure we don't have
          // a backlog of multiple requests in flight
          timeoutId = setTimeout(loop, KEY_ROTATION_POLLING_INTERVAL);
        }
      };
      // make the progrss dialog update immediately and kick off the polling loop
      loop();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [activeRotationJobId, enableResourceSigning, refreshKeyRotationState]);

  const [showKeyRotationInProgress, setShowKeyRotationInProgress] =
    useState(false);
  useEffect(() => {
    // if rotation is in progress, show the progress dialog
    if (
      keyRotationState?.status ===
      KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS
    ) {
      setShowKeyRotationInProgress(true);
    }
  }, [keyRotationState?.status]);

  const rotateKeys = useCallback(() => {
    setConfirmRotationModalOpen(false);
    startKeyRotation({}).then((resp) => {
      setActiveRotationJobId(resp?.id);
      setShowKeyRotationInProgress(true);
    });
  }, [startKeyRotation]);

  const cancelSigning = useCallback(() => {
    if (!activeRotationJobId) {
      return;
    }
    setConfirmCancelRotationModalOpen(false);
    cancelKeyRotation({ rotationId: activeRotationJobId }).then(() => {
      refreshKeyRotationState();
    });
  }, [activeRotationJobId, cancelKeyRotation, refreshKeyRotationState]);

  const rotationDisabledReason = useMemo(() => {
    if (!canManageAgents) {
      return "You do not have permission to rotate keys";
    }
    if (!orgIsOnPremise(organization)) {
      return "Resource signing is only available for on-premise deployments";
    }
    if (!hasActiveAgent) {
      return "There are no active agents";
    }
    // can't rotate if there is a rotation in progress
    if (
      keyRotationState?.status ===
      KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS
    ) {
      return "There is already a rotation in progress";
    }
    // only can do this if all signing keys are the same
    const baseKey = agents.find(
      (agent) => agent.status === AgentStatus.ACTIVE,
    )?.signingKeyId;
    const allSame = agents.every(
      (agent) =>
        agent.status !== AgentStatus.ACTIVE ||
        (agent.signingKeyId && agent.signingKeyId === baseKey),
    );
    if (!allSame) {
      return "All agents must use the same signing key before rotating";
    }
    return null;
  }, [
    agents,
    keyRotationState?.status,
    hasActiveAgent,
    organization,
    canManageAgents,
  ]);

  return (
    <PageWrapper pageName={AGENTS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        {isOnPremInUse || agents.length > 0 ? (
          <MainWrapper>
            <Row justify="center">
              <Col span={24}>
                <FullWidthSpace direction="vertical">
                  {showAlert}
                  <div
                    className={HeaderWrapper}
                    style={{ flexDirection: "row", marginBottom: 0 }}
                  >
                    <div className="page-header-title"> {AGENTS_TITLE} </div>
                    <Tooltip
                      title={
                        canManageAgents
                          ? undefined
                          : "You do not have permission to switch deployment mode"
                      }
                    >
                      <Select
                        style={{ width: 200 }}
                        value={
                          isOnPremInUse
                            ? AgentSwitchDeploymentModalType.TO_OPA
                            : AgentSwitchDeploymentModalType.TO_CLOUD
                        }
                        size="large"
                        onChange={switchAgentDeployment}
                        data-test="switch-agent-mode"
                        disabled={!canManageAgents}
                      >
                        <Select.Option
                          value={AgentSwitchDeploymentModalType.TO_CLOUD}
                          data-test={`dropdown-select-${AgentSwitchDeploymentModalType.TO_CLOUD}`}
                        >
                          Cloud Deployment
                        </Select.Option>
                        <Select.Option
                          value={AgentSwitchDeploymentModalType.TO_OPA}
                          data-test={`dropdown-select-${AgentSwitchDeploymentModalType.TO_OPA}`}
                        >
                          On-Premise Deployment
                        </Select.Option>
                      </Select>
                    </Tooltip>
                  </div>
                  <Row />
                  <Row
                    justify="start"
                    align="middle"
                    style={{ marginBottom: 12 }}
                  >
                    <Col span={12}></Col>
                    <Col span={12}>
                      <FiltersRow justify="end">
                        <Space size={20} align="end" direction="horizontal">
                          <Select
                            style={{ width: 140 }}
                            placeholder="All Statuses"
                            onChange={filterAgentByStatus}
                            value={selectedStatus}
                            options={allStatusOptions}
                          />
                          {enableProfiles ? (
                            <Select
                              mode="multiple"
                              allowClear
                              showArrow
                              style={{ width: 240 }}
                              placeholder="All Tags"
                              onChange={filterAgentByTags}
                              value={selectedTags}
                              options={allTagsOptions}
                              maxTagCount="responsive"
                            />
                          ) : (
                            <Select
                              style={{ width: 140 }}
                              placeholder="All Tags"
                              onChange={filterAgentByTag}
                              value={selectedTag}
                              options={allEnvsOptions}
                            />
                          )}
                          <Select
                            style={{ width: 140 }}
                            placeholder="All Versions"
                            onChange={filterAgentByVersion}
                            value={selectedVersion}
                            options={allVersionsOptions}
                          />

                          {enableResourceSigning && (
                            <Tooltip
                              title={
                                rotationDisabledReason != null
                                  ? rotationDisabledReason
                                  : "Sign resources"
                              }
                            >
                              <Button
                                type={"ghost"}
                                data-test="rotate-key-button"
                                style={{ padding: "8px 0px", width: 32 }}
                                onClick={() =>
                                  setConfirmRotationModalOpen(true)
                                }
                                disabled={
                                  rotationDisabledReason != null ||
                                  !canManageAgents
                                }
                              >
                                <RotateKeysIcon />
                              </Button>
                            </Tooltip>
                          )}
                          <Tooltip
                            title={
                              canManageAgents
                                ? undefined
                                : "You do not have permission to add agents"
                            }
                          >
                            <Button
                              type="primary"
                              onClick={viewDeploymentInstructions}
                              disabled={!canManageAgents}
                            >
                              Add Agent
                            </Button>
                          </Tooltip>
                        </Space>
                      </FiltersRow>
                    </Col>
                  </Row>
                  <Table
                    className={TableClass}
                    loading={isLoading}
                    rowKey={(record) => (record as any).id}
                    dataSource={filteredAgents}
                    columns={columns}
                    bordered={false}
                    locale={
                      agents.length > 0
                        ? {
                            emptyText: <>{emptyText}</>,
                          }
                        : {
                            emptyText: isOnPremInUse
                              ? "No On-Premise Agents available."
                              : "Your organization is using Superblocks cloud agents.",
                          }
                    }
                    pagination={false}
                    onRow={(record, rowIndex) => {
                      return {
                        onMouseEnter: (event) => {
                          const selectedAgent = record as Agent;
                          const agentId = selectedAgent.id;
                          setSelectedAgentHealthInTable(
                            agentWithHealths.find(
                              (agentWithHealth) =>
                                agentWithHealth.agent.id === agentId,
                            ),
                          );
                        },
                      };
                    }}
                  />
                </FullWidthSpace>
              </Col>
            </Row>
          </MainWrapper>
        ) : (
          <AgentLandingPage
            callToActionButtonText="View deployment instructions"
            onClickCallToAction={viewDeploymentInstructions}
            isDisabled={!canManageAgents}
          />
        )}
      </Layout>
      <Modal
        open={confirmRotationModalOpen}
        okText={<span data-test="start-signing">Start signing</span>}
        cancelText="Cancel"
        onOk={rotateKeys}
        onCancel={() => setConfirmRotationModalOpen(false)}
        title="Start signing resources"
      >
        <div>
          <p>
            Are you sure you want to start signing your Superblocks code?
            Resources will be uneditable for a few seconds.
          </p>
          <p>
            Resigning may take a while. Feel free to check back later for
            progress.
          </p>
        </div>
      </Modal>
      <Modal
        open={confirmCancelRotationModalOpen}
        okText={
          <span data-test="confirm-cancel-signing-button">Cancel signing</span>
        }
        cancelText="Continue signing"
        onOk={cancelSigning}
        onCancel={() => setConfirmCancelRotationModalOpen(false)}
        title="Cancel resource signing?"
      >
        <div>
          <p>
            All resources haven’t been signed with your new signing key yet. Are
            you sure you want to cancel?
          </p>
        </div>
      </Modal>
      {showKeyRotationInProgress &&
        keyRotationState &&
        enableResourceSigning && (
          <ProgressModal>
            <div
              style={{
                padding: "16px 16px 8px 16px",
                borderBottom: `1px solid ${colors.GREY_100}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              data-test="signing-status-title"
            >
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {getSigningStatusTitle(keyRotationState?.status)}
              </Typography.Title>
              {keyRotationState.status ===
              KeyRotationStatus.KEY_ROTATION_STATUS_IN_PROGRESS ? (
                <Tooltip title="Cancel signing">
                  <Button
                    type="text"
                    onClick={() => setConfirmCancelRotationModalOpen(true)}
                    style={{ padding: "4px 0px" }}
                    data-test="cancel-key-rotation-button"
                  >
                    <CloseIcon height={16} width={16} />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type="text"
                  onClick={() => setShowKeyRotationInProgress(false)}
                  style={{ padding: "4px 0px" }}
                  data-test="close-key-rotation-button"
                >
                  <CloseIcon height={16} width={16} />
                </Button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                color: colors.GREY_500,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  lineHeight: "18px",
                }}
              >
                {getSigningStatusIcon(keyRotationState?.status)}
                {getSigningStatusMessage(keyRotationState)}
              </div>
              {keyRotationState?.status ===
                KeyRotationStatus.KEY_ROTATION_STATUS_FAILED && (
                <Button
                  onClick={rotateKeys}
                  data-test="retry-key-rotation-button"
                >
                  Retry signing
                </Button>
              )}
            </div>
          </ProgressModal>
        )}
      {selectedAgentHealthInTable && (
        <AgentDetailModal
          agentWithHealth={selectedAgentHealthInTable}
          open={isDetailModalVisible}
          onCancel={handleCancel}
        />
      )}
      <AgentSwitchDeploymentModal
        open={isAgentSwitchModalVisible}
        modalType={agentSwitchModalType}
        onCancel={cancelAgentSwitchModal}
        onOk={confirmAgentSwitchModal}
      />
    </PageWrapper>
  );
};

export default Agents;
