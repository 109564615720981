import { Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import {
  DocsPage,
  DOCS_PAGE_URL,
  OPA_LATEST_RELEASE_PAGE_URL,
} from "legacy/constants/routes";

interface UpdateProps {
  version: string;
}

export const AgentUpgradeNotice = (props: UpdateProps) => {
  return (
    <>
      A newer version of the On-Premise Agent is available. Please{" "}
      <Typography.Link
        href={DOCS_PAGE_URL(DocsPage.ON_PREMISE_AGENT_USAGE)}
        target="_blank"
      >
        upgrade
      </Typography.Link>{" "}
      {/* In the event that the version isn't populated, we should still link to the deploy/upgrade docs */}
      to{" "}
      <Typography.Link href={OPA_LATEST_RELEASE_PAGE_URL} target="_blank">
        {isEmpty(props.version) ? "the latest version" : props.version}
      </Typography.Link>{" "}
      to get the latest features.
    </>
  );
};
