import { noop } from "lodash";
import React, { useMemo, Context, createContext } from "react";

type AiAssistantContextType = {
  activeEditorId: string | null;
  setActiveEditor: (editorId: string | null) => void;
};

export const AiAssistantContext: Context<AiAssistantContextType> =
  createContext<AiAssistantContextType>({
    activeEditorId: null,
    setActiveEditor: noop,
  });

export const AiAssistantContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeEditorId, setActiveEditorId] = React.useState<string | null>(
    null,
  );

  const value = useMemo(
    () => ({
      activeEditorId,
      setActiveEditor: (id: string | null) => {
        setActiveEditorId(id);
      },
    }),
    [activeEditorId, setActiveEditorId],
  );

  return (
    <AiAssistantContext.Provider value={value}>
      {children}
    </AiAssistantContext.Provider>
  );
};
