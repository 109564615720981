import { get, isArray, mergeWith } from "lodash";
import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { fastClone } from "utils/clone";
import dot from "utils/dot";
import { AutocompleteConfiguration } from "../CodeEditor/EditorConfig";
import { FormContext } from "./FormContext";
import useFormStateManager from "./useFormStateManager";

interface Props {
  autocompleteConfiguration: AutocompleteConfiguration;
  autocompleteAdditionalData?: Record<string, Record<string, unknown>>;
  children?: React.ReactNode;
  data: Record<string, unknown>;
  defaultValues?: Record<string, unknown>;
  onChange: (newData: Record<string, unknown>) => void;
  getValue: (path: string) => unknown;
  datasourceName?: string;
}

export interface FormRef {
  validate: () => boolean;
}

export const SBDynamicForm = forwardRef<FormRef, Props>(
  (
    {
      autocompleteConfiguration,
      autocompleteAdditionalData,
      data,
      defaultValues,
      onChange,
      children,
      getValue,
    },
    ref,
  ) => {
    const [validators, setValidators] = useState<
      Record<string, (value: unknown) => boolean>
    >({});
    const mergedData = useMemo(() => {
      const merged = mergeWith(
        dot.object(fastClone(defaultValues as any) ?? {}),
        dot.object(fastClone(data)),
        (objValue: unknown, srcValue: unknown) => {
          if (isArray(objValue)) {
            return srcValue;
          }
        },
      );
      return dot.dot(merged);
    }, [data, defaultValues]);
    const [subscribe, changeHandler] = useFormStateManager({
      data: mergedData,
      onChange,
    });

    const registerValidation = useCallback(
      (path: string, validator: (value: unknown) => boolean) => {
        setValidators((validators) => {
          validators[path] = validator;
          return validators;
        });
      },
      [setValidators],
    );

    const unregisterValidation = useCallback(
      (path: string) => {
        setValidators((validators) => {
          delete validators[path];
          return validators;
        });
      },
      [setValidators],
    );

    useImperativeHandle(
      ref,
      () => ({
        validate() {
          let hasError = false;
          Object.entries(validators).forEach(([path, validator]) => {
            if (validator(get(mergedData, path))) {
              hasError = true;
            }
          });
          return !hasError;
        },
      }),
      [mergedData, validators],
    );

    const formInstance = useMemo(() => {
      return {
        autocompleteConfiguration,
        autocompleteAdditionalData,
        subscribe,
        onChange: changeHandler,
        registerValidation,
        unregisterValidation,
        getValue,
      };
    }, [
      autocompleteConfiguration,
      autocompleteAdditionalData,
      subscribe,
      changeHandler,
      registerValidation,
      unregisterValidation,
      getValue,
    ]);

    return (
      <FormContext.Provider value={formInstance}>
        {children}
      </FormContext.Provider>
    );
  },
);
SBDynamicForm.displayName = "SBDynamicForm";
