// eslint-disable-next-line react/jsx-filename-extension
import React from "react";

interface GitOnboardingContextProps {
  appFlobsInConfig?: any[];
  setAppFlobsInConfig?: (value: any[]) => void;
  protectDefaultBranch?: boolean;
  setProtectDefaultBranch?: (value: boolean) => void;
}

export const GitOnboardingContext =
  React.createContext<GitOnboardingContextProps>({});

export const GitOnboardingContextProvider = GitOnboardingContext.Provider;
