import { normalizeRoleName, RoleTypeEnum } from "@superblocksteam/shared";
import { Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { useCreateRoleMutation } from "store/slices/reduxApi/rbac";
import { USER_FRIENDLY_ROLE_NAMES } from "./constants";

type ErrorType = {
  name: string | null;
  description: string | null;
  key: string | null;
  modal: string | null;
};

const NO_ERRORS: ErrorType = {
  name: null,
  description: null,
  key: null,
  modal: null,
};

export const CreateRoleModal = ({
  onClose,
  roleType,
}: {
  onClose: () => void;
  roleType: RoleTypeEnum;
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const organizationId = useSelector(getCurrentOrgId);
  const [errors, setErrors] = useState<ErrorType>(NO_ERRORS);

  const [createRole, { isLoading: isSaving }] = useCreateRoleMutation();

  const handleCreateRole = useCallback(async () => {
    const errors = { ...NO_ERRORS };
    let hasError = false;
    if (!name) {
      errors.name = "Name is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(errors);
      return;
    }
    setErrors(NO_ERRORS);
    try {
      await createRole({
        role: {
          name,
          description,
          organizationId,
          type: roleType,
          permissions: [],
        },
        organizationId,
      }).unwrap();
      onClose();
    } catch (error: any) {
      setErrors({
        ...NO_ERRORS,
        modal: error?.message ?? error?.error ?? "Failed to create role",
      });
    }
  }, [name, description, roleType, createRole, onClose, organizationId]);

  return (
    <Modal
      title={
        roleType === RoleTypeEnum.ORGANIZATION
          ? "Create new role"
          : `Create new ${USER_FRIENDLY_ROLE_NAMES[roleType]} role`
      }
      open={true}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper}>
        <div className={FormWrapper}>
          <FormItem label="Name" required={true} error={errors.name}>
            <Input
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormItem>
          <FormItem
            label="Key"
            required={true}
            error={errors.key}
            subText="Unique identifier of the role to use in your code."
          >
            <Input
              disabled
              placeholder="Enter key"
              value={normalizeRoleName(name)}
            />
          </FormItem>
          <FormItem label="Description" error={errors.description}>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </FormItem>
          <FormItem error={errors.modal} hidden={!errors.modal} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            data-test="cancel-create-role-button"
            onClick={onClose}
            loading={isSaving}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="primary"
            onClick={handleCreateRole}
            data-test="create-role-button"
            loading={isSaving}
            disabled={!name}
          >
            Create
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
