import { PageDSL8, WidgetProps8 } from "@superblocksteam/shared";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 2. Never update a migration. Instead, create a new one.
 ***/

// THESE ARE THE WIDGET TYPES AT THE TIME OF WRITING THIS MIGRATION
// incase they change in the future we don't want to update the migration
enum WidgetTypes {
  CUSTOM_WIDGET = "CUSTOM_WIDGET",
  UNREGISTERED_CUSTOM_WIDGET = "UNREGISTERED_CUSTOM_WIDGET",
}

const removeContainerStyle = (page: PageDSL8) => {
  const updateWidgets = (widget: WidgetProps8) => {
    // We don't update custom widgets since users could have added containerStyle as a prop
    if (
      widget.type !== WidgetTypes.CUSTOM_WIDGET &&
      widget.type !== WidgetTypes.UNREGISTERED_CUSTOM_WIDGET
    ) {
      if ("containerStyle" in widget) {
        delete widget.containerStyle;
      }
    }

    if (widget.children) {
      widget.children.forEach(updateWidgets);
    }
  };

  updateWidgets(page);
  return page;
};

export default removeContainerStyle;
