import { useCallback, useEffect, useState } from "react";

export function usePageVisibility() {
  const [documentVisible, setDocumentVisible] = useState(true);
  const onVisibilityChange = useCallback(
    () => setDocumentVisible(!document.hidden),
    [setDocumentVisible],
  );
  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);
  return documentVisible;
}
