import { ApiNotificationConfiguration } from "@superblocksteam/shared";
import { ApiExecutionResponseDto } from "store/slices/apis/types";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import type { ExecutionResponse } from "store/slices/apisV2";

// TODO (control flow) need to connect all of these to v2 APIs

export const DEFAULT_API_NOTIFICATION_CONFIGURATION: ApiNotificationConfiguration =
  Object.freeze({
    onError: {
      enabled: false,
      includeError: true,
    },
  });

export function defaultNotifSuccessMessage(apiName: string): string {
  return `${apiName} completed`;
}

export function defaultNotifErrorMessage(
  apiName: string,
  stepName?: string,
): string {
  return stepName ? `${apiName} failed at ${stepName}` : `${apiName} failed`;
}

export function sendUINotification(
  apiResponse: ApiExecutionResponseDto | undefined,
) {
  if (!apiResponse) {
    return;
  }

  const apiName = apiResponse.apiName || apiResponse.apiId || "An API";

  const err = findError(apiResponse);
  if (err === undefined) {
    const onSuccessConfig =
      apiResponse.notificationConfig?.onSuccess ??
      DEFAULT_API_NOTIFICATION_CONFIGURATION.onSuccess;
    const defaultMessage = defaultNotifSuccessMessage(apiName);
    if (onSuccessConfig && onSuccessConfig.enabled) {
      sendSuccessUINotification({
        message: onSuccessConfig.customText ?? defaultMessage,
      });
    }
  } else {
    const onErrorConfig =
      apiResponse.notificationConfig?.onError ??
      DEFAULT_API_NOTIFICATION_CONFIGURATION.onError;
    const defaultMessage = defaultNotifErrorMessage(
      apiName,
      apiResponse.context?.errorContext?.actionName,
    );
    if (onErrorConfig && onErrorConfig.enabled) {
      sendErrorUINotification({
        message: onErrorConfig.customText ?? defaultMessage,
        description: onErrorConfig.includeError ? err : undefined,
        duration: 8, // keep errors on screen for a bit longer
      });
    }
  }
}

export const findError = (
  apiResponse?: ApiExecutionResponseDto,
): string | undefined => {
  if (!apiResponse?.context || !apiResponse.context.errorContext) {
    return undefined;
  }
  const context = apiResponse.context;
  return `Error in ${context.errorContext?.actionName}: ${context.error}`;
};

export const findV2Error = (
  apiResponse?: ExecutionResponse,
): string | undefined => {
  if (apiResponse && "systemError" in apiResponse) {
    // system errors are not errors that occurred within the API and thus are not reported
    // to error triggers or the data tree. This is also how it works for V1 APIs
    return undefined;
  }

  const firstUnhandledError = apiResponse?.errors?.find(
    (error) => !error?.handled,
  );

  if (!firstUnhandledError) {
    return undefined;
  }
  return `Error in API: ${firstUnhandledError?.message}`;
};
