import {
  MapBooleansTo,
  ValidateReduxPath,
  ValidateReduxPathItem,
} from "@superblocksteam/shared";
import { Checkbox, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { useAppSelector } from "store/helpers";
import { selectHasConnectedTokens } from "store/slices/datasources";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormText } from "../FormText";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";

type Props = DynamicFormItemProps & {
  mapBooleansTo?: MapBooleansTo;
  validateReduxPath?: ValidateReduxPath;
  integrationId: string;
  configurationId?: string;
};

const DynamicFormCheckbox = ({
  path,
  label,
  tooltip,
  readOnly,
  rules,
  mapBooleansTo,
  validateReduxPath,
  integrationId,
  configurationId,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const [validationMessage, setValidationMessage] = useState("");
  const changeHandler = useCallback(
    (e: CheckboxChangeEvent) => {
      const val = e.target.checked;
      if (mapBooleansTo) {
        context.onChange(path, mapBooleansTo[val ? "true" : "false"]);
      } else {
        context.onChange(path, !!val);
      }
    },
    [context, path, mapBooleansTo],
  );
  const [value, setValue] = useState<boolean | string>(
    mapBooleansTo ? mapBooleansTo.false : false,
  );

  const unmapValue = (
    value: string,
    map: MapBooleansTo,
  ): boolean | undefined => {
    for (const key in map) {
      if (map[key as keyof MapBooleansTo] === value) {
        return key === "true";
      }
    }
  };

  const hasConnectedTokens = useAppSelector((state) =>
    selectHasConnectedTokens(state, configurationId as string, integrationId),
  );
  const getValidateReduxPathRule = useCallback(
    (checkboxValue: boolean, validateReduxPathItem: ValidateReduxPathItem) => {
      const reduxPathValidator = (value: unknown) => {
        const unmappedValue = mapBooleansTo
          ? unmapValue(value as string, mapBooleansTo)
          : value;
        if (unmappedValue !== checkboxValue) {
          return;
        }
        let selectedValue;
        switch (validateReduxPathItem?.selector) {
          case "selectHasConnectedTokens":
            selectedValue = hasConnectedTokens;
            if (selectedValue !== validateReduxPathItem.validValue) {
              return Error(validateReduxPathItem.errorMessage);
            }
            break;
          default:
            return new Error("Invalid selector");
        }
      };
      return {
        validator: reduxPathValidator,
      };
    },
    [hasConnectedTokens, mapBooleansTo],
  );

  useEffect(() => {
    const validateReduxPathRules = [];
    for (const key in validateReduxPath) {
      const value = validateReduxPath[key as keyof ValidateReduxPath];
      validateReduxPathRules.push(
        getValidateReduxPathRule(
          key === "true",
          value as ValidateReduxPathItem,
        ),
      );
    }
    const allRules = [...(rules ?? []), ...validateReduxPathRules];
    ruleParser(path, allRules, context.registerValidation, (value) => {
      setValidationMessage(value);
    });
    return () => {
      context.unregisterValidation(path);
    };
  }, [
    path,
    context.registerValidation,
    context,
    getValidateReduxPathRule,
    rules,
    validateReduxPath,
  ]);

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) =>
      setValue(value as boolean | string),
    );

    return () => unsubscribe();
  }, [path, context]);

  if (otherProps.hidden === true) {
    return <></>;
  } else {
    return (
      <label>
        <Checkbox
          id={path}
          checked={
            mapBooleansTo ? value === mapBooleansTo.true : (value as boolean)
          }
          onChange={changeHandler}
          disabled={readOnly}
        />{" "}
        <FormText>{label}</FormText>
        <FormTooltip tooltip={tooltip} />
        <Typography.Text type="danger">{validationMessage}</Typography.Text>
      </label>
    );
  }
};

export default DynamicFormCheckbox;
