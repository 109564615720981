import React from "react";
import styled from "styled-components";

// the stand alone badge compared to antd Badge wrapper
// can use position absolute to flexibly manipulate position if needed on corner

type Props = {
  count?: number;
  maxCount?: number;
  className?: string;
  dataTest?: string;
};

const StyledCountBadge = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.DANGER};
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 10px;
  height: 16px;
  min-width: 16px;
`;

const CountBadge = (props: Props) => {
  if (props.count === 0 || props.count === undefined) return null;
  const maxCount = props.maxCount ?? 99;
  return (
    <StyledCountBadge className={props.className} data-test={props.dataTest}>
      {props.count <= maxCount ? props.count : `${maxCount}+`}
    </StyledCountBadge>
  );
};

export default CountBadge;
