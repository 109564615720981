export const isUserOnMac = () =>
  window.navigator.platform.toLowerCase().indexOf("mac") >= 0;

export const getShortcutString = (keys: string[]) =>
  isUserOnMac() ? `⌘${keys.join("")}` : `Ctrl+${keys.join("+")}`;

export const getApiEditorToggleShortcutString = () =>
  isUserOnMac() ? "⌘J" : "Ctrl+J";

export const getApiEditorMaxShortcutString = () =>
  isUserOnMac() ? "⌘U" : "Ctrl+U";

export const getEntityACShortcutString = () =>
  isUserOnMac() ? "⌘K" : "Ctrl+K";

export const getCommandPromptShortcutString = () =>
  isUserOnMac() ? "⌘E" : "Ctrl+E";

export const getRunShortcutString = () =>
  isUserOnMac() ? "⌘+Enter" : "Ctrl+Enter";

export const getOpenAiAssistantShortcutString = () =>
  isUserOnMac() ? "⌘I" : "Ctrl+I";

export const getRunSingleStepShortcutString = () =>
  isUserOnMac() ? "⌘+Shift+Enter" : "Ctrl+Shift+Enter";

export const getHardRefreshShortcutString = () =>
  isUserOnMac() ? "⌘+Shift+R" : "Ctrl+Shift+R";

export const getGroupIntoContainerShortcutString = () =>
  isUserOnMac() ? "⌘G" : "Ctrl+G";

export const getTogglePropsPaneShortcutString = () =>
  isUserOnMac() ? "⌘\\" : "Ctrl+\\";
