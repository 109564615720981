import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/helpers";
import { selectApiVersionLookup } from "store/slices/apisShared/selectors";
import { duplicateV2ApiToPageSaga } from "store/slices/apisV2/sagas/duplicateV2ApiToPage";

export default function useDuplicateApiToPage() {
  const dispatch = useAppDispatch();
  const apiVersionLookup = useSelector(selectApiVersionLookup);

  return useCallback(
    (apiId: string, pageId: string) => {
      const apiVersion = apiVersionLookup[apiId];
      if (apiVersion === "v2") {
        dispatch(duplicateV2ApiToPageSaga.start.create({ apiId, pageId }));
      }
    },
    [apiVersionLookup, dispatch],
  );
}
