import { Layout } from "antd";
import React from "react";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const StyledHeader = styleAsClass`
  &, &.ant-layout-header {
    background-color: ${colors.RED_500};
    padding: 0;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
  }
`;

const StyledMessage = styleAsClass`
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  height: 35px;
  line-height: 35px;
  a {
    color: white;
    text-decoration: underline;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
`;

const OrgWarningBanner = () => {
  const enableOrgMigrateToEUBanner = useFeatureFlag(
    Flag.ENABLE_ORG_MIGRATE_TO_EU_BANNER,
  );
  if (
    enableOrgMigrateToEUBanner &&
    window.location.host !== "eu.superblocks.com"
  )
    return (
      <Layout.Header className={StyledHeader} data-test="org-warning">
        <div className={StyledMessage}>
          Your organization has moved to Superblocks EU. No more changes should
          be made in this region. To continue development{" "}
          <a
            href="https://eu.superblocks.com/"
            target="_blank"
            rel="noreferrer"
          >
            log in to eu.superblocks.com
          </a>
        </div>
      </Layout.Header>
    );
  return null;
};

export default OrgWarningBanner;
