import { Modal, Space, Typography } from "antd";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import SbText from "components/ui/SbText";
import { closePurchasedModal } from "legacy/actions/modalActions";
import { getModalState } from "legacy/selectors/modalSelectors";
import { useAppDispatch } from "store/helpers";

const PurchasedModal = () => {
  const modalState = useSelector(getModalState);
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    dispatch(closePurchasedModal());
  }, [dispatch]);

  return (
    <>
      <Modal
        title={
          <Space size={6}>
            <Typography.Text>{`🎉 Upgrade Successful`}</Typography.Text>
          </Space>
        }
        centered
        open={modalState.showPurchasedModal !== null}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
      >
        <SbText>{`You're all set! Your plan is upgraded to ${modalState.showPurchasedModal}.`}</SbText>
      </Modal>
    </>
  );
};

export default PurchasedModal;
