import type { AutoHeightWidget } from "./Widget";

function hasXOverlap(
  currentWidget: AutoHeightWidget,
  widgetAbove: AutoHeightWidget,
) {
  return (
    currentWidget.x < widgetAbove.x + widgetAbove.width &&
    currentWidget.x + currentWidget.width > widgetAbove.x
  );
}

/**
 * takes an array of `Widget` objects and creates a map where each
 * `Widget` is mapped to a set of `Widget` objects that are directly above it, removing any indirect
 * relationships.
 * @param {AutoHeightWidget[]} widgets - An array of Widget objects.
 * @returns The function `createAboveMap` returns an object that maps widget IDs to an array of widgets
 * that are directly above them.
 */
export function createAboveMap(widgets: AutoHeightWidget[]) {
  const widgetAboveMap = new Map<string, Set<AutoHeightWidget>>();

  widgets.forEach((widget, i) => {
    const widgetsAbove = new Set<AutoHeightWidget>();
    widgetAboveMap.set(widget.id, widgetsAbove);

    for (let j = 0; j < i; j++) {
      const widgetAbove = widgets[j];
      if (hasXOverlap(widget, widgetAbove)) {
        widgetsAbove.add(widgetAbove);

        // Remove any widgets that a widget above is above
        // We only want to know about the direct widgets above
        widgetAboveMap
          .get(widgetAbove.id)
          ?.forEach((w) => widgetsAbove.delete(w));
      }
    }
  });

  return widgetAboveMap;
}
