import { InputDataType } from "@superblocksteam/shared";
import { Input, InputNumber } from "antd";
import React, { useCallback, useState, ChangeEvent } from "react";
import styled from "styled-components";

const StyledInput = styled(Input)`
  font-size: ${(props) => props.theme.text.sizes.small};
`;
const StyledPassword = styled(Input.Password)`
  font-size: ${(props) => props.theme.text.sizes.small};
`;
const StyledInputNumber = styled(InputNumber)`
  font-size: ${(props) => props.theme.text.sizes.small};
`;

interface InputElementProps {
  placeholder?: string;
  dataType?: InputDataType;
  value?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
}

const InputElement = ({
  onChange,
  value,
  placeholder,
  dataType,
  ...otherProps
}: InputElementProps) => {
  const [internalValue, setValue] = useState(value);

  const extractValue = (
    e: ChangeEvent<HTMLInputElement> | string | number | null,
  ): string => {
    if (typeof e === "string" || typeof e === "number") {
      return e.toString();
    }
    return e?.target.value ?? "";
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | string | number | null) => {
      if (onChange) {
        onChange(e);
      }
      setValue(extractValue(e));
    },
    [onChange],
  );

  switch (dataType) {
    case InputDataType.PASSWORD:
      return (
        <StyledPassword
          onChange={handleChange}
          value={internalValue}
          placeholder={placeholder}
          {...otherProps}
        />
      );
    case InputDataType.NUMBER:
      return (
        <StyledInputNumber
          onChange={handleChange}
          value={internalValue}
          placeholder={placeholder}
          {...otherProps}
        />
      );
    default:
      return (
        <StyledInput
          onChange={handleChange}
          value={internalValue}
          placeholder={placeholder}
          {...otherProps}
        />
      );
  }
};

export default InputElement;
