import {
  AwsOpenSearch,
  Datadog,
  DummyConfig,
  Elastic,
  FormComponentType,
  Kafka,
  Observability,
  ObservabilityVendorConfigurationKey,
  ObservabilityVendorType,
} from "@superblocksteam/shared";

import { STRING_VALIDATOR, ValidationResult } from "./validators";

type DropdownOption = {
  value: string;
  label: string;
};

type DropdownProperty = {
  defaultOption: DropdownOption;
  options: DropdownOption[];
};

type SinkTemplateField = {
  label: string;
  key: string;
  componentType: FormComponentType;
  required: boolean;
  sensitive: boolean;
  validator: (label: string, val: any) => ValidationResult;
  dropdownProperty?: DropdownProperty;
};

type SinkTemplate = {
  fields: SinkTemplateField[];
};

export class Sink<TConfig> {
  type: ObservabilityVendorType;
  key: ObservabilityVendorConfigurationKey;
  supported: boolean;
  label: string;
  iconLocation: string;
  template: SinkTemplate;
  // If this sink is supported or not. If not, only show in demo.
  persistentId?: string;
  configuration?: TConfig;

  constructor({
    type,
    key,
    supported,
    label,
    iconLocation,
    template,
  }: {
    type: ObservabilityVendorType;
    key: ObservabilityVendorConfigurationKey;
    supported: boolean;
    label: string;
    iconLocation: string;
    template: SinkTemplate;
  }) {
    this.type = type;
    this.key = key;
    this.supported = supported;
    this.label = label;
    this.iconLocation = iconLocation;
    this.template = template;
  }

  configure(configuration: any) {
    this.configuration = configuration;
  }

  setPersistentId(id: string) {
    this.persistentId = id;
  }

  getConfiguration(key: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.configuration ? this.configuration[key] : undefined;
  }

  static DATADOG_SINK_BUILDER(): Sink<Datadog> {
    return new Sink({
      type: ObservabilityVendorType.DATADOG,
      key: ObservabilityVendorConfigurationKey.DATADOG,
      supported: true,
      label: "Datadog",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/datadog.png",
      template: {
        fields: [
          {
            label: "Site",
            key: "site",
            componentType: FormComponentType.DROPDOWN,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
            dropdownProperty: {
              defaultOption: {
                value: "US",
                label: "US",
              },
              options: [
                {
                  value: "US",
                  label: "US",
                },
                {
                  value: "US3",
                  label: "US3",
                },
                {
                  value: "US5",
                  label: "US5",
                },
                {
                  value: "EU",
                  label: "EU",
                },
                {
                  value: "US1-FED",
                  label: "US1-FED",
                },
              ],
            },
          },
          {
            label: "Api Key",
            key: "apiKey",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static KAFKA_SINK_BUILDER(): Sink<Kafka> {
    return new Sink({
      type: ObservabilityVendorType.KAFKA,
      key: ObservabilityVendorConfigurationKey.KAFKA,
      supported: true,
      label: "Kafka",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/kafka.png",
      template: {
        fields: [
          {
            label: "Bootstrap Server",
            key: "bootstrap",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Topic",
            key: "topic",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "SASL Username",
            key: "username",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "SASL Password",
            key: "password",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static CONFLUENT_CLOUD_SINK_BUILDER(): Sink<Kafka> {
    return new Sink({
      type: ObservabilityVendorType.CONFLUENT_CLOUD,
      key: ObservabilityVendorConfigurationKey.KAFKA,
      supported: true,
      label: "Confluent Cloud",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/confluent-cloud.png",
      template: {
        fields: [
          {
            label: "Bootstrap Server",
            key: "bootstrap",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Topic",
            key: "topic",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Api Key",
            key: "username",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Api Secret",
            key: "password",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static ELASTIC_CLOUD_SINK_BUILDER(): Sink<Elastic> {
    return new Sink({
      type: ObservabilityVendorType.ELASTIC_CLOUD,
      key: ObservabilityVendorConfigurationKey.ELASTIC,
      supported: true,
      label: "Elastic Cloud",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/elastic-cloud.png",
      template: {
        fields: [
          {
            label: "Cloud ID",
            key: "cloudId",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Cloud Auth",
            key: "cloudAuth",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: (label: string, val: any): ValidationResult => {
              if (!(val as string).startsWith("elastic:")) {
                return {
                  ok: false,
                  error: `${label} must start with 'elastic:'.`,
                };
              }
              return STRING_VALIDATOR(label, val);
            },
          },
        ],
      },
    });
  }

  static AWS_OPEN_SEARCH_SINK_BUILDER(): Sink<AwsOpenSearch> {
    return new Sink({
      type: ObservabilityVendorType.AWS_OPEN_SEARCH,
      key: ObservabilityVendorConfigurationKey.AWS_OPEN_SEARCH,
      label: "AWS OpenSearch",
      supported: true,
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/open-search.jpeg",
      template: {
        fields: [
          {
            label: "Host",
            key: "host",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Port",
            key: "port",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Username",
            key: "userName",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Password",
            key: "password",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  // The sinks below are only used in sales demo
  static SPLUNK_SINK_BUILDER(): Sink<DummyConfig> {
    return new Sink({
      type: ObservabilityVendorType.SPLUNK,
      key: ObservabilityVendorConfigurationKey.SPLUNK,
      supported: false,
      label: "Splunk",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/splunk-453px+3.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static DYNATRACE_SINK_BUILDER(): Sink<DummyConfig> {
    return new Sink({
      type: ObservabilityVendorType.DYNATRACE,
      key: ObservabilityVendorConfigurationKey.DYNATRACE,
      supported: false,
      label: "Dynatrace",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Dynatrace.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static GRAFANA_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.GRAFANA,
      key: ObservabilityVendorConfigurationKey.GRAFANA,
      supported: false,
      label: "Grafana",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Grafana.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static APP_DYNAMICS_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.APP_DYNAMICS,
      key: ObservabilityVendorConfigurationKey.APP_DYNAMICS,
      supported: false,
      label: "App Dynamics",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/AppDynamics.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static NEW_RELIC_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.NEW_RELIC,
      key: ObservabilityVendorConfigurationKey.NEW_RELIC,
      supported: true,
      label: "New Relic",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/New+Relic.png",
      template: {
        fields: [
          {
            label: "API Key (Ingest License Key)",
            key: "apiKey",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Region",
            key: "region",
            componentType: FormComponentType.DROPDOWN,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
            dropdownProperty: {
              defaultOption: {
                value: "us",
                label: "US",
              },
              options: [
                {
                  value: "us",
                  label: "US",
                },
                {
                  value: "eu",
                  label: "EU",
                },
              ],
            },
          },
        ],
      },
    });
  }

  static CORALOGIX_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.CORALOGIX,
      key: ObservabilityVendorConfigurationKey.CORALOGIX,
      supported: true,
      label: "Coralogix",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Coralogix.png",
      template: {
        fields: [
          {
            label: "API Key (Send-Your-Data API key)",
            key: "apiKey",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
          {
            label: "Domain (region)",
            key: "endpoint",
            componentType: FormComponentType.DROPDOWN,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
            dropdownProperty: {
              defaultOption: {
                value: "ingress.coralogix.com",
                label: "coralogix.com (eu-west-1)",
              },
              options: [
                {
                  value: "ingress.coralogix.com",
                  label: "coralogix.com (eu-west-1)",
                },
                {
                  value: "ingress.coralogix.in",
                  label: "coralogix.in (ap-south1)",
                },
                {
                  value: "ingress.coralogix.us",
                  label: "coralogix.us (us-east2)",
                },
                {
                  value: "ingress.eu2.coralogix.com",
                  label: "eu2.coralogix.com (eu-north-1)",
                },
                {
                  value: "ingress.coralogixsg.com",
                  label: "coralogixsg.com (ap-southeast-1)",
                },
                {
                  value: "ingress.cx498-aws-us-west-2.coralogix.com",
                  label: "cx498.coralogix.com (us-west-2)",
                },
              ],
            },
          },
        ],
      },
    });
  }

  static SUMOLOGIC_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.SUMOLOGIC,
      key: ObservabilityVendorConfigurationKey.SUMOLOGIC,
      supported: true,
      label: "Sumo Logic",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/SumoLogic.png",
      template: {
        fields: [
          {
            label: "HTTP Logs & Metrics Endpoint",
            key: "endpoint",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: true,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static CHRONOSPHERE_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.CHRONOSPHERE,
      key: ObservabilityVendorConfigurationKey.CHRONOSPHERE,
      supported: false,
      label: "Chronosphere",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Chronosphere.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static PROMETHEUS_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.PROMETHEUS,
      key: ObservabilityVendorConfigurationKey.PROMETHEUS,
      supported: false,
      label: "Prometheus",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Prometheus.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static SYSDIG_SINK_BUILDER(): Sink<any> {
    return new Sink({
      type: ObservabilityVendorType.SYSDIG,
      key: ObservabilityVendorConfigurationKey.SYSDIG,
      supported: false,
      label: "SYSDIG",
      iconLocation:
        "https://superblocks.s3.us-west-2.amazonaws.com/img/observability/Sysdig.png",
      template: {
        fields: [
          {
            label: "Koala",
            key: "koala",
            componentType: FormComponentType.INPUT_TEXT,
            required: true,
            sensitive: false,
            validator: STRING_VALIDATOR,
          },
        ],
      },
    });
  }

  static BUILDERS = {
    [ObservabilityVendorType.DATADOG]: Sink.DATADOG_SINK_BUILDER,
    [ObservabilityVendorType.KAFKA]: Sink.KAFKA_SINK_BUILDER,
    [ObservabilityVendorType.CONFLUENT_CLOUD]:
      Sink.CONFLUENT_CLOUD_SINK_BUILDER,
    [ObservabilityVendorType.ELASTIC_CLOUD]: Sink.ELASTIC_CLOUD_SINK_BUILDER,
    [ObservabilityVendorType.AWS_OPEN_SEARCH]:
      Sink.AWS_OPEN_SEARCH_SINK_BUILDER,
    [ObservabilityVendorType.SPLUNK]: Sink.SPLUNK_SINK_BUILDER,
    [ObservabilityVendorType.DYNATRACE]: Sink.DYNATRACE_SINK_BUILDER,
    [ObservabilityVendorType.GRAFANA]: Sink.GRAFANA_SINK_BUILDER,
    [ObservabilityVendorType.APP_DYNAMICS]: Sink.APP_DYNAMICS_SINK_BUILDER,
    [ObservabilityVendorType.NEW_RELIC]: Sink.NEW_RELIC_SINK_BUILDER,
    [ObservabilityVendorType.CHRONOSPHERE]: Sink.CHRONOSPHERE_SINK_BUILDER,
    [ObservabilityVendorType.CORALOGIX]: Sink.CORALOGIX_SINK_BUILDER,
    [ObservabilityVendorType.SUMOLOGIC]: Sink.SUMOLOGIC_SINK_BUILDER,
    [ObservabilityVendorType.PROMETHEUS]: Sink.PROMETHEUS_SINK_BUILDER,
    [ObservabilityVendorType.SYSDIG]: Sink.SYSDIG_SINK_BUILDER,
  };

  static fromObservability(entity: Observability): Sink<any> {
    const sink = Sink.BUILDERS[entity.type]();
    sink.configure(entity.config[sink.key]);
    entity.id && sink.setPersistentId(entity.id);
    return sink;
  }
}
