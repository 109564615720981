import { Organization, Profile } from "@superblocksteam/shared";
import { AccessToken } from "../../../pages/AccessTokens/constants";
import { HttpMethod, callServer } from "../../utils/client";

export function getAllOrganizationsByUser(userId: string) {
  return callServer<Organization[]>({
    method: HttpMethod.Get,
    url: "v1/organizations/",
    query: { userId },
  });
}

export function getOrganizationAgentKeys(organizationId: string) {
  return callServer<AccessToken[]>({
    method: HttpMethod.Get,
    url: `v1/organizations/${organizationId}/agent-keys`,
  });
}

export function updateOrganization(body: Partial<Organization>) {
  return callServer<Partial<Organization>>({
    method: HttpMethod.Put,
    url: `v1/organizations/${body.id}`,
    body: body,
  });
}

export function updateProfile(
  organizationId: string,
  profileId: string,
  body: Partial<Profile>,
) {
  return callServer<Profile>({
    method: HttpMethod.Put,
    url: `v1/organizations/:organizationId/profiles/${profileId}`,
    params: { organizationId: organizationId },
    body: body,
  });
}

export function createProfile(organizationId: string, body: Partial<Profile>) {
  return callServer<Profile>({
    method: HttpMethod.Post,
    url: `v1/organizations/:organizationId/profiles`,
    params: { organizationId: organizationId },
    body: body,
  });
}

export function deleteProfile(organizationId: string, profileId: string) {
  return callServer<any>({
    method: HttpMethod.Delete,
    url: `v1/organizations/:organizationId/profiles/:profileId`,
    params: { organizationId: organizationId, profileId: profileId },
  });
}
