import { AccessMode, ThemeMode } from "@superblocksteam/shared";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import AuthProvider from "auth/auth0";
import TokenProvider from "auth/token";
import { usePrevious } from "hooks/ui";
import { updateEmbedPropMetaPropertiesWithOnChange } from "legacy/actions/embeddingActions";
import { setAccessMode } from "legacy/actions/userActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { APP_EMBED_URL } from "legacy/constants/routes";
import { getAllEmbedPropertiesAsArray } from "legacy/selectors/sagaSelectors";
import { useAppDispatch } from "store/helpers";
import { navigation } from "utils/polyfills/interceptNavigation";
import {
  IncomingMessage,
  OutgoingMessage,
  sendEventToEmbedder,
} from "./messages";

export const EmbedWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const knownEmbedProperties = useSelector(getAllEmbedPropertiesAsArray);

  const updateAllEmbedProperties = useCallback(
    (embedProperties: any) => {
      if (!knownEmbedProperties) {
        // the app hasnt been initialized yet, so we dont know which properties are expected
        // for now store them at the top-level of the redux state, and later they'll be moved to the embedMetaReducer
        dispatch({
          type: ReduxActionTypes.INIT_EMBED_PROPERTY_VALUES,
          payload: { values: embedProperties },
        });
      } else if (knownEmbedProperties.length) {
        const updates: Record<string, { value: any }> = {};
        for (const embedPropConfig of knownEmbedProperties) {
          const { id, name } = embedPropConfig;
          updates[id] = {
            value: embedProperties?.[name],
          };
        }
        dispatch(updateEmbedPropMetaPropertiesWithOnChange(updates));
      }
    },
    [knownEmbedProperties, dispatch],
  );

  const updateColorScheme = useCallback(
    (colorScheme?: string) => {
      if (colorScheme === "light" || colorScheme === "dark") {
        dispatch({
          type: ReduxActionTypes.SET_OVERRIDE_THEME_MODE,
          payload: {
            mode: colorScheme === "light" ? ThemeMode.LIGHT : ThemeMode.DARK,
          },
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const handleMessage = (event: any) => {
      // check if event.type is one of the expected IncomingMessage types
      if (
        !event ||
        typeof event !== "object" ||
        !event.data ||
        typeof event.data !== "object" ||
        !Object.values(IncomingMessage).includes(event?.data?.type)
      )
        return;
      // Assuming the message contains an action type and payload
      const { type, data } = event.data;
      switch (type) {
        case IncomingMessage.INITIALIZE_EMBED:
          setIsReady(true);
          if (data?.token) {
            TokenProvider.setToken(data.token, "embed");
          }
          sendEventToEmbedder({
            type: OutgoingMessage.INITIALIZED,
            data: {},
          });
          updateAllEmbedProperties(data?.properties);
          updateColorScheme(data?.colorScheme);
          break;
        case IncomingMessage.PROPERTY_CHANGE:
          updateAllEmbedProperties(data?.properties);
          break;
        case IncomingMessage.TOKEN_CHANGE:
          if (data?.token && typeof data.token === "string") {
            AuthProvider.setTokenGenerator(() => Promise.resolve(data.token));
            TokenProvider.setToken(event.data.token, "embed");
            dispatch(setAccessMode(AccessMode.EXTERNAL_USER));
          } else {
            AuthProvider.removeTokenGenerator();
            TokenProvider.clearToken();
            dispatch(setAccessMode(AccessMode.VISITOR));
          }
          break;
        case IncomingMessage.TRIGGER_EVENT:
          dispatch({
            type: ReduxActionTypes.TRIGGER_CUSTOM_EVENT,
            payload: {
              eventName: data?.eventName,
              eventPayload: data?.payload,
            },
          });
          break;
        default:
        case IncomingMessage.COLOR_SCHEME_CHANGE:
          updateColorScheme(data?.colorScheme);
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch, updateAllEmbedProperties, updateColorScheme]);

  useEffect(() => {
    navigation.addEventListener("navigate", (e: any) => {
      if (
        // <form> submission is rare, but will likely cause errors no matter how we handle it
        !e.formData &&
        // We are only trying to redirect when the user is escaping the iframe
        !e.destination.sameDocument &&
        // Redirects to blob: URLs are treated as inline downloads
        !e.downloadRequest
      ) {
        sendEventToEmbedder({
          type: OutgoingMessage.NAVIGATION,
          data: {
            url: e.destination.url,
          },
        });
      }
    });
  }, []);

  const prevPathname = usePrevious(location.pathname);
  useEffect(() => {
    if (isReady && prevPathname !== location.pathname && prevPathname) {
      const params = matchPath(APP_EMBED_URL, location.pathname);
      sendEventToEmbedder({
        type: OutgoingMessage.NAVIGATION,
        data: {
          url: `${window.location.origin}${location.pathname}`,
          appId: params?.params?.applicationId,
        },
      });
    }
  }, [location, prevPathname, isReady]);

  if (!isReady) return null;
  return <>{children}</>;
};
