import * as TogglePrimitive from "@radix-ui/react-toggle";
import { Space, SpaceProps } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

export interface ToggleSelectOption {
  value: string;
  display: string;
  imageUrl?: string;
}

interface ToggleSelectProps<Option extends ToggleSelectOption> {
  options: Option[];
  defaultSelectedOptions: Option[];
  isMultiselect: boolean;
  onChange: (options: Option[]) => void;
}

const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const StyledSpace = styled(
  ({ extraPadding, ...props }: SpaceProps & { extraPadding: boolean }) => (
    <Space {...props} />
  ),
)`
  white-space: "nowrap";
  padding-top: 10;
  padding-bottom: 10;
  // if large buttons are used (because there is an image in some button)
  // and if this particular option does not have an image
  // then add 8px of horizontal padding to it
  padding-left: ${({ extraPadding }) => 16 + 8 * Number(extraPadding)}px;
  padding-right: ${({ extraPadding }) => 16 + 8 * Number(extraPadding)}px;
`;

const StyledToggle = styled(
  ({
    hasImage,
    ...props
  }: TogglePrimitive.ToggleProps & { hasImage: boolean }) => (
    <TogglePrimitive.Root {...props} />
  ),
)`
  height: ${(props) => (props.hasImage ? "60px" : "44px")};

  border: 1px solid ${(props) => props.theme.colors.GREY_300};
  box-sizing: border-box;
  border-radius: 32px;
  background-color: white;
  color: #64676b;
  cursor: pointer;
  /* smoothly transition colors when the button is clicked */
  transition: 0.1s ease-out;
  transition-property: background-color, color, border-color, filter;
  &[data-state="on"] {
    background-color: rgba(124, 79, 248, 0.12);
    border-color: rgba(124, 79, 248, 0.32);
    color: #7c4ff8;
  }
  &:hover {
    border-color: #7c4ff8;
  }
  & ${StyledIcon} {
    filter: grayscale(1);
    opacity: 0.6;
  }
  &[data-state="on"] ${StyledIcon}, &:hover ${StyledIcon} {
    filter: none;
    opacity: 1;
  }
`;

export function ToggleSelect<Option extends ToggleSelectOption>({
  options,
  defaultSelectedOptions,
  isMultiselect,
  onChange,
}: ToggleSelectProps<Option>) {
  const uniqueOptions = useMemo(
    () => new Map(options.map((option) => [option.value, option])),
    [options],
  );
  // if there is at least one image, make all buttons big for consistency
  const hasImage = useMemo(
    () => [...uniqueOptions.values()].some((option) => !!option.imageUrl),
    [uniqueOptions],
  );
  const [pressedToggles, setPressedToggles] = useState(() =>
    defaultSelectedOptions.filter((opt) => uniqueOptions.has(opt.value)),
  );
  const onTogglePress = useCallback(
    (option: Option, pressed: boolean) => {
      if (pressed || !isMultiselect) {
        const newOptions = isMultiselect
          ? [...pressedToggles, option]
          : [option];
        setPressedToggles(newOptions);
        onChange(newOptions);
        return;
      }
      const removedOption = pressedToggles.filter((item) => option !== item);
      setPressedToggles(removedOption);
      onChange(removedOption);
    },
    [isMultiselect, onChange, pressedToggles],
  );

  return (
    <Space direction="horizontal" size={16} style={{ flexWrap: "wrap" }}>
      {[...uniqueOptions.values()].map((option) => (
        <StyledToggle
          key={option.value}
          pressed={pressedToggles.includes(option)}
          onPressedChange={(pressed) => onTogglePress(option, pressed)}
          hasImage={hasImage}
        >
          <StyledSpace
            direction="horizontal"
            size={10}
            // if large buttons are used (because there is an image in some button)
            // and if this particular option does not have an image
            // then add 8px of horizontal padding to it
            extraPadding={hasImage && !option.imageUrl}
          >
            {option.imageUrl && (
              <StyledIcon src={option.imageUrl} alt={option.value} />
            )}
            {option.display}
          </StyledSpace>
        </StyledToggle>
      ))}
    </Space>
  );
}
