import { ApplicationScope } from "@superblocksteam/shared";
import { DispatchProp } from "react-redux";
import { Store } from "redux";
import { AdditionalScopeProps } from "autocomplete/types";
import {
  ErrorTypes,
  SUPPORTED_FORMATTER_TYPE,
  SyntaxType,
} from "code-formatting/constants";
import {
  AiContextMode,
  KeyBindings,
} from "legacy/constants/EditorPreferencesConstants";
import { CodeMirrorState } from "legacy/pages/Editor/Codeium/codemirrorInject";
import { FormattingStatus } from "store/slices/apisV2";
import { EvaluatedValuePopupProps } from "./EvaluatedValuePopup";
import type {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
  AutocompleteConfiguration,
} from "./EditorConfig";
import type { QueryEditorRouteParams } from "legacy/constants/routes";
import type { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import type { DatasourceMeta } from "store/slices/datasources/slice";
import type { AppState } from "store/types";
import type { ApiScope } from "utils/dataTree/scope";
import type { ApiError } from "utils/error/error";

export enum EditorLanguage {
  TEXT = "TEXT",
  TEXT_BINDING = "TEXT_BINDING",
  SQL = "SQL",
  SQL_BINDING = "SQL_BINDING",
  JSON = "JSON",
  JSON_BINDING = "JSON_BINDING",
  JAVASCRIPT = "JAVASCRIPT",
  PYTHON = "PYTHON",
}

export interface ReduxStateProps {
  dynamicData: DataTree;
  apiScope?: ApiScope;
  pageId?: string;
  appId?: string;
  currentScope: ApplicationScope;
  datasourceMetadata?: DatasourceMeta;
  combinedAdditionalDynamicData?: Record<string, Record<string, unknown>>;
  keyBindings: KeyBindings;
  languagesToFormat: SUPPORTED_FORMATTER_TYPE[];
  isNewCodeEditorFeatureFlagEnabled: boolean; // Enables code formatting, VIM bindings an new code theme
  // TODO(omar): remove this once we ship programmatic table edit
  isProgrammaticTableEnabled: boolean;
}

export type EditorStyleProps = {
  borderLess?: boolean;
  className?: string;
  disabled?: boolean;
  evaluatedValue?: any;
  expected?: string;
  monospace?: boolean;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  fontSize?: string;
  link?: string;
  leftIcon?: React.ReactNode;
  leftImage?: string;
  placeholder?: string;
  rightIcon?: React.ReactNode;
  showLineNumbers?: boolean;
  isExpression?: boolean;
  showShortcutMenu?: boolean;
  exampleData?: string;
  docLink?: string;
  validationError?: string;
  showValidationErrorEvenWhenEmpty?: boolean;
  apiErrors?: ApiError[];
  noScroll?: boolean;
  autoFocus?: boolean;
};

export type EditorConfigProps = {
  theme: EditorTheme;
  mode: EditorModes;
  syntax?: SyntaxType; // more granular than EditorModes. Used in formatting
  tabBehaviour: TabBehaviour;
  size: EditorSize;
  enableSearch?: boolean;
  expandIntoTabBtn?: React.ReactChild | null;
  noNewlines?: boolean;
  lineWrapping?: boolean;
  maintainSelectionOnBlur?: boolean;
  autoScroll?: boolean;
  disableBindings?: boolean;
  disableTabIndent?: boolean;
  showJsExprModeIndicator?: boolean;
  isInPropertyPanel?: boolean;
  disableEvaluatedValuePopover?: boolean;
  shouldOpenIconSelectorForIconBindings?: boolean;
  // Optional, primarily used for widgets/statevars but not for workflows/jobs
  dataTreePath?: string;
  dataTreeScope?: ApplicationScope;
};

type EditorFieldProps = {
  input: Omit<Partial<any>, "checked">;
  subtitle?: string;
};

export type PresetOption = {
  value: string;
  doc?: string;
  type?: string;
};

export type EditorExtraProps = {
  "data-test"?: string;
  autocompleteConfiguration: AutocompleteConfiguration;
  codeMirrorState?: CodeMirrorState;
  aiContextMode?: AiContextMode;
  additionalDynamicData?: Record<string, Record<string, unknown>>;
  promptMessage?: React.ReactNode | string;
  aiMenuOpen?: boolean;
  isIconSelectorOpen?: boolean;
  // compared to input onBlur
  // this is fired only when the user genuinely blurs the editor
  // and not programmatically
  onEditorBlur?: (value: string) => void;
  onEditorFocus?: (cm: CodeMirror.Editor) => void;
  onEditorWillUnmount?: (value: string) => void;
  onSubmit?: (value: string) => void;
  evaluatedValuePopupProps?: Partial<
    Pick<
      EvaluatedValuePopupProps,
      "showValueOnly" | "placement" | "offset" | "getWrapperElement"
    >
  >;
  // Used by the AIAssistant enabled editor
  openAiAssistant?: (from?: "gutter" | "placeholder") => void;
  onEditorMount?: (editor: CodeMirror.Editor) => void;
  onEscapeKey?: () => void;

  // show a list of dropdown options (from source defined by plugin) to autocomplete the field (different from the codeMirror autocomplete)
  presetOptions?: PresetOption[];
  additionalScopeProps?: AdditionalScopeProps;

  formatCodeOnShortcut?: boolean;
  formatCodeOnBlur?: boolean; // Note that formatting on blur requires extra handling and setup for when component unmounts
  formattingStatus?: FormattingStatus; // when running externally as in a manual run
  formattingError?: ErrorTypes; // when running externally as in a manual runs
};

export type EditorProps = EditorStyleProps &
  EditorConfigProps &
  EditorFieldProps &
  EditorExtraProps;

export type Props = EditorProps &
  ReduxStateProps &
  DispatchProp<any> & {
    params: QueryEditorRouteParams;
    store: Store<AppState>;
  };
