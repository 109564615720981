import localStorage from "legacy/utils/localStorage";

const LOCAL_STORAGE_PREFIX = "control-flow-block-preferences";

type BlockState = {
  collapsed?: boolean;
};

export type LocalBlockStateMap = Record<string, BlockState>;

const getLocalStorageString = (apiId: string) => {
  return `${LOCAL_STORAGE_PREFIX}-${apiId}`;
};

export const getLocalBlockState = (apiId: string): LocalBlockStateMap => {
  const item = localStorage.getItem(getLocalStorageString(apiId));
  try {
    if (typeof item !== "string" || item === "") {
      return {};
    }
    const parsed: LocalBlockStateMap = JSON.parse(item);
    if (typeof parsed !== "object") {
      return {};
    }
    return parsed;
  } catch (e) {
    return {};
  }
};

export const setLocalBlockState = (
  apiId: string,
  blocksState: Record<string, BlockState>,
) => {
  localStorage.setItem(
    getLocalStorageString(apiId),
    JSON.stringify(blocksState),
  );
};

export const clearLocalBlockState = (apiId: string) => {
  localStorage.removeItem(getLocalStorageString(apiId));
};
