import { Alert } from "antd";
import _ from "lodash";

import React, { useContext } from "react";
import { useAppSelector } from "store/helpers";
import { selectDatasourceMetaById } from "store/slices/datasources";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";

interface AlertProps {
  type?: "success" | "info" | "warning" | "error";
  showIcon?: boolean;
  messageTemplate: string; // supports ERB template, see http://underscorejs.org/#template
  integrationId: string;
  configurationId?: string;
}

type Props = AlertProps & DynamicFormItemProps;

const DynamicFormAlert = ({
  type,
  showIcon,
  messageTemplate,
  integrationId,
  configurationId,
}: Props) => {
  const context = useContext(FormContext);
  const compiledTemplate = _.template(messageTemplate);
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, integrationId),
  );
  const message = compiledTemplate({
    context,
    datasourceMeta,
    configurationId,
  });
  return message ? (
    <Alert
      type={type}
      showIcon={showIcon}
      message={
        <StyledReactMarkdown linkTarget="_blank">{message}</StyledReactMarkdown>
      }
    />
  ) : (
    <></>
  );
};

export default DynamicFormAlert;
