import { useSelector } from "react-redux";
import { useFeatureFlag } from "hooks/ui";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { Flag } from "store/slices/featureFlags";

export const useIsControlFlowEnabledWorkflowsAndJobs = (): boolean => {
  const isEnabled = useFeatureFlag(Flag.SHOW_CONTROL_FLOW_WORKFLOWS_JOBS);
  const baseEnabled = useSelector(selectControlFlowEnabledDynamic);

  return isEnabled && baseEnabled;
};

export const useAreControlBlocksAvailableForWorkflows = (): boolean => {
  const isEnabled = useFeatureFlag(
    Flag.SHOW_CONTROL_FLOW_CONTROL_BLOCKS_WORKFLOWS,
  );
  return useIsControlFlowEnabledWorkflowsAndJobs() && isEnabled;
};

export const useAreControlBlocksAvailableForJobs = (): boolean => {
  const isEnabled = useFeatureFlag(Flag.SHOW_CONTROL_FLOW_CONTROL_BLOCKS_JOBS);
  return useIsControlFlowEnabledWorkflowsAndJobs() && isEnabled;
};
