import { AccessMode } from "@superblocksteam/shared";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import { useSaga } from "hooks/store";
import { usePreventNavigation } from "hooks/ui";
import { openPurchasedModal } from "legacy/actions/modalActions";
import { CHECKOUT_URL, HOME_URL } from "legacy/constants/routes";
import { SUBMIT_PAYMENT } from "pages/routes";
import { useAppDispatch } from "store/helpers";
import initializeUser from "store/sagas/initializeUser";
import { callServer, HttpMethod } from "store/utils/client";

const CheckoutCompletePage = () => {
  const [isLoading, setLoading] = useState(true);
  const [isComplete, setComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [planName, setPlanName] = useState("new plan");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [initializeUserSaga] = useSaga(initializeUser);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPlanName(params.get("plan_name") ?? "new plan");
    const creatorPrice = params.get("creator_price") ?? null;
    const creatorQuantity = params.get("creator_quantity") ?? null;
    const standardPrice = params.get("standard_price") ?? null;
    const standardQuantity = params.get("standard_quantity") ?? null;
    const promoCode = params.get("promo_code") ?? null;

    if (!creatorPrice || !creatorQuantity) {
      navigate({
        pathname: HOME_URL,
      });
    }

    callServer({
      method: HttpMethod.Post,
      url: SUBMIT_PAYMENT,
      body: {
        promoCode: promoCode,
        items: [
          {
            price: creatorPrice,
            quantity: creatorQuantity,
            name: "Creator Seat",
          },
          {
            price: standardPrice,
            quantity: standardQuantity,
            name: "Standard Seat",
          },
        ],
      },
    })
      .then((res) => {
        setLoading(false);
        setComplete(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(
          error?.message ??
            "Unable to create subscription, please verify your card details.",
        );
      })
      .finally(() => setLoading(false));
  }, [navigate]);

  if (isComplete) {
    initializeUserSaga({ accessMode: AccessMode.AUTH_USER }).finally(() => {
      dispatch(openPurchasedModal(planName));
      navigate({
        pathname: HOME_URL,
      });
    });
  } else if (error !== null) {
    navigate({ pathname: CHECKOUT_URL }, { state: { error: error } });
  }

  usePreventNavigation("system")(isLoading);

  return <FullPageSpinner />;
};

export default CheckoutCompletePage;
