import {
  RepositoryDto,
  VcsProvider,
  getRemoteGitHubUrl,
  getRemoteGitLabUrl,
} from "@superblocksteam/shared";
import { NavigateFunction } from "react-router";
import { ReactComponent as GitHubIcon } from "assets/icons/home/githubInverted.svg";
import { ReactComponent as GitLabIcon } from "assets/icons/home/gitlab.svg";
import { concatenateQueryParams } from "legacy/utils/Utils";
import { SessionStorageKey } from "legacy/utils/sessionStorage";

export const extractCurrentBranchFromSearchString = (
  search: string,
): string | null => {
  const parsed = new URLSearchParams(search).get("branch");
  return parsed;
};

export const extractCurrentBranchFromSearchStringOrStorage = (
  search: string,
  entityId: string,
  navigate: NavigateFunction,
): string | undefined => {
  const entityToCurrentBranchRaw = sessionStorage.getItem(
    SessionStorageKey.ENTITY_TO_CURRENT_BRANCH,
  );
  const entityToCurrentBranch = entityToCurrentBranchRaw
    ? JSON.parse(entityToCurrentBranchRaw)
    : {};

  const parsed = extractCurrentBranchFromSearchString(search);

  // if queryParam not provided, use the branch stored in seesionStorage
  if (!parsed && entityToCurrentBranch[entityId]) {
    navigate({
      search: concatenateQueryParams(
        search,
        `branch=${entityToCurrentBranch[entityId]}`,
      ),
    });
    return entityToCurrentBranch[entityId];
  }

  // store branch in sessionStorage if exists in queryParam
  if (parsed && entityId) {
    entityToCurrentBranch[entityId] = parsed;
    sessionStorage.setItem(
      SessionStorageKey.ENTITY_TO_CURRENT_BRANCH,
      JSON.stringify(entityToCurrentBranch),
    );
  }
  return parsed ?? undefined;
};

export const resetCachedCurrentBranch = (entityId: string) => {
  const entityToCurrentBranchRaw = sessionStorage.getItem(
    SessionStorageKey.ENTITY_TO_CURRENT_BRANCH,
  );
  const entityToCurrentBranch = entityToCurrentBranchRaw
    ? JSON.parse(entityToCurrentBranchRaw)
    : {};
  if (entityToCurrentBranch[entityId]) {
    delete entityToCurrentBranch[entityId];
    sessionStorage.setItem(
      SessionStorageKey.ENTITY_TO_CURRENT_BRANCH,
      JSON.stringify(entityToCurrentBranch),
    );
  }
};

export type Repository = {
  id: string;
  name: string;
  displayName: string;
  owner: {
    name: string;
    displayName: string;
  };
  defaultBranch: string;
  remoteUrl?: string;
  installationId?: string;
  provider: VcsProvider;
};

export const getProviderBaseUrl = (provider: VcsProvider | undefined) => {
  switch (provider) {
    case VcsProvider.GITLAB:
      return `https://gitlab.com`;
    case VcsProvider.GITHUB:
    default:
      return `https://github.com`;
  }
};

export const getCommitUrl = (repository: RepositoryDto, commitId: string) => {
  switch (repository.provider) {
    case VcsProvider.GITLAB:
      return `${getRemoteGitLabUrl(
        repository.owner,
        repository.name,
      )}/commit/${commitId}`;
    case VcsProvider.GITHUB:
    default:
      return `${getRemoteGitHubUrl(
        repository.owner,
        repository.name,
      )}/commit/${commitId}`;
  }
};

export const getCompareUrl = (
  repository: RepositoryDto,
  fromBranch: string,
  toBranch: string,
) => {
  switch (repository.provider) {
    case VcsProvider.GITLAB:
      return `${getRemoteGitLabUrl(
        repository.owner,
        repository.name,
      )}/-/merge_requests/new?merge_request%5Bsource_branch%5D=${encodeURIComponent(
        fromBranch,
      )}`;
    case VcsProvider.GITHUB:
    default:
      return `${getRemoteGitHubUrl(
        repository.owner,
        repository.name,
      )}/compare/${toBranch
        .split("/")
        .map(encodeURIComponent)
        .join("/")}...${fromBranch
        .split("/")
        .map(encodeURIComponent)
        .join("/")}`;
  }
};

export const providerDisplayName = (provider: VcsProvider) => {
  switch (provider) {
    case VcsProvider.GITLAB:
      return "GitLab";
    case VcsProvider.GITHUB:
    default:
      return "GitHub";
  }
};

export const getProviderIcon = (provider: VcsProvider) => {
  switch (provider) {
    case VcsProvider.GITLAB:
      return GitLabIcon;
    case VcsProvider.GITHUB:
    default:
      return GitHubIcon;
  }
};

export const InvalidTokenKeyWords = "invalid token";

export const isInValidTokenMessage = (message: string) => {
  const messageLowerCase = message.toLocaleLowerCase();
  // message can be invalid <provider> token
  return (
    messageLowerCase.includes("invalid") && messageLowerCase.includes("token")
  );
};
