import { FolderDto } from "@superblocksteam/shared";
import { Form, Input, Modal } from "antd";
import React, { useCallback, useEffect } from "react";
import { renameFolder } from "store/slices/folders/client";
import logger from "utils/logger";

type RenameFolderModalProps = {
  renamingFolder: FolderDto | undefined;
  onClose: () => void;
  refreshFolders: () => void;
};

const RenameFolderModal = ({
  renamingFolder,
  onClose,
  refreshFolders,
}: RenameFolderModalProps) => {
  const [renameFolderForm] = Form.useForm();

  const handleFolderRename = useCallback(() => {
    if (!renamingFolder) {
      return;
    }
    renameFolderForm
      .validateFields()
      .then((values) => {
        renameFolder(renamingFolder.id, values.name).then(() =>
          refreshFolders(),
        );
        onClose();
        // setRenamingFolder(undefined);
      })
      .catch((info) => {
        logger.debug("Validate Failed:", info);
      });
  }, [refreshFolders, renamingFolder, renameFolderForm, onClose]);

  useEffect(() => {
    if (renamingFolder) {
      // Reset the field every time the modal opens to re-initialize the initial
      // values.
      renameFolderForm.resetFields();
    }
  }, [renameFolderForm, renamingFolder]);

  return (
    <Modal
      title={`Renaming folder: ${renamingFolder?.name ?? "unknown"}`}
      open={renamingFolder !== undefined}
      onOk={() => handleFolderRename()}
      onCancel={() => {
        renameFolderForm.resetFields();
        onClose();
        // setRenamingFolder(undefined);
      }}
      okText="Rename"
      destroyOnClose
      data-test="rename-folder-modal"
    >
      <Form form={renameFolderForm} layout="vertical" name="rename_folder_form">
        <Form.Item
          name="name"
          label="Folder name"
          initialValue={renamingFolder?.name ?? ""}
          rules={[
            {
              required: true,
              message: "Please input a valid folder name",
            },
          ]}
        >
          <Input
            autoFocus
            placeholder="Folder name..."
            data-test="folder-rename-name"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameFolderModal;
