import styled from "styled-components";

export default styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.02em;
  margin-bottom: 5px;
  margin-top: 10px;
`;
