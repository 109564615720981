import {
  ApiTriggerType,
  VariableMode,
  VariableType,
} from "@superblocksteam/shared";
import { ActionDto } from "store/slices/apis";
import { getNextBlockName } from "store/slices/apisShared/getNextBlockName";
import {
  GenericBlock,
  BlockType,
  ParallelWait,
  LoopType,
} from "store/slices/apisV2/control-flow/types";
import { DEFAULT_PARALLEL_POOL_SIZE } from "../constants";
import { createVariableName, createVariableNames } from "./createVariableName";

export const DEFAULT_VARIABLE_VALUE = `"my variable value"`;

const CONTROL_TYPE_BASE_NAMES = {
  [BlockType.CONDITION]: "Condition",
  [BlockType.LOOP]: "Loop",
  [BlockType.PARALLEL]: "Parallel",
  [BlockType.TRY_CATCH]: "TryCatch",
  [BlockType.VARIABLES]: "Variables",
  [BlockType.BREAK]: "Break",
  [BlockType.RETURN]: "Return",
  [BlockType.WAIT]: "Wait",
  [BlockType.THROW]: "ThrowError",
  [BlockType.SEND]: "Send",
  [BlockType.STREAM]: "Stream",
};

export const LOOP_DEFAULT_RANGE_VALUES = {
  [LoopType.FOREACH]: "{{[1,2,3]}}",
  [LoopType.FOR]: "{{3}}",
  [LoopType.WHILE]: "{{1 == 2}}",
  [LoopType.UNSPECIFIED]: "{{}}",
};

export const createBlankControlBlock = (params: {
  controlType: Exclude<BlockType, BlockType.STEP>;
  existingBlocks: Record<string, GenericBlock>;
  parentId: undefined | string;
  usedVariables: Set<string>;
  triggerType: null | undefined | ApiTriggerType;
}): GenericBlock => {
  const { controlType, existingBlocks, parentId, usedVariables, triggerType } =
    params;
  const blockNames = Object.keys(existingBlocks);
  const blocksOfType = blockNames.filter((name) => {
    const block = existingBlocks[name];
    return block.type === controlType;
  });
  const name = getNextBlockName(
    CONTROL_TYPE_BASE_NAMES[controlType],
    blockNames,
    blocksOfType.length,
  );

  const baseBlock = {
    id: name,
    name,
    type: controlType,
    parentId,
  };

  switch (controlType) {
    case BlockType.PARALLEL: {
      return {
        ...baseBlock,
        config: {
          static: {
            paths: {
              Path1: [],
              Path2: [],
            },
          },
          wait: ParallelWait.WAIT_ALL,
          poolSize: DEFAULT_PARALLEL_POOL_SIZE,
        },
      };
    }
    case BlockType.CONDITION: {
      return {
        ...baseBlock,
        config: {
          if: {
            condition: "{{2 > 1}}",
            blocks: [],
          },
          else: [],
        },
      };
    }
    case BlockType.TRY_CATCH: {
      return {
        ...baseBlock,
        config: {
          try: [],
          catch: [],
          variables: {
            error: createVariableName("error", usedVariables),
          },
        },
      };
    }
    case BlockType.LOOP: {
      const [item, index] = createVariableNames(
        ["item", "index"],
        usedVariables,
      );
      return {
        ...baseBlock,
        config: {
          type: LoopType.FOREACH,
          range: LOOP_DEFAULT_RANGE_VALUES[LoopType.FOREACH],
          variables: {
            item,
            index,
          },
          blocks: [],
        },
      };
    }
    case BlockType.VARIABLES: {
      return {
        ...baseBlock,
        config: {
          variables: [
            {
              key: createVariableName("myVar", usedVariables),
              type: VariableType.SIMPLE,
              value: `{{${DEFAULT_VARIABLE_VALUE}}}`,
              mode: VariableMode.READWRITE,
            },
          ],
        },
      };
    }
    case BlockType.BREAK: {
      return {
        ...baseBlock,
        config: {
          condition: "{{true}}",
        },
      };
    }
    case BlockType.THROW: {
      return {
        ...baseBlock,
        config: {
          error: `{{"An error occurred"}}`,
        },
      };
    }
    case BlockType.RETURN: {
      return {
        ...baseBlock,
        config: {
          data: `{{"Return data"}}`,
        },
      };
    }
    case BlockType.WAIT: {
      return {
        ...baseBlock,
        config: {
          condition: "",
        },
      };
    }
    case BlockType.SEND: {
      return {
        ...baseBlock,
        config: {
          message: `{{"Message data"}}`,
        },
      };
    }
    case BlockType.STREAM: {
      return {
        ...baseBlock,
        config: {
          process: undefined,
          trigger: undefined,
          autoSend: triggerType !== ApiTriggerType.SCHEDULE,
          variables: {
            item: createVariableName("message", usedVariables),
          },
        },
      };
    }
    default: {
      const exhaustiveCheck: never = controlType;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};

export const createNewStepBlock = (
  actionConfig: ActionDto,
  existingBlocks: Record<string, GenericBlock>,
  parentId: undefined | string,
): GenericBlock => {
  const allBlockNames = Object.keys(existingBlocks);
  const actionBlockNames = allBlockNames.filter((name) => {
    const block = existingBlocks[name];
    return block.type === BlockType.STEP;
  });
  const name = getNextBlockName("Step", allBlockNames, actionBlockNames.length);

  const baseBlock = {
    id: name,
    name,
    type: BlockType.STEP,
    parentId,
    config: actionConfig,
  };

  return baseBlock;
};
