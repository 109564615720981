import { Input } from "antd";
import styled from "styled-components";

export const RoundedInput = styled(Input)`
  font-size: 16px;
  height: 44px;
  border: 1px solid ${(props) => props.theme.colors.GREY_300};
  box-sizing: border-box;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.GREY_500};
  margin-top: 8px;
`;
