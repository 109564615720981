import { TASKS } from "@superblocksteam/shared";
import { Modal } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import envs from "env";
import { useSaga } from "hooks/store";
import useTimeout from "hooks/ui/useTimeout";
import { updateTaskSaga } from "store/slices/user";
import { UIEvent } from "utils/event";
import logger from "utils/logger";

const DemoVideoModal = styled(Modal)`
  .ant-modal-content,
  .ant-modal-body {
    height: 490px;
  }
`;

const VideoIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

interface Props {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const DEMO_URL = envs.get("SUPERBLOCKS_UI_DEMO_VIDEO_URL");

const VideoModal = ({ url, isOpen, onClose }: Props) => {
  const [update] = useSaga(updateTaskSaga);
  useTimeout(
    () => {
      if (url === DEMO_URL) {
        update({ id: TASKS.VIEW_DEMO, completed: new Date() });
      }
    },
    1000 * 60, // 1 minute
  );

  useEffect(() => {
    if (url === DEMO_URL) {
      logger.event(UIEvent.VIEWED_DEMO_VIDEO);
    }
  });

  return (
    <DemoVideoModal
      open={isOpen}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      width={800}
    >
      <VideoIFrame src={url} allow="autoplay; fullscreen; picture-in-picture" />
    </DemoVideoModal>
  );
};

export default VideoModal;
