import { OrganizationTokenDto } from "@superblocksteam/shared";
import { DatePicker, Input, Modal } from "antd";
import { noop } from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as WarningIcon } from "assets/icons/common/system-danger.svg";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { DatePickerStyle } from "components/ui/Datepicker";
import { FormItem, FormWrapper } from "components/ui/Form";
import { FooterWrapper, ModalInnerWrapper } from "components/ui/Modal";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { tokenTypeOptions } from "pages/Agents/common";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { HttpMethod, callServer } from "store/utils/client";
import { colors } from "styles/colors";
import { AccessToken, convertOrgToken } from "./constants";

const CreateAccessTokenModalClassName = "create-access-token-modal";

const UpdateTokenModal = ({
  isModalOpen,
  setIsModalOpen,
  tokenToModify,
  setTokens,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  tokenToModify: AccessToken;
  setTokens: React.Dispatch<React.SetStateAction<AccessToken[]>>;
}) => {
  const [nameError, setNameError] = useState<string>();
  const [error, setError] = useState<string>();

  const [newTokenName, setNewTokenName] = useState(tokenToModify.name || "");
  useEffect(() => {
    setNewTokenName(tokenToModify.name || "");
  }, [tokenToModify.name]);

  const [isLoading, setIsLoading] = useState(false);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewTokenName(event?.target?.value || "");
    },
    [],
  );

  const orgId = useSelector(selectOnlyOrganizationId);

  const validate = useCallback(() => {
    if (newTokenName === "") {
      setNameError("Name is required");
      return false;
    }
    return true;
  }, [newTokenName]);

  const cleanStates = useCallback(() => {
    setError("");
    setNameError("");
  }, []);

  const onUpdate = useCallback(async () => {
    if (!validate()) {
      return;
    }
    setIsLoading(true);
    try {
      const tokenCreated = await callServer<OrganizationTokenDto>(
        {
          url: `/v1/organizations/${orgId}/tokens/${tokenToModify.id}`,
          method: HttpMethod.Put,
          body: {
            name: newTokenName,
          },
        },
        {
          notifyOnError: false,
          onError: (e) => {
            setError(e?.message ?? "Failed to update token");
          },
        },
      );

      setTokens((tokens: AccessToken[]) =>
        tokens.map((token) =>
          token.id === tokenToModify.id ? convertOrgToken(tokenCreated) : token,
        ),
      );
      setIsModalOpen(false);
      cleanStates();
    } catch (e: any) {
      setError(e?.message ?? "Failed to update token");
    } finally {
      setIsLoading(false);
    }
  }, [
    cleanStates,
    newTokenName,
    orgId,
    setIsModalOpen,
    setTokens,
    tokenToModify.id,
    validate,
  ]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        validate() && onUpdate();
        e.stopPropagation();
      }
    },
    [onUpdate, validate],
  );

  const onCancel = useCallback(() => {
    setIsModalOpen(false);
    cleanStates();
  }, [cleanStates, setIsModalOpen]);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        title="Edit access token"
        footer={null}
        destroyOnClose={true}
        wrapClassName={CreateAccessTokenModalClassName}
      >
        <div className={ModalInnerWrapper} ref={parentRef}>
          <div className={FormWrapper}>
            <FormItem label="Name" error={nameError}>
              <Input
                data-test="token-name-input"
                value={newTokenName}
                autoFocus={true}
                onChange={onNameChange}
                suffix={
                  nameError ? (
                    <WarningIcon color={colors.RED_500} />
                  ) : (
                    <div></div>
                  ) //need to add this to avoid relayout
                }
                onKeyDown={handleKeyPress}
              />
            </FormItem>
            <FormItem label="Expiration">
              <DatePicker
                className={DatePickerStyle}
                disabled
                value={
                  tokenToModify.expiresAt && moment(tokenToModify.expiresAt)
                }
                format={"MM/DD/YYYY"}
              />
            </FormItem>
            <FormItem label="Token type">
              <RecommendedSingleDropdown
                value={tokenToModify.scope}
                options={tokenTypeOptions}
                parentRef={parentRef}
                disabled={Boolean(tokenToModify)}
                onChange={noop}
              />
            </FormItem>
            <FormItem error={error} hidden={!error} />
          </div>
          <div className={FooterWrapper}>
            <SecondaryButton
              data-test="cancel-save-token-button"
              onClick={onCancel}
              loading={isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              type="primary"
              onClick={onUpdate}
              data-test="save-token-button"
              loading={isLoading}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateTokenModal;
