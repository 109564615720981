import { QuestionCircleTwoTone } from "@ant-design/icons";
import {
  ENVIRONMENT_STAGING,
  jsonPrettyPrint,
  Organization,
  ViewDatasourceDto,
} from "@superblocksteam/shared";
import { Alert, Input, Space, Tooltip, Typography } from "antd";
import { produce } from "immer";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { CodeBlock } from "components/ui/CodeBlock";
import DisplayRequestURL from "components/ui/DisplayRequestURL";
import { DeploymentAlertWithProfiles } from "components/ui/Environment";
import { FullWidthSpace } from "components/ui/Space";
import { WizardStep } from "components/ui/WizardFlow";
import { CREATE_INTEGRATION } from "constants/rbac";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { useIsControlFlowEnabledWorkflowsAndJobs } from "hooks/api/useIsControlFlowEnabledWorkflowsAndJobs";
import { useSaga } from "hooks/store";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import {
  DOCS_PAGE_URL,
  DocsPage,
  WORKFLOW_CONFIGURE_URL,
  WORKFLOW_EDITOR_URL,
  WORKFLOW_EXECUTE_URL,
} from "legacy/constants/routes";
import { CodeExamples } from "pages/Editors/ApiEditor/WorkflowEditor/WorkflowConfiguration/CodeExamples";
import { getInitialApiConfiguration } from "pages/Editors/ApiEditor/utils";
import { NEW_INTEGRATION_URL } from "pages/routes";
import { useAppSelector } from "store/helpers";
import { selectActiveAgentUrl } from "store/slices/agents";
import {
  ApiDto,
  persistApiSaga,
  selectApiById,
  updateApiSaga,
} from "store/slices/apis";
import {
  persistV2ApiSaga,
  selectV2ApiById,
  updateV2ApiSaga,
} from "store/slices/apisV2";
import { createNewStepBlock } from "store/slices/apisV2/control-flow/block-generation";
import { convertDSLToBackendBlocks } from "store/slices/apisV2/control-flow/dsl-converters";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { getPluginById } from "utils/integrations";
import WorkflowBodySelector from "../../Editors/ApiEditor/WorkflowEditor/WorkflowConfiguration/WorkflowBodySelector";
import WorkflowQuerySelector from "../../Editors/ApiEditor/WorkflowEditor/WorkflowConfiguration/WorkflowQuerySelector";
import { ApiIntegrations } from "./ApiIntegrations";
import { DatasourceList } from "./CommonComponents";
import {
  CreateSteps,
  createStepShortcuts,
  useApiDatasources,
  type CommonStepProps,
  type CreateWorkflowProps,
} from "./common";

export const CreateWorkflowSteps = (
  props: CreateWorkflowProps &
    CommonStepProps & {
      organization: Organization;
      userOrganizationId: string; // TODO why would this be different from organization.id?;
    },
) => {
  const { workflowApiId, activeStep, organization, userOrganizationId } = props;
  const navigate = useNavigate();
  const [updateV1Api] = useSaga(updateApiSaga);
  const [updateV2Api] = useSaga(updateV2ApiSaga);
  const controlFlowEnabled = useIsControlFlowEnabledWorkflowsAndJobs();

  const updateApi = useCallback(
    async (api: ApiDto | ApiDtoWithPb) => {
      if (controlFlowEnabled) {
        await updateV2Api({ apiPb: (api as ApiDtoWithPb).apiPb });
      } else {
        await updateV1Api(api as ApiDto);
      }
    },
    [updateV1Api, updateV2Api, controlFlowEnabled],
  );

  const workflowApiV1 = useAppSelector((state) =>
    selectApiById(state, workflowApiId),
  );
  const workflowApiV2 = useAppSelector((state) =>
    selectV2ApiById(state, workflowApiId),
  );
  const [queryParameterMap, setQueryParameterMap] = useState<
    Record<string, string>
  >({});
  const workflowApi = workflowApiV2 ?? workflowApiV1;
  const workflowBody = useMemo(() => {
    if (workflowApiV2) {
      return workflowApiV2.apiPb.trigger?.workflow?.parameters?.body;
    }
    return workflowApiV1?.actions?.workflowParams?.[0]?.value;
  }, [workflowApiV2, workflowApiV1]);
  const [persistApi] = useSaga(persistApiSaga);
  const [persistApiV2] = useSaga(persistV2ApiSaga);
  const { searchDatasources, setDatasourcesSearch } = useApiDatasources(
    userOrganizationId,
    activeStep === CreateSteps.WORKFLOW_FIRST_ACTION,
  );

  const handleCreateWorkflow = useCallback(
    async (selectedFirstStep: ViewDatasourceDto) => {
      if (!workflowApiV1 && !workflowApiV2) {
        return;
      }
      if (!selectedFirstStep) {
        // just go to the workflow editor
        navigate({ pathname: WORKFLOW_CONFIGURE_URL(workflowApiId) });
        return;
      }
      if (workflowApiV2) {
        let actionId: string | undefined;
        const updatedApi: ApiDtoWithPb = produce(workflowApiV2, (api) => {
          if (selectedFirstStep) {
            actionId = uuidv4();
            const plugin = getPluginById(selectedFirstStep.pluginId);
            const step = createNewStepBlock(
              {
                id: actionId,
                pluginId: selectedFirstStep.pluginId,
                datasourceId: selectedFirstStep.id,
                name: "Step1",
                type: 0,
                organizationId: organization.id,
                configuration: getInitialApiConfiguration(plugin),
              },
              {},
              undefined,
            );
            api.apiPb.blocks = convertDSLToBackendBlocks({
              rootBlocks: [step.name],
              blocks: {
                [step.name]: step,
              },
            });
          }
        });

        await persistApiV2({ api: updatedApi.apiPb });
        navigate({ pathname: WORKFLOW_EDITOR_URL(updatedApi.id, "Step1") });
      } else if (workflowApiV1) {
        let actionId: string | undefined;
        const updatedApi: ApiDto = produce(workflowApiV1, (api) => {
          if (selectedFirstStep) {
            actionId = uuidv4();
            const plugin = getPluginById(selectedFirstStep.pluginId);
            if (api?.actions) {
              api.actions.actions[actionId] = {
                id: actionId,
                pluginId: selectedFirstStep.pluginId,
                datasourceId: selectedFirstStep.id,
                name: "Step1",
                type: 0,
                organizationId:
                  selectedFirstStep.organizationId ?? organization.id,
                configuration: getInitialApiConfiguration(plugin),
              };

              api.actions.triggerActionId = actionId;
            }
          }
        });

        await persistApi({ api: updatedApi, shouldUpdateState: true });
        navigate({ pathname: WORKFLOW_EDITOR_URL(updatedApi.id, actionId) });
      }
    },
    [
      navigate,
      organization.id,
      persistApi,
      workflowApiV1,
      workflowApiV2,
      persistApiV2,
      workflowApiId,
    ],
  );

  const workflowVariableStep = workflowApi ? (
    <Space direction="vertical" size={20}>
      <Alert
        message={`"${workflowApi?.actions?.name}" successfully created!`}
        type="success"
        showIcon
      />
      <Typography.Text>
        Workflows are REST Endpoints that can be called within Superblocks or
        from existing Admin Tooling or 3rd party webhooks.{" "}
        <Typography.Link
          href={DOCS_PAGE_URL(DocsPage.WORKFLOWS)}
          target="_blank"
        >
          Learn the basics
        </Typography.Link>
      </Typography.Text>
      <FullWidthSpace direction="vertical">
        <Typography.Text strong>Request Query Parameters</Typography.Text>
        <Typography.Text>
          You may use the URL query parameter as variables that can be
          referenced using{" "}
          <Typography.Text code>params.variable_name</Typography.Text>.
        </Typography.Text>
        <WorkflowQuerySelector
          apiId={workflowApi.id}
          apiV1={workflowApiV1}
          apiV2={workflowApiV2}
          params={workflowApi.actions?.workflowQueries ?? []}
          updateApi={updateApi}
          onQueryParamsUpdate={(queryParameterMap) =>
            setQueryParameterMap(queryParameterMap)
          }
        />
      </FullWidthSpace>
      <FullWidthSpace direction={"vertical"}>
        <Typography.Text strong>Request Body</Typography.Text>
        <Typography.Text>
          You may use the POST&apos;s data payload as variables that can be
          referenced using{" "}
          <Typography.Text code>body.variable_name</Typography.Text>.
        </Typography.Text>
        <WorkflowBodySelector
          apiId={workflowApi.id}
          apiV1={workflowApiV1}
          apiV2={workflowApiV2}
          params={workflowApi.actions?.workflowParams ?? []}
          updateApi={updateApi}
          shortPreview={true}
        />
      </FullWidthSpace>
      {!isEmpty(workflowBody) && (
        <FullWidthSpace direction="vertical">
          <Typography.Text strong>Test HTTP Body</Typography.Text>
          <CodeBlock text={jsonPrettyPrint(workflowBody)} />
        </FullWidthSpace>
      )}
    </Space>
  ) : (
    <Typography.Text>Workflow not found</Typography.Text>
  );

  const agentUrl =
    useSelector(
      selectActiveAgentUrl(organization.agentType, ENVIRONMENT_STAGING),
    ) ?? "";

  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);

  const workflowCallStep = workflowApi ? (
    <FullWidthSpace direction="vertical" size={20}>
      <Typography.Text>
        Test calling the Workflow via cURL, Postman, or via your code.
      </Typography.Text>
      <FullWidthSpace direction="vertical">
        <Typography.Text strong>
          URL{" "}
          <Tooltip
            title={`This workflow triggers when an external app or service sends a web
          request to this URL`}
          >
            <QuestionCircleTwoTone />
          </Tooltip>
        </Typography.Text>
        <DisplayRequestURL
          addonBefore={<Typography.Text strong>POST</Typography.Text>}
          value={WORKFLOW_EXECUTE_URL(
            controlFlowEnabled
              ? {
                  orchestratorUrl: SUPERBLOCKS_UI_AGENT_BASE_URL,
                  apiId: workflowApi.id,
                }
              : { agentUrl, apiId: workflowApi.id },
          )}
          size="middle"
          disabled
        />
      </FullWidthSpace>
      <CodeExamples
        apiId={workflowApi.id}
        bodyParams={workflowApi.actions?.workflowParams ?? []}
        organization={organization}
        environments={[ENVIRONMENT_STAGING]}
        customQueryParamsMap={queryParameterMap}
      />
      {enableProfiles && <DeploymentAlertWithProfiles />}
    </FullWidthSpace>
  ) : (
    <Typography.Text>Workflow not found</Typography.Text>
  );

  const [canCreateIntegration] = useAuthorizationCheck([CREATE_INTEGRATION]);

  const chooseFirstStepWorkflow = workflowApi ? (
    <FullWidthSpace direction="vertical" size={20}>
      <Typography.Text>
        Workflow steps allow you to query data sources or execute code whenever
        the workflow has been called
      </Typography.Text>
      <Input
        autoFocus
        placeholder="Filter integrations"
        onChange={(e) => {
          setDatasourcesSearch(e.target.value);
        }}
      />
      {canCreateIntegration && (
        <Typography.Link href={NEW_INTEGRATION_URL("")} target="_blank">
          + New Integration
        </Typography.Link>
      )}
      <DatasourceList>
        <ApiIntegrations
          searchDatasources={searchDatasources}
          handleClickIntegration={handleCreateWorkflow}
        />
      </DatasourceList>
    </FullWidthSpace>
  ) : (
    <Typography.Text>Workflow not found</Typography.Text>
  );

  return (
    <>
      <WizardStep {...stepIds[CreateSteps.WORKFLOW_VARIABLES]} noBack>
        {workflowVariableStep}
      </WizardStep>
      <WizardStep {...stepIds[CreateSteps.WORKFLOW_CALL]} nextText={"Next"}>
        {workflowCallStep}
      </WizardStep>
      <WizardStep
        {...stepIds[CreateSteps.WORKFLOW_FIRST_ACTION]}
        canSkip
        onSkip={() => {
          navigate({ pathname: WORKFLOW_CONFIGURE_URL(workflowApiId) });
        }}
      >
        {chooseFirstStepWorkflow}
      </WizardStep>
    </>
  );
};

const order = [
  CreateSteps.CHOOSE_ENTITY,
  CreateSteps.WORKFLOW_VARIABLES,
  CreateSteps.WORKFLOW_CALL,
  CreateSteps.WORKFLOW_FIRST_ACTION,
] as const;
const stepIds = createStepShortcuts(order);
