import { MinusCircleOutlined } from "@ant-design/icons";
import { KVPair } from "@superblocksteam/shared";
import { Button, Form } from "antd";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";

import React, { useCallback } from "react";
import styled from "styled-components";
import {
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";

import CodeEditor from "./CodeEditor";
import Input from "./Input";

const FormItemWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const FormItem = styled(Form.Item)<{ grow?: number; shrink?: number }>`
  flex-grow: ${(props) => props.grow ?? 1};
  flex-shrink: ${(props) => props.shrink ?? 0};
  margin-right: 8px;
`;

interface FieldListProps {
  name: string;
  isDynamic: boolean;
  initialValue?: KVPair[];
  disabledPairCount?: number;
}

const isDisabled = (key: number, disabledPairCount = 0) => {
  return key < disabledPairCount;
};

const FieldList = ({
  name: listName,
  isDynamic,
  initialValue,
  disabledPairCount,
}: FieldListProps) => {
  const renderFormList = useCallback(
    (fields: FormListFieldData[], { add, remove }: FormListOperation) => {
      // Add extra row if all rows are disabled since user won't be able
      // to generate a new one by typing.
      if (fields.length === disabledPairCount) {
        addRow();
      }

      function addRow() {
        add({ key: "", value: "" });
      }

      function addExtraRowWhileTyping(index: number) {
        if (index === fields.length - 1) {
          addRow();
        }
      }

      return (
        <>
          {fields.map(({ key, name }) => (
            <FormItemWrapper key={key}>
              <FormItem
                name={[name, "key"]}
                trigger="onBlur"
                validateTrigger="onBlur"
                grow={0}
                shrink={0}
              >
                {isDynamic && !isDisabled(key, disabledPairCount) ? (
                  <CodeEditor
                    data-test={`dynamic-text-${listName}_${name}-key`}
                    showLineNumbers={false}
                    mode={"text-js" as any}
                    tabBehaviour={TabBehaviour.INDENT}
                    theme={EditorTheme.LIGHT}
                    size={EditorSize.EXTENDED}
                    monospace={true}
                    enableSearch={false}
                    height="40px"
                    onChange={() => {
                      addExtraRowWhileTyping(name);
                    }}
                    placeholder={"Key"}
                  />
                ) : (
                  <Input
                    disabled={isDisabled(key, disabledPairCount)}
                    placeholder={"Key"}
                    onChange={() => {
                      addExtraRowWhileTyping(name);
                    }}
                  />
                )}
              </FormItem>
              <FormItem
                name={[name, "value"]}
                trigger="onBlur"
                validateTrigger="onBlur"
                grow={1}
                shrink={1}
              >
                {/* CodeEditor's disabled mode is currently pretty busted.
                    It renders as a read-only input box with no visual indicator;
                    the cursor is still enabled and the colors remain unchanged.
                    I tried my hand at fixing it really quickly but failed, and
                    decided to switch it to Input when we're displaying disabled values.
                */}
                {!isDisabled(key, disabledPairCount) ? (
                  <CodeEditor
                    data-test={`dynamic-text-${listName}_${name}-value`}
                    showLineNumbers={false}
                    mode={"text-js" as any}
                    tabBehaviour={
                      isDynamic ? TabBehaviour.INDENT : TabBehaviour.INPUT
                    }
                    theme={EditorTheme.LIGHT}
                    size={EditorSize.EXTENDED}
                    enableSearch={false}
                    minHeight="33px"
                    maxHeight="350px"
                    monospace={true}
                    onChange={() => {
                      addExtraRowWhileTyping(name);
                    }}
                    placeholder={"Value"}
                  />
                ) : (
                  <Input
                    disabled={true}
                    placeholder={"Key"}
                    onChange={() => {
                      addExtraRowWhileTyping(name);
                    }}
                  />
                )}
              </FormItem>
              <Button
                data-test={`form_delete_${name}_button`}
                disabled={isDisabled(key, disabledPairCount)}
                icon={<MinusCircleOutlined />}
                type="text"
                onClick={() => {
                  remove(name);
                }}
              />
            </FormItemWrapper>
          ))}
        </>
      );
    },
    [listName, isDynamic, disabledPairCount],
  );

  return (
    <Form.List
      name={listName}
      initialValue={[...(initialValue ?? []), { key: "", value: "" }]}
    >
      {renderFormList}
    </Form.List>
  );
};

export default FieldList;
