import { get, reject } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { getCreateConfig } from "legacy/sagas/WidgetOperationsSagasUtils";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { getWidgetDynamicPropertyPathList } from "legacy/utils/DynamicBindingUtils";
import { convertToString } from "legacy/utils/Utils";
import { WidgetProps } from "legacy/widgets";
import { GeneratorReturnType } from "store/utils/types";
import { selectAiState } from "../selectors";
import { updateAiChanges, updateAiDynamicProperties } from "../slice";

export function* updateAiDynamicPropertiesSaga(
  action: ReturnType<typeof updateAiDynamicProperties>,
) {
  const { itemId, propertyName, isDynamicProperty } = action.payload;
  const state: ReturnType<typeof selectAiState> = yield select(selectAiState);
  const widget: WidgetProps = yield select(getWidget, itemId);
  const propertyValue = get(
    state.dataTreeChanges ?? {},
    action.payload.propertyName,
  );
  let dynamicPropertyPathList = getWidgetDynamicPropertyPathList(
    state.dataTreeChanges ?? {},
  );
  const propertyUpdates: Record<string, unknown> = {};
  if (isDynamicProperty) {
    dynamicPropertyPathList.push({
      key: propertyName,
    });
    propertyUpdates[propertyName] = convertToString(propertyValue);
  } else {
    dynamicPropertyPathList = reject(dynamicPropertyPathList, {
      key: propertyName,
    });
    const widgetConfig: GeneratorReturnType<typeof getCreateConfig> =
      yield call(getCreateConfig, widget.type);
    propertyUpdates[propertyName] =
      widgetConfig[propertyName as keyof typeof widgetConfig];
  }
  propertyUpdates.dynamicPropertyPathList = dynamicPropertyPathList;
  yield put(updateAiChanges({ updates: propertyUpdates }));
}
