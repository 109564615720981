import Icon from "@ant-design/icons";
import { Typography } from "antd";
import { match } from "path-to-regexp";
import React, { useMemo, useState, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as SuperblocksLogo } from "assets/images/ColorLogo.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { Layers } from "legacy/constants/Layers";
import { PAGE_VIEWER_URL } from "../../../legacy/constants/routes";

const Wrapper = styled.div`
  height: 28px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 4px;
  padding: 4px 6px;
  z-index: ${Layers.intercom};
  position: fixed;
  bottom: 16px;
  right: 16px;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
`;

const CloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 4px;
  padding: 0 1px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

type ApplicationViewerPageMatchResult = {
  applicationId: string;
  pageId: string;
};

export const BuildInSuperblocksOverlay = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(true);

  const onClose = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsVisible(false);
  }, []);

  const applicationViewerPageMatchResult = useMemo(() => {
    const mm = match(PAGE_VIEWER_URL, {
      decode: decodeURIComponent,
    });
    const res = mm(window.location.pathname);
    if (res === false) {
      return undefined;
    }
    return res?.params as ApplicationViewerPageMatchResult;
  }, []);

  const redirectLink = useMemo(() => {
    const link = `https://www.superblocks.com/`;
    if (applicationViewerPageMatchResult) {
      return (
        link + `?referrer=${applicationViewerPageMatchResult.applicationId}`
      );
    }
  }, [applicationViewerPageMatchResult]);

  return (
    <div style={{ width: `100%`, height: "100%" }}>
      {children}
      {isVisible && (
        <Wrapper
          id="superblocks-pill"
          onClick={() => {
            window.open(redirectLink);
          }}
        >
          <Icon
            component={SuperblocksLogo}
            style={{
              fontSize: 18,
              color: theme.colors.OFF_WHITE,
              marginRight: "6px",
            }}
          />
          <Typography.Text>
            <strong>Built with Superblocks</strong>
          </Typography.Text>
          <CloseBtn onClick={onClose}>
            <CloseIcon />
          </CloseBtn>
        </Wrapper>
      )}
    </div>
  );
};
