import React from "react";
import { ReactComponent as ReposImg } from "assets/icons/git-sync/repos_ illustration.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const TitleStyle = styleAsClass`
  color:${colors.GREY_900};
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
`;

const SubTextStyle = styleAsClass`
  color:${colors.GREY_500};
  font-size: 12px;
  line-height: 20px;
  max-width: 465px;
  text-align: center;
`;

const WrapperStyle = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 100px;
`;

const ReposEmptyState = () => {
  return (
    <div className={WrapperStyle}>
      <div className={TitleStyle}> No repositories available</div>
      <div className={SubTextStyle}>
        Once repositories are connected, you’ll be able to view them here.{" "}
        <br />
        Learn more about Source Control
      </div>
      <div>
        <ReposImg />
      </div>
    </div>
  );
};

export default ReposEmptyState;
