import {
  AgentType,
  DropdownOption,
  ENVIRONMENT_ALL,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_STAGING,
  Organization,
  Profile,
  WorkflowExecutionParamsKey,
  ExecutionParam,
} from "@superblocksteam/shared";
import { Tabs } from "antd";
import { isEmpty, uniqBy } from "lodash";
import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CodeBlock } from "components/ui/CodeBlock";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useFeatureFlag } from "hooks/ui";
import { getProfileForTest } from "legacy/selectors/applicationSelectors";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import {
  selectActiveAgents,
  selectActiveAgentUrl,
} from "../../../../../store/slices/agents";
import { prettyEnvironment } from "../../../../../store/utils/environment";
import { noActiveAgentMessage } from "../../../../../utils/error/error";
import { findKeyInExecutionParams, removeEmptyItemInJSON } from "../utils";
import { buildFullWorkflowUrl } from "./common";

interface Props {
  apiId: string;
  bodyParams: ExecutionParam[];
  organization: Organization;
  customQueryParamsMap?: Record<string, string>;
  alwaysShowTitle?: boolean;
  environments?: string[];
  test?: boolean;
}

const UrlDropdownWrapper = styled.div`
  margin-bottom: 10px;
`;

export const CodeExamples = ({
  apiId,
  bodyParams,
  organization,
  alwaysShowTitle,
  environments = [ENVIRONMENT_STAGING, ENVIRONMENT_PRODUCTION],
  test = true,
  customQueryParamsMap: customQueryParamsMapWithoutProfile = {},
}: Props) => {
  const stagingAgentUrl = useSelector(
    selectActiveAgentUrl(organization.agentType, ENVIRONMENT_STAGING),
  );
  const productionAgentUrl = useSelector(
    selectActiveAgentUrl(organization.agentType, ENVIRONMENT_PRODUCTION),
  );

  const agentUrls: Record<string, string | undefined> = {
    [ENVIRONMENT_STAGING]: stagingAgentUrl,
    [ENVIRONMENT_PRODUCTION]: productionAgentUrl,
  };

  const isV2 = useIsControlFlowEnabled();

  // opa profiles
  const isOPA = organization.agentType === AgentType.ONPREMISE;

  // profiles
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  let testProfile = useAppSelector((state) => getProfileForTest(state, apiId));
  if (!test) testProfile = undefined;
  const customQueryParamsMap = useMemo(
    () =>
      testProfile?.key
        ? {
            ...customQueryParamsMapWithoutProfile,
            profile: testProfile?.key,
          }
        : customQueryParamsMapWithoutProfile,
    [customQueryParamsMapWithoutProfile, testProfile?.key],
  );

  const agentUrlWithoutEnv = useSelector(
    selectActiveAgentUrl(organization.agentType, testProfile?.key ?? ""),
  );

  const branch = useSelector(getCurrentBranch);

  const bodyValue = useMemo(
    () => ({
      ...(branch ? { branchName: branch.name } : {}),
      ...(findKeyInExecutionParams(bodyParams, WorkflowExecutionParamsKey.BODY)
        ?.value as any),
    }),
    [bodyParams, branch],
  );

  const getText = useCallback(
    ({
      agentUrl,
      environment,
      profile,
    }: {
      agentUrl: string | undefined;
      environment?: string;
      profile?: Profile | { key: string };
    }) => {
      if (isV2 ? isOPA && !agentUrl : !agentUrl) {
        const noAgentMessage = noActiveAgentMessage(organization.agentType);
        return {
          curlText: noAgentMessage,
          jsText: noAgentMessage,
          pythonText: noAgentMessage,
        };
      }

      // If the workflow have customized body, we generate the trigger request with headers and the body.
      // Otherwise, we generate the trigger without headers and the body.
      if (isEmpty(bodyValue)) {
        const fullUrl = buildFullWorkflowUrl({
          executeUrl: isV2
            ? {
                orchestratorUrl: isOPA
                  ? agentUrl
                  : SUPERBLOCKS_UI_AGENT_BASE_URL,
              }
            : { agentUrl: agentUrl as string },
          apiId: apiId,
          environment,
          test,
          bearer: organization.apiKey,
          customQuery: customQueryParamsMap,
          profile,
        });
        return {
          curlText: `curl --location \\
    --request POST '${fullUrl}'`,
          jsText: `await fetch("${fullUrl}", {
  method: "POST",
});`,
          pythonText: `resp = requests.post("${fullUrl}")`,
        };
      } else {
        const fullUrl = buildFullWorkflowUrl({
          executeUrl: isV2
            ? {
                orchestratorUrl: isOPA
                  ? agentUrl
                  : SUPERBLOCKS_UI_AGENT_BASE_URL,
              }
            : { agentUrl: agentUrl as string },
          apiId: apiId,
          environment,
          test,
          customQuery: customQueryParamsMap,
        });
        const body = removeEmptyItemInJSON(bodyValue);

        const optionalCurlBody = isEmpty(body)
          ? ""
          : ` \\\n    --data-raw '${JSON.stringify(body, null, 4)}'`;

        const optionalJSBody = isEmpty(body)
          ? ""
          : `,\n  body: JSON.stringify(${JSON.stringify(body)})`;

        const optionalPythonBody = isEmpty(body)
          ? ""
          : `,\n    data=json.dumps(${JSON.stringify(body)})`;

        return {
          curlText: `curl --location --request POST '${fullUrl}' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${organization.apiKey}'${optionalCurlBody}`,
          jsText: `await fetch("${fullUrl}", {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${organization.apiKey}",
  }${optionalJSBody}
});`,
          pythonText: `resp = requests.post(
    "${fullUrl}",
    headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ${organization.apiKey}",
    }${optionalPythonBody}
)`,
        };
      }
    },
    [
      bodyValue,
      organization.agentType,
      organization.apiKey,
      apiId,
      test,
      customQueryParamsMap,
      isV2,
      isOPA,
    ],
  );

  const showTitle = environments.length > 1 || alwaysShowTitle;

  const activeAgents = useSelector(
    selectActiveAgents(organization.agentType, testProfile?.key ?? ""),
  );
  const [selectedAgentUrl, setSelectedAgentUrl] = useState(
    activeAgents?.[0]?.url,
  );
  const onSelectAgentUrl = useCallback((option: DropdownOption) => {
    setSelectedAgentUrl(option.value);
  }, []);
  const opaAgentsOptions = useMemo(
    () =>
      uniqBy(
        activeAgents.map((agent) => ({
          value: agent.url,
          key: agent.url,
          displayName: `${agent.url}${
            agent.environment === ENVIRONMENT_ALL
              ? ""
              : ` [${agent.environment}]`
          }`,
        })),
        (option: DropdownOption) => option.value,
      ),
    [activeAgents],
  );
  const urlDropdown = isOPA ? (
    <UrlDropdownWrapper>
      Agent Url:
      <RecommendedSingleDropdown
        value={selectedAgentUrl}
        options={opaAgentsOptions}
        onChange={onSelectAgentUrl}
      />
    </UrlDropdownWrapper>
  ) : null;

  const curlExamples = enableProfiles ? (
    <CodeBlock
      dataTest="curl-example-container"
      text={
        getText({
          agentUrl: isOPA ? selectedAgentUrl : agentUrlWithoutEnv,
          profile: testProfile,
        }).curlText
      }
    />
  ) : (
    environments.map((environment) => (
      <div key={environment}>
        {showTitle && `Environment: ${prettyEnvironment(environment)}`}
        <CodeBlock
          text={
            getText({ agentUrl: agentUrls[environment], environment }).curlText
          }
        />
      </div>
    ))
  );

  const jsExamples = enableProfiles ? (
    <CodeBlock
      dataTest="js-example-container"
      text={
        getText({
          agentUrl: isOPA ? selectedAgentUrl : agentUrlWithoutEnv,
          profile: testProfile,
        }).jsText
      }
    />
  ) : (
    environments.map((environment) => (
      <div key={environment}>
        {showTitle && `Environment: ${prettyEnvironment(environment)}`}
        <CodeBlock
          text={
            getText({ agentUrl: agentUrls[environment], environment }).jsText
          }
        />
      </div>
    ))
  );

  const pythonExamples = enableProfiles ? (
    <CodeBlock
      dataTest="python-example-container"
      text={
        getText({
          agentUrl: isOPA ? selectedAgentUrl : agentUrlWithoutEnv,
          profile: testProfile,
        }).pythonText
      }
    />
  ) : (
    environments.map((environment) => (
      <div key={environment}>
        {showTitle && `Environment: ${prettyEnvironment(environment)}`}
        <CodeBlock
          text={
            getText({ agentUrl: agentUrls[environment], environment })
              .pythonText
          }
        />
      </div>
    ))
  );

  return (
    <Tabs data-test="code-examples" defaultActiveKey="0">
      <Tabs.TabPane tab={<div data-test="curl-code-example">cURL</div>} key="0">
        {urlDropdown}
        {curlExamples}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<div data-test="curl-code-example">JavaScript</div>}
        key="1"
        data-test="javascript-code-example"
      >
        {urlDropdown}
        {jsExamples}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<div data-test="python-code-example"> Python </div>}
        key="2"
      >
        {urlDropdown}
        {pythonExamples}
      </Tabs.TabPane>
    </Tabs>
  );
};
