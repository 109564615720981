import { Modal, Table, Alert } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { AgentWithHealth } from "../../store/slices/agents/client";

type AgentDetailModalProps = {
  agentWithHealth: AgentWithHealth;
  open: boolean;
  onCancel: () => void;
};

export const AgentDetailModal = ({
  agentWithHealth,
  open,
  onCancel,
}: AgentDetailModalProps) => {
  const modalContent = useMemo(() => {
    return (
      <>
        <AgentDetailSection
          title={agentWithHealth.description()}
          healthy={agentWithHealth.healthy()}
          mitigations={agentWithHealth.mitigations()}
        />
        {!agentWithHealth.healthy && agentWithHealth.hasServerErrors() && (
          <AgentServerErrorLogTable agentWithHealth={agentWithHealth} />
        )}
      </>
    );
  }, [agentWithHealth]);

  return (
    <Modal
      title={`Agent Details: ${agentWithHealth.agent.url}`}
      open={open}
      onCancel={onCancel}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      width={700}
      destroyOnClose={true}
    >
      <StyledWrapper>{modalContent}</StyledWrapper>
    </Modal>
  );
};

const StyledWrapper = styled.div`
  .ant-table-wrapper .ant-table-cell {
    font-size: 12px;
    padding: 4px 0px;
    color: ${(props) => props.theme.colors.GREY_900};
  }
`;

type AgentDetailSectionProps = {
  title: string;
  healthy: boolean;
  mitigations?: string[];
  fontSize?: string;
};

const AgentDetailSection = ({
  title,
  healthy,
  mitigations = [],
  fontSize = "12px",
}: AgentDetailSectionProps) => {
  return (
    <div>
      <Alert
        message={title}
        style={{
          fontSize: fontSize,
          padding: 0,
          backgroundColor: "white",
          border: 0,
        }}
        type={healthy ? "success" : "warning"}
        showIcon
      />
      <ul>
        {mitigations.map((mitigation) => (
          <li style={{ fontSize: fontSize }} key={mitigation}>
            {mitigation}
          </li>
        ))}
      </ul>
    </div>
  );
};

type AgentServerErrorLogTableProps = {
  agentWithHealth: AgentWithHealth;
  pageSize?: number;
};
const AgentServerErrorLogTable = ({
  agentWithHealth,
  pageSize = 10,
}: AgentServerErrorLogTableProps) => {
  const agentServerErrorColumns = [
    {
      title: "Time",
      dataIndex: "time",
      width: 300,
    },
    {
      title: "Message",
      dataIndex: "message",
      width: 500,
    },
  ];

  const data: any[] = [];
  for (const errorMessage of agentWithHealth.healthDetail?.server_errors ??
    []) {
    const date = new Date(errorMessage.ts);
    data.push({
      time: date.toUTCString(),
      message: errorMessage.msg,
    });
  }

  return (
    <Table
      columns={agentServerErrorColumns}
      dataSource={data}
      pagination={{ pageSize: pageSize }}
    />
  );
};
