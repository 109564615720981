import React, { useState, useEffect } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

// This provider is meant to support components rendered from outside the main react root.
// AKA via a direct "render" or "createRoot" call.

import getTheme, { Theme } from "styles/theme";

let lazyTheme: null | Theme = null;
const loadTheme = async () => {
  if (lazyTheme) return lazyTheme;
  const theme = await getTheme();
  lazyTheme = theme;
  return theme;
};

const LazyThemeProvider = (props: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<null | Theme>(lazyTheme);
  useEffect(() => {
    if (theme == null) {
      loadTheme().then(setTheme);
    }
  }, [theme]);
  return (
    theme && (
      <ThemeProvider theme={theme as DefaultTheme}>
        {props.children}
      </ThemeProvider>
    )
  );
};

export default LazyThemeProvider;
