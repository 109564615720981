import { ApplicationScope } from "@superblocksteam/shared";
import { CallEffect, SelectEffect } from "redux-saga/effects";
import logger from "utils/logger";

let originalEvaluateActionBindings:
  | ((
      bindings: string[],
      scope: ApplicationScope,
      executionParams: Record<string, any> | string,
      additionalNamedArguments?: Record<string, any>,
      spanId?: string,
      includeErrors?: boolean,
    ) => Generator<SelectEffect | CallEffect<unknown>, any, unknown>)
  | null = null;

export function* evaluateActionBindings(
  bindings: string[],
  scope: ApplicationScope,
  executionParams: Record<string, any> | string = {},
  additionalNamedArguments?: Record<string, any>,
  spanId?: string,
  includeErrors?: boolean,
): Generator<SelectEffect | CallEffect<unknown>, any, unknown> {
  if (!originalEvaluateActionBindings) {
    logger.error("Unexpected state in evaluateActionBindings");
    throw new Error("Unexpected state");
  }
  const ret = yield* originalEvaluateActionBindings(
    bindings,
    scope,
    executionParams,
    additionalNamedArguments,
    spanId,
    includeErrors,
  );
  return ret;
}

export function setEvaluateActionBindings(fn: typeof evaluateActionBindings) {
  originalEvaluateActionBindings = fn;
}
