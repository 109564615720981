import { BlockType, StepBlock } from "@superblocksteam/shared";
import { get, isEmpty, set } from "lodash";
import { AiContextMode } from "legacy/constants/EditorPreferencesConstants";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { WidgetFactory, WidgetMap } from "legacy/widgets";
import BaseWidget from "legacy/widgets/BaseWidget";
import CustomWidget from "legacy/widgets/CustomWidget/CustomWidget";
import { ControlFlowFrontendDSL } from "store/slices/apisV2/control-flow/types";

const getPropertyPaneConfig = (
  widgetClass: typeof BaseWidget,
): PropertyPaneConfig[] => {
  if (widgetClass.prototype instanceof CustomWidget.constructor) {
    // Custom widgets do not have statically defined properties.
    return [];
  }

  let newConfig: PropertyPaneConfig[] | undefined;
  if (widgetClass.getNewPropertyPaneConfig) {
    newConfig = widgetClass.getNewPropertyPaneConfig();
  }

  if (newConfig && newConfig.length > 0) {
    return newConfig;
  } else if (widgetClass.getPropertyPaneConfig) {
    return widgetClass.getPropertyPaneConfig();
  }

  return [];
};

function removeValues(obj: any, layers: number = -1): any {
  if (layers === 0) return returnEmptyValue(obj);

  if (obj === null) return "null";
  if (obj === undefined) return "";
  // Base cases for primitive types
  if (typeof obj === "string") return "";
  if (typeof obj === "number") return 0;
  if (typeof obj === "boolean") return false;

  const newObj: Record<string, any> = {};

  // Recurse for objects and arrays
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (key === "!doc" && !!obj[key]["value"]) {
        return removeValues(obj[key]["value"], layers - 1);
      }
      if (key.startsWith("!")) {
        continue;
      }
      // Handle arrays separately
      if (Array.isArray(obj[key])) {
        newObj[key] = obj[key]
          .slice(0, 1)
          .map((x: any) => removeValues(x, layers - 1)); // Map each element to its default value
      } else {
        newObj[key] = removeValues(obj[key], layers - 1); // Recurse for nested objects
      }
    }
  }

  return newObj; // Return the modified object
}

function returnEmptyValue(obj: any): any {
  if (obj === null) return "null";
  if (obj === undefined) return "";
  if (typeof obj === "string") return "";
  if (typeof obj === "number") return 0;
  if (typeof obj === "boolean") return false;
  if (Array.isArray(obj)) return [];
  if (typeof obj === "object") return {};

  return "";
}

// Set the values after the props to empty values.
function emptyValueAfterProps(obj: any, propPaths: string[]) {
  const ret = {};
  for (const path of propPaths) {
    set(ret, path, returnEmptyValue(get(obj, path)));
  }
  return ret;
}

export type ContextFile = {
  text: string;
  mode: string;
};

export function getDataTreeAsCode(
  dataTree: Record<string, any>,
  pluginId: "python" | "javascript" | "sql",
  apiDsl: ControlFlowFrontendDSL | undefined,
  widgets: WidgetMap,
  aiContextMode: AiContextMode,
  apiName?: string,
) {
  let code = "";
  const files: Record<string, ContextFile> = {};
  const keysAdded: string[] = [];

  const sqlSteps = Object.entries(apiDsl?.blocks ?? {})
    .filter(
      ([_, block]) =>
        block.type === BlockType.STEP &&
        [
          "postgres",
          "snowflake",
          "bigquery",
          "redshift",
          "mysql",
          "mssql",
          "mariadb",
          "oracle",
        ].includes((block as StepBlock).config.pluginId as string),
    )
    .map(([stepName, _]) => stepName);

  if (pluginId === "sql") {
    files["binding_example"] = { text: "", mode: "sql" };
  }

  const widgetNames = Object.values(widgets).reduce(
    (acc, widget) => ({ ...acc, [widget.widgetName]: widget.type }),
    {} as Record<string, WidgetType>,
  );

  for (const [key, value] of Object.entries(dataTree)) {
    if (isEmpty(value)) {
      continue;
    }
    if (key.startsWith("!")) {
      continue;
    }
    // Exclude the current API from the context. Although you can reference it, it's not suggested.
    if (key === apiName) {
      continue;
    }

    let strValue = "";
    if (aiContextMode === AiContextMode.AllKeys) {
      strValue = JSON.stringify(removeValues(value, -1));
    } else if (widgetNames[key]) {
      // If it's a widget look at the property map.
      const type = widgetNames[key];
      const widgetClass = WidgetFactory.getWidgetClasses()[
        type
      ] as typeof BaseWidget;
      const props = getPropertyPaneConfig(widgetClass);
      const getLeafPaths = (config: PropertyPaneConfig): string[] => {
        if ("propertyName" in config) {
          return [config.propertyName as string];
        }
        return config.children?.flatMap(getLeafPaths);
      };
      const propPaths = props.flatMap(getLeafPaths);
      strValue = JSON.stringify(emptyValueAfterProps(value, propPaths));
    } else {
      // Otherwise do it based on the layer depth. This is for things like APIs, state variables, etc.
      // We own all top level keys.
      let layers = 1;
      if (["Global", "theme"].includes(key)) {
        layers = -1; // -1 means infinite layers
      } else if (sqlSteps.includes(key)) {
        // The first layer is output, the second layer is the array (so the index is 0),
        // the third layer is the object.
        if (aiContextMode === AiContextMode.OnlySuperblocksKeys) {
          layers = 2;
        } else {
          layers = 3;
        }
      }
      strValue = JSON.stringify(removeValues(value, layers));
    }

    if (pluginId === "javascript") {
      keysAdded.push(key);
      code += `// ${key} is a readonly variable\nconst ${key} = Object.freeze(${strValue});\n\n`;
    } else if (pluginId === "python") {
      code += `${key} = ${strValue}\n\n`;
    } else {
      code += `-- const ${key} = ${strValue};\n`;
      if (typeof value === "object") {
        for (const subKey in value) {
          files["binding_example"].text +=
            `SELECT * FROM table WHERE ${subKey} = {{${key}.${subKey}}};\n`;
        }
      }
    }
  }

  return { keysAdded, code, files };
}

export function getContext(
  userAccessibleTree: Record<string, any>,
  pluginId: "python" | "javascript" | "sql",
  apiDsl: ControlFlowFrontendDSL | undefined,
  apiName: string | undefined,
  widgets: WidgetMap,
  aiContextMode: AiContextMode,
) {
  let prefix = "";
  let suffix = "";
  let files: Record<string, ContextFile> = {};

  switch (pluginId) {
    case "python":
      files["requirements"] = {
        text: PYTHON_REQUIREMENTS,
        mode: "txt",
      };
      break;
    case "javascript":
      files["example_imports"] = {
        text: JS_LIBRARY_IMPORTS,
        mode: "javascript",
      };
      break;
    case "sql":
      break;
  }

  const {
    keysAdded,
    code,
    files: dataTreeFiles,
  } = getDataTreeAsCode(
    userAccessibleTree,
    pluginId,
    apiDsl,
    widgets,
    aiContextMode,
    apiName,
  );
  prefix += code;
  files = { ...files, ...dataTreeFiles };

  // Add sufficient whitespace to semantically separate the inline context from the code from other files.
  prefix += `\n\n\n`;
  switch (pluginId) {
    case "javascript":
      prefix += "\n(() => [" + keysAdded.join(", ") + "])();\n";
      prefix += "// If output is an available property try to use it.\n";
      prefix += `(() => {\n`;
      suffix += `\n})();`;
      break;
  }

  if (pluginId === "javascript") {
    suffix += "\nmodule.exports = {}";
  }

  // Add code from other files in the API.
  if (apiDsl) {
    Object.keys(apiDsl.blocks).forEach((blockName) => {
      if (!userAccessibleTree[blockName as keyof DataTree]) return;
      const block = apiDsl.blocks[blockName];
      if (block.type === BlockType.STEP) {
        const typedBlock = block as unknown as StepBlock;
        const { datasourceId, configuration } = typedBlock.config;
        files[blockName] = {
          text: (configuration as any).body ?? "",
          mode: (datasourceId as string) ?? "",
        };
      }
    });
  }

  return { prefix, suffix, files, variableNames: keysAdded };
}

const PYTHON_REQUIREMENTS = `Acquisition==4.13
aiohttp==3.9.5
aiosignal==1.3.1
anthropic==0.25.7
anyio==4.3.0
appdirs==1.4.4
appnope==0.1.4
arrow==1.3.0
asana==2.0.0
asn1crypto==1.5.1
asttokens==2.4.1
async_timeout==4.0.3
asyncio==3.4.3
attrs==22.2.0
AuthEncoding==4.3
Authlib==1.3.2
backcall==0.2.0
backoff==2.2.1
beautifulsoup4==4.12.3
bidict==0.23.1
boto==2.49.0
boto3==1.22.10
botocore==1.25.10
Brotli==1.1.0
bs4==0.0.2
BTrees==4.11.3
CacheControl==0.14.0
cachetools==5.3.3
certifi==2024.7.4
cffi==1.15.0
charset_normalizer==2.0.12
click==8.1.7
cmake==3.24.1.1
ConfigArgParse==1.7
croniter==2.0.7
cryptography==36.0.2
cssselect==1.1.0
cvxpy==1.1.24
cycler==0.11.0
Cython==0.29.37
dataclasses_json==0.5.7
DateTime==4.9
decorator==5.1.1
defusedxml==0.7.1
Deprecated==1.2.14
distro==1.9.0
dnspython==2.6.1
ecos==2.0.14
et_xmlfile==1.1.0
exceptiongroup==1.2.2
executing==0.8.3
ExtensionClass==4.9
fake_useragent==0.1.14
feedparser==6.0.11
filelock==3.15.4
firebase_admin==6.5.0
Flask==2.2.5
Flask_BasicAuth==0.2.0
Flask_Cors==3.0.10
fonttools==4.43.0
fred==3.1
fredapi==0.5.2
frozendict==2.3.7
frozenlist==1.3.1
fsspec==2024.3.1
future==0.18.3
geojson==2.5.0
gevent==21.12.0
geventhttpclient==2.0.12
gocardless_pro==1.32.0
google_api_core==2.18.0
google_api_python_client==2.95.0
google_auth==2.29.0
google_auth_httplib2==0.1.0
google_auth_oauthlib==0.5.3
google_cloud_bigquery==2.31.0
google_cloud_core==2.3.0
google_cloud_firestore==2.16.0
google_cloud_functions==1.16.5
google_cloud_pubsub==2.13.0
google_cloud_storage==1.43.0
google_crc32c==1.3.0
google_resumable_media==2.3.2
googleapis_common_protos==1.63.2
graphviz==0.20.3
greenlet==1.1.3.post0
grpc_google_iam_v1==0.12.4
grpcio==1.53.2
grpcio_status==1.46.0
gspread==5.5.0
h11==0.14.0
html5lib==1.1
httpcore==1.0.5
httplib2==0.22.0
httpx==0.27.0
huggingface_hub==0.23.0
idna==3.7
importlib_metadata==4.11.3
ipython==8.10.0
isodate==0.6.1
itsdangerous==2.1.2
jedi==0.18.1
jeepney==0.8.0
Jinja2==3.1.4
jira==3.2.0
jmespath==1.0.1
joblib==1.3.2
jsondiff==2.0.0
jsonpatch==1.33
jsonpickle==2.1.0
jsonpointer==3.0.0
jycm==1.3.0
kaleido==0.2.1
keyring==23.6.0
kiwisolver==1.4.5
langchain==0.1.20
langchain_community==0.0.38
langchain_core==0.1.52
langchain_text_splitters==0.0.2
langchainplus_sdk==0.0.20
langsmith==0.1.95
locust==2.10.2
loguru==0.6.0
lxml==4.9.4
MarkupSafe==2.1.5
marshmallow==3.18.0
marshmallow_enum==1.5.1
matplotlib==3.5.2
matplotlib_inline==0.1.6
mccabe==0.7.0
moncli==2.0.14
msgpack==1.0.8
multidict==6.0.5
multipart==0.2.4
multitasking==0.0.11
mypy_extensions==0.4.4
mysql_connector_python==8.0.29
nest_asyncio==1.5.6
networkx==2.8.8
nltk==3.8.1
numexpr==2.8.5
numpy==1.26.4
oauthlib==3.2.2
openai==0.27.2
openapi_schema_pydantic==1.2.4
openpyxl==3.0.9
opentelemetry_api==1.15.0
opentelemetry_exporter_otlp_proto_http==1.15.0
opentelemetry_proto==1.15.0
opentelemetry_sdk==1.15.0
opentelemetry_semantic_conventions==0.36b0
orjson==3.10.6
oscrypto==1.3.0
osqp==0.6.7
packaging==23.2
pandas==1.5.3
parse==1.19.0
parso==0.8.3
pathspec==0.10.1
patsy==0.5.6
peewee==3.17.1
Persistence==3.6
persistent==4.9.3
pexpect==4.8.0
phonenumberslite==8.13.31
pickleshare==0.7.5
pillow==10.3.0
pinecone_client==2.2.4
platformdirs==4.2.2
plotly==5.19.0
postgresql_audit==0.17.1
prometheus_client==0.14.1
prompt_toolkit==3.0.43
proto_plus==1.23.0
protobuf==3.20.3
psutil==5.9.8
psycopg2==2.9.9
ptyprocess==0.7.0
pure_eval==0.2.2
pyaes==1.6.1
pyarrow==14.0.1
pyasn1==0.4.8
pyasn1_modules==0.2.8
pybind11==2.10.0
pycodestyle==2.9.1
pycountry==22.3.5
pycparser==2.21
pycryptodomex==3.19.1
pydantic==1.10.14
pydash==6.0.2
pydot==1.4.2
pyee==8.2.2
Pygments==2.17.2
PyJWT==2.8.0
pymongo==4.6.3
pymongo_auth_aws==1.1.0
pymsteams==0.2.2
pyodbc==5.0.1
pyOpenSSL==22.0.0
pyowm==3.3.0
pyparsing==3.0.8
pypdf==3.17.4
pypng==0.20220715.0
pyppeteer==1.0.2
pyquery==1.4.3
PySocks==1.7.1
python_barcode==0.15.1
python_box==5.4.1
python_dateutil==2.8.2
python_docx==0.8.11
python_dotenv==0.21.1
python_engineio==4.8.2
python_gettext==4.1
python_graphql_client==0.4.3
python_json_logger==2.0.7
python_socketio==5.7.2
pytz==2023.3
pyvis==0.2.1
PyYAML==6.0.1
pyzipper==0.3.6
pyzmq==22.3.0
qdldl==0.1.7.post0
qrcode==7.4.2
quickchart.io==1.0.1
redis==4.6.0
regex==2023.12.25
requests==2.32.0
requests_file==1.5.1
requests_futures==1.0.1
requests_html==0.10.0
requests_oauthlib==1.3.1
requests_toolbelt==0.9.1
roundrobin==0.0.4
rsa==4.8
s3transfer==0.5.2
schematics==2.1.1
scikit_learn==1.5.0
scipy==1.14.0
scs==3.2.7
SecretStorage==3.3.3
sgmllib3k==1.0.0
simple_salesforce==1.11.4
simple_websocket==1.0.0
simplejson==3.17.6
six==1.16.0
SJSON==2.0.3
slack_sdk==3.15.1
sniffio==1.3.1
snowflake_connector_python==3.10.1
sortedcontainers==2.4.0
soupsieve==2.3.2.post1
SQLAlchemy==1.4.53
SQLAlchemy_Utils==0.41.2
stack_data==0.2.0
statsmodels==0.13.2
stripe==3.5.0
tablib==3.2.1
tabulate==0.9.0
Telethon==1.34.0
tenacity==8.1.0
threadpoolctl==3.1.0
thrift==0.16.0
tldextract==5.1.2
tokenizers==0.19.1
tomli==2.0.1
tomlkit==0.13.2
tqdm==4.66.3
traitlets==5.2.0
transaction==3.0.1
types_python_dateutil==2.9.0.20240316
types_requests==2.28.11.2
types_simplejson==3.17.7
types_urllib3==1.26.25.14
typing_inspect==0.8.0
typing_extensions==4.11.0
ujson==5.10.0
uritemplate==4.1.1
urllib3==1.26.19
w3lib==1.22.0
wcwidth==0.2.13
webencodings==0.5.1
websockets==10.3
Werkzeug==3.0.3
wrapt==1.14.1
wsproto==1.2.0
xero_python==1.18.0
yahoo_fin==0.8.9.1
yarl==1.8.1
yfinance==0.2.37
zeep==4.2.1
zExceptions==4.2
zipp==3.19.1
zope.browser==2.4
zope.component==5.0.1
zope.configuration==4.4.1
zope.contenttype==4.6
zope.deferredimport==4.4
zope.deprecation==4.4.0
zope.event==4.5.0
zope.exceptions==4.5
zope.hookable==5.2
zope.i18n==4.9.0
zope.i18nmessageid==5.0.1
zope.interface==5.4.0
zope.location==4.2
zope.proxy==4.5.1
zope.publisher==6.1.0
zope.schema==6.2.1
zope.security==5.4
zope.testing==4.10`;

const JS_LIBRARY_IMPORTS = `const _ = require('lodash');

const moment = require('moment');

const axios = require('axios');

const xmlbuilder2 = require('xmlbuilder2');

const base64url = require('base64url');

const jsonwebtoken = require('jsonwebtoken');

const bcrypt = require('bcrypt');

const xml2json = require('xml2json');\n\n`;
