import { ViewMode } from '../event';

export enum ProfileType {
  RESERVED = 'RESERVED',
  CUSTOM = 'CUSTOM'
}

export class Profile {
  id: string;
  key: string;
  displayName: string;
  description: string;
  type: ProfileType;

  constructor({
    id,
    key,
    displayName,
    description,
    type
  }: {
    id: string;
    key: string;
    displayName: string;
    description: string;
    type: ProfileType;
  }) {
    this.id = id;
    this.key = key;
    this.displayName = displayName;
    this.description = description;
    this.type = type;
  }
}

export type EntityModeProfileSettings = {
  availableProfileIds: string[];
  defaultProfileId: string;
};

export type EntityProfileSettings = {
  profiles: {
    [ViewMode.EDITOR]: EntityModeProfileSettings;
    [ViewMode.PREVIEW]?: EntityModeProfileSettings;
    [ViewMode.DEPLOYED]: EntityModeProfileSettings;
  };
};
