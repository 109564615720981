import { useState } from "react";

export const ALL_BLOCKS = "##All blocks"; // as long as this isn't ever a valid block name, should have no conflicts

export type ResultsPanelState = {
  // if string, should correspond to a block name
  blockFilter?: undefined | string | typeof ALL_BLOCKS;
  logSearchText?: string;
  currentTab?: TabNames;
};

export const useResultsPanelState = () => {
  return useState<ResultsPanelState>(() => ({}));
};

export enum TabNames {
  RESULT = "Result",
  REQUEST = "Request",
  CONSOLE = "Console",
  DETAILS = "Details", // only for output-less blocks for now
}
