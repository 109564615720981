import CodeMirror from "codemirror";
import type {
  ApplicationScope,
  DatasourceMetadataDto,
} from "@superblocksteam/shared";
import type { DataTreeDef } from "autocomplete/dataTreeTypeDefCreator";
import type { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import type { ApiScope } from "utils/dataTree/scope";

export enum EditorModes {
  TEXT = "text/plain",
  SQL = "sql",
  TEXT_WITH_BINDING = "text-js",
  JSON = "application/json",
  JSON_WITH_BINDING = "json-js",
  SQL_WITH_BINDING = "sql-js",
  JAVASCRIPT = "javascript-sb",
  PYTHON = "python-sb",
}

export enum EditorTheme {
  LIGHT = "LIGHT",
  DARK = "DARK",
}
export enum TabBehaviour {
  INPUT = "INPUT",
  INDENT = "INDENT",
}

export enum EditorSize {
  COMPACT = "COMPACT",
  EXTENDED = "EXTENDED",
}

export const EditorThemes: Record<EditorTheme, string> = {
  [EditorTheme.LIGHT]: "neo",
  [EditorTheme.DARK]: "neo", //FIXME let's clean up themes properly
};

export type HintHelper = (
  editor: CodeMirror.Editor,
  data: DataTree,
  additionalData: Record<string, Record<string, unknown>> | undefined,
  datasourceMetadata: DatasourceMetadataDto | undefined,
  apiScope: ApiScope | undefined,
  appScope: ApplicationScope,
  configuration: AutocompleteConfiguration,
  openAiAssistant?: () => void,
) => Hinter;

export type Hinter = {
  showHint: (editor: CodeMirror.Editor) => boolean;
  update?: (updateRequest: HinterUpdateRequest) => void;
  unregister: () => void;
};

export type HinterUpdateRequest = {
  data: DataTree;
  additionalData?: Record<string, unknown>;
  datasourceMetadata?: DatasourceMetadataDto;
  apiScope?: ApiScope;
  appScope: ApplicationScope;
  configuration: AutocompleteConfiguration;
};

export enum AutocompleteFunctionsType {
  /** Only functions that are available on the frontend will be present in autocomplete */
  FRONTEND = "frontend",
  /** Only functions that are available on the backend will be present in autocomplete */
  BACKEND = "backend",
}

export type AutocompleteConfiguration = {
  /** Should `Env` object be present in autocomplete */
  env?: boolean;
  /** Should `Global` object be present in autocomplete */
  global?: boolean;
  /** Which (or if) functions Should be present in autocomplete */
  functions?: AutocompleteFunctionsType | false;
  /** Should autocomplete be triggered inside `{{ }}` bindings */
  bindingsDisabled?: boolean;
  /** Additional definitions to be added to autocomplete */
  additionalDefs?: DataTreeDef;
  shouldOpenIconSelectorForIconBindings?: boolean;
  openIconSelector?: () => void;

  // Remove this when we ship table edits
  isProgrammaticTableEnabled?: boolean;
};
