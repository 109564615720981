import { ExclamationCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import {
  ApiTriggerType,
  ApplicationUserStatus,
  DefaultGroupName,
  GroupBrief,
  GroupType,
  isGroupShareEntry,
  isUserShareEntry,
  Organization,
  OrganizationUserDto,
  OrganizationUserStatus,
  PermissionedEntities,
  RbacRole,
  ShareEntryCreationDto,
  ShareEntryDto,
} from "@superblocksteam/shared";
import { Avatar, Space, Typography } from "antd";
import Modal from "antd/lib/modal";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as CodeIcon } from "assets/icons/common/code-bordered.svg";
import { ReactComponent as GroupIcon } from "assets/icons/common/user-three.svg";
import CopyLink from "components/ui/CopyLink";
import DropdownButtonMinimal from "components/ui/DropdownButtonMinimal";
import { ConfirmModalClass } from "components/ui/Modal";
import { Spinner } from "components/ui/Spinner";
import { useFeatureFlag } from "hooks/ui";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { scrollbarLight } from "legacy/constants/DefaultTheme";
import { getApplicationDeployedURL } from "legacy/constants/routes";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import {
  Invitee,
  getAccessText,
  getVersionedPermissionedEntityType,
} from "pages/Permissions/constants";
import PermissionEntriesList from "pages/components/PermissionEntriesList";
import {
  GET_SHARE_ENTRIES,
  GROUPS_FOR_ORG_ID_URL,
  USERS_FOR_ORG_ID_URL,
} from "pages/routes";
import { useAppDispatch } from "store/helpers";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer, HttpMethod } from "store/utils/client";
import { DefaultAvatarColor } from "styles/colorGeneration";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import unreachable from "utils/unreachable";
import { updateApplicationMetadata } from "../../store/slices/application/applicationActions";
import { getCurrentApplication } from "../../store/slices/application/selectors";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "../../utils/notification";
import {
  acceptRequestAccess,
  addShareEntriesForResourceId,
  updateShareEntryForResourceId,
} from "../Permissions/client";
import SearchAndInvite from "./SearchAndInvite";
const { Text } = Typography;

const { confirm } = Modal;

const SHARE_MODAL_WIDTH = 640;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: flex-start;
    gap: 36px;
  }

  .ant-modal-footer > div {
    width: auto;
  }

  .ant-typography {
    display: block;
    margin-bottom: 12px;
  }
`;

const AllUsersRow = styled.div`
  position: relative;
  display: flex;

  justify-content: space-between;
`;

const StyledAvatarWrapper = styled.div`
  span.ant-avatar {
    color: ${DefaultAvatarColor.FRONT};
    background: ${DefaultAvatarColor.BACKGROUND};
  }
`;

const PermissionListWrapper = styled.div`
  margin-top: 18px;
  padding-top: 2px;
  max-height: 500px;
  overflow: auto;
  ${scrollbarLight}
`;

const FooterLinkWrapper = styleAsClass`
  color: ${colors.ACCENT_BLUE_500};
  display: flex;
  align-items: center;
  gap: 3px;
  &:hover {
    cursor: pointer;
    color: ${colors.ACCENT_BLUE_600};
  }
  path {
    stroke: ${colors.ACCENT_BLUE_500};
  }
`;

interface Props {
  isVisible: boolean;
  organizationId: string;
  setShareModalVisible: (visible: boolean) => void;
  resourceId: string;
  resourceType: PermissionedEntities;
  resourceDisplayName: string;
  roles: ShareRole[];
  applicationModalProps?: ApplicationModalProps;
  apiModalProps?: ApiModalProps;
  onModalClose?: (shareEntries: ShareEntryDto[]) => void;
}

export interface ApiModalProps {
  apiTriggerType?: ApiTriggerType;
}

export interface ApplicationModalProps {
  inEditMode: boolean;
}

enum AllUsersScope {
  PUBLIC = "PUBLIC",
  OPEN_TO_ORG = "OPEN_TO_ORG",
  RESTRICTED = "RESTRICTED",
}

export class ShareRole {
  static VIEWER: ShareRole = { label: "Can view", value: "viewer" };
  static BUILDER: ShareRole = { label: "Can build", value: "builder" };
  static BUILD_WITH: ShareRole = { label: "Can build with", value: "builder" };
  static CONFIGURATOR: ShareRole = {
    label: "Can configure",
    value: "configurator",
  };
  label: string;
  value: "viewer" | "builder" | "configurator";

  constructor(label: string, value: "viewer" | "builder") {
    this.label = label;
    this.value = value;
  }
}

const rbacRoleToAllUsersScope = {
  [RbacRole.VIEWER]: AllUsersScope.OPEN_TO_ORG,
  [RbacRole.BUILDER]: AllUsersScope.OPEN_TO_ORG,
  [RbacRole.CONFIGURATOR]: AllUsersScope.OPEN_TO_ORG,
  [RbacRole.OWNER]: AllUsersScope.OPEN_TO_ORG,
  [RbacRole.NONE]: AllUsersScope.RESTRICTED,
  [RbacRole.EXECUTOR]: AllUsersScope.RESTRICTED, //will not be run, since executor is only for app apis
};

// XXX(pbardea): Encountered two children with the same key `roles`
const allUsersScopeToDefaultRbacRole = (
  allUsersScope: AllUsersScope,
  resourceType: PermissionedEntities,
): RbacRole => {
  switch (allUsersScope) {
    case AllUsersScope.PUBLIC:
      return RbacRole.VIEWER;
    case AllUsersScope.RESTRICTED:
      return RbacRole.NONE;
    case AllUsersScope.OPEN_TO_ORG:
      switch (resourceType) {
        case PermissionedEntities.APPLICATION:
          return RbacRole.VIEWER;
        case PermissionedEntities.API:
          return RbacRole.BUILDER;
        case PermissionedEntities.DATASOURCE:
        case PermissionedEntities.INTEGRATION:
          return RbacRole.CONFIGURATOR;
        case PermissionedEntities.API_V3:
          return RbacRole.BUILDER;
        case PermissionedEntities.APPLICATION_V2:
          return RbacRole.VIEWER;
        default:
          unreachable(resourceType);
      }
  }
};

const openOrgAccessText = ({
  allUsersScope,
  organization,
  resourceType,
  apiTriggerType,
}: {
  allUsersScope: AllUsersScope;
  organization: Organization;
  resourceType: PermissionedEntities;
  apiTriggerType?: ApiTriggerType;
}) => {
  switch (resourceType) {
    case PermissionedEntities.API:
    case PermissionedEntities.API_V3:
      switch (apiTriggerType) {
        case ApiTriggerType.WORKFLOW:
          return `Everyone in ${organization.name} can build this workflow`;
        case ApiTriggerType.SCHEDULE:
          return `Everyone in ${organization.name} can build this scheduled job`;
      }
      break;
    case PermissionedEntities.DATASOURCE:
    case PermissionedEntities.INTEGRATION:
    case PermissionedEntities.APPLICATION:
    case PermissionedEntities.APPLICATION_V2:
      switch (allUsersScope) {
        case AllUsersScope.PUBLIC:
          return `Public to anyone on the internet`;
        case AllUsersScope.OPEN_TO_ORG:
          return `Everyone in ${organization.name}`;
      }
  }
  return `-`;
};

const restrictedOrgAccessText = ({
  resourceType,
  apiTriggerType,
}: {
  resourceType: PermissionedEntities;
  apiTriggerType?: ApiTriggerType;
}) => {
  switch (resourceType) {
    case PermissionedEntities.API:
    case PermissionedEntities.API_V3:
      switch (apiTriggerType) {
        case ApiTriggerType.WORKFLOW:
          return `Only invited users can build this workflow`;
        case ApiTriggerType.SCHEDULE:
          return `Only invited users can build this schedule job`;
      }
      break;
    case PermissionedEntities.DATASOURCE:
    case PermissionedEntities.INTEGRATION:
      return `Only invited users can configure or build with this integration`;
    case PermissionedEntities.APPLICATION:
    case PermissionedEntities.APPLICATION_V2:
      return `Only invited users can access this application`;
  }
  return "-";
};

const roleTooltipText = ({
  resourceType,
  apiTriggerType,
}: {
  resourceType: PermissionedEntities;
  apiTriggerType?: ApiTriggerType;
}) => {
  switch (resourceType) {
    case PermissionedEntities.API:
    case PermissionedEntities.API_V3:
      switch (apiTriggerType) {
        case ApiTriggerType.WORKFLOW:
          return `You can only invite new builders to a workflow`;
        case ApiTriggerType.SCHEDULE:
          return `You can only invite new builders to a scheduled job`;
      }
      break;
    case PermissionedEntities.DATASOURCE:
    case PermissionedEntities.INTEGRATION:
    case PermissionedEntities.APPLICATION:
    case PermissionedEntities.APPLICATION_V2:
      return undefined;
  }
};

const orgRoleChangeSuccessNotificationText = ({
  organization,
  role,
  resourceType,
  resourceDisplayName,
}: {
  organization: Organization;
  role: RbacRole;
  resourceType: PermissionedEntities;
  resourceDisplayName: string;
}) => {
  switch (role) {
    case RbacRole.NONE: {
      if (
        resourceType === PermissionedEntities.INTEGRATION ||
        resourceType === PermissionedEntities.DATASOURCE
      ) {
        return `Only people invited can configure or bulid with ${resourceDisplayName} now`;
      }
      return `Only people invited can access ${resourceDisplayName} now`;
    }
    case RbacRole.BUILDER: {
      return `Everyone in ${organization.name} can build ${resourceDisplayName} now`;
    }
    case RbacRole.VIEWER: {
      return `Everyone in ${organization.name} can view ${resourceDisplayName} now`;
    }
    case RbacRole.CONFIGURATOR: {
      return `Everyone in ${organization.name} can configure and build with ${resourceDisplayName} now`;
    }
    default: {
      // This shouldn't happen
      return ``;
    }
  }
};

const ShareModal = ({
  isVisible,
  resourceId,
  organizationId,
  setShareModalVisible,
  resourceType,
  resourceDisplayName,
  roles,
  applicationModalProps,
  apiModalProps,
  onModalClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const application = useSelector(getCurrentApplication);
  const versionedResourceType =
    getVersionedPermissionedEntityType(resourceType);
  useEffect(() => {
    (async () => {
      if (
        application &&
        versionedResourceType === PermissionedEntities.APPLICATION_V2
      ) {
        setPublic(!!application.isPublic);
      }
    })();
  }, [dispatch, resourceId, versionedResourceType, isVisible, application]);

  // Autocomplete
  const [allUsersForOrg, setUsers] = useState<OrganizationUserDto[]>([]);
  const [allGroupsForOrg, setGroups] = useState<GroupBrief[]>([]);

  const updateApplicationDispatch = useCallback(
    (id: string, data: { isPublic: boolean }) => {
      dispatch(updateApplicationMetadata(id, data));
    },
    [dispatch],
  );
  const [isPublic, setPublic] = useState(false);
  const handleTogglePublic = useCallback(
    (newIsPublic: boolean) => {
      if (resourceId) {
        if (application) {
          updateApplicationDispatch(resourceId || "", {
            isPublic: newIsPublic,
          });
        }
        setPublic(newIsPublic);
        newIsPublic &&
          sendSuccessUINotification({
            message: newIsPublic
              ? `Anyone on the internet with this link can view ${resourceDisplayName} now`
              : `Only people invited can view ${resourceDisplayName} now`,
          });
      }
    },
    [application, resourceDisplayName, resourceId, updateApplicationDispatch],
  );

  const loadAutocomplete = useCallback(async () => {
    function getUsersForOrgId(orgId: string) {
      return callServer<OrganizationUserDto[]>(
        {
          method: HttpMethod.Get,
          url: USERS_FOR_ORG_ID_URL(),
          params: { orgId },
        },
        {
          notifyOnError: false,
        },
      );
    }

    function getGroupsForOrg(orgId: string) {
      return callServer<{ groups: GroupBrief[] }>(
        {
          method: HttpMethod.Get,
          url: GROUPS_FOR_ORG_ID_URL(),
          params: { orgId },
        },
        {
          notifyOnError: false,
        },
      );
    }

    const retUsers = await getUsersForOrgId(organizationId);
    const retGroups = await getGroupsForOrg(organizationId);
    setUsers(retUsers);
    setGroups(retGroups.groups);
  }, [setUsers, organizationId]);

  // Share entries list
  const currentUser = useSelector(getCurrentUser);
  const currentOrg = useSelector(selectOnlyOrganization);

  const [shareEntries, setShareEntries] = useState<ShareEntryDto[]>([]);
  const [isShareEntriesLoading, setIsShareEntriesLoading] = useState(false);

  const loadShareEntries = useCallback(async () => {
    function getShareEntries(resourceId: string) {
      return callServer<ShareEntryDto[]>(
        {
          method: HttpMethod.Get,
          url: GET_SHARE_ENTRIES(versionedResourceType),
          params: { resourceId: resourceId },
        },
        {
          notifyOnError: false,
        },
      );
    }

    if (!resourceId) {
      return;
    }
    setIsShareEntriesLoading(true);
    getShareEntries(resourceId)
      .then((newshareEntries) => {
        setShareEntries(newshareEntries ?? []);
      })
      .catch((error) => {
        sendErrorUINotification({
          message: `Failed to pull users ${error?.message}`,
        });
      })
      .finally(() => {
        setIsShareEntriesLoading(false);
      });
  }, [resourceId, versionedResourceType]);

  useEffect(() => {
    if (isVisible) {
      // reload users list on modal open
      loadAutocomplete();
      loadShareEntries();
    }
  }, [isVisible, loadAutocomplete, loadShareEntries]);

  const allUsersGroup = allGroupsForOrg.find(
    (group) => group.type === GroupType.EVERYONE,
  );

  const allUsersGroupName = allUsersGroup?.name || DefaultGroupName.EVERYONE;

  const allUsersGroupShareEntry = shareEntries.find(
    (entry) => entry.type === "group" && entry.name === allUsersGroupName,
  );

  const allUsersRbacRole = allUsersGroupShareEntry?.role ?? RbacRole.NONE;
  const currentAllUsersScope = isPublic
    ? AllUsersScope.PUBLIC
    : rbacRoleToAllUsersScope[allUsersRbacRole];

  const upsertShareEntries = useCallback(
    async (
      invitees: { type: "user" | "group"; lookupId: string; name: string }[],
      role: RbacRole,
      isAllUsersGroup?: boolean,
    ) => {
      const shareEntriesToAdd: (ShareEntryCreationDto & {
        name: string;
      })[] = invitees.map((invitee) => ({
        type: invitee.type,
        lookupId: invitee.lookupId,
        role,
        name: invitee.name,
      }));

      setIsShareEntriesLoading(true);
      return addShareEntriesForResourceId(
        resourceId,
        versionedResourceType,
        shareEntriesToAdd,
      )
        .then((newShareEntries) => {
          setShareEntries(newShareEntries ?? []);
          sendSuccessUINotification({
            message: isAllUsersGroup
              ? orgRoleChangeSuccessNotificationText({
                  organization: currentOrg,
                  role,
                  resourceType,
                  resourceDisplayName,
                })
              : versionedResourceType === PermissionedEntities.INTEGRATION ||
                  versionedResourceType === PermissionedEntities.DATASOURCE
                ? `${shareEntriesToAdd
                    .map((entry) => entry.name)
                    .join(", ")} ${getAccessText(
                    role,
                  )} ${resourceDisplayName} now`
                : `Invites sent to ${shareEntriesToAdd
                    .map((entry) => entry.name)
                    .join(", ")}`,
          });
        })
        .catch((error) => {
          sendErrorUINotification({
            message: `Failed add access ${error?.message}`,
          });
          throw error;
        })
        .finally(() => {
          setIsShareEntriesLoading(false);
        });
    },
    [
      resourceId,
      resourceType,
      versionedResourceType,
      currentOrg,
      resourceDisplayName,
    ],
  );

  const updateShareEntry = useCallback(
    async (shareEntry: ShareEntryDto, isDelete?: boolean) => {
      setIsShareEntriesLoading(true);
      updateShareEntryForResourceId(
        resourceId,
        versionedResourceType,
        shareEntry,
        isDelete,
      )
        .then((newshareEntries) => {
          setShareEntries(newshareEntries ?? []);
          sendSuccessUINotification({
            message: isDelete
              ? `Access removed for ${shareEntry.name}`
              : `${
                  shareEntry.type === "group" &&
                  shareEntry.name === allUsersGroupName
                    ? shareEntry.role === RbacRole.NONE
                      ? `Only people invited`
                      : `Everyone in ${currentOrg.name} `
                    : shareEntry.name
                } ${getAccessText(shareEntry.role)} ${resourceDisplayName} now`,
          });
        })
        .catch((error) => {
          sendErrorUINotification({
            message: `Failed to update user. ${error?.message}`,
          });
        })
        .finally(() => {
          setIsShareEntriesLoading(false);
        });
    },
    [
      resourceId,
      versionedResourceType,
      allUsersGroupName,
      currentOrg.name,
      resourceDisplayName,
    ],
  );

  const handleCancel = useCallback(() => {
    setShareModalVisible(false);
    onModalClose?.(shareEntries);
  }, [onModalClose, setShareModalVisible, shareEntries]);

  const groupInvitees = useMemo(() => {
    return allGroupsForOrg
      .filter(
        (group) =>
          group.type !== GroupType.ADMIN && group.type !== GroupType.EVERYONE,
      )
      .map(
        (group) =>
          ({
            name: group.name,
            type: "group",
            id: group.id,
            size: group.size,
          }) as Invitee,
      );
  }, [allGroupsForOrg]);

  const allUsersDropdownOnSelect = useCallback(
    (value: any) => {
      //if switch to all users, set viewer by default
      if (allUsersGroup) {
        switch (value) {
          case AllUsersScope.PUBLIC:
            handleTogglePublic(true);
            break;
          case AllUsersScope.OPEN_TO_ORG:
          case AllUsersScope.RESTRICTED:
            handleTogglePublic(false);
            upsertShareEntries(
              [
                {
                  type: "group",
                  lookupId: allUsersGroup.id,
                  name: DefaultGroupName.EVERYONE,
                },
              ],
              allUsersScopeToDefaultRbacRole(value, resourceType),
              true,
            );
            break;
        }
      }
    },
    [allUsersGroup, handleTogglePublic, upsertShareEntries, resourceType],
  );

  const groupMap = useMemo(() => {
    const groupMap: { [key: string]: GroupBrief } = {};
    allGroupsForOrg.forEach((group) => {
      groupMap[group.name] = group;
    });
    return groupMap;
  }, [allGroupsForOrg]);

  const allShareEntries = useMemo(() => {
    const getSortPriority = (entry: ShareEntryDto) => {
      if (isUserShareEntry(entry)) {
        if (entry.email === currentUser?.email) {
          return 0;
        }
        if (entry.status === ApplicationUserStatus.EDITOR_PENDING_JOIN) {
          return 2;
        }
      }
      return 1;
    };

    return shareEntries
      .filter(
        (entry) =>
          !(entry.type === "group" && entry.name === allUsersGroupName),
      )
      .sort((a, b) => {
        const diff = getSortPriority(a) - getSortPriority(b);
        if (diff < 0) {
          return -1;
        } else if (diff > 0) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
      .map((shareEntry) => ({
        ...shareEntry,
        ...(shareEntry.type === "group" && groupMap[shareEntry.name]?.size
          ? { size: groupMap[shareEntry.name]?.size }
          : {}),
      }));
  }, [allUsersGroupName, currentUser?.email, groupMap, shareEntries]);

  const allUserDropdownOptions = useMemo(() => {
    const commonOptions = [
      {
        dataTest: "all-users-scope-option-org",
        key: "open-to-org",
        value: AllUsersScope.OPEN_TO_ORG,
        text: openOrgAccessText({
          allUsersScope: AllUsersScope.OPEN_TO_ORG,
          organization: currentOrg,
          resourceType,
          apiTriggerType: apiModalProps?.apiTriggerType,
        }),
      },
      {
        dataTest: "all-users-scope-option-invited",
        key: "restricted",
        value: AllUsersScope.RESTRICTED,
        text: restrictedOrgAccessText({
          resourceType,
          apiTriggerType: apiModalProps?.apiTriggerType,
        }),
      },
    ];
    versionedResourceType === PermissionedEntities.APPLICATION_V2 &&
      commonOptions.push({
        dataTest: "public-option",
        key: "public",
        value: AllUsersScope.PUBLIC,
        text: openOrgAccessText({
          allUsersScope: AllUsersScope.PUBLIC,
          organization: currentOrg,
          resourceType,
          apiTriggerType: apiModalProps?.apiTriggerType,
        }),
      });

    return commonOptions;
  }, [
    apiModalProps?.apiTriggerType,
    currentOrg,
    resourceType,
    versionedResourceType,
  ]);

  const allInvitees = useMemo(() => {
    const invitedUsersSet = new Set(
      shareEntries.filter(isUserShareEntry).map((entry) => entry.email),
    );
    const invitedGroupsSet = new Set(
      shareEntries.filter(isGroupShareEntry).map((entry) => entry.name),
    );
    return [
      ...allUsersForOrg
        .filter((user) => user.status !== OrganizationUserStatus.INACTIVE)
        .map(
          (user) =>
            ({
              name: user.name,
              email: user.email,
              type: "user",
              id: user.id,
            }) as Invitee,
        ),
      ...groupInvitees,
    ].filter((invitee) => {
      if (invitee.type === "group") {
        return !invitedGroupsSet.has(invitee.name);
      }
      if (invitee.type === "user") {
        return !invitedUsersSet.has(invitee.email ?? "");
      }
      return false;
    });
  }, [allUsersForOrg, groupInvitees, shareEntries]);

  const allUsersIcon = useMemo(() => {
    switch (currentAllUsersScope) {
      case AllUsersScope.PUBLIC: {
        return <GlobalOutlined style={{ fontSize: "24" }} />;
      }
      case AllUsersScope.OPEN_TO_ORG: {
        return <GroupIcon width="24" />;
      }
      case AllUsersScope.RESTRICTED: {
        return <Icon width="15" height="15" icon="fa:lock" />;
      }
    }
  }, [currentAllUsersScope]);

  const urlParams = new URLSearchParams(window.location.search);
  const accessRequestId = urlParams.get("access-request-id");
  //get an accessRequest id from url and use that to get the request info
  const handleAccessRequest = useCallback(
    async (accessRequestId: string) => {
      try {
        const { permissions, requester, role } =
          await acceptRequestAccess(accessRequestId);
        const accessText = getAccessText(role);
        setShareEntries(permissions ?? []);
        sendSuccessUINotification({
          message: `${requester.name} ${accessText} ${resourceDisplayName} now`,
        });
      } catch (err: any) {
        sendErrorUINotification({
          message: `Failed to grant access: ${err?.message}`,
        });
      }
    },
    [resourceDisplayName],
  );

  useEffect(() => {
    if (accessRequestId) {
      setShareModalVisible(true);
      handleAccessRequest(accessRequestId);
    }
  }, [accessRequestId, handleAccessRequest, setShareModalVisible]);

  const onClickSetupConfig = useCallback(() => {
    dispatch(
      updateApplicationSidebarKey({
        selectedKey: SideBarKeys.EMBEDDING,
      }),
    );
    setShareModalVisible(false);
  }, [dispatch, setShareModalVisible]);

  const enableEmbed = useFeatureFlag(Flag.ENABLE_EMBED);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <StyledModal
      open={isVisible}
      destroyOnClose
      data-test={`share-modal-${isVisible ? "visible" : "hidden"}`}
      onCancel={handleCancel}
      title={`Share ${resourceDisplayName}`}
      centered
      width={SHARE_MODAL_WIDTH}
      footer={
        versionedResourceType === PermissionedEntities.APPLICATION_V2 && [
          <CopyLink
            key="share-link"
            url={window.location.origin + getApplicationDeployedURL(resourceId)}
            text="Copy Link"
          />,
          ...(enableEmbed && applicationModalProps?.inEditMode
            ? [
                <div
                  key="embed-opener"
                  className={FooterLinkWrapper}
                  onClick={onClickSetupConfig}
                >
                  <CodeIcon /> Setup embed
                </div>,
              ]
            : []),
        ]
      }
    >
      <div ref={parentRef}>
        <SearchAndInvite
          allOptions={allInvitees}
          onAddInvitees={upsertShareEntries}
          roles={roles}
          roleTooltip={roleTooltipText({
            resourceType,
            apiTriggerType: apiModalProps?.apiTriggerType,
          })}
        />
        <Spinner spinning={isShareEntriesLoading}>
          <PermissionListWrapper>
            <AllUsersRow>
              <Space>
                <StyledAvatarWrapper>
                  <Avatar icon={allUsersIcon} />
                </StyledAvatarWrapper>
                <DropdownButtonMinimal
                  dataTest="all-users-scope-select"
                  key="roles"
                  value={currentAllUsersScope}
                  defaultValue={currentAllUsersScope}
                  onValueChange={allUsersDropdownOnSelect}
                  heavyStyle={true}
                  options={allUserDropdownOptions}
                />
              </Space>
              {roles &&
                roles.length > 1 &&
                !isPublic &&
                allUsersGroupShareEntry &&
                allUsersGroupShareEntry.role !== RbacRole.NONE && (
                  <DropdownButtonMinimal
                    data-test="all-users-role-select"
                    key="roles"
                    value={allUsersGroupShareEntry?.role}
                    onValueChange={(role) => {
                      updateShareEntry({
                        ...allUsersGroupShareEntry,
                        role,
                      });
                    }}
                    options={roles.map(({ label, value }) => ({
                      key: value,
                      value: value,
                      text: label,
                    }))}
                  />
                )}
            </AllUsersRow>

            {allShareEntries.length > 0 && (
              <PermissionEntriesList
                resourceType={versionedResourceType}
                currentUser={currentUser}
                permissionEntries={allShareEntries}
                roles={roles}
                updateRole={(shareEntry: ShareEntryDto, roleAction: string) => {
                  if (
                    (Object.values(RbacRole) as string[]).includes(roleAction)
                  ) {
                    const newShareEntry: ShareEntryDto = {
                      ...shareEntry,
                      role: roleAction as RbacRole,
                    };
                    updateShareEntry(newShareEntry);
                  }
                }}
                onRemoveEntry={(shareEntry: ShareEntryDto) => {
                  confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <Text data-test="share-entry-remove-confirmation">{`Remove access for ${shareEntry.name}?`}</Text>
                    ),
                    onOk() {
                      updateShareEntry(shareEntry, true);
                    },
                    className: `share-entry-remove-confirmation ${ConfirmModalClass}`,
                    cancelText: "Cancel",
                    okText: "Confirm",
                    okType: "danger",
                    centered: true,
                  });
                }}
              />
            )}
          </PermissionListWrapper>
        </Spinner>
      </div>
    </StyledModal>
  );
};

export default ShareModal;
