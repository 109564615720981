import { CloseCircleOutlined } from "@ant-design/icons";
import { Popover, PopoverProps, Space, Tooltip, Typography } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { STORAGE_KEYS } from "legacy/utils/StorageUtils";
import localStorage from "legacy/utils/localStorage";

interface IntercomPopoverProps extends PopoverProps {
  isPaidPlan: boolean;
}

export const IntercomPopover = (props: IntercomPopoverProps) => {
  const theme = useTheme();
  const [hasDismissed, setHasDismissed] = useState(
    !!localStorage.getItem(STORAGE_KEYS.SHOW_INTERCOM_POPOVER_KEY),
  );

  const isPaidPlan = props.isPaidPlan;

  const paidChatPrompt = "Chat with a Superblocks Engineer";
  const chatPrompt = useMemo(() => {
    if (isPaidPlan) {
      return paidChatPrompt;
    } else {
      return (
        <>
          Live chat is available on the Enterprise plan. For support, please
          contact{" "}
          <a
            href="mailto:help@superblocks.com"
            style={{
              textDecoration: "underline",
              color: theme.colors.GREY_100,
            }}
          >
            help@superblocks.com
          </a>{" "}
          with the details of your question.
        </>
      );
    }
  }, [isPaidPlan, theme.colors.GREY_100]);

  const onDismiss = useCallback(() => {
    localStorage.setItem(STORAGE_KEYS.SHOW_INTERCOM_POPOVER_KEY, "true");
    setHasDismissed(true);
  }, []);

  const content = useMemo(
    () => (
      <Space direction="horizontal">
        <Typography.Text>{chatPrompt}</Typography.Text>
        <CloseCircleOutlined
          style={{ cursor: "pointer", color: theme.colors.GREY_300 }}
          onClick={onDismiss}
        />
      </Space>
    ),
    [onDismiss, chatPrompt, theme.colors.GREY_300],
  );
  const popoverRef = useRef<any>();

  return isPaidPlan ? (
    <Popover
      content={content}
      placement="right"
      open={!hasDismissed}
      ref={(el) => {
        popoverRef.current = el;
      }}
    >
      {hasDismissed ? (
        <Tooltip title={chatPrompt} placement="right">
          {props.children}
        </Tooltip>
      ) : (
        props.children
      )}
    </Popover>
  ) : (
    <Tooltip
      title={chatPrompt}
      placement="right"
      overlayStyle={{ maxWidth: 400 }}
    >
      {props.children}
    </Tooltip>
  );
};
