import { Space, Modal } from "antd";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormText } from "../FormText";

type Props = DynamicFormItemProps;

const StyledSpace = styled(Space)`
  max-height: 80vh;
  overflow: auto;

  pre {
    font-size: 12px;
    white-space: pre-wrap;
    background: ${(props) => props.theme.colors.GREY_25};
    padding: 16px;
    border: 1px solid ${(props) => props.theme.colors.GREY_100};
    border-radius: 4px;
  }
`;

const LinkButton = styled.span`
  display: block;
  padding-top: 24px;
  margin-top: 38px;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_100};

  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  cursor: pointer;
`;

export const DynamicSqlPreview = (props: Props) => {
  const context = useContext(FormContext);
  const [isOpen, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  return (
    <>
      <LinkButton role="button" onClick={toggleModal}>
        Preview SQL operation
      </LinkButton>
      <Modal
        width={"600px"}
        open={isOpen}
        destroyOnClose={true}
        onCancel={toggleModal}
        title={"Preview SQL Operation"}
        footer={null}
      >
        <StyledSpace direction="vertical">
          <FormText>
            The SQL update mode protects your data by running validation queries
            before saving. Every row in your update must have a unique match in
            your database. All validation is done in a transaction, which
            prevents partial updates.
          </FormText>
          <FormText>Example validation queries with Postgres syntax:</FormText>
          <pre>
            {`CREATE TEMP TABLE temptable1
( "unique_col" coltype, "update_me" coltype)
ON COMMIT DROP`}
            ;
          </pre>
          <FormText>
            Note: The database connection must have permissions to CREATE TEMP
            TABLE. Temp tables are used for performance reasons.
          </FormText>
          <pre>{`INSERT INTO temptable1 (VALUES ($1, $2), ($3, $4));
CREATE INDEX "temptable1_idx" ON temptable1 ("unique_col");
ANALYZE temptable1;`}</pre>
          <pre>{`SELECT COUNT(*) as count, COUNT("${
            context.getValue("table") ?? "tablename"
          }"."unique_col") as non_null FROM temptable1
LEFT JOIN "${context.getValue("table") ?? "tablename"}" ON "${
            context.getValue("table") ?? "tablename"
          }"."unique_col" = temptable1."unique_col"`}</pre>
          <FormText>
            If all previous validations pass, the final update will be executed:
          </FormText>
          <pre>{`UPDATE "${context.getValue("table") ?? "tablename"}"
SET "update_me" = temptable1."update_me"
FROM temptable1
WHERE "${
            context.getValue("table") ?? "tablename"
          }"."unique_col" = temptable1."unique_col"`}</pre>
        </StyledSpace>
      </Modal>
    </>
  );
};
