import { RoleDto, RoleTypeEnum } from "@superblocksteam/shared";
import { set } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getRoleDataMap,
  getRolesToSave,
  PermissionEdits,
} from "pages/Permissions/Shared";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { useUpdateRolesMutation } from "store/slices/reduxApi/rbac";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";

type PartialRecord<K extends PropertyKey, T> = Partial<Record<K, T>>;

export const useEditPermissions = ({
  rolesByType,
}: {
  rolesByType: PartialRecord<RoleTypeEnum, RoleDto[]>;
}) => {
  const organizationId = useSelector(selectOnlyOrganizationId);
  const { originalDataMapByType, resourceAndActionToPermissionIdByType } =
    useMemo(() => {
      const originalDataMapByType: PartialRecord<
        RoleTypeEnum,
        PermissionEdits
      > = {};
      const resourceAndActionToPermissionIdByType: PartialRecord<
        RoleTypeEnum,
        Record<string, string>
      > = {};
      (Object.keys(rolesByType) as RoleTypeEnum[]).forEach((roleType) => {
        const roles = rolesByType[roleType];
        const { dataMap, resourceAndActionToPermissionId } =
          getRoleDataMap(roles);
        originalDataMapByType[roleType] = dataMap;
        resourceAndActionToPermissionIdByType[roleType] =
          resourceAndActionToPermissionId;
      });
      return {
        originalDataMapByType,
        resourceAndActionToPermissionIdByType,
      };
    }, [rolesByType]);

  const [permissionEditsByType, setPermissionEditsByType] = useState<
    Record<string, PermissionEdits>
  >({});

  const [numEdits, setNumEdits] = useState(0);
  const handleEdit = useCallback(
    (change: {
      updates: Record<string, boolean>;
      roleId: string;
      roleType: RoleTypeEnum;
    }) => {
      const { roleId, roleType, updates } = change;
      // for each update, check if it is already in the edit state
      // if so, remove it and decrement the numEdits
      // if not, add it and increment the numEdits
      const newPermissionEdits = { ...permissionEditsByType };
      let editCount = numEdits;
      Object.entries(updates).forEach(([key, value]) => {
        if (value === permissionEditsByType?.[roleType]?.[roleId]?.[key]) {
          // if value in update is the same as the current edits, the update is already handled
          return;
        }

        const originalValue =
          originalDataMapByType?.[roleType]?.[roleId]?.[key] === true;

        if (
          originalValue === value &&
          permissionEditsByType?.[roleType]?.[roleId]?.[key] != null
        ) {
          // delete the edit
          set(newPermissionEdits, [roleType, roleId, key], undefined);
          editCount -= 1;
        } else if (originalValue !== value) {
          // add the edit
          set(newPermissionEdits, [roleType, roleId, key], value);
          editCount += 1;
        }
      });
      setNumEdits(editCount);
      setPermissionEditsByType(newPermissionEdits);
    },

    [originalDataMapByType, permissionEditsByType, numEdits],
  );

  const handleAllowAll = useCallback(
    ({ roleId, roleType }: { roleId: string; roleType: RoleTypeEnum }) => {
      // 1. clear all of the existing edits for the role and decrement the edit count
      const newPermissionEdits = { ...permissionEditsByType };
      let editCount = numEdits;
      Object.entries(permissionEditsByType?.[roleType]?.[roleId] ?? {}).forEach(
        ([key, value]) => {
          if (value != null) {
            set(newPermissionEdits, [roleType, roleId, key], undefined);
            editCount -= 1;
          }
        },
      );
      // 2. go through the original role and for everything previously not set to true, update
      // to true + increment the edit count
      Object.keys(
        resourceAndActionToPermissionIdByType[roleType] ?? {},
      ).forEach((key) => {
        if (originalDataMapByType?.[roleType]?.[roleId]?.[key] !== true) {
          set(newPermissionEdits, [roleType, roleId, key], true);
          editCount += 1;
        }
      });
      setNumEdits(editCount);
      setPermissionEditsByType(newPermissionEdits);
    },
    [
      originalDataMapByType,
      permissionEditsByType,
      numEdits,
      resourceAndActionToPermissionIdByType,
    ],
  );

  const handleClearAll = useCallback(
    ({ roleId, roleType }: { roleId: string; roleType: RoleTypeEnum }) => {
      // 1. clear all of the existing edits for the role and decrement the edit count
      const newPermissionEdits = { ...permissionEditsByType };
      let editCount = numEdits;
      Object.entries(permissionEditsByType?.[roleType]?.[roleId] ?? {}).forEach(
        ([key, value]) => {
          if (value != null) {
            set(newPermissionEdits, [roleType, roleId, key], undefined);
            editCount -= 1;
          }
        },
      );
      // 2. go through the original role and for everything previously set to true,
      // set it to false + increment the edit count
      Object.entries(originalDataMapByType?.[roleType]?.[roleId] ?? {}).forEach(
        ([key, value]) => {
          if (value === true) {
            set(newPermissionEdits, [roleType, roleId, key], false);
            editCount += 1;
          }
        },
      );
      setNumEdits(editCount);
      setPermissionEditsByType(newPermissionEdits);
    },
    [originalDataMapByType, permissionEditsByType, numEdits],
  );

  const handleReset = useCallback(() => {
    setPermissionEditsByType({});
    setNumEdits(0);
  }, []);

  const [updateRoles, { isLoading: isSaving }] = useUpdateRolesMutation();
  const handleSave = useCallback(async () => {
    const allRolesToSave: Partial<RoleDto>[] = [];
    (Object.keys(rolesByType) as RoleTypeEnum[]).forEach((roleType) => {
      const rolesToSave = getRolesToSave({
        originalRoles: rolesByType[roleType] ?? [],
        edits: permissionEditsByType[roleType],
        resourceAndActionToPermissionId:
          resourceAndActionToPermissionIdByType[roleType] ?? {},
      });
      allRolesToSave.push(...rolesToSave);
    });

    if (allRolesToSave.length === 0) {
      return;
    }
    const response = await updateRoles({
      roles: allRolesToSave,
      organizationId: organizationId ?? "",
    });
    if (response.error) {
      sendErrorUINotification({
        message: (response.error as any)?.error ?? "Failed to update roles",
      });
    } else {
      // add a timeout to avoid emptying the edits before GET roles call is completed
      setTimeout(handleReset, 500);
      sendSuccessUINotification({
        message: "Roles updated",
      });
    }
  }, [
    rolesByType,
    updateRoles,
    organizationId,
    permissionEditsByType,
    resourceAndActionToPermissionIdByType,
    handleReset,
  ]);

  return {
    handleEdit,
    handleAllowAll,
    handleClearAll,
    handleReset,
    handleSave,
    permissionEditsByType,
    numEdits,
    isSaving,
  };
};
