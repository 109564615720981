import equal from "@superblocksteam/fast-deep-equal/es6";
import { createDraft, finishDraft } from "immer";
import { call, select } from "redux-saga/effects";
import replayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { EntitiesErrorType } from "store/utils/types";
import { fastClone } from "utils/clone";
import { createSaga, callSagas } from "../../../utils/saga";
import * as BackendTypes from "../backend-types";
import {
  renameBlock,
  updateBlockConfig,
  dropBlock,
  updateDSL,
  addNewControlBlock,
  addNewStepBlock,
  setActionConfig,
  deleteBlock,
  MutationReturnUnion,
  MutationPayloadMap,
  MutationReturnWithMeta,
} from "../control-flow/ControlFlowMutator";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { convertDSLToBackendBlocks } from "../control-flow/dsl-converters";
import { selectV2ApiById, selectV2ApiMeta } from "../selectors";
import slice from "../slice";
import { EnrichedExecutionResult } from "../types";
import { getV2ApiTestDataDebouncedSaga } from "./getV2ApiTestDataDebounced";
import { updateEnrichedExecutionSaga } from "./updateEnrichedExecution";
import { updateV2ApiSaga } from "./updateV2Api";
import type { ControlFlowFrontendDSL } from "../control-flow/types";

function* mutateControlFlow(
  draft: {
    controlFlow: ControlFlowFrontendDSL;
    enrichedExecutionResult: undefined | EnrichedExecutionResult;
  },
  mutationPayload: MutationPayloadMap[keyof MutationPayloadMap],
): Generator<any, MutationReturnWithMeta<MutationReturnUnion>, any> {
  const type = mutationPayload.type;
  switch (type) {
    case "renameBlock": {
      return yield call(renameBlock, draft, mutationPayload.payload);
    }
    case "updateBlockConfig": {
      return yield call(updateBlockConfig, draft, mutationPayload.payload);
    }
    case "dropBlock": {
      return dropBlock(draft, mutationPayload.payload);
    }
    case "updateDSL": {
      return updateDSL(draft, mutationPayload.payload);
    }
    case "addNewControlBlock": {
      return addNewControlBlock(draft, mutationPayload.payload);
    }
    case "addNewStepBlock": {
      return addNewStepBlock(draft, mutationPayload.payload);
    }
    case "setActionConfig": {
      return setActionConfig(draft, mutationPayload.payload);
    }
    case "deleteBlock": {
      return deleteBlock(draft, mutationPayload.payload);
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
}

function* updateV2FromControlFlowInternal(payload: {
  mutationPayload: MutationPayloadMap[keyof MutationPayloadMap];
  apiId: string;
}): Generator<unknown, MutationReturnUnion, any> {
  const { mutationPayload, apiId } = payload;

  const api: ReturnType<typeof selectV2ApiById> = yield select((state) =>
    selectV2ApiById(state, apiId),
  );
  const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) => selectCachedControlFlowById(state, apiId));
  if (api == null || controlFlow == null) {
    return;
  }

  const meta: ReturnType<typeof selectV2ApiMeta> =
    yield select(selectV2ApiMeta);
  const enrichedExecutionResult = meta?.[apiId]?.enrichedExecutionResult;

  const draft = createDraft({ controlFlow, enrichedExecutionResult });

  const ret: MutationReturnWithMeta<MutationReturnUnion> = yield call(
    mutateControlFlow,
    draft,
    mutationPayload,
  );

  const {
    controlFlow: newControlFlow,
    enrichedExecutionResult: newEnrichedExecutionResult,
  } = finishDraft(draft);

  // execution result might have changed during mutateControlFlow, but not always,
  // so we want to make sure its actually different before sending downstream
  const didExecutionResultChange = !equal(
    newEnrichedExecutionResult,
    enrichedExecutionResult,
  );

  const newApi: BackendTypes.Api = {
    ...api.apiPb,
    blocks: convertDSLToBackendBlocks(newControlFlow),
  };

  yield callSagas([
    updateV2ApiSaga.apply({
      apiPb: newApi,
      hydrateBlockId: ret?.hydrateBlockId,
    }),
    ...(newEnrichedExecutionResult && didExecutionResultChange
      ? [
          updateEnrichedExecutionSaga.apply({
            newEnrichedExecutionResult,
            apiId,
          }),
        ]
      : []),
    ...((mutationPayload.payload as any)?.updatedBlock?.name
      ? [
          getV2ApiTestDataDebouncedSaga.apply({
            apiId: api.apiPb.metadata.id,
            blockName: (mutationPayload.payload as any)?.updatedBlock?.name,
            computeDependencies: false,
          }),
        ]
      : []),
  ]);

  return ret?.result;
}

export const updateV2FromControlFlowSaga = createSaga(
  updateV2FromControlFlowInternal,
  "updateV2FromControlFlowSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(updateV2FromControlFlowSaga, {
  start(state, { payload }) {
    delete state.errors[payload.apiId];
    // add the initial api dsl to the replay state if not already there
    const apiId = payload.apiId;
    if (state.meta[apiId]) {
      state.meta[apiId].dirty = true;
    }
    replayManager.initializeManager(
      apiId,
      fastClone(state.entities[apiId]?.apiPb),
    );
  },
  success(state, { payload, meta }) {
    // update the replay manager
    if (meta?.args?.apiId) {
      const apiId = meta.args.apiId;
      replayManager.update(apiId, fastClone(state.entities[apiId]?.apiPb));
    }
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = {
      type: EntitiesErrorType.SAVE_ERROR,
      error: payload,
    };
  },
});
