import React from "react";
import styled from "styled-components";
import { StyledReactMarkdown } from "./DynamicFormItem";

interface Props {
  text?: string;
}

const TextWrapper = styled.div`
  max-width: 500px;
  color: ${({ theme }) => theme.colors.GREY_400};
`;

export const FormSubheading = ({ text }: Props) => {
  return (
    <TextWrapper>
      <StyledReactMarkdown linkTarget="_blank">
        {text ?? ""}
      </StyledReactMarkdown>
    </TextWrapper>
  );
};
