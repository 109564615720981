import { Select } from "antd";
import { InternalSelectProps } from "antd/lib/select";
import React, { useCallback } from "react";
import styled from "styled-components";

const SelectWrapper = styled.div`
  &&& .ant-select-selector {
    height: 100%;
  }
  &&& .ant-select-selection-item,
  &&& .ant-select-selection-placeholder {
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }
  &&& .ant-select-selection-item {
    font-weight: normal;
    color: ${(props) => props.theme.colors.GREY_700};
  }
`;

const SelectOptionWrapper = styled.div`
  &&& .ant-select-item-option-content {
    font-size: 12px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.GREY_700};
  }
`;

const SelectImpl = styled(Select)`
  .ant-select-selection-item {
    font-weight: 400;
  }
`;

export const StyledSelect = (props: InternalSelectProps<any>) => {
  const dropdownRenderer = useCallback(
    (node: JSX.Element) => <SelectOptionWrapper>{node}</SelectOptionWrapper>,
    [],
  );

  return (
    <SelectWrapper>
      <SelectImpl dropdownRender={dropdownRenderer} {...(props as any)}>
        {props.children}
      </SelectImpl>
    </SelectWrapper>
  );
};
