import Api from "./Api";
import { ApiResponse } from "./ApiResponses";

export enum KeyRotationStatus {
  KEY_ROTATION_STATUS_UNSPECIFIED = "KEY_ROTATION_STATUS_UNSPECIFIED",
  KEY_ROTATION_STATUS_IN_PROGRESS = "KEY_ROTATION_STATUS_IN_PROGRESS",
  KEY_ROTATION_STATUS_COMPLETED = "KEY_ROTATION_STATUS_COMPLETED",
  KEY_ROTATION_STATUS_FAILED = "KEY_ROTATION_STATUS_FAILED",
  KEY_ROTATION_STATUS_CANCELED = "KEY_ROTATION_STATUS_CANCELED",
}

export type KeyRotation = {
  id: string;
  status: KeyRotationStatus;
  signingKeyId: string;
  created: string | null;
  updated: string | null;
  completed: string | null;
};

export interface KeyRotationWithCounts extends KeyRotation {
  resourcesCompleted: number;
  resourcesTotal: number;
}

export type RotationResponse = {
  keyRotations: Array<KeyRotation>;
};

class SigningKeyRotationApi extends Api {
  static sigingKeyUrl = "v1/keyrotations";
  static sigingKeyUrlV2 = "v2/keyrotations";

  static getRotations(onlyInProgress: boolean) {
    let url = SigningKeyRotationApi.sigingKeyUrlV2;
    if (onlyInProgress) {
      url = `${url}?status=IN_PROGRESS`;
    }
    return Api.get<ApiResponse<RotationResponse>>(url);
  }

  static getRotation(rotationJobId: string) {
    return Api.get(`${SigningKeyRotationApi.sigingKeyUrlV2}/${rotationJobId}`);
  }

  static startRotation() {
    return Api.post<ApiResponse<RotationResponse>>(
      SigningKeyRotationApi.sigingKeyUrl,
    );
  }

  static cancelRotation(id: string) {
    return Api.delete<ApiResponse<RotationResponse>>(
      `${SigningKeyRotationApi.sigingKeyUrl}/${id}`,
    );
  }
}

export default SigningKeyRotationApi;
