import { getType, Types } from "legacy/utils/TypeHelpers";
import { generateReactKey } from "legacy/utils/generators";
import { envTypeDefDocString, Origin } from "./dataTreeTypeDefCreator";

let extraDefs: any = {};
const knownDocStrings: Record<string, any> = {
  sb_secrets: {
    "!doc": {
      docString: "Credentials for accessing engineering systems.",
    },
  },
  Env: envTypeDefDocString,
};

export const customTreeTypeDefCreator = (dataTree: Record<string, unknown>) => {
  const def: any = {
    "!name": Origin.CUSTOM_DATA_TREE,
  };
  Object.keys(dataTree).forEach((entityName) => {
    const entity = dataTree[entityName];
    def[entityName] = generateTypeDef(entity);
    if (knownDocStrings[entityName]) {
      def[entityName] = {
        ...def[entityName],
        ...knownDocStrings[entityName],
      };
    }
    if (entity && typeof entity === "object" && "!doc" in entity) {
      def[entityName]["!doc"] = entity["!doc"];
    }
  });
  def["!define"] = { ...extraDefs };
  extraDefs = {};
  return { ...def };
};

function generateTypeDef(
  obj: any,
): string | Record<string, string | Record<string, unknown>> {
  // if !type is already set, dont reset it
  if (obj && typeof obj === "object" && "!type" in obj) {
    return obj;
  }
  const type = getType(obj);
  switch (type) {
    case Types.ARRAY: {
      if (obj.length <= 0) {
        return "[]";
      }
      const arrayType = generateTypeDef(obj[0]);
      const name = generateReactKey();
      extraDefs[name] = arrayType;
      return `[${name}]`;
    }
    case Types.OBJECT: {
      const objType: Record<string, string | Record<string, unknown>> = {};
      Object.keys(obj).forEach((k) => {
        objType[k] = generateTypeDef(obj[k]);
      });
      return objType;
    }
    case Types.FUNCTION:
      return "fn()";
    case Types.STRING:
      return "string";
    case Types.NUMBER:
      return "number";
    case Types.BOOLEAN:
      return "bool";
    case Types.MAP:
      return "Map.prototype";
    case Types.SET:
      return "Set.prototype";
    case Types.NULL:
    case Types.UNDEFINED:
      return "?";
    default:
      return "?";
  }
}
