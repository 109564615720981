import TokenProvider from "auth/token";

class State {
  lastSubmittedId: string = "";
}

const state = new State();

export const sendCodeCompletionSuggest = async ({
  input,
  output,
  context,
  metadata,
}: {
  input: {
    text: string;
    cursorOffset: number;
    language: string;
  };
  output: string;
  context: string;
  metadata: Record<string, any>;
}): Promise<void> => {
  const { callServer } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );
  const url = `v1/demos/ai/code-completion/suggest`;

  const response = await callServer({
    url,
    method: "POST",
    body: {
      action: "submit",
      input,
      output,
      context,
      metadata,
      jwt: TokenProvider.getToken(),
    },
    priority: "low",
  });

  state.lastSubmittedId = (response as any).output.result.row_ids[0];
  return response;
};

export const sendCodeCompletionFeedback = async ({
  action,
  reason,
  feedback,
}: {
  action: "accept" | "reject" | "dismiss";
  reason: string;
  feedback?: string;
}): Promise<void> => {
  const { callServer } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );
  const url = `v1/demos/ai/code-completion/feedback`;

  const response = await callServer({
    url,
    method: "POST",
    body: {
      id: state.lastSubmittedId,
      action,
      reason,
      feedback,
      jwt: TokenProvider.getToken(),
    },
    priority: "low",
  });

  return response;
};
