import {
  AgentType,
  compareSemVer,
  DisplayUnsupportedState,
  FormItem,
  FormItemDisplay,
  FormRow,
  FormTemplate,
  getBasePluginId,
  getRowItemsFromSectionItem,
  InitialValue,
  isFormItem,
  PluginExecutionVersions,
  SemVer,
  TooltipIconType,
  VERSION_INITIAL,
} from "@superblocksteam/shared";
import { isEmpty, isNumber, some, isNil, get } from "lodash";
import { AGENTS_BASE_URL } from "legacy/constants/routes";
import toposort from "utils/dag";
import logger from "utils/logger";

type DisplayInitialValuePair = {
  display: FormItemDisplay;
  initialValue: InitialValue | undefined;
};

export type InitialValueFn = () => [any, FormTemplate | undefined];

export const ruleParser = (
  path: string,
  rules: any[],
  registerValidation: (
    path: string,
    validationFunction: (value: unknown) => boolean,
  ) => void,
  setValidationMessage: (value: string) => void,
) => {
  for (const rule of rules ?? []) {
    // More validation rules will be added here and
    // extract common code
    if ("required" in rule) {
      if (rule.required) {
        registerValidation(path, (value) => {
          let isInvalid = true;
          if (isNumber(value)) isInvalid = false;
          else isInvalid = isEmpty(value);
          if (isInvalid) {
            setValidationMessage(rule.message);
          } else {
            setValidationMessage("");
          }
          return isInvalid;
        });
      }
    } else if (rule.enum) {
      registerValidation(path, (value) => {
        const foundRule = some(rule.enum, (item) => {
          return item === value;
        });
        if (foundRule) {
          setValidationMessage("");
        } else {
          setValidationMessage(rule.message);
        }
        return !foundRule;
      });
    } else if (rule.validator) {
      registerValidation(path, (value) => {
        const validationError = rule.validator(value);
        if (validationError) {
          setValidationMessage(validationError.message);
          return true;
        } else {
          setValidationMessage("");
          return false;
        }
      });
    } else {
      logger.error(`Unsupported validation rule: ${JSON.stringify(rule)}`);
    }
  }
};

// This function filters out unsupported plugin form fields based on
// the fields' start/end versions and the plugin execution version
export const filterUnsupportedFields = (
  formTemplate: FormTemplate,
  pluginVersion: SemVer,
  agentType: AgentType,
  isIntegrationEnableExperimentalEnabled?: boolean,
) => {
  for (const section of formTemplate.sections) {
    for (let i = section.items.length - 1; i >= 0; i--) {
      const sectionItem = section.items[i];
      const isRow = !isFormItem(sectionItem);

      const rowItems = isRow
        ? (sectionItem as FormRow).rowItems
        : [sectionItem];

      for (
        let indexRowItem = rowItems.length - 1;
        indexRowItem >= 0;
        indexRowItem--
      ) {
        const item = rowItems[indexRowItem];
        let needToRemove = false;
        if (!isIntegrationEnableExperimentalEnabled && !isEmpty(item.ldFlag)) {
          needToRemove = true;
        } else if (compareSemVer(item.startVersion, pluginVersion) > 0) {
          // If the start version is greater than the plugin version, apply filtering rules.
          // Currently, we support hiding (default) and disablement. We could support
          // more states in the future.
          if (item.displayUnsupported === DisplayUnsupportedState.DISABLE) {
            item.disabled = true;
            item.tooltip = {
              markdownText: `Please upgrade your agent to get support for this integration. More info can be found in the [on-premise agents page](${AGENTS_BASE_URL}).`,
              iconType: TooltipIconType.WARNING,
            };
          } else {
            needToRemove = true;
          }
        } else if (
          item.endVersion &&
          compareSemVer(item.endVersion, pluginVersion) < 0
        ) {
          // If the end version is less than the plugin version, simply hide the field.
          // We could have a deprecated representation for the field in the future if needed.
          needToRemove = true;
        } else if (!isNil(item.agentType) && item.agentType !== agentType) {
          needToRemove = true;
        }
        if (needToRemove) {
          if (isRow) {
            rowItems.splice(indexRowItem, 1);
          } else {
            section.items.splice(i, 1);
          }
        }
      }
    }
  }
  return formTemplate;
};

export function getPluginVersion(
  pluginExecutionVersions: PluginExecutionVersions | undefined,
  pluginId: string,
): string {
  return (
    pluginExecutionVersions?.[getBasePluginId(pluginId)] ?? VERSION_INITIAL
  );
}

const shouldShowFormField = (
  formItemDisplay?: FormItemDisplay,
  values?: Record<string, InitialValue>,
): boolean => {
  if (formItemDisplay?.show) {
    for (const [name, matchValues] of Object.entries(formItemDisplay.show)) {
      if (!matchValues.includes(String(get(values, name)))) return false;
    }
  }
  return true;
};

// convert the template to a list of display and initial value pairs (there could be more than one pair of display and initial value because there could be more than one item with the same field name, e.g. authConfig.username)
// the list is also sorted topologically, so we clear the form fields in the correct order
export const flattenTemplateToListOfDisplayAndInitialValuePair = (
  template: FormTemplate | undefined,
): {
  name: string;
  displayInitialValuePairs: DisplayInitialValuePair[] | undefined;
}[] => {
  if (!template) return [];
  const edges: [string, string][] = [];
  // there could be more than one set of display conditions
  const itemToDisplayAndInitialValuePairsMap: Record<
    string,
    { display: FormItemDisplay; initialValue: InitialValue | undefined }[]
  > = {};
  const nameToItemMap: Record<string, FormItem> = {};
  template.sections.forEach((section) => {
    section.items.forEach((sectionItem) => {
      const rowItems = getRowItemsFromSectionItem(sectionItem);
      rowItems.forEach((item) => {
        if (!item.display || item.doNotClearOnDependenciesChange) return;

        if (item.name in itemToDisplayAndInitialValuePairsMap) {
          itemToDisplayAndInitialValuePairsMap[item.name].push({
            display: item.display,
            initialValue: item?.initialValue,
          });
        } else {
          itemToDisplayAndInitialValuePairsMap[item.name] = [
            { display: item.display, initialValue: item.initialValue },
          ];
        }
        nameToItemMap[item.name] = item;
      });
    });
  });
  Object.entries(itemToDisplayAndInitialValuePairsMap).forEach(
    ([name, displayAndInitialValue]) => {
      const dependencySet = new Set();
      displayAndInitialValue.forEach(({ display, initialValue }) => {
        if (!display.show) return;
        Object.keys(display.show).forEach((dependency) => {
          if (name in dependencySet) return;
          edges.push([dependency, name]);
          dependencySet.add(name);
        });
      });
    },
  );
  const { result, errors } = toposort(edges);
  if (errors.length > 0) {
    return [];
  }
  return result.map((name) => ({
    name,
    displayInitialValuePairs: itemToDisplayAndInitialValuePairsMap[name],
  }));
};

export const cleanFormData = (
  formData: any,
  displayAndInitialValuePairList: {
    name: string;
    displayInitialValuePairs: DisplayInitialValuePair[] | undefined;
  }[],
) => {
  displayAndInitialValuePairList.forEach(
    ({ name, displayInitialValuePairs }) => {
      if (!displayInitialValuePairs) return;

      let shouldShow = false;
      let intendedInitialValue;
      for (const displayAndInitialValue of displayInitialValuePairs) {
        const { display, initialValue } = displayAndInitialValue;
        if (shouldShowFormField(display, formData)) {
          shouldShow = true;
          intendedInitialValue = initialValue;
          break;
        }
      }
      if (!shouldShow) {
        delete formData[name];
      } else if (intendedInitialValue && get(formData, name) === undefined) {
        formData[name] = intendedInitialValue as InitialValue;
      } else if (
        intendedInitialValue === undefined &&
        get(formData, name) === null
      ) {
        // for fields that are of type number and need to be set back to undefined on clear
        setNestedFieldFunctional(formData, name, undefined);
      }
    },
  );
};

function setNestedFieldFunctional(obj: any, field: string, value: any) {
  field.split(".").reduce((acc, cur, idx, arr) => {
    if (idx === arr.length - 1) {
      acc[cur] = value;
    } else if (!acc[cur] || typeof acc[cur] !== "object") {
      acc[cur] = {};
    }
    return acc[cur];
  }, obj);
}
