import { EditorLanguage, FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';

export const CosmosDbPluginVersions = {
  V1: '0.0.1'
};

// const CONNECTION_METHODS_AND_DISPLAY_NAMES: Record<string, string> = {
//   fields: 'Fields'
// };

const AUTH_METHODS_AND_DISPLAY_NAMES: Record<string, string> = {
  key: 'Azure key'
};

const ACTION_DROPDOWN: { label: string; value: string; children: { label: string; value: string }[] }[] = [
  {
    label: 'Query with SQL',
    value: 'sql',
    children: [{ label: 'SQL', value: 'singleton' }]
  },
  // NOTE: (joey) we need to be careful with verbage here.
  // I believe if a container has multiple items with an undefined partition key and the same id, it will affect all of them.
  {
    label: 'Single Object Actions',
    value: 'pointOperation',
    children: [
      { label: 'Create an object', value: 'create' },
      { label: 'Read an object', value: 'read' },
      { label: 'Upsert an object', value: 'upsert' },
      { label: 'Replace an object', value: 'replace' },
      { label: 'Delete an object', value: 'delete' }
    ]
  }
];

const makeDropdownItem = (value: string, displayName?: string, subText?: string) => {
  return {
    key: value,
    value: value,
    displayName: displayName ?? value,
    subText: subText ?? ''
  };
};

export const CosmosDbPlugin: Plugin = {
  id: 'cosmosdb',
  name: 'Azure CosmosDB',
  moduleName: 'CosmosDbPlugin',
  modulePath: 'plugins/cosmosdb/CosmosDbPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/cosmosdb.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/cosmosdb',
  type: PluginType.DB,
  responseType: PluginResponseType.JSON,
  hasRawRequest: false,
  hasMetadata: true,
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'Example CosmosDB Integration',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Auth type',
            name: 'connection.auth.config.case',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'key',
            hidden: true,
            rules: [{ required: true }],
            options: Object.entries(AUTH_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) => makeDropdownItem(value, displayName))
          },
          // TODO: (joey) only show these fields when 'fields' is selected
          {
            label: 'Host',
            name: 'connection.host',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'https://my-org.documents.azure.com',
            rules: [{ required: true, message: 'Host is required' }]
          },
          {
            label: 'Port',
            name: 'connection.port',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.NUMBER,
            initialValue: 443,
            placeholder: '443',
            rules: [{ required: true, message: 'Port is required' }]
          },
          {
            label: 'Database ID',
            name: 'connection.databaseId',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            subtitle: 'Select **Browse** in the CosmosDB panel to see your collections and database IDs.',
            rules: [{ required: true, message: 'Database ID is required' }]
          },
          {
            label: 'Master key',
            name: 'connection.auth.config.value.masterKey',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'Master key is required' }]
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'cosmosdbAction.value.action.case',
            secondaryName: 'cosmosdbAction.case',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.ONEOF_ACTION_DROPDOWN,
            actionOptions: ACTION_DROPDOWN,
            initialValue: 'singleton',
            secondaryInitialValue: 'sql'
          },
          // container id for sql
          {
            label: 'Container ID',
            name: 'cosmosdbAction.value.action.value.containerId',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'my-table',
            rules: [{ required: true, message: 'Container ID is required' }],
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['singleton'],
                'cosmosdbAction.case': ['sql']
              }
            }
          },
          // container id for point operations
          {
            label: 'Container ID',
            name: 'cosmosdbAction.value.containerId',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'Container ID is required' }],
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['read', 'replace', 'upsert', 'delete', 'create'],
                'cosmosdbAction.case': ['pointOperation']
              }
            }
          },
          {
            label: 'Cross partition',
            name: 'cosmosdbAction.value.action.value.crossPartition',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.CHECKBOX,
            initialValue: true,
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['singleton'],
                'cosmosdbAction.case': ['sql']
              }
            }
          },
          {
            label: 'Item partition key (optional)',
            name: 'cosmosdbAction.value.action.value.partitionKey',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'tenant-1',
            display: {
              show: {
                'cosmosdbAction.value.action.value.crossPartition': ['false'],
                'cosmosdbAction.value.action.case': ['singleton'],
                'cosmosdbAction.case': ['sql']
              }
            }
          },
          {
            label: 'Query',
            name: 'cosmosdbAction.value.action.value.query',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: '',
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['singleton'],
                'cosmosdbAction.case': ['sql']
              }
            }
          },
          // Point Operations
          {
            label: 'Item ID',
            name: 'cosmosdbAction.value.action.value.id',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'Item ID is required' }],
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['read', 'delete'],
                'cosmosdbAction.case': ['pointOperation']
              }
            }
          },
          {
            label: 'Item partition key',
            name: 'cosmosdbAction.value.action.value.partitionKey',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: 'tenant-1',
            tooltip: {
              markdownText:
                'Used to match on a specific item. Leave blank to match only on items without an explicitly assigned partition key'
            },
            display: {
              show: {
                // NOTE: (joey) this field *can* be used for UPSERT and CREATE
                // we are ommiting them for now for form simplicity
                'cosmosdbAction.value.action.case': ['read', 'delete', 'replace'],
                'cosmosdbAction.case': ['pointOperation']
              }
            }
          },
          {
            label: 'Item body',
            name: 'cosmosdbAction.value.action.value.body',
            startVersion: CosmosDbPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            display: {
              show: {
                'cosmosdbAction.value.action.case': ['create', 'replace', 'upsert'],
                'cosmosdbAction.case': ['pointOperation']
              }
            },
            placeholder: '{"id": "12345", "name": "John Smith", "age": 25}',
            rules: [{ required: true, message: 'Item body is required' }]
          }
        ]
      }
    ]
  }
};
