import { Dimension, PageDSL8 } from "@superblocksteam/shared";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 2. Never update a migration. Instead, create a new one.
 ***/

const OLD_MIN_WIDTH = 840;
const OLD_MAX_WIDTH = 7680;
const addMinAndMaxWidthToPage = (page: PageDSL8) => {
  page.minWidth = Dimension.px(OLD_MIN_WIDTH);
  page.maxWidth = Dimension.px(OLD_MAX_WIDTH);
  return page;
};

export default addMinAndMaxWidthToPage;
