import { AccessMode } from "@superblocksteam/shared";
import { Button, List, Modal, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SbText from "components/ui/SbText";
import { useSaga } from "hooks/store";
import {
  closeDowngradeModal,
  closePricingModal,
} from "legacy/actions/modalActions";
import { getModalState } from "legacy/selectors/modalSelectors";
import { FeatureCloseMark, StyledListItem } from "pages/Checkout";
import { DOWNGRADE_PLAN } from "pages/routes";
import { useAppDispatch } from "store/helpers";
import initializeUser from "store/sagas/initializeUser";
import { callServer, HttpMethod } from "store/utils/client";

const DowngradeModal = () => {
  const modalState = useSelector(getModalState);
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    dispatch(closeDowngradeModal());
  }, [dispatch]);

  const downgradePlan = useCallback(() => {
    callServer({
      method: HttpMethod.Post,
      url: DOWNGRADE_PLAN,
    })
      .then(() => {
        setIsDowngraded(true);
      })
      .finally(() => setIsLoading(false));
    dispatch(closeDowngradeModal());
    dispatch(closePricingModal());
  }, [dispatch]);

  const lostFeatures = [
    "Single-Sign-On / SAML",
    "Granular Permissions (RBAC)",
    "Git Sync via GitHub, GitLab, Bitbucket",
    "Audit Logging",
    "User Analytics",
    "Monitoring & Observability",
    "Unlimited Custom Integration Profiles",
    "Live Queries with Timers",
    "Dedicated CSM & Technical Support from a Superblocks Engineer",
    "Keep data in your VPC with the On-Premise Agent",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isDowngraded, setIsDowngraded] = useState(false);

  const [initializeUserSaga] = useSaga(initializeUser);

  useEffect(() => {
    if (isDowngraded) {
      initializeUserSaga({ accessMode: AccessMode.AUTH_USER }).finally(() => {
        onCancel();
      });
    }
  }, [initializeUserSaga, isDowngraded, onCancel]);

  return (
    <>
      <Modal
        title="Downgrade Plan"
        open={modalState.showDowngradeModal}
        onCancel={onCancel}
        centered
        destroyOnClose
        footer={null}
        width={650}
      >
        <>
          <SbText>
            By downgrading to a Free plan you will lose access to:
          </SbText>
          <br />
          <br />
          <List split={false}>
            {lostFeatures.map((point, i) => (
              <StyledListItem key={i}>
                <FeatureCloseMark />
                <div>{point}</div>
              </StyledListItem>
            ))}
          </List>
          <br />
          <Row justify="space-between">
            <Button
              onClick={downgradePlan}
              type="link"
              danger
              loading={isLoading}
            >{`I don't need those features, downgrade my account`}</Button>
            <Button type="primary" onClick={onCancel}>
              I want to pick a plan
            </Button>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default DowngradeModal;
