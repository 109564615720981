import { ApplicationScope } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { createMarkedSelector } from "legacy/selectors/markedSelector";
import {
  AppTimer,
  AppTimerScoped,
  AppTimerWithMetaType,
  AppTimersMap,
} from "../timers/TimerConstants";
import { getTimerByName } from "../timers/selectors";
import { getAllTimers } from "../timers/selectors";
import type { AppState } from "store/types";

export const getTimersMeta = (state: AppState) => state.application.timersMeta;

export const getAllTimersMeta = getTimersMeta;

export type TimersMetaState = ReturnType<typeof getAllTimersMeta>;

export const getTimerMetaById = createSelector(
  getAllTimersMeta,
  (_: AppState, id: string) => id,
  (meta, id) =>
    meta[ApplicationScope.PAGE][id] ?? meta[ApplicationScope.APP][id],
);

export const getTimerByNameWithMeta = createMarkedSelector(
  "getTimerByNameWithMeta",
)(
  getTimerByName,
  getAllTimersMeta,
  (
    timer: AppTimerScoped | undefined,
    timersMeta: TimersMetaState,
  ): AppTimerWithMetaType | undefined => {
    if (!timer || !timersMeta) return undefined;
    const meta =
      timersMeta[ApplicationScope.PAGE][timer.id] ??
      timersMeta[ApplicationScope.APP][timer.id];
    if (!meta) return undefined;
    return {
      ...timer,
      ...meta,
    };
  },
);
export const getTimersWithMeta = createMarkedSelector("getTimersWithMeta")(
  getAllTimers,
  getAllTimersMeta,
  (
    timers: AppTimersMap,
    timersMeta: TimersMetaState,
  ): Record<AppTimer["id"], AppTimerWithMetaType> => {
    return Object.fromEntries(
      Object.values(timers)
        .map((timer) => ({
          ...timer,
          ...timersMeta[timer.scope][timer.id],
        }))
        .map((timer) => [timer.id, timer]),
    );
  },
);
