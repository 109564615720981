type SearchState =
  | { type: "start"; offset: number; length: number }
  | { type: "end"; offset: number };

function traverseTree(
  node: Node,
  tokenRanges: Range[],
  tokenStates: Map<number, SearchState>,
) {
  if (node instanceof Text) {
    // eslint-disable-next-line prefer-const
    for (let [idx, state] of tokenStates.entries()) {
      if (state.type === "start") {
        if (state.offset >= node.length) {
          state.offset -= node.length;
          continue;
        } else {
          tokenRanges[idx].setStart(node, state.offset);
          state = {
            type: "end",
            offset: state.offset + state.length,
          };
          tokenStates.set(idx, state);
        }
      }
      if (state.type === "end") {
        if (state.offset > node.length) {
          state.offset -= node.length;
        } else {
          tokenRanges[idx].setEnd(node, state.offset);
          tokenStates.delete(idx);
        }
      }
    }
  } else if (node instanceof HTMLElement) {
    for (const child of node.childNodes) {
      traverseTree(child, tokenRanges, tokenStates);
      if (tokenStates.size === 0) {
        // exit early
        return;
      }
    }
  } else {
    throw new Error(`Cannot handle nodes of type ${node.nodeName}`);
  }
}

/**
 * Given a list of tokens, return a list of Range objects that correspond to the DOM ranges for each of those tokens.
 * If a token is out of range with respect to `elt`, then an empty range is returned. TODO: we might want to throw instead.
 * @param elt - The root element that contains all the text that includes the tokens.
 * @param tokens - The list of tokens given by their start offset (relative to the text held by `elt`) and length.
 */
export function getTokenRanges(
  elt: HTMLElement,
  tokens: { startOffset: number; length: number }[],
): Range[] {
  const tokenRanges = tokens.map(() => new Range());
  traverseTree(
    elt,
    tokenRanges,
    new Map(
      tokens.map(({ startOffset, length }, idx) => [
        idx,
        { type: "start", offset: startOffset, length },
      ]),
    ),
  );
  return tokenRanges;
}
