import { ApplicationScope, Plugin } from "@superblocksteam/shared";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectBuildPluginDatasourcesAsList,
  selectAllPluginDatasources,
} from "store/slices/datasources";
import { getPluginById } from "utils/integrations";
import DefaultShortcutMenu from "./DefaultShortcutMenu";
import { sortDatasourcesOrPlugin } from "./sortDatasourcesOrPlugin";

interface Props {
  isFocused: boolean;
  children: React.ReactNode;
  insertText: (value: string, cursor?: number) => void;
  onOptionHover: (entityName: string) => void;
  onOptionHoverLeave: () => void;
  setCanEvaluate: (canEval: boolean) => void;
  currentScope: ApplicationScope;
  additionalDynamicData?: Record<string, Record<string, unknown>>;
  handleParentEditorBlur: () => void;
  openAiAssistant?: () => void;
}

const ShortcutMenu = ({
  isFocused,
  children,
  insertText,
  onOptionHover,
  onOptionHoverLeave,
  setCanEvaluate,
  currentScope,
  additionalDynamicData,
  handleParentEditorBlur,
  openAiAssistant,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    // this is just several frames, but its enough to detect user intent
    // if they are tabbing through tab-able single-line editors quickly
    if (isFocused) {
      timerRef.current = setTimeout(() => {
        setIsVisible(isFocused);
      }, 500);
    } else {
      setIsVisible(isFocused);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isFocused]);

  const datasources = useSelector(selectBuildPluginDatasourcesAsList);
  const datasourcesFull = useSelector(selectAllPluginDatasources);

  const options = useMemo(
    () =>
      datasources
        .map((datasource) => {
          const plugin = getPluginById(datasource.pluginId) as Plugin;

          return {
            id: datasource.id,
            name: datasource.name,
            icon: plugin?.iconLocation,
            pluginId: plugin?.id,
            isDemo: Boolean(datasourcesFull[datasource.id]?.demoIntegrationId),
          };
        })
        .sort(sortDatasourcesOrPlugin),
    [datasources, datasourcesFull],
  );

  return (
    <DefaultShortcutMenu
      insertText={insertText}
      onOptionHover={onOptionHover}
      onOptionHoverLeave={onOptionHoverLeave}
      setIsVisible={setIsVisible}
      setCanEvaluate={setCanEvaluate}
      isVisible={isVisible}
      datasources={datasources}
      options={options}
      currentScope={currentScope}
      additionalDynamicData={additionalDynamicData}
      handleParentEditorBlur={handleParentEditorBlur}
      openAiAssistant={openAiAssistant}
      canKeyboardFocus={false}
    >
      {children}
    </DefaultShortcutMenu>
  );
};

export default ShortcutMenu;
