import {
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_STAGING,
} from "@superblocksteam/shared";

export function prettyEnvironment(environment: string) {
  if (
    environment.toLowerCase() === ENVIRONMENT_STAGING ||
    environment.toLowerCase() === ENVIRONMENT_PRODUCTION
  ) {
    return environment.charAt(0).toUpperCase() + environment.slice(1);
  }
  return environment;
}
