import { Divider, Input } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import { Layout, MainWrapper } from "components/app";
import { SecretInput } from "components/ui/SecretInput";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import {
  PERSONAL_SETTINGS_TITLE,
  PageWrapper,
} from "pages/components/PageWrapper";
import { FETCH_USER_API_KEY } from "pages/routes";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer } from "store/utils/client";
import { sendErrorUINotification } from "utils/notification";

const InnerWrapper = styled.div`
  max-width: 768px;
`;

const SectionHeader = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const SectionSubHeader = styled.div`
  font-size: 12px;
`;

const SectionItem = styled.div`
  font-size: 12px;
  margin-top: 16px;
`;

const SectionsWrapper = styled.div`
  input {
    font-size: 12px !important;
  }
  input:not(:last-child) {
    margin-bottom: 16px;
  }
  input.ant-input[disabled] {
    color: ${(props) => props.theme.colors.GREY_300};
    border-color: ${(props) => props.theme.colors.GREY_50};
    background-color: ${(props) => props.theme.colors.GREY_50};
    cursor: text;
  }
  .ant-input-password > input {
    margin-bottom: unset;
    caret-color: transparent;
  }
`;

const StyledLink = styled.a`
  text-decoration: underline;
`;

const PersonalSettings = () => {
  const { hash } = useLocation();

  useEffect(() => {
    document.getElementById(hash.replace("#", ""))?.scrollIntoView();
  }, [hash]);

  const user = useSelector(getCurrentUser);
  const [apiKey, setApiKey] = useState("");

  const organization = useSelector(selectOnlyOrganization);

  useEffect(() => {
    callServer<string>(
      {
        method: "GET",
        url: FETCH_USER_API_KEY,
      },
      {
        notifyOnError: false,
        shouldCrashApp: false,
      },
    )
      .then((res) => {
        setApiKey(res);
      })
      .catch((error) => {
        sendErrorUINotification({
          message: `Error fetching api token: ${error.message} (${error.code})`,
        });
      });
  }, []);

  return (
    <PageWrapper pageName={PERSONAL_SETTINGS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <InnerWrapper>
            <Title level={2}>{PERSONAL_SETTINGS_TITLE}</Title>
            <SectionsWrapper>
              <SectionHeader id="profile">Profile</SectionHeader>
              <SectionItem>
                <label>Email</label>
                <Input value={user?.email} disabled />
              </SectionItem>
              <SectionItem>
                <label>Name</label>
                <Input value={user?.name} disabled />
              </SectionItem>
            </SectionsWrapper>
            <Divider />
            <SectionsWrapper>
              <SectionHeader id="apiKey">API Key</SectionHeader>
              <SectionSubHeader>
                Use your personal API key to set up and interact with
                Superblocks using the &nbsp;
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={DOCS_PAGE_URL(DocsPage.SUPERBLOCKS_CLI)}
                >
                  Superblocks CLI
                </StyledLink>
                .
              </SectionSubHeader>
              <SectionItem>
                <SecretInput token={apiKey} />
              </SectionItem>
            </SectionsWrapper>
            <Divider />
            <SectionsWrapper>
              <SectionHeader id="apiKey">Execution Token</SectionHeader>
              <SectionSubHeader>
                Use the execution token to authenticate the Superblocks Agent
                SDK.
              </SectionSubHeader>
              <SectionItem>
                <SecretInput token={organization.apiKey} />
              </SectionItem>
            </SectionsWrapper>
          </InnerWrapper>
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

PersonalSettings.displayName = PERSONAL_SETTINGS_TITLE;

export default PersonalSettings;
