import React, { useState } from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import ExpandIconButton from "./IconButtons/ExpandIconButton";
import { Markdown } from "./Markdown";

const MessageWrapper = styleAsClass`
  display: flex;
  font-weight: 400;
  gap: 8px;
`;

const ActionButton = styleAsClass`
  all: unset;
  color: ${colors.ACCENT_BLUE_500};
  font-weight: 500;
  cursor: pointer;
`;

const TitleText = styleAsClass`
  strong {
    font-weight: 500;
  }
`;

const SecondaryText = styleAsClass`
  strong {
    font-weight: 500;
  }
  color: ${colors.GREY_500}
`;

type NotificationProps = {
  mainText: string;
  secondaryText: string;
  buttonText?: string;
  onClick?: () => void;
};

const NotificationExpandable = ({
  mainText,
  secondaryText,
  buttonText,
  onClick,
}: NotificationProps) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div className={MessageWrapper} data-test="page-load-change-notification">
      {secondaryText && (
        <ExpandIconButton
          isExpanded={isExpanded}
          onClick={() => setExpanded(!isExpanded)}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <div className={TitleText}>
            <Markdown>{mainText}</Markdown>
          </div>
          {isExpanded && (
            <div className={SecondaryText}>
              <Markdown>{secondaryText}</Markdown>
            </div>
          )}
        </div>
        <button className={ActionButton} onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export const getExpandableNotification = (props: NotificationProps) => (
  <NotificationExpandable {...props} />
);
