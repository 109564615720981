import { Modal } from "antd";
import React, { useCallback } from "react";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import {
  DefaultModalWidth,
  ModalInnerWrapper,
  FooterWrapperWide,
  ModalWrapClass,
} from "components/ui/Modal";

const ConfirmRoleChangeModal = ({
  isVisible,
  oldRole,
  newRole,
  principalName,
  onCancel,
  onConfirm,
  isDeleting,
  principalType,
}: {
  isVisible: boolean;
  oldRole: string;
  newRole: string;
  principalName: string;
  onCancel: () => void;
  onConfirm: () => void;
  isDeleting: boolean;
  principalType: "group" | "user";
}) => {
  const handleCancel = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  const handleConfirm = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      onConfirm();
    },
    [onConfirm],
  );

  return (
    <Modal
      title="Confirm Role Change"
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper}>
        {isDeleting && principalType === "group" ? (
          <div>
            Are you sure you want to remove the <b>{oldRole}</b> role from the{" "}
            <b>{principalName}</b> group?
          </div>
        ) : principalType === "group" ? (
          <div>
            Are you sure you want to change the <b>{principalName}</b>
            {" group'"}s role{" "}
            {oldRole ? (
              <span>
                from <b>{oldRole}</b>{" "}
              </span>
            ) : null}
            to <b>{newRole}</b>?
          </div>
        ) : (
          <div>
            Are you sure you want to change <b>{principalName}</b>
            {"'"}s role from <b>{oldRole}</b> to <b>{newRole}</b>?
          </div>
        )}
        <div className={FooterWrapperWide}>
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>Save</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRoleChangeModal;
