import {
  ApplicationScope,
  RouteDef,
  WidgetTypes,
} from "@superblocksteam/shared";
import { set, uniq, uniqBy } from "lodash";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ItemWithPropertiesType } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import {
  flattenObject,
  unflattenObject,
} from "legacy/pages/Editor/PropertyPane/widgetPropertyPaneConfigUtils";
import { GeneratedTheme } from "legacy/themes";
import {
  getWidgetDynamicPropertyPathList,
  mergeUpdatesWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { fastClone } from "utils/clone";
import { Flag, Flags } from "../featureFlags";
import { getEventHandlers } from "./eventHandlers";
import {
  addColumns,
  getColumnIdFromAi,
  removeColumns,
  updateColumns,
} from "./tableUtils";
import {
  AddActionEvent,
  AddEventAction,
  ComponentEditAction,
  RemoveColumnAction,
  RemoveEventAction,
  SetAction,
  SetColumnAction,
} from "./types";
import { sanitizeEdits } from "./utils";

export type DiscardedEdit = {
  propertyName: string;
  reason: string;
  propertyValue: unknown;
};

type KeyPathObj = {
  key: string;
};

export const processActionsIntoChanges = async ({
  actions,
  existingWidget,
  dataTree,
  routes,
  theme,
  nameValidator,
  featureFlags,
}: {
  actions: ComponentEditAction[];
  existingWidget: Partial<WidgetProps>;
  dataTree: DataTree;
  routes: RouteDef[];
  theme: GeneratedTheme;
  nameValidator: ReturnType<typeof createNameValidator>;
  featureFlags: Flags;
}): Promise<{
  changedKeys: Array<string>;
  rename?: string;
  dataTreeChanges: Record<string, unknown>;
  discardedEdits: DiscardedEdit[];
}> => {
  const changes: Record<string, unknown> = {};
  let rename: string | undefined;
  const discardedEdits: DiscardedEdit[] = [];

  const columnNames = (existingWidget as any).primaryColumns
    ? Object.keys((existingWidget as any).primaryColumns)
    : [];
  let numColumns = columnNames.length;

  actions.forEach((action) => {
    const { action: actionType, value: returnedValue } = action;
    let column: string | undefined;

    if ("column" in action && action.column) {
      const primaryColumns = (existingWidget as any).primaryColumns as Record<
        string,
        any
      >;

      if (primaryColumns[action.column]) {
        column = getColumnIdFromAi(action.column, primaryColumns);
        action.column = column as string;
      } else {
        column = action.column;
      }
    }
    const columnExists =
      column &&
      (columnNames.includes(column) ||
        (changes.columnOrder as string[])?.includes(column));

    let propertyName =
      !action.property && actionType === "remove" && column
        ? `primaryColumns.${column}`
        : (action.property as string);

    const prevValue =
      changes[propertyName] ??
      existingWidget[propertyName as keyof WidgetProps];
    if (
      propertyName === "textProps.textStyle.textColor.default" &&
      existingWidget.type === WidgetTypes.BUTTON_WIDGET
    ) {
      propertyName = "textColor";
    }

    switch (actionType) {
      case "set": {
        if (column && !columnExists) {
          // Adding new column
          addColumns(
            action as SetColumnAction,
            existingWidget,
            changes,
            numColumns,
          );
          numColumns += 1;

          // Also update the column if there was a property+value in the action
          if (action.property && action.value) {
            updateColumns({
              action: action as SetAction | AddEventAction | RemoveEventAction,
              widget: existingWidget,
              changes,
              dataTree,
              routes,
            });
          }
        } else if (column && columnExists) {
          // Updating an existing column
          updateColumns({
            action: action as SetAction | AddEventAction | RemoveEventAction,
            widget: existingWidget,
            changes,
            dataTree,
            routes,
          });
        } else if (propertyName === "widgetName") {
          if (typeof returnedValue === "string") {
            const val = returnedValue.replaceAll(" ", "_");
            const nameError = nameValidator({
              currentName: existingWidget.widgetName as string,
              name: val,
              scope: ApplicationScope.PAGE,
            });
            if (!nameError) {
              rename = val;
            }
          }
        } else {
          changes[propertyName] = returnedValue;
        }
        break;
      }
      case "add": {
        changes[propertyName] = getEventHandlers({
          prevValue,
          value: returnedValue as AddActionEvent | AddActionEvent[],
          dataTree,
          routes,
        });
        break;
      }
      case "remove": {
        if (column && columnExists) {
          removeColumns(action as RemoveColumnAction, existingWidget, changes);
          numColumns -= 1;
        } else {
          const idToRemove = (action as RemoveEventAction).value?.id;
          if (Array.isArray(prevValue)) {
            changes[propertyName] = prevValue.filter(
              (item) => item.id !== idToRemove,
            );
          }
        }
        break;
      }
      default:
        console.error(`Unknown action type ${actionType} returned`);
    }
  });

  if (Object.keys(changes).length > 0) {
    const propSections = getItemPropertyPaneConfig(
      existingWidget.type as ItemWithPropertiesType,
    );
    let dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      changes,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const allProperties = propSections
      .flatMap((section) => section.children)
      .filter(Boolean);

    const dynamicProperties = getWidgetDynamicPropertyPathList(existingWidget);

    let changedKeys = Object.keys(dataTreeChanges);

    const newDataTreeChanges: Record<string, unknown> = {};
    // roll up the data tree changes to the top level, backfilling from existing widget when needed
    Object.entries(dataTreeChanges).forEach(([key, value]) => {
      if (newDataTreeChanges[key]) {
        return;
      }
      if (key.includes(".")) {
        const topLevelKey = key.split(".")[0];
        if (!newDataTreeChanges[topLevelKey]) {
          newDataTreeChanges[topLevelKey] = fastClone(
            existingWidget[topLevelKey as keyof WidgetProps],
          );
        }
        set(newDataTreeChanges, key, value);
      } else {
        newDataTreeChanges[key] = value;
      }
    });

    dataTreeChanges = newDataTreeChanges;

    await sanitizeEdits({
      edits: dataTreeChanges,
      properties: allProperties as PropertyPaneConfig[],
      discardedEdits,
      dynamicProperties,
      previousWidget: existingWidget,
      theme,
      featureFlags,
      changedKeys,
    });

    // make sure dynamic property list is unique
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      dynamicProperties,
      (prop) => prop.key,
    );

    // TODO: We have to flatten and then unflatten the dataTreeChanges here
    // to make the logic in mergeUpdatesWithBindingsOrTriggers work as expected.
    // This is not performant, so we should fix soon
    dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      flattenObject(dataTreeChanges),
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    // unflatten the dataTreeChanges
    dataTreeChanges = unflattenObject(dataTreeChanges);

    changedKeys = uniq(
      changedKeys.filter((key) => {
        if (discardedEdits.some((edit) => edit.propertyName === key)) {
          return false;
        }
        return true;
      }),
    );

    if (
      changedKeys.filter(
        (key) =>
          ![
            "dynamicPropertyPathList",
            "dynamicBindingPathList",
            "dynamicTriggerPathList",
          ].includes(key),
      ).length === 0
    ) {
      return {
        changedKeys: [],
        dataTreeChanges: {},
        rename,
        discardedEdits,
      };
    }

    dataTreeChanges.dynamicBindingPathList = uniqBy(
      (dataTreeChanges as any).dynamicBindingPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    dataTreeChanges.dynamicTriggerPathList = uniqBy(
      (dataTreeChanges as any).dynamicTriggerPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      (dataTreeChanges as any).dynamicPropertyPathList ?? [],
      (prop: { key: string }) => prop.key,
    );

    // Make sure to remove any removed columns
    if (dataTreeChanges.primaryColumns) {
      // Get all removed columns by finding any primaryColumns that are null
      const removedColumns = Object.keys(
        dataTreeChanges.primaryColumns as Record<string, any>,
      ).filter(
        (column) =>
          dataTreeChanges.primaryColumns &&
          (dataTreeChanges.primaryColumns as Record<string, any>)[column] !==
            undefined &&
          (dataTreeChanges.primaryColumns as Record<string, any>)[column] ===
            null,
      );

      const removeMatchingPaths = (columnName: string, pathList: any): void => {
        const pathListArray = pathList as KeyPathObj[];

        for (let i = pathListArray.length - 1; i >= 0; i--) {
          if (
            pathListArray[i].key.startsWith(`primaryColumns.${columnName}`) ||
            pathListArray[i].key.startsWith(`derivedColumns.${columnName}`)
          ) {
            pathListArray.splice(i, 1);
          }
        }
      };

      for (const column of removedColumns) {
        removeMatchingPaths(column, dataTreeChanges.dynamicBindingPathList);
        removeMatchingPaths(column, dataTreeChanges.dynamicTriggerPathList);
        removeMatchingPaths(column, dataTreeChanges.dynamicPropertyPathList);

        // Make sure primaryColumns and derivedColumns have this column removed, but other columns are there
        delete (dataTreeChanges.primaryColumns as Record<string, any>)[column];
        delete (dataTreeChanges.derivedColumns as Record<string, any>)[column];
      }
    }
    return {
      changedKeys,
      dataTreeChanges,
      rename,
      discardedEdits,
    };
  }

  return { changedKeys: [], dataTreeChanges: {}, rename, discardedEdits: [] };
};
