import {
  OpenApiSpec,
  Plugin,
  RegisteredPlugins,
  RegisteredSecrets,
} from "@superblocksteam/shared";
import { SUPERBLOCKS_UI_GOOGLE_SHEETS_CLIENT_ID } from "env";
import {
  hasSuperblocksTest,
  isOpenApiBasedRestPlugin,
} from "pages/Integrations/openApiUtils";

const PLUGIN_REGISTRY = RegisteredPlugins(
  SUPERBLOCKS_UI_GOOGLE_SHEETS_CLIENT_ID,
);
const SECRET_REGISTRY = RegisteredSecrets();

export const PLUGIN_INTEGRATIONS_LIST = PLUGIN_REGISTRY.getAll();

export const SECRET_INTEGRATIONS_LIST = SECRET_REGISTRY.getAll();

export const PLUGIN_INTEGRATIONS_MAP = PLUGIN_INTEGRATIONS_LIST.reduce(
  (acc: Record<string, Plugin>, plugin) => {
    acc[plugin.id] = plugin;
    return acc;
  },
  {},
);

export const PLUGIN_INTEGRATIONS_IDS = new Set<string>(
  Object.keys(PLUGIN_INTEGRATIONS_MAP),
);

export const SECRET_INTEGRATIONS_MAP = SECRET_REGISTRY.getAll().reduce(
  (acc: Record<string, Plugin>, plugin) => {
    acc[plugin.id] = plugin;
    return acc;
  },
  {},
);

const ALL_INTEGRATIONS_MAP = {
  ...PLUGIN_INTEGRATIONS_MAP,
  ...SECRET_INTEGRATIONS_MAP,
};

export const isPluginTestable = (
  plugin: Plugin,
  openApiSpec: OpenApiSpec,
): boolean => {
  if (isOpenApiBasedRestPlugin(plugin.id)) {
    return hasSuperblocksTest(openApiSpec);
  }
  return plugin.hasTest ?? true;
};

export const getPluginById = (id = ""): Plugin | undefined =>
  ALL_INTEGRATIONS_MAP[id];

export const hasMetadata = (id: string): boolean => {
  const plugin = getPluginById(id);

  if (plugin && plugin.hasMetadata) {
    return true;
  } else {
    return false;
  }
};
