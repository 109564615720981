import { useMemo, useCallback } from "react";
import { useSaga } from "hooks/store";
import { updateV2FromControlFlowSaga } from "store/slices/apisV2";
import {
  MutationPayloadMap,
  MutatorActionPayload,
  MutationReturnUnion,
  MutatorActions,
  MutationReturnType,
} from "store/slices/apisV2/control-flow/ControlFlowMutator";
import { useApiId } from "./controlFlowSelectors";

// we use a class here to avoid having to bind the dispatches

type CallSagaType = (
  wrappedPayload: MutationPayloadMap[keyof MutationPayloadMap],
) => Promise<MutationReturnUnion>;

class DispatchWrapper implements MutatorActions {
  callSaga: CallSagaType;

  constructor(callSaga: CallSagaType) {
    this.callSaga = callSaga;
  }

  async renameBlock(payload: MutatorActionPayload<"renameBlock">) {
    return (await this.callSaga({
      type: "renameBlock",
      payload,
    })) as MutationReturnType<"renameBlock">;
  }

  async updateBlockConfig(payload: MutatorActionPayload<"updateBlockConfig">) {
    return (await this.callSaga({
      type: "updateBlockConfig",
      payload,
    })) as MutationReturnType<"updateBlockConfig">;
  }

  async dropBlock(payload: MutatorActionPayload<"dropBlock">) {
    return (await this.callSaga({
      type: "dropBlock",
      payload,
    })) as MutationReturnType<"dropBlock">;
  }

  async updateDSL(payload: MutatorActionPayload<"updateDSL">) {
    return (await this.callSaga({
      type: "updateDSL",
      payload,
    })) as MutationReturnType<"updateDSL">;
  }

  async addNewControlBlock(
    payload: MutatorActionPayload<"addNewControlBlock">,
  ) {
    return (await this.callSaga({
      type: "addNewControlBlock",
      payload,
    })) as MutationReturnType<"addNewControlBlock">;
  }

  async addNewStepBlock(payload: MutatorActionPayload<"addNewStepBlock">) {
    return (await this.callSaga({
      type: "addNewStepBlock",
      payload,
    })) as MutationReturnType<"addNewStepBlock">;
  }

  async setActionConfig(payload: MutatorActionPayload<"setActionConfig">) {
    return (await this.callSaga({
      type: "setActionConfig",
      payload,
    })) as MutationReturnType<"setActionConfig">;
  }

  async deleteBlock(payload: MutatorActionPayload<"deleteBlock">) {
    return (await this.callSaga({
      type: "deleteBlock",
      payload,
    })) as MutationReturnType<"deleteBlock">;
  }
}

export const useControlFlowActions = () => {
  const apiId = useApiId();
  const [updateFromControlFlow] = useSaga(updateV2FromControlFlowSaga);

  const callSaga = useCallback(
    async (wrappedPayload: MutationPayloadMap[keyof MutationPayloadMap]) => {
      return await updateFromControlFlow({
        apiId,
        mutationPayload: wrappedPayload,
      });
    },
    [apiId, updateFromControlFlow],
  );

  const Dispatcher = useMemo(() => new DispatchWrapper(callSaga), [callSaga]);

  return Dispatcher;
};
