import { DB_SQL_INITIAL_TEXT, EditorLanguage, FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';
import { sshTunnelSections } from './shared';

export const CouchbasePluginVersions = {
  V1: '0.0.1'
};

type DisplayInfo = { displayName: string; description: string };
const COUCHBASE_ACTIONS_AND_DISPLAY_NAMES: Record<string, DisplayInfo> = {
  runSql: { displayName: 'Query', description: 'Execute a raw query' },
  insert: { displayName: 'Insert item', description: 'Insert an item with the specified key and value' },
  get: { displayName: 'Get item', description: 'Get an item with the specified key' },
  remove: { displayName: 'Remove item', description: 'Remove an item with the specified key' }
};

const makeDropdownItem = (value: string, displayName?: string, subText?: string) => {
  return {
    key: value,
    value: value,
    displayName: displayName ?? value,
    subText: subText ?? ''
  };
};

export const CouchbasePlugin: Plugin = {
  id: 'couchbase',
  name: 'Couchbase',
  moduleName: 'CouchbasePlugin',
  modulePath: 'plugins/couchbase/CouchbasePlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/couchbase.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/couchbase',
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  hasMetadata: true,
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'URI',
            name: 'connection.url',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: `couchbase[s]://my-host-name:11210,my-host-name:8091?[network=external]&[operation_timeout=10.0]`,
            tooltip: {
              markdownText: '[Read the docs](https://docs.couchbase.com/nodejs-sdk/current/howtos/managing-connections.html)'
            },
            rules: [{ required: true, message: 'URI is required' }]
          },
          {
            label: 'Username',
            name: 'connection.user',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'user',
            tooltip: {
              markdownText: 'Cluster access credentials name'
            },
            rules: [{ required: true, message: 'Username is required' }]
          },
          {
            label: 'Password',
            name: 'connection.password',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            placeholder: 'password',
            tooltip: {
              markdownText: 'Cluster access credentials password'
            },
            rules: [{ required: true, message: 'Password is required' }]
          },
          ...sshTunnelSections({
            startVersion: CouchbasePluginVersions.V1,
            isProto: true
          })
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'couchbaseAction.case',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'runSql',
            rules: [{ required: true }],
            options: Object.entries(COUCHBASE_ACTIONS_AND_DISPLAY_NAMES).map(([value, displayInfo]) =>
              makeDropdownItem(value, displayInfo.displayName, displayInfo.description)
            )
          },
          {
            label: 'Bucket',
            name: 'bucketName',
            placeholder: 'my_bucket',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            },
            rules: [{ required: true, message: 'Bucket is required' }]
          },
          {
            label: 'Scope',
            name: 'couchbaseAction.value.identifier.scope',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'scope',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: 'Collection',
            name: 'couchbaseAction.value.identifier.collection',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'collection',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: '', // Query
            name: 'couchbaseAction.value.sqlBody',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT,
            display: {
              show: {
                'couchbaseAction.case': ['runSql']
              }
            }
          },
          {
            label: 'Key',
            name: 'couchbaseAction.value.key',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'key',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: 'Document',
            name: 'couchbaseAction.value.value',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            initialValue: '',
            placeholder: '',
            display: {
              show: {
                'couchbaseAction.case': ['insert']
              }
            }
          }
        ]
      }
    ]
  }
};
