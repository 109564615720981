import { isEmpty } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { orgIsOnPremise, orgIsCloud } from "store/slices/organizations/utils";
import slice from "./slice";

export const selectOnlyOrganization = createCachedSelector(
  [slice.selector, (state: any) => "ONLY_ORGANIZATION"],
  (state) => {
    const organizationId = Object.keys(state.entities)[0];
    return state.entities[organizationId];
  },
)((state) => "ONLY_ORGANIZATION");

export const selectOnlyOrganizationId = createCachedSelector(
  slice.selector,
  (state: any) => "ONLY_ORGANIZATION",
  (state) =>
    !isEmpty(state.entities) ? Object.keys(state.entities)[0] : undefined,
)((state) => "ONLY_ORGANIZATION");

export const selectOnlyOrganizationIsOnPremise = createCachedSelector(
  slice.selector,
  (state: any) => "ONLY_ORGANIZATION",
  (state) =>
    !isEmpty(state.entities)
      ? orgIsOnPremise(Object.values(state.entities)[0])
      : undefined,
)((state) => "ONLY_ORGANIZATION");

export const selectOnlyOrganizationIsCloud = createCachedSelector(
  slice.selector,
  (state: any) => "ONLY_ORGANIZATION",
  (state) =>
    !isEmpty(state.entities)
      ? orgIsCloud(Object.values(state.entities)[0])
      : undefined,
)((state) => "ONLY_ORGANIZATION");

export const selectProfiles = (organizationId: string) => {
  return createCachedSelector(
    slice.selector,
    (state: any) => "ONLY_ORGANIZATION",
    (state) => state.entities[organizationId].profiles,
  )((state) => "ONLY_ORGANIZATION");
};

/**
 * Deprecated
 * use {@link selectOnlyOrganization}
 */
export const selectOrganizationById = createCachedSelector(
  slice.selector,
  (state: any, organizationId: string) => organizationId,
  (state, organizationId) => state.entities[organizationId],
)((state, organizationId) => organizationId);

/**
 * Deprecated
 * use {@link selectOnlyOrganization}
 * TODO we assume there's only one organization for now
 */
export const selectOrganizations = createSelector(
  slice.selector,
  (state) => state.entities,
);
