import Icon, { LoadingOutlined } from "@ant-design/icons";
import { BillingPlan } from "@superblocksteam/shared";
import { Tooltip, Menu, Spin } from "antd";
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as IntercomPaidIcon } from "assets/icons/home/intercom-paid.svg";
import { ReactComponent as IntercomUnpaidIcon } from "assets/icons/home/intercom-unpaid.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/sidebar/settings-icon.svg";
import CountBadge from "components/ui/CountBadge";
import Popper from "components/ui/Popper";
import { useDebounce, useFeatureFlag, usePointerDownOutside } from "hooks/ui";
import { Layers } from "legacy/constants/Layers";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getIsLeftPanePinned } from "legacy/selectors/editorSelectors";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";

import { IntercomPopover } from "../Intercom/IntercomPopover";
import { INTERCOM_LAUNCHER_CLASSNAME } from "../Intercom/constants";
import type { MenuClickEventHandler } from "rc-menu/lib/interface";

const INTERCOM_CLASS_NAME = "intercom";

const Container = styled.div<{ $isLeftPanePinned: boolean }>`
  flex: 0 0 auto;
  position: relative;
  margin-right: ${({ theme, $isLeftPanePinned }) =>
    $isLeftPanePinned ? `${theme.legacy.sidebarWidth}px` : 0};

  .ant-menu-vertical .ant-menu-item {
    overflow: unset;
  }

  &[data-panel-width="wide"] {
    margin-right: ${({ theme, $isLeftPanePinned }) =>
      $isLeftPanePinned ? `${theme.legacy.wideSidebarWidth}px` : 0};
  }
`;

interface ContentProps {
  $visible: boolean;
  $isLeftPanePinned?: boolean;
}

const Content = styled.div<ContentProps>`
  position: absolute;
  top: 0;
  left: ${({ theme, $visible, $isLeftPanePinned }) =>
    $visible ? `${theme.legacy.explorerClosedWidth}px` : "-320px"};
  bottom: 0;
  width: ${({ theme }) => `${theme.legacy.sidebarWidth}px`};
  background: ${({ theme }) => theme.colors.WHITE};
  z-index: ${Layers.explorerSidebar};
  transition: left 250ms;
  box-shadow: ${({ theme, $isLeftPanePinned }) =>
    $isLeftPanePinned ? "none" : theme.shadows.base};
  border-right: ${({ theme, $isLeftPanePinned }) =>
    $isLeftPanePinned ? `1px solid ${theme.colors.GREY_100}` : `none`};

  [data-panel-width="wide"] & {
    width: ${({ theme }) => `${theme.legacy.wideSidebarWidth}px`};
  }
`;

const ContentPage = styled.div<ContentProps>`
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  height: 100%;
  flex-direction: column;
  overflow: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
`;

const SettingWrapper = styleAsClass`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const BottomMenuWrapper = styleAsClass`
  margin-top: auto;
`;

const StyledMenuItemWrapper = styleAsClass`
  color: ${colors.GREY_200};
  background-color: inherit;
  display: flex;
  justify-items: center;
  border-radius: 20%;
  &[data-active="true"] {
    color: ${colors.WHITE};
  }
`;

const SubMenuWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);
  border-radius: 4px;
  background: ${colors.WHITE};
  padding: 6px;
  width: 120px;
`;

const SubMenuOption = styleAsClass`
  font-weight: 400;
  font-size: 12px;
  padding: 8px 10px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${colors.GREY_50};
  }
  span {
    margin-left: 6px;
  }
`;

const styledMenuWrapper = styleAsClass`
  background-color: ${colors.GREY_900};
  border-right: 1px solid ${colors.GREY_800};
  position: relative;
  height: 100%;
  z-index: ${String(Layers.explorerSidebar)};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .ant-menu {
    border: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

 .${INTERCOM_CLASS_NAME} {
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 22px;
    &:hover path {
      stroke: ${colors.WHITE};
    }
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  && .ant-menu-item,
  && .ant-menu-item-only-child {
    width: ${/* explorerClosedWidth */ String(40 - 5 * 2)}px;
    height: ${/* explorerClosedWidth */ String(40 - 5 * 2)}px;
    height: auto;
    padding: 0;
    margin: 5px;
    color: ${colors.GREY_200};

    display: flex;
    justify-items: center;
    border-radius: 20%;

    &:hover {
      color: ${colors.WHITE};
    }

    & svg {
      width: 20px;
      height: 20px;
      margin: auto;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-click-animating-node {
      display: none;
    }

    &.ant-menu-item-selected {
      color: ${colors.WHITE};
      background-color: ${colors.GREY_700};
    }
    &:active {
      background-color: ${colors.GREY_700};
    }

    .ant-menu-item-icon {
      font-size: 28px;
      line-height: 30px;
      margin: auto;
    }

    .ant-menu-title-content {
      display: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.colors.GREY_900};
  position: relative;
  z-index: ${Layers.explorerSidebar};
`;

const iconWrapper = styleAsClass`
  &&& {
    margin: auto;
    font-size: inherit;
  }
`;

const StyledCountBadge = styled(CountBadge)`
  position: absolute;
  top: -2px;
  right: -3px;
  box-shadow: 0 0 0 1px ${(props) => props.theme.colors.GREY_900};
  font-size: 10px;
  line-height: 8px;
  height: 15px;
  min-width: 15px;
`;

interface SidebarProps {
  containerProps?: Record<string, unknown>;
  loading?: boolean;
  selectedKey?: string;
  onSelect?: (value?: string) => void;
  zIndex?: number;
  children?: React.ReactNode;
}

export const Sidebar: FunctionComponent<
  React.PropsWithChildren<SidebarProps>
> & {
  Page: FunctionComponent<React.PropsWithChildren<SidebarPageProps>>;
} = ({ children, loading, selectedKey, onSelect, zIndex, containerProps }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentKey, setCurrentKey] = useState<string | undefined>(undefined);
  const [tooltipKeyHover, setTooltipKeyHover] = useState<string | undefined>(
    undefined,
  );

  const onSelectWithTooltipDismiss = useCallback(
    (value?: string): void => {
      setTooltipKeyHover(undefined);
      if (onSelect) {
        onSelect(value);
      }
    },
    [onSelect],
  );

  const selectedKeys = useMemo(
    () => (selectedKey || currentKey ? [selectedKey ?? currentKey ?? ""] : []),
    [currentKey, selectedKey],
  );

  const handleClickInternal = useCallback<MenuClickEventHandler>(({ key }) => {
    setCurrentKey((currentKey) => (currentKey === key ? undefined : key));
  }, []);

  const handleClickExternal = useCallback<MenuClickEventHandler>(
    ({ key }) => {
      onSelectWithTooltipDismiss(key);
    },
    [onSelectWithTooltipDismiss],
  );

  const isLeftPanePinned = useSelector(getIsLeftPanePinned);

  const handleClose = useCallback(() => {
    if (isLeftPanePinned) return;
    if (onSelect) {
      onSelectWithTooltipDismiss();
    } else {
      setCurrentKey(undefined);
    }
  }, [isLeftPanePinned, onSelect, onSelectWithTooltipDismiss]);

  usePointerDownOutside({
    wrapperRefs: [containerRef],
    wrapperSelectors: [
      ".TimerEditor",
      ".TimerEditorDeleteConfirm",
      ".bp5-portal",
      ".CodeMirror-hints",
      ".ant-menu-submenu-popup",
      ".PropertyPane",
      ".ant-dropdown-menu-title-content",
      ".ant-popconfirm",
      ".embedding-modal-wrapper",
      ".sidebar-modal",
      ".add-entities-dropdown",
      ".add-api-step-popover",
      ".color-picker-wrapper",
      ".ant-select-dropdown",
      ".google-font-list",
      ".ant-dropdown",
      ".popper-wrapper",
      ".popup-confirm-wrapper",
    ],
    onClickOutside: handleClose,
  });

  // Cannot use React.Children.map, because it adds `.$` prefix to keys after the mapping if the are the same as before.
  const childrenArray = React.Children.toArray(children);

  const menuItems = useMemo(() => {
    return (
      childrenArray.filter((child) => {
        if (isSidebarPageElement(child)) {
          // if inSettings is true, will not render this side bar page as menu item, the page will be toggled from settings
          return !child.props.inSettings;
        } else {
          logger.warn("Sidebar children should be Sidebar.Page elements");
          return false;
        }
      }) as ReactElement<SidebarPageProps>[]
    ).map((child) => {
      const keyStr = (child.key ?? "").toString();
      const normalizedKey = keyStr.replace(".$", "");
      const tooltipWrappedIcon = (
        <Tooltip
          placement="right"
          title={child.props.title}
          open={!!tooltipKeyHover && tooltipKeyHover === keyStr}
        >
          <div className={iconWrapper} ref={child.props.navRef}>
            {child.props.icon}
            <StyledCountBadge count={child.props.count} />
          </div>
        </Tooltip>
      );
      return (
        <Menu.Item
          data-test={`editor-left-nav-${normalizedKey}`}
          key={normalizedKey}
          icon={tooltipWrappedIcon}
          onMouseEnter={() => setTooltipKeyHover(keyStr)}
          onMouseLeave={() => setTooltipKeyHover(undefined)}
        />
      );
    });
  }, [childrenArray, tooltipKeyHover]);

  const onSettingItemClick = useCallback(
    (e: React.MouseEvent, key: SideBarKeys) => {
      e.stopPropagation();
      onSelect?.(key);
      setIsSettingPopperOpen(false);
      setIsSettingSubMenuHovered(false);
    },
    [onSelect],
  );

  const settingItems = useMemo(() => {
    return (
      childrenArray.filter((child) => {
        if (isSidebarPageElement(child)) {
          // if inSettings is true, will not render this side bar page as menu item, the page will be toggled from settings
          return child.props.inSettings;
        } else {
          logger.warn("Sidebar children should be Sidebar.Page elements");
          return false;
        }
      }) as ReactElement<SidebarPageProps>[]
    ).map((child) => {
      const keyStr = (child.key ?? "").toString();
      const normalizedKey = keyStr.replace(".$", "");
      return (
        <div
          key={normalizedKey}
          className={SubMenuOption}
          onClick={(e) => onSettingItemClick(e, normalizedKey as SideBarKeys)}
          data-test={normalizedKey}
        >
          {child.props.icon}
          <span>{child.props.title}</span>
        </div>
      );
    });
  }, [childrenArray, onSettingItemClick]);

  const contentPages = useMemo(() => {
    return childrenArray.map((child) => {
      if (isSidebarPageElement(child)) {
        const normalizedKey = child.key?.toString().replace(".$", "");

        const isVisible = selectedKeys[0] === normalizedKey;
        return (
          <ContentPage key={`${normalizedKey}_page`} $visible={isVisible}>
            {isVisible &&
              (loading ? (
                <SpinnerContainer>
                  <Spin indicator={<LoadingOutlined spin />} size="large" />
                </SpinnerContainer>
              ) : (
                child
              ))}
          </ContentPage>
        );
      } else {
        logger.warn("Sidebar children should be Sidebar.Page elements");
        return undefined;
      }
    });
  }, [childrenArray, loading, selectedKeys]);

  const settingPopperRef = useRef<HTMLDivElement>(null);
  const [isSettingPopperOpen, setIsSettingPopperOpen] = useState(false);
  const [isSettingIconHovered, setIsSettingIconHovered] = useState(false);
  const [isSettingSubMenuHovered, setIsSettingSubMenuHovered] = useState(false);
  const mouseInSettingIcon = useCallback(() => {
    setIsSettingIconHovered(true);
  }, []);
  const mouseOutSettingIcon = useDebounce(() => {
    setIsSettingIconHovered(false);
  }, 200);
  const onSettingSubMenuHover = useCallback(() => {
    setIsSettingSubMenuHovered(true);
  }, []);
  const onSettingSubMenuOut = useCallback(() => {
    setIsSettingSubMenuHovered(false);
  }, []);

  usePointerDownOutside({
    wrapperRefs: [settingPopperRef],
    wrapperSelectors: ["[id=sidebar-setting-wrapper]"],
    onClickOutside: () => {
      setIsSettingPopperOpen(false);
      setIsSettingSubMenuHovered(false);
    },
  });

  const isSettingPopperVisible =
    isSettingPopperOpen || isSettingIconHovered || isSettingSubMenuHovered;

  const organization = useSelector(selectOnlyOrganization);
  const isPaidPlan = organization.billing.plan === BillingPlan.ENTERPRISE;
  const enableIntercomLiveChat = useFeatureFlag(Flag.ENABLE_INTERCOM_LIVE_CHAT);

  const isPaidPlanOrEnabledChat = isPaidPlan || enableIntercomLiveChat;
  const iconComponent = isPaidPlanOrEnabledChat
    ? IntercomPaidIcon
    : IntercomUnpaidIcon;

  return (
    <Container
      ref={containerRef}
      style={{ zIndex }}
      {...containerProps}
      $isLeftPanePinned={isLeftPanePinned}
    >
      <Content
        $visible={selectedKeys.length > 0}
        $isLeftPanePinned={isLeftPanePinned}
      >
        {contentPages}
      </Content>
      <div className={styledMenuWrapper}>
        <StyledMenu
          selectedKeys={selectedKeys}
          onClick={onSelect ? handleClickExternal : handleClickInternal}
        >
          {menuItems}
        </StyledMenu>
        <div className={BottomMenuWrapper}>
          <div
            className={SettingWrapper}
            data-test={`editor-left-nav-setting`}
            id="sidebar-setting-wrapper"
            onClick={() => {
              setIsSettingPopperOpen(!isSettingPopperOpen);
              setIsSettingIconHovered(false);
            }}
          >
            <div
              className={StyledMenuItemWrapper}
              data-active={isSettingPopperVisible}
              onMouseEnter={mouseInSettingIcon}
              onMouseLeave={mouseOutSettingIcon}
            >
              <SettingsIcon data-test="sidebar-nav-item-settings" />
            </div>
            {isSettingPopperVisible && (
              <Popper
                zIndex={100}
                isOpen={isSettingPopperVisible}
                targetNode={
                  document.querySelector(
                    "[id=sidebar-setting-wrapper]",
                  ) as Element
                }
                ref={settingPopperRef}
                placement="right-start"
              >
                <div
                  className={SubMenuWrapper}
                  onMouseEnter={onSettingSubMenuHover}
                  onMouseLeave={onSettingSubMenuOut}
                >
                  {settingItems}
                </div>
              </Popper>
            )}
          </div>
          <IntercomPopover isPaidPlan={isPaidPlanOrEnabledChat}>
            <div
              className={INTERCOM_CLASS_NAME}
              onClick={(e) => {
                if (!isPaidPlanOrEnabledChat) {
                  e.stopPropagation(); // Prevent the live chat window from opening
                }
              }}
            >
              <Icon
                component={iconComponent}
                className={INTERCOM_LAUNCHER_CLASSNAME}
              />
            </div>
          </IntercomPopover>
        </div>
      </div>
    </Container>
  );
};

function isSidebarPageElement(
  node: ReactNode,
): node is ReactElement<SidebarPageProps> {
  return Boolean(
    React.isValidElement<SidebarPageProps>(node) &&
      (("icon" in node.props && "title" in node.props) ||
        "inSettings" in node.props),
  );
}

interface SidebarPageProps {
  icon?: React.ReactNode;
  title?: string;
  count?: number;
  navRef?: React.MutableRefObject<HTMLDivElement | null>;
  inSettings?: boolean; //if set to true, the page will be opened from settings so it will not show in sidebar menu with icons
  children?: React.ReactNode;
}

Sidebar.Page = ({ children }) => {
  return <>{children}</>;
};

Sidebar.Page.displayName = "Sidebar.Page";
