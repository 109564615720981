import { RepositoryEntityType } from "@superblocksteam/shared";
import { Tag } from "antd";
import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const StyledTag = styleAsClass`
  margin: 0 auto;
  text-align: center;
  padding: 1px 8px;
  border-radius: 32px;
  font-weight: 500;
`;

const getEntityNameByType = (type: RepositoryEntityType) => {
  switch (type) {
    case RepositoryEntityType.WORKFLOW:
      return "Workflow";
    case RepositoryEntityType.SCHEDULED_JOB:
      return "Scheduled Job";
    default:
      return "Application";
  }
};

const getEntityColorByType = (type: RepositoryEntityType) => {
  switch (type) {
    case RepositoryEntityType.WORKFLOW:
      return colors.ACCENT_GREEN;
    case RepositoryEntityType.SCHEDULED_JOB:
      return colors.ACCENT_PURPLE;
    default:
      return colors.ACCENT_BLUE_500;
  }
};

interface TypeTagProps {
  type: RepositoryEntityType;
}

const TypeTag = ({ type }: TypeTagProps) => {
  const typeLabel = getEntityNameByType(type);
  const color = getEntityColorByType(type);

  return (
    <Tag
      className={StyledTag}
      style={{
        color: color,
        background: `${color}14`,
        borderColor: `${color}14`,
      }}
    >
      {typeLabel}
    </Tag>
  );
};

export default TypeTag;
