import equal from "@superblocksteam/fast-deep-equal/react";
import {
  ApplicationScope,
  EvaluationPair,
  LanguagePluginID,
} from "@superblocksteam/shared";
import CodeMirror, { EditorConfiguration, KeyMap } from "codemirror";
import { JSHINT } from "jshint";
import { debounce, get, throttle, isNil, isEmpty } from "lodash";
import React, { Component, MutableRefObject } from "react";
import { flushSync } from "react-dom";
import { connect, useStore } from "react-redux";

import { useParams } from "react-router";
import { Transition } from "react-transition-group";
import shallowEqual from "shallowequal";
import {
  entityDefinitions,
  GLOBAL_FUNCTIONS,
} from "autocomplete/dataTreeTypeDefCreator";
import {
  ErrorTypes as FormattingErrorTypes,
  isSupportedFormatter,
} from "code-formatting/constants";
import FormatWorkerService from "code-formatting/worker/FormatWorkerService";
import {
  canNavigateTo,
  viewApi,
  viewWidget,
  viewStateVar,
  viewTimer,
  viewEmbedProp,
  viewEvent,
} from "hooks/ui/useNavigateTo";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import {
  AiContextMode,
  KeyBindings,
} from "legacy/constants/EditorPreferencesConstants";
import { getEntity } from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getContext } from "legacy/pages/Editor/Codeium/context_utils";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import {
  getDataTreeForAutocomplete,
  getUserAccessibleDataTree,
} from "legacy/selectors/dataTreeSelectors";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import {
  getAllEntityNames,
  getSharedDeveloperPreferences,
  getWidgets,
} from "legacy/selectors/sagaSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import {
  extractJsEvaluationPairs,
  extractPythonEvaluationPairs,
} from "legacy/workers/evaluationUtils";
import { selectActionById } from "store/slices/apis";
import { extractBindingsFromValueWithRanges } from "store/slices/apis/utils/bindings";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { selectUserAccessibleScopeForApiUnion } from "store/slices/apisShared/selectors/selectUserAccessibleScopeForApiUnion";
import {
  selectCachedControlFlowById,
  selectV2ApiById,
  selectV2BlockById,
} from "store/slices/apisV2";
import {
  selectDatasourceMetaById,
  selectSecretStoreMetadata,
} from "store/slices/datasources";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganizationIsOnPremise } from "store/slices/organizations/selectors";
import { styleAsClass } from "styles/styleAsClass";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { ApiErrorType } from "utils/error/error";
import { debounceWithState, NOOP } from "utils/function";
import logger from "utils/logger";

import { getEntityACShortcutString, isUserOnMac } from "utils/navigator";
import omit from "utils/omit";
import ShortcutMenu from "../ShortcutMenu";

import {
  EditorModes,
  EditorSize,
  EditorTheme,
  EditorThemes,
  Hinter,
  TabBehaviour,
  HintHelper,
  AutocompleteFunctionsType,
} from "./EditorConfig";
import EvaluatedValuePopup, { VALIDATION_STATUS } from "./EvaluatedValuePopup";
import FormattingStatusIndicator from "./FormattingStatusIndicator";
import { getValue } from "./datatree/getValue";
import {
  DynamicAutocompleteInputWrapper,
  EditorJsExprModeIndicator,
  EditorWrapper,
  IconContainer,
} from "./editorStyles";
import {
  bindingHint,
  entityHint,
  presetListHint,
  pythonHint,
  sqlHint,
} from "./hintHelpers";
import {
  functionScopeErrorMarker,
  nullMarker,
  appScopeErrorMarker,
} from "./markHelpers";

import "codemirror/addon/hint/show-hint";
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/mode/multiplex";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/lint";
import "codemirror/addon/search/search";
import "codemirror/addon/search/jump-to-line";
import "codemirror/addon/search/match-highlighter";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/dialog/dialog";
import "codemirror/keymap/vim";
import "./addons/hint/sql-hint";
import "./addons/no-new-lines";
import "./addons/active-lines";
import "./textHover";
import "./textClick";
import "./modes";
import "./CodeEditor.css";
import type { Props, EditorProps, ReduxStateProps } from "./types";
import type { AppState } from "store/types";

const BRACKET_MATCHING_MODES: string[] = [
  EditorModes.JAVASCRIPT,
  EditorModes.JSON,
  EditorModes.JSON_WITH_BINDING,
  EditorModes.PYTHON,
];

const MULTILINE_MODES: string[] = [
  EditorModes.JAVASCRIPT,
  EditorModes.PYTHON,
  EditorModes.SQL_WITH_BINDING,
];

const ORIGIN_ENTITY = "+entity";
const ORIGIN_COMPLETE = "complete";

const NO_HINTS_ORIGINS = [ORIGIN_ENTITY, ORIGIN_COMPLETE];

export const SQL_MODES: string[] = [
  EditorModes.SQL,
  EditorModes.SQL_WITH_BINDING,
];

(window as any).JSHINT = JSHINT;

const COMPLETE_REGEX = /([a-zA-Z_$][0-9a-zA-Z_$]*|\[["']|\{\{|\.)$/;
const LEADING_TABS_REGEX = /^ *(\t+)/;
const FOUR_SPACES = "    ";
let handleShortcutHoverLeaveTimer: ReturnType<typeof setTimeout>;

export const PLACEHOLDER_TEXT = `${getEntityACShortcutString()} for variables or`;

const formattingService = new FormatWorkerService();

enum FormattingStatus {
  STARTED = "started",
  FAILED = "failed",
}

type State = {
  isFocused: boolean;
  isOpened: boolean;
  autoCompleteVisible: boolean;
  shortcutHoverValue: any;
  shortcutMenuHovered: boolean;
  canEvaluateShortcutValue: boolean;
  isAllSelected: boolean;
  delayingForValidationError: boolean;
  showFormattingStatus?: boolean;
  formattingStatus?: FormattingStatus;
  formattingError?: FormattingErrorTypes | string;
};

const FORMATTING_MESSAGE_MIN_DURATION = 150; //ms
const FORMATTING_ERROR_MESSAGE_DURATION = 3000; //ms
const FORMATTING_TRANSITION_MESSAGE_DURATION = 200; //ms;
const FormattingStatusContainer = styleAsClass`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
`;

const TransitionExitedClassName = styleAsClass`
  opacity: 0;
  transition: opacity ${String(FORMATTING_TRANSITION_MESSAGE_DURATION)}ms;
`;

class CodeEditor extends Component<Props, State> {
  static defaultProps = {
    showShortcutMenu: true,
  };

  textArea = React.createRef<HTMLTextAreaElement>();
  editor!: CodeMirror.Editor;
  entityHinter: Hinter | undefined;
  presetListHinter: Hinter | undefined;
  hinters: Hinter[] = [];
  placeholder?: HTMLPreElement;
  linesElementPadding?: number;
  nullMarkers: CodeMirror.TextMarker[] = [];
  functionScopeErrorMarkers: CodeMirror.TextMarker[] = [];
  appScopeErrorMarkers: CodeMirror.TextMarker[] = [];
  aiAssistantLineNumber?: number;
  formatCodeAbortController: AbortController;

  constructor(props: Props) {
    super(props);

    this.state = {
      isFocused: false,
      isOpened: false,
      autoCompleteVisible: false,
      shortcutHoverValue: {},
      shortcutMenuHovered: false,
      canEvaluateShortcutValue: true,
      isAllSelected: false,
      delayingForValidationError: false,
      showFormattingStatus: false,
    };

    this.formatCodeAbortController = new AbortController();
  }

  componentDidMount(): void {
    if (this.textArea.current) {
      if (
        !this.props.height &&
        this.props.placeholder &&
        this.props.placeholder.split("\n").length > 1
      ) {
        this.placeholder = document.createElement("pre");
        this.placeholder.className = "CodeMirror-line-like";
        this.placeholder.innerText = this.props.placeholder;
      }

      const gutters =
        this.props.showLineNumbers && this.props.openAiAssistant
          ? ["ai-assistant", "CodeMirror-linenumbers"]
          : this.props.showLineNumbers
            ? ["CodeMirror-linenumbers"]
            : [];

      const options: EditorConfiguration = {
        mode: this.props.mode,
        theme: EditorThemes[this.props.theme],
        noNewlines:
          this.props.noNewlines !== undefined ? this.props.noNewlines : false,
        viewportMargin: 10,
        tabSize: this.props.mode === EditorModes.PYTHON ? 4 : 2,
        autoCloseBrackets: true,
        indentWithTabs: this.props.tabBehaviour === TabBehaviour.INDENT,
        lineWrapping:
          this.props.size !== EditorSize.COMPACT || this.props.lineWrapping,
        lineNumbers: this.props.showLineNumbers,
        gutters,
        addModeClass: true,
        matchBrackets: false,
        cursorScrollMargin: 10,
        showCursorWhenSelecting: true,
        scrollbarStyle:
          this.props.noScroll || this.props.size === EditorSize.COMPACT
            ? "null"
            : "native",
        lint: !this.isJavascriptEditor()
          ? undefined
          : {
              // @ts-expect-error: ts def for lint property is incorrect, this serves as
              // "options" obj for JSHINT but definition is missing this information.
              esversion: 11,

              // Ignore jshint warnings:
              // https://github.com/jshint/jshint/blob/6d06f8f8fccc0b89a54bfe58af83a54bc1fae414/src/messages.js
              "-W014": this.props.isNewCodeEditorFeatureFlagEnabled, // Misleading line break before '{a}'; readers may interpret this as an expression boundary. This rule contradicts our code formatter
              getAnnotations: (text: string, options: any, cm: any) => {
                const annotations = cm.getHelper(CodeMirror.Pos(0, 0), "lint");
                // without wrapping in async, JSHINT returns error for top-level await
                // as it's internal grammar doesn't allow for await without async block
                return annotations(
                  // note: both branches add an extra line to the top and bottom of the text, so this can be reversed the same way
                  this.props.isExpression
                    ? `(\n${text}\n)`
                    : `async () => {\n${text}\n}`,
                  options,
                  cm,
                ).map(
                  (annotation: {
                    from: CodeMirror.Position;
                    to: CodeMirror.Position;
                  }) => {
                    // Remove the extra that we added above
                    const withOffset = {
                      ...annotation,
                      from: {
                        ...annotation.from,
                        line: annotation.from.line - 1,
                      },
                      to: {
                        ...annotation.to,
                        line: annotation.to.line - 1,
                      },
                    };
                    const lineLength = cm.getLine(withOffset.to.line)?.length;
                    // Sometimes jshint produces errors past the end of the line,
                    // which is invisible. We fix these cases by moving the error
                    // onto the last character.
                    if (
                      typeof lineLength === "number" &&
                      withOffset.from.line === withOffset.to.line &&
                      lineLength <= withOffset.from.ch
                    ) {
                      return {
                        ...withOffset,
                        from: { ...withOffset.from, ch: lineLength - 1 },
                        to: { ...withOffset.to, ch: lineLength },
                      };
                    }
                    return withOffset;
                  },
                );
              },
              ...(this.props.isExpression
                ? {
                    expr: true,
                    asi: true,
                  }
                : {}),
            },
        extraKeys: {
          "Ctrl-F": (cm) => cm.execCommand("findPersistent"),
          "Cmd-F": (cm) => cm.execCommand("findPersistent"),
          "Ctrl-/": (cm) => cm.execCommand("toggleComment"),
          "Cmd-/": (cm) => cm.execCommand("toggleComment"),

          "Ctrl-U": NOOP,
          "Cmd-U": NOOP,
        },
        configureMouse: (_cm, _repeat, _ev) => {
          return { addNew: false };
        },
        placeholder: this.placeholder || this.props.placeholder,
        readOnly:
          this.props.disabled || this.props.isIconSelectorOpen
            ? "nocursor"
            : false,
        textHover: {
          data: () => this.props.dynamicData,
          additionalData: () => this.props.combinedAdditionalDynamicData ?? {},
          apiScope: () => this.props.apiScope,
          appScope: () => this.props.currentScope,
        },
        textClick: {
          canClick: (_cm, tokens) => {
            const value = getValue(tokens, {
              data: this.props.dynamicData,
              additionalData: this.props.combinedAdditionalDynamicData ?? {},
              apiScope: this.props.apiScope,
              appScope: this.props.currentScope,
            });
            return canNavigateTo(value);
          },
          onClick: (_cm, tokens) => {
            const value = getValue(tokens, {
              data: this.props.dynamicData,
              additionalData: this.props.combinedAdditionalDynamicData ?? {},
              apiScope: this.props.apiScope,
              appScope: this.props.currentScope,
            });
            switch (value.ENTITY_TYPE) {
              case ENTITY_TYPE.WIDGET: {
                const widgets = getWidgets(this.props.store.getState());
                viewWidget(value, widgets, this.props.dispatch);
                break;
              }
              case ENTITY_TYPE.ACTION:
                viewApi(value, this.props.appId, "", this.props.dispatch);
                break;
              case ENTITY_TYPE.STATE_VAR:
                viewStateVar(value, this.props.dispatch);
                break;
              case ENTITY_TYPE.TIMER:
                viewTimer(value, this.props.dispatch);
                break;
              case ENTITY_TYPE.EMBED_PROP:
                viewEmbedProp(value, this.props.dispatch);
                break;
              case ENTITY_TYPE.EMBEDDING:
                this.props.dispatch(
                  updateApplicationSidebarKey({
                    selectedKey: SideBarKeys.EMBEDDING,
                  }),
                );
                break;
              case ENTITY_TYPE.CUSTOM_EVENT:
                viewEvent(value, this.props.dispatch);
            }
          },
        },
      };

      if (
        this.props.showLineNumbers &&
        this.props.isNewCodeEditorFeatureFlagEnabled
      ) {
        options.styleActiveLine = { nonEmpty: true };
      }

      if (
        this.props.showLineNumbers &&
        this.props.keyBindings !== KeyBindings.Default
      ) {
        options.keyMap = this.props.keyBindings;
      }

      if (this.props.openAiAssistant) {
        // add hotkeys for cmd I and ctrl I to open AI assistant
        (options.extraKeys as KeyMap) = {
          ...(options.extraKeys as KeyMap),
          "Ctrl-I": () => this.props.openAiAssistant?.(),
          "Cmd-I": () => this.props.openAiAssistant?.(),
        };
      }

      if (
        !options.readOnly &&
        this.props.isNewCodeEditorFeatureFlagEnabled &&
        this.props.formatCodeOnShortcut
      ) {
        (options.extraKeys as KeyMap) = {
          ...(options.extraKeys as KeyMap),
          "Ctrl-S": this.handleFormatCodeKeyBinding,
          "Cmd-S": this.handleFormatCodeKeyBinding,
        };
      }

      if (!this.props.disableBindings) {
        (options.extraKeys as KeyMap) = {
          ...(options.extraKeys as KeyMap),
          "Ctrl-K": this.handleEntityAutocompleteKeybinding,
          "Cmd-K": this.handleEntityAutocompleteKeybinding,
        };
      }

      if (this.props.onEscapeKey) {
        (options.extraKeys as KeyMap) = {
          ...(options.extraKeys as KeyMap),
          Esc: this.props.onEscapeKey,
        };
      }

      if (this.props.disableTabIndent) {
        (options.extraKeys as KeyMap) = {
          ...(options.extraKeys as KeyMap),
          Tab: false,
          "Shift-Tab": false,
        };
      }

      if (
        !this.props.input.onChange ||
        this.props.disabled ||
        this.props.isIconSelectorOpen
      ) {
        options.readOnly = true;
        options.scrollbarStyle = "null";
      }

      if (this.props.tabBehaviour === TabBehaviour.INPUT) {
        (options.extraKeys as KeyMap)["Tab"] = false;
      }

      if (this.props.onSubmit) {
        (options.extraKeys as KeyMap)["Ctrl-Enter"] = this.handleSubmit;
        (options.extraKeys as KeyMap)["Cmd-Enter"] = this.handleSubmit;
      }

      if (this.props.enableSearch === false) {
        this.disableSearchKeys(options);
      }

      this.addPythonIndentationOptions(options);

      this.editor = CodeMirror.fromTextArea(this.textArea.current, options);

      this.addPythonReindentWithSpacesButton();

      this.editor.on("change", this.handleChange);
      this.editor.on("change", this.handleChangeDebounced);
      this.editor.on("focus", this.handleEditorFocus);
      this.editor.on("blur", () => {
        // editor blur event fires before shortcut menu option onClick event
        // let the shortcut menu handle the blur instead of editor, in case the shortcut menu is unmounted before menu option onClick is called
        if (!this.state.shortcutMenuHovered) {
          this.initiateEditorBlur();
        }
      });
      this.editor.on("cursorActivity", this.handleCursorMovement);
      this.editor.on("cursorActivity", this.handleCursorMovementDebounced);
      this.editor.on("startCompletion", this.handleStartCompletion);
      this.editor.on("endCompletion", this.handleEndCompletion);

      if (MULTILINE_MODES.includes(this.props.mode.toLowerCase())) {
        this.editor.on("beforeSelectionChange", this.handleNewLinePlaceholder);
      }

      if (this.props.height) {
        this.editor.setSize(null, this.props.height);
      } else {
        this.editor.setSize(null, "auto");

        // Calculate padding around placeholder and add refresh handler
        if (this.placeholder) {
          const linesElement: HTMLElement | null = this.editor
            .getScrollerElement()
            .querySelector(".CodeMirror-lines");

          const linesElementStyle = linesElement
            ? getComputedStyle(linesElement)
            : { paddingTop: 0, paddingBottom: 0 };

          this.linesElementPadding =
            parseInt(linesElementStyle.paddingTop.toString(), 10) +
            parseInt(linesElementStyle.paddingBottom.toString(), 10);

          this.editor.on("refresh", this.handleRefreshThrottled);

          this.handleRefreshThrottled();
          this.handleRefreshThrottled.flush();
        }
      }

      // Set value of the editor
      this.editor.setValue(this.getInputValue());
      this.updateNullMarkers();
      this.updateFunctionScopeErrorMarkers();
      this.updateAppScopeErrorMarkers();
      this.props.onEditorMount && this.props.onEditorMount(this.editor);
      this.editor.getDoc().markClean();
      if (this.props.autoFocus) {
        setTimeout(() => {
          this.editor.focus();
        }, 10);
      }
    }
  }

  private handleFormatCodeKeyBinding = (instance: CodeMirror.Editor) => {
    (async () => {
      try {
        const originalCursor = this.editor.getCursor();
        await this.formatCode(this.formatCodeAbortController.signal);
        if (!this.formatCodeAbortController.signal.aborted) {
          this.editor.setCursor(originalCursor);
        }
      } catch {
        logger.error("Could not format code with shortcut");
      }
    })();
  };

  private disableSearchKeys(options: CodeMirror.EditorConfiguration) {
    (options.extraKeys as KeyMap) = {
      ...(options.extraKeys as KeyMap),
      ...(isUserOnMac()
        ? {
            "Cmd-F": false,
            "Cmd-G": false,
            "Shift-Cmd-G": false,
            "Cmd-Alt-F": false,
            "Shift-Cmd-Alt-F": false,
          }
        : {
            "Ctrl-F": false,
            "Ctrl-G": false,
            "Shift-Ctrl-G": false,
            "Shift-Ctrl-F": false,
            "Shift-Ctrl-R": false,
          }),
    };
  }

  cancelFormattingProcess() {
    this.formatCodeAbortController.abort(); // Cancel current formatting (if any)
    this.debouncedFormatCode.cancel(); // Cancel any queued formatting (if any)
  }

  // Make sure that we leave without ongoing work
  componentWillUnmount() {
    this.cancelFormattingProcess();
    // We don't terminate formattingService - it's re-used by other editors

    const hide = this.editor.state.textHover.hide;
    if (typeof hide === "function") hide();

    // Remove DOM elements
    this.textArea.current?.remove();
    (this.textArea as MutableRefObject<HTMLTextAreaElement | null>).current =
      null;

    this.editor.closeHint();
    this.handleChangeDebounced.flush();
    this.updateHintersDebounced.cancel();
    this.handleCursorMovementDebounced.cancel();
    this.triggerChangeThrottled.cancel();
    this.triggerHinters.cancel();
    this.updateNullMarkers.cancel();
    this.updateFunctionScopeErrorMarkers.cancel();
    this.updateAppScopeErrorMarkers.cancel();
    this.initiateEditorBlur.cancel();

    this.hinters.forEach((hinter) => hinter.unregister());
    this.entityHinter?.unregister();
    this.presetListHinter?.unregister();
    if (this.props.onEditorWillUnmount) {
      this.props.onEditorWillUnmount(this.editor.getValue());
    }
  }

  // This happens on state updates, such as when blurring the editor.
  componentDidUpdate(prevProps: Props, prevState: State): void {
    // we reassign to avoid type check errors below when accessing prevProps, and we want to remove the react component props
    const previous = prevProps;
    if (equal(previous, this.props)) return;

    if (!this.state.isFocused) {
      this.updateEditorValue(this.getInputValue());
    }
    // Update the dynamic bindings for autocomplete
    // Because we share one tern server for all editors, we need to update the
    // definitions when we come back to use the editor.
    const focusChanged = prevState.isFocused !== this.state.isFocused;
    if (
      this.state.isFocused &&
      (focusChanged ||
        prevProps.dynamicData !== this.props.dynamicData ||
        prevProps.autocompleteConfiguration !==
          this.props.autocompleteConfiguration ||
        prevProps.combinedAdditionalDynamicData !==
          this.props.combinedAdditionalDynamicData ||
        prevProps.datasourceMetadata !== this.props.datasourceMetadata)
    ) {
      this.updateNullMarkers();
      this.updateFunctionScopeErrorMarkers();
      this.updateAppScopeErrorMarkers();
      this.updateHintersDebounced();
    }
    if (prevProps.placeholder !== this.props.placeholder) {
      this.editor.setOption("placeholder", this.props.placeholder);
    }
    if (
      prevProps.disabled !== this.props.disabled ||
      prevProps.isIconSelectorOpen !== this.props.isIconSelectorOpen
    ) {
      this.editor.setOption(
        "readOnly",
        this.props.disabled || this.props.isIconSelectorOpen,
      );
    }

    if (
      this.props.showLineNumbers &&
      prevProps.keyBindings !== this.props.keyBindings
    ) {
      this.editor.setOption("keyMap", this.props.keyBindings);
    }

    // Support displaying formatting status and formatting errors programmatically by props
    if (
      prevProps.formattingError !== this.props.formattingError ||
      prevProps.formattingStatus !== this.props.formattingStatus ||
      (this.state.formattingStatus === undefined &&
        this.props.formattingStatus !== undefined) ||
      (this.state.formattingError === undefined &&
        this.props.formattingError !== undefined)
    ) {
      if (this.props.isNewCodeEditorFeatureFlagEnabled) {
        this.setState({
          showFormattingStatus: true,
          formattingStatus: this.props.formattingStatus,
          formattingError: this.props.formattingError,
        });
      }
    }
  }

  // Updating Tern & other definitions is a slow process (hundreds of ms),
  // so we only update once per second instead of every keypress.
  updateHintersDebounced = debounce(() => {
    this.hinters.forEach(
      (hinter) =>
        hinter.update &&
        hinter.update({
          data: this.props.dynamicData,
          additionalData: this.props.combinedAdditionalDynamicData,
          datasourceMetadata: this.props.datasourceMetadata?.metadata,
          apiScope: this.props.apiScope,
          appScope: this.props.currentScope,
          configuration: {
            ...this.props.autocompleteConfiguration,
            isProgrammaticTableEnabled: this.props.isProgrammaticTableEnabled,
          },
        }),
    );
    this.entityHinter &&
      this.entityHinter.update &&
      this.entityHinter.update({
        data: this.props.dynamicData,
        additionalData: this.props.combinedAdditionalDynamicData,
        datasourceMetadata: this.props.datasourceMetadata?.metadata,
        apiScope: this.props.apiScope,
        appScope: this.props.currentScope,
        configuration: {
          ...this.props.autocompleteConfiguration,
          isProgrammaticTableEnabled: this.props.isProgrammaticTableEnabled,
        },
      });
  }, 1000);

  isPropertyPaneEditor(): boolean {
    return Boolean(this.props.dataTreePath);
  }

  isJavascriptEditor(): boolean {
    return this.props.mode.toLowerCase() === EditorModes.JAVASCRIPT;
  }

  isPythonEditor(): boolean {
    return this.props.mode.toLowerCase() === EditorModes.PYTHON;
  }

  isSqlEditor(): boolean {
    return SQL_MODES.includes(this.props.mode.toLowerCase());
  }

  getInputValue(): string {
    const inputValue = this.props.input.value;

    if (typeof inputValue === "object") {
      return JSON.stringify(inputValue, null, 2);
    } else if (typeof inputValue === "number") {
      return inputValue.toString();
    } else if (typeof inputValue === "string") {
      return inputValue;
    }

    return "";
  }

  handleSubmit = () => {
    if (typeof this.props.onSubmit === "function") {
      this.props.onSubmit(this.editor.getValue());
    }
  };

  startAutocomplete() {
    const showBindings =
      !this.props.autocompleteConfiguration.bindingsDisabled ||
      this.isJavascriptEditor();
    const hintHelpers = [
      showBindings && bindingHint,
      this.isPythonEditor() && pythonHint,
      this.isSqlEditor() && sqlHint,
    ].filter((helper): helper is HintHelper => Boolean(helper));

    this.hinters = hintHelpers.map((helper) =>
      helper(
        this.editor,
        this.props.dynamicData,
        this.props.combinedAdditionalDynamicData,
        this.props.datasourceMetadata?.metadata,
        this.props.apiScope,
        this.props.currentScope,
        {
          ...this.props.autocompleteConfiguration,
          isProgrammaticTableEnabled: this.props.isProgrammaticTableEnabled,
        },
      ),
    );

    this.entityHinter = entityHint(
      this.editor,
      this.props.dynamicData,
      this.props.combinedAdditionalDynamicData,
      this.props.datasourceMetadata?.metadata,
      this.props.apiScope,
      this.props.currentScope,
      {
        ...this.props.autocompleteConfiguration,
        isProgrammaticTableEnabled: this.props.isProgrammaticTableEnabled,
      },
      this.props.openAiAssistant,
    );

    this.presetListHinter = presetListHint(this.props.presetOptions ?? []);
  }

  handleCursorMovement = (instance: CodeMirror.Editor) => {
    if (
      !this.props.input.onChange ||
      this.props.disabled ||
      this.props.isIconSelectorOpen
    ) {
      return;
    }

    const mode = instance.getModeAt(instance.getCursor());

    if (mode && mode.name && BRACKET_MATCHING_MODES.includes(mode.name)) {
      this.editor.setOption("matchBrackets", true);
    } else {
      this.editor.setOption("matchBrackets", false);
    }
  };

  handleCursorMovementDebounced = debounce((instance: CodeMirror.Editor) => {
    const selection = instance.getSelection();
    if (selection.length === instance.getValue().length) {
      this.setState({
        isAllSelected: true,
      });
    } else if (this.state.isAllSelected) {
      // set isAllSelected to false indebounce, as cursor event happens before shortcut menu onClick event.
      this.setState({
        isAllSelected: false,
      });
    }
    this.addAiAssistantMarker(instance);
  }, 200);

  addAiAssistantMarker = (cm: CodeMirror.Editor) => {
    // if there are line numbers shown + the ai assistant is available, show a gutter icon on the first line of the selected text
    if (this.props.showLineNumbers && this.props.openAiAssistant) {
      const cursor = cm.getCursor("to");
      if (cursor.line !== this.aiAssistantLineNumber) {
        // remove the old gutter icon
        if (this.aiAssistantLineNumber != null) {
          cm.setGutterMarker(this.aiAssistantLineNumber, "ai-assistant", null);
        }

        cm.setGutterMarker(cursor.line, "ai-assistant", this.makeMarker());
        this.aiAssistantLineNumber = cursor.line;
      }
    }
  };

  makeMarker = () => {
    const marker = document.createElement("div");
    marker.className = "ai-assistant-marker";
    if (this.props.openAiAssistant) {
      marker.onclick = () => this.props.openAiAssistant?.("gutter");
    }
    return marker;
  };

  handleEditorFocus = (instance: CodeMirror.Editor, _event: FocusEvent) => {
    flushSync(() => {
      // Cancel any ongoing code formatting.
      // There could be an ongoing code formatting process,
      // that when returns could make the cursor jump (to the start),
      // and lose track of the current cursor position,
      // or its result could be stale after the user started typing again
      // and override user changes.
      this.cancelFormattingProcess();
      this.formatCodeAbortController = new AbortController();

      if (
        this.initiateEditorBlur.cancel() ||
        instance.state.completionActive ||
        this.state.isFocused ||
        this.props.disabled
      ) {
        return;
      }
      this.addAiAssistantMarker(instance);
      this.startAutocomplete();

      this.setState({ isFocused: true });
      this.props.onEditorFocus && this.props.onEditorFocus(instance);
      if (this.props.size === EditorSize.COMPACT) {
        this.editor.setOption("lineWrapping", true);
      }
      // when clicking into editor, show a list of preset options
      // this should not be triggered in binding mode
      if (this.props.presetOptions && !instance.getValue().includes("{{")) {
        this.presetListHinter?.showHint(instance);
      }
    });
  };

  handleStartCompletion = () => {
    this.setState({ autoCompleteVisible: true });
  };

  handleEndCompletion = () => {
    this.setState({ autoCompleteVisible: false });
  };

  formatCode = async (signal: AbortSignal) => {
    if (!this.props.isNewCodeEditorFeatureFlagEnabled) {
      return;
    }

    if (signal.aborted) {
      return;
    }

    signal.addEventListener("abort", () => {
      this.setState({
        showFormattingStatus: false,
        formattingStatus: undefined,
        formattingError: undefined,
      });
    });

    const scrollInfo = this.editor.getScrollInfo();

    if (
      isSupportedFormatter(this.props.mode) &&
      this.props.languagesToFormat.includes(this.props.mode)
    ) {
      this.setState({
        showFormattingStatus: true,
        formattingStatus: FormattingStatus.STARTED,
        formattingError: undefined,
      });

      const value = this.editor.getValue();

      try {
        const response = await formattingService.formatCode(
          value,
          this.props.mode,
          { syntax: this.props.syntax },
        );

        if (!signal.aborted) {
          if (response.type === "success") {
            if (value !== response.formattedCode) {
              this.editor.setValue(response.formattedCode);
              this.triggerChangeThrottled();
              this.triggerChangeThrottled.flush();

              this.editor.scrollIntoView({
                left: scrollInfo.left,
                top: scrollInfo.top,
                right: scrollInfo.left + scrollInfo.width,
                bottom: scrollInfo.top + scrollInfo.height,
              });
            }

            setTimeout(() => {
              if (this.state.formattingStatus !== FormattingStatus.FAILED) {
                this.setState({
                  showFormattingStatus: false,
                });
              }
            }, FORMATTING_MESSAGE_MIN_DURATION);
          } else {
            this.setState({
              showFormattingStatus: true,
              formattingStatus: FormattingStatus.FAILED,
              formattingError: response.error,
            });

            setTimeout(() => {
              if (this.state.formattingStatus === FormattingStatus.FAILED) {
                this.setState({
                  showFormattingStatus: false,
                });
              }
            }, FORMATTING_ERROR_MESSAGE_DURATION);
          }
        }
      } catch (error) {
        logger.error(
          `Could not format ${this.props.mode} code on blur: ${error}`,
        );
      }
    }
  };

  debouncedFormatCode = debounce(this.formatCode, 1000, {
    leading: true, // trigger formatCode immediately
    trailing: true, // formatCode with subsequent changes
  });

  triggerBlur = () => {
    const value = this.editor.getValue();

    if (this.props.formatCodeOnBlur) {
      this.debouncedFormatCode(this.formatCodeAbortController.signal);
    }

    if (this.props.input.onBlur) {
      // onBlur will trigger with the user typed code without any formatting
      this.props.input.onBlur(value);
    }
  };

  triggerChange = (origin?: string) => {
    const value = this.editor.getValue();

    // CodeMirror's internal undo/redo operations might disagree with the external props,
    // so we force an update here
    if (
      origin === "undo" ||
      origin === "redo" ||
      value !== this.props.input.value
    ) {
      if (this.props.input.onChange) {
        this.props.input.onChange(value);
      }
      this.updateNullMarkers();
      this.updateFunctionScopeErrorMarkers();
      this.updateAppScopeErrorMarkers();
    }
  };

  insertShortcutValue = (value: string) => {
    // Update property value and let the value overwrite editor input
    flushSync(() => {
      this.updateEditorValue(value); // updates the value directly, ignoring the usual focus restrictions
      this.props.input.onChange?.(value);
    });
  };

  triggerChangeThrottled = throttle(this.triggerChange, 5000, {
    leading: false,
  });

  handleEditorBlur = () => {
    flushSync(() => {
      if (
        this.editor.somethingSelected() &&
        !this.props.maintainSelectionOnBlur
      ) {
        // Replaces selection range with just a cursor
        this.editor.setCursor(this.editor.getCursor());
      }
      const hide = this.editor.state.textHover.hide;

      this.handleChangeDebounced.flush();
      this.triggerHinters.cancel();
      this.triggerBlur();
      this.editor.closeHint();
      if (typeof hide === "function") hide();

      const value = this.editor.getValue();

      if (this.props.onEditorBlur) {
        this.props.onEditorBlur(value);
      }

      this.setState({
        isFocused: false,
        canEvaluateShortcutValue: true, // unfocus should reset the flag to show evaluated popup
        shortcutMenuHovered: false,
        delayingForValidationError: this.props.input.value === "", // avoid flickering of editor error state when transition from empty input to selecting an api response
      });

      setTimeout(() => {
        this.setState({
          delayingForValidationError: false,
        });
      }, 2000);

      if (this.props.size === EditorSize.COMPACT) {
        this.editor.setOption("lineWrapping", false);
      }

      this.editor.setOption("matchBrackets", false);
    });
  };

  // Not sure why this debounce was added, but removing it will cause some tests to fail
  // Meanthile, increacing the value might delay setting this.state.isFocused to false and make the evaluated popup show unexpectedly after clicking on add api submenu.
  initiateEditorBlur = debounceWithState(this.handleEditorBlur.bind(this), 200);

  // Hints can be slow to compute on every keypress, this debounce could even be longer
  triggerHinters = debounce((instance: CodeMirror.Editor) => {
    flushSync(() => {
      this.hinters.forEach((hinter) => hinter.showHint(instance));
      const value = instance.getValue();
      // show preset options if not in binding mode or input is empty
      if (this.props.presetOptions && (!value.includes("{{") || value === "")) {
        this.presetListHinter?.showHint(instance);
      }
    });
  }, 250);

  // Those options ensures python code will be space indented
  addPythonIndentationOptions = (options: EditorConfiguration) => {
    if (this.isPythonEditor()) {
      // The following two options controls smart indent, pressing enter after ':' (e.g. functions or if clauses)
      options.indentUnit = 4;
      options.indentWithTabs = false;
      // The following option impacts the behaviour when Tab key is pressed. We are using 4 spaces here.
      (options.extraKeys as KeyMap)["Tab"] = function (cm) {
        cm.replaceSelection(FOUR_SPACES, "end");
      };
    }
  };

  // Add a component within codemirror
  addPythonReindentWithSpacesButton = () => {
    if (this.isPythonEditor()) {
      const divNode = document.createElement("div");
      const buttonNode = document.createElement("button");

      buttonNode.innerHTML = "Tabs detected: reindent with spaces";
      buttonNode.setAttribute("type", "button");
      buttonNode.setAttribute("class", "ant-btn");
      buttonNode.setAttribute("data-test", "reindent-with-spaces");
      divNode.appendChild(buttonNode);

      buttonNode.addEventListener("click", (e) => {
        e.preventDefault();
        this.replaceTabsWithSpaces();
      });

      // hidden has to be set before we add wideget, otherwise the button
      // will show up on editor load time then disappear if no tabs detected
      divNode.style.display = "none";
      this.editor.addWidget({ line: 1, ch: 1 }, divNode, true);

      // The position will be modified by addWidget, the following styles are overriding
      divNode.style.top = "5px";
      divNode.style.left = "auto";
      divNode.style.right = "20px";
      divNode.style.zIndex = "2";
      divNode.style.opacity = "0.7";

      this.editor.state.divNode = divNode;
    }
  };

  handleChange = (
    instance: CodeMirror.Editor,
    change: CodeMirror.EditorChange,
  ) => {
    flushSync(() => {
      // Trigger change callback at least every 5 seconds to avoid
      // the situation where content is lost due to e.g. loss of power.
      this.triggerChangeThrottled();

      if (this.isPythonEditor()) {
        if (this.isTabIndented() && instance.state.divNode) {
          // show replace button
          instance.state.divNode.style.display = "block";
        } else {
          // hide replace button
          instance.state.divNode.style.display = "none";
        }
      }

      const cursor = instance.getCursor();
      const currentLine = instance.getLine(cursor.line);

      if (
        this.state.isFocused &&
        !NO_HINTS_ORIGINS.includes(change.origin ?? "") &&
        ((currentLine &&
          currentLine.slice(0, cursor.ch).match(COMPLETE_REGEX)) ||
          (currentLine === "" && this.props.presetOptions)) // show preset options if input is empty
      ) {
        this.triggerHinters(instance);
      } else {
        this.triggerHinters.cancel();
      }
    });
  };

  isTabIndented(): boolean {
    for (const line of this.editor.getValue().split("\n")) {
      if (line.match(LEADING_TABS_REGEX) !== null) {
        return true;
      }
    }
    return false;
  }

  replaceTabsWithSpaces = () => {
    const codeWithTabs = this.editor.getValue().split("\n");
    const linesWithSpaces: string[] = [];
    codeWithTabs.forEach((line) => {
      linesWithSpaces.push(
        line.replace(LEADING_TABS_REGEX, (match) =>
          FOUR_SPACES.repeat(match.length),
        ),
      );
    });
    if (this.props.input.onChange) {
      this.props.input.onChange(linesWithSpaces.join("\n"));
    }
  };

  updateCodePreviewContext = async () => {
    if (!this.props.codeMirrorState) {
      return;
    }
    const apiId = this.props.dataTreePath ? undefined : this.props.params.apiId;
    const actionId = this.props.dataTreePath
      ? undefined
      : this.props.params.actionId;
    const state = this.props.store.getState();
    const userAccessibleTree = getUserAccessibleDataTree(
      state,
      apiId,
      actionId,
    ) as DataTree;
    const apiName = selectV2ApiById(state, apiId)?.name;
    const apiDsl = selectCachedControlFlowById(state, apiId);
    const widgets = getWidgets(state);

    let pluginId = "sql" as "python" | "javascript" | "sql";
    if (this.isPythonEditor()) {
      pluginId = "python";
    } else if (this.isJavascriptEditor()) {
      pluginId = "javascript";
    }

    const stepName = actionId ?? "unnamedStep";
    const { prefix, files, suffix, variableNames } = getContext(
      userAccessibleTree,
      pluginId,
      apiDsl,
      apiName,
      widgets,
      this.props.aiContextMode ?? AiContextMode.OnlySuperblocksKeys,
    );
    this.props.codeMirrorState.updateContext({
      prefix: prefix,
      suffix: suffix,
      previousSteps: files,
      stepName,
      variableNames,
    });
  };

  handleEntityAutocompleteKeybinding = (instance: CodeMirror.Editor) => {
    if (!this.props.autocompleteConfiguration.bindingsDisabled) {
      const cursor = instance.getCursor();
      instance.replaceRange("{{}}", cursor, undefined, ORIGIN_ENTITY);
      instance.setCursor({
        ...cursor,
        ch: cursor.ch + 2,
      });
      setTimeout(() => {
        this.entityHinter?.showHint(instance);
      }, 200);
    }

    this.entityHinter?.showHint(instance);
  };

  makePlaceholderWidget = () => {
    // make a line that acts as the placeholder
    // if ai assistant is available, it includes a link-like button that opens the assistant
    const placeholderLine = document.createElement("div");
    placeholderLine.className = "CodeMirror-activeline-placeholder";
    // add text to the placeholder
    const placeholderText = document.createElement("span");
    placeholderText.innerText = this.props.openAiAssistant
      ? PLACEHOLDER_TEXT
      : `${getEntityACShortcutString()} for variables`;
    placeholderLine.appendChild(placeholderText);

    // add a button to open the assistant
    if (this.props.openAiAssistant) {
      const button = document.createElement("button");
      button.className = "CodeMirror-activeline-placeholder-button";
      button.innerText = "Ask AI";
      button.setAttribute("cm-ignore-events", "true");
      button.onclick = () => this.props.openAiAssistant?.("placeholder");
      placeholderLine.appendChild(button);
    }
    return placeholderLine;
  };

  handleNewLinePlaceholder = (
    instance: CodeMirror.Editor,
    selection: CodeMirror.EditorSelectionChange,
  ) => {
    if (!instance.getValue() && this.props.placeholder) {
      return;
    }
    const ranges = selection.ranges;
    let activeLine: any = undefined;

    for (const range of ranges) {
      // @ts-expect-error: the codemirror editor typedef is not accurate
      const line = instance.getLineHandleVisualStart(range.head.line);
      if (line.text === "") {
        activeLine = line;
        break;
      }
    }
    instance.operation(() => {
      if (instance.state.placeholderLine?.widgets?.[0]) {
        instance.removeLineWidget(instance.state.placeholderLine.widgets[0]);
      }
      if (activeLine) {
        instance.addLineWidget(activeLine, this.makePlaceholderWidget(), {
          handleMouseEvents: true,
        });
        instance.state.placeholderLine = activeLine;
      }
    });
  };

  handleChangeDebounced = debounce(
    (_instance: CodeMirror.Editor, changeObj: CodeMirror.EditorChange) => {
      flushSync(() => {
        if (changeObj.origin === "complete") {
          AnalyticsUtil.logEvent("AUTO_COMPLETE_SELECT", {
            searchString: changeObj.text[0],
          });
        }
        this.updateCodePreviewContext();
        this.triggerChangeThrottled.cancel();
        this.triggerChange(changeObj.origin);
        this.handleRefreshThrottled();
      });
    },
    50,
  );

  handleRefreshThrottled = throttle(
    () => {
      if (!this.placeholder) {
        return;
      }

      if (!this.editor.getValue()) {
        const desiredHeight =
          this.placeholder.offsetHeight + (this.linesElementPadding ?? 0);

        if (
          this.placeholder.offsetHeight &&
          this.editor.state.size !== desiredHeight
        ) {
          this.editor.setSize(null, desiredHeight);
          this.editor.state.size = desiredHeight;
        }
      } else if (this.editor.state.size !== "auto") {
        this.editor.setSize(null, "auto");
        this.editor.state.size = "auto";
      }
    },
    500,
    { leading: false },
  );

  handleShortcutHover = (entityName: string) => {
    const entity = getEntity(
      { scope: this.props.currentScope, name: entityName },
      this.props.dynamicData,
    );
    if (handleShortcutHoverLeaveTimer !== undefined) {
      clearTimeout(handleShortcutHoverLeaveTimer);
    }
    if (
      entity &&
      "ENTITY_TYPE" in entity &&
      entity.ENTITY_TYPE === ENTITY_TYPE.ACTION
    ) {
      this.setState({
        shortcutHoverValue: entity.response,
        shortcutMenuHovered: true,
      });
      return;
    }

    this.setState({
      canEvaluateShortcutValue: false,
      shortcutHoverValue: {},
      shortcutMenuHovered: true,
    });
  };

  handleShortcutHoverLeave = () => {
    // to avoid the flickering when moving from "Add new API" to submenu
    handleShortcutHoverLeaveTimer = setTimeout(
      () =>
        this.setState({
          canEvaluateShortcutValue: true,
          shortcutMenuHovered: false,
        }),
      200,
    );
  };

  handleCanEvaluateShortcutValue = (canEval: boolean) => {
    this.setState({
      canEvaluateShortcutValue: canEval,
    });
  };

  updateNullMarkers = debounce(async () => {
    // Ignore API and Action IDs if we're in the PropertyPane (and hence we have data tree path)
    // NOTE: pay attention to the memoization function at the bottom of this file as it only compares the router match info
    // if params apiId or actionId are changed
    const apiId = this.props.dataTreePath ? undefined : this.props.params.apiId;
    const actionId = this.props.dataTreePath
      ? undefined
      : this.props.params.actionId;
    const state = this.props.store.getState();
    const userAccessibleTree = getUserAccessibleDataTree(
      state,
      apiId,
      actionId,
    );

    const pluginId = this.isPythonEditor()
      ? LanguagePluginID.Python
      : LanguagePluginID.JavaScript;

    const pairs = await extractBindingsFromValueWithRanges(
      this.editor.getValue(),
      Object.keys(userAccessibleTree),
      pluginId,
      userAccessibleTree,
    );

    for (const marker of this.nullMarkers) marker.clear();

    this.nullMarkers = [];

    for (const pair of pairs) {
      if (pair.value === null) {
        this.nullMarkers.push(
          nullMarker(this.editor, pair.range, pair.binding),
        );
      }
    }
  }, 250);

  updateFunctionScopeErrorMarkers = debounce(async () => {
    const { autocompleteConfiguration, dynamicData, mode } = this.props;

    if (
      autocompleteConfiguration.functions !== AutocompleteFunctionsType.BACKEND
    ) {
      return;
    }

    const value = this.editor.getValue();

    const globalFunctionNames = Object.keys(GLOBAL_FUNCTIONS).map((name) =>
      name.substring(0, name.length - 2),
    );

    const entityNames = new Set(getAllEntityNames(this.props.store.getState()));

    let pairs: EvaluationPair[] = [];
    if (mode === EditorModes.PYTHON) {
      pairs = await extractPythonEvaluationPairs(
        value,
        entityNames,
        dynamicData,
      );
    } else {
      pairs = await extractJsEvaluationPairs(
        value,
        entityNames,
        dynamicData[this.props.currentScope],
      );
    }

    this.functionScopeErrorMarkers.forEach((m) => m.clear());
    this.functionScopeErrorMarkers = [];

    for (const pair of pairs) {
      // Something like, "myModal.show", because we need to grab what the user actually wrote
      // in the editor, not just the current binding value
      const bindingAndIdentifier = this.editor.getRange(
        this.editor.posFromIndex(pair.range.start),
        this.editor.posFromIndex(pair.range.end),
      );

      const [, identifier] = bindingAndIdentifier.split(".") as [
        string,
        string | undefined,
      ];

      // Its possible we have a binding to a widget, or that we are calling a global client function.
      // Need to check both cases.
      if (
        typeof pair.value === "object" &&
        pair.value != null &&
        pair.value.ENTITY_TYPE != null &&
        identifier
      ) {
        const typeKey = (pair.value.type ||
          pair.value.ENTITY_TYPE) as keyof typeof entityDefinitions;
        const def = entityDefinitions[typeKey];

        if (!def) {
          continue;
        }

        const backendEntityDefinition =
          typeof def === "function"
            ? def(pair.value, AutocompleteFunctionsType.BACKEND)
            : def;
        const frontendEntityDefinition =
          typeof def === "function"
            ? def(pair.value, AutocompleteFunctionsType.FRONTEND)
            : def;

        // we dont really have enough information if this is a function call or property
        // without parsing out an AST, but we can assume for now we are just checking for functions
        const identifierAsFunction = `${identifier}()`;

        const isValidFrontendIdentifier =
          identifierAsFunction in frontendEntityDefinition;

        const isValidBackendIdentifier =
          identifierAsFunction in backendEntityDefinition;

        if (isValidFrontendIdentifier && !isValidBackendIdentifier) {
          this.addErrorMarker(pair);
        }
      } else if (globalFunctionNames.includes(bindingAndIdentifier)) {
        this.addErrorMarker(pair);
      }
    }
  }, 250);

  updateAppScopeErrorMarkers = debounce(async () => {
    this.appScopeErrorMarkers.forEach((m) => m.clear());
    this.appScopeErrorMarkers = [];

    const value = this.editor.getValue();
    if (this.props.currentScope === ApplicationScope.APP) {
      // check if a string contains App. and if it does, add an error
      const lines = value.split("\n");
      lines.forEach((line, i) => {
        const regex = /\bApp\.(\w+)\b/g;
        const matches = line.matchAll(regex);
        for (const match of matches) {
          if (match[0] == null || match.index == null || !match[1]) continue;

          this.appScopeErrorMarkers.push(
            appScopeErrorMarker(
              this.editor,
              {
                line: i,
                start: match.index,
                end: match.index + match[0].length,
              },
              match[0],
              match[1],
            ),
          );
        }
      });
    }
  }, 250);

  addErrorMarker = (pair: EvaluationPair) => {
    const startPos = this.editor.posFromIndex(pair.range.start);
    const endPos = this.editor.posFromIndex(pair.range.end);

    const entireLine = this.editor.getLine(startPos.line);

    // we only want to highlight the error on the specific function, not the entire line,
    // so we need to calculate an offset on where to start
    const expression = this.editor.getRange(startPos, endPos);
    let method = expression;
    if (expression.includes(".")) {
      const parts = expression.split(".");
      method = parts[parts.length - 1];

      const startOffset = expression.indexOf(method);
      pair.range.start = pair.range.start + startOffset;
    }

    // and we need a similar offset on where to end for function calls
    // i.e. showAlert("test") needs to highlight to end of line
    let endOffset = 0;
    const remainingChars = entireLine.substring(endPos.ch, entireLine.length);
    if (remainingChars.startsWith("(")) {
      for (const char of remainingChars.split("")) {
        endOffset++;
        if (char === ")") {
          break;
        }
      }
      pair.range.end = pair.range.end + endOffset;
    }

    const marker = functionScopeErrorMarker(
      this.editor,
      pair.range,
      `${expression}()`,
    );
    this.functionScopeErrorMarkers.push(marker);
  };

  updateEditorValue = (value: string) => {
    const editorValue = this.editor.getValue();

    // Safe update of value of the editor when value updated outside the editor
    if (!isNil(value) && value !== editorValue) {
      // Ignore any ongoing formatting process in favor of the new value
      this.cancelFormattingProcess();

      const scrollInfo = this.editor.getScrollInfo();
      const wasEditorClean = this.editor.getDoc().isClean();
      this.editor.setValue(value);
      // There is extra undo history added when the step first loads but doesn't yet have content
      // so an undo will clear the content by "going back" to the state of having no content.

      // But we must keep the history if the editor's value is changed programmatically.
      // e.g: users should be able to UNDO the formatting done automatically after a manual run.
      // See `markClean()` on `componentDidMount`

      if (wasEditorClean) {
        this.editor.getDoc().clearHistory();
      }
      this.updateNullMarkers();
      this.updateFunctionScopeErrorMarkers();
      this.updateAppScopeErrorMarkers();

      if (this.props.autoScroll) {
        this.editor.scrollIntoView({
          left: scrollInfo.left,
          top: scrollInfo.top,
          right: scrollInfo.left + scrollInfo.width,
          bottom: scrollInfo.top + scrollInfo.height,
        });
      }
    }
  };

  render() {
    const {
      className,
      borderLess,
      dataTreePath,
      disabled,
      isIconSelectorOpen,
      dynamicData,
      expected,
      monospace,
      height,
      input,
      leftIcon,
      leftImage,
      link,
      minHeight,
      maxHeight,
      fontSize,
      rightIcon,
      size,
      theme,
      exampleData,
      docLink,
      apiErrors,
      expandIntoTabBtn,
      evaluatedValuePopupProps,
      showJsExprModeIndicator,
    } = this.props;

    let { validationError } = this.props;

    const hasApiError = Boolean(
      apiErrors &&
        apiErrors?.filter((api) => api.type === ApiErrorType.EXECUTION_ERROR)
          .length > 0,
    );
    const hasAPIWarning = Boolean(
      apiErrors &&
        apiErrors?.filter(
          (api) =>
            api.type === ApiErrorType.NOT_EXECUTED ||
            api.type === ApiErrorType.STALE,
        ).length > 0,
    );

    if (this.props.showValidationErrorEvenWhenEmpty === false) {
      if (
        (this.state.isFocused && this.props.input.value === "") ||
        this.state.delayingForValidationError
      ) {
        // if user start editing the data field while it is empty, do not who errors until they unfocus
        validationError = "";
      }
    }

    let hasError = Boolean(validationError || hasApiError);
    if (!hasApiError && hasAPIWarning) {
      //validation error might only caused by empty API result
      hasError = false;
    }

    // undefined indicates users input nothing, we should show empty string
    const showAPIResponseValue =
      this.state.shortcutMenuHovered &&
      this.state.shortcutHoverValue !== undefined &&
      this.state.canEvaluateShortcutValue;

    const isPropertyPanelEditor = this.isPropertyPaneEditor();
    const showValueInEvaluatedPopup =
      (isPropertyPanelEditor || this.props.evaluatedValue !== undefined) &&
      (this.editor?.getValue().length !== 0 || showAPIResponseValue);

    const showEvaluatedValue =
      (isPropertyPanelEditor || this.props.evaluatedValue !== undefined) &&
      this.state.canEvaluateShortcutValue &&
      this.state.isFocused &&
      !this.props.disableEvaluatedValuePopover &&
      (!isEmpty(this.props.expected) || showValueInEvaluatedPopup);

    const showRequiredFormat =
      this.state.canEvaluateShortcutValue &&
      this.state.isFocused &&
      !isEmpty(this.props.expected);

    const showShortcutMenu =
      Boolean(this.props.showShortcutMenu) &&
      (this.state.isFocused || this.state.shortcutMenuHovered) &&
      this.state.isAllSelected &&
      this.props.mode !== EditorModes.JAVASCRIPT; // We do not need to suggest api.run() or slideout.open() in js mode

    // to disable hovering on code editor and showing shortcut menu at the same time
    if (this.editor) {
      if (showShortcutMenu || this.state.autoCompleteVisible) {
        this.editor.state.textHover.visible = false;
        const hide = this.editor.state.textHover.hide;

        if (typeof hide === "function") hide();
      } else {
        this.editor.state.textHover.visible = true;
      }
    }
    // the first focus into data field will select all, but that does not indicating user is editing
    // we expand the example only when:
    // there is error or user is editing, or data field is empty

    // If theres an error
    // If there is no possibility of a evaluatedValue
    // If there is not value && it's focused
    const shouldOpenExample =
      hasError ||
      (!isPropertyPanelEditor && this.props.evaluatedValue === undefined) ||
      (this.state.isFocused && this.editor?.getValue().length === 0);

    let validationStatus = VALIDATION_STATUS.VALID;

    if (hasAPIWarning) validationStatus = VALIDATION_STATUS.WARNING;
    if (hasApiError || validationError)
      validationStatus = VALIDATION_STATUS.ERROR;
    if (hasAPIWarning && !hasApiError)
      validationStatus = VALIDATION_STATUS.WARNING; //if error is caused by empty API, show warning

    if (this.state.shortcutMenuHovered) {
      validationStatus = VALIDATION_STATUS.NONE;
    }

    const evaluatedValue =
      showShortcutMenu && this.state.shortcutMenuHovered
        ? this.state.shortcutHoverValue
        : this.props.evaluatedValue !== undefined
          ? this.props.evaluatedValue
          : dataTreePath
            ? get(dynamicData, dataTreePath)
            : undefined;

    return (
      <DynamicAutocompleteInputWrapper
        data-test="dynamic-autocomplete-input"
        isError={hasError}
        isActive={(this.state.isFocused && !hasError) || this.state.isOpened}
        isNotHover={this.state.isFocused || this.state.isOpened}
      >
        {expandIntoTabBtn}
        <EvaluatedValuePopup
          currentScope={this.props.currentScope}
          dataTreePath={dataTreePath}
          theme={theme || EditorTheme.LIGHT}
          isOpen={
            (showEvaluatedValue || showRequiredFormat) && !this.props.aiMenuOpen
          }
          evaluatedValue={evaluatedValue}
          expected={expected}
          hasError={hasError}
          validationError={validationError}
          apiErrors={this.props.apiErrors}
          shouldOpenExample={shouldOpenExample}
          exampleData={exampleData}
          docLink={docLink}
          validationStatus={validationStatus}
          showValue={showValueInEvaluatedPopup}
          showValueOnly={showAPIResponseValue}
          openDelay={300}
          {...evaluatedValuePopupProps}
        >
          <ShortcutMenu
            isFocused={showShortcutMenu && !this.props.aiMenuOpen}
            insertText={this.insertShortcutValue}
            onOptionHover={this.handleShortcutHover}
            onOptionHoverLeave={this.handleShortcutHoverLeave}
            currentScope={this.props.currentScope}
            setCanEvaluate={this.handleCanEvaluateShortcutValue}
            additionalDynamicData={this.props.combinedAdditionalDynamicData}
            handleParentEditorBlur={this.initiateEditorBlur}
            openAiAssistant={this.props.openAiAssistant}
          >
            <EditorWrapper
              editorTheme={theme}
              hasError={hasError}
              size={size}
              isFocused={this.state.isFocused}
              customDisabled={(disabled || isIconSelectorOpen) ?? false}
              className={`EditorWrapper ${className}`}
              height={height}
              borderLess={borderLess}
              monospace={monospace}
              minHeight={minHeight}
              maxHeight={maxHeight}
              fontSize={fontSize}
              data-test={this.props["data-test"]}
              visibleTabs={this.isPythonEditor()}
              isNewCodeEditorFeatureFlagEnabled={
                this.props.isNewCodeEditorFeatureFlagEnabled
              }
              showLineNumbers={this.props.showLineNumbers}
            >
              <Transition
                in={this.state.showFormattingStatus}
                timeout={FORMATTING_TRANSITION_MESSAGE_DURATION}
              >
                {(state) => (
                  <div
                    className={`${FormattingStatusContainer} ${
                      state === "exited" ? TransitionExitedClassName : ""
                    }`}
                  >
                    <FormattingStatusIndicator
                      status={this.state.formattingStatus}
                      errorType={this.state.formattingError}
                    />
                  </div>
                )}
              </Transition>

              {leftIcon && <IconContainer>{leftIcon}</IconContainer>}

              {leftImage && (
                <img src={leftImage} alt="img" className="leftImageStyles" />
              )}

              {showJsExprModeIndicator && <EditorJsExprModeIndicator />}

              <textarea
                ref={this.textArea}
                {...omit(input, "onChange", "value")}
                defaultValue={disabled ? "" : input.value}
              />
              {link && (
                <a
                  href={link}
                  target="_blank"
                  className="linkStyles"
                  rel="noopener noreferrer"
                >
                  API documentation
                </a>
              )}
              {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
            </EditorWrapper>
          </ShortcutMenu>
        </EvaluatedValuePopup>
      </DynamicAutocompleteInputWrapper>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: Props): ReduxStateProps {
  // Ignore API and Action IDs if we're in the PropertyPane (and hence we have data tree path)
  // NOTE: pay attention to the memoization function at the bottom of this file as it only compares the router match info
  // if params apiId or actionId are changed
  const apiId = ownProps.dataTreePath ? undefined : ownProps.params.apiId;
  const actionId = ownProps.dataTreePath ? undefined : ownProps.params.actionId;
  const currentScope = ownProps.dataTreeScope ?? ApplicationScope.PAGE;

  const controlFlowEnabled = selectControlFlowEnabledDynamic(state);
  const isSecretsEnabled = selectFlagById(
    state as any,
    Flag.ENABLE_SECRETS_MANAGEMENT,
  );

  // Enables code formatting, VIM bindings an new code theme
  const isNewCodeEditorFeatureFlagEnabled = selectFlagById(
    state as any,
    Flag.CODE_FORMATTING,
  ) as boolean;

  const { keyBindings, languagesToFormat } =
    getSharedDeveloperPreferences(state);

  let datasourceId = "";
  if (controlFlowEnabled) {
    const block = selectV2BlockById(state, apiId, actionId);
    if (block?.config && "datasourceId" in block.config) {
      datasourceId = block?.config?.datasourceId ?? "";
    }
  } else {
    datasourceId = selectActionById(state, apiId, actionId)?.datasourceId ?? "";
  }
  const isFrontend =
    ownProps.isInPropertyPanel ||
    Boolean(
      ownProps.autocompleteConfiguration.functions ===
        AutocompleteFunctionsType.FRONTEND,
    );

  // Secrets only show if a secret store is configured
  const currentSecretMetadata = selectSecretStoreMetadata(state);
  const secretStoreMetadata =
    isFrontend ||
    !isSecretsEnabled ||
    Object.keys(currentSecretMetadata.sb_secrets).length === 0
      ? {}
      : selectSecretStoreMetadata(state);

  // Env should always be available in the autocomplete in orgs configured for on-prem
  const isOnPremise = selectOnlyOrganizationIsOnPremise(state);
  const envDynamicData: Record<string, unknown> = isOnPremise
    ? { Env: {} }
    : {};
  const dd = getDataTreeForAutocomplete(state);

  const isProgrammaticTableEnabled = selectFlagById(
    state,
    Flag.ENABLE_PROGRAMMATIC_TABLE,
  ) as boolean;

  return {
    dynamicData: dd,
    apiScope: selectUserAccessibleScopeForApiUnion(
      state,
      apiId,
      actionId,
      ownProps.additionalScopeProps,
    ),
    pageId: getCurrentPageId(state),
    appId: getCurrentApplicationId(state),
    currentScope,
    datasourceMetadata: selectDatasourceMetaById(state, datasourceId),
    keyBindings,
    languagesToFormat,
    combinedAdditionalDynamicData: {
      ...ownProps.additionalDynamicData,
      ...secretStoreMetadata,
      ...envDynamicData,
    },
    isNewCodeEditorFeatureFlagEnabled,
    isProgrammaticTableEnabled,
  };
}

const ConnectedEditor = connect(mapStateToProps)(CodeEditor);

const MemoizedEditor = React.memo(
  ConnectedEditor as React.FC<Props>,
  (prev, next: Props) => {
    // Compare the router match info and only track apiId or matchId
    const prevApiId = prev.params.apiId;
    const prevActionId = prev.params.actionId;
    const nextApiId = next.params.apiId;
    const nextActionId = next.params.actionId;

    const matchChanged =
      prevApiId !== nextApiId || prevActionId !== nextActionId;
    return (
      shallowEqual(prev, next, (_a, _b, key) =>
        key === "match" ? true : undefined,
      ) && !matchChanged
    );
  },
);

function EditorWithParams(props: EditorProps) {
  const params = useParams() as Props["params"];
  const store = useStore();
  const Component = MemoizedEditor as any;
  return <Component {...props} params={params} store={store} />;
}

export default EditorWithParams;
