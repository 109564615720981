import { ApplicationScope } from "@superblocksteam/shared";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useEntityUsageInfo } from "analysis/useEntityUsageInfo";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning.svg";
import Popper from "components/ui/Popper";
import { usePointerDownOutside } from "hooks/ui";
import { useDeleteEntity } from "legacy/pages/Editor/Explorer/Entity/useDeleteEntity";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import { isValidEntity } from "legacy/workers/evaluationUtils";
import { useAppSelector } from "store/helpers";
import { RunAnalysisAgain } from "./RunAnalysisAgain";
import { setReferenceAnalysisModalOpen } from "./actions";
import { ButtonRow } from "./commonStyles";

const BadgeContainer = styled.div`
  line-height: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 14px;
  }
`;

const OverlayPopoverContents = styled.div`
  box-shadow:
    0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.GREY_500};
  gap: 12px;
  max-width: 300px;
`;

const InfoRow = styled.div`
  word-break: break-word;
  pre {
    color: ${({ theme }) => theme.colors.GREY_700};
  }
`;

const EntityNameSpan = styled.span`
  color: ${({ theme }) => theme.colors.GREY_700};
  word-break: break-word;
`;

const EntityAnalysisOverlayContents = (props: {
  closeOverlay: () => void;
  popperRef: React.RefObject<HTMLDivElement>;
  badgeRef: React.RefObject<HTMLDivElement>;
  entityId: string;
  entityName: string;
  entityKind?: ItemKinds;
  isStale?: boolean;
  scope: ApplicationScope;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dataTree = useAppSelector(getDataTree);
  const entity = dataTree[props.scope]?.[props.entityName];

  usePointerDownOutside({
    wrapperRefs: [wrapperRef, props.popperRef, props.badgeRef],
    onClickOutside: () => {
      props.closeOverlay();
    },
  });

  const dispatch = useDispatch();
  const handleViewAllClicked = useCallback(() => {
    if (isValidEntity(entity)) {
      dispatch(setReferenceAnalysisModalOpen(entity.ENTITY_TYPE));
    }
  }, [dispatch, entity]);

  const handleDeleteEntity = useDeleteEntity({
    entityKind: props.entityKind,
    entityId: props.entityId,
    scope: props.scope,
  });

  return (
    <OverlayPopoverContents
      ref={wrapperRef}
      onClick={(ev) => {
        ev.stopPropagation(); // prevent clicks from bubbling up to the entity
      }}
    >
      <RunAnalysisAgain />
      <InfoRow>
        <EntityNameSpan>{props.entityName}</EntityNameSpan> is not in use
      </InfoRow>

      <ButtonRow>
        {(props.entityKind === ItemKinds.WIDGET ||
          props.entityKind === ItemKinds.API_V2) && (
          <button onClick={handleViewAllClicked}>View all</button>
        )}
        <button onClick={handleDeleteEntity}>Delete</button>
      </ButtonRow>
    </OverlayPopoverContents>
  );
};

const UnusedEntityBadge = (props: {
  name: string;
  entityId: string;
  scope?: ApplicationScope;
  entityKind?: ItemKinds;
  isStale?: boolean;
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const badgeRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  if (!props.scope) return null;

  return (
    <BadgeContainer
      style={{
        opacity: props.isStale ? 0.4 : 1,
      }}
      ref={badgeRef}
    >
      <WarningIcon
        onClick={(ev) => {
          setMenuIsOpen((prev) => !prev);
          ev.stopPropagation();
        }}
      />
      {menuIsOpen && (
        <Popper
          placement="top"
          isOpen={menuIsOpen}
          zIndex={15}
          targetNode={badgeRef.current || undefined}
          ref={popperRef}
        >
          <EntityAnalysisOverlayContents
            closeOverlay={() => setMenuIsOpen(false)}
            popperRef={popperRef}
            badgeRef={badgeRef}
            entityId={props.entityId}
            entityName={props.name}
            entityKind={props.entityKind}
            isStale={props.isStale}
            scope={props.scope}
          />
        </Popper>
      )}
    </BadgeContainer>
  );
};

const EntityAnalysisRenderer = (props: {
  name: string;
  scope?: ApplicationScope;
  entityKind?: ItemKinds;
  entityId: string;
}) => {
  const { canPrune, analysisStatus } = useEntityUsageInfo({
    entityName: props.name,
    entityScope: props.scope,
  });
  if (!analysisStatus.hasRun || !canPrune) return null;

  return (
    <UnusedEntityBadge
      name={props.name}
      scope={props.scope}
      entityKind={props.entityKind}
      entityId={props.entityId}
      isStale={analysisStatus.isStale}
    />
  );
};

export default EntityAnalysisRenderer;
