import { ApplicationScope } from "@superblocksteam/shared";
import CodeMirror, { Editor, Hint, Hints } from "codemirror";
import {
  AutocompleteConfiguration,
  Hinter,
  HinterUpdateRequest,
} from "components/app/CodeEditor/EditorConfig";
import { adjustHintPosition } from "components/app/CodeEditor/hintUtils";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ApiScope } from "utils/dataTree/scope";
import {
  sortAndFilterExpandedCompletions,
  renderAutocompleteRow,
  compileKeyPathStr,
} from "./ExpandedAutocomplete";
import { HINTER_OPTIONS } from "./constants";
import { customTreeTypeDefCreator } from "./customTreeTypeDefCreator";
import {
  dataTreeTypeDefCreator,
  DataTreeDef,
  Origin,
  appScopeDataTreeTypeDefCreator,
} from "./dataTreeTypeDefCreator";
import { functionInfo } from "./function-info";
import { ExpandedAutocompleteDefinition } from "./types";
import { renderHint, typeToIcon } from "./util";

interface Completion {
  text: string;
  def?: DataTreeDef;
  origin?: string | undefined;
  type?: string;
  className?: string;
  forceTop?: boolean;
}

class EntityCompletion {
  defs: DataTreeDef[];
  rendered: { tooltip: HTMLElement; remove: () => void } | undefined =
    undefined;
  hinters: Hinter[] = [];
  openAiAssistant: (() => void) | undefined;

  constructor(
    dataTree: DataTree,
    additionalData: Record<string, unknown> | undefined,
    apiScope: ApiScope | undefined,
    appScope: ApplicationScope,
    configuration: AutocompleteConfiguration,
    openAiAssistant?: () => void,
  ) {
    // TODO: move to worker??
    const dataTreeDef = dataTreeTypeDefCreator({
      dataTree,
      apiScope,
      appScope,
      configuration,
    });
    this.defs = [dataTreeDef];
    this.openAiAssistant = openAiAssistant;

    if (appScope === ApplicationScope.PAGE) {
      const appDataTreeDef = appScopeDataTreeTypeDefCreator({
        dataTree,
        apiScope,
        appScope: ApplicationScope.APP,
        keepOriginalDef: false,
      });
      this.defs.push(appDataTreeDef);
    }

    if (additionalData) {
      const customTreeDef = customTreeTypeDefCreator(additionalData);
      this.defs.push(customTreeDef);
    }
  }

  unregister() {
    this.remove();
    this.defs = [];
    this.hinters = [];
  }

  static getExpanded(
    def: DataTreeDef | undefined,
  ): undefined | ExpandedAutocompleteDefinition {
    return def?.["!doc"]?.expanded;
  }

  static renderExpandedRow(
    el: HTMLElement,
    pos: Hints,
    cur: Completion & Hint,
  ) {
    const expanded = EntityCompletion.getExpanded(cur.def);
    if (expanded) {
      renderAutocompleteRow(expanded, cur.displayText ?? cur.text, el);
    }
  }

  update(updateRequest: HinterUpdateRequest) {
    const dataTreeDef = dataTreeTypeDefCreator({
      dataTree: updateRequest.data,
      apiScope: updateRequest.apiScope,
      appScope: updateRequest.appScope,
      configuration: updateRequest.configuration,
    });

    this.defs = [dataTreeDef];

    if (updateRequest.appScope === ApplicationScope.PAGE) {
      const appDataTreeDef = appScopeDataTreeTypeDefCreator({
        dataTree: updateRequest.data,
        apiScope: updateRequest.apiScope,
        appScope: ApplicationScope.APP,
        configuration: updateRequest.configuration,
      });
      this.defs.push(appDataTreeDef);
    }

    if (updateRequest.additionalData) {
      const customDataTreeDef = customTreeTypeDefCreator(
        updateRequest.additionalData,
      );
      this.defs.push(customDataTreeDef);
    }
  }

  complete(cm: CodeMirror.Editor) {
    cm.showHint({
      hint: adjustHintPosition(this.getHint.bind(this)),
      ...HINTER_OPTIONS,
    });
  }

  async getHint(cm: CodeMirror.Editor): Promise<CodeMirror.Hints> {
    const completion = await this.getHints(cm);
    CodeMirror.on(completion, "close", () => this.remove());
    CodeMirror.on(completion, "update", () => this.remove());
    CodeMirror.on(completion, "select", (cur: any, node: any) => {
      this.remove();
      this.rendered = renderHint(cm, {
        node,
        "!doc": cur.def?.["!doc"],
        "!type": cur.def?.["!type"],
      });
    });

    return {
      ...completion,
      list: this.sortCompletions(completion.list),
    };
  }

  sortCompletions(completions: Completion[]) {
    const expandedCompletions = new Array<Completion>();
    const stepCompletions = new Array<Completion>();
    const nonStepCompletions = new Array<Completion>();
    const forcedTopCompletions = new Array<Completion>();
    for (const c of completions) {
      // stepCompletions will be obsolete once we remove v1 APIs
      if (c.forceTop) {
        forcedTopCompletions.push(c);
      } else if (EntityCompletion.getExpanded(c.def)) {
        expandedCompletions.push(c);
      } else if (c.origin === "API" || c.origin === Origin.ENTITY_AC) {
        stepCompletions.push(c);
      } else if (c.origin !== "API" && c.origin !== Origin.ENTITY_AC) {
        nonStepCompletions.push(c);
      }
    }

    const processedExpandedCompletions = sortAndFilterExpandedCompletions(
      expandedCompletions,
      (a) => EntityCompletion.getExpanded(a.def),
    );

    return forcedTopCompletions.concat(
      processedExpandedCompletions,
      stepCompletions,
      nonStepCompletions,
    );
  }

  makeCompletion(key: string, dt: DataTreeDef) {
    const expanded = EntityCompletion.getExpanded(dt);
    const render = expanded ? EntityCompletion.renderExpandedRow : undefined;
    const decrementCursor =
      functionInfo(dt).hasParams && key.endsWith(")") ? 1 : 0;
    return {
      text: key,
      displayText: key,
      hint: (cm: Editor, data: Hints, cur: Hint) => {
        const currentCursor = cm.getCursor();
        cm.getDoc().replaceRange(cur.text, currentCursor);
        if (decrementCursor != null) {
          const { ch, line } = cm.getCursor();
          cm.setCursor({
            ch: ch - decrementCursor,
            line,
          });
        }
      },
      render,
      def: dt,
      origin: dt["!doc"]?.entityType ?? Origin.ENTITY_AC,
      className: render
        ? undefined
        : typeToIcon(dt["!type"] as string, dt["!doc"]?.entityType),
    };
  }

  async getHints(cm: CodeMirror.Editor): Promise<{
    from: CodeMirror.Position;
    to: CodeMirror.Position;
    list: Completion[];
  }> {
    const completions = this.defs.flatMap((def) => {
      if (!def) {
        return [];
      }
      const keys = Object.keys(def).filter((key) => key.indexOf("!") !== 0);
      return keys.flatMap((key) => {
        const results: Array<Completion & Hint> = [];
        const dt = def[key] as DataTreeDef;
        const expanded = EntityCompletion.getExpanded(dt);
        if (expanded?.expandedShortcuts?.length) {
          expanded.expandedShortcuts.forEach((shortcut) => {
            const { keyPath, fnSignature, ...shortcutDef } = shortcut;
            const text = compileKeyPathStr(keyPath, fnSignature);
            results.push(this.makeCompletion(text, shortcutDef));
          });
        }
        if (!dt["!doc"]?.hidden) {
          results.push(this.makeCompletion(key, dt));
        }
        return results;
      });
    });
    if (this.openAiAssistant) {
      completions.unshift({
        text: "Ask AI for help",
        displayText: "Ask AI for help",
        def: {},
        origin: Origin.ENTITY_AC,
        className:
          "CodeMirror-Tern-completion CodeMirror-Tern-completions-ai-assist",
        hint: () => {
          this.openAiAssistant?.();
        },
        forceTop: true,
      });
    }
    return {
      from: cm.getCursor(),
      to: cm.getCursor(),
      list: completions,
    };
  }

  remove() {
    if (this.rendered) {
      this.rendered.remove();
      delete this.rendered;
    }
  }
}

export default EntityCompletion;
