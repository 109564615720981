import {
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_STAGING,
} from "@superblocksteam/shared";
import { Location, NavigateFunction } from "react-router";

/**
 * Replace or insert the given key-value pair to the window query parameter.
 * @param k key of the query parameter
 * @param v value of the query parameter
 *
 * @return New search params containing the updated key-value pair
 */
export function updatedSearchParams(k: string, v: string): URLSearchParams {
  const queryString = window.location.search.substring(1);
  const queryTuples = queryString.length ? queryString.split("&") : [];
  const result = new URLSearchParams();
  for (let i = 0; i < queryTuples.length; i++) {
    const tuple = queryTuples[i].split("=");
    if (tuple[0] === k) {
      continue;
    }
    result.append(tuple[0], tuple[1]);
  }
  result.append(k, v);
  return result;
}

export function getOrSetWindowEnvironment(
  navigate: NavigateFunction,
  location: Location,
  defaultEnvironment: string = ENVIRONMENT_STAGING,
  profileEnabled: boolean,
): string {
  if (!location.search.includes("environment=")) {
    setEnvironmentSearchParam(
      navigate,
      location,
      defaultEnvironment,
      profileEnabled,
    );
    return defaultEnvironment;
  }
  return location.search.includes("environment=staging")
    ? ENVIRONMENT_STAGING
    : ENVIRONMENT_PRODUCTION;
}

function setEnvironmentSearchParam(
  navigate: NavigateFunction,
  location: Location,
  environment: string,
  profileEnabled: boolean,
): void {
  const searchParams = updatedSearchParams("environment", environment);
  if (!profileEnabled) {
    navigate(
      {
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      },
      {
        replace: true,
      },
    );
  }
}
