import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const VerificationInputWrapper = styled.div`
  font-size: 40px;
  display: flex;
  justify-content: space-between;
  width: 460px;

  input {
    width: 103px;
    height: 80px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.GREY_200};
    border-radius: 4px;

    :focus {
      border: 2px solid ${({ theme }) => theme.colors.ACCENT_PURPLE};
    }
  }
`;

const InputCell = ({
  isFocused,
  onFocus,
  onType,
  goBack,
  value,
}: {
  isFocused: boolean;
  onFocus: () => void;
  goBack: () => void;
  onType: (newValue: string) => void;
  value: string;
}) => {
  const ref = useMemo(() => {
    return React.createRef<HTMLInputElement>();
  }, []);

  useEffect(() => {
    if (ref.current && isFocused) {
      ref.current.focus();
    }
  }, [isFocused, ref]);

  return (
    <input
      onFocus={onFocus}
      ref={ref}
      value={value}
      onChange={(e) => {
        onType(e.target.value);
      }}
      onKeyDown={(e) => {
        switch (e.key) {
          case "Backspace":
            if (isEmpty(value)) {
              e.preventDefault();
              goBack();
            }
            break;
          default:
            break;
        }
      }}
    />
  );
};

type VerificationInputProps = {
  values: string[];
  setParentValues: (values: string[]) => void;
  length: number;
  onComplete: (values: string[]) => Promise<void>;
  resetValues: () => void;
};

const VerificationInput = ({
  length,
  onComplete,
  values,
  setParentValues,
  resetValues,
}: VerificationInputProps) => {
  const setValues = useCallback(
    (newValues: any) => {
      setParentValues(newValues);
      if (newValues.find(isEmpty) === undefined) {
        onComplete(newValues).then(() => {
          resetValues();
          setCurrentIndex(0);
        });
      }
    },
    [onComplete, resetValues, setParentValues],
  );

  const updateValues = useCallback(
    (index: any, newValue: any) => {
      setValues(values.map((value, i) => (i === index ? newValue : value)));
    },
    [setValues, values],
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const onType = useCallback(
    (oldValue: any) => (newValue: string) => {
      const chars = newValue.toUpperCase().split("");
      if (chars.length === 0) {
        updateValues(currentIndex, "");
        return;
      }
      if (!isEmpty(oldValue)) {
        return;
      }
      let char_idx = 0;
      let value_idx = currentIndex;
      const oldValues = [...values];
      while (char_idx < chars.length && value_idx < length) {
        oldValues[value_idx] = chars[char_idx];
        value_idx++;
        char_idx++;
      }
      setValues(oldValues);
      setCurrentIndex(Math.min(value_idx, length - 1));
    },
    [currentIndex, length, setValues, updateValues, values],
  );

  const goBack = useCallback(() => {
    const prevIndex = Math.max(currentIndex - 1, 0);
    updateValues(prevIndex, "");
    setCurrentIndex(prevIndex);
  }, [currentIndex, updateValues]);

  const inputs = useMemo(() => {
    return values.map((value, i) => {
      return (
        <InputCell
          key={i}
          isFocused={currentIndex === i}
          onFocus={() => {
            setCurrentIndex(i);
          }}
          value={value}
          goBack={goBack}
          onType={onType(value)}
        />
      );
    });
  }, [currentIndex, goBack, onType, values]);

  return <VerificationInputWrapper>{inputs}</VerificationInputWrapper>;
};

export default VerificationInput;
