import { Space } from "antd";
import { noop } from "lodash";
import React from "react";
import styled from "styled-components";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";

type WeekdayIconProps = {
  isSelected: boolean;
};

const weekdayIconAttrs = (props: WeekdayIconProps) => ({
  isSelected: props.isSelected,
});

const WeekdayIcon = styled.div.attrs(weekdayIconAttrs)`
  background-color: ${(props) =>
    props.isSelected ? LegacyNamedColors.PURPLE : LegacyNamedColors.MERCURY};
  color: ${(props) =>
    props.isSelected ? LegacyNamedColors.WHITE : LegacyNamedColors.BLACK};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

const weekdayLabels = ["S", "M", "T", "W", "T", "F", "S"];

type WeekdaySelectorProps = {
  value: boolean[];
  onChange: (value: boolean[]) => void;
  disabled?: boolean;
};

const WeekdaySelector = ({
  value,
  onChange,
  disabled,
}: WeekdaySelectorProps) => {
  const updateWeekdays = (i: number) => {
    const newValue = [...value.slice(0, i), !value[i], ...value.slice(i + 1)];
    if (newValue.filter((item) => item === true).length === 0) {
      return;
    }
    onChange(newValue);
  };

  return (
    <Space direction="horizontal" size={10}>
      {value.map((isChecked, i) => (
        <WeekdayIcon
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          key={`weekday-selector-${i}`}
          isSelected={isChecked}
          onClick={() => (disabled ? noop : updateWeekdays(i))}
          data-test={`weekday-selector-${i}`}
          data-test-is-selected={isChecked}
        >
          {weekdayLabels[i]}
        </WeekdayIcon>
      ))}
    </Space>
  );
};

export default WeekdaySelector;
