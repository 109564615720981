import React, { ChangeEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/common/search.svg";
import { useDebounce } from "hooks/ui";
import { ENTITY_EXPLORER_SEARCH_ID } from "legacy/constants/Explorer";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";

const ExplorerSearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  font-size: 12px;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.GREY_300};
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  border-radius: 4px;

  & {
    svg {
      margin-left: 6px;
    }

    input {
      display: flex;
      border: none;
      background: none;
      padding-right: 8px;
      color: ${LegacyNamedColors.GRAY_DARKER};

      &::placeholder {
        color: ${({ theme }) => theme.colors.GREY_300};
      }

      &:focus {
        background: none;

        & ~ div.underline {
          width: 100%;
        }
      }
    }
  }
`;

interface ExplorerSearchProps {
  onChange: (value?: string) => void;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  value?: string;
  placeholder?: string;
}

const ExplorerSearch = ({
  onChange,
  value,
  searchInputRef,
  placeholder = "Search",
}: ExplorerSearchProps) => {
  const [internalValue, setInternalValue] = useState<string>();

  const triggerChange = useCallback(
    (value?: string) => {
      onChange(value);
      setInternalValue(undefined);
    },
    [onChange],
  );

  const debouncedChange = useDebounce(triggerChange, 500);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      setInternalValue(value);

      if (debouncedChange) {
        debouncedChange(value.length > 0 ? value : undefined);
      }
    },
    [debouncedChange],
  );

  return (
    <ExplorerSearchWrapper>
      <SearchIcon height={15} width={15} />
      <input
        autoComplete="off"
        id={ENTITY_EXPLORER_SEARCH_ID}
        data-test={ENTITY_EXPLORER_SEARCH_ID}
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        value={internalValue ?? value ?? ""}
        ref={searchInputRef}
      />
    </ExplorerSearchWrapper>
  );
};

export default ExplorerSearch;
