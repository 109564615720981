import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope, Dimension } from "@superblocksteam/shared";
import { RunEventHandlersPayload } from "legacy/constants/ActionConstants";
import { WidgetHeightConstraintType } from "legacy/constants/WidgetConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { showItemPropertyPane } from "./propertyPaneActions";
import type { CopiedWidgets, WidgetMap } from "legacy/widgets";

export const runEventHandlers =
  createAction<RunEventHandlersPayload>("RUN_EVENT_HANDLERS");

export const runEventHandlersSuccess = createAction(
  "RUN_EVENT_HANDLERS_SUCCESS",
  (callbackId?: string) => ({
    payload: { callbackId },
  }),
);

export const runEventHandlersError = createAction(
  "RUN_EVENT_HANDLERS_ERROR",
  (callbackId?: string) => ({
    payload: { callbackId },
  }),
);

export const disableDragAction = createAction(
  "DISABLE_WIDGET_DRAG",
  (isDraggingDisabled: boolean) => ({
    payload: { isDraggingDisabled },
  }),
);

export const disableNudgeAction = createAction(
  "DISABLE_WIDGET_NUDGE",
  (isNudgeDisabled: boolean) => ({
    payload: { isNudgeDisabled },
  }),
);

export const selectWidgets = createAction(
  "SELECT_WIDGETS",
  (
    widgetIds: string[],
    selectingMultiple?: boolean,
    forceNavigateInPropertyPane?: boolean,
  ) => ({
    payload: { widgetIds, selectingMultiple, forceNavigateInPropertyPane },
  }),
);

export const setSelectedWidgetsAncestory = createAction(
  "SET_SELECTED_WIDGETS_ANCESTORY",
  (payload: Array<{ widgetId: string; ancestory: string[] }>) => ({
    payload,
  }),
);

export const focusWidget = createAction(
  "FOCUS_WIDGET",
  (widgetId?: string) => ({
    payload: { widgetId },
  }),
);

export const addSectionWidgetColumn = createAction(
  "WIDGET_ADD_SECTION_COLUMN",
  (sectionWidgetId?: string) => ({
    payload: { sectionWidgetId },
  }),
);

export const resizeSectionWidget = createAction(
  "WIDGET_RESIZE_SECTION",
  (
    sectionWidgetId: string,
    constraintType: WidgetHeightConstraintType,
    newHeight: Dimension<"gridUnit" | "px">,
  ) => ({
    payload: { sectionWidgetId, constraintType, newHeight },
  }),
);

export const updateWidgetChildren = createAction(
  "WIDGET_UPDATE_CHILDREN",
  (widgetId: string, newChildren: string[]) => ({
    payload: { widgetId, newChildren },
  }),
);

export const resizeSectionWidgetColumns = createAction(
  "WIDGET_RESIZE_SECTION_COLUMN",
  (widgetId: string, newWidths: number[], overrideWidgets?: WidgetMap) => ({
    payload: { sectionWidgetId: widgetId, newWidths, overrideWidgets },
  }),
);

export const addSectionWidget = createAction(
  "WIDGET_ADD_SECTION",
  (sectionWidgetId?: string, placement?: "above" | "below") => ({
    payload: { sectionWidgetId, placement },
  }),
);

export const unfocusWidget = () => focusWidget();

export const switchTab = createAction(
  "SWITCH_TAB",
  (tabsId: string, tabId: string) => ({
    payload: { tabsId, tabId },
  }),
);

export const showWidgetPropertyPane = (
  widgetId?: string,
  isVisible?: boolean,
) =>
  showItemPropertyPane(
    widgetId === undefined
      ? undefined
      : { kind: ItemKinds.WIDGET, id: widgetId, scope: ApplicationScope.PAGE },
    isVisible,
  );

export const clearPropertyPane = createAction("CLEAR_PROPERTY_PANE");

export const showModal = createAction(
  "SHOW_MODAL",
  (
    id: string,
    showModalOptions: {
      showPropertyPane: boolean;
      isSelectingMultiple?: boolean;
      doNotSelect?: boolean;
      isRouteInitiated?: boolean;
    } = {
      showPropertyPane: true,
      isSelectingMultiple: false,
      doNotSelect: false,
      isRouteInitiated: false,
    },
  ) => {
    return {
      payload: {
        modalId: id,
        showPropertyPane: showModalOptions.showPropertyPane,
        isSelectingMultiple: showModalOptions.isSelectingMultiple,
        doNotSelect: showModalOptions.doNotSelect,
        isRouteInitiated: showModalOptions.isRouteInitiated,
      },
    };
  },
);

export const closeModal = createAction(
  "CLOSE_MODAL",
  (modalName?: string, exclude?: string) => ({
    payload: { modalName, exclude },
  }),
);
export const closeAllModals = () => closeModal();

export const copyWidget = createAction(
  "COPY_SELECTED_WIDGET_INIT",
  (isShortcut: boolean) => {
    return {
      payload: {
        isShortcut: !!isShortcut,
      },
    };
  },
);

export const pasteWidget = createAction(
  "PASTE_COPIED_WIDGET_INIT",
  (params: {
    pasteTargetId: string;
    mousePosition?: {
      x: number;
      y: number;
    };
    pasteAtCursor?: boolean;
    forcePasteIntoContainer?: boolean;
    stackInsertionPosition?: number;
    sectionInsertionPosition?: number;
    columnInsertionPosition?: number;
    copiedWidgetsOverride?: CopiedWidgets;
    skipReplaceConfirmation?: boolean;
  }) => {
    return {
      payload: {
        pasteTargetId: params.pasteTargetId,
        pasteAtCursor: params.pasteAtCursor,
        forcePasteIntoContainer: params.forcePasteIntoContainer,
        mousePosition: params.mousePosition,
        stackInsertionPosition: params.stackInsertionPosition,
        sectionInsertionPosition: params.sectionInsertionPosition,
        columnInsertionPosition: params.columnInsertionPosition,
        copiedWidgetsOverride: params.copiedWidgetsOverride,
        skipReplaceConfirmation: params.skipReplaceConfirmation,
      },
    };
  },
);

export const pasteWidgetConfirmation = createAction(
  "PASTE_COPIED_WIDGET_CONFIRMATION_INIT",
  ({
    pasteTargetId,
    copiedWidgets,
  }: {
    pasteTargetId: string;
    copiedWidgets: CopiedWidgets;
  }) => ({ payload: { pasteTargetId, copiedWidgets } }),
);

export const pasteWidgetConfirmationAccepted = createAction(
  "PASTE_COPIED_WIDGET_CONFIRMATION_SUCCESS",
);
export const pasteWidgetConfirmationCancelled = createAction(
  "PASTE_COPIED_WIDGET_CONFIRMATION_CANCEL",
);

export const deleteWidgets = createAction(
  "WIDGET_DELETE",
  ({
    widgetIds,
    isShortcut,
    disallowUndo = false,
  }: {
    widgetIds: string[] | undefined;
    isShortcut: boolean;
    disallowUndo?: boolean;
  }) => ({
    payload: {
      widgetIds,
      isShortcut,
      disallowUndo,
    },
  }),
);

export const deleteSelectedWidget = (
  isShortcut: boolean,
  disallowUndo = false,
) => deleteWidgets({ widgetIds: undefined, isShortcut, disallowUndo });

export const deleteEntityFromWidgets = createAction(
  "DELETE_ENTITY_FROM_WIDGETS",
  (entityName: string) => ({
    payload: { entityName },
  }),
);

export const groupSelectedWidgets = createAction("GROUP_SELECTED_WIDGETS");

export const cutWidget = createAction("CUT_SELECTED_WIDGET");

export const evaluateBindings = createAction(
  "EVALUATE_BINDINGS_DIRECTLY",
  (bindings: string[], callbackId: string) => ({
    payload: { bindings, callbackId, scope: ApplicationScope.PAGE },
  }),
);

export const updateTableRows = createAction(
  "UPDATE_TABLE_ROWS",
  (
    widgetId: string,
    rows: Record<number, Record<string, unknown>>,
    options?: { absoluteIndices: boolean },
  ) => ({
    payload: { widgetId, rows, options },
  }),
);

export const deleteTableRows = createAction(
  "DELETE_TABLE_ROWS",
  (widgetId: string, rows: number[]) => ({
    payload: { widgetId, rows },
  }),
);

export const insertTableRows = createAction(
  "INSERT_TABLE_ROWS",
  (widgetId: string, startIndex: number, rows: Record<string, unknown>[]) => ({
    payload: { widgetId, startIndex, rows },
  }),
);
