import { createAction } from "@reduxjs/toolkit";

export const startEvaluation = createAction(
  "START_EVALUATION",
  (payload: { evaluationType: "ui" | "headless" | "unknown" }) => {
    return {
      payload,
    };
  },
);

export const appFrameLoaded = createAction("APP_FRAME_LOADED");

export const stopEvaluation = createAction("STOP_EVALUATION");

export const restartEvaluation = createAction("RESTART_EVALUATION");

export const hasUpdatedEntityNames = createAction("hasUpdatedEntityNames");

export const pageLoadActionsComplete = createAction(
  "PAGE_LOAD_ACTIONS_COMPLETE",
);
