import { json, Graph } from "@dagrejs/graphlib";
import { createSelector } from "reselect";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { ReferenceGraph, UsageGraph } from "./types";
import type { AppState } from "store/types";

const getReferenceAnalysisState = (state: AppState) =>
  state.legacy.ui.referenceAnalysis;

export const getReferenceAnalysisDeleteModalIsOpen = (state: AppState) => {
  return getReferenceAnalysisDeleteModalType(state) != null;
};

export const getReferenceAnalysisDeleteModalType = (state: AppState) => {
  return state.legacy.ui.referenceAnalysis.entityModalOpenType;
};

export const getAllReferencesModalOpen = (state: AppState) => {
  return state.legacy.ui.referenceAnalysis.allReferencesModalOpenPath != null;
};

export const getAllReferencesModalOpenPath = (state: AppState) => {
  return state.legacy.ui.referenceAnalysis.allReferencesModalOpenPath;
};

export const getReferenceGraph = createSelector(
  getReferenceAnalysisState,
  (referenceAnalysisState): ReferenceGraph => {
    if (referenceAnalysisState.referenceGraphJson) {
      return json.read(
        referenceAnalysisState.referenceGraphJson,
      ) as ReferenceGraph;
    }
    return new Graph({ directed: true, multigraph: true }) as ReferenceGraph;
  },
);

export const getUsageGraph = createSelector(
  getReferenceAnalysisState,
  (referenceAnalysisState): UsageGraph => {
    if (referenceAnalysisState.usageGraphJson) {
      return json.read(referenceAnalysisState.usageGraphJson) as UsageGraph;
    }
    return new Graph({ directed: true, multigraph: true }) as UsageGraph;
  },
);

export const getAnalysisStatus = createSelector(
  getReferenceAnalysisState,
  selectLastSuccessfulWrite,
  (referenceAnalysisState, lastSuccessfulWrite) => {
    const meta = referenceAnalysisState.meta;
    if (!meta) {
      return {
        isStale: false,
        hasRun: false,
      };
    }
    return {
      isStale: meta.lastSuccessfulWrite < lastSuccessfulWrite.valueOf(),
      hasRun: true,
    };
  },
);
