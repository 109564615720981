import { matchRoutes } from "react-router";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  getApplicationDeployedURL,
  getApplicationEmbedPreviewURL,
  getApplicationEmbedURL,
  getApplicationPreviewURL,
} from "legacy/constants/routes";
import { APP_MODE } from "legacy/reducers/types";
import { router } from "router";
import { Result } from "utils/result";
import { isOnEmbedPreviewRoute, isOnEmbedRoute } from "../embed/messages";
import {
  buildURL,
  NavigateToRouteOpts,
  RouteToUrlError,
  validateAndBuildRouteInputs,
} from "./shared";
import type { RouteDef } from "@superblocksteam/shared";

// E.g: localhost:3000/applications/edit/7d6e773d-9fff-4876-86c9-840d82467840/books/5
export function buildApplicationUrlFromRoute(
  route: RouteDef,
  navigateToRoute: NavigateToRouteOpts,
  context: {
    applicationId: string | undefined;
    appMode: APP_MODE | undefined;
    currentPageId: string | undefined;
  },
): Result<URL, RouteToUrlError> {
  const result = validateAndBuildRouteInputs(route, navigateToRoute);
  if (!result.ok) {
    return result;
  }
  const navigationUrlParams: Parameters<typeof getTargetNavigationURL>[0] = {
    applicationId: context.applicationId,
    appMode: context.appMode,
    targetPath: result.value.path,
    params: result.value.queryParams,
  };
  if (context.appMode === APP_MODE.EDIT) {
    navigationUrlParams.resetUrlState =
      "pageId" in route && context.currentPageId !== route.pageId;
  }

  const url = getTargetNavigationURL(navigationUrlParams);

  return {
    ok: true,
    value: url,
  };
}

export function getTargetNavigationURL({
  targetPath,
  applicationId,
  appMode,
  params = {},
  resetUrlState = false,
}: {
  targetPath: string;
  applicationId: string | undefined;
  params?: Record<string, string>;
  appMode: APP_MODE | undefined;
  resetUrlState?: boolean;
}) {
  let basePath: string;
  if (isOnEmbedPreviewRoute()) {
    basePath = getApplicationEmbedPreviewURL(applicationId);
  } else if (isOnEmbedRoute()) {
    basePath = getApplicationEmbedURL(applicationId);
  } else if (appMode === APP_MODE.EDIT) {
    const matchedRoute = matchRoutes(router.routes, window.location)?.at(-1);

    // legacy behavior, we should always have a matched route, otherwise we really can't do much
    if (!matchedRoute) {
      const baseEditPath = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId,
      });
      const optionalTabsPath =
        window.location.pathname.split(baseEditPath)[1] ?? "";
      basePath = `${baseEditPath}${optionalTabsPath}`;
    } else if (resetUrlState) {
      // when we navigate between pages, we want to reset things like the tab state in the URL
      basePath = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId,
      });
    } else {
      basePath = getEditorBasePath(matchedRoute.route.path as any, {
        ...matchedRoute.params,
        applicationId: applicationId,
      });
    }
  } else if (appMode === APP_MODE.PREVIEW) {
    basePath = getApplicationPreviewURL(applicationId);
  } else {
    basePath = getApplicationDeployedURL(applicationId);
  }

  return buildURL({
    targetPath,
    basePath,
    params,
  });
}
