import { Button, Modal, Row, Space, Typography } from "antd";
import React from "react";
import styled, { useTheme } from "styled-components";

import { ReactComponent as IconOk } from "./assets/icon-ok.svg";
import { ReactComponent as IconWarning } from "./assets/icon-warning.svg";

const StyledIconOk = styled(IconOk)`
  width: 24px;
  height: 24px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledIconWarning = styled(IconWarning)`
  width: 24px;
  height: 24px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

const FooterWrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export enum AgentSwitchDeploymentModalType {
  TO_CLOUD = 0,
  TO_OPA,
  TO_OPA_NO_AGENTS,
  TO_OPA_AFTER_DEPLOY,
}

const MODALS = [
  {
    warning: true,
    title: "Switch to Cloud",
    description: (
      <>
        <Paragraph>
          Switching to Superblocks Cloud may cause database connect issues. Make
          sure you’ve added Superblocks IPs to your allowed list before
          continuing.
        </Paragraph>
        <Paragraph>
          Learn more about{" "}
          <Typography.Link
            href={
              "https://docs.superblocks.com/integrations/troubleshooting/troubleshooting-databases"
            }
            target="_blank"
          >
            Connecting to databases
          </Typography.Link>
          .
        </Paragraph>
      </>
    ),
    confirmText: "Switch to Cloud",
    cancelText: "Cancel",
  },
  {
    warning: false,
    title: "Switch to On-Premise",
    description: (
      <Paragraph>
        Are you sure you want to change your organization&apos;s deployment type
        to
        <br />
        <Typography.Text strong>On-Premise Deployment</Typography.Text>
      </Paragraph>
    ),
    confirmText: (
      <span data-test="switch-to-on-premise"> Switch to On-Premise</span>
    ),
    cancelText: "Cancel",
  },
  {
    warning: true,
    title: "No Reachable Agents",
    description: (
      <>
        <Paragraph>
          Your browser cannot currently connect to any agent. This is required
          for Superblocks to work as expected.
          <br />
          Are you sure you want to continue?
        </Paragraph>
        <Paragraph>
          For helping, see{" "}
          <Typography.Link
            href={
              "https://docs.superblocks.com/on-premise-agent/manage/troubleshooting"
            }
            target="_blank"
          >
            Truoblesshooting OPA Connectivity
          </Typography.Link>
          .
        </Paragraph>
      </>
    ),
    confirmText: "Switch to On-Premise",
    cancelText: "Cancel",
  },
  {
    warning: false,
    title: "Agent Deployed",
    description: (
      <Paragraph>
        Your agent successfuly registered and is reachable from your browser. To
        start using the agent, switch your Superblocks deployment method to
        On-Premise.
      </Paragraph>
    ),
    confirmText: "Switch to On-Premise",
    cancelText: "Exit and Stay on Cloud",
  },
];

type Props = {
  open: boolean;
  modalType: AgentSwitchDeploymentModalType;
  onCancel: () => void;
  onOk: () => void;
};

export const AgentSwitchDeploymentModal = ({
  open,
  modalType,
  onCancel,
  onOk,
}: Props) => {
  const modal = MODALS[modalType];
  const theme = useTheme();
  return (
    <Modal
      open={open}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={onOk}
      title={
        <TitleWrapper>
          {modal.warning === true ? (
            <StyledIconWarning color={theme.colors.ACCENT_ORANGE} />
          ) : (
            <StyledIconOk color={theme.colors.ACCENT_BLUE_500} />
          )}
          <TitleTextWrapper>{modal.title}</TitleTextWrapper>
        </TitleWrapper>
      }
      footer={
        <FooterWrapper>
          <Button type="primary" ghost onClick={onCancel}>
            {modal.cancelText}
          </Button>
          <Button type="primary" onClick={onOk}>
            {modal.confirmText}
          </Button>
        </FooterWrapper>
      }
    >
      <Space direction="vertical" size={20}>
        <Row justify="start">{modal.description}</Row>
      </Space>
    </Modal>
  );
};
