import {
  ExclamationCircleOutlined,
  PlusOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  List,
  Row,
  Space,
  Tooltip,
} from "antd";
import Title from "antd/lib/typography/Title";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import styled, { useTheme } from "styled-components";
import { Layout, MainWrapper } from "components/app";
import SbText from "components/ui/SbText";
import { FullWidthSpace } from "components/ui/Space";
import { Spinner } from "components/ui/Spinner";
import envs from "env";
import {
  openPricingModal,
  openQualificationModal,
} from "legacy/actions/modalActions";
import { ReactComponent as CheckedIcon } from "legacy/assets/icons/control/checkmark.svg";
import { ReactComponent as RemoveIcon } from "legacy/assets/icons/control/remove.svg";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { formatCurrency, formatNumber } from "legacy/utils/FormatUtils";
import Header from "pages/components/Header";
import { PageWrapper } from "pages/components/PageWrapper";
import { CHECK_PROMO_CODE, CREATE_PAYMENT_INTENT } from "pages/routes";
import { useAppDispatch } from "store/helpers";
import { callServer, HttpMethod } from "store/utils/client";
import { UIEvent } from "utils/event";
import logger from "utils/logger";
import Payment from "./Payment";
import { PRICING, PlanTypes } from "./PlanDefinition";
import { ReactComponent as LightningIcon } from "./lightning.svg";
import PricingSelector from "./pricingRadio";
import { ReactComponent as StarIcon } from "./star.svg";

// Disabled because this requires loading stripe on all pages,
// and we have chosen to use lazy-loading on the checkout screen
loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(envs.get("SUPERBLOCKS_UI_STRIPE_KEY"));

const CheckoutTitle = styled(Title).attrs({
  className: "sb-override",
})`
  // Increase specificity above antd's default tag.class style
  && {
    font-weight: 700;
  }

  h2& {
    font-size: 32px;
  }
  h3& {
    font-size: 20px;
  }
  h4& {
    font-size: 16px;
  }
`;

export const FeatureCheckMark = styled(CheckedIcon)<{ color?: string }>`
  path {
    fill: ${(props) => props.color};
  }
  top: 4px;
  height: 14px;
  margin-right: 5px;
  vertical-align: top;
`;

export const FeatureCloseMark = styled(RemoveIcon)`
  path {
    fill: ${(props) => props.theme.colors.GREY_400};
  }
  top: 4px;
  height: 14px;
  margin-right: 5px;
  vertical-align: top;
`;

const LineItem = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.GREY_700};
  margin-bottom: 0;
`;

const StyledStar = styled(StarIcon)<{ color: string; backgroundColor: string }>`
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  padding: 5px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 12px;
`;

const StyledLightning = styled(LightningIcon)<{
  color: string;
  backgroundColor: string;
}>`
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  padding: 5px;
  height: 40px;
  width: 40px;
  padding: 10px;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 16px;
`;

export const StyledListItem = styled(List.Item)`
  display: flex;
  padding: 0 0 6px 0;

  svg {
    flex: 0 0 24px;
  }

  div {
    flex-grow: 1;
  }
`;

const Frame = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  width: 100%;
`;

const TooltipWithHoverHint = styled(Tooltip)`
  border-bottom: 1px solid ${(props) => props.theme.colors.GREY_900};
  cursor: help;
  margin-right: 5px;
`;

const CHECKOUT_TITLE = "Upgrade Plan";

const CheckoutBackground = styled(MainWrapper)`
  background: ${(props) => props.theme.colors.GREY_25};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainContainer = styled.div`
  max-width: 1000px;
  width: 100%;
`;

const CheckoutContainer = styled.div`
  display: flex;
  max-width: 100%;

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const CheckoutPanel = styled.div`
  background: ${(props) => props.theme.colors.WHITE};
  padding: 40px;
  border-radius: 4px;
`;

const CheckoutMainContent = styled(CheckoutPanel)`
  flex-grow: 1;
`;

const CheckoutSidePanel = styled.div`
  flex: 0 0 300px;

  @media only screen and (min-width: 800px) {
    margin-left: 32px;
  }
`;

const CheckoutBenefits = styled(CheckoutPanel)`
  padding: 24px;
`;

type SeatSelection = {
  type: "Creator" | "End User";
  description: string;
  tooltip: string;
  minSeatCount: number;
  seatCount: number;
  setSeatCount: (seats: number | null) => void;
};

interface PlanSelection {
  frequency: "MONTHLY" | "YEARLY";
  creatorSeats: number;
  standardSeats: number;
}

export const Checkout = () => {
  const location = useLocation();

  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [planSelection, setPlanSelection] = useState<PlanSelection>({
    frequency: "YEARLY",
    creatorSeats: 5,
    standardSeats: 5,
  });

  const [paymentError, setPaymentError] = useState<string>();

  useEffect(() => {
    // this error is not set currently, but could be used in the future
    const error = get(location.state, "error");
    if (error !== null || error !== undefined) {
      setPaymentError(error);
    }
    logger.event(UIEvent.VIEWED_CHECKOUT_SCREEN);
  }, [location.state]);

  useEffect(() => {
    callServer<{ subscriptionId: string; clientSecret: string }>({
      method: HttpMethod.Post,
      url: CREATE_PAYMENT_INTENT,
    }).then(({ clientSecret }) => {
      setClientSecret(clientSecret);
    });
  }, []);

  const setFrequency = useCallback((newValue: string) => {
    setPlanSelection((state) => ({
      ...state,
      frequency: newValue as "MONTHLY" | "YEARLY",
    }));
  }, []);
  const setCreatorSeats = useCallback((value: number | null) => {
    setPlanSelection((state) => ({
      ...state,
      creatorSeats: value ?? 0,
    }));
  }, []);
  const setStandardSeats = useCallback((value: number | null) => {
    setPlanSelection((state) => ({
      ...state,
      standardSeats: value ?? 0,
    }));
  }, []);

  const [failedPromoCode, setFailedPromoCode] = useState<string>();
  const [currentPromoCode, setCurrentPromoCode] = useState("");
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState<number>();
  const [discountButtonLoading, setDiscountButtonLoading] = useState(false);
  const applyPromoCode = useCallback(() => {
    setFailedPromoCode(undefined);
    setDiscountButtonLoading(true);
    callServer<{ percent_off?: string }>({
      method: HttpMethod.Get,
      url: CHECK_PROMO_CODE,
      query: { promo: currentPromoCode },
    })
      .then((res) => {
        if (res.percent_off) {
          setAppliedDiscount(parseInt(res.percent_off));
        } else {
          setFailedPromoCode(currentPromoCode);
        }
        return;
      })
      .finally(() => {
        setDiscountButtonLoading(false);
      });
  }, [currentPromoCode, setFailedPromoCode]);

  const creatorSeatCharge = useMemo(() => {
    return (
      PRICING["CREATOR_SEAT"][planSelection.frequency].price *
      planSelection.creatorSeats
    );
  }, [planSelection.creatorSeats, planSelection.frequency]);

  const standardSeatCharge = useMemo(() => {
    return (
      PRICING["END_USER_SEAT"][planSelection.frequency].price *
      planSelection.standardSeats
    );
  }, [planSelection.frequency, planSelection.standardSeats]);

  const subTotal = useMemo(() => {
    return creatorSeatCharge + standardSeatCharge;
  }, [creatorSeatCharge, standardSeatCharge]);

  const annualSavings = useMemo(() => {
    return (
      (PRICING["CREATOR_SEAT"].MONTHLY.price * 12 -
        PRICING["CREATOR_SEAT"].YEARLY.price) *
        planSelection.creatorSeats +
      (PRICING["END_USER_SEAT"].MONTHLY.price * 12 -
        PRICING["END_USER_SEAT"].YEARLY.price) *
        planSelection.standardSeats
    );
  }, [planSelection.creatorSeats, planSelection.standardSeats]);

  const discountAmount = useMemo(() => {
    if (!appliedDiscount) {
      return 0;
    }
    return (subTotal * appliedDiscount) / 100;
  }, [appliedDiscount, subTotal]);

  const totalAmount = useMemo(() => {
    return subTotal - discountAmount;
  }, [discountAmount, subTotal]);

  const seatSelections: SeatSelection[] = useMemo(() => {
    return [
      {
        type: "Creator",
        description: "Develop Apps, Workflows and Scheduled Jobs",
        tooltip:
          "Users who build and manage Superblocks Applications, Workflows and Scheduled Jobs",
        minSeatCount: 1,
        seatCount: planSelection.creatorSeats,
        setSeatCount: setCreatorSeats,
      },
      {
        type: "End User",
        description: "Unlimited access to consume Superblocks Apps",
        tooltip: "Frequent users who have unlimited access to Superblocks Apps",
        minSeatCount: 0,
        seatCount: planSelection.standardSeats,
        setSeatCount: setStandardSeats,
      },
    ];
  }, [
    planSelection.creatorSeats,
    planSelection.standardSeats,
    setCreatorSeats,
    setStandardSeats,
  ]);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  // Only PRO can self-checkout
  const currentPlan = PlanTypes["PRO"];

  return (
    <PageWrapper pageName={CHECKOUT_TITLE}>
      <Layout Header={<Header />}>
        <CheckoutBackground data-test="checkout-home">
          <MainContainer>
            <CheckoutTitle level={2}>
              <StyledLightning
                color={currentPlan.color}
                backgroundColor={currentPlan.secondaryColor}
              />
              {CHECKOUT_TITLE}
            </CheckoutTitle>
            <CheckoutContainer>
              <CheckoutMainContent>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <SbText>
                      <Space size={2} direction="horizontal" align="start">
                        <Button
                          type="link"
                          style={{
                            cursor: "default",
                            padding: 0,
                            color: "black",
                          }}
                        >
                          You selected the
                        </Button>
                        <Button
                          type="text"
                          onClick={() => dispatch(openPricingModal())}
                          style={{
                            cursor: "pointer",
                            padding: 0,
                            color: currentPlan.color,
                            paddingLeft: 4,
                            paddingRight: 4,
                            backgroundColor: currentPlan.secondaryColor,
                          }}
                        >
                          <strong>{currentPlan.displayName} plan</strong>
                        </Button>
                      </Space>
                    </SbText>
                  </Row>

                  {seatSelections.map((seatSelection, i) => (
                    <Row key={i}>
                      <label>
                        <Space direction="vertical">
                          <div style={{ fontSize: 15, fontWeight: 600 }}>
                            {seatSelection.type}s{" "}
                            <Tooltip overlay={seatSelection.tooltip}>
                              <QuestionCircleTwoTone
                                twoToneColor={theme.colors.GREY_300}
                                style={{
                                  fontSize: 14,
                                }}
                              />
                            </Tooltip>
                          </div>
                          <SbText color={"GREY_500"} sizePx={14}>
                            {seatSelection.description}
                          </SbText>
                          <InputNumber
                            min={seatSelection.minSeatCount}
                            onChange={seatSelection.setSeatCount}
                            value={seatSelection.seatCount}
                            style={{ width: 130 }}
                          />
                        </Space>
                      </label>
                    </Row>
                  ))}

                  <Row style={{ width: "100%" }}>
                    <CheckoutTitle level={4}>Your Billing Cycle</CheckoutTitle>
                    <PricingSelector
                      onChange={setFrequency}
                      value={planSelection.frequency}
                      items={[
                        {
                          key: "YEARLY",
                          title: "Yearly",
                          tag: `Save ${formatCurrency(annualSavings)}`,
                          monthly_price:
                            (PRICING["CREATOR_SEAT"].YEARLY.price *
                              planSelection.creatorSeats +
                              PRICING["END_USER_SEAT"].YEARLY.price *
                                planSelection.standardSeats) /
                            12,
                        },
                        {
                          key: "MONTHLY",
                          title: "Monthly",
                          monthly_price:
                            PRICING["CREATOR_SEAT"].MONTHLY.price *
                              planSelection.creatorSeats +
                            PRICING["END_USER_SEAT"].MONTHLY.price *
                              planSelection.standardSeats,
                        },
                      ]}
                    />
                  </Row>

                  <Row>
                    <Space
                      direction="vertical"
                      size="large"
                      style={{
                        width: "100%",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <List split={false}>
                        <StyledListItem style={{ fontSize: 16 }}>
                          <FullWidthSpace direction="vertical" size={4}>
                            <Row justify="space-between">
                              <strong style={{ fontSize: 16 }}>
                                Order Summary
                              </strong>
                            </Row>
                            <Frame>
                              <Row justify="space-between">
                                <LineItem>
                                  {`$${
                                    planSelection.frequency === "YEARLY"
                                      ? PRICING["CREATOR_SEAT"].YEARLY.price /
                                        12
                                      : PRICING["CREATOR_SEAT"].MONTHLY.price
                                  } per Creator x ${
                                    planSelection.creatorSeats
                                  } x ${
                                    planSelection.frequency === "YEARLY"
                                      ? "12 months"
                                      : "1 month"
                                  }`}
                                </LineItem>
                                <p>{formatCurrency(creatorSeatCharge)}</p>
                              </Row>
                              <Row justify="space-between">
                                <LineItem>
                                  {`$${
                                    planSelection.frequency === "YEARLY"
                                      ? PRICING["END_USER_SEAT"].YEARLY.price /
                                        12
                                      : PRICING["END_USER_SEAT"].MONTHLY.price
                                  } per End User x ${
                                    planSelection.standardSeats
                                  } x ${
                                    planSelection.frequency === "YEARLY"
                                      ? "12 months"
                                      : "1 month"
                                  }`}
                                </LineItem>
                                <p>{formatCurrency(standardSeatCharge)}</p>
                              </Row>
                              <Divider style={{ margin: "10px 0" }} />
                              {appliedDiscount && (
                                <>
                                  <Row justify="space-between">
                                    <LineItem>{currentPromoCode}</LineItem>
                                    <strong>
                                      -{formatCurrency(discountAmount)}
                                    </strong>
                                  </Row>
                                </>
                              )}
                              <Row justify="space-between">
                                <LineItem>
                                  <strong>Total due today</strong>
                                </LineItem>
                                <strong>{formatCurrency(subTotal)}</strong>
                              </Row>
                            </Frame>
                            {planSelection.frequency === "YEARLY" && (
                              <>
                                <Row
                                  justify="space-between"
                                  style={{ padding: 20 }}
                                >
                                  <LineItem
                                    style={{
                                      color: theme.colors.ACCENT_GREEN,
                                    }}
                                  >
                                    {`Your savings`}
                                  </LineItem>
                                  <p
                                    style={{
                                      color: theme.colors.ACCENT_GREEN,
                                    }}
                                  >
                                    {formatCurrency(annualSavings)}
                                  </p>
                                </Row>
                              </>
                            )}
                            {!showPromoCode && (
                              <Row>
                                <Button
                                  type="link"
                                  icon={<PlusOutlined />}
                                  style={{ padding: 0, color: "#6C7689" }}
                                  onClick={() =>
                                    setShowPromoCode(!showPromoCode)
                                  }
                                >
                                  Add Promo Code
                                </Button>
                              </Row>
                            )}
                            {showPromoCode && !appliedDiscount && (
                              <Row gutter={16}>
                                <Col span={18}>
                                  <Input
                                    placeholder="Enter Promo Code"
                                    onChange={(e) =>
                                      setCurrentPromoCode(e.target.value)
                                    }
                                    suffix={
                                      failedPromoCode ? (
                                        <Tooltip
                                          overlay={`Could not find promo code: ${failedPromoCode}`}
                                        >
                                          <ExclamationCircleOutlined
                                            style={{
                                              color:
                                                LegacyNamedColors.ERROR_RED,
                                            }}
                                          />
                                        </Tooltip>
                                      ) : undefined
                                    }
                                  />
                                </Col>
                                <Col span={6}>
                                  <Button
                                    type="primary"
                                    ghost
                                    onClick={applyPromoCode}
                                    style={{ width: "100%" }}
                                    loading={discountButtonLoading}
                                  >
                                    Apply
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </FullWidthSpace>
                        </StyledListItem>
                      </List>
                    </Space>
                  </Row>

                  <CheckoutTitle level={4}>Payment</CheckoutTitle>
                  {clientSecret ? (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <Payment
                        clientSecret={clientSecret}
                        creatorSeats={{
                          price:
                            PRICING["CREATOR_SEAT"][planSelection.frequency]
                              .priceId,
                          quantity: planSelection.creatorSeats,
                        }}
                        standardSeats={{
                          price:
                            PRICING["END_USER_SEAT"][planSelection.frequency]
                              .priceId,
                          quantity: planSelection.standardSeats,
                        }}
                        seatCount={planSelection.creatorSeats}
                        finalPrice={formatCurrency(totalAmount)}
                        planName={currentPlan.displayName}
                        promoCode={currentPromoCode}
                        paymentError={paymentError}
                      />
                    </Elements>
                  ) : (
                    <Spinner />
                  )}
                </Space>
              </CheckoutMainContent>
              <CheckoutSidePanel>
                <CheckoutBenefits>
                  <CheckoutTitle level={4}>
                    <StyledStar
                      color={currentPlan.color}
                      backgroundColor={currentPlan.secondaryColor}
                    />
                    With {currentPlan.displayName} you will get
                  </CheckoutTitle>

                  <List split={false}>
                    <StyledListItem key={"sdlkfjslkfj"}>
                      <FeatureCheckMark color={currentPlan.color} />
                      <div>
                        <TooltipWithHoverHint
                          overlay={
                            <>
                              <span>
                                A Step Run is counted every time a step in a
                                Deployed Workflow or Scheduled Job successfully
                                moves data, executes code, or takes an action.
                                For example, a Workflow with 1 step counts as 1
                                Step Run every time it is called, while a
                                Workflow of 3 steps is counted as 3 Step Runs
                                when called.
                              </span>
                              <br />
                              <br />
                              <span>{`$${currentPlan.incrementalStepRunCost} per ${currentPlan.incrementalStepRunQuanitty} on-demand, per month`}</span>
                            </>
                          }
                        >
                          {formatNumber(currentPlan.includedStepRuns)} Step Runs
                        </TooltipWithHoverHint>
                        included
                      </div>
                    </StyledListItem>
                    {currentPlan.bulletPoints.map((point, i) => (
                      <StyledListItem key={i}>
                        <FeatureCheckMark color={currentPlan.color} />
                        <div>{point}</div>
                      </StyledListItem>
                    ))}
                  </List>
                </CheckoutBenefits>
                <Space
                  style={{
                    justifyContent: "end",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Button
                    type="link"
                    onClick={() => dispatch(openQualificationModal())}
                  >
                    Need help? Talk to an expert
                  </Button>
                </Space>
              </CheckoutSidePanel>
            </CheckoutContainer>
          </MainContainer>
        </CheckoutBackground>
      </Layout>
    </PageWrapper>
  );
};

export default Checkout;
