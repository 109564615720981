import { DB_SQL_INITIAL_TEXT, EditorLanguage, FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';

export const AthenaPluginVersions = {
  V1: '0.0.1'
};

const ATHENA_S3_SUFFIXES_AND_DISPLAY_NAMES: Record<string, string> = {
  0: 'None',
  1: 'YYYY',
  2: 'YYYY/MM',
  3: 'YYYY/MM/DD'
};

const makeDropdownItem = (value: string, displayName?: string, subText?: string) => {
  return {
    key: value,
    value: value,
    displayName: displayName ?? value,
    subText: subText ?? ''
  };
};

export const AthenaPlugin: Plugin = {
  id: 'athena',
  name: 'Amazon Athena',
  moduleName: 'AthenaPlugin',
  modulePath: 'plugins/athena/AthenaPlugin',
  iconLocation: 'https://superblocks.s3.us-west-2.amazonaws.com/img/integrations/athena.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/aws-athena',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'AthenaDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Region',
            name: 'connection.awsConfig.region',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'us-east-1',
            rules: [{ required: true, message: 'Region is required' }]
          },
          {
            label: 'Access key ID',
            name: 'connection.awsConfig.auth.accessKeyId',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'Access key ID is required' }]
          },
          {
            label: 'Secret key',
            name: 'connection.awsConfig.auth.secretKey',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'Secret key is required' }]
          },
          {
            label: 'Database name',
            name: 'connection.databaseName',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'default',
            rules: [{ required: true, message: 'Database name is required' }]
          },
          {
            label: 'IAM role ARN (optional)',
            name: 'connection.awsConfig.auth.iamRoleArn',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'arn:aws:iam::123456789012:role/myRole'
          },
          {
            label: 'Workgroup name (optional)',
            name: 'connection.workgroupName',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'myWorkgroup'
          },
          {
            label: 'Set S3 output location',
            name: 'connection.overrideS3OutputLocation',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.CHECKBOX,
            initialValue: false
          },
          {
            label: 'S3 output location',
            name: 'connection.s3OutputLocation',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 's3://myBucket/outputs/',
            rules: [{ required: true, message: 'S3 output location is required' }],
            display: {
              show: {
                'connection.overrideS3OutputLocation': ['true']
              }
            }
          },
          {
            label: 'S3 output location suffix',
            name: 'connection.s3OutputLocationSuffix',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 0,
            options: Object.entries(ATHENA_S3_SUFFIXES_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            ),
            tooltip: {
              markdownText:
                'A UTC timestamp with this format will be appended to the given S3 output location override. e.g. s3://myBucket/outputs/ -> s3://myBucket/outputs/2022/03/09/'
            },
            display: {
              show: {
                'connection.overrideS3OutputLocation': ['true']
              }
            }
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'runSql.sqlBody',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'runSql.useParameterized',
            startVersion: AthenaPluginVersions.V1,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
