import { PageDSL8, WidgetProps8 } from "@superblocksteam/shared";

/***
 * PLEASE READ THIS BEFORE MAKING CHANGES
 * 2. Never update a migration. Instead, create a new one.
 ***/

const populateCanvasAlignment = (page: PageDSL8) => {
  const updateCanvasWidgets = (children: WidgetProps8[]) => {
    children.forEach((widget) => {
      // ensure alignment and distribution is populated for any stack
      if (widget.type === "CANVAS_WIDGET") {
        // only run for stacks
        if (widget.layout === "VSTACK") {
          if (widget.alignment === undefined) {
            widget.alignment = "STRETCH";
          }
          if (widget.distribution === undefined) {
            widget.distribution = "TOP";
          }
        }
      }

      if (widget.children) {
        updateCanvasWidgets(widget.children);
      }
    });
  };

  updateCanvasWidgets(page.children ?? []);
  return page;
};

export default populateCanvasAlignment;
