import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  overflow: hidden;
`;

const Usher = styled.div<{ transitionDurationMs: number }>`
  width: 0;
  transition: margin-left ease-in-out ${(props) => props.transitionDurationMs}ms;
`;

const SlideWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

interface SlideProps {
  isSelected: boolean;
  children?: React.ReactNode;
}

const Slide = ({ children, isSelected }: SlideProps) => {
  // we want to force the children to be re-rendered every time the slide gets selected
  // we do that by modifying the key every time the slide gets selected
  //to accomplish that, we track the number of times the slide has been selected and use that as the key
  const [selectedCount, setSelectedCount] = useState(0);

  // obtain an imperative handle to the DOM element so that we can explicitly control focus
  const eltRef = useRef<HTMLDivElement | null>(null);
  useRef(null);

  useEffect(() => {
    if (isSelected) {
      // the slide has been just selected, let's bump the counter
      setSelectedCount((count) => count + 1);

      // the slide has been just selected, set the focus to it
      if (eltRef.current) {
        eltRef.current.blur();
        eltRef.current.focus();
      }
    }
  }, [isSelected]);

  return (
    <SlideWrapper key={selectedCount} ref={eltRef}>
      {children}
    </SlideWrapper>
  );
};

interface TransitionSlidesProps {
  style?: React.CSSProperties;
  selectedSlideIdx: number;
  transitionDurationMs: number;
  children?: React.ReactNode;
}

export const TransitionSlides = ({
  children,
  style,
  selectedSlideIdx,
  transitionDurationMs,
}: TransitionSlidesProps) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <Container style={style}>
      <Usher
        transitionDurationMs={transitionDurationMs}
        style={{ marginLeft: `-${selectedSlideIdx * 100}%` }}
      />
      {childrenArray.map((child, idx) => (
        <Slide key={idx} isSelected={selectedSlideIdx === idx}>
          {child}
        </Slide>
      ))}
    </Container>
  );
};
