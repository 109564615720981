import { Graph } from "@dagrejs/graphlib";
import { ApplicationScope } from "@superblocksteam/shared";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { DataTreeAction } from "legacy/entities/DataTree/dataTreeFactory";
import { EvaluationsState } from "legacy/reducers/evaluationReducers";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { getMergedDataTreeWithKeys } from "utils/dataTree/MergedDataTree";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { getScopeAndEntityName } from "utils/dottedPaths";
import {
  getDataTreeReferencesFromEntity,
  getGraphIdFromDataTreeEntity,
  getGraphIdFromWidgetId,
} from "./entityUtils";
import { ReferenceEdgeType, ReferenceGraph } from "./types";

export class ReferenceGraphBuilder {
  // node labels are the data tree entity
  private graph: ReferenceGraph = new Graph({
    directed: true,
    multigraph: true,
  }) as ReferenceGraph;
  private canvasWidgets: CanvasWidgetsReduxState;
  private evaluationsState: EvaluationsState;

  constructor(params: {
    canvasWidgets: CanvasWidgetsReduxState;
    evaluationsState: EvaluationsState;
  }) {
    this.canvasWidgets = params.canvasWidgets;
    this.evaluationsState = params.evaluationsState;
  }

  private populateAllNodes() {
    const dataTree = this.evaluationsState.tree;

    for (const scope in ApplicationScope) {
      const subTree = dataTree[scope as ApplicationScope];

      Object.keys(subTree).forEach((key) => {
        const nodeId = getGraphIdFromDataTreeEntity(
          scope as ApplicationScope,
          key,
          subTree[key],
        );
        if (!nodeId) {
          return;
        }
        if (!this.graph.hasNode(nodeId)) {
          this.graph.setNode(nodeId, subTree[key]);
        }
      });
    }
  }

  private addWidgetParentChildRelations(widgetId: string) {
    const widget = this.canvasWidgets[widgetId];
    if (!widget) {
      console.error(`Widget not found: ${widgetId}`);
      return;
    }
    const parent = this.canvasWidgets[widget.parentId];
    if (parent) {
      // parent node should be set by now
      const nodeId = getGraphIdFromWidgetId(this.canvasWidgets, widgetId);
      const parentId = getGraphIdFromWidgetId(
        this.canvasWidgets,
        widget.parentId,
      );
      if (!nodeId || !parentId) {
        console.error(
          `Node not found for either: ${widgetId} or ${widget.parentId}`,
        );
        return;
      }
      this.graph.setEdge(parentId, nodeId, "", ReferenceEdgeType.PARENT);
    }
    (widget.children || []).forEach((childId) => {
      this.addWidgetParentChildRelations(childId);
    });
  }

  // get all bindings from widgets to other entities.
  private addWidgetBindingRelations() {
    // NOTE: For some reason, the inverse dependency map is not complete, so we must use the entityDependencyMap even though
    // it has less information (does not tell us the keypaths of where within the entities the references are)
    const { entityDependencyMap } = this.evaluationsState.dependencies;
    // nodeId and each dependency should match the scope.name format we've been using throughout the graph
    Object.entries(entityDependencyMap).forEach(([nodeId, dependencies]) => {
      dependencies.forEach((dependency) => {
        if (!this.graph.hasNode(nodeId)) {
          console.error(`Node not found: ${nodeId}`);
          return;
        }
        if (!this.graph.hasNode(dependency)) {
          console.error(`Node not found: ${dependency}`);
          return;
        }
        if (nodeId === dependency) {
          return; // self edges aren't bad but we can remove for optimization
        }
        this.graph.setEdge(nodeId, dependency, "", ReferenceEdgeType.BINDING);
      });
    });
  }

  private isEntityApi(nodeValue: any): nodeValue is DataTreeAction {
    return (
      typeof nodeValue === "object" &&
      nodeValue !== null &&
      nodeValue.ENTITY_TYPE === ENTITY_TYPE.ACTION
    );
  }

  private addApiBindingRelations() {
    const apiNodes = this.graph
      .nodes()
      .reduce(
        (accum: Array<[nodeId: string, entity: DataTreeAction]>, nodeId) => {
          const node = this.graph.node(nodeId);
          if (this.isEntityApi(node)) {
            accum.push([nodeId, node]);
          }
          return accum;
        },
        [],
      );

    apiNodes.forEach(([apiNodeId, apiNode]) => {
      const { evaluatedDependencies = [] } = apiNode;
      evaluatedDependencies.forEach((dependency) => {
        const { scope, entityName } = getScopeAndEntityName(dependency);
        const targetNodeId = `${scope}.${entityName}`;
        if (!this.graph.hasNode(targetNodeId)) {
          console.error(`Node not found: ${dependency}`);
          return;
        }
        if (apiNodeId === targetNodeId) {
          return; // self edges aren't bad but we can remove for optimization
        }
        this.graph.setEdge(
          apiNodeId,
          targetNodeId,
          "",
          ReferenceEdgeType.BINDING,
        );
      });
    });
  }

  private async addEventHandlerRelations() {
    const dataTree = this.evaluationsState.tree;
    for (const scope in ApplicationScope) {
      const subTree = dataTree[scope as ApplicationScope];
      const { keys, localTree } = getMergedDataTreeWithKeys(
        scope as ApplicationScope,
        dataTree,
      );
      const promises = Object.keys(subTree).map(async (entityKey) => {
        const entity = subTree[entityKey];
        const sourceNodeId = getGraphIdFromDataTreeEntity(
          scope as ApplicationScope,
          entityKey,
          entity,
        );
        if (!sourceNodeId || !this.graph.hasNode(sourceNodeId)) {
          console.error(`Source node not found: ${sourceNodeId}`);
          return;
        }
        const references = await getDataTreeReferencesFromEntity(entity, {
          localTree,
          dataTree,
          identifiers: keys,
          scope: scope as ApplicationScope,
        });
        references.forEach(([targetNodeId, path]) => {
          const targetNode = this.graph.node(targetNodeId);
          if (!targetNode) {
            console.error(`Target node not found: ${targetNodeId}`);
            return;
          }
          if (sourceNodeId === targetNodeId) {
            return; // self edges aren't bad but we can remove for optimization
          }
          this.graph.setEdge(
            sourceNodeId,
            targetNodeId,
            path,
            ReferenceEdgeType.EVENT,
          );
        });
      });

      await Promise.all(promises);
    }
  }

  public async buildGraph() {
    this.populateAllNodes();

    // parent-child edges
    this.addWidgetParentChildRelations(PAGE_WIDGET_ID);
    // entity to entity edges
    this.addWidgetBindingRelations();
    // api to entity edges
    this.addApiBindingRelations();
    // extract event handler references
    await this.addEventHandlerRelations();

    return this.graph;
  }
}
