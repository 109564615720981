import { useAuth0 } from "@auth0/auth0-react";
import {
  Agent,
  AgentType,
  extractParamsWithPrefix,
} from "@superblocksteam/shared";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useFeatureFlag } from "hooks/ui";
import { getAppProfilesInCurrentMode } from "legacy/selectors/applicationSelectors";
import { getQueryParams } from "legacy/utils/Utils";
import { clearCopiedBlocksFromLocalStorage } from "pages/Editors/ApiEditor/ControlFlow/hooks/useCopyPasteBlocks";
import { selectActiveAgents } from "store/slices/agents";
import { logoutApisV3 } from "store/slices/apisShared/client-auth";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import logger from "utils/logger";

export const LogoutComponent = () => {
  const { logout } = useAuth0();

  const organization = useSelector(selectOnlyOrganization);
  const environment = useSelector(getEnvironment);

  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profiles = useSelector(getAppProfilesInCurrentMode);
  const profile = profiles?.selected;

  const agents: Agent[] = useSelector(
    selectActiveAgents(
      organization?.agentType ?? AgentType.MULTITENANT,
      enableProfiles ? (profile?.key ?? "") : environment,
    ),
  );

  const isControlFlow = useIsControlFlowEnabled();
  // Clear all agent cookies.
  useEffect(() => {
    (async () => {
      try {
        if (!organization || !agents) {
          throw new Error("no org or agent found");
        }
        clearCopiedBlocksFromLocalStorage();
        if (isControlFlow) {
          await logoutApisV3({
            orchestrator: true,
            agents,
            organization,
          });
        }
        // logout of controller agents even if control flow is ON
        // just in case the flag was turned on recently and the agent is still authed
        await logoutApisV3({
          orchestrator: false,
          agents,
          organization,
        }).catch((err) => {
          logger.warn(
            `Failed to clear agent cookies ${err} (possibly old agent version)`,
          );
        });
      } catch (err) {
        logger.info(`failed to clear cookies: ${err}`);
      } finally {
        logout({ returnTo: window.location.origin });
      }
    })();
  });

  return <></>;
};

export const LoginComponent = () => {
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect();
  return <></>;
};

export const SignupComponent = () => {
  const { loginWithRedirect } = useAuth0();
  const params = getQueryParams();

  const utmParams = extractParamsWithPrefix(params, "utm_");
  const sbTrackingParams = extractParamsWithPrefix(params, "sbt_");

  loginWithRedirect({
    screen_hint: "signup",
    login_hint: decodeURIComponent(get(params, "email") ?? ""),
    appState: {
      referrer: get(params, "referrer") ?? "",
      ...utmParams,
      ...sbTrackingParams,
    },
  });
  return <></>;
};
