import { AccessMode } from "@superblocksteam/shared";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";

export const setAccessMode = (payload: AccessMode): ReduxAction<AccessMode> => {
  return {
    type: ReduxActionTypes.SET_USER_MODE,
    payload,
  };
};
