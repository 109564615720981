import React from "react";
import { ReactComponent as ErrorIcon } from "assets/icons/common/multiply-circle-red.svg";
import { ErrorTypes } from "code-formatting/constants";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

enum FormattingStatus {
  STARTED = "started",
  FAILED = "failed",
}

const BaseStyle = styleAsClass`
  font-size: 12px;
  font-weight: 400;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  user-select: none;
  background-color: rgba(255, 255, 255, 0.9);
`;

const DefaultStyle = styleAsClass`
  color: ${colors.GREY_400};
`;

const ErrorStyle = styleAsClass`
  color: ${colors.RED_500};
`;

interface Props {
  status?: FormattingStatus;
  errorType?: ErrorTypes | string;
}

const classNameByStatus: { [key in FormattingStatus]: string } = {
  [FormattingStatus.STARTED]: `${BaseStyle} ${DefaultStyle}`,
  [FormattingStatus.FAILED]: `${BaseStyle} ${ErrorStyle}`,
};

const messageByStatus: { [key in FormattingStatus]: string } = {
  [FormattingStatus.STARTED]: "Formatting...",
  [FormattingStatus.FAILED]: "Syntax error",
};

const iconByStatus: Partial<{ [key in FormattingStatus]: React.ReactElement }> =
  {
    [FormattingStatus.FAILED]: <ErrorIcon />,
  };

const specificErrors: Record<
  ErrorTypes,
  {
    message: string;
    className: string;
    icon: React.ReactNode;
  }
> = {};

const createIndicator = (props: Props) => {
  if (!props.status) {
    throw new Error("Status is required to create indicator");
  }

  if (
    props.status === FormattingStatus.FAILED &&
    props.errorType &&
    (specificErrors as Record<string, (typeof specificErrors)[ErrorTypes]>)[
      props.errorType
    ]
  ) {
    return (
      specificErrors as Record<string, (typeof specificErrors)[ErrorTypes]>
    )[props.errorType];
  }

  return {
    message: messageByStatus[props.status],
    icon: iconByStatus[props.status],
    className: classNameByStatus[props.status],
  };
};

export default function FormattingStatusIndicator({
  status,
  errorType,
}: Props) {
  if (status === undefined) {
    return null;
  }

  const { icon, className, message } = createIndicator({ status, errorType });

  return (
    <div className={className} data-test="code-formatting-status">
      {icon} <span>{message}</span>
    </div>
  );
}
