import WebSocket from 'isomorphic-ws';
import { ISocket, createISocketClient } from './socket';
import { GenericMiddleware, ISocketClient, MethodHandlers, RequestContextBase, SocketTimeouts } from './types';
import { connectWebSocket } from './util';

// a subclass of ISocket that sends an auth token on the first request
// this is useful for client-side sockets that need to authenticate
// TODO(george): if we start using this for long-lived connections, we should add a way to refresh the token
export class ISocketWithClientAuth<ImplementedMethods, CallableMethods, RequestContext extends RequestContextBase> extends ISocket<
  ImplementedMethods,
  CallableMethods,
  RequestContext
> {
  private readonly authorization?: string;
  private hasSentAuth = false;

  constructor(
    ws: WebSocket,
    authorization: string | undefined,
    requestHandlers: MethodHandlers<ImplementedMethods, CallableMethods, RequestContext>,
    globalMiddlewares: GenericMiddleware<CallableMethods, RequestContext>[],
    timeouts?: SocketTimeouts
  ) {
    super(ws, requestHandlers, globalMiddlewares, timeouts);
    this.authorization = authorization;
  }

  // override `request` from the base class to send `authorization` when appropriate
  async request<Params, Result>(method: string, params: Params): Promise<Result> {
    // only send `authorization` on the first request
    const authorization = this.hasSentAuth ? undefined : this.authorization;
    const result = await super.request<Params, Result>(method, params, authorization);
    this.hasSentAuth = true;
    return result;
  }
}

export async function connectISocket<CallableMethods, ImplementedMethods, RequestContext extends RequestContextBase = RequestContextBase>(
  wsUrl: string,
  authorization: string | undefined,
  requestHandlers: MethodHandlers<ImplementedMethods, CallableMethods, RequestContext>,
  globalMiddlewares: GenericMiddleware<CallableMethods, RequestContext>[],
  timeouts?: SocketTimeouts
): Promise<ISocketClient<CallableMethods>> {
  const ws = await connectWebSocket(wsUrl);
  const isocket = new ISocketWithClientAuth(ws, authorization, requestHandlers, globalMiddlewares, timeouts);
  return createISocketClient(isocket);
}
