import {
  ApiReservedQueryParams,
  Profile,
  WorkflowExecutionParamsKey,
} from "@superblocksteam/shared";
import { InputRef } from "antd";
import { isEmpty } from "lodash";
import { fastClone } from "utils/clone";

import { WORKFLOW_EXECUTE_URL } from "../../../../../legacy/constants/routes";

export const buildFullWorkflowUrl = ({
  executeUrl,
  apiId,
  environment,
  bearer,
  test = true,
  customQuery = {},
  profile,
}: {
  executeUrl: { orchestratorUrl: string } | { agentUrl: string };
  apiId: string;
  environment?: string;
  bearer?: string;
  test?: boolean;
  customQuery?: Record<string, unknown>;
  profile?: Profile | { key: string };
}) => {
  const baseUrl = WORKFLOW_EXECUTE_URL(
    "agentUrl" in executeUrl
      ? { agentUrl: executeUrl.agentUrl, apiId }
      : { orchestratorUrl: executeUrl.orchestratorUrl, apiId },
  );
  const fullQuery: Record<string, unknown> = fastClone(customQuery);
  bearer && (fullQuery[ApiReservedQueryParams.AUTH] = bearer);
  test && (fullQuery[ApiReservedQueryParams.TEST] = "true");
  environment && (fullQuery[ApiReservedQueryParams.ENVIRONMENT] = environment);
  profile && (fullQuery[ApiReservedQueryParams.PROFILE] = profile?.key);

  const queryString = Object.entries(fullQuery)
    .map(([k, v]) => [k, String(v)])
    .filter(([k, v]) => !isEmpty(v))
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .reduce(
      (state, value) => (state === "" ? `?${value}` : `${state}&${value}`),
      "",
    );
  return `${baseUrl}${queryString}`;
};

export interface VariableDataField {
  id: string;
  name: string;
  value: string;
  ref: React.RefObject<InputRef>;
}

export const tableDataToParams = (
  tableData: VariableDataField[],
  dataFieldKey: WorkflowExecutionParamsKey,
) => {
  return tableData.reduce(
    (acc, current) => ({
      key: acc.key,
      value: {
        ...acc.value,
        [current.name]: current.value,
      },
    }),
    { key: dataFieldKey, value: {} as Record<string, string> },
  );
};
