import { useCallback } from "react";
import { updatePropertyPaneWidthAction } from "legacy/actions/propertyPaneActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { PropertyPaneLayoutConfig } from "legacy/constants/WidgetConstants";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { useAppDispatch } from "store/helpers";

export const useResetPropertyPaneWidth = () => {
  const dispatch = useAppDispatch();

  const onClose = useCallback(
    ({ shouldClose = true }: { shouldClose?: boolean } = {}) => {
      // Reset back to a reasonable size on close if the user has resized the property
      // to something larger than PropertyPaneLayoutConfig.resetWidth
      // so they don't have a huge propety pane when opening it for a different
      // component
      const storedWidth = sessionStorage.getInt(
        SessionStorageKey.PROP_PANE_WIDTH,
      );
      if (
        Number.isNaN(Number(storedWidth)) ||
        Number(storedWidth) > PropertyPaneLayoutConfig.resetWidth
      ) {
        sessionStorage.setItem(
          SessionStorageKey.PROP_PANE_WIDTH,
          String(PropertyPaneLayoutConfig.resetWidth),
        );
        dispatch(
          updatePropertyPaneWidthAction(PropertyPaneLayoutConfig.resetWidth),
        );
      }
      if (shouldClose) {
        dispatch({ type: ReduxActionTypes.HIDE_PROPERTY_PANE });
      }
    },
    [dispatch],
  );

  return onClose;
};
