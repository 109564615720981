import { SyntaxType } from "./constants";

export const getSyntaxForPlugin = (pluginId?: string) => {
  switch (pluginId) {
    case "postgres":
      return SyntaxType.POSTGRESQL;
    case "mysql":
      return SyntaxType.MYSQL;
    case "mssql":
      return SyntaxType.MSSQL;
    case "mariadb":
      return SyntaxType.MARIADB;
    case "snowflake":
      return SyntaxType.SNOWFLAKE;
    case "cockroachdb":
      return SyntaxType.COCKROACHDB;
    case "rockset":
      return SyntaxType.ROCKSET;
    case "redshift":
      return SyntaxType.REDSHIFT;
    case "bigquery":
      return SyntaxType.BIGQUERY;
    case "dynamodb":
      return SyntaxType.DYNAMODB;
    case "mongodb":
      return SyntaxType.MONGODB;
    // Use JSON syntax here, because the body of these
    // plugins are json
    case "restapi":
      return SyntaxType.JSON;
    case "graphql":
      return SyntaxType.JSON;
    case "oracledb":
      return SyntaxType.ORACLEDB;
    default:
      return SyntaxType.UNKNOWN;
  }
};

export const getPluginIdForSyntax = (syntax: SyntaxType): string => {
  switch (syntax) {
    case SyntaxType.POSTGRESQL:
      return "postgres";
    case SyntaxType.MYSQL:
      return "mysql";
    case SyntaxType.MSSQL:
      return "mssql";
    case SyntaxType.MARIADB:
      return "mariadb";
    case SyntaxType.SNOWFLAKE:
      return "snowflake";
    case SyntaxType.COCKROACHDB:
      return "cockroachdb";
    case SyntaxType.ROCKSET:
      return "rockset";
    case SyntaxType.REDSHIFT:
      return "redshift";
    case SyntaxType.BIGQUERY:
      return "bigquery";
    case SyntaxType.DYNAMODB:
      return "dynamodb";
    case SyntaxType.MONGODB:
      return "mongodb";
    case SyntaxType.REST_API:
      return "restapi";
    case SyntaxType.GRAPHQL:
      return "graphql";
    case SyntaxType.ORACLEDB:
      return "oracledb";
    default:
      return "javascript";
  }
};
