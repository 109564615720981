import { AgentStatus, Organization } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import agentSlice, { AgentState } from "store/slices/agents/slice";
import { isProfileSupported } from "store/slices/agents/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { selectCurrentProfileForApi } from "./selectCurrentProfileForApi";

const controlFlowFlagSelector = (state: any) =>
  selectFlagById(state, Flag.SHOW_CONTROL_FLOW);

export const controlFlowOverrideFlagSelector = (state: any): boolean =>
  Boolean(selectFlagById(state, Flag.OVERRIDE_CONTROL_FLOW_ON));

const profileInfoSelector = (state: any, pathname?: string) => {
  return selectCurrentProfileForApi(
    state,
    pathname ?? window.location.pathname,
  );
};

const isControlFlowEnabledHelper = (params: {
  organization: undefined | Organization;
  profileKey: string;
  agentState: AgentState;
  overrideFlagOn: number | boolean;
  controlFlowFlagValue: number | boolean | string | Record<string, any>;
}): "ENABLED" | "DISABLED" | "UNKNOWN" => {
  const {
    organization,
    profileKey,
    agentState,
    overrideFlagOn,
    controlFlowFlagValue,
  } = params;
  if (overrideFlagOn) {
    return "ENABLED";
  }
  const isOnPremise = orgIsOnPremise(organization);
  if (isOnPremise) {
    const activeUsableAgents = Object.values(agentState.entities).filter(
      (agentWithHealth) => {
        return (
          agentWithHealth.agent.status === AgentStatus.ACTIVE &&
          isProfileSupported(agentWithHealth.agent, profileKey)
        );
      },
    );
    if (activeUsableAgents.length === 0) {
      return "UNKNOWN";
    }
    return "ENABLED";
  }
  return controlFlowFlagValue ? "ENABLED" : "DISABLED";
};

/*
 *  Control flow is ON in the following cases:
 *  - Not on-premise and feature flag is enabled
 *  - OPA and at least one active orchestrator agent
 *  - OPA and there are no active agents, but feature flag is on
 *  - The override flag is on
 */
export const selectControlFlowContextAware = createSelector(
  selectOnlyOrganization,
  agentSlice.selector,
  controlFlowFlagSelector,
  controlFlowOverrideFlagSelector,
  profileInfoSelector,
  (
    organization,
    agentState,
    controlFlowFlagValue,
    overrideFlagOn,
    profileInfo,
  ): boolean => {
    const val = isControlFlowEnabledHelper({
      organization,
      profileKey: profileInfo.profileKey,
      agentState,
      overrideFlagOn,
      controlFlowFlagValue,
    });
    if (val === "ENABLED") {
      return true;
    } else if (val === "DISABLED") {
      return false;
    } else {
      // we enter this state if there are no agents that serve this profile. We have to make an educated guess as
      // to what the "normal" state is for this profile
      const orchestratorBuckets = {
        activeDiffProfile: 0,
        inactiveSameProfile: 0,
      };
      const controllerBuckets = {
        activeDiffProfile: 0,
        inactiveSameProfile: 0,
      };
      Object.values(agentState.entities).forEach((agentWithHealth) => {
        const isActive = agentWithHealth.agent.status === AgentStatus.ACTIVE;
        const supportsProfile = isProfileSupported(
          agentWithHealth.agent,
          profileInfo.profileKey,
        );
        if (isActive && !supportsProfile) {
          orchestratorBuckets.activeDiffProfile++;
        } else if (!isActive && supportsProfile) {
          orchestratorBuckets.inactiveSameProfile++;
        }
      });
      if (
        orchestratorBuckets.inactiveSameProfile !==
        controllerBuckets.inactiveSameProfile
      ) {
        return (
          orchestratorBuckets.inactiveSameProfile >
          controllerBuckets.inactiveSameProfile
        );
      } else if (
        orchestratorBuckets.activeDiffProfile !==
        controllerBuckets.activeDiffProfile
      ) {
        return (
          orchestratorBuckets.activeDiffProfile >
          controllerBuckets.activeDiffProfile
        );
      }
      return Boolean(controlFlowFlagValue);
    }
  },
);

// control flow flag value is determined first by
// 1. the session flag value (if OPA)
// 2. the selectControlFlowContextAware value
export const selectControlFlowEnabledDynamic = createSelector(
  selectControlFlowContextAware,
  selectOnlyOrganization,
  (isEnabled, organization): boolean => {
    const isOpa = organization && orgIsOnPremise(organization);
    const sessionFlagValue = sessionStorage.getItem(
      SessionStorageKey.CONTROL_FLOW_PROFILE_BASED_OVERRIDE,
    );
    if (!sessionFlagValue || !isOpa) {
      return isEnabled;
    }
    try {
      const parsed = JSON.parse(sessionFlagValue ?? "");
      if (typeof parsed === "boolean") {
        return parsed;
      }
    } catch (e) {
      // do nothing
    }
    return isEnabled;
  },
);

export const selectControlFlowEnabledAllEnvironments = createSelector(
  selectOnlyOrganization,
  agentSlice.selector,
  controlFlowFlagSelector,
  controlFlowOverrideFlagSelector,
  (organization, agentState, controlFlowFlagValue, overrideFlagOn): boolean => {
    if (!organization) {
      return false;
    }
    const profiles = organization.profiles ?? [];
    return profiles.every(
      (profile) =>
        isControlFlowEnabledHelper({
          organization,
          profileKey: profile.key,
          agentState,
          overrideFlagOn,
          controlFlowFlagValue,
        }) !== "DISABLED",
    );
  },
);

export const selectDoesControlFlowDependOnProfile = createSelector(
  selectOnlyOrganization,
  agentSlice.selector,
  controlFlowFlagSelector,
  controlFlowOverrideFlagSelector,
  (organization, agentState, controlFlowFlagValue, overrideFlagOn): boolean => {
    if (!organization) {
      return false;
    }
    const profiles = organization.profiles ?? [];
    let enabledProfileExists = false;
    let disabledProfileExists = false;
    profiles.forEach((profile) => {
      const enabledValue = isControlFlowEnabledHelper({
        organization,
        profileKey: profile.key,
        agentState,
        overrideFlagOn,
        controlFlowFlagValue,
      });
      if (enabledValue === "ENABLED") {
        enabledProfileExists = true;
      } else if (enabledValue === "DISABLED") {
        disabledProfileExists = true;
      }
    });
    return enabledProfileExists && disabledProfileExists;
  },
);
