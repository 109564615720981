import { DataTreeEntity } from "legacy/entities/DataTree/dataTreeFactory";
import { EvaluationsState } from "legacy/reducers/evaluationReducers";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { ApiV2State } from "store/slices/apisV2/slice";
import { ReferenceGraphBuilder } from "./ReferenceGraphBuilder";
import {
  ReferenceEdgeType,
  TypedGraph,
  UsageEdgeType,
  UsageNodeState,
} from "./types";
import { UsageGraphBuilder } from "./usage/UsageGraphBuilder";

export const computeDeps = async (params: {
  evaluationsState: EvaluationsState;
  canvasWidgets: CanvasWidgetsReduxState;
  apis: ApiV2State["entities"];
}) => {
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.COMPUTE_UNUSED_REFERENCES,
  );

  const referenceGraphBuilder = new ReferenceGraphBuilder({
    canvasWidgets: params.canvasWidgets,
    evaluationsState: params.evaluationsState,
  });

  const referenceGraph = await referenceGraphBuilder.buildGraph();

  const usageGraphBuilder = new UsageGraphBuilder({
    canvasWidgets: params.canvasWidgets,
    evaluationsState: params.evaluationsState,
    referenceGraph,
    apis: params.apis,
  });

  const usageGraph = await usageGraphBuilder.buildGraph();

  const usedEntities = new Set<string>();
  const unusedEntities = new Set<string>();
  usageGraph.nodes().forEach((nodeId) => {
    const node = usageGraph.node(nodeId);
    if (node) {
      if (node.isUsed) {
        usedEntities.add(nodeId);
      } else {
        unusedEntities.add(nodeId);
      }
    }
  });

  // optimization: remove the data tree entities from the graph nodes for easier serialization
  const prunedReferenceGraph = referenceGraph as TypedGraph<
    undefined | DataTreeEntity,
    ReferenceEdgeType,
    string | undefined
  >;
  prunedReferenceGraph.nodes().forEach((nodeId) => {
    prunedReferenceGraph.setNode(nodeId, undefined);
  });

  const prunedUsageGraph = usageGraph as TypedGraph<
    Omit<UsageNodeState, "entity">,
    UsageEdgeType,
    undefined
  >;
  prunedUsageGraph.nodes().forEach((nodeId) => {
    const node = prunedUsageGraph.node(nodeId);
    if (node && "entity" in node) {
      delete node.entity;
    }
  });

  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.COMPUTE_UNUSED_REFERENCES,
    {
      numIterations: usageGraphBuilder.getNumTotalIterations(),
    },
  );
  return {
    referenceGraph: prunedReferenceGraph,
    usageGraph: prunedUsageGraph,
    usedEntities,
    unusedEntities,
  };
};
