import { doesEntityHaveAnyStepDefinitions } from "analysis/entityUtils";
import { ReferenceEdgeType, UsageEdgeType } from "analysis/types";
import { isCustomEvent } from "legacy/workers/evaluationUtils";
import { UsageContext } from "./UsageContext";
import { EdgeInfo } from "./types";

/**
 *  Custom events are considered used if all of the following are met:
 *  - They are triggered in any event handler of a used entity, and it has at least 1 step
 *
 *  Usage calculation is similar to timers, except that custom events should not be referenced as bindings, and the event
 *  needs an explicit trigger (vs timers which could start on page load)
 */
export const calculateCustomEventUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;
  const customEventEntity = referenceGraph.node(nodeId);
  if (!customEventEntity || !isCustomEvent(customEventEntity)) {
    throw new Error(`Expected entity to be custom event`);
  }

  const usageEdges: EdgeInfo[] = [];
  const eventPotentiallyDoesSomething =
    doesEntityHaveAnyStepDefinitions(customEventEntity);

  if (!eventPotentiallyDoesSomething) {
    return [];
  }

  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);

  for (const predecessorId of usedPredecessors) {
    const hasEventEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.EVENT,
    );
    if (hasEventEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.ACTIVATION,
      });
    }
  }

  return usageEdges;
};
