import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { INTERCOM_LAUNCHER_CLASSNAME } from "./constants";

const LinkButton = styled(Button)`
  padding: 0px;
  color: ${(props) => props.theme.colors.ACCENT_PURPLE};
  background-color: unset;

  :hover,
  :focus {
    color: ${(props) => props.theme.colors.ACCENT_PURPLE};
    background-color: unset;
  }
`;

export const IntercomPopoutLink = ({ helpText }: { helpText: string }) => {
  return (
    <LinkButton type="link" className={INTERCOM_LAUNCHER_CLASSNAME}>
      {helpText}
    </LinkButton>
  );
};
