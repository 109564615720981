import { takeLatest } from "redux-saga/effects";
import {
  processAiActions,
  processAiProperties,
  updateAiDynamicProperties,
} from "../slice";
import { processAiActionsSaga } from "./processAiActions";
import { processAiPropertiesSaga } from "./processAiProperties";
import { updateAiDynamicPropertiesSaga } from "./updateDynamicProperties";

export function* aiSaga() {
  yield takeLatest(processAiActions.type, processAiActionsSaga);
  yield takeLatest(
    updateAiDynamicProperties.type,
    updateAiDynamicPropertiesSaga,
  );
  yield takeLatest(processAiProperties.type, processAiPropertiesSaga);
}
