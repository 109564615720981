import { Dimension } from "@superblocksteam/shared";
import { WidgetHeightModes } from "legacy/constants/WidgetConstants";

type DynamicChange =
  | { type: "visibility"; value: boolean }
  | { type: "height"; value: number };

export type DynamicChanges = Record<string, DynamicChange>;

export const dynamicHeight = (height: number): DynamicChange => ({
  type: "height",
  value: height,
});

export const dynamicVisibility = (visible: boolean): DynamicChange => ({
  type: "visibility",
  value: visible,
});

type YInfo = {
  top: number;
  height: number;
};

export type YInfos = Record<string, YInfo>;

export type ReflowResult = {
  changes: Record<
    string,
    {
      top: Dimension<"gridUnit">;
      height: Dimension<WidgetHeightModes>;
    }
  >;
};
