import { isEqual } from "lodash";
import { all } from "redux-saga/effects";
import { select, put } from "redux-saga/effects";
import {
  deleteApiInfo,
  updateApiInfo,
  updatePartialApiInfo,
} from "legacy/actions/apiActions";
import { updatePartialLayout } from "legacy/actions/pageActions";
import { deleteEntityFromWidgets } from "legacy/actions/widgetActions";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getMainContainer } from "legacy/selectors/entitiesSelector";
import { queued } from "store/utils/effects";
import { fastClone } from "utils/clone";
import omit from "utils/omit";

/*
 * The API info lives within the application DSL and not the API itself.
 */
function* updatePartialApiInfoSaga(
  action: ReturnType<typeof updatePartialApiInfo>,
) {
  const mainContainer: ReturnType<typeof getMainContainer> =
    yield select(getMainContainer);
  if (!mainContainer) return;
  const apiMap = fastClone(mainContainer?.apis?.apiMap || {});
  const newInfo = {
    ...apiMap[action.payload.id],
    ...action.payload.data,
    id: action.payload.id,
  };

  if (isEqual(newInfo, apiMap[action.payload.id])) return;

  apiMap[action.payload.id] = newInfo;
  const updatedWidgetsPartial = {
    [PAGE_WIDGET_ID]: {
      ...mainContainer,
      apis: {
        ...mainContainer.apis,
        apiMap,
      },
    } as typeof mainContainer,
  };
  yield put(updatePartialLayout(updatedWidgetsPartial));
}

function* updateApiInfoSaga(action: ReturnType<typeof updateApiInfo>) {
  const mainContainer: ReturnType<typeof getMainContainer> =
    yield select(getMainContainer);
  if (!mainContainer) return;

  const updatedWidgetsPartial = {
    [PAGE_WIDGET_ID]: {
      ...mainContainer,
      apis: {
        ...mainContainer.apis,
        apiMap: action.payload,
      },
    } as typeof mainContainer,
  };
  yield put(updatePartialLayout(updatedWidgetsPartial));
}

function* deleteApiInfoSaga(action: ReturnType<typeof deleteApiInfo>) {
  const mainContainer: ReturnType<typeof getMainContainer> =
    yield select(getMainContainer);
  if (!mainContainer) return;
  const updatedWidgetsPartial = {
    [PAGE_WIDGET_ID]: {
      ...mainContainer,
      apis: {
        ...mainContainer.apis,
        apiMap: omit(mainContainer.apis.apiMap, action.payload.id),
      },
    },
  };
  yield put(updatePartialLayout(updatedWidgetsPartial));
  yield put(deleteEntityFromWidgets(action.payload.name));
}

export default function* apiInfoSagas() {
  yield all([
    queued(updatePartialApiInfo.type, updatePartialApiInfoSaga),
    queued(updateApiInfo.type, updateApiInfoSaga),
    queued(deleteApiInfo.type, deleteApiInfoSaga),
  ]);
}
