import { Select } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";

export const SelectWrapper = styled.div`
  margin-top: 8px;

  &&& .ant-select-selector {
    height: 100%;
  }
  &&& .ant-select-selection-item,
  &&& .ant-select-selection-placeholder {
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
  }
  &&& .ant-select-selection-item {
    font-weight: normal;
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const SelectOptionWrapper = styled.div`
  &&& .ant-select-item-option-content {
    font-size: 16px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const StyledSelect = styled(Select)`
  display: block;
  height: 44px;
  border: 1px solid ${(props) => props.theme.colors.GREY_300};
  border-radius: 6px;
`;

interface RoundedDropDownOption {
  value: string;
  display: string;
}

interface RoundedDropDownProps<Option extends RoundedDropDownOption> {
  placeholder?: string;
  options: Option[];
  value?: string;
  onChange?: (value: string) => void;
}

export function RoundedDropDown<Option extends RoundedDropDownOption>({
  placeholder,
  options,
  value,
  onChange,
}: RoundedDropDownProps<Option>) {
  const dropdownRenderer = useCallback(
    (node: JSX.Element) => <SelectOptionWrapper>{node}</SelectOptionWrapper>,
    [],
  );

  return (
    <SelectWrapper>
      <StyledSelect
        autoFocus
        placeholder={placeholder}
        // if the value is the empty string, use undefined instead because it prevents the placeholder from showing
        value={value || undefined}
        onChange={onChange as any}
        bordered={false}
        dropdownRender={dropdownRenderer}
      >
        {options.map((option, idx) => (
          <Select.Option key={idx} value={option.value} title="">
            {option.display}
          </Select.Option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
}
