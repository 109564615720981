import _ from "lodash";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import { ERROR_500 } from "../constants/messages";

export const convertToString = (value: any): string => {
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isObject(value)) {
    return JSON.stringify(value, null, 2);
  }
  if (_.isString(value)) return value;
  return value.toString();
};

export function getQueryParams() {
  return convertQueryStringToParams(window.location.search);
}

export function convertObjectToQueryParams(object: any): string {
  if (!_.isNil(object)) {
    const paramArray: string[] = _.map(_.keys(object), (key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(object[key]);
    });
    return "?" + _.join(paramArray, "&");
  } else {
    return "";
  }
}

export function convertQueryStringToParams(queryString: string) {
  const queryStringOnly = queryString.split("?")[1];
  return Object.fromEntries(new URLSearchParams(queryStringOnly).entries());
}

export function concatenateQueryParams(
  originalQueryParams: string,
  newQueryParams: string,
): string {
  const currentSearch = new URLSearchParams(originalQueryParams);
  const newSearch = new URLSearchParams(newQueryParams);
  for (const [key, value] of newSearch.entries()) {
    currentSearch.set(key, value);
  }

  return currentSearch.toString();
}

export async function retryPromise<T>(
  resolver: () => Promise<T>,
  signal?: AbortSignal,
  retries = 5,
  interval = 1000,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const returnValue = (result: T) => !signal?.aborted && resolve(result);

    const retryRequest = () => {
      setTimeout(() => {
        if (signal?.aborted || retries <= 0) {
          return reject({
            code: ERROR_CODES.SERVER_ERROR,
            message: ERROR_500,
            show: false,
          });
        }

        retryPromise(resolver, signal, retries - 1, interval).then(
          resolve,
          reject,
        );
      }, interval);
    };

    resolver().then(returnValue).catch(retryRequest);
  });
}

export const chooseRandom = <T>(...array: Array<T>): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const evenlyDivideReduction = (
  value: number,
  itemsToReduce: number[],
  increment: number,
  minimumPerItem: number,
): number[] => {
  let remainder = value;
  const itemsToReduceRemainders = [...itemsToReduce];
  const result = new Array(itemsToReduceRemainders.length).fill(0);

  if (increment > value) {
    throw new Error("Value not high enough to divide by columns");
  }

  let index = 0;
  while (remainder > 0) {
    if (!result[index]) result[index] = 0;

    if (
      itemsToReduceRemainders[index] <= minimumPerItem &&
      remainder > 0 &&
      itemsToReduceRemainders.every((item) => item <= minimumPerItem)
    ) {
      throw new Error(
        `Cannot evenly divide ${value} by ${itemsToReduce} with increments of ${increment}`,
      );
    }

    if (itemsToReduceRemainders[index] > minimumPerItem) {
      result[index] = result[index] + increment;
      itemsToReduceRemainders[index] =
        itemsToReduceRemainders[index] - increment;
      remainder = remainder - increment;
    }

    if (remainder < 0) {
      throw new Error(
        `Cannot evenly divide ${value} by ${itemsToReduce} with increments of ${increment}`,
      );
    }

    if (remainder === 0) {
      break;
    }

    if (index === itemsToReduceRemainders.length - 1) {
      index = 0;
    } else {
      index++;
    }
  }

  return result;
};

export const evenlyDivide = (
  value: number,
  itemsToDivideBy: number,
  increment: number,
): number[] => {
  const result = new Array(itemsToDivideBy).fill(0);
  let remainder = value;
  let index = 0;

  while (remainder > 0) {
    result[index] = result[index] + increment;
    remainder = remainder - increment;
    if (index === itemsToDivideBy - 1) {
      index = 0;
    } else {
      index++;
    }
  }

  return result;
};
