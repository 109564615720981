import {
  ApiKeyScope,
  OrganizationTokenDto,
  OrganizationUserDto,
} from "@superblocksteam/shared";
import moment from "moment";

export const formatDate = (date: Date) => moment(date).format("MMMM D, YYYY");
export type AccessToken = {
  id: string;
  name: string;
  scope: ApiKeyScope;
  creator: OrganizationUserDto;
  lastUsed: Date;
  expiresAt: Date;
  key: string;
};

export const convertOrgToken = (
  orgToken: OrganizationTokenDto,
): AccessToken => {
  return {
    id: orgToken.id,
    name: orgToken.name,
    scope: orgToken.scope,
    creator: orgToken.creator as OrganizationUserDto,
    lastUsed: orgToken.lastUsed as Date,
    expiresAt: orgToken.expiresAt as Date,
    key: orgToken.key as string,
  };
};

export const convertScopeToType = (scope: ApiKeyScope): string => {
  switch (scope) {
    case ApiKeyScope.ORG_ADMIN:
      return "Org Admin";
    case ApiKeyScope.ORG_SESSION_MANAGEMENT:
      return "Embed";
    case ApiKeyScope.ORG_AGENT:
      return "Agent";
    default:
      return "Unknown";
  }
};
