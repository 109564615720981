import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  SearchableListPopup,
  SearchableListProps,
} from "./SearchableListPopup";

const ListWrapperStyles = styleAsClass`
  box-shadow: none !important;
  width: 100% !important;
  .searchable-list-input {
    border: 1px solid ${colors.GREY_100};
    border-radius: 4px;
    .ant-input-affix-wrapper {
      padding: 5px 7px;
    }
    input::placeholder {
      color: ${colors.GREY_300};
    }
  }
`;

export const SearchableList = (props: SearchableListProps) => {
  return (
    <SearchableListPopup {...props} wrapperClassName={ListWrapperStyles} />
  );
};
