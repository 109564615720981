import ReactDiffViewer, {
  DiffMethod,
} from "@superblocksteam/react-diff-viewer";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as DiffIcon } from "assets/icons/common/diff.svg";
import { ReactComponent as EditIcon } from "assets/icons/common/edit.svg";
import { ReactComponent as PasteIcon } from "assets/icons/common/paste-arrow.svg";
import { SyntaxType } from "code-formatting/constants";
import HighlightedCode from "legacy/components/editorComponents/HighlightedCode";
import { colors } from "styles/colors";
import { Footer, PasteButton, ResponseEditorWrapper, Row } from "../Shared";
import { getHighlightSyntaxForSyntax } from "./util";

const SectionWrapper = styled(Row)`
  border-bottom: 1px solid ${(props) => props.theme.colors.GREY_100};
`;

const DIFF_VIEWER_STYLE_OVERRIDES = {
  marker: {
    width: "18px",
  },
  content: {
    paddingRight: "8px",
  },
  lineNumber: {
    fontSize: "11px",
  },
  gutter: {
    minWidth: "32px",
    maxWidth: "32px",
    width: "32px",
    paddingRight: "10px",
  },
  wordDiff: {
    padding: "0px 2px",
    height: "16px",
  },
  codeFold: {
    fontSize: "11px",
    height: "32px",
    // for some reason, extra cells are being rendered in the folded code when it is the first
    // row in the table. This is a hack to hide those cells
    "> td:nth-child(5)": {
      display: "none",
    },
    "> td:nth-child(6)": {
      display: "none",
    },
  },
};

const StyleOverrides = styled.div`
  height: 100%;
  width: 100%;
  min-height: 32px;
  .language-python,
  .language-sql,
  .language-javascript {
    .token.entity,
    .token.url,
    .token.symbol,
    .token.number,
    .token.boolean,
    .token.variable,
    .token.constant,
    .token.property,
    .token.regex,
    .token.inserted {
      color: #8250df;
    }

    .token.keyword,
    .token.property,
    .token.selector,
    .token.constant,
    .token.symbol,
    .token.builtin {
      color: #0a74fa;
    }
  }
  table {
    colgroup {
      col:first-child {
        // first gutter
        width: 32px;
      }
      col:nth-child(2) {
        // second gutter
        width: 32px;
      }
      col:nth-child(3) {
        // diff markers
        width: 32px;
      }
    }
    tbody {
      font-family: var(--font-monospace);
      font-size: 11px;
      tr {
        td {
          pre {
            line-height: 18px;
            padding-top: 2px;
            code[class*="language-"] {
              padding: 0px;
              background: none;
            }
          }
        }
      }
    }
  }
`;

export const DiffViewer = ({
  syntax,
  oldCode,
  newCode,
  firstLineNumber,
  responseEditor,
  isLoading,
  onConfirm,
}: {
  syntax: SyntaxType;
  oldCode: string;
  newCode: string;
  firstLineNumber: number;
  responseEditor: ReactElement;
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  const [viewMode, setViewMode] = useState<"RESPONSE" | "DIFF">("RESPONSE");
  const viewButtonRef = useRef<HTMLButtonElement>(null);
  const pasteButtonRef = useRef<HTMLButtonElement>(null);
  const diffRef = useRef<HTMLDivElement>(null);

  const moveFocus = useCallback((e: any) => {
    if (
      e.key === "ArrowRight" &&
      viewButtonRef.current === document.activeElement
    ) {
      pasteButtonRef.current?.focus();
    } else if (
      e.key === "ArrowLeft" &&
      pasteButtonRef.current === document.activeElement
    ) {
      viewButtonRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    const elem = diffRef.current;
    if (!isLoading) {
      viewButtonRef.current?.focus();
      elem?.addEventListener?.("keydown", moveFocus);
    }
    return () => {
      elem?.removeEventListener?.("keydown", moveFocus);
    };
  }, [isLoading, moveFocus]);

  const language = getHighlightSyntaxForSyntax(syntax);

  const renderDiffCode = useCallback(
    (code: string) => {
      return (
        <HighlightedCode codeText={code} language={language} sqlParams={[]} />
      );
    },
    [language],
  );

  return (
    <>
      <div style={{ flex: 2 }}>
        {viewMode === "DIFF" ? (
          <SectionWrapper $gap={0} $height="100%">
            <StyleOverrides>
              <ReactDiffViewer
                oldValue={oldCode}
                newValue={newCode}
                splitView={false}
                compareMethod={DiffMethod.WORDS_WITH_SPACE}
                linesOffset={firstLineNumber}
                styles={DIFF_VIEWER_STYLE_OVERRIDES}
                renderContent={renderDiffCode}
              />
            </StyleOverrides>
          </SectionWrapper>
        ) : (
          <SectionWrapper $gap={0} $height="100%" $alignment="stretch">
            <ResponseEditorWrapper width="100%" className="drag-disabled">
              {responseEditor}
            </ResponseEditorWrapper>
          </SectionWrapper>
        )}
      </div>
      {!isLoading && (
        <Footer $gap={8} $height={32} ref={diffRef}>
          {viewMode === "DIFF" ? (
            <PasteButton
              ref={viewButtonRef}
              onClick={() => setViewMode("RESPONSE")}
              tabIndex={1}
              className="diff-viewer-button"
            >
              <EditIcon color={colors.GREY_300} />
              View code
            </PasteButton>
          ) : (
            <PasteButton
              onClick={() => setViewMode("DIFF")}
              ref={viewButtonRef}
              autoFocus
              tabIndex={1}
            >
              <DiffIcon />
              View diff
            </PasteButton>
          )}
          <PasteButton onClick={onConfirm} tabIndex={2} ref={pasteButtonRef}>
            <PasteIcon />
            Insert
          </PasteButton>
        </Footer>
      )}
    </>
  );
};
