import { createPopper, Instance } from "@popperjs/core";
import { PlaceholderInfo } from "@superblocksteam/shared";
import Prism from "prismjs";
import React, {
  useRef,
  useEffect,
  MutableRefObject,
  forwardRef,
  Ref,
} from "react";
import styled from "styled-components";
import { Skin } from "legacy/constants/DefaultTheme";
import { getTokenRanges } from "utils/tokenRanges";
import themes from "./themes";
import "prismjs/components/prism-sql.min";
import "prismjs/components/prism-python.min";

// TODO(abhinav): This is rudimentary. Enhance it.
Prism.languages["superblocks-binding"] = {
  punctuation: {
    pattern: /^{{|}}$/,
  },
  property: {
    pattern: /(\.\w+)/,
  },
};

const StyledCode = styled.div<{ skin: Skin }>`
  position: relative;
  ${themes.LIGHT};
  padding: 0 0px;
  code {
    white-space: pre-wrap !important;
    word-break: break-word !important;
  }
`;

/* When adding an entry please make sure to include it in the craco.common.config.js as well */
export enum SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES {
  JAVASCRIPT = "language-javascript",
  HTML = "language-html",
  PYTHON = "language-python",
  SQL = "language-sql",
  SUPERBLOCKS = "language-superblocks-binding", // Please note that we're using the CSS class name required by prismjs.
}

type HighlightedCodeProps = {
  codeText: string;
  sqlParams?: PlaceholderInfo[];
  language?: SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES;
  skin?: Skin;
  multiline?: boolean;
  onClick?: () => void;
  className?: string;
};
/* eslint-disable react/display-name */
const HighlightedCode = forwardRef(
  (props: HighlightedCodeProps, ref: Ref<HTMLDivElement>) => {
    const codeRef: MutableRefObject<HTMLElement | null> = useRef(null);

    // Highlight when component renders with new props.
    // Skin is irrelevant here, as it only uses css.
    // Skinning is handled in StyledCode component.
    useEffect(() => {
      const cleanupPoppers: Instance[] = [];
      const cleanupElements: HTMLElement[] = [];
      if (codeRef.current) {
        // When this is run, the code text is tokenized
        // into HTML on which the theme CSS is applied
        Prism.highlightElement(codeRef.current);
        if (props.sqlParams) {
          const paramTokens = props.sqlParams.flatMap(({ value, locations }) =>
            locations.map((loc) => ({ ...loc, value })),
          );
          const ranges = getTokenRanges(codeRef.current, paramTokens);
          // wrap each range with span element
          // this allows us to catch mouse hovers & customize styling
          for (const [idx, range] of ranges.entries()) {
            const value = paramTokens[idx].value;

            const tooltipValueWrapper = document.createElement("pre");
            tooltipValueWrapper.append(value);

            const tooltip = document.createElement("div");
            cleanupElements.push(tooltip);
            tooltip.classList.add("sql-param-value-tooltip");
            tooltip.append(tooltipValueWrapper);
            document.body.append(tooltip);

            const wrapper = document.createElement("span");
            wrapper.classList.add("sql-param-hover-target");
            wrapper.addEventListener("mouseenter", () => {
              tooltip.setAttribute("data-show", "");
              popper.update();
            });
            wrapper.addEventListener("mouseleave", () => {
              tooltip.removeAttribute("data-show");
            });
            wrapper.appendChild(range.extractContents());
            range.insertNode(wrapper);

            const popper = createPopper(wrapper, tooltip, {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 5],
                  },
                },
              ],
            });
            cleanupPoppers.push(popper);
          }
        }
      }

      return () => {
        cleanupPoppers.forEach((popper) => popper.destroy());
        cleanupElements.forEach((elt) => elt.remove());
      };
    }, [props.codeText, props.sqlParams, props.language, codeRef]);

    // Set the default language to javascript if not provided.
    const language =
      props.language || SYNTAX_HIGHLIGHTING_SUPPORTED_LANGUAGES.JAVASCRIPT;

    return (
      <StyledCode
        skin={props.skin || Skin.DARK}
        onClick={props.onClick}
        ref={ref}
        className={props.className}
      >
        <code ref={codeRef} className={language}>
          {props.codeText}
        </code>
      </StyledCode>
    );
  },
);

export default HighlightedCode;
