import {
  ApplicationSignatureTreeSigned,
  CreateApplicationPagePayload,
  CreateApplicationPageResponseBody,
  GetApplicationPageResponseBody,
  PageDSL8,
} from "@superblocksteam/shared";
import { AxiosRequestConfig } from "axios";
import { SUPERBLOCKS_UI_SAVE_LAYOUT_TIMEOUT_MS } from "env";
import { PageAction } from "legacy/constants/ActionConstants";
import { getUrlWithBranch } from "utils/urlWithBranch";
import Api from "./Api";
import { ApiResponse } from "./ApiResponses";

interface SavePageRequest {
  dsl: PageDSL8;
  signature?: ApplicationSignatureTreeSigned;
  layoutId: string;
  pageId: string;
  lastSuccessfulWrite: Date;
}

type SavePageResponse = ApiResponse<{
  id: string;
  layoutOnLoadActions: PageAction[][];
  dsl: PageDSL8;
  messages: string[];
  actionUpdates: Array<{
    executeOnLoad: boolean;
    id: string;
    name: string;
  }>;
  updated: Date;
}>;

class LayoutApi extends Api {
  static url = "v2/layouts";

  static getLayoutUpdateURL = (
    pageId: string,
    layoutId: string,
    branch: string | undefined,
  ) => {
    return getUrlWithBranch(`v2/layouts/${layoutId}/pages/${pageId}`, branch);
  };

  static createPage(
    applicationId: string,
    branch: string | undefined,
    body: CreateApplicationPagePayload,
  ) {
    return Api.post<ApiResponse<CreateApplicationPageResponseBody>>(
      getUrlWithBranch(`v2/applications/${applicationId}/pages`, branch),
      body,
    );
  }

  static getPage(
    applicationId: string,
    pageId: string,
    viewMode: string,
    branch: string | undefined,
    commitId: string | undefined,
    options?: Partial<AxiosRequestConfig>,
  ) {
    return Api.get<ApiResponse<GetApplicationPageResponseBody>>(
      `${getUrlWithBranch(
        `v2/applications/${applicationId}/pages/${pageId}`,
        branch,
      )}`,
      commitId ? { viewMode, commitId } : { viewMode },
      options,
    );
  }

  static savePage(
    savePageRequest: SavePageRequest,
    superblocksSupportUpdateEnabled = false,
    branch: string | undefined,
  ) {
    const body = {
      dsl: savePageRequest.dsl,
      signature: savePageRequest.signature,
      lastSuccessfulWrite: savePageRequest.lastSuccessfulWrite,
    };

    return Api.put<SavePageResponse>(
      LayoutApi.getLayoutUpdateURL(
        savePageRequest.pageId,
        savePageRequest.layoutId,
        branch,
      ),
      body,
      superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : undefined,
      {
        timeout: SUPERBLOCKS_UI_SAVE_LAYOUT_TIMEOUT_MS,
      },
    );
  }
}

export default LayoutApi;
