import { Button } from "antd";
import React, { forwardRef, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { usePointerDownOutside } from "hooks/ui";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";
import { colors } from "styles/colors";

const StyledButton = styled(Button)`
  color: ${colors.GREY_300};
  border-color: transparent;
  background-color: unset;

  /* override antd specificity */
  && {
    padding: 2px 0px;
    box-shadow: none;
  }

  :hover,
  :focus {
    color: ${colors.GREY_500};
    background-color: ${colors.GREY_25};
    border-color: ${colors.GREY_100};
  }

  .entity-header-row &.show-on-hover {
    visibility: hidden;
  }
  .entity-header-row:hover &.show-on-hover {
    visibility: visible;
  }

  // helps with visual centering of the actual icon
  .entity-header-row &:only-child {
    margin-right: -4px;
  }

  &&.visible {
    visibility: visible;
  }
`;

type HeaderButtonProps = {
  icon: React.ReactNode;
  onClick?: () => void;
  showOnHeaderHover?: boolean;
  hideOnClickOutside?: boolean;
  outsideClickPointerSelectors?: string[];
};

const HeaderButton = forwardRef<HTMLElement, HeaderButtonProps>(
  (
    {
      onClick,
      hideOnClickOutside = false,
      showOnHeaderHover = true,
      icon: Icon,
      outsideClickPointerSelectors,
      ...rest // keeps tooltips working
    },
    ref,
  ) => {
    const [active, setActive] = useState(false);
    const isReadOnly = useSelector(getEditorReadOnly);

    const handleClickOutside = useCallback(() => {
      setActive(false);
    }, []);

    usePointerDownOutside({
      wrapperSelectors: [
        ".header-button",
        ...(outsideClickPointerSelectors ?? []),
      ],
      onClickOutside: handleClickOutside,
    });

    return (
      <StyledButton
        {...rest}
        ref={ref}
        icon={Icon}
        disabled={isReadOnly}
        size="small"
        className={`header-button ${showOnHeaderHover ? "show-on-hover" : ""} ${
          hideOnClickOutside && active ? "visible" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
          onClick?.();
        }}
      />
    );
  },
);
HeaderButton.displayName = "HeaderButton";

export const PlusButton = forwardRef<
  HTMLElement,
  Omit<HeaderButtonProps, "icon">
>((props, ref) => {
  return <HeaderButton ref={ref} icon={<PlusIcon />} {...props} />;
});
PlusButton.displayName = "PlusButton";
