import { matchPath } from "react-router";

import { APP_EMBED_PREVIEW_URL, APP_EMBED_URL } from "legacy/constants/routes";

export enum EmbedEventType {
  EMIT = "EMIT",
  TRIGGER = "TRIGGER",
}

export enum IncomingMessage {
  INITIALIZE_EMBED = "INITIALIZE_EMBED",
  PROPERTY_CHANGE = "PROPERTY_CHANGE",
  TOKEN_CHANGE = "TOKEN_CHANGE",
  TRIGGER_EVENT = "TRIGGER_EVENT",
  COLOR_SCHEME_CHANGE = "COLOR_SCHEME_CHANGE",
}

export enum OutgoingMessage {
  APP_LOADED = "APP_LOADED",
  NAVIGATION = "NAVIGATION",
  AUTH_ERROR = "AUTH_ERROR",
  INITIALIZED = "INITIALIZED",
  EMIT_EVENT = "EMIT_EVENT",
}

type MessageTypeToPayload = {
  [IncomingMessage.INITIALIZE_EMBED]: {
    properties?: Record<string, unknown>;
    token?: string;
    colorScheme?: string;
  };
  [IncomingMessage.PROPERTY_CHANGE]: {
    properties: Record<string, unknown>;
  };
  [IncomingMessage.TOKEN_CHANGE]: {
    token?: string;
  };
  [IncomingMessage.TRIGGER_EVENT]: {
    eventName: string;
    payload: Record<string, unknown>;
  };
  [IncomingMessage.COLOR_SCHEME_CHANGE]: {
    colorScheme: string;
  };
  [OutgoingMessage.APP_LOADED]: {
    appId: string;
    appName: string;
  };
  [OutgoingMessage.NAVIGATION]: {
    url: string;
    appId?: string;
  };
  [OutgoingMessage.AUTH_ERROR]: {
    error: "access_denied" | "token_expired" | "unauthorized";
    message?: string;
  };
  [OutgoingMessage.INITIALIZED]: Record<string, never>;
  [OutgoingMessage.EMIT_EVENT]: {
    eventName: string;
    payload: Record<string, unknown>;
  };
};

type Event<T extends OutgoingMessage> = {
  type: T;
  data: MessageTypeToPayload[T];
};

export const isOnEmbedPreviewRoute = () => {
  return matchPath(APP_EMBED_PREVIEW_URL, window.location.pathname) != null;
};

export const isOnEmbedRoute = () => {
  return (
    matchPath(APP_EMBED_URL, window.location.pathname) != null ||
    isOnEmbedPreviewRoute()
  );
};

export const isIFrameInEmbedRoute = () => {
  return (
    window.location.pathname.startsWith("/applications/iframe") &&
    window.location.search?.includes("embed_mode=true")
  );
};

const PARENT_REF = window !== window.parent ? window.parent : null;

export function sendEventToEmbedder<T extends OutgoingMessage>(
  event: Event<T>,
): void {
  if (isOnEmbedRoute() && PARENT_REF) {
    PARENT_REF.postMessage(event, "*");
  }
}
