import { InfoCircleOutlined } from "@ant-design/icons";
import { FolderDto } from "@superblocksteam/shared";
import { Form, Input, Modal, Space, Tooltip, Typography } from "antd";
import React, { useCallback } from "react";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { createFolder } from "store/slices/folders/client";
import logger from "utils/logger";

type CreateFolderModalProps = {
  isOpen: boolean;
  setIsOpen: () => void;
  onComplete: (folder: FolderDto) => void;
};

const CreateFolderModal = ({
  isOpen,
  setIsOpen,
  onComplete,
}: CreateFolderModalProps) => {
  const [createFolderForm] = Form.useForm();

  const handleCreateNewFolder = useCallback(() => {
    createFolderForm
      .validateFields()
      .then((values) => {
        createFolder({ name: values.name }).then((folder) =>
          onComplete(folder),
        );
        Toaster.show({
          text: "Folder created",
          variant: Variant.success,
        });
        setIsOpen();
        createFolderForm.resetFields();
      })
      .catch((info) => {
        logger.debug("Validate Failed:", info);
        Toaster.show({
          text: "Folder creation failed",
          variant: Variant.danger,
        });
      });
  }, [createFolderForm, onComplete, setIsOpen]);

  return (
    <Modal
      title={
        <Space size={6}>
          <Typography.Text>Create New Folder</Typography.Text>
          <Tooltip title="Group Applications, Workflows and Scheduled Jobs with Folders">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
      open={isOpen}
      onCancel={() => {
        createFolderForm.resetFields();
        setIsOpen();
      }}
      onOk={() => {
        handleCreateNewFolder();
      }}
      destroyOnClose
      okText="Create"
      data-test="create-folder-modal"
    >
      <Form form={createFolderForm} layout="vertical" name="create_new_form">
        <Form.Item
          name="name"
          label="Folder name"
          rules={[
            {
              required: true,
              message: "Please input a valid folder name",
            },
          ]}
        >
          <Input
            autoFocus
            placeholder="Folder name..."
            data-test="create-folder-name"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFolderModal;
