import { fastClone } from "utils/clone";

const REPLACEMENT = "Stringified Function";
/**
 * Mutates the object by removing functions from it.
 * @param {any} o - the object to remove functions from
 * @param {any} orig - the original object to check for frozen state
 * @returns the object without any functions.
 * @throws when there is recursion
 */
const removeFunctions = (o: any, orig: any) => {
  if (typeof o === "function") return REPLACEMENT;
  if (typeof o !== "object" || o === null) return o;

  const isFrozen = Object.isFrozen(orig);
  for (const k in o) {
    if (Object.prototype.hasOwnProperty.call(o, k) === false) continue;
    const kType = typeof o[k];
    if (kType === "function") {
      o[k] = REPLACEMENT;
    } else if (kType === "object") {
      o[k] = removeFunctions(o[k], orig[k]);
    }
  }
  if (isFrozen) return Object.freeze(o);
  return o;
};

const cloneAndRemoveFunctions = (o: any) => {
  const clone = fastClone(o);
  return removeFunctions(clone, o);
};

export { removeFunctions, cloneAndRemoveFunctions };
