import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { ApiV2State } from "store/slices/apisV2/slice";
import { ReferenceGraph, UsageGraph } from "../types";
import { type ContextParams } from "./types";

/**
 * Assumptions of the usage context:
 * - This is constructed exactly once to assist the building of the usage graph
 * - The reference graph should not be modified once this is constructed
 * - However, the usage graph is expected to change throughout the lifetime of the context
 */
export class UsageContext {
  public usageGraph: UsageGraph;
  public referenceGraph: ReferenceGraph;
  public canvasWidgets: CanvasWidgetsReduxState;
  public apis: ApiV2State["entities"];

  private uncheckedPredecessorsById: Record<string, Set<string>> = {};

  constructor(params: ContextParams) {
    this.usageGraph = params.usageGraph;
    this.referenceGraph = params.referenceGraph;
    this.canvasWidgets = params.canvasWidgets;
    this.apis = params.apis;
  }

  /**
   * For a given node, get all the predecessors in the reference graph that are marked as used in the usage graph
   * If lazy is set to true, this function only returns nodes at most once.
   */
  public getUsedRefPredecessors(nodeId: string, lazy: boolean) {
    if (!lazy) {
      // just return all the currently used predecessors
      return (this.referenceGraph.predecessors(nodeId) ?? []).filter(
        (predecessorId) => {
          const node = this.usageGraph.node(predecessorId);
          return node && node.isUsed;
        },
      );
    }

    this.uncheckedPredecessorsById[nodeId] ??= new Set(
      this.referenceGraph.predecessors(nodeId) ?? [],
    );

    const toReturn = [];
    for (const predecessorId of this.uncheckedPredecessorsById[nodeId]) {
      const node = this.usageGraph.node(predecessorId);
      if (node && node.isUsed) {
        toReturn.push(predecessorId);
        this.uncheckedPredecessorsById[nodeId].delete(predecessorId);
      }
    }
    return toReturn;
  }
}
