import { EditorModes } from "components/app/CodeEditor/EditorConfig";

const SUPPORTED_FORMATTERS = [
  EditorModes.PYTHON,
  EditorModes.JAVASCRIPT,
  EditorModes.SQL_WITH_BINDING,
] as const;

export type SUPPORTED_FORMATTER_TYPE = (typeof SUPPORTED_FORMATTERS)[number];

export const isSupportedFormatter = (
  mode: EditorModes,
): mode is SUPPORTED_FORMATTER_TYPE =>
  (SUPPORTED_FORMATTERS as ReadonlyArray<EditorModes>).includes(mode);

export enum ErrorTypes {}

export enum SyntaxType {
  JAVASCRIPT = "SYNTAX_JAVASCRIPT",
  PYTHON = "SYNTAX_PYTHON",
  UNKNOWN = "SYNTAX_UNSPECIFIED",
  POSTGRESQL = "SYNTAX_POSTGRESQL",
  MSSQL = "SYNTAX_MSSQL",
  MYSQL = "SYNTAX_MYSQL",
  MARIADB = "SYNTAX_MARIADB",
  SNOWFLAKE = "SYNTAX_SNOWFLAKE",
  COCKROACHDB = "SYNTAX_COCKROACHDB",
  ROCKSET = "SYNTAX_ROCKSET",
  REDSHIFT = "SYNTAX_REDSHIFT",
  BIGQUERY = "SYNTAX_BIGQUERY",
  DYNAMODB = "SYNTAX_DYNAMODB",
  MONGODB = "SYNTAX_MONGODB",
  BINDING = "SYNTAX_BINDING",
  JSON = "SYNTAX_JSON",
  HTML = "SYNTAX_HTML",
  REST_API = "SYNTAX_PLUGIN_RESTAPI",
  GRAPHQL = "SYNTAX_PLUGIN_GRAPHQL",
  ORACLEDB = "SYNTAX_ORACLEDB",
}
