import { Modal, Tooltip } from "antd";
import React, { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import { FormItem, FormWrapper } from "components/ui/Form";
import { PlainLink } from "components/ui/Link";
import {
  DefaultModalWidth,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { SearchableList } from "components/ui/SearchableList";
import {
  CustomRenderProps,
  SearchListItem,
} from "components/ui/SearchableListPopup";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { AvailablePlugin } from "./utils";

const UpgradeReminderWrapper = styleAsClass`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const UpgradeReminder = styleAsClass`
  font-size: 11px;
  line-height: 16px;
  color: ${colors.ORANGE_600};
  background-color: ${colors.ORANGE_600}14;
  padding: 0px 4px;
  border-radius: 2px;
  border: 1px solid ${colors.ORANGE_25};
`;
const NewIntegrationModal = ({
  isModalOpen,
  setIsModalOpen,
  available,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  available: AvailablePlugin[];
}) => {
  const navigate = useNavigate();
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const availableItems = useMemo(() => {
    return available
      .map((integration) => {
        return {
          label: integration.pluginName,
          value: integration.pluginId,
          customRender: ({
            focusedIndex,
            index,
            className,
            onMouseEnter,
            onClick,
            dataTest,
          }: CustomRenderProps) => {
            const disabled = !integration.isSupported;
            return (
              <SearchListItem
                className={className}
                onClick={disabled ? undefined : onClick}
                onMouseEnter={onMouseEnter}
                data-focused={focusedIndex === index}
                data-disabled={disabled}
                data-test={dataTest}
              >
                <div>
                  <img
                    className="plugin-img"
                    src={integration.iconLocation ?? ""}
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                </div>
                {integration.pluginName}
                {!integration.isSupported && (
                  <div className={UpgradeReminderWrapper}>
                    <Tooltip
                      title={
                        <span>
                          Upgrade your agent to access this Integration{" "}
                          <PlainLink
                            href={
                              "https://docs.superblocks.com/on-premise-agent/deployment"
                            }
                            target="_blank"
                            rel="noreferrer"
                            onClick={stopPropagation}
                          >
                            learn how
                          </PlainLink>
                        </span>
                      }
                    >
                      <div className={UpgradeReminder}>Upgrade required</div>
                    </Tooltip>
                  </div>
                )}
              </SearchListItem>
            );
          },
          disabled: !integration.isSupported,
          key: integration.pluginId,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [available, stopPropagation]);

  const onItemSelect = useCallback(
    (value: string) => {
      navigate({ pathname: `/integrations/${value}` });
    },
    [navigate],
  );

  const onCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const parentRef = useRef<HTMLDivElement>(null);
  const noResultsMessage = useMemo(() => {
    return (
      <span>
        No integration found. Connect using{" "}
        <PlainLink
          href="https://docs.superblocks.com/integrations/integrations-library/rest-apis"
          target="_blank"
          rel="noreferrer"
        >
          REST,{" "}
        </PlainLink>
        <PlainLink
          href="https://docs.superblocks.com/integrations/integrations-library/graphql"
          target="_blank"
          rel="noreferrer"
        >
          GraphQL,{" "}
        </PlainLink>
        or browse our{" "}
        <PlainLink
          href="https://docs.superblocks.com/integrations/integrations-library"
          target="_blank"
          rel="noreferrer"
        >
          Integrations Library{" "}
        </PlainLink>
      </span>
    );
  }, []);

  return (
    <Modal
      title="Add a new integration"
      open={isModalOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          <FormItem label="">
            <SearchableList
              items={availableItems}
              onItemSelect={onItemSelect}
              placeholder="Search integrations"
              dataTest="add-integration-searchable-list"
              noResultsMessage={noResultsMessage}
            />
          </FormItem>
        </div>
      </div>
    </Modal>
  );
};

export default NewIntegrationModal;
