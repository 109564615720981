import { createCachedSelector } from "re-reselect";
import { WidgetType } from "legacy/constants/WidgetConstants";
import WidgetConfigResponse from "legacy/mockResponses/WidgetConfigResponse";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { selectFlags } from "../../store/slices/featureFlags";
import { onCreateConfigChanged } from "./onCreateConfigChanged";
import type { WidgetTypeToPropType } from "legacy/widgets";
import type { AppState } from "store/types";

export const getWidgetBlueprint = createCachedSelector(
  [
    selectFlags,
    selectGeneratedTheme,
    (_: AppState, widgetType: keyof WidgetTypeToPropType | WidgetType) =>
      widgetType,
  ],
  (flags, theme, widgetType) =>
    WidgetConfigResponse.getCreateConfig(
      widgetType as keyof WidgetTypeToPropType,
      flags,
      theme,
    ),
)((_, widgetType) => widgetType);

onCreateConfigChanged(getWidgetBlueprint.clearCache);
