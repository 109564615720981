import { FormComponentType, Plugin, PluginResponseType, PluginType } from '../../types';

export const SuperblocksOcrPluginVersions = {
  V1: '0.0.1'
};
export enum SUPERBLOCKS_OCR_ACTION {
  FROM_FILE = 'From File',
  FROM_URL = 'From URL'
}

export const SUPERBLOCKS_OCR_ACTION_AND_DESCRIPTION = {
  [SUPERBLOCKS_OCR_ACTION.FROM_FILE]: 'Given an image or PDF file, convert to text',
  [SUPERBLOCKS_OCR_ACTION.FROM_URL]: 'Given a URL to an image or PDF file, convert to text'
};

const makeDropdownItem = (value: string, subText?: string) => ({
  key: value,
  value: value,
  displayName: value,
  subText: subText ? subText : ''
});

export const SuperblocksOcrPlugin: Plugin = {
  id: 'superblocks-ocr',
  name: 'Superblocks OCR',
  moduleName: 'SuperblocksOcrPlugin',
  modulePath: 'plugins/superblocks-ocr/SuperblocksOcrPlugin',
  iconLocation: 'https://superblocks.s3.us-west-2.amazonaws.com/img/integrations/ocr_logo.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/superblocks-ocr',
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  rawRequestName: 'Executed request',
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'action',
            startVersion: SuperblocksOcrPluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: SUPERBLOCKS_OCR_ACTION.FROM_FILE,
            rules: [{ required: true }],
            options: Object.entries(SUPERBLOCKS_OCR_ACTION_AND_DESCRIPTION).map(([key, value]) => makeDropdownItem(key, value))
          },
          {
            label: 'File',
            name: 'file',
            startVersion: SuperblocksOcrPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'File is required.' }],
            display: {
              show: {
                action: [SUPERBLOCKS_OCR_ACTION.FROM_FILE]
              }
            },
            tooltip: {
              markdownText: 'A file to be converted to text. Can take images and PDFs.'
            },
            subtitle: 'E.g. for a File Picker, it will be {{FilePicker1.files[0]}}'
          },
          {
            label: 'File URL',
            name: 'fileUrl',
            startVersion: SuperblocksOcrPluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            rules: [{ required: true, message: 'File URL is required.' }],
            display: {
              show: {
                action: [SUPERBLOCKS_OCR_ACTION.FROM_URL]
              }
            },
            tooltip: {
              markdownText: 'A URL to a file to be converted to text. Can take images and PDFs.'
            }
          }
        ]
      }
    ]
  }
};
