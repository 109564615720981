import { Padding, PageDSL7 } from '../types';
import { generatePredictableId } from '../utils/appDSL';

const defaultPadding: Padding = {
  left: {
    value: 16,
    mode: 'px'
  },
  right: {
    value: 16,
    mode: 'px'
  },
  top: {
    value: 12,
    mode: 'px'
  },
  bottom: {
    value: 12,
    mode: 'px'
  }
};

const canvasId = generatePredictableId('Canvas1');
const sectionId = generatePredictableId('Section1');

const page: PageDSL7 = {
  version: 7,
  widgetId: '0',
  type: 'PAGE_WIDGET',
  widgetName: 'Page1',
  detachFromLayout: true,
  apis: {
    apiMap: {}
  },
  stateVars: {
    stateVarMap: {}
  },
  timers: {
    timerMap: {}
  },
  canExtend: true,
  dynamicBindingPathList: [],
  gridColumns: 96,
  left: {
    value: 0,
    mode: 'gridUnit'
  },
  top: {
    value: 0,
    mode: 'gridUnit'
  },
  width: {
    value: 0,
    mode: 'gridUnit'
  },
  height: {
    value: 99,
    mode: 'gridUnit'
  },
  children: [
    {
      widgetId: sectionId,
      parentId: '0',
      widgetName: 'Section1',
      type: 'SECTION_WIDGET',
      detachFromLayout: true,
      isLoading: false,
      isVisible: true,
      canExtend: true,
      dragDisabled: true,
      shouldScrollContents: true,
      collapseWhenHidden: true,
      gridColumns: 96,
      left: {
        value: 0,
        mode: 'gridUnit'
      },
      top: {
        value: 0,
        mode: 'gridUnit'
      },
      width: {
        value: 96,
        mode: 'gridUnit'
      },
      height: {
        value: 99,
        mode: 'gridUnit'
      },
      children: [
        {
          widgetId: canvasId,
          parentId: sectionId,
          widgetName: 'Canvas1',
          type: 'CANVAS_WIDGET',
          detachFromLayout: true,
          dragDisabled: true,
          isLoading: false,
          isVisible: true,
          collapseWhenHidden: false,
          containerStyle: 'none',
          minHeight: {
            value: 1292,
            mode: 'px'
          },
          children: [],
          canExtend: true,
          dynamicBindingPathList: [],
          layout: 'FIXED_GRID',
          gridColumns: 96,
          left: {
            value: 0,
            mode: 'gridUnit'
          },
          top: {
            value: 0,
            mode: 'gridUnit'
          },
          width: {
            value: 0,
            mode: 'gridUnit'
          },
          height: {
            value: 99,
            mode: 'gridUnit'
          },
          padding: defaultPadding
        }
      ]
    }
  ]
};

export default page;
