import { EditorLanguage, FormComponentType, FormItem, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION, SQL_INITIAL_TEXT } from './constants';
import { CONNECTION_METHODS_AND_DISPLAY_NAMES, makeDropdownItem } from './shared/db';

export const RedshiftPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2',
  V6: '0.0.6',
  V7: '0.0.7'
};

const BASE_HOST = {
  label: 'Host',
  name: 'endpoint.host',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'superblocks-redshift-dev.clyn5z3du7tf.us-west-2.redshift.amazonaws.com',
  rules: [{ required: true, message: 'Host is required' }]
};

const BASE_PORT = {
  label: 'Port',
  name: 'endpoint.port',
  componentType: FormComponentType.INPUT_TEXT,
  initialValue: '5439',
  dataType: InputDataType.NUMBER
};

const BASE_SCHEMA = {
  label: 'Schema',
  name: 'authentication.custom.databaseSchema.value',
  initialValue: 'public',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_NAME = {
  label: 'Database name',
  name: 'authentication.custom.databaseName.value',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_USERNAME = {
  label: 'Database username',
  name: 'authentication.username',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_PASSWORD = {
  label: 'Database password',
  name: 'authentication.password',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.PASSWORD
};

const BASE_ENABLE_SSL = {
  label: 'Enable SSL',
  name: 'connection.useSsl',
  initialValue: 'checked',
  componentType: FormComponentType.CHECKBOX
};

export const RedshiftPlugin: Plugin = {
  id: 'redshift',
  name: 'Amazon RedShift',
  moduleName: 'RedshiftPlugin',
  modulePath: 'plugins/redshift/RedshiftPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/redshift.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/aws-redshift',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: RedshiftPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'Redshift Prod Cluster',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Connection method',
            name: 'connectionType',
            startVersion: RedshiftPluginVersions.V7,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          {
            label: 'URI',
            name: 'connectionUrl',
            startVersion: RedshiftPluginVersions.V7,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `redshift://[:username]:[:password]@[:host]:[:port]/[:database]`,
            rules: [{ required: true, message: 'URI is required' }],
            display: {
              show: {
                connectionType: ['url']
              }
            }
          },
          // HOST
          {
            ...BASE_HOST,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_HOST,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // PORT
          {
            ...BASE_PORT,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_PORT,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // Schema
          {
            ...BASE_SCHEMA,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_SCHEMA,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE NAME
          {
            ...BASE_DATABASE_NAME,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_DATABASE_NAME,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE USERNAME
          {
            ...BASE_DATABASE_USERNAME,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_DATABASE_USERNAME,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE PASSWORD
          {
            ...BASE_DATABASE_PASSWORD,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_DATABASE_PASSWORD,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // Enable SSL
          {
            ...BASE_ENABLE_SSL,
            startVersion: RedshiftPluginVersions.V1,
            endVersion: RedshiftPluginVersions.V6
          } as FormItem,
          {
            ...BASE_ENABLE_SSL,
            startVersion: RedshiftPluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'body',
            startVersion: RedshiftPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'usePreparedSql',
            startVersion: RedshiftPluginVersions.V6,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
