export enum ObservabilityVendorType {
  AWS_OPEN_SEARCH = 'AWS_OPEN_SEARCH',
  DATADOG = 'DATADOG',
  ELASTIC_CLOUD = 'ELASTIC_CLOUD',
  KAFKA = 'KAFKA',
  CONFLUENT_CLOUD = 'CONFLUENT_CLOUD',
  CORALOGIX = 'CORALOGIX',
  NEW_RELIC = 'NEW_RELIC',
  SUMOLOGIC = 'SUMOLOGIC',
  // The sinks below are only used in sales demo
  SPLUNK = 'SPLUNK',
  DYNATRACE = 'DYNATRACE',
  GRAFANA = 'GRAFANA',
  APP_DYNAMICS = 'APP_DYNAMICS',
  CHRONOSPHERE = 'CHRONOSPHERE',
  PROMETHEUS = 'PROMETHEUS',
  SYSDIG = 'SYSDIG'
}

export type Kafka = {
  bootstrap: string;
  topic: string;
  username: string;
  password: string;
};

export type Datadog = {
  apiKey: string;
  site?: string; // The UI should always be sending this. To be extra safe and to be backward compatible, we'll default to the US.
};

export type Elastic = {
  cloudId: string;
  cloudAuth: string;
};

export type AwsOpenSearch = {
  host: string;
  port: string;
  userName: string;
  password: string;
};

export type NewRelic = {
  apiKey: string;
  region: string;
};

export type Coralogix = {
  apiKey: string;
  endpoint: string;
};

export type SumoLogic = {
  endpoint: string;
};

export type DummyConfig = {
  koala: string;
};

export type ObservabilityVendor = {
  [ObservabilityVendorConfigurationKey.DATADOG]?: Datadog;
  [ObservabilityVendorConfigurationKey.ELASTIC]?: Elastic;
  [ObservabilityVendorConfigurationKey.KAFKA]?: Kafka;
  [ObservabilityVendorConfigurationKey.AWS_OPEN_SEARCH]?: AwsOpenSearch;
  [ObservabilityVendorConfigurationKey.NEW_RELIC]?: NewRelic;
  [ObservabilityVendorConfigurationKey.CORALOGIX]?: Coralogix;
  [ObservabilityVendorConfigurationKey.SUMOLOGIC]?: SumoLogic;
  // The sinks below are only used in sales demo
  [ObservabilityVendorConfigurationKey.SPLUNK]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.DYNATRACE]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.GRAFANA]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.APP_DYNAMICS]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.CHRONOSPHERE]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.PROMETHEUS]?: DummyConfig;
  [ObservabilityVendorConfigurationKey.SYSDIG]?: DummyConfig;
};

export enum ObservabilityVendorConfigurationKey {
  DATADOG = 'datadog',
  ELASTIC = 'elastic',
  KAFKA = 'kafka',
  AWS_OPEN_SEARCH = 'aws-open-search',
  NEW_RELIC = 'newrelic',
  CORALOGIX = 'CORALOGIX',
  SUMOLOGIC = 'SUMOLOGIC',
  // The sinks below are only used in sales demo
  SPLUNK = 'SPLUNK',
  DYNATRACE = 'DYNATRACE',
  GRAFANA = 'GRAFANA',
  APP_DYNAMICS = 'APP_DYNAMICS',
  CHRONOSPHERE = 'CHRONOSPHERE',
  PROMETHEUS = 'PROMETHEUS',
  SYSDIG = 'SYSDIG'
}
