import { ApplicationScope, PageDSL8 } from "@superblocksteam/shared";
import { Timestamp } from "utils/interval";
import { MultiStepDef } from "../../../../legacy/constants/ActionConstants";

export const DEFAULT_TIMER_INTERVAL = 300000;

// Maximum interval. If larger, interval will default to 0ms
// See https://developer.mozilla.org/en-US/docs/Web/API/setInterval
export const TIMER_MAX_INTERVAL = 2147483646;

export const TIMER_MIN_INTERVAL = 5_000;

export type AppTimer = Omit<
  Required<PageDSL8>["timers"]["timerMap"][string],
  "steps"
> & {
  steps: MultiStepDef;
};
export type AppTimerScoped = {
  scope: ApplicationScope;
} & AppTimer;

export type AppTimersMap = Record<AppTimer["id"], AppTimerScoped>;

export enum ActiveTimerStatus {
  IDLE = "IDLE",
  EXECUTING_STEPS = "EXECUTING_STEPS",
}

type ActiveTimerState =
  | { status: ActiveTimerStatus.IDLE; nextInvocation: Timestamp }
  | { status: ActiveTimerStatus.EXECUTING_STEPS };

/**
 * Invariant: !!timerMeta.state === timerMeta.isActive
 * Unfortunately, cannot be enforced statically b/c typescript does not allow
 * extending union types and we need to be able to extend AppTimerMetaType.
 */
export type AppTimerMetaType = {
  isActive: boolean;
  state?: ActiveTimerState;
};

export type AppTimerWithMetaType = AppTimerScoped & AppTimerMetaType;
