import { Typography } from "antd";
import React from "react";

import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import Helm from "./Helm";

const AKS = ({
  agentHost,
  agentUrl,
  agentEnvironment,
  agentDataDomain,
  showInstruction,
}: AgentDeploymentInstructionsProps) => {
  return (
    <Typography.Paragraph>
      <Typography.Text strong>
        Create an AKS cluster using the{" "}
        <Typography.Link
          href={"https://docs.microsoft.com/en-us/cli/azure/install-azure-cli"}
          target="_blank"
        >
          Azure CLI
        </Typography.Link>
      </Typography.Text>
      <CodeBlock
        content={`az group create --name superblocks --location centralus`}
      />
      <CodeBlock
        content={`az aks create \\
  --resource-group superblocks \\
  --name superblocks \\
  --node-count 1 \\
  --generate-ssh-keys \\
  --node-vm-size standard_d2as_v5`}
      />
      <CodeBlock
        content={`az aks get-credentials \\
  --resource-group superblocks \\
  --name superblocks`}
      />
      <Helm
        agentHost={agentHost}
        agentUrl={agentUrl}
        agentEnvironment={agentEnvironment}
        agentDataDomain={agentDataDomain}
        showInstruction={showInstruction}
      />
    </Typography.Paragraph>
  );
};

export default AKS;
