import { Classes } from "@blueprintjs/core";
import {
  RestApiFields,
  getRowItemsFromSectionItem,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as DiffIcon } from "assets/icons/common/diff.svg";
import { SyntaxType } from "code-formatting/constants";
import { getPluginIdForSyntax } from "code-formatting/utils";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import CodeEditor from "components/app/CodeEditor/index";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { JsonStreamParser } from "utils/JsonStreamParser";
import { getPluginById } from "utils/integrations";
import {
  makeParseKeyValueArrayToString,
  makeParseStringToKeyValueArray,
  parseFromString,
  parseToString,
} from "./util";

const Grid = styled.div`
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  border-radius: 4px;
`;

const KeyValuePair = styled.div`
  position: relative;
  display: flex;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.GREY_100};
`;

const Key = styled.div`
  padding: 5px 12px;
  font-size: 13px;
  border: 1px solid transparent;
  width: 150px;
`;

const Value = styled.div`
  width: calc(100% - 150px);
  padding: 7px 11px;
  font-size: 12px;
  white-space: pre-line;
  font-family: var(--font-monospace);
  cursor: default;
  overflow: hidden;
  word-break: break-word;
`;

const ValueEditorWrapper = styled.div`
  width: calc(100% - 150px);
  .EditorWrapper {
    border: none;
    opacity: 1;
    height: calc(100% + 2px);
    .CodeMirror {
      top: -1px;
      border: 1px solid transparent;
      border-top-color: ${(props) => props.theme.colors.GREY_100};
      border-bottom-color: ${(props) => props.theme.colors.GREY_100};
      border-radius: 0px;
      background: ${(props) => props.theme.colors.WHITE};
      &.CodeMirror-focused {
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme.colors.ACCENT_BLUE_500};
        z-index: 2;
      }
      .CodeMirror-lines {
        padding-top: 6px;
        background: none;
        cursor: text;
      }
    }
  }
`;

const ValueDropdownWrapper = styled.div`
  width: calc(100% - 150px);
  .${Classes.POPOVER_OPEN} {
    input {
      border-color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }
  }

  .${Classes.INPUT} {
    border-color: transparent;
    font-family: var(--font-monospace);
    font-size: 12px;
    ::before {
      content: "" !important;
    }
  }
`;

const DiffButton = styled.button<{ $isActive: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.GREY_100};
  border-radius: 4px;
  height: 24px;
  width: 24px;
  background: ${(props) => props.theme.colors.WHITE};
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 1;
  padding: 3px;
  svg {
    path {
      ${(props) =>
        props.$isActive && `stroke: ${props.theme.colors.ACCENT_BLUE_500};`};
    }
  }
`;

const KEYS_FOR_SYNTAX: Record<string, Array<string>> = {
  [SyntaxType.REST_API]: [
    RestApiFields.HTTP_METHOD,
    RestApiFields.PATH,
    RestApiFields.HEADERS,
    RestApiFields.PARAMS,
    RestApiFields.BODY,
    RestApiFields.FILE_NAME,
    RestApiFields.FORM_DATA,
  ],
  [SyntaxType.GRAPHQL]: ["path", "body", "headers", "custom.variables.value"],
};

const PARSERS_FOR_FIELDS: Record<
  string,
  { toString?: (value: any) => string; fromString?: (value: string) => any }
> = {
  headers: {
    toString: makeParseKeyValueArrayToString(" "),
    fromString: makeParseStringToKeyValueArray(" "),
  },
  params: {
    toString: makeParseKeyValueArrayToString("="),
    fromString: makeParseStringToKeyValueArray("="),
  },
  body: {
    fromString: (value: string) => value,
    toString: undefined,
  },
  "custom.variables.value": {
    fromString: (value: string) => value,
    toString: undefined,
  },
};

const OVERWRITE_LABELS_BY_SYNTAX: Record<string, Record<string, string>> = {
  [SyntaxType.REST_API]: {
    body: "Body",
  },
};

const autocompleteConfiguration = { env: true };
const ValueEditor = ({
  initialValue,
  onBlur,
  rawValue,
  inputType,
  options,
}: {
  initialValue: string | number | boolean;
  onBlur: (value: string) => void;
  rawValue: any;
  inputType?: string;
  options?: Array<{
    displayName: string;
    value: string;
    key: string;
  }>;
}) => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback((value: any) => {
    setValue(value);
  }, []);
  const input = useMemo(() => ({ value, onChange }), [value, onChange]);

  const onDropdownChange = useCallback(
    (option: any) => {
      onBlur(option.value);
      setValue(option.value);
    },
    [onBlur],
  );

  if (inputType === "DROPDOWN" && options) {
    return (
      <ValueDropdownWrapper className="drag-disabled">
        <RecommendedSingleDropdown
          options={options}
          value={String(value)}
          onChange={onDropdownChange}
        />
      </ValueDropdownWrapper>
    );
  }
  return (
    <ValueEditorWrapper className="drag-disabled">
      <CodeEditor
        showLineNumbers={false}
        mode={EditorModes.TEXT_WITH_BINDING}
        tabBehaviour={TabBehaviour.INDENT}
        disabled={false}
        theme={EditorTheme.LIGHT}
        size={EditorSize.EXTENDED}
        monospace={true}
        onEditorBlur={onBlur}
        showShortcutMenu={false}
        input={input}
        minHeight="34px"
        autocompleteConfiguration={autocompleteConfiguration}
      />
    </ValueEditorWrapper>
  );
};

export const ParsedJsonViewer = ({
  messages,
  isLoading,
  syntax,
  onResponseChange,
  initialConfig,
}: {
  messages: Array<string>;
  isLoading: boolean;
  syntax: SyntaxType;
  onResponseChange: (value: string) => void;
  initialConfig?: Record<string, any>;
}) => {
  const [currentView, setCurrentView] = useState<
    "AI_RESPONSE" | "INITIAL_DATA"
  >("AI_RESPONSE");
  const toggleCurrentView = useCallback(() => {
    setCurrentView((prev) =>
      prev === "AI_RESPONSE" ? "INITIAL_DATA" : "AI_RESPONSE",
    );
  }, []);

  const [parsedJson, setParsedJson] = useState<Record<string, unknown> | null>(
    null,
  );
  const handleChunk = useCallback((jsonObject: Record<string, unknown>) => {
    setParsedJson(jsonObject);
  }, []);

  const jsonParser = useRef(new JsonStreamParser(handleChunk));
  const lastReadMessageIndex = useRef(-1);

  useEffect(() => {
    // combine all messages from the last read index to the end and add them to the parser
    let messageToRead = "";
    for (let i = lastReadMessageIndex.current + 1; i < messages.length; i++) {
      messageToRead += messages[i];
    }
    jsonParser.current.addChunk(messageToRead);
    // update the last read index
    lastReadMessageIndex.current = messages.length - 1;
  }, [messages]);

  useEffect(() => {
    if (!isLoading) {
      jsonParser.current.reset();
    }
  }, [isLoading]);

  const pluginId = getPluginIdForSyntax(syntax);
  const plugin = getPluginById(pluginId);
  const itemKeysToConfig = useMemo(() => {
    if (plugin?.actionTemplate?.sections) {
      const sections = plugin.actionTemplate.sections;
      return sections.reduce(
        (
          acc: Record<
            string,
            {
              label: string;
              componentType: string;
              options?: Array<{
                displayName: string;
                value: string;
                key: string;
              }>;
            }
          >,
          section,
        ) => {
          section.items.forEach((sectionItem) => {
            const rowItems = getRowItemsFromSectionItem(sectionItem);
            for (const item of rowItems) {
              acc[item.name] = {
                // hack because multiple fields have body as the name
                label:
                  OVERWRITE_LABELS_BY_SYNTAX[syntax]?.[item.name] ?? item.label,
                componentType: item.componentType,
                options: (item as any).options,
              };
            }
          });
          return acc;
        },
        {},
      );
    }
    return {};
  }, [plugin, syntax]);

  const handleValueEdited = useCallback(
    (key: string, value: string) => {
      const parser = PARSERS_FOR_FIELDS[key]?.fromString;
      const updatedJson = {
        ...parsedJson,
        [key]: (parser ?? parseFromString)(value),
      };
      setParsedJson(updatedJson);
      onResponseChange(JSON.stringify(updatedJson));
    },
    [parsedJson, onResponseChange],
  );

  // turn the parsed json into content based on the syntax
  const content = useMemo(() => {
    if (!parsedJson) {
      return null;
    }
    const keysToShow = KEYS_FOR_SYNTAX[syntax] ?? Object.keys(itemKeysToConfig);
    return (
      <Grid>
        {keysToShow.map((key, i) => {
          if (parsedJson[key] != null) {
            const onBlur = (newValue: string) => {
              handleValueEdited(key, newValue);
            };
            const parser = PARSERS_FOR_FIELDS[key]?.toString;
            const value = (parser ?? parseToString)(
              currentView === "INITIAL_DATA"
                ? initialConfig?.[key]
                : parsedJson[key],
            );
            const itemConfig = itemKeysToConfig[key];
            return (
              <>
                <KeyValuePair key={key}>
                  <Key>{itemConfig?.label ?? key}</Key>
                  {!isLoading && currentView !== "INITIAL_DATA" ? (
                    <ValueEditor
                      onBlur={onBlur}
                      initialValue={value === "--" ? "" : value}
                      rawValue={parsedJson[key]}
                      inputType={itemConfig?.componentType}
                      options={itemConfig?.options}
                    />
                  ) : (
                    <Value>{value}</Value>
                  )}
                </KeyValuePair>
                {i !== keysToShow.length - 1 && (
                  <Divider key={`divider-${i}`} />
                )}
              </>
            );
          }
          return null;
        })}
      </Grid>
    );
  }, [
    parsedJson,
    syntax,
    itemKeysToConfig,
    isLoading,
    handleValueEdited,
    initialConfig,
    currentView,
  ]);

  return (
    <div style={{ position: "relative" }}>
      {content}
      {!isLoading && initialConfig && (
        <Tooltip
          title={
            currentView === "AI_RESPONSE"
              ? "Compare to current config"
              : "View AI response"
          }
        >
          <DiffButton
            onClick={toggleCurrentView}
            $isActive={currentView === "INITIAL_DATA"}
          >
            <DiffIcon />
          </DiffButton>
        </Tooltip>
      )}
    </div>
  );
};
