import CodeMirror from "codemirror";
import { isUserOnMac } from "utils/navigator";
import { getMarkers, getTokenData, getTokens } from "./mouse/mouseHelper";

const HOVER_CLASS = "CodeMirror-hyperlink";
declare module "codemirror" {
  export interface EditorConfiguration {
    textClick?: Options | undefined;
  }
}

export interface Options {
  canClick: (cm: CodeMirror.Editor, tokens: string[]) => boolean;
  onClick: (cm: CodeMirror.Editor, tokens: string[]) => void;
}

type State = {
  currentLink?: HTMLElement;
  currentMouseEvent?: MouseEvent;
  handleMouseOver: (e: MouseEvent) => void;
  handleMouseClick: (e: MouseEvent) => void;
  handleMouseMove: (e: MouseEvent) => void;
  onKeyChange: (e: KeyboardEvent) => void;
} & Options;

const isKeyPressed = (e: MouseEvent | KeyboardEvent) => {
  if (e.metaKey && isUserOnMac()) return true; // cmd key
  if (e.ctrlKey && !isUserOnMac()) return true; // ctrl key
  return false;
};

function handleMouseClick(instance: CodeMirror.Editor, e: MouseEvent) {
  if (!isKeyPressed(e)) return;

  const tokenData = getTokenData(instance, e);
  if (!tokenData) return;

  const state = instance.state.textClick as State;
  const tokens = getTokens(instance, tokenData);
  const markers = getMarkers(instance, tokenData);

  const isValid = tokens !== undefined && markers !== undefined;

  if (isValid && state.canClick(instance, tokens)) {
    state.onClick(instance, tokens);
  }
}

function onKeyChange(instance: CodeMirror.Editor, e: KeyboardEvent) {
  const state = instance.state.textClick as State;
  if (state.currentMouseEvent && isKeyPressed(e)) {
    handleMouse(instance, state.currentMouseEvent, true);
  } else {
    hideUnderline(instance);
  }
}

function handleMouse(
  instance: CodeMirror.Editor,
  e: MouseEvent,
  forceKeyPressed = false,
) {
  const node = (e.target || e.srcElement) as HTMLElement;
  const state = instance.state.textClick as State;
  state.currentMouseEvent = e;

  if (!isKeyPressed(e) && !forceKeyPressed) {
    hideUnderline(instance);
    return;
  }

  const tokenData = getTokenData(instance, e);
  if (!tokenData) {
    hideUnderline(instance);
    return;
  }

  const tokens = getTokens(instance, tokenData);
  const markers = getMarkers(instance, tokenData);

  const isValid = tokens !== undefined && markers !== undefined;
  const canOpen = isValid && state.canClick(instance, tokens);

  if (canOpen) {
    hideUnderline(instance, true);
    showUnderline(node, instance);
  } else {
    hideUnderline(instance, true);
  }
}

function showUnderline(node: HTMLElement, instance: CodeMirror.Editor) {
  node.className = `${node.className} ${HOVER_CLASS}`;
  (instance.state.textClick as State).currentLink = node;
}

function hideUnderline(instance: CodeMirror.Editor, clearNode = true) {
  const currentLinkNode = (instance.state.textClick as State).currentLink as
    | HTMLElement
    | undefined;
  if (currentLinkNode) {
    currentLinkNode.className = currentLinkNode.className
      .replace(HOVER_CLASS, "")
      .trim();
    if (clearNode) {
      delete instance.state.textClick.currentLink;
    }
  }
}

function optionHandler(
  instance: CodeMirror.Editor,
  current: Options,
  old: Options | CodeMirror.Init,
) {
  const wrapper = instance.getWrapperElement();
  const state = instance.state.textClick;

  if (old && old !== CodeMirror.Init) {
    CodeMirror.off(wrapper, "mouseover", state.handleMouseOver);
    CodeMirror.off(wrapper, "mousemove", state.handleMouseMove);
    CodeMirror.off(wrapper, "click", state.handleMouseClick);
    document.removeEventListener("keydown", state.onKeyChange);
    document.removeEventListener("keyup", state.onKeyChange);
    delete instance.state.textClick;
  }

  if (current) {
    instance.state.textClick = {
      ...current,
      handleMouseOver(e: MouseEvent) {
        handleMouse(instance, e);
      },
      handleMouseMove(e: MouseEvent) {
        handleMouse(instance, e);
      },
      handleMouseClick(e: MouseEvent) {
        handleMouseClick(instance, e);
      },
      onKeyChange(e: KeyboardEvent) {
        onKeyChange(instance, e);
      },
    };

    const state = instance.state.textClick;
    CodeMirror.on(wrapper, "mouseover", state.handleMouseOver);
    CodeMirror.on(wrapper, "mousemove", state.handleMouseMove);
    CodeMirror.on(wrapper, "click", state.handleMouseClick);
    document.addEventListener("keydown", state.onKeyChange);
    document.addEventListener("keyup", state.onKeyChange);
  }
}

CodeMirror.defineOption("textClick", false, optionHandler);
