import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "lodash";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { initCanvasLayout } from "legacy/actions/pageActions";
import { toScopedEntity } from "store/utils/scope";
import { fetchApplicationSuccess } from "../applicationActions";
import { renameCustomEvents } from "./eventActions";
import { EventDefinition, EventDefinitionScoped } from "./eventConstants";

const initialState: {
  [ApplicationScope.GLOBAL]: Record<string, EventDefinitionScoped>;
  [ApplicationScope.APP]: Record<string, EventDefinitionScoped>;
  [ApplicationScope.PAGE]: Record<string, EventDefinitionScoped>;
} = {
  [ApplicationScope.GLOBAL]: {},
  [ApplicationScope.PAGE]: {},
  [ApplicationScope.APP]: {},
};

type EventMap = typeof initialState;

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    overwriteScopedEvents: (
      state,
      action: PayloadAction<{
        scope: ApplicationScope;
        events: EventMap[ApplicationScope];
      }>,
    ) => {
      state[action.payload.scope] = action.payload.events;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initCanvasLayout, (state, action) => {
        if (action.payload.eventMap) {
          state[ApplicationScope.PAGE] = toScopedEntity<EventDefinitionScoped>(
            action.payload.eventMap as Record<string, EventDefinition>,
            ApplicationScope.PAGE,
          );
        } else {
          state[ApplicationScope.PAGE] = {};
        }
      })
      .addCase(fetchApplicationSuccess, (state, action) => {
        if (action.payload.configuration?.dsl?.events?.eventMap) {
          state[ApplicationScope.APP] = toScopedEntity<EventDefinitionScoped>(
            action.payload.configuration.dsl.events.eventMap as Record<
              string,
              EventDefinition
            >,
            ApplicationScope.APP,
          );
        }
      })
      .addCase(renameCustomEvents, (state, action) => {
        action.payload.updates.forEach(
          ({ eventId, propertyName, propertyValue, scope }) => {
            const event = state[scope][eventId];
            if (event) {
              set(event, propertyName, propertyValue);
            }
          },
        );
      })
      .addCase(stopEvaluation, () => initialState);
  },
});

export const { overwriteScopedEvents } = eventsSlice.actions;
