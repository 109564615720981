import {
  ActionConfigParam,
  ActionConfigParamIn,
  ActionConfigParamValue,
  ExtendedIntegrationPluginId,
  ExtendedIntegrationPluginMap,
  HttpMethod,
  OpenApiSpec,
  RestApiIntegrationActionConfiguration,
  TestCase,
} from "@superblocksteam/shared";
import { merge as allOfMerge } from "allof-merge";
import { get, isArray } from "lodash";
import { isValidUrl } from "utils/url";

// openAPI helpers
export const OpenAPIMethods = [
  "get",
  "post",
  "put",
  "delete",
  "patch",
  "head",
  "options",
];
const consumesToInParameterMapping: any = {
  "application/x-www-form-urlencoded": "formData",
  "multipart/form-data": "formData",
  "application/json": "body",
  "text/plain": "body",
  "application/octet-stream": "body",
};

const METHOD_PREFIX_LENGTH = 10;

// adjustment on the spaces caused by font not having mono width
const PrefixToSpacesAdjust: Record<string, number> = {
  get: 2,
  post: 0,
  put: 2,
  delete: -2,
  patch: -1,
  head: 0,
  options: -3,
};

export const OPENAPI_UPLOAD_NOTIFICATION_KEY =
  "OPENAPI_UPLOAD_NOTIFICATION_KEY";

const SUPERBLOCKS_TEST_KEY_NAME = "x-superblocks-test";

const fillWithSpaces = (str: string, len: number) => {
  while (str.length < len) {
    str += " ";
  }
  return str;
};

export const fillMethodPrefixWithSpaces = (method: string) => {
  let adjust = 0;
  if (method.toLowerCase() in PrefixToSpacesAdjust) {
    adjust = PrefixToSpacesAdjust[method.toLowerCase()];
  }
  return fillWithSpaces(method, METHOD_PREFIX_LENGTH + adjust);
};

export const generateOpenApiSpecRef = (
  datasourceId: string,
  fileName: string,
) => {
  return `${datasourceId}_${fileName}`;
};

export const getOpenApiSpecFileName = (openApiSpecRef: string) => {
  if (isValidUrl(openApiSpecRef)) {
    // Return the last part of the url that represents the file name
    return openApiSpecRef.split("/").pop();
  }
  const startPos = openApiSpecRef.indexOf("_");

  if (startPos !== -1) {
    return openApiSpecRef.substring(startPos + 1);
  }

  return openApiSpecRef;
};

export const getParamsByType = ({
  openApiSpec,
  params,
  type,
}: {
  openApiSpec: any;
  params: any[];
  type: string;
}) => {
  const filteredParams = (params as any[])
    ?.map((param) => {
      if (param["$ref"]) {
        const refParam = get(
          openApiSpec,
          param["$ref"].replace("#/", "").replaceAll("/", "."),
        );
        if (refParam?.in === type) {
          return refParam;
        }
      } else if (param?.in === type) {
        return param;
      }
      return undefined;
    })
    .filter(Boolean);
  if (filteredParams && filteredParams.length) {
    return filteredParams;
  }
  return undefined;
};

const generateExampleFromSchema: any = (obj: any) => {
  if (obj?.type === "object") {
    const newObj = { ...obj.properties };
    Object.entries(newObj).forEach(([key, value]) => {
      newObj[key] = generateExampleFromSchema(value);
    });
    return newObj;
  } else if (obj?.type === "array") {
    return [generateExampleFromSchema(obj.items)];
  } else if (obj?.type === "boolean") {
    return obj?.example ?? false;
  } else if (obj?.type === "string") {
    return obj?.example ?? obj?.enum?.[0] ?? "example_string";
  } else if (obj?.type === "number" || obj?.type === "integer") {
    return obj?.example ?? obj?.enum?.[0] ?? 123456;
  } else {
    return obj?.example ?? obj?.enum?.[0];
  }
};

export const getIsOpenAPIV2 = (openApiSpec: any) => {
  return Boolean(openApiSpec?.swagger);
};

const getSchemaFromFormDataParams = (params: any[]) => {
  const properties: any = {};
  params.forEach((param: any) => {
    properties[param.name] = {
      ...param,
    };
  });
  return {
    type: "object",
    properties,
  };
};

export const getDetailsFromRequest = ({
  actionDetails,
  isOpenAPIV2,
}: {
  // path[method]
  actionDetails: any;
  isOpenAPIV2: boolean;
}) => {
  const bodyType = isOpenAPIV2
    ? actionDetails?.consumes?.[0]
    : Object.keys(actionDetails?.requestBody?.content ?? {})?.[0];
  const bodyIn = consumesToInParameterMapping[bodyType] ?? "";
  const body = isOpenAPIV2
    ? actionDetails?.parameters?.find((param: any) => param?.in === bodyIn)
    : Object.values(actionDetails?.requestBody?.content ?? {})?.[0];

  // The OpenAPI specification allows you to provide a single example
  // with the `example` field, or multiple examples with the `examples` field.
  // We merge these two and give precedence to the `example` field. We only ever
  // use the first one however so this logic future proofs a world in which we
  // show multiple examples.
  const bodyExamples = (body?.example ? [{ value: body.example }] : [])
    .concat(body?.examples)
    .map((example) => {
      if (!example?.value) {
        return example;
      }

      try {
        return { value: JSON.parse(example.value) };
      } catch {
        return { value: example.value };
      }
    });
  const unmergedSchema =
    body?.schema ??
    // if bodyType is formData, we need to get the schema from parameters
    getSchemaFromFormDataParams(
      actionDetails?.parameters?.filter((param: any) => param?.in === bodyIn) ??
        [],
    );
  const schema = allOfMerge(unmergedSchema);
  const example =
    (Object.values(bodyExamples ?? {})?.[0] as any)?.value ??
    generateExampleFromSchema(schema);
  const exampleString = JSON.stringify(example, null, 2);
  const schemaString = JSON.stringify(schema, null, 2);
  return { body, bodyType, exampleString, schemaString };
};

export const getDetailsFromResponse = ({
  responseDetails,
  actionDetails,
  isOpenAPIV2,
}: {
  // in 3.x, it is response[code].content
  // in 2.0 it is response[code] for response
  responseDetails: any;
  // path[method]
  actionDetails: any;
  isOpenAPIV2: boolean;
}) => {
  const bodyType = isOpenAPIV2
    ? actionDetails?.produces?.[0]
    : Object.keys(responseDetails?.content ?? {})?.[0];

  const body = isOpenAPIV2
    ? responseDetails
    : Object.values(responseDetails?.content ?? {})?.[0];

  const bodyExamples = (body?.example ? [{ value: body.example }] : [])
    .concat(body?.examples)
    .map((example) => {
      if (!example?.value) {
        return example;
      }

      try {
        return { value: JSON.parse(example.value) };
      } catch {
        return { value: example.value };
      }
    });
  const unmergedSchema = body?.schema;
  const schema = allOfMerge(unmergedSchema);
  const example =
    (Object.values(bodyExamples ?? {})?.[0] as any)?.value ??
    generateExampleFromSchema(schema);
  // TODO: check schema and recursive ref
  const exampleString = JSON.stringify(example, null, 2);
  const schemaString = JSON.stringify(schema, null, 2);

  // headers
  const headers = Object.entries(responseDetails?.headers ?? {}).map(
    ([key, value]) => {
      return {
        name: key,
        description: (value as any)?.description,
        schema: (value as any)?.schema,
        type: (value as any)?.type,
      };
    },
  );

  return { body, bodyType, exampleString, schemaString, headers };
};

const generateOAuth2Documentation = (oauth2Object: any) => {
  let document = "";

  const addFieldToDocument = (
    fieldName: string,
    fieldValue: string,
    indentLevel = 0,
  ) => {
    const indent = "  ".repeat(indentLevel);
    const capitalizedFieldName =
      fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    document += `${indent}- **${capitalizedFieldName}**: ${fieldValue}\n`;
  };

  // Add the main description header
  if (oauth2Object.description) {
    document += `### OAuth 2.0\n\n${oauth2Object.description}\n\n`;
  }

  // Process each flow in the OAuth 2.0 object
  for (const flowName in oauth2Object.flows) {
    const flow = oauth2Object.flows[flowName];

    // Add the flow header
    document += `#### ${
      flowName.charAt(0).toUpperCase() + flowName.slice(1)
    } Flow\n\n`;

    // Process and add details for each field in the flow
    for (const fieldName in flow) {
      const fieldValue = flow[fieldName];

      // Skip the "scopes" field, as it requires special handling
      if (fieldName === "scopes") {
        continue;
      }

      // Add the field and its value to the document
      addFieldToDocument(fieldName, fieldValue, 1);
    }

    // Add the scopes field and its values, if present
    if (flow.scopes) {
      document += `#### Scopes\n\n`;
      for (const scopeName in flow.scopes) {
        const scopeDescription = flow.scopes[scopeName];
        addFieldToDocument(scopeName, scopeDescription, 1);
      }
    }

    // Add an empty line to separate each flow
    document += "\n";
  }
  return document;
};

export const generateAuthenticationDoc = (authObj: any): string => {
  const type = authObj.type;
  // The apiKey type uses 'in' instead of 'scheme' but to maintain backwards compatibility with existing specs,
  // we check both with the 'scheme' field taking precedence
  // Ref: https://swagger.io/docs/specification/authentication/api-keys/
  const scheme = authObj.scheme || authObj["in"];

  let authenticationName = "";
  let docFunc: any;
  if (type === "apiKey") {
    if (scheme === "header") {
      authenticationName = "API Key (Header)";
    } else if (scheme === "query") {
      authenticationName = "API Key (Query Parameter)";
    } else if (scheme === "cookie") {
      authenticationName = "API Key (Cookie)";
    }
  } else if (type === "http") {
    if (scheme === "basic") {
      authenticationName = "Basic Authentication";
    } else if (scheme === "bearer") {
      authenticationName = "Bearer Token Authentication";
    } else if (scheme === "mutualTLS") {
      authenticationName = "Mutual TLS (mTLS)";
    }
  } else if (type === "oauth2") {
    authenticationName = "OAuth 2.0";
    docFunc = generateOAuth2Documentation;
  } else if (type === "openIdConnect") {
    authenticationName = "OpenID Connect";
  } else if (type === "jwt") {
    authenticationName = "JWT (JSON Web Token)";
  }
  if (!docFunc && authenticationName) {
    // Why is `description` the literal string `undefined` if not set?
    // Something must be setting it upstream.
    return `### ${authenticationName}\n\n${
      authObj?.description && authObj.description !== "undefined"
        ? authObj.description
        : ""
    }\n\n`;
  }

  return docFunc?.(authObj) ?? "";
};

export const getDisplayedTag = (tag: string) => {
  return tag.charAt(0).toUpperCase() + tag.slice(1);
};

export const getTypeString = (type: string | Array<string> | undefined) => {
  if (isArray(type)) {
    type = type.join(" | ");
  }
  return type;
};

export const isOpenApiBasedRestPlugin = (id: string) => {
  // iterate over values of the ExtendedIntegrationPluginId enum
  for (const value of Object.values(ExtendedIntegrationPluginId)) {
    if (value === id) {
      return (
        ExtendedIntegrationPluginMap[id] ===
        ExtendedIntegrationPluginId.REST_API
      );
    }
  }
  return false;
};

export const extractActionConfigs = (
  openApiSpec: OpenApiSpec,
): RestApiIntegrationActionConfiguration[] => {
  const superblocksTest =
    SUPERBLOCKS_TEST_KEY_NAME in openApiSpec
      ? (openApiSpec[SUPERBLOCKS_TEST_KEY_NAME] as TestCase[])
      : null;
  const actionConfigs = superblocksTest?.map((testCase: TestCase) => {
    const operationId = testCase.operation;
    // find the operation in the openApiSpec
    // go over all paths and methods
    let foundOperation: Record<string, unknown> | null = null;
    let foundPath: string | null = null;
    let foundMethod: string | null = null;
    for (const path in openApiSpec.paths) {
      for (const method in openApiSpec.paths[path]) {
        const operation = openApiSpec.paths[path][method];
        if (operation.operationId === operationId) {
          foundOperation = operation;
          foundPath = path;
          foundMethod = method;
          break;
        }
      }
    }
    if (!foundOperation || !foundPath || !foundMethod) {
      return null;
    }
    const parameters: Record<string, ActionConfigParamValue> = {};
    testCase.parameters?.forEach((param: ActionConfigParam) => {
      parameters[param.name] = {
        in: param.in.toUpperCase() as ActionConfigParamIn,
        value: param.value,
      };
    });
    const httpMethods = Object.values(HttpMethod).filter(
      (m) => m.toLowerCase() === foundMethod,
    );
    if (httpMethods.length === 0) {
      return null;
    }
    const httpMethod = httpMethods[0];
    const actionConfig: RestApiIntegrationActionConfiguration = {
      httpMethod: httpMethod,
      urlPath: foundPath,
    };
    return actionConfig;
  });
  return (
    (actionConfigs?.filter(
      (ac) => ac !== null && ac !== undefined,
    ) as RestApiIntegrationActionConfiguration[]) ?? []
  );
};

export const hasSuperblocksTest = (openApiSpec?: OpenApiSpec): boolean => {
  return (openApiSpec && SUPERBLOCKS_TEST_KEY_NAME in openApiSpec) ?? false;
};
