import { DatasourceMetadataDto } from "@superblocksteam/shared";
import { SyntaxType } from "code-formatting/constants";

interface AiAssistantCreateRequest {
  task: {
    create: {
      prompt: string;
      syntax: SyntaxType;
      context?: {
        integrationId?: string;
        configurationId?: string;
        metadata?: DatasourceMetadataDto;
      };
    };
  };
}

interface AiAssistantEditRequest {
  task: {
    edit: {
      prompt: string;
      syntax: SyntaxType;
      snippet: string;
      contents: string;
      context?: {
        integrationId?: string;
        configurationId?: string;
        metadata?: DatasourceMetadataDto;
      };
    };
  };
}

interface AiAssistantExplainRequest {
  task: {
    explain: {
      snippet: string;
      contents: string;
      syntax: SyntaxType;
      language: string;
    };
  };
}

interface AiAssistantMockRequest {
  task: {
    mock: {
      prompt: string;
      syntax: SyntaxType;
      shape: string;
    };
  };
}

export type AiAssistantRequest =
  | AiAssistantCreateRequest
  | AiAssistantEditRequest
  | AiAssistantExplainRequest
  | AiAssistantMockRequest;

export enum FlowType {
  GENERATE = "GENERATE",
  EXPLAIN = "EXPLAIN",
  MOCK = "MOCK",
  EDIT = "EDIT",
}

export enum ScopeType {
  PLUGIN = "PLUGIN",
  EDITOR = "EDITOR",
}

export type AiAssistantOptionType = {
  flowType: FlowType;
  syntax: SyntaxType;
};

export interface AiAssistantProps {
  options: Array<AiAssistantOptionType>;
  onClose: () => void;
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string | null,
  ) => void;
  onOptionSelected: () => void;
  maxHeight: number;
  scope: ScopeType;

  // contextual data
  initialConfig?: Record<string, unknown>;
  selectedText?: string;
  editorContents?: string;
  expectedDataFormat?: string;
  integrationId?: string;
  configurationId?: string;
  pluginId?: string;
  firstLineNumber?: number;
  datasourceMeta?: DatasourceMetadataDto;
}
