import { useCallback } from "react";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { useAppDispatch } from "store/helpers";

// TODO: Could be expanded into a toggle sidebar hook which lets you pass an option of Toggle/Open/Close
export default function useOpenSidebar(sidebarKey: SideBarKeys) {
  const dispatch = useAppDispatch();

  const open = useCallback(() => {
    dispatch(
      updateApplicationSidebarKey({
        selectedKey: SideBarKeys.DEVELOPER_PREFERENCES,
      }),
    );
  }, [dispatch]);

  return open;
}
