import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension, Padding, PageDSL8 } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import {
  MAX_SAVE_FAILURES,
  OccupiedSpace,
} from "legacy/constants/editorConstants";
import CanvasWidgetsNormalizer from "legacy/normalizers/CanvasWidgetsNormalizer";
import { DynamicProperties } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  getDataTreeItem,
  isEntityLoading,
} from "legacy/selectors/dataTreeSelectors";
import {
  getCanvasWidget,
  getCanvasWidgets,
} from "legacy/selectors/entitiesSelector";
import { doesWidgetContainFillParentSection } from "legacy/widgets/base/sizing";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
  pickStaticProps,
  type WidgetLayoutProps,
} from "legacy/widgets/shared";
import { selectFlags } from "store/slices/featureFlags/selectors";
import { type AppState } from "store/types";
import { fastClone } from "utils/clone";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { isDev } from "utils/env";
import logger from "utils/logger";
import { recalculateWidgetsLayout, hasLeftRightProperties } from "utils/size";
import {
  GridDefaults,
  WidgetTypes,
  PAGE_WIDGET_ID,
  Hierarchy,
  Position,
  PositionStickyBehavior,
} from "../constants/WidgetConstants";
import { getAppMode, getResponsiveCanvasWidth } from "./applicationSelectors";
import { getResizingStackWidgetWidth } from "./dndSelectors";
import { applyStaticProps } from "./editor/applyStaticProps";
import {
  getDynamicLayoutWidget,
  getDynamicLayoutWidgets,
} from "./layoutSelectors";
import { createMarkedSelector, markFunction } from "./markedSelector";
import { getWidgetMetaProps, getPageDSLVersion } from "./sagaSelectors";
import { selectGeneratedTheme } from "./themeSelectors";
import { getDynamicVisibilityWidgets } from "./visibilitySelectors";
import type { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import type { PageListReduxState } from "legacy/reducers/entityReducers/pageListReducer";
import type { DynamicLayoutProps } from "legacy/widgets/BaseWidget";

const getPersistedWidgets = (state: AppState): CanvasWidgetsReduxState =>
  state.legacy.entities.canvasWidgets;

export const isLocalDevModeEnabled = (state: AppState) => {
  if (getAppMode(state) !== APP_MODE.EDIT) return false;
  return state.legacy.ui.editor.localDevModeEnabled;
};

export const getCurrentBranch = (state: AppState) => {
  return state.legacy.ui.editor.branch;
};

export const getEditorReadOnly = (state: AppState) => {
  if (getIsPageLoadingError(state)) return true;
  return getCurrentBranch(state)?.protected ?? false;
};

export const getLastHotReloadTime = (state: AppState) => {
  return state.legacy.ui.editor.localDevLastHotReloadTime;
};

export const getLocalDevServerState = (state: AppState) => {
  return state.legacy.ui.editor.localDevServerState;
};

export const shouldShowCustomComponentModal = (state: AppState) => {
  return state.legacy.ui.editor.showCustomComponentModal;
};

export const getDeleteRouteConfirmationModal = (state: AppState) => {
  return state.legacy.ui.editor.deleteRouteConfirmationModal;
};

export const getPasteWidgetConfirmationInfo = (state: AppState) => {
  return state.legacy.ui.editor.pasteConfirmationInfo;
};

export const getIsEditorInitialized = (state: AppState) =>
  state.legacy.ui.editor.initialized;

export const getIsFetchingPage = (state: AppState) =>
  state.legacy.ui.editor.loadingStates.isPageSwitching;

export const getIsPageSaving = (state: AppState) => {
  return state.legacy.ui.editor.loadingStates.saving;
};
export const getIsPageLoadingError = (state: AppState) =>
  state.legacy.ui.editor.loadingStates.pageSwitchingError;

export const getIsPageDirty = (state: AppState) =>
  !state.legacy.ui.editor.loadingStates.staleUpdateError &&
  (state.legacy.ui.editor.loadingStates.dirty ||
    state.legacy.ui.editor.loadingStates.saving);

export const getStalePageUpdateError = (state: AppState) => {
  return state.legacy.ui.editor.loadingStates.staleUpdateError;
};

export const getPageSavingError = (state: AppState) => {
  return state.legacy.ui.editor.loadingStates.savingError;
};

export const getPageSavingFailuresMaxReached = (state: AppState) => {
  return (
    state.legacy.ui.editor.loadingStates.savingFailuresCount >=
    MAX_SAVE_FAILURES
  );
};

export const getPageList = createSelector(
  (state: AppState) => state.legacy.entities.pageList.pages,
  (pages) =>
    [...pages].sort((page1, page2) =>
      page1.pageName.localeCompare(page2.pageName),
    ),
);

export const getCurrentPageId = (state: AppState) =>
  state.legacy.entities.pageList.currentPageId;

export const getCurrentApplicationId = (state: AppState) =>
  state.legacy.entities.pageList.applicationId;

export const getCurrentPageVersion = (state: AppState): number | undefined =>
  state?.legacy?.ui?.editor?.currentPageVersion;

export const getCurrentPageName = createMarkedSelector("getCurrentPageName")(
  (state: AppState) => state.legacy.entities.pageList,
  (pageList: PageListReduxState) =>
    pageList.pages.find((page) => page.pageId === pageList.currentPageId)
      ?.pageName,
);

export const getSingleWidgetProps = createCachedSelector(
  (_: AppState, widgetId: string) => getCanvasWidget(_, widgetId),
  (_: AppState, widgetId: string) => getDataTreeItem(_, widgetId),
  (_: AppState, widgetId: string) => isEntityLoading(_, widgetId),
  (_: AppState, widgetId: string) => getWidgetMetaProps(_, widgetId),
  (_: AppState, widgetId: string) => getDynamicLayoutWidget(_, widgetId),
  markFunction(
    "getSingleWidgetProps",
    (canvasWidget, evaluatedWidget, isLoading, widgetMeta, dynamicInfo) => {
      // Widgets can come back from the evaluated tree without being evaluated.
      // For example: when they are behind a slide-out that is not open and those items are not depended on.
      // When this happens we use a loading widget.
      const hasBeenFullEvaluated = !evaluatedWidget?.skippedEvaluation;
      if (canvasWidget && evaluatedWidget && hasBeenFullEvaluated) {
        const widget = createCanvasWidget(
          canvasWidget,
          evaluatedWidget,
          widgetMeta,
          dynamicInfo,
        );
        widget.isLoading = isLoading;
        return widget;
      } else {
        return createLoadingWidget(canvasWidget);
      }
    },
  ),
)((_: AppState, widgetId: string) => widgetId);

export const getWidgetParentIdsHelper = (
  widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
  widgetId: string,
): string[] => {
  const parents = [];
  let currentParent = widgets[widgetId]?.parentId;
  while (currentParent) {
    parents.push(currentParent);
    currentParent = widgets[currentParent]?.parentId;
  }
  return parents;
};

export const getWidgetParentIds = createCachedSelector(
  getCanvasWidgets,
  (_: AppState, widgetId: string | undefined) => widgetId,
  (widgets, widgetId) => {
    if (!widgetId) return [];
    return getWidgetParentIdsHelper(widgets, widgetId);
  },
)((_: AppState, widgetId: string | undefined) => widgetId);

export const getMainContainerWidgetId = createMarkedSelector(
  "getMainContainerWidgetId",
)(
  getPageDSLVersion,
  getCanvasWidgets,
  selectFlags,
  (dslVersion, widgets, flags): string => {
    return PAGE_WIDGET_ID;
  },
);

const getPageWidget = createMarkedSelector("getPageWidget")(
  getCanvasWidgets,
  (widgets) => widgets[PAGE_WIDGET_ID] as PageDSL8 | undefined,
);

export const getPageWidth = createMarkedSelector("getPageWidth")(
  getPageWidget,
  selectFlags,
  (page, flags): { minWidth?: Dimension<"px">; maxWidth?: Dimension<"px"> } => {
    const widthInfo = {
      minWidth: page?.minWidth,
      maxWidth: page?.maxWidth,
    };
    return widthInfo;
  },
);

// This selector is used to get the DSL skeleton(widget hierarchy) of the current page
// instead of returning the entire DSL, we return only the skeleton to allow
// result equality checks to be more efficient
const getDSLHierarchy = createMarkedSelector("getDSLSkeleton", {
  resultEqualityCheck: equal,
})(getCanvasWidgets, (widgets): Hierarchy | undefined => {
  const denormalizedWidgets = CanvasWidgetsNormalizer.denormalize(
    PAGE_WIDGET_ID,
    {
      canvasWidgets: widgets,
    },
  ) as unknown as Hierarchy;
  if (!denormalizedWidgets) return undefined;

  const toSkeleton = (widget: Hierarchy): Hierarchy => {
    return {
      widgetId: widget?.widgetId,
      type: widget?.type,
      widgetName: widget?.widgetName,
      children: widget?.children?.map((c) => toSkeleton(c)),
    };
  };
  return toSkeleton(denormalizedWidgets);
});

export const gridDescendants = createMarkedSelector("gridDescendants")(
  getDSLHierarchy,
  (dsl) => {
    const descendants = new Set<string>();
    const traverse = (widget: Hierarchy, parentIsGrid: boolean) => {
      if (parentIsGrid) {
        descendants.add(widget.widgetId);
      }

      if (widget.type === WidgetTypes.GRID_WIDGET) {
        widget.children?.forEach((child) => {
          traverse(child, true);
        });
      } else if (
        widget.type === WidgetTypes.MODAL_WIDGET ||
        widget.type === WidgetTypes.SLIDEOUT_WIDGET
      ) {
        // These widgets are not part of the grid layout because they are detached
        widget.children?.forEach((child) => {
          traverse(child, false);
        });
      } else {
        widget.children?.forEach((child) => {
          traverse(child, parentIsGrid);
        });
      }
    };
    if (dsl) {
      traverse(dsl, false);
    }
    return descendants;
  },
);

// These are used to cache the previous result of @getStaticStructure
const staticStructureCache = {
  widgets: {} as ReturnType<typeof getCanvasWidgets>,
  skeleton: undefined as ReturnType<typeof getDSLHierarchy>,
  width: 0 as ReturnType<typeof getResponsiveCanvasWidth>,
  theme: undefined as ReturnType<typeof selectGeneratedTheme> | undefined,
  dynamicWidgets: {} as ReturnType<typeof getDynamicLayoutWidgets>,
  dynamicVisibilityWidgets: {} as ReturnType<
    typeof getDynamicVisibilityWidgets
  >,
  clearCache: () => {
    staticStructureCache.skeleton = undefined;
    staticStructureCache.widgets = {};
    staticStructureCache.width = 0;
    staticStructureCache.theme = undefined;
    staticStructureCache.dynamicWidgets = {};
    staticStructureCache.dynamicVisibilityWidgets = {};
  },
};

export const getStaticStructure = createMarkedSelector("getStaticStructure")(
  getDSLHierarchy,
  getCanvasWidgets,
  getResponsiveCanvasWidth,
  selectGeneratedTheme,
  selectFlags,
  getDynamicLayoutWidgets,
  getDynamicVisibilityWidgets,
  getResizingStackWidgetWidth,
  getAppMode,
  (
    skeleton,
    widgets,
    width,
    theme,
    flags,
    _dynamicLayoutWidgets,
    dynamicVisibilityWidgets,
    resizingStackWidget,
    appMode,
  ): WidgetLayoutProps | undefined => {
    const previousResult = getStaticStructure.lastResult() as WidgetLayoutProps;
    if (skeleton === undefined || isEmpty(widgets)) {
      return undefined;
    }
    let dynamicLayoutWidgets = _dynamicLayoutWidgets;
    if (
      appMode === APP_MODE.EDIT &&
      resizingStackWidget?.canvasId &&
      widgets[resizingStackWidget.canvasId]?.type ===
        WidgetTypes.CANVAS_WIDGET &&
      resizingStackWidget?.width
    ) {
      dynamicLayoutWidgets = {
        ..._dynamicLayoutWidgets,
        [resizingStackWidget.canvasId]: {
          ..._dynamicLayoutWidgets[resizingStackWidget.canvasId],
          width: Dimension.px(resizingStackWidget.width),
        },
      };
    }

    // if the width or skeleton has changed, we need to recalculate
    const needsNewObject =
      staticStructureCache.skeleton !== skeleton ||
      staticStructureCache.theme !== theme ||
      staticStructureCache.width !== width;

    const target = needsNewObject ? fastClone(skeleton) : { ...previousResult };

    if (isDev() && isEmpty(target)) {
      logger.error(
        `target is empty, skeleton:${JSON.stringify(skeleton)}
        previousResult: ${JSON.stringify(previousResult)}`,
      );
    }

    const denormalizedWidgets = applyStaticProps(
      widgets,
      staticStructureCache.widgets,
      dynamicVisibilityWidgets,
      staticStructureCache.dynamicVisibilityWidgets,
      dynamicLayoutWidgets,
      staticStructureCache.dynamicWidgets,
      target,
      needsNewObject,
    );

    if (denormalizedWidgets) {
      const xPadding = Padding.x(denormalizedWidgets.padding).value;

      denormalizedWidgets.parentColumnSpace =
        (width - xPadding) / GridDefaults.DEFAULT_GRID_COLUMNS;
      denormalizedWidgets.width = Dimension.px(width - xPadding);
      denormalizedWidgets.left = Dimension.gridUnit(0);
      denormalizedWidgets.gridColumns = GridDefaults.DEFAULT_GRID_COLUMNS;
      recalculateWidgetsLayout({
        widget: denormalizedWidgets,
        responsiveCanvasScaledWidth: width,
        theme,
        flags,
        appMode: appMode || APP_MODE.PUBLISHED,
        widthHydrationContext: {
          visited: new Set<string>(),
          overridenCanvasId: resizingStackWidget?.canvasId,
        },
      });
    }
    staticStructureCache.widgets = { ...widgets };
    staticStructureCache.dynamicVisibilityWidgets = {
      ...dynamicVisibilityWidgets,
    };
    staticStructureCache.dynamicWidgets = { ...dynamicLayoutWidgets };
    staticStructureCache.skeleton = skeleton;
    staticStructureCache.theme = theme;
    staticStructureCache.width = width;

    return denormalizedWidgets;
  },
);

export const getFlattenedCanvasWidgets = createMarkedSelector(
  "getFlattenedCanvasWidgets",
)(getStaticStructure, (dsl) => {
  const flattenedCanvasWidgets = CanvasWidgetsNormalizer.normalize(
    dsl as any,
  ) as any;
  return flattenedCanvasWidgets.entities
    .canvasWidgets as FlattenedWidgetLayoutMap;
});

export const getFlattenedCanvasWidget = createCachedSelector(
  getFlattenedCanvasWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (widgets, widgetId) => {
    return widgets[widgetId] as unknown as FlattenedWidgetLayoutProps;
  },
)((_: AppState, widgetId: string) => widgetId);

export const getParentNonDetachedChildren = createCachedSelector(
  getFlattenedCanvasWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (widgets, widgetId) => {
    const parent = widgets[widgetId] as unknown as FlattenedWidgetLayoutProps;

    if (!parent || !parent.children) return [];

    return parent.children
      .map((childId) => (widgets[childId].detachFromLayout ? null : childId))
      .filter((child) => child !== null);
  },
)((_: AppState, widgetId: string) => widgetId);

export const getParentHasFillParentChild = createCachedSelector(
  getFlattenedCanvasWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (widgets, widgetId) => {
    return doesWidgetContainFillParentSection(widgets, widgetId);
  },
)((_: AppState, widgetId: string) => widgetId);

export const getFlattenedCanvasWidgetsByName = createMarkedSelector(
  "getFlattenedCanvasWidgetsByName",
)(getFlattenedCanvasWidgets, (flattenedWidgets) => {
  return Object.fromEntries(
    Object.values(flattenedWidgets).map((widget) => [
      widget.widgetName,
      widget,
    ]),
  );
});

const getOccupiedSpacesForContainer = (
  containerWidgetId: string,
  widgets: FlattenedWidgetProps[],
): OccupiedSpace[] => {
  return widgets.map((widget) => {
    if (!hasLeftRightProperties(widget)) throw Error("");
    const occupiedSpace: OccupiedSpace = {
      id: widget.widgetId,
      parentId: containerWidgetId,
      left: widget.left.value,
      top: widget.top.value,
      bottom: Dimension.add(
        widget.top,
        Dimension.toGridUnit(
          widget.height,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).roundUp(),
      ).value,
      right: Dimension.add(
        widget.left,
        widget.width,
        widget.parentColumnSpace,
      ).asFirst().value, // as first because right mode should be the same as left mode
    };
    return occupiedSpace;
  });
};

// same as getOccupiedSpaces but gets only the container specific occupied Spaces
export function getOccupiedSpacesSelectorForContainer(
  containerId: string | undefined,
) {
  return createSelector(
    getPersistedWidgets,
    (widgets: CanvasWidgetsReduxState): OccupiedSpace[] | undefined => {
      if (containerId === null || containerId === undefined) return undefined;

      const containerWidget: FlattenedWidgetProps = widgets[containerId];

      if (!containerWidget || !containerWidget.children) return undefined;

      // Get child widgets for the container
      const childWidgets = Object.keys(widgets).filter(
        (widgetId) =>
          containerWidget.children &&
          containerWidget.children.indexOf(widgetId) > -1 &&
          !widgets[widgetId].detachFromLayout,
      );

      const occupiedSpaces = getOccupiedSpacesForContainer(
        containerId,
        childWidgets.map((widgetId) => widgets[widgetId]),
      );
      return occupiedSpaces;
    },
  );
}

const createCanvasWidget = (
  canvasWidget: FlattenedWidgetProps,
  evaluatedWidget: DataTreeWidget,
  widgetMeta: Record<string, unknown>,
  dynamicInfo: DynamicProperties,
): FlattenedWidgetProps & DataTreeWidget & DynamicLayoutProps => {
  const widgetStaticProps = pickStaticProps(canvasWidget);

  return {
    ...evaluatedWidget,
    ...widgetMeta,
    ...widgetStaticProps,
    dynamicWidgetLayout: {
      height: dynamicInfo?.height,
    },
  };
};

const createLoadingWidget = (
  canvasWidget?: FlattenedWidgetProps,
): ReturnType<typeof createCanvasWidget> => {
  const widgetStaticProps = canvasWidget
    ? pickStaticProps(canvasWidget)
    : { type: WidgetTypes.SKELETON_WIDGET };
  return {
    ...widgetStaticProps,
    type: WidgetTypes.SKELETON_WIDGET,
    loadedType: widgetStaticProps.type,
    ENTITY_TYPE: ENTITY_TYPE.WIDGET,
    bindingPaths: {},
    isLoading: true,
    dynamicWidgetLayout: {
      height: undefined,
    },
  } as unknown as ReturnType<typeof createCanvasWidget>;
};

// clears all the selectors caching
export const clearSelectorCache = () => {
  getSingleWidgetProps.clearCache();
  getDSLHierarchy.clearCache();
  getStaticStructure.clearCache();
  staticStructureCache.clearCache();
};

export const getIsLeftPanePinned = (state: AppState) =>
  state.legacy.ui.editor.layout.isLeftPanePinned;

const SECTIONS_GUTTER_SIZE = 1; // Gutters disabled in layouts for now.

export const getCanvasGutter = createSelector(
  getAppMode,
  selectFlags,
  (mode, flags) => {
    return mode === APP_MODE.EDIT ? SECTIONS_GUTTER_SIZE : 0;
  },
);

export const getStickySectionTopValuePx = createCachedSelector(
  getFlattenedCanvasWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (widgets, sectionWidgetId) => {
    const parentId = widgets[sectionWidgetId]?.parentId;
    const pageWidget = widgets[parentId];
    const pageChildren = pageWidget?.children || [];

    const sectionWidgetIndex = pageChildren.indexOf(sectionWidgetId);

    if (sectionWidgetIndex === 0) {
      return 0;
    }

    // Calculate the total height of all sections above this one in the page
    let totalHeightOfSectionsAbove = 0;
    for (let i = 0; i < sectionWidgetIndex; i++) {
      const section = widgets[pageChildren[i]];

      if (
        section?.position === Position.STICKY &&
        section?.stickyBehavior === PositionStickyBehavior.STACK
      ) {
        totalHeightOfSectionsAbove += Dimension.toPx(
          section.height,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).value;
      }
    }

    return totalHeightOfSectionsAbove;
  },
)((_: AppState, widgetId: string) => widgetId);
