import { Tabs } from "antd";
import React, { useCallback, useEffect } from "react";

import { useNavigate, useParams } from "react-router";
import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper } from "components/ui/Page";
import { useFeatureFlag } from "hooks/ui";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { PERMISSION_PAGE_URL, ROLES_AND_PERMISSIONS_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import EntityList from "./EntityList";
import IntegrationList from "./IntegrationList";
import { isValidPageType, RoleAndPermissionsPages } from "./constants";

const PermissionsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pageType = isValidPageType(params.pageType)
    ? params.pageType
    : "entities";

  const handleTabChange = useCallback(
    (key: string) => {
      navigate({ pathname: PERMISSION_PAGE_URL(key) });
    },
    [navigate],
  );

  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2_ENFORCEMENT);

  useEffect(() => {
    if (rbacV2Enabled) {
      navigate({
        pathname: ROLES_AND_PERMISSIONS_URL(
          RoleAndPermissionsPages.ORGANIZATION_ROLES,
        ),
      });
    }
  }, [rbacV2Enabled, navigate]);

  return (
    <PageWrapper pageName="Permissions">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> Permissions </div>
            <div className="page-header-description">
              Grant users or groups permissions to your Superblocks Apps,
              Workflows, Jobs, and Integrations to ensure they have access to
              the tools they need in Superblocks.{" "}
              <a
                href="https://docs.superblocks.com/account-management-and-permissions/role-based-access-control"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <Tabs activeKey={pageType} onTabClick={handleTabChange}>
            <Tabs.TabPane tab="Apps, Workflows & Jobs" key="entities">
              <EntityList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Integrations" key="integrations">
              <IntegrationList />
            </Tabs.TabPane>
          </Tabs>
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default PermissionsPage;
