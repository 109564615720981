import { omit } from "lodash";
import {
  PropsPanelCategory,
  PropertyPaneSectionConfig,
  type PropertyPaneConfig,
  PropertyPaneCategoryConfig,
  PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import {
  isPropertyPaneCategoryConfig,
  isPropertyPaneControlConfig,
  isPropertyPaneSectionConfig,
} from "legacy/utils/PropertyControlUtils";
import { fastClone } from "utils/clone";

// TODO move to propertyPaneCategoryUtils when we remove this file
export const CATEGORY_PROPERTIES: Record<
  PropsPanelCategory,
  {
    order: number;
    title: string;
  }
> = {
  [PropsPanelCategory.Uncategorized]: {
    order: 0,
    title: "General",
  },
  [PropsPanelCategory.Routing]: {
    order: 1,
    title: "Routing",
  },
  [PropsPanelCategory.Content]: {
    order: 2,
    title: "Content",
  },
  [PropsPanelCategory.Appearance]: {
    order: 3,
    title: "Appearance",
  },
  [PropsPanelCategory.Layout]: {
    order: 4,
    title: "Layout",
  },
  [PropsPanelCategory.Interaction]: {
    order: 5,
    title: "Interaction",
  },
  [PropsPanelCategory.Permissions]: {
    order: 6,
    title: "Permissions",
  },
  [PropsPanelCategory.EventHandlers]: {
    order: 7,
    title: "Event handlers",
  },
};

// These are just components we think are primarily used for layout so we want to show a different props panel order
const LAYOUT_COMPONENTS = [
  WidgetTypes.CANVAS_WIDGET,
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.TABS_WIDGET,
  WidgetTypes.SECTION_WIDGET,
  WidgetTypes.FORM_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
];

const getLayoutComponentsCategoryProperties = () => {
  const layoutCategoryProperties = fastClone(CATEGORY_PROPERTIES);

  layoutCategoryProperties[PropsPanelCategory.Layout].order = 3;
  layoutCategoryProperties[PropsPanelCategory.Appearance].order = 4;

  return layoutCategoryProperties;
};

const LAYOUT_COMPONENTS_CATEGORY_PROPERTIES =
  getLayoutComponentsCategoryProperties();

const SUPPRESS_EXISTING_COLLAPSE = true;

// converts an "old" property pane config to v2 format
export function upgradePropertyConfig(
  type: WidgetTypes,
  originalConfig: readonly PropertyPaneConfig[],
): readonly PropertyPaneConfig[] {
  if (originalConfig.every(isPropertyPaneCategoryConfig)) {
    return originalConfig; // then this is already a v2 config
  }

  const categoryPropertiesMeta = LAYOUT_COMPONENTS.includes(type)
    ? LAYOUT_COMPONENTS_CATEGORY_PROPERTIES
    : CATEGORY_PROPERTIES;

  const transformSectionIfNeeded = (
    config: PropertyPaneSectionConfig,
  ): PropertyPaneSectionConfig => {
    let sectionConfig: PropertyPaneSectionConfig = config;
    if (
      ((sectionConfig.showHeader && sectionConfig.headerType === "Collapse") ||
        sectionConfig.isDefaultOpen != null) &&
      SUPPRESS_EXISTING_COLLAPSE
    ) {
      sectionConfig = {
        ...sectionConfig,
        showHeader: false,
        headerType: undefined,
        isDefaultOpen: undefined,
      };
      return sectionConfig;
    }
    if (sectionConfig.sectionCategory === PropsPanelCategory.EventHandlers) {
      // get rid of the headerType of "Add" for event handler sections
      sectionConfig = {
        ...sectionConfig,
        headerType: undefined,
        showHeader: false,
      };
    }
    return sectionConfig;
  };

  const transformPropertyControlIfNeeded = (
    config: PropertyPaneControlConfig,
    parentConfig?: PropertyPaneSectionConfig,
  ): PropertyPaneControlConfig => {
    const isEventHandlerSection =
      parentConfig?.sectionCategory === PropsPanelCategory.EventHandlers;
    let controlConfig: PropertyPaneControlConfig = config;
    if (config.panelConfig) {
      const panelConfigChildren = upgradePropertyConfig(
        type,
        config.panelConfig.children,
      );

      controlConfig = {
        ...config,
        panelConfig: {
          ...config.panelConfig,
          children: panelConfigChildren as PropertyPaneControlConfig[],
        },
      };
    }
    if (isEventHandlerSection) {
      // get rid of the visibility config so that all event handlers show up
      controlConfig = omit(controlConfig, ["visibility"]);
    }
    return controlConfig;
  };

  const categorizeSection = (config: PropertyPaneSectionConfig) => {
    const sectionCategory = config.sectionCategory;

    const configsByCategory: Record<string, PropertyPaneSectionConfig> = {};
    config.children.forEach((_childConfig) => {
      const childConfig = transformPropertyControlIfNeeded(
        _childConfig,
        config,
      );
      const childCategory =
        childConfig.propertyCategory ??
        sectionCategory ??
        PropsPanelCategory.Uncategorized;
      // we're splitting children of a section that have different categories into separate sections
      configsByCategory[childCategory] ??= {
        ...config,
        sectionCategory: childCategory,
        children: [],
      };
      configsByCategory[childCategory]?.children?.push(childConfig);
    });

    return configsByCategory;
  };

  const categorizeProperty = (controlConfig: PropertyPaneControlConfig) => {
    const category =
      controlConfig.propertyCategory ?? PropsPanelCategory.Uncategorized;
    return { [category]: controlConfig };
  };

  const categorizeConfig = (
    config: PropertyPaneConfig,
  ): Record<string, PropertyPaneConfig> => {
    if (isPropertyPaneSectionConfig(config)) {
      return categorizeSection(transformSectionIfNeeded(config));
    } else if (isPropertyPaneControlConfig(config)) {
      return categorizeProperty(transformPropertyControlIfNeeded(config));
    } else {
      throw new Error("Invalid property config encountered");
    }
  };

  const configsByCategory: Record<string, PropertyPaneConfig[]> = {};

  originalConfig.forEach((config) => {
    const processedConfig = categorizeConfig(config);
    Object.entries(processedConfig).forEach(([category, config]) => {
      configsByCategory[category] ??= [];
      configsByCategory[category].push(config);
    });
  });

  const onlyOneCategory = Object.keys(configsByCategory).length === 1;

  const propertyConfigs = Object.keys(configsByCategory).map((category) => {
    const categoryProperties =
      categoryPropertiesMeta[category as PropsPanelCategory] ??
      categoryPropertiesMeta[PropsPanelCategory.Uncategorized];

    if (!categoryProperties) {
      throw new Error(`Invalid category encountered: ${category}`);
    }

    const configs = configsByCategory[category];

    const noHeader =
      onlyOneCategory || category === PropsPanelCategory.Uncategorized;

    const categoryConfig: PropertyPaneCategoryConfig = {
      categoryName: categoryProperties.title,
      key: category,
      showHeader: noHeader ? false : true,
      sectionCategory: category as PropsPanelCategory,
      headerType: noHeader ? undefined : "Collapse",
      children: configs,
    };

    return categoryConfig;
  });

  const sortedConfigs = propertyConfigs.sort((a, b) => {
    return (
      categoryPropertiesMeta[
        a.sectionCategory ?? PropsPanelCategory.Uncategorized
      ].order -
      categoryPropertiesMeta[
        b.sectionCategory ?? PropsPanelCategory.Uncategorized
      ].order
    );
  });

  return sortedConfigs;
}
