import {
  PostApiCommitCreateRequestBody,
  PostApplicationCommitCreateRequestBody,
} from "@superblocksteam/schemas";
import {
  GetApiCommitsResponseBody,
  GetApplicationCommitsResponseBody,
  GetCommitsCompareResponseBody,
  PostApiRemoteCommitCreateResponseBody,
  PostApplicationRemoteCommitCreateResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { getUrlWithBranch } from "utils/urlWithBranch";
import {
  callServer,
  ErrorHandlingOptions,
  HttpMethod,
} from "../../utils/client";

export function getCommits(
  entityId: string,
  entityType: VersionedEntityType,
  // TODO: get rid of the `| undefined` when branch is always present
  branch: string | undefined,
): Promise<GetApplicationCommitsResponseBody | GetApiCommitsResponseBody> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer<GetApplicationCommitsResponseBody>({
        method: HttpMethod.Get,
        url: getUrlWithBranch(
          `v2/applications/${entityId}`,
          branch,
          "/commits",
        ),
      });
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer<GetApiCommitsResponseBody>({
        method: HttpMethod.Get,
        url: getUrlWithBranch(`v3/apis/${entityId}`, branch, "/commits"),
      });
  }
}

export function createCommit(
  entityId: string,
  entityType: VersionedEntityType,
  commitMessage: string,
  lastSuccessfulWrite: number,
  // TODO: make branch required
  branch: string | undefined,
): Promise<
  PostApplicationCommitCreateRequestBody | PostApiCommitCreateRequestBody
> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer<PostApplicationCommitCreateRequestBody>(
        {
          method: HttpMethod.Post,
          url: getUrlWithBranch(
            `v2/applications/${entityId}`,
            branch,
            "/commit",
          ),
          body: { commitMessage, lastSuccessfulWrite },
        },
        {
          notifyOnError: false,
          shouldCrashApp: false,
          onError: (err) => {
            throw err;
          },
        },
      );
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer<PostApiCommitCreateRequestBody>({
        method: HttpMethod.Post,
        url: getUrlWithBranch(`v3/apis/${entityId}`, branch, "/commit"),
        body: { commitMessage },
      });
  }
}

export function createCommitRemote(
  entityId: string,
  entityType: VersionedEntityType,
  commitId: string,
  errorHandlingOptions?: ErrorHandlingOptions,
): Promise<
  | PostApplicationRemoteCommitCreateResponseBody
  | PostApiRemoteCommitCreateResponseBody
> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer<PostApplicationRemoteCommitCreateResponseBody>(
        {
          method: HttpMethod.Post,
          url: `v2/applications/${entityId}/commit/${commitId}/remote`,
        },
        errorHandlingOptions,
      );
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer<PostApiRemoteCommitCreateResponseBody>(
        {
          method: HttpMethod.Post,
          url: `v3/apis/${entityId}/commit/${commitId}/remote`,
        },
        errorHandlingOptions,
      );
  }
}

export function resetEditor(
  entityId: string,
  entityType: VersionedEntityType,
  // TODO: remove `| undefined` when branch is always present
  branch: string | undefined,
  commitId: string,
): Promise<
  PostApplicationCommitCreateRequestBody | PostApiCommitCreateRequestBody
> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer<PostApplicationCommitCreateRequestBody>({
        method: HttpMethod.Post,
        url: getUrlWithBranch(`v2/applications/${entityId}`, branch, "/reset"),
        body: { commitId },
      });
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer<PostApiCommitCreateRequestBody>({
        method: HttpMethod.Post,
        url: getUrlWithBranch(`v3/apis/${entityId}`, branch, "/reset"),
        body: { commitId },
      });
  }
}

export function compareCommits(
  entityId: string,
  entityType: VersionedEntityType,
  // TODO: remove `| null` when branch is always present
  branch: string | undefined,
  fromCommit = "",
  toCommit = "",
): Promise<GetCommitsCompareResponseBody> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer({
        method: HttpMethod.Get,
        url: getUrlWithBranch(
          `v2/applications/${entityId}`,
          branch,
          "/commits/compare",
        ),
        query: { fromCommit, toCommit },
      });
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer({
        method: HttpMethod.Get,
        url: getUrlWithBranch(
          `v3/apis/${entityId}`,
          branch,
          "/commits/compare",
        ),
        query: { fromCommit, toCommit },
      });
  }
}
