import { SettingOutlined } from "@ant-design/icons";
import {
  AuditLogDetails,
  AuditLogDto,
  AuditLogEntity,
  AuditLogEntityType,
  GetAuditLogsEntitiesResponseBody,
  OrganizationUserStatus,
  AuditLogStatusFilter,
} from "@superblocksteam/shared";
import { Button, DatePicker, Radio, Row, Space, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment, { Moment } from "moment-timezone";
import { RangeValue } from "rc-picker/lib/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled, { useTheme } from "styled-components";
import { ReactComponent as ClearIcon } from "assets/icons/common/clear.svg";

import { Layout, MainWrapper } from "components/app";
import { HeaderWrapper } from "components/ui/Page";
import { RecommendedMultiDropdown } from "components/ui/RecommendedMultiDropdown";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { Spinner } from "components/ui/Spinner";
import { Switch } from "components/ui/Switch";
import { getUsers } from "pages/Permissions/client";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { AUDIT_LOGS_TITLE, PageWrapper } from "pages/components/PageWrapper";
import { selectOrganizations } from "store/slices/organizations";
import { HttpMethod, buildQueryString, callServer } from "store/utils/client";
import { UIEvent } from "utils/event";
import logger from "utils/logger";
import { sendErrorUINotification } from "utils/notification";
import LogTable, { columns } from "./LogTable";

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

const { RangePicker } = DatePicker;

type AuditEntityOption = DropdownOption & AuditLogEntity;

const defaultAudiEntityOption = {
  value: "*",
  displayName: "*",
  key: "*",
  id: "*",
  name: "*",
  type: 0,
  applicationId: "*",
};

enum AppTypeFilter {
  ALL = "All",
  APP = "Applications",
  WORKFLOW = "Workflows",
  SCHEDULE = "Scheduled Jobs",
}

// TODO(pbardea): Update the radio buttons to be generated based on this enum.
enum DeployedModeFilter {
  ALL = "All",
  DEPLOYED = "Deployed",
  TESTING = "Testing",
}

enum DurationDescription {
  MIN_15 = "15 minutes",
  HOUR_1 = "1 hour",
  HOUR_2 = "2 hours",
  DAY = "Day",
  DAY_7 = "7 days",
  CUSTOM = "Custom",
}

enum DurationShorthand {
  MIN_15 = "15m",
  HOUR_1 = "1h",
  HOUR_2 = "2h",
  DAY = "1d",
  DAY_7 = "7d",
  CUSTOM = "custom",
}

const durationShorthandToDescription: Record<
  DurationShorthand,
  DurationDescription
> = {
  [DurationShorthand.MIN_15]: DurationDescription.MIN_15,
  [DurationShorthand.HOUR_1]: DurationDescription.HOUR_1,
  [DurationShorthand.HOUR_2]: DurationDescription.HOUR_2,
  [DurationShorthand.DAY]: DurationDescription.DAY,
  [DurationShorthand.DAY_7]: DurationDescription.DAY_7,
  [DurationShorthand.CUSTOM]: DurationDescription.CUSTOM,
};
const durationDescriptionToShorthand: Record<
  DurationDescription,
  DurationShorthand
> = {
  [DurationDescription.MIN_15]: DurationShorthand.MIN_15,
  [DurationDescription.HOUR_1]: DurationShorthand.HOUR_1,
  [DurationDescription.HOUR_2]: DurationShorthand.HOUR_2,
  [DurationDescription.DAY]: DurationShorthand.DAY,
  [DurationDescription.DAY_7]: DurationShorthand.DAY_7,
  [DurationDescription.CUSTOM]: DurationShorthand.CUSTOM,
};

type AuditLogTableRow = {
  entityId: string;
  entityType: AuditLogEntityType;
  deployed: boolean;
  startTime: Date;
  source?: string;
  details?: AuditLogDetails;
  routePath?: string;

  // The rendered log name.
  logName?: string;
};

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const getStartDateByRelativeRangeOption = (option: DurationDescription) => {
  switch (option) {
    case DurationDescription.MIN_15:
      return moment(new Date()).subtract({ minutes: 15 });
    case DurationDescription.HOUR_1:
      return moment(new Date()).subtract({ hours: 1 });
    case DurationDescription.HOUR_2:
      return moment(new Date()).subtract({ hours: 2 });
    case DurationDescription.DAY:
      return moment(new Date()).subtract({ days: 1 });
    case DurationDescription.DAY_7:
      return moment(new Date()).subtract({ days: 7 });
    default:
      return moment(new Date()).subtract({ days: 1 });
  }
};

const AuditLogs = () => {
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [auditLogs, setAuditLogs] = useState<AuditLogDto[]>([]);

  // dateRange filter & start/end date
  // If startDate and endDate are not defined, set dateRangeOption based on the dateRange query param.
  // Otherwise, set dateRangeOption to custom.
  const startTimeFromUrl = Number(searchParams.get("start"));
  const endTimeFromUrl = Number(searchParams.get("end"));
  const dateRangeFromUrl = searchParams.get("dateRange");
  const dateRangeOptionFromUrl =
    durationShorthandToDescription[dateRangeFromUrl as DurationShorthand];
  const dateRangeOptions = useMemo(() => {
    return [
      {
        value: DurationDescription.MIN_15,
        displayName: "Last 15 minutes",
        key: DurationDescription.MIN_15,
      },
      {
        value: DurationDescription.HOUR_1,
        displayName: "Last 1 hour",
        key: DurationDescription.HOUR_1,
      },
      {
        value: DurationDescription.HOUR_2,
        displayName: "Last 2 hours",
        key: DurationDescription.HOUR_2,
      },
      {
        value: DurationDescription.DAY,
        displayName: "Last day",
        key: DurationDescription.DAY,
      },
      {
        value: DurationDescription.DAY_7,
        displayName: "Last 7 days",
        key: DurationDescription.DAY_7,
      },
      {
        value: DurationDescription.CUSTOM,
        displayName: "Custom",
        key: DurationDescription.CUSTOM,
      },
    ];
  }, []);
  const [dateRangeOption, setDateRangeOption] = useState<DurationDescription>(
    dateRangeOptionFromUrl
      ? (dateRangeOptionFromUrl as DurationDescription)
      : startTimeFromUrl && endTimeFromUrl
        ? DurationDescription.CUSTOM
        : DurationDescription.DAY,
  );

  // TODO: It's not great that this has to be kept in sync with the selector.
  // This should be computed.

  const [customStartTime, setCustomStartTime] = useState(
    startTimeFromUrl
      ? // eslint-disable-next-line no-constant-binary-expression
        new Date(startTimeFromUrl) ||
          getStartDateByRelativeRangeOption(dateRangeOption).toDate()
      : getStartDateByRelativeRangeOption(dateRangeOption).toDate(),
  );
  const [customEndTime, setCustomEndTime] = useState(
    // eslint-disable-next-line no-constant-binary-expression
    endTimeFromUrl ? new Date(endTimeFromUrl) || new Date() : new Date(),
  );

  const onDateRangeChange = useCallback((newDates: RangeValue<Moment>) => {
    if (!newDates) {
      return;
    }
    setCustomStartTime(newDates[0]?.toDate() ?? new Date());
    setCustomEndTime(newDates[1]?.toDate() ?? new Date());
  }, []);

  const onDateRangeOptionChange = useCallback(
    (newOption: DropdownOption) => {
      const now = moment(new Date());
      if (newOption.value === DurationDescription.CUSTOM) {
        onDateRangeChange([moment(customStartTime), moment(customEndTime)]);
      } else {
        onDateRangeChange([
          getStartDateByRelativeRangeOption(
            newOption.value as DurationDescription,
          ),
          now,
        ]);
      }
      setDateRangeOption(newOption.value as DurationDescription);
    },
    [customEndTime, customStartTime, onDateRangeChange],
  );

  //  mode filter
  const modeFromUrl = searchParams.get("mode");
  const [deployedModeFilter, setDeployedModeFilter] =
    useState<DeployedModeFilter>(
      (modeFromUrl as DeployedModeFilter) || DeployedModeFilter.ALL,
    );

  const modeOptions = useMemo(
    () => [
      {
        value: DeployedModeFilter.ALL,
        displayName: "All",
        key: DeployedModeFilter.ALL,
      },
      {
        value: DeployedModeFilter.DEPLOYED,
        displayName: "Deployed",
        key: DeployedModeFilter.DEPLOYED,
      },
      {
        value: DeployedModeFilter.TESTING,
        displayName: "Testing",
        key: DeployedModeFilter.TESTING,
      },
    ],
    [],
  );
  const onDeployModeFilterChange = (option: DropdownOption) => {
    setDeployedModeFilter(option?.value as DeployedModeFilter);
  };

  // type filters
  const typeFromUrl = searchParams.get("type");
  const [appTypeFilter, setAppTypeFilter] = useState<AppTypeFilter>(
    (typeFromUrl as AppTypeFilter) || AppTypeFilter.ALL,
  );

  const onAppTypeChange = (newAppType: AppTypeFilter) => {
    setAppTypeFilter(newAppType);
  };

  // name filter
  const [entitiesForLog, setEntitiesForLog] = useState<AuditLogEntity[]>([]);

  useEffect(() => {
    const loadEntities = async () => {
      const allEntities = await callServer<GetAuditLogsEntitiesResponseBody>({
        method: HttpMethod.Get,
        url: `v2/audit/entities`,
      });
      if (allEntities) {
        setEntitiesForLog(allEntities);
      }
    };
    loadEntities();
  }, []);

  const filteredEntitiesForLog = useMemo(
    () =>
      entitiesForLog?.filter((entity) => {
        switch (appTypeFilter) {
          case AppTypeFilter.APP:
            return (
              entity.type === AuditLogEntityType.APPLICATION ||
              entity.type === AuditLogEntityType.APPLICATION_API
            );
          case AppTypeFilter.WORKFLOW:
            return entity.type === AuditLogEntityType.WORKFLOW;
          case AppTypeFilter.SCHEDULE:
            return entity.type === AuditLogEntityType.SCHEDULED_JOB;
          default:
            return true;
        }
      }),
    [appTypeFilter, entitiesForLog],
  );

  // name filter which will derive audit_entity_id param and audit_entity_type
  const auditEntityIdFromUrl = searchParams.get("audit_entity_id");
  const auditEntityTypeFromUrl = searchParams.get("audit_entity_type");
  const [selectedAuditEntity, setSelectedAuditEntity] = useState<{
    id: string;
    type: number;
  }>(
    auditEntityIdFromUrl && auditEntityTypeFromUrl
      ? { id: auditEntityIdFromUrl, type: Number(auditEntityTypeFromUrl) }
      : {
          id: "*",
          type: 0,
        },
  );

  const entitiesOptions = useMemo(() => {
    const options: AuditEntityOption[] = [];
    filteredEntitiesForLog.forEach((entity) => {
      const option: AuditEntityOption = {
        value: entity.id,
        displayName: entity.name,
        key: entity.id,
        id: entity.id,
        name: entity.name,
        type: entity.type,
        applicationId: entity.applicationId,
      };
      options.push(option);
    });
    return options;
  }, [filteredEntitiesForLog]);

  const onAuditEntitySelect = (option: DropdownOption) => {
    if ((option as AuditEntityOption).id === "*") {
      setSelectedAuditEntity(defaultAudiEntityOption);
    } else {
      setSelectedAuditEntity({
        id: (option as AuditEntityOption).id,
        type: (option as AuditEntityOption).type,
      });
    }
  };

  // source filter
  const sourceFromUrl = searchParams.getAll("source");
  const [sourceFilter, setSourceFilter] = useState(sourceFromUrl);
  const [selectedSourcesOptions, setSelectedSourcesOptions] = useState<
    DropdownOption[]
  >([]);
  const [allSourcesOptions, setAllSourcesOptions] = useState<DropdownOption[]>(
    [],
  );

  const organizations = useSelector(selectOrganizations);
  const organizationId = Object.keys(organizations)[0];

  useEffect(() => {
    (async () => {
      const orgUsers = await getUsers(organizationId);
      const activeUsers = orgUsers.filter(
        (user) => user.status === OrganizationUserStatus.ACTIVE,
      );

      const selectedSources: DropdownOption[] = [];
      setAllSourcesOptions(
        [
          {
            value: "Schedule",
            displayName: "Schedule",
            key: "Schedule",
          },
        ].concat(
          activeUsers.map((user) => {
            const option = {
              value: user.email,
              displayName: user.email,
              key: user.email,
            };
            if (sourceFromUrl.includes(user.email)) {
              selectedSources.push(option);
            }
            return option;
          }),
        ),
      );
      setSelectedSourcesOptions(selectedSources);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const onSourcesChange = useCallback((items: DropdownOption[]) => {
    setSelectedSourcesOptions(items);
    const newSourceFilters = items.map((item) => item.value);
    setSourceFilter(newSourceFilters);
  }, []);

  // status filter
  const statusFromUrl = searchParams.get("status");
  const [statusFilter, setStatusFilter] = useState(
    (statusFromUrl as AuditLogStatusFilter) || AuditLogStatusFilter.ALL,
  );

  const [isLoading, setIsLoading] = useState(false);

  // log view event
  useEffect(() => {
    logger.event(UIEvent.VIEWED_AUDIT_LOGS);
  }, []);

  // to table format
  const tableLogs = useMemo(
    () =>
      auditLogs.map((auditLog: AuditLogDto): AuditLogTableRow => {
        return {
          entityId: auditLog.entityId,
          entityType: auditLog.entityType,
          deployed: auditLog.deployed,
          startTime: auditLog.startTime,
          source: auditLog.source,
          details: auditLog.details,
          logName: auditLog.name ?? auditLog.id ?? "Unknown Name",
          routePath: auditLog.routePath,
        };
      }),
    [auditLogs],
  );

  moment.locale("en", {
    longDateFormat: {
      LT: "HH:mm:ss",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
  });

  const [colSelectModalOpen, setColSelectModalOpen] = useState(false);

  useEffect(() => {
    const urlQueryString = buildQueryString({
      type: appTypeFilter,
      ...(selectedAuditEntity.id !== "*"
        ? {
            audit_entity_id: selectedAuditEntity.id,
            audit_entity_type: String(selectedAuditEntity.type),
          }
        : {}),
      ...(statusFilter ? { status: statusFilter } : {}),
      ...(deployedModeFilter ? { mode: deployedModeFilter } : {}),
      ...(sourceFilter?.length > 0 ? { source: sourceFilter } : {}),
      ...(dateRangeOption
        ? { dateRange: durationDescriptionToShorthand[dateRangeOption] }
        : {}),
      ...(customStartTime ? { start: String(customStartTime.getTime()) } : {}),
      ...(customEndTime ? { end: String(customEndTime.getTime()) } : {}),
    });

    // update url without realoading page
    window.history.replaceState(
      null,
      "",
      `${window.location.origin}${window.location.pathname}${
        urlQueryString ? `?${urlQueryString}` : ""
      }`,
    );

    // send query to get logs with filters
    (async () => {
      setIsLoading(true);
      try {
        const newLogs = await callServer<AuditLogDto[]>({
          method: HttpMethod.Get,
          url: `v2/audit`,
          query: {
            ...(appTypeFilter !== AppTypeFilter.ALL
              ? {
                  entity_type: String(
                    appTypeFilter === AppTypeFilter.APP
                      ? 0
                      : appTypeFilter === AppTypeFilter.WORKFLOW
                        ? 1
                        : 2,
                  ),
                }
              : {}),
            startTime:
              dateRangeOption === DurationDescription.CUSTOM
                ? customStartTime.toISOString()
                : getStartDateByRelativeRangeOption(dateRangeOption)
                    .toDate()
                    .toISOString(),
            endTime:
              dateRangeOption === DurationDescription.CUSTOM
                ? customEndTime.toISOString()
                : new Date().toISOString(),
            ...(selectedAuditEntity.id !== "*"
              ? {
                  audit_entity_id: selectedAuditEntity.id,
                  audit_entity_type: String(selectedAuditEntity.type),
                }
              : {}),
            ...(deployedModeFilter !== DeployedModeFilter.ALL
              ? {
                  deployed: String(
                    deployedModeFilter === DeployedModeFilter.DEPLOYED,
                  ),
                }
              : {}),
            ...(sourceFilter?.length > 0
              ? {
                  triggered_by: sourceFilter,
                }
              : {}),
            ...(statusFilter !== AuditLogStatusFilter.ALL
              ? {
                  status: statusFilter,
                }
              : {}),
          },
        });

        setAuditLogs(newLogs ?? []);
      } catch (e: any) {
        sendErrorUINotification({
          message: `Failed to fetch audit logs: ${e?.message}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    appTypeFilter,
    statusFilter,
    deployedModeFilter,
    sourceFilter,
    dateRangeOption,
    customEndTime,
    customStartTime,
    selectedAuditEntity.id,
    selectedAuditEntity.type,
    selectedAuditEntity,
  ]);

  const shouldShowPicker = useMemo(() => {
    return dateRangeOption === DurationDescription.CUSTOM;
  }, [dateRangeOption]);

  const filterComponents = useMemo(() => {
    return (
      <>
        <Row justify="space-between">
          <Radio.Group
            onChange={(e) => onAppTypeChange(e.target.value)}
            value={appTypeFilter}
          >
            <Radio data-test="type-all" value={AppTypeFilter.ALL}>
              All
            </Radio>
            <Radio data-test="type-apps" value={AppTypeFilter.APP}>
              Applications
            </Radio>
            <Radio data-test="type-workflows" value={AppTypeFilter.WORKFLOW}>
              Workflows
            </Radio>
            <Radio data-test="type-jobs" value={AppTypeFilter.SCHEDULE}>
              Scheduled Jobs
            </Radio>
          </Radio.Group>
          <Space direction="horizontal" size={10}>
            <RecommendedSingleDropdown
              value={dateRangeOption}
              onChange={onDateRangeOptionChange}
              data-test="time-range-filter"
              options={dateRangeOptions}
              style={{ width: 180 }}
            />
            {shouldShowPicker && (
              <RangePicker
                onChange={onDateRangeChange}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[moment(customStartTime), moment(customEndTime)]}
                format="YYYY-MM-DD HH:mm"
                showTime={{
                  format: "HH:mm",
                }}
              />
            )}
          </Space>
        </Row>
        <Row justify="start">
          <Space direction="horizontal" size={40} style={{ width: "80%" }} wrap>
            <Space direction="vertical" style={{ width: 400 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography.Text strong>
                  Name{" "}
                  {selectedAuditEntity.id !== defaultAudiEntityOption.id && (
                    <Button
                      style={{
                        width: 20,
                        height: 20,
                        color: theme.colors.GREY_400,
                        padding: 0,
                      }}
                      icon={<ClearIcon height={15} width={15} />}
                      type="link"
                      onClick={() => {
                        setSelectedAuditEntity(defaultAudiEntityOption);
                      }}
                    />
                  )}
                </Typography.Text>
              </div>
              <RecommendedSingleDropdown
                data-test="name-filter"
                onChange={onAuditEntitySelect}
                placeholder="Search Audit Logs"
                options={entitiesOptions}
                value={selectedAuditEntity?.id ?? ""}
                resetOnSelect={true}
              />
            </Space>
            <Space direction="vertical" style={{ width: 400 }}>
              <Typography.Text strong>Status</Typography.Text>
              <Radio.Group
                onChange={(e) =>
                  e !== undefined ? setStatusFilter(e.target.value) : false
                }
                defaultValue={statusFilter}
                optionType="button"
                options={[
                  {
                    label: AuditLogStatusFilter.ALL,
                    value: AuditLogStatusFilter.ALL,
                    style: { fontWeight: "bold" },
                  },
                  {
                    label: AuditLogStatusFilter.SUCCESS,
                    value: AuditLogStatusFilter.SUCCESS,
                    style: {
                      fontWeight: "bold",
                      color: theme.palettes.success[5] ?? "green",
                    },
                  },
                  {
                    label: AuditLogStatusFilter.RUNNING,
                    value: AuditLogStatusFilter.RUNNING,
                    style: {
                      fontWeight: "bold",
                      color: theme.palettes.info[5] ?? "blue",
                    },
                  },
                  {
                    label: AuditLogStatusFilter.ABORTED,
                    value: AuditLogStatusFilter.ABORTED,
                    style: {
                      fontWeight: "bold",
                      color: theme.palettes.warning[5] ?? "yellow",
                    },
                  },
                  {
                    label: AuditLogStatusFilter.ERROR,
                    value: AuditLogStatusFilter.ERROR,
                    style: {
                      fontWeight: "bold",
                      color: theme.palettes.error[5] ?? "red",
                    },
                  },
                ]}
              />
            </Space>
            <Space direction="vertical" style={{ width: 100 }}>
              <Typography.Text strong>Mode</Typography.Text>
              <RecommendedSingleDropdown
                data-test="mode-filter"
                value={deployedModeFilter}
                options={modeOptions}
                onChange={onDeployModeFilterChange}
                style={{ width: 100 }}
              />
            </Space>
            <Space direction="vertical" style={{ width: 100 }}>
              <Typography.Text strong>Triggered by</Typography.Text>
              <RecommendedMultiDropdown
                dataTest="source-filter"
                options={allSourcesOptions}
                onChange={onSourcesChange}
                selectedItems={selectedSourcesOptions}
                style={{ width: 300 }}
              />
            </Space>
          </Space>
          <Row justify="end" style={{ width: "20%" }}>
            <Space direction="vertical" align="end">
              <Typography.Text strong>Visible columns</Typography.Text>
              <Button
                icon={<SettingOutlined />}
                disabled={!!colSelectModalOpen}
                onClick={() => setColSelectModalOpen(true)}
              />
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [
    allSourcesOptions,
    appTypeFilter,
    colSelectModalOpen,
    customEndTime,
    customStartTime,
    dateRangeOption,
    dateRangeOptions,
    deployedModeFilter,
    entitiesOptions,
    modeOptions,
    onDateRangeChange,
    onDateRangeOptionChange,
    onSourcesChange,
    selectedAuditEntity.id,
    selectedSourcesOptions,
    shouldShowPicker,
    statusFilter,
    theme.colors.GREY_400,
    theme.palettes.error,
    theme.palettes.info,
    theme.palettes.success,
    theme.palettes.warning,
  ]);

  const spinner = useMemo(() => {
    return (
      <SpinnerContainer>
        <Spinner size="large" />
      </SpinnerContainer>
    );
  }, []);

  // columns filter
  const requiredColumns = ["Date", "Name"];

  const allColKeys = columns.map((col) => col.key?.toString() ?? "");

  const allConfigurableColKeys = allColKeys.filter(
    (colKey) => !requiredColumns.includes(colKey ?? ""),
  );

  const defaultColVisibility = allColKeys.reduce(
    (map, colKey) => {
      if (colKey) {
        map[colKey] = true;
      }
      return map;
    },
    {} as Record<string, boolean>,
  );

  const [colVisibility, setColVisibility] =
    useState<Record<string, boolean>>(defaultColVisibility);

  return (
    <PageWrapper pageName={AUDIT_LOGS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> {AUDIT_LOGS_TITLE} </div>
          </div>
          <FullWidthSpace direction="vertical" size={20}>
            {filterComponents}
            {isLoading ? (
              spinner
            ) : (
              <LogTable
                filteredLogs={tableLogs}
                colVisibility={colVisibility}
              />
            )}
          </FullWidthSpace>
        </MainWrapper>
      </Layout>
      <Modal
        title={"Select visible columns"}
        onCancel={() => setColSelectModalOpen(false)}
        open={colSelectModalOpen}
        footer={[
          <Button key="close" onClick={() => setColSelectModalOpen(false)}>
            Close
          </Button>,
        ]}
        destroyOnClose
      >
        <Space size={10} direction="vertical">
          {allConfigurableColKeys.map((col, i) => {
            return (
              <Switch
                size="small"
                key={col}
                label={col}
                checked={colVisibility[col]}
                onChange={(checked) => {
                  const newColVisiblity = Object.assign({}, colVisibility);
                  newColVisiblity[col] = checked;
                  setColVisibility(newColVisiblity);
                }}
              />
            );
          })}
        </Space>
      </Modal>
    </PageWrapper>
  );
};

AuditLogs.displayName = AUDIT_LOGS_TITLE;

export default AuditLogs;
