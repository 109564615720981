import { KVPair } from "@superblocksteam/shared";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button } from "components/ui/Button";
import { FullWidthSpace } from "components/ui/Space";
import FormLabel from "legacy/components/editorComponents/FormLabel";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormTooltip } from "../FormTooltip";
import { DynamicFormFieldItemForm } from "./DynamicFormFieldItemForm";

const DynamicFormFieldListForm = React.memo(
  ({ path, label, tooltip, readOnly }: DynamicFormItemProps) => {
    const context = useContext(FormContext);
    const theme = useTheme();

    const [value, setValue] = useState<KVPair[]>([]);

    useEffect(() => {
      const unsubscribe = context.subscribe(path, (value) =>
        setValue((value as KVPair[]) ?? []),
      );

      return () => unsubscribe();
    }, [path, context.subscribe, context]);

    const handleChange = useCallback(
      (newKvPair: KVPair, idx: number) => {
        const arr = [...value];
        const newVal = { ...arr[idx], ...newKvPair };
        arr[idx] = newVal;
        setValue(arr);
        context.onChange(path, arr, { debounced: true });
      },
      [context, path, value],
    );
    const remove = useCallback(
      (idx: number) => {
        const arr = [...value];
        arr.splice(idx, 1);
        context.onChange(path, arr);
      },
      [value, context, path],
    );
    const add = useCallback(() => {
      const arr = [...value];
      arr.push({ key: "", value: "" });
      context.onChange(path, arr);
    }, [value, context, path]);

    return (
      <FullWidthSpace direction="vertical">
        <FormLabel>{label}</FormLabel>
        <FormTooltip tooltip={tooltip} />
        {value?.map((kvPair, idx) => (
          <DynamicFormFieldItemForm
            idx={idx}
            key={idx}
            kvPair={kvPair}
            path={path}
            readOnly={readOnly}
            autocompleteConfiguration={context.autocompleteConfiguration}
            autocompleteAdditionalData={context.autocompleteAdditionalData}
            onChange={handleChange}
            onRemove={remove}
          />
        ))}
        <Button
          onClick={add}
          data-test={`${path}_add_button`}
          type="text"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            fontSize: theme.text.sizes.default,
          }}
          disabled={readOnly}
        >
          + Add {label}
        </Button>
      </FullWidthSpace>
    );
  },
);

DynamicFormFieldListForm.displayName = "DynamicFormFieldListForm";
export default DynamicFormFieldListForm;
