import { call } from "redux-saga/effects";
import { clearLocalBlockState } from "pages/Editors/ApiEditor/ControlFlow/local-block-state";
import { createSaga } from "../../../utils/saga";
import { deleteV2Apis } from "../client";
import slice from "../slice";

interface DeleteApisPayload {
  ids: string[];
  branch: string | undefined;
}

function* deleteV2ApiInternal({ ids, branch }: DeleteApisPayload) {
  yield call(deleteV2Apis, ids, branch);
  return {
    ids,
  };
}

export const deleteV2ApisSaga = createSaga(
  deleteV2ApiInternal,
  "deleteV2ApisSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(deleteV2ApisSaga, {
  start(state, { payload }) {
    payload.ids.forEach((id) => {
      state.loading[id] = true;
      state.meta[id].isDeleting = true;
      delete state.errors[id];
    });
  },
  success(state, { payload, meta }) {
    payload.ids.forEach((id) => {
      delete state.loading[id];
      delete state.entities[id];
      delete state.meta[id];
      clearLocalBlockState(id);
    });
  },
  error(state, { payload, meta }) {
    meta.args.ids.forEach((id) => {
      state.errors[id] = { error: payload };
      state.loading[id] = false;
      state.meta[id].isDeleting = false;
    });
  },
});
