import { RoleDto, UserType } from "@superblocksteam/shared";
import { Modal } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DangerButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  DeleteModalHeader,
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { useDeleteRoleMutation } from "store/slices/reduxApi/rbac";
import { useListUsersQuery } from "store/slices/reduxApi/users";
import { RoleDropdown } from "./RoleDropdown";

export const DeleteRoleModal = ({
  onClose,
  role,
}: {
  onClose: () => void;
  role: RoleDto;
}) => {
  const [fallbackRole, setFallbackRole] = useState<string | null>(null);
  const [error, setError] = useState<null | string>(null);
  const organizationId = useSelector(getCurrentOrgId);

  const [deleteRole, { isLoading: isSaving }] = useDeleteRoleMutation();
  const handleDeleteRole = useCallback(async () => {
    if (!fallbackRole) {
      setError("Please select a role to reassign users");
      return;
    }
    try {
      await deleteRole({
        role,
        organizationId,
        replacementRoleId: fallbackRole,
      }).unwrap();
      onClose();
    } catch (error: any) {
      setError(error?.error ?? error?.message ?? "Failed to delete role");
    }
  }, [fallbackRole, deleteRole, role, organizationId, onClose]);

  const parentRef = useRef<HTMLDivElement>(null);

  const { data: users } = useListUsersQuery({
    organizationId,
    type: UserType.SUPERBLOCKS,
  });

  const usersWithRole = useMemo(() => {
    return users?.filter(
      (user) => user.assignments?.[0]?.role?.name === role.name,
    );
  }, [users, role.name]);

  return (
    <Modal
      title={<DeleteModalHeader title={`Delete ${role.name} role`} />}
      open={true}
      onCancel={onClose}
      destroyOnClose={true}
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} ref={parentRef}>
        <div className={FormWrapper}>
          {/* TODO: (rbac) better handling of 0 users scenario */}
          <div>
            The {role.name} role is currently assigned to{" "}
            {usersWithRole?.length ?? 0} users. Choose a new role for them
            before deleting.
          </div>
          <FormItem
            label={
              <span>
                New role for users with <b>{role.name}</b>
              </span>
            }
            required={true}
          >
            <RoleDropdown
              roleType={role.type}
              selectedRole={fallbackRole}
              onRoleChanged={setFallbackRole}
              isClearable={false}
              isDisabled={isSaving}
              parentRef={parentRef}
              filterRoles={(r) => r.id !== role.id}
              shouldAutoUpdate={true}
            />
          </FormItem>
          <FormItem error={error} hidden={!error} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            data-test="cancel-delete-role-button"
            onClick={onClose}
            loading={isSaving}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            type="primary"
            onClick={handleDeleteRole}
            data-test="delete-role-button"
            loading={isSaving}
            disabled={!fallbackRole}
          >
            Reassign & delete
          </DangerButton>
        </div>
      </div>
    </Modal>
  );
};
