import {
  ApplicationScope,
  getDynamicBindings,
  isValidStepDef,
  StepDef,
  ValidStepDef,
} from "@superblocksteam/shared";
import { flatten, get, isArray, isObject, isString } from "lodash";
import { resolveDataTreePath } from "legacy/entities/DataTree/DataTreeHelpers";
import {
  DataTree,
  DataTreeEntity,
  ScopedDataTreePath,
} from "legacy/entities/DataTree/dataTreeFactory";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import {
  extractJsEvaluationBindings,
  isCustomEvent,
} from "legacy/workers/evaluationUtils";
import { ScopeSpecificDataTree } from "utils/dataTree/MergedDataTree";
import { getScopeAndEntityName } from "utils/dottedPaths";
import { createTriggerReferenceExtractor } from "./extractTriggerReferences";

const getStepDefinitionsAndLocationsFromEntity = (entity: DataTreeEntity) => {
  const dynamicTriggerPathList = getEntityDynamicTriggerPathList(entity);
  return dynamicTriggerPathList.reduce(
    (
      accum: Array<{
        step: ValidStepDef;
        key: string;
        index: number;
      }>,
      { key },
    ) => {
      const stepDef: StepDef[] = get(entity, key);
      stepDef?.forEach((step, index) => {
        if (isValidStepDef(step)) {
          accum.push({ step, key, index });
        }
      });
      return accum;
    },
    [],
  );
};

type ReferenceContext = {
  isJS: boolean;
  identifiers: Set<string>;
  localTree: ScopeSpecificDataTree;
  dataTree: DataTree;
  scope: ApplicationScope;
};

const getReferencesFromText = async (
  text: string,
  context: ReferenceContext,
) => {
  if (context.isJS) {
    return await extractJsEvaluationBindings(
      text,
      context.identifiers,
      context.localTree,
    );
  } else {
    const { jsSnippets } = getDynamicBindings(text);
    return (
      await Promise.all(
        jsSnippets.map((jsSnippet) =>
          extractJsEvaluationBindings(
            jsSnippet,
            context.identifiers,
            context.localTree,
          ),
        ),
      )
    ).flat();
  }
};

const computeEvaluationReferences = async (
  value: unknown,
  context: ReferenceContext,
): Promise<string[]> => {
  if (isString(value)) {
    return getReferencesFromText(value, context);
  } else if (isArray(value)) {
    const refs = await Promise.all(
      value.map(async (v) => computeEvaluationReferences(v, context)),
    );
    return flatten(refs);
  } else if (isObject(value)) {
    const refs = await Promise.all(
      Object.values(value).map(async (v) =>
        computeEvaluationReferences(v, context),
      ),
    );
    return flatten(refs);
  }
  return [];
};

export const getDataTreeReferencesFromEntity = async (
  entity: DataTreeEntity,
  context: Omit<ReferenceContext, "isJS">,
) => {
  const stepInfo = getStepDefinitionsAndLocationsFromEntity(entity);
  const references: [ScopedDataTreePath, string][] = [];
  const extractReferences = createTriggerReferenceExtractor({
    addReference: (reference, scope, path) => {
      // if scope is provided, then we don't need to infer
      if (!reference) {
        return;
      }
      const scopedDataTreePath =
        scope != null
          ? (`${scope}.${reference}` as ScopedDataTreePath)
          : resolveDataTreePath(
              reference,
              (scope ?? context.scope) as ApplicationScope,
              context.dataTree,
            );

      if (scopedDataTreePath) {
        const scopeAndName = getScopeAndEntityName(scopedDataTreePath);
        references.push([
          `${scopeAndName.scope}.${scopeAndName.entityName}`,
          path,
        ]);
      } else {
        console.error(`Could not resolve reference in ${path}`);
      }
    },
    getEvaluationReferences: async (value, isJS) => {
      const refs = await computeEvaluationReferences(value, {
        isJS,
        ...context,
      });
      return refs;
    },
  });

  for (const { step, key, index } of stepInfo) {
    await extractReferences(step, key, index);
  }

  return references;
};

export function getGraphIdFromWidgetId(
  canvasWidgets: CanvasWidgetsReduxState,
  widgetId: string,
  scope = ApplicationScope.PAGE,
) {
  const widget = canvasWidgets[widgetId];
  if (widget) {
    return `${scope}.${widget.widgetName}`;
  }
  console.error(`Widget not found: ${widgetId}`);
  return undefined;
}

export function getGraphIdFromDataTreeEntity(
  scope: ApplicationScope,
  key: string,
  entity: DataTree[ApplicationScope][string],
) {
  if (typeof entity !== "object" || entity == null || Array.isArray(entity)) {
    return `${scope}.${key}`;
  }
  const name = "name" in entity ? entity.name : key;
  return `${scope}.${name}`;
}

const getEntityDynamicTriggerPathList = (entity: DataTreeEntity) => {
  if (isCustomEvent(entity)) {
    // custom events do not have a dynamicTriggerPathList in the DSL (not sure if this is a bug or not)
    return [
      {
        key: "onTrigger",
      },
    ];
  }
  if (
    typeof entity !== "object" ||
    entity == null ||
    Array.isArray(entity) ||
    !("dynamicTriggerPathList" in entity)
  ) {
    return [];
  }
  return entity.dynamicTriggerPathList ?? [];
};

export const doesEntityHaveAnyStepDefinitions = (entity: DataTreeEntity) => {
  const dtPathList = getEntityDynamicTriggerPathList(entity);
  return dtPathList.some(({ key }) => {
    const stepDef: StepDef[] = get(entity, key);
    return stepDef?.some(isValidStepDef);
  });
};
