import {
  ApiKeyScope,
  DropdownOption,
  jsonPrettyPrint,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { OPA_LATEST_RELEASE_API_URL } from "legacy/constants/routes";
import logger from "utils/logger";

export const HELM_CHART_REPOSITORY_URL =
  "https://charts.superblocks.com/superblocks";

export type AgentDeploymentInstructionsProps = {
  agentDomain?: string;
  agentSubdomain?: string;
  agentHost?: string;
  agentUrl?: string;
  agentEnvironment?: string;
  agentDataDomain: string;
  startIdx?: number;
  latestOpaVersion?: string;
  showInstruction: boolean;
};

export enum AgentFormDefault {
  PORT = "8080",
  URL = `http://localhost:8080`,
  DOMAIN = `example.com`,
  SUBDOMAIN = `superblocks`,
  ENVIRONMENT = "*",
}

export enum AgentConfigStatus {
  NULL = "",
  SUCCESS = "success",
  ERROR = "error",
}

export const tokenTypeOptions: DropdownOption[] = [
  {
    displayName: "Org Admin",
    value: ApiKeyScope.ORG_ADMIN,
    key: "orgAdmin",
    subText: "Manage your organization users and groups",
  },
  {
    displayName: "Embed",
    value: ApiKeyScope.ORG_SESSION_MANAGEMENT,
    key: "embed",
    subText:
      "Create and manage authenticated session tokens for embedded users",
  },
  {
    displayName: "Agent key",
    value: ApiKeyScope.ORG_AGENT,
    key: "agent",
    subText: "Manage registration and authentication of On-Premise Agents",
  },
];

export const isAgentOutdated = (
  currentVersion: string,
  latestVersion: string,
) => {
  if (!isEmpty(currentVersion) && !isEmpty(latestVersion)) {
    return currentVersion < latestVersion;
  }
  return false;
};

export const isHostnameValid = (host: string) => {
  if (host == null || host.length === 0) {
    return false;
  }

  const regex = new RegExp(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
    //https://regexr.com/3au3g
  );
  return regex.test(host);
};

export const getLatestAgentRelease = () => {
  return fetch(OPA_LATEST_RELEASE_API_URL)
    .then(async (response) => {
      const release = await response.json();
      return release.name;
    })
    .catch((err) =>
      logger.error(
        `Failed to get latest OPA release version from '${OPA_LATEST_RELEASE_API_URL}'. Error: ${jsonPrettyPrint(
          err,
        )}`,
      ),
    );
};
