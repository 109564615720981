export enum EntitiesErrorType {
  SAVE_ERROR = "SAVE_ERROR",
  EXECUTE_ERROR = "EXECUTE_ERROR",
}

type EntitiesError = {
  error: Error;
  type?: EntitiesErrorType;
};

interface EntitesState<T> {
  entities: Record<string, T>;
  loading: Record<string, boolean>;
  errors: Record<string, EntitiesError>;
}

export interface EntityState<T> {
  entity: T;
  loading: Record<keyof T, boolean>;
  error: Record<keyof T, Error>;
}

export interface EntitiesStateWithMeta<TEntity, TMeta>
  extends EntitesState<TEntity> {
  meta: Record<string, TMeta>;
}

export const ROOT = "_";
export const NEW_ID = "_new";

export class HttpError extends Error {
  code: number;
  critical: boolean;

  constructor(code: number, critical = false, m?: string) {
    super(m);
    this.code = code;
    this.critical = critical;
  }
}

// Agent system errors are errors caused by browser can't connect to the agent,
// or caused by browser to agent requests failed with any HTTP error;
export type AgentSystemError = {
  systemError?: string;
  systemErrorCode?: number;
};

/** "Fun" TypeScript hack to be able to index object by key received from Object.entries() */
export function typeSafeEntries<T extends object>(
  object: T,
): [keyof T, T[keyof T]][] {
  function isSafeKey(key: string | number | symbol): key is keyof T {
    return key in object;
  }

  const result: [keyof T, T[keyof T]][] = [];

  for (const [key, value] of Object.entries(object)) {
    if (isSafeKey(key)) {
      result.push([key, value]);
    }
  }

  return result;
}

type GeneratorFunction = (...args: any[]) => Generator<any, any, any>;
export type GeneratorReturnType<
  GF extends GeneratorFunction,
  GR = ReturnType<GF>,
> = GR extends Generator<any, infer R, any> ? R : never;
