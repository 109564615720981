import { Switch, Classes } from "@blueprintjs/core";
import styled, { AnyStyledComponent, css } from "styled-components";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { ControlIcons } from "legacy/icons/ControlIcons";
import { FormIcons } from "legacy/icons/FormIcons";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { ControlLayout } from "./BaseControl";

const MIN_FLEX_LABEL_WIDTH = 40;
const CONTROL_LABEL_MIN_WIDTH = 85;
const CONTROL_LABEL_PERCENT_WIDTH = 35;
const CONTROL_LABEL_MAX_WIDTH = 200;
const APPENDED_DOCS_LINK_WIDTH = 20;
const TRIGGER_ADD_BUTTON_WIDTH = 24;
const GAP = 8;

export const ControlWrapper = styled.div<{
  "data-layout": ControlLayout;
  "data-right-item"?: boolean;
  "data-is-trigger"?: boolean;
}>`
  padding: 4px 0;

  &[data-layout="${ControlLayout.VERTICAL_FLEX}"] {
    display: block;
  }
  &[data-layout="${ControlLayout.HORIZONTAL_FLEX}"] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${GAP}px;
    > :first-child {
      min-width: ${MIN_FLEX_LABEL_WIDTH}px;
    }
  }
  &[data-layout="${ControlLayout.GRID}"] {
    display: grid;
    grid-template-columns:
      minmax(${CONTROL_LABEL_MIN_WIDTH}px, ${CONTROL_LABEL_PERCENT_WIDTH}%)
      1fr;
    grid-gap: ${GAP}px;
    > :first-child {
      min-width: ${CONTROL_LABEL_MIN_WIDTH}px;
      max-width: ${CONTROL_LABEL_MAX_WIDTH}px;
    }
    &[data-right-item="true"] {
      grid-template-columns:
        minmax(${CONTROL_LABEL_MIN_WIDTH}px, ${CONTROL_LABEL_PERCENT_WIDTH}%)
        1fr ${APPENDED_DOCS_LINK_WIDTH}px;
    }
  }
`;

export const V2ControlWrapper = styled(ControlWrapper)`
  padding: 6px 0px;
  min-height: 44px; // 32px + 12px of padding
  position: relative;
`;

export const ControlPropertyLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  font-size: 12px;
  .underline {
    color: ${(props) => props.theme.legacy.colors.textDefault};
  }
`;

export const V2ControlPropertyLabelContainer = styled(
  ControlPropertyLabelContainer,
)`
  padding: 0px;
  height: min-content;
  min-height: 32px;
  &[data-layout="${ControlLayout.VERTICAL_FLEX}"] {
    min-height: 24px;
    padding: 4px 0;
  }
  &[data-is-trigger="true"] {
    margin-right: ${TRIGGER_ADD_BUTTON_WIDTH}px;
  }
`;

export const CodeToggleButton = styled.span<{
  active: boolean;
  disabled: boolean;
}>`
  margin: 1px 3px 3px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 11px;
  padding: 1px 4px;
  display: flex;
  color: ${(props) =>
    props.active ? props.theme.colors.WHITE : props.theme.colors.GREY_300};
  background: ${(props) =>
    props.active
      ? props.theme.colors.ACCENT_BLUE_500
      : props.theme.colors.GREY_50};
  span {
    margin-left: 3px;
  }
  ${(props) => props.disabled && "cursor: not-allowed;"}
`;

export const CodeToggleButtonV2 = styleAsClass`
  height: 14px;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 11px;
  display: flex;

  &[data-active="false"] {
    color: ${colors.GREY_300};
    background-color: ${colors.GREY_50};
  }

  &[data-active="true"] {
    color: ${colors.WHITE};
    background-color: ${colors.ACCENT_BLUE_500};
  }

  &[data-disabled="false"] {
    cursor: pointer;
  }

  &[data-disabled="true"] {
    cursor: not-allowed;
  }
`;

export const StyledDropDownContainer = styled.div`
  &&&& .${Classes.BUTTON} {
    box-shadow: none;
    border-radius: 4px;
    padding: 5px 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    :hover {
      border: 1px solid
        ${(props) => props.theme.legacy.appColors.textField.normal.border};
    }
    background-color: ${LegacyNamedColors.WHITE};
    color: ${LegacyNamedColors.SHARK};
    background-image: none;
    &.code-highlight {
      .language-javascript {
        border: none;
        box-shadow: none;
        background: transparent;
        white-space: normal;
        word-break: break-word;
      }
      .bp5-button-text {
        white-space: normal;
        word-break: break-word;
        display: block;
        overflow: auto;
        overflow-y: hidden;
        font-size: 12px;
      }
    }
  }
  width: 100%;
`;

export const StyledSwitch = styled(Switch)`
  margin-bottom: 0;

  &&.bp5-control.bp5-switch .bp5-control-indicator {
    width: 24px;
    min-width: 24px;
    border-radius: 24px;
    height: 16px;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
  }

  &&.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
    background: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }

  &&.bp5-control.bp5-switch .bp5-control-indicator::before {
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-left: 2px;
    box-shadow: 0px 1px 3px 0px rgba(41, 50, 61, 0.16);
  }

  &&.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator::before {
    margin-left: 6px;
  }
`;

export const StyledDynamicInput = styled.div`
  width: 100%;
  min-width: 0;
  &&& {
    input {
      border: none;
      color: ${(props) => props.theme.legacy.colors.textOnDarkBG};
      background: ${(props) => props.theme.legacy.colors.paneBG};
      &:focus {
        border: none;
        color: ${(props) => props.theme.legacy.colors.textOnDarkBG};
        background: ${(props) => props.theme.legacy.colors.paneBG};
      }
    }
  }
`;

export const StyledDragIcon = styled(
  ControlIcons.DRAG_CONTROL as AnyStyledComponent,
)`
  padding: 0;
  :first-child {
    margin-left: 2px;
  }
  margin-right: 2px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "move")};
  z-index: 1;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: 6px;
  svg {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    fill: ${({ theme }) => theme.colors.GREY_800};
  }
`;

export const StyledPlusIcon = styled(FormIcons.PLUS_ICON)`
  padding: 0;
  :first-child {
    margin-left: 8px;
  }
  margin-right: 8px;
  cursor: move;
  z-index: 1;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: 7px;
  svg {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    fill: ${({ theme }) => theme.colors.GREY_800};
  }
`;

export const StyledDeleteIcon = styled(
  ControlIcons.DELETE_COLUMN as AnyStyledComponent,
)<{ $isDisabled: boolean }>`
  padding: 0;
  cursor: pointer;
  margin-right: 8px;
  margin-left: auto;

  :first-child {
    margin-left: 8px;
  }

  svg {
    width: 14px;
    height: 16px;
    position: relative;
  }

  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const StyledEyeIcon = styled(
  ControlIcons.EYE_ICON as AnyStyledComponent,
)<{ $isDisabled: boolean }>`
  padding: 0;
  cursor: pointer;
  :first-child {
    margin-left: 8px;
  }
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
  }
  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const StyledHideIcon = styled(
  ControlIcons.HIDE_COLUMN as AnyStyledComponent,
)<{ $isDisabled: boolean }>`
  padding: 0;
  cursor: pointer;
  :first-child {
    margin-left: 8px;
  }
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    path {
      fill: transparent;
    }
  }
  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const StyledFreezeColumnIcon = styled(
  ControlIcons.FREEZE_COLUMN as AnyStyledComponent,
)`
  padding: 0;
  cursor: pointer;
  :first-child {
    margin-left: 8px;
  }
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
    position: relative;
  }
`;
