import { Def, Events, Server } from "tern";
import logger from "utils/logger";
import { cloneAndRemoveFunctions } from "utils/removeFunctions";
import { TernActions } from "./types";

type CallbackFn = (...args: any) => any;

class TernWorkerServer implements Server {
  private worker: Worker | undefined;
  private msgId = 0;
  private pending: { [x: number]: CallbackFn } = {};
  private defs: Def[] = [];

  private workerPromise: Promise<Worker> | undefined;

  constructor(defs: Def[]) {
    this.defs = defs;
  }

  ensureLoaded() {
    if (this.workerPromise) {
      return this.workerPromise;
    }

    this.workerPromise = new Promise((resolve) => {
      // usually you'd catch the import error, but not necessary for inline workers
      import(
        /* webpackChunkName: "tern-worker" */
        "worker-loader?inline=no-fallback&filename='tern'!./tern.worker"
      ).then(({ default: Worker }) => {
        this.worker = new Worker();

        this.worker.postMessage({
          type: TernActions.INIT,
          defs: this.defs,
        });

        this.worker.onmessage = this.onMessage.bind(this);
        this.worker.onerror = this.onError.bind(this);

        resolve(this.worker);
      });
    });

    return this.workerPromise;
  }

  public terminate() {
    this.worker?.terminate();
    this.workerPromise = undefined;
  }

  private onMessage(e: any) {
    const data = e.data;
    if (data.id && this.pending[data.id]) {
      this.pending[data.id](data.err, data.body);
      delete this.pending[data.id];
    }
  }

  private onError(e: any) {
    for (const id in this.pending) this.pending[id](e);
    this.pending = {};
  }

  private send(data: { type: TernActions } & any, callback?: CallbackFn) {
    if (callback) {
      data.id = ++this.msgId;
      this.pending[this.msgId] = callback;
    }
    this.ensureLoaded().then(() => {
      try {
        this.worker?.postMessage(data);
      } catch (e: any) {
        logger.error(
          "WARNING: Failed to send message for autocomplete without cloning. Autocomplete is running with reduced performance.",
          e.toString(),
        );
        this.worker?.postMessage(cloneAndRemoveFunctions(data));
      }
    });
  }

  addFile(name: string, text: string) {
    this.send({ type: TernActions.ADD_FILE, name: name, text: text });
  }

  delFile(name: string) {
    this.send({ type: TernActions.DELETE_FILE, name: name });
  }

  request(body: any, callback: CallbackFn) {
    this.send({ type: TernActions.REQUEST, body: body }, callback);
  }

  addDefs(defs: Def[], atFront?: boolean) {
    this.send({
      type: TernActions.ADD_DEF,
      defs: defs,
      atFront: atFront,
    });
  }

  deleteDefs(name: string) {
    this.send({ type: TernActions.DELETE_DEF, name });
  }

  flush(callback: () => void): void {
    throw new Error("Method not implemented.");
  }
  loadPlugin(name: string, options: object): void {
    throw new Error("Method not implemented.");
  }
  off<K extends keyof Events>(eventType: K, handler: Events[K]): void {
    throw new Error("Method not implemented.");
  }
  on<K extends keyof Events>(eventType: K, handler: Events[K]): void {
    throw new Error("Method not implemented.");
  }
}

export default TernWorkerServer;
