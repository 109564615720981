import { createSelector } from "reselect";

import { selectAllV2NonStreamingApiNames } from "legacy/selectors/sagaSelectors";
import {
  selectAllApiNames,
  selectAllLoadingApiNames,
  selectAllApis,
} from "../../apis/selectors";
import {
  selectAllLoadingApiV2Names,
  selectAllV2ApiNames,
  selectAllV2Apis,
} from "../../apisV2/selectors";

export const selectAllApiUnionNames = createSelector(
  selectAllApiNames,
  selectAllV2ApiNames,
  (v1Apis, v2Apis) => [...v1Apis, ...v2Apis],
);

export const selectAllNonStreamingApiUnionNames = createSelector(
  selectAllApiNames, // no v1 apis are streaming
  selectAllV2NonStreamingApiNames,
  (v1Apis, v2Apis) => [...v1Apis, ...v2Apis],
);

export const selectAllLoadingApiUnionNames = createSelector(
  selectAllLoadingApiNames,
  selectAllLoadingApiV2Names,
  (v1Apis, v2Apis): string[] => [...v1Apis, ...v2Apis],
);

export const selectApiVersionLookup = createSelector(
  selectAllApis,
  selectAllV2Apis,
  (v1Apis, v2Apis) => {
    const idToVersion: Record<string, "v1" | "v2"> = {};
    Object.keys(v1Apis).forEach((id) => {
      idToVersion[id] = "v1";
    });
    Object.keys(v2Apis).forEach((id) => {
      idToVersion[id] = "v2";
    });
    return idToVersion;
  },
);

export * from "./controlFlowEnabledDynamic";
