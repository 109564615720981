import { UserAnalyticsDto } from "@superblocksteam/shared";
import { callServer, HttpMethod } from "../../utils/client";

export function submitSurvey(email: string, formAnswers: any) {
  return callServer<string>({
    method: HttpMethod.Post,
    url: "v1/users/submit-survey",
    body: { user: { email }, formAnswers },
  });
}

export function getAnalytics() {
  return callServer<UserAnalyticsDto>(
    {
      method: HttpMethod.Get,
      url: "v1/users/analytics",
    },
    {
      // hide because this path is non critical and we don't want to show error to user (especially in embed mode)
      notifyOnError: false,
    },
  ).then((data) => {
    handleDates(data);
    return data;
  });
}

export function updateAnalytics(body: Partial<UserAnalyticsDto>) {
  return callServer<UserAnalyticsDto>(
    {
      method: HttpMethod.Put,
      url: "v1/users/analytics",
      body,
    },
    {
      // hide because this path is non critical and we don't want to show error to user (especially in embed mode)
      notifyOnError: false,
    },
  ).then((data) => {
    handleDates(data);
    return data;
  });
}

function handleDates(body: any) {
  const isoDateFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
  function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
  }

  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = new Date(value);
    } else if (typeof value === "object") {
      handleDates(value);
    }
  }
}
