import { UserDefinedTheme } from "@superblocksteam/shared/src/types/application";
import localforage from "localforage";
import type { CopiedWidgets } from "legacy/widgets";

export const STORAGE_KEYS: { [id: string]: string } = {
  AUTH_EXPIRATION: "Auth.expiration",
  ROUTE_BEFORE_LOGIN: "RedirectPath",
  COPIED_WIDGET: "CopiedWidget",
  COPIED_THEME: "CopiedTheme",
  SHOW_INTERCOM_POPOVER_KEY: "SHOULD_SHOW_INTERCOM_TOOLTIP",
  CANVAS_SCALE_FACTOR: "CanvasScaleFactor",
  VISITOR_ID: "VisitorId",
  SHOW_PROMOTION_MODAL_KEY: "SHOULD_SHOW_PROMOTION_MODAL",
  PRICING_MODAL_AUTOLOADED_EPOCH_KEY: "PRICING_MODAL_AUTOLOADED_EPOCH",
  RECENT_ICON_LIST: "RECENT_ICON_LIST",
  CODE_FORMATTING_ONBOARDING_BANNER_DISMISSED:
    "CODE_FORMATTING_ONBOARDING_BANNER_DISMISSED",
  TRACK_FOCUSED_ITEMS: "TRACK_FOCUSED_ITEMS",
  REDUX: "redux",
};

const store = localforage.createInstance({
  name: "Superblocks",
});

export const saveCopiedWidgets = async (widgetsJSON: string) => {
  try {
    await store.setItem(STORAGE_KEYS.COPIED_WIDGET, widgetsJSON);
    return true;
  } catch (error) {
    console.log("An error occurred when storing copied component: ", error);
    return false;
  }
};

export const getCopiedWidgets = async (): Promise<
  CopiedWidgets | undefined
> => {
  try {
    const widget: string | null = await store.getItem(
      STORAGE_KEYS.COPIED_WIDGET,
    );
    if (widget && widget.length > 0) {
      return JSON.parse(widget);
    }
  } catch (error) {
    console.log("An error occurred when fetching copied component: ", error);
  }
};

export const saveCopiedUserDefinedTheme = async (themeJSON: string) => {
  // No exception handling  - this should be handled one layer above
  await store.setItem(STORAGE_KEYS.COPIED_THEME, themeJSON);
};

export const getCopiedUserDefinedTheme = async (): Promise<
  UserDefinedTheme | undefined
> => {
  // No exception handling  - this should be handled one layer above
  const theme: string | null = await store.getItem(STORAGE_KEYS.COPIED_THEME);
  if (theme && theme.length > 0) {
    return JSON.parse(theme);
  } else {
    throw new Error("Cannot parse stored theme");
  }
};

export const hasUserDefinedThemeInClipboard = async (): Promise<boolean> => {
  try {
    const theme = await store.getItem(STORAGE_KEYS.COPIED_THEME);
    return !!theme;
  } catch {
    return false;
  }
};
