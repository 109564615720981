import { ProfileCreate } from "@superblocksteam/schemas";
import { Profile } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { PayloadAction } from "../../../utils/action";
import { createSaga } from "../../../utils/saga";
import { createProfile } from "../client";
import slice from "../slice";

interface CreateProfilePayload {
  orgId: string;
  created: ProfileCreate;
}

interface CreateProfileResponse {
  orgId: string;
  created: Profile;
}

function* createProfileInternal({ orgId, created }: CreateProfilePayload) {
  const profile: Profile = yield call(createProfile, orgId, created);
  return {
    orgId: orgId,
    created: profile,
  } as CreateProfileResponse;
}

export const createProfileSaga = createSaga(
  createProfileInternal,
  "createProfileSaga",
  { sliceName: "organizations" },
);

slice.saga(createProfileSaga, {
  start(state, payload: PayloadAction<CreateProfilePayload>) {
    state.loading[payload.payload.orgId] = true;
    delete state.errors[payload.payload.orgId];
  },
  success(state, { payload }) {
    state.entities[payload.orgId] = state.entities[payload.orgId] ?? {};
    const existingProfiles = state.entities[payload.orgId].profiles ?? [];
    state.entities[payload.orgId].profiles = existingProfiles.concat([
      payload.created,
    ]);
    delete state.loading[payload.orgId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.orgId] = { error: payload };
    delete state.loading[meta.args.orgId];
  },
});
