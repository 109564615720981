import { call } from "redux-saga/effects";
import { clearLocalBlockState } from "pages/Editors/ApiEditor/ControlFlow/local-block-state";
import { createSaga } from "../../../utils/saga";
import { deleteV2Api } from "../client";
import slice from "../slice";

interface DeleteApiPayload {
  id: string;
  branch: string | undefined;
}

function* deleteV2ApiInternal({ id, branch }: DeleteApiPayload) {
  yield call(deleteV2Api, id, branch);
  return {
    id,
  };
}

export const deleteV2ApiSaga = createSaga(
  deleteV2ApiInternal,
  "deleteV2ApiSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(deleteV2ApiSaga, {
  start(state, { payload }) {
    state.loading[payload.id] = true;
    state.meta[payload.id].isDeleting = true;
    delete state.errors[payload.id];
  },
  success(state, { payload, meta }) {
    delete state.loading[payload.id];
    delete state.entities[payload.id];
    delete state.meta[payload.id];
    clearLocalBlockState(payload.id);
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.id] = { error: payload };
    state.loading[meta.args.id] = false;
    state.meta[meta.args.id].isDeleting = false;
  },
});
