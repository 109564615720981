import {
  JavascriptPlugin,
  PythonPlugin,
  RestApiIntegrationPlugin,
  RestApiPlugin,
} from "@superblocksteam/shared";

type DatasourceType = {
  id: string;
  name: string;
  pluginId: string;
  icon: string;
  isDemo: boolean;
};

const PluginPriorityMap = {
  [PythonPlugin.id]: 0,
  [JavascriptPlugin.id]: 1,
  [RestApiPlugin.id]: 2,
  [RestApiIntegrationPlugin.id]: 3,
};

const priority = (datasource: DatasourceType) => {
  let priority;
  if (datasource.pluginId in PluginPriorityMap) {
    priority = PluginPriorityMap[datasource.pluginId];
  } else {
    priority = Object.keys(PluginPriorityMap).length;
  }
  if (datasource.isDemo) {
    priority += 10000000; // sort all demo datasource at the bottom
  }
  return priority;
};

export const sortDatasourcesOrPlugin = (
  a: DatasourceType,
  b: DatasourceType,
) => {
  return (
    priority(a) - priority(b) ||
    a.pluginId?.localeCompare(b.pluginId) ||
    a.name?.localeCompare(b.name)
  );
};
