import { Plugin } from "@superblocksteam/shared";
import { set } from "lodash";
import { extractAttachedFiles as v1ExtractAttachedFiles } from "store/slices/apis/utils/bindings";
import { FileRequestParam } from "store/slices/apisV2/backend-types";
import encodeFileToBase64 from "store/slices/apisV2/utils/base64-encode-file";
import { computeBlockBindings } from "./block-bindings";
import { ControlFlowFrontendDSL } from "./types";
import type { ScopeSpecificDataTree } from "utils/dataTree/MergedDataTree";

async function extractBindingsFromControlFlow(params: {
  controlFlow: ControlFlowFrontendDSL | undefined;
  identifiers: string[];
  plugins: Partial<Record<string, Plugin>>;
  dataTree: ScopeSpecificDataTree;
}): Promise<Array<{ blockName: string; bindings: string[] }>> {
  const { controlFlow, dataTree, plugins } = params;
  if (!controlFlow) {
    return [];
  }

  const { blocks } = controlFlow;

  // compute bindings for each block in the blocks map
  const blockBindings = await Promise.all(
    Object.keys(blocks).map(async (blockName) => {
      const block = blocks[blockName];
      return await computeBlockBindings(block, {
        identifiers: new Set(params.identifiers),
        dataTree,
        plugins,
      });
    }),
  );

  return blockBindings;
}

export async function extractBindingsByBlockFromControlFlow(params: {
  controlFlow: ControlFlowFrontendDSL;
  identifiers: string[];
  plugins: Partial<Record<string, Plugin>>;
  dataTree: ScopeSpecificDataTree;
}): Promise<Record<string, string[]>> {
  const bindings = await extractBindingsFromControlFlow(params);
  return bindings.reduce((acc: Record<string, string[]>, binding) => {
    acc[binding.blockName] = binding.bindings;
    return acc;
  }, {});
}

export function createExpandedBindingValueObject(
  rawBindingValues: Record<string, unknown>,
): Record<string, unknown> {
  const ret = {};
  Object.keys(rawBindingValues).forEach((key) => {
    let value = rawBindingValues[key];
    // transform a value of type Uint8Array to
    // new_value = {
    //    "type": "Buffer",
    //    "data": [32, 23, 23, ...]
    // }
    // this is the proper schema of a node.js Buffer
    if (value instanceof Uint8Array) {
      value = {
        type: "Buffer",
        data: Array.from(value),
      };
    }
    set(ret, key, value);
  });
  return ret;
}

// We will extract files as long as any top-level entity contains files, like
// if all we can detect is that the user references FilePicker1
export async function extractAttachedFiles(
  rawBindingValues: Record<string, unknown>,
): Promise<FileRequestParam[]> {
  const attachedFiles = await v1ExtractAttachedFiles(
    Object.keys(rawBindingValues).map((key) => {
      return {
        key,
        value: rawBindingValues[key],
      };
    }),
  );

  return formatFilesForRequest(attachedFiles);
}

async function formatFilesForRequest(
  attachedFiles: Record<string, File>,
): Promise<FileRequestParam[]> {
  const filesForRequest: FileRequestParam[] = [];
  for (let i = 0; i < Object.keys(attachedFiles).length; i++) {
    const fileKey = Object.keys(attachedFiles)[i];
    const file = attachedFiles[fileKey];

    let base64EncodedFile = await encodeFileToBase64(file);
    // Remove mimetype from start of string, as it's not used
    // for grpc
    base64EncodedFile = base64EncodedFile.split(",")[1];

    filesForRequest.push({
      originalName: fileKey,
      buffer: base64EncodedFile,
      encoding: "base64",
      mimetype: "text/plain",
    });
  }

  return filesForRequest;
}
