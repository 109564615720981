import { Solver, Variable, Strength, Constraint, Operator } from "@lume/kiwi";
import { getBottomPadding } from "./utils";
import type { AutoHeightWidget } from "./Widget";

export class WidgetVariable {
  private widget: AutoHeightWidget;
  private solver: Solver;
  private roundToGrid: number;

  public y: Variable;

  public height: Variable;
  private heightConstraint: Constraint;

  public bottomPadding: Variable;
  private bottomPaddingConstraint: Constraint;

  constructor(
    widget: AutoHeightWidget,
    solver: Solver,
    options: {
      roundToGrid: number;
    } = { roundToGrid: 0 },
  ) {
    this.widget = widget;
    this.solver = solver;
    this.roundToGrid = options.roundToGrid;

    this.y = new Variable(widget.id + ".y");
    this.height = new Variable(widget.id + ".height");
    this.bottomPadding = new Variable(widget.id + ".bottomPadding");

    solver.addEditVariable(this.y, Strength.weak);

    this.heightConstraint = new Constraint(
      this.height,
      Operator.Eq,
      widget.height,
    );
    solver.addConstraint(this.heightConstraint);

    this.bottomPaddingConstraint = new Constraint(
      this.bottomPadding,
      Operator.Eq,
      getBottomPadding(widget.height, this.roundToGrid),
    );
    solver.addConstraint(this.bottomPaddingConstraint);

    // Ensure Y can't go below 0
    solver.addConstraint(new Constraint(this.y, Operator.Ge, 0));
    // Make y as close to 0 as possible
    solver.addConstraint(
      new Constraint(this.y, Operator.Eq, 0, Strength.strong),
    );
  }

  updateHeight(height: number) {
    if (height !== this.height.value()) {
      this.solver.removeConstraint(this.heightConstraint);
      this.heightConstraint = new Constraint(this.height, Operator.Eq, height);
      this.solver.addConstraint(this.heightConstraint);
    }

    const bottomPadding = getBottomPadding(height, this.roundToGrid);
    if (bottomPadding !== this.bottomPadding.value()) {
      this.solver.removeConstraint(this.bottomPaddingConstraint);
      this.bottomPaddingConstraint = new Constraint(
        this.bottomPadding,
        Operator.Eq,
        bottomPadding,
      );
      this.solver.addConstraint(this.bottomPaddingConstraint);
    }
  }

  toWidget(): AutoHeightWidget {
    const y = this.y.value();
    const height = this.height.value();
    return {
      ...this.widget,
      y: Object.is(y, -0) ? 0 : y,
      height: Object.is(height, -0) ? 0 : height,
    };
  }
}
