import { useAuth0 } from "@auth0/auth0-react";
import { AccessMode, DatasourceOneTimeState } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import AuthProvider from "auth/auth0";
import TokenProvider from "auth/token";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import { useSaga } from "hooks/store";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { STATUS_PAGE } from "pages/routes";
import { useAppDispatch } from "store/helpers";
import initializeUser from "store/sagas/initializeUser";
import {
  OutgoingMessage,
  isOnEmbedRoute,
  sendEventToEmbedder,
} from "utils/embed/messages";
import log from "utils/logger";
import { setAccessMode } from "../legacy/actions/userActions";

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const {
    isLoading: auth0Loading,
    error: auth0Error,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [initializeUserSaga] = useSaga(initializeUser);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const dispatch = useAppDispatch();

  // state parameter, used by integration auth clashes with auth0 state parameter
  const [integrationAuthRedirectInProgress, redirectInitiatedByEmbedUser] =
    isIntegrationAuthRedirectInProgress();

  // initialize application
  useEffect(() => {
    if (auth0Loading) {
      PerformanceTracker.startAsyncTracking(
        PerformanceTransactionName.LOAD_AUTH0,
      );
      return;
    }
    PerformanceTracker.stopAsyncTracking(PerformanceTransactionName.LOAD_AUTH0);

    if (isOnEmbedRoute() && TokenProvider.getTokenType() === "embed") {
      const sessionToken = TokenProvider.getToken();
      if (sessionToken) {
        // If in embedded mode and a token is set, use that token for auhentication
        // otherwise, use auth0 (logged in) or visitor mode
        AuthProvider.setTokenGenerator(() => Promise.resolve(sessionToken));
        dispatch(setAccessMode(AccessMode.EXTERNAL_USER));
        (async () => {
          await initializeUserSaga({ accessMode: AccessMode.EXTERNAL_USER });
          setIsLoadingUser(false);
        })();
        return;
      }
    } else if (isCallbackRoute() && redirectInitiatedByEmbedUser) {
      // If in embedded mode and oauth redirect initiated by embed user
      // We are going to load the current user, server will return the token as http-only cookie
      // Server will be able to authenticate the user when exchanging the code for access token
      dispatch(setAccessMode(AccessMode.EXTERNAL_USER));
      (async () => {
        await initializeUserSaga({ accessMode: AccessMode.EXTERNAL_USER });
        setIsLoadingUser(false);
      })();
      return;
    }
    if (isAuthenticated) {
      // set token generator to have global access to bearer token
      AuthProvider.setTokenGenerator(getAccessTokenSilently);
      dispatch(setAccessMode(AccessMode.AUTH_USER));
      // Make sure we have user & org before loading into app
      (async () => {
        await initializeUserSaga({ accessMode: AccessMode.AUTH_USER });
        setIsLoadingUser(false);
      })();
    } else if (
      (!auth0Error && shouldRedirectToLogin()) ||
      integrationAuthRedirectInProgress
    ) {
      // Get connection name from the Initiate Login URI
      // This enables OpenID apps to pass the corresponding Auth0 connection name
      // for a true 1-click sign-in experience.
      // Ref: https://support.auth0.com/tickets/00509876
      const connectionName =
        new URLSearchParams(window.location.search).get("connection") ??
        undefined;

      dispatch(setAccessMode(AccessMode.AUTH_USER));
      loginWithRedirect({
        // Set the return URL to the path and query params For example, if the
        // original URL path is `/applications/:appId/edit` and there is one query
        // param `environment=production`, and one hash `code=foo`, the return URL will be
        // `/applications/:appId/edit?environment=production#code=foo`.
        // The hash codes are used during in-app OAuth callbacks to authorize datasources.
        appState: {
          returnUrl: `${window.location.pathname}${window.location.search}${window.location.hash}`,
        },
        connection: connectionName,
      });
    } else {
      dispatch(setAccessMode(AccessMode.VISITOR));

      // We don't have enough info to load the organization for now
      (async () => {
        await initializeUserSaga({ accessMode: AccessMode.VISITOR });
        setIsLoadingUser(false);
      })();
    }
  }, [
    isAuthenticated,
    initializeUserSaga,
    getAccessTokenSilently,
    setIsLoadingUser,
    dispatch,
    loginWithRedirect,
    auth0Loading,
    auth0Error,
    integrationAuthRedirectInProgress,
    redirectInitiatedByEmbedUser,
  ]);

  if (auth0Loading || isLoadingUser) {
    return <FullPageSpinner />;
  }
  if (auth0Error && !isCallbackRoute() && !redirectInitiatedByEmbedUser) {
    log.error(JSON.stringify(auth0Error));
    log.error(auth0Error.message);
    sendEventToEmbedder({
      type: OutgoingMessage.AUTH_ERROR,
      data: {
        error: "unauthorized",
        message: auth0Error.message,
      },
    });
    return (
      <ErrorComponent
        title="Login Failed"
        errorCode="LOGIN_FAILURE"
        errorMessage={
          <>
            <span>{`${auth0Error.message}, see `}</span>
            <a href={STATUS_PAGE} target="_blank" rel="noreferrer">
              Status Page
            </a>
          </>
        }
        buttonText={"Retry login"}
        handleButtonClick={() => {
          logout({ returnTo: window.location.origin });
        }}
      />
    );
  }
  return <>{children}</>;
};

const shouldRedirectToLogin = () => {
  if (isOnEmbedRoute()) return false;
  const isApplicationsPage = window.location.pathname.match(/\/application.*$/);

  const isApplicationsEditPage = window.location.pathname.match(
    /\/application.*\/edit.*?$/,
  );

  const isApplicationsViewPage = isApplicationsPage && !isApplicationsEditPage;
  return !isApplicationsViewPage;
};

// this is relevant for integration that send state parameter
// for other integrations, there's no 'state' parameter being sent so no conflict with auth0's state parameter
const isIntegrationAuthRedirectInProgress = (): [boolean, boolean] => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get("state");
  if (isEmpty(state) || state === null) {
    return [false, false];
  }
  try {
    const authState: DatasourceOneTimeState = JSON.parse(atob(state));
    return [!isEmpty(authState.oneTimeCode), authState.externalUser];
  } catch {
    // not a Superblocks-generated integration auth state, ignore
  }
  return [false, false];
};

function isCallbackRoute() {
  return window.location.pathname === "/oauth/callback";
}

export default AuthWrapper;
