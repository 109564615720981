import { SelectPopoverProps } from "@blueprintjs/select";
import { RoleDto, TASKS } from "@superblocksteam/shared";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as GroupIcon } from "assets/icons/common/users.svg";
import { PrimaryButton } from "components/ui/Button";
import Link from "components/ui/Link";
import {
  DropdownOption as DropdownOptionType,
  RecommendedMultiDropdown,
} from "components/ui/RecommendedMultiDropdown";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import {
  Invitee,
  InviteeToSend,
  SHARE_MODAL_ROLE_SELECT_WIDTH,
} from "pages/Permissions/constants";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import ColoredAvatar from "./ColoredAvatar";

type DropdownOption = DropdownOptionType<Invitee>;

const ROLE_SELECT_WIDTH = 120;

const StyledSelectRow = styleAsClass`
  width: 100%;
  display: flex;
  justify-content: end;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
`;
const StyledSelectWrapper = styleAsClass`
  flex: 1 0;
  max-width: 100%;
  min-width: 0;
`;

const RoleSelectWrapper = styleAsClass`
  display: flex;
  align-items: center;
  input {
    border: 1px solid transparent !important;
    font-weight: 500;
  }
  input:focus, input:hover{
    border: 1px solid transparent !important;
  }
  .bp5-input-group::before {
    svg {
      fill: ${colors.GREY_300};
    }
  }
`;
const RightElementWrapper = styleAsClass`
  input {
   min-height: 30px !important;
  }
`;

const StyledRoleBox = styleAsClass`
  flex: 0 0 ${String(ROLE_SELECT_WIDTH)}px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
  border: 1px solid transparent;
  border-radius: 4px;
  flex-basis: 80px;
  letter-spacing: normal;
`;
const StyledInviteButtonWrapper = styleAsClass`
  flex: 0 0 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const UserHeaderOption = {
  key: "options-group-header-user",
  value: "options-group-header-user",
  displayName: "Users",
  isGroupHeader: true,
};

const GroupHeaderOption = {
  key: "options-group-header-group",
  value: "options-group-header-group",
  displayName: "Groups",
  isGroupHeader: true,
};

interface Props {
  allInvitees: Invitee[];
  onAddInvitees?: (props: {
    invitees: InviteeToSend[];
    role?: RoleDto;
  }) => Promise<void>;
  roles?: RoleDto[];
  inviteButtonText?: string;
  placeholder?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  noGrouping?: boolean;
  onSelectedInviteesChange?: (invitees: Invitee[]) => void;
  noResultsMessage?: string | JSX.Element;
  disabled?: boolean;
  inviteButtonWidth?: number;
  popoverProps?: SelectPopoverProps["popoverProps"];
}
// TODO: replace this with RecommendedMultiDropdown
const SearchAndInvite = (props: Props) => {
  const [selectedInviteeOptions, setSelectedInviteeOptions] = useState<
    DropdownOption[]
  >([]);
  const [searchInviteeInput, setSearchInviteeInput] = useState<string>("");
  const [roleForInvitees, setRoleForInvitees] = useState("Developer");
  const [inviteCallPending, setInviteCallPending] = useState(false);
  useEffect(() => {
    return () => {
      setSelectedInviteeOptions([]);
      setSearchInviteeInput("");
      setRoleForInvitees("Developer");
    };
  }, []);

  const allOptions: DropdownOption[] = useMemo(() => {
    let firstGroupIndex = -1;
    const nonSelectedInvitees = props.allInvitees.filter(
      (invitee) =>
        !selectedInviteeOptions.some(
          (o) =>
            o.value ===
            (invitee.type === "user" ? invitee.email : invitee.name),
        ),
    );

    let hasUsers = false;
    let hasGroups = false;
    const optionsWithoutGrouping = nonSelectedInvitees.map((invitee, index) => {
      const identifier = invitee.type === "user" ? invitee.email : invitee.name;
      const displayName = invitee.name;
      if (invitee.type === "user") {
        hasUsers = true;
      }
      if (invitee.type === "group") {
        if (firstGroupIndex === -1) {
          firstGroupIndex = index;
        }
        hasGroups = true;
      }
      return {
        key: identifier,
        value: identifier,
        optionData: invitee,
        displayName,
        icon:
          invitee.type === "user" ? (
            <ColoredAvatar name={invitee.name}>
              {invitee.name?.charAt(0).toUpperCase()}
            </ColoredAvatar>
          ) : (
            <ColoredAvatar>
              <GroupIcon width="16" />
            </ColoredAvatar>
          ),
        subText:
          invitee.type === "group"
            ? `${invitee?.size} members`
            : invitee?.email,
      };
    });

    return props.noGrouping
      ? optionsWithoutGrouping
      : [
          ...(hasUsers ? [UserHeaderOption] : []),
          ...(firstGroupIndex === -1
            ? optionsWithoutGrouping
            : optionsWithoutGrouping.slice(0, firstGroupIndex)),
          ...(hasGroups ? [GroupHeaderOption] : []),
          ...(firstGroupIndex === -1
            ? []
            : optionsWithoutGrouping.slice(firstGroupIndex)),
        ];
  }, [props.allInvitees, props.noGrouping, selectedInviteeOptions]);

  const predicateFunc = useCallback(
    (query: string, items: DropdownOption[]) => {
      const queryLower = query.toLowerCase();
      if (query?.trim() === "") {
        return items;
      }
      let hasUsers = false;
      let hasGroups = false;

      const filteredOptionsWithoutGrouping = items.filter((item) => {
        const isMatched =
          !item.isGroupHeader && item.optionData?.type === "user"
            ? item.optionData?.name.toLowerCase().includes(queryLower) ||
              item.optionData?.email.toLowerCase().includes(queryLower)
            : item.optionData?.name.toLowerCase().includes(queryLower);
        if (isMatched && item.optionData?.type === "user") {
          hasUsers = true;
        }
        if (isMatched && item.optionData?.type === "group") {
          hasGroups = true;
        }
        return isMatched;
      });

      const firstGroupIndex = filteredOptionsWithoutGrouping.findIndex(
        (option) => option.optionData?.type === "group",
      );

      return props.noGrouping
        ? filteredOptionsWithoutGrouping
        : [
            ...(hasUsers ? [UserHeaderOption] : []),
            ...(firstGroupIndex === -1
              ? filteredOptionsWithoutGrouping
              : filteredOptionsWithoutGrouping.slice(0, firstGroupIndex)),
            ...(hasGroups ? [GroupHeaderOption] : []),
            ...(firstGroupIndex === -1
              ? []
              : filteredOptionsWithoutGrouping.slice(firstGroupIndex)),
          ];
    },
    [props.noGrouping],
  );

  const roleOptions = props.roles?.map((role) => ({
    key: role.name,
    value: role.name,
    displayName: role.name,
    dataTest: `invitees-role-option-${role.name}`,
  }));

  const inviteeSelectOnSearch = useCallback((value: any) => {
    setSearchInviteeInput(value);
  }, []);

  const inviteeSelectOnChange = useCallback(
    (selectedOptions: DropdownOption[]) => {
      setSelectedInviteeOptions(selectedOptions);
      if (props.onSelectedInviteesChange) {
        const invitees = selectedOptions
          .map((option) => option.optionData)
          .filter((invitee) => invitee !== undefined) as Invitee[];
        props.onSelectedInviteesChange(invitees);
      }
      setSearchInviteeInput("");
    },
    [props],
  );

  const hasAutoCompleteOptions = useRef(false);
  const onFilterItems = useCallback((options: DropdownOption[]) => {
    if (options.length === 0) {
      hasAutoCompleteOptions.current = false;
    } else {
      hasAutoCompleteOptions.current = true;
    }
  }, []);

  const inviteeSelectOnBlur = useCallback(() => {
    setSearchInviteeInput("");
  }, []);

  const roleSelectOnSelect = useCallback(
    (option: DropdownOption) => setRoleForInvitees(option.value),
    [],
  );

  const [markTaskComplete] = useMarkTaskComplete(TASKS.INVITE_TEAMMATE);
  const inviteButtonOnClick = useCallback(async () => {
    const invitees: Invitee[] = selectedInviteeOptions
      .map((option) => option.optionData)
      .filter((invitee) => invitee !== undefined) as Invitee[];

    const inviteesToSend: InviteeToSend[] = invitees.map((invitee) => {
      if (invitee.type === "user") {
        return {
          type: "user",
          id: invitee.id ?? "",
          name: invitee.name,
        };
      }
      return {
        type: "group",
        id: invitee.id,
        name: invitee.name,
      };
    });
    setInviteCallPending(true);
    try {
      const roleToAdd = props.roles?.find(
        (role: RoleDto) => role.name === roleForInvitees,
      );

      await props.onAddInvitees?.({
        invitees: inviteesToSend,
        role: roleToAdd,
      });
      setSelectedInviteeOptions([]);
      setSearchInviteeInput("");
      markTaskComplete();
    } catch {
      //error handled by user of this component, but still throwed here to avoid clearing input on fail
    } finally {
      setInviteCallPending(false);
    }
  }, [markTaskComplete, props, roleForInvitees, selectedInviteeOptions]);

  return (
    <div className={StyledSelectRow}>
      <div className={StyledSelectWrapper}>
        <RecommendedMultiDropdown
          dataTest="invitees-search-select"
          options={allOptions}
          selectedItems={selectedInviteeOptions}
          onChange={inviteeSelectOnChange}
          disabled={props.disabled}
          noResultsMessage={
            props.noResultsMessage ?? (
              <span>
                No matching user or group.{" "}
                <Link to="/home/invite" target="_blank" rel="noreferrer">
                  Invite to Superblocks{" "}
                </Link>
              </span>
            )
          }
          parentRef={props.parentRef}
          queryInput={searchInviteeInput}
          onQueryChange={inviteeSelectOnSearch}
          onFilterItems={onFilterItems}
          onInputBlur={inviteeSelectOnBlur}
          placeholder={
            props.placeholder || "Begin typing to select users or groups..."
          }
          popoverProps={{
            hasBackdrop: false, // so that you can click share button without closing the popover
            ...(props.popoverProps ?? {}),
          }}
          hideCheckbox={true}
          customPredicate={predicateFunc}
          rightElement={
            props.roles &&
            props.roles?.length > 0 &&
            roleOptions &&
            selectedInviteeOptions.length > 0 ? (
              props.roles.length === 1 ? (
                <div className={`${StyledRoleBox} ${RightElementWrapper}`}>
                  {props.roles[0].name}
                </div>
              ) : (
                <div className={`${RoleSelectWrapper} ${RightElementWrapper}`}>
                  <RecommendedSingleDropdown
                    value={roleForInvitees}
                    onChange={roleSelectOnSelect}
                    options={roleOptions}
                    parentRef={props.parentRef}
                    width={SHARE_MODAL_ROLE_SELECT_WIDTH}
                    disableSearch={true}
                  />
                </div>
              )
            ) : undefined
          }
        />
      </div>
      {props.onAddInvitees && (
        <div
          className={StyledInviteButtonWrapper}
          style={
            props.inviteButtonWidth
              ? { flex: `0 0 ${props.inviteButtonWidth}px` }
              : undefined
          }
        >
          <PrimaryButton
            data-test="invitees-invite-button"
            type="primary"
            onClick={inviteButtonOnClick}
            disabled={selectedInviteeOptions.length === 0 || props.disabled}
            loading={inviteCallPending}
          >
            {props.inviteButtonText || "Share"}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default SearchAndInvite;
