export type ValidationResult = {
  ok: boolean;
  error?: string;
};

export const STRING_VALIDATOR = (label: string, val: any): ValidationResult => {
  if ((val as string).length > 256) {
    return { ok: false, error: `${label} exceeds the maximum length.` };
  }
  return { ok: true };
};
