import {
  DB_SQL_INITIAL_TEXT,
  EditorLanguage,
  FormComponentType,
  FormItem,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';
import { CONNECTION_METHODS_AND_DISPLAY_NAMES, makeDropdownItem } from './shared/db';

export const OracleDbPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2'
};

const BASE_HOST = {
  label: 'Host URL',
  name: 'connection.hostUrl',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'Oracle Cloud Host URL',
  rules: [{ required: true, message: 'Host URL is required' }]
};

const BASE_PORT = {
  label: 'Port',
  name: 'connection.port',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.NUMBER,
  initialValue: 1521,
  placeholder: '1521',
  rules: [{ required: true, message: 'Port is required' }]
};

const BASE_DATABASE_NAME = {
  label: 'Database service name',
  name: 'connection.databaseService',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_ENABLE_TCPS = {
  label: 'Use TCPS',
  name: 'connection.useTcps',
  initialValue: true,
  componentType: FormComponentType.CHECKBOX
};

export const OracleDbPlugin: Plugin = {
  id: 'oracledb',
  name: 'Oracle Cloud Database',
  moduleName: 'OracleDbPlugin',
  modulePath: 'plugins/oracledb/OracleDbPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/oracledb.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/oracledb',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: OracleDbPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Connection method',
            name: 'connection.connectionType',
            startVersion: OracleDbPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          {
            label: 'Connection String',
            name: 'connection.connectionUrl',
            startVersion: OracleDbPluginVersions.V2,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `mydbmachine.example.com/orclpdb1`,
            tooltip: {
              markdownText:
                'Learn more about [Oracle connection options](https://node-oracledb.readthedocs.io/en/latest/user_guide/connection_handling.html#connection-strings)'
            },
            rules: [{ required: true, message: 'Connection String is required' }],
            display: {
              show: {
                ['connection.connectionType']: ['url']
              }
            }
          },
          // HOST
          {
            ...BASE_HOST,
            startVersion: OracleDbPluginVersions.V1,
            endVersion: OracleDbPluginVersions.V1
          } as FormItem,
          {
            ...BASE_HOST,
            startVersion: OracleDbPluginVersions.V2,
            display: {
              show: {
                ['connection.connectionType']: ['fields']
              }
            }
          } as FormItem,
          // PORT
          {
            ...BASE_PORT,
            startVersion: OracleDbPluginVersions.V1,
            endVersion: OracleDbPluginVersions.V1
          } as FormItem,
          {
            ...BASE_PORT,
            startVersion: OracleDbPluginVersions.V2,
            display: {
              show: {
                ['connection.connectionType']: ['fields']
              }
            }
          } as FormItem,
          // DATABASE NAME
          {
            ...BASE_DATABASE_NAME,
            startVersion: OracleDbPluginVersions.V1,
            endVersion: OracleDbPluginVersions.V1
          } as FormItem,
          {
            ...BASE_DATABASE_NAME,
            startVersion: OracleDbPluginVersions.V2,
            display: {
              show: {
                ['connection.connectionType']: ['fields']
              }
            }
          } as FormItem,
          // DATABASE USERNAME is needed by both fields and connection string
          {
            label: 'Database username',
            name: 'connection.user',
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'User is required' }],
            startVersion: OracleDbPluginVersions.V1
          } as FormItem,
          // DATABASE PASSWORD is needed by both fields and connection string
          {
            label: 'Database password',
            name: 'connection.password',
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'Password is required' }],
            startVersion: OracleDbPluginVersions.V1
          } as FormItem,
          // ENABLE TCPS
          {
            ...BASE_ENABLE_TCPS,
            startVersion: OracleDbPluginVersions.V1,
            endVersion: OracleDbPluginVersions.V1
          } as FormItem,
          {
            ...BASE_ENABLE_TCPS,
            startVersion: OracleDbPluginVersions.V2,
            display: {
              show: {
                ['connection.connectionType']: ['fields']
              }
            }
          } as FormItem
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'runSql.sqlBody',
            startVersion: OracleDbPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'runSql.useParameterized',
            startVersion: OracleDbPluginVersions.V1,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
