import { LoadingOutlined } from "@ant-design/icons";
import {
  EmailVerificationStatus,
  EMAIL_VERIFICATION_CODE_LENGTH,
  AccessMode,
} from "@superblocksteam/shared";
import { Alert, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Button } from "components/ui/Button";
import { FullWidthSpace } from "components/ui/Space";
import { LOGOUT_URL, SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { getCurrentUser, getAccessMode } from "legacy/selectors/usersSelectors";
import { PageWrapper } from "pages/components/PageWrapper";
import { REQUEST_CODE, VERIFY_EMAIL } from "pages/routes";
import { callServer, HttpMethod } from "store/utils/client";
import { colors } from "styles/colors";
import logger from "utils/logger";
import { IntercomOverlay } from "../Intercom/IntercomOverlay";
import { INTERCOM_LAUNCHER_CLASSNAME } from "../Intercom/constants";
import {
  QuestionLabel,
  SecondaryText,
  SurveyItemSlide,
} from "../Survey/SurveyUtilities";
import VerificationInput from "./VerificationInput";

const LinkButton = styled(Button)`
  padding: 0px;
  color: ${(props) => props.theme.colors.ACCENT_PURPLE};
  background-color: unset;

  :hover,
  :focus {
    color: ${(props) => props.theme.colors.ACCENT_PURPLE};
    background-color: unset;
  }
`;

const EmailVerificationWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentUser = useSelector(getCurrentUser);
  const emailAddress = useMemo(
    () => currentUser?.email ?? "",
    [currentUser?.email],
  );
  const accessMode = useSelector(getAccessMode);

  const codeLength = EMAIL_VERIFICATION_CODE_LENGTH;
  const emptyValues = Array(codeLength).fill("");
  const [values, setValues] = useState(emptyValues);

  const [resendLoading, setResendLoading] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  const [notice, setNotice] = useState<
    { message: string; type: "success" | "error" } | undefined
  >();
  const [hasSentEmail, setHasSentEmail] = useState(false);

  const requestNewCode = useCallback(
    (email: string | undefined, force = false) => {
      if (isEmpty(email)) {
        logger.error(`Email verification code requested without and email`);
      }
      if (force) {
        setNotice(undefined);
        setResendLoading(true);
      }
      return callServer<boolean>({
        method: HttpMethod.Post,
        url: REQUEST_CODE,
        body: {
          email: email,
          force: force,
        },
      }).finally(() => {
        if (force) {
          setResendLoading(false);
          setNotice({
            message: `A new code has been sent to ${emailAddress}`,
            type: "success",
          });
          setHasSentEmail(true);
          setTimeout(() => setHasSentEmail(false), 5000);
        }
      });
    },
    [emailAddress],
  );

  const verifyCode = useCallback(async (email: string, code: string) => {
    setVerificationLoading(true);
    try {
      const res = await callServer<{ success: boolean; error: string }>({
        method: HttpMethod.Post,
        url: VERIFY_EMAIL,
        body: {
          email: email,
          code: code,
        },
      });
      if (res.success) {
        setNotice({
          message: "Email successfully verified! Redirecting...",
          type: "success",
        });
        window.location.reload();
      } else {
        setNotice({
          message: res.error,
          type: "error",
        });
      }
    } finally {
      setVerificationLoading(false);
    }
  }, []);

  const resetValues = useCallback(() => {
    setValues(emptyValues);
  }, [emptyValues]);

  const onComplete = useCallback(
    (values: any) => {
      return verifyCode(emailAddress, values.join(""));
    },
    [emailAddress, verifyCode],
  );

  useEffect(() => {
    if (
      accessMode === AccessMode.AUTH_USER &&
      currentUser?.emailVerificationStatus !== EmailVerificationStatus.VERIFIED
    ) {
      requestNewCode(emailAddress);
    }
  }, [
    emailAddress,
    currentUser?.emailVerificationStatus,
    requestNewCode,
    accessMode,
  ]);

  const navigate = useNavigate();
  const handleLogout = useCallback(
    () => navigate({ pathname: LOGOUT_URL }),
    [navigate],
  );

  if (
    accessMode === AccessMode.VISITOR ||
    accessMode === AccessMode.EXTERNAL_USER ||
    currentUser?.emailVerificationStatus === EmailVerificationStatus.VERIFIED
  ) {
    return <>{children}</>;
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <IntercomOverlay>
      <PageWrapper pageName={"Email Verification"}>
        <SurveyItemSlide>
          <FullWidthSpace direction="vertical" size={30} align="start">
            <FullWidthSpace direction="vertical" size={20} align="start">
              <>
                <QuestionLabel>
                  Check your email for a confirmation code
                </QuestionLabel>
                <SecondaryText>
                  We&apos;ve sent an email to <b>{`${emailAddress}`}</b> with
                  your confirmation code
                </SecondaryText>
              </>
              <SecondaryText></SecondaryText>
              <VerificationInput
                length={codeLength}
                values={values}
                setParentValues={setValues}
                onComplete={onComplete}
                resetValues={resetValues}
              />
              {verificationLoading && <Spin indicator={antIcon} />}
              {notice && <Alert message={notice.message} type={notice.type} />}
              <FullWidthSpace direction="horizontal" size={20}>
                <LinkButton
                  type="text"
                  onClick={() => requestNewCode(emailAddress, true)}
                  loading={resendLoading}
                  disabled={hasSentEmail}
                >
                  {hasSentEmail ? "Sent!" : "Resend code"}
                </LinkButton>
                <LinkButton type="text" onClick={() => handleLogout()}>
                  Logout
                </LinkButton>
              </FullWidthSpace>
            </FullWidthSpace>
            <FullWidthSpace direction="vertical" size={6}>
              <FullWidthSpace
                direction="horizontal"
                size={2}
                style={{ color: colors.GREY_500 }}
              >
                {`Didn't get an email? Search for "Superblocks Verification Code" or check your spam folder.`}
              </FullWidthSpace>
              <FullWidthSpace
                direction="horizontal"
                size={2}
                style={{ color: colors.GREY_500 }}
              >
                {`Still can't find it? Sorry about that. `}
                <LinkButton type="link" className={INTERCOM_LAUNCHER_CLASSNAME}>
                  Chat with us
                </LinkButton>
                {` or `}
                <LinkButton
                  type="link"
                  href={`mailto:${SUPPORT_EMAIL_ADDRESS}?subject=Verifying ${emailAddress} on Superblocks`}
                >
                  email support
                </LinkButton>
                {` from ${emailAddress} and we'll help sort it out.`}
              </FullWidthSpace>
            </FullWidthSpace>
          </FullWidthSpace>
        </SurveyItemSlide>
      </PageWrapper>
    </IntercomOverlay>
  );
};

EmailVerificationWrapper.displayName = "Verify email";

export default EmailVerificationWrapper;
