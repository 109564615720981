import { DB_SQL_INITIAL_TEXT, EditorLanguage, FormComponentType, Plugin, PluginResponseType, PluginType } from '../../types';

export const RocksetPluginVersions = {
  V1: '0.0.1',
  V7: '0.0.7'
};

const RocksetRegionBaseURL = {
  US_WEST_2: 'https://api.usw2a1.rockset.com',
  US_EAST_1: 'https://api.use1a1.rockset.com',
  EU_CENTRAL_1: 'https://api.euc1a1.rockset.com'
};

const ROCKSET_REGION_OPTIONS = {
  US_WEST_2: {
    displayName: 'Oregon (us-west-2)',
    key: RocksetRegionBaseURL.US_WEST_2,
    value: RocksetRegionBaseURL.US_WEST_2
  },
  US_EAST_1: {
    displayName: 'N. Virginia (us-east-1)',
    key: RocksetRegionBaseURL.US_EAST_1,
    value: RocksetRegionBaseURL.US_EAST_1
  },
  EU_CENTRAL_1: {
    displayName: 'Frankfurt (eu-central-1)',
    key: RocksetRegionBaseURL.EU_CENTRAL_1,
    value: RocksetRegionBaseURL.EU_CENTRAL_1
  }
};

// NOTE(taha) This is the default value for the region dropdown in the Rockset integration form, as well as the default value for the
// region-based baseURL in the Rockset plugin code (packages/plugins/rockset/src/index.ts). If you change this value, existing Rockset
// integration instances (created using v1) might no longer work. If you absolutely need to change this value, you'll need to run a migration
// to set the baseURL for all existing Rockset integration configurations first.
export const DEFAULT_ROCKSET_REGION_BASE_URL = RocksetRegionBaseURL.US_WEST_2;

export const RocksetPlugin: Plugin = {
  id: 'rockset',
  name: 'Rockset',
  moduleName: 'RocksetPlugin',
  modulePath: 'plugins/rockset/RocksetPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/rockset.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/rockset',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: RocksetPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'Example Rockset integration',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'API key',
            name: 'apiKey',
            startVersion: RocksetPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'API key is required' }]
          },
          {
            label: 'Region',
            name: 'baseURL',
            startVersion: RocksetPluginVersions.V7,
            componentType: FormComponentType.DROPDOWN,
            // Note(taha) Avoid changing this initialValue as existing Rockset integration instances (created using v1) don't have a baseURL specified.
            // Changing this will cause a different value to be persisted on integration update than the one that is being defaulted to in the plugin code.
            initialValue: DEFAULT_ROCKSET_REGION_BASE_URL,
            rules: [{ required: true }],
            options: [ROCKSET_REGION_OPTIONS.US_WEST_2, ROCKSET_REGION_OPTIONS.US_EAST_1, ROCKSET_REGION_OPTIONS.EU_CENTRAL_1]
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'body',
            startVersion: RocksetPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      }
    ]
  }
};
