import { Popconfirm } from "antd";
import { LegacyButtonType } from "antd/lib/button/button";
import React, { useCallback, useMemo } from "react";
import {
  DangerButtonCssString,
  PrimaryButtonCssString,
  SecondaryButtonCssString,
} from "components/ui/Button";
import { styleAsClass } from "styles/styleAsClass";

const PopconfirmOverlayClass = styleAsClass`
  &.ant-popconfirm .ant-popover-inner .ant-popover-buttons .ant-btn-default {
   ${SecondaryButtonCssString}
  }
  &.ant-popconfirm .ant-popover-inner .ant-popover-buttons .ant-btn-primary {
    ${PrimaryButtonCssString}
  }
  &.ant-popconfirm .ant-popover-inner .ant-popover-buttons .ant-btn-dangerous {
    ${DangerButtonCssString}
  }
`;

export const usePopconfirm = ({
  onConfirm,
  children,
  title,
  confirmType,
  confirmText = "Confirm",
  icon,
  confirmOpen: confirmOpenExternal,
  setConfirmOpen: setConfirmOpenExternal,
}: {
  onConfirm: () => void;
  children: React.ReactNode;
  title: string;
  confirmText?: string;
  confirmType?: LegacyButtonType;
  icon?: React.ReactNode;
  confirmOpen?: boolean;
  setConfirmOpen?: (open: boolean) => void;
}) => {
  const [confirmOpenInternal, setConfirmOpenInternal] = React.useState(false);
  const confirmOpen = confirmOpenExternal ?? confirmOpenInternal;
  const setConfirmOpen = setConfirmOpenExternal ?? setConfirmOpenInternal;

  const onDeleteConfirm = useCallback(() => {
    onConfirm();
    setConfirmOpen(false);
  }, [onConfirm, setConfirmOpen]);

  const onDeleteCancel = useCallback(() => {
    setConfirmOpen(false);
  }, [setConfirmOpen]);

  const onDeleteConfirmVisibleChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        // when unfocus from pop confirm
        setConfirmOpen(false);
      }
    },
    [setConfirmOpen],
  );

  const handleWrapperClick = useCallback(() => {
    setConfirmOpen(true);
  }, [setConfirmOpen]);

  const popover = useMemo(
    () => (
      <Popconfirm
        title={title}
        open={confirmOpen}
        okText={<span data-test={"pop-confirm"}>{confirmText}</span>}
        okType={confirmType}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        onOpenChange={onDeleteConfirmVisibleChange}
        overlayClassName={`${PopconfirmOverlayClass}`}
        icon={icon}
      >
        <div
          onClick={setConfirmOpenExternal ? undefined : handleWrapperClick}
          style={{ display: "inline-block" }}
        >
          {children}
        </div>
      </Popconfirm>
    ),
    [
      title,
      confirmOpen,
      confirmText,
      confirmType,
      onDeleteConfirm,
      onDeleteCancel,
      onDeleteConfirmVisibleChange,
      icon,
      setConfirmOpenExternal,
      handleWrapperClick,
      children,
    ],
  );

  return { popover, confirmOpen, setConfirmOpen };
};
