import {
  Dimension,
  DimensionModes,
  Margin,
  Padding,
} from "@superblocksteam/shared";
import {
  CanvasAlignment,
  CanvasDefaults,
  CanvasLayout,
  GridDefaults,
  MIN_MAX_BUFFER_PX,
} from "legacy/constants/WidgetConstants";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { GeneratedTheme } from "legacy/themes";
import { type CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { isStackLayout } from "legacy/widgets/StackLayout/utils";
import {
  getBorderThickness,
  getWidgetDefaultPadding,
  hackyIsWidgetCollapsed,
} from "./canvasSizingUtil";
import { isFixedWidth } from "./dynamicLayoutUtils";
import type {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
  WidgetLayoutProps,
} from "../../shared";

export type WidthHydrationContext = {
  visited: Set<string>;
  overridenCanvasId?: string;
};

const setWidthValues = (widget: WidgetLayoutProps, width: Dimension<"px">) => {
  widget.dynamicWidgetLayout = {
    ...widget.dynamicWidgetLayout,
    width,
  };
  const widthValue = Dimension.toGridUnit(width, widget.parentColumnSpace).raw()
    .value;
  widget.width = {
    ...widget.width,
    value: widthValue,
  };
};

export const hydrateFitContentWidths = (
  widget: WidgetLayoutProps,
  context: WidthHydrationContext,
  parent: WidgetLayoutProps,
  theme: GeneratedTheme,
  appMode: APP_MODE,
) => {
  if (context.visited.has(widget.widgetId)) return;
  context.visited.add(widget.widgetId);

  (widget.children ?? []).forEach((child) => {
    child.parentColumnSpace = widget.parentColumnSpace;
  });

  if (widget.type !== WidgetTypes.CANVAS_WIDGET) {
    // then we are container or form
    if (widget.width.mode !== "fitContent") {
      return;
    }
  }

  // at this point we are guaranteed to be a direct child of a fitContent widget (or its canvas)
  widget.children?.forEach((child) => {
    child.parentColumnSpace = widget.parentColumnSpace;
    child.parentLayout = widget.layout; // needed so calculations involving getApplicableMin/Max width work
    hydrateFitContentWidths(child, context, widget, theme, appMode);
  });

  if (widget.type === WidgetTypes.CANVAS_WIDGET) {
    let minWidth = 0;
    if (!widget.children?.length && appMode === APP_MODE.EDIT) {
      // is empty
      minWidth = GridDefaults.EMPTY_CONTAINER_EDIT_MODE_WIDTH_PX;
    } else if (widget.widgetId === context.overridenCanvasId) {
      minWidth = widget.dynamicWidgetLayout?.width?.value ?? 0;
    } else {
      minWidth = getCanvasMinWidthNested(widget)?.value ?? 0;
    }

    const widgetPadding =
      widget.padding ?? getWidgetDefaultPadding(theme, widget);
    const excessWidth =
      Padding.x(widgetPadding).value +
      getBorderThickness(parent, theme, widget) * 2;
    minWidth += excessWidth;
    setWidthValues(widget, Dimension.px(minWidth));
  } else if (widget.children?.length) {
    // Then we are container/form
    const firstCanvas = widget.children[0];
    let widthPx = firstCanvas.dynamicWidgetLayout?.width?.value;
    if (widthPx) {
      const minWidth = getApplicableMinWidth(widget);
      const maxWidth = getApplicableMaxWidth(widget);

      if (minWidth) {
        widthPx = Math.max(widthPx, minWidth.value);
      }
      if (maxWidth) {
        widthPx = Math.min(widthPx, maxWidth.value);
      }

      setWidthValues(widget, Dimension.px(widthPx));
    }
  }
};
export function getCanvasMinWidthFlattened(
  props: FlattenedWidgetLayoutProps,
  widgets: FlattenedWidgetLayoutMap,
): Dimension<"px"> {
  const children = (widgets[props.widgetId]?.children ?? []).map(
    (id) => widgets[id],
  );
  return getCanvasMinWidth(props, children);
}

export function getCanvasMinWidthNested(widget: WidgetLayoutProps) {
  return getCanvasMinWidth(widget, widget.children ?? []);
}

export function getCanvasMinWidth(
  props: WidgetLayoutProps | FlattenedWidgetLayoutProps,
  children: (WidgetLayoutProps | FlattenedWidgetLayoutProps)[],
): Dimension<"px"> {
  const layout = props.layout ?? CanvasLayout.FIXED;

  switch (layout) {
    case CanvasLayout.FIXED:
      return getMinWidthForFixedGrid(children);
    case CanvasLayout.VSTACK:
      return getMinWidthForVStack(
        children,
        props.alignment === CanvasAlignment.STRETCH,
      );
    case CanvasLayout.HSTACK:
      return getMinWidthForHStack(children, props);
  }
}

export function getWidgetMinWidth(
  widget: WidgetLayoutProps | FlattenedWidgetLayoutProps,
  parentColumnSpace = widget.parentColumnSpace ??
    GridDefaults.DEFAULT_GRID_COLUMNS,
  isParentStretched = false,
) {
  let minDynamicWidth = GridDefaults.FILL_PARENT_DEFAULT_MIN_WIDTH_PX;
  const minWidth = getApplicableMinWidth(widget);
  const maxWidth = getApplicableMaxWidth(widget);
  if (minWidth) {
    const minWidthPx = Dimension.toPx(minWidth, parentColumnSpace).value;
    minDynamicWidth = Math.max(minDynamicWidth, minWidthPx);
  }
  if (maxWidth) {
    const maxWidthPx = Dimension.toPx(maxWidth, parentColumnSpace).value;
    minDynamicWidth = Math.min(minDynamicWidth, maxWidthPx);
  }

  if (widget.width.mode === "fitContent") {
    return Math.max(
      widget.dynamicWidgetLayout?.width?.value ?? minDynamicWidth,
      minDynamicWidth,
    );
  } else if (widget.width.mode === "fillParent" || isParentStretched) {
    return minDynamicWidth;
  } else if (widget.width.mode === "gridUnit" && (minWidth || maxWidth)) {
    let widthPx = Dimension.toPx(widget.width, parentColumnSpace).value;

    if (minWidth) {
      widthPx = Math.max(widthPx, minWidth.value);
    }
    if (maxWidth) {
      widthPx = Math.min(widthPx, maxWidth.value);
    }
    return widthPx;
  }

  return Dimension.toPx(widget.width, parentColumnSpace).value;
}

function getChildWidgetMinWidthPx(
  widget: WidgetLayoutProps | FlattenedWidgetLayoutProps,
  isParentStretched?: boolean,
): number {
  const widgetWidthPx = getWidgetMinWidth(widget, undefined, isParentStretched);
  const marginXPx = Margin.x(widget.margin).value ?? 0;

  if (hackyIsWidgetCollapsed(widget)) {
    return 0;
  }

  return widgetWidthPx + marginXPx;
}

function getMinWidthForFixedGrid(
  children: (WidgetLayoutProps | FlattenedWidgetLayoutProps)[],
): Dimension<"px"> {
  let rightColumnPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;
    const childRightColumn =
      Dimension.toPx(child.left, child.parentColumnSpace).value +
      Dimension.toPx(child.width, child.parentColumnSpace).value;
    if (childRightColumn > rightColumnPx) {
      rightColumnPx = childRightColumn;
    }
  });
  return Dimension.px(rightColumnPx);
}

function getMinWidthForVStack(
  children: (WidgetLayoutProps | FlattenedWidgetLayoutProps)[],
  isParentStretched: boolean,
): Dimension<"px"> {
  let rightColumnPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;
    const childRightColumnPx = getChildWidgetMinWidthPx(
      child,
      isParentStretched,
    );
    if (childRightColumnPx > rightColumnPx) {
      rightColumnPx = childRightColumnPx;
    }
  });
  return Dimension.px(rightColumnPx);
}

function getMinWidthForHStack(
  children: (WidgetLayoutProps | FlattenedWidgetLayoutProps)[],
  props: {
    spacing?: Dimension<"px" | "gridUnit">;
    parentColumnSpace: number;
  },
): Dimension<"px"> {
  let childCount = 0;
  let childWidthsPx = 0;
  children?.forEach((child) => {
    if (child.detachFromLayout) return;
    const width = getChildWidgetMinWidthPx(child);

    if (width === 0) {
      return;
    }

    childCount++;
    childWidthsPx += width;
  });

  // note we should take the child's parentColumnSpace since the gap is applied on the child level
  const spacingPx = (props.spacing ?? CanvasDefaults.SPACING).value;
  const totalSpacing = childCount > 0 ? spacingPx * (childCount - 1) : 0;

  return Dimension.px(childWidthsPx + totalSpacing);
}

type WithWidgetWidthProperties<T extends DimensionModes> = {
  width: Dimension<T>;
  minWidth?: Dimension<"px">;
  maxWidth?: Dimension<"px">;
  parentLayout?: CanvasLayout;
};

export function getApplicableMaxWidth<T extends DimensionModes>(
  widget: WithWidgetWidthProperties<T>,
): undefined | Dimension<"px"> {
  if (!widget) {
    return undefined;
  }
  if (!isStackLayout(widget.parentLayout)) {
    return undefined;
  }
  if (isFixedWidth(widget.width.mode)) {
    return undefined;
  }
  return widget.maxWidth;
}

export function getApplicableMinWidth<T extends DimensionModes>(
  widget: WithWidgetWidthProperties<T>,
): undefined | Dimension<"px"> {
  if (!widget) {
    return undefined;
  }
  if (!isStackLayout(widget.parentLayout)) {
    return undefined;
  }
  if (isFixedWidth(widget.width.mode)) {
    return undefined;
  }
  return widget.minWidth;
}

export const clampMinMaxWidth = (params: {
  constraintType: "minWidth" | "maxWidth";
  newWidth: Dimension<"px">;
  widget: CanvasWidgetsReduxState[string];
  parentLayout?: CanvasLayout;
}) => {
  const { constraintType, widget, newWidth, parentLayout } = params;
  const applicableWidgetProps = { ...widget, parentLayout };
  const maxWidth: undefined | Dimension<"px"> = getApplicableMaxWidth(
    applicableWidgetProps,
  );
  const minWidth: undefined | Dimension<"px"> = getApplicableMinWidth(
    applicableWidgetProps,
  );

  if (constraintType === "minWidth") {
    const newWidget = {
      ...widget,
    };

    if (maxWidth && newWidth.value >= maxWidth.value) {
      newWidget.maxWidth = Dimension.px(newWidth.value + MIN_MAX_BUFFER_PX);
    }

    if (
      isFixedWidth(widget.width.mode) &&
      newWidth.value >= widget.width.value
    ) {
      newWidget.width = Dimension.px(newWidth.value);
    }

    return newWidget;
  }

  if (constraintType === "maxWidth") {
    const newWidget = {
      ...widget,
    };

    if (minWidth && newWidth.value <= minWidth.value) {
      newWidget.minWidth = Dimension.px(
        Math.max(
          newWidth.value - MIN_MAX_BUFFER_PX,
          CanvasDefaults.MIN_GRID_UNIT_WIDTH,
        ),
      );
    }

    if (
      isFixedWidth(widget.width.mode) &&
      newWidth.value <= widget.width.value
    ) {
      newWidget.width = Dimension.px(newWidth.value);
    }

    return newWidget;
  }

  return widget;
};
