import { Typography } from "antd";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import BranchAndDirectoryWrapper from "./BranchAndDirectoryWrapper";
import { GitOnboardingContext } from "./GitOnboardingContext";
import { BackButton, ButtonContainer, NextButton } from "./SelectConfigFile";
import TypeTag from "./TypeTag";
import { Repository } from "./utils";

interface ReviewAndConnectProps {
  repo: Repository | null;
  handleBack: () => void;
  handleNext: () => void;
  directory: string;
  selectedBranch: string;
  setDirectory: (value: any) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px; /* max height to show 5 rows */
  overflow-y: auto;
  margin-top: 10px;
  font-size: 12px;
  border: 1px solid #f3f4f6;
  border-radius: 2px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid #f3f4f6;
  }
`;

const StyledColumn1 = styled.div`
  display: flex;
  width: 132px;
  padding: 0px 8px 0px 14px;
  align-items: center;
  align-self: stretch;
`;

const StyledColumn2 = styled.div`
  display: flex;
  padding: 12px 18px;
  align-items: flex-start;
  flex: 1 0 0;
`;

const StyledSubtext = styled(Typography.Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.GREY_500};
`;

const ReviewAndConnect = ({
  repo,
  handleBack,
  handleNext,
  directory,
  setDirectory,
  selectedBranch,
}: ReviewAndConnectProps) => {
  const handleProceed = useCallback(() => {
    handleNext();
  }, [handleNext]);
  const onboardingCtx = useContext(GitOnboardingContext);

  return (
    <div style={{ marginTop: "12px" }}>
      <Typography.Text style={{ fontSize: "13px" }}>
        Review the setup below. If everything looks correct, click connect to
        start syncing.
      </Typography.Text>
      <div style={{ marginTop: "20px" }}>
        <BranchAndDirectoryWrapper
          repo={repo}
          showAlert={false}
          foundConfig={true}
          setFoundConfig={() => {
            return;
          }}
          directory={directory}
          setDirectory={setDirectory}
          selectedBranch={selectedBranch}
        />
      </div>
      <StyledContainer>
        <Typography.Text
          style={{ color: "var(--dark-700, #454D5F)", fontWeight: 500 }}
        >
          Applications, Workflows, and Scheduled Jobs
        </Typography.Text>
        <StyledSubtext>
          {onboardingCtx.appFlobsInConfig?.length === 0 ? (
            <>
              No tools have been added to this repository to sync. Learn{" "}
              <a
                href={DOCS_PAGE_URL(DocsPage.DEPLOY_GIT_SYNC)}
                target="_blank"
                rel="noreferrer"
              >
                how to add them.
              </a>
            </>
          ) : (
            <>
              The following Applications, Workflows, and Scheduled Jobs are
              currently in this repository. Changes will start syncing for these
              tools immediately.{" "}
              <a
                href={DOCS_PAGE_URL(DocsPage.DEPLOY_GIT_SYNC)}
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </>
          )}
        </StyledSubtext>

        {onboardingCtx.appFlobsInConfig?.length !== 0 && (
          <StyledTable>
            {onboardingCtx.appFlobsInConfig?.map(
              (appFlob: any, index: number) => (
                <StyledRow key={index}>
                  <StyledColumn1>
                    {" "}
                    <TypeTag type={appFlob.type} />
                  </StyledColumn1>
                  <StyledColumn2> {appFlob.name} </StyledColumn2>
                </StyledRow>
              ),
            )}
          </StyledTable>
        )}
      </StyledContainer>

      <ButtonContainer>
        <BackButton onClick={handleBack}>← Back</BackButton>
        <NextButton onClick={handleProceed}>Connect</NextButton>
      </ButtonContainer>
    </div>
  );
};

export default ReviewAndConnect;
