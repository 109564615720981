import { useCallback } from "react";
import { useStore } from "react-redux";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import { showWidgetPropertyPane } from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import {
  getIsPropertyPaneVisible,
  getOpenPropertyPanelItem,
} from "legacy/selectors/propertyPaneSelectors";
import { useAppDispatch } from "store/helpers";
import type { AppState } from "store/types";

export const useTogglePropertyPane = ({
  beforeCallback,
}: {
  beforeCallback?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();

  return useCallback(() => {
    beforeCallback?.();

    if (getIsPropertyPaneVisible(store.getState())) {
      dispatch({
        type: ReduxActionTypes.HIDE_PROPERTY_PANE,
      });
    } else {
      const propertyPaneItem = getOpenPropertyPanelItem(store.getState());

      if (propertyPaneItem) {
        dispatch(showItemPropertyPane(propertyPaneItem));
      } else {
        dispatch(showWidgetPropertyPane(PAGE_WIDGET_ID));
      }
    }
  }, [dispatch, beforeCallback, store]);
};
