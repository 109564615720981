import moment from "moment";
import { select } from "redux-saga/effects";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getLastEvaluationStartTime } from "legacy/selectors/evaluatorSelectors";

import { createSaga } from "../../../utils/saga";

import slice from "../slice";

function* markApiDoneEvaluatingInternal(): Generator<any, any, any> {
  const lastStartTime = yield select(getLastEvaluationStartTime);
  return lastStartTime;
}

export const markApiDoneEvaluatingSaga = createSaga(
  markApiDoneEvaluatingInternal,
  "markApiDoneEvaluating",
  {
    sliceName: "apis",
    // Taking this one because it doesn't have a large payload to repeat
    triggerOn: { type: ReduxActionTypes.TREE_WILL_UPDATE },
  },
);

slice.saga(markApiDoneEvaluatingSaga, {
  success(state, { payload }) {
    Object.entries(state.meta).forEach(([apiId, meta]) => {
      if (
        meta.waitingForEvaluationSince &&
        moment(meta.waitingForEvaluationSince).isBefore(moment(payload))
      ) {
        delete state.meta[apiId].waitingForEvaluationSince;
      }
    });
  },
});
