import { ApplicationScope } from "@superblocksteam/shared";
import { all, select } from "redux-saga/effects";
import {
  getMergedDataTreeKeys,
  getMergedDataTree,
  getDataTree,
  getNameIteratorValue,
} from "legacy/selectors/dataTreeSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { ROOT } from "store/utils/types";
import { PLUGIN_INTEGRATIONS_MAP } from "utils/integrations";
import { createSaga } from "../../../utils/saga";
import { selectControlFlowPluginInfo } from "../control-flow/control-flow-selectors";
import { selectV2ApiById, selectV2ApiMeta } from "../selectors";
import slice, { type Binding } from "../slice";
import { getV2ApiId, getV2ApiName } from "../utils/getApiIdAndName";
import { calculateBindings } from "./calculateBindings";
import type { ApiDtoWithPb } from "../slice";

interface PayloadElement {
  id: string;
  name: string;
  allBindings: Binding[];
  bindingsByBlock: Record<string, Binding[]>;
  nameIteratorValue: number;
}

type Payload = Array<PayloadElement>;

// Loads all api deps based on extractingBindings && getEntityNames keys changing
function* getV2ApiToComponentDepsInternal(
  {
    apiIdsToAnalyze,
  }: {
    // if `apiIdsToAnalyze` is undefined, then all APIs are analyzed
    apiIdsToAnalyze?: string[];
  },
  callId: number,
): Generator<any, Payload, any> {
  // TODO(API_SCOPE):
  const scope = ApplicationScope.PAGE;

  const apiMeta: ReturnType<typeof selectV2ApiMeta> =
    yield select(selectV2ApiMeta);
  const nameIteratorValue = yield select(getNameIteratorValue);
  const apiIds = (apiIdsToAnalyze ?? Object.keys(apiMeta)).filter((id) => {
    if (id === ROOT) return false;
    const meta = apiMeta[id];
    const keysChanged = meta.nameIteratorValue !== nameIteratorValue;
    const isFlaggedForExtraction = meta?.extractingBindings === callId;
    return keysChanged || isFlaggedForExtraction;
  });

  if (!apiIds.length) {
    return [];
  }
  const apis: Array<ReturnType<typeof selectV2ApiById>> = yield all(
    apiIds.map((id) => select(selectV2ApiById, id)),
  );
  if (!apis.length) {
    return [];
  }
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXTRACT_BINDINGS_FROM_API,
  );

  const plugins = PLUGIN_INTEGRATIONS_MAP;
  const pluginInfo = yield select(selectControlFlowPluginInfo);

  const userAccessibleDataTree: ReturnType<typeof getMergedDataTree> =
    yield select(getMergedDataTree, scope);
  const userAccessibleDataTreeKeys: ReturnType<typeof getMergedDataTreeKeys> =
    yield select(getMergedDataTreeKeys, scope);
  const dataTree: ReturnType<typeof getDataTree> = yield select(getDataTree);

  const bindings: Payload = yield all(
    (apis.filter((api) => api?.apiPb?.blocks) as ApiDtoWithPb[]).map(
      function* (api) {
        const { bindingsByBlock, allBindings } = yield calculateBindings({
          api,
          scope,
          plugins,
          pluginInfo,
          userAccessibleDataTreeKeys,
          userAccessibleDataTree,
          dataTree,
        });
        return {
          id: getV2ApiId(api),
          name: getV2ApiName(api),
          allBindings,
          bindingsByBlock,
          nameIteratorValue,
        } satisfies PayloadElement;
      },
    ),
  );
  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.EXTRACT_BINDINGS_FROM_API,
  );
  return bindings;
}

export const getV2ApiToComponentDepsSaga = createSaga(
  getV2ApiToComponentDepsInternal,
  "getV2ApiToComponentDeps",
  {
    sliceName: slice.name,
    autoGenerateUniqueKey: true,
  },
);

slice.saga(getV2ApiToComponentDepsSaga, {
  start(state, { payload, callId }) {
    (payload.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      const meta = state.meta[id];
      if (id !== ROOT && meta?.needsBindingExtraction) {
        state.meta[id].extractingBindings = callId;
        state.meta[id].needsBindingExtraction = false;
      }
    });
  },
  success(state, { payload, meta }) {
    payload.forEach(
      ({ id, bindingsByBlock, allBindings, nameIteratorValue }) => {
        state.meta[id] = state.meta[id] ?? {};
        state.meta[id].extractingBindings = undefined;
        state.meta[id].extractedBindings = allBindings;
        state.meta[id].extractedBindingsByBlock = bindingsByBlock;
        state.meta[id].nameIteratorValue = nameIteratorValue;
      },
    );
  },
  error(state, { meta }) {
    (meta.args.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      state.meta[id].extractedBindingsByBlock = {};
      state.meta[id].needsBindingExtraction = false;
      state.meta[id].extractedBindings = [];
    });
  },
});
