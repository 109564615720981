import {
  PrimaryKey,
  Table,
  Schema,
  DropdownOption,
} from "@superblocksteam/shared";
import { Input, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as CrossIcon } from "assets/icons/common/cross.svg";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { Button } from "components/ui/Button";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { FullWidthSpace } from "components/ui/Space";
import { useAppSelector } from "store/helpers";
import { selectDatasourceMetaById } from "store/slices/datasources";
import logger from "utils/logger";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext, FormContextType } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormText } from "../FormText";

interface DynamicFormKeyMappingProps {
  datasourceId: string;
}

type Props = DynamicFormKeyMappingProps & DynamicFormItemProps;

type MappedColumns = Array<{ json: string; sql: string }>;

const StyledFullWidthSpace = styled(FullWidthSpace)`
  & > * {
    flex: 1;
    align-items: center;
  }
`;

const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 32px;
  gap: 12px;
`;

const Styled2Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 12px;
`;

const StyledInput = styled(Input)`
  &[disabled] {
    color: ${(props) => props.theme.colors.GREY_700};
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  height: 16px;
  margin-top: 3px;
  color: ${({ theme }) => theme.colors.GREY_500};
`;

interface MappedRowProps {
  mapping: { json: string; sql: string };
  index: number;
  isPrimary?: boolean;
  isDisabled?: boolean;
  context: FormContextType;
  currentMappings: MappedColumns;
}

const MappedRow = ({
  mapping,
  index,
  isPrimary = false,
  isDisabled = false,
  context,
  currentMappings,
}: MappedRowProps) => {
  const theme = useTheme();
  const [localJson, setLocalJson] = useState(mapping.json);

  useEffect(() => {
    setLocalJson(mapping.json);
  }, [mapping.json]);

  const debouncedUpdate = useMemo(
    () =>
      _.debounce((value: string) => {
        context.onChange(`mappedColumns[${index}].json`, value, {
          isNested: true,
        });
      }, 300),
    [context, index],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setLocalJson(newValue);
      debouncedUpdate(newValue);
    },
    [debouncedUpdate],
  );

  return (
    <StyledForm>
      <StyledInput
        value={`${mapping.sql}${isPrimary ? " (Primary key)" : ""}`}
        disabled={false}
        style={{ fontSize: theme.text.sizes.default }}
        bordered={false}
        readOnly={true}
      />
      <StyledInput
        placeholder={"JSON Key"}
        value={localJson}
        disabled={context.getValue("mappingMode") !== "manual"}
        style={{ fontSize: theme.text.sizes.default }}
        data-test={`key-mapping-${mapping.sql}`}
        onChange={handleChange}
      />
      <Tooltip
        title={
          isDisabled
            ? "This column is required to be mapped based on your matching settings."
            : "Removing the mapping will remove it from data"
        }
      >
        <Button
          icon={
            <IconWrapper>
              <CrossIcon />
            </IconWrapper>
          }
          aria-label="Remove row mapping"
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled) {
              context.onChange(
                `mappedColumns`,
                currentMappings.filter((m, i) => i !== index),
              );
            }
          }}
        />
      </Tooltip>
    </StyledForm>
  );
};

export const DynamicFormKeyMapping = ({
  datasourceId,
  path,
  label,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTable, setActiveTable] = useState<Table | null>(null);
  const [activeSchema, setActiveSchema] = useState<Schema | null>(null);
  const currentMappings = context.getValue("mappedColumns") as
    | MappedColumns
    | undefined;

  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, datasourceId),
  );

  const tables = useMemo(() => {
    const result: Table[] = [];
    try {
      datasourceMeta?.metadata?.dbSchema?.tables?.forEach((table: Table) => {
        result.push(table);
      });
      if (result.length > 0) {
        setIsLoading(false);
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [datasourceMeta?.metadata?.dbSchema?.tables]);

  const schemas = useMemo(() => {
    const result: Schema[] = [];
    try {
      datasourceMeta?.metadata?.dbSchema?.schemas?.forEach((schema: Schema) => {
        result.push(schema);
      });
      if (result.length > 0) {
        setIsLoading(false);
      }
      return result;
    } catch (e) {
      logger.error(`Failed to load options: ${e}`);
    }
    return result;
  }, [datasourceMeta?.metadata?.dbSchema?.schemas]);

  useEffect(() => {
    const unsubscribe = context.subscribe("schema", (dependencyValue) => {
      if (dependencyValue && schemas) {
        const activeSchema = schemas.find((t) => t.name === dependencyValue);
        setActiveSchema(activeSchema ?? null);
      }
    });
    return () => unsubscribe();
  }, [context, schemas]);

  useEffect(() => {
    const unsubscribe = context.subscribe("table", (dependencyValue) => {
      if (dependencyValue && tables) {
        const activeTable = tables.find((t) => {
          if (!activeSchema) {
            return t.name === dependencyValue;
          }
          return t.name === dependencyValue && t.schema === activeSchema.name;
        });
        setActiveTable(activeTable ?? null);
      }
    });
    return () => unsubscribe();
  }, [context, activeSchema, tables]);

  const primaryKeys = useMemo(() => {
    const potentialKey = activeTable?.keys?.find(
      (k) => k.type === "primary key",
    ) as PrimaryKey | undefined;
    return potentialKey?.columns;
  }, [activeTable]);

  const unmappedColumns = useMemo(() => {
    const mappedColumns = (currentMappings ?? [])?.map((m) => m.sql);
    return (activeTable?.columns ?? [])
      .filter(({ name }) => !mappedColumns.includes(name))
      .map((c) => c.name);
  }, [currentMappings, activeTable]);

  const theme = useTheme();

  const onChangeMode = useCallback(
    (option: DropdownOption) => {
      const val = option.value;
      context.onChange("mappingMode", val); // when user updates the value
      if (val === "manual") {
        context.onChange(
          "mappedColumns",
          (activeTable?.columns ?? []).map((c) => ({
            json: c.name,
            sql: c.name,
          })),
        );
      } else {
        context.onChange("mappedColumns", []);
      }
    },
    [context, activeTable],
  );

  const options = useMemo(
    () => [
      {
        displayName:
          "Automatically map all JSON keys to database table columns",
        subText: "Use when all JSON keys exactly match the SQL columns",
        value: "auto",
        key: "auto",
      },
      {
        displayName: "Manually map JSON keys to table columns",
        subText:
          "Use when JSON keys do not exactly match SQL columns, for example when using aliases or JOINs.",
        value: "manual",
        key: "manual",
      },
    ],
    [],
  );

  const modeSelector = (
    <>
      <label>
        <FormLabel hidden={otherProps.hidden}>{label}</FormLabel>
        <RecommendedSingleDropdown
          options={options}
          data-test={"key-mapping-mode-selector"}
          onChange={onChangeMode}
          value={(context.getValue("mappingMode") ?? "auto") as string}
        />
      </label>
    </>
  );

  if (context.getValue("mappingMode") !== "manual") {
    return (
      <StyledFullWidthSpace direction="vertical">
        {modeSelector}
      </StyledFullWidthSpace>
    );
  }

  return (
    <StyledFullWidthSpace direction="vertical">
      {modeSelector}

      <FormText>
        Only mapped columns will be updated.
        {context.getValue("useAdvancedMatching") === "advanced"
          ? " Because you are using manual row matching, you must also configure mappings for any column you are matching against."
          : ""}
      </FormText>

      <StyledForm>
        <FormLabel>SQL Column</FormLabel>
        <FormLabel>JSON Key</FormLabel>
      </StyledForm>

      <FormText>
        {isLoading ? (
          <span>
            <Spin size="small" /> Loading metadata...
          </span>
        ) : (
          ""
        )}
      </FormText>

      {!currentMappings?.length ? (
        <FormText>You must provide at least one mapping column.</FormText>
      ) : null}

      {currentMappings?.map((c, index) => {
        const isPrimary = primaryKeys?.includes(c.sql);
        const isDisabled = Boolean(
          isPrimary && context.getValue("useAdvancedMatching") !== "advanced",
        );
        return (
          <MappedRow
            key={index}
            mapping={c}
            index={index}
            isPrimary={isPrimary}
            isDisabled={isDisabled}
            context={context}
            currentMappings={currentMappings}
          />
        );
      })}

      {unmappedColumns.map((name, index) => {
        return (
          <Styled2Col key={index}>
            <StyledInput
              value={name}
              disabled={true}
              style={{ fontSize: theme.text.sizes.default }}
              bordered={true}
              readOnly={true}
            />
            <Button
              icon={
                <IconWrapper>
                  <PlusIcon />
                </IconWrapper>
              }
              aria-label="Toggle row mapping"
              onClick={(val) => {
                context.onChange(
                  `mappedColumns`,
                  (currentMappings ?? []).concat({
                    json: name,
                    sql: name,
                  }),
                );
              }}
            />
          </Styled2Col>
        );
      })}
    </StyledFullWidthSpace>
  );
};
