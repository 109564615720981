export enum StatusCodes {
  TOO_MANY_REQUESTS = 429,
}

export const is4xx = function (statusCode: number) {
  return statusCode >= 400 && statusCode <= 499;
};

export const is5xx = function (statusCode: number) {
  return statusCode >= 500 && statusCode <= 599;
};
