import { PageDSL8 } from "@superblocksteam/shared";
import { select } from "redux-saga/effects";
import { selectAllV2Apis } from "store/slices/apisV2";
import { selectFlags } from "store/slices/featureFlags";
import logger from "utils/logger";
import { AuxiliaryData, migratePage } from "../../appMigrations/MigratePage";

export function* migrateServerDSL(
  serverDSL: PageDSL8,
): Generator<any, PageDSL8, any> {
  const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
  const extraData: AuxiliaryData = {
    apis: (yield select(selectAllV2Apis)) as ReturnType<typeof selectAllV2Apis>,
  };

  if (!extraData.apis) {
    logger.error("Extra migration data missing, apis");
  }

  const migratedDSL = migratePage({
    serverDSL,
    flags,
    extraData,
  });

  return migratedDSL;
}
