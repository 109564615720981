import { select } from "redux-saga/effects";
import {
  WidgetCardProps,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { generateReactKey } from "legacy/utils/generators";
import { customComponentType } from "legacy/widgets/CustomComponentTypeUtil";
import { getApplicationSettings } from "store/slices/application/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { createSaga } from "store/utils/saga";
import { ROOT } from "store/utils/types";
import { WidgetCardsCategorized, baseWidgets } from "../baseWidgets";
import slice from "../slice";

interface GetAvailableWidgetsArgs {
  applicationId: string;
}

function* getAvailableWidgetsInternal({
  applicationId,
}: GetAvailableWidgetsArgs) {
  const appSettings: ReturnType<typeof getApplicationSettings> = yield select(
    getApplicationSettings,
  ) ?? {};
  const customWidgets: WidgetCardProps[] = Object.values(
    appSettings?.registeredComponents ?? {},
  ).map((value: any) => ({
    type: customComponentType(value.id) as WidgetType,
    widgetCardName: value.displayName,
    key: generateReactKey(),
  }));

  const isMenuWidgetEnabled: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_MENU_WIDGET,
  );

  const isLinkWidgetEnabled: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_LINK_WIDGET,
  );

  const optionalCommonWidgets: [WidgetType, boolean][] = [
    [WidgetTypes.MENU_WIDGET, isMenuWidgetEnabled],
    [WidgetTypes.LINK_WIDGET, isLinkWidgetEnabled],
  ];
  const commonWidgetsToExclude = optionalCommonWidgets.reduce(
    (acc: WidgetType[], [type, isEnabled]) =>
      !isEnabled ? [...acc, type] : acc,
    [],
  );

  const widgets: WidgetCardsCategorized = baseWidgets.map(
    (categorizedWidgetCards) => {
      if (categorizedWidgetCards.widgetCategory === "CUSTOM") {
        return {
          ...categorizedWidgetCards,
          widgetCards: customWidgets,
        };
      }

      if (
        !isMenuWidgetEnabled &&
        categorizedWidgetCards.widgetCategory === "COMMON"
      ) {
        return {
          ...categorizedWidgetCards,
          widgetCards: categorizedWidgetCards.widgetCards.filter(
            (card) => !commonWidgetsToExclude.includes(card.type),
          ),
        };
      }

      return categorizedWidgetCards;
    },
  );

  return { widgets, applicationId };
}

export const getAvailableWidgetsSaga = createSaga(
  getAvailableWidgetsInternal,
  "getAvailableWidgets",
  {
    sliceName: "availableWidgets",
  },
);

slice.saga(getAvailableWidgetsSaga, {
  start(state) {
    state.loading[ROOT] = true;
    delete state.errors[ROOT];
  },
  success(state, { payload: { widgets } }) {
    state.entities[ROOT] = { widgets };
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
});
