import {
  SUPPORTED_FORMATTER_TYPE,
  SyntaxType,
} from "code-formatting/constants";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import {
  CodeFormattingResponse,
  ErrorResponse,
  FormatCodeRequest,
  FormatWorkerActions,
} from "./types";

type CallbackFn = (
  value: CodeFormattingResponse | PromiseLike<CodeFormattingResponse>,
) => void;

// We import workers in different ways depending if they are wasm or javascript based.
// If imported on the same worker we get an `importScripts` issue:
// Uncaught DOMException: Failed to execute 'importScripts'
const formatters: {
  [KEY in SUPPORTED_FORMATTER_TYPE]: "wasm" | "js";
} = {
  [EditorModes.SQL_WITH_BINDING]: "js",
  [EditorModes.JAVASCRIPT]: "js",
  [EditorModes.PYTHON]: "wasm",
};

export default class FormatWorkerService {
  private wasmWorker: Worker | undefined;
  private jsWorker: Worker | undefined;

  private msgId = 0;
  private pending: { [x: number]: CallbackFn } = {};

  async ensureLoaded(mode: SUPPORTED_FORMATTER_TYPE) {
    const worker = this.getWorker(mode);
    if (worker) {
      return;
    }

    if (formatters[mode] === "js") {
      const { default: Worker } = await import(
        /* webpackChunkName: "js-format-worker" */
        "worker-loader?inline=no-fallback&filename='js-format'!./js-format.worker"
      );

      this.jsWorker = new Worker();
      this.jsWorker.onmessage = this.onMessage.bind(this);
      this.jsWorker.onerror = this.onError.bind(this);
    } else {
      /**
       * - Workers need to load from same origin domain, not from our CDN.
       * - worker-loader usually handles that for us.
       * - worker-loader does not support WASM.
       * - Webpack 5 now supports loading workers without using worker-loader.
       * - But the `webpackConfig.output.workerPublicPath` is not well documented & does not work for us.
       *
       * This is a hack:
       */

      const originalWebpackPublicPath = __webpack_public_path__;
      __webpack_public_path__ = "/";
      const formattingWorker = new Worker(
        new URL("./wasm-format.worker.ts", import.meta.url),
      );
      __webpack_public_path__ = originalWebpackPublicPath;

      this.wasmWorker = formattingWorker;
      this.wasmWorker.onmessage = this.onMessage.bind(this);
      this.wasmWorker.onerror = this.onError.bind(this);
    }
  }

  getWorker(mode: SUPPORTED_FORMATTER_TYPE) {
    return formatters[mode] === "js" ? this.jsWorker : this.wasmWorker;
  }

  public terminate() {
    this.jsWorker?.terminate();
    this.wasmWorker?.terminate();
  }

  // Receive response from worker
  private onMessage(event: MessageEvent<CodeFormattingResponse>) {
    const data = event.data;
    if (this.pending[data.id] !== undefined) {
      this.pending[data.id](data);
      delete this.pending[data.id];
    }
  }

  // Receive response from worker
  private onError(error: ErrorEvent) {
    for (const id in this.pending) {
      const errorResponse: ErrorResponse = {
        type: "error",
        id: id as unknown as number,
        error: error.message,
      };
      console.error(errorResponse);
      this.pending[id](errorResponse);
    }
    this.pending = {};
  }

  // Send message to worker
  private async send(
    data: Omit<FormatCodeRequest, "id">,
    callback: CallbackFn,
  ) {
    const id = this.msgId++;
    this.pending[id] = callback;
    await this.ensureLoaded(data.mode);
    this.getWorker(data.mode)?.postMessage({ ...data, id });
  }

  async formatCode(
    codeToFormat: string,
    mode: SUPPORTED_FORMATTER_TYPE,
    options?: { syntax?: SyntaxType },
  ): Promise<CodeFormattingResponse> {
    return new Promise((resolve) => {
      this.send(
        {
          type: FormatWorkerActions.FORMAT,
          mode,
          codeToFormat,
          options,
        },
        resolve,
      );
    });
  }
}
