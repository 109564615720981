import {
  ApplicationSettings,
  Organization,
  ViewMode,
  Profile,
} from "@superblocksteam/shared";
import { put, select } from "redux-saga/effects";

import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

import {
  getAppViewMode,
  getSelectedProfileId,
} from "legacy/selectors/applicationSelectors";

import { getQueryParams } from "legacy/utils/Utils";

import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";

import { getApplicationSettings } from "store/slices/application/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";

// the profile object in redux contains extra fields from db that we do not want to show
const filterProfile = (profile: Profile | undefined) => {
  if (profile === undefined) return undefined;
  return {
    id: profile.id,
    key: profile.key,
    displayName: profile.displayName,
    type: profile.type,
  };
};

export function* updateProfiles(selectedProfileId?: string) {
  const settings: ApplicationSettings = yield select(getApplicationSettings);
  const mode: ViewMode = yield select(getAppViewMode);
  const orgData: Organization = yield select(selectOnlyOrganization);

  const availableIds = settings?.profiles?.[mode]?.availableProfileIds;
  const defaultProfileId = settings?.profiles?.[mode]?.defaultProfileId;
  const selectedId: string = yield select(getSelectedProfileId);

  const available = availableIds
    ?.map((id) =>
      filterProfile(orgData.profiles?.find((profile) => profile.id === id)),
    )
    .filter(Boolean);

  let selected = filterProfile(
    orgData.profiles?.find(
      (profile) => profile.id === (selectedProfileId ?? selectedId),
    ),
  );

  const enableProfilesWithEnv: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES_WITH_ENV,
  );

  if (enableProfilesWithEnv) {
    // once profile is enabled, some org may still specify environment in app url, and expect to use Global.URL.queryParams.environment
    // we just set profile base on the query param
    const queryParams = getQueryParams();
    if (queryParams.environment) {
      const profileFromParam = filterProfile(
        orgData.profiles?.find(
          (profile) => profile.key === queryParams.environment,
        ),
      );
      if (profileFromParam) {
        selected = profileFromParam;
      }
    }
  }

  try {
    const sessionProfileValue = sessionStorage.getItem(
      SessionStorageKey.CONTROL_FLOW_PROFILE_ID_OVERRIDE,
    );
    // only persist the app profile override once, since if the user goes back to the page we want it to use default
    sessionStorage.removeItem(
      SessionStorageKey.CONTROL_FLOW_PROFILE_ID_OVERRIDE,
    );
    const parsedId =
      typeof sessionProfileValue === "string"
        ? JSON.parse(sessionProfileValue)
        : null;
    const profileFromParam = filterProfile(
      orgData.profiles?.find((profile) => profile.id === parsedId),
    );
    if (profileFromParam) {
      selected = profileFromParam;
    }
  } catch (e) {
    console.error("Unable to parse profile ID override", e);
  }

  const defaultProfile = filterProfile(
    orgData.profiles?.find((profile) => profile.id === defaultProfileId),
  );

  const profiles = {
    available,
    selected,
    default: defaultProfile,
  };

  yield put({
    type: ReduxActionTypes.UPDATE_PROFILES,
    payload: profiles,
  });
}
