import CodeMirror from "codemirror";
import { EditorModes } from "../EditorConfig";
import { MarkerType } from "../markHelpers";
import { BASE_MODE_ERROR } from "../modes";

interface TokenData {
  token: CodeMirror.Token;
  position: CodeMirror.Position;
}

export interface Marker {
  type: MarkerType;
  content: string;
  methodName: string | undefined;
  entityName: string | undefined;
}

function isPropertyToken(value: string) {
  return /^([0-9a-zA-Z_$]+|\.)$/.test(value);
}

export function getTokenData(instance: CodeMirror.Editor, e: MouseEvent) {
  const node = (e.target || e.srcElement) as HTMLElement;
  const text = node.innerText || node.textContent;
  const rect = node.getBoundingClientRect();

  const position = instance.coordsChar({
    left: rect.left + rect.width / 2,
    top: rect.top + rect.height / 2,
  });

  const currentMode = instance.getModeAt(position).name;

  if (
    currentMode !== EditorModes.JAVASCRIPT &&
    // This is the embedded mode inside text
    currentMode !== "javascript" &&
    currentMode !== EditorModes.PYTHON
  ) {
    return;
  }

  const token = instance.getTokenAt(position, true);
  const line = instance.getLine(position.line);

  if (
    token &&
    (!text ||
      token.string === text ||
      line.substring(token.start, token.end + 1) === text)
  ) {
    return {
      token,
      position,
    } as TokenData;
  }
}

export function getTokens(instance: CodeMirror.Editor, tokenData: TokenData) {
  if (!isPropertyToken(tokenData.token.string)) return [];

  const tokens = [tokenData.token];

  let previous = instance.getTokenAt({
    line: tokenData.position.line,
    ch: tokenData.token.start,
  });

  while (previous) {
    if (!isPropertyToken(previous.string)) break;

    tokens.push(previous);

    previous = instance.getTokenAt({
      line: tokenData.position.line,
      ch: previous.start,
    });
  }

  return tokens.map((token) => token.string).reverse();
}

export function getMarkers(instance: CodeMirror.Editor, tokenData: TokenData) {
  const markers = instance.findMarksAt(tokenData.position);

  return markers
    .map((marker) => {
      if (!marker.attributes) {
        return null;
      }
      return {
        type: marker.attributes["data-type"],
        content: marker.attributes["data-content"],
        methodName: marker.attributes["data-method-name"] || undefined,
        entityName: marker.attributes["data-entity-name"] || undefined,
      };
    })
    .filter((value): value is Marker => {
      if (value === null) {
        return false;
      }

      return Boolean(value.type && value.content);
    });
}

export function isHoveringBindingError(
  instance: CodeMirror.Editor,
  e: MouseEvent,
): boolean {
  // Binding errors are only supported in JavaScript for now
  const currentMode = instance.getMode().name;
  if (currentMode !== EditorModes.JAVASCRIPT) {
    return false;
  }

  const node = e.target as HTMLElement;
  const rect = node.getBoundingClientRect();
  const position = instance.coordsChar({
    left: rect.left + rect.width / 2,
    top: rect.top + rect.height / 2,
  });

  const token = instance.getTokenAt(position, true);

  const isNodeBindingError =
    node.classList.contains(`cm-${BASE_MODE_ERROR}-binding`) ||
    node.classList.contains(`cm-${BASE_MODE_ERROR}-open`) ||
    node.classList.contains(`cm-${BASE_MODE_ERROR}-close`);

  return token && isNodeBindingError;
}
