import { CreateUIBlock, CreateUIBlockCategory } from "@superblocksteam/schemas";
import { UIBlocksStatus } from "@superblocksteam/shared";
import { UIBlockCategory, UIBlockConfig } from "../uiBlocks/types";
import { reduxApiSlice } from "./index";

type BackendUIBlockConfig = CreateUIBlock & { id: string };
type BackendUIBlockCategory = Omit<CreateUIBlockCategory, "blocks"> & {
  id: string;
} & {
  uiBlocks: BackendUIBlockConfig[];
};

const transformBackendBlock = (block: BackendUIBlockConfig): UIBlockConfig => {
  const { imageUri, status, ...rest } = block;

  const newBlock: UIBlockConfig = {
    ...rest,
    image: imageUri,
    status: status ?? UIBlocksStatus.PUBLIC,
  };
  return newBlock;
};

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: move to `query` and `transformResponse` once we have a proper backend endpoint
    listDemoUiBlocks: builder.query<
      UIBlockConfig[] | undefined,
      { showPending?: boolean }
    >({
      queryFn: async (options: { showPending?: boolean }) => {
        try {
          const query = options.showPending
            ? "?status=PENDING&status=PUBLIC"
            : "?status=PUBLIC";
          const response = await fetch(
            `https://uiblocks.superblocks.dev/uiblocks${query}`,
          );

          const data = await response.json();
          return { data: data.blocks };
        } catch (error) {
          return { error };
        }
      },
    }),
    getDemoUiBlocksCategories: builder.query<
      UIBlockCategory[],
      { showPending?: boolean }
    >({
      queryFn: async (options: { showPending?: boolean }) => {
        try {
          const query = options.showPending
            ? "?status=PENDING&status=PUBLIC"
            : "?status=PUBLIC";
          const response = await fetch(
            `https://uiblocks.superblocks.dev/categories${query}`,
          );

          const data = await response.json();
          return { data: data.categories };
        } catch (error) {
          return { error };
        }
      },
    }),
    listUiBlocks: builder.query<UIBlockConfig[], { showPending?: boolean }>({
      query: (options: { showPending?: boolean }) => {
        const queryString = new URLSearchParams();
        if (options.showPending) {
          queryString.append("status", "PENDING");
          queryString.append("status", "PUBLIC");
        } else {
          queryString.append("status", "PUBLIC");
        }
        return {
          url: `v1/uiblocks/blocks?${queryString.toString()}`,
          method: "GET",
        };
      },
      transformResponse: (response: { data: BackendUIBlockConfig[] }) => {
        return response.data.map((block) => {
          return transformBackendBlock(block);
        });
      },
    }),
    listUiBlockCategories: builder.query<
      UIBlockCategory[],
      { showPending?: boolean }
    >({
      query: (options: { showPending?: boolean }) => {
        const queryString = new URLSearchParams();
        if (options.showPending) {
          queryString.append("status", "PENDING");
          queryString.append("status", "PUBLIC");
        } else {
          queryString.append("status", "PUBLIC");
        }
        return {
          url: `v1/uiblocks/categories?${queryString.toString()}`,
          method: "GET",
        };
      },
      transformResponse: (response: { data: BackendUIBlockCategory[] }) => {
        return (response.data ?? []).map((category) => {
          const { uiBlocks, imageUri, ...rest } = category;
          return {
            ...rest,
            image: imageUri,
            blocks: uiBlocks.map((block) => {
              return {
                ...transformBackendBlock(block),
                categoryId: category.id,
              };
            }),
          };
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useListDemoUiBlocksQuery,
  useGetDemoUiBlocksCategoriesQuery,
  // useListUiBlocksQuery, // not actually needed right now
  useListUiBlockCategoriesQuery,
} = extendedApi;
