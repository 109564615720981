import type { AppState } from "store/types";

export const getApplicationSidebarKey = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar.selectedKey;

export const getWorkflowSidebarKey = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.workflow.sidebar.selectedKey;

export const getSidebarFocusedItems = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar.focusedItems;

export const getIsSidebarFocusedItemsTracked = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar
    .trackFocusedItems;

export const getSidebarExpandedItems = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar
    .expandedItemNames;

export const getSidebarExpandedStateItems = (state: AppState) => {
  return state.legacy.ui.editorPreferences.general.application.sidebar
    .expandedStateItems;
};
