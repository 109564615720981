import { PostApiBranchCreateRequestBody } from "@superblocksteam/schemas";
import {
  GetApiBranchesResponseBody,
  GetApplicationBranchesResponseBody,
  PostApplicationBranchCreateResponseBody,
} from "@superblocksteam/shared";
import {
  callServer,
  ErrorHandlingOptions,
  HttpMethod,
} from "store/utils/client";
import { getUrlWithBranch } from "utils/urlWithBranch";

export function fetchAppBranches(
  entityId: string,
): Promise<GetApplicationBranchesResponseBody> {
  return callServer<GetApplicationBranchesResponseBody>({
    method: HttpMethod.Get,
    url: `v2/applications/${entityId}/branches`,
  });
}

export function fetchApiBranches(
  entityId: string,
): Promise<GetApiBranchesResponseBody> {
  return callServer<GetApiBranchesResponseBody>({
    method: HttpMethod.Get,
    url: `v3/apis/${entityId}/branches`,
  });
}

export const createAppBranch = ({
  applicationId,
  name,
  baseBranchName,
  errorHandlingOptions,
}: {
  applicationId: string;
  baseBranchName: string;
  name: string;
  errorHandlingOptions?: ErrorHandlingOptions;
}) => {
  return callServer<PostApplicationBranchCreateResponseBody>(
    {
      method: HttpMethod.Post,
      url: `v2/applications/${applicationId}/branches`,
      body: {
        name,
        baseBranchName,
      },
    },
    errorHandlingOptions,
  );
};

export const createApiBranch = ({
  apiId,
  name,
  baseBranchName,
  errorHandlingOptions,
}: {
  apiId: string;
  name: string;
  baseBranchName: string;
  errorHandlingOptions?: ErrorHandlingOptions;
}) => {
  return callServer<PostApiBranchCreateRequestBody>(
    {
      method: HttpMethod.Post,
      url: `v3/apis/${apiId}/branches`,
      body: {
        name: name,
        baseBranchName,
      },
    },
    errorHandlingOptions,
  );
};

export const deleteBranch = ({
  repositoryId,
  name,
  errorHandlingOptions,
}: {
  repositoryId: string;
  name: string;
  errorHandlingOptions?: ErrorHandlingOptions;
}) => {
  return callServer<any>(
    {
      method: HttpMethod.Delete,
      url: getUrlWithBranch(`v1/repositories/${repositoryId}`, name),
    },
    errorHandlingOptions,
  );
};
