import { doesEntityHaveAnyStepDefinitions } from "analysis/entityUtils";
import { ReferenceEdgeType, UsageEdgeType } from "analysis/types";
import { isTimer } from "legacy/workers/evaluationUtils";
import { UsageContext } from "./UsageContext";
import { EdgeInfo } from "./types";

/**
 * Timers are considered used if any of the following are met:
 * - They are referenced by any used entity in a binding
 *    - the only value that can be referenced on a timer is "isActive" - this is unlikely to actually be useful but we cannot rule it out
 * - They are set to start at page load, or a used entity has an event edge to the timer (and it has at least 1 step)
 *   AKA it is either on, or could be turned on. This means a timer that does nothing could be considered used, as long as it has any handlers
 */
export const calculateTimerUsageEdges = (
  nodeId: string,
  context: UsageContext,
): Array<EdgeInfo> => {
  const referenceGraph = context.referenceGraph;
  const timerEntity = referenceGraph.node(nodeId);
  if (!timerEntity || !isTimer(timerEntity)) {
    throw new Error(`Expected entity to be timer`);
  }

  const usageEdges: EdgeInfo[] = [];

  const timerStartsOnPageLoad = timerEntity.startOnPageLoad;
  const timerPotentiallyDoesSomething =
    doesEntityHaveAnyStepDefinitions(timerEntity);

  if (timerStartsOnPageLoad && timerPotentiallyDoesSomething) {
    // self reference
    usageEdges.push({
      usageSourceNodeId: nodeId,
      edgeType: UsageEdgeType.ACTIVATION,
    });
  }

  const usedPredecessors = context.getUsedRefPredecessors(nodeId, true);
  for (const predecessorId of usedPredecessors) {
    const hasBindingEdge = referenceGraph.hasEdge(
      predecessorId,
      nodeId,
      ReferenceEdgeType.BINDING,
    );
    if (hasBindingEdge) {
      usageEdges.push({
        usageSourceNodeId: predecessorId,
        edgeType: UsageEdgeType.DEPENDENCY,
      });
    }

    // if timer doesn't start on page load, we must check if other entities start us
    if (!timerStartsOnPageLoad) {
      const hasEventEdge = referenceGraph.hasEdge(
        predecessorId,
        nodeId,
        ReferenceEdgeType.EVENT,
      );
      if (hasEventEdge && timerPotentiallyDoesSomething) {
        usageEdges.push({
          usageSourceNodeId: predecessorId,
          edgeType: UsageEdgeType.ACTIVATION,
        });
      }
    }
  }

  return usageEdges;
};
