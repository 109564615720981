import {
  DefaultGroupName,
  DropdownOption,
  PermissionEntityType,
  RbacRole,
  ShareEntryDto,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Cell } from "react-table";
import { PrimaryButton } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import { SingleFilter } from "components/ui/Filter";
import RecommendedTable, {
  DEFAULT_TABLE_PAGE_SIZE,
  RecColumn,
  TagStyle,
} from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { useDebounce, usePreventNavigation } from "hooks/ui";
import {
  addShareEntriesForResourceId,
  updateShareEntryForResourceId,
} from "pages/Permissions/client";
import {
  entityCompareFn,
  getPermissionedEntitiesType,
  getTypeLabel,
} from "pages/Permissions/constants";
import { selectOnlyOrganization } from "store/slices/organizations";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { getIntegrationsPermissionsForGroupId } from "./client";
import { getEntitiesForGroupIdV2 } from "./client";
import {
  DirtyRecord,
  GroupEntityToRender,
  convertToGroupEntityToRender,
} from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<GroupEntityToRender>;

type TypeValue = "application" | "workflow" | "scheduled job" | "all";

const allTypeOptions: {
  value: TypeValue | "all";
  displayName: string;
  key: TypeValue;
}[] = [
  { value: "all", displayName: "All", key: "all" },
  { value: "application", displayName: "Application", key: "application" },
  { value: "workflow", displayName: "Workflow", key: "workflow" },
  {
    value: "scheduled job",
    displayName: "Scheduled Job",
    key: "scheduled job",
  },
];

const renderType = ({ value }: { value: PermissionEntityType }) => {
  let text = "Unknown";
  let style: React.CSSProperties = {
    color: colors.GREY_500,
    backgroundColor: `${colors.GREY_500}14`,
    borderColor: `${colors.GREY_500}2E`,
  };

  switch (value) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      text = "Application";
      style = {
        color: colors.ACCENT_BLUE_500,
        backgroundColor: colors.ACCENT_BLUE_500_25,
        borderColor: colors.ACCENT_BLUE_500_18,
      };

      break;
    case PermissionEntityType.SCHEDULED_JOB:
      text = "Scheduled Job";
      style = {
        color: colors.ACCENT_PURPLE,
        backgroundColor: `${colors.ACCENT_PURPLE}14`,
        borderColor: `${colors.ACCENT_PURPLE}2E`,
      };
      break;
    case PermissionEntityType.WORKFLOW:
      text = "Workflow";
      style = {
        color: colors.ACCENT_GREEN,
        backgroundColor: `${colors.ACCENT_GREEN}14`,
        borderColor: `${colors.ACCENT_GREEN}2E`,
      };
  }

  return (
    <div className={TagStyle} style={style}>
      {text}
    </div>
  );
};

const EntityList = ({
  groupId,
  groupName,
  isIntegration,
}: {
  groupId: string;
  groupName: string;
  isIntegration: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [entities, setEntities] = useState<GroupEntityToRender[]>([]);
  const [dirtyEntitiesOriginalMap, setDirtyEntitiesOriginalMap] = useState<
    Record<string, DirtyRecord>
  >({});
  const [dirtyEntitiesModifidedMap, setDirtyEntitiesModifiedMap] = useState<
    Record<string, DirtyRecord>
  >({});

  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<DropdownOption>(
    allTypeOptions[0],
  );
  const orgId = useSelector(selectOnlyOrganization).id;
  useEffect(() => {
    async function loadEntities() {
      setEntitiesLoading(true);
      const entities = await (
        isIntegration
          ? getIntegrationsPermissionsForGroupId
          : getEntitiesForGroupIdV2
      )(orgId, groupId);
      if (entities) {
        setEntities(
          entities.map((entity) =>
            convertToGroupEntityToRender(entity, groupId),
          ),
        );
      }
      setEntitiesLoading(false);
    }
    loadEntities();
  }, [groupId, isIntegration, orgId]);

  const fitleredEntities = useMemo(() => {
    let filtered =
      searchTerm.length > 1
        ? entities.filter((entity) =>
            filterBySearch<GroupEntityToRender>(entity, searchTerm, ["name"]),
          )
        : entities;
    filtered =
      selectedType.value === "all"
        ? filtered
        : filtered.filter((entity) =>
            entity.type
              .toLocaleLowerCase()
              .includes(selectedType.value.toLocaleLowerCase()),
          );

    return filtered.sort(entityCompareFn);
  }, [entities, searchTerm, selectedType.value]);

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  usePreventNavigation("system")(isDirty || isSaving);

  const getRenderCell = useCallback(
    (checkedRole: RbacRole) => {
      const renderCheckbox = ({
        value: originalValue,
        cell,
      }: {
        value: boolean | undefined;
        cell: Cell<GroupEntityToRender, boolean | undefined>;
      }) => {
        const groupEntity = cell.row.original;
        const wasChecked = isEmpty(dirtyEntitiesModifidedMap[groupEntity.id])
          ? (originalValue ?? false)
          : dirtyEntitiesModifidedMap[groupEntity.id].role === checkedRole;
        const label = getTypeLabel(groupEntity.type);
        if (checkedRole === RbacRole.VIEWER && label !== "Application") {
          return (
            <Tooltip title={`You cannot assign view access to a ${label}`}>
              <div style={{ textAlign: "center" }}>-</div>
            </Tooltip>
          );
        }
        return (
          <Checkbox
            checked={wasChecked}
            dataTest={`permissions-groups-entities-checkbox-${groupEntity.name}-${checkedRole}`}
            style={{ justifyContent: "center" }}
            onClick={() => {
              const checked = !wasChecked;
              // if it is not all users group, then it is a delete
              const isDelete =
                !checked && groupName !== DefaultGroupName.EVERYONE;

              if (!(groupEntity.id in dirtyEntitiesOriginalMap)) {
                const newOriginalMap = { ...dirtyEntitiesOriginalMap };
                newOriginalMap[groupEntity.id] = {
                  role: groupEntity.roleActor?.role ?? RbacRole.NONE,
                  type: groupEntity.type,
                  isDelete,
                  // roleActor is used to check if we need to send post or put request
                  roleActor: groupEntity.roleActor,
                };
                setDirtyEntitiesOriginalMap(newOriginalMap);
              }
              const newModifiedMap = { ...dirtyEntitiesModifidedMap };
              newModifiedMap[groupEntity.id] = {
                role: isDelete ? RbacRole.NONE : checkedRole, //RbacRole.None is used to compare with original, will not update a role to NONE in db
                type: groupEntity.type,
                isDelete,
                roleActor: groupEntity.roleActor,
              };
              setDirtyEntitiesModifiedMap(newModifiedMap);
              setIsDirty(true);
            }}
          />
        );
      };
      return renderCheckbox;
    },
    [dirtyEntitiesModifidedMap, dirtyEntitiesOriginalMap, groupName],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Configure",
        accessor: "canConfigure",
        Cell: getRenderCell(RbacRole.CONFIGURATOR),
        style: { width: 84 },
        hidden: !isIntegration,
      },
      {
        Header: isIntegration ? "Build with" : "Build",
        accessor: "canBuild",
        Cell: getRenderCell(RbacRole.BUILDER),
        style: { width: isIntegration ? 84 : 52 },
      },
      {
        Header: "View",
        accessor: "canView",
        Cell: getRenderCell(RbacRole.VIEWER),
        style: { width: 50 },
        hidden: isIntegration,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: renderType,
        style: { width: 130 },
        hidden: isIntegration,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div className={NameStyle}>{value}</div>,
      },
    ],
    [getRenderCell, isIntegration],
  );

  const onSave = useCallback(async () => {
    const promises: Promise<ShareEntryDto[]>[] = [];
    const entityIdsModified: string[] = [];
    Object.keys(dirtyEntitiesOriginalMap).forEach((entityId, index) => {
      const originalRecord = dirtyEntitiesOriginalMap[entityId];
      const modifiedRecord = dirtyEntitiesModifidedMap[entityId];
      if (!originalRecord.type) return;
      if (dirtyEntitiesOriginalMap[entityId].role !== modifiedRecord.role) {
        entityIdsModified.push(entityId);
        promises.push(
          // if no entries was configured on an entity, we need to send a post call
          originalRecord.roleActor === undefined
            ? addShareEntriesForResourceId(
                entityId,
                getPermissionedEntitiesType(originalRecord.type),
                [
                  {
                    type: "group",
                    lookupId: groupId,
                    role: modifiedRecord.role,
                  },
                ],
              )
            : updateShareEntryForResourceId(
                entityId,
                getPermissionedEntitiesType(originalRecord.type),
                {
                  ...originalRecord.roleActor,
                  role: modifiedRecord.role,
                },
                modifiedRecord.isDelete,
              ),
        );
      }
    });
    setIsSaving(true);
    try {
      const newShareEntries = await Promise.all(promises);
      // after updating, we need to update the modified map and original map
      // note that we need to update the roleActor in the original map to reflect if in the future we will add or update the entity's shared entries
      const newModifiedMap = { ...dirtyEntitiesModifidedMap };
      entityIdsModified.forEach((entityId, index) => {
        const actorRole = newShareEntries[index].find(
          (entry) => entry.actorId === groupId,
        );
        newModifiedMap[entityId] = {
          ...dirtyEntitiesModifidedMap[entityId],
          roleActor: actorRole,
        };
      });

      // update entities based on response, especially the roleActor
      setEntities((entities) =>
        entities.map((entity) => {
          if (entity.id in newModifiedMap) {
            return {
              ...entity,
              canView: newModifiedMap[entity.id].role === RbacRole.VIEWER,
              canBuild: newModifiedMap[entity.id].role === RbacRole.BUILDER,
              canConfigure:
                newModifiedMap[entity.id].role === RbacRole.CONFIGURATOR,
              roleActor: newModifiedMap[entity.id].roleActor,
            };
          } else {
            return entity;
          }
        }),
      );

      sendSuccessUINotification({ message: "Permissions updated" });
      setDirtyEntitiesModifiedMap({});
      setDirtyEntitiesOriginalMap({});
      setIsDirty(false);
    } catch (error: any) {
      sendErrorUINotification({
        message: `Failed to save permissions: ${error?.message}`,
      });
    }
    setIsSaving(false);
  }, [dirtyEntitiesModifidedMap, dirtyEntitiesOriginalMap, groupId]);

  return (
    <>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search"
          onChange={onSearchChangeDebounced}
          data-test="permissions-groups-entities-search"
        />
        {!isIntegration && (
          <SingleFilter
            value={selectedType}
            options={allTypeOptions}
            onChange={setSelectedType}
            width={155}
            label={"Type"}
          />
        )}
        <PrimaryButton
          disabled={!isDirty}
          loading={isSaving}
          onClick={onSave}
          style={{ minWidth: 80 }}
          data-test="permissions-groups-entities-save"
        >
          Save
        </PrimaryButton>
      </div>
      <RecommendedTable<GroupEntityToRender>
        data={fitleredEntities}
        dataLabel={
          isIntegration
            ? "integration"
            : selectedType.value === "all"
              ? "applications, workflows or scheduled jobs"
              : `${selectedType.value}s`
        }
        uniqueKey="id"
        columns={columns}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={
          entities.length > DEFAULT_TABLE_PAGE_SIZE
            ? { pageSize: DEFAULT_TABLE_PAGE_SIZE }
            : undefined
        }
        loading={entitiesLoading || isSaving}
      />
    </>
  );
};

export default EntityList;
