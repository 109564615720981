import { Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { CodeBlock } from "../CodeBlock";
import { AgentDeploymentInstructionsProps } from "../common";
import { Terraform_AgentKey } from "../constants";
const { Link, Paragraph, Text } = Typography;

const StepParagraph = styled(Text)`
  & > .ant-typography-secondary {
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const ECS = ({
  agentSubdomain,
  agentDomain,
  agentEnvironment,
  agentDataDomain,
  latestOpaVersion,
}: AgentDeploymentInstructionsProps) => {
  return (
    <Space direction="vertical" size={20}>
      <StepParagraph>
        <Text type="secondary">Requirements:</Text>
        <br />
        <Text type="secondary">
          • Install{" "}
          <Link
            href={"https://developer.hashicorp.com/terraform/downloads"}
            target="_blank"
          >
            Terraform
          </Link>
        </Text>
        <br />
        <Text type="secondary">
          • Configure{" "}
          <Link
            href={
              "https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html"
            }
            target="_blank"
          >
            AWS Credentials
          </Link>
        </Text>
      </StepParagraph>

      <Paragraph>
        <Text strong>Add Superblocks Module to main.tf</Text>
        <CodeBlock
          content={`provider "aws" {
  region = <AWS_REGION>
}

module "terraform-aws-superblocks" {
  source  = "superblocksteam/superblocks/aws"
  version = "~>1.0"

  vpc_id         = "<VPC_ID>"
  lb_subnet_ids  = "<LIST_OF_SUBNET_IDS_FOR_LOAD_BALANCER>"
  ecs_subnet_ids = "<LIST_OF_SUBNET_IDS_FOR_SUPERBLOCKS_AGENT_ECS_CLUSTER>"
  domain         = "${agentDomain}"
  subdomain      = "${agentSubdomain}"

  ${Terraform_AgentKey}        = "<AGENT_KEY>"
  superblocks_agent_tags = "profile:*"

  superblocks_agent_data_domain = "${agentDataDomain}"

  superblocks_agent_image = "ghcr.io/superblocksteam/agent:${latestOpaVersion}"
}`}
        />
      </Paragraph>

      <Paragraph>
        <Text strong>Initialize & Apply Terraform</Text>
        <CodeBlock
          content={`terraform init
terraform apply`}
        />
      </Paragraph>
    </Space>
  );
};

export default ECS;
