import { Typography } from "antd";
import React from "react";

type CodeBlockProps = {
  content: string;
  fontSize?: string;
  copyable?: boolean;
};

export const CodeBlock = ({ content, fontSize, copyable }: CodeBlockProps) => {
  fontSize = fontSize ?? "85%";
  copyable = copyable ?? true;

  return (
    <pre style={{ fontSize }}>
      <Typography.Text code copyable={copyable}>
        {content}
      </Typography.Text>
    </pre>
  );
};
