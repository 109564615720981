import { RoleTypeEnum } from "@superblocksteam/shared";
import { Button, Dropdown } from "antd";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as MoreIcon } from "assets/icons/common/dotdotdot.svg";
import AddButton from "components/ui/AddButton";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import RecommendedTable from "components/ui/RecommendedTable";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { MANAGE_ROLES } from "constants/rbac";
import { useFeatureFlag, usePrevious } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useEditPermissions } from "hooks/ui/rbac/useEditPermissions";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { useListRolesQuery } from "../../store/slices/reduxApi/rbac";
import { CreateRoleModal } from "./CreateRoleModal";
import {
  BottomBarClass,
  filterAndTransformHighights,
  formatNestedRoleTableData,
  getRoleTableColumns,
  RoleTableItem,
} from "./Shared";

export const OrganizationRoles = () => {
  const customRolesEnabled = useFeatureFlag(Flag.ENABLE_RBAC_CUSTOM_ROLES);
  const [canAddRoles] = useAuthorizationCheck([MANAGE_ROLES]);
  const organizationId = useSelector(getCurrentOrgId);
  const { data: roles, isLoading } = useListRolesQuery({
    type: RoleTypeEnum.ORGANIZATION,
    organizationId,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useMemo(
    () => debounce(setSearchTerm, 100),
    [],
  );
  const rolesByType = useMemo(() => {
    return { [RoleTypeEnum.ORGANIZATION]: roles ?? [] };
  }, [roles]);

  const {
    handleEdit,
    handleAllowAll,
    handleClearAll,
    permissionEditsByType,
    numEdits,
    handleReset,
    handleSave,
    isSaving,
  } = useEditPermissions({
    rolesByType,
  });
  const permissionEdits = permissionEditsByType[RoleTypeEnum.ORGANIZATION];

  const columns = useMemo(
    () =>
      getRoleTableColumns({
        data: roles,
        roleType: RoleTypeEnum.ORGANIZATION,
        onCheckboxToggled: handleEdit,
        onAllowAll: handleAllowAll,
        onClearAll: handleClearAll,
      }),
    [roles, handleEdit, handleAllowAll, handleClearAll],
  );

  const rowData = useMemo(() => {
    return formatNestedRoleTableData(roles, columns, permissionEdits);
  }, [roles, columns, permissionEdits]);

  const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
    {},
  );

  const onRowExpand = useCallback((row: RoleTableItem, isExpanded: boolean) => {
    setExpandedState((prev) => ({
      ...prev,
      [row.name]: isExpanded,
    }));
  }, []);

  const [filteredItems, setFilteredItems] =
    useState<Array<RoleTableItem>>(rowData);

  const fuse = useMemo(
    () =>
      new Fuse(rowData, {
        shouldSort: false,
        threshold: 0.1,
        distance: 100,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["name", "children.name"],
        includeMatches: true,
      }),
    [rowData],
  );

  const prevSearchTerm = usePrevious(searchTerm);
  useEffect(() => {
    if (!searchTerm) {
      setFilteredItems(rowData);
      if (prevSearchTerm !== searchTerm) {
        setExpandedState({});
      }

      return;
    }

    /*
      Filter out the nested children and expand all of the top-level items whose children matche the search term
      Also add the matched inidices to each item for highlighting
    */
    const results = fuse.search(searchTerm);
    const updatedExpandedState: Record<string, boolean> = {};

    const withFilteredChildren: Array<RoleTableItem> = results.map((result) => {
      const item = result.item;
      const matches = result.matches || [];
      const topLevelHiglights = matches.find(
        (match) => match.key === "name",
      )?.indices;

      const filteredChildren = item.children
        .map((child: any) => {
          const highlights = matches.find(
            (match) =>
              match.key === "children.name" && match.value === child.name,
          )?.indices;
          return {
            ...child,
            highlights: filterAndTransformHighights(searchTerm, highlights),
          };
        })
        .filter((child) => child.highlights != null);

      if (filteredChildren.length > 0) {
        updatedExpandedState[item.name] = true;
      }
      return {
        ...item,
        highlights: filterAndTransformHighights(searchTerm, topLevelHiglights),
        children: filteredChildren,
      } as RoleTableItem;
    });

    setFilteredItems(withFilteredChildren);
    setExpandedState(updatedExpandedState);
  }, [searchTerm, fuse, rowData, prevSearchTerm]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "expand-all",
        label: "Expand all",
        onClick: () =>
          setExpandedState(
            Object.fromEntries(rowData.map((item) => [item.name, true])),
          ),
      },
      {
        key: "collapse-all",
        label: "Collapse all",
        onClick: () => setExpandedState({}),
      },
    ];
  }, [rowData]);

  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);

  return (
    <>
      <div style={numEdits > 0 ? { paddingBottom: 64 } : {}}>
        {isCreateRoleModalOpen && (
          <CreateRoleModal
            onClose={() => setIsCreateRoleModalOpen(false)}
            roleType={RoleTypeEnum.ORGANIZATION}
          />
        )}
        <div className={SearchContainer}>
          <SearchInput
            placeholder="Search permissions by name"
            onChange={(e) => onSearchChangeDebounced(e.target.value)}
          />
          {customRolesEnabled && (
            <AddButton
              text="Add role"
              onAdd={() => setIsCreateRoleModalOpen(true)}
              disabled={!canAddRoles}
              disabledText="You do not have permission to add roles"
            />
          )}
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Button
              icon={
                <MoreIcon
                  style={{
                    position: "relative",
                    top: "-3px",
                    color: colors.GREY_500,
                    minWidth: 30,
                  }}
                />
              }
            />
          </Dropdown>
        </div>
        <RecommendedTable
          columns={columns}
          data={filteredItems}
          canExpandRows={true}
          expandedState={expandedState}
          loading={isLoading}
          uniqueKey="name"
          useFixedColumnWidths={true}
          onRowExpand={onRowExpand}
        />
      </div>
      {numEdits > 0 && (
        <div className={BottomBarClass}>
          {numEdits} unsaved change{numEdits > 1 ? "s" : ""}
          <SecondaryButton onClick={handleReset} disabled={isSaving}>
            Reset
          </SecondaryButton>
          <PrimaryButton onClick={handleSave} disabled={isSaving}>
            Save
          </PrimaryButton>
        </div>
      )}
    </>
  );
};
