import {
  getBlockChildListLocators,
  findBlockLocation,
  getBlockListFromLocator,
  BlockPositionLocator,
} from "store/slices/apisV2/control-flow/locators";
import { ControlFlowFrontendDSL } from "store/slices/apisV2/control-flow/types";

export const deleteBlockTree = (
  newState: ControlFlowFrontendDSL,
  blockName: string,
) => {
  const blockToDelete = newState.blocks[blockName];
  const location = findBlockLocation(newState, blockName);
  if (blockToDelete == null || location == null) {
    throw new Error("Could not find block to delete");
  }
  if (location.parentId == null) {
    newState.rootBlocks.splice(location.idx, 1);
  } else {
    const containingList = getBlockListFromLocator(newState, location);
    containingList.splice(location.idx, 1);
  }
  const innerBlockLocators = getBlockChildListLocators(blockToDelete);
  innerBlockLocators.forEach((locator) => {
    const blockList = getBlockListFromLocator(newState, locator);
    blockList.forEach((childName) => {
      deleteBlockTree(newState, childName);
    });
  });
  delete newState.blocks[blockName];
};

export const moveBlocks = (params: {
  blockNames: string[];
  newLocation: BlockPositionLocator;
  state: ControlFlowFrontendDSL;
}) => {
  const { state, blockNames, newLocation } = params;
  const oldLocation = findBlockLocation(state, blockNames[0]);
  if (!oldLocation) {
    throw new Error("Could not find block to move");
  }
  const removeFromArr = getBlockListFromLocator(state, oldLocation);
  const insertArr = getBlockListFromLocator(state, newLocation);
  const insertIdx = newLocation.idx;
  // get the indices of all of the blocks to move
  // sort them and group them as > or < the insertIdx
  const groups = {
    lessThanInsert: [] as Array<{ blockName: string; index: number }>,
    greaterThanInsert: [] as Array<{ blockName: string; index: number }>,
  };
  blockNames.forEach((blockName) => {
    const index = removeFromArr.indexOf(blockName);
    if (index < insertIdx) {
      groups.lessThanInsert.push({ blockName, index });
    } else {
      groups.greaterThanInsert.push({ blockName, index });
    }
  });

  // Sort each group, so that we maintain the original order the blocks were in
  groups.lessThanInsert.sort((a, b) => a.index - b.index);
  groups.greaterThanInsert.sort((a, b) => a.index - b.index);

  // remove items that are below the insert index, in reverse order
  for (let i = groups.greaterThanInsert.length - 1; i >= 0; i--) {
    removeFromArr.splice(groups.greaterThanInsert[i].index, 1);
  }
  // insert the items from above the insert index into the new position
  insertArr.splice(
    insertIdx,
    0,
    ...groups.lessThanInsert.map((x) => x.blockName),
  );
  // insert the items from below the insert index into the new position
  insertArr.splice(
    insertIdx + groups.lessThanInsert.length,
    0,
    ...groups.greaterThanInsert.map((x) => x.blockName),
  );
  // remove items above the insert index in reverse order
  groups.lessThanInsert
    .reverse()
    .forEach((x) => removeFromArr.splice(x.index, 1));

  // update the parent ids
  blockNames.forEach((blockName) => {
    state.blocks[blockName].parentId = newLocation.parentId ?? undefined;
  });
};

export const renameBlock = (params: {
  prevBlockName: string;
  updatedBlockName: string;
  location: BlockPositionLocator;
  state: ControlFlowFrontendDSL;
}) => {
  const { prevBlockName, updatedBlockName, state, location } = params;
  const updateNameArr = getBlockListFromLocator(state, location);
  updateNameArr.splice(location.idx, 1, updatedBlockName);

  state.blocks[updatedBlockName] = {
    ...state.blocks[prevBlockName],
    name: updatedBlockName,
  };
  delete state.blocks[prevBlockName];

  const innerBlockLocators = getBlockChildListLocators(
    state.blocks[updatedBlockName],
  );
  innerBlockLocators.forEach((locator) => {
    const blockList = getBlockListFromLocator(state, locator);
    blockList.forEach((childName) => {
      // update parent id
      state.blocks[childName].parentId = updatedBlockName;
    });
  });
};

export const insertBlocksIntoParent = (params: {
  blockNames: string[];
  location: BlockPositionLocator;
  state: ControlFlowFrontendDSL;
}) => {
  const { state, blockNames, location } = params;
  const insertArr = getBlockListFromLocator(state, location);
  const insertIdx = location.idx;
  insertArr.splice(insertIdx + 1, 0, ...blockNames);

  blockNames.forEach((blockName) => {
    state.blocks[blockName].parentId = location.parentId ?? undefined;
  });
};
