import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { useSaga } from "hooks/store";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import { EditorRoute } from "legacy/constants/routes";
import { duplicateV1ApiSaga } from "store/slices/apisShared";
import { selectApiVersionLookup } from "store/slices/apisShared/selectors";
import { duplicateV2ApiSaga } from "store/slices/apisV2";
import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";

export default function useDuplicateApi(applicationId: string) {
  const navigate = useNavigate();
  const location = useLocation();
  const [duplicateV1Api] = useSaga(duplicateV1ApiSaga, {
    resolveAfterUnmounted: true,
  });
  const [duplicateV2Api] = useSaga(duplicateV2ApiSaga, {
    resolveAfterUnmounted: true,
  });
  const apiVersionLookup = useSelector(selectApiVersionLookup);
  const getEditorPath = useGetEditorPath();

  return useCallback(
    async (apiId: string) => {
      const apiVersion = apiVersionLookup[apiId];
      if (apiVersion === "v1") {
        const ret = await duplicateV1Api({ apiId });
        if (ret) {
          const actionIds = Object.keys(ret?.actions?.actions ?? {});
          const firstAction = actionIds.length > 0 ? actionIds[0] : undefined;
          navigate({
            pathname: getEditorPath(EditorRoute.EditApiAction, {
              applicationId,
              apiId: ret.id,
              actionId: firstAction,
            }),
            search: location.search,
          });
        }
        return ret;
      } else if (apiVersion === "v2") {
        const ret = await duplicateV2Api({ apiId });
        if (ret && ret.apiPb && ret.apiPb.blocks) {
          const blocks = ret.apiPb.blocks;
          const firstBlock = blocks.length > 0 ? blocks[0] : undefined;
          navigate({
            pathname: getEditorPath(EditorRoute.EditApiAction, {
              applicationId,
              apiId: getV2ApiId(ret),
              actionId: firstBlock?.name,
            }),
            search: location.search,
          });
        }
        return ret;
      }
    },
    [
      apiVersionLookup,
      duplicateV1Api,
      navigate,
      getEditorPath,
      applicationId,
      location.search,
      duplicateV2Api,
    ],
  );
}
