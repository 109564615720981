import {
  ApiTriggerType,
  ViewMode,
  OrchestratorViewMode,
  ScheduleConfig,
  Plugin,
  DatasourceDto,
} from "@superblocksteam/shared";
import { v4 as uuidv4 } from "uuid";
import { createV2Schedule } from "pages/Editors/ApiEditor/WorkflowEditor/api-version-utils";
import { getInitialApiConfiguration } from "pages/Editors/ApiEditor/utils";
import {
  createBlankControlBlock,
  createNewStepBlock,
} from "store/slices/apisV2/control-flow/block-generation";
import { convertDSLToBackendBlocks } from "store/slices/apisV2/control-flow/dsl-converters";
import {
  GenericBlock,
  BlockType,
  AddAPIBlockOption,
} from "store/slices/apisV2/control-flow/types";
import * as BackendTypes from "../backend-types";
import type { ExecutionParamDto } from "../../apis";

export const getTriggerTypeFromApi = (
  api: BackendTypes.Api,
): ApiTriggerType => {
  if (api.trigger?.application != null) {
    return ApiTriggerType.UI;
  } else if (api.trigger?.workflow != null) {
    return ApiTriggerType.WORKFLOW;
  } else if (api.trigger?.job != null) {
    return ApiTriggerType.SCHEDULE;
  }
  throw new Error("Invalid trigger type");
};

export const getUpdatedTime = (updated: Date | undefined): number => {
  if (typeof updated === "string") {
    return new Date(updated).valueOf();
  } else if (typeof updated === "number") {
    return updated;
  }
  return Date.now();
};

export const V2ApiPayloadCreators = {
  [ApiTriggerType.UI]: (params: {
    blockName: string;
    option: AddAPIBlockOption;
    organizationId: string;
    applicationId: string;
    pageId?: string;
  }): BackendTypes.Api => {
    const { option, blockName, organizationId, applicationId } = params;
    let firstBlock: GenericBlock;

    if (option.type === BlockType.STEP) {
      const actionId = uuidv4();
      firstBlock = createNewStepBlock(
        {
          id: actionId,
          pluginId: option.plugin?.id,
          datasourceId: option.datasource?.id,
          name: blockName,
          type: 0,
          applicationId: applicationId,
          organizationId: organizationId,
          configuration: getInitialApiConfiguration(option.plugin),
        },
        {},
        undefined,
      );
    } else {
      firstBlock = createBlankControlBlock({
        controlType: option.type,
        existingBlocks: {},
        parentId: undefined,
        usedVariables: new Set(),
        triggerType: ApiTriggerType.UI,
      });
    }

    return {
      metadata: {
        name: "",
        organization: organizationId,
        id: uuidv4(),
      },
      blocks: convertDSLToBackendBlocks({
        rootBlocks: [firstBlock.name],
        blocks: {
          [firstBlock.name]: firstBlock,
        },
      }),
      trigger: {
        application: {
          id: applicationId,
          pageId: params.pageId,
        },
      },
    };
  },
  [ApiTriggerType.WORKFLOW]: (params: {
    name: string;
    organizationId: string;
    folder?: string;
  }) => {
    const { name, organizationId, folder } = params;
    return {
      metadata: {
        name,
        organization: organizationId,
        id: uuidv4(),
        folder,
      },
      blocks: [],
      trigger: {
        workflow: {},
      },
    };
  },
  [ApiTriggerType.SCHEDULE]: (params: {
    name: string;
    organizationId: string;
    folder?: string;
    plugin?: Plugin;
    datasource?: DatasourceDto;
    schedule?: ScheduleConfig;
  }) => {
    let firstBlock: GenericBlock | undefined;
    const { name, organizationId, folder, datasource, plugin, schedule } =
      params;
    if (datasource && plugin) {
      firstBlock = createNewStepBlock(
        {
          id: uuidv4(),
          pluginId: plugin?.id,
          datasourceId: datasource?.id,
          name: "Step 1",
          type: 0,
          applicationId: "",
          organizationId: organizationId,
          configuration: getInitialApiConfiguration(plugin),
        },
        {},
        undefined,
      );
    }
    return {
      metadata: {
        name,
        organization: organizationId,
        id: uuidv4(),
        folder,
      },
      blocks: firstBlock
        ? convertDSLToBackendBlocks({
            rootBlocks: [firstBlock.name],
            blocks: {
              [firstBlock.name]: firstBlock,
            },
          })
        : [],
      trigger: {
        job: {
          ...(schedule ? createV2Schedule(schedule) : {}),
          options: {
            sendEmailOnFailure: true,
            profiles: {},
          },
        },
      },
    };
  },
} as const;

export const getV2ApiExecutionParams = (apiPb: BackendTypes.Api) => {
  const triggerType = getTriggerTypeFromApi(apiPb);
  const params: Array<ExecutionParamDto> = [];
  if (triggerType === ApiTriggerType.WORKFLOW) {
    const properties = apiPb.trigger.workflow;
    if (properties) {
      Object.entries(properties).forEach(([key, value]) => {
        params.push({ key, value });
      });
    }
  }
  return params;
};

export const translateViewMode = (mode: ViewMode): OrchestratorViewMode => {
  switch (mode) {
    case ViewMode.EDITOR:
      return OrchestratorViewMode.EDIT;
    case ViewMode.PREVIEW:
      return OrchestratorViewMode.PREVIEW;
    case ViewMode.DEPLOYED:
      return OrchestratorViewMode.DEPLOYED;
    default:
      return OrchestratorViewMode.UNSPECIFIED;
  }
};
