import { ArrowRightOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import logo from "assets/images/ColorLogo.svg";
import SurveyBackgroundImage from "assets/images/survey_background.png";
import { FullWidthSpace } from "components/ui/Space";

export const BACK_BTN_WIDTH_PX = 18;
export const BACK_BTN_MARGIN_RIGHT_PX = 14;
export const BACK_BTN_LEFT_OFFSET_PX =
  BACK_BTN_WIDTH_PX + BACK_BTN_MARGIN_RIGHT_PX;

const SurveyItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  background-color: white;
`;

const Sidebar = styled.aside`
  background-color: #7c4ef7;
  width: 296px;
`;

const MainPane = styled.main`
  flex-grow: 1;
  padding: 36px;
  display: flex;
  flex-direction: column;
`;

const MainPaneHeader = styled.header``;

const LogoWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  flex-direction: row;
  .ant-typography {
    margin-bottom: 0px;
    font-weight: 900;
  }
`;

const Frame = styled.div`
  width: 868px;
  margin: auto;
  height: 600px;
`;

export const SurveyItemSlide = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <SurveyItemWrapper>
      <MainPane>
        <MainPaneHeader>
          <LogoWrapper>
            <Space direction="horizontal" size={12}>
              <img src={logo} alt="Superblocks" data-test="brand-logo" />
              <Typography.Title level={4}>Superblocks</Typography.Title>
            </Space>
          </LogoWrapper>
        </MainPaneHeader>
        <Frame>{children}</Frame>
      </MainPane>
      <Sidebar>
        <img src={SurveyBackgroundImage} alt="background" />
      </Sidebar>
    </SurveyItemWrapper>
  );
};

export const SurveyContentsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FullWidthSpace
      direction="vertical"
      size={30}
      style={{ padding: `0 ${BACK_BTN_LEFT_OFFSET_PX}px` }}
    >
      {children}
    </FullWidthSpace>
  );
};

export const ProgressLabelContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

export const QuestionLabel = styled.div`
  font-size: 36px;
  font-weight: 900;
`;

const BigButton = styled.button`
  height: 64px;
  min-width: 160px;

  border-width: 0px;
  background: #7c4ff8;
  color: white;
  border-radius: 32px;
  cursor: pointer;
  font-size: 18px;

  & .ant-space {
    transition: transform 200ms ease-out;
  }
  &:hover .ant-space {
    transform: scale(1.05);
  }
`;

interface NextButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export const NextButton = ({ onClick, children }: NextButtonProps) => {
  return (
    <BigButton onClick={onClick}>
      <Space direction="horizontal" size={10}>
        {children}
        <ArrowRightOutlined />
      </Space>
    </BigButton>
  );
};

export const SecondaryText = styled.div`
  color: ${(props) => props.theme.colors.GREY_500};
  font-size: 20px;
`;
