import {
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  ColorBlock,
  TextStyleBlock,
  Typographies,
} from "@superblocksteam/shared";
import { get } from "lodash";
import { fastClone } from "utils/clone";
import { getPrimaryVariants } from "./generateColors";
import { GeneratedTheme } from "./types";

const generateColorBlock = (
  colorBlock: ColorBlock,
  options: {
    colors: GeneratedTheme["colors"];
    defaultFontFamily: GeneratedTheme["fontFamily"];
  },
): ColorBlock => {
  const updatedColorBlock = { ...colorBlock };
  Object.keys(colorBlock).forEach((colorKey: string) => {
    const typedColorKey = colorKey as keyof ColorBlock;
    const generatedColor = get(options, colorBlock[typedColorKey] ?? "");
    updatedColorBlock[typedColorKey] =
      generatedColor || colorBlock[typedColorKey];
  });
  return updatedColorBlock;
};

export const generateTypographies = (
  typographies: Typographies,
  options: {
    colors: GeneratedTheme["colors"];
    defaultFontFamily: GeneratedTheme["fontFamily"];
  },
): Typographies => {
  // replace all colors of the format colors.colorName with the generated color
  const generatedTypographies = fastClone(typographies);
  Object.keys(generatedTypographies).forEach((key) => {
    if (key === CUSTOM_THEME_TYPOGRAPHY_KEY) {
      const customTypographies =
        generatedTypographies[CUSTOM_THEME_TYPOGRAPHY_KEY] ?? {};
      Object.keys(customTypographies).forEach((customKey) => {
        if (
          generatedTypographies?.[CUSTOM_THEME_TYPOGRAPHY_KEY]?.[customKey]
            ?.styles?.textColor == null
        )
          return;
        generatedTypographies[CUSTOM_THEME_TYPOGRAPHY_KEY][
          customKey
        ].styles.textColor = generateColorBlock(
          generatedTypographies[CUSTOM_THEME_TYPOGRAPHY_KEY][customKey].styles
            .textColor,
          options,
        );
      });
    } else {
      const typedKey = key as keyof Typographies;
      const typography = generatedTypographies[typedKey] as TextStyleBlock;
      typography.textColor = generateColorBlock(typography.textColor, options);
      // for links specifically, we need to dynamically generate the hover & active colors based on the default color
      if (typedKey === "link") {
        const color = typography.textColor.default;
        const { primary600, primary700 } = getPrimaryVariants(color);
        typography.textColor.hover = primary600;
        typography.textColor.active = primary700;
        // legacy link styles did not set textTransform correctly to inherit
        typography.textTransform = "inherit";
      }
      if (typedKey === "inputPlaceholder") {
        // legacy apps did not have fontStyle, textTransform, and textDecoration set to inherit
        typography.fontStyle = "inherit";
        typography.textTransform = "inherit";
        typography.textDecoration = "inherit";
      }
    }
  });

  return generatedTypographies;
};
