import {
  PostApiCreateRequestBody,
  PutApiUpdateRequestBody,
} from "@superblocksteam/schemas";
import {
  Agent,
  Organization,
  Profile,
  ViewMode,
  ApiTriggerTypeQuery,
} from "@superblocksteam/shared";
import {
  SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS,
  SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
} from "env";
import { EventType } from "legacy/constants/ActionConstants";
import { SERVER_API_TIMEOUT_ERROR } from "legacy/constants/messages";
import { type ApiDtoWithPb } from "store/slices/apisV2/slice";
import { AgentApiPaths } from "store/utils/agent";
import { HttpError } from "store/utils/types";
import UITracing from "tracing/UITracing";
import { sendErrorUINotification } from "utils/notification";
import { getUrlWithBranch } from "utils/urlWithBranch";
import {
  callServer,
  callAgent,
  ErrorHandlingOptions,
  HttpMethod,
} from "../../utils/client";
import {
  ApiDto,
  ApiExecutionRequestDto,
  ApiExecutionResponseDto,
} from "./types";

// TODO: ask backend to implement this endpoint
// export function getAllApis({
//   applicationId,
//   environment,
//   isPublished = "false",
//   profile,
// }: {
//   applicationId: string;
//   environment?: string;
//   isPublished?: string;
//   profile?: Profile;
// }) {
//   return callServer<ApiDto[]>({
//     method: HttpMethod.Get,
//     url: "v3/apis/",
//     query: {
//       applicationId,
//       isPublished,
//       ...(environment ? { environment } : {}),
//       ...(profile?.key ? { profile: profile?.key } : {}),
//       ...(profile?.id ? { profileId: profile?.id } : {}),
//     },
//   });
// }

export function getApiV3({
  apiId,
  branch,
  errorHandlingOptions,
}: {
  apiId: string;
  branch?: string;
  errorHandlingOptions?: ErrorHandlingOptions;
}) {
  return callServer<ApiDto>(
    {
      method: HttpMethod.Get,
      url: branch
        ? `v3/apis/${apiId}/branches/${encodeURIComponent(branch)}`
        : `v3/apis/${apiId}`,
    },
    errorHandlingOptions ?? {
      notifyOnError: false,
    },
  );
}

export function createV3Api(
  branch: string | undefined,
  body: PostApiCreateRequestBody,
) {
  return callServer<ApiDto>({
    method: HttpMethod.Post,
    url: getUrlWithBranch(`v3/apis`, branch),
    body,
  });
}

export function getAllWorkflowsAndScheduledJobs() {
  return callServer<ApiDtoWithPb[]>({
    method: HttpMethod.Get,
    url: `v3/apis`,
  });
}

export function getAllWorkflows() {
  return callServer<ApiDtoWithPb[]>({
    method: HttpMethod.Get,
    url: `v3/apis/`,
    query: {
      triggerType: ApiTriggerTypeQuery.WORKFLOW,
    },
  });
}

export function getAllScheduledJobs() {
  return callServer<ApiDtoWithPb[]>({
    method: HttpMethod.Get,
    url: `v3/apis/`,
    query: {
      triggerType: ApiTriggerTypeQuery.SCHEDULE,
    },
  });
}

export function executeApi({
  agents,
  body,
  environment,
  eventType,
  notifyOnSystemError,
  organization,
  files,
  profile,
  spanId,
  mode,
  commitId,
}: {
  agents: Agent[];
  body: ApiExecutionRequestDto;
  // environment might be undefined if the profiles feature is enabled
  environment?: string;
  eventType: EventType;
  notifyOnSystemError: boolean;
  organization: Organization;
  files?: Record<string, File>;
  profile?: Profile | { key: string };
  spanId?: string;
  mode?: ViewMode;
  commitId?: string;
}) {
  environment = profile?.key ?? environment;
  return callAgent<ApiExecutionResponseDto>({
    method: HttpMethod.Post,
    url: AgentApiPaths.API_EXECUTE,
    query: {
      ...(environment ? { environment } : {}),
      ...(profile?.key ? { profile: profile?.key } : {}),
      ...((profile as Profile)?.id
        ? { profileId: (profile as Profile).id }
        : {}),
      eventType: eventType,
      ...(mode ? { mode } : {}),
      commitId: commitId ?? "",
    },
    body,
    files,
    timeout: Number(SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS),
    agents,
    notifyOnSystemError,
    organization,
    headers: UITracing.getTracingHeaders(spanId),
  });
}

export async function persistApiV3(
  apiId: string,
  api: PutApiUpdateRequestBody,
  superblocksSupportUpdateEnabled = false,
  branch: string | undefined,
) {
  let err: undefined | HttpError;
  const result = await callServer<ApiDto>(
    {
      method: HttpMethod.Put,
      // TODO: always use branch based path
      url: getUrlWithBranch("v3/apis/:apiId", branch),
      params: { apiId },
      body: api,
      timeout: SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
      query: superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : {},
    },
    {
      notifyOnError: false,
      onError: (error: HttpError) => {
        err = error;
        // if error code is 409, modal will show, so no need to show notification
        if (error.code !== 409) {
          sendErrorUINotification({
            message: `Failed to save api: ${
              error.code === 20
                ? SERVER_API_TIMEOUT_ERROR
                : `${error.message} (${error.code})`
            }`,
          });
        }
      },
    },
  );
  if (err) {
    throw err;
  }
  return result;
}

export function deleteApiV3(apiId: string, branch: string | undefined) {
  return callServer<ApiDto>({
    method: HttpMethod.Delete,
    url: getUrlWithBranch("v3/apis/:apiId", branch),
    params: { apiId },
  });
}

export function cloneApi(apiId: string) {
  return callServer<ApiDto>({
    method: HttpMethod.Post,
    url: "v3/apis/:apiId/clone",
    params: { apiId },
  });
}
