import React from "react";

type PaneProps = {
  children: React.ReactNode;
  className: string;
  split?: "vertical" | "horizontal";
  style: React.CSSProperties;
  size?: string | number;
  eleRef?: (node: HTMLDivElement) => void;
};

const Pane = ({
  children,
  className,
  split = "vertical",
  style: styleProps,
  size,
  eleRef,
}: PaneProps) => {
  const classes = ["Pane", split, className];

  let style: React.CSSProperties = {
    flex: 1,
    position: "relative",
    outline: "none",
  };

  if (size !== undefined) {
    if (split === "vertical") {
      style.width = size;
    } else {
      style.height = size;
      style.display = "flex";
    }
    style.flex = "none";
  }

  style = Object.assign({}, style, styleProps || {});

  return (
    <div ref={eleRef} className={classes.join(" ")} style={style}>
      {children}
    </div>
  );
};

export default Pane;
