import { ApplicationScope } from "@superblocksteam/shared";
import {
  EmbedProperty,
  EmbedPropertyMetaType,
} from "legacy/constants/EmbeddingConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { EmbedEventType } from "utils/embed/messages";
import { showItemPropertyPane } from "./propertyPaneActions";

export function createAndOpenEmbedProp(id?: string, name?: string) {
  return {
    type: ReduxActionTypes.CREATE_EMBED_PROPERTY,
    payload: {
      id,
      name,
      open: true,
    },
  };
}

export function deleteEmbedProp(id: EmbedProperty["id"]) {
  return {
    type: ReduxActionTypes.DELETE_EMBED_PROPERTY,
    payload: { id },
  };
}

export function updateEmbedProp(
  payload: Record<
    EmbedProperty["id"],
    Partial<Pick<EmbedProperty, "defaultValue">>
  >,
) {
  return {
    type: ReduxActionTypes.UPDATE_EMBED_PROPERTY,
    payload,
  };
}

export const editEmbedPropPropertyPane = (embedPropId: string) =>
  showItemPropertyPane({
    kind: ItemKinds.EMBED_PROP,
    id: embedPropId,
    scope: ApplicationScope.PAGE,
  });

// meta
export interface UpdateEmbedPropMetaPropertiesPayload {
  updates: Record<string, EmbedPropertyMetaType>;
}

export const updateEmbedPropMetaPropertiesWithOnChange = (
  updates: UpdateEmbedPropMetaPropertiesPayload["updates"],
): ReduxAction<UpdateEmbedPropMetaPropertiesPayload> => {
  return {
    type: ReduxActionTypes.UPDATE_EMBED_PROPERTY_META_ON_CHANGE,
    payload: {
      updates,
    },
  };
};

export function updateEmbedEvents(
  eventsType: EmbedEventType,
  events: Record<string, boolean>,
) {
  return {
    type: ReduxActionTypes.UPDATE_EMBED_EVENTS,
    payload: { eventsType, events },
  };
}
