import { datadogRum } from "@datadog/browser-rum";
import { AccessMode, extractParamsWithPrefix } from "@superblocksteam/shared";
import { get } from "lodash";
import { call, select } from "redux-saga/effects";
import envs from "env";
import { User } from "legacy/constants/userConstants";
import { getCurrentUserSaga } from "legacy/sagas/UserSagas";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { getQueryParams } from "legacy/utils/Utils";
import { createSaga } from "store/utils/saga";
import logger from "utils/logger";
import { ReduxActionTypes } from "../../legacy/constants/ReduxActionConstants";
import { selectOnlyOrganization } from "../slices/organizations";

interface IntializeUserPayload {
  accessMode: AccessMode;
  /**
   * True: Browser will healthcheck OPAs and Cloud agents of the organization.
   * False: Browser will only healthcheck either OPAs or Cloud agents based on the organization setting.
   */
  probeAllAgents?: boolean;
}

function* initializeUser({
  accessMode,
  probeAllAgents = false,
}: IntializeUserPayload): Generator<any, any, any> {
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.INITIALIZE_APP_SAGA,
  );

  const params = getQueryParams();
  // we expect utm_ parameters to be fwded in cookies, but we still support
  // taking in params as well for direct links. (e.g.
  // app.superblocks.com?utm_source=foobar)
  const utmParams = extractParamsWithPrefix(params, "utm_");
  // Sbt is a custom prefix for other Superblocks tracking.
  const sbTrackingParams = extractParamsWithPrefix(params, "sbt_");
  // we expect gclid to be sent here (Google Click ID)
  const googleParams = extractParamsWithPrefix(params, "gclid");

  const trackingParams = {
    referrer: get(params, "referrer"),
    ...utmParams,
    ...googleParams,
    ...sbTrackingParams,
  };

  yield call(getCurrentUserSaga, {
    type: ReduxActionTypes.FETCH_USER_INIT,
    payload: {
      accessMode,
      probeAllAgents,
      trackingParams,
    },
  });
  const user = (yield select(getCurrentUser)) as User;
  // If the user is not hydrated, it implies that the app crashed and we're
  // handling that above. So we don't need to do anything here.
  if (!user) return;
  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );
  logger.setUIDiagnosticContext({
    superblocks_user_id: user.id,
    superblocks_user_email: user.email,
    superblocks_org_id: organization.id,
    superblocks_org_name: organization.name,
  });

  // Initialize intercom user
  window.Intercom &&
    window.Intercom("boot", {
      app_id: envs.get("SUPERBLOCKS_UI_INTERCOM_APPID"),
      email: user.email,
      name: user.name,
      user_id: user.id,
      organization: organization.name,
      organization_id: organization.id,
    });

  // add user/org info to rum
  datadogRum.setGlobalContext({
    superblocks_user_id: user.id,
    superblocks_user_email: user.email,
    superblocks_org_id: organization.id,
    superblocks_org_name: organization.name,
  });

  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.INITIALIZE_APP_SAGA,
  );
}

function* handleInitializeVisitor(): Generator<any, any, any> {
  const params = getQueryParams();
  const utmParams = extractParamsWithPrefix(params, "utm_");
  const sbTrackingParams = extractParamsWithPrefix(params, "sbt_");

  const trackingParams = {
    referrer: get(params, "referrer"),
    ...utmParams,
    ...sbTrackingParams,
  };
  yield call(getCurrentUserSaga, {
    type: ReduxActionTypes.FETCH_USER_INIT,
    payload: {
      accessMode: AccessMode.VISITOR,
      probeAllAgents: false,
      trackingParams,
    },
  });
  const user = yield select(getCurrentUser);
  // If the user is not hydrated, it implies that the app crashed and we're
  // handling that above. So we don't need to do anything here.
  if (!user) return;

  logger.setUIDiagnosticContext({
    superblocks_user_id: user.id,
  });
}

export const initializeVisitor = createSaga(
  handleInitializeVisitor,
  "initializeVisitorSaga",
);

export default createSaga(initializeUser, "initializeUserSaga");
