import { buffers } from "@redux-saga/core";
import { ActionPattern, Channel } from "@redux-saga/types";
import * as Sentry from "@sentry/react";
import { Task } from "redux-saga";
import {
  ForkEffect,
  actionChannel,
  call,
  debounce as singleDebounce,
  delay,
  fork,
  spawn,
  take,
  takeEvery,
  takeLatest as singleTakeLatest,
  takeLeading as singleTakeLeading,
  takeMaybe,
  throttle as singleThrottle,
  flush,
  cancel,
} from "redux-saga/effects";

import log from "utils/logger";
import { PayloadAction, Action } from "./action";
import { FullSagaDefinition, SagaType } from "./saga";

export function queued<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
) {
  return fork(function* () {
    const queueActionChannel: Channel<A> = yield actionChannel(
      pattern,
      buffers.expanding(),
    );

    while (true) {
      const action: A = yield take(queueActionChannel);
      yield call(worker, action);
    }
  });
}

export function queuedBatchDebounce<A extends Action>(
  pattern: ActionPattern<A>,
  worker: (actions: A[]) => any,
  debounceTimeMs = 3,
) {
  return fork(function* () {
    // Create a channel with an expanding buffer
    const queueActionChannel: Channel<A> = yield actionChannel(
      pattern,
      buffers.expanding(),
    );

    // Infinite loop to keep the saga running
    while (true) {
      const firstAction: A = yield take(queueActionChannel);
      yield delay(debounceTimeMs);
      const batchedActions: A[] = yield flush(queueActionChannel);
      batchedActions.unshift(firstAction);
      yield call(worker, batchedActions);
    }
  });
}

export function takeLatestByKey<A extends Action, K>(
  pattern: ActionPattern<A>,
  worker: (action: A) => any,
  keySelector: (action: A) => K,
) {
  return fork(function* () {
    const taskMap = new Map<K, Task>();

    while (true) {
      const action: A = yield take(pattern);
      const key = keySelector(action);

      if (taskMap.has(key)) {
        const lastTask = taskMap.get(key);
        if (lastTask) {
          yield cancel(lastTask);
        }
      }

      const task: Task = yield fork(worker, action);
      taskMap.set(key, task);
    }
  });
}

export function spawnSaga(saga: () => Generator): ForkEffect<void> {
  function* tryRun() {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        log.error(e);
        Sentry.captureException(e);
        yield delay(100);
      }
    }
  }

  return spawn(tryRun);
}

function multiThrottle(
  keySelector: (payload: unknown, callId?: number) => string,
  ms: number,
  triggers: string[],
  saga: (action: any) => Generator,
): ForkEffect<never> {
  return fork(function* () {
    const taskMap = new Map<string, Task>();

    while (true) {
      const action: PayloadAction<unknown> = yield take(triggers);
      // TODO: should we pass callId to keySelector? it probably doesn't make sense because it would negate the purpose of throttling
      const key = keySelector(action.payload);

      if (taskMap.has(key)) {
        continue;
      }

      const filter = (takenAction: Action) => {
        if (triggers.includes(takenAction.type)) {
          const payload = (takenAction as PayloadAction<unknown>).payload;

          if (typeof payload === "object" && payload !== null) {
            return keySelector(payload) === key;
          }
        }

        return false;
      };

      const task: Task = yield fork(function* () {
        try {
          const throttleChannel: Channel<PayloadAction<unknown>> =
            yield actionChannel(filter, buffers.sliding(1));

          let latestAction = action;

          while (latestAction) {
            yield call(saga, latestAction);
            yield delay(ms);

            latestAction = yield takeMaybe(throttleChannel);
          }
        } finally {
          taskMap.delete(key);
        }
      });

      taskMap.set(key, task);
    }
  });
}

// Prevent duplicate saga registration
const registeredSagaNames = new Set<string>();

export function initializeSaga(definition: FullSagaDefinition<any, any>) {
  function* watch() {
    switch (definition.type) {
      case SagaType.Debounced:
        yield singleDebounce(
          definition.delay ?? 500,
          definition.triggers,
          definition.root,
        );
        break;
      case SagaType.Throttled:
        if (definition.keySelector) {
          yield multiThrottle(
            definition.keySelector,
            definition.delay ?? 500,
            definition.triggers,
            definition.root,
          );
        } else {
          yield singleThrottle(
            definition.delay ?? 500,
            definition.triggers,
            definition.root,
          );
        }
        break;
      case SagaType.Every:
        yield takeEvery(definition.triggers, definition.root);
        break;
      case SagaType.Latest:
        yield singleTakeLatest(definition.triggers, definition.root);
        break;
      case SagaType.Leading:
        yield singleTakeLeading(definition.triggers, definition.root);
        break;
    }
  }

  // Only start a saga once- they keep running in the background
  if (!registeredSagaNames.has(definition.name)) {
    registeredSagaNames.add(definition.name);

    return spawnSaga(watch);
  }
}
