import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  focusWidget,
  selectWidgets,
  showWidgetPropertyPane,
} from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getIsPropertyPaneVisible } from "legacy/selectors/propertyPaneSelectors";
import {
  getIsWidgetFocused,
  getWidgetsAreSelected,
  getSelectedWidgetsIds,
} from "legacy/selectors/sagaSelectors";

function* endCanvasSelectionSaga(
  action: ReduxAction<{ widgetId: string; forceUnselectAll?: boolean }>,
) {
  const currentSelectedWidgets: string[] = yield select(getSelectedWidgetsIds);
  const isPropertyPaneVisible: boolean = yield select(getIsPropertyPaneVisible);
  // to handle situation when showPropertyPane is not called conditionally in selectWidgetAncestrySaga
  // 1. dragging from no right pane
  // 2. dragging with right pane opened but only selecting one widget.
  if (!isPropertyPaneVisible) {
    return;
  }

  if (
    (currentSelectedWidgets.length > 0 && !isPropertyPaneVisible) ||
    (currentSelectedWidgets.length === 1 && isPropertyPaneVisible)
  ) {
    if (!action.payload.forceUnselectAll) {
      yield put(
        showWidgetPropertyPane(
          currentSelectedWidgets[currentSelectedWidgets.length - 1],
        ),
      );
    }
  }
}

function* startCanvasSelectionSaga(action: ReduxAction<{ widgetId: string }>) {
  const stopAction: ReduxAction<{
    widgetId: string;
    forceUnselectAll?: boolean;
  }> = yield take(ReduxActionTypes.STOP_CANVAS_SELECTION);
  yield call(endCanvasSelectionSaga, stopAction);
}

function* clearPropertyPaneSaga(): Generator<any, any, any> {
  const appHasSelectedWidgets = yield select(getWidgetsAreSelected);
  const appHasFocusedWidgets = yield select(getIsWidgetFocused);
  const isPropertyPaneVisible = yield select(getIsPropertyPaneVisible);

  if (appHasSelectedWidgets) yield put(selectWidgets([], false));
  if (appHasFocusedWidgets) yield put(focusWidget(undefined));

  if (isPropertyPaneVisible) {
    yield put(showWidgetPropertyPane(PAGE_WIDGET_ID));
  }
}

export default function* selectionCanvasSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.START_CANVAS_SELECTION,
      startCanvasSelectionSaga,
    ),
  ]);
  yield all([
    takeLatest(ReduxActionTypes.CLEAR_PROPERTY_PANE, clearPropertyPaneSaga),
  ]);
}
