/**
 * Adapted from https://codemirror.net/5/addon/selection/active-line.js
 *
 * Defines a styleActiveLine option that, when enabled, gives the wrapper of the line
 * that contains the cursor the class CodeMirror-activeline, adds a background with the
 * class CodeMirror-activeline-background, and adds the class CodeMirror-activeline-gutter
 * to the line's gutter space is enabled.
 *
 * Demo: https://codemirror.net/5/demo/activeline.html
 *
 * The option's value may be a boolean or an object specifying the following options:
 * @nonEmpty: bool - Controls whether single-line selections, or just cursor selections, are styled.
 *                   Defaults to false (only cursor selections).
 */

/* eslint-disable */

import CodeMirror from "codemirror";

(function () {
  const WRAP_CLASS = "CodeMirror-activeline";
  const BACK_CLASS = "CodeMirror-activeline-background";
  const GUTT_CLASS = "CodeMirror-activeline-gutter";

  function clearActiveLines(cm: any) {
    for (let i = 0; i < cm.state.activeLines.length; i++) {
      cm.removeLineClass(cm.state.activeLines[i], "wrap", WRAP_CLASS);
      cm.removeLineClass(cm.state.activeLines[i], "background", BACK_CLASS);
      cm.removeLineClass(cm.state.activeLines[i], "gutter", GUTT_CLASS);
    }
  }

  function sameArray(a: any, b: any) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
    return true;
  }

  function updateActiveLines(cm: any, ranges: any) {
    const active: any = [];
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      const option = cm.getOption("styleActiveLine");
      if (
        typeof option === "object" && option.nonEmpty
          ? range.anchor.line !== range.head.line
          : !range.empty()
      )
        continue;
      const line = cm.getLineHandleVisualStart(range.head.line);
      if (active[active.length - 1] !== line) active.push(line);
    }
    if (sameArray(cm.state.activeLines, active)) return;
    cm.operation(function () {
      clearActiveLines(cm);
      for (let i = 0; i < active.length; i++) {
        cm.addLineClass(active[i], "wrap", WRAP_CLASS);
        cm.addLineClass(active[i], "background", BACK_CLASS);
        cm.addLineClass(active[i], "gutter", GUTT_CLASS);
      }
      cm.state.activeLines = active;
    });
  }

  function selectionChange(cm: any, sel: any) {
    updateActiveLines(cm, sel.ranges);
  }

  CodeMirror.defineOption("styleActiveLine", false, function (cm, val, old) {
    var prev = old == CodeMirror.Init ? false : old;
    if (val == prev) return;
    if (prev) {
      cm.off("beforeSelectionChange", selectionChange);
      clearActiveLines(cm);
      delete cm.state.activeLines;
    }
    if (val) {
      cm.state.activeLines = [];
      updateActiveLines(cm, cm.listSelections());
      cm.on("beforeSelectionChange", selectionChange);
    }
  });
})();
