import {
  generatedThemeStylesEditMode,
  generateWrapperStyles,
  generateThemedStyleSheet,
  generatedEditorWrapperStyles,
} from "./generatedStyles/themeStyles";
import { loadThemeFonts } from "./typefaces/utils";
import { GeneratedTheme } from "./types";

// all modes, inside iframe only
export function applyThemeToIframe(generatedTheme: GeneratedTheme) {
  loadThemeFonts(generatedTheme);
  generateThemedStyleSheet(generatedTheme);
}

// edit mode styles for inside iframe only
export function applyThemeToEditorIframe(generatedTheme: GeneratedTheme) {
  generatedThemeStylesEditMode(generatedTheme);
}

// edit mode styles for outside the iframe
export function applyThemeToEditorWrapper(generatedTheme: GeneratedTheme) {
  generatedEditorWrapperStyles(generatedTheme);
}

// in all modes, outside the iframe
export function applyThemeToWrapper(generatedTheme: GeneratedTheme) {
  generateWrapperStyles(generatedTheme);
}

export * from "./types";
