import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension } from "@superblocksteam/shared";
import { uniqBy } from "lodash";
import { select } from "redux-saga/effects";
import {
  DETACHED_WIDGETS,
  GridDefaults,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { APP_MODE } from "legacy/reducers/types";
import { updateSectionWidgetCanvasHeights } from "legacy/sagas/WidgetOperationsSagasUtils";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getSingleWidgetProps } from "legacy/selectors/editorSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import {
  DynamicWidgetsVisibilityState,
  getDynamicVisibilityWidgets,
} from "legacy/selectors/visibilitySelectors";
import WidgetFactory from "legacy/widgets/Factory";
import { fastClone } from "utils/clone";
import {
  getAverageFor,
  resetAverageFor,
  trackPerformance,
} from "utils/decorators/timing";
import { getWidgets } from "../../selectors/sagaSelectors";
import { LayoutSystemManager } from "./LayoutSystemManager";
import { AutoHeightWidget } from "./Widget";
import {
  DynamicChanges,
  YInfos,
  dynamicHeight,
  ReflowResult,
} from "./compositeReflowTypes";
import { LayoutUpdateBuilder } from "./singleReflow";
import { toAHWidget } from "./utils";
import { sortByYX } from "./utils";
import type { CanvasWidgetsReduxState } from "legacy/widgets/Factory";

interface IReflow {
  reflow: (changes: DynamicChanges) => Generator<any, ReflowResult, any>;
  onDelete: (widgetId: string) => void;
}

export class Reflow implements IReflow {
  private widgetsByContainer: Record<string, AutoHeightWidget[]> = {};
  private layoutSystems = new LayoutSystemManager();
  private heightCache = new Map<string, number>();

  *reflow(changes: DynamicChanges) {
    const start = performance.now();

    const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);
    const shouldAccountForHiddenWidgets = appMode !== "EDIT";

    const theme: ReturnType<typeof selectGeneratedTheme> =
      yield select(selectGeneratedTheme);
    const dynamicVisibility: DynamicWidgetsVisibilityState = yield select(
      getDynamicVisibilityWidgets,
    );

    const originalStaticWidgets: CanvasWidgetsReduxState =
      yield select(getWidgets);
    const staticWidgets: CanvasWidgetsReduxState = fastClone(
      originalStaticWidgets,
    );

    console.debug(
      "Reflowing",
      Object.entries(changes).map(([id, change]) => ({
        widget: staticWidgets[id]?.widgetName ?? `Unknown Widget ${id}`,
        change,
      })),
    );

    // Step 1: For each widget with dynamic height find all their parents and depth
    const depthMapSorted = this.createDepthMap(changes, staticWidgets);

    // Step2: We need to reflow containers, starting from the deepest container
    // and going up the tree
    const changedYs: YInfos = {};
    for (const { id: containerId } of depthMapSorted) {
      // Step2.1: Reflow the children of the container
      const result = this.reflowChildren(containerId, staticWidgets, changes);

      // Step2.2: Update the children's heights
      // Also update staticWidgets because we need to use the new height in the computeMinHeightFromProps function
      for (const [id, heightInfo] of Object.entries(result.changes)) {
        changedYs[id] = heightInfo;

        const top = Dimension.build(
          Dimension.toGridUnit(
            Dimension.px(heightInfo.top),
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw().value,
          staticWidgets[id].top.mode,
        );

        const heightMode = staticWidgets[id].height.mode;
        const isPx = heightMode === "px";
        const height = isPx
          ? Dimension.px(heightInfo.height)
          : Dimension.build(
              Dimension.toGridUnit(
                Dimension.px(heightInfo.height),
                GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              ).raw().value,
              heightMode,
            );

        staticWidgets[id] = {
          ...staticWidgets[id],
          top,
          height,
        };
      }

      // Step2.3: Update the container's height if it's a fitContent container
      const container: ReturnType<typeof getSingleWidgetProps> = yield select(
        getSingleWidgetProps,
        containerId,
      );

      // All Canvases are fit content
      const containerIsFitContent =
        container?.height.mode === "fitContent" ||
        container.type === WidgetTypes.CANVAS_WIDGET;

      if (containerIsFitContent) {
        let heightPx: number | undefined = undefined; // undefined means we don't need to update the height
        const collapseWidget =
          shouldAccountForHiddenWidgets &&
          container.isVisible === false &&
          container.collapseWhenHidden;

        if (collapseWidget) {
          heightPx = 0;
        } else {
          const measuredHeight = WidgetFactory.getWidgetComputedHeight(
            container,
            staticWidgets,
            theme,
            appMode ?? APP_MODE.PUBLISHED,
            dynamicVisibility,
          );
          heightPx = measuredHeight?.value;
        }

        // Update the container's height if
        // it's supplied a static height
        if (heightPx !== undefined) {
          if (container.type !== WidgetTypes.SECTION_WIDGET) {
            const topPx = Dimension.toPx(
              container.top,
              GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
            ).value;
            changedYs[containerId] = {
              top: topPx,
              height: heightPx,
            };
            // Append the new height to the heights object to be used by the next container
            changes[containerId] = dynamicHeight(heightPx);
          } else {
            updateSectionWidgetCanvasHeights(
              staticWidgets,
              theme,
              appMode ?? APP_MODE.PUBLISHED,
              container,
              changedYs,
              changes,
            );
          }
        }
      }
    }

    // Step3: Apply changes to staticWidgets
    const reflowResult: ReflowResult = {
      changes: {},
    };
    Object.entries(changedYs).forEach(([id, heightInfo]) => {
      if (staticWidgets[id].height.mode !== "fillParent") {
        const top = Dimension.build(
          Dimension.toGridUnit(
            Dimension.px(heightInfo.top),
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw().value,
          staticWidgets[id].top.mode,
        );

        const heightMode = staticWidgets[id].height.mode;
        const isPx = heightMode === "px";
        const height = isPx
          ? Dimension.px(heightInfo.height)
          : Dimension.build(
              Dimension.toGridUnit(
                Dimension.px(heightInfo.height),
                GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              ).raw().value,
              heightMode,
            );

        const original = originalStaticWidgets[id];
        const changed =
          original.top.value !== top.value ||
          original.height.value !== height.value;

        if (changed) {
          reflowResult.changes[id] = {
            top,
            height,
          };
        }
      }
    });

    this.logResult(start, reflowResult, originalStaticWidgets);
    return reflowResult;
  }

  onDelete(widgetId: string) {
    // Delete the layout system for this widget
    this.layoutSystems.delete(widgetId);
    delete this.widgetsByContainer[widgetId];
  }

  arrayValuesEquals = (a: AutoHeightWidget[], b: AutoHeightWidget[]) => {
    if (a.length !== b.length) return false;
    return a.every((value, index) => equal(value, b[index]));
  };

  @trackPerformance("createDepthMap", true)
  private createDepthMap(
    changes: Record<string, unknown>,
    staticWidgets: CanvasWidgetsReduxState,
  ) {
    // TODO(Layouts) we should check for fixedHeight containers and not get their parents
    const depthMap = Object.entries(changes).flatMap(([id]) => {
      const parents = [];
      const widget = staticWidgets[id];
      let parent = staticWidgets[widget.parentId];
      while (parent) {
        parents.push({
          id: parent.widgetId,
          name: parent.widgetName,
        });
        parent = staticWidgets[parent.parentId];
      }
      return parents.map((parent, index) => ({
        ...parent,
        depth: parents.length - index,
      }));
    });
    const depthMapSorted = uniqBy(depthMap, (a) => a.id).sort(
      (a, b) => b.depth - a.depth,
    );
    return depthMapSorted;
  }

  @trackPerformance("reflowChildren", true)
  private reflowChildren(
    containerId: string,
    widgets: CanvasWidgetsReduxState,
    updates: DynamicChanges,
  ): { changes: YInfos } {
    const container = widgets[containerId];
    const children = (container.children ?? []).map((id) => widgets[id]);

    if (children.length === 0) {
      return {
        changes: {},
      };
    }

    const { newChildren, oldChildren } = this.getContainerChildren(
      containerId,
      children,
    );

    const childrenChanged = !this.arrayValuesEquals(newChildren, oldChildren);
    const layoutSystem = this.layoutSystems.get(containerId, container.type);
    if (childrenChanged) {
      this.saveContainerChildren(containerId, newChildren);
      layoutSystem.updateLayout(LayoutUpdateBuilder.fullUpdate(newChildren));
    }

    const newHeights: Record<string, number> = this.getHeightValue(
      updates,
      widgets,
    );

    const ysBefore = getYInfo(newChildren);
    layoutSystem.updateDynamicHeights(newHeights);
    const afterReflow = layoutSystem.getLayout();
    const ysAfter = getYInfo(afterReflow);

    this.saveContainerChildren(containerId, afterReflow);

    const changes = Object.entries(ysAfter).reduce((acc, [id, props]) => {
      const visibilityChanged = typeof updates[id] === "boolean";
      const posChanged = !equal(ysBefore[id], props);
      return posChanged || visibilityChanged
        ? {
            ...acc,
            [id]: {
              ...props,
            },
          }
        : acc;
    }, {} as YInfos);
    return { changes };
  }

  /**
   * Saves the children of a container and adds a yIndex if there are two widgets with the same y.
   * The yIndex is based on the order of the children.
   * @param containerId - The ID of the container to save the children for.
   * @param children - The children widgets to save.
   */
  private saveContainerChildren(
    containerId: string,
    children: AutoHeightWidget[],
  ) {
    const yIndexMap: Record<number, number> = {};
    children.forEach((widget) => {
      const yIndex = yIndexMap[widget.y] ?? 0;
      widget.yIndex = yIndex;
      yIndexMap[widget.y] = yIndex + 1;
    });
    this.widgetsByContainer[containerId] = children;
  }

  /**
   * Retrieves the old and new children of a container, sorted by their y and x coordinates.
   * @param containerId - The ID of the container to retrieve children for.
   * @param children - The flattened widget props to convert to real widgets and include in the new children.
   * @returns An object containing the old children and new children, sorted by their y and x coordinates.
   */
  private getContainerChildren(
    containerId: string,
    children: FlattenedWidgetProps[],
  ) {
    const oldChildren = this.widgetsByContainer[containerId] ?? [];
    const newChildren = sortByYX(
      children
        .filter((w) => DETACHED_WIDGETS.indexOf(w.type) === -1)
        .map(toAHWidget)
        .map((w) => ({
          ...w,
          yIndex: oldChildren.find((o) => o.id === w.id)?.yIndex ?? 0,
        })),
    );
    return { newChildren, oldChildren };
  }

  /**
   * Returns a record of widget heights based on the provided updates and widgets.
   * @param updates - An object containing updates to widget heights.
   * @param widgets - The current state of the canvas widgets.
   * @returns A record of widget heights.
   */
  private getHeightValue(
    updates: DynamicChanges,
    widgets: CanvasWidgetsReduxState,
  ) {
    const getPreviousHeight = (id: string): number => {
      const update = updates[id];

      switch (update.type) {
        case "visibility":
          if (update.value) {
            return this.heightCache.get(id) ?? widgets[id].height.value;
          } else {
            // If the widget is hidden, cache the height to the previous height
            if (widgets[id].height.value !== 0) {
              this.heightCache.set(
                id,
                Dimension.toPx(
                  widgets[id].height,
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                ).value,
              );
            }
            return 0;
          }
        case "height":
          return update.value;
      }
    };

    // map heights to number
    const newHeights: Record<string, number> = {};
    for (const id of Object.keys(updates)) {
      newHeights[id] = getPreviousHeight(id);
    }
    return newHeights;
  }

  private logResult(
    start: number,
    reflowResult: ReflowResult,
    widgets: CanvasWidgetsReduxState,
  ) {
    const results = Object.entries(reflowResult.changes).reduce(
      (acc, [id, props]) => {
        const widgetName = widgets[id]?.widgetName ?? `Unknown Widget[${id}]`;
        acc[widgetName] = props;
        return acc;
      },
      {} as ReflowResult["changes"],
    );
    const timing = {
      total: performance.now() - start,
      reflowChildren: getAverageFor(this.reflowChildren),
      createDepthMap: getAverageFor(this.createDepthMap),
    };
    console.debug("Reflow Result", { results, timing });
    resetAverageFor(this.reflowChildren);
    resetAverageFor(this.createDepthMap);
  }
}

function getYInfo(afterReflow: AutoHeightWidget[]) {
  const yInfos: YInfos = {};
  for (const widget of afterReflow) {
    yInfos[widget.id] = {
      top: widget.y,
      height: widget.height,
    };
  }
  return yInfos;
}
