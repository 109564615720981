// TODO: use reflect-meta package, it's current considered experimental
// Create a key to store the metadata under. This should be a symbol.
// import "reflect-metadata";
// const lastCallElapsedKey = Symbol("lastCallElapsedKey");

const timing = new WeakMap<any, number>();
const total = new WeakMap<any, { count: number; total: number }>();

/**
 * Wraps a function and returns a new function that does the same thing, but also measures how long
 * it takes to run
 * @param {string} [message] - The message to be displayed in the performance tab.
 */
function trackPerformance(message?: string, average = false) {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    const mName = message ?? name;

    descriptor.value = function (...args: any) {
      performance.mark?.(`start ${mName}`);
      const timeStart = performance.now();

      const result = method.bind(this)(...args);

      const timeTaken = performance.now() - timeStart;
      performance.measure?.(mName, `start ${mName}`);

      performance.clearMarks?.(`start ${mName}`);
      performance.clearMeasures?.(mName);

      timing.set(descriptor.value, timeTaken);
      if (average) {
        const tot = total.get(descriptor.value) ?? {
          count: 0,
          total: 0,
        };
        tot.count++;
        tot.total += timeTaken;
        total.set(descriptor.value, tot);
      }
      return result;
    };
  };
}

/**
 * It returns the last timing of a function, or -1 if the function has never been timed
 * Note {@link trackPerformance} must be used on this function
 * @param fn - The function to be timed.
 * @returns The last timing of the function.
 */
function lastTimingFor(fn: (...args: any[]) => any) {
  return timing.get(fn) ?? -1;
}

function getTotalFor(fn: (...args: any[]) => any) {
  const i = total.get(fn) ?? { count: 0, total: 0 };
  return { ...i, average: i.total / i.count };
}

function resetAverageFor(fn: (...args: any[]) => any) {
  total.set(fn, { count: 0, total: 0 });
}

export {
  trackPerformance,
  lastTimingFor,
  getTotalFor as getAverageFor,
  resetAverageFor,
};
