import { useSelector } from "react-redux";
import { useContextSelector, useContext } from "use-context-selector";
import { useAppSelector } from "store/helpers";
import {
  EnrichedExecutionResult,
  getTriggerTypeFromApi,
} from "store/slices/apisV2";
import {
  selectApiName,
  selectCachedControlFlowById,
  selectV2BlockById,
  selectV2BlocksById,
} from "store/slices/apisV2/control-flow/control-flow-selectors";
import { ControlFlowFrontendDSL } from "store/slices/apisV2/control-flow/types";
import {
  selectV2ApiById,
  selectV2ApiMetaById,
} from "store/slices/apisV2/selectors";
import {
  ControlFlowContextType,
  ControlFlowContext,
  BlockRegistryType,
  emptyControlFlow,
} from "./ControlFlowContext";
import { ControlFlowExplorerState } from "./control-flow-explorer-state";
import { ResultsPanelState } from "./results-panel-state";

/**
 * @deprecated
 * This hook has been deprecated due to performance issues,
 * it leads to unnecessary re-renders as it triggers every time the context state changes.
 * Please use `useControlFlowSelector` instead, which allows you to select specific parts
 * of the context state, thus avoiding unnecessary re-renders.
 */
export const useControlFlowContext = (): ControlFlowContextType => {
  return useContext(ControlFlowContext);
};

export const useControlFlowSelector = <Selected>(
  sel: (s: ControlFlowContextType) => Selected,
) => {
  return useContextSelector(ControlFlowContext, sel);
};

export const useControlFlowIsWorkflowMode = (): boolean => {
  return useControlFlowSelector((context) => context.isWorkflowMode);
};

export const useControlFlowBlockRegistry = (): BlockRegistryType => {
  return useControlFlowSelector((context) => context.blockRegistry);
};

export const useControlFlowExplorerState = (): ControlFlowExplorerState => {
  return useControlFlowSelector((context) => context.explorerState);
};

export const useControlFlowResultsPanelState = (): [
  ResultsPanelState,
  ControlFlowContextType["setResultsPanelState"],
] => {
  return [
    useControlFlowSelector((context) => context.resultsPanelState),
    useControlFlowSelector((context) => context.setResultsPanelState),
  ];
};

export const useIsApiRunning = (): boolean => {
  return useControlFlowSelector((context) => context.isApiRunning);
};

export const useApiId = (): string => {
  return useControlFlowSelector((context) => context.apiId);
};

export const useControlFlowDSL = <R = ControlFlowFrontendDSL>(
  selector?: (state: ControlFlowFrontendDSL) => R,
) => {
  const apiId = useApiId();
  const controlFlowState = useAppSelector((state) => {
    const controlFlow =
      selectCachedControlFlowById(state, apiId) ?? emptyControlFlow;
    return selector ? selector(controlFlow) : (controlFlow as R);
  });

  return controlFlowState;
};

export const useAPIName = () => {
  const apiId = useApiId();
  return useAppSelector((s) => selectApiName(s, apiId));
};

export const useCurrentTriggerType = () => {
  const apiId = useApiId();
  return useAppSelector((state) => {
    const api = selectV2ApiById(state, apiId);
    if (!api?.apiPb) {
      return undefined;
    }
    return getTriggerTypeFromApi(api.apiPb);
  });
};

export const useControlFlowBlocks = () => {
  const apiId = useApiId();
  return useSelector(
    (state) => selectV2BlocksById(state, apiId) ?? emptyControlFlow.blocks,
  );
};

export const useControlFlowBlock = (blockId?: string) => {
  const apiId = useApiId();
  return useAppSelector((state) => selectV2BlockById(state, apiId, blockId));
};

export const useEnrichedExecutionResult = <
  R = EnrichedExecutionResult | undefined,
>(
  selector?: (state: EnrichedExecutionResult | undefined) => R,
): R => {
  const apiId = useApiId();
  const execResult = useAppSelector((state) => {
    const result = selectV2ApiMetaById(state, apiId)?.enrichedExecutionResult;
    return selector ? selector(result) : (result as R);
  });
  return execResult;
};
