import { Typography } from "antd";
import React, { useEffect } from "react";
import { GITHUB_ACCESS_TOKEN_STORAGE_KEY } from "pages/Repositories";
import { HttpMethod, callServer } from "../../store/utils/client";

const GitHubOAuthCallback = () => {
  const [fetching, setFetching] = React.useState(false);
  useEffect(() => {
    (async () => {
      try {
        setFetching(true);
        console.log(`window.location.search: ${window.location.search}`);
        const urlParams = new URLSearchParams(window.location.search);
        const accessCode = urlParams.get("code");
        if (accessCode) {
          console.log(`accessCode: ${accessCode}`);
          const { accessToken } = await callServer<{ accessToken: string }>({
            method: HttpMethod.Post,
            url: "/github/oauth-callback",
            removeApiPath: true,
            query: {
              code: accessCode,
            },
          });

          localStorage.setItem(GITHUB_ACCESS_TOKEN_STORAGE_KEY, accessToken);
          window.close();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refresh = urlParams.get("refresh");
    if (refresh) {
      localStorage.setItem("refreshGithubOnboardingModal", "true");
      window.close();
    }
  }, []);

  return (
    <div>
      {fetching ? (
        <Typography.Text>Authenticating with GitHub...</Typography.Text>
      ) : (
        <Typography.Text>
          An error occurred while trying to authenticate with GitHub. Please try
          again.
        </Typography.Text>
      )}
    </div>
  );
};

export default GitHubOAuthCallback;
