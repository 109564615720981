import { noop } from "lodash";
import React, { useMemo, Context, createContext } from "react";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import useJwt from "hooks/ui/useJwt";

type JwtProviderType = {
  jwt?: string;
  isLoadingJwt: boolean;
  fetchJwt: () => void;
};

export const JwtContext: Context<JwtProviderType> =
  createContext<JwtProviderType>({
    jwt: "",
    isLoadingJwt: false,
    fetchJwt: noop,
  });

export const JwtContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [jwt, fetchJwt, isLoadingJwt] = useJwt();

  const value = useMemo(
    () => ({
      jwt,
      fetchJwt,
      isLoadingJwt,
    }),
    [jwt, isLoadingJwt, fetchJwt],
  );

  // we cannot load app while fetching sb jwt as it is required for all api calls to cache
  if (isLoadingJwt) {
    return <FullPageSpinner />;
  }
  return <JwtContext.Provider value={value}>{children}</JwtContext.Provider>;
};
