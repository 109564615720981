import { cloneDeep } from 'lodash';
import { pageV6, pageV7, pageV8 } from '../pages';
import type { AnyPageDSL, PageDSL4and5, PageDSL6, PageDSL7, PageDSL8, WidgetProps8 } from '../types/page';

type GenerateLayoutFn = (featureFlags?: Flags) => AnyPageDSL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Flags = Record<string, boolean | number | string | Record<string, any>>;

export const defaultPageLayouts: Record<string, GenerateLayoutFn> = {
  '5': (_featureFlags?: Flags): PageDSL4and5 => {
    const v5: PageDSL4and5 = {
      ...cloneDeep(pageV6),
      version: 5
    };
    return v5;
  },
  '6': (_featureFlags?: Flags): PageDSL6 => cloneDeep(pageV6),
  '7': (_featureFlags?: Flags): PageDSL7 => cloneDeep(pageV7),
  '8': (_featureFlags?: Flags): PageDSL8 => {
    let page = cloneDeep(pageV8);

    try {
      const firstSection = page.children?.[0] as unknown as WidgetProps8;
      const firstColumn = firstSection.children?.[0] as unknown as WidgetProps8;

      // Set page minWidth to 300
      page.minWidth = { value: 300, mode: 'px' };

      // Set the first section to fitContent
      firstSection.height.mode = 'fitContent';
      firstSection.height.value = 8;
      delete firstSection.minHeight;

      // Set the first column to scrollContents
      firstColumn.shouldScrollContents = true;

      // Sets the first section stackOnMobile to 480px, Breakpoint.mobile
      firstSection.stackColumnsAt = { value: 480, mode: 'px' };

      // Update the layoutVersion to 2, this will skip migrations
      page.layoutVersion = 2;
    } catch (e) {
      console.error('FAILED TO UPDATE PAGE LAYOUT TO V8');
      console.error(e);

      // We don't need to throw an error here, but we should fix it because the defaults will be wrong
      // Instead we just return the default page
      page = cloneDeep(pageV8);
    }

    return page;
  }
};

export const defaultLayouts = {
  5: (): PageDSL4and5 => {
    const v5: PageDSL4and5 = {
      ...cloneDeep(pageV6),
      version: 5
    };
    return v5;
  },
  6: (): PageDSL6 => cloneDeep(pageV6),
  7: (): PageDSL7 => cloneDeep(pageV7),
  8: (): PageDSL8 => cloneDeep(pageV8)
};

// This logic is only used when creating pages. It should only contain
// the current and next version, delete any unused versions
export function getDefaultPageLayout(version: number | string, featureFlags: Flags): AnyPageDSL {
  if (defaultPageLayouts[String(version)]) {
    return defaultPageLayouts[String(version)](featureFlags);
  } else {
    throw Error(`Missing layout for version ${version}`);
  }
}

export const DEFAULT_PAGE_NAME = 'Page1';
