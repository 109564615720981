import { DropdownOption } from "@superblocksteam/shared";
import Typography from "antd/lib/typography";
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import logger from "utils/logger";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";

interface DropdownProps {
  showSearch?: boolean;
  optionFilterProp?: string;
  ["data-test"]?: string;
  integrationId: string;
  pluginId?: string;
  actionOptions: {
    label?: string;
    value: string;
    children: { label: string; value: string; description?: string }[];
  }[];
  secondaryPath: string;
  secondaryInitialValue?: unknown;
}

type Props = DropdownProps & DynamicFormItemProps;

export const ActionDropdown = ({
  path,
  secondaryPath,
  label,
  placeholder,
  initialValue,
  secondaryInitialValue,
  actionOptions = [],
  loading,
  rules,
  ...otherProps
}: Props) => {
  const SEPARATOR = ";";
  const context = useContext(FormContext);

  const [value, setValue] = useState<string>(
    [secondaryInitialValue as string, initialValue as string].join(SEPARATOR),
  );

  useEffect(() => {
    const existingInitialValue = context.getValue(path);
    const existingSecondaryValue = context.getValue(secondaryPath);
    if (
      typeof initialValue !== "undefined" &&
      !existingInitialValue &&
      typeof secondaryInitialValue !== "undefined" &&
      !existingSecondaryValue
    ) {
      setValue(
        [secondaryInitialValue as string, initialValue as string].join(
          SEPARATOR,
        ),
      );
    } else {
      setValue(
        [existingSecondaryValue as string, existingInitialValue as string].join(
          SEPARATOR,
        ),
      );
    }
  }, [path, secondaryPath, context, initialValue, secondaryInitialValue]);

  const [validationMessage, setValidationMessage] = useState("");

  const [sectionValue, actionValue] = useMemo(() => {
    const parts = value.split(SEPARATOR);
    if (parts.length !== 2) {
      logger.error(`unexpected action dropdown form value ${value}`);
      return [secondaryInitialValue as string, initialValue as string];
    }
    return parts;
  }, [value, initialValue, secondaryInitialValue]);

  useEffect(() => {
    // Subscribes to the action enum path
    const unsubscribePath = context.subscribe(path, (newActionVal: any) => {
      const newValue = `${sectionValue}${SEPARATOR}${newActionVal}`;
      if (value !== newValue) {
        setValue(newValue); //update UI
      }
    });

    return () => {
      unsubscribePath();
    };
  }, [path, context, value, sectionValue]);

  useEffect(() => {
    // Subscribes to the section/secondary path
    const unsubscribeSecondaryPath = context.subscribe(
      secondaryPath ?? "",
      (newSectionValue: any) => {
        const newValue = `${newSectionValue}${SEPARATOR}${actionValue}`;
        if (value !== newValue) {
          setValue(newValue); //update UI
        }
      },
    );
    return () => {
      unsubscribeSecondaryPath();
    };
  }, [secondaryPath, context, value, actionValue]);

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [
    path,
    rules,
    context,
    context.registerValidation,
    context.unregisterValidation,
  ]);

  const [options] = useMemo(() => {
    const options: DropdownOption[] = [];

    for (const section of actionOptions) {
      if (section.children.length > 1) {
        options.push({
          key: section.value,
          value: section.value,
          displayName: section.label,
          isGroupHeader: true,
        });
      }
      for (const action of section.children) {
        options.push({
          key: `${section.value}${SEPARATOR}${action.value}`,
          value: `${section.value}${SEPARATOR}${action.value}`,
          displayName: action.label,
          subText: action.description ?? "",
        });
      }
    }

    return [options];
  }, [actionOptions]);

  const onChange = useCallback(
    (val: DropdownOption) => {
      const [sectionValue, actionValue] = val.value.split(SEPARATOR);
      context.onChange(path, actionValue, { debounced: true });
      context.onChange(secondaryPath ?? "", sectionValue);
    },
    [context, path, secondaryPath],
  );

  return (
    <div>
      <label>
        <FormLabel hidden={otherProps.hidden}>{label}</FormLabel>
        <FormTooltip tooltip={otherProps.tooltip} />
        <RecommendedSingleDropdown
          data-test={otherProps["data-test"]}
          value={value}
          onChange={onChange}
          options={options ?? []}
          renderSelectedOptionWithStyles={true}
        />
      </label>
      <Typography.Text type="danger">{validationMessage}</Typography.Text>
    </div>
  );
};
