import { KVPair } from "@superblocksteam/shared";
import { Input } from "antd";
import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as CrossIcon } from "assets/icons/common/cross.svg";
import CodeEditor from "components/app/CodeEditor";
import {
  AutocompleteConfiguration,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { Button } from "components/ui/Button";
import { FullWidthSpace } from "components/ui/Space";
import type { ChangeEvent } from "react";

interface DynamicFormFieldItemProps {
  autocompleteConfiguration: AutocompleteConfiguration;
  autocompleteAdditionalData?: Record<string, Record<string, unknown>>;
  idx: number;
  kvPair: KVPair;
  path: string;
  readOnly?: boolean;
  onRemove: (idx: number) => void;
  onKeyChange: (
    e: ChangeEvent<HTMLInputElement> | string | number,
    idx: number,
  ) => void;
  onValueChange: (value: string, idx: number) => void;
}

const StyledFullWidthSpace = styled(FullWidthSpace)`
  div:nth-child(1) {
    width: 100%;
  }

  div:nth-child(2) {
    width: 100%;
  }
`;

const CloseButton = styled(Button)`
  color: ${(props) => props.theme.colors.GREY_500};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &,
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.GREY_500};
    border-color: ${(props) => props.theme.colors.GREY_100};
  }
  :hover {
    background-color: ${(props) => props.theme.colors.GREY_25};
  }
  :active {
    background-color: ${(props) => props.theme.colors.GREY_50};
  }
  :disabled,
  :hover:disabled,
  :active:disabled,
  :focus:disabled {
    color: ${(props) => props.theme.colors.GREY_300};
    background-color: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
    cursor: not-allowed !important;
  }
`;

export const DynamicFormFieldItem = React.memo(
  ({
    autocompleteConfiguration,
    autocompleteAdditionalData,
    idx,
    kvPair,
    path,
    readOnly,
    onRemove,
    onKeyChange,
    onValueChange,
    hidden,
  }: DynamicFormFieldItemProps & {
    hidden?: boolean;
  }) => {
    const theme = useTheme();

    const input = useMemo<Omit<Partial<any>, "checked">>(
      () => ({
        value: kvPair.value,
        onChange: (val: unknown) => onValueChange(val as string, idx),
      }),
      [idx, kvPair.value, onValueChange],
    );

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => onKeyChange(e, idx),
      [idx, onKeyChange],
    );

    const handleRemove = useCallback(() => {
      onRemove(idx);
    }, [idx, onRemove]);

    const isDisabled =
      kvPair.editable !== undefined ||
      kvPair.editable ||
      readOnly ||
      kvPair.system;

    return (
      <div
        key={idx}
        style={{ display: hidden ? "none" : "block", paddingBottom: "8px" }}
      >
        <StyledFullWidthSpace direction="horizontal" size={10}>
          <Input
            id={`${path}_key_${idx}`}
            placeholder={"Key"}
            value={kvPair.key}
            onChange={handleChange}
            disabled={isDisabled}
            style={{ fontSize: theme.text.sizes.default }}
          />
          {isDisabled ? (
            <Input
              id={`${path}_value_${idx}`}
              placeholder={"Value"}
              value={kvPair.value}
              disabled={true}
              style={{ fontSize: 12 }}
            />
          ) : (
            <CodeEditor
              autocompleteConfiguration={autocompleteConfiguration}
              additionalDynamicData={autocompleteAdditionalData}
              data-test={`dynamic-text-${path}_value_${idx}`}
              showLineNumbers={false}
              showShortcutMenu={false}
              mode={"text-js" as any}
              tabBehaviour={TabBehaviour.INPUT}
              enableSearch={false}
              theme={EditorTheme.LIGHT}
              size={EditorSize.EXTENDED}
              minHeight="30px" // awkward size to match antd's input
              maxHeight="350px"
              monospace={true}
              input={input}
              placeholder={"Value"}
              disabled={kvPair.editable !== undefined || kvPair.editable}
              noScroll
            />
          )}
          <CloseButton
            data-test={`form_delete_${idx}_button`}
            icon={<CrossIcon />}
            aria-label="Delete"
            type="default"
            onClick={handleRemove}
            disabled={isDisabled}
          />
        </StyledFullWidthSpace>
      </div>
    );
  },
);

DynamicFormFieldItem.displayName = "DynamicFormFieldItem";
