import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  ClearEvaluatedWidgetLayoutPayload,
  DynamicProperties,
  SetEvaluatedWidgetLayoutPayload,
} from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";

export const updateWidgetDynamicProps = (
  widgetId: string,
  props: Partial<DynamicProperties>,
) => {
  return {
    type: ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS,
    payload: {
      widgetId,
      props,
    } satisfies SetEvaluatedWidgetLayoutPayload & any,
  };
};

export const clearWidgetDynamicHeight = (widgetId: string) => {
  return {
    type: ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT,
    payload: { widgetId } satisfies ClearEvaluatedWidgetLayoutPayload,
  };
};

export const clearWidgetDynamicWidth = (widgetId: string) => {
  return {
    type: ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_WIDTH,
    payload: { widgetId } satisfies ClearEvaluatedWidgetLayoutPayload,
  };
};
