import {
  DB_SQL_INITIAL_TEXT,
  EditorLanguage,
  FormComponentType,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType,
  FormItem
} from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';
import { makeDropdownItem } from './shared/db';

export const DatabricksPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2'
};

enum ConnectionType {
  PERSONAL_ACCESS_TOKEN = '1',
  MACHINE_TO_MACHINE = '2'
}

const CONNECTION_METHODS_AND_DISPLAY_NAMES_V2 = {
  [ConnectionType.PERSONAL_ACCESS_TOKEN]: 'Personal access token',
  [ConnectionType.MACHINE_TO_MACHINE]: 'Machine-to-machine'
};

const BASE_HOST = {
  label: 'Host',
  name: 'connection.hostUrl',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'warehouse-staging.cloud.databricks.com',
  rules: [{ required: true, message: 'Host is required' }]
};

const BASE_PORT = {
  label: 'Port',
  name: 'connection.port',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.NUMBER,
  initialValue: 443,
  rules: [{ required: true, message: 'Port is required' }]
};

const BASE_PATH = {
  label: 'Path',
  name: 'connection.path',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: '/sql/1.0/warehouses/warehouse',
  rules: [{ required: true, message: 'Path is required' }]
};

const BASE_ACCESS_TOKEN = {
  label: 'Access token',
  name: 'connection.token',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.PASSWORD,
  rules: [{ required: true, message: 'Access token is required' }]
};

const BASE_DEFAULT_CATALOG = {
  label: 'Default catalog',
  name: 'connection.defaultCatalog',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'hive_datastore'
};

const BASE_DEFAULT_SCHEMA = {
  label: 'Default schema',
  name: 'connection.defaultSchema',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'default'
};

export const DatabricksPlugin: Plugin = {
  id: 'databricks',
  name: 'Databricks',
  moduleName: 'DatabricksPlugin',
  modulePath: 'plugins/databricks/DatabricksPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/databricks.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/databricks',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          // HOST
          {
            ...BASE_HOST,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_HOST,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN, ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          // PORT
          {
            ...BASE_PORT,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_PORT,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN, ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          // PATH
          {
            ...BASE_PATH,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_PATH,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN, ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          // DEFAULT CATALOG
          {
            ...BASE_DEFAULT_CATALOG,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_DEFAULT_CATALOG,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN, ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          // DEFAULT SCHEMA
          {
            ...BASE_DEFAULT_SCHEMA,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_DEFAULT_SCHEMA,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN, ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          {
            label: 'Connection method',
            name: 'connection.connectionType',
            startVersion: DatabricksPluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: ConnectionType.PERSONAL_ACCESS_TOKEN,
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES_V2).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          // ACCESS TOKEN
          {
            ...BASE_ACCESS_TOKEN,
            startVersion: DatabricksPluginVersions.V1,
            endVersion: DatabricksPluginVersions.V1
          } as FormItem,
          {
            ...BASE_ACCESS_TOKEN,
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.PERSONAL_ACCESS_TOKEN]
              }
            }
          } as FormItem,
          // OAUTH CLIENT ID
          {
            label: 'OAuth client ID',
            name: 'connection.oauthClientId',
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'OAuth client ID is required' }],
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem,
          // OAUTH CLIENT SECRET
          {
            label: 'OAuth client secret',
            name: 'connection.oauthClientSecret',
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'OAuth client secret is required' }],
            startVersion: DatabricksPluginVersions.V2,
            display: {
              show: {
                'connection.connectionType': [ConnectionType.MACHINE_TO_MACHINE]
              }
            }
          } as FormItem
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'runSql.sqlBody',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'runSql.useParameterized',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
