import {
  SelectedStateVarProps,
  TriggerStepType,
  ValidStepDef,
  StepDef,
  MultiStepDef,
  ValidMultiStepDef,
  isValidStepDef,
  isValidMultiStepDef,
  ApplicationScope,
} from "@superblocksteam/shared";

export type {
  SelectedStateVarProps,
  ValidStepDef,
  StepDef,
  MultiStepDef,
  ValidMultiStepDef,
};

export { TriggerStepType, isValidStepDef, isValidMultiStepDef };

interface ExecuteActionCallStackItem {
  type: EventType;
  propertyPath: string;
}

export type ExecuteActionCallStack = ExecuteActionCallStackItem[];

export type ExecuteActionPayloadEvent = {
  type: EventType;
  callStack?: ExecuteActionCallStack;
  callbackId?: string;
  spanId?: string;
};

export type ExecutionResult = {
  success: boolean;
};

export type RunEventHandlersPayload = {
  steps: MultiStepDef;
  event: ExecuteActionPayloadEvent;
  currentScope: ApplicationScope; // for example, components will use PAGE
  propertyPath?: string;
  additionalNamedArguments?: Record<string, unknown>;
  additionalEventAttributes?: Record<string, string | undefined>;
};

export enum EventType {
  ON_RESET = "ON_RESET",
  ON_PAGE_LOAD = "ON_PAGE_LOAD",
  ON_PREV_PAGE = "ON_PREV_PAGE",
  ON_NEXT_PAGE = "ON_NEXT_PAGE",
  ON_FILTERS_CHANGED = "ON_FILTERS_CHANGED",
  ON_ERROR = "ON_ERROR",
  ON_SUCCESS = "ON_SUCCESS",
  ON_CANCEL = "ON_CANCEL",
  ON_CELL_SELECTED = "ON_CELL_SELECTED",
  ON_SEARCH = "ON_SEARCH",
  ON_CLICK = "ON_CLICK",
  ON_FILES_SELECTED = "ON_FILES_SELECTED",
  ON_HOVER = "ON_HOVER",
  ON_TOGGLE = "ON_TOGGLE",
  ON_LOAD = "ON_LOAD",
  ON_TEXT_CHANGE = "ON_TEXT_CHANGE",
  ON_SUBMIT = "ON_SUBMIT",
  ON_CHECK_CHANGE = "ON_CHECK_CHANGE",
  ON_SELECT = "ON_SELECT",
  ON_DATE_SELECTED = "ON_DATE_SELECTED",
  ON_DATE_RANGE_SELECTED = "ON_DATE_RANGE_SELECTED",
  ON_OPTION_CHANGE = "ON_OPTION_CHANGE",
  ON_CLEAR = "ON_CLEAR",
  ON_MARKER_CLICK = "ON_MARKER_CLICK",
  ON_CREATE_MARKER = "ON_CREATE_MARKER",
  ON_TAB_CHANGE = "ON_TAB_CHANGE",
  ON_VIDEO_START = "ON_VIDEO_START",
  ON_VIDEO_END = "ON_VIDEO_END",
  ON_VIDEO_PLAY = "ON_VIDEO_PLAY",
  ON_VIDEO_PAUSE = "ON_VIDEO_PAUSE",
  ON_SLIDEOUT_OPEN = "ON_SLIDEOUT_OPEN",
  ON_SLIDEOUT_CLOSE = "ON_SLIDEOUT_CLOSE",
  ON_SELECT_DATA = "ON_SELECT_DATA",
  ON_IFRAME_MESSAGE_RECEIVED = "ON_IFRAME_MESSAGE_RECEIVED",
  ON_MODAL_OPEN = "ON_MODAL_OPEN",
  ON_MODAL_CLOSE = "ON_MODAL_CLOSE",
  ON_CHAT_MESSAGE_SEND = "ON_CHAT_MESSAGE_SEND",
  ON_TIMER_INTERVAL = "ON_TIMER_INTERVAL",
  ON_RUN_CLICK = "ON_RUN_CLICK",
  ON_API_SUCCESS = "ON_API_SUCCESS",
  ON_API_ERROR = "ON_API_ERROR",
  ON_FOCUS = "ON_FOCUS",
  ON_BLUR = "ON_BLUR",
  ON_MESSAGE = "ON_MESSAGE",
  ON_CALLOUT_CTA_CLICK = "ON_CALLOUT_CTA_CLICK",
  ON_CALLOUT_DISMISS = "ON_CALLOUT_DISMISS",
  ON_CUSTOM_COMPONENT_EVENT = "ON_CUSTOM_COMPONENT_EVENT",
  ON_EMBED_PROP_CHANGE = "ON_EMBED_PROP_CHANGE",
  ON_EMBED_EVENT = "ON_EMBED_EVENT",
  ON_CUSTOM_EVENT = "ON_CUSTOM_EVENT",
  ON_ROUTE_LOAD = "ON_ROUTE_LOAD",
  ON_TRIGGER_EVENT = "ON_TRIGGER_EVENT",
}

export interface PageAction {
  id: string;
  // TODO: This type looks unused
  pluginType:
    | "API"
    | "QUERY"
    | "NAVIGATION"
    | "ALERT"
    | "JS_FUNCTION"
    | "SET_VALUE"
    | "DOWNLOAD";
  name: string;
  timeoutInMillisecond: number;
}

export const EXECUTION_PARAM_KEY = "executionParams";
