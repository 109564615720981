export const VALID_CANVAS_SCALE_FACTORS = [
  0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5,
];

export enum SideBarKeys {
  COMPONENTS = "components",
  NAVIGATION = "navigation",
  STATE_VARS = "state-vars",
  TIMERS = "timers",
  SETTINGS_PROFILES = "settings_profiles",
  DEVELOPER_PREFERENCES = "developer_preferences",
  EMBEDDING = "embedding",
  DEBUGGER = "debugger",
  CUSTOM_COMPONENTS = "custom-components",
  VERSIONS = "versions",
  COMMAND_BAR = "command-bar",
  THEME = "theme",
  CUSTOM_EVENTS = "custom-events",
}
