import {
  EMBED_PATH_PREFIX,
  EmbedProperty,
} from "legacy/constants/EmbeddingConstants";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { getPopoverConfig } from "legacy/widgets/eventHandlerPanel";

export const EMBED_PROP_PROPERTIES: PropertyPaneConfig<EmbedProperty>[] = [
  {
    sectionName: "General",
    children: [
      {
        helpText:
          "Sets the test value of the embed property. This value will be used when the application is not embedded.",
        propertyName: "defaultValue",
        label: "Test value",
        controlType: "INPUT_TEXT",
        placeholderText: "test value",
        isBindProperty: true,
        isTriggerProperty: false,
        dataTreePrefix: EMBED_PATH_PREFIX,
      },
      getPopoverConfig<EmbedProperty>(
        "onChange",
        "Actions that fire when the value of the embed property changes when the application is embedded",
        {
          label: "onChange",
        },
        undefined,
        true,
        "onChange",
      ),
    ],
  },
];
