import {
  ActionTypeEnum,
  Agent,
  Organization,
  ResourceTypeEnum,
} from "@superblocksteam/shared";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { User } from "legacy/constants/userConstants";
import { UIEvent } from "utils/event";
import loginState from "utils/loginState";
import logger from "../../utils/logger";
import { convertToQueryParams } from "../constants/routes";
import { STORAGE_KEYS } from "../utils/StorageUtils";
import localStorage from "../utils/localStorage";
import Api from "./Api";
import { ApiResponse } from "./ApiResponses";

interface FetchUserResponse {
  id: string;
}

interface FetchUserRequest {
  id: string;
}

// Keep this synced with the same type name in the server
export interface UserMeDto {
  user: User;
  organizations: Organization[];
  agents: Agent[];
  flagBootstrap: LDFlagSet;
  permissions: Array<{
    actionType: ActionTypeEnum;
    resourceType: ResourceTypeEnum;
  }>;
}

class UserApi extends Api {
  static usersURL = "v1/users";
  static currentUserURL = "v1/users/me";
  static currentVisitorUrl = "v1/visitors/me";

  static fetchUser(request: FetchUserRequest) {
    return Api.get<ApiResponse<FetchUserResponse>>(
      UserApi.usersURL + "/" + request.id,
    );
  }

  static getCurrentVisitor(trackingParams?: Record<string, string>) {
    const queryParams: Record<string, string> = {};
    const visitorId = localStorage.getItem(STORAGE_KEYS.VISITOR_ID);
    if (visitorId) {
      queryParams["visitorId"] = visitorId;
    }
    if (trackingParams) {
      Object.entries(trackingParams).forEach(([key, value]) => {
        if (String(value).trim() !== "") {
          queryParams[key] = value;
        }
      });
    }

    return Api.get<ApiResponse<UserMeDto>>(
      `${UserApi.currentUserURL}${convertToQueryParams(queryParams)}`,
    ).then((resp) => {
      if (resp.data.user.id) {
        localStorage.setItem(STORAGE_KEYS.VISITOR_ID, resp.data.user.id);
      }
      return resp;
    });
  }

  static getCurrentUser(trackingParams?: Record<string, string>) {
    const queryParams: Record<string, string> = {};
    if (trackingParams) {
      Object.entries(trackingParams).forEach(([key, value]) => {
        if (String(value).trim() !== "") {
          queryParams[key] = value;
        }
      });
    }
    return Api.get<ApiResponse<UserMeDto>>(
      `${UserApi.currentUserURL}${convertToQueryParams(queryParams)}`,
    ).then((resp) => {
      const userId = resp.data.user.id;
      const isNewUser = resp.data.user.new;
      logger.event(UIEvent.SESSION_START);

      //  this is used by Google Tag Manager analytics.
      if (loginState.newlyLoggedIn) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: isNewUser ? "UserSignup" : "UserLogin",
          email: resp.data.user.email,
          userid: userId,
        });
        const event = isNewUser ? UIEvent.SIGNED_UP : UIEvent.LOGGED_IN;
        logger.info(`User ${userId} ${event}.`);
        logger.event(event);
      }
      loginState.newlyLoggedIn = false;
      return resp;
    });
  }
}

export default UserApi;
