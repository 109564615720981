import React from "react";

import {
  ReactComponent as CodeIcon,
  default as CodeIconPath,
} from "assets/icons/common/code.svg";
import {
  ReactComponent as ConditionControlIcon,
  default as ConditionControlIconPath,
} from "assets/icons/control-flow/control-condition.svg";
import {
  ReactComponent as ErrorHandlerControlIcon,
  default as ErrorHandlerControlIconPath,
} from "assets/icons/control-flow/control-error-handler.svg";
import {
  ReactComponent as LoopControlIcon,
  default as LoopControlIconPath,
} from "assets/icons/control-flow/control-loop.svg";
import {
  ReactComponent as ParallelControlIcon,
  default as ParallelControlIconPath,
} from "assets/icons/control-flow/control-parallel.svg";
import {
  ReactComponent as StreamControlIcon,
  default as StreamControlIconPath,
} from "assets/icons/control-flow/control-stream.svg";
import {
  ReactComponent as ThrowControlIcon,
  default as ThrowControlIconPath,
} from "assets/icons/control-flow/control-throw.svg";
import {
  ReactComponent as ControlVariablesIcon,
  default as ControlVariablesIconPath,
} from "assets/icons/control-flow/control-variables.svg";
import {
  ReactComponent as WaitControlIcon,
  default as WaitControlIconPath,
} from "assets/icons/control-flow/control-wait.svg";
import {
  ReactComponent as BreakFunctionIcon,
  default as BreakFunctionIconPath,
} from "assets/icons/control-flow/function-break.svg";
import {
  ReactComponent as ReturnFunctionIcon,
  default as ReturnFunctionIconPath,
} from "assets/icons/control-flow/function-return.svg";
import {
  ReactComponent as SendFunctionIcon,
  default as SendFunctionIconPath,
} from "assets/icons/control-flow/function-send.svg";
import BreakPreviewImage from "assets/images/control-flow/break-preview.png";
import ConditionPreviewImage from "assets/images/control-flow/condition-preview.png";
import LoopPreviewImage from "assets/images/control-flow/loop-preview.png";
import ParallelPreviewImage from "assets/images/control-flow/parallel-preview.png";
import ReturnPreviewImage from "assets/images/control-flow/return-preview.png";
import SendPreviewImage from "assets/images/control-flow/send-preview.png";
import StreamPreviewImage from "assets/images/control-flow/stream-preview.png";
import ThrowPreviewImage from "assets/images/control-flow/throw-preview.png";
import TryCatchPreviewImage from "assets/images/control-flow/try-catch-preview.png";
import VariablesPreviewImage from "assets/images/control-flow/variables-preview.png";
import WaitPreviewImage from "assets/images/control-flow/wait-preview.png";

import {
  BlockType,
  GenericBlock,
  HelpContentMap,
  StepConfig,
} from "store/slices/apisV2/control-flow/types";
import { getPluginById } from "utils/integrations";
import documentation from "./documentation";

type BlockInfo = {
  IconComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      children?: React.ReactNode;
    }
  >;
  IconPath: string;
};

export const BLOCK_TYPE_INFO: Record<BlockType, BlockInfo> = {
  [BlockType.CONDITION]: {
    IconComponent: ConditionControlIcon,
    IconPath: ConditionControlIconPath,
  },
  [BlockType.LOOP]: {
    IconComponent: LoopControlIcon,
    IconPath: LoopControlIconPath,
  },
  [BlockType.PARALLEL]: {
    IconComponent: ParallelControlIcon,
    IconPath: ParallelControlIconPath,
  },
  [BlockType.TRY_CATCH]: {
    IconComponent: ErrorHandlerControlIcon,
    IconPath: ErrorHandlerControlIconPath,
  },
  [BlockType.VARIABLES]: {
    IconComponent: ControlVariablesIcon,
    IconPath: ControlVariablesIconPath,
  },
  [BlockType.RETURN]: {
    IconComponent: ReturnFunctionIcon,
    IconPath: ReturnFunctionIconPath,
  },
  [BlockType.BREAK]: {
    IconComponent: BreakFunctionIcon,
    IconPath: BreakFunctionIconPath,
  },
  [BlockType.WAIT]: {
    IconComponent: WaitControlIcon,
    IconPath: WaitControlIconPath,
  },
  [BlockType.THROW]: {
    IconComponent: ThrowControlIcon,
    IconPath: ThrowControlIconPath,
  },
  [BlockType.STEP]: {
    IconComponent: CodeIcon, // This is a placeholder and shouldn't be rendered
    IconPath: CodeIconPath,
  },
  [BlockType.SEND]: {
    IconComponent: SendFunctionIcon,
    IconPath: SendFunctionIconPath,
  },
  [BlockType.STREAM]: {
    IconComponent: StreamControlIcon,
    IconPath: StreamControlIconPath,
  },
};

export const getIconFromBlock = (block: GenericBlock) => {
  const { type } = block;
  switch (type) {
    case BlockType.STEP: {
      const stepConfig = block.config as StepConfig;
      const plugin = getPluginById(stepConfig.pluginId);
      const iconLoc = plugin?.iconLocation ?? BLOCK_TYPE_INFO[type].IconPath;
      const pluginName = stepConfig.pluginId ?? "unknown";
      const StepIcon = () => <img src={iconLoc} alt={pluginName} />;
      return StepIcon;
    }
    default: {
      const info = BLOCK_TYPE_INFO[type];
      return info?.IconComponent ?? CodeIcon;
    }
  }
};

export const getIconPathFromBlock = (block: GenericBlock) => {
  const { type } = block;
  switch (type) {
    case BlockType.STEP: {
      const stepConfig = block.config as StepConfig;
      const plugin = getPluginById(stepConfig.pluginId);
      const iconLoc = plugin?.iconLocation ?? BLOCK_TYPE_INFO[type].IconPath;
      return iconLoc;
    }
    default: {
      const info = BLOCK_TYPE_INFO[type];
      return info?.IconPath ?? CodeIconPath;
    }
  }
};

export const BLOCK_HELP_CONTENT: HelpContentMap = {
  [BlockType.STEP]: {
    description: "",
    imageSrc: "",
  },
  [BlockType.CONDITION]: {
    description: "Run different blocks based on a condition",
    imageSrc: ConditionPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#condition",
    fieldHelpContent: {
      condition: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#condition",
        description:
          "The condition checked to determine whether to execute this branch",
      },
      showElse: { description: "Show the else branch of the conditional" },
    },
  },
  [BlockType.LOOP]: {
    description:
      "Run blocks multiple times. Supports while, for, and for each loops",
    imageSrc: LoopPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#loop",
    fieldHelpContent: {
      forIterations: {
        description: "The number of times to run the loop",
        link: "https://docs.superblocks.com/backend-logic/control-blocks#for-loop",
      },
      forEachIterations: {
        description: "The list of items to iterate over",
        link: "https://docs.superblocks.com/backend-logic/control-blocks#for-each-loop",
      },
      whileCondition: {
        description:
          "The condition checked on every iteration - must be true for the next iteration to run.",
        link: "https://docs.superblocks.com/backend-logic/control-blocks#while-loop",
      },
    },
  },
  [BlockType.PARALLEL]: {
    description: "Run multiple blocks in parallel",
    imageSrc: ParallelPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#parallel",
    fieldHelpContent: {
      parallelType: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#parallel",
        description:
          "Static parallel blocks run a fixed number of paths, while dynamic parallel blocks run a variable number of paths",
      },
      waitFor: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#parallel",
        description:
          "Whether to wait for all paths to complete before continuing. If set to none, API execution will continue without waiting for paths to complete",
      },
      enablePooling: {
        description: "Used to limit the number of paths that can run at once",
      },
      poolSize: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#parallel",
        description: "The maximum number of paths that can run at once.",
      },
      dynamicItems: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#dynamic-parallel",
        description:
          "The list of items to iterate over. For each item, the child blocks will run in parallel",
      },
    },
  },
  [BlockType.TRY_CATCH]: {
    description: "Run blocks and handle errors",
    imageSrc: TryCatchPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#try-catch",
    fieldHelpContent: {
      showFinally: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#try-catch",
        description:
          "The finally section always runs after the try and catch sections, regardless of whether there is an error", // docs link: https://docs.superblocks.com/backend-logic/control-blocks#try-catch-block
      },
    },
  },
  [BlockType.SEND]: {
    description: "Send a message that will be handled by an onMessage handler",
    imageSrc: SendPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#send",
    documentation: documentation.SEND_DOCUMENTATION,
  },
  [BlockType.STREAM]: {
    description: "Stream data from an external source and process the results",
    imageSrc: StreamPreviewImage,
    documentation: documentation.STREAM_DOCUMENTATION,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#stream",
    fieldHelpContent: {
      autoSend: {
        description:
          "The message value will be the message received from the trigger step or the output returned from the final process step. You can disable this setting and use the Send block instead to control when messages are sent",
      },
      showProcess: {
        description:
          "Be sure to return a value from the final process step when auto-sending messages so you can access it in the onMessage event handler",
      },
    },
  },
  [BlockType.VARIABLES]: {
    description: "Set variables to be used in other blocks",
    imageSrc: VariablesPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#variables",
  },
  [BlockType.RETURN]: {
    description: "Return a value from the API",
    imageSrc: ReturnPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#return",
  },
  [BlockType.THROW]: {
    description: "Throw an error from the API",
    imageSrc: ThrowPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#throw-error",
  },
  [BlockType.WAIT]: {
    description: "Wait for a parallel block to finish",
    imageSrc: WaitPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#wait",
  },
  [BlockType.BREAK]: {
    description: "Break out of a loop",
    imageSrc: BreakPreviewImage,
    link: "https://docs.superblocks.com/backend-logic/control-blocks#break",
    fieldHelpContent: {
      ifCondition: {
        link: "https://docs.superblocks.com/backend-logic/control-blocks#break",
        description: "The condition checked to determine whether to break",
      },
    },
  },
};
