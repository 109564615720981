import {
  ActionTypeEnum,
  IntegrationConfigurationDto,
  Profile,
  RbacRole,
} from "@superblocksteam/shared";
import { isEmpty } from "lodash";

export type AvailablePlugin = {
  iconLocation?: string;
  pluginId: string;
  pluginName: string;
  pluginType: string;
  isSupported: boolean;
};

export type ConnectedIntegration = {
  datasourceName: string;
  datasourceId: string;
  datasourceSlug?: string;
  iconLocation?: string;
  pluginId?: string;
  pluginName?: string;
  pluginType?: string;
  permissions?: Array<ActionTypeEnum>;
  configurations: IntegrationConfigurationDto[];
  // TODO(rbac): remove this once RBAC v2 is GA
  role?: RbacRole;
  isSupported: boolean;
  createdBy?: string;
  lastUpdated?: Date;
};

// used to render configuration navigation in the integration page
export type ConfigMeta = {
  id: string;
  isDefault: boolean;
  profileIds: string[];
  created: Date;
  originalProfileIds: string[];
};

export const generateEmptyConfigTooltipText = (
  configMeta: ConfigMeta,
  profiles: Profile[],
) => {
  const originProfileNames = profiles
    .filter((profile) => configMeta.originalProfileIds.includes(profile.id))
    .map((profile) => profile.displayName)
    .join(", ");

  return `${
    !isEmpty(configMeta.originalProfileIds)
      ? `**${originProfileNames}** no longer ${
          configMeta.originalProfileIds.length > 1 ? "use" : "uses"
        } this configuration`
      : "No profiles use this configuration"
  }.${configMeta.isDefault ? "" : " Config will be deleted when you save."}`;
};

export enum IntegrationPageTabKeys {
  Configurations = "Configurations",
  OpenApiSpec = "OpenApiSpec",
}
